/* eslint-disable camelcase */
import moment from 'moment';
import { nightsBetween } from '@nomady/shared/utils/dates';
const getDatesApiParams = (dates) => {
    if ((dates === null || dates === void 0 ? void 0 : dates.startDate) &&
        (dates === null || dates === void 0 ? void 0 : dates.endDate) &&
        moment(dates.startDate).isBefore(moment(dates.endDate))) {
        const start = moment(dates === null || dates === void 0 ? void 0 : dates.startDate).utc().toISOString();
        const end = moment(dates === null || dates === void 0 ? void 0 : dates.endDate).utc().toISOString();
        const operator = ',';
        const minBookableNights = nightsBetween(dates === null || dates === void 0 ? void 0 : dates.startDate, dates === null || dates === void 0 ? void 0 : dates.endDate);
        const pub_minBookableNights = `${operator}${minBookableNights + 1}`;
        const pub_maxBookableNights = `${minBookableNights}${operator}`;
        const availability = 'day-full';
        return {
            start,
            end,
            availability,
            pub_minBookableNights,
            pub_maxBookableNights,
        };
    }
    return {};
};
export default getDatesApiParams;
