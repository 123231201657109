import moment from 'moment';
import Decimal from '../utils/decimal';
import { LINE_ITEM_ADULT_TAXES, LINE_ITEM_CHILD_TAXES, LINE_ITEM_CLEANING_FEE, LINE_ITEM_CUSTOMER_COMMISSION, LINE_ITEM_GIFT_CARD_DEDUCTION_1, LINE_ITEM_GIFT_CARD_DEDUCTION_2, LINE_ITEM_GIFT_CARD_DEDUCTION_3, LINE_ITEM_GIFT_CARD_PROVIDER_PAYOUT, LINE_ITEM_GIFT_CARD_TOTAL_TRANSACTION_VALUE, LINE_ITEM_PROVIDER_COMMISSION, LINE_ITEM_TEEN_TAXES, TOURIST_TAX_LINE_ITEMS, } from '../utils/lineItemTypes';
import { types } from '../utils/sdkLoader';
import getTotalTransactionValue from './utils/getTotalTransactionValue';
import getTransactionProtectedData from './utils/getTransactionProtectedData';
const { Money } = types;
export var REFUND_TYPE;
(function (REFUND_TYPE) {
    REFUND_TYPE["CUSTOMER_BEFORE_ACCEPT"] = "customer-before-accept";
    REFUND_TYPE["CUSTOMER_WITHIN_CANCELLATION_POLICY"] = "customer-within-cancellation-policy";
    REFUND_TYPE["CUSTOMER_OUTSIDE_CANCELLATION_POLICY_BEFORE_CHECKIN"] = "customer-outside-cancellation-policy-before-checkin";
    REFUND_TYPE["CUSTOMER_OUTSIDE_CANCELLATION_POLICY_AFTER_CHECKIN"] = "customer-outside-cancellation-policy-after-checkin";
    REFUND_TYPE["PROVIDER_DECLINE_BOOKING"] = "provider-decline-booking";
    REFUND_TYPE["AUTOMATIC_EXPIRE_BOOKING_REQUEST"] = "automatic-expire-booking-request";
    REFUND_TYPE["OPERATOR_PROVIDER_PAYOUT_AND_PROVIDER_COMMISSION"] = "operator-provider-payout-and-provider-commission";
    REFUND_TYPE["OPERATOR_EVERYTHING"] = "operator-everything";
    REFUND_TYPE["OPERATOR_DECLINE_BOOKING"] = "operator-decline-booking";
})(REFUND_TYPE || (REFUND_TYPE = {}));
export const REFUND_TYPES_ELIGIBLE_FOR_FULL_REFUND = [
    REFUND_TYPE.CUSTOMER_BEFORE_ACCEPT,
    REFUND_TYPE.OPERATOR_EVERYTHING,
    REFUND_TYPE.PROVIDER_DECLINE_BOOKING,
    REFUND_TYPE.AUTOMATIC_EXPIRE_BOOKING_REQUEST,
    REFUND_TYPE.OPERATOR_DECLINE_BOOKING,
];
export const REFUND_TYPES_PARTIAL_REFUND_NO_COMMISSION = [
    REFUND_TYPE.CUSTOMER_OUTSIDE_CANCELLATION_POLICY_BEFORE_CHECKIN,
];
const REFUND_TYPES_ELIGIBLE_FOR_REFUND_EXCEPT_CUSTOMER_COMMISSION = [
    REFUND_TYPE.CUSTOMER_WITHIN_CANCELLATION_POLICY,
    REFUND_TYPE.OPERATOR_PROVIDER_PAYOUT_AND_PROVIDER_COMMISSION,
];
class RefundCalculator {
    constructor(refundType, payinDate, lineItems, creditCardPayin, giftCard1, giftCard2, giftCard3, lastLegalCancellationDate) {
        var _a, _b;
        this.getRefundType = () => {
            return this.refundType;
        };
        this.getFullCreditCardRefund = () => {
            if (!this.creditCardPayin) {
                return;
            }
            // eslint-disable-next-line consistent-return
            return new Money(-1 * this.creditCardPayin.amount, this.creditCardPayin.currency);
        };
        this.getFullGiftCardRefund = (index) => {
            const currentGiftCard = this[`giftCard${index}`];
            if (!currentGiftCard) {
                return;
            }
            const refund = new Money(-1 * currentGiftCard.amount, currentGiftCard.currency);
            // eslint-disable-next-line consistent-return
            return Object.assign(Object.assign({}, refund), { metadata: currentGiftCard.metadata });
        };
        this.getFullRefund = () => {
            return {
                creditCard: this.getFullCreditCardRefund(),
                giftCard1: this.getFullGiftCardRefund(1),
                giftCard2: this.getFullGiftCardRefund(2),
                giftCard3: this.getFullGiftCardRefund(3),
                total: new Money(-1 * this.totalPayin.amount, this.totalPayin.currency),
                refundType: this.refundType,
            };
        };
        this.getPartialRefund = (nonRefundableValue) => {
            const refundValue = this.totalPayin.amount - nonRefundableValue.amount;
            let remainingRefundValue = refundValue;
            const lastLegalCancellationDate = this.getLastLegalCancellationDate();
            const refund = {
                total: new Money(-1 * refundValue, this.customerCommission.currency),
                refundType: this.refundType,
                lastLegalCancellationDate,
            };
            if (Number(refund.total.amount) === Number(-0)) {
                refund.total = new Money(0, this.customerCommission.currency);
            }
            if (this.giftCard3) {
                if (remainingRefundValue <= this.giftCard3.amount) {
                    const giftCard3Refund = new Money(-1 * remainingRefundValue, this.giftCard3.currency);
                    return Object.assign(Object.assign({}, refund), { giftCard3: Object.assign(Object.assign({}, giftCard3Refund), { metadata: this.giftCard3.metadata }) });
                }
                const giftCard3FullRefund = new Money(-1 * this.giftCard3.amount, this.giftCard3.currency);
                refund.giftCard3 = Object.assign(Object.assign({}, giftCard3FullRefund), { metadata: this.giftCard3.metadata });
                remainingRefundValue -= this.giftCard3.amount;
            }
            if (this.giftCard2) {
                if (remainingRefundValue <= this.giftCard2.amount) {
                    const giftCard2Refund = new Money(-1 * remainingRefundValue, this.giftCard2.currency);
                    return Object.assign(Object.assign({}, refund), { giftCard2: Object.assign(Object.assign({}, giftCard2Refund), { metadata: this.giftCard2.metadata }) });
                }
                const giftCard2FullRefund = new Money(-1 * this.giftCard2.amount, this.giftCard2.currency);
                refund.giftCard2 = Object.assign(Object.assign({}, giftCard2FullRefund), { metadata: this.giftCard2.metadata });
                remainingRefundValue -= this.giftCard2.amount;
            }
            if (this.giftCard1) {
                if (remainingRefundValue <= this.giftCard1.amount) {
                    const giftCard1Refund = new Money(-1 * remainingRefundValue, this.giftCard1.currency);
                    return Object.assign(Object.assign({}, refund), { giftCard1: Object.assign(Object.assign({}, giftCard1Refund), { metadata: this.giftCard1.metadata }) });
                }
                const giftCard1FullRefund = new Money(-1 * this.giftCard1.amount, this.giftCard1.currency);
                refund.giftCard1 = Object.assign(Object.assign({}, giftCard1FullRefund), { metadata: this.giftCard1.metadata });
                remainingRefundValue -= this.giftCard1.amount;
            }
            if (this.creditCardPayin) {
                if (remainingRefundValue <= this.creditCardPayin.amount) {
                    return Object.assign(Object.assign({}, refund), { creditCard: new Money(-1 * remainingRefundValue, this.creditCardPayin.currency) });
                }
                refund.creditCard = new Money(-1 * this.creditCardPayin.amount, this.creditCardPayin.currency);
            }
            return refund;
        };
        this.getNoRefund = () => {
            const noRefund = new Money(0, this.customerCommission.currency);
            return {
                creditCard: noRefund,
                giftCard1: noRefund,
                giftCard2: noRefund,
                giftCard3: noRefund,
                total: noRefund,
                refundType: this.refundType,
            };
        };
        this.getRefund = () => {
            var _a;
            const payin = {
                creditCard: this.creditCardPayin,
                giftCard1: this.giftCard1,
                giftCard2: this.giftCard2,
                giftCard3: this.giftCard3,
                date: this.payinDate,
                total: this.totalPayin,
            };
            const nomadyCustomerFee = this.customerCommission;
            const { currency } = this;
            const refundConditions = {
                payin,
                currency,
            };
            if (REFUND_TYPES_ELIGIBLE_FOR_FULL_REFUND.includes(this.refundType)) {
                refundConditions.refund = this.getFullRefund();
                refundConditions.nomadyFee = new Money(0, this.totalPayin.currency);
            }
            else if (REFUND_TYPES_ELIGIBLE_FOR_REFUND_EXCEPT_CUSTOMER_COMMISSION.includes(this.refundType)) {
                refundConditions.refund = this.getPartialRefund(nomadyCustomerFee);
                refundConditions.nomadyFee = nomadyCustomerFee;
            }
            else if (this.refundType ===
                REFUND_TYPE.CUSTOMER_OUTSIDE_CANCELLATION_POLICY_BEFORE_CHECKIN) {
                const totalTouristTaxes = this.lineItems
                    .filter(lineItem => TOURIST_TAX_LINE_ITEMS.includes(lineItem.code))
                    .reduce((previousValue, currentValue) => previousValue + currentValue.lineTotal.amount, 0);
                if (totalTouristTaxes > 0) {
                    refundConditions.touristTaxes = new Money(totalTouristTaxes, currency);
                }
                const cleaningFee = ((_a = this.lineItems.find(lineItem => LINE_ITEM_CLEANING_FEE === lineItem.code)) === null || _a === void 0 ? void 0 : _a.lineTotal.amount) || 0;
                if (0 < cleaningFee) {
                    refundConditions.cleaningFee = new Money(cleaningFee, currency);
                }
                const listingBasePrice = new Money(this.totalPayin.amount -
                    totalTouristTaxes -
                    cleaningFee -
                    nomadyCustomerFee.amount, currency);
                const nonRefundable = new Money(listingBasePrice.amount + nomadyCustomerFee.amount, currency);
                refundConditions.refund = this.getPartialRefund(nonRefundable);
                refundConditions.nomadyFee = nomadyCustomerFee;
                refundConditions.listingBasePrice = listingBasePrice;
            }
            else {
                refundConditions.refund = this.getNoRefund();
            }
            return refundConditions;
        };
        this.getLineItem = (lineItem) => {
            const { quantity, percentage, units } = lineItem;
            const newLineItem = Object.assign(Object.assign({}, lineItem), { quantity,
                percentage,
                units });
            if (newLineItem.seats && newLineItem.units) {
                delete newLineItem.quantity;
            }
            return newLineItem;
        };
        this.getReversalLineItem = (lineItem) => {
            const reversalLineItem = Object.assign(Object.assign({}, lineItem), { lineTotal: new Money(-1 * lineItem.lineTotal.amount, lineItem.lineTotal.currency), reversal: true });
            if (lineItem.seats && lineItem.units) {
                delete reversalLineItem.quantity;
                reversalLineItem.seats = lineItem.seats;
                reversalLineItem.units = lineItem.units.mul(-1);
            }
            else if (lineItem.quantity) {
                reversalLineItem.quantity = lineItem.quantity.mul(-1);
            }
            else if (lineItem.percentage) {
                reversalLineItem.percentage = lineItem.percentage.mul(-1);
            }
            return reversalLineItem;
        };
        this.getLastLegalCancellationDate = () => {
            if (this.lastLegalCancellationDate) {
                return this.lastLegalCancellationDate.toDate();
            }
            return moment(0).toDate();
        };
        this.getLineItemsAfterRefund = () => {
            const lineItems = this.lineItems.map(lineItem => this.getLineItem(lineItem));
            const refund = this.getRefund();
            if (REFUND_TYPES_ELIGIBLE_FOR_FULL_REFUND.includes(refund.refund.refundType)) {
                const reversalLineItems = lineItems.map(lineItem => this.getReversalLineItem(lineItem));
                return [...lineItems, ...reversalLineItems];
            }
            if (REFUND_TYPES_ELIGIBLE_FOR_REFUND_EXCEPT_CUSTOMER_COMMISSION.includes(this.refundType) ||
                this.refundType ===
                    REFUND_TYPE.CUSTOMER_OUTSIDE_CANCELLATION_POLICY_BEFORE_CHECKIN) {
                let lineItemsExceptCustomerCommissionAndGiftCards;
                const BEFORE_CHECKIN_OUTSIDE_CANCELLATION_POLICY_REFUND_LINE_ITEMS = [
                    LINE_ITEM_ADULT_TAXES,
                    LINE_ITEM_TEEN_TAXES,
                    LINE_ITEM_CHILD_TAXES,
                    LINE_ITEM_CLEANING_FEE,
                ];
                const WITHIN_CANCELLATION_POLICY_REFUND_LINE_ITEMS = [
                    LINE_ITEM_CUSTOMER_COMMISSION,
                    LINE_ITEM_GIFT_CARD_TOTAL_TRANSACTION_VALUE,
                    LINE_ITEM_GIFT_CARD_DEDUCTION_1,
                    LINE_ITEM_GIFT_CARD_DEDUCTION_2,
                    LINE_ITEM_GIFT_CARD_DEDUCTION_3,
                ];
                if (this.refundType !==
                    REFUND_TYPE.CUSTOMER_OUTSIDE_CANCELLATION_POLICY_BEFORE_CHECKIN) {
                    lineItemsExceptCustomerCommissionAndGiftCards = lineItems.filter(lineItem => !WITHIN_CANCELLATION_POLICY_REFUND_LINE_ITEMS.includes(lineItem.code));
                }
                else {
                    lineItemsExceptCustomerCommissionAndGiftCards = lineItems.filter(lineItem => BEFORE_CHECKIN_OUTSIDE_CANCELLATION_POLICY_REFUND_LINE_ITEMS.includes(lineItem.code));
                }
                const reversalLineItems = lineItemsExceptCustomerCommissionAndGiftCards.map(lineItem => this.getReversalLineItem(lineItem));
                const giftCardReversalLineItems = [];
                if (this.giftCard1 && refund.refund.giftCard1) {
                    const giftCard1LineItem = lineItems.find(lineItem => lineItem.code === LINE_ITEM_GIFT_CARD_DEDUCTION_1);
                    if (refund.payin.giftCard1.amount === refund.refund.giftCard1.amount) {
                        giftCardReversalLineItems.push(this.getReversalLineItem(giftCard1LineItem));
                    }
                    else {
                        giftCardReversalLineItems.push(Object.assign(Object.assign({}, giftCard1LineItem), { quantity: giftCard1LineItem.quantity.mul(-1), unitPrice: new Money(refund.refund.giftCard1.amount, giftCard1LineItem.unitPrice.currency), lineTotal: new Money(-1 * refund.refund.giftCard1.amount, giftCard1LineItem.lineTotal.currency), reversal: true }));
                    }
                }
                if (this.giftCard2 && refund.refund.giftCard2) {
                    const giftCard2LineItem = lineItems.find(lineItem => lineItem.code === LINE_ITEM_GIFT_CARD_DEDUCTION_2);
                    if (refund.payin.giftCard2.amount === refund.refund.giftCard2.amount) {
                        giftCardReversalLineItems.push(this.getReversalLineItem(giftCard2LineItem));
                    }
                    else {
                        giftCardReversalLineItems.push(Object.assign(Object.assign({}, giftCard2LineItem), { quantity: giftCard2LineItem.quantity.mul(-1), unitPrice: new Money(refund.refund.giftCard2.amount, giftCard2LineItem.unitPrice.currency), lineTotal: new Money(-1 * refund.refund.giftCard2.amount, giftCard2LineItem.lineTotal.currency), reversal: true }));
                    }
                }
                if (this.giftCard3 && refund.refund.giftCard3) {
                    const giftCard3LineItem = lineItems.find(lineItem => lineItem.code === LINE_ITEM_GIFT_CARD_DEDUCTION_3);
                    if (refund.payin.giftCard3.amount === refund.refund.giftCard3.amount) {
                        giftCardReversalLineItems.push(this.getReversalLineItem(giftCard3LineItem));
                    }
                    else {
                        giftCardReversalLineItems.push(Object.assign(Object.assign({}, giftCard3LineItem), { quantity: giftCard3LineItem.quantity.mul(-1), unitPrice: new Money(refund.refund.giftCard3.amount, giftCard3LineItem.unitPrice.currency), lineTotal: new Money(-1 * refund.refund.giftCard3.amount, giftCard3LineItem.lineTotal.currency), reversal: true }));
                    }
                }
                const giftCardTotalTransactionValue = lineItems.find(lineItem => lineItem.code === LINE_ITEM_GIFT_CARD_TOTAL_TRANSACTION_VALUE);
                if (giftCardTotalTransactionValue) {
                    const giftCardTotalTransactionValueAfterRefund = -1 * refund.refund.total.amount;
                    giftCardReversalLineItems.push(Object.assign(Object.assign({}, giftCardTotalTransactionValue), { quantity: giftCardTotalTransactionValue.quantity.mul(-1), unitPrice: new Money(giftCardTotalTransactionValueAfterRefund, giftCardTotalTransactionValue.unitPrice.currency), lineTotal: new Money(-1 * giftCardTotalTransactionValueAfterRefund, giftCardTotalTransactionValue.lineTotal.currency), reversal: true }));
                }
                const lineItemGiftCardProviderPayout = lineItems.find(lineItem => lineItem.code === LINE_ITEM_GIFT_CARD_PROVIDER_PAYOUT);
                if (lineItemGiftCardProviderPayout) {
                    let totalToReturn = 0;
                    lineItemsExceptCustomerCommissionAndGiftCards.forEach(currentValue => {
                        totalToReturn += currentValue.lineTotal.amount;
                    });
                    giftCardReversalLineItems.push({
                        code: LINE_ITEM_GIFT_CARD_PROVIDER_PAYOUT,
                        unitPrice: new Money(totalToReturn, lineItemGiftCardProviderPayout.unitPrice.currency),
                        lineTotal: new Money(totalToReturn, lineItemGiftCardProviderPayout.lineTotal.currency),
                        reversal: true,
                        includeFor: ['provider'],
                        quantity: new Decimal(1),
                    });
                }
                return [...lineItems, ...reversalLineItems, ...giftCardReversalLineItems];
            }
            return lineItems;
        };
        this.lineItems = lineItems;
        this.customerCommission = (_a = lineItems.find(item => item.code === LINE_ITEM_CUSTOMER_COMMISSION)) === null || _a === void 0 ? void 0 : _a.lineTotal;
        this.providerCommission = (_b = lineItems.find(item => item.code === LINE_ITEM_PROVIDER_COMMISSION)) === null || _b === void 0 ? void 0 : _b.lineTotal;
        this.lastLegalCancellationDate = lastLegalCancellationDate;
        this.refundType = refundType;
        this.payinDate = payinDate;
        if (creditCardPayin) {
            this.creditCardPayin = creditCardPayin;
        }
        if (giftCard1) {
            this.giftCard1 = Object.assign(Object.assign({}, giftCard1), { amount: -1 * giftCard1.amount });
        }
        if (giftCard2) {
            this.giftCard2 = Object.assign(Object.assign({}, giftCard2), { amount: -1 * giftCard2.amount });
        }
        if (giftCard3) {
            this.giftCard3 = Object.assign(Object.assign({}, giftCard3), { amount: -1 * giftCard3.amount });
        }
        let totalPayin = 0;
        if (this.giftCard1) {
            totalPayin += this.giftCard1.amount;
        }
        if (this.giftCard2) {
            totalPayin += this.giftCard2.amount;
        }
        if (this.giftCard3) {
            totalPayin += this.giftCard3.amount;
        }
        if (this.creditCardPayin) {
            totalPayin += this.creditCardPayin.amount;
        }
        this.totalPayin = new Money(totalPayin, this.customerCommission.currency);
        this.currency = this.customerCommission.currency;
    }
    /**
     * Returns all necessary transaction protected data for a trasaction
     *
     * @returns {*} Object of protected data
     */
    getTransactionProtectedData() {
        var _a;
        const lineItems = this.getLineItemsAfterRefund();
        const totalTransactionValue = ((_a = getTotalTransactionValue(lineItems)) === null || _a === void 0 ? void 0 : _a.amount) || 0;
        return getTransactionProtectedData(lineItems, totalTransactionValue, this.currency);
    }
}
export default RefundCalculator;
