import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import styled from 'styled-components/macro';
import Text, { TextVariant } from '../../Text/Text';
import { ColorVariant } from '../../../theme';
const Head = styled.div `
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding-top: 30px;
  padding-left: 20px;
  padding-right: 20px;

  @media ${props => props.theme.device.tablet} {
    padding-left: 30px;
    padding-right: 30px;
  }
`;
const Title = styled(Text) `
  margin: 0;
`;
const ModalHeader = ({ title }) => {
    return (_jsx(Head, { children: title && (_jsx(Title, Object.assign({ variant: TextVariant.H50, as: "h2", colorVariant: ColorVariant.Stone }, { children: title }))) }));
};
export default ModalHeader;
