import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styled from 'styled-components/macro';
import { injectIntl } from 'react-intl';
import Icon, { IconVariant, IconSizeVariant } from '../Icon/Icon';
import Text, { TextVariant } from '../Text/Text';
import { ColorVariant } from '../../theme';
const AverageRating = styled.div `
  display: flex;
  text-align: left;
  align-items: center;
  color: ${props => props.theme.color.carrot};
  overflow: hidden;
  & > div {
    height: 12px;
    width: 18px;
  }
`;
const ReviewRating = ({ rating = 0, textVariant = TextVariant.H14, intl, }) => {
    const formatRating = (rating) => {
        if (!rating || rating === 0) {
            return intl.formatMessage({
                id: 'ReviewRating.new',
            });
        }
        if (rating < 100) {
            return rating.toFixed(1);
        }
        return (rating / 100).toFixed(1);
    };
    return (_jsxs(AverageRating, { children: [_jsx(Icon, { variant: IconVariant.Star, sizeVariant: IconSizeVariant.ExtraExtraSmall, colorVariant: ColorVariant.Carrot }), _jsx(Text, Object.assign({ variant: textVariant, colorVariant: ColorVariant.Carrot, "data-testid": "ReviewRating_number" }, { children: formatRating(rating) }))] }));
};
export default injectIntl(ReviewRating);
