import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { ListingLink } from '..';
import EditListingDescriptionWinterForm from '../../forms/EditListingDescriptionWinterForm/EditListingDescriptionWinterForm';
import { Title } from '../EditListingDescriptionPanel/EditListingDescriptionPanel';
const EditListingDescriptionWinterPanel = props => {
    const { listing, onSubmit, onFormDirty, submitButtonText, updateInProgress, errors, } = props;
    return (_jsxs(_Fragment, { children: [_jsx(Title, { children: _jsx(FormattedMessage, { id: "EditListingDescriptionWinterPanel.title", values: { listingTitle: _jsx(ListingLink, { listing: listing }) } }) }), _jsx(EditListingDescriptionWinterForm, { listing: listing, submitButtonLabel: submitButtonText, updateListingInProgress: updateInProgress, updateListingError: errors.updateListingError, onSubmit: onSubmit, onFormDirty: onFormDirty })] }));
};
export default EditListingDescriptionWinterPanel;
