import moment from 'moment';
const getListingCheckInTime = (listing) => {
    var _a;
    const checkInString = (_a = listing.attributes.publicData) === null || _a === void 0 ? void 0 : _a.checkIn;
    if (!checkInString) {
        throw new Error('check in time not set on listing public data');
    }
    const checkInTime = parseTimeString(checkInString);
    return checkInTime;
};
export default getListingCheckInTime;
export const parseTimeString = (checkInString) => {
    const checkInParts = checkInString.split(':');
    const checkInHour = Number.parseInt(checkInParts[0], 10);
    const checkInMinute = Number.parseInt(checkInParts[1], 10);
    const checkInTime = moment('1970-01-01T00:00:00.000Z')
        .set('hour', checkInHour)
        .set('minute', checkInMinute);
    return checkInTime;
};
