var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styled, { css } from 'styled-components/macro';
import { ColorVariant } from '../../theme';
import Text, { TextVariant } from '../Text/Text';
import { Description, Label as FieldLabel } from '../Input/Input';
import CheckedIcon from './assets/checked.svg';
import UncheckedIcon from './assets/unchecked.svg';
import IntermediaryIcon from './assets/intermediary.svg';
import DisabledCheckedIcon from './assets/disabled-checked.svg';
import DisabledUncheckedIcon from './assets/disabled-unchecked.svg';
import ErrorIcon from './assets/error.svg';
import InputErrorMessage from '../InputErrorMessage/InputErrorMessage';
export var CheckboxInputSpacingVariant;
(function (CheckboxInputSpacingVariant) {
    CheckboxInputSpacingVariant["Default"] = "Default";
    CheckboxInputSpacingVariant["Slim"] = "Slim";
})(CheckboxInputSpacingVariant || (CheckboxInputSpacingVariant = {}));
const Wrapper = styled.label `
  display: flex;
  flex-direction: column;
  padding-top: 0;
  padding-bottom: 0;
  width: 100%;
`;
const InputWrapper = styled.div `
  display: flex;
  padding-left: 12px;
`;
const getIcon = (props) => {
    if (props.intermediaryChecked) {
        return css `
      background-image: url(${IntermediaryIcon});
    `;
    }
    if (props.error) {
        return css `
      background-image: url(${ErrorIcon});
    `;
    }
    if (props.disabled) {
        return css `
      background-image: url(${DisabledUncheckedIcon});
    `;
    }
    return css `
    background-image: url(${UncheckedIcon});
  `;
};
const Input = styled.input `
  width: 18px;
  height: 18px;
  opacity: 0;

  + label {
    position: relative;
    display: inline-block;
    cursor: pointer;
    padding: 12px 0;

    width: 100%;

    ${props => props.spacingVariant === CheckboxInputSpacingVariant.Slim &&
    css `
        padding: 0;
      `}

    &::before {
      content: '';
      display: inline-block;
      position: absolute;
      height: 24px;
      width: 24px;
      left: -29px;

      ${props => getIcon(props)};
    }
  }

  &:checked {
    + label::before {
      background-image: url(${CheckedIcon});
    }
  }

  &:checked:disabled {
    + label::before {
      background-image: url(${DisabledCheckedIcon});
    }
  }
`;
const Label = styled(Text) `
  &::first-line {
    line-height: 24px;
  }
`;
const CheckboxInput = (_a) => {
    var { id, checked, intermediaryChecked = false, label, fieldLabel, spacingVariant, children, inputRef, error, description, disabled = false } = _a, rest = __rest(_a, ["id", "checked", "intermediaryChecked", "label", "fieldLabel", "spacingVariant", "children", "inputRef", "error", "description", "disabled"]);
    const hasError = !!error;
    const errorMessageId = hasError ? `${id}-errormessage` : '';
    const errorMessageAriaInputProps = hasError
        ? { 'aria-invalid': true, 'aria-errormessage': errorMessageId }
        : {};
    return (_jsxs(Wrapper, { children: [fieldLabel && (_jsx(FieldLabel, Object.assign({ variant: TextVariant.H13, as: "label", htmlFor: id, hasError: hasError }, { children: fieldLabel }))), _jsxs(InputWrapper, { children: [_jsx(Input, Object.assign({ type: "checkbox", id: id, checked: !intermediaryChecked ? checked : false, intermediaryChecked: intermediaryChecked, disabled: disabled, error: error, ref: inputRef, spacingVariant: spacingVariant }, rest, errorMessageAriaInputProps)), _jsx(Label, Object.assign({ variant: TextVariant.H13, colorVariant: hasError ? ColorVariant.Rose : ColorVariant.Cave, as: "label", htmlFor: id }, { children: label || children }))] }), hasError && _jsx(InputErrorMessage, { id: errorMessageId, error: error }), description && _jsx(Description, { text: description })] }));
};
export default CheckboxInput;
