/* eslint-disable no-prototype-builtins */
import find from 'lodash/find';
import { matchPath } from 'react-router-dom';
import { compile } from 'path-to-regexp';
import { DEFAULT_LOCALE } from '../marketplace-custom-config';
import { stringify } from './urlHelpers';
import Page from '../routeConfiguration/Page';
import { getMarketplaceDomain } from './localized-domains';
const findRouteByName = (nameToFind, locale, routes) => {
    return find(routes, route => route.name === nameToFind && route.language === locale);
};
/**
 * E.g. ```const toListingPath = toPathByRouteName('ListingPage', routes);```
 * Then we can generate listing paths with given params (```toListingPath({ id: uuidX })```)
 */
const toPathByRouteName = (nameToFind, routes, locale = DEFAULT_LOCALE) => {
    let route = findRouteByName(nameToFind, locale, routes);
    if (!route) {
        console.error(`Path "${nameToFind}" was not found in ${locale}.`);
        route = findRouteByName(Page.NotFound, locale, routes);
    }
    return compile(route.path);
};
/**
 * Shorthand for single path call. (```pathByRouteName('ListingPage', routes, { id: uuidX });```)
 */
export const pathByRouteName = (nameToFind, routes, params = {}, searchParams = {}) => {
    const hasEmptySlug = params && params.hasOwnProperty('slug') && !params.slug;
    const pathParams = hasEmptySlug ? Object.assign(Object.assign({}, params), { slug: 'no-slug' }) : params;
    const searchQuery = stringify(searchParams);
    const includeSearchQuery = searchQuery.length > 0 ? `?${searchQuery}` : '';
    const path = toPathByRouteName(nameToFind, routes, params === null || params === void 0 ? void 0 : params.language)(pathParams);
    return `${path}${includeSearchQuery}`;
};
/**
 * Find the matching routes and their params for the given pathname
 *
 * @param {String} pathname - Full URL path from root with possible
 * search params and hash included
 *
 * @return {Array<{ route, params }>} - All matches as { route, params } objects if matches has
 * exact flag set to false. If not, an array containing just the first matched exact route is returned.
 */
export const matchPathname = (pathname, routeConfiguration) => {
    const matchedRoutes = routeConfiguration.reduce((matches, route) => {
        const { path, exact = true } = route;
        const match = matchPath(pathname, { path, exact });
        if (match) {
            matches.push({
                route,
                params: match.params || {},
            });
        }
        return matches;
    }, []);
    const matchedExactRoute = matchedRoutes.find(r => {
        return r.exact === true || r.exact == null;
    });
    // We return matched 'exact' path route only if such exists
    // and all matches if no exact flag exists.
    return matchedExactRoute ? [matchedExactRoute] : matchedRoutes;
};
/**
 * ResourceLocatorString is used to direct webapp to correct page.
 * In contrast to Universal Resource Locator (URL), this doesn't contain protocol, host, or port.
 */
export const createResourceLocatorString = (routeName, routes, pathParams = {}, searchParams = {}, hash = '') => {
    const searchQuery = stringify(searchParams);
    const includeSearchQuery = searchQuery.length > 0 ? `?${searchQuery}` : '';
    const path = pathByRouteName(routeName, routes, pathParams);
    return `${path}${includeSearchQuery}${hash}`;
};
/**
 * Find component related to route name
 * E.g. `const PageComponent = findComponentByRouteName('CheckoutPage', routes);`
 * Then we can call static methods of given component:
 * `dispatch(PageComponent.setInitialValues({ listing, bookingDates }));`
 *
 * @param {String} nameToFind - Route name
 * @param {Array<{ route }>} routes - Route configuration as flat array.
 *
 * @return {Route} - Route that matches the given route name.
 */
export const findRouteByRouteName = (nameToFind, language = DEFAULT_LOCALE, routes) => {
    const route = findRouteByName(nameToFind, language, routes);
    if (!route) {
        throw new Error(`Component "${nameToFind}" was not found.`);
    }
    return route;
};
/**
 * Get the canonical URL from the given location
 *
 * @param {Array<{ route }>} routes - Route configuration as flat array
 * @param {Object} location - location object from React Router
 *
 * @return {String} Canonical URL of the given location
 *
 */
export const canonicalRoutePath = (routeConfiguration, location, params = {}, searchParams = {}, withRoot = false) => {
    const { pathname } = location;
    const requestedRoute = matchPathname(pathname, routeConfiguration);
    const { route } = requestedRoute[0] || {};
    const canonicalPage = `${(route === null || route === void 0 ? void 0 : route.forceCanonical) || (route === null || route === void 0 ? void 0 : route.name)}`;
    const locale = (route === null || route === void 0 ? void 0 : route.language) || DEFAULT_LOCALE;
    const path = pathByRouteName(canonicalPage, routeConfiguration, Object.assign(Object.assign({ slug: '' }, params), { language: locale }), searchParams);
    const marketplaceDomain = getMarketplaceDomain();
    if (withRoot) {
        return `https://${marketplaceDomain}${path}`;
    }
    return path;
};
