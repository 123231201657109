import React from 'react';
import classNames from 'classnames';

const UserDisplayName = props => {
  const {
    rootClassName,
    className,
    user,
    intl,
    deletedUserDisplayName,
    bannedUserDisplayName,
  } = props;
  const hasAttributes = user && user.attributes;
  const userIsDeleted = hasAttributes && user.attributes.deleted;
  const userIsBanned = hasAttributes && user.attributes.banned;
  const userHasProfile = hasAttributes && user.attributes.profile;
  const userDisplayName = userHasProfile && user.attributes.profile.displayName;

  const deletedUserDisplayNameInUse =
    deletedUserDisplayName ||
    intl.formatMessage({
      id: 'UserDisplayName.deleted',
    });

  const bannedUserDisplayNameInUse =
    bannedUserDisplayName ||
    intl.formatMessage({
      id: 'UserDisplayName.banned',
    });

  const getUserName = userName => {
    if (userName) {
      return userName;
    }

    if (userIsDeleted) {
      return deletedUserDisplayNameInUse;
    }

    if (userIsBanned) {
      return bannedUserDisplayNameInUse;
    }

    return null;
  };

  const displayName = getUserName(userDisplayName);

  const classes = classNames(rootClassName, className);
  return <span className={classes}>{displayName}</span>;
};

export default UserDisplayName;
