var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Device } from '@capacitor/device';
import React, { useEffect, useState } from 'react';
import { isAndroid, isIos } from '../../util/deviceContext';
import { Preferences } from '@capacitor/preferences';
import SheetWelcome from './components/SheetWelcome';
import SheetNotification from './components/SheetNotification';
import SheetRoleSelect from './components/SheetRoleSelect';
import SheetUserMigrationFromMyCabin from './components/SheetUserMigrationFromMyCabin';
const AppIntro = ({ onClose }) => {
    const [step, setStep] = useState(0);
    const [shownUserCentricPopover, setShownUserCentricPopover] = useState(false);
    useEffect(() => {
        if (step < 2 || shownUserCentricPopover)
            return;
        setShownUserCentricPopover(true);
        const UC_UI = window.UC_UI;
        if (!UC_UI ||
            !UC_UI.showFirstLayer ||
            typeof UC_UI.showFirstLayer !== 'function') {
            console.error('Usercentrics CMP not initialized');
            return;
        }
        UC_UI.showFirstLayer();
    }, [shownUserCentricPopover, step]);
    const [showNotificationPromptSlide, setShowNotificationPromptSlide] = useState(false);
    useEffect(() => {
        const checkDevice = () => __awaiter(void 0, void 0, void 0, function* () {
            var _a, _b;
            if (isIos) {
                setShowNotificationPromptSlide(true);
            }
            else if (isAndroid) {
                const deviceInfo = yield Device.getInfo();
                const osVersion = parseInt((_b = (_a = deviceInfo.osVersion.match(/\d+/)) === null || _a === void 0 ? void 0 : _a[0]) !== null && _b !== void 0 ? _b : '0', 10);
                setShowNotificationPromptSlide(osVersion >= 13);
            }
        });
        checkDevice();
    }, []);
    const notificationPrompt = () => __awaiter(void 0, void 0, void 0, function* () {
        const { default: OneSignal } = yield import('onesignal-cordova-plugin');
        OneSignal.Notifications.requestPermission(true).then((accepted) => __awaiter(void 0, void 0, void 0, function* () {
            finishAppIntro();
        }));
    });
    const finishAppIntro = () => __awaiter(void 0, void 0, void 0, function* () {
        yield saveFinishedAppIntroToPreferences();
        if (isFormerMyCabinAppUser) {
            setStep(3);
        }
        else {
            setStep(2);
        }
    });
    const saveFinishedAppIntroToPreferences = () => __awaiter(void 0, void 0, void 0, function* () {
        yield Preferences.set({
            key: 'completedAppIntro',
            value: 'true',
        });
    });
    const [isFormerMyCabinAppUser, setIsFormerMyCabinAppUser] = useState(false);
    useEffect(() => {
        const fetchPreferences = () => __awaiter(void 0, void 0, void 0, function* () {
            const result = yield Preferences.keys();
            const foundKeys = result.keys;
            const hasKeysFromMyCabin = foundKeys.includes('appIntroShown');
            setIsFormerMyCabinAppUser(hasKeysFromMyCabin);
            if (hasKeysFromMyCabin)
                yield Preferences.clear();
        });
        fetchPreferences();
    }, []);
    const introContent = [
        _jsx(SheetWelcome, { onContinue: () => __awaiter(void 0, void 0, void 0, function* () {
                if (showNotificationPromptSlide) {
                    setStep(step + 1);
                }
                else {
                    yield finishAppIntro();
                }
            }), isFormerMyCabinAppUser: isFormerMyCabinAppUser }, 0),
        _jsx(SheetNotification, { onBack: () => setStep(step - 1), onContinue: notificationPrompt }, 1),
        _jsx(SheetRoleSelect, { onClose: onClose }, 2),
        _jsx(SheetUserMigrationFromMyCabin, { onClose: onClose, onClickNoMyCabinAccount: () => setStep(2) }, 3),
    ];
    return introContent[step];
};
export default AppIntro;
