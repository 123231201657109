var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import { useForm } from 'react-hook-form';
import Button, { ButtonSizeVariant, ButtonColorVariant, } from '../../../styled-components/Button/Button';
import InputPhone from '../../../styled-components/InputPhone/InputPhone';
import Input from '../../../styled-components/Input/Input';
import { updateProfile } from '../../ProfileSettingsPage/ProfileSettingsPageSlice';
import { currentUserSelector, userHasLastNameSelector, userHasPhoneNumberSelector, } from '../../../ducks/user.duck';
import { getMissingLastNameOrPhoneNumberSchema } from '@nomady/shared/schemas/user/profile';
import { zodResolver } from '@hookform/resolvers/zod';
const ButtonArea = styled.div `
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;
const Form = styled.form `
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 14px;
`;
const CompletePersonalData = ({ onClose }) => {
    var _a, _b, _c, _d, _e;
    const intl = useIntl();
    const dispatch = useDispatch();
    const onSuccess = (data) => __awaiter(void 0, void 0, void 0, function* () {
        var _f;
        const params = {};
        if (!userHasLastName) {
            params.lastName = (_f = data.lastName) === null || _f === void 0 ? void 0 : _f.trim();
        }
        if (!userHasPhoneNumber) {
            params.profile = {
                privateData: {
                    phoneNumber: data.phoneNumber,
                },
            };
        }
        dispatch(updateProfile(params));
        if (onClose)
            onClose();
    });
    const currentUser = useSelector(currentUserSelector);
    const userHasLastName = useSelector(userHasLastNameSelector);
    const userHasPhoneNumber = useSelector(userHasPhoneNumberSelector);
    const defaultValues = {
        lastName: (_b = (_a = currentUser === null || currentUser === void 0 ? void 0 : currentUser.attributes) === null || _a === void 0 ? void 0 : _a.profile) === null || _b === void 0 ? void 0 : _b.lastName,
        phoneNumber: (_e = (_d = (_c = currentUser === null || currentUser === void 0 ? void 0 : currentUser.attributes) === null || _c === void 0 ? void 0 : _c.profile) === null || _d === void 0 ? void 0 : _d.privateData) === null || _e === void 0 ? void 0 : _e.phoneNumber,
    };
    const { register, handleSubmit, control, formState: { errors }, } = useForm({
        defaultValues,
        resolver: zodResolver(getMissingLastNameOrPhoneNumberSchema(intl)),
    });
    const lastName = register('lastName');
    return (_jsxs(Form, Object.assign({ onSubmit: handleSubmit(onSuccess) }, { children: [!userHasLastName && (_jsx(Input, Object.assign({ label: intl.formatMessage({
                    id: 'Input.label.lastName',
                }), id: "lastName", autoComplete: "lastName", type: "text" }, lastName, { inputRef: lastName.ref, error: errors === null || errors === void 0 ? void 0 : errors.lastName }))), !userHasPhoneNumber && (_jsx(InputPhone, { label: intl.formatMessage({
                    id: 'Input.label.phoneNumber',
                }), id: "phoneNumber", name: "phoneNumber", control: control })), _jsx(ButtonArea, { children: _jsx("div", { children: _jsx(Button, { type: "submit", dataTestId: "CompletePersonalData_SaveButton", label: intl.formatMessage({
                            id: 'ProfileSettingsForm.saveChanges',
                        }), sizeVariant: ButtonSizeVariant.Small, colorVariant: ButtonColorVariant.Forest }) }) })] })));
};
export default CompletePersonalData;
