import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { useIntl } from 'react-intl';
import { ColorVariant } from '../../theme';
import Text, { TextVariant } from '../Text/Text';
import Modal from '../Modal/Modal';
import Icon, { IconSizeVariant, IconVariant } from '../Icon/Icon';
import CampSpotsContent from '../../containers/ListingPage/ModalContents/CampSpotsContent';
const Wrapper = styled.div `
  display: flex;
  flex-direction: row;
  padding: 10px 0;
`;
const DetailsText = styled(Text) `
  text-decoration: underline;
  margin-left: 20px;
  line-height: 18px;
  &:hover {
    cursor: pointer;
  }
`;
const Indicator = styled.div `
  display: flex;
  gap: 6px;
`;
const SeatsAvailabilityIndicator = ({ totalSeats, availableSeats, }) => {
    const [detailsModalIsOpen, setDetailsModalIsOpen] = useState(false);
    const intl = useIntl();
    const LIMIT_INDICATOR = 4;
    const spotIds = [...Array(totalSeats).keys()].map(spotIndex => spotIndex + 1);
    return (_jsxs(Wrapper, Object.assign({ "data-testid": "SeatsAvailabilityIndicator" }, { children: [_jsxs(Indicator, { children: [spotIds.map(spotId => {
                        if (spotId < LIMIT_INDICATOR) {
                            return (_jsx(Icon, { variant: IconVariant[`Seat${spotId}`], sizeVariant: IconSizeVariant.Small, strikethrough: availableSeats < spotId, backgroundColor: availableSeats < spotId ? ColorVariant.Fog : undefined }, `campSeats${spotId}`));
                        }
                        return null;
                    }), totalSeats >= LIMIT_INDICATOR && (_jsx(Icon, { variant: IconVariant.SeatThreeDots, sizeVariant: IconSizeVariant.Small, backgroundColor: LIMIT_INDICATOR - 1 > availableSeats
                            ? ColorVariant.Fog
                            : undefined }, `campSeatsThreeDots}`))] }), _jsx(DetailsText, Object.assign({ variant: TextVariant.B30, onClick: () => setDetailsModalIsOpen(true) }, { children: intl.formatMessage({ id: 'SeatsAvailabilityIndicator.details' }) })), _jsx(Modal, Object.assign({ isOpen: detailsModalIsOpen, onClose: () => setDetailsModalIsOpen(false), title: intl.formatMessage({
                    id: 'SeatsAvailabilityIndicator.availableSpotsTitle',
                }, { seats: totalSeats }) }, { children: _jsx(CampSpotsContent, { availableSeats: availableSeats }) }))] })));
};
export default SeatsAvailabilityIndicator;
