var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { Controller } from 'react-hook-form';
import { useIntl } from 'react-intl';
import parsePhoneNumber from 'libphonenumber-js';
import Input from '../Input/Input';
import { IconVariant } from '../Icon/Icon';
export const InputPhone = (_a) => {
    var { control, rules } = _a, props = __rest(_a, ["control", "rules"]);
    const intl = useIntl();
    return (_jsx(Controller, { render: ({ field, fieldState }) => {
            var _a;
            return (_jsx(Input, Object.assign({}, props, field, { autoComplete: "tel", type: "tel", icon: IconVariant.Phone, error: fieldState.error, defaultValue: (_a = props.defaultValue) === null || _a === void 0 ? void 0 : _a.formattedValue, onChange: event => {
                    const parsedPhoneNumber = parsePhoneNumber(event.target.value);
                    if (parsedPhoneNumber) {
                        const { nationalNumber, number, country } = parsedPhoneNumber;
                        const formattedValue = parsedPhoneNumber === null || parsedPhoneNumber === void 0 ? void 0 : parsedPhoneNumber.formatInternational();
                        const value = { nationalNumber, number, country, formattedValue };
                        field.onChange(value);
                    }
                    else {
                        field.onChange(event.target.value);
                    }
                }, placeholder: intl.formatMessage({
                    id: 'Input.placeholder.phoneNumber',
                }) })));
        }, name: props.name, control: control, rules: rules }));
};
export default InputPhone;
