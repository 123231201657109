import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import Page from '@nomady/shared/routeConfiguration/Page';
import { createSlug, parse } from '@nomady/shared/utils/urlHelpers';
import SplitPicturePageContainer from '../../styled-components/PageContainer/SplitPicturePageContainer';
import config from '../../config';
import Text, { TextVariant } from '../../styled-components/Text/Text';
import Button, { ButtonColorVariant, ButtonSizeVariant, } from '../../styled-components/Button/Button';
import FormErrorMessage from '../../styled-components/FormErrorMessage/FormErrorMessage';
import { ColorVariant } from '../../theme';
import { verificationInProgressSelector, verify, } from './EmailVerificationPageSlice';
import { retrieveBookingDataAndDates } from '../ListingPage/ListingPageSlice';
const Title = styled(Text) `
  margin-bottom: 20px;
`;
const Message = styled(Text) `
  margin-bottom: 20px;
`;
const ButtonCenteredWrap = styled.div `
  width: fit-content;
  margin: 30px 0px;
`;
/**
 * extract the verificationToken from the querystring
 *
 * @param search
 * @returns {string | null} verification Token or null in case of unparsable querystring
 */
const parseVerificationToken = (search) => {
    const urlParams = parse(`${search}`);
    const verificationToken = urlParams.t;
    if (verificationToken) {
        return `${verificationToken}`;
    }
    return null;
};
const EmailVerificationPage = () => {
    var _a, _b, _c, _d;
    const intl = useIntl();
    const { siteTitle } = config;
    const schemaTitle = intl.formatMessage({ id: 'EmailVerificationPage.schemaTitle' }, { siteTitle });
    const dispatch = useDispatch();
    const location = useLocation();
    const verificationToken = parseVerificationToken(location.search || null);
    const user = useSelector((state) => state.user.currentUser);
    const { listing, bookingEnd, bookingStart } = useSelector((state) => state.ListingPage);
    const verificationPending = !((_a = user === null || user === void 0 ? void 0 : user.attributes) === null || _a === void 0 ? void 0 : _a.emailVerified) || ((_b = user === null || user === void 0 ? void 0 : user.attributes) === null || _b === void 0 ? void 0 : _b.pendingEmail);
    const verificationInProgress = useSelector(verificationInProgressSelector);
    const verificationError = useSelector((state) => state.EmailVerification.verificationError);
    const defaultButton = (_jsx(Button, { label: intl.formatMessage({
            id: 'EmailVerificationPage.successPrimaryButtonLabel',
        }), colorVariant: ButtonColorVariant.Dark, sizeVariant: ButtonSizeVariant.Small, targetRoute: Page.Landing, inProgress: verificationInProgress }));
    const targetRouteParams = {
        id: ((_c = listing === null || listing === void 0 ? void 0 : listing.id) === null || _c === void 0 ? void 0 : _c.uuid) || '',
        slug: createSlug(((_d = listing === null || listing === void 0 ? void 0 : listing.attributes) === null || _d === void 0 ? void 0 : _d.title) || ''),
    };
    const continueBookingButton = (_jsx(Button, { label: intl.formatMessage({
            id: 'EmailVerificationPage.continueBookingButton',
        }), colorVariant: ButtonColorVariant.Dark, sizeVariant: ButtonSizeVariant.Small, targetRoute: Page.Listing, targetRouteParams: targetRouteParams, inProgress: verificationInProgress }));
    const defaultSuccessMessage = intl.formatMessage({
        id: 'EmailVerificationPage.successText',
    });
    const continueWithBookingMessage = intl.formatMessage({
        id: 'EmailVerificationPage.continueBookingText',
    });
    const verificationWhileBooking = listing && bookingStart && bookingEnd;
    useEffect(() => {
        if (verificationToken && verificationPending) {
            dispatch(retrieveBookingDataAndDates());
            dispatch(verify(verificationToken));
        }
    }, []);
    return (_jsxs(SplitPicturePageContainer, Object.assign({ schemaTitle: schemaTitle, noIndex: true }, { children: [_jsx(Title, Object.assign({ variant: TextVariant.H20, as: "h2" }, { children: intl.formatMessage({ id: 'EmailVerificationPage.successTitle' }, { name: user === null || user === void 0 ? void 0 : user.attributes.profile.firstName }) })), _jsx(Message, Object.assign({ variant: TextVariant.H30, as: "p", colorVariant: ColorVariant.Stone }, { children: verificationWhileBooking
                    ? continueWithBookingMessage
                    : defaultSuccessMessage })), !!verificationError && (_jsx(FormErrorMessage, { id: 'EmailVerificationPage.verificationFailed' })), _jsx(ButtonCenteredWrap, { children: verificationWhileBooking ? continueBookingButton : defaultButton })] })));
};
export default EmailVerificationPage;
