import getCampStyleQueryString from './camp-style/url/getCampStyleQueryString';
import getDatesQueryString from './dates/url/getDatesQueryString';
import getFeaturesQueryString from './features/url/getFeaturesQueryString';
import getGuestsQueryString from './guests/url/getGuestsQueryString';
import getLocationQueryString from './location/url/getLocationQueryString';
const getQueryString = (searchQuery) => {
    const locationQueryStringParams = getLocationQueryString(searchQuery);
    const datesQueryStringParams = getDatesQueryString(searchQuery);
    const guestsQueryStringParams = getGuestsQueryString(searchQuery);
    const campStyleQueryStringParams = getCampStyleQueryString(searchQuery);
    const featuresQueryStringParams = getFeaturesQueryString(searchQuery);
    return Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({ sort: searchQuery.sort }, datesQueryStringParams), locationQueryStringParams), guestsQueryStringParams), campStyleQueryStringParams), featuresQueryStringParams);
};
export default getQueryString;
