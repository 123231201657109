var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { CmsSlug } from '@nomady/shared/cmsConfig';
import { getCmsEndpoint } from '../cmsConfig';
const initialState = {
    termsOfServiceTitle: undefined,
    termsOfServiceSection: undefined,
    nomadyPactTitle: undefined,
    nomadyPactSection: undefined,
    giftCardTermsTitle: undefined,
    giftCardTermsSection: undefined,
};
export const CmsDataSlice = createSlice({
    name: 'app/cmsData',
    initialState,
    reducers: {
        setInitialState: state => {
            state = initialState;
        },
        fetchTermsOfServiceSuccess: (state, action) => {
            state.termsOfServiceTitle = action.payload.title;
            state.termsOfServiceSection = action.payload.sections;
        },
        fetchNomadyPactSuccess: (state, action) => {
            state.nomadyPactTitle = action.payload.title;
            state.nomadyPactSection = action.payload.sections;
        },
        fetchPrivacyPolicySuccess: (state, action) => {
            state.privacyPolicyTitle = action.payload.title;
            state.privacyPolicySection = action.payload.sections;
        },
        fetchGiftCardTermsSuccess: (state, action) => {
            state.giftCardTermsTitle = action.payload.title;
            state.giftCardTermsSection = action.payload.sections;
        },
    },
});
export const { setInitialState, fetchTermsOfServiceSuccess, fetchNomadyPactSuccess, fetchPrivacyPolicySuccess, fetchGiftCardTermsSuccess, } = CmsDataSlice.actions;
const loadTermsOfService = (locale) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    try {
        const endpoint = getCmsEndpoint(CmsSlug.TermsAndConditions, locale);
        const response = yield fetch(endpoint);
        const json = yield response.json();
        const page = (_a = json === null || json === void 0 ? void 0 : json.data) === null || _a === void 0 ? void 0 : _a[0].attributes;
        const { mainTitle } = page;
        const cmsContent = {
            title: mainTitle,
            sections: page.sections,
        };
        dispatch(fetchTermsOfServiceSuccess(cmsContent));
    }
    catch (error) {
        console.error(error);
    }
});
const loadPrivacyPolicy = (locale) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    var _b;
    try {
        const endpoint = getCmsEndpoint(CmsSlug.PrivacyPolicy, locale);
        const response = yield fetch(endpoint);
        const json = yield response.json();
        const page = (_b = json === null || json === void 0 ? void 0 : json.data) === null || _b === void 0 ? void 0 : _b[0].attributes;
        const cmsContent = {
            title: page.title,
            sections: page.sections,
        };
        dispatch(fetchPrivacyPolicySuccess(cmsContent));
    }
    catch (error) {
        console.error(error);
    }
});
const loadNomadyPact = (locale) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    var _c;
    try {
        const endpoint = getCmsEndpoint(CmsSlug.NomadyPact, locale);
        const response = yield fetch(endpoint);
        const json = yield response.json();
        const page = (_c = json === null || json === void 0 ? void 0 : json.data) === null || _c === void 0 ? void 0 : _c[0].attributes;
        const cmsContent = {
            title: page.title,
            sections: page.sections,
        };
        dispatch(fetchNomadyPactSuccess(cmsContent));
    }
    catch (error) {
        console.error(error);
    }
});
const loadGiftCardTerms = (locale) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    var _d;
    try {
        const endpoint = getCmsEndpoint(CmsSlug.GiftCardTerms, locale);
        const response = yield fetch(endpoint);
        const json = yield response.json();
        const page = (_d = json === null || json === void 0 ? void 0 : json.data) === null || _d === void 0 ? void 0 : _d[0].attributes;
        const cmsContent = {
            title: page.title,
            sections: page.sections,
        };
        dispatch(fetchGiftCardTermsSuccess(cmsContent));
    }
    catch (error) {
        console.error(error);
    }
});
export const loadData = () => (dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
    const state = getState();
    const { locale } = state.UI;
    // Clear state so that previously loaded data is not visible
    // in case this page load fails.
    dispatch(setInitialState());
    if (!locale) {
        return Promise.reject();
    }
    return Promise.all([
        dispatch(loadTermsOfService(locale)),
        dispatch(loadNomadyPact(locale)),
        dispatch(loadPrivacyPolicy(locale)),
        dispatch(loadGiftCardTerms(locale)),
    ]);
});
export default CmsDataSlice.reducer;
