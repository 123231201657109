import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components/macro';
import Icon, { IconSizeVariant, IconVariant } from '../Icon/Icon';
import Spinner from '../Spinner/Spinner';
import { ColorVariant } from '../../theme';
import Text, { TextVariant } from '../Text/Text';
const SpinnerWrapper = styled.div `
  height: 18px;
  width: 18px;
`;
const ToggleButton = styled.button `
  width: fit-content;
  border: none;
  display: flex;
  padding: 0;
  gap: 5px;
  justify-content: space-between;
  align-items: center;
  background: none;
  color: ${props => props.theme.color.stone};
  cursor: pointer;
`;
const ToggleTranslationButton = ({ active, onClick, loading }) => {
    const translatedButtonState = {
        icon: IconVariant.TranslationInactive,
        formattedMessage: 'ToggleTranslationButton.translate',
    };
    if (active) {
        translatedButtonState.icon = IconVariant.TranslationActive;
        translatedButtonState.formattedMessage =
            'ToggleTranslationButton.showOriginal';
    }
    return (_jsxs(ToggleButton, Object.assign({ onClick: () => onClick(!active), disabled: loading }, { children: [loading ? (_jsx(SpinnerWrapper, { children: _jsx(Spinner, { colorVariant: ColorVariant.Cave }) })) : (_jsx(Icon, { sizeVariant: IconSizeVariant.ExtraSmall, variant: translatedButtonState.icon })), _jsx(Text, Object.assign({ variant: TextVariant.B50 }, { children: _jsx(FormattedMessage, { id: translatedButtonState.formattedMessage }) }))] })));
};
export default ToggleTranslationButton;
