import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styled from 'styled-components/macro';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import Icon, { IconSizeVariant, IconVariant, } from '../../../styled-components/Icon/Icon';
import Text, { TextVariant } from '../../../styled-components/Text/Text';
import { ColorVariant } from '../../../theme';
import { listingSelector } from '../ListingPageSlice';
const getNightsTextMessageId = (minBookableNights, maxBookableNights) => {
    if (minBookableNights > 1 && maxBookableNights > 0) {
        return 'ListingPage.overview.minAndMaxNight';
    }
    if (minBookableNights > 1 && maxBookableNights === 0) {
        return 'ListingPage.overview.minNight';
    }
    if (minBookableNights <= 1 && maxBookableNights > 0) {
        return 'ListingPage.overview.maxNight';
    }
};
const TitleContainer = styled.div `
  margin: 42px 0px 30px;
  @media ${props => props.theme.device.tablet} {
    margin: 42px 0px 42px;
  }
`;
const Wrapper = styled.div `
  display: flex;
  flex-direction: column;
`;
const InfoWrapper = styled.ul `
  display: flex;
  flex-direction: column;
  gap: 35px;
  @media ${props => props.theme.device.tablet} {
    column-gap: 30px;
    gap: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: 1fr;
  }
`;
const InfoItemWrapper = styled.li `
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: 15px;
  @media ${props => props.theme.device.tablet} {
    margin-left: 20px;
  }
`;
const ItemTextWrapper = styled.div `
  display: flex;
  flex-direction: column;
  max-width: 360px;
  overflow: hidden;
`;
const UnderTextWrapper = styled.div `
  margin-top: -5px;
`;
const LabelIcon = styled.div `
  display: flex;
  background: ${props => props.theme.color.blueberry};
  height: 18px;
  width: 18px;
  align-items: center;
  text-align: center;
  padding: 4px;
  margin: 3px;
`;
export const InfoItem = ({ iconVariant, labelIcon, title, text }) => {
    return (_jsxs(InfoItemWrapper, { children: [iconVariant && (_jsx(Icon, { variant: iconVariant, sizeVariant: IconSizeVariant.Small })), labelIcon && (_jsx(LabelIcon, { children: _jsx(Text, Object.assign({ variant: TextVariant.H50, colorVariant: ColorVariant.Pearl }, { children: labelIcon })) })), _jsxs(ItemTextWrapper, { children: [_jsx(Text, Object.assign({ variant: text ? TextVariant.B50 : TextVariant.H51, colorVariant: ColorVariant.Cave }, { children: title })), text && (_jsx(UnderTextWrapper, { children: _jsx(Text, Object.assign({ variant: TextVariant.H51, colorVariant: ColorVariant.Cave }, { children: text })) }))] })] }));
};
const NightItem = ({ minBookableNights, maxBookableNights, }) => {
    const intl = useIntl();
    const nightsTextMessageId = getNightsTextMessageId(minBookableNights, maxBookableNights);
    if (!nightsTextMessageId) {
        return null;
    }
    const nightsText = intl.formatMessage({
        id: nightsTextMessageId,
    }, { minNights: minBookableNights, maxNights: maxBookableNights });
    return (_jsx(InfoItem, { iconVariant: IconVariant.Moon, title: intl.formatMessage({
            id: 'ListingPage.overnightStays',
        }), text: nightsText }));
};
const BookingDetailsSection = () => {
    var _a;
    const intl = useIntl();
    const listing = useSelector(listingSelector);
    const { checkIn, latestCheckIn, checkOut, minBookableNights = 0, maxBookableNights = 0, bookablePersonsPerSeat, parkingNearby, privateCamp, } = ((_a = listing === null || listing === void 0 ? void 0 : listing.attributes) === null || _a === void 0 ? void 0 : _a.publicData) || {};
    const checkInTextMessageId = latestCheckIn
        ? 'ListingPage.checkInWithLatest'
        : 'ListingPage.checkIn';
    const checkInText = intl.formatMessage({ id: checkInTextMessageId }, { checkInTime: checkIn, latestCheckInTime: latestCheckIn });
    return (_jsxs(Wrapper, { children: [_jsx(TitleContainer, { children: _jsx(Text, Object.assign({ "data-testid": "BookingDetailsSection_Title", variant: TextVariant.H30, colorVariant: ColorVariant.Cave, as: "h2" }, { children: intl.formatMessage({
                        id: 'ListingPage.bookingDetails',
                    }) })) }), _jsxs(InfoWrapper, { children: [_jsx(InfoItem, { iconVariant: IconVariant.CheckIn, title: intl.formatMessage({
                            id: 'ListingPage.checkInTitle',
                        }), text: checkInText }), _jsx(InfoItem, { iconVariant: IconVariant.CheckOut, title: intl.formatMessage({
                            id: 'ListingPage.checkOutTitle',
                        }), text: intl.formatMessage({
                            id: 'ListingPage.checkOut',
                        }, { checkOutTime: checkOut }) }), (0 < minBookableNights || 0 < maxBookableNights) && (_jsx(NightItem, { minBookableNights: minBookableNights, maxBookableNights: maxBookableNights })), !!bookablePersonsPerSeat && (_jsx(InfoItem, { iconVariant: IconVariant.User, title: intl.formatMessage({
                            id: 'ListingPage.amountBookablePersons',
                        }, { maxPersons: bookablePersonsPerSeat }) })), parkingNearby && (_jsx(InfoItem, { iconVariant: IconVariant.Parking, title: intl.formatMessage({
                            id: 'ListingPage.parkingAtCamp',
                        }) })), privateCamp && (_jsx(InfoItem, { iconVariant: IconVariant.DoNotDisturbCard, title: intl.formatMessage({
                            id: 'ListingPage.privateCamp',
                        }) }))] })] }));
};
export default BookingDetailsSection;
