const getGuestsApiParams = (searchQuery) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
    const hasDates = !!(((_a = searchQuery === null || searchQuery === void 0 ? void 0 : searchQuery.dates) === null || _a === void 0 ? void 0 : _a.startDate) && ((_b = searchQuery === null || searchQuery === void 0 ? void 0 : searchQuery.dates) === null || _b === void 0 ? void 0 : _b.endDate));
    const totalGuests = [
        (_d = (_c = searchQuery === null || searchQuery === void 0 ? void 0 : searchQuery.guests) === null || _c === void 0 ? void 0 : _c.adults) !== null && _d !== void 0 ? _d : 0,
        (_f = (_e = searchQuery === null || searchQuery === void 0 ? void 0 : searchQuery.guests) === null || _e === void 0 ? void 0 : _e.teens) !== null && _f !== void 0 ? _f : 0,
        (_h = (_g = searchQuery === null || searchQuery === void 0 ? void 0 : searchQuery.guests) === null || _g === void 0 ? void 0 : _g.children) !== null && _h !== void 0 ? _h : 0,
        (_k = (_j = searchQuery === null || searchQuery === void 0 ? void 0 : searchQuery.guests) === null || _j === void 0 ? void 0 : _j.infants) !== null && _k !== void 0 ? _k : 0,
    ].reduce((acc, curr) => acc + curr, 0);
    const dogsAllowedFromFeatures = (_l = searchQuery === null || searchQuery === void 0 ? void 0 : searchQuery.features) === null || _l === void 0 ? void 0 : _l.dogsAllowed;
    const dogsAllowedFromGuests = ((_m = searchQuery === null || searchQuery === void 0 ? void 0 : searchQuery.guests) === null || _m === void 0 ? void 0 : _m.dogs)
        ? 0 < searchQuery.guests.dogs
        : undefined;
    const pub_dogsAllowed = dogsAllowedFromFeatures || dogsAllowedFromGuests || undefined;
    const totalSeats = (_o = searchQuery === null || searchQuery === void 0 ? void 0 : searchQuery.guests) === null || _o === void 0 ? void 0 : _o.seats;
    return {
        seats: hasDates ? totalSeats === null || totalSeats === void 0 ? void 0 : totalSeats.toString() : undefined,
        pub_seats: !hasDates ? (totalSeats === null || totalSeats === void 0 ? void 0 : totalSeats.toString()) + ',' : undefined,
        pub_bookablePersonsPerSeat: Math.ceil(totalGuests / (totalSeats !== null && totalSeats !== void 0 ? totalSeats : 1)).toString() + ',',
        pub_dogsAllowed,
    };
};
export default getGuestsApiParams;
