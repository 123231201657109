import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import PageContainer from './PageContainer';
import LayoutSplitPicture from '../LayoutSplitPicture/LayoutSplitPicture';
import { PageLayoutContent, PageLayoutTop } from '../PageLayout/PageLayout';
import TopBar from '../TopBar/TopBar';
const SplitPicturePageContainer = ({ schemaTitle, noIndex = true, children, }) => {
    return (_jsxs(PageContainer, Object.assign({ title: schemaTitle, noIndex: noIndex, schema: {
            '@context': 'https://schema.org',
            '@type': 'WebPage',
            name: schemaTitle,
        } }, { children: [_jsx(PageLayoutTop, { children: _jsx(TopBar, { hideListingSearchBar: true }) }), _jsx(PageLayoutContent, { children: _jsx(LayoutSplitPicture, { children: children }) })] })));
};
export default SplitPicturePageContainer;
