import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styled from 'styled-components/macro';
import { useIntl } from 'react-intl';
import { convertMoneyToNumber } from '@nomady/shared/utils/currency';
import getTotalTransactionValue from '@nomady/shared/controllers/utils/getTotalTransactionValue';
import { types as sdkTypes } from '@nomady/shared/utils/sdkLoader';
import getTotalProviderPayoutValue from '@nomady/shared/controllers/utils/getTotalProviderPayoutValue';
import PriceInformation from './partials/PriceInformation';
import BookingBreakdownToggle from '../BookingBreakdownToggle/BookingBreakdownToggle';
import Modal from '../Modal/Modal';
import BookingBreakdown, { useBreakdownLineItems, } from '../BookingBreakdown';
const { Money } = sdkTypes;
const InformationSection = styled.div `
  border-radius: 10px;
  padding: 12px 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.color.pearl};
  border: 1px solid ${props => props.theme.color.pearl};
  &:hover {
    cursor: pointer;
    border: 1px solid ${props => props.theme.color.stone};
  }
`;
const PriceInformationBox = ({ listing, giftCards, customerCommissionDiscountCode, bookingStart, bookingEnd, seatQuantity, adultQuantity, childQuantity, teenQuantity, infantQuantity, dogQuantity, breakdownOpen, setBreakdownOpen, nightsQuantity, extras, role, }) => {
    const intl = useIntl();
    const { groupedLineItems, lineItemsFromCalculator, giftCardDeduction } = useBreakdownLineItems({
        listing,
        giftCards,
        customerCommissionDiscountCode,
        bookingStart,
        bookingEnd,
        seatQuantity,
        adultQuantity,
        childQuantity,
        teenQuantity,
        infantQuantity,
        dogQuantity,
        extras,
        role,
    });
    const totalPriceForCustomer = (lineItemsFromCalculator === null || lineItemsFromCalculator === void 0 ? void 0 : lineItemsFromCalculator.length)
        ? getTotalTransactionValue(lineItemsFromCalculator)
        : listing.attributes.price;
    const totalPriceNumber = convertMoneyToNumber(totalPriceForCustomer);
    const detailsDisabled = !(lineItemsFromCalculator === null || lineItemsFromCalculator === void 0 ? void 0 : lineItemsFromCalculator.length);
    const totalGiftcardPayin = giftCardDeduction
        ? convertMoneyToNumber(giftCardDeduction)
        : 0;
    const totalProviderPayoutValue = (lineItemsFromCalculator === null || lineItemsFromCalculator === void 0 ? void 0 : lineItemsFromCalculator.length)
        ? getTotalProviderPayoutValue(lineItemsFromCalculator)
        : listing === null || listing === void 0 ? void 0 : listing.attributes.price;
    const totalProviderPayout = convertMoneyToNumber(totalProviderPayoutValue !== null && totalProviderPayoutValue !== void 0 ? totalProviderPayoutValue : new Money(0, totalPriceForCustomer.currency));
    return (_jsxs(InformationSection, Object.assign({ onClick: () => !detailsDisabled && setBreakdownOpen(true) }, { children: [_jsx(PriceInformation, { totalPeople: adultQuantity + teenQuantity + childQuantity, nightsQuantity: nightsQuantity, price: role === 'provider' ? totalProviderPayout : totalPriceNumber, currency: listing.attributes.publicData.currency }), _jsx(BookingBreakdownToggle, { detailsDisabled: detailsDisabled }), _jsx(Modal, Object.assign({ isOpen: breakdownOpen, onClose: () => setBreakdownOpen(false), title: intl.formatMessage({
                    id: 'PriceInformationBox.yourBill',
                }) }, { children: bookingStart && bookingEnd && (_jsx(BookingBreakdown, { currency: listing.attributes.publicData.currency, totalPrice: role === 'customer' ? totalPriceNumber : undefined, category: listing.attributes.publicData.category, lineItems: groupedLineItems, nightsBooked: nightsQuantity, bookingStart: bookingStart, bookingEnd: bookingEnd, giftCards: giftCards, customerCommissionDiscountCode: customerCommissionDiscountCode, totalGiftcardPayin: totalGiftcardPayin, totalProviderPayout: role === 'provider' ? totalProviderPayout : undefined })) }))] })));
};
export default PriceInformationBox;
