import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components/macro';
import { SnackBar } from './SnackBar';
import { removeSnackBar, selectSnackBars } from './SnackBarSlice';
const SnackbarPositionWrapper = styled.div `
  pointer-events: none;
  position: fixed;
  bottom: 8px;
  left: 8px;
  right: 8px;
  z-index: ${props => props.theme.priority.always};

  @media ${props => props.theme.device.tablet} {
    bottom: 24px;
    left: 24px;
    right: auto;
  }
`;
const SnackbarContainer = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  gap: 12px;
  @media ${props => props.theme.device.tablet} {
    align-items: start;
  }
`;
export const SnackBarProvider = () => {
    const snackBars = useSelector(selectSnackBars);
    const dispatch = useDispatch();
    return (_jsx(SnackbarPositionWrapper, { children: _jsx(SnackbarContainer, { children: snackBars.slice(-3).map(snackBarData => (_jsx(SnackBar, Object.assign({}, snackBarData, { onClose: () => dispatch(removeSnackBar(snackBarData.id)) }), snackBarData.id))) }) }));
};
