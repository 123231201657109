import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styled from 'styled-components/macro';
import Text from '../../../../styled-components/Text/Text';
import { ColorVariant } from '../../../../theme';
const ContentWrapper = styled.div `
  display: flex;
  flex-direction: column;
  margin: 12px 0px 30px;
`;
const Divider = styled.hr `
  border: 0;
  border-bottom: 1px solid ${props => props.theme.color.fog};
  margin: 0px 0px 30px;
`;
const StyledTitle = styled(Text) `
  ${props => props.theme.typeset.H20}

  @media ${props => props.theme.device.tablet} {
    ${props => props.theme.typeset.H30}
  }
`;
export const DescriptionContainer = styled.div `
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 36px;
  padding-bottom: 5px;
`;
const SectionLayout = ({ title, hideDivider = false, children, }) => {
    return (_jsxs(_Fragment, { children: [_jsx(StyledTitle, Object.assign({ colorVariant: ColorVariant.Stone }, { children: title })), _jsx(ContentWrapper, { children: children }), !hideDivider && _jsx(Divider, {})] }));
};
export default SectionLayout;
