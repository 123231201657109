import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import styled from 'styled-components/macro';
import { ReactComponent as ToggleOn } from './assets/toggle-on.svg';
import { ReactComponent as ToggleOff } from './assets/toggle-off.svg';
import { ColorVariant } from '../../theme';
import Spinner from '../Spinner/Spinner';
export var ToggleOffColorVariant;
(function (ToggleOffColorVariant) {
    ToggleOffColorVariant[ToggleOffColorVariant["Default"] = ColorVariant.Fog] = "Default";
    ToggleOffColorVariant[ToggleOffColorVariant["Colorized"] = ColorVariant.Rose] = "Colorized";
})(ToggleOffColorVariant || (ToggleOffColorVariant = {}));
const Wrapper = styled.button `
  border: 0;
  background-color: transparent;
  padding: 0;
`;
const OffWrapper = styled.div `
  .fill {
    fill: ${props => props.colorVariant &&
    props.theme.color[props.colorVariant]};
  }
`;
const SpinnerWrapper = styled.div `
  height: 36px;
  width: 36px;
`;
const Toggle = ({ checked = false, disabled = false, inProgress = false, onClick, toggleOffColorVariant = ToggleOffColorVariant.Default, }) => {
    let inner = (_jsx(OffWrapper, Object.assign({ colorVariant: toggleOffColorVariant }, { children: _jsx(ToggleOff, {}) })));
    if (checked) {
        inner = _jsx(ToggleOn, {});
    }
    if (inProgress) {
        inner = (_jsx(SpinnerWrapper, { children: _jsx(Spinner, { colorVariant: ColorVariant.Fog }) }));
    }
    return (_jsx(Wrapper, Object.assign({ type: "button", onClick: () => onClick(!checked), role: "switch", "aria-checked": checked, "aria-disabled": disabled, disabled: disabled }, { children: inner })));
};
export default Toggle;
