export var AvailabilityStateEnum;
(function (AvailabilityStateEnum) {
    AvailabilityStateEnum["AllFree"] = "ALL_FREE";
    AvailabilityStateEnum["AllBlocked"] = "ALL_BLOCKED";
    AvailabilityStateEnum["PartiallyBlocked"] = "PARTIALLY_BLOCKED";
})(AvailabilityStateEnum || (AvailabilityStateEnum = {}));
export var Operation;
(function (Operation) {
    Operation["Blocking"] = "blocking";
    Operation["Freeing"] = "freeing";
})(Operation || (Operation = {}));
