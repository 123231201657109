import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import styled from 'styled-components/macro';
import BlogArticle from '../BlogArticle/BlogArticle';
const ArticlesWrapper = styled.div `
  display: flex;
`;
const SectionBlogArticles = ({ articles, }) => {
    return (_jsx(ArticlesWrapper, { children: articles.map((article, index) => (_jsx(BlogArticle, { article: article }, index))) }));
};
export default SectionBlogArticles;
