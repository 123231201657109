import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import styled from 'styled-components/macro';
import Icon, { IconSizeVariant, IconVariant } from '../Icon/Icon';
import Text, { TextVariant } from '../Text/Text';
const Wrapper = styled.div `
  width: 100%;
  border-top: 1px solid ${props => props.theme.color.fog};

  &:last-child {
    border-bottom: 1px solid ${props => props.theme.color.fog};
  }
`;
const PaddedIcon = styled.div `
  padding: 0 8px;
`;
const Toggle = styled.button `
  width: 100%;
  padding: 20px 0 25px 0;
  border: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: transparent;
`;
const Title = styled(Text) `
  flex-grow: 1;
  text-align: left;
  margin-top: 0;
  margin-bottom: 0;

  color: ${props => props.theme.color.stone};
`;
const Content = styled.div `
  display: block;
  margin-bottom: 20px;
`;
const CollapsableContent = ({ id, title, children, defaultOpen = false, }) => {
    const [open, setOpen] = useState(defaultOpen);
    return (_jsxs(Wrapper, { children: [_jsxs(Toggle, Object.assign({ onClick: () => setOpen(!open), "aria-expanded": open, "aria-controls": `collapsable-content-${id}`, type: "button" }, { children: [_jsx(Title, Object.assign({ variant: TextVariant.H10B, as: "h5" }, { children: title })), _jsx(PaddedIcon, { children: _jsx(Icon, { variant: open ? IconVariant.Minus : IconVariant.Plus, sizeVariant: IconSizeVariant.ExtraSmall }) })] })), open && _jsx(Content, { children: children })] }));
};
export default CollapsableContent;
