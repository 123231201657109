import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import { isBrowser } from '@nomady/shared/utils/env';
import { MigrationForm } from './components/MigrationForm';
const Wrapper = styled.section `
  display: flex;
  flex-direction: column;
  margin-top: 80px;
  margin-bottom: 80px;
`;
const MigrateMyCabinUserSection = ({ title, text }) => {
    useEffect(() => {
        if (isBrowser() && !!(window === null || window === void 0 ? void 0 : window.fbq)) {
            console.info('track viewcontent');
            window.fbq('track', 'ViewContent');
        }
    }, []);
    return (_jsx(Wrapper, { children: _jsx(MigrationForm, { title: title, text: text }) }));
};
export default MigrateMyCabinUserSection;
