import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import CheckboxInputSection from '../../CheckboxInputSection/CheckboxInputSection';
import FilterDetailGroup from '../../FilterDetailGroup/FilterDetailGroup';
import FilterGroup from '../../FilterGroup/FilterGroup';
import { IconVariant } from '../../Icon/Icon';
const FacilitiesFilterGroup = ({ currentFeaturesSearchQuery, intl, onSetFeatures, }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17, _18, _19, _20;
    return (_jsxs(FilterGroup, Object.assign({ iconVariant: IconVariant.ShowerNew, label: intl.formatMessage({
            id: 'SearchBarSheet.Features.Facilities.title',
        }) }, { children: [_jsx(CheckboxInputSection, { checkboxes: [
                    {
                        id: 'facilities-basics-water',
                        checked: !!((_b = (_a = currentFeaturesSearchQuery.facilities) === null || _a === void 0 ? void 0 : _a.basics) === null || _b === void 0 ? void 0 : _b.water),
                        label: intl.formatMessage({
                            id: 'SearchBarSheet.Features.Facilities.Basics.water',
                        }),
                        onChange: (value) => onSetFeatures({
                            facilities: {
                                basics: { water: value || undefined },
                            },
                        }),
                    },
                    {
                        id: 'facilities-basics-power',
                        checked: false,
                        label: intl.formatMessage({
                            id: 'MarketplaceConfig.filterElectricity',
                        }),
                        onChange: (value) => {
                            var _a, _b;
                            return onSetFeatures({
                                facilities: {
                                    basics: {
                                        powerSupply: {
                                            smallDevices: ((_a = (value || []).find(element => element.id === 'facilities-power-supply-small-devices')) === null || _a === void 0 ? void 0 : _a.checked) || undefined,
                                            campingVehicles: ((_b = (value || []).find(element => element.id ===
                                                'facilities-power-supply-camping-vehicles')) === null || _b === void 0 ? void 0 : _b.checked) || undefined,
                                        },
                                    },
                                },
                            });
                        },
                        checkboxes: [
                            {
                                id: 'facilities-power-supply-small-devices',
                                checked: !!((_e = (_d = (_c = currentFeaturesSearchQuery.facilities) === null || _c === void 0 ? void 0 : _c.basics) === null || _d === void 0 ? void 0 : _d.powerSupply) === null || _e === void 0 ? void 0 : _e.smallDevices),
                                label: intl.formatMessage({
                                    id: 'MarketplaceConfig.powerSupplyType.smallDevices',
                                }),
                            },
                            {
                                id: 'facilities-power-supply-camping-vehicles',
                                checked: !!((_h = (_g = (_f = currentFeaturesSearchQuery.facilities) === null || _f === void 0 ? void 0 : _f.basics) === null || _g === void 0 ? void 0 : _g.powerSupply) === null || _h === void 0 ? void 0 : _h.campingVehicles),
                                label: intl.formatMessage({
                                    id: 'MarketplaceConfig.powerSupplyType.campingVehicles',
                                }),
                            },
                        ],
                    },
                    {
                        id: 'facilities-basics-toilet',
                        checked: false,
                        label: intl.formatMessage({
                            id: 'MarketplaceConfig.filterToilet',
                        }),
                        onChange: (value) => {
                            var _a, _b, _c;
                            return onSetFeatures({
                                facilities: {
                                    basics: {
                                        toilet: {
                                            portableToilet: ((_a = (value || []).find(element => element.id === 'facilities-basics-portable-toilet')) === null || _a === void 0 ? void 0 : _a.checked) || undefined,
                                            regularToilet: ((_b = (value || []).find(element => element.id === 'facilities-basics-regular-toilet')) === null || _b === void 0 ? void 0 : _b.checked) || undefined,
                                            closeToCampsite: ((_c = (value || []).find(element => element.id === 'facilities-basics-close-to-campsite')) === null || _c === void 0 ? void 0 : _c.checked) || undefined,
                                        },
                                    },
                                },
                            });
                        },
                        checkboxes: [
                            {
                                id: 'facilities-basics-portable-toilet',
                                checked: !!((_l = (_k = (_j = currentFeaturesSearchQuery.facilities) === null || _j === void 0 ? void 0 : _j.basics) === null || _k === void 0 ? void 0 : _k.toilet) === null || _l === void 0 ? void 0 : _l.portableToilet),
                                label: intl.formatMessage({
                                    id: 'MarketplaceConfig.portableToilet',
                                }),
                            },
                            {
                                id: 'facilities-basics-regular-toilet',
                                checked: !!((_p = (_o = (_m = currentFeaturesSearchQuery.facilities) === null || _m === void 0 ? void 0 : _m.basics) === null || _o === void 0 ? void 0 : _o.toilet) === null || _p === void 0 ? void 0 : _p.regularToilet),
                                label: intl.formatMessage({
                                    id: 'MarketplaceConfig.regularToilet',
                                }),
                            },
                            {
                                id: 'facilities-basics-close-to-campsite',
                                checked: !!((_s = (_r = (_q = currentFeaturesSearchQuery.facilities) === null || _q === void 0 ? void 0 : _q.basics) === null || _r === void 0 ? void 0 : _r.toilet) === null || _s === void 0 ? void 0 : _s.closeToCampsite),
                                label: intl.formatMessage({
                                    id: 'SearchBarSheet.Features.Facilities.Basics.toilet_closeToCampsite',
                                }),
                            },
                        ],
                    },
                ] }), _jsx(FilterDetailGroup, { label: intl.formatMessage({
                    id: 'SearchBarSheet.Features.Facilities.Accommodation.title',
                }), iconVariant: IconVariant.Hut, checkboxes: [
                    {
                        id: 'facilities-accommodation-million-stars',
                        checked: !!((_u = (_t = currentFeaturesSearchQuery.facilities) === null || _t === void 0 ? void 0 : _t.accommodation) === null || _u === void 0 ? void 0 : _u.millionStars),
                        label: intl.formatMessage({
                            id: 'SearchBarSheet.Features.Facilities.Accommodation.millionStars',
                        }),
                        onChange: (value) => onSetFeatures({
                            facilities: { accommodation: { millionStars: value } },
                        }),
                    },
                    {
                        id: 'facilities-accommodation-heating',
                        checked: !!((_w = (_v = currentFeaturesSearchQuery.facilities) === null || _v === void 0 ? void 0 : _v.accommodation) === null || _w === void 0 ? void 0 : _w.heating),
                        label: intl.formatMessage({
                            id: 'MarketplaceConfig.heating',
                        }),
                        onChange: (value) => onSetFeatures({
                            facilities: { accommodation: { heating: value } },
                        }),
                    },
                    {
                        id: 'facilities-accommodation-linen',
                        checked: !!((_y = (_x = currentFeaturesSearchQuery.facilities) === null || _x === void 0 ? void 0 : _x.accommodation) === null || _y === void 0 ? void 0 : _y.linen),
                        label: intl.formatMessage({
                            id: 'MarketplaceConfig.bedding',
                        }),
                        onChange: (value) => onSetFeatures({
                            facilities: { accommodation: { linen: value } },
                        }),
                    },
                ] }), _jsx(FilterDetailGroup, { label: intl.formatMessage({
                    id: 'SearchBarSheet.Features.Facilities.Miscellaneous.title',
                }), iconVariant: IconVariant.Table, checkboxes: [
                    {
                        id: 'facilities-miscellaneous-shower',
                        checked: false,
                        label: intl.formatMessage({
                            id: 'MarketplaceConfig.filterShower',
                        }),
                        onChange: (value) => {
                            var _a, _b;
                            return onSetFeatures({
                                facilities: {
                                    shower: {
                                        outdoorShower: (_a = (value || []).find(element => element.id === 'facilities-miscellaneous-outdoor-shower')) === null || _a === void 0 ? void 0 : _a.checked,
                                        regularShower: (_b = (value || []).find(element => element.id === 'facilities-miscellaneous-regular-shower')) === null || _b === void 0 ? void 0 : _b.checked,
                                    },
                                },
                            });
                        },
                        checkboxes: [
                            {
                                id: 'facilities-miscellaneous-regular-shower',
                                checked: !!((_0 = (_z = currentFeaturesSearchQuery.facilities) === null || _z === void 0 ? void 0 : _z.shower) === null || _0 === void 0 ? void 0 : _0.regularShower),
                                label: intl.formatMessage({
                                    id: 'MarketplaceConfig.regularShower',
                                }),
                            },
                            {
                                id: 'facilities-miscellaneous-outdoor-shower',
                                checked: !!((_2 = (_1 = currentFeaturesSearchQuery.facilities) === null || _1 === void 0 ? void 0 : _1.shower) === null || _2 === void 0 ? void 0 : _2.outdoorShower),
                                label: intl.formatMessage({
                                    id: 'MarketplaceConfig.outdoorShower',
                                }),
                            },
                        ],
                    },
                    {
                        id: 'facilities-miscellaneous-kitchen',
                        checked: !!((_4 = (_3 = currentFeaturesSearchQuery.facilities) === null || _3 === void 0 ? void 0 : _3.miscellaneous) === null || _4 === void 0 ? void 0 : _4.kitchen),
                        label: intl.formatMessage({
                            id: 'MarketplaceConfig.kitchen',
                        }),
                        onChange: (value) => onSetFeatures({
                            facilities: { miscellaneous: { kitchen: value } },
                        }),
                    },
                    {
                        id: 'facilities-miscellaneous-picnic-table',
                        checked: !!((_6 = (_5 = currentFeaturesSearchQuery.facilities) === null || _5 === void 0 ? void 0 : _5.miscellaneous) === null || _6 === void 0 ? void 0 : _6.picnicTable),
                        label: intl.formatMessage({
                            id: 'MarketplaceConfig.picNicTable',
                        }),
                        onChange: (value) => onSetFeatures({
                            facilities: { miscellaneous: { picnicTable: value } },
                        }),
                    },
                    {
                        id: 'facilities-miscellaneous-bin',
                        checked: !!((_8 = (_7 = currentFeaturesSearchQuery.facilities) === null || _7 === void 0 ? void 0 : _7.miscellaneous) === null || _8 === void 0 ? void 0 : _8.bin),
                        label: intl.formatMessage({
                            id: 'MarketplaceConfig.trash',
                        }),
                        onChange: (value) => onSetFeatures({
                            facilities: { miscellaneous: { bin: value } },
                        }),
                    },
                    {
                        id: 'facilities-miscellaneous-dish-washing',
                        checked: !!((_10 = (_9 = currentFeaturesSearchQuery.facilities) === null || _9 === void 0 ? void 0 : _9.miscellaneous) === null || _10 === void 0 ? void 0 : _10.dishwashing),
                        label: intl.formatMessage({
                            id: 'MarketplaceConfig.dishwashing',
                        }),
                        onChange: (value) => onSetFeatures({
                            facilities: { miscellaneous: { dishwashing: value } },
                        }),
                    },
                    {
                        id: 'facilities-miscellaneous-gray-water-disposal',
                        checked: !!((_12 = (_11 = currentFeaturesSearchQuery.facilities) === null || _11 === void 0 ? void 0 : _11.miscellaneous) === null || _12 === void 0 ? void 0 : _12.grayWaterDisposal),
                        label: intl.formatMessage({
                            id: 'MarketplaceConfig.greywater',
                        }),
                        onChange: (value) => onSetFeatures({
                            facilities: { miscellaneous: { grayWaterDisposal: value } },
                        }),
                    },
                    {
                        id: 'facilities-miscellaneous-shelter',
                        checked: !!((_14 = (_13 = currentFeaturesSearchQuery.facilities) === null || _13 === void 0 ? void 0 : _13.miscellaneous) === null || _14 === void 0 ? void 0 : _14.shelter),
                        label: intl.formatMessage({
                            id: 'MarketplaceConfig.shelter',
                        }),
                        onChange: (value) => onSetFeatures({
                            facilities: { miscellaneous: { shelter: value } },
                        }),
                    },
                    {
                        id: 'facilities-miscellaneous-internet-hotspot',
                        checked: !!((_16 = (_15 = currentFeaturesSearchQuery.facilities) === null || _15 === void 0 ? void 0 : _15.miscellaneous) === null || _16 === void 0 ? void 0 : _16.internetHotspot),
                        label: intl.formatMessage({
                            id: 'SearchBarSheet.Features.Facilities.Miscellaneous.internetHotspot',
                        }),
                        onChange: (value) => onSetFeatures({
                            facilities: { miscellaneous: { internetHotspot: value } },
                        }),
                    },
                    {
                        id: 'facilities-miscellaneous-mobile-reception',
                        checked: !!((_18 = (_17 = currentFeaturesSearchQuery.facilities) === null || _17 === void 0 ? void 0 : _17.miscellaneous) === null || _18 === void 0 ? void 0 : _18.mobileReception),
                        label: intl.formatMessage({
                            id: 'MarketplaceConfig.reception',
                        }),
                        onChange: (value) => onSetFeatures({
                            facilities: { miscellaneous: { mobileReception: value } },
                        }),
                    },
                ] }), _jsx(FilterDetailGroup, { label: intl.formatMessage({
                    id: 'SearchBarSheet.Features.Facilities.Miscellaneous.store',
                }), iconVariant: IconVariant.Shop, toggle: {
                    onChange: value => onSetFeatures({
                        facilities: { store: value },
                    }),
                    checked: !!((_19 = currentFeaturesSearchQuery.facilities) === null || _19 === void 0 ? void 0 : _19.store),
                } }), _jsx(FilterDetailGroup, { label: intl.formatMessage({
                    id: 'SearchBarSheet.Features.Facilities.Miscellaneous.winterCamping',
                }), iconVariant: IconVariant.BubbleSnowflake, toggle: {
                    onChange: value => onSetFeatures({
                        facilities: { winterCamping: value || undefined },
                    }),
                    checked: !!((_20 = currentFeaturesSearchQuery.facilities) === null || _20 === void 0 ? void 0 : _20.winterCamping),
                } })] })));
};
export default FacilitiesFilterGroup;
