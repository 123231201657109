var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import styled from 'styled-components/macro';
import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useIntl } from 'react-intl';
import { Controller, useForm, } from 'react-hook-form';
import { localizedZodCustomErrorMap } from '@nomady/shared/schemas/localizedZodCustomErrorMap';
import Button, { ButtonColorVariant, ButtonSizeVariant, } from '../Button/Button';
import { IconVariant } from '../Icon/Icon';
import IconButtonOld, { OldIconButtonColorVariant, OldIconButtonSizeVariant, } from '../IconButtonOld/IconButtonOld';
import Input, { ErrorMessageWrapper } from '../Input/Input';
import InputListItem from './InputListItem';
import InputErrorMessage from '../InputErrorMessage/InputErrorMessage';
const Wrapper = styled.div `
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
`;
const ClearButton = styled.div `
  align-self: flex-start;
`;
const AddItemWrapper = styled.form `
  display: grid;
  grid-template-columns: 1fr 48px;
  grid-column-gap: 10px;
  align-items: top;
`;
const InputWrap = styled.div `
  flex-grow: 1;
  margin-right: 10px;
`;
const AddButtonWrapper = styled.div `
  padding-top: 36px;
`;
const ItemsWrapper = styled.div `
  display: flex;
  flex-direction: row;
`;
const Items = styled.div `
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: flex-start;
`;
const InternalField = (_a) => {
    var { type, inputPlaceholder, description, label, maxItemLength, field, fieldState, formState, error } = _a, props = __rest(_a, ["type", "inputPlaceholder", "description", "label", "maxItemLength", "field", "fieldState", "formState", "error"]);
    const [items, setItems] = useState(field.value || []);
    const intl = useIntl();
    const hasError = !!error;
    const errorMessageId = hasError ? `${props.id}-errormessage` : '';
    const addItem = data => {
        if (hasError) {
            return;
        }
        items.push(data.newItem);
        setItems(items);
        field.onChange(items);
        resetField('newItem');
    };
    const moveItem = (newIndex, oldIndex) => {
        // https://stackoverflow.com/a/60142451
        const move = (arr, from, to) => arr.map((item, i) => 
        // eslint-disable-next-line no-nested-ternary
        i === to
            ? arr[from]
            : i >= Math.min(from, to) && i <= Math.max(from, to)
                ? arr[i + Math.sign(to - from)]
                : item);
        const newItems = move(items, oldIndex, newIndex);
        setItems(newItems);
        field.onChange(items);
    };
    const findItem = (item) => items.findIndex(value => value === item);
    const removeItem = (event, item) => {
        event.preventDefault();
        const index = findItem(item);
        items.splice(index, 1);
        setItems(items);
        field.onChange(items);
    };
    const removeAllItems = (event) => {
        event.preventDefault();
        setItems([]);
        field.onChange([]);
    };
    z.setErrorMap(localizedZodCustomErrorMap(intl, 'ListingSchemaError'));
    const InputSchema = z.object({
        newItem: z.string().max(maxItemLength || 100),
    });
    const { register, handleSubmit, formState: { errors }, resetField, } = useForm({
        mode: 'onChange',
        defaultValues: { newItem: '' },
        resolver: zodResolver(InputSchema),
    });
    const newItem = register('newItem');
    return (_jsxs(Wrapper, { children: [_jsxs(AddItemWrapper, Object.assign({ onSubmit: (event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    handleSubmit(addItem)();
                } }, { children: [_jsx(InputWrap, { children: _jsx(Input, Object.assign({ label: label, description: description, id: "newItem" }, newItem, { type: "text", placeholder: inputPlaceholder, max: maxItemLength, inputRef: newItem.ref, error: errors.newItem, hasError: hasError })) }), _jsx(AddButtonWrapper, { children: _jsx(IconButtonOld, { iconVariant: IconVariant.PlusThin, iconButtonSizeVariant: OldIconButtonSizeVariant.Large, colorVariant: OldIconButtonColorVariant.Light }) })] })), hasError && (_jsx(ErrorMessageWrapper, Object.assign({ role: "alert" }, { children: _jsx(InputErrorMessage, { id: errorMessageId, error: error }) }))), 0 < items.length && (_jsxs(ItemsWrapper, { children: [_jsx(Items, { children: items.map((item, index) => (_jsx(InputListItem, { type: props.name || 'input-list', item: item, index: index, removeItem: (event) => removeItem(event, item), moveItem: (newIndex, oldIndex) => moveItem(newIndex, oldIndex) }, item))) }), _jsx(ClearButton, { children: _jsx(Button, { onClick: removeAllItems, label: intl.formatMessage({ id: 'InputList.clearAllButton' }), icon: IconVariant.Garbage, sizeVariant: ButtonSizeVariant.Small, colorVariant: ButtonColorVariant.Transparent }) })] }))] }));
};
const InputList = (_a) => {
    var { control, rules } = _a, props = __rest(_a, ["control", "rules"]);
    return (_jsx(Controller, { render: fieldProps => _jsx(InternalField, Object.assign({}, fieldProps, props)), name: props.name, control: control, rules: rules }));
};
export default InputList;
