import * as custom from '@nomady/shared/marketplace-custom-config';
import { currencyConfiguration } from '@nomady/shared/currency-config';
import env from '@nomady/shared/utils/env';
import { RuntimeEnvironment } from '@nomady/shared/utils/localized-domains';
import { defaultMCC, stripePublishableKey, stripeCountryDetails, } from '@nomady/shared/stripe-config';
import defaultLocationSearches from './default-location-searches';
const reactAppEnv = env('ENV');
const dev = reactAppEnv !== RuntimeEnvironment.Production;
// If you want to change the language, remember to also change the
// locale data and the messages in the app.js file.
const locale = 'de';
const apiSiteHandle = 'german'; // Available: german, english, french
const i18n = {
    /*
      0: Sunday
      1: Monday
      ...
      6: Saturday
    */
    firstDayOfWeek: 1,
};
// Should search results be ordered by distance to origin.
// NOTE: If this is set to true add parameter 'origin' to every location in default-location-searches.js
// Without the 'origin' parameter, search will not work correctly
// NOTE: Keyword search and ordering search results by distance can't be used at the same time. You can turn keyword
// search off by changing the keywordFilterConfig parameter active to false in marketplace-custom-config.js
const sortSearchByDistance = false;
// API supports custom processes to be used in booking process.
// We need to specify it when we are initiating a new order
// (or fetching price information by calling 'initiateSpeculative' endpoint).
//
// In a way, 'processAlias' defines which transaction process (or processes)
// this particular web application is able to handle.
const legacyBookingProcessName = 'sca-preauth-nightly-line-items-booking';
const legacyBookingProcessAlias = `${legacyBookingProcessName}/release-1.32.0`;
const customBookingProcessName = 'nightly-custom';
const customBookingProcessAlias = `${customBookingProcessName}/release-1.36.0`;
const cancellationProcessVersion = env('PROCESS_VERSION_CANCELLATION');
// The transaction line item code for the main unit type in bookings.
//
// Possible values: ['line-item/night', 'line-item/day', 'line-item/units';]
//
// Note: translations will use different translation keys for night, day or unit
// depending on the value chosen.
const bookingUnitType = 'line-item/night';
// A maximum number of days forwards during which a booking can be made.
// This is limited due to Stripe holding funds up to 90 days from the
// moment they are charged. Also note that available time slots can only
// be fetched for 366 days in the future.
const dayCountAvailableForBooking = 366;
// To pass environment variables to the client app in the build
// script, react-scripts (and the sharetribe-scripts fork of
// react-scripts) require using the REACT_APP_ prefix to avoid
// exposing server secrets to the client side.
const sdkClientId = env('SHARETRIBE_SDK_CLIENT_ID');
const sdkBaseUrl = env('SHARETRIBE_SDK_BASE_URL');
const sdkTransitVerbose = env('SHARETRIBE_SDK_TRANSIT_VERBOSE') === 'true';
// Currency formatting options.
// See: https://github.com/yahoo/react-intl/wiki/API#formatnumber
const getCurrencyConfig = (dynamicCurrency) => currencyConfiguration(dynamicCurrency);
// Sentry DSN (Data Source Name), a client key for authenticating calls to Sentry
const sentryDsn = env('SENTRY_DSN');
// If webapp is using SSL (i.e. it's behind 'https' protocol)
const usingSSL = env('SHARETRIBE_USING_SSL') === 'true';
// Address information is used in SEO schema for Organization (http://schema.org/PostalAddress)
const addressCountry = 'CH';
const addressRegion = 'Einsiedeln';
const postalCode = '8840';
const streetAddress = 'Eisenbahnstrasse 7';
// Site title is needed in meta tags (bots and social media sharing reads those)
const siteTitle = 'Nomady';
// Twitter handle is needed in meta tags (twitter:site). Start it with '@' character
const siteTwitterHandle = '@nomady';
// Instagram page is used in SEO schema (http://schema.org/Organization)
const siteInstagramPage = 'https://www.instagram.com/nomady.camp/';
// Facebook page is used in SEO schema (http://schema.org/Organization)
const siteFacebookPage = 'https://www.facebook.com/Nomady.camp/';
// Facebook counts shares with app or page associated by this id
// Currently it is unset, but you can read more about fb:app_id from
// https://developers.facebook.com/docs/sharing/webmasters#basic
// You should create one to track social sharing in Facebook
const facebookAppId = null;
const maps = {
    googleMapsAPIKey: env('GOOGLE_MAPS_API_KEY'),
    mapboxKey: env('MAPBOX_KEY'),
    // The location search input can be configured to show default
    // searches when the user focuses on the input and hasn't yet typed
    // anything. This reduces typing and avoids too many Geolocation API
    // calls for common searches.
    search: {
        // When enabled, the first suggestion is "Current location" that
        // uses the browser Geolocation API to query the user's current
        // location.
        suggestCurrentLocation: true,
        // Distance in meters for calculating the bounding box around the
        // current location.
        currentLocationBoundsDistance: 14000,
        // Example location can be edited in the
        // `default-location-searches.js` file.
        defaults: defaultLocationSearches,
    },
};
// NOTE: only expose configuration that should be visible in the
// client side, don't add any server secrets in this file.
const config = {
    env: reactAppEnv,
    dev,
    locale,
    apiSiteHandle,
    legacyBookingProcessName,
    legacyBookingProcessAlias,
    customBookingProcessName,
    customBookingProcessAlias,
    cancellationProcessVersion,
    bookingUnitType,
    dayCountAvailableForBooking,
    i18n,
    sdk: {
        clientId: sdkClientId,
        baseUrl: sdkBaseUrl,
        transitVerbose: sdkTransitVerbose,
    },
    sortSearchByDistance,
    getCurrencyConfig,
    stripe: {
        defaultMCC,
        publishableKey: stripePublishableKey,
        supportedCountries: stripeCountryDetails,
    },
    address: {
        addressCountry,
        addressRegion,
        postalCode,
        streetAddress,
    },
    siteTitle,
    siteFacebookPage,
    siteInstagramPage,
    siteTwitterHandle,
    facebookAppId,
    sentryDsn,
    usingSSL,
    maps,
    custom,
};
export default config;
