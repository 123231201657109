import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styled, { css } from 'styled-components/macro';
import routeConfiguration from '@nomady/shared/routeConfiguration/routeConfiguration';
import Text from '../Text/Text';
import Icon, { IconSizeVariant, IconVariant } from '../Icon/Icon';
import { ColorVariant } from '../../theme';
import Spinner from '../Spinner/Spinner';
import { NamedLink } from '../../components';
export var ButtonColorVariant;
(function (ButtonColorVariant) {
    ButtonColorVariant["Default"] = "Default";
    ButtonColorVariant["Dark"] = "Dark";
    ButtonColorVariant["Light"] = "Default";
    ButtonColorVariant["Amber"] = "Amber";
    ButtonColorVariant["Fir"] = "Fir";
    ButtonColorVariant["Transparent"] = "Transparent";
    ButtonColorVariant["Forest"] = "Forest";
})(ButtonColorVariant || (ButtonColorVariant = {}));
export var ButtonOutlineVariant;
(function (ButtonOutlineVariant) {
    ButtonOutlineVariant["Default"] = "Default";
    ButtonOutlineVariant["Outlined"] = "Dark";
    ButtonOutlineVariant["OutlinedCornflowerBlue"] = "OutlinedCornflowerBlue";
    ButtonOutlineVariant["OutlinedWhite"] = "OutlinedWhite";
})(ButtonOutlineVariant || (ButtonOutlineVariant = {}));
export var ButtonSizeVariant;
(function (ButtonSizeVariant) {
    ButtonSizeVariant["Default"] = "Default";
    ButtonSizeVariant["Small"] = "Small";
    ButtonSizeVariant["ExtraSmall"] = "ExtraSmall";
    ButtonSizeVariant["ListingSearchBar"] = "ListingSearchBar";
    ButtonSizeVariant["RegionNavigation"] = "RegionNavigation";
})(ButtonSizeVariant || (ButtonSizeVariant = {}));
export var ButtonBorderRadiusVariant;
(function (ButtonBorderRadiusVariant) {
    ButtonBorderRadiusVariant["Default"] = "Default";
    ButtonBorderRadiusVariant["Squared"] = "Squared";
})(ButtonBorderRadiusVariant || (ButtonBorderRadiusVariant = {}));
export var TextAlign;
(function (TextAlign) {
    TextAlign["Left"] = "left";
    TextAlign["Center"] = "center";
    TextAlign["Right"] = "right";
})(TextAlign || (TextAlign = {}));
const IconWrapper = styled.div `
  width: 24px;
  margin: 0 auto;

  ${props => props.sizeVariant !== ButtonSizeVariant.ListingSearchBar &&
    css `
      padding: 1px 0 3px 0;
    `}

  ${props => props.sizeVariant === ButtonSizeVariant.Small &&
    css `
      padding: 3px 0 3px 0;
    `}

  ${props => props.sizeVariant === ButtonSizeVariant.RegionNavigation &&
    css `
      fill: ${props.colorVariant &&
        props.colorVariant === ButtonColorVariant.Dark
        ? props.theme.color.pearl
        : props.theme.color.stone};
      stroke: ${props.colorVariant &&
        props.colorVariant === ButtonColorVariant.Dark
        ? props.theme.color.pearl
        : props.theme.color.stone};
    `}
`;
export const buttonBaseStyles = (props) => css `
  display: flex;
  align-items: center;

  border-radius: 50px;
  padding: 10px 35px 11px 35px;
  border: none;
  background: ${props => props.theme.color.pearl};
  border: 1px solid ${props => props.theme.color.pearl};
  cursor: pointer;

  ${props => !props.icon &&
    css `
      width: 100%;
      text-align: center;
    `}

  ${props => (props.mobileSizeVariant === ButtonSizeVariant.Small ||
    props.mobileSizeVariant === ButtonSizeVariant.RegionNavigation ||
    props.responsive) &&
    css `
      padding: 5px 15px 5px 15px;
    `}

  ${props => props.mobileSizeVariant === ButtonSizeVariant.ExtraSmall &&
    css `
      padding: 3px 10px 3px 10px;
    `}


  ${props => props.mobileSizeVariant === ButtonSizeVariant.ListingSearchBar &&
    css `
      padding: 11px;
    `}

  @media ${props => props.theme.device.tablet} {
    padding: 10px 35px 11px 35px;
    ${props => props.responsive &&
    `
      padding: 10px 35px 11px 35px;
    `}

    ${props => (props.sizeVariant === ButtonSizeVariant.Small ||
    props.sizeVariant === ButtonSizeVariant.RegionNavigation ||
    props.responsive) &&
    css `
        padding: 5px 15px 5px 15px;
      `}

    ${props => props.sizeVariant === ButtonSizeVariant.ExtraSmall &&
    css `
        padding: 3px 10px 3px 10px;
      `}

    ${props => props.sizeVariant === ButtonSizeVariant.ListingSearchBar &&
    css `
        padding: 11px;
      `}
  }

  ${props => props.colorVariant === ButtonColorVariant.Dark &&
    css `
      background: ${props.theme.color.cave};
      border: 1px solid ${props.theme.color.cave};
    `}

  ${props => props.colorVariant === ButtonColorVariant.Fir &&
    css `
      background: ${props.theme.color.fir};
      border: 1px solid ${props.theme.color.fir};
    `}

  ${props => props.colorVariant === ButtonColorVariant.Amber &&
    css `
      background: ${props.theme.color.amber};
      border: 1px solid ${props.theme.color.amber};
    `}

  ${props => props.colorVariant === ButtonColorVariant.Forest &&
    css `
      background: ${props.theme.color.forest};
      border: 1px solid ${props.theme.color.forest};
    `}
  
  ${props => props.icon === IconVariant.ArrowRight &&
    css `
      ${IconWrapper} {
        transform: translateX(-5px);
      }
    `}

  ${props => props.icon === IconVariant.ArrowRight &&
    css `
      &:hover {
        ${IconWrapper} {
          transform: translateX(0);
          transition: transform 200ms ease;
        }
      }
    `}

    ${props => props.colorVariant === ButtonColorVariant.Transparent &&
    css `
      background-color: transparent;
      border-color: transparent;
    `}


  ${props => props.outlineVariant === ButtonOutlineVariant.Outlined &&
    css `
      border: 1px solid ${props.theme.color.cave};
    `}

  ${props => props.outlineVariant === ButtonOutlineVariant.OutlinedWhite &&
    css `
      border: 1px solid ${props.theme.color.pearl};
    `}

  ${props => props.outlineVariant === ButtonOutlineVariant.OutlinedCornflowerBlue &&
    css `
      border: 1px solid ${props.theme.color.cornflowerblue};
    `}


  ${props => props.borderRadiusVariant === ButtonBorderRadiusVariant.Squared &&
    css `
      border-radius: 5px;
    `}

  ${props => props.disabled &&
    css `
      opacity: 0.4;
    `}

  @media ${props => props.theme.device.desktop} {
    ${props => !props.disabled &&
    css `
        &:hover {
          cursor: pointer;
          border: 1px solid ${props => props.theme.color.stone};
        }
      `}
  }
`;
const Wrapper = styled.button `
  width: 100%;
  ${props => buttonBaseStyles(props)}
`;
const Content = styled(Text) `
  width: 100%;
  text-align: ${props => props.textAlign || TextAlign.Center};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${props => props.underlineLabel &&
    css `
      text-decoration: underline;
      text-underline-offset: 5px;
    `}

  ${props => props.hasIcon && `margin-left: 10px;`}

  ${props => (props.mobileSizeVariant === ButtonSizeVariant.Small ||
    props.mobileSizeVariant === ButtonSizeVariant.RegionNavigation ||
    props.responsive) &&
    css `
      ${props.theme.typeset.H10}
    `}
  ${props => props.mobileSizeVariant === ButtonSizeVariant.ExtraSmall &&
    css `
      ${props.theme.typeset.H12B}
    `}

  ${props => props.mobileSizeVariant === ButtonSizeVariant.ListingSearchBar &&
    css `
      ${props.theme.typeset.H11B}
    `}

  @media ${props => props.theme.device.tablet} {
    ${props => props.responsive && `${props.theme.typeset.B20}`}
    ${props => props.sizeVariant === ButtonSizeVariant.Default &&
    css `
        ${props.theme.typeset.B20}
      `}
    ${props => (props.sizeVariant === ButtonSizeVariant.Small ||
    props.sizeVariant === ButtonSizeVariant.RegionNavigation ||
    props.responsive) &&
    css `
        ${props.theme.typeset.H10}
      `}
    ${props => props.sizeVariant === ButtonSizeVariant.ExtraSmall &&
    css `
        ${props.theme.typeset.H12B}
      `}
  
    ${props => props.sizeVariant === ButtonSizeVariant.ListingSearchBar &&
    css `
        ${props.theme.typeset.H11B}
      `}
  }

  color: ${props => props.theme.color.cave};

  ${props => (props.buttonColorVariant === ButtonColorVariant.Dark ||
    props.buttonColorVariant === ButtonColorVariant.Fir ||
    props.buttonColorVariant === ButtonColorVariant.Forest) &&
    css `
      color: ${props.theme.color.pearl};
    `}
`;
/**
 * @deprecated Use Button2024 instead
 */
const Button = ({ onClick, targetRoute, targetRouteParams, searchParams, externalLink, icon, type, label, colorVariant = ButtonColorVariant.Default, outlineVariant = ButtonOutlineVariant.Default, borderRadiusVariant = ButtonBorderRadiusVariant.Default, sizeVariant = ButtonSizeVariant.Default, mobileSizeVariant = sizeVariant, disabled = false, ariaLabel, ariaCurrent = false, ariaHidden, ariaDisabled, inProgress, target, rel, ready, responsive = false, textAlign, dataTestId, underlineLabel = false, children, }) => {
    const buttonColorVariant = colorVariant === ButtonColorVariant.Light ||
        colorVariant === ButtonColorVariant.Amber ||
        colorVariant === ButtonColorVariant.Transparent
        ? ColorVariant.Cave
        : ColorVariant.Pearl;
    let content = label ? _jsx(_Fragment, { children: label }) : children;
    if (inProgress && !icon) {
        content = (_jsx(IconWrapper, Object.assign({ "aria-hidden": true, sizeVariant: sizeVariant }, { children: _jsx(Spinner, { colorVariant: buttonColorVariant }) })));
    }
    else if (ready) {
        content = (_jsx(Icon, { colorVariant: ColorVariant.Pearl, variant: IconVariant.Checkmark }));
    }
    const iconSizeVariant = (deviceSizeVariant) => deviceSizeVariant === ButtonSizeVariant.Small
        ? IconSizeVariant.ExtraSmall
        : IconSizeVariant.Default;
    const inner = (_jsxs(Wrapper, Object.assign({ onClick: onClick, type: type, colorVariant: colorVariant, outlineVariant: outlineVariant, borderRadiusVariant: borderRadiusVariant, sizeVariant: sizeVariant, mobileSizeVariant: mobileSizeVariant, icon: icon, disabled: disabled, responsive: responsive, "aria-label": ariaLabel, "aria-current": ariaCurrent, "aria-hidden": ariaHidden, "aria-disabled": ariaDisabled, "data-test-id": dataTestId, "data-testid": dataTestId }, { children: [icon && !inProgress && (_jsx(IconWrapper, Object.assign({ colorVariant: colorVariant, "aria-hidden": true, sizeVariant: sizeVariant }, { children: _jsx(Icon, { variant: icon, colorVariant: buttonColorVariant, sizeVariant: iconSizeVariant(sizeVariant), mobileSizeVariant: iconSizeVariant(mobileSizeVariant) }) }))), icon && inProgress && (_jsx(IconWrapper, Object.assign({ "aria-hidden": true, sizeVariant: sizeVariant }, { children: _jsx(Spinner, { colorVariant: buttonColorVariant }) }))), content && (_jsx(Content, Object.assign({ hasIcon: !!icon, sizeVariant: sizeVariant, mobileSizeVariant: mobileSizeVariant, responsive: responsive, buttonColorVariant: colorVariant, textAlign: textAlign, underlineLabel: underlineLabel }, { children: content })))] })));
    if (targetRoute && routeConfiguration) {
        return (_jsx(NamedLink, Object.assign({ name: targetRoute, params: targetRouteParams, searchParams: searchParams }, { children: inner })));
    }
    if (externalLink) {
        return (_jsx("a", Object.assign({ href: externalLink, target: target, rel: rel }, { children: inner })));
    }
    return inner;
};
export default Button;
