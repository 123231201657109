import * as z from 'zod';
import { types as sdkTypes } from '../../utils/sdkLoader';
import { AccommodationAmenity, CampCategory, CateringType, DefaultAmenity, Environment, FireplaceType, ShowerType, SummerActivity, ToiletType, PowerSupplyFeature, PowerSupplyPayment, FirewoodPayment, } from '../../types';
import { localizedZodCustomErrorMap } from '../localizedZodCustomErrorMap';
import { PriceSchema } from '../price';
import { MARKETPLACE_CURRENCY } from '../../config';
const { Money } = sdkTypes;
export const STORE_PRODUCT_ITEM_MAX_LENGTH = 500;
export const MAX_STORE_PRODUCTS = 12;
export const MAX_FAQ = 6;
export const FAQ_ITEM_MAX_LENGTH = 500;
export const MAX_HIGHLIGHTS = 7;
export const HIGHLIGHT_ITEM_MAX_LENGTH = 500;
const ShowerAvailableSchema = z.object({
    available: z.literal(true),
    feature: z.array(z.nativeEnum(ShowerType)).nullish(),
});
const ShowerNotAvailableSchema = z.object({
    available: z.literal(false),
});
const ToiletAvailableSchema = z.object({
    available: z.literal(true),
    feature: z.array(z.nativeEnum(ToiletType)).nullish(),
});
const ToiletNotAvailableSchema = z.object({
    available: z.literal(false),
});
const FirewoodPricingSchema = z
    .object({
    payment: z.nativeEnum(FirewoodPayment),
})
    .passthrough()
    .pipe(z.discriminatedUnion('payment', [
    z.object({
        payment: z.literal(FirewoodPayment.firewoodPurchasable),
        price: PriceSchema,
    }),
    z.object({
        payment: z.literal(FirewoodPayment.firewoodInclusive),
    }),
    z.object({
        payment: z.literal(FirewoodPayment.firewoodSaleOnSite),
    }),
    z.object({
        payment: z.literal(FirewoodPayment.firewoodGuestBringsAlong),
    }),
]));
const FireplaceAvailableSchema = z.object({
    available: z.literal(true),
    feature: z.array(z.nativeEnum(FireplaceType)),
    pricing: FirewoodPricingSchema,
});
const FireplaceNotAvailableSchema = z.object({
    available: z.literal(false),
});
const PowerSupplyPricingSchema = z
    .object({
    payment: z.nativeEnum(PowerSupplyPayment),
})
    .passthrough()
    .pipe(z.discriminatedUnion('payment', [
    z.object({
        payment: z.literal(PowerSupplyPayment.soldSeparately),
        price: PriceSchema,
    }),
    z.object({
        payment: z.literal(PowerSupplyPayment.soldOnSite),
    }),
    z.object({
        payment: z.literal(PowerSupplyPayment.includedInPrice),
    }),
]));
const PowerSupplyAvailableSchema = z.object({
    available: z.literal(true),
    feature: z.array(z.nativeEnum(PowerSupplyFeature)).min(1),
    pricing: PowerSupplyPricingSchema,
});
const PowerSupplyNotAvailableSchema = z.object({
    available: z.literal(false),
});
const CommonSchema = z.object({
    environment: z.array(z.nativeEnum(Environment)).nullish(),
    environmentDescription: z.string().nullish(),
    summerActivities: z.array(z.nativeEnum(SummerActivity)).nullish(),
    summerActivitiesDescription: z.string().nullish(),
    toilet: z.discriminatedUnion('available', [
        ToiletAvailableSchema,
        ToiletNotAvailableSchema,
    ]),
    fireplace: z.discriminatedUnion('available', [
        FireplaceAvailableSchema,
        FireplaceNotAvailableSchema,
    ]),
    shower: z.discriminatedUnion('available', [
        ShowerAvailableSchema,
        ShowerNotAvailableSchema,
    ]),
    waterAvailable: z.boolean().nullish(),
    powerSupply: z.discriminatedUnion('available', [
        PowerSupplyAvailableSchema,
        PowerSupplyNotAvailableSchema,
    ]),
    amenitiesDescription: z.string().nullish(),
    cateringType: z.array(z.nativeEnum(CateringType)).nullish(),
    cateringDescription: z.string().nullish(),
    storeProducts: z
        .array(z.string().max(STORE_PRODUCT_ITEM_MAX_LENGTH))
        .max(MAX_STORE_PRODUCTS)
        .nullish(),
    familyFriendly: z.boolean(),
    faq: z.array(z.string().max(FAQ_ITEM_MAX_LENGTH)).max(MAX_FAQ).nullish(),
    highlights: z
        .array(z.string().max(HIGHLIGHT_ITEM_MAX_LENGTH))
        .max(MAX_HIGHLIGHTS)
        .nullish(),
});
const AccommodationSchema = CommonSchema.extend({
    category: z.literal(CampCategory.accommodation),
    amenities: z
        .array(z.nativeEnum(DefaultAmenity).or(z.nativeEnum(AccommodationAmenity)))
        .nullish(),
});
const CampingSchema = CommonSchema.extend({
    category: z.literal(CampCategory.camping),
    amenities: z.array(z.nativeEnum(DefaultAmenity)).nullish(),
});
export const ListingFacilitiesSchema = z.discriminatedUnion('category', [
    AccommodationSchema,
    CampingSchema,
]);
export const getListingFacilitiesSchema = (intl) => {
    z.setErrorMap(localizedZodCustomErrorMap(intl, 'ListingSchemaError'));
    return ListingFacilitiesSchema;
};
export const getListingFacilitiesDefaultValues = (attributes) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17, _18, _19, _20, _21, _22, _23, _24, _25, _26, _27;
    return ({
        category: (_a = attributes === null || attributes === void 0 ? void 0 : attributes.publicData) === null || _a === void 0 ? void 0 : _a.category,
        summerActivities: (_e = (_d = (_c = (_b = attributes === null || attributes === void 0 ? void 0 : attributes.publicData) === null || _b === void 0 ? void 0 : _b.localizedPublicData) === null || _c === void 0 ? void 0 : _c.seasons) === null || _d === void 0 ? void 0 : _d.summer) === null || _e === void 0 ? void 0 : _e.activities,
        summerActivitiesDescription: (_j = (_h = (_g = (_f = attributes === null || attributes === void 0 ? void 0 : attributes.publicData) === null || _f === void 0 ? void 0 : _f.localizedPublicData) === null || _g === void 0 ? void 0 : _g.seasons) === null || _h === void 0 ? void 0 : _h.summer) === null || _j === void 0 ? void 0 : _j.activitiesDescription,
        environment: (_k = attributes === null || attributes === void 0 ? void 0 : attributes.publicData) === null || _k === void 0 ? void 0 : _k.environment,
        environmentDescription: (_m = (_l = attributes === null || attributes === void 0 ? void 0 : attributes.publicData) === null || _l === void 0 ? void 0 : _l.localizedPublicData) === null || _m === void 0 ? void 0 : _m.environmentDescription,
        toilet: {
            available: !!((_o = attributes === null || attributes === void 0 ? void 0 : attributes.publicData) === null || _o === void 0 ? void 0 : _o.toiletAvailable),
            feature: (_p = attributes === null || attributes === void 0 ? void 0 : attributes.publicData) === null || _p === void 0 ? void 0 : _p.toiletType,
        },
        fireplace: {
            available: !!((_q = attributes === null || attributes === void 0 ? void 0 : attributes.publicData) === null || _q === void 0 ? void 0 : _q.openFireAllowed),
            feature: ((_t = (_s = (_r = attributes === null || attributes === void 0 ? void 0 : attributes.publicData) === null || _r === void 0 ? void 0 : _r.fireplaceType) === null || _s === void 0 ? void 0 : _s.filter(element => Object.values(FireplaceType).includes(element))) !== null && _t !== void 0 ? _t : []),
            pricing: {
                payment: (_v = (_u = attributes === null || attributes === void 0 ? void 0 : attributes.publicData) === null || _u === void 0 ? void 0 : _u.fireplaceType) === null || _v === void 0 ? void 0 : _v.filter(element => Object.values(FirewoodPayment).includes(element))[0],
                price: new Money((_y = (_x = (_w = attributes === null || attributes === void 0 ? void 0 : attributes.publicData) === null || _w === void 0 ? void 0 : _w.pricing) === null || _x === void 0 ? void 0 : _x.firewood) !== null && _y !== void 0 ? _y : 0, (_0 = (_z = attributes === null || attributes === void 0 ? void 0 : attributes.publicData) === null || _z === void 0 ? void 0 : _z.currency) !== null && _0 !== void 0 ? _0 : MARKETPLACE_CURRENCY),
            },
        },
        shower: {
            available: !!((_1 = attributes === null || attributes === void 0 ? void 0 : attributes.publicData) === null || _1 === void 0 ? void 0 : _1.showerAvailable),
            feature: (_2 = attributes === null || attributes === void 0 ? void 0 : attributes.publicData) === null || _2 === void 0 ? void 0 : _2.showerType,
        },
        waterAvailable: (_3 = attributes === null || attributes === void 0 ? void 0 : attributes.publicData) === null || _3 === void 0 ? void 0 : _3.waterAvailable,
        powerSupply: {
            available: !!((_4 = attributes === null || attributes === void 0 ? void 0 : attributes.publicData) === null || _4 === void 0 ? void 0 : _4.powerSupplyAvailable),
            feature: ((_7 = (_6 = (_5 = attributes === null || attributes === void 0 ? void 0 : attributes.publicData) === null || _5 === void 0 ? void 0 : _5.powerSupplyType) === null || _6 === void 0 ? void 0 : _6.filter(element => Object.values(PowerSupplyFeature).includes(element))) !== null && _7 !== void 0 ? _7 : []),
            pricing: {
                payment: (_9 = (_8 = attributes === null || attributes === void 0 ? void 0 : attributes.publicData) === null || _8 === void 0 ? void 0 : _8.powerSupplyType) === null || _9 === void 0 ? void 0 : _9.filter(element => Object.values(PowerSupplyPayment).includes(element))[0],
                price: new Money((_12 = (_11 = (_10 = attributes === null || attributes === void 0 ? void 0 : attributes.publicData) === null || _10 === void 0 ? void 0 : _10.pricing) === null || _11 === void 0 ? void 0 : _11.powerSupply) !== null && _12 !== void 0 ? _12 : 0, ((_14 = (_13 = attributes === null || attributes === void 0 ? void 0 : attributes.publicData) === null || _13 === void 0 ? void 0 : _13.currency) !== null && _14 !== void 0 ? _14 : MARKETPLACE_CURRENCY)),
            },
        },
        amenities: (_15 = attributes === null || attributes === void 0 ? void 0 : attributes.publicData) === null || _15 === void 0 ? void 0 : _15.amenities,
        amenitiesDescription: (_17 = (_16 = attributes === null || attributes === void 0 ? void 0 : attributes.publicData) === null || _16 === void 0 ? void 0 : _16.localizedPublicData) === null || _17 === void 0 ? void 0 : _17.amenitiesDescription,
        cateringType: (_18 = attributes === null || attributes === void 0 ? void 0 : attributes.publicData) === null || _18 === void 0 ? void 0 : _18.cateringType,
        cateringDescription: (_20 = (_19 = attributes === null || attributes === void 0 ? void 0 : attributes.publicData) === null || _19 === void 0 ? void 0 : _19.localizedPublicData) === null || _20 === void 0 ? void 0 : _20.cateringDescription,
        storeProducts: (_22 = (_21 = attributes === null || attributes === void 0 ? void 0 : attributes.publicData) === null || _21 === void 0 ? void 0 : _21.localizedPublicData) === null || _22 === void 0 ? void 0 : _22.storeProducts,
        familyFriendly: !!((_23 = attributes === null || attributes === void 0 ? void 0 : attributes.publicData) === null || _23 === void 0 ? void 0 : _23.familyFriendly),
        faq: (_25 = (_24 = attributes === null || attributes === void 0 ? void 0 : attributes.publicData) === null || _24 === void 0 ? void 0 : _24.localizedPublicData) === null || _25 === void 0 ? void 0 : _25.faq,
        highlights: (_27 = (_26 = attributes === null || attributes === void 0 ? void 0 : attributes.publicData) === null || _26 === void 0 ? void 0 : _26.localizedPublicData) === null || _27 === void 0 ? void 0 : _27.highlights,
    });
};
