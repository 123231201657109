import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components/macro';
import Icon, { IconSizeVariant, IconVariant } from '../Icon/Icon';
import { ColorVariant } from '../../theme';
import { useIntl } from 'react-intl';
import Text, { TextVariant } from '../Text/Text';
import { useDispatch, useSelector } from 'react-redux';
import { setMenuOpen } from '../../slices/UISlice';
import Avatar, { SizeVariant } from '../Avatar/Avatar';
import SearchBarSheetVariant from '../SearchBarSheet/SearchBarSheetVariant';
import { openSearchBarSheet } from '../../slices/listingSearchSlice';
import { useHistory } from 'react-router-dom';
import { shouldShowBackButton } from '../TopBar/utils';
import { useFilterSummary } from './utils';
export var MobileSearchAndNavigationBarVariant;
(function (MobileSearchAndNavigationBarVariant) {
    MobileSearchAndNavigationBarVariant["Standalone"] = "Standalone";
    MobileSearchAndNavigationBarVariant["Navigation"] = "Navigation";
})(MobileSearchAndNavigationBarVariant || (MobileSearchAndNavigationBarVariant = {}));
const Wrapper = styled.div `
  height: 56px;
  display: flex;
  min-width: 0;
  align-items: center;
  width: 100%;
  border-radius: 16px;
  overflow: hidden;
  transition: background-color 0.25s linear, color 0.25s linear;
  ${({ variant }) => variant !== MobileSearchAndNavigationBarVariant.Standalone
    ? css `
          padding-right: 8px;
        `
    : css `
          padding-right: 0;
        `}
  ${({ transparent, theme }) => transparent
    ? css `
          background-color: ${theme.color.backgroundBlur};
          backdrop-filter: blur(2px);
        `
    : css `
          background-color: ${theme.color.basicPearlWhiteTypeWhite};
        `}
`;
const LeftIconWrapper = styled.div `
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 56px;
  width: 40px;
`;
const FilterIconWrapper = styled.div `
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 56px;
  width: 40px;
`;
const SearchButtonWrapper = styled.div `
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 56px;
  width: 56px;
  border-radius: 16px;
  background-color: ${({ theme }) => theme.color.functionSuccess3};
`;
const TextsWrapper = styled.div `
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  overflow: hidden;
  min-width: 0;
  padding-right: 8px;
`;
const SingleLineText = styled(Text) `
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
`;
const AvatarWrapper = styled.div `
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 56px;
  padding-left: 4px;
`;
const MobileSearchAndNavigationBar = ({ variant, }) => {
    var _a, _b, _c;
    const intl = useIntl();
    const [transparent, setTransparent] = useState(false);
    const [lastScrollY, setLastScrollY] = useState(0);
    useEffect(() => {
        if (variant === MobileSearchAndNavigationBarVariant.Navigation) {
            const handleScroll = () => {
                const currentScrollY = window.scrollY;
                const isScrollingDown = currentScrollY > lastScrollY;
                setTransparent(isScrollingDown);
                setLastScrollY(currentScrollY);
            };
            window.addEventListener('scroll', handleScroll);
            return () => {
                window.removeEventListener('scroll', handleScroll);
            };
        }
    }, [variant, lastScrollY]);
    const contentColor = transparent
        ? ColorVariant.BasicPearlWhiteTypeWhite
        : ColorVariant.BasicCaveBlack;
    const history = useHistory();
    const showBackButton = shouldShowBackButton(history.location.pathname);
    const getLeftIcon = () => {
        if (showBackButton) {
            return IconVariant.Arrow_M_Left;
        }
        else if (variant === MobileSearchAndNavigationBarVariant.Standalone) {
            return IconVariant.LocationPin;
        }
        else {
            return IconVariant.NomadyLogoLeafOnly;
        }
    };
    const leftIcon = getLeftIcon();
    const getLeftIconSizeVariant = () => {
        if (leftIcon === IconVariant.LocationPin) {
            return IconSizeVariant.Medium;
        }
        else if (leftIcon === IconVariant.NomadyLogoLeafOnly) {
            return IconSizeVariant.ExtraSmall;
        }
        else {
            return IconSizeVariant.Small;
        }
    };
    const leftIconSizeVariant = getLeftIconSizeVariant();
    const { title, subtitle } = useFilterSummary();
    const dispatch = useDispatch();
    const { currentUser, currentProviderNotificationCount } = useSelector((state) => state.user);
    const userInitials = (_a = currentUser === null || currentUser === void 0 ? void 0 : currentUser.attributes) === null || _a === void 0 ? void 0 : _a.profile.abbreviatedName;
    const userAvatar = (_c = (_b = currentUser === null || currentUser === void 0 ? void 0 : currentUser.profileImage) === null || _b === void 0 ? void 0 : _b.attributes) === null || _c === void 0 ? void 0 : _c.variants['square-small'].url;
    const onClickSearch = () => dispatch(openSearchBarSheet(SearchBarSheetVariant.Location));
    const onClickFilter = () => dispatch(openSearchBarSheet(SearchBarSheetVariant.Features));
    return (_jsxs(Wrapper, Object.assign({ variant: variant, transparent: transparent }, { children: [_jsx(LeftIconWrapper, Object.assign({ onClick: leftIcon === IconVariant.Arrow_M_Left ? history.goBack : onClickSearch }, { children: _jsx(Icon, { variant: leftIcon, colorVariant: contentColor, sizeVariant: leftIconSizeVariant }) })), _jsxs(TextsWrapper, Object.assign({ onClick: onClickSearch }, { children: [_jsx(SingleLineText, Object.assign({ variant: subtitle ? TextVariant.L2SB : TextVariant.L1SB, colorVariant: contentColor }, { children: title !== null && title !== void 0 ? title : intl.formatMessage({ id: 'SearchBarSheet.Location.title' }) })), subtitle && (_jsx(SingleLineText, Object.assign({ variant: TextVariant.L4R, colorVariant: contentColor === ColorVariant.BasicCaveBlack
                            ? ColorVariant.FunctionStoneGreyInactive
                            : contentColor }, { children: subtitle })))] })), _jsx(FilterIconWrapper, Object.assign({ onClick: onClickFilter }, { children: _jsx(Icon, { variant: IconVariant.Filter, colorVariant: contentColor, sizeVariant: IconSizeVariant.Medium }) })), variant === MobileSearchAndNavigationBarVariant.Standalone && (_jsx(SearchButtonWrapper, Object.assign({ onClick: onClickSearch }, { children: _jsx(Icon, { variant: IconVariant.Search, colorVariant: ColorVariant.BasicPearlWhiteTypeWhite, sizeVariant: IconSizeVariant.Medium }) }))), variant === MobileSearchAndNavigationBarVariant.Navigation && (_jsx(AvatarWrapper, Object.assign({ onClick: () => {
                    dispatch(setMenuOpen(true));
                } }, { children: _jsx(Avatar, { sizeVariant: SizeVariant.XXS, initials: userInitials || '', source: userAvatar || '', notificationCount: currentProviderNotificationCount }) })))] })));
};
export default MobileSearchAndNavigationBar;
