// https://www.npmjs.com/package/language-name-map
// prettier-ignore
export const languageNativeCodes = [
  {
    code: 'en',
    name: 'English',
    dir: 1,
    native: 'English',
  },
  {
    code: 'de',
    name: 'German',
    dir: 1,
    native: 'Deutsch',
  },
  {
    code: 'nl',
    name: 'Dutch',
    dir: 1,
    native: 'Nederlands',
  },
  {
    code: 'fr',
    name: 'French',
    dir: 1,
    native: 'Français',
  },
  {
    code: 'es',
    name: 'Spanish',
    dir: 1,
    native: 'Español',
  },
  {
    code: 'ca',
    name: 'Catalan',
    dir: 1,
    native: 'Català',
  },
  {
    code: 'it',
    name: 'Italian',
    dir: 1,
    native: 'Italiano',
  },
  {
    code: 'pt',
    name: 'Portuguese',
    dir: 1,
    native: 'Português',
  },
  {
    code: 'no',
    name: 'Norwegian',
    dir: 1,
    native: 'Norsk',
  },
  {
    code: 'fi',
    name: 'Finnish',
    dir: 1,
    native: 'Suomi',
  },
  {
    code: 'sv',
    name: 'Swedish',
    dir: 1,
    native: 'Svenska',
  },
  {
    code: 'da',
    name: 'Danish',
    dir: 1,
    native: 'Dansk',
  },
  {
    code: 'cs',
    name: 'Czech',
    dir: 1,
    native: 'Česky',
  },
  {
    code: 'hu',
    name: 'Hungarian',
    dir: 1,
    native: 'Magyar',
  },
  {
    code: 'ro',
    name: 'Romanian',
    dir: 1,
    native: 'Română',
  },
  {
    code: 'ja',
    name: 'Japanese',
    dir: 1,
    native: '日本語',
  },

  {
    code: 'zh',
    name: 'Chinese',
    dir: 1,
    native: '中文',
  },
  {
    code: 'pl',
    name: 'Polish',
    dir: 1,
    native: 'Polski',
  },
  {
    code: 'el',
    name: 'Greek',
    dir: 1,
    native: 'Ελληνικά',
  },
  {
    code: 'ru',
    name: 'Russian',
    dir: 1,
    native: 'Русский',
  },
  {
    code: 'tr',
    name: 'Turkish',
    dir: 1,
    native: 'Türkçe',
  },
  {
    code: 'bg',
    name: 'Bulgarian',
    dir: 1,
    native: 'Български',
  },
  {
    code: 'ar',
    name: 'Arabic',
    dir: 0,
    native: 'العربية',
  },
  {
    code: 'ko',
    name: 'Korean',
    dir: 1,
    native: '한국어',
  },
  {
    code: 'he',
    name: 'Hebrew',
    dir: 0,
    native: 'עברית',
  },
  {
    code: 'lv',
    name: 'Latvian',
    dir: 1,
    native: 'Latviešu',
  },
  {
    code: 'uk',
    name: 'Ukrainian',
    dir: 1,
    native: 'Українська',
  },
  {
    code: 'id',
    name: 'Indonesian',
    dir: 1,
    native: 'Bahasa Indonesia',
  },
  {
    code: 'mg',
    name: 'Malagasy',
    dir: 1,
    native: 'Malagasy',
  },
  {
    code: 'et',
    name: 'Estonian',
    dir: 1,
    native: 'Eesti',
  },
  {
    code: 'hr',
    name: 'Croatian',
    dir: 1,
    native: 'Hrvatski',
  },
  {
    code: 'lt',
    name: 'Lithuanian',
    dir: 1,
    native: 'Lietuvių',
  },
  {
    code: 'sk',
    name: 'Slovak',
    dir: 1,
    native: 'Slovenčina',
  },
  {
    code: 'sr',
    name: 'Serbian',
    dir: 1,
    native: 'Српски',
  },
  {
    code: 'sl',
    name: 'Slovenian',
    dir: 1,
    native: 'Slovenščina',
  },
  {
    code: 'is',
    name: 'Icelandic',
    dir: 1,
    native: 'Íslenska',
  },
  {
    code: 'uk',
    name: 'Ukrainian',
    dir: 1,
    native: 'Українська',
  },
];

export const getLanguageNativeFromCode = langCode => {
  if (langCode) {
    const result = languageNativeCodes.filter(item => item.code === langCode);
    if (result && result[0]) {
      return result[0].native;
    }
  }

  return '';
};
