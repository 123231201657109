import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components/macro';
import { FormattedMessage } from '../../util/reactIntl';
import Button, { ButtonColorVariant, ButtonSizeVariant, } from '../../styled-components/Button/Button';
import Icon, { IconVariant } from '../Icon/Icon';
import Text, { TextVariant } from '../Text/Text';
import { ColorVariant } from '../../theme';
export var StripeConnectAccountStatus;
(function (StripeConnectAccountStatus) {
    StripeConnectAccountStatus["STATUS_VERIFICATION_NEEDED"] = "verificationNeeded";
    StripeConnectAccountStatus["STATUS_VERIFICATION_SUCCESS"] = "verificationSuccess";
    StripeConnectAccountStatus["STATUS_VERIFICATION_ERROR"] = "verificationError";
})(StripeConnectAccountStatus || (StripeConnectAccountStatus = {}));
const WrapperBaseStyles = css `
  border: 1px solid;
  border-radius: 8px;
  padding: 16px 32px;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
`;
const WrapperWarning = styled.div `
  ${WrapperBaseStyles}

  border-color:  ${props => props.theme.color.carrot};
`;
const WrapperSuccess = styled.div `
  ${WrapperBaseStyles}

  border-color:  ${props => props.theme.color.moss};
`;
const WrapperError = styled.div `
  ${WrapperBaseStyles}

  border-color:  ${props => props.theme.color.cinnabar};
`;
const StatusWithIcon = styled.div `
  display: grid;
  grid-auto-flow: column;
  column-gap: 8px;
  width: fit-content;
`;
const StatusWithText = styled.div `
  display: grid;
  grid-auto-flow: row;
  row-gap: 8px;
`;
const StripeConnectAccountStatusBox = props => {
    const { status, onGetStripeConnectAccountLink, inProgress, disabled } = props;
    const intl = useIntl();
    if (status === StripeConnectAccountStatus.STATUS_VERIFICATION_NEEDED) {
        return (_jsxs(WrapperWarning, { children: [_jsxs(StatusWithText, { children: [_jsx(Text, Object.assign({ variant: TextVariant.B40, as: "h4", colorVariant: ColorVariant.Carrot }, { children: _jsx(FormattedMessage, { id: "StripeConnectAccountStatusBox.verificationNeededTitle" }) })), _jsx(Text, Object.assign({ variant: TextVariant.B30 }, { children: _jsx(FormattedMessage, { id: "StripeConnectAccountStatusBox.verificationNeededText" }) }))] }), _jsx(Button, { type: "button", inProgress: inProgress, disabled: disabled, onClick: onGetStripeConnectAccountLink, sizeVariant: ButtonSizeVariant.Small, colorVariant: ButtonColorVariant.Fir, label: intl.formatMessage({
                        id: 'StripeConnectAccountStatusBox.getVerifiedButton',
                    }) })] }));
    }
    if (status === StripeConnectAccountStatus.STATUS_VERIFICATION_SUCCESS) {
        return (_jsxs(WrapperSuccess, { children: [_jsxs(StatusWithIcon, { children: [_jsx(Icon, { variant: IconVariant.BubbleCheckmark }), _jsx(Text, Object.assign({ variant: TextVariant.B30 }, { children: _jsx(FormattedMessage, { id: "StripeConnectAccountStatusBox.verificationSuccessTitle" }) }))] }), _jsx(Button, { icon: IconVariant.Pen, sizeVariant: ButtonSizeVariant.Small, colorVariant: ButtonColorVariant.Fir, type: "button", inProgress: inProgress, disabled: disabled, onClick: onGetStripeConnectAccountLink, label: intl.formatMessage({
                        id: 'StripeConnectAccountStatusBox.editAccountButton',
                    }) })] }));
    }
    if (status === StripeConnectAccountStatus.STATUS_VERIFICATION_ERROR) {
        return (_jsxs(WrapperError, { children: [_jsxs(StatusWithText, { children: [_jsx(Text, Object.assign({ variant: TextVariant.B40, as: "h4", colorVariant: ColorVariant.Cinnabar }, { children: _jsx(FormattedMessage, { id: "StripeConnectAccountStatusBox.verificationFailedTitle" }) })), _jsx(Text, Object.assign({ variant: TextVariant.B30 }, { children: _jsx(FormattedMessage, { id: "StripeConnectAccountStatusBox.verificationFailedText" }) }))] }), _jsx(Button, { type: "button", inProgress: inProgress, disabled: disabled, onClick: onGetStripeConnectAccountLink, sizeVariant: ButtonSizeVariant.Small, colorVariant: ButtonColorVariant.Fir, label: intl.formatMessage({
                        id: 'StripeConnectAccountStatusBox.getVerifiedButton',
                    }) })] }));
    }
    throw new Error(`Unknown status ${status} for StripeConnectAccountStatusBox`);
};
export default StripeConnectAccountStatusBox;
