import styled, { css } from 'styled-components/macro';
export var TextVariant;
(function (TextVariant) {
    /** @deprecated Please strongly prefer new typography system */
    TextVariant["H11"] = "H11";
    /** @deprecated Please strongly prefer new typography system */
    TextVariant["H12"] = "H12";
    /** @deprecated Please strongly prefer new typography system */
    TextVariant["H1"] = "H1";
    /** @deprecated Please strongly prefer new typography system */
    TextVariant["H2"] = "H2";
    /** @deprecated Please strongly prefer new typography system */
    TextVariant["H3"] = "H3";
    /** @deprecated Please strongly prefer new typography system */
    TextVariant["H4"] = "H4";
    /** @deprecated Please strongly prefer new typography system */
    TextVariant["H5"] = "H5";
    /** @deprecated Please strongly prefer new typography system */
    TextVariant["H6"] = "H6";
    /** @deprecated Please strongly prefer new typography system */
    TextVariant["H7"] = "H7";
    /** @deprecated Please strongly prefer new typography system */
    TextVariant["H8"] = "H8";
    /** @deprecated Please strongly prefer new typography system */
    TextVariant["H8L"] = "H8L";
    /** @deprecated Please strongly prefer new typography system */
    TextVariant["H9"] = "H9";
    /** @deprecated Please strongly prefer new typography system */
    TextVariant["H10"] = "H10";
    /** @deprecated Please strongly prefer new typography system */
    TextVariant["H10B"] = "H10B";
    /** @deprecated Please strongly prefer new typography system */
    TextVariant["H10M"] = "H10M";
    /** @deprecated Please strongly prefer new typography system */
    TextVariant["H11B"] = "H11B";
    /** @deprecated Please strongly prefer new typography system */
    TextVariant["H12B"] = "H12B";
    /** @deprecated Please strongly prefer new typography system */
    TextVariant["H13"] = "H13";
    /** @deprecated Please strongly prefer new typography system */
    TextVariant["H14"] = "H14";
    /** @deprecated Please strongly prefer new typography system */
    TextVariant["H20"] = "H20";
    /** @deprecated Please strongly prefer new typography system */
    TextVariant["H30"] = "H30";
    /** @deprecated Please strongly prefer new typography system */
    TextVariant["H50"] = "H50";
    /** @deprecated Please strongly prefer new typography system */
    TextVariant["H51"] = "H51";
    /** @deprecated Please strongly prefer new typography system */
    TextVariant["H52"] = "H52";
    /** @deprecated Please strongly prefer new typography system */
    TextVariant["B20"] = "B20";
    /** @deprecated Please strongly prefer new typography system */
    TextVariant["B30"] = "B30";
    /** @deprecated Please strongly prefer new typography system */
    TextVariant["B40"] = "B40";
    /** @deprecated Please strongly prefer new typography system */
    TextVariant["B50"] = "B50";
    /** @deprecated Please strongly prefer new typography system */
    TextVariant["B60"] = "B60";
    /** @deprecated Please strongly prefer new typography system */
    TextVariant["NotificationBadge"] = "NotificationBadge";
    // NEW TYPOGRAPHY
    TextVariant["L1R"] = "L1R";
    TextVariant["L1SB"] = "L1SB";
    TextVariant["L2R"] = "L2R";
    TextVariant["L2SB"] = "L2SB";
    TextVariant["L3R"] = "L3R";
    TextVariant["L3SB"] = "L3SB";
    TextVariant["L4R"] = "L4R";
    TextVariant["L4SB"] = "L4SB";
    TextVariant["D1R"] = "D1R";
    TextVariant["D2R"] = "D2R";
    TextVariant["H1SB"] = "H1SB";
    TextVariant["H2SB"] = "H2SB";
    TextVariant["H3M"] = "H3M";
    TextVariant["H4M"] = "H4M";
    TextVariant["H5SB"] = "H5SB";
    TextVariant["P1M"] = "P1M";
    TextVariant["P2R"] = "P2R";
    TextVariant["P3R"] = "P3R";
    TextVariant["P4SB"] = "P4SB";
    TextVariant["P4R"] = "P4R";
    TextVariant["S1R"] = "S1R";
})(TextVariant || (TextVariant = {}));
const Text = styled.span `
  margin: 0;
  ${props => props.theme.typeset[props.variant ? props.variant : TextVariant.H8]}

  ${props => props.colorVariant && `color: ${props.theme.color[props.colorVariant]};`}

  ${props => props.truncateWithEllipsis &&
    css `
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `}
`;
export default Text;
