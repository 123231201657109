import cloneDeep from 'lodash/cloneDeep';
import isObject from 'lodash/isObject';
export default function merge(target, source) {
    const sourceCopy = cloneDeep(source);
    Object.keys(sourceCopy).forEach(key => {
        if (isObject(sourceCopy[key]))
            Object.assign(sourceCopy[key], merge(target[key], source[key]));
    });
    Object.assign(target || {}, sourceCopy);
    return target;
}
