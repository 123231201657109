import React, { Component } from 'react';
import { bool, func, object, shape, string } from 'prop-types';
import classNames from 'classnames';
import { ValidationError, ExpandingTextarea } from '../index.ts';
import Tooltip from '../../styled-components/Tooltip/Tooltip.tsx';
import css from './FieldTextInput.module.scss';

const CONTENT_MAX_LENGTH = 5000;

class FieldTextInputComponent extends Component {
  constructor(props) {
    super(props);

    this.inputRef = React.createRef();
  }

  componentDidMount() {
    if (this.props.input.type === 'number' && this.inputRef) {
      const { inputRef } = this;
      this.inputRef.current.addEventListener('mousewheel', () => {
        inputRef.current.blur();
      });
    }
  }

  render() {
    /* eslint-disable no-unused-vars */
    const {
      rootClassName,
      className,
      inputRootClass,
      customErrorText,
      id,
      label,
      tooltip,
      input,
      meta,
      isUncontrolled,
      inputRef,
      required,
      minLength,
      maxLength,
      ...rest
    } = this.props;
    /* eslint-enable no-unused-vars */

    if (label && !id) {
      throw new Error('id required when a label is given');
    }

    const { valid, invalid, touched, error } = meta;
    const isTextarea = input.type === 'textarea';

    const errorText = customErrorText || error;

    // Error message and input error styles are only shown if the
    // field has been touched and the validation has failed.
    const hasError = !!customErrorText || !!(touched && invalid && error);

    const fieldMeta = { touched: hasError, error: errorText };

    // Textarea doesn't need type.
    const { type, ...inputWithoutType } = input;
    // Uncontrolled input uses defaultValue instead of value.
    const { value: defaultValue, ...inputWithoutValue } = input;
    // Use inputRef if it is passed as prop.
    const refMaybe = inputRef ? { ref: inputRef } : {};

    const inputClasses =
      inputRootClass ||
      classNames(css.input, {
        [css.inputSuccess]: valid,
        [css.inputError]: hasError,
      });
    const maxContentLength = CONTENT_MAX_LENGTH;

    const uncontrolledInputProps = isUncontrolled
      ? {
          className: inputClasses,
          id,
          type,
          defaultValue,
          maxLength,
          minLength,
          ...refMaybe,
          ...inputWithoutValue,
          ...rest,
        }
      : {
          className: inputClasses,
          id,
          type,
          maxLength,
          ...refMaybe,
          ...input,
          ...rest,
        };

    const inputProps = isTextarea
      ? {
          className: inputClasses,
          id,
          rows: 1,
          maxLength: maxContentLength,
          ...refMaybe,
          ...inputWithoutType,
          ...rest,
        }
      : uncontrolledInputProps;

    const classes = classNames(rootClassName, className);

    const infoBubble = tooltip ? <Tooltip text={tooltip} /> : null;

    return (
      <div className={classes}>
        {label ? (
          <label htmlFor={id}>
            {required ? '* ' : null}
            {label} {infoBubble}
          </label>
        ) : null}
        {isTextarea ? (
          <ExpandingTextarea {...inputProps} />
        ) : (
          <input {...inputProps} ref={this.inputRef} />
        )}
        <ValidationError fieldMeta={fieldMeta} />
      </div>
    );
  }
}

FieldTextInputComponent.defaultProps = {
  rootClassName: null,
  className: null,
  inputRootClass: null,
  customErrorText: null,
  id: null,
  label: null,
  tooltip: null,
  isUncontrolled: false,
  inputRef: null,
};

FieldTextInputComponent.propTypes = {
  rootClassName: string,
  className: string,
  inputRootClass: string,

  // Error message that can be manually passed to input field,
  // overrides default validation message
  customErrorText: string,

  // Label is optional, but if it is given, an id is also required so
  // the label can reference the input in the `for` attribute
  id: string,
  label: string,
  tooltip: string,

  // Uncontrolled input uses defaultValue prop, but doesn't pass value from form to the field.
  // https://reactjs.org/docs/uncontrolled-components.html#default-values
  isUncontrolled: bool,
  // a ref object passed for input element.
  inputRef: object,

  // Generated by final-form's Field component
  input: shape({
    onChange: func.isRequired,
    // Either 'textarea' or something that is passed to the input element
    type: string.isRequired,
  }).isRequired,
  meta: object.isRequired,
};

export default FieldTextInputComponent;
