import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import styled from 'styled-components/macro';
import { ColorVariant } from '../../theme';
import Text from '../Text/Text';
const StyledText = styled(Text) `
  display: inline-block;
  padding: 0px 4px;
  border-radius: 5px;
  border: 1px solid
    ${props => props.theme.color[props.borderColor || 'transparent']};
  background-color: ${props => props.theme.color[props.backgroundColor || 'transparent']};
  height: fit-content;
  font-weight: ${props => props.fontWeight || 'normal'};
`;
const Tag = ({ label, borderColor = ColorVariant.Transparent, backgroundColor = ColorVariant.Transparent, textColor, textVariant, fontWeight = 'normal', }) => {
    return (_jsx(StyledText, Object.assign({ variant: textVariant, colorVariant: textColor, fontWeight: fontWeight, backgroundColor: backgroundColor, borderColor: borderColor }, { children: label })));
};
export default Tag;
