import moment from 'moment';
import { DayVariant } from '../CalendarDay/CalendarDay';
const calendarDayEqualsDay = (calendarDay, day) => (day === null || day === void 0 ? void 0 : day.isSame(calendarDay.date, 'day')) || false;
const calendarDayIsBeforeDay = (calendarDay, day) => calendarDay.date.isBefore(day, 'day') || false;
const calendarDayIsNewHoveredStartDay = (calendarDay, currentStartDay, currentEndDay, intermediateStart, intermediateEnd) => {
    const isIntermediateStart = intermediateStart === null || intermediateStart === void 0 ? void 0 : intermediateStart.date.isSame(calendarDay.date, 'day');
    return (!currentStartDay &&
        !currentEndDay &&
        isIntermediateStart &&
        !intermediateEnd);
};
const calendarDayIsCurrentStartDay = (isStartDay, isEndDaySelected, intermediateEnd, startDay) => startDay && !isEndDaySelected && isStartDay && !intermediateEnd;
const calendarDayIsBeforeCurrentStartDayWithoutPreviousRange = (calendarDay, isEndDaySelected, startDay, intermediateStart) => startDay &&
    !isEndDaySelected &&
    intermediateStart &&
    (intermediateStart === null || intermediateStart === void 0 ? void 0 : intermediateStart.date.isBefore(startDay, 'day')) &&
    calendarDay.date.isSame(intermediateStart.date, 'day');
const calendarDayIsHoveredForNewRange = (calendarDay, startDay, endDay, intermediateStart) => startDay &&
    endDay &&
    intermediateStart &&
    !(intermediateStart === null || intermediateStart === void 0 ? void 0 : intermediateStart.date.isBetween(startDay, endDay, 'day', '[]')) &&
    calendarDay.date.isSame(intermediateStart.date, 'day');
const calendarDayIsHoveredEndWithSelectedStartDay = (isStartDay, isEndDaySelected, intermediateEnd) => isStartDay && !isEndDaySelected && intermediateEnd;
const calendarDayIsBetweenSelectedStartDayAndHoveredEndDay = (calendarDay, isEndDaySelected, startDay, intermediateEnd) => startDay &&
    !isEndDaySelected &&
    intermediateEnd &&
    calendarDay.date.isBetween(startDay, intermediateEnd.date, 'day') &&
    startDay.isBefore(calendarDay.date, 'day');
const calendarDayIsHoveredEndDay = (calendarDay, startDay, intermediateEnd) => startDay && (intermediateEnd === null || intermediateEnd === void 0 ? void 0 : intermediateEnd.date.isSame(calendarDay.date, 'day'));
const calendarDayIsBetweenSelectedStartAndEndDay = (calendarDay, startDay, endDay) => startDay && endDay && calendarDay.date.isBetween(startDay, endDay, 'day');
const getMonthData = (timeSlots, monthData, totalSeats, requestedSeats, intermediateStart, intermediateEnd, startDay, endDay) => {
    let hasReachedLatestPossibleEndDate = false;
    return monthData.map((month, monthDataIndex) => month.map((calendarDay, calendarDayIndex) => {
        var _a;
        const today = moment();
        const isDayInPast = calendarDayIsBeforeDay(calendarDay, today);
        if (isDayInPast) {
            return Object.assign(Object.assign({}, calendarDay), { variant: DayVariant.BOOKING_IN_THE_PAST });
        }
        const availableSeats = (_a = timeSlots === null || timeSlots === void 0 ? void 0 : timeSlots.find(slot => moment(slot.attributes.start).utc().isSame(calendarDay.date.utc()))) === null || _a === void 0 ? void 0 : _a.attributes.seats;
        const hasAvailableSeats = requestedSeats
            ? requestedSeats <= (availableSeats !== null && availableSeats !== void 0 ? availableSeats : 0)
            : true;
        const isLatestPossibleEndDate = !!startDay &&
            !endDay &&
            calendarDay.date.isAfter(startDay, 'day') &&
            !hasAvailableSeats &&
            !hasReachedLatestPossibleEndDate;
        if (isLatestPossibleEndDate) {
            hasReachedLatestPossibleEndDate = true;
        }
        if (hasReachedLatestPossibleEndDate && !isLatestPossibleEndDate) {
            return Object.assign(Object.assign({}, calendarDay), { variant: DayVariant.UNAVAILABLE });
        }
        const isEndDaySelected = !!endDay;
        const isStartDay = calendarDayEqualsDay(calendarDay, startDay);
        const isEndDay = calendarDayEqualsDay(calendarDay, endDay);
        const hoverNewStartWithoutPreviousRange = calendarDayIsNewHoveredStartDay(calendarDay, startDay, endDay, intermediateStart, intermediateEnd);
        const isCurrentStartDay = calendarDayIsCurrentStartDay(isStartDay, isEndDaySelected, intermediateEnd, startDay);
        const hoverBeforeCurrentStartDayWithoutPreviousRange = calendarDayIsBeforeCurrentStartDayWithoutPreviousRange(calendarDay, isEndDaySelected, startDay, intermediateStart);
        const hoverWithPreviousRange = calendarDayIsHoveredForNewRange(calendarDay, startDay, endDay, intermediateStart);
        if (hoverNewStartWithoutPreviousRange ||
            isCurrentStartDay ||
            hoverBeforeCurrentStartDayWithoutPreviousRange ||
            hoverWithPreviousRange) {
            return Object.assign(Object.assign({}, calendarDay), { variant: DayVariant.BOOKING_RANGE_START_ONLY });
        }
        const isSelectedStartDayAndHoveringEndDay = calendarDayIsHoveredEndWithSelectedStartDay(isStartDay, isEndDaySelected, intermediateEnd);
        if (isSelectedStartDayAndHoveringEndDay) {
            return Object.assign(Object.assign({}, calendarDay), { variant: DayVariant.BOOKING_INTERMEDIATE_RANGE_START });
        }
        const isBetweenSelectedStartDayAndHoveredEndDay = calendarDayIsBetweenSelectedStartDayAndHoveredEndDay(calendarDay, isEndDaySelected, startDay, intermediateEnd);
        if (isBetweenSelectedStartDayAndHoveredEndDay) {
            return Object.assign(Object.assign({}, calendarDay), { variant: DayVariant.BOOKING_INTERMEDIATE_RANGE_IN_BETWEEN });
        }
        const isHoveredEndDay = calendarDayIsHoveredEndDay(calendarDay, startDay, intermediateEnd);
        if (isHoveredEndDay) {
            return Object.assign(Object.assign({}, calendarDay), { variant: DayVariant.BOOKING_INTERMEDIATE_RANGE_END });
        }
        const isSelectedStartDay = endDay && isStartDay;
        if (isSelectedStartDay) {
            return Object.assign(Object.assign({}, calendarDay), { variant: DayVariant.BOOKING_RANGE_START });
        }
        const isSelectedEndDay = startDay && isEndDay;
        if (isSelectedEndDay) {
            return Object.assign(Object.assign({}, calendarDay), { variant: DayVariant.BOOKING_RANGE_END });
        }
        const isBetweenSelectedStartAndEndDay = calendarDayIsBetweenSelectedStartAndEndDay(calendarDay, startDay, endDay);
        if (isBetweenSelectedStartAndEndDay) {
            return Object.assign(Object.assign({}, calendarDay), { variant: DayVariant.BOOKING_RANGE_IN_BETWEEN });
        }
        if (isLatestPossibleEndDate)
            return Object.assign(Object.assign({}, calendarDay), { variant: DayVariant.BOOKING_IN_THE_FUTURE });
        if (!hasAvailableSeats) {
            return Object.assign(Object.assign({}, calendarDay), { variant: DayVariant.UNAVAILABLE });
        }
        return Object.assign(Object.assign({}, calendarDay), { variant: DayVariant.BOOKING_IN_THE_FUTURE });
    }));
};
export default getMonthData;
