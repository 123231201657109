import styled from 'styled-components/macro';
export default styled.div `
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  grid-gap: 12px;
  row-gap: 8px;

  @media ${props => props.theme.device.tablet} {
    flex-direction: column-reverse;
  }
`;
