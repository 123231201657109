import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styled, { css } from 'styled-components/macro';
import { TabState, } from '@nomady/shared/routeConfiguration/routeConfiguration';
import { NamedLink } from '../../components';
import Icon, { IconVariant } from '../Icon/Icon';
import { ColorVariant } from '../../theme';
const Navigation = styled.nav `
  position: sticky;
`;
const LinkList = styled.ul `
  display: flex;
  flex-direction: row;
  padding: 0;
  margin-top: 0;
  margin-bottom: 60px;
  flex-shrink: 0;
  background-color: ${props => props.theme.color.pearl};
  box-shadow: ${props => props.theme.shadow.light};
  border-top: 1px solid ${props => props.theme.color.fog};
  border-bottom: 1px solid ${props => props.theme.color.fog};
  overflow-x: scroll;
  padding-left: 20px;
  padding-right: 20px;

  @media ${props => props.theme.device.desktop} {
    display: grid;
    grid-auto-flow: row;
    row-gap: 8px;
    background-color: initial;
    box-shadow: initial;
    border: none;
    overflow-x: initial;
    padding: 0;
    margin: initial;
  }
`;
const LinkItem = styled.li ``;
const linkStyles = css `
  ${props => props.theme.typeset.H8L}

  display: block;
  color: ${props => props.theme.color.cave};
  padding: 20px 10px;
  margin-top: auto;
  flex-shrink: 0;
  white-space: nowrap;

  @media ${props => props.theme.device.desktop} {
    ${props => props.theme.typeset.H11B}

    padding: 12px 16px;

    display: grid;
    grid-auto-flow: column;
    grid-template-columns: min-content 1fr;
    align-items: center;
    column-gap: 8px;
    background: white;
    border-radius: 8px;
  }
`;
const Link = styled(NamedLink) `
  ${linkStyles}

  ${props => props.active && `text-decoration: underline;`}
  
  border: 1px solid transparent;

  ${props => props.state === TabState.Invalid &&
    `color: ${props.theme.color.functionError0};`}

  @media ${props => props.theme.device.desktop} {
    text-decoration: initial;

    ${props => props.state === TabState.Invalid &&
    `border-color: ${props.theme.color.functionError0};`}

    ${props => props.active && `border-color: ${props.theme.color.indigo};`}
  }
`;
const DisabledLink = styled.span `
  ${linkStyles}

  color: ${props => props.theme.color.fog};

  cursor: not-allowed;
`;
const IconWrapper = styled.div `
  display: none;

  @media ${props => props.theme.device.desktop} {
    display: block;
  }
`;
const SideNavigation = ({ tabs }) => {
    return (_jsx(Navigation, Object.assign({ role: "secondary" }, { children: _jsx(LinkList, { children: tabs.map(tab => (_jsx(LinkItem, { children: tab.state !== TabState.Disabled ? (_jsxs(Link, Object.assign({ name: tab.page }, tab.linkProps, { active: tab.active, state: tab.state }, { children: [_jsx(IconWrapper, { children: _jsx(Icon, { variant: tab.state === undefined
                                    ? tab.icon || IconVariant.Void
                                    : IconVariant.Warning_Orange, colorVariant: tab.state === undefined
                                    ? ColorVariant.BasicCaveBlack
                                    : ColorVariant.FunctionError0 }) }), tab.title, _jsx(IconWrapper, { children: _jsx(Icon, { variant: IconVariant.Arrow_M_Right }) })] }))) : (_jsxs(DisabledLink, { children: [tab.icon && (_jsx(IconWrapper, { children: _jsx(Icon, { variant: tab.icon, colorVariant: ColorVariant.Fog }) })), tab.title] })) }, tab.intlId))) }) })));
};
export default SideNavigation;
