var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import PageContainer from './PageContainer';
import { PageLayoutContent, PageLayoutTop } from '../PageLayout/PageLayout';
import TopBar from '../TopBar/TopBar';
const MapPageContainer = (_a) => {
    var { children, hideTopBar = false } = _a, props = __rest(_a, ["children", "hideTopBar"]);
    return (_jsxs(PageContainer, Object.assign({}, props, { children: [!hideTopBar && (_jsx(PageLayoutTop, { children: _jsx(TopBar, {}) })), _jsx(PageLayoutContent, { children: children })] })));
};
export default MapPageContainer;
