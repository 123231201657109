var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { storableError } from '../../util/errors';
const initialState = {
    resetPasswordInProgress: false,
    resetPasswordError: null,
    passwordResetted: false,
};
export const PasswordResetPageSlice = createSlice({
    name: 'app/PasswordResetPage',
    initialState,
    reducers: {
        resetPasswordRequest: state => {
            state.resetPasswordInProgress = true;
            state.resetPasswordError = null;
        },
        resetPasswordSuccess: state => {
            state.resetPasswordInProgress = false;
            state.passwordResetted = true;
        },
        resetPasswordError: (state, action) => {
            state.resetPasswordInProgress = false;
            state.resetPasswordError = action.payload;
        },
    },
});
export const { resetPasswordRequest, resetPasswordSuccess, resetPasswordError, } = PasswordResetPageSlice.actions;
export const resetPassword = (email, passwordResetToken, newPassword) => (dispatch, _, sdk) => __awaiter(void 0, void 0, void 0, function* () {
    dispatch(resetPasswordRequest());
    try {
        yield sdk.passwordReset.reset({
            email,
            passwordResetToken,
            newPassword,
        });
        dispatch(resetPasswordSuccess());
    }
    catch (error) {
        dispatch(resetPasswordError(storableError(error)));
    }
});
export default PasswordResetPageSlice.reducer;
export const resetPasswordInProgressSelector = (state) => state.PasswordResetPage.resetPasswordInProgress;
