import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import { MARKETPLACE_CURRENCIES } from '@nomady/shared/config';
import { formatMoney } from '@nomady/shared/utils/currency';
import { LISTING_STATE_PENDING_APPROVAL, LISTING_STATE_CLOSED, LISTING_STATE_DRAFT, LISTING_STATE_PUBLISHED, } from '@nomady/shared/utils/types';
import { LISTING_PAGE_PENDING_APPROVAL_VARIANT, LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PARAM_TYPE_DRAFT, LISTING_PAGE_PARAM_TYPE_EDIT, createSlug, } from '@nomady/shared/utils/urlHelpers';
import { createResourceLocatorString } from '@nomady/shared/utils/routes';
import routeConfiguration from '@nomady/shared/routeConfiguration/routeConfiguration';
import Page from '@nomady/shared/routeConfiguration/Page';
import { FormattedMessage } from '../../util/reactIntl';
import { InlineTextButton, Menu, MenuLabel, MenuContent, MenuItem, NamedLink, IconSpinner, ResponsiveImage, } from '..';
import MenuIcon from './MenuIcon';
import Overlay from './Overlay';
import css from './ManageListingCard.module.scss';
import { ReactComponent as InstantBookingIcon } from '../../assets/svg/instant-booking.svg';
import { ReactComponent as VerifiedIcon } from '../../assets/svg/verified.svg';
import { ReactComponent as PenIcon } from '../../assets/svg/pen.svg';
import { ReactComponent as CalendarIcon } from '../../assets/svg/calendar-small.svg';
import Icon, { IconSizeVariant } from '../../styled-components/Icon/Icon';
import campStyleIcons from '../../campStyleIcons';
// Menu content needs the same padding
const MENU_CONTENT_OFFSET = -12;
const priceData = (price, intl) => {
    if (price && MARKETPLACE_CURRENCIES.includes(price.currency)) {
        const formattedPrice = formatMoney(intl, price);
        return { formattedPrice, priceTitle: formattedPrice };
    }
    if (price) {
        return {
            formattedPrice: intl.formatMessage({ id: 'ManageListingCard.unsupportedPrice' }, { currency: price.currency }),
            priceTitle: intl.formatMessage({ id: 'ManageListingCard.unsupportedPriceTitle' }, { currency: price.currency }),
        };
    }
    return {};
};
const createListingURL = (routes, listing) => {
    const id = listing === null || listing === void 0 ? void 0 : listing.id.uuid;
    const slug = createSlug(listing.attributes.title);
    const isPendingApproval = listing.attributes.state === LISTING_STATE_PENDING_APPROVAL;
    const isDraft = listing.attributes.state === LISTING_STATE_DRAFT;
    const variantIsNotDraft = isPendingApproval
        ? LISTING_PAGE_PENDING_APPROVAL_VARIANT
        : null;
    const variant = isDraft ? LISTING_PAGE_DRAFT_VARIANT : variantIsNotDraft;
    const linkProps = isPendingApproval || isDraft
        ? {
            name: 'ListingPageVariant',
            params: {
                id,
                slug,
                variant,
            },
        }
        : {
            name: 'ListingPage',
            params: { id, slug },
        };
    return createResourceLocatorString(linkProps.name, routes, linkProps.params, {});
};
export const ManageListingCardComponent = props => {
    var _a, _b, _c, _d, _e, _f, _g;
    const { className, rootClassName, hasClosingError, hasOpeningError, isMenuOpen, actionsInProgressListingId, listing, onCloseListing, onOpenListing, onToggleMenu, renderSizes, } = props;
    const intl = useIntl();
    const history = useHistory();
    const classes = classNames(rootClassName || css.root, className);
    const id = listing === null || listing === void 0 ? void 0 : listing.id.uuid;
    const { title = '', price, state } = listing === null || listing === void 0 ? void 0 : listing.attributes;
    const slug = createSlug(title);
    const isPendingApproval = state === LISTING_STATE_PENDING_APPROVAL;
    const isClosed = state === LISTING_STATE_CLOSED;
    const isDraft = state === LISTING_STATE_DRAFT;
    const isPublished = state === LISTING_STATE_PUBLISHED;
    const instantBooking = (_b = (_a = listing === null || listing === void 0 ? void 0 : listing.attributes) === null || _a === void 0 ? void 0 : _a.publicData) === null || _b === void 0 ? void 0 : _b.instantBookingAvailable;
    const isVerified = !!((_d = (_c = listing === null || listing === void 0 ? void 0 : listing.attributes) === null || _c === void 0 ? void 0 : _c.metadata) === null || _d === void 0 ? void 0 : _d.isVerified);
    const publicData = ((_e = listing === null || listing === void 0 ? void 0 : listing.attributes) === null || _e === void 0 ? void 0 : _e.publicData) || null;
    const firstImage = ((_f = listing === null || listing === void 0 ? void 0 : listing.images) === null || _f === void 0 ? void 0 : _f[0]) || null;
    const menuItemClasses = classNames(css.menuItem, {
        [css.menuItemDisabled]: !!actionsInProgressListingId,
    });
    const { formattedPrice, priceTitle } = priceData(price, intl);
    const hasError = hasOpeningError || hasClosingError;
    const thisListingInProgress = actionsInProgressListingId && actionsInProgressListingId.uuid === id;
    const titleClasses = classNames(css.title, {
        [css.titlePending]: isPendingApproval,
        [css.titleDraft]: isDraft,
    });
    const editListingLinkType = isDraft
        ? LISTING_PAGE_PARAM_TYPE_DRAFT
        : LISTING_PAGE_PARAM_TYPE_EDIT;
    const unitTranslationKey = 'ManageListingCard.perNightInclusive';
    return (_jsxs("div", Object.assign({ className: classes }, { children: [_jsxs("div", Object.assign({ className: css.threeToTwoWrapper, tabIndex: 0, onClick: event => {
                    event.preventDefault();
                    event.stopPropagation();
                    // ManageListingCard contains links, buttons and elements that are working with routing.
                    // This card doesn't work if <a> or <button> is used to wrap events that are card 'clicks'.
                    //
                    // NOTE: It might be better to absolute-position those buttons over a card-links.
                    // (So, that they have no parent-child relationship - like '<a>bla<a>blaa</a></a>')
                    history.push(createListingURL(routeConfiguration, listing));
                } }, { children: [_jsx("div", Object.assign({ className: css.aspectWrapper }, { children: _jsx(ResponsiveImage, { rootClassName: css.rootForImage, alt: title, image: firstImage, variants: ['landscape-crop', 'landscape-crop2x'], sizes: renderSizes }) })), _jsxs("div", Object.assign({ className: css.onImageIconContainer }, { children: [isVerified ? (_jsx("div", Object.assign({ className: css.approvedIcon }, { children: _jsx(VerifiedIcon, {}) }))) : null, instantBooking ? (_jsx("div", Object.assign({ className: css.powerIcon }, { children: _jsx(InstantBookingIcon, {}) }))) : null] })), _jsxs("div", Object.assign({ className: classNames(css.menuOverlayWrapper, {
                            [css.menuOverlayOpen]: isMenuOpen,
                        }) }, { children: [_jsx("div", { className: classNames(css.menuOverlay) }), _jsx("div", Object.assign({ className: css.menuOverlayContent }, { children: _jsx(FormattedMessage, { id: "ManageListingCard.viewListing" }) }))] })), isDraft ? (_jsxs(React.Fragment, { children: [_jsx("div", { className: classNames({ [css.draftNoImage]: !firstImage }) }), _jsx(Overlay, Object.assign({ message: intl.formatMessage({ id: 'ManageListingCard.draftOverlayText' }, { listingTitle: title }) }, { children: _jsx(NamedLink, Object.assign({ className: css.finishListingDraftLink, name: "EditListingPage", params: {
                                        id,
                                        slug,
                                        type: LISTING_PAGE_PARAM_TYPE_DRAFT,
                                        tab: 'photos',
                                    } }, { children: _jsx(FormattedMessage, { id: "ManageListingCard.finishListingDraft" }) })) }))] })) : null, isClosed ? (_jsx(Overlay, Object.assign({ message: intl.formatMessage({ id: 'ManageListingCard.closedListing' }, { listingTitle: title }) }, { children: _jsx("button", Object.assign({ className: css.openListingButton, disabled: !!actionsInProgressListingId, onClick: event => {
                                event.preventDefault();
                                event.stopPropagation();
                                if (!actionsInProgressListingId) {
                                    onOpenListing(listing === null || listing === void 0 ? void 0 : listing.id);
                                }
                            } }, { children: _jsx(FormattedMessage, { id: "ManageListingCard.openListing" }) })) }))) : null, isPendingApproval && !isDraft && !isClosed ? (_jsx(Overlay, { message: intl.formatMessage({ id: 'ManageListingCard.pendingApproval' }, { listingTitle: title }) })) : null, thisListingInProgress && !hasError ? (_jsx(Overlay, { children: _jsx(IconSpinner, {}) })) : null, !thisListingInProgress && hasError ? (_jsx(Overlay, { errorMessage: intl.formatMessage({
                            id: 'ManageListingCard.actionFailed',
                        }) })) : null] })), _jsxs("div", Object.assign({ className: classNames(css.info, css.contentContainer) }, { children: [_jsx("div", Object.assign({ className: classNames(css.mainInfo, css.nameText) }, { children: _jsx("div", Object.assign({ className: css.titleWrapper }, { children: _jsx(InlineTextButton, Object.assign({ rootClassName: titleClasses, onClick: (event) => {
                                    event.preventDefault();
                                    event.stopPropagation();
                                    if (!isDraft) {
                                        history.push(createListingURL(routeConfiguration, listing));
                                    }
                                } }, { children: title })) })) })), _jsxs("div", Object.assign({ className: css.priceCampStyleContainer }, { children: [_jsx("div", Object.assign({ className: css.cardIconContainer }, { children: _jsx("div", Object.assign({ className: css.iconContainer }, { children: (_g = publicData === null || publicData === void 0 ? void 0 : publicData.campStyle) === null || _g === void 0 ? void 0 : _g.map(campStyle => (_jsx(Icon, { variant: campStyleIcons[campStyle], sizeVariant: IconSizeVariant.Medium }, `icon-${campStyle}`))) })) })), _jsx("div", Object.assign({ className: classNames(css.price, css.priceContainer) }, { children: formattedPrice ? (_jsxs(React.Fragment, { children: [_jsx("div", Object.assign({ className: classNames(css.perUnit, css.priceText) }, { children: _jsx(FormattedMessage, { id: unitTranslationKey }) })), _jsx("div", Object.assign({ className: classNames(css.priceValue, css.price), title: priceTitle }, { children: formattedPrice }))] })) : (_jsx("div", Object.assign({ className: css.noPrice }, { children: _jsx(FormattedMessage, { id: "ManageListingCard.priceNotSet" }) }))) }))] })), _jsx("div", { className: css.listingCardLineDivider }), _jsxs("div", Object.assign({ className: css.stateEditContainer }, { children: [_jsxs("div", Object.assign({ className: classNames(css.stateButton, isDraft ? css.stateDraft : null, isPendingApproval && !isDraft && !isClosed
                                    ? css.statePending
                                    : null, isPublished && !isPendingApproval ? css.statePublished : null, isClosed ? css.stateClosed : null) }, { children: [isDraft
                                        ? intl.formatMessage({ id: 'ManageListingCard.draftLabel' })
                                        : null, isPendingApproval && !isDraft && !isClosed
                                        ? intl.formatMessage({ id: 'ManageListingCard.unapprovedLabel' })
                                        : null, isClosed
                                        ? intl.formatMessage({ id: 'ManageListingCard.closedLabel' })
                                        : null, isPublished && !isPendingApproval
                                        ? intl.formatMessage({ id: 'ManageListingCard.publishedLabel' })
                                        : null] })), !isPendingApproval && (_jsxs("div", Object.assign({ className: css.menubarWrapper }, { children: [_jsx("div", { className: css.menubarGradient }), _jsx("div", Object.assign({ className: css.menubar }, { children: _jsxs(Menu, Object.assign({ className: classNames(css.menu, {
                                                [css.cardIsOpen]: !isClosed,
                                            }), contentPlacementOffset: MENU_CONTENT_OFFSET, contentPosition: "left", useArrow: false, onToggleActive: (isOpen) => {
                                                const listingOpen = isOpen ? listing : null;
                                                onToggleMenu(listingOpen);
                                            }, isOpen: isMenuOpen }, { children: [_jsx(MenuLabel, Object.assign({ className: css.menuLabel, isOpenClassName: css.listingMenuIsOpen }, { children: _jsx("div", Object.assign({ className: css.iconWrapper }, { children: _jsx(MenuIcon, { className: css.menuIcon, isActive: isMenuOpen }) })) })), _jsx(MenuContent, Object.assign({ rootClassName: css.menuContent }, { children: _jsx(MenuItem, { children: _jsx(InlineTextButton, Object.assign({ rootClassName: menuItemClasses, onClick: (event) => {
                                                                event.preventDefault();
                                                                event.stopPropagation();
                                                                if (!actionsInProgressListingId) {
                                                                    onToggleMenu(null);
                                                                    onCloseListing(listing === null || listing === void 0 ? void 0 : listing.id, isDraft);
                                                                }
                                                            } }, { children: _jsx(FormattedMessage, { id: "ManageListingCard.closeListing" }) })) }, "close-listing") }))] })) }))] })))] })), _jsxs("div", Object.assign({ className: css.manageLinks }, { children: [_jsxs(NamedLink, Object.assign({ className: css.manageLink, name: "EditListingPage", params: { id, slug, type: editListingLinkType, tab: 'description' } }, { children: [_jsx(PenIcon, {}), _jsx(FormattedMessage, { id: "ManageListingCard.editListing" })] })), _jsxs(NamedLink, Object.assign({ className: css.manageLink, name: Page.InboxCalendar, searchParams: { listingId: id } }, { children: [_jsx(CalendarIcon, {}), _jsx(FormattedMessage, { id: "ManageListingCard.manageAvailability" })] }))] }))] }))] })));
};
export default ManageListingCardComponent;
