var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Page from '@nomady/shared/routeConfiguration/Page';
import { createResourceLocatorString } from '@nomady/shared/utils/routes';
import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';
import { routeConfiguration } from '../..';
import getQueryString from '../../util/search-query/getQueryString';
import Icon, { IconSizeVariant, IconVariant } from '../Icon/Icon';
import Text from '../Text/Text';
const Wrapper = styled.button `
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border: 0;
  outline: 0;
  padding: 0;
  text-align: left;
  cursor: pointer;

  flex: 1;

  margin-right: 10px;
`;
const Picture = styled.div `
  background-position: center center;
  display: flex;
  width: 100%;
  height: 150px;
  align-items: center;
  justify-content: center;
  border-radius: 10px 10px 0px 0px;
  background-size: cover;
  background-image: url(${props => props.backgroundImage});

  @media ${props => props.theme.device.tabletL} {
    height: 272px;
  }
`;
const PictureDescription = styled.div `
  padding: 10px 15px 15px;
  background-color: ${props => props.theme.color.pearl};
  border-radius: 0px 0px 10px 10px;
  height: 70px;
  display: flex;
  width: 100%;
`;
const PictureTitle = styled(Text) `
  ${props => props.theme.typeset.H6}
  color: ${props => props.theme.color.cave};
  display: inline-block;
  align-self: flex-end;
  padding-right: 4px;
  flex: 1;
`;
const PictureIconWrapper = styled.div `
  display: inline-block;
  align-self: flex-end;
  padding-bottom: 4px;
`;
const PictureCard = (_a) => {
    var { searchQuery, title } = _a, props = __rest(_a, ["searchQuery", "title"]);
    const history = useHistory();
    const navigateToSearchPage = (searchQuery) => {
        const queryString = getQueryString(searchQuery);
        const target = createResourceLocatorString(Page.Search, routeConfiguration, {}, queryString);
        history.push(target);
    };
    const onClick = () => {
        if (props.onClick) {
            return props.onClick();
        }
        if (searchQuery) {
            return navigateToSearchPage(searchQuery);
        }
        return null;
    };
    return (_jsxs(Wrapper, Object.assign({ onClick: onClick }, { children: [_jsx(Picture, { backgroundImage: props.backgroundImage }), _jsxs(PictureDescription, { children: [_jsx(PictureTitle, { children: title }), _jsx(PictureIconWrapper, { children: _jsx(Icon, { variant: IconVariant.DirectionRight, sizeVariant: IconSizeVariant.ExtraExtraSmall }) })] })] })));
};
export default PictureCard;
