import { switchLocale } from '../../slices/UISlice.ts';
import { fetchCurrentUser } from '../../ducks/user.duck.ts';
import { storableError } from '../../util/errors.ts';
import { updateProfileCurrentUser } from '../../util/api.ts';

// ================ Action types ================ //

const CHANGE_LANGUAGE_REQUEST =
  'app/LanguageSettingsPage/CHANGE_LANGUAGE_REQUEST';
const CHANGE_LANGUAGE_SUCCESS =
  'app/LanguageSettingsPage/CHANGE_LANGUAGE_SUCCESS';
const CHANGE_LANGUAGE_ERROR = 'app/LanguageSettingsPage/CHANGE_LANGUAGE_ERROR';

const CHANGE_LANGUAGE_CLEAR = 'app/LanguageSettingsPage/CHANGE_LANGUAGE_CLEAR';

// ================ Reducer ================ //

const initialState = {
  languageSettingsError: null,
  languageSettingsInProgress: false,
  languageSettingsChanged: false,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case CHANGE_LANGUAGE_REQUEST:
      return {
        ...state,
        languageSettingsInProgress: true,
        languageSettingsError: null,
        languageSettingsChanged: false,
      };
    case CHANGE_LANGUAGE_SUCCESS:
      return {
        ...state,
        languageSettingsInProgress: false,
        languageSettingsChanged: true,
      };
    case CHANGE_LANGUAGE_ERROR:
      return {
        ...state,
        languageSettingsInProgress: false,
        languageSettingsError: payload,
      };

    case CHANGE_LANGUAGE_CLEAR:
      return { ...initialState };

    default:
      return state;
  }
}

// ================ Action creators ================ //

const languageSettingsRequest = () => ({
  type: CHANGE_LANGUAGE_REQUEST,
});
const languageSettingsSuccess = () => ({
  type: CHANGE_LANGUAGE_SUCCESS,
});
const languageSettingsError = error => ({
  type: CHANGE_LANGUAGE_ERROR,
  payload: error,
  error: true,
});

export const changeLocaleClear = () => ({
  type: CHANGE_LANGUAGE_CLEAR,
});

// ================ Thunks ================ //

export const changeLocale = params => dispatch => {
  dispatch(languageSettingsRequest());
  const { locale, automaticTranslationEnabled } = params;

  return updateProfileCurrentUser({
    profile: {
      privateData: {
        locale,
        automaticTranslationEnabled,
      },
    },
  })
    .then(() => {
      dispatch(switchLocale(locale));
      dispatch(languageSettingsSuccess());
      dispatch(fetchCurrentUser());
    })
    .catch(e => {
      dispatch(languageSettingsError(storableError(storableError(e))));
      // This is thrown so that form can be cleared
      // after a timeout on changeLanguage submit handler
      throw e;
    });
};
