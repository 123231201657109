var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { denormalisedResponseEntities } from '@nomady/shared/utils/data';
import { createSlice } from '@reduxjs/toolkit';
import merge from 'lodash/merge';
import moment from 'moment';
import { currentUserShowSuccess } from '../../ducks/user.duck';
import { updateProfileCurrentUser } from '../../util/api';
import { storableError } from '../../util/errors';
const initialState = {
    saveEmailError: null,
    saveDataError: null,
    saveContactDetailsInProgress: false,
    contactDetailsChanged: false,
};
export const ContactDetailsPageSlice = createSlice({
    name: 'app/ContactDetailsPage',
    initialState,
    reducers: {
        saveContactDetailsRequest: state => {
            state.saveContactDetailsInProgress = true;
            state.saveEmailError = null;
            state.saveDataError = null;
            state.contactDetailsChanged = false;
        },
        saveContactDetailsSuccess: state => {
            state.saveContactDetailsInProgress = false;
            state.contactDetailsChanged = true;
        },
        saveEmailError: (state, action) => {
            state.saveContactDetailsInProgress = false;
            state.saveEmailError = action.payload;
        },
        saveDataError: (state, action) => {
            state.saveContactDetailsInProgress = false;
            state.saveDataError = action.payload;
        },
        saveContactDetailsClear: state => {
            state.saveContactDetailsInProgress = false;
            state.saveEmailError = null;
            state.saveDataError = null;
            state.contactDetailsChanged = false;
        },
    },
});
export const { saveContactDetailsRequest, saveContactDetailsSuccess, saveEmailError, saveDataError, saveContactDetailsClear, } = ContactDetailsPageSlice.actions;
const requestSaveData = (params) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    const { phoneNumber, birthday, address } = params;
    const privateData = {};
    if (phoneNumber) {
        privateData.phoneNumber = phoneNumber;
    }
    if (birthday) {
        privateData.birthday = birthday;
    }
    if (address) {
        privateData.address = address;
    }
    try {
        const response = yield updateProfileCurrentUser({ profile: { privateData } }, {
            expand: true,
            include: ['profileImage'],
            'fields.image': ['variants.square-small', 'variants.square-small2x'],
        });
        const entities = denormalisedResponseEntities(response);
        if (entities.length !== 1) {
            throw new Error('Expected a resource in the updateProfileCurrentUser response');
        }
        return entities[0];
    }
    catch (error) {
        dispatch(saveDataError(storableError(error)));
        throw error;
    }
});
const requestSaveEmail = (params) => (dispatch, _, sdk) => __awaiter(void 0, void 0, void 0, function* () {
    const { email, currentPassword } = params;
    try {
        const response = yield sdk.currentUser.changeEmail({ email, currentPassword }, {
            expand: true,
        });
        const entities = denormalisedResponseEntities(response);
        if (entities.length !== 1) {
            throw new Error('Expected a resource in the sdk.currentUser.changeEmail response');
        }
        return entities[0];
    }
    catch (error) {
        dispatch(saveEmailError(storableError(error)));
        throw error;
    }
});
const saveEmail = (params) => (dispatch, getState) => {
    return (dispatch(requestSaveEmail(params))
        .then(user => {
        const state = getState();
        const currentUserMerge = merge(state.user.currentUser, user);
        dispatch(currentUserShowSuccess(currentUserMerge));
        dispatch(saveContactDetailsSuccess());
    })
        // error action dispatched in requestSaveEmail
        .catch((e) => console.error(e)));
};
/**
 * Save phone number and update the current user.
 */
const saveData = (params) => (dispatch) => {
    return (dispatch(requestSaveData(params))
        .then(user => {
        dispatch(currentUserShowSuccess(user));
        dispatch(saveContactDetailsSuccess());
    })
        // error action dispatched in requestSavePhoneNumber
        .catch((e) => console.error(e)));
};
/**
 * Save email and phone number and update the current user.
 */
const saveEmailAndData = (params) => (dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
    const { email, phoneNumber, birthday, address, currentPassword } = params;
    // order of promises: 1. email, 2. phone number
    const promises = [
        dispatch(requestSaveEmail({ email, currentPassword })),
        dispatch(requestSaveData({ phoneNumber, birthday, address })),
    ];
    try {
        const values = yield Promise.all(promises);
        const saveEmailUser = values[0];
        const saveDataUser = values[1];
        const { attributes: { profile: { privateData }, }, } = saveDataUser;
        const dataMergeSource = {
            attributes: { profile: { privateData } },
        };
        const updateMerge = merge(saveEmailUser, dataMergeSource);
        const state = getState();
        const currentUserMerge = merge(state.user.currentUser, updateMerge);
        dispatch(currentUserShowSuccess(currentUserMerge));
        dispatch(saveContactDetailsSuccess());
    }
    catch (error) {
        console.error(error);
    }
});
export const saveContactDetails = (params) => (dispatch, getState) => {
    dispatch(saveContactDetailsRequest());
    const state = getState();
    if (!state.user.currentUser) {
        throw new Error('current user unavailable');
    }
    const { email: currentEmail, profile: { privateData }, } = state.user.currentUser.attributes;
    const { address: currentAddress, birthday: currentBirthday, phoneNumber: currentPhoneNumber, } = privateData || {};
    const { email, currentPassword, phoneNumber, birthday, address } = params;
    const emailChanged = email && currentEmail && currentPassword && email !== currentEmail;
    const phoneNumberChanged = phoneNumber && (phoneNumber === null || phoneNumber === void 0 ? void 0 : phoneNumber.number) !== (currentPhoneNumber === null || currentPhoneNumber === void 0 ? void 0 : currentPhoneNumber.number);
    const birthdayChanged = !moment(birthday).isSame(moment(currentBirthday), 'day');
    const addressChanged = (address === null || address === void 0 ? void 0 : address.address) !== (currentAddress === null || currentAddress === void 0 ? void 0 : currentAddress.address);
    if (email &&
        emailChanged &&
        phoneNumberChanged &&
        birthdayChanged &&
        addressChanged) {
        return dispatch(saveEmailAndData({
            email,
            currentPassword,
            phoneNumber,
            birthday,
        }));
    }
    if (email && emailChanged && phoneNumberChanged && birthdayChanged) {
        return dispatch(saveEmailAndData({ email, currentPassword, phoneNumber, birthday }));
    }
    if (email && emailChanged && addressChanged && birthdayChanged) {
        return dispatch(saveEmailAndData({ email, currentPassword, birthday, address }));
    }
    if (email && emailChanged && phoneNumberChanged && addressChanged) {
        return dispatch(saveEmailAndData({ email, currentPassword, address, phoneNumber }));
    }
    if (email && emailChanged && phoneNumberChanged) {
        return dispatch(saveEmailAndData({ email, currentPassword, phoneNumber }));
    }
    if (email && emailChanged && birthdayChanged) {
        return dispatch(saveEmailAndData({ email, currentPassword, birthday }));
    }
    if (email && emailChanged && addressChanged) {
        return dispatch(saveEmailAndData({ email, currentPassword, address }));
    }
    if (phoneNumberChanged && birthdayChanged) {
        return dispatch(saveData({ phoneNumber, birthday }));
    }
    if (addressChanged && birthdayChanged) {
        return dispatch(saveData({ address, birthday }));
    }
    if (addressChanged && phoneNumberChanged) {
        return dispatch(saveData({ address, phoneNumber }));
    }
    if (emailChanged) {
        return dispatch(saveEmail({ email, currentPassword }));
    }
    if (phoneNumberChanged) {
        return dispatch(saveData({ phoneNumber }));
    }
    if (birthdayChanged) {
        return dispatch(saveData({ birthday }));
    }
    if (addressChanged) {
        return dispatch(saveData({ address }));
    }
    return dispatch(saveContactDetailsSuccess());
};
export default ContactDetailsPageSlice.reducer;
