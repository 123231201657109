import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import Icon, { IconSizeVariant, IconVariant, } from '../../../styled-components/Icon/Icon';
import Text, { TextVariant } from '../../../styled-components/Text/Text';
const IconWrapper = styled.div `
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 15px;
  padding-bottom: 5px;
`;
const Description = styled.div `
  margin-top: 20px;
`;
const ArrivalContent = () => {
    var _a, _b;
    const { listing } = useSelector((state) => state.ListingPage);
    const intl = useIntl();
    const arrivalType = (_b = (_a = listing === null || listing === void 0 ? void 0 : listing.attributes) === null || _a === void 0 ? void 0 : _a.publicData) === null || _b === void 0 ? void 0 : _b.arrivalType;
    const arrivalTypeToTitleTranslation = {
        direct: 'ListingPage.arrival.direct.title',
        reception: 'ListingPage.arrival.reception.title',
    };
    const arrivalTypeToDescriptionTranslation = {
        direct: 'ListingPage.arrival.direct.description',
        reception: 'ListingPage.arrival.reception.description',
    };
    return (_jsxs(_Fragment, { children: [_jsx(IconWrapper, { children: _jsx(Icon, { variant: IconVariant.Hand, sizeVariant: IconSizeVariant.Large }) }), !!arrivalType && (_jsxs(_Fragment, { children: [_jsx(Text, Object.assign({ variant: TextVariant.H20 }, { children: intl.formatMessage({
                            id: arrivalTypeToTitleTranslation[arrivalType],
                        }) })), _jsx(Description, { children: _jsx(Text, Object.assign({ variant: TextVariant.B20 }, { children: intl.formatMessage({
                                id: arrivalTypeToDescriptionTranslation[arrivalType],
                            }) })) })] }))] }));
};
export default ArrivalContent;
