import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styled, { css } from 'styled-components/macro';
import { injectIntl } from 'react-intl';
import Icon, { IconVariant } from '../Icon/Icon';
import Text, { TextVariant } from '../Text/Text';
import { NamedLink } from '../../components';
import { ColorVariant } from '../../theme';
export var MenuItemVariant;
(function (MenuItemVariant) {
    MenuItemVariant["Default"] = "Primary";
    MenuItemVariant["Primary"] = "Primary";
    MenuItemVariant["Secondary"] = "Secondary";
})(MenuItemVariant || (MenuItemVariant = {}));
const baseCss = css `
  display: flex;
  padding-top: 9px;
  padding-bottom: 9px;
  padding-left: 5px;
  padding-right: 5px;
  width: 100%;
  align-items: center;

  ${props => props.theme.typeset.H8}
  color: ${props => props.theme.color.cave};

  ${props => props.hoverColorVariant &&
    `
  &:hover {
   background-color: ${props.theme.color[props.hoverColorVariant]};
   border-radius: 40px;
  }
  `}

  ${props => props.menuItemVariant === MenuItemVariant.Secondary &&
    css `
      ${props.theme.typeset.H10}
      color: ${props.theme.color.stone};

      padding: 5px;
    `}

   

  @media ${props => props.theme.device.tablet} {
    padding-left: 25px;
  }
`;
const NamedLinkWrapper = styled(NamedLink) `
  ${baseCss}
`;
const LinkWrapper = styled.a `
  ${baseCss}
`;
const TextWrapper = styled(Text) `
  ${baseCss}
`;
const Label = styled.li `
  display: inline;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 15px;
  align-items: center;
`;
const NotificationBubble = styled.div `
  background-color: ${props => props.theme.color.cinnabar};
  height: 9px;
  width: 9px;
  margin-left: 10px;
  border-radius: 50%;
  align-self: center;
`;
const PriceWrapper = styled(Text) `
  color: ${props => props.theme.color.stone};
  margin-left: 10px;
  margin-right: 25px;
  white-space: nowrap;
`;
const MenuItem = ({ label, to, params, searchParams, onClick, icon = IconVariant.Void, image, menuItemVariant = MenuItemVariant.Primary, hoverColorVariant = ColorVariant.Snow, price, hasNotifications, intl, showActive = false, }) => {
    const inner = (_jsxs(_Fragment, { children: [!image && icon && (_jsx(Icon, { variant: icon || IconVariant.Void, colorVariant: menuItemVariant === MenuItemVariant.Secondary
                    ? ColorVariant.Stone
                    : undefined })), image && (_jsx("img", { src: image, height: 24, width: 24, alt: intl.formatMessage({ id: 'TopBar.menuItemImageAlt' }), "aria-hidden": true })), _jsx(Label, Object.assign({ role: "menuitem" }, { children: label })), hasNotifications && (_jsx(NotificationBubble, { "aria-label": intl.formatMessage({ id: 'TopBar.unreadInfo' }) })), price && (_jsx(PriceWrapper, Object.assign({ variant: TextVariant.H10 }, { children: `${price.currency} ${(price.amount / 100).toString()}` })))] }));
    if (!onClick && !to) {
        return _jsx(TextWrapper, Object.assign({ menuItemVariant: menuItemVariant }, { children: inner }));
    }
    if (onClick && !to) {
        return (_jsx(LinkWrapper, Object.assign({ role: "presentation", menuItemVariant: menuItemVariant, hoverColorVariant: hoverColorVariant, onClick: onClick }, { children: inner })));
    }
    return (_jsx(NamedLinkWrapper, Object.assign({ name: to, params: params, searchParams: searchParams, role: "presentation", menuItemVariant: menuItemVariant, hoverColorVariant: hoverColorVariant, onClick: onClick, showActive: showActive }, { children: inner })));
};
export default injectIntl(MenuItem);
