export const getSrcSetAndSizeOfImg = (formats) => {
    let srcSet = '';
    let sizes = '';
    if (formats === null || formats === void 0 ? void 0 : formats.large) {
        srcSet = `${formats.large.url} ${Number.parseInt(formats.large.width, 10) * 2}w,`;
        sizes = `(min-width: ${formats.large.width}px) ${formats.large.width}px,`;
    }
    if (formats === null || formats === void 0 ? void 0 : formats.medium) {
        srcSet += `${formats.medium.url} ${Number.parseInt(formats.medium.width, 10) * 2}w,`;
        sizes += `(min-width: ${formats.medium.width}px) ${formats.medium.width}px,`;
    }
    if (formats === null || formats === void 0 ? void 0 : formats.small) {
        srcSet += `${formats.small.url} ${Number.parseInt(formats.small.width, 10) * 2}w`;
        sizes += ` ${formats.small.width}px`;
    }
    return { srcSet, sizes };
};
export const getBackgroundImageUrlLarge = (formats, imageSrc) => {
    var _a, _b, _c;
    return (((_a = formats === null || formats === void 0 ? void 0 : formats.large) === null || _a === void 0 ? void 0 : _a.url) ||
        ((_b = formats === null || formats === void 0 ? void 0 : formats.medium) === null || _b === void 0 ? void 0 : _b.url) ||
        ((_c = formats === null || formats === void 0 ? void 0 : formats.small) === null || _c === void 0 ? void 0 : _c.url) ||
        imageSrc);
};
export const getBackgroundImageUrlMedium = (formats, imageSrc) => {
    var _a, _b;
    return ((_a = formats === null || formats === void 0 ? void 0 : formats.medium) === null || _a === void 0 ? void 0 : _a.url) || ((_b = formats === null || formats === void 0 ? void 0 : formats.small) === null || _b === void 0 ? void 0 : _b.url) || imageSrc;
};
export const getSeoSocialMediaImage = (seo) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3;
    return (((_a = seo === null || seo === void 0 ? void 0 : seo.metaImage) === null || _a === void 0 ? void 0 : _a.data.attributes.formats) && [
        {
            url: ((_d = (_c = (_b = seo === null || seo === void 0 ? void 0 : seo.metaImage) === null || _b === void 0 ? void 0 : _b.data.attributes.formats) === null || _c === void 0 ? void 0 : _c.medium) === null || _d === void 0 ? void 0 : _d.url) ||
                ((_g = (_f = (_e = seo === null || seo === void 0 ? void 0 : seo.metaImage) === null || _e === void 0 ? void 0 : _e.data.attributes.formats) === null || _f === void 0 ? void 0 : _f.small) === null || _g === void 0 ? void 0 : _g.url) ||
                ((_k = (_j = (_h = seo === null || seo === void 0 ? void 0 : seo.metaImage) === null || _h === void 0 ? void 0 : _h.data.attributes.formats) === null || _j === void 0 ? void 0 : _j.thumbnail) === null || _k === void 0 ? void 0 : _k.url),
            width: ((_o = (_m = (_l = seo === null || seo === void 0 ? void 0 : seo.metaImage) === null || _l === void 0 ? void 0 : _l.data.attributes.formats) === null || _m === void 0 ? void 0 : _m.medium) === null || _o === void 0 ? void 0 : _o.width) ||
                ((_r = (_q = (_p = seo === null || seo === void 0 ? void 0 : seo.metaImage) === null || _p === void 0 ? void 0 : _p.data.attributes.formats) === null || _q === void 0 ? void 0 : _q.small) === null || _r === void 0 ? void 0 : _r.width) ||
                ((_u = (_t = (_s = seo === null || seo === void 0 ? void 0 : seo.metaImage) === null || _s === void 0 ? void 0 : _s.data.attributes.formats) === null || _t === void 0 ? void 0 : _t.thumbnail) === null || _u === void 0 ? void 0 : _u.width),
            height: ((_x = (_w = (_v = seo === null || seo === void 0 ? void 0 : seo.metaImage) === null || _v === void 0 ? void 0 : _v.data.attributes.formats) === null || _w === void 0 ? void 0 : _w.medium) === null || _x === void 0 ? void 0 : _x.height) ||
                ((_0 = (_z = (_y = seo === null || seo === void 0 ? void 0 : seo.metaImage) === null || _y === void 0 ? void 0 : _y.data.attributes.formats) === null || _z === void 0 ? void 0 : _z.small) === null || _0 === void 0 ? void 0 : _0.height) ||
                ((_3 = (_2 = (_1 = seo === null || seo === void 0 ? void 0 : seo.metaImage) === null || _1 === void 0 ? void 0 : _1.data.attributes.formats) === null || _2 === void 0 ? void 0 : _2.thumbnail) === null || _3 === void 0 ? void 0 : _3.height),
        },
    ]);
};
const IMAGE_ALT_TEXT_PREFIX = `image_caption_`;
export const getImageAltText = (image, altTexts = {}) => {
    const key = `${IMAGE_ALT_TEXT_PREFIX}${image.id.uuid}`;
    const altText = altTexts[key];
    if (!altText) {
        return undefined;
    }
    return altText;
};
