import moment from 'moment';
import Decimal from '@nomady/shared/utils/decimal';
import { GIFT_CARD_TRANSFER_ACTION, MarketplaceCurrency, DEFAULT_SEAT_QUANTITY, PaymentMode, } from '../config';
import { types } from '../utils/sdkLoader';
import { isMoney } from '../utils/currency';
import { dateFromLocalToAPI, nightsBetween } from '../utils/dates';
import { LINE_ITEM_ADULT_TAXES, LINE_ITEM_TEEN_TAXES, LINE_ITEM_CHILDREN, LINE_ITEM_CHILD_TAXES, LINE_ITEM_CLEANING_FEE, LINE_ITEM_CUSTOMER_COMMISSION, LINE_ITEM_PROVIDER_COMMISSION, LINE_ITEM_GIFT_CARD_DEDUCTION_1, LINE_ITEM_GIFT_CARD_DEDUCTION_2, LINE_ITEM_GIFT_CARD_DEDUCTION_3, LINE_ITEM_GIFT_CARD_PROVIDER_PAYOUT, LINE_ITEM_GIFT_CARD_TOTAL_TRANSACTION_VALUE, LINE_ITEM_PROVIDER_PAYOUT, LINE_ITEM_CUSTOMER_COMMISSION_DISCOUNT, LINE_ITEM_SEATS, LINE_ITEM_ADULTS, LINE_ITEM_TEENS, LINE_ITEM_INFANTS, LINE_ITEM_DOGS, LINE_ITEM_EXTRAS_POWER_SUPPLY, LINE_ITEM_EXTRAS_FIREWOOD, } from '../utils/lineItemTypes';
import { calculateCustomerCommissionPercentage, calculateProviderCommissionPercentage, calculateCustomerCommissionAbsolute, calculateProviderCommissionAbsolute, calculateCustomerCommissionDiscountCodeDiscountAbsolute, } from '../utils/commissionCalculation';
import { calculateTotalForCustomer, calculateTotalForProvider, calculateTotalFromLineItems, } from '../utils/lineItemHelpers';
import { CleaningFeeType, VisitorsTaxType, } from '../types';
import getTransactionProtectedData from './utils/getTransactionProtectedData';
import getGiftCardLineItems from './utils/getGiftCardLineItems';
import getTotalProviderPayoutValue from './utils/getTotalProviderPayoutValue';
import getTotalCommission from './utils/getTotalCommission';
import getTotalTransactionValue from './utils/getTotalTransactionValue';
const { Money } = types;
const NON_COMISSION_BASIC_VALUE_LINE_ITEMS = [
    LINE_ITEM_PROVIDER_COMMISSION,
    LINE_ITEM_CUSTOMER_COMMISSION,
    LINE_ITEM_ADULT_TAXES,
    LINE_ITEM_TEEN_TAXES,
    LINE_ITEM_CHILD_TAXES,
    LINE_ITEM_CUSTOMER_COMMISSION_DISCOUNT,
];
class LineItemCalculator {
    /**
     *
     * @param {Listing} listing Object containing all listing information
     * @param {Object} lineItems Object containing the lineItems
     * @param {Number} lineItems.adults Number of adults
     * @param {Number} lineItems.teens Number of teens
     * @param {Number} lineItems.children Number of children
     * @param {Money} lineItems.giftCodeDeduction Amount of giftcode deduction
     * @param {Date} bookingStart Start date of the booking
     * @param {Date} bookingEnd End date of the booking
     */
    constructor(listing, lineItems, bookingStart, bookingEnd, listingPricing, paymentMode = PaymentMode.Legacy) {
        this.bookingStart = undefined;
        this.bookingEnd = undefined;
        this.pricePerSeat = {
            _sdkType: 'Money',
            amount: 0,
            currency: MarketplaceCurrency.CHF,
        };
        this.seats = DEFAULT_SEAT_QUANTITY;
        this.adults = 0;
        this.teens = 0;
        this.children = 0;
        this.infants = 0;
        this.dogs = 0;
        this.powerSupply = false;
        this.firewood = false;
        this.pricePerAdult = null;
        this.pricePerTeen = null;
        this.pricePerChild = null;
        this.taxesPerAdult = null;
        this.taxesPerTeen = null;
        this.taxesPerChild = null;
        this.cleaningFee = null;
        this.powerSupplyPrice = null;
        this.firewoodPrice = null;
        this.giftCards = [];
        this.perPersonAndNightTaxes = null;
        this.nights = new Decimal(0);
        this.currency = MarketplaceCurrency.CHF;
        this.giftCardTransferAction = GIFT_CARD_TRANSFER_ACTION.NO_TRANSFER;
        const { seats, adults, teens, children, infants, dogs, giftCards, customerCommissionDiscountCode, extras, } = lineItems;
        if (listing.attributes.publicData.currency) {
            this.currency = listing.attributes.publicData.currency;
        }
        if (bookingStart instanceof Date) {
            this.bookingStart = bookingStart;
        }
        else {
            throw new Error('bookingStart must be of type Date');
        }
        if (bookingStart instanceof Date) {
            this.bookingEnd = bookingEnd;
        }
        else {
            throw new Error('bookingEnd must be of type Date');
        }
        if (typeof seats === 'number') {
            if (0 < seats) {
                this.seats = seats;
            }
            else {
                throw new Error('seats must be greater than 0');
            }
        }
        else {
            throw new Error('seats must be of type Number');
        }
        if (typeof adults === 'number') {
            if (0 < adults) {
                this.adults = adults;
            }
            else {
                throw new Error('adults must be greater than 0');
            }
        }
        else {
            throw new Error('extraAdults must be of type Number');
        }
        if (customerCommissionDiscountCode) {
            this.customerCommissionDiscountCode = customerCommissionDiscountCode;
        }
        if (typeof teens === 'number') {
            if (0 <= teens) {
                this.teens = teens;
            }
            else {
                throw new Error('teens must be positive');
            }
        }
        else {
            throw new Error('teens must be of type Number');
        }
        if (typeof children === 'number') {
            if (0 <= children) {
                this.children = children;
            }
            else {
                throw new Error('children must be positive');
            }
        }
        else {
            throw new Error('children must be of type Number');
        }
        if (typeof infants === 'number') {
            if (0 <= infants) {
                this.infants = infants;
            }
            else {
                throw new Error('infants must be positive');
            }
        }
        else {
            throw new Error('infants must be of type Number');
        }
        if (typeof dogs === 'number') {
            if (0 <= dogs) {
                this.dogs = dogs;
            }
            else {
                throw new Error('dogs must be positive');
            }
        }
        else {
            throw new Error('dogs must be of type Number');
        }
        if (extras === null || extras === void 0 ? void 0 : extras.powerSupply) {
            this.powerSupply = extras.powerSupply;
        }
        if (extras === null || extras === void 0 ? void 0 : extras.firewood) {
            this.firewood = extras.firewood;
        }
        const { attributes: { publicData: { visitorsTaxType, cleaningFeeType, }, }, } = listing;
        const { seat, adult, teen, child, visitorsTax, cleaningFee: cleaningFeeNumeric, powerSupply: powerSupplyNumeric, firewood: firewoodNumeric, } = listingPricing;
        const { adult: visitorsTaxAdult, teen: visitorsTaxTeen, child: visitorsTaxChild, } = visitorsTax || {};
        this.perPersonAndNightTaxes =
            visitorsTaxType === VisitorsTaxType.perPersonAndPerNight;
        this.paymentMode = paymentMode;
        if (seat === undefined || seat < 0) {
            throw new Error('pricePerSeat must be a valid amount');
        }
        const pricePerSeat = new Money(seat, this.currency);
        this.pricePerSeat = pricePerSeat;
        if (adult === undefined || adult < 0) {
            throw new Error('listing pricing adult must be set');
        }
        const pricePerAdult = new Money(adult, this.currency);
        const pricePerTeen = new Money(teen || 0, this.currency);
        const pricePerChild = new Money(child || 0, this.currency);
        if (isMoney(pricePerAdult)) {
            this.pricePerAdult = pricePerAdult;
        }
        else {
            throw new Error('pricePerAdult must be of type Money and contain a valid amount');
        }
        if (isMoney(pricePerTeen)) {
            this.pricePerTeen = pricePerTeen;
        }
        else {
            throw new Error('pricePerTeen must be of type Money and contain a valid amount');
        }
        if (isMoney(pricePerChild)) {
            this.pricePerChild = pricePerChild;
        }
        else {
            throw new Error('pricePerChild must be of type Money and contain a valid amount');
        }
        if (this.perPersonAndNightTaxes) {
            const taxesPerAdult = new Money(visitorsTaxAdult || 0, this.currency);
            const taxesPerTeen = new Money(visitorsTaxTeen || 0, this.currency);
            if (isMoney(taxesPerAdult)) {
                this.taxesPerAdult = taxesPerAdult;
            }
            else {
                throw new Error('taxesPerAdult must be of type Money and contain a valid amount');
            }
            if (isMoney(taxesPerTeen)) {
                this.taxesPerTeen = taxesPerTeen;
            }
            else {
                throw new Error('taxesPerTeen must be of type Money and contain a valid amount');
            }
            if (visitorsTaxChild) {
                const taxesPerChild = new Money(visitorsTaxChild, this.currency);
                if (isMoney(taxesPerChild)) {
                    this.taxesPerChild = taxesPerChild;
                }
                else {
                    throw new Error('taxesPerChild must be of type Money and contain a valid amount');
                }
            }
        }
        if (cleaningFeeType === CleaningFeeType.cleaningFeePricing &&
            cleaningFeeNumeric &&
            0 < cleaningFeeNumeric) {
            const cleaningFee = new Money(cleaningFeeNumeric, this.currency);
            if (isMoney(cleaningFee)) {
                this.cleaningFee = cleaningFee;
            }
            else {
                throw new Error('cleaningFee must be of type Money and contain a valid amount');
            }
        }
        if (powerSupplyNumeric && 0 < powerSupplyNumeric) {
            this.powerSupplyPrice = new Money(powerSupplyNumeric, this.currency);
        }
        if (firewoodNumeric && 0 < firewoodNumeric) {
            this.firewoodPrice = new Money(firewoodNumeric, this.currency);
        }
        if (giftCards && 0 < giftCards.length) {
            if (giftCards.some(giftCard => giftCard.currency !== this.currency)) {
                throw new Error('all applied gift cards must match the listing currency');
            }
            const firstGitCardType = giftCards[0].codeType;
            if (giftCards.some(giftCard => giftCard.codeType !== firstGitCardType)) {
                throw new Error('can not mix discount codes and gift card codes');
            }
            giftCards.forEach(giftCard => {
                if (giftCard.remainingValue <= 0) {
                    return;
                }
                const remainingValue = -1 * giftCard.remainingValue;
                this.giftCards.push(Object.assign(Object.assign({}, giftCard), { remainingValue }));
            });
        }
        const nights = nightsBetween(bookingStart, bookingEnd);
        if (0 < nights) {
            this.nights = new Decimal(nights);
        }
        else {
            throw new Error('invalid date range');
        }
    }
    /**
     * Calculates lineTotal by multipling unitprice with quantity
     *
     * @param {Money} unitPrice
     * @param {Number} quantity
     */
    lineTotal(unitPrice, quantity) {
        return new Money(unitPrice.amount * quantity.toNumber(), this.currency);
    }
    getLineItems() {
        const lineItems = [];
        if (!this.pricePerAdult) {
            throw new Error(`pricePerAdult is not set`);
        }
        {
            const lineTotal = this.lineTotal(this.pricePerSeat, this.nights.mul(this.seats));
            lineItems.push({
                code: LINE_ITEM_SEATS,
                includeFor: ['customer', 'provider'],
                unitPrice: this.pricePerSeat,
                seats: this.seats,
                units: this.nights,
                lineTotal,
                reversal: false,
            });
        }
        {
            const unitPrice = new Money(this.pricePerAdult.amount, this.currency);
            const seats = this.adults;
            const lineTotal = this.lineTotal(unitPrice, this.nights.mul(seats));
            lineItems.push({
                code: LINE_ITEM_ADULTS,
                includeFor: ['customer', 'provider'],
                unitPrice,
                seats,
                units: this.nights,
                lineTotal,
                reversal: false,
            });
        }
        if (this.taxesPerAdult &&
            0 < this.adults &&
            0 <= this.taxesPerAdult.amount) {
            const lineTotal = this.lineTotal(this.taxesPerAdult, this.nights.mul(this.adults));
            lineItems.push({
                code: LINE_ITEM_ADULT_TAXES,
                includeFor: ['customer', 'provider'],
                unitPrice: this.taxesPerAdult,
                seats: this.adults,
                units: this.nights,
                lineTotal,
                reversal: false,
            });
        }
        if (this.pricePerTeen && 0 < this.teens) {
            const lineTotal = this.lineTotal(this.pricePerTeen, this.nights.mul(this.teens));
            lineItems.push({
                code: LINE_ITEM_TEENS,
                includeFor: ['customer', 'provider'],
                unitPrice: this.pricePerTeen,
                seats: this.teens,
                units: this.nights,
                lineTotal,
                reversal: false,
            });
        }
        if (this.taxesPerTeen && 0 < this.teens && 0 <= this.taxesPerTeen.amount) {
            const lineTotal = this.lineTotal(this.taxesPerTeen, this.nights.mul(this.teens));
            lineItems.push({
                code: LINE_ITEM_TEEN_TAXES,
                includeFor: ['customer', 'provider'],
                unitPrice: this.taxesPerTeen,
                seats: this.teens,
                units: this.nights,
                lineTotal,
                reversal: false,
            });
        }
        if (this.pricePerChild && 0 < this.children) {
            const lineTotal = this.lineTotal(this.pricePerChild, this.nights.mul(this.children));
            lineItems.push({
                code: LINE_ITEM_CHILDREN,
                includeFor: ['customer', 'provider'],
                unitPrice: this.pricePerChild,
                seats: this.children,
                units: this.nights,
                lineTotal,
                reversal: false,
            });
        }
        if (this.taxesPerChild &&
            0 < this.children &&
            0 <= this.taxesPerChild.amount) {
            const lineTotal = this.lineTotal(this.taxesPerChild, this.nights.mul(this.children));
            lineItems.push({
                code: LINE_ITEM_CHILD_TAXES,
                includeFor: ['customer', 'provider'],
                unitPrice: this.taxesPerChild,
                seats: this.children,
                units: this.nights,
                lineTotal,
                reversal: false,
            });
        }
        if (0 < this.infants) {
            lineItems.push({
                code: LINE_ITEM_INFANTS,
                includeFor: ['customer', 'provider'],
                unitPrice: new Money(0, this.currency),
                seats: this.infants,
                units: this.nights,
                lineTotal: new Money(0, this.currency),
                reversal: false,
            });
        }
        if (0 < this.dogs) {
            lineItems.push({
                code: LINE_ITEM_DOGS,
                includeFor: ['customer', 'provider'],
                unitPrice: new Money(0, this.currency),
                seats: this.dogs,
                units: this.nights,
                lineTotal: new Money(0, this.currency),
                reversal: false,
            });
        }
        if (this.cleaningFee) {
            lineItems.push({
                code: LINE_ITEM_CLEANING_FEE,
                unitPrice: this.cleaningFee,
                quantity: new Decimal(1),
                lineTotal: this.cleaningFee,
                reversal: false,
                includeFor: ['customer', 'provider'],
            });
        }
        if (this.powerSupply && this.powerSupplyPrice) {
            const lineTotal = this.lineTotal(this.powerSupplyPrice, this.nights);
            lineItems.push({
                code: LINE_ITEM_EXTRAS_POWER_SUPPLY,
                includeFor: ['customer', 'provider'],
                unitPrice: this.powerSupplyPrice,
                seats: 1,
                units: this.nights,
                lineTotal,
                reversal: false,
            });
        }
        if (this.firewood && this.firewoodPrice) {
            const lineTotal = this.lineTotal(this.firewoodPrice, this.nights);
            lineItems.push({
                code: LINE_ITEM_EXTRAS_FIREWOOD,
                includeFor: ['customer', 'provider'],
                unitPrice: this.firewoodPrice,
                seats: 1,
                units: this.nights,
                lineTotal,
                reversal: false,
            });
        }
        const totalBeforeCommissions = calculateTotalFromLineItems(lineItems.filter(lineItem => !NON_COMISSION_BASIC_VALUE_LINE_ITEMS.includes(lineItem.code)));
        const providerCommissionPercentage = calculateProviderCommissionPercentage(totalBeforeCommissions.amount);
        const customerCommissionPercentage = calculateCustomerCommissionPercentage(totalBeforeCommissions.amount);
        const customerCommissionLineTotal = new Money(calculateCustomerCommissionAbsolute(totalBeforeCommissions.amount), this.pricePerSeat.currency);
        const providerCommissionLineTotal = new Money(calculateProviderCommissionAbsolute(totalBeforeCommissions.amount), this.pricePerSeat.currency);
        lineItems.push({
            code: LINE_ITEM_CUSTOMER_COMMISSION,
            percentage: customerCommissionPercentage,
            reversal: false,
            unitPrice: totalBeforeCommissions,
            lineTotal: customerCommissionLineTotal,
            includeFor: ['customer'],
        });
        if (this.customerCommissionDiscountCode) {
            const discount = calculateCustomerCommissionDiscountCodeDiscountAbsolute(totalBeforeCommissions.amount, this.customerCommissionDiscountCode.discountInPercent);
            lineItems.push({
                code: LINE_ITEM_CUSTOMER_COMMISSION_DISCOUNT,
                percentage: new Decimal(-1 * this.customerCommissionDiscountCode.discountInPercent),
                reversal: false,
                unitPrice: totalBeforeCommissions,
                lineTotal: new Money(discount, this.pricePerSeat.currency),
                includeFor: ['customer'],
            });
        }
        lineItems.push({
            code: LINE_ITEM_PROVIDER_COMMISSION,
            percentage: providerCommissionPercentage,
            reversal: false,
            unitPrice: totalBeforeCommissions,
            lineTotal: providerCommissionLineTotal,
            includeFor: ['provider'],
        });
        if (this.giftCards[0]) {
            const payinTotal = calculateTotalForCustomer(lineItems);
            const giftCardDeduction = this.giftCards[0].remainingValue;
            const { id } = this.giftCards[0];
            const giftCardCoversTotalAmount = payinTotal.amount <= -1 * giftCardDeduction;
            const unitPrice = giftCardCoversTotalAmount
                ? new Money(-1 * payinTotal.amount, this.currency)
                : new Money(giftCardDeduction, this.currency);
            lineItems.push({
                code: LINE_ITEM_GIFT_CARD_DEDUCTION_1,
                unitPrice,
                quantity: new Decimal(1),
                lineTotal: unitPrice,
                reversal: false,
                id,
                includeFor: ['customer'],
            });
        }
        if (this.giftCards[1]) {
            const payinTotal = calculateTotalForCustomer(lineItems);
            const giftCardDeduction = this.giftCards[1].remainingValue;
            const { id } = this.giftCards[1];
            const giftCardCoversTotalAmount = payinTotal.amount <= -1 * giftCardDeduction;
            const unitPrice = giftCardCoversTotalAmount
                ? new Money(-1 * payinTotal.amount, this.currency)
                : new Money(giftCardDeduction, this.currency);
            lineItems.push({
                code: LINE_ITEM_GIFT_CARD_DEDUCTION_2,
                unitPrice,
                quantity: new Decimal(1),
                lineTotal: unitPrice,
                reversal: false,
                id,
                includeFor: ['customer'],
            });
        }
        if (this.giftCards[2]) {
            const payinTotal = calculateTotalForCustomer(lineItems);
            const giftCardDeduction = this.giftCards[2].remainingValue;
            const { id } = this.giftCards[2];
            const giftCardCoversTotalAmount = payinTotal.amount <= -1 * giftCardDeduction;
            const unitPrice = giftCardCoversTotalAmount
                ? new Money(-1 * payinTotal.amount, this.currency)
                : new Money(giftCardDeduction, this.currency);
            lineItems.push({
                code: LINE_ITEM_GIFT_CARD_DEDUCTION_3,
                unitPrice,
                quantity: new Decimal(1),
                lineTotal: unitPrice,
                reversal: false,
                id,
                includeFor: ['customer'],
            });
        }
        const giftCardDeductions = this.getGiftCardLineItems(lineItems);
        if (0 < giftCardDeductions.length) {
            const giftCardDeductionTotal = calculateTotalFromLineItems(giftCardDeductions);
            const payinTotal = calculateTotalForCustomer(lineItems);
            const payoutTotal = calculateTotalForProvider(lineItems);
            if (payinTotal.amount === 0) {
                //   __
                //  /_ |
                //   | |
                //   | |
                //   | |
                //   |_|
                // (ex preA) gift codes cover the commission and provider payout, add gift-code item, so payin is not negative
                // transfer payout -> provider stripe connected account
                // transfer commission -> add BalanceTransaction
                this.giftCardTransferAction =
                    GIFT_CARD_TRANSFER_ACTION.FULL_COMMISSION_FULL_PROVIDER_PAYOUT;
                lineItems.push({
                    code: LINE_ITEM_GIFT_CARD_TOTAL_TRANSACTION_VALUE,
                    unitPrice: new Money(-1 * giftCardDeductionTotal.amount, this.currency),
                    quantity: new Decimal(1),
                    lineTotal: new Money(-1 * giftCardDeductionTotal.amount, this.currency),
                    reversal: false,
                    includeFor: ['customer'],
                });
                return lineItems;
            }
            //   ___
            //  |__ \
            //     ) |
            //    / /
            //   / /_
            //  |____|
            // (ex A) Payin covers payout ( Do nothing )
            // For the remaining commission we need to add a balance transaction
            if (payoutTotal.amount <= payinTotal.amount) {
                this.giftCardTransferAction =
                    GIFT_CARD_TRANSFER_ACTION.REMAINING_COMMISSION;
                return lineItems;
            }
            const providerCommissionLineItem = lineItems.find(lineItem => lineItem.code === LINE_ITEM_PROVIDER_COMMISSION);
            const customerCommissionLineItem = lineItems.find(lineItem => lineItem.code === LINE_ITEM_CUSTOMER_COMMISSION);
            const totalCommissions = -1 * ((providerCommissionLineItem === null || providerCommissionLineItem === void 0 ? void 0 : providerCommissionLineItem.lineTotal.amount) || 0) +
                ((customerCommissionLineItem === null || customerCommissionLineItem === void 0 ? void 0 : customerCommissionLineItem.lineTotal.amount) || 0);
            if (totalCommissions <= payinTotal.amount) {
                //   ____
                //  |___ \
                //    __) |
                //   |__ <
                //   ___) |
                //  |____/
                // (ex B1) payin covers at least our commissions
                // Let's grab our commission and remainder of stripe payment
                // Do a full manual payout
                this.giftCardTransferAction =
                    GIFT_CARD_TRANSFER_ACTION.FULL_PROVIDER_PAYOUT;
                lineItems.push({
                    code: LINE_ITEM_GIFT_CARD_PROVIDER_PAYOUT,
                    unitPrice: giftCardDeductionTotal,
                    quantity: new Decimal(1),
                    lineTotal: giftCardDeductionTotal,
                    reversal: false,
                    includeFor: ['provider'],
                });
                lineItems.push({
                    code: LINE_ITEM_PROVIDER_PAYOUT,
                    unitPrice: new Money(-1 * (payoutTotal.amount + giftCardDeductionTotal.amount), this.currency),
                    quantity: new Decimal(1),
                    lineTotal: new Money(-1 * (payoutTotal.amount + giftCardDeductionTotal.amount), this.currency),
                    reversal: false,
                    includeFor: ['provider'],
                });
            }
            if (payinTotal.amount < totalCommissions) {
                //   _  _
                //  | || |
                //  | || |_
                //  |__   _|
                //     | |
                //     |_|
                // (ex B2) payin covers neither commission nor payout
                // take all the remaining money as our commission, so it won't get payed out automatically
                // BalanceTransaction for full provider payout
                // BalanceTransaction for remaining commission
                // payout should be zero
                this.giftCardTransferAction =
                    GIFT_CARD_TRANSFER_ACTION.REMAINING_COMMISSION_FULL_PROVIDER_PAYOUT;
                lineItems.push({
                    code: LINE_ITEM_GIFT_CARD_PROVIDER_PAYOUT,
                    unitPrice: new Money(-1 * payoutTotal.amount, this.currency),
                    quantity: new Decimal(1),
                    lineTotal: new Money(-1 * payoutTotal.amount, this.currency),
                    reversal: false,
                    includeFor: ['provider'],
                });
            }
        }
        return lineItems;
    }
    /**
     * Calculates the payin for sharetribe
     *
     * @returns {Money}
     */
    getSharetribePayin() {
        const lineItems = this.getLineItems();
        const lineItemsWithoutProviderCommission = calculateTotalForCustomer(lineItems);
        return lineItemsWithoutProviderCommission;
    }
    /**
     * Gets the total transaction value, including gift cards and stripe payment
     *
     * @returns {Number} Total Transaction Value
     */
    getTotalTransactionValue() {
        var _a;
        const lineItems = this.getLineItems();
        return ((_a = getTotalTransactionValue(lineItems)) === null || _a === void 0 ? void 0 : _a.amount) || 0;
    }
    /**
     * Returns all necessary transaction protected data for a trasaction
     *
     * @returns {*} Object of protecte data
     */
    getTransactionProtectedData() {
        const lineItems = this.getLineItems();
        const totalTransactionValue = this.getTotalTransactionValue();
        const currency = this.getCurrency();
        const giftCards = this.getGiftCards();
        const giftCardTransferAction = this.getGiftCardTransferAction();
        const customerCommissionDiscountCode = this.getCustomerCommissionDiscountCode();
        const paymentMode = this.getPaymentMode();
        return getTransactionProtectedData(lineItems, totalTransactionValue, currency, giftCards, giftCardTransferAction, customerCommissionDiscountCode, paymentMode);
    }
    getGiftCardTransferAction() {
        return this.giftCardTransferAction;
    }
    getSeats() {
        return this.seats;
    }
    /**
     * Returns the line items containing gift codes out of an array with line items
     * If no lineItems are passed, we'll get the lineItems from the currenct LineItemCalculator instance
     *
     * @param {LineItem[]} lineItems Array of Line Items
     *
     * @returns {LineItem[]} Array of Gift Card Line Items if present, or empty array otherwise
     */
    getGiftCardLineItems(lineItems) {
        let localLineItems = lineItems;
        if (!lineItems) {
            localLineItems = this.getLineItems();
        }
        return getGiftCardLineItems(localLineItems);
    }
    getTotalCommission() {
        const lineItems = this.getLineItems();
        const currency = this.getCurrency();
        return getTotalCommission(lineItems, currency);
    }
    /**
     * Calculates the payout for sharetribe
     * the payout of this amount will automatically be handled by sharetribe
     *
     * @returns {sharetribe.types.Money}
     */
    getSharetribePayout() {
        const lineItems = this.getLineItems();
        return calculateTotalForProvider(lineItems);
    }
    /**
     * Checks if any gift cards are applied
     *
     * @returns {Boolean}
     */
    includesGiftCardCodes() {
        const lineItemsGiftCardCodes = this.getGiftCardLineItems();
        return 0 < lineItemsGiftCardCodes.length;
    }
    /**
     * Returns total value of gift cards applied
     *
     * @returns {sharetribe.types.Money}
     */
    getGiftCardDeduction() {
        const lineItemsGiftCards = this.getGiftCardLineItems();
        if ((lineItemsGiftCards === null || lineItemsGiftCards === void 0 ? void 0 : lineItemsGiftCards.length) === 0) {
            return new Money(0, this.currency);
        }
        const totalDeductions = calculateTotalFromLineItems(lineItemsGiftCards);
        return totalDeductions;
    }
    getTotalProviderPayoutValue() {
        const lineItems = this.getLineItems();
        return getTotalProviderPayoutValue(lineItems).amount;
    }
    /**
     * calculates the amount of money of the stripe payment that gets manually transferred to the provider account
     *
     * in case of giftCardTransferAction 3 (full provider payout) it returns the amount
     * in all other giftcard transfer actions it returns 0
     *
     * @returns {number} provider payout value
     */
    getTransferAmountFromStripePaymentToProviderBalance() {
        const lineItems = this.getLineItems();
        const lineItemsProviderWithoutPayoutDifference = lineItems.filter(lineItem => lineItem.code === LINE_ITEM_PROVIDER_PAYOUT);
        if ((lineItemsProviderWithoutPayoutDifference === null || lineItemsProviderWithoutPayoutDifference === void 0 ? void 0 : lineItemsProviderWithoutPayoutDifference.length) === 0) {
            return 0;
        }
        const totalProviderPayout = calculateTotalForProvider(lineItemsProviderWithoutPayoutDifference);
        return -1 * totalProviderPayout.amount;
    }
    /**
     * calculate the amount of money that get transfered from the giftcard balance to the provider balance
     *
     * in case of giftCardTransferAction 3 (full provider payout) 4 (remaining commission full provider payout) it returns the amount
     * in all other giftcard transfer actions it returns 0
     *
     * @returns {number} amount that gets transfered from giftcard balance to provider balance
     */
    getTransferAmountFromGiftCardToProviderBalance() {
        const lineItems = this.getLineItems();
        const lineItemProviderPayoutDifference = lineItems.filter(lineItem => lineItem.code === LINE_ITEM_GIFT_CARD_PROVIDER_PAYOUT);
        if ((lineItemProviderPayoutDifference === null || lineItemProviderPayoutDifference === void 0 ? void 0 : lineItemProviderPayoutDifference.length) === 0) {
            return 0;
        }
        const totalProviderPayoutDifference = calculateTotalFromLineItems(lineItemProviderPayoutDifference);
        return -1 * totalProviderPayoutDifference.amount;
    }
    getCurrency() {
        return this.currency;
    }
    getPaymentMode() {
        return this.paymentMode;
    }
    /**
     * Get applied gift cards
     *
     * @returns {*} Array of Gift cards
     *
     */
    getGiftCards() {
        return this.giftCards;
    }
    /**
     * Get applied customer commission discount code
     *
     * @returns {LineItemCalculatorCustomerCommissionDiscountCodeConfig} customer commission discount code
     *
     */
    getCustomerCommissionDiscountCode() {
        return this.customerCommissionDiscountCode;
    }
    getApiStartDate() {
        return dateFromLocalToAPI(moment(this.bookingStart).startOf('day').toDate());
    }
    getApiEndDate() {
        return dateFromLocalToAPI(moment(this.bookingEnd).startOf('day').toDate());
    }
    getProviderCommission() {
        const lineItems = this.getCommissionBasicValueLineItems();
        const totalBeforeCommissions = calculateTotalFromLineItems(lineItems);
        return Math.abs(calculateProviderCommissionPercentage(totalBeforeCommissions.amount).toNumber());
    }
    getCustomerCommission() {
        const lineItems = this.getCommissionBasicValueLineItems();
        const totalBeforeCommissions = calculateTotalFromLineItems(lineItems);
        return calculateCustomerCommissionPercentage(totalBeforeCommissions.amount).toNumber();
    }
    getCommissionBasicValueLineItems() {
        return this.getLineItems().filter(lineItem => !NON_COMISSION_BASIC_VALUE_LINE_ITEMS.includes(lineItem.code));
    }
}
export default LineItemCalculator;
