import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { MarketplaceLocale } from '@nomady/shared/config';
import { MAILCHIMP_LOCALE_GROUPS } from '@nomady/shared/marketplace-custom-config';
import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import { localeSelector } from '../../slices/UISlice';
const Wrapper = styled.div `
  display: none;
`;
const MailchimpSignup = ({ firstName, email, formRef, suppressSignupConfirmation, }) => {
    const locale = useSelector(localeSelector);
    const localeGroup = MAILCHIMP_LOCALE_GROUPS[locale];
    const suppressIframeId = 'mailchimp';
    const formTarget = suppressSignupConfirmation ? suppressIframeId : '_blank';
    return (_jsxs(Wrapper, Object.assign({ id: "mc_embed_signup" }, { children: [_jsx("iframe", { name: suppressIframeId, id: suppressIframeId, style: { display: 'none' }, title: "mc" }), _jsxs("form", Object.assign({ action: "https://nomady.us20.list-manage.com/subscribe/post?u=a08fad38a7b2728cb82e6f4bd&id=c5905c1767", method: "post", id: "mc-embedded-subscribe-form", name: "mc-embedded-subscribe-form", target: formTarget, noValidate: true, onSubmit: event => event.preventDefault(), ref: formRef }, { children: [_jsxs("div", Object.assign({ className: "mc-field-group" }, { children: [_jsx("label", Object.assign({ htmlFor: "mce-MMERGE1" }, { children: "Vorname " })), _jsx("input", { type: "text", value: firstName, name: "MMERGE1", id: "mce-MMERGE1", readOnly: true })] })), _jsxs("div", Object.assign({ className: "mc-field-group" }, { children: [_jsx("label", Object.assign({ htmlFor: "mce-EMAIL" }, { children: "Email Adresse " })), _jsx("input", { type: "email", value: email, name: "EMAIL", id: "mce-EMAIL", readOnly: true })] })), _jsx("div", Object.assign({ className: "mc-field-group input-group" }, { children: _jsxs("ul", { children: [_jsxs("li", { children: [_jsx("input", { type: "radio", value: "32", name: "group[226619]", id: "mce-group[226619]-226619-0", checked: localeGroup ===
                                                MAILCHIMP_LOCALE_GROUPS[MarketplaceLocale['de-CH']], readOnly: true }), _jsx("label", Object.assign({ htmlFor: "mce-group[226619]-226619-0" }, { children: "DE-CH" }))] }), _jsxs("li", { children: [_jsx("input", { type: "radio", value: "64", name: "group[226619]", id: "mce-group[226619]-226619-1", checked: localeGroup ===
                                                MAILCHIMP_LOCALE_GROUPS[MarketplaceLocale['en-US']], readOnly: true }), _jsx("label", Object.assign({ htmlFor: "mce-group[226619]-226619-1" }, { children: "EN-US" }))] }), _jsxs("li", { children: [_jsx("input", { type: "radio", value: "128", name: "group[226619]", id: "mce-group[226619]-226619-2", checked: localeGroup ===
                                                MAILCHIMP_LOCALE_GROUPS[MarketplaceLocale['it-CH']], readOnly: true }), _jsx("label", Object.assign({ htmlFor: "mce-group[226619]-226619-2" }, { children: "IT-CH" }))] }), _jsxs("li", { children: [_jsx("input", { type: "radio", value: "256", name: "group[226619]", id: "mce-group[226619]-226619-3", checked: localeGroup ===
                                                MAILCHIMP_LOCALE_GROUPS[MarketplaceLocale['fr-CH']], readOnly: true }), _jsx("label", Object.assign({ htmlFor: "mce-group[226619]-226619-3" }, { children: "FR-CH" }))] }), _jsxs("li", { children: [_jsx("input", { type: "radio", value: "512", name: "group[226619]", id: "mce-group[226619]-226619-4", checked: localeGroup ===
                                                MAILCHIMP_LOCALE_GROUPS[MarketplaceLocale['de-DE']], readOnly: true }), _jsx("label", Object.assign({ htmlFor: "mce-group[226619]-226619-4" }, { children: "DE-DE" }))] }), _jsxs("li", { children: [_jsx("input", { type: "radio", value: "1024", name: "group[226619]", id: "mce-group[226619]-226619-5", checked: localeGroup ===
                                                MAILCHIMP_LOCALE_GROUPS[MarketplaceLocale['de-AT']], readOnly: true }), _jsx("label", Object.assign({ htmlFor: "mce-group[226619]-226619-5" }, { children: "DE-AT" }))] }), _jsxs("li", { children: [_jsx("input", { type: "radio", value: "2048", name: "group[226619]", id: "mce-group[226619]-226619-6", checked: localeGroup ===
                                                MAILCHIMP_LOCALE_GROUPS[MarketplaceLocale['it-IT']], readOnly: true }), _jsx("label", Object.assign({ htmlFor: "mce-group[226619]-226619-6" }, { children: "IT-IT" }))] }), _jsxs("li", { children: [_jsx("input", { type: "radio", value: "4096", name: "group[226619]", id: "mce-group[226619]-226619-7", checked: localeGroup ===
                                                MAILCHIMP_LOCALE_GROUPS[MarketplaceLocale['fr-FR']], readOnly: true }), _jsx("label", Object.assign({ htmlFor: "mce-group[226619]-226619-7" }, { children: "FR-FR" }))] })] }) }))] }))] })));
};
export default MailchimpSignup;
