import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { injectIntl } from 'react-intl';
import styled from 'styled-components/macro';
import { getSrcSetAndSizeOfImg } from '../../util/imageHelper';
import Text, { TextVariant } from '../Text/Text';
const Wrapper = styled.section `
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${props => props.theme.device.tabletL} {
    flex-direction: row;
    align-items: stretch;
  }
`;
const Image = styled.img `
  height: 100px;
  margin-bottom: 25px;
  flex-shrink: 0;

  @media ${props => props.theme.device.tablet} {
    margin-left: 50px;
    margin-right: 50px;
    height: 140px;
  }

  @media ${props => props.theme.device.desktop} {
    margin: 10px 100px;
    height: 180px;
  }

  @media ${props => props.theme.device.desktopL} {
    margin: 20px 150px;
    height: 220px;
  }
`;
const ContentWrapper = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  padding-bottom: 24px;
  border-bottom: 1px solid ${props => props.theme.color.stone};
  margin-bottom: 8px;
  width: 100%;

  @media ${props => props.theme.device.tabletL} {
    margin-left: 30px;
    padding-bottom: initial;

    ${props => props.isFirst &&
    `
      border-top: 1px solid ${props.theme.color.stone};
    `}
  }
`;
const Content = styled(Text) `
  @media ${props => props.theme.device.tablet} {
    ${props => props.theme.typeset.H4}
  }
`;
const Benefit = ({ text, icon, iconAlt, isFirst, intl, }) => {
    const { srcSet, sizes } = getSrcSetAndSizeOfImg(icon.data.attributes.formats);
    return (_jsxs(Wrapper, { children: [_jsx(Image, { srcSet: srcSet, sizes: sizes, src: icon.data.attributes.url, alt: iconAlt || intl.formatMessage({ id: 'Benefit.altImage' }), loading: "lazy" }), _jsx(ContentWrapper, Object.assign({ isFirst: isFirst }, { children: _jsx(Content, Object.assign({ variant: TextVariant.H3 }, { children: text })) }))] }));
};
export default injectIntl(Benefit);
