import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styled from 'styled-components/macro';
import GoogleAttribution from '../GoogleAttribution/GoogleAttribution';
import GeocoderGoogleMaps from './GeocoderGoogleMaps';
const Wrapper = styled.ul `
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0;

  border-radius: 10px;
  background-color: ${props => props.theme.color.pearl};
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  ${props => props.theme.priority.low}
`;
const Result = styled.li `
  padding: 16px;
  border: 1px solid transparent;
  border-bottom: 1px solid ${props => props.theme.color.snow};
  border-radius: 5px;
  width: 100%;

  ${props => props.theme.typeset.H14}

  ${props => props.focussed &&
    `
    color: ${props.theme.color.cave};
    border-color: ${props.theme.color.lavender};
  `}

  &:hover {
    border-color: ${props => props.theme.color.fog};
    cursor: pointer;
  }
`;
const PoweredByGoogle = styled.div `
  margin: 10px;

  img {
    height: 14px;
    width: auto;
  }
`;
const ResultsList = ({ predictions, focussedIndex, onClickResult, }) => {
    if ((predictions === null || predictions === void 0 ? void 0 : predictions.length) === 0) {
        return null;
    }
    const item = (prediction, index) => {
        const isFocussed = index === focussedIndex;
        const predictionId = GeocoderGoogleMaps.getPredictionId(prediction);
        return (_jsx(Result, Object.assign({ focussed: isFocussed, onClick: () => onClickResult(predictionId) }, { children: GeocoderGoogleMaps.getPredictionAddress(prediction) }), predictionId));
    };
    return (_jsxs(Wrapper, { children: [predictions.map(item), _jsx(PoweredByGoogle, { children: _jsx(GoogleAttribution, {}) })] }));
};
export default ResultsList;
