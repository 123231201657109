var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { LISTING_STATE_DRAFT } from '@nomady/shared/utils/types';
import { LISTING_PAGE_PARAM_TYPE_DRAFT, LISTING_PAGE_PARAM_TYPE_NEW, } from '@nomady/shared/utils/urlHelpers';
import Page from '@nomady/shared/routeConfiguration/Page';
import { createResourceLocatorString } from '@nomady/shared/utils/routes';
import routeConfiguration from '@nomady/shared/routeConfiguration/routeConfiguration';
import EditListingDescriptionPanel from '../EditListingDescriptionPanel/EditListingDescriptionPanel';
import EditListingFacilitiesPanel from '../EditListingFacilitiesPanel/EditListingFacilitiesPanel';
import EditListingLocationPanel from '../EditListingLocationPanel/EditListingLocationPanel';
import EditListingRulesPanel from '../EditListingRulesPanel/EditListingRulesPanel';
import EditListingPricingPanel from '../EditListingPricingPanel/EditListingPricingPanel';
import EditListingPhotosPanel from '../EditListingPhotosPanel/EditListingPhotosPanel';
import EditListingDescriptionWinterPanel from '../EditListingDescriptionWinterPanel/EditListingDescriptionWinterPanel';
import EditListingHostProfilePanel from '../EditListingHostProfilePanel/EditListingHostProfilePanel';
import { userHasPhoneNumberSelector } from '../../ducks/user.duck';
import { saveContactDetails } from '../../containers/ContactDetailsPage/ContactDetailsPageSlice';
import { requestUpdateListing, requestCreateListingDraft, clearUpdatedTab, loadData, } from '../../containers/EditListingPage/EditListingPageSlice';
import { getTabStatus } from './EditListingWizard';
import EditListingPayoutPanel from '../EditListingPayoutPanel/EditListingPayoutPanel';
export var EditListingTab;
(function (EditListingTab) {
    EditListingTab["hostProfile"] = "hostProfile";
    EditListingTab["description"] = "description";
    EditListingTab["location"] = "location";
    EditListingTab["facilities"] = "facilities";
    EditListingTab["rules"] = "rules";
    EditListingTab["pricing"] = "pricing";
    EditListingTab["descriptionWinter"] = "descriptionWinter";
    EditListingTab["photos"] = "photos";
    EditListingTab["payout"] = "payout";
})(EditListingTab || (EditListingTab = {}));
const pathParamsToNextTab = (params, tab, enabledTabs) => {
    const nextTabIndex = enabledTabs.findIndex(s => s === tab) + 1;
    const nextTab = nextTabIndex < enabledTabs.length
        ? enabledTabs[nextTabIndex]
        : enabledTabs[enabledTabs.length - 1];
    return Object.assign(Object.assign({}, params), { tab: nextTab });
};
// When user has update draft listing, he should be redirected to next EditListingWizardTab
const redirectAfterDraftUpdate = (listingId, params, tab, enabledTabs, history) => {
    const currentPathParams = Object.assign(Object.assign({}, params), { type: LISTING_PAGE_PARAM_TYPE_DRAFT, id: listingId });
    // Replace current "new" path to "draft" path.
    // Browser's back button should lead to editing current draft instead of creating a new one.
    if (params.type === LISTING_PAGE_PARAM_TYPE_NEW) {
        const draftURI = createResourceLocatorString(Page.EditListing, routeConfiguration, currentPathParams, {});
        history.replace(draftURI);
    }
    // Redirect to next tab
    const nextPathParams = pathParamsToNextTab(currentPathParams, tab, enabledTabs);
    const to = createResourceLocatorString(Page.EditListing, routeConfiguration, nextPathParams, {});
    history.push(to);
};
const EditListingWizardTab = props => {
    var _a;
    const { params, listing = null, currentUser, onFormDirty } = props;
    const intl = useIntl();
    const history = useHistory();
    const dispatch = useDispatch();
    const onImageUploaded = () => __awaiter(void 0, void 0, void 0, function* () { return dispatch(loadData(params)); });
    const state = useSelector((state) => state);
    const { tab } = params;
    const { createStripeAccountInProgress, createStripeAccountError } = state.stripeConnectAccount;
    const { createListingDraftError = null, publishListingError = null, updateListingError = null, showListingsError = null, uploadImageError = null, updatedTab, updateInProgress, } = state.EditListingPage;
    const { listingsWithPendingRequests, listingDefaultCurrency } = state.user;
    const fetchInProgress = createStripeAccountInProgress;
    const userHasPhoneNumber = userHasPhoneNumberSelector(state);
    const onUpdateListing = (tab, values) => dispatch(requestUpdateListing(tab, values));
    const onCreateListingDraft = (values) => dispatch(requestCreateListingDraft(values));
    const onChange = () => dispatch(clearUpdatedTab());
    const onPhoneNumberSave = (phoneNumber) => dispatch(saveContactDetails({ phoneNumber }));
    const { type } = params;
    const isNewURI = type === LISTING_PAGE_PARAM_TYPE_NEW;
    const isDraftURI = type === LISTING_PAGE_PARAM_TYPE_DRAFT;
    const isNewListingFlow = isNewURI || isDraftURI;
    const onCompleteEditListingHostProfileTab = () => __awaiter(void 0, void 0, void 0, function* () {
        const currentPathParams = Object.assign({}, params);
        const nextPathParams = Object.assign(Object.assign({}, currentPathParams), { tab: EditListingTab.description });
        const to = createResourceLocatorString(Page.EditListing, routeConfiguration, nextPathParams, {});
        return history.push(to);
    });
    const tabStatus = getTabStatus(listing);
    const enabledTabs = Object.keys(tabStatus).filter(key => !!tabStatus[key]);
    const onCompleteEditListingWizardTab = (wizardTab, wizardUpdateValues, noSubmit = false) => __awaiter(void 0, void 0, void 0, function* () {
        var _b;
        const updateValuesWithImages = wizardUpdateValues;
        if (isNewListingFlow && !noSubmit) {
            const shouldStorePhoneNumberOnProfile = wizardTab === EditListingTab.location && !userHasPhoneNumber;
            if (shouldStorePhoneNumberOnProfile &&
                ((_b = wizardUpdateValues === null || wizardUpdateValues === void 0 ? void 0 : wizardUpdateValues.protectedData) === null || _b === void 0 ? void 0 : _b.afterBookingPhone)) {
                onPhoneNumberSave(wizardUpdateValues.protectedData.afterBookingPhone);
            }
            try {
                const response = (isNewURI
                    ? yield onCreateListingDraft(updateValuesWithImages)
                    : yield onUpdateListing(wizardTab, Object.assign(Object.assign({}, updateValuesWithImages), { id: listing === null || listing === void 0 ? void 0 : listing.id })));
                // After successful saving of draft data, user should be redirected to next tab
                return redirectAfterDraftUpdate(response.data.data.id.uuid, params, wizardTab, enabledTabs, history);
            }
            catch (e) {
                console.error(e);
            }
        }
        if (listing) {
            yield onUpdateListing(tab, Object.assign(Object.assign({}, updateValuesWithImages), { id: listing.id }));
            const nextPathParams = pathParamsToNextTab(params, tab, enabledTabs);
            const to = createResourceLocatorString(Page.EditListing, routeConfiguration, nextPathParams, {});
            history.push(to);
        }
    });
    const listingState = (_a = listing === null || listing === void 0 ? void 0 : listing.attributes) === null || _a === void 0 ? void 0 : _a.state;
    const newListingPublished = !!(isDraftURI &&
        listing &&
        listingState !== LISTING_STATE_DRAFT);
    const panelProps = (currentTab) => {
        return {
            errors: {
                createListingDraftError,
                publishListingError,
                updateListingError,
                showListingsError,
                uploadImageError,
                createStripeAccountError,
            },
            listing,
            onChange,
            onFormDirty,
            panelUpdated: updatedTab === currentTab,
            updateInProgress,
            // newListingPublished and fetchInProgress are flags for the last wizard tab
            ready: newListingPublished,
            disabled: fetchInProgress,
            submitButtonText: intl.formatMessage({
                id: 'EditListingWizard.saveAndContinue',
            }),
        };
    };
    const ContentConfig = {
        [EditListingTab.hostProfile]: (_jsx(EditListingHostProfilePanel, Object.assign({}, panelProps(EditListingTab.hostProfile), { onSubmit: onCompleteEditListingHostProfileTab }))),
        [EditListingTab.description]: (_jsx(EditListingDescriptionPanel, Object.assign({}, panelProps(EditListingTab.description), { onSubmit: values => {
                onCompleteEditListingWizardTab(tab, values);
            } }))),
        [EditListingTab.facilities]: (_jsx(EditListingFacilitiesPanel, Object.assign({}, panelProps(EditListingTab.facilities), { onSubmit: values => {
                onCompleteEditListingWizardTab(tab, values);
            } }))),
        [EditListingTab.rules]: (_jsx(EditListingRulesPanel, Object.assign({}, panelProps(EditListingTab.rules), { onSubmit: values => {
                onCompleteEditListingWizardTab(tab, values);
            } }))),
        [EditListingTab.location]: (_jsx(EditListingLocationPanel, Object.assign({}, panelProps(EditListingTab.location), { onSubmit: values => {
                onCompleteEditListingWizardTab(tab, values);
            } }))),
        [EditListingTab.pricing]: (_jsx(EditListingPricingPanel, Object.assign({}, panelProps(EditListingTab.pricing), { onSubmit: values => {
                onCompleteEditListingWizardTab(tab, values);
            }, listingDefaultCurrency: listingDefaultCurrency, listingsWithPendingRequests: listingsWithPendingRequests }))),
        [EditListingTab.descriptionWinter]: (_jsx(EditListingDescriptionWinterPanel, Object.assign({}, panelProps(EditListingTab.description), { onSubmit: values => {
                onCompleteEditListingWizardTab(tab, values);
            } }))),
        [EditListingTab.photos]: (_jsx(EditListingPhotosPanel, Object.assign({}, panelProps(EditListingTab.pricing), { onImageUploaded: onImageUploaded, onSubmit: values => {
                onCompleteEditListingWizardTab(tab, values);
            } }))),
        [EditListingTab.payout]: (_jsx(EditListingPayoutPanel, Object.assign({}, panelProps(EditListingTab.pricing), { onSubmit: values => {
                onCompleteEditListingWizardTab(tab, values);
            } }))),
    };
    return ContentConfig[tab];
};
export default EditListingWizardTab;
