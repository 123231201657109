var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { FormattedMessage } from '../../../util/reactIntl';
import Button, { ButtonColorVariant, ButtonOutlineVariant, ButtonSizeVariant, } from '../../../styled-components/Button/Button';
import Modal from '../../../styled-components/Modal/Modal';
import ActionErrors from '../../../components/TransactionPanel/Actions/ActionErrors';
import styled from 'styled-components/macro';
import Input from '../../../styled-components/Input/Input';
import SelectInput from '../../../styled-components/SelectInput/SelectInput';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { getTransactionTerminationReasonSchema, } from '../../../util/transactionTerminationReasonUtils';
import { ProviderTransactionTerminationReason } from '@nomady/shared/types';
const ActionButtons = styled.div `
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
const ActionButtonWrapper = styled.div `
  display: flex;
  gap: 16px;
  justify-content: space-between;
`;
const ActionButton = styled.div ``;
const ProviderActions = props => {
    const { acceptModal, showButtons, acceptInProgress, declineInProgress, acceptSaleError, declineSaleError, onDeclineSale, } = props;
    const intl = useIntl();
    const [acceptModalOpen, setAcceptModalOpen] = useState(false);
    const [declineModalOpen, setDeclineModalOpen] = useState(false);
    const { register, handleSubmit, setValue, watch, formState: { errors }, } = useForm({
        mode: 'onChange',
        defaultValues: {
            reason: undefined,
            reasonText: undefined,
        },
        resolver: zodResolver(getTransactionTerminationReasonSchema(intl, ProviderTransactionTerminationReason)),
    });
    const reason = watch('reason');
    const formRef = useRef(null);
    const onSuccess = (data) => __awaiter(void 0, void 0, void 0, function* () {
        onDeclineSale(data);
    });
    const registeredReason = register('reason');
    const registeredReasonText = register('reasonText');
    const triggerSubmit = () => {
        if (formRef.current) {
            formRef.current.dispatchEvent(new Event('submit', { cancelable: true }));
        }
    };
    if (!showButtons) {
        return null;
    }
    const buttonsDisabled = acceptInProgress || declineInProgress;
    const acceptErrorMessage = acceptSaleError ? (_jsx(FormattedMessage, { id: "TransactionPanel.acceptSaleFailed" })) : null;
    const declineErrorMessage = declineSaleError ? (_jsx(FormattedMessage, { id: "TransactionPanel.declineSaleFailed" })) : null;
    return (_jsxs(ActionButtons, { children: [_jsxs(ActionErrors, { children: [acceptErrorMessage, declineErrorMessage] }), _jsxs(ActionButtonWrapper, { children: [_jsx(ActionButton, { children: _jsx(Button, { inProgress: declineInProgress, disabled: buttonsDisabled, colorVariant: ButtonColorVariant.Default, sizeVariant: ButtonSizeVariant.Small, outlineVariant: ButtonOutlineVariant.Outlined, onClick: () => setDeclineModalOpen(true), label: intl.formatMessage({ id: 'TransactionPanel.declineButton' }) }) }), _jsx(ActionButton, { children: _jsx(Button, { inProgress: acceptInProgress, disabled: buttonsDisabled, colorVariant: ButtonColorVariant.Forest, sizeVariant: ButtonSizeVariant.Small, onClick: () => setAcceptModalOpen(true), label: intl.formatMessage({ id: 'TransactionPanel.acceptButton' }) }) }), _jsx(Modal, Object.assign({}, acceptModal, { isOpen: acceptModalOpen, onClose: () => setAcceptModalOpen(false), leftButton: {
                            onClick: () => setAcceptModalOpen(false),
                            label: intl.formatMessage({
                                id: 'TransactionPanel.ProviderAcceptActions.cancelButtonLabel',
                            }),
                        }, id: "AcceptModal" }))] }), _jsxs(Modal, Object.assign({ isOpen: declineModalOpen, onClose: () => setDeclineModalOpen(false), title: intl.formatMessage({
                    id: 'TransactionPanel.ProviderAcceptActions.declineTitle',
                }), leftButton: {
                    onClick: () => setDeclineModalOpen(false),
                    label: intl.formatMessage({
                        id: 'TransactionPanel.ProviderAcceptActions.cancelButtonLabel',
                    }),
                }, rightButton: {
                    label: intl.formatMessage({
                        id: 'TransactionPanel.ProviderAcceptActions.declineButtonLabel',
                    }),
                    onClick: triggerSubmit,
                    isPrimary: true,
                }, id: "DeclineModal" }, { children: [intl.formatMessage({
                        id: 'TransactionPanel.ProviderAcceptActions.declineText',
                    }), _jsxs("form", Object.assign({ ref: formRef, onSubmit: handleSubmit(onSuccess) }, { children: [_jsx(SelectInput, Object.assign({ options: Object.values(ProviderTransactionTerminationReason).map((reason) => ({
                                    label: intl.formatMessage({
                                        id: `ProviderTransactionTerminationReason.${reason}`,
                                    }),
                                    value: reason,
                                })), label: intl.formatMessage({
                                    id: 'RefundModal.cancelReason',
                                }), id: "reason", error: errors === null || errors === void 0 ? void 0 : errors.reason }, registeredReason, { onChange: event => {
                                    setValue('reason', event.target.value);
                                    registeredReason.onChange(event);
                                } })), reason === ProviderTransactionTerminationReason.Other && (_jsx(Input, Object.assign({ label: intl.formatMessage({
                                    id: 'RefundModal.reasonText',
                                }), id: "reasonText", type: "text", error: errors === null || errors === void 0 ? void 0 : errors.reasonText }, registeredReasonText, { onChange: event => {
                                    setValue('reasonText', event.target.value);
                                    registeredReasonText.onChange(event);
                                } })))] }))] }))] }));
};
export default ProviderActions;
