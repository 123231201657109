import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import CheckboxInputSection from '../../CheckboxInputSection/CheckboxInputSection';
import FilterGroup from '../../FilterGroup/FilterGroup';
import { IconVariant } from '../../Icon/Icon';
const AccessFilterGroup = ({ currentFeaturesSearchQuery, intl, onSetFeatures, }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    return (_jsxs(FilterGroup, Object.assign({ iconVariant: IconVariant.CamperOnGround, label: intl.formatMessage({
            id: 'SearchBarSheet.Features.Access.title',
        }) }, { children: [_jsx(CheckboxInputSection, { label: intl.formatMessage({
                    id: 'SearchBarSheet.Features.Access.access',
                }), checkboxes: [
                    {
                        id: 'access-access-regular-vehicle',
                        checked: !!((_b = (_a = currentFeaturesSearchQuery.access) === null || _a === void 0 ? void 0 : _a.access) === null || _b === void 0 ? void 0 : _b.regularVehicle),
                        label: intl.formatMessage({
                            id: 'SearchBarSheet.Features.Access.access_regularVehicle',
                        }),
                        onChange: (value) => onSetFeatures({
                            access: { access: { regularVehicle: value } },
                        }),
                    },
                    {
                        id: 'access-access-ground-clearance-suggested',
                        checked: !!((_d = (_c = currentFeaturesSearchQuery.access) === null || _c === void 0 ? void 0 : _c.access) === null || _d === void 0 ? void 0 : _d.groundClearanceSuggested),
                        label: intl.formatMessage({
                            id: 'MarketplaceConfig.extraGroundClearance',
                        }),
                        onChange: (value) => onSetFeatures({
                            access: { access: { groundClearanceSuggested: value } },
                        }),
                    },
                    {
                        id: 'access-access-offroader',
                        checked: !!((_f = (_e = currentFeaturesSearchQuery.access) === null || _e === void 0 ? void 0 : _e.access) === null || _f === void 0 ? void 0 : _f.offRoader),
                        label: intl.formatMessage({
                            id: 'MarketplaceConfig.fourByFour',
                        }),
                        onChange: (value) => onSetFeatures({
                            access: { access: { offRoader: value } },
                        }),
                    },
                ] }), _jsx(CheckboxInputSection, { label: intl.formatMessage({
                    id: 'SearchBarSheet.Features.Access.ground',
                }), checkboxes: [
                    {
                        id: 'access-ground-lawn',
                        checked: !!((_h = (_g = currentFeaturesSearchQuery.access) === null || _g === void 0 ? void 0 : _g.ground) === null || _h === void 0 ? void 0 : _h.lawn),
                        label: intl.formatMessage({
                            id: 'MarketplaceConfig.lawn',
                        }),
                        onChange: (value) => onSetFeatures({
                            access: { ground: { lawn: value } },
                        }),
                    },
                    {
                        id: 'access-ground-tarmac',
                        checked: !!((_k = (_j = currentFeaturesSearchQuery.access) === null || _j === void 0 ? void 0 : _j.ground) === null || _k === void 0 ? void 0 : _k.tarmac),
                        label: intl.formatMessage({
                            id: 'MarketplaceConfig.asphalt',
                        }),
                        onChange: (value) => onSetFeatures({
                            access: { ground: { tarmac: value } },
                        }),
                    },
                    {
                        id: 'access-ground-gravel-or-chipped-wood',
                        checked: !!((_m = (_l = currentFeaturesSearchQuery.access) === null || _l === void 0 ? void 0 : _l.ground) === null || _m === void 0 ? void 0 : _m.gravelOrChippedWood),
                        label: intl.formatMessage({
                            id: 'MarketplaceConfig.gravel',
                        }),
                        onChange: (value) => onSetFeatures({
                            access: { ground: { gravelOrChippedWood: value } },
                        }),
                    },
                ] })] })));
};
export default AccessFilterGroup;
