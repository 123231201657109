import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { cateringTypes } from '@nomady/shared/marketplace-custom-config';
import { IconVariant } from '../../../styled-components/Icon/Icon';
import ListingChecklist from '../../../styled-components/ListingChecklist';
const CateringContent = () => {
    var _a, _b, _c, _d, _e;
    const { listing } = useSelector((state) => state.ListingPage);
    const intl = useIntl();
    const description = (_c = (_b = (_a = listing === null || listing === void 0 ? void 0 : listing.attributes) === null || _a === void 0 ? void 0 : _a.publicData) === null || _b === void 0 ? void 0 : _b.localizedPublicData) === null || _c === void 0 ? void 0 : _c.cateringDescription;
    const activeCateringTypes = ((_e = (_d = listing === null || listing === void 0 ? void 0 : listing.attributes) === null || _d === void 0 ? void 0 : _d.publicData) === null || _e === void 0 ? void 0 : _e.cateringType) || [];
    const elements = cateringTypes
        .filter(element => activeCateringTypes.includes(element.key))
        .map((element, index) => ({
        text: intl.formatMessage({ id: element.label }),
        isChecked: true,
        key: `catering-${index}`,
    }));
    if (description) {
        elements.push({
            text: description,
            isChecked: true,
            key: 'catering-description',
        });
    }
    return (_jsx(ListingChecklist, { items: elements, title: intl.formatMessage({ id: 'ListingPage.catering' }), iconVariant: IconVariant.Food }));
};
export default CateringContent;
