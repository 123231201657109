/**
 * Import reducers from shared ducks modules (default export)
 * We are following Ducks module proposition:
 * https://github.com/erikras/ducks-modular-redux
 */
import Auth from './Auth.duck';
import LocationFilter from './LocationFilter.duck';
import marketplaceData from './marketplaceData.duck';
import stripeConnectAccount from './stripeConnectAccount.duck';
import user from './user.duck';
import Analytics from './Analytics.duck';
export const GlobalReducers = {
    Analytics,
    Auth,
    LocationFilter,
    marketplaceData,
    stripeConnectAccount,
    user,
};
