import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styled from 'styled-components/macro';
import Button, { ButtonColorVariant, ButtonSizeVariant, } from '../Button/Button';
import Text, { TextVariant } from '../Text/Text';
import SolvedIcon from './assets/solved-icon.webp';
import HostIllustrationSrc from './assets/host-illustration.webp';
import Schildershop_Illu from './assets/240424_Schildershop_Illu_XS.webp';
import { ColorVariant } from '../../theme';
export var EmptyCardVariant;
(function (EmptyCardVariant) {
    EmptyCardVariant["WithIllustration"] = "WithIllustration";
    EmptyCardVariant["Default"] = "Default";
})(EmptyCardVariant || (EmptyCardVariant = {}));
const Wrapper = styled.div `
  background: ${props => props.theme.color.pearl};
  padding: 30px;
  display: grid;
  gap: 10px;
  width: 100%;
  grid-template-columns: auto;
  grid-template-rows: auto auto auto;
  border-radius: 5px;

  @media ${props => props.theme.device.tablet} {
    padding: 0 30px;

    grid-template-columns: 1fr 2fr 1fr;
    grid-template-rows: auto;

    ${props => props.variant === EmptyCardVariant.WithIllustration &&
    `
      padding: 30px;
      padding-bottom:0;

      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto auto;

      `}
  }

  @media ${props => props.theme.device.desktop} {
    grid-template-columns: min-content 2fr 1fr;
  }
`;
const Illustration = styled.div `
  background-image: url(${HostIllustrationSrc});
  background-size: contain;
  background-position: center;
  height: 270px;
  width: 100%;

  @media ${props => props.theme.device.tablet} {
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 2;

    height: 320px;
    background-position: top;
    background-size: 100%;
  }

  @media ${props => props.theme.device.desktop} {
    height: 360px;
    background-size: 80%;
  }
`;
const HeadIcon = styled.div `
  background-image: url(${SolvedIcon});
  background-size: contain;
  background-position: center;
  width: 100%;
  display: none;

  ${props => props.variant === EmptyCardVariant.Default &&
    `
    grid-column-start: 1;
    grid-row-start: 1;
    display: initial;
    width: 60px;
  `}

  @media ${props => props.theme.device.tablet} {
    width: 120px;
    height: 120px;
  }

  @media ${props => props.theme.device.desktop} {
    display: initial;
    grid-column-start: 1;
  }
`;
const DescriptionWrapper = styled.div `
  width: fit-content;
  align-self: center;
  @media ${props => props.theme.device.tablet} {
    grid-column-start: 2;

    ${props => props.variant === EmptyCardVariant.WithIllustration &&
    `
      grid-column-start: 1;
      `}
  }

  @media ${props => props.theme.device.desktop} {
    grid-column-start: 2;
  }
`;
const ButtonWrapper = styled.div `
  width: fit-content;
  align-self: center;

  @media ${props => props.theme.device.tablet} {
    grid-column-start: 3;
    justify-self: end;

    ${props => props.variant === EmptyCardVariant.WithIllustration &&
    `
      grid-column-start: 2;
      `}
  }

  @media ${props => props.theme.device.desktop} {
    grid-column-start: 3;
  }
`;
const Schildershop_Illustration = styled.div `
  background-image: url(${Schildershop_Illu});
  background-size: contain;
  background-position: center;
  width: 120px;
  height: 120px;
`;
const EmptyCard = ({ description, button, buttonLabel, variant = EmptyCardVariant.Default, plateShoopIcon = false, }) => {
    return (_jsxs(Wrapper, Object.assign({ variant: variant }, { children: [variant === EmptyCardVariant.WithIllustration && (_jsx(Illustration, {})), plateShoopIcon ? (_jsx(Schildershop_Illustration, {})) : (_jsx(HeadIcon, { variant: variant })), _jsx(DescriptionWrapper, Object.assign({ variant: variant }, { children: _jsx(Text, Object.assign({ as: "p", variant: TextVariant.B20, colorVariant: ColorVariant.Stone }, { children: description })) })), button && buttonLabel && (_jsx(ButtonWrapper, Object.assign({ variant: variant }, { children: _jsx(Button, Object.assign({ sizeVariant: ButtonSizeVariant.Small, colorVariant: ButtonColorVariant.Dark, label: buttonLabel }, button)) })))] })));
};
export default EmptyCard;
