import { MarketplaceCurrency, PaymentMode } from '../config';
export const getPaymentMethods = (instantBookingAvailable, currency, paymentMode) => {
    const paymentMethods = ['card'];
    if (paymentMode === PaymentMode.Legacy) {
        return paymentMethods;
    }
    paymentMethods.push(...['klarna', 'paypal']);
    if (instantBookingAvailable && currency !== MarketplaceCurrency.CHF) {
        paymentMethods.push('giropay');
    }
    if (instantBookingAvailable && currency === MarketplaceCurrency.CHF) {
        paymentMethods.push('twint');
    }
    return paymentMethods;
};
