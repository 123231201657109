import * as z from 'zod';
import { localizedZodCustomErrorMap } from '../localizedZodCustomErrorMap';
export const MYCABIN_MISSING_LAST_NAME_PLACEHOLDER = '?';
export const MAX_AMOUNT_OF_LANGUAGE_SKILLS = 6;
export const UserProfileSchema = z.object({
    firstName: z.string().min(1),
    lastName: z
        .string()
        .min(1)
        .refine(value => value !== MYCABIN_MISSING_LAST_NAME_PLACEHOLDER, {
        message: 'Input.validationMessage.required',
    }),
    biography: z.string(),
    languageSkills: z.array(z.string()).min(1).max(MAX_AMOUNT_OF_LANGUAGE_SKILLS),
});
const PhoneNumberSchema = z.object({
    phoneNumber: z.object({
        country: z.string(),
        formattedValue: z.string(),
        nationalNumber: z.string(),
        number: z.string(),
    }), // change this into satisfies when typescript version is upgraded to > 4.9
});
export const getUserProfileSchema = (intl) => {
    z.setErrorMap(localizedZodCustomErrorMap(intl, 'UserSchemaError'));
    return UserProfileSchema;
};
export const getMissingLastNameOrPhoneNumberSchema = (intl) => {
    z.setErrorMap(localizedZodCustomErrorMap(intl, 'UserSchemaError'));
    return UserProfileSchema.pick({
        lastName: true,
    }).merge(PhoneNumberSchema);
};
export const getUserProfileDefaultValues = (currentUser) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    return ({
        firstName: (_a = currentUser === null || currentUser === void 0 ? void 0 : currentUser.attributes) === null || _a === void 0 ? void 0 : _a.profile.firstName,
        lastName: (_c = (_b = currentUser === null || currentUser === void 0 ? void 0 : currentUser.attributes) === null || _b === void 0 ? void 0 : _b.profile) === null || _c === void 0 ? void 0 : _c.lastName,
        biography: (_f = (_e = (_d = currentUser === null || currentUser === void 0 ? void 0 : currentUser.attributes) === null || _d === void 0 ? void 0 : _d.profile.publicData) === null || _e === void 0 ? void 0 : _e.localizedPublicData) === null || _f === void 0 ? void 0 : _f.biography,
        languageSkills: (_h = (_g = currentUser === null || currentUser === void 0 ? void 0 : currentUser.attributes) === null || _g === void 0 ? void 0 : _g.profile.publicData) === null || _h === void 0 ? void 0 : _h.languageSkills,
    });
};
