import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styled from 'styled-components/macro';
import Text, { TextVariant } from '../../Text/Text';
import { ColorVariant } from '../../../theme';
const DetailElementWrapper = styled.div `
  display: flex;
  justify-content: space-between;
  background-color: ${props => props.theme.color.transparent};
  border-radius: 5px;
  padding: 3px 0px;
  gap: 15px;
`;
const LeftItemContainer = styled.div `
  display: flex;
  align-items: center;
  max-width: 65%;
`;
const DetailImage = styled.img `
  width: 18px;
  height: 18px;
  margin-right: 5px;
  border-radius: 5px;
`;
const ItemText = styled(Text) `
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const DetailElement = ({ leftText, rightText, isTotal, picture, }) => {
    return (_jsxs(DetailElementWrapper, { children: [_jsxs(LeftItemContainer, { children: [picture && _jsx(DetailImage, { src: picture }), _jsx(ItemText, Object.assign({ colorVariant: ColorVariant.Tar, variant: TextVariant.B60 }, { children: leftText || '' }))] }), _jsx(ItemText, Object.assign({ colorVariant: ColorVariant.Tar, variant: isTotal ? TextVariant.B50 : TextVariant.B60 }, { children: rightText }))] }));
};
export default DetailElement;
