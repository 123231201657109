var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useState, useEffect } from 'react';
import { Controller, } from 'react-hook-form';
import { useIntl } from 'react-intl';
import Decimal from '@nomady/shared/utils/decimal';
import { types as sdkTypes } from '@nomady/shared/utils/sdkLoader';
import { isSafeNumber, unitDivisor, convertMoneyToNumber, ensureDotSeparator, ensureSeparator, truncateToSubUnitPrecision, convertUnitToSubUnit, } from '@nomady/shared/utils/currency';
import Input from '../Input/Input';
import config from '../../config';
const { Money } = sdkTypes;
const getPrice = (unformattedValue, currencyConfig) => {
    const isEmptyString = unformattedValue === '';
    try {
        return isEmptyString
            ? null
            : convertUnitToSubUnit(unformattedValue, unitDivisor(currencyConfig.currency));
    }
    catch (e) {
        return null;
    }
};
const InternalField = (_a) => {
    var { field, fieldState, formState } = _a, props = __rest(_a, ["field", "fieldState", "formState"]);
    const intl = useIntl();
    const [formattedValue, setFormattedValue] = useState();
    const [unformattedValue, setUnformattedValue] = useState();
    const [editActive, setEditActive] = useState(false);
    const [usesComma, setUsesComma] = useState(false);
    const [currencyConfig, setCurrencyConfig] = useState();
    useEffect(() => {
        const { defaultValue } = props;
        const value = new Money(field.value, field.value.currency);
        if (!value) {
            return;
        }
        const currencyConfig = config.getCurrencyConfig(field.value.currency);
        setCurrencyConfig(currencyConfig);
        const initialValueIsMoney = value instanceof Money;
        const hasValue = value.amount || value.amount === 0;
        const initialValue = initialValueIsMoney && hasValue
            ? convertMoneyToNumber(value.amount)
            : defaultValue;
        const hasInitialValue = typeof initialValue === 'number' && !Number.isNaN(initialValue);
        const testSubUnitFormat = intl.formatNumber(1.1, currencyConfig);
        const usesComma = testSubUnitFormat.includes(',');
        try {
            const unformattedValue = hasInitialValue
                ? truncateToSubUnitPrecision(ensureSeparator(initialValue.toString(), usesComma), unitDivisor(currencyConfig.currency), usesComma)
                : '';
            const formattedValue = hasInitialValue
                ? intl.formatNumber(Number.parseFloat(ensureDotSeparator(unformattedValue)), currencyConfig)
                : '';
            setFormattedValue(formattedValue);
            setUnformattedValue(unformattedValue);
            setUsesComma(usesComma);
        }
        catch (e) {
            console.error(e);
        }
    }, [field.value.amount, field.value.currency]);
    const onInputChange = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const unformattedValue = updateValues(event);
        if (unformattedValue) {
            const price = getPrice(ensureDotSeparator(unformattedValue), currencyConfig);
            if (price !== null) {
                field.onChange(new Money(price, field.value.currency));
            }
        }
        else {
            field.onChange(new Money(0, field.value.currency));
        }
    };
    const onInputBlur = () => {
        setEditActive(false);
    };
    const onInputFocus = () => {
        setEditActive(true);
    };
    const updateValues = (event) => {
        if (!currencyConfig) {
            return console.error('No currency config found');
        }
        try {
            const targetValue = event.target.value.trim();
            const isEmptyString = targetValue === '';
            const valueOrZero = isEmptyString ? '0' : targetValue;
            const targetDecimalValue = isEmptyString
                ? null
                : new Decimal(ensureDotSeparator(targetValue));
            const isSafeValue = isEmptyString ||
                ((targetDecimalValue === null || targetDecimalValue === void 0 ? void 0 : targetDecimalValue.isPositive()) && isSafeNumber(targetDecimalValue));
            if (!isSafeValue) {
                throw new Error(`Unsafe money value: ${targetValue}`);
            }
            const truncatedValueString = truncateToSubUnitPrecision(valueOrZero, unitDivisor(currencyConfig.currency), usesComma);
            const unformattedValue = !isEmptyString ? truncatedValueString : '';
            const formattedValue = !isEmptyString
                ? intl.formatNumber(Number.parseFloat(ensureDotSeparator(truncatedValueString)), currencyConfig)
                : '';
            setUnformattedValue(unformattedValue);
            setFormattedValue(formattedValue);
            return unformattedValue;
        }
        catch (e) {
            console.error(e);
            return unformattedValue;
        }
    };
    return (_jsx(Input, Object.assign({}, props, field, { error: props.error, name: props.name, value: editActive ? unformattedValue : formattedValue, onFocus: onInputFocus, onBlur: onInputBlur, onChange: onInputChange })));
};
const InputPrice = (_a) => {
    var { control, rules } = _a, props = __rest(_a, ["control", "rules"]);
    return (_jsx(Controller, { render: fieldProps => _jsx(InternalField, Object.assign({}, fieldProps, props)), name: props.name, control: control, rules: rules }));
};
export default InputPrice;
