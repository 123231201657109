var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import moment from 'moment';
import { TOURIST_TAX_REPORT_TYPE } from '@nomady/shared/config';
import SignedInPageContainer from '../../styled-components/PageContainer/SignedInPageContainer';
import config from '../../config';
import FileDownloadTable from '../../styled-components/FileDownloadTable/FileDownloadTable';
import BottomSheet, { BottomSheetLeft, BottomSheetRight, BottomSheetTitle, } from '../../styled-components/BottomSheet/BottomSheet';
import Button, { ButtonColorVariant, ButtonOutlineVariant, ButtonSizeVariant, } from '../../styled-components/Button/Button';
import RadioInput, { RadioInputSpacingVariant, } from '../../styled-components/RadioInput/RadioInput';
import { downloadTouristTaxReport } from '../../util/api';
import Illustration from './static/illustration.png';
import { TextVariant } from '../../styled-components/Text/Text';
import LayoutSideNavigation from '../../styled-components/LayoutSideNavigation/LayoutSideNavigation';
export var FileType;
(function (FileType) {
    FileType["PDF"] = "PDF";
    FileType["CSV"] = "CSV";
})(FileType || (FileType = {}));
const PageWrapper = styled.div `
  width: 100%;
`;
const BottomSheetWrapper = styled.div `
  display: flex;
  flex-direction: column;

  @media ${props => props.theme.device.tablet} {
    flex-direction: row;
    margin-top: 50px;
    margin-bottom: 200px;
  }
`;
const ButtonWrap = styled.div `
  width: 150px;
  margin-left: auto;
  margin-right: auto;
  @media ${props => props.theme.device.tablet} {
    margin-left: initial;
    margin-right: initial;
    margin-top: 25px;
  }
`;
const RadioWrap = styled.div `
  margin-top: 20px;
`;
const TouristTaxReportsPage = ({ tabs }) => {
    const intl = useIntl();
    const { siteTitle } = config;
    const schemaTitle = intl.formatMessage({ id: 'ContactDetailsPage.schemaTitle' }, { siteTitle });
    const reports = useSelector((state) => state.TouristTaxReportsPage.touristTaxReports);
    const [chosenReport, setChosenReport] = useState(undefined);
    const [checkedFileType, setCheckedFileType] = useState(FileType.PDF);
    const touristTaxReportDownloadRef = useRef(null);
    const downloadFile = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!chosenReport) {
            return;
        }
        const fileStream = yield downloadTouristTaxReport(chosenReport.id, checkedFileType.toLowerCase());
        const blobUrl = URL.createObjectURL(new Blob([fileStream], { type: 'application/octet-stream' }));
        const fileName = chosenReport.readableFilename || chosenReport.id;
        const fileNameWithType = `${fileName}.${checkedFileType}`.toLowerCase();
        if (touristTaxReportDownloadRef.current) {
            touristTaxReportDownloadRef.current.href = blobUrl;
            touristTaxReportDownloadRef.current.download = fileNameWithType;
            touristTaxReportDownloadRef.current.click();
        }
        setChosenReport(undefined);
    });
    const getInformation = (touristTaxReport) => {
        if (touristTaxReport.type === TOURIST_TAX_REPORT_TYPE.Monthly) {
            return {
                name: `${moment(touristTaxReport.start_date)
                    .format('MMM YYYY')
                    .toUpperCase()}`.replaceAll('.', ''),
                meta: intl.formatMessage({ id: 'TouristTaxReportsPage.monthly' }),
            };
        }
        if (touristTaxReport.type === TOURIST_TAX_REPORT_TYPE.Biannual) {
            return {
                name: `${moment(touristTaxReport.start_date)
                    .format('MMM')
                    .toUpperCase()}–${moment(touristTaxReport.end_date)
                    .format('MMM YYYY')
                    .toUpperCase()}`.replaceAll('.', ''),
                meta: intl.formatMessage({ id: 'TouristTaxReportsPage.biannual' }),
            };
        }
        if (touristTaxReport.type === TOURIST_TAX_REPORT_TYPE.Annual) {
            return {
                name: `${moment(touristTaxReport.start_date)
                    .format('MMM')
                    .toUpperCase()}–${moment(touristTaxReport.end_date)
                    .format('MMM YYYY')
                    .toUpperCase()}`.replaceAll('.', ''),
                meta: intl.formatMessage({ id: 'TouristTaxReportsPage.annual' }),
            };
        }
        return {
            name: 'N/A',
            meta: 'N/A',
        };
    };
    const reportFiles = reports.map(report => {
        const { name, meta } = getInformation(report);
        return {
            id: report.id,
            name,
            meta,
            readableFilename: report.readable_filename,
        };
    });
    const bottomSheetTitle = chosenReport
        ? `${chosenReport.name} - ${chosenReport.meta}`
        : '';
    return (_jsx(SignedInPageContainer, Object.assign({ title: schemaTitle, noIndex: true }, { children: _jsx(LayoutSideNavigation, Object.assign({ tabs: tabs }, { children: _jsxs(PageWrapper, { children: [_jsx(FileDownloadTable, { files: reportFiles, title: intl.formatMessage({
                            id: 'TouristTaxReportsPage.title',
                        }), startDownload: (id) => {
                            setChosenReport(reportFiles.find(file => file.id === id));
                        } }), _jsx(BottomSheetWrapper, { children: _jsxs(BottomSheet, Object.assign({ open: !!chosenReport, onClose: () => setChosenReport(undefined) }, { children: [_jsx(BottomSheetLeft, { children: _jsx("img", { src: Illustration, alt: "illustration", style: { width: 290 }, "aria-hidden": true }) }), _jsxs(BottomSheetRight, { children: [_jsx(BottomSheetTitle, Object.assign({ variant: TextVariant.H4 }, { children: bottomSheetTitle })), _jsxs(RadioWrap, { children: [_jsx(RadioInput, { id: 'pdf', checked: checkedFileType === FileType.PDF, label: 'PDF', spacingVariant: RadioInputSpacingVariant.Slim, onChange: () => checkedFileType !== FileType.PDF &&
                                                        setCheckedFileType(FileType.PDF) }), _jsx(RadioInput, { id: 'csv', checked: checkedFileType === FileType.CSV, label: 'CSV', spacingVariant: RadioInputSpacingVariant.Slim, onChange: () => checkedFileType !== FileType.CSV &&
                                                        setCheckedFileType(FileType.CSV) })] }), _jsx(ButtonWrap, { children: _jsx("a", Object.assign({ onClick: () => downloadFile(), ref: touristTaxReportDownloadRef }, { children: _jsx(Button, { label: intl.formatMessage({
                                                        id: 'TouristTaxReportsPage.downloadButton',
                                                    }), sizeVariant: ButtonSizeVariant.Small, outlineVariant: ButtonOutlineVariant.Outlined, colorVariant: ButtonColorVariant.Transparent }) })) })] })] })) })] }) })) })));
};
export default TouristTaxReportsPage;
