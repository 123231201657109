import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { ReactComponent as InstagramIcon } from './assets/Company=Instagram, Style=Gray, Background=None.svg';
import { ReactComponent as YouTubeIcon } from './assets/Company=YouTube, Style=Gray, Background=None.svg';
import { ReactComponent as FacebookIcon } from './assets/Company=Facebook, Style=Gray, Background=None.svg';
import { ReactComponent as LinkedInIcon } from './assets/Company=LinkedIn, Style=Gray, Background=None.svg';
import { ReactComponent as PinterestIcon } from './assets/Company=Pinterest, Style=Gray, Background=None.svg';
import { ReactComponent as TikTokIcon } from './assets/Company=TikTok, Style=Gray, Background=None.svg';
import { ReactComponent as WhatsAppIcon } from './assets/Company=Whatsapp, Style=Gray, Background=None.svg';
import { ReactComponent as MailIcon } from './assets/mail.svg';
export var MediaType;
(function (MediaType) {
    MediaType["Instagram"] = "instagram";
    MediaType["YouTube"] = "youtube";
    MediaType["Facebook"] = "facebook";
    MediaType["LinkedIn"] = "linkedin";
    MediaType["Pinterest"] = "pinterest";
    MediaType["TikTok"] = "tiktok";
    MediaType["WhatsApp"] = "whatsapp";
    MediaType["Mail"] = "mail";
})(MediaType || (MediaType = {}));
const mediaLinks = {
    [MediaType.Instagram]: 'https://www.instagram.com/nomady.camp/',
    [MediaType.YouTube]: 'https://www.youtube.com/@nomadycamp',
    [MediaType.Facebook]: 'https://www.facebook.com/Nomady.camp/',
    [MediaType.LinkedIn]: 'https://www.linkedin.com/company/nomadycamp/',
    [MediaType.Pinterest]: 'https://www.pinterest.ch/nomadycamps/',
    [MediaType.TikTok]: 'https://www.tiktok.com/@nomady.camp',
    [MediaType.WhatsApp]: 'https://whatsapp.com/channel/0029VadZ2NsLdQeia7WGX12C',
    [MediaType.Mail]: 'mailto:hello@nomady.camp',
};
const IconComponent = ({ mediaType }) => {
    switch (mediaType) {
        case MediaType.Instagram:
            return _jsx(InstagramIcon, {});
        case MediaType.YouTube:
            return _jsx(YouTubeIcon, {});
        case MediaType.Facebook:
            return _jsx(FacebookIcon, {});
        case MediaType.LinkedIn:
            return _jsx(LinkedInIcon, {});
        case MediaType.Pinterest:
            return _jsx(PinterestIcon, {});
        case MediaType.TikTok:
            return _jsx(TikTokIcon, {});
        case MediaType.WhatsApp:
            return _jsx(WhatsAppIcon, {});
        case MediaType.Mail:
            return _jsx(MailIcon, {});
        default:
            return null;
    }
};
const MediaIcon = ({ media }) => {
    return (_jsx("a", Object.assign({ href: mediaLinks[media], target: "_blank", rel: "noopener noreferrer" }, { children: _jsx(IconComponent, { mediaType: media }) })));
};
export default MediaIcon;
