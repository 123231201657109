import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styled from 'styled-components/macro';
import { useIntl } from 'react-intl';
import Text, { TextVariant } from '../../../styled-components/Text/Text';
import Icon, { IconVariant } from '../../../styled-components/Icon/Icon';
import ReviewRating from '../../../styled-components/Review/ReviewRating';
import { ColorVariant } from '../../../theme';
import campStyleIcons from '../../../campStyleIcons';
const Card = styled.div `
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background-color: ${props => props.theme.color.pearl};
  padding: 8px 10px 2px;
`;
const TopRow = styled.div `
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 8px;
  border-bottom: 1px solid ${props => props.theme.color.stone};
`;
const StyledText = styled(Text) `
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 240px;
`;
const TextContainer = styled.div `
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 80%;
`;
const Image = styled.img `
  height: 42px;
  width: 56px;
  border-radius: 5px;
  object-fit: cover;
  margin-left: 10px;
`;
const LocationContainer = styled.div `
  display: flex;
  align-items: center;
`;
const LocationPinContainer = styled.div `
  margin-left: -6px;
`;
const MiddleRow = styled.div `
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px 0;
  border-bottom: 1px solid ${props => props.theme.color.stone};
`;
const BottomRow = styled.div `
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 3px;
`;
const CampTypesContainer = styled.div `
  display: flex;
  align-items: center;
`;
const ListingPreviewCard = ({ title, address, hostName, imageSrc, averageRating, campStyles, }) => {
    const intl = useIntl();
    return (_jsxs(Card, { children: [_jsxs(TopRow, { children: [_jsxs(TextContainer, { children: [_jsx(StyledText, Object.assign({ variant: TextVariant.H51, "data-testid": "ListingPreviewCard_title" }, { children: title })), _jsxs(LocationContainer, { children: [_jsx(LocationPinContainer, { children: _jsx(Icon, { colorVariant: ColorVariant.Cave, variant: IconVariant.SimplePin }) }), _jsx(StyledText, Object.assign({ colorVariant: ColorVariant.Cave, variant: TextVariant.B50 }, { children: address }))] })] }), _jsx(Image, { src: imageSrc, alt: "listing" })] }), _jsxs(MiddleRow, { children: [_jsx(CampTypesContainer, { children: campStyles === null || campStyles === void 0 ? void 0 : campStyles.map(campStyle => (_jsx(Icon, { variant: campStyleIcons[campStyle] }, `icon-${campStyle}`))) }), _jsx(ReviewRating, { textVariant: TextVariant.H50, rating: averageRating })] }), _jsxs(BottomRow, { children: [_jsx(Text, Object.assign({ colorVariant: ColorVariant.Cave, variant: TextVariant.B50 }, { children: intl.formatMessage({ id: 'ListingPreviewCard.host' }) })), _jsx(Text, Object.assign({ colorVariant: ColorVariant.Cave, variant: TextVariant.B50, "data-testid": "ListingPreviewCard_hostName" }, { children: hostName }))] })] }));
};
export default ListingPreviewCard;
