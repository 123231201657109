import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FilterDirection, } from '@nomady/shared/marketplace-custom-config';
import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import styled from 'styled-components/macro';
import BottomSheet from '../BottomSheet/BottomSheet';
import { ButtonSizeVariant, buttonBaseStyles, } from '../Button/Button';
import Icon, { IconVariant } from '../Icon/Icon';
import RadioInput, { RadioInputSpacingVariant } from '../RadioInput/RadioInput';
import Text from '../Text/Text';
const Wrapper = styled.div ``;
const ButtonWrapper = styled.div `
  display: flex;

  ${props => buttonBaseStyles(props)}
  cursor: default;
`;
const DropdownButton = styled.button `
  border: none;
  background: ${props => props.theme.color.pearl};
  cursor: pointer;
`;
const DirectionButton = styled.button `
  border: none;
  background: ${props => props.theme.color.pearl};
  cursor: pointer;
`;
const Dropdown = styled.div `
  position: relative;

  ${props => props.theme.priority.low};

  display: none;

  @media ${props => props.theme.device.tablet} {
    display: ${props => (props.isOpen ? 'block' : 'none')};
  }
`;
const InnerDropdown = styled.div `
  background-color: ${props => props.theme.color.pearl};
  position: absolute;
  top: 3px;
  min-width: 280px;
  padding: 15px;
  box-shadow: ${props => props.theme.shadow.medium};
  border-radius: 10px;
`;
const FilterDropdown = ({ onChange, intl, filters, filterSelection, directionSelection = FilterDirection.ASC, hasDirection = false, }) => {
    var _a;
    const [isOpen, setIsOpen] = useState(false);
    const [direction, setDirection] = useState(directionSelection);
    const resolveDefaultDirection = (key) => {
        var _a;
        return (_a = filters.find(filter => filter.key === key)) === null || _a === void 0 ? void 0 : _a.defaultDirection;
    };
    const directionClicked = (filterSelection) => {
        let newDirection;
        if (direction === FilterDirection.DESC) {
            newDirection = FilterDirection.ASC;
        }
        else {
            newDirection = FilterDirection.DESC;
        }
        setDirection(newDirection);
        const currentFilter = filters.find(option => filterSelection.includes(option.key));
        if (currentFilter) {
            onChange(currentFilter.key, newDirection);
        }
    };
    const dropdownButton = (_jsx(DropdownButton, Object.assign({ onClick: () => setIsOpen(!isOpen) }, { children: _jsx(Icon, { variant: IconVariant.ArrowDown }) })));
    const RadioInputOrderList = filters === null || filters === void 0 ? void 0 : filters.map((filter) => (_jsx(RadioInput, { id: filter.key, name: "review_filters", label: intl.formatMessage({
            id: filter.label,
        }), spacingVariant: RadioInputSpacingVariant.Slim, checked: filterSelection === null || filterSelection === void 0 ? void 0 : filterSelection.endsWith(filter.key), onChange: () => {
            const newDirection = resolveDefaultDirection(filter.key) || direction;
            setDirection(newDirection);
            onChange(filter.key, newDirection);
            setIsOpen(false);
        } }, filter.key)));
    return (_jsxs(Wrapper, { children: [_jsxs(ButtonWrapper, Object.assign({ sizeVariant: ButtonSizeVariant.ExtraSmall }, { children: [hasDirection ? (_jsx(DirectionButton, Object.assign({ onClick: () => directionClicked(filterSelection) }, { children: _jsx(Icon, { variant: direction === FilterDirection.DESC
                                ? IconVariant.SortDesc
                                : IconVariant.SortAsc }) }))) : (dropdownButton), _jsx(Text, Object.assign({ onClick: () => setIsOpen(!isOpen) }, { children: intl.formatMessage({
                            id: ((_a = filters.find(filter => filterSelection.endsWith(filter.key))) === null || _a === void 0 ? void 0 : _a.label) || '',
                        }) })), hasDirection && dropdownButton] })), _jsx(Dropdown, Object.assign({ isOpen: isOpen }, { children: _jsx(InnerDropdown, { children: _jsx("form", { children: RadioInputOrderList }) }) })), _jsx(BottomSheet, Object.assign({ open: isOpen, onClose: () => setIsOpen(false), hideOnDesktop: true }, { children: _jsx("form", { children: RadioInputOrderList }) }))] }));
};
export default injectIntl(FilterDropdown);
