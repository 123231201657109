var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable no-param-reassign */
import moment from 'moment';
import { createSlice, } from '@reduxjs/toolkit';
import merge from 'lodash/merge';
import { denormalisedResponseEntities } from '@nomady/shared/utils/data';
import { types as sdkTypes } from '@nomady/shared/utils/sdkLoader';
import { LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT, } from '@nomady/shared/utils/urlHelpers';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { apiLocaleSelector, getInitialPageTranslationActiveSelector, localeSelector, } from '../../slices/UISlice';
import * as api from '../../util/api';
import { addMarketplaceEntities, getMarketplaceEntities, } from '../../ducks/marketplaceData.duck';
import { storableError } from '../../util/errors';
import config from '../../config';
import { defaultBookingData, } from '../CheckoutPage/utils/BookingData';
import getNearbyListings from '../../util/search-query/getNearbyListings';
import { userIsAllowedToStartBookingProcessSelector } from '../../ducks/Auth.duck';
import { clearData, retrieveData, storeData } from './utils/localStorage';
export const STORAGE_KEY_BOOKING_AUTH_FLOW = 'BookingAuthFlow';
const { UUID } = sdkTypes;
export var ListingContentModalVariant;
(function (ListingContentModalVariant) {
    ListingContentModalVariant["LOCATION"] = "Location";
    ListingContentModalVariant["BOOKING_DATES"] = "BookingDates";
    ListingContentModalVariant["BOOKING_GUESTS"] = "BookingGuests";
    ListingContentModalVariant["BOOKING_EXTRAS"] = "BookingExtras";
    ListingContentModalVariant["REVIEWS_LIST"] = "ReviewsList";
    ListingContentModalVariant["SHOP"] = "Shop";
    ListingContentModalVariant["DOGS"] = "Dogs";
    ListingContentModalVariant["ACCESS"] = "Access";
    ListingContentModalVariant["FIREPLACE"] = "Fireplace";
    ListingContentModalVariant["AMENITIES"] = "Amenities";
    ListingContentModalVariant["ENVIRONMENT"] = "Environment";
    ListingContentModalVariant["ACTIVITIES"] = "Activities";
    ListingContentModalVariant["LISTING_STYLE"] = "ListingStyle";
    ListingContentModalVariant["CANCELLATION"] = "Cancellation";
    ListingContentModalVariant["ARRIVAL"] = "Arrival";
    ListingContentModalVariant["FAQ"] = "Faq";
    ListingContentModalVariant["RULES"] = "Rules";
    ListingContentModalVariant["CAMP_SPOTS"] = "CampSpots";
    ListingContentModalVariant["INSTANT_BOOKING"] = "InstantBooking";
    ListingContentModalVariant["VERIFIED"] = "Verified";
    ListingContentModalVariant["CAMP_STYLE_DETAILS"] = "CampStyleDetails";
    ListingContentModalVariant["BREAKDOWN"] = "Breakdown";
    ListingContentModalVariant["ERROR_CLOSED"] = "ErrorClosed";
    ListingContentModalVariant["ERROR_FAILED_LOADING"] = "ErrorFailedLoading";
    ListingContentModalVariant["PUBLIC_TRANSPORT"] = "PublicTransport";
    ListingContentModalVariant["MISSING_PERSONAL_DATA"] = "MissingPersonalData";
    ListingContentModalVariant["POWER_SUPPLY"] = "PowerSupply";
})(ListingContentModalVariant || (ListingContentModalVariant = {}));
const initialState = {
    translationActive: false,
    fetchListingInProgress: false,
    fetchListingError: null,
    listing: null,
    fetchReviewsInProgress: false,
    fetchReviewsError: null,
    reviews: {},
    reviewsPagination: { totalPages: 0, activePage: 1, totalReviews: 0 },
    fetchProviderInProgress: false,
    fetchProviderError: null,
    provider: null,
    fetchTimeSlotsInProgress: false,
    fetchTimeSlotsError: null,
    timeSlots: null,
    showPictureOverviewModal: false,
    nearbyListings: [],
    showPictureLightbox: false,
    currentPictureLightboxImage: undefined,
    showReviewsModal: false,
    bookingStart: undefined,
    bookingEnd: undefined,
    bookingData: defaultBookingData,
    guestsChecked: false,
};
export const ListingPageSlice = createSlice({
    name: 'app/ListingPage',
    initialState,
    reducers: {
        resetListingPage: () => {
            return initialState;
        },
        fetchListingRequest: state => {
            state.fetchListingInProgress = true;
            state.fetchListingError = null;
        },
        fetchListingSuccess: (state, action) => {
            state.fetchListingInProgress = false;
            state.listing = action.payload;
        },
        fetchListingError: (state, action) => {
            state.fetchListingInProgress = false;
            state.fetchListingError = action.payload;
        },
        fetchReviewsRequest: state => {
            state.fetchReviewsInProgress = true;
            state.fetchReviewsError = null;
        },
        fetchReviewsSuccess: (state, action) => {
            state.fetchReviewsInProgress = false;
            state.reviews[action.payload.index] = action.payload.reviews;
        },
        setReviewsPagination: (state, action) => {
            state.reviewsPagination = action.payload;
        },
        fetchReviewsError: (state, action) => {
            state.fetchReviewsInProgress = false;
            state.fetchReviewsError = action.payload;
        },
        fetchProviderRequest: state => {
            state.fetchProviderInProgress = true;
            state.fetchProviderError = null;
        },
        fetchProviderSuccess: (state, action) => {
            state.fetchProviderInProgress = false;
            state.provider = action.payload;
        },
        fetchProviderError: (state, action) => {
            state.fetchProviderInProgress = false;
            state.fetchProviderError = action.payload;
        },
        fetchTimeSlotsRequest: state => {
            state.fetchTimeSlotsInProgress = true;
            state.fetchTimeSlotsError = null;
        },
        fetchTimeSlotsSuccess: (state, action) => {
            state.fetchTimeSlotsInProgress = false;
            state.timeSlots = action.payload;
        },
        fetchTimeSlotsError: (state, action) => {
            state.fetchTimeSlotsInProgress = false;
            state.fetchTimeSlotsError = action.payload;
        },
        setTranslationActive: (state, action) => {
            state.translationActive = action.payload;
        },
        setShowPictureOverviewModal: (state, action) => {
            state.showPictureOverviewModal = action.payload;
        },
        setNearbyListings: (state, action) => {
            state.nearbyListings = action.payload;
        },
        setShowPictureLightbox: (state, action) => {
            state.showPictureLightbox = action.payload;
        },
        setCurrentPictureLightboxImage: (state, action) => {
            state.showPictureLightbox = true;
            state.currentPictureLightboxImage = action.payload;
        },
        setShowReviewsModal: (state, action) => {
            state.showReviewsModal = action.payload;
        },
        setBookingStart: (state, action) => {
            state.bookingStart = action.payload;
        },
        setBookingEnd: (state, action) => {
            state.bookingEnd = action.payload;
        },
        setBookingData: (state, action) => {
            state.bookingData = merge({}, state.bookingData, action.payload);
        },
        setGuestsChecked: (state, action) => {
            state.guestsChecked = action.payload;
        },
        setProviderStripeAccountId: (state, action) => {
            state.providerStripeAccountId = action.payload;
        },
    },
});
export const { fetchListingRequest, fetchListingSuccess, fetchListingError, fetchReviewsRequest, fetchReviewsSuccess, setReviewsPagination, fetchReviewsError, fetchProviderRequest, fetchProviderSuccess, fetchProviderError, setTranslationActive, fetchTimeSlotsRequest, fetchTimeSlotsSuccess, fetchTimeSlotsError, setShowPictureOverviewModal, setNearbyListings, setShowPictureLightbox, setCurrentPictureLightboxImage, setShowReviewsModal, setGuestsChecked, resetListingPage, setProviderStripeAccountId, } = ListingPageSlice.actions;
export const storeBookingDataAndDates = () => (_, getState) => __awaiter(void 0, void 0, void 0, function* () {
    const state = getState();
    const userIsAllowedToStartBookingProcess = userIsAllowedToStartBookingProcessSelector(state);
    if (userIsAllowedToStartBookingProcess) {
        return;
    }
    const bookingData = bookingDataSelector(state);
    const bookingDates = bookingDatesSelector(state);
    const { listing } = state.ListingPage;
    storeData(STORAGE_KEY_BOOKING_AUTH_FLOW, {
        bookingData,
        bookingDates,
        listing: listing !== null && listing !== void 0 ? listing : undefined,
    });
});
export const retrieveBookingDataAndDates = () => (dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b;
    const bookingAuthFlow = retrieveData(STORAGE_KEY_BOOKING_AUTH_FLOW);
    dispatch(setBookingStart((_a = bookingAuthFlow.bookingDates) === null || _a === void 0 ? void 0 : _a.bookingStart));
    dispatch(setBookingEnd((_b = bookingAuthFlow.bookingDates) === null || _b === void 0 ? void 0 : _b.bookingEnd));
    if (bookingAuthFlow.bookingData) {
        dispatch(setBookingData(bookingAuthFlow.bookingData));
    }
    dispatch(setGuestsChecked(true));
    if (bookingAuthFlow.listing) {
        dispatch(fetchListingSuccess(bookingAuthFlow.listing));
    }
    clearData(STORAGE_KEY_BOOKING_AUTH_FLOW);
});
export const setBookingStart = (bookingStart) => (dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
    dispatch(ListingPageSlice.actions.setBookingStart(bookingStart));
    dispatch(storeBookingDataAndDates());
});
export const setBookingEnd = (bookingEnd) => (dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
    dispatch(ListingPageSlice.actions.setBookingEnd(bookingEnd));
    dispatch(storeBookingDataAndDates());
});
export const setBookingData = (bookingData) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    dispatch(ListingPageSlice.actions.setBookingData(bookingData));
    dispatch(storeBookingDataAndDates());
});
export const showProvider = (providerId, apiLocale) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    dispatch(fetchProviderRequest());
    const locale = apiLocale ? { locale: apiLocale } : {};
    try {
        const response = yield api.showUser(Object.assign(Object.assign({ id: providerId }, locale), { include: ['profileImage', 'stripeAccount'], 'fields.image': ['variants.square-small', 'variants.square-small2x'] }));
        if (response) {
            const stripeAccount = (response.data.included || []).find(element => element.type === 'stripeAccount');
            if (stripeAccount) {
                dispatch(setProviderStripeAccountId(stripeAccount.attributes.stripeAccountId));
            }
            dispatch(fetchProviderSuccess(response.data.data));
        }
    }
    catch (e) {
        dispatch(fetchProviderError(storableError(e)));
    }
});
export const toggleTranslation = (listingId, isOwn = false) => (dispatch, getState) => {
    const state = getState();
    const translationActive = !state.ListingPage.translationActive;
    dispatch(setTranslationActive(translationActive));
    dispatch(showListing(listingId, isOwn));
    dispatch(fetchReviews(listingId));
};
export const showListing = (listingId, isOwn = false) => (dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
    var _c, _d, _e, _f;
    dispatch(fetchListingRequest());
    const state = getState();
    const apiLocale = apiLocaleSelector(state, !state.ListingPage.translationActive);
    const locale = apiLocale ? { locale: apiLocale } : {};
    const params = Object.assign(Object.assign({ id: listingId }, locale), { include: ['author', 'author.profileImage', 'images'], 'fields.image': [
            // Listing page
            'variants.landscape-crop',
            'variants.landscape-crop2x',
            'variants.landscape-crop4x',
            'variants.landscape-crop6x',
            // Social media
            'variants.facebook',
            'variants.twitter',
            // Image carousel
            'variants.scaled-small',
            'variants.scaled-medium',
            'variants.scaled-large',
            'variants.scaled-xlarge',
            // Avatars
            'variants.square-small',
            'variants.square-small2x',
        ] });
    try {
        const response = yield (isOwn
            ? api.showOwnListing(params)
            : api.showListing(params));
        dispatch(addMarketplaceEntities(response));
        const data = ((_c = response === null || response === void 0 ? void 0 : response.data) === null || _c === void 0 ? void 0 : _c.data) || null;
        const providerId = ((_f = (_e = (_d = data === null || data === void 0 ? void 0 : data.relationships) === null || _d === void 0 ? void 0 : _d.author) === null || _e === void 0 ? void 0 : _e.data) === null || _f === void 0 ? void 0 : _f.id) || null;
        if (providerId) {
            dispatch(showProvider(providerId, apiLocale));
        }
        dispatch(fetchListingSuccess(data));
    }
    catch (e) {
        console.error(e);
        dispatch(fetchListingError(storableError(e)));
    }
});
const REVIEWS_DEFAULT_AMOUNT = 10;
export const fetchReviews = (listingId, page = 1) => (dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
    var _g, _h, _j;
    const state = getState();
    const { reviews, reviewsPagination } = state.ListingPage;
    const shouldNotFetchSamePageAgain = ((_g = reviews === null || reviews === void 0 ? void 0 : reviews[page - 1]) === null || _g === void 0 ? void 0 : _g.length) > 0;
    if (shouldNotFetchSamePageAgain) {
        dispatch(setReviewsPagination(Object.assign(Object.assign({}, reviewsPagination), { activePage: page })));
        return;
    }
    dispatch(fetchReviewsRequest());
    const apiLocale = apiLocaleSelector(state, !state.ListingPage.translationActive);
    const locale = apiLocale ? { locale: apiLocale } : {};
    const params = Object.assign({ listingId,
        page, perPage: REVIEWS_DEFAULT_AMOUNT, state: 'public', include: ['author', 'author.profileImage'], 'fields.image': [
            // Avatars
            'variants.square-small',
            'variants.square-small2x',
        ] }, locale);
    try {
        const reviews = yield api.queryReviews(params);
        dispatch(fetchReviewsSuccess({ reviews: reviews.data.data, index: page - 1 }));
        dispatch(setReviewsPagination({
            totalPages: ((_h = reviews === null || reviews === void 0 ? void 0 : reviews.data) === null || _h === void 0 ? void 0 : _h.totalPages) || 0,
            totalReviews: (_j = reviews === null || reviews === void 0 ? void 0 : reviews.data) === null || _j === void 0 ? void 0 : _j.totalItems,
            activePage: page,
        }));
    }
    catch (e) {
        dispatch(fetchReviewsError(storableError(e)));
    }
});
export const fetchTimeSlots = (listingId) => (dispatch, _, sdk) => __awaiter(void 0, void 0, void 0, function* () {
    dispatch(fetchTimeSlotsRequest());
    // Time slots can be fetched for 90 days at a time,
    // for at most 366 days from now.
    const maxTimeSlotsPerApiCall = 89; // 90 days is the max, but we subtract 1 to be safe
    const bookingRange = config.dayCountAvailableForBooking - 1;
    const start = moment.utc().startOf('day').toDate();
    let allTimeSlots = [];
    try {
        let remainingDays = bookingRange;
        let currentStart = start;
        while (remainingDays > 0) {
            const currentRange = Math.min(remainingDays, maxTimeSlotsPerApiCall);
            const currentEnd = moment(currentStart)
                .add(currentRange, 'days')
                .toDate();
            const response = yield sdk.timeslots.query({
                listingId,
                start: currentStart,
                end: currentEnd,
            });
            const timeSlots = denormalisedResponseEntities(response);
            allTimeSlots = allTimeSlots.concat(timeSlots);
            remainingDays -= currentRange;
            currentStart = currentEnd;
        }
        dispatch(fetchTimeSlotsSuccess(allTimeSlots));
    }
    catch (error) {
        dispatch(fetchTimeSlotsError(storableError(error)));
    }
});
export const fetchNearbyListings = () => (dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
    var _k;
    const state = getState();
    const locale = localeSelector(state);
    const { listing } = state.ListingPage;
    if ((_k = listing === null || listing === void 0 ? void 0 : listing.attributes) === null || _k === void 0 ? void 0 : _k.geolocation) {
        const NUMBER_OF_LISTINGS = 7;
        const params = {
            page: 1,
            perPage: NUMBER_OF_LISTINGS,
            include: ['author', 'images'],
            'fields.listing': [
                'title',
                'geolocation',
                'price',
                'description',
                'publicData',
                'metadata',
            ],
            'fields.user': [
                'profile.displayName',
                'profile.abbreviatedName',
                'profile.publicData',
            ],
            'fields.image': [
                'variants.landscape-crop',
                'variants.landscape-crop2x',
                'variants.square-small2x',
            ],
        };
        const nearbyListings = yield getNearbyListings(listing.attributes.geolocation, NUMBER_OF_LISTINGS, params, locale);
        dispatch(setNearbyListings(nearbyListings.filter(nearbyListing => nearbyListing.id.uuid !== listing.id.uuid)));
    }
});
export const loadData = (params) => (dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
    var _l;
    const state = getState();
    const listingId = new UUID(params.id);
    if (((_l = state === null || state === void 0 ? void 0 : state.ListingPage) === null || _l === void 0 ? void 0 : _l.listing) &&
        (params === null || params === void 0 ? void 0 : params.id) &&
        state.ListingPage.listing.id.uuid !== params.id) {
        dispatch(resetListingPage());
    }
    dispatch(fetchCurrentUser());
    dispatch(setTranslationActive(getInitialPageTranslationActiveSelector(state)));
    const ownListingVariants = [
        LISTING_PAGE_DRAFT_VARIANT,
        LISTING_PAGE_PENDING_APPROVAL_VARIANT,
    ];
    yield dispatch(showListing(listingId, ownListingVariants.includes(params.variant)));
    // reviews and timeslots router/redux/caching problem exists via TOPBAR / Searchbar ?
    return Promise.all([
        dispatch(fetchNearbyListings()),
        dispatch(fetchTimeSlots(listingId)),
        dispatch(fetchReviews(listingId)),
    ]);
});
export default ListingPageSlice.reducer;
export const listingSelector = (state) => {
    const { id, type } = state.ListingPage.listing || {};
    if (!id || !type) {
        return null;
    }
    const ref = { id, type };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
};
export const providerStripeAccountIdSelector = (state) => state.ListingPage.providerStripeAccountId;
export const bookingDataSelector = (state) => {
    var _a, _b, _c;
    return ({
        adultQuantity: state.ListingPage.bookingData.adultQuantity,
        seatQuantity: state.ListingPage.bookingData.seatQuantity,
        teenQuantity: (_a = state.ListingPage.bookingData.teenQuantity) !== null && _a !== void 0 ? _a : 0,
        childQuantity: (_b = state.ListingPage.bookingData.childQuantity) !== null && _b !== void 0 ? _b : 0,
        infantQuantity: (_c = state.ListingPage.bookingData.infantQuantity) !== null && _c !== void 0 ? _c : 0,
    });
};
export const bookingDatesSelector = (state) => ({
    bookingStart: state.ListingPage.bookingStart,
    bookingEnd: state.ListingPage.bookingEnd,
});
