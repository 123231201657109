var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import routeConfiguration from '@nomady/shared/routeConfiguration/routeConfiguration';
import Page from '@nomady/shared/routeConfiguration/Page';
import { pathByRouteName } from '@nomady/shared/utils/routes';
import LayoutSideNavigation from '../../styled-components/LayoutSideNavigation/LayoutSideNavigation';
import Text, { TextVariant } from '../../styled-components/Text/Text';
import ProfileSettingsForm from '../../forms/ProfileSettingsForm/ProfileSettingsForm';
import { updateProfile, uploadImage } from './ProfileSettingsPageSlice';
import SignedInPageContainer from '../../styled-components/PageContainer/SignedInPageContainer';
import { currentUserSelector } from '../../ducks/user.duck';
import Button, { ButtonSizeVariant, } from '../../styled-components/Button/Button';
import { IconVariant } from '../../styled-components/Icon/Icon';
import Modal from '../../styled-components/Modal/Modal';
import { deleteCurrentUser } from '../../util/api';
import { logout } from '../../ducks/Auth.duck';
import Button2024 from '../../styled-components/Button2024/Button2024';
export const onImageUploadHandler = (values, fn) => {
    const { id, imageId, file } = values;
    if (file) {
        fn({ id, imageId, file });
    }
};
const Title = styled.div `
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;
const DeleteAccountButton = styled.div `
  margin-top: 200px;
`;
const ProfileSettingsPage = props => {
    const { tabs } = props;
    const intl = useIntl();
    const dispatch = useDispatch();
    const [isDeleteAccountModalOpen, setIsDeleteAccountModalOpen] = useState(false);
    const [accountDeleteInProgress, setAccountDeleteInProgress] = useState(false);
    const onAccountDelete = () => __awaiter(void 0, void 0, void 0, function* () {
        setAccountDeleteInProgress(true);
        yield deleteCurrentUser();
        setAccountDeleteInProgress(false);
        dispatch(logout());
    });
    const onImageUpload = (data) => dispatch(uploadImage(data));
    const onUpdateProfile = (data) => dispatch(updateProfile(data));
    const { updateInProgress, updateProfileError, uploadImageError, uploadInProgress, } = useSelector((state) => state.ProfileSettingsPage);
    const currentUser = useSelector(currentUserSelector);
    if (!currentUser) {
        return null;
    }
    const profileSettingsForm = currentUser.id ? (_jsx(ProfileSettingsForm, { currentUser: currentUser, onImageUpload: (e) => onImageUploadHandler(e, onImageUpload), updateInProgress: updateInProgress, uploadProfileInProgress: uploadInProgress, uploadImageError: uploadImageError, updateProfileError: updateProfileError, onSubmit: onUpdateProfile })) : null;
    const profileLink = currentUser.id
        ? pathByRouteName(Page.Profile, routeConfiguration, {
            id: currentUser.id.uuid,
        })
        : undefined;
    const title = intl.formatMessage({ id: 'ProfileSettingsPage.title' });
    return (_jsx(SignedInPageContainer, Object.assign({ title: title }, { children: _jsxs(LayoutSideNavigation, Object.assign({ tabs: tabs }, { children: [_jsxs(Title, { children: [_jsx(Text, Object.assign({ variant: TextVariant.H3, as: "h2" }, { children: title })), profileLink ? (_jsx(Button, { externalLink: profileLink, label: intl.formatMessage({
                                id: 'ProfileSettingsPage.viewProfileLink',
                            }), sizeVariant: ButtonSizeVariant.ExtraSmall, icon: IconVariant.Eye })) : null] }), profileSettingsForm, _jsxs(DeleteAccountButton, { children: [_jsx(Button2024, { label: intl.formatMessage({
                                id: 'ProfileSettingsForm.deleteAccount',
                            }), destructive: true, onClick: () => setIsDeleteAccountModalOpen(true) }), _jsx(Modal, { isOpen: isDeleteAccountModalOpen, onClose: () => setIsDeleteAccountModalOpen(false), title: intl.formatMessage({
                                id: 'ProfileSettingsForm.deleteAccountModalTitle',
                            }), leftButton: {
                                label: intl.formatMessage({
                                    id: 'ProfileSettingsForm.deleteAccountModalCancel',
                                }),
                                onClick: () => setIsDeleteAccountModalOpen(false),
                                isPrimary: true,
                                disabled: accountDeleteInProgress,
                            }, rightButton: {
                                label: intl.formatMessage({
                                    id: 'ProfileSettingsForm.deleteAccountModalConfirm',
                                }),
                                onClick: onAccountDelete,
                                inProgress: accountDeleteInProgress,
                            } })] })] })) })));
};
export default ProfileSettingsPage;
