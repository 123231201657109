var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components/macro';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { accountTypes, AccountType, stripeCountryDetails, defaultMCC, } from '@nomady/shared/stripe-config';
import { DevTool } from '@hookform/devtools';
import { getStripeAccountSchema } from '@nomady/shared/schemas/user/stripeAccount';
import { getStripeAccountEditSchema } from '@nomady/shared/schemas/user/stripeAccountEdit';
import config from '../../config';
import Button, { ButtonColorVariant, ButtonSizeVariant, } from '../../styled-components/Button/Button';
import Input from '../../styled-components/Input/Input';
import SelectInput from '../../styled-components/SelectInput/SelectInput';
import FormErrorMessage from '../../styled-components/FormErrorMessage/FormErrorMessage';
import InputRadioSelect from '../../styled-components/InputRadioSelect/InputRadioSelect';
import { IconVariant } from '../../styled-components/Icon/Icon';
import Text, { TextVariant } from '../../styled-components/Text/Text';
import InputBankAccount from '../../styled-components/InputBankAccount/InputBankAccount';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useDispatch, useSelector } from 'react-redux';
import { getMarketplaceDomain, getDomainWithProtocol, } from '@nomady/shared/utils/localized-domains';
import Page from '@nomady/shared/routeConfiguration/Page';
import routeConfiguration from '@nomady/shared/routeConfiguration/routeConfiguration';
import { createResourceLocatorString } from '@nomady/shared/utils/routes';
import { currentUserSelector } from '../../ducks/user.duck';
import { getStripeConnectAccountLink, stripeConnectAccountLoading, stripeConnectAccountSelector, savePayoutDetails, payoutDetailsSaveInProgressSelector, } from '../../ducks/stripeConnectAccount.duck';
import LoadingAnimation from '../../components/LoadingAnimation/LoadingAnimation';
import { updateProfile } from '../../containers/ProfileSettingsPage/ProfileSettingsPageSlice';
import StripeConnectAccountStatusBox, { StripeConnectAccountStatus, } from '../../styled-components/StripeConnectAccountStatusBox/StripeConnectAccountStatusBox';
import { EditListingTab, } from '../../components/EditListingWizard/EditListingWizardTab';
import { useParams } from 'react-router-dom';
import { RedirectToStripe } from './RedirectToStripe';
import { requestPublishListingDraft } from '../../containers/EditListingPage/EditListingPageSlice';
const stripePromise = loadStripe(config.stripe.publishableKey);
const accountTypeIcons = {
    [AccountType.individual]: IconVariant.User,
    [AccountType.company]: IconVariant.Shop,
};
const STRIPE_ONBOARDING_RETURN_URL_SUCCESS = 'success';
const STRIPE_ONBOARDING_RETURN_URL_FAILURE = 'failure';
const createReturnURL = (returnURLType, rootURL, routes, pathParams) => {
    const path = createResourceLocatorString(Page.EditListingPayout, routes, Object.assign(Object.assign({}, pathParams), { tab: EditListingTab.payout, returnURLType }), {});
    const root = rootURL.replace(/\/$/, '');
    return `${root}${path}`;
};
const hasRequirements = (stripeAccountData, requirementType) => stripeAccountData != null &&
    stripeAccountData.requirements &&
    Array.isArray(stripeAccountData.requirements[requirementType]) &&
    stripeAccountData.requirements[requirementType].length > 0;
// Redirect user to Stripe's hosted Connect account onboarding form
const handleGetStripeConnectAccountLinkFn = (getLinkFn, commonParams) => (type) => () => {
    getLinkFn(Object.assign({ type }, commonParams))
        .then((url) => {
        window.location.href = url;
    })
        .catch((err) => console.error(err));
};
const Form = styled.form ``;
export const FormSection = styled.div `
  margin-bottom: 60px;
`;
const FormRow = styled.div `
  margin-bottom: 10px;
`;
const PayoutSettingsForm = ({ updateListingInProgress, updateListingError, onSubmit, listing, submitButtonLabel, onFormDirty, }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t;
    const intl = useIntl();
    const params = useParams();
    const dispatch = useDispatch();
    const onUpdateProfile = (data) => dispatch(updateProfile(data));
    const onGetStripeConnectAccountLink = (params) => dispatch(getStripeConnectAccountLink(params));
    const onPublishListingDraft = (listingId) => __awaiter(void 0, void 0, void 0, function* () { return dispatch(requestPublishListingDraft(listingId)); });
    const [defaultValuesLoaded, setDefaultValuesLoaded] = useState(false);
    const currentUser = useSelector(currentUserSelector);
    const currentUserLoaded = !!(currentUser === null || currentUser === void 0 ? void 0 : currentUser.id);
    const isStripeConnectAccountLoading = useSelector(stripeConnectAccountLoading);
    const stripeConnectAccount = useSelector(stripeConnectAccountSelector);
    const { getAccountLinkInProgress, getAccountLinkError: stripeAccountLinkError, } = stripeConnectAccount || {};
    const payoutDetailsSaveInProgress = useSelector(payoutDetailsSaveInProgressSelector);
    const stripeConnected = currentUserLoaded && !!stripeConnectAccount && !!stripeConnectAccount.id;
    const stripeAccountAlreadySetUp = !!(stripeConnectAccount === null || stripeConnectAccount === void 0 ? void 0 : stripeConnectAccount.attributes.stripeAccountId);
    const indexOfLatestAccount = ((_c = (_b = (_a = stripeConnectAccount === null || stripeConnectAccount === void 0 ? void 0 : stripeConnectAccount.attributes.stripeAccountData) === null || _a === void 0 ? void 0 : _a.external_accounts) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.length) - 1;
    const bankAccount = 0 <= indexOfLatestAccount
        ? (_e = (_d = stripeConnectAccount.attributes.stripeAccountData) === null || _d === void 0 ? void 0 : _d.external_accounts) === null || _e === void 0 ? void 0 : _e.data[indexOfLatestAccount]
        : undefined;
    const listingLoaded = !!(listing === null || listing === void 0 ? void 0 : listing.id);
    const { state } = (listing === null || listing === void 0 ? void 0 : listing.attributes) || {};
    const defaultValues = {
        accountType: (_f = stripeConnectAccount === null || stripeConnectAccount === void 0 ? void 0 : stripeConnectAccount.attributes.stripeAccountData) === null || _f === void 0 ? void 0 : _f.business_type,
        userCountry: (_g = stripeConnectAccount === null || stripeConnectAccount === void 0 ? void 0 : stripeConnectAccount.attributes.stripeAccountData) === null || _g === void 0 ? void 0 : _g.country,
        placeOfBirth: (_j = (_h = currentUser === null || currentUser === void 0 ? void 0 : currentUser.attributes.profile) === null || _h === void 0 ? void 0 : _h.privateData) === null || _j === void 0 ? void 0 : _j.placeOfBirth,
        vatId: (_l = (_k = currentUser === null || currentUser === void 0 ? void 0 : currentUser.attributes.profile) === null || _k === void 0 ? void 0 : _k.privateData) === null || _l === void 0 ? void 0 : _l.vatId,
    };
    const { register, handleSubmit, control, reset, trigger, watch, setValue, getValues, formState: { errors, isDirty, isSubmitSuccessful }, } = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: stripeAccountAlreadySetUp
            ? zodResolver(getStripeAccountEditSchema(intl))
            : zodResolver(getStripeAccountSchema(intl)),
    });
    useEffect(() => {
        var _a, _b, _c, _d, _e, _f;
        reset({
            accountType: (_a = stripeConnectAccount === null || stripeConnectAccount === void 0 ? void 0 : stripeConnectAccount.attributes.stripeAccountData) === null || _a === void 0 ? void 0 : _a.business_type,
            userCountry: (_b = stripeConnectAccount === null || stripeConnectAccount === void 0 ? void 0 : stripeConnectAccount.attributes.stripeAccountData) === null || _b === void 0 ? void 0 : _b.country,
            placeOfBirth: (_d = (_c = currentUser === null || currentUser === void 0 ? void 0 : currentUser.attributes.profile) === null || _c === void 0 ? void 0 : _c.privateData) === null || _d === void 0 ? void 0 : _d.placeOfBirth,
            vatId: (_f = (_e = currentUser === null || currentUser === void 0 ? void 0 : currentUser.attributes.profile) === null || _e === void 0 ? void 0 : _e.privateData) === null || _f === void 0 ? void 0 : _f.vatId,
        });
    }, [
        (_p = (_o = (_m = currentUser === null || currentUser === void 0 ? void 0 : currentUser.attributes) === null || _m === void 0 ? void 0 : _m.profile) === null || _o === void 0 ? void 0 : _o.privateData) === null || _p === void 0 ? void 0 : _p.placeOfBirth,
        reset,
        (_q = stripeConnectAccount === null || stripeConnectAccount === void 0 ? void 0 : stripeConnectAccount.attributes.stripeAccountData) === null || _q === void 0 ? void 0 : _q.business_type,
        (_r = stripeConnectAccount === null || stripeConnectAccount === void 0 ? void 0 : stripeConnectAccount.attributes.stripeAccountData) === null || _r === void 0 ? void 0 : _r.country,
        (_t = (_s = currentUser === null || currentUser === void 0 ? void 0 : currentUser.attributes.profile) === null || _s === void 0 ? void 0 : _s.privateData) === null || _t === void 0 ? void 0 : _t.vatId,
    ]);
    useEffect(() => {
        reset(defaultValues);
        setDefaultValuesLoaded(true);
    }, [listingLoaded]);
    useEffect(() => {
        if (isSubmitSuccessful) {
            const values = getValues();
            reset(values);
        }
    }, [isSubmitSuccessful, getValues, reset]);
    const validateOnRender = () => {
        defaultValuesLoaded && trigger();
    };
    useEffect(validateOnRender, [trigger, defaultValuesLoaded]);
    useEffect(() => onFormDirty(isDirty), [isDirty, onFormDirty]);
    const defaultMarketpalaceDomain = getMarketplaceDomain();
    const domainWithProtocol = getDomainWithProtocol(defaultMarketpalaceDomain);
    const { returnURLType } = params, pathParams = __rest(params, ["returnURLType"]);
    const accountId = stripeConnected ? stripeConnectAccount.id : null;
    const successURL = createReturnURL(STRIPE_ONBOARDING_RETURN_URL_SUCCESS, domainWithProtocol, routeConfiguration, pathParams);
    const failureURL = createReturnURL(STRIPE_ONBOARDING_RETURN_URL_FAILURE, domainWithProtocol, routeConfiguration, pathParams);
    const handleGetStripeConnectAccountLink = handleGetStripeConnectAccountLinkFn(onGetStripeConnectAccountLink, {
        accountId,
        successURL,
        failureURL,
    });
    const onSuccess = (data) => __awaiter(void 0, void 0, void 0, function* () {
        var _w, _x;
        if (!currentUser) {
            return;
        }
        const userParams = {
            profile: {
                privateData: {
                    placeOfBirth: (_w = data.placeOfBirth) === null || _w === void 0 ? void 0 : _w.trim(),
                    vatId: (_x = data.vatId) === null || _x === void 0 ? void 0 : _x.trim(),
                },
            },
        };
        onUpdateProfile(userParams);
        const params = {};
        if (!stripeAccountAlreadySetUp) {
            const pathToProfilePage = (uuid) => createResourceLocatorString(Page.Profile, routeConfiguration, { id: uuid }, {});
            const businessProfileURL = `${domainWithProtocol}${pathToProfilePage(currentUser.id.uuid)}`;
            yield dispatch(savePayoutDetails({
                country: data.userCountry,
                bankAccountToken: data.bankAccount,
                businessProfileMCC: defaultMCC,
                businessProfileURL,
                accountType: data.accountType,
            }, false));
            handleGetStripeConnectAccountLink('custom_account_verification')();
        }
        const requirementsMissing = stripeConnectAccount &&
            (hasRequirements(stripeAccountData, 'past_due') ||
                hasRequirements(stripeAccountData, 'currently_due'));
        if (stripeConnected &&
            !requirementsMissing &&
            listing &&
            listing.attributes.state === 'draft') {
            yield onPublishListingDraft(listing.id);
        }
        onSubmit(params);
    });
    const stripeAccountData = stripeConnectAccount === null || stripeConnectAccount === void 0 ? void 0 : stripeConnectAccount.attributes.stripeAccountData;
    const requirementsMissing = stripeConnectAccount &&
        (hasRequirements(stripeAccountData, 'past_due') ||
            hasRequirements(stripeAccountData, 'currently_due'));
    const returnedAbnormallyFromStripe = returnURLType === STRIPE_ONBOARDING_RETURN_URL_FAILURE;
    const showVerificationNeeded = stripeConnected && requirementsMissing;
    const accountType = register('accountType');
    const accountTypeValue = watch('accountType');
    const isIndividualAccountType = accountTypeValue === null || accountTypeValue === void 0 ? void 0 : accountTypeValue.includes(AccountType.individual);
    const _u = register('placeOfBirth'), { ref: placeOfBirthRef } = _u, placeOfBirth = __rest(_u, ["ref"]);
    const _v = register('vatId'), { ref: vatIdRef } = _v, vatId = __rest(_v, ["ref"]);
    const userCountry = register('userCountry');
    const readyForPendingApproval = stripeAccountAlreadySetUp && state === 'draft';
    if (isStripeConnectAccountLoading || payoutDetailsSaveInProgress) {
        return (_jsx(_Fragment, { children: _jsx(LoadingAnimation, {}) }));
    }
    if (currentUserLoaded &&
        returnedAbnormallyFromStripe &&
        !stripeAccountLinkError) {
        return _jsx(RedirectToStripe, { redirectFn: handleGetStripeConnectAccountLink });
    }
    return (_jsx(Elements, Object.assign({ stripe: stripePromise }, { children: _jsxs(Form, Object.assign({ onSubmit: handleSubmit(onSuccess) }, { children: [_jsx(DevTool, { control: control }), _jsxs(FormSection, { children: [_jsx(Text, Object.assign({ variant: TextVariant.H4, as: "h3" }, { children: intl.formatMessage({
                                id: 'PayoutSettingsForm.aboutYou',
                            }) })), _jsx(FormRow, { children: _jsx(InputRadioSelect, Object.assign({ disabled: stripeAccountAlreadySetUp, control: control, id: "accountType" }, accountType, { label: intl.formatMessage({
                                    id: `PayoutSettingsForm.stripeConnectAccountTypeLabel`,
                                }), options: accountTypes.map(element => ({
                                    value: element.key,
                                    label: intl.formatMessage({ id: element.label }),
                                    icon: accountTypeIcons[element.key],
                                })), error: errors === null || errors === void 0 ? void 0 : errors.accountType, onChange: event => {
                                    setValue('bankAccount', undefined, { shouldValidate: true });
                                    accountType.onChange(event);
                                } })) }), isIndividualAccountType && (_jsx(FormRow, { children: _jsx(Input, Object.assign({ label: `${intl.formatMessage({
                                    id: 'ProfileSettingsForm.placeOfBirthLabel',
                                })}`, id: "placeOfBirth", type: "text" }, placeOfBirth, { inputRef: placeOfBirthRef, error: errors === null || errors === void 0 ? void 0 : errors.placeOfBirth, description: intl.formatMessage({
                                    id: 'ProfileSettingsForm.placeOfBirthInfo',
                                }) })) })), accountTypeValue && (_jsx(FormRow, { children: _jsx(Input, Object.assign({ label: `${intl.formatMessage({
                                    id: 'ProfileSettingsForm.vatIdLabel',
                                })}`, id: "vatId", type: "text" }, vatId, { inputRef: vatIdRef, error: errors === null || errors === void 0 ? void 0 : errors.vatId, description: intl.formatMessage({
                                    id: `ProfileSettingsForm.vatIdInfo.${accountTypeValue}`,
                                }) })) })), accountTypeValue && (_jsx(FormRow, { children: _jsx(SelectInput, Object.assign({ disabled: stripeAccountAlreadySetUp, options: stripeCountryDetails.map(({ code }) => ({
                                    label: intl.formatMessage({
                                        id: `StripeConnectAccountForm.countryNames.${code}`,
                                    }),
                                    value: code,
                                })), label: intl.formatMessage({
                                    id: `PayoutSettingsForm.userCountry.${accountTypeValue}`,
                                }), id: "userCountry" }, userCountry, { inputRef: userCountry.ref, error: errors === null || errors === void 0 ? void 0 : errors.userCountry })) }))] }), _jsxs(FormSection, { children: [_jsx(Text, Object.assign({ variant: TextVariant.H4, as: "h3" }, { children: intl.formatMessage({
                                id: 'PayoutSettingsForm.aboutYourBankAccount',
                            }) })), _jsx(FormRow, { children: _jsx(InputBankAccount, { disabled: stripeAccountAlreadySetUp, control: control, id: "bankAccount", name: "bankAccount", error: errors === null || errors === void 0 ? void 0 : errors.bankAccount, description: intl.formatMessage({
                                    id: 'PayoutSettingsForm.bankAccountNumberDescription',
                                }), accountType: accountTypeValue, defaultValues: {
                                    country: bankAccount === null || bankAccount === void 0 ? void 0 : bankAccount.country,
                                    currency: (bankAccount === null || bankAccount === void 0 ? void 0 : bankAccount.currency)
                                        ? bankAccount === null || bankAccount === void 0 ? void 0 : bankAccount.currency.toUpperCase()
                                        : undefined,
                                    bankAccountNumber: (bankAccount === null || bankAccount === void 0 ? void 0 : bankAccount.last4)
                                        ? `••• •••• •••• •••• •••• ${bankAccount.last4}`
                                        : '',
                                } }) })] }), _jsxs(FormSection, { children: [stripeConnected &&
                            !returnedAbnormallyFromStripe &&
                            showVerificationNeeded ? (_jsx(StripeConnectAccountStatusBox, { status: StripeConnectAccountStatus.STATUS_VERIFICATION_NEEDED, inProgress: getAccountLinkInProgress, onGetStripeConnectAccountLink: handleGetStripeConnectAccountLink('custom_account_verification') })) : null, stripeConnected &&
                            !returnedAbnormallyFromStripe &&
                            !showVerificationNeeded ? (_jsx(StripeConnectAccountStatusBox, { status: StripeConnectAccountStatus.STATUS_VERIFICATION_SUCCESS, inProgress: getAccountLinkInProgress, disabled: false, onGetStripeConnectAccountLink: handleGetStripeConnectAccountLink('custom_account_update') })) : null] }), _jsx(Button, { label: readyForPendingApproval
                        ? intl.formatMessage({
                            id: 'PayoutSettingsForm.submitListingForApproval',
                        })
                        : submitButtonLabel, colorVariant: ButtonColorVariant.Dark, sizeVariant: ButtonSizeVariant.Small, type: "submit", inProgress: updateListingInProgress }), updateListingError && (_jsx(FormErrorMessage, { id: "PayoutSettingsForm.updateFailed" }))] })) })));
};
export default PayoutSettingsForm;
