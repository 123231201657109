import { MarkerType } from '@nomady/shared/types';
const mapMarkerIcon = {
    [MarkerType.camp]: '/static/icons/map-markers/camp.svg',
    [MarkerType.fireplace]: '/static/icons/map-markers/fireplace.svg',
    [MarkerType.electricity]: '/static/icons/map-markers/electricity.svg',
    [MarkerType.parking]: '/static/icons/map-markers/camper-parking.svg',
    [MarkerType.toilet]: '/static/icons/map-markers/toilet.svg',
    [MarkerType.water]: '/static/icons/map-markers/water.svg',
};
export default mapMarkerIcon;
