import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { fireplaceTypes, firewoodPaymentTypes, } from '@nomady/shared/marketplace-custom-config';
import { FirewoodPayment } from '@nomady/shared/types';
import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { IconVariant } from '../../../styled-components/Icon/Icon';
import ListingChecklist from '../../../styled-components/ListingChecklist';
import BookingExtrasSelection from './BookingExtrasSelection';
import { BookingExtraType } from '../../../styled-components/BookingExtra/BookingExtra';
const FireplaceContent = () => {
    var _a, _b;
    const { listing } = useSelector((state) => state.ListingPage);
    const intl = useIntl();
    const activeFireplaceTypes = ((_b = (_a = listing === null || listing === void 0 ? void 0 : listing.attributes) === null || _a === void 0 ? void 0 : _a.publicData) === null || _b === void 0 ? void 0 : _b.fireplaceType) || [];
    const activeFireplacePaymentTypes = firewoodPaymentTypes.filter(element => activeFireplaceTypes.includes(element.key));
    const elements = [...fireplaceTypes, ...activeFireplacePaymentTypes].map(fireplaceType => ({
        isChecked: activeFireplaceTypes.includes(fireplaceType.key),
        text: intl.formatMessage({ id: fireplaceType.label }),
    }));
    const showBookingExtras = activeFireplaceTypes.includes(FirewoodPayment.firewoodPurchasable);
    return (_jsxs(_Fragment, { children: [_jsx(ListingChecklist, { iconVariant: IconVariant.Fireplace, title: intl.formatMessage({ id: 'ListingPage.fireplace' }), items: elements }), showBookingExtras && (_jsx(BookingExtrasSelection, { single: BookingExtraType.Firewood }))] }));
};
export default FireplaceContent;
