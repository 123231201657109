import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { useIntl } from 'react-intl';
import Page from '@nomady/shared/routeConfiguration/Page';
import { LOCALE_CODES } from '@nomady/shared/marketplace-custom-config';
import LocaleSwitchModal from '../LocaleSwitchModal/LocaleSwitchModal';
import { IconVariant } from '../Icon/Icon';
import MenuItem, { MenuItemVariant } from '../MenuItem/MenuItem';
const Wrapper = styled.div `
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-start;
`;
const LocaleSwitch = ({ selectedLocale, isUserLoggedIn, }) => {
    var _a;
    const [showModal, setShowModal] = useState(false);
    const maybeRedirectToProfile = isUserLoggedIn
        ? Page.LanguageSettings
        : undefined;
    const currentLocaleLabel = ((_a = LOCALE_CODES.find(locale => locale.key === selectedLocale)) === null || _a === void 0 ? void 0 : _a.label) || '';
    const intl = useIntl();
    return (_jsxs(Wrapper, { children: [_jsx(MenuItem, { icon: IconVariant.Globe, onClick: () => {
                    if (!isUserLoggedIn) {
                        setShowModal(true);
                    }
                }, to: maybeRedirectToProfile, label: `${intl.formatMessage({
                    id: 'LocaleSwitch.language',
                })}: ${currentLocaleLabel}`, menuItemVariant: MenuItemVariant.Secondary }), _jsx(LocaleSwitchModal, { showLocaleSwitchModal: showModal, selectedLocale: selectedLocale, onCloseLocaleSwitchModal: () => setShowModal(false) })] }));
};
export default LocaleSwitch;
