import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import styled from 'styled-components/macro';
import Markdown from 'react-showdown';
import { LayoutRowColorVariant } from '../Layout/Layout';
const Wrapper = styled.div `
  padding-top: 16px;
  padding-bottom: 16px;

  ${props => props.theme.layout.gridContainer};

  @media ${props => props.theme.device.tabletL} {
    padding-top: 32px;
    padding-bottom: 32px;
  }

  @media ${props => props.theme.device.desktop} {
    padding-top: 64px;
    padding-bottom: 64px;
  }

  ${props => props.colorVariant === LayoutRowColorVariant.Fir &&
    `
    background-color:  ${props.theme.color.fir};
    color: ${props.theme.color.pearl};
    `}

  ${props => props.colorVariant === LayoutRowColorVariant.Horizon &&
    `
      background-color:  ${props.theme.color.horizon4};
      color: ${props.theme.color.pearl};
    `}

  ${props => props.colorVariant === LayoutRowColorVariant.Pearl &&
    `
      background-color:  ${props.theme.color.pearl};
    `}
`;
const Text = styled.p `
  ${props => props.theme.typeset.H2}

  grid-column-start:contentStart;
  grid-column-end: contentEnd;

  @media ${props => props.theme.device.tabletL} {
    width: 635px;
  }
`;
const Lead = ({ colorVariant, children, }) => {
    return (_jsx(Wrapper, Object.assign({ colorVariant: colorVariant }, { children: _jsx(Text, { children: _jsx(Markdown, { markdown: children.toString() }) }) })));
};
export default Lead;
