import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components/macro';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import { IconSizeVariant, IconVariant, } from '../../../styled-components/Icon/Icon';
import { LayoutRow, LayoutRowBottomVariant, LayoutRowHorizontalVariant, } from '../../../styled-components/Layout/Layout';
import ReviewSlider from '../../../styled-components/ReviewSlider/ReviewSlider';
import SectionContentSlider from '../../../styled-components/SectionContentSlider/SectionContentSlider';
import { TextVariant } from '../../../styled-components/Text/Text';
import { ColorVariant } from '../../../theme';
import ListingModal from './ListingModal';
import ReviewsList from '../ModalContents/ReviewsList';
import { ListingContentModalVariant } from '../ListingPageSlice';
const ButtonWrap = styled.div `
  min-width: 290px;
  border-radius: 10px;
  margin-bottom: 45px;
  & span {
    padding-right: 30px;
  }
`;
const BottomRow = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 60px;

  @media ${props => props.theme.device.tablet} {
    margin-top: 50px;
  }

  @media ${props => props.theme.device.desktop} {
    margin-top: 40px;
  }
`;
const Wrapper = styled.div `
  padding-left: 15px;
  @media ${props => props.theme.device.desktop} {
    padding-left: 0px;
  }
`;
const ReviewsSection = ({ reviews, }) => {
    const intl = useIntl();
    const openReviewsModalButton = (_jsx(ListingModal, Object.assign({ titleId: 'ListingPage.showAllReviews', id: ListingContentModalVariant.REVIEWS_LIST, iconVariant: IconVariant.Star, iconSizeVariant: IconSizeVariant.ExtraExtraSmall, iconColorVariant: ColorVariant.Stone }, { children: _jsx(ReviewsList, {}) })));
    return (_jsxs(Wrapper, { children: [_jsx(LayoutRow, Object.assign({ horizontalVariant: LayoutRowHorizontalVariant.MobileContainedLeft, bottomVariant: LayoutRowBottomVariant.SmallMargin }, { children: _jsx(SectionContentSlider, Object.assign({ title: intl.formatMessage({ id: 'ListingPage.reviewsOfCamp' }), titleTextVariant: TextVariant.H30, colorVariant: ColorVariant.Cave, buttonIconVariant: IconVariant.ArrowRight, horizontalVariant: LayoutRowHorizontalVariant.ContainedLeft }, { children: _jsx(LazyLoadComponent, { children: _jsx(ReviewSlider, { reviews: reviews }) }) })) })), _jsx(BottomRow, { children: _jsx(ButtonWrap, { children: openReviewsModalButton }) })] }));
};
export default ReviewsSection;
