import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styled, { css } from 'styled-components/macro';
export var DayVariant;
(function (DayVariant) {
    DayVariant["FREE_IN_THE_FUTURE"] = "freeInFuture";
    DayVariant["FREE_IN_PAST"] = "freeInPast";
    DayVariant["BOOKED_IN_FUTURE"] = "bookedInFuture";
    DayVariant["BOOKED_IN_PAST"] = "bookedInPast";
    DayVariant["BLOCKED_IN_FUTURE"] = "blockedInFuture";
    DayVariant["BLOCKED_IN_PAST"] = "blockedInPast";
    DayVariant["PENDING_IN_PAST"] = "pendingInPast";
    DayVariant["PENDING_IN_FUTURE"] = "pendingInFuture";
    DayVariant["BOOKING_RANGE_START"] = "bookingRangeStart";
    DayVariant["BOOKING_RANGE_START_ONLY"] = "bookingRangeStartOnly";
    DayVariant["BOOKING_RANGE_IN_BETWEEN"] = "bookingRangeInBetween";
    DayVariant["BOOKING_RANGE_END"] = "bookingRangeEnd";
    DayVariant["BOOKING_INTERMEDIATE_RANGE_START"] = "bookingIntermediateRangeStart";
    DayVariant["BOOKING_INTERMEDIATE_RANGE_END"] = "bookingIntermediateRangeEnd";
    DayVariant["BOOKING_INTERMEDIATE_RANGE_IN_BETWEEN"] = "bookingIntermediateRangeInBetween";
    DayVariant["BOOKING_IN_THE_PAST"] = "bookingInThePast";
    DayVariant["BOOKING_IN_THE_FUTURE"] = "bookingInTheFuture";
    DayVariant["DEFAULT"] = "default";
    DayVariant["UNAVAILABLE"] = "unavailable";
})(DayVariant || (DayVariant = {}));
export const ALL_AVAILABILITY_VARIANTS = [
    DayVariant.BLOCKED_IN_FUTURE,
    DayVariant.BLOCKED_IN_PAST,
    DayVariant.BOOKED_IN_FUTURE,
    DayVariant.BOOKED_IN_PAST,
    DayVariant.FREE_IN_PAST,
    DayVariant.FREE_IN_THE_FUTURE,
    DayVariant.PENDING_IN_FUTURE,
    DayVariant.PENDING_IN_PAST,
];
export const ALL_BOOKING_RANGE_VARIANTS = [
    DayVariant.BOOKING_RANGE_START,
    DayVariant.BOOKING_RANGE_START_ONLY,
    DayVariant.BOOKING_RANGE_END,
    DayVariant.BOOKING_RANGE_IN_BETWEEN,
    DayVariant.BOOKING_INTERMEDIATE_RANGE_END,
    DayVariant.BOOKING_INTERMEDIATE_RANGE_IN_BETWEEN,
    DayVariant.BOOKING_INTERMEDIATE_RANGE_START,
    DayVariant.BOOKING_IN_THE_FUTURE,
    DayVariant.BOOKING_IN_THE_PAST,
    DayVariant.UNAVAILABLE,
];
const Day = styled.div `
  box-sizing: border-box;
  text-align: center;
  align-items: center;
  border: none;
  ${props => props.theme.typeset.H12B}

  ${props => ALL_BOOKING_RANGE_VARIANTS.includes(props.variant) &&
    css `
      width: 36px;
      height: 36px;
      line-height: 36px;
    `}

  ${props => ALL_AVAILABILITY_VARIANTS.includes(props.variant) &&
    css `
      width: 34px;
      height: 34px;
      line-height: 34px;
    `}

  ${props => props.variant === DayVariant.PENDING_IN_PAST &&
    css `
      background: ${props => props.theme.color.clementine};
      opacity: 0.4;
      border-radius: 8px;
      color: ${props => props.theme.color.pearl};
      border: 1px solid ${props => props.theme.color.clementine};
    `}

  ${props => props.variant === DayVariant.PENDING_IN_FUTURE &&
    css `
      background: ${props => props.theme.color.clementine};
      color: ${props => props.theme.color.pearl};
      border-radius: 8px;
      border: 1px solid ${props => props.theme.color.clementine};
    `}



  ${props => props.variant === DayVariant.BOOKED_IN_PAST &&
    css `
      background: ${props => props.theme.color.lagoon};
      opacity: 0.4;
      border-radius: 8px;
      color: ${props => props.theme.color.pearl};
      border: 1px solid ${props => props.theme.color.lagoon};
    `}

  ${props => (props.variant === DayVariant.FREE_IN_PAST ||
    props.variant === DayVariant.FREE_IN_THE_FUTURE) &&
    css `
      border-radius: 20%;
      border: 1px solid ${props => props.theme.color.snow};
      color: ${props => props.theme.color.cave};
    `}

  ${props => (props.variant === DayVariant.FREE_IN_PAST ||
    props.variant === DayVariant.BOOKING_IN_THE_PAST) &&
    css `
      line-height: 36px;
      color: ${props => props.theme.color.fog};
    `}

  ${props => props.variant === DayVariant.UNAVAILABLE &&
    css `
      line-height: 36px;
      color: ${props => props.theme.color.fog};
      text-decoration: line-through;
      text-decoration-color: ${props.theme.color.cave};
    `}

  ${props => props.variant === DayVariant.BOOKING_IN_THE_FUTURE &&
    css `
      color: ${props => props.theme.color.cave};
    `}

  ${props => props.variant === DayVariant.BLOCKED_IN_PAST &&
    css `
      background: ${props => props.theme.color.rose};
      opacity: 0.4;
      color: ${props => props.theme.color.pearl};
      border-radius: 8px;
      border: 1px solid ${props => props.theme.color.rose};
    `}
    
        ${props => props.isToday &&
    css `
      border-color: ${props => props.theme.color.tar};
      background: ${props => props.theme.color.pearl};
      border: solid 1px ${props => props.theme.color.cave} !important;
      border-radius: 5px;

      ${props => props.theme.typeset.H11B};

      line-height: 36px;
    `};

  ${props => props.variant === DayVariant.BOOKED_IN_FUTURE &&
    css `
      background: ${props => props.theme.color.lagoon};
      color: ${props => props.theme.color.pearl};
      border-radius: 8px;
      border: 1px solid ${props => props.theme.color.lagoon};
    `}

  ${props => props.variant === DayVariant.BLOCKED_IN_FUTURE &&
    css `
      background: ${props => props.theme.color.rose};
      border-radius: 8px;
      color: ${props => props.theme.color.pearl};
      border: 1px solid ${props => props.theme.color.rose};
    `}

  ${props => props.variant === DayVariant.BOOKING_RANGE_START_ONLY &&
    css `
      background: ${props => props.theme.color.lagoon};
      color: ${props => props.theme.color.pearl};
      border-radius: 8px;
      border-color: ${props => props.theme.color.lagoon};
    `}

  ${props => props.variant === DayVariant.BOOKING_INTERMEDIATE_RANGE_START &&
    css `
      background: ${props => props.theme.color.lagoon};
      color: ${props => props.theme.color.pearl};
      border-radius: 8px;
    `}

  ${props => props.variant === DayVariant.BOOKING_INTERMEDIATE_RANGE_IN_BETWEEN &&
    css `
      width: 100%;
      background: ${props => props.theme.color.transparent};
      color: ${props => props.theme.color.cave};
    `}

  ${props => props.variant === DayVariant.BOOKING_INTERMEDIATE_RANGE_END &&
    css `
      background: ${props => props.theme.color.lagoon};
      color: ${props => props.theme.color.pearl};
      border-radius: 8px;
    `}

  ${props => props.variant === DayVariant.BOOKING_RANGE_START &&
    css `
      background: ${props => props.theme.color.aquamarine};
      color: ${props => props.theme.color.pearl};
      border-radius: 8px;
    `}
  ${props => props.variant === DayVariant.BOOKING_RANGE_IN_BETWEEN &&
    css `
      width: 100%;
      background: ${props => props.theme.color.transparent};
      color: ${props => props.theme.color.pearl};
    `}

  ${props => props.variant === DayVariant.BOOKING_RANGE_END &&
    css `
      background: ${props => props.theme.color.aquamarine};
      color: ${props => props.theme.color.pearl};
      border-radius: 8px;
    `}


  @media ${props => props.theme.device.tablet} {
    font-size: 18px;

    ${props => ALL_BOOKING_RANGE_VARIANTS.includes(props.variant) &&
    css `
        width: 56px;
        height: 56px;
        line-height: 56px;
      `}

    ${props => ALL_AVAILABILITY_VARIANTS.includes(props.variant) &&
    css `
        width: 56px;
        height: 56px;
        line-height: 54px;
      `}
    ${props => (props.variant === DayVariant.BOOKING_RANGE_IN_BETWEEN ||
    props.variant === DayVariant.BOOKING_INTERMEDIATE_RANGE_IN_BETWEEN) &&
    css `
        width: 100%;
      `}
  }

  @media ${props => props.theme.device.desktop} {
    ${props => ALL_BOOKING_RANGE_VARIANTS.includes(props.variant) &&
    css `
        width: 36px;
        height: 36px;
        line-height: 36px;

        font-size: 14px;
      `}

    ${props => (props.variant === DayVariant.BOOKING_RANGE_IN_BETWEEN ||
    props.variant === DayVariant.BOOKING_INTERMEDIATE_RANGE_IN_BETWEEN) &&
    css `
        width: 100%;
      `}
  }
`;
const SeatDetailsWrapper = styled.div `
  display: flex;
  top: -9px;
  left: -1px;
  position: relative;
`;
var SeatCountVariant;
(function (SeatCountVariant) {
    SeatCountVariant[SeatCountVariant["Blocked"] = 0] = "Blocked";
    SeatCountVariant[SeatCountVariant["Booked"] = 1] = "Booked";
    SeatCountVariant[SeatCountVariant["Pending"] = 2] = "Pending";
})(SeatCountVariant || (SeatCountVariant = {}));
const SeatCount = styled.div `
  font-size: 8px;
  font-weight: 600;
  line-height: 14px;
  height: 12px;
  width: 12px;
  border-radius: 2px;
  margin-left: 1px;
  color: ${props => props.theme.color.pearl};

  ${props => props.variant === SeatCountVariant.Blocked &&
    `
    background: ${props.theme.color.rose};
  `}

  ${props => props.variant === SeatCountVariant.Booked &&
    `
    background: ${props.theme.color.lavender};
  `}

    ${props => props.variant === SeatCountVariant.Pending &&
    `
    background: ${props.theme.color.clementine};
  `}

  @media ${props => props.theme.device.tablet} {
    height: 18px;
    width: 18px;
    font-size: 12px;
    line-height: 18px;
    font-weight: normal;
  }
`;
export const CalendarDay = ({ variant = DayVariant.DEFAULT, label, blockedSeats, bookedSeats, pendingSeats, isToday = false, }) => {
    return (_jsxs(Day, Object.assign({ variant: variant, isToday: isToday }, { children: [label, (bookedSeats || blockedSeats || pendingSeats) && (_jsxs(SeatDetailsWrapper, { children: [bookedSeats && (_jsx(SeatCount, Object.assign({ variant: SeatCountVariant.Booked }, { children: bookedSeats }))), blockedSeats && (_jsx(SeatCount, Object.assign({ variant: SeatCountVariant.Blocked }, { children: blockedSeats }))), pendingSeats && (_jsx(SeatCount, Object.assign({ variant: SeatCountVariant.Pending }, { children: pendingSeats })))] }))] })));
};
