import { css } from 'styled-components/macro';
const fontFamily = 'Poppins, sans-serif';
export var ColorVariant;
(function (ColorVariant) {
    /** @deprecated Use new color */
    ColorVariant["Fir"] = "fir";
    /** @deprecated Use new color */
    ColorVariant["Cinnabar"] = "cinnabar";
    /** @deprecated Use new color */
    ColorVariant["Tar"] = "tar";
    /** @deprecated Use new color */
    ColorVariant["Eclipse"] = "eclipse";
    /** @deprecated Use new color */
    ColorVariant["Cave"] = "cave";
    /** @deprecated Use new color */
    ColorVariant["CaveTransparent"] = "caveTransparent";
    /** @deprecated Use new color */
    ColorVariant["Cactus"] = "cactus";
    /** @deprecated Use new color */
    ColorVariant["Pearl"] = "pearl";
    /** @deprecated Use new color */
    ColorVariant["Snow"] = "snow";
    /** @deprecated Use new color */
    ColorVariant["Cloud"] = "cloud";
    /** @deprecated Use new color */
    ColorVariant["Vulcano"] = "vulcano";
    /** @deprecated Use new color */
    ColorVariant["Amber"] = "amber";
    /** @deprecated Use new color */
    ColorVariant["Stone"] = "stone";
    /** @deprecated Use new color */
    ColorVariant["Fog"] = "fog";
    /** @deprecated Use new color */
    ColorVariant["Rose"] = "rose";
    /** @deprecated Use new color */
    ColorVariant["Clementine"] = "clementine";
    /** @deprecated Use new color */
    ColorVariant["Lagoon"] = "lagoon";
    /** @deprecated Use new color */
    ColorVariant["lagoonLight"] = "lagoonLight";
    /** @deprecated Use new color */
    ColorVariant["Aquamarine"] = "aquamarine";
    /** @deprecated Use new color */
    ColorVariant["Lavender"] = "lavender";
    /** @deprecated Use new color */
    ColorVariant["Blueberry"] = "blueberry";
    /** @deprecated Use new color */
    ColorVariant["CornflowerBlue"] = "cornflowerblue";
    /** @deprecated Use new color */
    ColorVariant["Indigo"] = "indigo";
    /** @deprecated Use new color */
    ColorVariant["Horizon"] = "horizon";
    /** @deprecated Use new color */
    ColorVariant["Horizon1"] = "horizon1";
    /** @deprecated Use new color */
    ColorVariant["Horizon2"] = "horizon2";
    /** @deprecated Use new color */
    ColorVariant["Horizon3"] = "horizon3";
    /** @deprecated Use new color */
    ColorVariant["Horizon4"] = "horizon4";
    /** @deprecated Use new color */
    ColorVariant["Moss"] = "moss";
    /** @deprecated Use new color */
    ColorVariant["Wine"] = "wine";
    /** @deprecated Use new color */
    ColorVariant["Gras"] = "gras";
    /** @deprecated Use new color */
    ColorVariant["Blood"] = "blood";
    /** @deprecated Use new color */
    ColorVariant["Forest"] = "forest";
    /** @deprecated Use new color */
    ColorVariant["Alge"] = "alge";
    /** @deprecated Use new color */
    ColorVariant["Sky"] = "sky";
    /** @deprecated Use new color */
    ColorVariant["Carrot"] = "carrot";
    /** @deprecated Use new color */
    ColorVariant["TarTransparent"] = "tarTransparent";
    /** @deprecated Use new color */
    ColorVariant["Lilac"] = "lilac";
    ColorVariant["Transparent"] = "transparent";
    ColorVariant["PrimaryFirGreen"] = "primaryFirGreen";
    ColorVariant["PrimaryCinnabarRed"] = "primaryCinnabarRed";
    ColorVariant["PrimaryOstseeBeach"] = "primaryOstseeBeach";
    ColorVariant["PrimaryMossGreen"] = "primaryMossGreen";
    ColorVariant["BasicCaveBlack"] = "basicCaveBlack";
    ColorVariant["BasicPearlWhiteTypeWhite"] = "basicPearlWhiteTypeWhite";
    ColorVariant["BasicRavenBlack"] = "basicRavenBlack";
    ColorVariant["BasicSnowGrey"] = "basicSnowGrey";
    ColorVariant["FunctionFogGreyInactive"] = "functionFogGreyInactive";
    ColorVariant["FunctionStoneGreyInactive"] = "functionStoneGreyInactive";
    ColorVariant["FunctionGranitGrey"] = "functionGranitGrey";
    ColorVariant["FunctionColeGrey"] = "functionColeGrey";
    ColorVariant["FunctionElectricVioletActive"] = "functionElectricVioletActive";
    ColorVariant["FunctionGiftAmberYellow"] = "functionGiftAmberYellow";
    ColorVariant["FunctionDiscountViollett"] = "functionDiscountViollett";
    ColorVariant["FunctionOrangeRating"] = "functionOrangeRating";
    ColorVariant["FunctionBlueInformation"] = "functionBlueInformation";
    ColorVariant["FunctionSuccess00"] = "functionSuccess00";
    ColorVariant["FunctionSuccess0"] = "functionSuccess0";
    ColorVariant["FunctionSuccess1"] = "functionSuccess1";
    ColorVariant["FunctionSuccess2"] = "functionSuccess2";
    ColorVariant["FunctionSuccess3"] = "functionSuccess3";
    ColorVariant["FunctionSuccess4"] = "functionSuccess4";
    ColorVariant["FunctionError0"] = "functionError0";
    ColorVariant["FunctionError1"] = "functionError1";
    ColorVariant["FunctionError2"] = "functionError2";
    ColorVariant["FunctionError3"] = "functionError3";
    ColorVariant["FunctionError4"] = "functionError4";
    ColorVariant["BackgroundBlur"] = "backgroundBlur";
})(ColorVariant || (ColorVariant = {}));
const color = {
    fir: '#405547',
    cinnabar: '#E13F32',
    tar: '#000000',
    tarTransparent: '#000000CC',
    eclipse: '#4A4A4A',
    cave: '#222222',
    caveTransparent: '#222222F2',
    cactus: '#6F7A6A',
    pearl: '#FFFFFF',
    snow: '#F5F5F5',
    cloud: '#EEEEEE',
    vulcano: '#686868',
    amber: '#FFAA00',
    stone: '#8E8E8E',
    fog: '#BFBFBF',
    rose: '#FF254C',
    lagoon: '#00D8A4',
    lagoonLight: '#80EBD1',
    aquamarine: '#00AF85',
    lavender: '#7B61FF',
    blueberry: '#2F80ED',
    clementine: '#FF810D',
    cornflowerblue: '#7559FF',
    indigo: '#5D24FF',
    horizon: '#AFC5D0',
    horizon1: '#97DBFD',
    horizon2: '#78909C',
    horizon3: '#1E3F50',
    horizon4: '#01141F',
    moss: '#A3B69A',
    wine: '#761C14',
    gras: '#00664E',
    blood: '#F00',
    forest: '#01765A',
    alge: '#273A2D',
    sky: '#005DB4',
    carrot: '#FF9900',
    lilac: '#C457C7',
    transparent: 'transparent',
    primaryFirGreen: '#273A2D',
    primaryCinnabarRed: '#E13F32',
    primaryOstseeBeach: '#DCD6C6',
    primaryMossGreen: '#A3B69A',
    basicCaveBlack: '#222222',
    basicPearlWhiteTypeWhite: '#FFFFFF',
    basicRavenBlack: '#000000',
    basicSnowGrey: '#F5F5F5',
    functionFogGreyInactive: '#BFBFBF',
    functionStoneGreyInactive: '#8E8E8E',
    functionGranitGrey: '#595959',
    functionColeGrey: '#333333',
    functionElectricVioletActive: '#5D24FF',
    functionGiftAmberYellow: '#FFAA00',
    functionDiscountViollett: '#C457C7',
    functionOrangeRating: '#FF810D',
    functionBlueInformation: '#00AAF3',
    functionSuccess00: '#8FFFE4',
    functionSuccess0: '#0AFFC4',
    functionSuccess1: '#00D8A4',
    functionSuccess2: '#00AF85',
    functionSuccess3: '#01765A',
    functionSuccess4: '#004938',
    functionError0: '#FF627F',
    functionError1: '#FF254C',
    functionError2: '#E30E34',
    functionError3: '#B20020',
    functionError4: '#6A0013',
    backgroundBlur: '#00000066',
};
const device = {
    mobile: `(min-width: 320px)`,
    tablet: `(min-width: 600px)`,
    tabletL: `(min-width: 768px)`,
    desktop: `(min-width: 1024px)`,
    desktopL: `(min-width: 1440px)`,
    desktopXL: `(min-width: 1580px)`,
    desktopXXL: `(min-width: 1921px)`,
    retina: `only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (min-device-pixel-ratio: 2),
  only screen and (min-resolution: 192dpi),
  only screen and (min-resolution: 2dppx)`,
};
const typeset = {
    H11: css `
    font-family: ${fontFamily};
    font-style: normal;
    font-weight: normal;
    font-size: 42px;
    line-height: 120%;
    /* or 50px */

    letter-spacing: -0.005em;

    @media ${device.tablet} {
      font-size: 70px;
      line-height: 115%;
      /* or 80px */

      letter-spacing: -0.005em;
    }
  `,
    H12: css `
    font-family: ${fontFamily};
    font-style: normal;
    font-weight: 300;
    font-size: 36px;
    line-height: 120%;
    /* or 43px */

    letter-spacing: -0.01em;

    @media ${device.tablet} {
      font-size: 60px;
      line-height: 120%;
      /* or 72px */
    }
  `,
    H1: css `
    font-family: ${fontFamily};
    font-style: normal;
    font-weight: 300;
    font-size: 30px;
    line-height: 130%;
    /* or 62px */

    @media ${device.tablet} {
      font-size: 48px;
    }
  `,
    H2: css `
    font-family: ${fontFamily};
    font-style: normal;
    font-weight: 300;
    font-size: 22px;
    line-height: 150%;

    @media ${device.tablet} {
      font-size: 24px;
      line-height: 160%;
      /* or 38px */
    }
  `,
    H3: css `
    font-family: ${fontFamily};
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 130%;
    /* or 31px */

    @media ${device.tablet} {
      font-size: 34px;
      line-height: 130%;
      /* or 44px */
    }
  `,
    H4: css `
    font-family: ${fontFamily};
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 130%;
    /* or 23px */

    @media ${device.tablet} {
      font-size: 28px;
      line-height: 130%;
      /* or 36px */
    }
  `,
    H5: css `
    font-family: ${fontFamily};
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 130%;
    /* identical to box height, or 21px */

    letter-spacing: 0.15em;
    text-transform: uppercase;
    color: ${color.stone};

    @media ${device.tablet} {
      font-size: 18px;
      line-height: 130%;
      /* or 23px */
    }
  `,
    H6: css `
    font-family: ${fontFamily};
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 130%;
    /* identical to box height, or 21px */

    @media ${device.tablet} {
      font-size: 18px;
      line-height: 130%;
      /* or 23px */
    }
  `,
    H7: css `
    font-family: ${fontFamily};

    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 130%;
    /* identical to box height, or 21px */

    color: ${color.stone};
  `,
    H8L: css `
    font-family: ${fontFamily};
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 160%;
    /* or 32px */
  `,
    H8: css `
    font-family: ${fontFamily};
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 160%;
    /* or 26px */

    @media ${device.tablet} {
      font-size: 18px;
    }
  `,
    H9: css `
    font-family: ${fontFamily};
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 130%;
    /* identical to box height, or 23px */
  `,
    H10: css `
    font-family: ${fontFamily};
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 165%;
    /* or 21px */

    @media ${device.tablet} {
      font-size: 14px;
      line-height: 160%;
      /* or 22px */
    }
  `,
    H10B: css `
    font-family: ${fontFamily};
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 165%;
    /* or 21px */

    color: ${color.fog};

    @media ${device.tablet} {
      font-size: 14px;
      line-height: 160%;
      /* or 22px */
    }
  `,
    H10M: css `
    font-family: ${fontFamily};
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 165%;
    /* or 21px */

    @media ${device.tablet} {
      font-size: 14px;
      line-height: 160%;
      /* or 22px */
    }
  `,
    H11B: css `
    font-family: ${fontFamily};
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 160%;
    /* or 22px */
  `,
    H12B: css `
    font-family: ${fontFamily};
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 140%;

    color: ${color.stone};
  `,
    H13: css `
    font-family: ${fontFamily};
    font-style: normal;
    font-weight: 500;
    font-size: 12px;

    line-height: 160%;
    /* or 19px */

    color: ${color.stone};
  `,
    H14: css `
    font-family: ${fontFamily};
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 160%;
    /* or 19px */

    color: ${color.stone};
  `,
    H20: css `
    font-family: ${fontFamily};
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 31.2px;
    @media ${device.tablet} {
      font-size: 34px;
      line-height: 44.2px;
    }
  `,
    H30: css `
    font-family: ${fontFamily};
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 23.4px;

    @media ${device.tablet} {
      font-size: 28px;
      line-height: 36.4px;
    }
  `,
    H50: css `
    font-family: ${fontFamily};
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;

    @media ${device.tablet} {
      font-size: 18px;
    }
  `,
    H51: css `
    font-family: ${fontFamily};
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 140%;
  `,
    H52: css `
    font-family: ${fontFamily};
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
  `,
    B20: css `
    font-family: ${fontFamily};
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 25.6px;

    @media ${device.desktop} {
      font-size: 18px;
      line-height: 28.8px;
    }
  `,
    B30: css `
    font-family: ${fontFamily};
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 160%;
    color: ${color.cave};
  `,
    B40: css `
    font-family: ${fontFamily};
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 170%;
  `,
    B50: css `
    font-family: ${fontFamily};
    font-style: normal;

    font-weight: 500;
    font-size: 12px;
    line-height: 170%;

    color: ${color.stone};
  `,
    B60: css `
    font-family: ${fontFamily};
    font-style: normal;

    font-weight: 400;
    font-size: 12px;
    line-height: 170%;

    color: ${color.stone};
  `,
    NotificationBadge: css `
    font-family: ${fontFamily};
    font-style: normal;
    font-weight: 500;
    font-size: 8px;
    line-height: 165%;

    color: ${color.pearl};
  `,
    // NEW TYPOGRAPHY
    L1R: css `
    font-family: ${fontFamily};
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  `,
    L1SB: css `
    font-family: ${fontFamily};
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  `,
    L2R: css `
    font-family: ${fontFamily};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  `,
    L2SB: css `
    font-family: ${fontFamily};
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  `,
    L3R: css `
    font-family: ${fontFamily};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  `,
    L3SB: css `
    font-family: ${fontFamily};
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
  `,
    L4R: css `
    font-family: ${fontFamily};
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  `,
    L4SB: css `
    font-family: ${fontFamily};
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
  `,
    D1R: css `
    font-family: ${fontFamily};
    font-size: 42px;
    font-style: normal;
    font-weight: 400;
    line-height: 48px;

    @media ${device.desktop} {
      font-size: 70px;
      line-height: 80px;
    }
  `,
    D2R: css `
    font-family: ${fontFamily};
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;

    @media ${device.desktop} {
      font-size: 56px;
      line-height: 64px;
    }
  `,
    H1SB: css `
    font-family: ${fontFamily};
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;

    @media ${device.desktop} {
      font-size: 48px;
      line-height: 56px;
    }
  `,
    H2SB: css `
    font-family: ${fontFamily};
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;

    @media ${device.desktop} {
      font-size: 34px;
      line-height: 40px;
    }
  `,
    H3M: css `
    font-family: ${fontFamily};
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;

    @media ${device.desktop} {
      font-size: 28px;
      line-height: 32px;
    }
  `,
    H4M: css `
    font-family: ${fontFamily};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 2.4px;
    text-transform: uppercase;

    @media ${device.desktop} {
      font-size: 18px;
      line-height: 24px;
      letter-spacing: 2.7px;
    }
  `,
    H5SB: css `
    font-family: ${fontFamily};
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;

    @media ${device.desktop} {
      font-size: 18px;
      line-height: 24px;
    }
  `,
    P1M: css `
    font-family: ${fontFamily};
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;

    @media ${device.desktop} {
      font-size: 28px;
      line-height: 32px;
    }
  `,
    P2R: css `
    font-family: ${fontFamily};
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;

    @media ${device.desktop} {
      font-size: 24px;
      line-height: 32px;
    }
  `,
    P3R: css `
    font-family: ${fontFamily};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;

    @media ${device.desktop} {
      font-size: 18px;
      line-height: 24px;
    }
  `,
    P4SB: css `
    font-family: ${fontFamily};
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  `,
    P4R: css `
    font-family: ${fontFamily};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  `,
    S1R: css `
    font-family: Noto Serif SC;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;

    @media ${device.desktop} {
      font-size: 18px;
      line-height: 24px;
    }
  `,
};
const priority = {
    background: css `
    z-index: -1;
  `,
    zero: css `
    z-index: 0;
  `,
    low: css `
    z-index: 1;
  `,
    medium: css `
    z-index: 2;
  `,
    mediumHigh: css `
    z-index: 4;
  `,
    high: css `
    z-index: 5;
  `,
    xhigh: css `
    z-index: 10;
  `,
    xxhigh: css `
    z-index: 15;
  `,
    xxxhigh: css `
    z-index: 20;
  `,
    ultra: css `
    z-index: 25;
  `,
    mega: css `
    z-index: 30;
  `,
    always: css `
    z-index: 1002; // once everything is refactored to priority, set back to 10
  `,
};
const shadow = {
    light: `0 2px 4px 0 rgba(0, 0, 0, 0.05)`,
    medium: `0px 4px 4px rgba(0, 0, 0, 0.25)`,
};
const layout = {
    gridContainer: css `
    display: grid;
    grid-template-columns: [viewPortStart] 1fr [contentStart] 290px [contentEnd] 1fr [viewPortEnd];
    grid-template-rows: auto;

    @media ${props => props.theme.device.tablet} {
      grid-template-columns: [viewPortStart] 1fr [contentStart] 510px [contentEnd] 1fr [viewPortEnd];
    }

    @media ${props => props.theme.device.tabletL} {
      grid-template-columns: [viewPortStart] 1fr [contentStart] 690px [contentEnd] 1fr [viewPortEnd];
    }

    @media ${props => props.theme.device.desktop} {
      grid-template-columns: [viewPortStart] 1fr [contentStart] 930px [contentEnd] 1fr [viewPortEnd];
    }

    @media ${props => props.theme.device.desktopL} {
      grid-template-columns: [viewPortStart] 1fr [contentStart] 1110px [contentEnd] 1fr [viewPortEnd];
    }
  `,
    container: css `
    min-width: 290px;
    padding-left: 15px;
    padding-right: 15px;

    @media ${props => props.theme.device.tablet} {
      min-width: 510px;
      padding-left: 45px;
      padding-right: 45px;
    }

    @media ${props => props.theme.device.tabletL} {
      min-width: 690px;
    }

    @media ${props => props.theme.device.desktop} {
      width: 930px;

      margin-left: auto;
      margin-right: auto;

      padding-left: initial;
      padding-right: initial;
    }

    @media ${props => props.theme.device.desktopL} {
      width: 1110px;
    }
  `,
    resetContainer: css `
    width: initial;
    margin-left: initial;
    margin-right: initial;
    flex-grow: 1;
  `,
    col6: css `
    @media ${props => props.theme.device.tabletL} {
      width: 330px;
    }

    @media ${props => props.theme.device.desktop} {
      width: 450px;
    }

    @media ${props => props.theme.device.desktopL} {
      width: 540px;
    }
  `,
    col5: css `
    @media ${props => props.theme.device.desktopL} {
      flex-basis: 41.6667%;
      max-width: 41.666667%;
    }
  `,
    col4: css `
    @media ${props => props.theme.device.tabletL} {
      width: 210px;
    }

    @media ${props => props.theme.device.desktop} {
      width: 290px;
    }

    @media ${props => props.theme.device.desktopL} {
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
    }
  `,
    col3: css `
    @media ${props => props.theme.device.tabletL} {
      width: 236px;
    }

    @media ${props => props.theme.device.desktop} {
      width: 321px;
    }

    @media ${props => props.theme.device.desktopL} {
      flex-basis: 25%;
      max-width: 25%;
    }
  `,
};
export const DefaultTheme = {
    color,
    device,
    typeset,
    priority,
    shadow,
    layout,
};
