import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import { cancellationTypes } from '@nomady/shared/marketplace-custom-config';
import { CancellationType } from '@nomady/shared/types';
import Icon, { IconSizeVariant, IconVariant, } from '../../../styled-components/Icon/Icon';
import Text, { TextVariant } from '../../../styled-components/Text/Text';
const cancellationTypeToDescriptionTranslationKey = {
    [CancellationType.superFlex]: 'ListingPage.cancellationDescription.super_flex',
    [CancellationType.flex]: 'ListingPage.cancellationDescription.flex',
    [CancellationType.easy]: 'ListingPage.cancellationDescription.easy',
    [CancellationType.medium]: 'ListingPage.cancellationDescription.medium',
    [CancellationType.strict]: 'ListingPage.cancellationDescription.strict',
    [CancellationType.none]: 'ListingPage.cancellationDescription.none',
};
const IconWrapper = styled.div `
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 15px;
  padding-bottom: 5px;
`;
const Description = styled.div `
  margin-top: 20px;
`;
const CancellationContent = () => {
    var _a, _b;
    const { listing } = useSelector((state) => state.ListingPage);
    const intl = useIntl();
    const activeCancellationType = ((_b = (_a = listing === null || listing === void 0 ? void 0 : listing.attributes) === null || _a === void 0 ? void 0 : _a.publicData) === null || _b === void 0 ? void 0 : _b.cancellationPolicyType) || [];
    const activeMarketPlaceCancellationType = cancellationTypes.find(cancellationType => {
        var _a, _b;
        return cancellationType.key ===
            ((_b = (_a = listing === null || listing === void 0 ? void 0 : listing.attributes) === null || _a === void 0 ? void 0 : _a.publicData) === null || _b === void 0 ? void 0 : _b.cancellationPolicyType);
    });
    return (_jsxs(_Fragment, { children: [_jsx(IconWrapper, { children: _jsx(Icon, { variant: IconVariant[activeMarketPlaceCancellationType === null || activeMarketPlaceCancellationType === void 0 ? void 0 : activeMarketPlaceCancellationType.iconName], sizeVariant: IconSizeVariant.Large }) }), _jsx(Text, Object.assign({ variant: TextVariant.H20 }, { children: intl.formatMessage({
                    id: activeMarketPlaceCancellationType === null || activeMarketPlaceCancellationType === void 0 ? void 0 : activeMarketPlaceCancellationType.label,
                }) })), _jsx(Description, { children: _jsx(Text, Object.assign({ variant: TextVariant.B20 }, { children: intl.formatMessage({
                        id: cancellationTypeToDescriptionTranslationKey[activeCancellationType],
                    }) })) })] }));
};
export default CancellationContent;
