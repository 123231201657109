import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { ListingLink } from '..';
import { Title } from '../EditListingDescriptionPanel/EditListingDescriptionPanel';
import EditListingRulesForm from '../../forms/EditListingRulesForm/EditListingRulesForm';
const EditListingRulesPanel = props => {
    const { listing, onSubmit, submitButtonText, updateInProgress, errors, onFormDirty, } = props;
    const panelTitle = (_jsx(FormattedMessage, { id: "EditListingRulesPanel.title", values: { listingTitle: _jsx(ListingLink, { listing: listing }) } }));
    return (_jsxs(_Fragment, { children: [_jsx(Title, { children: panelTitle }), _jsx(EditListingRulesForm, { listing: listing, submitButtonLabel: submitButtonText, updateListingInProgress: updateInProgress, updateListingError: errors.updateListingError, onSubmit: onSubmit, onFormDirty: onFormDirty })] }));
};
export default EditListingRulesPanel;
