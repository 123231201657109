var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components/macro';
import { useDrag, useDrop } from 'react-dnd';
import { useIntl } from 'react-intl';
import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import Button, { ButtonSizeVariant } from '../Button/Button';
import { IconVariant } from '../Icon/Icon';
import Modal from '../Modal/Modal';
import InputTextArea from '../InputTextArea/InputTextArea';
import { localizedZodCustomErrorMap } from '@nomady/shared/schemas/localizedZodCustomErrorMap';
const Wrapper = styled.div `
  position: relative;

  ${props => props.isDragging &&
    `
    opacity: 0;
  `}

  ${props => props.isUploadInProgress &&
    `
    opacity: 0.5;
    pointer-events: none;
  `}
`;
const Image = styled.img `
  border-radius: 16px;
  cursor: move;
  width: 100%;
  height: 100%;

  object-fit: cover;
  aspect-ratio: 1 / 1;
`;
const ModalImage = styled(Image) `
  cursor: initial;

  width: auto;
  object-fit: contain;
  aspect-ratio: initial;
  max-height: 380px;
`;
const EditButton = styled.div `
  position: absolute;
  right: 8px;
  top: 8px;

  ${props => props.theme.priority.low};
`;
var DRAG_AND_DROP_TYPE;
(function (DRAG_AND_DROP_TYPE) {
    DRAG_AND_DROP_TYPE["UPLOAD_IMAGE"] = "UPLOAD_IMAGE";
})(DRAG_AND_DROP_TYPE || (DRAG_AND_DROP_TYPE = {}));
const readPicture = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = e => { var _a; return resolve((_a = e.target) === null || _a === void 0 ? void 0 : _a.result); };
    reader.onerror = e => {
        var _a, _b;
        console.error('Error (', e, `) happened while reading ${file.name}: ${(_a = e.target) === null || _a === void 0 ? void 0 : _a.result}`);
        reject(new Error(`Error reading ${file.name}: ${(_b = e.target) === null || _b === void 0 ? void 0 : _b.result}`));
    };
    reader.readAsDataURL(file);
});
const InputPicturesItem = ({ picture, findPicture, movePicture, onClickEdit, isEditing, onEndEditing, onUpdatePicture, onDeletePicture, isUploadInProgress, }) => {
    var _a, _b, _c, _d, _e, _f, _g;
    const [dataUrl, setDataUrl] = useState();
    const intl = useIntl();
    useEffect(() => {
        const loadFile = () => __awaiter(void 0, void 0, void 0, function* () {
            const data = yield readPicture(picture.file);
            setDataUrl(data);
        });
        if (picture === null || picture === void 0 ? void 0 : picture.file) {
            loadFile();
        }
        else {
            setDataUrl(undefined);
        }
    }, [picture]);
    const id = picture === null || picture === void 0 ? void 0 : picture.id.uuid;
    const index = findPicture(id).index;
    const ref = useRef(null);
    const [{ isDragging }, drag] = useDrag({
        type: DRAG_AND_DROP_TYPE.UPLOAD_IMAGE,
        item: () => ({
            id,
            index,
        }),
        collect: monitor => ({
            isDragging: monitor.isDragging(),
        }),
    });
    const [{ handlerId }, drop] = useDrop({
        accept: DRAG_AND_DROP_TYPE.UPLOAD_IMAGE,
        hover(item) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const draggedId = item.id;
            const hoverIndex = index;
            if (dragIndex === hoverIndex) {
                return;
            }
            const { index: overIndex } = findPicture(id);
            movePicture(draggedId, overIndex);
            item.index = hoverIndex;
        },
    });
    drag(drop(ref));
    z.setErrorMap(localizedZodCustomErrorMap(intl, 'ListingSchemaError'));
    const InputSchema = z.object({
        description: z.string().nullish(),
    });
    const description = (_a = picture.attributes) === null || _a === void 0 ? void 0 : _a.description;
    const { handleSubmit, control, formState: { errors }, reset, } = useForm({
        mode: 'onChange',
        defaultValues: { description },
        resolver: zodResolver(InputSchema),
    });
    const onSubmitDescription = () => handleSubmit(data => onUpdatePicture(picture.id.uuid, data))();
    console.log('isUploadInProgress', isUploadInProgress);
    return (_jsxs(_Fragment, { children: [_jsxs(Wrapper, Object.assign({ isDragging: isDragging, isUploadInProgress: isUploadInProgress, ref: ref, "data-handler-id": handlerId }, { children: [_jsx(EditButton, { children: _jsx(Button, { icon: IconVariant.Pen, label: intl.formatMessage({
                                id: 'InputPictures.editButtonLabel',
                            }), type: "button", sizeVariant: ButtonSizeVariant.Small, onClick: () => onClickEdit(picture.id.uuid) }) }), _jsx(Image, { src: dataUrl ||
                            ((_d = (_c = (_b = picture.attributes) === null || _b === void 0 ? void 0 : _b.variants) === null || _c === void 0 ? void 0 : _c['square-small2x']) === null || _d === void 0 ? void 0 : _d.url), alt: description })] })), _jsxs(Modal, Object.assign({ isOpen: isEditing, onClose: () => {
                    reset();
                    onEndEditing(picture.id.uuid);
                }, title: intl.formatMessage({
                    id: 'InputPictures.editPictureTitle',
                }), leftButton: {
                    label: intl.formatMessage({
                        id: 'InputPictures.editPictureDeleteButtonLabel',
                    }),
                    onClick: () => {
                        onDeletePicture(picture.id.uuid);
                    },
                }, rightButton: {
                    label: intl.formatMessage({
                        id: 'InputPictures.editPictureSaveButtonLabel',
                    }),
                    onClick: onSubmitDescription,
                    isPrimary: true,
                } }, { children: [_jsx(ModalImage, { src: dataUrl ||
                            ((_g = (_f = (_e = picture.attributes) === null || _e === void 0 ? void 0 : _e.variants) === null || _f === void 0 ? void 0 : _f['landscape-crop2x']) === null || _g === void 0 ? void 0 : _g.url), alt: description }), _jsx("form", { children: _jsx(InputTextArea, { label: intl.formatMessage({
                                id: 'InputPictures.descriptionLabel',
                            }), placeholder: intl.formatMessage({
                                id: 'InputPictures.descriptionPlaceholder',
                            }), control: control, id: `description-${picture.id.uuid}`, name: "description", error: errors === null || errors === void 0 ? void 0 : errors.description }) })] }))] }));
};
export default InputPicturesItem;
