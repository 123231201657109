import { FireplaceType, FirewoodPayment } from '@nomady/shared/types';
const getFirePlaceSearchParam = (fireplaceSearchQuery) => {
    const values = [];
    if (fireplaceSearchQuery === null || fireplaceSearchQuery === void 0 ? void 0 : fireplaceSearchQuery.grate) {
        values.push(FireplaceType.fireplaceWithGrate);
    }
    if (fireplaceSearchQuery === null || fireplaceSearchQuery === void 0 ? void 0 : fireplaceSearchQuery.firewoodIncluded) {
        values.push(FirewoodPayment.firewoodInclusive);
    }
    if (fireplaceSearchQuery === null || fireplaceSearchQuery === void 0 ? void 0 : fireplaceSearchQuery.firewoodSale) {
        values.push(FirewoodPayment.firewoodPurchasable);
    }
    if (values.length === 0) {
        return undefined;
    }
    return values.join(',');
};
export default getFirePlaceSearchParam;
