import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styled from 'styled-components/macro';
import DogCamping from './assets/dog-camping.webp';
import { TOPBAR_HEIGHT } from '../TopBar/TopBar';
const Wrapper = styled.div `
  ${props => props.theme.layout.container};

  display: flex;
  flex-direction: column;

  @media ${props => props.theme.device.tablet} {
    max-width: 480px;
    margin-left: auto;
    margin-right: auto;
  }

  @media ${props => props.theme.device.desktop} {
    max-width: initial;
    width: 100%;
    flex-direction: row;
  }
`;
const Picture = styled.div `
  display: block;
  width: 100%;
  height: 200px;
  background-image: url(${props => props.backgroundSource});
  background-size: cover;
  background-position: center bottom 30%;
  border-radius: 5px;
  margin: 20px 0;

  @media ${props => props.theme.device.desktop} {
    position: fixed;
    height: calc(100vh - ${TOPBAR_HEIGHT.tablet});
    display: block;
    left: 0;
    top: ${TOPBAR_HEIGHT.tablet};
    width: 50vw;
    border-radius: initial;
    border-top-right-radius: 20px;
  }
`;
const ContentWrap = styled.div `
  width: 100%;
  @media ${props => props.theme.device.desktop} {
    height: calc(100vh - ${TOPBAR_HEIGHT.tablet});
    width: 50vw;
    display: flex;
    justify-content: center;
    margin-left: 50vw;
    overflow-y: auto;
  }
`;
const Content = styled.div `
  width: 100%;
  @media ${props => props.theme.device.desktop} {
    max-width: 600px;
    margin-top: 100px;
  }
`;
const LayoutSplitPicture = ({ children }) => (_jsxs(Wrapper, { children: [_jsx(Picture, { backgroundSource: DogCamping }), _jsx(ContentWrap, { children: _jsx(Content, { children: children }) })] }));
export default LayoutSplitPicture;
