import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styled, { css } from 'styled-components/macro';
import { ColorVariant } from '../../theme';
import Text, { TextVariant } from '../Text/Text';
import CheckedIcon from './assets/checked.svg';
import UncheckedIcon from './assets/unchecked.svg';
import DisabledCheckedIcon from './assets/checked-disabled.svg';
import DisabledUncheckedIcon from './assets/unchecked-disabled.svg';
export var RadioInputSpacingVariant;
(function (RadioInputSpacingVariant) {
    RadioInputSpacingVariant["Default"] = "Default";
    RadioInputSpacingVariant["Slim"] = "Slim";
})(RadioInputSpacingVariant || (RadioInputSpacingVariant = {}));
const InputWrapper = styled.label `
  display: flex;
  padding-left: 10px;
`;
const Input = styled.input `
  width: 18px;
  height: 18px;

  opacity: 0;

  + label {
    position: relative;
    display: inline-block;

    ${props => !props.disabled && `cursor: pointer;`}
    padding: 15px 0;

    width: 100%;

    ${props => props.spacingVariant === RadioInputSpacingVariant.Slim &&
    css `
        padding: 5px 0;
      `}

    &::before {
      content: '';
      display: inline-block;
      position: absolute;
      height: 24px;
      width: 24px;
      left: -29px;

      background-image: url(${props => !props.disabled ? UncheckedIcon : DisabledUncheckedIcon});
    }
  }

  &:checked {
    + label::before {
      background-image: url(${props => !props.disabled ? CheckedIcon : DisabledCheckedIcon});
    }
  }
`;
const Label = styled(Text) `
  line-height: 24px;
`;
const RadioInput = ({ id, name, checked, onChange, spacingVariant, label, disabled, }) => (_jsxs(InputWrapper, { children: [_jsx(Input, { type: "radio", name: name, id: id, spacingVariant: spacingVariant, checked: checked, disabled: disabled, onChange: onChange }), _jsx(Label, Object.assign({ variant: TextVariant.H13, colorVariant: !disabled ? ColorVariant.Cave : ColorVariant.Stone, as: "label", htmlFor: id }, { children: label }))] }));
export default RadioInput;
