import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import routeConfiguration from '@nomady/shared/routeConfiguration/routeConfiguration';
import { scrollPositionSelector, setScrollingPosition, } from '../slices/UISlice';
const useScrollRestoration = (disableScrollRestoration = false) => {
    var _a;
    const location = useLocation();
    const history = useHistory();
    const routeMatch = useRouteMatch();
    const routePath = routeMatch.path;
    const requestedComponent = (_a = routeConfiguration.find(route => route.path === routePath)) === null || _a === void 0 ? void 0 : _a.id;
    const [currentComponent, setCurrentComponent] = useState();
    const prevComponentRef = useRef();
    const dispatch = useDispatch();
    const shouldRestoreScroll = history.action === 'POP';
    const scrollingPosition = useSelector((state) => scrollPositionSelector(state, location.pathname));
    useLayoutEffect(() => {
        prevComponentRef.current = currentComponent;
        const shouldScrollToTop = history.action === 'PUSH' && requestedComponent === currentComponent;
        if (shouldScrollToTop) {
            window.scrollTo({ top: 0 });
        }
    }, [location]);
    useEffect(() => {
        if (requestedComponent) {
            setCurrentComponent(requestedComponent);
        }
        if (disableScrollRestoration) {
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            return () => { };
        }
        window.history.scrollRestoration = 'manual';
        const { pathname } = location;
        if (shouldRestoreScroll && 0 < scrollingPosition) {
            window.scrollTo({ top: scrollingPosition });
        }
        return () => {
            dispatch(setScrollingPosition({
                pathname,
                scrollingPosition: (window === null || window === void 0 ? void 0 : window.scrollY) || 0,
            }));
            window.history.scrollRestoration = 'auto';
        };
    }, []);
};
export default useScrollRestoration;
