import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import IntroSheetContent from './IntroSheetContent';
import Text, { TextVariant } from '../../Text/Text';
import styled from 'styled-components/macro';
import { ColorVariant } from '../../../theme';
import Button, { ButtonColorVariant, ButtonSizeVariant, } from '../../Button/Button';
import Page from '@nomady/shared/routeConfiguration/Page';
import { NamedLink } from '../../../components';
import { useSelector } from 'react-redux';
import background from '../assets/231122_Nomady_DigitalFormats_AppWelcome4.webp';
import { useIntl } from 'react-intl';
import { safeSpaceTopSelector } from '../../../slices/UISlice';
import Icon, { IconSizeVariant, IconVariant } from '../../Icon/Icon';
import { CampStyle } from '@nomady/shared/types';
import campStyleIcons from '../../../campStyleIcons';
const Wrapper = styled.div `
  position: fixed;
  background-color: ${props => props.theme.color.snow};
  width: 100vw;
  height: 100vh;
  z-index: 20;
  display: flex;
  flex-direction: column;
  padding-top: ${props => props.safeSpaceTop}px;
  background-image: url(${background});
  background-position: bottom;
  background-size: cover;
`;
const LoginButtonAndTextWrapper = styled.div `
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
const RoleSelectionWrapper = styled.div `
  margin-bottom: 32px;
  position: relative;
  height: 100%;
  min-height: 0;
  display: flex;
  flex-direction: column;
`;
const RoleSelectionCardWrapper = styled.div `
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
  min-height: 0;
  justify-content: center;
`;
const RoleSelectionCard = styled.div `
  padding: 32px;
  border-radius: 16px;
  background-color: ${props => props.theme.color.pearl};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
const CloseSheetButton = styled.div `
  position: fixed;
  top: calc(${props => props.safeSpaceTop}px + 32px);
  right: 32px;
  padding: 8px;
  cursor: pointer;
  background-color: ${props => props.theme.color.pearl};
  border-radius: 16px;
`;
const IconRow = styled.div `
  display: flex;
  flex-wrap: nowrap;
  gap: 4px;
  overflow: hidden;
`;
const SheetRoleSelect = ({ onClose, }) => {
    const safeSpaceTop = useSelector(safeSpaceTopSelector);
    const intl = useIntl();
    return (_jsx(Wrapper, Object.assign({ safeSpaceTop: safeSpaceTop }, { children: _jsx(IntroSheetContent, { title: intl.formatMessage({
                id: 'AppIntro.RoleSelectSheet.Title',
            }), content: _jsxs(RoleSelectionWrapper, { children: [_jsxs(RoleSelectionCardWrapper, { children: [_jsx(NamedLink, Object.assign({ name: Page.Signup }, { children: _jsxs(RoleSelectionCard, { children: [_jsx(Text, Object.assign({ variant: TextVariant.H4 }, { children: intl.formatMessage({
                                                id: 'AppIntro.RoleSelectSheet.GuestButton',
                                            }) })), _jsx(IconRow, { children: [
                                                CampStyle.tent,
                                                CampStyle.carWithRooftopTent,
                                                CampStyle.smallVehicle,
                                                CampStyle.mediumVehicle,
                                                CampStyle.largeVehicle,
                                                CampStyle.caravan,
                                            ].map(campStyle => (_jsx(Icon, { variant: campStyleIcons[campStyle], sizeVariant: IconSizeVariant.Medium, colorVariant: ColorVariant.BasicCaveBlack }, `icon-${campStyle}`))) }), _jsx(IconRow, { children: [CampStyle.hut, CampStyle.glamping, CampStyle.shelter].map(campStyle => (_jsx(Icon, { variant: campStyleIcons[campStyle], sizeVariant: IconSizeVariant.Medium, colorVariant: ColorVariant.BasicCaveBlack }, `icon-${campStyle}`))) })] }) })), _jsx(NamedLink, Object.assign({ name: Page.Signup }, { children: _jsxs(RoleSelectionCard, { children: [_jsx(Text, Object.assign({ variant: TextVariant.H4 }, { children: intl.formatMessage({
                                                id: 'AppIntro.RoleSelectSheet.HostButton',
                                            }) })), _jsx(IconRow, { children: _jsx(Icon, { variant: IconVariant.Host, sizeVariant: IconSizeVariant.Medium, colorVariant: ColorVariant.BasicCaveBlack }) })] }) }))] }), _jsxs(LoginButtonAndTextWrapper, { children: [_jsx(Text, Object.assign({ variant: TextVariant.B30, colorVariant: ColorVariant.Cave }, { children: intl.formatMessage({
                                    id: 'AppIntro.RoleSelectSheet.AlreadyHaveAccountHint',
                                }) })), _jsx(Button, { sizeVariant: ButtonSizeVariant.Default, colorVariant: ButtonColorVariant.Light, targetRoute: Page.Login, label: intl.formatMessage({
                                    id: 'AppIntro.RoleSelectSheet.LoginButton',
                                }) })] }), _jsx(CloseSheetButton, Object.assign({ safeSpaceTop: safeSpaceTop, onClick: onClose }, { children: _jsx(Icon, { variant: IconVariant.X_Close, sizeVariant: IconSizeVariant.Default, colorVariant: ColorVariant.BasicCaveBlack }) }))] }) }, 2) })));
};
export default SheetRoleSelect;
