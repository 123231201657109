import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styled from 'styled-components/macro';
import { useIntl } from 'react-intl';
import Text, { TextVariant } from '../../../styled-components/Text/Text';
import { ColorVariant } from '../../../theme';
const CrookedNumber = styled(Text) `
  transform: rotate(
    ${props => (props.rotation === 'right' ? '15deg' : '-15deg')}
  );
  padding: 10px 15px;
  margin-left: 10px;
`;
const Wrapper = styled.div `
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.color.pearl};
  padding: 30px 15px;
  margin-inline: -15px;
  border-radius: 0px;
  margin-top: 30px;
  @media ${props => props.theme.device.tablet} {
    padding: 30px 40px 10px;
    border-radius: 10px;
    margin-inline: 0px;
  }
`;
const Title = styled(Text) `
  margin-bottom: 20px;
`;
const HighlightList = styled.ul `
  list-style: none;
  margin: 0;
  padding: 0;
`;
const HighlightWrapper = styled.div `
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 0px;
  border-bottom: 1px solid ${props => props.theme.color.fog};
  &:last-child {
    border-bottom: none;
  }
`;
const HighlightsSection = ({ highlights }) => {
    const intl = useIntl();
    return (_jsxs(Wrapper, { children: [_jsx(Title, Object.assign({ "data-testid": "HighlightsSection_Title", as: "h2", variant: TextVariant.H30, colorVariant: ColorVariant.Cave }, { children: intl.formatMessage({ id: 'ListingPage.highlights' }) })), _jsx(HighlightList, { children: highlights.map((highlight, index) => (_jsxs(HighlightWrapper, Object.assign({ as: "li" }, { children: [_jsx(CrookedNumber, Object.assign({ rotation: index % 2 === 0 ? 'left' : 'right', variant: TextVariant.H51, colorVariant: ColorVariant.Cave }, { children: index + 1 })), _jsx(Text, Object.assign({ variant: TextVariant.H51, colorVariant: ColorVariant.Cave }, { children: highlight }))] }), highlight))) })] }));
};
export default HighlightsSection;
