import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { useSelector } from 'react-redux';
import { listingsSelector, selectedListingUUIDSelector, } from '../CalendarSlice';
import AvailabilityDetailsForAllListingsModal from './AvailabilityDetailsForAllListingsModal';
import AvailabilityDetailsForSingleListingModal from './AvailabilityDetailsForSingleListingModal';
import { ALL_LISTINGS_KEY } from '../constants';
const AvailabilityDetailsModal = ({ selectedSlots, setSelectedSlots, }) => {
    const listings = useSelector(listingsSelector);
    const selectedListingUUID = useSelector(selectedListingUUIDSelector);
    const selectedListing = selectedListingUUID !== ALL_LISTINGS_KEY && listings
        ? listings.find(listing => listing.id.uuid === selectedListingUUID.uuid)
        : undefined;
    const open = !!selectedSlots;
    const onClose = () => setSelectedSlots(undefined);
    if (selectedListing === undefined &&
        selectedListingUUID === ALL_LISTINGS_KEY) {
        return (_jsx(AvailabilityDetailsForAllListingsModal, { open: open, onClose: onClose, selectedSlots: selectedSlots }));
    }
    if (selectedListing !== undefined) {
        return (_jsx(AvailabilityDetailsForSingleListingModal, { open: open, onClose: onClose, selectedSlots: selectedSlots, selectedListing: selectedListing }));
    }
    return null;
};
export default AvailabilityDetailsModal;
