import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Controller, } from 'react-hook-form';
import styled from 'styled-components/macro';
import InputErrorMessage from '../InputErrorMessage/InputErrorMessage';
import { TextVariant } from '../Text/Text';
import { Wrapper, Label, ErrorMessageWrapper, FakeInput, Description, } from '../Input/Input';
import CheckboxInput, { CheckboxInputSpacingVariant, } from '../CheckboxInput/CheckboxInput';
const StyledFakeInput = styled(FakeInput) `
  display: grid;
  padding: 0 16px;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 8px;
  padding: 14px;
`;
const InputMultiSelectSlim = ({ id, label, description, variant, error, options, control, rules, name, }) => {
    const hasError = !!error;
    const errorMessageId = hasError ? `${id}-errormessage` : '';
    const errorMessageAriaInputProps = hasError
        ? { 'aria-invalid': true, 'aria-errormessage': errorMessageId }
        : {};
    return (_jsx(Controller, { render: ({ field }) => {
            const selectedValues = field.value || [];
            return (_jsxs(Wrapper, { children: [label && (_jsx(Label, Object.assign({ inputVariant: variant, variant: TextVariant.H13, as: "label", htmlFor: id, hasError: hasError }, { children: label }))), _jsx(StyledFakeInput, Object.assign({ variant: variant, hasError: hasError }, errorMessageAriaInputProps, { children: options.map(({ value, label, icon }) => (_jsx(CheckboxInput, { id: value, label: label, checked: selectedValues.includes(value), spacingVariant: CheckboxInputSpacingVariant.Slim, onChange: () => {
                                const checked = selectedValues.includes(value);
                                if (checked) {
                                    const index = selectedValues.indexOf(value);
                                    const editedArray = selectedValues;
                                    editedArray.splice(index, 1);
                                    return field.onChange([...editedArray]);
                                }
                                return field.onChange([...selectedValues, value]);
                            } }, `${id}-${value}`))) })), hasError && (_jsx(ErrorMessageWrapper, Object.assign({ role: "alert" }, { children: _jsx(InputErrorMessage, { id: errorMessageId, error: error }) }))), description && _jsx(Description, { text: description })] }));
        }, name: name, control: control, rules: rules }));
};
export default InputMultiSelectSlim;
