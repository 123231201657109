import moment from 'moment';
import { BOOKING_PROCESS_NAME } from '@nomady/shared/config';
import { ensureTransaction } from './data';
import * as custom from '../marketplace-custom-config';
/**
 * Transitions
 *
 * These strings must sync with values defined in Flex API,
 * since transaction objects given by API contain info about last transitions.
 * All the actions in API side happen in transitions,
 * so we need to understand what those strings mean.
 */
// When a customer makes a booking to a listing, a transaction is
// created with the initial request-payment transition.
// At this transition a PaymentIntent is created by Marketplace API.
// After this transition, the actual payment must be made on client-side directly to Stripe.
export const TRANSITION_REQUEST_PAYMENT = 'transition/request-payment';
export const TRANSITION_PAY_FULL_AMOUNT_WITH_GIFT_CODE = 'transition/pay-full-amount-with-gift-code';
export const TRANSITION_PAY_FULL_AMOUNT_WITH_GIFT_CODE_FOR_INSTANT_BOOKING = 'transition/pay-full-amount-with-gift-code-for-instant-booking';
// Stripe SDK might need to ask 3D security from customer, in a separate front-end step.
// Therefore we need to make another transition to Marketplace API,
// to tell that the payment is confirmed.
export const TRANSITION_CONFIRM_PAYMENT = 'transition/confirm-payment';
export const TRANSITION_CONFIRM_PAYMENT_FOR_INSTANT_BOOKING = 'transition/confirm-payment-for-instant-booking';
// If the payment is not confirmed in the time limit set in transaction process (by default 15min)
// the transaction will expire automatically.
export const TRANSITION_EXPIRE_CREATE_PAYMENT = 'transition/expire-create-payment';
export const TRANSITION_EXPIRE_PAYMENT = 'transition/expire-payment';
// When the provider accepts or declines a transaction from the
// SalePage, it is transitioned with the accept or decline transition.
export const TRANSITION_ACCEPT = 'transition/accept';
export const TRANSITION_ACCEPT_FULL_AMOUNT_WITH_GIFT_CODE = 'transition/accept-full-amount-with-gift-code';
export const TRANSITION_ACCEPT_BY_OPERATOR = 'transition/accept-by-operator';
export const TRANSITION_ACCEPT_BY_OPERATOR_FULL_AMOUNT_WITH_GIFT_CODE = 'transition/accept-by-operator-full-amount-with-gift-code';
export const TRANSITION_DECLINE = 'transition/decline';
export const TRANSITION_DECLINE_FULL_AMOUNT_WITH_GIFT_CODE = 'transition/decline-full-amount-with-gift-code';
export const TRANSITION_DECLINE_BY_OPERATOR = 'transition/decline-by-operator';
export const TRANSITION_DECLINE_BY_OPERATOR_FULL_AMOUNT_WITH_GIFT_CODE = 'transition/decline-by-operator-full-amount-with-gift-code';
export const TRANSITION_UPDATE_BOOKING_BY_OPERATOR = 'transition/update-booking-by-operator';
export const TRANSITION_WITHDRAW_BOOKING_REQUEST_BY_CUSTOMER = 'transition/withdraw-booking-request-by-customer';
export const TRANSITION_WITHDRAW_BOOKING_REQUEST_BY_CUSTOMER_FULL_AMOUNT_WITH_GIFT_CODE = 'transition/withdraw-booking-request-by-customer-full-amount-with-gift-code';
// The backend automatically expire the transaction.
export const TRANSITION_EXPIRE = 'transition/expire';
export const TRANSITION_EXPIRE_FULL_AMOUNT_WITH_GIFT_CODE = 'transition/expire-full-amount-with-gift-code';
// Admin can also cancel the transition.
export const TRANSITION_CANCEL_BY_OPERATOR = 'transition/cancel-by-operator';
export const TRANSITION_CANCEL_BY_OPERATOR_FULL_AMOUNT_WITH_GIFT_CODE = 'transition/cancel-by-operator-full-amount-with-gift-code';
export const TRANSITION_CANCEL_FOR_MANUAL_REFUND_BY_OPERATOR = 'transition/cancel-for-manual-refund-by-operator';
// LEGACY TRANSITION
export const TRANSITION_CANCEL_BY_CUSTOMER = 'transition/cancel-by-customer';
export const TRANSITION_CANCEL_BY_CUSTOMER_OUTSIDE_CANCELLATION_POLICY_PARTIAL_REFUND = 'transition/cancel-by-customer-outside-cancellation-policy-partial-refund';
export const TRANSITION_CANCEL_BY_CUSTOMER_WITHIN_CANCELLATION_POLICY = 'transition/cancel-by-customer-within-cancellation-policy';
export const TRANSITION_CANCEL_BY_CUSTOMER_OUTSIDE_CANCELLATION_POLICY = 'transition/cancel-by-customer-outside-cancellation-policy';
// The backend will mark the transaction completed.
export const TRANSITION_COMPLETE = 'transition/complete';
export const TRANSITION_COMPLETE_FULL_AMOUNT_WITH_GIFT_CODE = 'transition/complete-full-amount-with-gift-code';
// Reviews are given through transaction transitions. Review 1 can be
// by provider or customer, and review 2 will be the other party of
// the transaction.
export const TRANSITION_REVIEW_1_BY_PROVIDER = 'transition/review-1-by-provider';
export const TRANSITION_REVIEW_2_BY_PROVIDER = 'transition/review-2-by-provider';
export const TRANSITION_REVIEW_1_BY_CUSTOMER = 'transition/review-1-by-customer';
export const TRANSITION_REVIEW_2_BY_CUSTOMER = 'transition/review-2-by-customer';
export const TRANSITION_EXPIRE_CUSTOMER_REVIEW_PERIOD = 'transition/expire-customer-review-period';
export const TRANSITION_EXPIRE_PROVIDER_REVIEW_PERIOD = 'transition/expire-provider-review-period';
export const TRANSITION_EXPIRE_REVIEW_PERIOD = 'transition/expire-review-period';
export const TRANSITION_REACTIVATE_CUSTOMER_REVIEW_PERIOD_BY_OPERATOR = 'transition/reactivate-customer-review-period-by-operator';
export const TRANSITION_REACTIVATE_PROVIDER_REVIEW_PERIOD_BY_OPERATOR = 'transition/reactivate-provider-review-period-by-operator';
export const TRANSITION_REACTIVATE_CUSTOMER_AND_PROVIDER_REVIEW_PERIOD_BY_OPERATOR = 'transition/reactivate-customer-and-provider-review-period-by-operator';
export const TRANSITION_CLOSE_CUSTOMER_REVIEW_PERIOD_BY_OPERATOR = 'transition/close-customer-review-period-by-operator';
export const TRANSITION_CLOSE_PROVIDER_REVIEW_PERIOD_BY_OPERATOR = 'transition/close-provider-review-period-by-operator';
export const TRANSITION_CLOSE_CUSTOMER_AND_PROVIDER_REVIEW_PERIOD_BY_OPERATOR = 'transition/close-customer-and-provider-review-period-by-operator';
export const TRANSITION_PAY_OUT = 'transition/pay-out';
/**
 * Actors
 *
 * There are 4 different actors that might initiate transitions:
 */
// Roles of actors that perform transaction transitions
export const TX_TRANSITION_ACTOR_CUSTOMER = 'customer';
export const TX_TRANSITION_ACTOR_PROVIDER = 'provider';
export const TX_TRANSITION_ACTOR_SYSTEM = 'system';
export const TX_TRANSITION_ACTOR_OPERATOR = 'operator';
export const TX_TRANSITION_ACTORS = [
    TX_TRANSITION_ACTOR_CUSTOMER,
    TX_TRANSITION_ACTOR_PROVIDER,
    TX_TRANSITION_ACTOR_SYSTEM,
    TX_TRANSITION_ACTOR_OPERATOR,
];
export const TX_MANUAL_REFUND_TRANSITIONS = [
    TRANSITION_CANCEL_FOR_MANUAL_REFUND_BY_OPERATOR,
    TRANSITION_CANCEL_BY_CUSTOMER,
];
export const TX_WITHDRAW_TRANSITIONS = [
    TRANSITION_WITHDRAW_BOOKING_REQUEST_BY_CUSTOMER,
    TRANSITION_WITHDRAW_BOOKING_REQUEST_BY_CUSTOMER_FULL_AMOUNT_WITH_GIFT_CODE,
];
/**
 * States
 *
 * These constants are only for making it clear how transitions work together.
 * You should not use these constants outside of this file.
 *
 * Note: these states are not in sync with states used transaction process definitions
 *       in Marketplace API. Only last transitions are passed along transaction object.
 */
const STATE_INITIAL = 'initial';
const STATE_PENDING_PAYMENT = 'pending-payment';
const STATE_PAYMENT_EXPIRED = 'payment-expired';
const STATE_PREAUTHORIZED = 'preauthorized';
const STATE_DECLINED = 'declined';
const STATE_ACCEPTED = 'accepted';
const STATE_CANCELLED = 'canceled';
const STATE_PAID_OUT = 'paid-out';
const STATE_WITHDRAWN = 'withdrawn';
const STATE_DELIVERED = 'delivered';
const STATE_REVIEWED = 'reviewed';
const STATE_REVIEWED_BY_CUSTOMER = 'reviewed-by-customer';
const STATE_REVIEWED_BY_PROVIDER = 'reviewed-by-provider';
/**
 * Description of transaction process
 *
 * You should keep this in sync with transaction process defined in Marketplace API
 *
 * Note: we don't use yet any state machine library,
 *       but this description format is following Xstate (FSM library)
 *       https://xstate.js.org/docs/
 */
const stateDescription = {
    // id is defined only to support Xstate format.
    // However if you have multiple transaction processes defined,
    // it is best to keep them in sync with transaction process aliases.
    id: BOOKING_PROCESS_NAME,
    // This 'initial' state is a starting point for new transaction
    initial: STATE_INITIAL,
    // States
    states: {
        [STATE_INITIAL]: {
            on: {
                [TRANSITION_REQUEST_PAYMENT]: STATE_PENDING_PAYMENT,
                [TRANSITION_PAY_FULL_AMOUNT_WITH_GIFT_CODE]: STATE_PREAUTHORIZED,
                [TRANSITION_PAY_FULL_AMOUNT_WITH_GIFT_CODE_FOR_INSTANT_BOOKING]: STATE_ACCEPTED,
            },
        },
        [STATE_PENDING_PAYMENT]: {
            on: {
                [TRANSITION_EXPIRE_PAYMENT]: STATE_PAYMENT_EXPIRED,
                [TRANSITION_CONFIRM_PAYMENT]: STATE_PREAUTHORIZED,
                [TRANSITION_CONFIRM_PAYMENT_FOR_INSTANT_BOOKING]: STATE_ACCEPTED,
            },
        },
        [STATE_PAYMENT_EXPIRED]: {},
        [STATE_PREAUTHORIZED]: {
            on: {
                [TRANSITION_DECLINE]: STATE_DECLINED,
                [TRANSITION_DECLINE_FULL_AMOUNT_WITH_GIFT_CODE]: STATE_DECLINED,
                [TRANSITION_DECLINE_BY_OPERATOR]: STATE_DECLINED,
                [TRANSITION_DECLINE_BY_OPERATOR_FULL_AMOUNT_WITH_GIFT_CODE]: STATE_DECLINED,
                [TRANSITION_WITHDRAW_BOOKING_REQUEST_BY_CUSTOMER]: STATE_WITHDRAWN,
                [TRANSITION_WITHDRAW_BOOKING_REQUEST_BY_CUSTOMER_FULL_AMOUNT_WITH_GIFT_CODE]: STATE_WITHDRAWN,
                [TRANSITION_EXPIRE]: STATE_DECLINED,
                [TRANSITION_EXPIRE_FULL_AMOUNT_WITH_GIFT_CODE]: STATE_DECLINED,
                [TRANSITION_ACCEPT]: STATE_ACCEPTED,
                [TRANSITION_ACCEPT_FULL_AMOUNT_WITH_GIFT_CODE]: STATE_ACCEPTED,
                [TRANSITION_ACCEPT_BY_OPERATOR]: STATE_ACCEPTED,
                [TRANSITION_ACCEPT_BY_OPERATOR_FULL_AMOUNT_WITH_GIFT_CODE]: STATE_ACCEPTED,
            },
        },
        [STATE_DECLINED]: {},
        [STATE_ACCEPTED]: {
            on: {
                [TRANSITION_CANCEL_BY_OPERATOR]: STATE_CANCELLED,
                [TRANSITION_CANCEL_BY_OPERATOR_FULL_AMOUNT_WITH_GIFT_CODE]: STATE_CANCELLED,
                [TRANSITION_CANCEL_FOR_MANUAL_REFUND_BY_OPERATOR]: STATE_CANCELLED,
                [TRANSITION_CANCEL_BY_CUSTOMER]: STATE_CANCELLED,
                [TRANSITION_CANCEL_BY_CUSTOMER_OUTSIDE_CANCELLATION_POLICY_PARTIAL_REFUND]: STATE_CANCELLED,
                [TRANSITION_CANCEL_BY_CUSTOMER_OUTSIDE_CANCELLATION_POLICY]: STATE_CANCELLED,
                [TRANSITION_CANCEL_BY_CUSTOMER_WITHIN_CANCELLATION_POLICY]: STATE_CANCELLED,
                [TRANSITION_COMPLETE]: STATE_DELIVERED,
                [TRANSITION_COMPLETE_FULL_AMOUNT_WITH_GIFT_CODE]: STATE_DELIVERED,
                [TRANSITION_UPDATE_BOOKING_BY_OPERATOR]: STATE_ACCEPTED,
            },
        },
        [STATE_CANCELLED]: { on: { [TRANSITION_PAY_OUT]: STATE_PAID_OUT } },
        [STATE_PAID_OUT]: {},
        [STATE_WITHDRAWN]: {},
        [STATE_DELIVERED]: {
            on: {
                [TRANSITION_EXPIRE_REVIEW_PERIOD]: STATE_REVIEWED,
                [TRANSITION_REVIEW_1_BY_CUSTOMER]: STATE_REVIEWED_BY_CUSTOMER,
                [TRANSITION_REVIEW_1_BY_PROVIDER]: STATE_REVIEWED_BY_PROVIDER,
                [TRANSITION_CLOSE_CUSTOMER_AND_PROVIDER_REVIEW_PERIOD_BY_OPERATOR]: STATE_REVIEWED,
            },
        },
        [STATE_REVIEWED_BY_CUSTOMER]: {
            on: {
                [TRANSITION_REVIEW_2_BY_PROVIDER]: STATE_REVIEWED,
                [TRANSITION_EXPIRE_PROVIDER_REVIEW_PERIOD]: STATE_REVIEWED,
                [TRANSITION_CLOSE_PROVIDER_REVIEW_PERIOD_BY_OPERATOR]: STATE_REVIEWED,
            },
        },
        [STATE_REVIEWED_BY_PROVIDER]: {
            on: {
                [TRANSITION_REVIEW_2_BY_CUSTOMER]: STATE_REVIEWED,
                [TRANSITION_EXPIRE_CUSTOMER_REVIEW_PERIOD]: STATE_REVIEWED,
                [TRANSITION_CLOSE_CUSTOMER_REVIEW_PERIOD_BY_OPERATOR]: STATE_REVIEWED,
            },
        },
        [STATE_REVIEWED]: {
            type: 'final',
            on: {
                [TRANSITION_REACTIVATE_CUSTOMER_REVIEW_PERIOD_BY_OPERATOR]: STATE_REVIEWED_BY_PROVIDER,
                [TRANSITION_REACTIVATE_PROVIDER_REVIEW_PERIOD_BY_OPERATOR]: STATE_REVIEWED_BY_CUSTOMER,
                [TRANSITION_REACTIVATE_CUSTOMER_AND_PROVIDER_REVIEW_PERIOD_BY_OPERATOR]: STATE_DELIVERED,
            },
        },
    },
};
// Note: currently we assume that state description doesn't contain nested states.
const statesFromStateDescription = (description) => description.states || {};
// Get all the transitions from states object in an array
const getTransitions = (states) => {
    const stateNames = Object.keys(states);
    const transitionsReducer = (transitionArray, name) => {
        const stateTransitions = states[name] && states[name].on;
        const transitionKeys = stateTransitions
            ? Object.keys(stateTransitions)
            : [];
        return [
            ...transitionArray,
            ...transitionKeys.map(key => ({ key, value: stateTransitions[key] })),
        ];
    };
    return stateNames.reduce(transitionsReducer, []);
};
export const PROVIDER_TRANSITIONS = [
    TRANSITION_CONFIRM_PAYMENT_FOR_INSTANT_BOOKING,
    TRANSITION_ACCEPT,
    TRANSITION_ACCEPT_FULL_AMOUNT_WITH_GIFT_CODE,
    TRANSITION_ACCEPT_BY_OPERATOR,
    TRANSITION_ACCEPT_BY_OPERATOR_FULL_AMOUNT_WITH_GIFT_CODE,
    TRANSITION_CANCEL_BY_OPERATOR,
    TRANSITION_CANCEL_BY_OPERATOR_FULL_AMOUNT_WITH_GIFT_CODE,
    TRANSITION_CANCEL_FOR_MANUAL_REFUND_BY_OPERATOR,
    TRANSITION_CANCEL_BY_CUSTOMER_OUTSIDE_CANCELLATION_POLICY,
    TRANSITION_CANCEL_BY_CUSTOMER_OUTSIDE_CANCELLATION_POLICY_PARTIAL_REFUND,
    TRANSITION_CANCEL_BY_CUSTOMER_WITHIN_CANCELLATION_POLICY,
    TRANSITION_CANCEL_BY_CUSTOMER,
    TRANSITION_COMPLETE,
    TRANSITION_COMPLETE_FULL_AMOUNT_WITH_GIFT_CODE,
    TRANSITION_CONFIRM_PAYMENT,
    TRANSITION_CONFIRM_PAYMENT_FOR_INSTANT_BOOKING,
    TRANSITION_PAY_FULL_AMOUNT_WITH_GIFT_CODE,
    TRANSITION_PAY_FULL_AMOUNT_WITH_GIFT_CODE_FOR_INSTANT_BOOKING,
    TRANSITION_DECLINE,
    TRANSITION_DECLINE_FULL_AMOUNT_WITH_GIFT_CODE,
    TRANSITION_DECLINE_BY_OPERATOR,
    TRANSITION_DECLINE_BY_OPERATOR_FULL_AMOUNT_WITH_GIFT_CODE,
    TRANSITION_WITHDRAW_BOOKING_REQUEST_BY_CUSTOMER,
    TRANSITION_WITHDRAW_BOOKING_REQUEST_BY_CUSTOMER_FULL_AMOUNT_WITH_GIFT_CODE,
    TRANSITION_EXPIRE,
    TRANSITION_EXPIRE_FULL_AMOUNT_WITH_GIFT_CODE,
    TRANSITION_EXPIRE_REVIEW_PERIOD,
    TRANSITION_REVIEW_1_BY_CUSTOMER,
    TRANSITION_REVIEW_1_BY_PROVIDER,
    TRANSITION_REVIEW_2_BY_CUSTOMER,
    TRANSITION_REVIEW_2_BY_PROVIDER,
    TRANSITION_EXPIRE_CUSTOMER_REVIEW_PERIOD,
    TRANSITION_EXPIRE_PROVIDER_REVIEW_PERIOD,
    TRANSITION_REACTIVATE_CUSTOMER_REVIEW_PERIOD_BY_OPERATOR,
    TRANSITION_REACTIVATE_PROVIDER_REVIEW_PERIOD_BY_OPERATOR,
    TRANSITION_REACTIVATE_CUSTOMER_AND_PROVIDER_REVIEW_PERIOD_BY_OPERATOR,
    TRANSITION_CLOSE_CUSTOMER_REVIEW_PERIOD_BY_OPERATOR,
    TRANSITION_CLOSE_PROVIDER_REVIEW_PERIOD_BY_OPERATOR,
    TRANSITION_CLOSE_CUSTOMER_AND_PROVIDER_REVIEW_PERIOD_BY_OPERATOR,
    TRANSITION_UPDATE_BOOKING_BY_OPERATOR,
    TRANSITION_PAY_OUT,
];
// This is a list of all the transitions that this app should be able to handle.
export const TRANSITIONS = getTransitions(statesFromStateDescription(stateDescription)).map(t => t.key);
// This function returns a function that has given stateDesc in scope chain.
const getTransitionsToStateFn = (stateDesc) => (state) => getTransitions(statesFromStateDescription(stateDesc))
    .filter(t => t.value === state)
    .map(t => t.key);
// Get all the transitions that lead to specified state.
const getTransitionsToState = getTransitionsToStateFn(stateDescription);
// This is needed to fetch transactions that need response from provider.
// I.e. transactions which provider needs to accept or decline
export const transitionsToRequested = getTransitionsToState(STATE_PREAUTHORIZED);
/**
 * Helper functions to figure out if transaction is in a specific state.
 * State is based on lastTransition given by transaction object and state description.
 */
const txLastTransition = (tx) => ensureTransaction(tx).attributes.lastTransition;
export const txIsPaymentPending = (tx) => getTransitionsToState(STATE_PENDING_PAYMENT).includes(txLastTransition(tx));
export const txIsPaymentExpired = (tx) => getTransitionsToState(STATE_PAYMENT_EXPIRED).includes(txLastTransition(tx));
// Note: state name used in Marketplace API docs (and here) is actually preauthorized
// However, word "requested" is used in many places so that we decided to keep it.
export const txIsRequested = (tx) => getTransitionsToState(STATE_PREAUTHORIZED).includes(txLastTransition(tx));
export const txIsAccepted = (tx) => getTransitionsToState(STATE_ACCEPTED).includes(txLastTransition(tx));
export const txIsExpired = (tx) => getTransitionsToState(STATE_DECLINED)
    .filter(element => element === TRANSITION_EXPIRE)
    .includes(txLastTransition(tx));
export const txIsDeclined = (tx) => getTransitionsToState(STATE_DECLINED)
    .filter(element => element !== TRANSITION_EXPIRE)
    .includes(txLastTransition(tx));
export const txIsWithdrawn = (tx) => getTransitionsToState(STATE_WITHDRAWN).includes(txLastTransition(tx));
export const txIsCancelledWithManualRefund = (tx) => {
    return (getTransitionsToState(STATE_CANCELLED).includes(txLastTransition(tx)) &&
        TX_MANUAL_REFUND_TRANSITIONS.includes(txLastTransition(tx)));
};
export const txIsCancelled = (tx) => getTransitionsToState(STATE_CANCELLED).includes(txLastTransition(tx));
export const txIsPaidOut = (tx) => getTransitionsToState(STATE_PAID_OUT).includes(txLastTransition(tx));
export const txIsCancelledByCustomerOutsideCancellationPolicy = (tx) => TRANSITION_CANCEL_BY_CUSTOMER_OUTSIDE_CANCELLATION_POLICY ===
    txLastTransition(tx);
export const txIsCancelledByCustomerOutsideCancellationPolicyPartialRefund = (tx) => TRANSITION_CANCEL_BY_CUSTOMER_OUTSIDE_CANCELLATION_POLICY_PARTIAL_REFUND ===
    txLastTransition(tx);
export const txIsCancellationPossibleByCancellationPolicy = (tx, cancellationPolicyType, isCustomer, booking, checkInTime) => {
    if (!booking) {
        throw new Error('booking not defined');
    }
    if (!isCustomer) {
        return false;
    }
    if (txIsAccepted(tx)) {
        const bookingAttributes = (booking === null || booking === void 0 ? void 0 : booking.attributes) || null;
        if (!cancellationPolicyType || !bookingAttributes)
            return false;
        const bookingStart = bookingAttributes
            ? moment(bookingAttributes.start)
                .set('hour', checkInTime.hour())
                .set('minute', checkInTime.minute())
            : null;
        const cancellationPolicy = custom.cancellationTypes.find(item => {
            return cancellationPolicyType === item.key;
        });
        const cancellationPolicyDays = cancellationPolicy === null || cancellationPolicy === void 0 ? void 0 : cancellationPolicy.days;
        const today = moment();
        if (!bookingStart || cancellationPolicyDays === null) {
            return false;
        }
        const currentDifferenceDays = bookingStart.diff(today, 'days');
        if (currentDifferenceDays === 0 &&
            cancellationPolicyDays === 0 &&
            today.isAfter(bookingStart)) {
            return false;
        }
        if (currentDifferenceDays >= cancellationPolicyDays) {
            return true;
        }
    }
    return false;
};
export const txIsDelivered = (tx) => getTransitionsToState(STATE_DELIVERED).includes(txLastTransition(tx));
export const txHasBeenDeliveredButPayoutPending = (tx) => {
    const hasBeenDelivered = txHasBeenDelivered(tx);
    if (!hasBeenDelivered) {
        return false;
    }
    const relevantTransition = tx.attributes.transitions.find(transition => getTransitionsToState(STATE_ACCEPTED).includes(transition.transition));
    if (relevantTransition &&
        moment()
            .startOf('day')
            .isSameOrBefore(moment(relevantTransition.createdAt).add(8, 'days').startOf('day'))) {
        return true;
    }
    return false;
};
const firstReviewTransitions = [
    ...getTransitionsToState(STATE_REVIEWED_BY_CUSTOMER),
    ...getTransitionsToState(STATE_REVIEWED_BY_PROVIDER),
];
export const txIsInFirstReview = (tx) => firstReviewTransitions.includes(txLastTransition(tx));
export const txIsInFirstReviewBy = (tx, isCustomer) => isCustomer
    ? getTransitionsToState(STATE_REVIEWED_BY_CUSTOMER).includes(txLastTransition(tx))
    : getTransitionsToState(STATE_REVIEWED_BY_PROVIDER).includes(txLastTransition(tx));
export const txIsReviewed = (tx) => getTransitionsToState(STATE_REVIEWED).includes(txLastTransition(tx));
/**
 * Helper functions to figure out if transaction has passed a given state.
 * This is based on transitions history given by transaction object.
 */
const txTransitions = (tx) => ensureTransaction(tx).attributes.transitions || [];
export const hasPassedTransition = (transitionName, tx) => !!txTransitions(tx).find((t) => t.transition === transitionName);
export const hasPassedStateFn = (state) => (tx) => getTransitionsToState(state).filter(t => hasPassedTransition(t, tx))
    .length > 0;
export const txHasBeenAccepted = hasPassedStateFn(STATE_ACCEPTED);
export const txHasBeenDeclined = hasPassedStateFn(STATE_DECLINED);
export const txHasBeenDelivered = hasPassedStateFn(STATE_DELIVERED);
/**
 * Other transaction related utility functions
 */
export const transitionIsReviewed = (transition) => getTransitionsToState(STATE_REVIEWED).includes(transition);
export const transitionIsFirstReviewedBy = (transition, isCustomer) => isCustomer
    ? getTransitionsToState(STATE_REVIEWED_BY_CUSTOMER).includes(transition)
    : getTransitionsToState(STATE_REVIEWED_BY_PROVIDER).includes(transition);
export const getReview1Transition = (isCustomer) => isCustomer
    ? TRANSITION_REVIEW_1_BY_CUSTOMER
    : TRANSITION_REVIEW_1_BY_PROVIDER;
export const getReview2Transition = (isCustomer) => isCustomer
    ? TRANSITION_REVIEW_2_BY_CUSTOMER
    : TRANSITION_REVIEW_2_BY_PROVIDER;
// Check if a transition is the kind that should be rendered
// when showing transition history (e.g. ActivityFeed)
// The first transition and most of the expiration transitions made by system are not relevant
export const isRelevantPastTransition = (transition) => {
    return [
        TRANSITION_ACCEPT,
        TRANSITION_ACCEPT_FULL_AMOUNT_WITH_GIFT_CODE,
        TRANSITION_ACCEPT_BY_OPERATOR,
        TRANSITION_ACCEPT_BY_OPERATOR_FULL_AMOUNT_WITH_GIFT_CODE,
        TRANSITION_CANCEL_BY_OPERATOR,
        TRANSITION_CANCEL_BY_OPERATOR_FULL_AMOUNT_WITH_GIFT_CODE,
        TRANSITION_CANCEL_FOR_MANUAL_REFUND_BY_OPERATOR,
        TRANSITION_CANCEL_BY_CUSTOMER,
        TRANSITION_CANCEL_BY_CUSTOMER_OUTSIDE_CANCELLATION_POLICY,
        TRANSITION_CANCEL_BY_CUSTOMER_OUTSIDE_CANCELLATION_POLICY_PARTIAL_REFUND,
        TRANSITION_CANCEL_BY_CUSTOMER_WITHIN_CANCELLATION_POLICY,
        TRANSITION_COMPLETE,
        TRANSITION_COMPLETE_FULL_AMOUNT_WITH_GIFT_CODE,
        TRANSITION_CONFIRM_PAYMENT,
        TRANSITION_CONFIRM_PAYMENT_FOR_INSTANT_BOOKING,
        TRANSITION_PAY_FULL_AMOUNT_WITH_GIFT_CODE,
        TRANSITION_PAY_FULL_AMOUNT_WITH_GIFT_CODE_FOR_INSTANT_BOOKING,
        TRANSITION_DECLINE,
        TRANSITION_DECLINE_FULL_AMOUNT_WITH_GIFT_CODE,
        TRANSITION_WITHDRAW_BOOKING_REQUEST_BY_CUSTOMER,
        TRANSITION_WITHDRAW_BOOKING_REQUEST_BY_CUSTOMER_FULL_AMOUNT_WITH_GIFT_CODE,
        TRANSITION_DECLINE_BY_OPERATOR,
        TRANSITION_DECLINE_BY_OPERATOR_FULL_AMOUNT_WITH_GIFT_CODE,
        TRANSITION_EXPIRE,
        TRANSITION_EXPIRE_FULL_AMOUNT_WITH_GIFT_CODE,
        TRANSITION_REVIEW_1_BY_CUSTOMER,
        TRANSITION_REVIEW_1_BY_PROVIDER,
        TRANSITION_REVIEW_2_BY_CUSTOMER,
        TRANSITION_REVIEW_2_BY_PROVIDER,
        TRANSITION_REACTIVATE_CUSTOMER_REVIEW_PERIOD_BY_OPERATOR,
        TRANSITION_REACTIVATE_PROVIDER_REVIEW_PERIOD_BY_OPERATOR,
        TRANSITION_REACTIVATE_CUSTOMER_AND_PROVIDER_REVIEW_PERIOD_BY_OPERATOR,
        TRANSITION_CLOSE_CUSTOMER_REVIEW_PERIOD_BY_OPERATOR,
        TRANSITION_CLOSE_PROVIDER_REVIEW_PERIOD_BY_OPERATOR,
        TRANSITION_CLOSE_CUSTOMER_AND_PROVIDER_REVIEW_PERIOD_BY_OPERATOR,
        TRANSITION_UPDATE_BOOKING_BY_OPERATOR,
        TRANSITION_PAY_OUT,
    ].includes(transition);
};
export const isCustomerReview = (transition) => {
    return [
        TRANSITION_REVIEW_1_BY_CUSTOMER,
        TRANSITION_REVIEW_2_BY_CUSTOMER,
    ].includes(transition);
};
export const isProviderReview = (transition) => {
    return [
        TRANSITION_REVIEW_1_BY_PROVIDER,
        TRANSITION_REVIEW_2_BY_PROVIDER,
    ].includes(transition);
};
export const isReversed = (tx) => {
    return txIsPaymentExpired(tx) || txIsDeclined(tx) || txIsCancelled(tx);
};
export const getUserTxRole = (currentUserId, transaction) => {
    const tx = ensureTransaction(transaction);
    const { customer } = tx;
    if (currentUserId && currentUserId.uuid && tx.id && customer.id) {
        // user can be either customer or provider
        return currentUserId.uuid === customer.id.uuid
            ? TX_TRANSITION_ACTOR_CUSTOMER
            : TX_TRANSITION_ACTOR_PROVIDER;
    }
    throw new Error(`Parameters for "userIsCustomer" function were wrong.
      currentUserId: ${currentUserId}, transaction: ${transaction}`);
};
export const txRoleIsProvider = (userRole) => userRole === TX_TRANSITION_ACTOR_PROVIDER;
export const txRoleIsCustomer = (userRole) => userRole === TX_TRANSITION_ACTOR_CUSTOMER;
// Check if the given transition is privileged.
//
// Privileged transitions need to be handled from a secure context,
// i.e. the backend. This helper is used to check if the transition
// should go through the local API endpoints, or if using JS SDK is
// enough.
export const isPrivileged = (transition) => {
    return [
        TRANSITION_REQUEST_PAYMENT,
        TRANSITION_CONFIRM_PAYMENT,
        TRANSITION_CONFIRM_PAYMENT_FOR_INSTANT_BOOKING,
        TRANSITION_PAY_FULL_AMOUNT_WITH_GIFT_CODE,
        TRANSITION_PAY_FULL_AMOUNT_WITH_GIFT_CODE_FOR_INSTANT_BOOKING,
        TRANSITION_DECLINE,
        TRANSITION_DECLINE_FULL_AMOUNT_WITH_GIFT_CODE,
        TRANSITION_WITHDRAW_BOOKING_REQUEST_BY_CUSTOMER,
        TRANSITION_WITHDRAW_BOOKING_REQUEST_BY_CUSTOMER_FULL_AMOUNT_WITH_GIFT_CODE,
        TRANSITION_ACCEPT,
        TRANSITION_ACCEPT_FULL_AMOUNT_WITH_GIFT_CODE,
        TRANSITION_CANCEL_BY_CUSTOMER_OUTSIDE_CANCELLATION_POLICY,
        TRANSITION_CANCEL_BY_CUSTOMER_OUTSIDE_CANCELLATION_POLICY_PARTIAL_REFUND,
        TRANSITION_CANCEL_BY_CUSTOMER_WITHIN_CANCELLATION_POLICY,
    ].includes(transition);
};
export const relevantLastTransitionsForProviderNotifications = [
    ...getTransitionsToState(STATE_PREAUTHORIZED),
    ...getTransitionsToState(STATE_DELIVERED),
    ...getTransitionsToState(STATE_REVIEWED_BY_CUSTOMER),
];
export const nonRelevantLastTransitionsForUpcomingBookings = [
    ...getTransitionsToState(STATE_PREAUTHORIZED),
    ...getTransitionsToState(STATE_PAYMENT_EXPIRED),
    ...getTransitionsToState(STATE_PENDING_PAYMENT),
];
export var TransactionStatus;
(function (TransactionStatus) {
    TransactionStatus["REQUEST"] = "REQUEST";
    TransactionStatus["ACCEPTED"] = "ACCEPTED";
    TransactionStatus["WITHDRAWN"] = "WITHDRAWN";
    TransactionStatus["EXPIRED"] = "EXPIRED";
    TransactionStatus["DECLINED"] = "DECLINED";
    TransactionStatus["CANCELLED"] = "CANCELLED";
    TransactionStatus["DELIVERED"] = "DELIVERED";
    TransactionStatus["REVIEWED"] = "REVIEWED";
    TransactionStatus["UNKNOWN"] = "UNKNOWN";
})(TransactionStatus || (TransactionStatus = {}));
export const getTransactionStatus = (transaction) => {
    if (txIsReviewed(transaction)) {
        return TransactionStatus.REVIEWED;
    }
    if (txIsDelivered(transaction) || txIsInFirstReview(transaction)) {
        return TransactionStatus.DELIVERED;
    }
    if (txIsAccepted(transaction)) {
        return TransactionStatus.ACCEPTED;
    }
    if (txIsRequested(transaction)) {
        return TransactionStatus.REQUEST;
    }
    if (txIsCancelled(transaction) ||
        txIsPaymentExpired(transaction) ||
        txIsPaidOut(transaction)) {
        return TransactionStatus.CANCELLED;
    }
    if (txIsExpired(transaction)) {
        return TransactionStatus.EXPIRED;
    }
    if (txIsDeclined(transaction)) {
        return TransactionStatus.DECLINED;
    }
    if (txIsWithdrawn(transaction)) {
        return TransactionStatus.WITHDRAWN;
    }
    return TransactionStatus.UNKNOWN;
};
export const payoutDueOnDate = (bookingEndDate) => {
    const minimumDaysBetweenPayInAndPayOut = 7;
    const payinDay = moment();
    const daysBetween = moment(bookingEndDate).diff(payinDay, 'days');
    if (daysBetween < minimumDaysBetweenPayInAndPayOut) {
        return moment(bookingEndDate)
            .add(minimumDaysBetweenPayInAndPayOut - daysBetween, 'days')
            .toDate();
    }
    return bookingEndDate;
};
