import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import CheckboxInputSection from '../../CheckboxInputSection/CheckboxInputSection';
import FilterGroup from '../../FilterGroup/FilterGroup';
import { IconVariant } from '../../Icon/Icon';
const AmbianceFilterGroup = ({ currentFeaturesSearchQuery, intl, onSetFeatures, }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    return (_jsx(FilterGroup, Object.assign({ iconVariant: IconVariant.Mountains, label: intl.formatMessage({
            id: 'SearchBarSheet.Features.Ambiance.title',
        }) }, { children: _jsx(CheckboxInputSection, { checkboxes: [
                {
                    id: 'ambiance-shadowy',
                    checked: !!((_a = currentFeaturesSearchQuery.ambiance) === null || _a === void 0 ? void 0 : _a.shadowy),
                    label: intl.formatMessage({
                        id: 'MarketplaceConfig.shadowy',
                    }),
                    onChange: (value) => onSetFeatures({
                        ambiance: { shadowy: value },
                    }),
                },
                {
                    id: 'ambiance-child-forest',
                    checked: !!((_b = currentFeaturesSearchQuery.ambiance) === null || _b === void 0 ? void 0 : _b.forest),
                    label: intl.formatMessage({
                        id: 'MarketplaceConfig.forest',
                    }),
                    onChange: (value) => onSetFeatures({
                        ambiance: { forest: value },
                    }),
                },
                {
                    id: 'ambiance-lawn',
                    checked: !!((_c = currentFeaturesSearchQuery.ambiance) === null || _c === void 0 ? void 0 : _c.lawn),
                    label: intl.formatMessage({
                        id: 'MarketplaceConfig.lawn',
                    }),
                    onChange: (value) => onSetFeatures({
                        ambiance: { lawn: value },
                    }),
                },
                {
                    id: 'ambiance-river-or-creek',
                    checked: !!((_d = currentFeaturesSearchQuery.ambiance) === null || _d === void 0 ? void 0 : _d.riverOrCreek),
                    label: intl.formatMessage({
                        id: 'SearchBarSheet.Features.Ambiance.riverOrCreek',
                    }),
                    onChange: (value) => onSetFeatures({
                        ambiance: { riverOrCreek: value },
                    }),
                },
                {
                    id: 'ambiance-lake',
                    checked: !!((_e = currentFeaturesSearchQuery.ambiance) === null || _e === void 0 ? void 0 : _e.lake),
                    label: intl.formatMessage({
                        id: 'MarketplaceConfig.lake',
                    }),
                    onChange: (value) => onSetFeatures({
                        ambiance: { lake: value },
                    }),
                },
                {
                    id: 'ambiance-mountains',
                    checked: !!((_f = currentFeaturesSearchQuery.ambiance) === null || _f === void 0 ? void 0 : _f.mountains),
                    label: intl.formatMessage({
                        id: 'MarketplaceConfig.mountains',
                    }),
                    onChange: (value) => onSetFeatures({
                        ambiance: { mountains: value },
                    }),
                },
                {
                    id: 'ambiance-farm',
                    checked: !!((_g = currentFeaturesSearchQuery.ambiance) === null || _g === void 0 ? void 0 : _g.farm),
                    label: intl.formatMessage({
                        id: 'MarketplaceConfig.farm',
                    }),
                    onChange: (value) => onSetFeatures({
                        ambiance: { farm: value },
                    }),
                },
                {
                    id: 'ambiance-restaurant',
                    checked: !!((_h = currentFeaturesSearchQuery.ambiance) === null || _h === void 0 ? void 0 : _h.restaurant),
                    label: intl.formatMessage({
                        id: 'SearchBarSheet.Features.Ambiance.restaurant',
                    }),
                    onChange: (value) => onSetFeatures({
                        ambiance: { restaurant: value },
                    }),
                },
                {
                    id: 'ambiance-road',
                    checked: !!((_j = currentFeaturesSearchQuery.ambiance) === null || _j === void 0 ? void 0 : _j.road),
                    label: intl.formatMessage({
                        id: 'MarketplaceConfig.street',
                    }),
                    onChange: (value) => onSetFeatures({
                        ambiance: { road: value },
                    }),
                },
            ] }) })));
};
export default AmbianceFilterGroup;
