import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useMemo, useRef } from 'react';
import styled, { css } from 'styled-components/macro';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import debounce from 'lodash/debounce';
import Page from '@nomady/shared/routeConfiguration/Page';
import { createResourceLocatorString } from '@nomady/shared/utils/routes';
import routeConfiguration from '@nomady/shared/routeConfiguration/routeConfiguration';
import Icon, { IconSizeVariant, IconVariant } from '../Icon/Icon';
import { queryLocationSearchResults, openSearchBarSheet, setIsOpen, isGeolocationUsedSelector, setLocationSearchInProgress, searchBarSheetOpenSelector, resetCurrentSearchBarSheet, loadResults, } from '../../slices/listingSearchSlice';
import getQueryStringFromState from '../../util/search-query/getQueryStringFromState';
import SearchBarSheetVariant from '../SearchBarSheet/SearchBarSheetVariant';
import MobileView from '../MobileView/MobileView';
import TabletView from '../TabletView/TabletView';
import Text, { TextVariant } from '../Text/Text';
import { ColorVariant } from '../../theme';
import SearchBarSheet from '../SearchBarSheet/SearchBarSheet';
import { safeSpaceTopSelector, setDisableScroll } from '../../slices/UISlice';
import BackDropDimmed from '../BackDropDimmed/BackDropDimmed';
import getFeaturesFilterCount from '../../util/search-query/features/getFeaturesFilterCount';
import MobileSearchAndNavigationBar, { MobileSearchAndNavigationBarVariant, } from './MobileSearchAndNavigationBar';
import { getFormattedDate, getGuestString, getHasVehicleAndGuestSelections, } from './utils';
const Search = styled.div `
  width: 100%;
`;
const SearchBarSheetButtonWrapper = styled.button `
  background: ${props => props.backgroundColorVariant
    ? props.theme.color[props.backgroundColorVariant]
    : props.theme.color.basicPearlWhiteTypeWhite};
  width: ${props => props.width};
  max-width: ${props => props.width};
  border-radius: 16px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  white-space: nowrap;
  padding: 8px;

  cursor: pointer;

  &:hover {
    box-shadow: 0px 0px 5px -1px ${props => props.theme.color.functionStoneGreyInactive},
      inset 0 0 0 1px ${props => props.theme.color.functionStoneGreyInactive};
  }

  ${props => props.isActive &&
    css `
      box-shadow: 0px 0px 5px -1px ${props.theme.color.functionElectricVioletActive},
        inset 0 0 0 1px ${props.theme.color.functionElectricVioletActive};
    `}
`;
const SearchBarButtonContentWrapper = styled.div `
  display: flex;
  min-width: 0;
  flex-direction: column;
  align-items: start;
  flex: 1;
`;
const SearchBarButtonClearButton = styled.div `
  border-radius: 16px;
  padding: 4px;
  background-color: ${props => props.theme.color.functionStoneGreyInactive};
`;
const SearchBarSheetButtonsWrapper = styled.div `
  display: flex;
  gap: 4px;
  justify-content: center;
  position: relative;
  ${props => props.theme.priority.ultra}
`;
const LocationInput = styled.input `
  border: 0;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 4px;
  padding-right: 0;
  margin-top: -2px;

  ${props => props.theme.typeset.L4R};

  color: ${props => props.theme.color.basicCaveBlack};

  ::placeholder {
    color: ${props => props.theme.color.functionStoneGreyInactive};
  }
`;
const Label = styled(Text) `
  padding-left: 4px;
  margin-top: -2px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  flex: 0 0 auto;
`;
const SpinnerWrapper = styled.div `
  margin-top: -2px;
`;
const SearchWrapper = styled.div `
  ${props => !props.searchBarSheetIsOpen && `display:none;`}
  background-color: ${props => props.theme.color.basicPearlWhiteTypeWhite};

  position: fixed;
  ${props => props.theme.priority.ultra};
  top: ${props => 8 + props.safeSpaceTop}px;
  left: 0;
  right: 0;
  bottom: 0;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  overflow: auto;

  @media ${props => props.theme.device.tablet} {
    position: relative;
    top: 4px;
    width: 100%;
    max-width: 616px;
    margin: 0 auto;
    height: auto;
    padding: 0;
    overflow: initial;
  }
`;
const ListingSearchBar = ({ integratedInTopBar, dataTestId, }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    const intl = useIntl();
    const history = useHistory();
    const dispatch = useDispatch();
    const locationInputRef = useRef(null);
    const onSetActiveSearchBarSheet = (searchBarSheetVariant) => dispatch(openSearchBarSheet(searchBarSheetVariant));
    const setLoading = (loading) => dispatch(setLocationSearchInProgress(loading));
    const onClose = () => {
        dispatch(setIsOpen(false));
        dispatch(setDisableScroll(false));
    };
    const { isOpen, activeSearchBarSheet, currentSearchQuery, searchResultsCount, } = useSelector((state) => state.listingSearch);
    const { searchInProgress } = useSelector((state) => state.SearchPage);
    const queryString = useSelector(getQueryStringFromState);
    useEffect(() => {
        if (locationInputRef.current &&
            activeSearchBarSheet === SearchBarSheetVariant.Location &&
            isOpen) {
            locationInputRef.current.focus();
        }
    }, []);
    const showResults = () => {
        onClose();
        const target = createResourceLocatorString(Page.Search, routeConfiguration, {}, queryString);
        return history.push(target);
    };
    const geolocationUsed = useSelector(isGeolocationUsedSelector);
    const noLocation = !((_b = (_a = currentSearchQuery.location) === null || _a === void 0 ? void 0 : _a.location) === null || _b === void 0 ? void 0 : _b.name) &&
        !((_c = currentSearchQuery.location) === null || _c === void 0 ? void 0 : _c.isFlexible) &&
        !geolocationUsed;
    const flexibleAddress = !((_e = (_d = currentSearchQuery.location) === null || _d === void 0 ? void 0 : _d.location) === null || _e === void 0 ? void 0 : _e.name) &&
        ((_f = currentSearchQuery.location) === null || _f === void 0 ? void 0 : _f.isFlexible);
    let buttonLabel = (_h = (_g = currentSearchQuery.location) === null || _g === void 0 ? void 0 : _g.location) === null || _h === void 0 ? void 0 : _h.name;
    buttonLabel = flexibleAddress
        ? intl.formatMessage({
            id: 'SearchBarSheet.Location.flexible',
        })
        : buttonLabel;
    buttonLabel = geolocationUsed
        ? intl.formatMessage({
            id: 'SearchBarSheet.Location.geolocation',
        })
        : buttonLabel;
    const onChangeLocation = (search) => {
        dispatch(queryLocationSearchResults(search));
    };
    const debouncedOnChangeLocation = useMemo(() => debounce(onChangeLocation, 750), []);
    useEffect(() => {
        return () => {
            debouncedOnChangeLocation.cancel();
        };
    }, []);
    const handleFocus = () => {
        var _a;
        onSetActiveSearchBarSheet(SearchBarSheetVariant.Location);
        (_a = locationInputRef === null || locationInputRef === void 0 ? void 0 : locationInputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
    };
    const hasVehicleAndGuestSelections = getHasVehicleAndGuestSelections(currentSearchQuery);
    const hasDateRangeSelections = ((_j = currentSearchQuery.dates) === null || _j === void 0 ? void 0 : _j.startDate) && currentSearchQuery.dates.endDate;
    const searchBarSheetIsOpen = useSelector(searchBarSheetOpenSelector);
    const safeSpaceTop = useSelector(safeSpaceTopSelector);
    const onCloseSearchBarSheet = () => {
        dispatch(setIsOpen(false));
        dispatch(setDisableScroll(false));
    };
    const resetCurrentSheet = () => {
        dispatch(resetCurrentSearchBarSheet());
        dispatch(loadResults());
    };
    const numberOfFeaturesSelected = useSelector(getFeaturesFilterCount);
    return (_jsxs(Search, { children: [_jsx(MobileView, { children: _jsx(MobileSearchAndNavigationBar, { variant: integratedInTopBar
                        ? MobileSearchAndNavigationBarVariant.Navigation
                        : MobileSearchAndNavigationBarVariant.Standalone }) }), _jsx(TabletView, { children: _jsxs(SearchBarSheetButtonsWrapper, { children: [_jsxs(SearchBarSheetButtonWrapper, Object.assign({ width: "152px", isActive: activeSearchBarSheet === SearchBarSheetVariant.Location && isOpen, onClick: handleFocus }, { children: [_jsxs(SearchBarButtonContentWrapper, { children: [_jsx(Icon, { variant: IconVariant.LocationPin, sizeVariant: IconSizeVariant.Small }), noLocation ? (_jsx(LocationInput, { placeholder: intl.formatMessage({
                                                id: 'SearchBarSheet.Location.searchPlaceholder',
                                            }), onFocus: handleFocus, onChange: event => {
                                                setLoading(true);
                                                // debouncedOnChangeLocation(event.target.value);
                                                onChangeLocation(event.target.value);
                                            }, ref: locationInputRef, "data-testid": dataTestId })) : (_jsx(Label, Object.assign({ variant: TextVariant.L4R }, { children: buttonLabel })))] }), activeSearchBarSheet === SearchBarSheetVariant.Location &&
                                    isOpen &&
                                    (buttonLabel !== undefined ||
                                        ((_k = locationInputRef.current) === null || _k === void 0 ? void 0 : _k.value) !== '') && (_jsx(SearchBarButtonClearButton, Object.assign({ onClick: event => {
                                        event.stopPropagation();
                                        event.preventDefault();
                                        if (buttonLabel !== undefined) {
                                            resetCurrentSheet();
                                            setTimeout(handleFocus, 1);
                                        }
                                        else {
                                            locationInputRef.current.value = '';
                                            onChangeLocation('');
                                            handleFocus();
                                        }
                                    } }, { children: _jsx(Icon, { variant: IconVariant.X_Close, sizeVariant: IconSizeVariant.ExtraExtraExtraSmall, colorVariant: ColorVariant.BasicPearlWhiteTypeWhite }) })))] })), _jsxs(SearchBarSheetButtonWrapper, Object.assign({ width: "152px", isActive: activeSearchBarSheet === SearchBarSheetVariant.Dates && isOpen, onClick: () => {
                                if (isOpen &&
                                    activeSearchBarSheet === SearchBarSheetVariant.Dates) {
                                    onClose();
                                }
                                else {
                                    onSetActiveSearchBarSheet(SearchBarSheetVariant.Dates);
                                }
                            } }, { children: [_jsxs(SearchBarButtonContentWrapper, { children: [_jsx(Icon, { variant: IconVariant.Calendar_NEW, sizeVariant: IconSizeVariant.Small }), _jsx(Label, Object.assign({ variant: TextVariant.L4R, colorVariant: hasDateRangeSelections
                                                ? ColorVariant.BasicCaveBlack
                                                : ColorVariant.FunctionStoneGreyInactive }, { children: hasDateRangeSelections && currentSearchQuery.dates.startDate
                                                ? `${getFormattedDate(currentSearchQuery.dates.startDate, currentSearchQuery.dates.endDate, true)}`
                                                : intl.formatMessage({
                                                    id: 'ListingSearchBar.datesDefaultText',
                                                }) }))] }), activeSearchBarSheet === SearchBarSheetVariant.Dates &&
                                    isOpen &&
                                    hasDateRangeSelections && (_jsx(SearchBarButtonClearButton, Object.assign({ onClick: event => {
                                        event.stopPropagation();
                                        event.preventDefault();
                                        resetCurrentSheet();
                                    } }, { children: _jsx(Icon, { variant: IconVariant.X_Close, sizeVariant: IconSizeVariant.ExtraExtraExtraSmall, colorVariant: ColorVariant.BasicPearlWhiteTypeWhite }) })))] })), _jsxs(SearchBarSheetButtonWrapper, Object.assign({ width: "152px", isActive: activeSearchBarSheet === SearchBarSheetVariant.Guests && isOpen, onClick: () => {
                                if (isOpen &&
                                    activeSearchBarSheet === SearchBarSheetVariant.Guests) {
                                    onClose();
                                }
                                else {
                                    onSetActiveSearchBarSheet(SearchBarSheetVariant.Guests);
                                }
                            } }, { children: [_jsxs(SearchBarButtonContentWrapper, { children: [_jsx(Icon, { variant: IconVariant.Avatar, sizeVariant: IconSizeVariant.Small }), _jsx(Label, Object.assign({ variant: TextVariant.L4R, colorVariant: hasVehicleAndGuestSelections
                                                ? ColorVariant.BasicCaveBlack
                                                : ColorVariant.FunctionStoneGreyInactive }, { children: hasVehicleAndGuestSelections
                                                ? getGuestString(currentSearchQuery, intl)
                                                : intl.formatMessage({
                                                    id: 'ListingSearchBar.guestsDefaultText',
                                                }) }))] }), activeSearchBarSheet === SearchBarSheetVariant.Guests &&
                                    isOpen &&
                                    hasVehicleAndGuestSelections && (_jsx(SearchBarButtonClearButton, Object.assign({ onClick: event => {
                                        event.stopPropagation();
                                        event.preventDefault();
                                        resetCurrentSheet();
                                    } }, { children: _jsx(Icon, { variant: IconVariant.X_Close, sizeVariant: IconSizeVariant.ExtraExtraExtraSmall, colorVariant: ColorVariant.BasicPearlWhiteTypeWhite }) })))] })), _jsxs(SearchBarSheetButtonWrapper, Object.assign({ width: "72px", isActive: activeSearchBarSheet === SearchBarSheetVariant.Features && isOpen, onClick: () => {
                                if (isOpen &&
                                    activeSearchBarSheet === SearchBarSheetVariant.Features) {
                                    onClose();
                                }
                                else {
                                    onSetActiveSearchBarSheet(SearchBarSheetVariant.Features);
                                }
                            } }, { children: [_jsxs(SearchBarButtonContentWrapper, { children: [_jsx(Icon, { variant: IconVariant.Filter, sizeVariant: IconSizeVariant.Small }), _jsx(Label, Object.assign({ variant: TextVariant.L4R, colorVariant: numberOfFeaturesSelected > 0
                                                ? ColorVariant.BasicCaveBlack
                                                : ColorVariant.FunctionStoneGreyInactive }, { children: `${numberOfFeaturesSelected > 0
                                                ? `${numberOfFeaturesSelected} `
                                                : ''}
                  ${intl.formatMessage({
                                                id: 'SearchFilters.filtersButtonLabel',
                                            })}` }))] }), activeSearchBarSheet === SearchBarSheetVariant.Features &&
                                    isOpen &&
                                    numberOfFeaturesSelected > 0 && (_jsx(SearchBarButtonClearButton, Object.assign({ onClick: event => {
                                        event.stopPropagation();
                                        event.preventDefault();
                                        resetCurrentSheet();
                                    } }, { children: _jsx(Icon, { variant: IconVariant.X_Close, sizeVariant: IconSizeVariant.ExtraExtraExtraSmall, colorVariant: ColorVariant.BasicPearlWhiteTypeWhite }) })))] })), _jsx(SearchBarSheetButtonWrapper, Object.assign({ width: "72px", backgroundColorVariant: ColorVariant.FunctionSuccess3, onClick: () => {
                                if (isOpen) {
                                    showResults();
                                }
                                else {
                                    onSetActiveSearchBarSheet(SearchBarSheetVariant.Location);
                                }
                            } }, { children: _jsxs(SearchBarButtonContentWrapper, { children: [_jsx(Icon, { variant: IconVariant.Search, sizeVariant: IconSizeVariant.Small, colorVariant: ColorVariant.BasicPearlWhiteTypeWhite }), searchInProgress ? (_jsx(SpinnerWrapper, { children: _jsx(Icon, { variant: IconVariant.Spinner, sizeVariant: IconSizeVariant.Tiny }) })) : (_jsx(Label, Object.assign({ variant: TextVariant.L4R, colorVariant: ColorVariant.BasicPearlWhiteTypeWhite }, { children: searchResultsCount })))] }) }))] }) }), _jsx(SearchWrapper, Object.assign({ searchBarSheetIsOpen: searchBarSheetIsOpen, safeSpaceTop: safeSpaceTop }, { children: _jsx(SearchBarSheet, { onClose: onCloseSearchBarSheet }) })), _jsx(BackDropDimmed, { onClick: onCloseSearchBarSheet, shown: searchBarSheetIsOpen })] }));
};
export default ListingSearchBar;
