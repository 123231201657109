var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { useSelector } from 'react-redux';
import { Controller, } from 'react-hook-form';
import { GoogleApiWrapper } from 'google-maps-react';
import Input from '../Input/Input';
import { IconVariant } from '../Icon/Icon';
import GeocoderGoogleMaps from './GeocoderGoogleMaps';
import ResultsList from './ResultsList';
import config from '../../config';
import { localeSelector } from '../../slices/UISlice';
const Wrapper = styled.div `
  position: relative;
  width: 100%;
`;
var Direction;
(function (Direction) {
    Direction["Up"] = "Up";
    Direction["Down"] = "Down";
})(Direction || (Direction = {}));
export const InputGeolocation = (_a) => {
    var { control, rules } = _a, props = __rest(_a, ["control", "rules"]);
    const [geocoder, setGeocoder] = useState(null);
    const [predictions, setPredictions] = useState();
    const [loading, setLoading] = useState(false);
    const [resultsListOpen, setResultsListOpen] = useState(false);
    const [focussedIndex, setFocussedIndex] = useState(-1);
    useEffect(() => {
        setGeocoder(new GeocoderGoogleMaps());
    }, []);
    const changeSelectedIndex = (direction) => {
        if (direction === Direction.Up) {
            const shouldSelectLastIndex = focussedIndex < 1;
            if (shouldSelectLastIndex) {
                setFocussedIndex(((predictions === null || predictions === void 0 ? void 0 : predictions.length) || 0) - 1);
            }
            else {
                setFocussedIndex(focussedIndex - 1);
            }
        }
        else if (direction === Direction.Down) {
            const shouldSelectFirstIndex = ((predictions === null || predictions === void 0 ? void 0 : predictions.length) || 0) < focussedIndex + 2;
            if (shouldSelectFirstIndex) {
                setFocussedIndex(0);
            }
            else {
                setFocussedIndex(focussedIndex + 1);
            }
        }
    };
    const language = useSelector(localeSelector);
    const predict = (search) => __awaiter(void 0, void 0, void 0, function* () {
        setLoading(true);
        try {
            const results = yield (geocoder === null || geocoder === void 0 ? void 0 : geocoder.getPlacePredictions(search, { language }));
            setPredictions(results === null || results === void 0 ? void 0 : results.predictions);
        }
        finally {
            setLoading(false);
        }
    });
    const onChange = (e) => {
        const { value } = e.target;
        if (!value) {
            return;
        }
        if (3 <= value.length) {
            setFocussedIndex(-1);
            predict(value);
        }
    };
    const onKeyDown = (event, field) => {
        const handledKeyEvents = ['ArrowUp', 'ArrowDown', 'Enter', 'Escape'];
        if (handledKeyEvents.includes(event.key)) {
            event.preventDefault();
        }
        if (event.key === 'ArrowUp') {
            changeSelectedIndex(Direction.Up);
        }
        else if (event.key === 'ArrowDown') {
            changeSelectedIndex(Direction.Down);
        }
        else if (event.key === 'Enter') {
            event.stopPropagation();
            if (predictions === null || predictions === void 0 ? void 0 : predictions[focussedIndex]) {
                selectPrediction(predictions[focussedIndex], field);
            }
        }
        else if (event.key === 'Escape') {
            setResultsListOpen(false);
        }
    };
    const selectPrediction = (prediction, field) => __awaiter(void 0, void 0, void 0, function* () {
        setLoading(true);
        try {
            const place = yield (geocoder === null || geocoder === void 0 ? void 0 : geocoder.getPlaceDetails(prediction));
            setLoading(false);
            if (place) {
                const value = {
                    address: prediction.description,
                    placeId: place.placeId,
                    address_components: place.address_components,
                    location: place.location,
                };
                field.onChange(value);
                setResultsListOpen(false);
            }
        }
        catch (e) {
            setLoading(false);
        }
        return Promise.resolve();
    });
    const showResultsList = resultsListOpen && predictions && 0 < predictions.length;
    return (_jsx(Controller, { render: ({ field, fieldState }) => {
            var _a;
            return (_jsx(Input, Object.assign({}, props, field, { type: "search", autoComplete: "off", loading: loading, icon: IconVariant.Magnifier, error: fieldState.error, onFocus: () => setResultsListOpen(true), onKeyDown: event => onKeyDown(event, field), value: (_a = field.value) === null || _a === void 0 ? void 0 : _a.address, onChange: event => {
                    onChange(event);
                    field.onChange(event.target.value);
                } }, { children: showResultsList && (_jsx(Wrapper, { children: _jsx(ResultsList, { predictions: predictions, focussedIndex: focussedIndex, onClickResult: (id) => {
                            const prediction = predictions.find(element => element.place_id === id);
                            if (prediction) {
                                selectPrediction(prediction, field);
                            }
                        } }) })) })));
        }, name: props.name, control: control, rules: rules }));
};
export default GoogleApiWrapper({
    apiKey: config.maps.googleMapsAPIKey,
    libraries: ['places'],
})(InputGeolocation);
