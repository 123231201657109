import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styled, { css } from 'styled-components/macro';
import Text, { TextVariant } from '../Text/Text';
import { ColorVariant } from '../../theme';
export var ContentVariant;
(function (ContentVariant) {
    ContentVariant["Inline"] = "inline";
    ContentVariant["Block"] = "block";
})(ContentVariant || (ContentVariant = {}));
const Wrapper = styled.div `
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;
const IconContainer = styled.div `
  display: flex;
  height: 100%;
  margin-block: auto;
  margin-right: 12px;
`;
const TextContainer = styled.div `
  display: flex;
  height: 41px;
  margin-block: auto;

  ${props => props.variant === ContentVariant.Inline
    ? css `
          flex-direction: row;
          gap: 12px;
        `
    : css `
          flex-direction: column;
          justify-content: center;
          margin-top: -2px;
          height: 68px;
        `}
`;
const Subtitle = styled(Text) `
  text-align: left;
  display: inline-block;
  max-width: 230px;

  @media ${props => props.theme.device.tablet} {
    max-width: 400px;
  }

  ${props => props.layoutVariant === ContentVariant.Inline &&
    css `
      height: fit-content;
      margin-block: auto;
    `}
`;
const Title = styled(Text) ``;
const TitleWrapper = styled.div `
  text-align: left;
  display: inline-block;
  margin-bottom: -5px;
  max-width: 230px;

  @media ${props => props.theme.device.tablet} {
    max-width: 400px;
  }

  ${props => props.layoutVariant === ContentVariant.Inline &&
    css `
      height: fit-content;
      margin-block: auto;
    `}
`;
const InfoLabel = ({ title, subtitle, children, dataTestId, variant = ContentVariant.Inline, }) => {
    return (_jsxs(Wrapper, { children: [!!children && _jsx(IconContainer, { children: children }), _jsxs(TextContainer, Object.assign({ variant: variant }, { children: [_jsx(TitleWrapper, Object.assign({ layoutVariant: variant }, { children: _jsx(Title, Object.assign({ variant: variant === ContentVariant.Block
                                ? TextVariant.B50
                                : TextVariant.H51, colorVariant: ColorVariant.Cave, "data-testid": !subtitle && dataTestId, truncateWithEllipsis: true }, { children: title })) })), subtitle && (_jsx(Subtitle, Object.assign({ layoutVariant: variant, variant: variant === ContentVariant.Block
                            ? TextVariant.H51
                            : TextVariant.B50, colorVariant: variant === ContentVariant.Block
                            ? ColorVariant.Cave
                            : ColorVariant.Stone, truncateWithEllipsis: true, "data-testid": subtitle && dataTestId }, { children: subtitle })))] }))] }));
};
export default InfoLabel;
