import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components/macro';
import Icon, { IconVariant } from '../Icon/Icon';
import Text, { TextVariant } from '../Text/Text';
import { ColorVariant } from '../../theme';
import { getLanguageNativeFromCode } from '../../translations/languageNativeCodes';
const ContentWrapper = styled.div `
  display: flex;
  margin-bottom: 30px;

  @media ${props => props.theme.device.desktopL} {
    margin-bottom: 50px;
  }
`;
const LanguagesWrapper = styled.div `
  display: block;
  margin-left: 10px;
`;
const UsersLanguageSkills = ({ languages }) => {
    const intl = useIntl();
    return (_jsxs(ContentWrapper, { children: [_jsx(Icon, { variant: IconVariant.Speaking }), _jsx(Text, Object.assign({ variant: TextVariant.H10, colorVariant: ColorVariant.Stone }, { children: intl.formatMessage({
                    id: 'UsersLanguageSkills.speaking',
                }) })), _jsx(LanguagesWrapper, { children: _jsx(Text, Object.assign({ variant: TextVariant.H10, colorVariant: ColorVariant.Cave }, { children: languages.map(value => getLanguageNativeFromCode(value)).join(', ') })) })] }));
};
export default UsersLanguageSkills;
