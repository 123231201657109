var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { ALLOWED_CHARACTERS } from '@nomady/shared/customer-commission-discount-code/sanitizeCode';
import Button, { ButtonSizeVariant, } from '../../../styled-components/Button/Button';
import FormErrorMessage from '../../../styled-components/FormErrorMessage/FormErrorMessage';
import Input from '../../../styled-components/Input/Input';
import { IconVariant } from '../../../styled-components/Icon/Icon';
import PaymentInfoCard from '../../../styled-components/BookingBreakdown/partials/PaymentInfoCard';
import { CODE_TYPE } from '../../../styled-components/BookingBreakdown/types';
import useValidateCode from './hooks/useValidateCode';
import Text, { TextVariant } from '../../../styled-components/Text/Text';
import { setGiftCardInputNotEmpty } from '../CheckoutPageSlice';
import { CUSTOMER_COMMISSION_DISCOUNT_CODE_STORAGE_KEY } from '../../ListingPage/ListingPage';
const StyledForm = styled.form `
  display: flex;
  flex-direction: column;
  gap: 10px;

  & button {
    margin-left: auto;
    width: fit-content;
  }

  & svg {
    @media ${props => props.theme.device.tablet} {
      margin-top: -4px;
    }
  }
`;
const CodesSubsection = styled.div `
  display: flex;
  flex-direction: column;
`;
const CodesContainer = styled.div `
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
const SubmittedCodesTitle = styled(Text) `
  margin-bottom: 4px;
  margin-left: 8px;
`;
const GiftCardDiscountCodeForm = ({ giftCardCodes, customerCommissionDiscountCode, listingCurrency, addGiftCardCode, removeGiftCardCode, addCustomerCommissionDiscountCode, removeCustomerCommissionDiscountCode, stripePayinTotal, bookingStart, bookingEnd, paymentMode, }) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const { register, handleSubmit, formState: { errors }, reset, } = useForm();
    const [showGiftCardInput, setShowGiftCardInput] = useState(true);
    const { errorMessage, handleAddCode } = useValidateCode({
        giftCardCodes,
        customerCommissionDiscountCode,
        listingCurrency,
        addGiftCardCode,
        addCustomerCommissionDiscountCode,
        bookingStart,
        bookingEnd,
        paymentMode,
    });
    useEffect(() => {
        if (window === null || window === void 0 ? void 0 : window.sessionStorage) {
            const sessionStorageCustomCommissionDiscountCode = sessionStorage.getItem(CUSTOMER_COMMISSION_DISCOUNT_CODE_STORAGE_KEY);
            if (sessionStorageCustomCommissionDiscountCode) {
                handleAddCode(sessionStorageCustomCommissionDiscountCode);
            }
        }
    }, []);
    useEffect(() => {
        const canAddDiscountCode = !customerCommissionDiscountCode;
        const canAddGiftCardCode = !giftCardCodes || (giftCardCodes && giftCardCodes.length < 3);
        const fullAmountCovered = stripePayinTotal === 0;
        const inputVisible = !fullAmountCovered && (canAddDiscountCode || canAddGiftCardCode);
        if (!inputVisible) {
            dispatch(setGiftCardInputNotEmpty(false));
        }
        setShowGiftCardInput(inputVisible);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [giftCardCodes, stripePayinTotal, customerCommissionDiscountCode]);
    const _a = register('code', {
        required: false,
        onChange: event => {
            var _a, _b;
            if (((_b = (_a = event.target) === null || _a === void 0 ? void 0 : _a.value) === null || _b === void 0 ? void 0 : _b.length) > 0) {
                dispatch(setGiftCardInputNotEmpty(true));
            }
            else {
                dispatch(setGiftCardInputNotEmpty(false));
            }
        },
        pattern: {
            value: ALLOWED_CHARACTERS,
            message: 'Input.validationMessage.giftCardCode',
        },
    }), { ref: codeRef } = _a, code = __rest(_a, ["ref"]);
    const onSuccess = (data) => __awaiter(void 0, void 0, void 0, function* () {
        if (data.code) {
            const response = yield handleAddCode(data.code);
            if (response !== 'error') {
                dispatch(setGiftCardInputNotEmpty(false));
                reset();
            }
        }
    });
    const sanitizeRemainingValue = (value) => {
        return value / 100;
    };
    return (_jsxs(_Fragment, { children: [showGiftCardInput && (_jsxs(StyledForm, Object.assign({ onSubmit: handleSubmit(onSuccess) }, { children: [_jsx(Input, Object.assign({ label: intl.formatMessage({
                            id: `GiftCardDiscountCodeForm.GiftCard.${paymentMode}.inputTitle`,
                        }), id: "code", icon: IconVariant.GiftCard }, code, { inputRef: codeRef, error: errors === null || errors === void 0 ? void 0 : errors.code, placeholder: intl.formatMessage({
                            id: 'GiftCardDiscountCodeForm.GiftCard.placeholder',
                        }), "data-testid": 'GiftCardDiscountCodeForm_input' })), _jsx(Button, { type: "submit", label: intl.formatMessage({
                            id: 'GiftCardDiscountCodeForm.GiftCard.submit',
                        }), sizeVariant: ButtonSizeVariant.Small, responsive: true })] }))), (!!(giftCardCodes === null || giftCardCodes === void 0 ? void 0 : giftCardCodes.length) || customerCommissionDiscountCode) && (_jsxs(CodesSubsection, { children: [_jsx(SubmittedCodesTitle, Object.assign({ variant: TextVariant.B30 }, { children: intl.formatMessage({
                            id: 'GiftCardDiscountCodeForm.GiftCard.submittedCodes',
                        }) })), _jsxs(CodesContainer, { children: [giftCardCodes === null || giftCardCodes === void 0 ? void 0 : giftCardCodes.map(code => (_jsx(PaymentInfoCard, { code: code.last2, currency: (code === null || code === void 0 ? void 0 : code.currency) || '', codeType: (code === null || code === void 0 ? void 0 : code.codeType) || CODE_TYPE.GIFT_CARD, value: sanitizeRemainingValue(code.remainingValue), onRemove: () => removeGiftCardCode(code.id) }, code.id))), customerCommissionDiscountCode && (_jsx(PaymentInfoCard, { value: customerCommissionDiscountCode.discountInPercent, code: customerCommissionDiscountCode.code, codeType: CODE_TYPE.CUSTOMER_COMMISSION_DISCOUNT_CODE, currency: "%", onRemove: removeCustomerCommissionDiscountCode }, customerCommissionDiscountCode.code))] })] })), errorMessage && _jsx(FormErrorMessage, { id: errorMessage })] }));
};
export default GiftCardDiscountCodeForm;
