import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import throttle from 'lodash/throttle';
/**
 * A higher order component (HOC) that provides dimensions to the wrapped component as a
 * `dimensions` prop that has the shape `{ width: 600, height: 400}`.
 *
 * @param {React.Component} Component to be wrapped by this HOC
 * @param {Object} options pass in options like maxWidth and maxHeight.
 *
 * @return {Object} HOC component which knows its dimensions
 */
function withDimensions(Component, options = {}) {
    // The resize event is flooded when the browser is resized. We'll
    // use a small timeout to throttle changing the viewport since it
    // will trigger rerendering.
    const THROTTLE_WAIT_MS = 200;
    // First render default wait after mounting (small wait for styled paint)
    const RENDER_WAIT_MS = 100;
    class WithDimensionsComponent extends React.Component {
        constructor(props) {
            super(props);
            this.elementRef = React.createRef();
            this.state = { width: 0, height: 0 };
            this.handleWindowResize = throttle(this.handleWindowResize.bind(this), THROTTLE_WAIT_MS);
            this.setDimensions = this.setDimensions.bind(this);
        }
        componentDidMount() {
            window.addEventListener('resize', this.handleWindowResize);
            window.addEventListener('orientationchange', this.handleWindowResize);
            this.defaultRenderTimeout = window.setTimeout(() => {
                this.setDimensions();
            }, RENDER_WAIT_MS);
        }
        componentWillUnmount() {
            window.removeEventListener('resize', this.handleWindowResize);
            window.removeEventListener('orientationchange', this.handleWindowResize);
            window.clearTimeout(this.defaultRenderTimeout);
        }
        handleWindowResize() {
            window.requestAnimationFrame(() => {
                this.setDimensions();
            });
        }
        setDimensions() {
            this.setState(() => {
                const { clientWidth, clientHeight } = this.elementRef.current || {
                    clientWidth: 0,
                    clientHeight: 0,
                };
                return { width: clientWidth, height: clientHeight };
            });
        }
        render() {
            // Dimensions from state (i.e. dimension after previous resize)
            // These are needed for component rerenders
            const { width, height } = this.state;
            // Current dimensions from element reference
            const { clientWidth, clientHeight } = this.elementRef.current || {
                clientWidth: 0,
                clientHeight: 0,
            };
            const widthAndHeightNotZero = width !== 0 && height !== 0 ? { width, height } : {};
            // clientWidth and clientHeight
            const currentDimensions = clientWidth !== 0 && clientHeight !== 0
                ? { width: clientWidth, height: clientHeight }
                : widthAndHeightNotZero;
            const props = Object.assign(Object.assign({}, this.props), { dimensions: currentDimensions });
            // lazyLoadWithDimensions HOC needs to take all given space
            // unless max dimensions are provided through options.
            const { maxWidth, maxHeight, pure } = options;
            if (pure) {
                return (_jsx("div", Object.assign({ ref: this.elementRef, style: { marginLeft: '16px', marginRight: '16px' } }, { children: _jsx(Component, Object.assign({}, props)) })));
            }
            const maxWidthMaybe = maxWidth ? { maxWidth } : {};
            const maxHeightMaybe = maxHeight ? { maxHeight } : {};
            const style = maxWidth || maxHeight
                ? Object.assign(Object.assign({ width: '100%', height: '100%' }, maxWidthMaybe), maxHeightMaybe) : {
                position: 'absolute',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
            };
            return (_jsx("div", Object.assign({ ref: this.elementRef, style: style }, { children: _jsx(Component, Object.assign({}, props)) })));
        }
    }
    WithDimensionsComponent.displayName = `withDimensions(${Component.displayName || Component.name})`;
    return WithDimensionsComponent;
}
export default withDimensions;
