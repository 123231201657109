import env, { isBrowser, uninterpolatedProcessEnv } from './env';
export var RuntimeEnvironment;
(function (RuntimeEnvironment) {
    RuntimeEnvironment["Production"] = "production";
    RuntimeEnvironment["Development"] = "development";
    RuntimeEnvironment["Local"] = "local";
})(RuntimeEnvironment || (RuntimeEnvironment = {}));
const DefaultRuntimeEnvironmentBaseUrl = {
    [RuntimeEnvironment.Development]: 'camp.nomady.dev',
    [RuntimeEnvironment.Production]: 'nomady.camp',
    [RuntimeEnvironment.Local]: 'localhost',
};
export const getMarketplaceDomain = (isApp) => {
    if (isApp) {
        return 'nomady.camp';
    }
    const environment = env('ENV');
    const marketplaceDomain = env('MARKETPLACE_URL') || DefaultRuntimeEnvironmentBaseUrl[environment];
    if (!marketplaceDomain) {
        throw new Error(`there is no base url defined for environment ${environment}. REACT_APP_ENV environment variable must be set to one of these values: ${Object.values(RuntimeEnvironment).join(', ')} or marketplace url must be set manually via REACT_APP_MARKETPLACE_URL`);
    }
    return marketplaceDomain;
};
export const santizeHost = (rawHost) => {
    const marketplaceDomain = getMarketplaceDomain();
    if (marketplaceDomain === rawHost) {
        return rawHost;
    }
    return undefined;
};
export const getStaticHost = (requestHost) => `static.${requestHost}`;
export const apiHost = () => {
    if (isBrowser() && !env('API_ENDPOINT')) {
        return `${window.location.origin}`;
    }
    if (isBrowser()) {
        return env('API_ENDPOINT');
    }
    const endpoint = uninterpolatedProcessEnv().SSR_API_ENDPOINT;
    if (!endpoint) {
        throw new Error('could not determine the api endpoint. Ensure SSR_API_ENDPOINT is set in the server side rendering rendering context. In browser context this setting can be overriden by setting REACT_APP_API_ENDPOINT env variable');
    }
    return endpoint;
};
export const currentOrigin = () => {
    if (isBrowser()) {
        return `${window.location.origin}`;
    }
    return '';
};
export const getDomainWithProtocol = (domain) => {
    const multiDomainEnvironmentDisabled = !!env('DISABLE_MULTI_DOMAIN_ENVIRONMENT');
    if (multiDomainEnvironmentDisabled) {
        return '';
    }
    const isLocalEnv = env('ENV') === RuntimeEnvironment.Local;
    const prefix = !isLocalEnv ? 'https://' : 'http://';
    return `${prefix}${domain}`;
};
