import styled from 'styled-components/macro';
export default styled.div `
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 18px 24px;
  z-index: ${props => props.theme.priority.low};

  box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.1);
  background-color: ${props => props.theme.color.pearl};

  @media ${props => props.theme.device.desktop} {
    z-index: initial;
    position: relative;
    box-shadow: none;
    width: auto;
    margin: 0 15px 0 15px;
    padding: 0;
  }
`;
