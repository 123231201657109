var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { maps } from '../config';
import { DEFAULT_LOCALE } from '../marketplace-custom-config';
import { types as sdkTypes } from './sdkLoader';
const { LatLng: SDKLatLng, LatLngBounds: SDKLatLngBounds } = sdkTypes;
const placeOrigin = (place) => {
    if (place && place.geometry && place.geometry.location) {
        return new SDKLatLng(place.geometry.location.lat(), place.geometry.location.lng());
    }
    return null;
};
const placeBounds = (place) => {
    var _a;
    // eslint-disable-next-line camelcase
    return locationBoundsFromPlaceId((_a = place === null || place === void 0 ? void 0 : place.place_id) !== null && _a !== void 0 ? _a : null);
};
const placeBoundsFromRadius = (place, centerLocationBoundsDistance) => {
    if (place && place.geometry && place.geometry.location) {
        const centerLocation = new SDKLatLng(place.geometry.location.lat(), place.geometry.location.lng());
        return locationBounds({ lat: centerLocation.lat, lng: centerLocation.lng }, centerLocationBoundsDistance);
    }
    return null;
};
export const addressComponents = (place) => {
    var _a, _b, _c, _d, _e, _f, _g;
    if (!place.address_components) {
        return null;
    }
    const addressComponents = {
        country: ((_a = place.address_components.find(element => element.types.includes('country'))) === null || _a === void 0 ? void 0 : _a.short_name) || undefined,
        level1: ((_b = place.address_components.find(element => element.types.includes('administrative_area_level_1'))) === null || _b === void 0 ? void 0 : _b.short_name) || undefined,
        level2: ((_c = place.address_components.find(element => element.types.includes('administrative_area_level_2'))) === null || _c === void 0 ? void 0 : _c.short_name) || undefined,
        postal_code: ((_d = place.address_components.find(element => element.types.includes('postal_code'))) === null || _d === void 0 ? void 0 : _d.short_name) || undefined,
        locality: ((_e = place.address_components.find(element => element.types.includes('locality'))) === null || _e === void 0 ? void 0 : _e.short_name) || undefined,
        street: ((_f = place.address_components.find(element => element.types.includes('route'))) === null || _f === void 0 ? void 0 : _f.short_name) || undefined,
        street_number: ((_g = place.address_components.find(element => element.types.includes('street_number'))) === null || _g === void 0 ? void 0 : _g.short_name) || undefined,
    };
    return addressComponents;
};
const placeName = (place) => {
    if (!place.address_components) {
        return place.formatted_address;
    }
    const locality = place.address_components.find(place => place.types.includes('locality'));
    if (locality) {
        return locality === null || locality === void 0 ? void 0 : locality.long_name;
    }
    return place.formatted_address;
};
/**
 * Get a detailed place object
 *
 * @param {String} placeId - ID for a place received from the
 * autocomplete service
 * @param {String} sessionToken - token to tie different autocomplete character searches together
 * with getPlaceDetails call
 *
 * @return {Promise<util.propTypes.place>} Promise that
 * resolves to the detailed place, rejects if the request failed
 */
export const getPlaceDetails = (placeId, sessionToken, language = DEFAULT_LOCALE) => new Promise((resolve, reject) => {
    const serviceStatus = window.google.maps.places.PlacesServiceStatus;
    const el = document.createElement('div');
    const service = new window.google.maps.places.PlacesService(el);
    const fields = [
        'address_components',
        'formatted_address',
        'geometry',
        'place_id',
    ];
    const sessionTokenMaybe = sessionToken ? { sessionToken } : {};
    const languageMaybe = language
        ? { language: language.substring(0, 2) }
        : {};
    service.getDetails(Object.assign(Object.assign({ placeId, fields }, languageMaybe), sessionTokenMaybe), (place, status) => {
        if (status !== serviceStatus.OK) {
            reject(new Error(`Could not get details for place id "${placeId}", error status was "${status}"`));
        }
        else {
            const commonPlaceInformation = {
                placeId,
                address: place.formatted_address,
                address_components: addressComponents(place),
                name: placeName(place),
                location: placeOrigin(place),
            };
            placeBounds(place)
                .then(bounds => {
                resolve(Object.assign(Object.assign({}, commonPlaceInformation), { bounds }));
            })
                .catch(() => {
                resolve(Object.assign(Object.assign({}, commonPlaceInformation), { bounds: placeBoundsFromRadius(place, maps.currentLocationBoundsDistance) }));
            });
        }
    });
});
const predictionSuccessful = (status) => {
    const { OK, ZERO_RESULTS } = window.google.maps.places.PlacesServiceStatus;
    return status === OK || status === ZERO_RESULTS;
};
/**
 * Get place predictions for the given search
 *
 * @param {String} search - place name or address to search
 * @param {String} sessionToken - token to tie different autocomplete character searches together
 * with getPlaceDetails call
 * @param {Object} searchConfigurations - defines the search configurations that can be used with
 * the autocomplete service. Used to restrict search to specific country (or countries).
 *
 * @return {Promise<{ search, predictions[] }>} - Promise of an object
 * with the original search query and an array of
 * `google.maps.places.AutocompletePrediction` objects
 */
export const getPlacePredictions = (search, sessionToken, searchConfigurations) => new Promise((resolve, reject) => {
    const service = new window.google.maps.places.AutocompleteService();
    const sessionTokenMaybe = sessionToken ? { sessionToken } : {};
    const language = searchConfigurations.language
        ? { language: searchConfigurations.language.substring(0, 2) }
        : { language: DEFAULT_LOCALE.substring(0, 2) };
    service.getPlacePredictions(Object.assign(Object.assign(Object.assign({ input: search }, sessionTokenMaybe), language), searchConfigurations), (predictions, status) => {
        if (!predictionSuccessful(status)) {
            reject(new Error(`Prediction service status not OK: ${status}`));
        }
        else {
            const results = {
                search,
                predictions: predictions || [],
            };
            resolve(results);
        }
    });
});
/**
 * Calculate a bounding box in the given location
 *
 * @param {latlng} center - center of the bounding box
 * @param {distance} distance - distance in meters from the center to
 * the sides of the bounding box
 *
 * @return {LatLngBounds} bounding box around the given location
 *
 */
const locationBoundsFromPlaceId = (placeId) => __awaiter(void 0, void 0, void 0, function* () {
    const geocoder = new window.google.maps.Geocoder();
    const place = yield geocoder.geocode({ placeId });
    const { bounds } = place.results[0].geometry;
    const ne = bounds.getNorthEast();
    const sw = bounds.getSouthWest();
    return new SDKLatLngBounds(new SDKLatLng(ne.lat(), ne.lng()), new SDKLatLng(sw.lat(), sw.lng()));
});
export const locationBounds = (latlng, distance) => {
    const bounds = new window.google.maps.Circle({
        center: new window.google.maps.LatLng(latlng.lat, latlng.lng),
        radius: distance,
    }).getBounds();
    const ne = bounds.getNorthEast();
    const sw = bounds.getSouthWest();
    return new SDKLatLngBounds(new SDKLatLng(ne.lat(), ne.lng()), new SDKLatLng(sw.lat(), sw.lng()));
};
