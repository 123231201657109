import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styled from 'styled-components/macro';
import { useIntl } from 'react-intl';
import Text, { TextVariant } from '../../Text/Text';
import Icon, { IconSizeVariant } from '../../Icon/Icon';
import { ColorVariant } from '../../../theme';
const BadgeLabel = styled.div `
  display: flex;
  gap: 2px;
  background: ${props => props.theme.color[props.backgroundColorVariant]};
  border-radius: 4px;
  width: fit-content;

  @media ${props => props.theme.device.tablet} {
    padding-left: 4px;
    padding-right: 10px;
  }
`;
const Label = styled(Text) `
  display: none;

  @media ${props => props.theme.device.tablet} {
    display: block;
  }
`;
const ListingCardBadge = ({ iconVariant, labelId, backgroundColorVariant = ColorVariant.BasicPearlWhiteTypeWhite, }) => {
    const intl = useIntl();
    const foregroundColorVariant = backgroundColorVariant === ColorVariant.BasicPearlWhiteTypeWhite
        ? ColorVariant.BasicCaveBlack
        : ColorVariant.BasicPearlWhiteTypeWhite;
    return (_jsxs(BadgeLabel, Object.assign({ backgroundColorVariant: backgroundColorVariant }, { children: [_jsx(Icon, { sizeVariant: IconSizeVariant.Tiny, variant: iconVariant, colorVariant: foregroundColorVariant }), _jsx(Label, Object.assign({ colorVariant: foregroundColorVariant, variant: TextVariant.L4SB }, { children: intl.formatMessage({ id: labelId }) }))] })));
};
export default ListingCardBadge;
