import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styled from 'styled-components/macro';
import Button, { ButtonColorVariant } from '../Button/Button';
import { IconVariant } from '../Icon/Icon';
import Text, { TextVariant } from '../Text/Text';
const Wrapper = styled.section `
  background-color: ${props => props.theme.color.pearl};

  width: 100%;

  padding-top: 30px;
  padding-bottom: 60px;

  @media ${props => props.theme.device.desktop} {
    padding-top: 60px;
    padding-bottom: 120px;
  }
`;
const Inner = styled.div `
  ${props => props.theme.layout.container}

  display: flex;
  flex-direction: column;

  @media ${props => props.theme.device.desktop} {
    flex-direction: row-reverse;
    align-items: center;
  }
`;
const Illustration = styled.img `
  ${props => props.src && `background-image: url(${props.src});`}
  background-size: cover;
  background-position: center center;

  max-width: 100%;
  height: auto;

  margin-bottom: 30px;

  @media ${props => props.theme.device.tablet} {
    max-width: 75%;

    margin-left: auto;
    margin-right: auto;
  }

  @media ${props => props.theme.device.desktop} {
    max-width: 45%;
  }
`;
const Content = styled.div `
  @media ${props => props.theme.device.desktop} {
    flex-grow: 1;
    margin-right: 120px;
  }
`;
const Title = styled(Text) `
  margin-bottom: 10px;
`;
const SubTitle = styled(Text) `
  margin-bottom: 30px;
`;
const HeroCta = ({ image, title, subTitle, ctaText, ctaTarget, ctaExternalLink, }) => (_jsx(Wrapper, { children: _jsxs(Inner, { children: [_jsx(Illustration, { src: image === null || image === void 0 ? void 0 : image.data.attributes.url, alt: image === null || image === void 0 ? void 0 : image.data.attributes.alternativeText, loading: "lazy" }), _jsxs(Content, { children: [_jsx(Title, Object.assign({ variant: TextVariant.H6, as: "h1" }, { children: title })), _jsx(SubTitle, Object.assign({ variant: TextVariant.H1, as: "p" }, { children: subTitle })), ctaTarget || ctaExternalLink ? (_jsx(Button, { label: ctaText, targetRoute: ctaTarget, externalLink: ctaExternalLink, icon: IconVariant.ArrowRight, colorVariant: ButtonColorVariant.Dark })) : null] })] }) }));
export default HeroCta;
