import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styled from 'styled-components/macro';
import { ColorVariant } from '../../theme';
const Wrapper = styled.div `
  stroke: ${props => props.theme.color[props.colorVariant]};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;

  stroke-width: 3px;
`;
const Spinner = ({ colorVariant = ColorVariant.Pearl, }) => {
    const inner = (_jsx("svg", Object.assign({ width: "100%", height: "100%", viewBox: "0 0 30 30", preserveAspectRatio: "xMidYMid", xmlns: "http://www.w3.org/2000/svg" }, { children: _jsxs("circle", Object.assign({ cx: "15", cy: "15", r: "12", fill: "none", strokeLinecap: "round" }, { children: [_jsx("animateTransform", { attributeName: "transform", type: "rotate", calcMode: "linear", values: "0 15 15;180 15 15;720 15 15", keyTimes: "0;0.5;1", dur: "0.9s", begin: "0s", repeatCount: "indefinite" }), _jsx("animate", { attributeName: "stroke-dasharray", calcMode: "linear", values: "9 56;46 14;9 56", keyTimes: "0;0.5;1", dur: "0.9s", begin: "0s", repeatCount: "indefinite" })] })) })));
    return _jsx(Wrapper, Object.assign({ colorVariant: colorVariant }, { children: inner }));
};
export default Spinner;
