var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createSlice } from '@reduxjs/toolkit';
import { LISTING_PAGE_PARAM_TYPE_NEW } from '@nomady/shared/utils/urlHelpers';
import { types as sdkTypes } from '@nomady/shared/utils/sdkLoader';
import * as log from '../../util/log';
import { storableError } from '../../util/errors';
import { createOwnListingDraft, showListing, showOwnListing, updateOwnListing, } from '../../util/api';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { localeSelector } from '../../slices/UISlice';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { fetchStripeAccount } from '../../ducks/stripeConnectAccount.duck';
const { UUID } = sdkTypes;
const initialState = {
    createListingDraftError: null,
    publishingListing: null,
    publishListingError: null,
    updateListingError: null,
    showListingsError: null,
    uploadImageError: null,
    createListingDraftInProgress: false,
    submittedListingId: null,
    redirectToListing: false,
    listingDraft: null,
    updatedTab: null,
    updateInProgress: false,
};
export const EditListingPageSlice = createSlice({
    name: 'app/EditListingPage',
    initialState,
    reducers: {
        markTabUpdated: (state, action) => (Object.assign(Object.assign({}, state), { updatedTab: action.payload })),
        clearUpdatedTab: (state, _) => (Object.assign(Object.assign({}, state), { updatedTab: null, updatedListing: null })),
        createListingDraftRequest: (state, _) => (Object.assign(Object.assign({}, state), { createListingDraftInProgress: true, createListingDraftError: null, submittedListingId: null, listingDraft: null })),
        createListingDraftSuccess: (state, action) => (Object.assign(Object.assign({}, state), { createListingDraftInProgress: false, submittedListingId: action.payload.data.id, listingDraft: action.payload.data })),
        createListingDraftError: (state, action) => (Object.assign(Object.assign({}, state), { createListingDraftInProgress: false, createListingDraftError: action.payload })),
        publishListingRequest: (state, action) => (Object.assign(Object.assign({}, state), { publishingListing: action.payload, publishListingError: null })),
        publishListingSuccess: (state, _) => (Object.assign(Object.assign({}, state), { redirectToListing: true, publishingListing: null, createListingDraftError: null, updateListingError: null, showListingsError: null, uploadImageError: null, createListingDraftInProgress: false, updateInProgress: false })),
        publishListingError: (state, action) => (Object.assign(Object.assign({}, state), { publishingListing: null, publishListingError: {
                listingId: state.publishingListing,
                error: action.payload,
            } })),
        updateListingRequest: (state, _) => (Object.assign(Object.assign({}, state), { updateInProgress: true, updateListingError: null })),
        updateListingSuccess: (state, _) => (Object.assign(Object.assign({}, state), { updateInProgress: false })),
        updateListingError: (state, action) => (Object.assign(Object.assign({}, state), { updateInProgress: false, updateListingError: action.payload })),
        showListingRequest: (state, _) => (Object.assign(Object.assign({}, state), { showListingsError: null })),
        showListingSuccess: (state, _) => initialState,
        showListingError: (state, action) => (Object.assign(Object.assign({}, state), { showListingsError: action.payload, redirectToListing: false })),
        uploadImageRequest: state => {
            return Object.assign(Object.assign({}, state), { uploadImageError: null });
        },
        uploadImageSuccess: state => {
            return Object.assign(Object.assign({}, state), { uploadImageError: null });
        },
        uploadImageError: (state, action) => {
            const { error } = action.payload;
            return Object.assign(Object.assign({}, state), { uploadImageError: error });
        },
        savePayoutDetailsRequest: (state, _) => (Object.assign(Object.assign({}, state), { payoutDetailsSaveInProgress: true })),
        savePayoutDetailsSuccess: (state, _) => (Object.assign(Object.assign({}, state), { payoutDetailsSaveInProgress: false, payoutDetailsSaved: true })),
        savePayoutDetailsError: (state, _) => (Object.assign(Object.assign({}, state), { payoutDetailsSaveInProgress: false })),
    },
});
export const { markTabUpdated, clearUpdatedTab, createListingDraftRequest, createListingDraftSuccess, createListingDraftError, publishListingRequest, publishListingSuccess, publishListingError, updateListingRequest, updateListingSuccess, updateListingError, showListingRequest, showListingSuccess, showListingError, uploadImageRequest, uploadImageSuccess, uploadImageError, savePayoutDetailsRequest, savePayoutDetailsSuccess, savePayoutDetailsError, } = EditListingPageSlice.actions;
const requestShowListing = (actionPayload) => (dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
    dispatch(showListingRequest());
    let response;
    try {
        response = yield showOwnListing(actionPayload);
        dispatch(addMarketplaceEntities(response));
        dispatch(showListingSuccess());
    }
    catch (e) {
        console.error(e);
        dispatch(showListingError(storableError(e)));
    }
    return response;
});
export const requestCreateListingDraft = (data) => (dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
    dispatch(createListingDraftRequest());
    const state = getState();
    const locale = localeSelector(state);
    const queryParams = {
        locale,
        expand: true,
        include: ['author', 'images'],
        'fields.user': ['profile.metadata'],
        'fields.image': [
            'variants.landscape-crop',
            'variants.landscape-crop2x',
            'variants.square-small',
            'variants.square-small2x',
        ],
    };
    try {
        const response = yield createOwnListingDraft(data, queryParams);
        // Add the created listing to the marketplace data
        dispatch(addMarketplaceEntities(response));
        // Modify store to understand that we have created listing and can redirect away
        dispatch(createListingDraftSuccess(response));
        return response;
    }
    catch (e) {
        log.error(e, 'create-listing-draft-failed', {
            listingData: data,
        });
        return dispatch(createListingDraftError(storableError(e)));
    }
});
export const requestPublishListingDraft = (listingId) => (dispatch, _, sdk) => __awaiter(void 0, void 0, void 0, function* () {
    dispatch(publishListingRequest(listingId));
    try {
        yield sdk.ownListings.publishDraft({ id: listingId }, { expand: true });
        const response = yield showListing({ id: listingId });
        dispatch(addMarketplaceEntities(response));
        dispatch(publishListingSuccess());
        return response;
    }
    catch (error) {
        dispatch(publishListingError(storableError(error)));
    }
});
// Images return imageId which we need to map with previously generated temporary id
export const requestImageUpload = (image) => (dispatch, _, sdk) => __awaiter(void 0, void 0, void 0, function* () {
    dispatch(uploadImageRequest());
    try {
        const response = yield sdk.images.upload({ image: image.file }, { expand: true });
        dispatch(uploadImageSuccess());
        return response.data.data;
    }
    catch (e) {
        dispatch(uploadImageError({
            error: storableError(e),
        }));
    }
});
// Update the given tab of the wizard with the given data. This saves
// the data to the listing, and marks the tab updated so the UI can
// display the state.
export const requestUpdateListing = (tab, data) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    dispatch(updateListingRequest());
    const { id } = data;
    try {
        const response = yield updateOwnListing(data);
        const payload = {
            id,
            include: ['author', 'images'],
            'fields.user': ['profile.metadata'],
            'fields.image': [
                'variants.landscape-crop',
                'variants.landscape-crop2x',
                'variants.square-small',
                'variants.square-small2x',
            ],
        };
        yield dispatch(requestShowListing(payload));
        dispatch(markTabUpdated(tab));
        dispatch(updateListingSuccess());
        return response;
    }
    catch (error) {
        log.error(error, 'update-listing-failed', { listingData: data });
        return dispatch(updateListingError(storableError(error)));
    }
});
export default EditListingPageSlice.reducer;
// loadData is run for each tab of the wizard. When editing an
// existing listing, the listing must be fetched first.
export const loadData = (params) => (dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
    dispatch(clearUpdatedTab());
    const { id, type } = params;
    if (type === LISTING_PAGE_PARAM_TYPE_NEW) {
        // No need to listing data when creating a new listing
        const response = yield Promise.all([dispatch(fetchCurrentUser())]);
        const { user: { currentUser }, } = getState();
        if (currentUser && currentUser.stripeAccount) {
            dispatch(fetchStripeAccount());
        }
        return response;
    }
    const payload = {
        id: new UUID(id),
        include: ['author', 'images'],
        'fields.user': ['profile.metadata'],
        'fields.image': [
            'variants.landscape-crop',
            'variants.landscape-crop2x',
            'variants.square-small',
            'variants.square-small2x',
        ],
    };
    const response = yield Promise.all([
        dispatch(requestShowListing(payload)),
        dispatch(fetchCurrentUser()),
    ]);
    const { user: { currentUser }, } = getState();
    if (currentUser && currentUser.stripeAccount) {
        dispatch(fetchStripeAccount());
    }
    return response;
});
