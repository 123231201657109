import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styled, { keyframes } from 'styled-components/macro';
import IconButtonOld, { OldIconButtonColorVariant, OldIconButtonSizeVariant, } from '../../styled-components/IconButtonOld/IconButtonOld';
import Icon, { IconVariant } from '../../styled-components/Icon/Icon';
import { ColorVariant } from '../../theme';
import Text, { TextVariant } from '../../styled-components/Text/Text';
import { SnackBarType } from './SnackBarSlice';
import { useIntl } from 'react-intl';
import jannisImg from './assets/Jannis.jpg';
const snackbarAnimation = keyframes `
  0% { transform: translateX(-100px); opacity: 0; }
  100% { transform: translateX(0); opacity: 1; }
`;
const Wrapper = styled.div `
  display: flex;
  align-items: start;
  gap: 8px;
  padding: 8px;
  background-color: ${props => props.theme.color.pearl};
  box-shadow: inset 0 0 0 1px ${props => props.theme.color[props.colorVariant]};
  border-radius: 8px;
  pointer-events: auto;
  animation: ${snackbarAnimation} 0.3s ease-in-out;
  max-width: 344px;
`;
const IconWrapper = styled.div `
  padding: 4px;
  border-radius: 8px;
  background-color: ${props => props.theme.color[props.colorVariant]};
`;
const TextWrapper = styled.div `
  display: flex;
  flex-direction: column;
`;
const AdvertImage = styled.div `
  width: 128px;
  height: 128px;
  border-radius: 8px;
  overflow: hidden;
  flex: 0 0 auto;
`;
export const SnackBar = ({ onClose, type, i18nTitleKey, i18nTextKey, plainTitle, plainText, }) => {
    const intl = useIntl();
    const title = i18nTitleKey
        ? intl.formatMessage({ id: i18nTitleKey })
        : plainTitle || '';
    const text = i18nTextKey
        ? intl.formatMessage({ id: i18nTextKey })
        : plainText || '';
    const colorMapping = {
        [SnackBarType.CONTACT_JANNIS]: ColorVariant.BasicCaveBlack,
        [SnackBarType.ERROR]: ColorVariant.FunctionError2,
        [SnackBarType.WARN]: ColorVariant.FunctionError2,
        [SnackBarType.INFO]: ColorVariant.FunctionBlueInformation,
        [SnackBarType.SUCCESS]: ColorVariant.FunctionSuccess3,
    };
    const color = colorMapping[type] || ColorVariant.FunctionError2;
    return (_jsxs(Wrapper, Object.assign({ colorVariant: color }, { children: [type === SnackBarType.CONTACT_JANNIS && (_jsx(AdvertImage, { children: _jsx("img", { src: jannisImg, alt: "Jannis", width: 128, height: 128 }) })), type === SnackBarType.ERROR ||
                type === SnackBarType.WARN ||
                type === SnackBarType.INFO ||
                (type === SnackBarType.SUCCESS && (_jsx(IconWrapper, Object.assign({ colorVariant: color }, { children: _jsx(IconComponent, { type: type }) })))), _jsxs(TextWrapper, { children: [_jsx(Text, Object.assign({ variant: TextVariant.P4SB, colorVariant: ColorVariant.BasicCaveBlack }, { children: title.split('\n').map((line, index) => (_jsxs(React.Fragment, { children: [line, _jsx("br", {})] }, index))) })), _jsx(Text, Object.assign({ variant: TextVariant.P4R, colorVariant: ColorVariant.FunctionStoneGreyInactive }, { children: text.split('\n').map((line, index) => (_jsxs(React.Fragment, { children: [line, _jsx("br", {})] }, index))) }))] }), _jsx(IconButtonOld, { colorVariant: OldIconButtonColorVariant.Transparent, iconVariant: IconVariant.X_Close, iconButtonSizeVariant: OldIconButtonSizeVariant.Medium, onClick: onClose, type: "button" })] })));
};
const IconComponent = ({ type }) => {
    switch (type) {
        case SnackBarType.WARN:
            return (_jsx(Icon, { variant: IconVariant.Warning_Orange, colorVariant: ColorVariant.BasicPearlWhiteTypeWhite }));
        case SnackBarType.ERROR:
            return (_jsx(Icon, { variant: IconVariant.Error_Careful, colorVariant: ColorVariant.BasicPearlWhiteTypeWhite }));
        case SnackBarType.SUCCESS:
            return (_jsx(Icon, { variant: IconVariant.Check_Light, colorVariant: ColorVariant.BasicPearlWhiteTypeWhite }));
        case SnackBarType.INFO:
        default:
            return (_jsx(Icon, { variant: IconVariant.Info, colorVariant: ColorVariant.BasicPearlWhiteTypeWhite }));
    }
};
