import { SummerActivity } from '@nomady/shared/types';
const getSummerActivitiesSearchParam = (activitiesSummerSearchQuery) => {
    const values = [];
    if (activitiesSummerSearchQuery === null || activitiesSummerSearchQuery === void 0 ? void 0 : activitiesSummerSearchQuery.biking) {
        values.push(SummerActivity.bike);
    }
    if (activitiesSummerSearchQuery === null || activitiesSummerSearchQuery === void 0 ? void 0 : activitiesSummerSearchQuery.hiking) {
        values.push(SummerActivity.hike);
    }
    if (activitiesSummerSearchQuery === null || activitiesSummerSearchQuery === void 0 ? void 0 : activitiesSummerSearchQuery.climbing) {
        values.push(SummerActivity.climb);
    }
    if (activitiesSummerSearchQuery === null || activitiesSummerSearchQuery === void 0 ? void 0 : activitiesSummerSearchQuery.canoeing) {
        values.push(SummerActivity.paddle);
    }
    if (activitiesSummerSearchQuery === null || activitiesSummerSearchQuery === void 0 ? void 0 : activitiesSummerSearchQuery.swimming) {
        values.push(SummerActivity.swim);
    }
    if (activitiesSummerSearchQuery === null || activitiesSummerSearchQuery === void 0 ? void 0 : activitiesSummerSearchQuery.fishing) {
        values.push(SummerActivity.fishing);
    }
    if (activitiesSummerSearchQuery === null || activitiesSummerSearchQuery === void 0 ? void 0 : activitiesSummerSearchQuery.observeWildAnimals) {
        values.push(SummerActivity.animalWatching);
    }
    if (values.length === 0) {
        return undefined;
    }
    return values.join(',');
};
export default getSummerActivitiesSearchParam;
