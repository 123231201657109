export var ModalColorVariant;
(function (ModalColorVariant) {
    ModalColorVariant["Default"] = "Default";
    ModalColorVariant["Snow"] = "Snow";
})(ModalColorVariant || (ModalColorVariant = {}));
export var ModalIllustrationVariant;
(function (ModalIllustrationVariant) {
    ModalIllustrationVariant["BrokenArrow"] = "BrokenArrow";
    ModalIllustrationVariant["Conversation"] = "Conversation";
    ModalIllustrationVariant["Contract"] = "Contract";
})(ModalIllustrationVariant || (ModalIllustrationVariant = {}));
