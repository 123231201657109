import Decimal from '@nomady/shared/utils/decimal';
import { getAmountAsDecimalJS, convertDecimalJSToNumber } from './currency';
import { types } from './sdkLoader';
import { LINE_ITEM_GIFT_CARD_PROVIDER_PAYOUT, LINE_ITEM_PROVIDER_COMMISSION, LINE_ITEM_PROVIDER_PAYOUT, } from './lineItemTypes';
const { Money } = types;
/** Helper functions for constructing line items */
/**
 * Calculates lineTotal for lineItem based on quantity.
 * The total will be `unitPrice * quantity`.
 *
 * @param {Money} unitPrice
 * @param {Decimal} quantity
 *
 * @returns {Money} lineTotal
 */
export const calculateTotalPriceFromQuantity = (unitPrice, unitCount) => {
    const amountFromUnitPrice = getAmountAsDecimalJS(unitPrice);
    // NOTE: We round the total price to the nearest integer.
    //       Payment processors don't support fractional subunits.
    const totalPrice = amountFromUnitPrice
        .times(unitCount)
        .toNearest(1, Decimal.ROUND_HALF_UP);
    // Get total price as Number (and validate that the conversion is safe)
    const numericTotalPrice = convertDecimalJSToNumber(totalPrice);
    return new Money(numericTotalPrice, unitPrice.currency);
};
/**
 * Calculates lineTotal for lineItem based on percentage.
 * The total will be `unitPrice * (percentage / 100)`.
 *
 * @param {Money} unitPrice
 * @param {Decimal} percentage
 *
 * @returns {Money} lineTotal
 */
export const calculateTotalPriceFromPercentage = (unitPrice, percentage) => {
    const amountFromUnitPrice = getAmountAsDecimalJS(unitPrice);
    // NOTE: We round the total price to the nearest integer.
    //       Payment processors don't support fractional subunits.
    const totalPrice = amountFromUnitPrice
        .times(percentage)
        .dividedBy(100)
        .toNearest(1, Decimal.ROUND_HALF_UP);
    // Get total price as Number (and validate that the conversion is safe)
    const numericTotalPrice = convertDecimalJSToNumber(totalPrice);
    return new Money(numericTotalPrice, unitPrice.currency);
};
/**
 * Calculates lineTotal for lineItem based on seats and units.
 * The total will be `unitPrice * units * seats`.
 *
 * @param {Money} unitPrice
 * @param {Decimal} unitCount
 * @param {number} seats
 *
 * @returns {Money} lineTotal
 */
export const calculateTotalPriceFromSeats = (unitPrice, unitCount, seats) => {
    if (seats < 0) {
        throw new Error(`Value of seats can't be negative`);
    }
    const amountFromUnitPrice = getAmountAsDecimalJS(unitPrice);
    // NOTE: We round the total price to the nearest integer.
    //       Payment processors don't support fractional subunits.
    const totalPrice = amountFromUnitPrice
        .times(unitCount)
        .times(seats)
        .toNearest(1, Decimal.ROUND_HALF_UP);
    // Get total price as Number (and validate that the conversion is safe)
    const numericTotalPrice = convertDecimalJSToNumber(totalPrice);
    return new Money(numericTotalPrice, unitPrice.currency);
};
/**
 *
 *  `lineTotal` is calculated by the following rules:
 * - If `quantity` is provided, the line total will be `unitPrice * quantity`.
 * - If `percentage` is provided, the line total will be `unitPrice * (percentage / 100)`.
 * - If `seats` and `units` are provided the line item will contain `quantity` as a product of `seats` and `units` and the line total will be `unitPrice * units * seats`.
 *
 * @param {Object} lineItem
 * @return {Money} lineTotal
 *
 */
export const calculateLineTotal = (lineItem) => {
    const { code, unitPrice, quantity, percentage, seats, units } = lineItem;
    if (quantity) {
        return calculateTotalPriceFromQuantity(unitPrice, quantity);
    }
    if (percentage) {
        return calculateTotalPriceFromPercentage(unitPrice, percentage);
    }
    if (seats && units) {
        return calculateTotalPriceFromSeats(unitPrice, units, seats);
    }
    throw new Error(`Can't calculate the lineTotal of lineItem: ${code}. Make sure the lineItem has quantity, percentage or both seats and units`);
};
/**
 * Calculates the total sum of lineTotals for given lineItems
 *
 * @param {Array} lineItems
 * @retuns {Money} total sum
 */
export const calculateTotalFromLineItems = (lineItems) => {
    const totalPrice = lineItems.reduce((sum, lineItem) => {
        const lineTotal = calculateLineTotal(lineItem);
        return getAmountAsDecimalJS(lineTotal).add(sum);
    }, new Decimal(0));
    // Get total price as Number (and validate that the conversion is safe)
    const numericTotalPrice = convertDecimalJSToNumber(totalPrice);
    const { unitPrice } = lineItems[0];
    return new Money(numericTotalPrice, unitPrice.currency);
};
export const getLineItemsByRole = (lineItems, role) => lineItems.filter(lineItem => lineItem.includeFor.includes(role));
/**
 * Calculates the total sum of lineTotals for given lineItems where `includeFor` includes `provider`
 * @param {*} lineItems
 * @returns {Money} total sum
 */
export const calculateTotalForProvider = (lineItems) => {
    const providerLineItems = lineItems.filter((lineItem) => lineItem.includeFor.includes('provider'));
    return calculateTotalFromLineItems(providerLineItems);
};
export const calculateTotalForProviderBeforeCommission = (lineItems) => {
    const providerLineItems = lineItems
        .filter((lineItem) => ![
        LINE_ITEM_PROVIDER_COMMISSION,
        LINE_ITEM_GIFT_CARD_PROVIDER_PAYOUT,
        LINE_ITEM_PROVIDER_PAYOUT,
    ].includes(lineItem.code))
        .filter((lineItem) => lineItem.includeFor.includes('provider'));
    return calculateTotalFromLineItems(providerLineItems);
};
/**
 * Calculates the total sum of lineTotals for given lineItems where `includeFor` includes `customer`
 * @param {*} lineItems
 * @returns {Money} total sum
 */
export const calculateTotalForCustomer = (lineItems) => {
    const customerLineItems = lineItems.filter((lineItem) => lineItem.includeFor.includes('customer'));
    return calculateTotalFromLineItems(customerLineItems);
};
/**
 * Constructs lineItems that can be used directly in FTW.
 * This function checks lineItem code and adds attributes like lineTotal and reversal
 * which are added in API response and some FTW components are expecting.
 *
 * This can be used when user is not authenticated and we can't call speculative API endpoints directly
 *
 * @param {Array} lineItems
 * @returns {Array} lineItems with lineTotal and reversal info
 *
 */
export const constructValidLineItems = (lineItems) => {
    const lineItemsWithTotals = lineItems.map((lineItem) => {
        const { code, quantity, percentage } = lineItem;
        if (!/^line-item\/.+/.test(code)) {
            throw new Error(`Invalid line item code: ${code}`);
        }
        // lineItems are expected to be in similar format as when they are returned from API
        // so that we can use them in e.g. BookingBreakdown component.
        // This means we need to convert quantity to Decimal and add attributes lineTotal and reversal to lineItems
        const lineTotal = calculateLineTotal(lineItem);
        return Object.assign(Object.assign({}, lineItem), { lineTotal, quantity: quantity ? new Decimal(quantity) : null, percentage: percentage ? new Decimal(percentage) : null, reversal: false });
    });
    return lineItemsWithTotals;
};
/**
 * Gets the specific line item from an array of line items by its code
 *
 * @param {Array} lineItems array of line items
 * @param {string} code code of line item
 * @retuns {object} found line items
 */
export const getLineItemByCode = (lineItems, code) => {
    return lineItems.find(lineItem => lineItem.code === code);
};
/**
 * Checks if a specific line item is included in an array of line items
 *
 * @param {Array} lineItems array of line items
 * @param {string} code code of line item
 * @retuns {boolean} true if its included, false if not
 */
export const hasLineItemCodeIncluded = (lineItems, code) => {
    return !!getLineItemByCode(lineItems, code);
};
/**
 * Gets the percentage of a specific line from an array of line items by its code
 *
 * @param {Array} lineItems array of line items
 * @param {string} code code of line item
 * @retuns {number} percentage of the line item or 0
 */
export const getLineItemPercentageByCode = (lineItems, code) => {
    var _a;
    const lineItem = getLineItemByCode(lineItems, code);
    return ((_a = lineItem === null || lineItem === void 0 ? void 0 : lineItem.percentage) === null || _a === void 0 ? void 0 : _a.toNumber()) || 0;
};
