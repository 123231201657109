import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import styled from 'styled-components/macro';
import { ColorVariant } from '../../theme';
export var HorizontalLineSpacingVariant;
(function (HorizontalLineSpacingVariant) {
    HorizontalLineSpacingVariant[HorizontalLineSpacingVariant["Medium"] = 0] = "Medium";
})(HorizontalLineSpacingVariant || (HorizontalLineSpacingVariant = {}));
const Line = styled.hr `
  border: 0;
  border-bottom: 1px solid
    ${props => props.theme.color[props.colorVariant || ColorVariant.Fog]};
  margin: 0;

  ${props => props.spacingVariant === HorizontalLineSpacingVariant.Medium &&
    `
    margin-bottom: 14px;
  `}
`;
const HorizontalLine = ({ spacingVariant, colorVariant, }) => _jsx(Line, { spacingVariant: spacingVariant, colorVariant: colorVariant });
export default HorizontalLine;
