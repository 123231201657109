var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import getDistanceBetween2Points from '@nomady/shared/utils/getDistanceBetween2Points';
import { queryListings } from '../api';
function getNearbyListings(origin, numberOfListings, queryParameters, locale) {
    var _a, _b;
    return __awaiter(this, void 0, void 0, function* () {
        const sharetribeResponse = yield queryListings(Object.assign(Object.assign({ origin, perPage: numberOfListings }, queryParameters), { locale }));
        return (_b = (_a = sharetribeResponse === null || sharetribeResponse === void 0 ? void 0 : sharetribeResponse.data) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.map(element => {
            var _a;
            return Object.assign(Object.assign({}, element), { label: element.attributes.title, geolocation: {
                    lat: element.attributes.geolocation.lat,
                    lng: element.attributes.geolocation.lng,
                    distance: getDistanceBetween2Points(element.attributes.geolocation.lat, element.attributes.geolocation.lng, origin.lat, origin.lng),
                }, price: element.attributes.price, images: ((_a = sharetribeResponse.data.included) === null || _a === void 0 ? void 0 : _a.filter(includedEntity => {
                    var _a, _b, _c, _d;
                    return includedEntity.type === 'image' &&
                        includedEntity.id.uuid ===
                            ((_d = (_c = (_b = (_a = element.relationships) === null || _a === void 0 ? void 0 : _a.images) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c[0]) === null || _d === void 0 ? void 0 : _d.id.uuid);
                })) || [] });
        });
    });
}
export default getNearbyListings;
