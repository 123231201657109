import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import { IconVariant } from '../../../styled-components/Icon/Icon';
import { LayoutRow, LayoutRowBottomVariant, LayoutRowColorVariant, LayoutRowHorizontalVariant, } from '../../../styled-components/Layout/Layout';
import ListingSlider from '../../../styled-components/ListingSlider/ListingSlider';
import SectionContentSlider from '../../../styled-components/SectionContentSlider/SectionContentSlider';
import { TextVariant } from '../../../styled-components/Text/Text';
import { ColorVariant } from '../../../theme';
const ContentWrapper = styled.div `
  padding-left: 15px;
  @media ${props => props.theme.device.desktop} {
    padding-left: 0px;
  }
`;
const NearbyListingsSection = () => {
    const intl = useIntl();
    const { nearbyListings } = useSelector((state) => state.ListingPage);
    return (_jsx(LayoutRow, Object.assign({ bottomVariant: LayoutRowBottomVariant.NoMargin, colorVariant: LayoutRowColorVariant.Alge, horizontalVariant: LayoutRowHorizontalVariant.MobileContainedLeft, enableTopMargin: true }, { children: _jsx(ContentWrapper, { children: _jsx(SectionContentSlider, Object.assign({ title: intl.formatMessage({ id: 'ListingPage.campsNearby' }), titleTextVariant: TextVariant.H30, colorVariant: ColorVariant.Pearl, buttonIconVariant: IconVariant.ArrowRight, horizontalVariant: LayoutRowHorizontalVariant.ContainedLeft, enableBottomSpacing: true }, { children: _jsx(ListingSlider, { titleComponent: "h3", listings: nearbyListings }) })) }) })));
};
export default NearbyListingsSection;
