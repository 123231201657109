import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import BackDrop from '../BackDrop/BackDrop';
import IconButtonOld, { OldIconButtonColorVariant, OldIconButtonSizeVariant, } from '../IconButtonOld/IconButtonOld';
import { IconSizeVariant, IconVariant } from '../Icon/Icon';
import { KEY_CODE_ESCAPE } from './constants';
import useDraggableModal from './hooks/useDraggableModal';
const Wrapper = styled.div `
  height: min-content;

  ${props => props.theme.priority.always};

  display: flex;
  flex-direction: column;
  align-items: center;

  position: fixed;
  transition: ${props => props.dragging ? 'none' : 'bottom 300ms ease-in-out'};
  @media ${props => props.theme.device.tabletL} {
    transition: bottom 300ms ease-in-out;
    position: relative;
    padding-top: 120px;
    padding-bottom: 100px;
    margin: -32px auto 0 auto;
    width: min-content;
  }
`;
const CloseButton = styled.div `
  margin: 10px;
  align-self: flex-start;
  @media ${props => props.theme.device.tabletL} {
    display: block;
    align-self: flex-start;
    margin-left: 15px;
    margin-bottom: 9px;
  }
`;
const DrawerHandleWrapper = styled.div `
  width: 100vw;
  height: 40px;
  position: absolute;
  top: 22px;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  @media ${props => props.theme.device.tabletL} {
    display: none;
  }
`;
const DrawerHandle = styled.div `
  width: 40px;
  height: 3px;
  background-color: ${props => props.theme.color.fog};
  border-radius: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
  position: relative;
`;
const BottomWhiteSheet = styled.div `
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100vh;
  background-color: ${props => props.theme.color.cloud};
  transition: ${props => props.dragging ? 'none' : 'bottom 300ms ease-in-out'};
  z-index: -10;

  @media ${props => props.theme.device.tabletL} {
    display: none;
  }
`;
const DraggableContainer = ({ fullTransparency, closeLabel, children, isOpen, onClose, }) => {
    const { modalRef, isDragging, offsetY, handleDragStart, handleDrag, handleDragEnd, handleClose, } = useDraggableModal(onClose, isOpen);
    const handleBodyKeyUp = (event) => {
        if (event.keyCode === KEY_CODE_ESCAPE && isOpen) {
            onClose(event);
        }
    };
    useEffect(() => {
        document.body.addEventListener('keyup', event => handleBodyKeyUp(event));
        return () => {
            document.body.removeEventListener('keyup', event => handleBodyKeyUp(event));
        };
    }, [isOpen]);
    return (_jsx(BackDrop, Object.assign({ onClick: handleClose, coversTopBar: true, fullTransparency: fullTransparency, visible: offsetY >= 0 }, { children: _jsxs(Wrapper, Object.assign({ dragging: isDragging, style: { bottom: `${offsetY !== null && offsetY !== void 0 ? offsetY : 0}px` } }, { children: [_jsxs(CloseButton, { children: [_jsx(DrawerHandleWrapper, Object.assign({ ref: modalRef, onTouchStart: handleDragStart, onTouchMove: handleDrag, onTouchEnd: handleDragEnd, onTouchCancel: handleDragEnd }, { children: _jsx(DrawerHandle, {}) })), _jsx(IconButtonOld, { iconVariant: IconVariant.Cross, onClick: handleClose, colorVariant: OldIconButtonColorVariant.Default, ariaLabel: closeLabel, iconButtonSizeVariant: OldIconButtonSizeVariant.Medium, iconSizeVariant: IconSizeVariant.ExtraSmall })] }), children, _jsx(BottomWhiteSheet, { style: { bottom: `calc(-100vh + 10px + ${offsetY !== null && offsetY !== void 0 ? offsetY : 0}px)` }, dragging: isDragging })] })) })));
};
export default DraggableContainer;
