import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components/macro';
import PoweredByGoogle from './assets/powered_by_google_on_white.png';
const PoweredByGoogleImage = styled.img `
  width: 144px;
  height: 18px;
`;
const GoogleAttribution = () => {
    const intl = useIntl();
    return (_jsx(PoweredByGoogleImage, { src: PoweredByGoogle, alt: intl.formatMessage({
            id: 'SearchBarSheet.Location.poweredByGoogle',
        }) }));
};
export default GoogleAttribution;
