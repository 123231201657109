var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components/macro';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { categories } from '@nomady/shared/marketplace-custom-config';
import Button, { ButtonColorVariant, ButtonSizeVariant, } from '../../styled-components/Button/Button';
import Input from '../../styled-components/Input/Input';
import InputMultiSelect from '../../styled-components/InputMultiSelect/InputMultiSelect';
import InputTextArea from '../../styled-components/InputTextArea/InputTextArea';
import CheckboxInput from '../../styled-components/CheckboxInput/CheckboxInput';
import { CampCategory, } from '@nomady/shared/types';
import { getListingDescriptionDefaultValues, getListingDescriptionSchema, } from '@nomady/shared/schemas/listing/description';
import SelectInput from '../../styled-components/SelectInput/SelectInput';
import config from '../../config';
import Text, { TextVariant } from '../../styled-components/Text/Text';
import campStyleIcons from '../../campStyleIcons';
import FormErrorMessage from '../../styled-components/FormErrorMessage/FormErrorMessage';
import InputRadioSelect from '../../styled-components/InputRadioSelect/InputRadioSelect';
const MIN_SEATS = 1;
const MAX_SEATS = 10;
const Form = styled.form ``;
export const FormSection = styled.div `
  margin-bottom: 60px;
`;
const FormRow = styled.div `
  margin-bottom: 10px;
`;
const EditListingDescriptionForm = ({ updateListingInProgress, updateListingError, onSubmit, onFormDirty, listing, submitButtonLabel, }) => {
    const intl = useIntl();
    const [defaultValuesLoaded, setDefaultValuesLoaded] = useState(false);
    const listingLoaded = !!(listing === null || listing === void 0 ? void 0 : listing.id);
    const defaultValues = getListingDescriptionDefaultValues(listing === null || listing === void 0 ? void 0 : listing.attributes);
    const { register, handleSubmit, control, reset, trigger, watch, setValue, getValues, formState: { errors, isDirty, isSubmitSuccessful }, } = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: zodResolver(getListingDescriptionSchema(intl)),
    });
    useEffect(() => {
        reset(defaultValues);
        setDefaultValuesLoaded(true);
    }, [listingLoaded]);
    const validateOnRender = () => {
        defaultValuesLoaded && trigger();
    };
    useEffect(validateOnRender, [trigger, defaultValuesLoaded]);
    useEffect(() => onFormDirty(isDirty), [isDirty, onFormDirty]);
    useEffect(() => {
        if (isSubmitSuccessful) {
            const values = getValues();
            reset(values);
        }
    }, [isSubmitSuccessful, getValues, reset]);
    const onSuccess = (data) => __awaiter(void 0, void 0, void 0, function* () {
        var _b;
        const isAccommodation = data.category === CampCategory.accommodation;
        const seats = isAccommodation ? 1 : data.seats;
        const availabilityPlan = {
            type: 'availability-plan/day',
            entries: [
                { dayOfWeek: 'mon', seats },
                { dayOfWeek: 'tue', seats },
                { dayOfWeek: 'wed', seats },
                { dayOfWeek: 'thu', seats },
                { dayOfWeek: 'fri', seats },
                { dayOfWeek: 'sat', seats },
                { dayOfWeek: 'sun', seats },
            ],
        };
        const params = {
            title: (_b = data.title) === null || _b === void 0 ? void 0 : _b.trim(),
            availabilityPlan,
            publicData: {
                localizedPublicData: {
                    seasons: {
                        summer: {
                            description: data.descriptionSummer,
                        },
                    },
                },
                winterCapability: data.winterCapability,
                category: data.category,
                seats,
                campStyle: Array.isArray(data.campStyle)
                    ? data.campStyle
                    : [data.campStyle],
                parkingNearby: data.parkingNearby,
                accessType: data.accessType,
                groundType: data.groundType,
                bookablePersonsPerSeat: data.bookablePersonsPerSeat,
                privateCamp: data.privateCamp,
            },
        };
        onSubmit(params);
    });
    const _a = register('category'), { ref: categoryRef, onChange: categoryOnChange } = _a, category = __rest(_a, ["ref", "onChange"]);
    const campStyle = register('campStyle');
    const seats = register('seats', {
        valueAsNumber: true,
    });
    const bookablePersonsPerSeat = register('bookablePersonsPerSeat', {
        valueAsNumber: true,
    });
    const parkingNearby = register('parkingNearby');
    const accessType = register('accessType');
    const groundType = register('groundType');
    const privateCamp = register('privateCamp');
    const title = register('title');
    const descriptionSummer = register('descriptionSummer');
    const winterCapability = register('winterCapability');
    const categoryValue = watch('category');
    const isAccommodation = categoryValue === CampCategory.accommodation;
    const seatsValue = watch('seats');
    const winterCapabilityValue = watch('winterCapability');
    return (_jsxs(Form, Object.assign({ onSubmit: handleSubmit(onSuccess) }, { children: [_jsxs(FormSection, { children: [_jsxs(FormRow, { children: [_jsx(SelectInput, Object.assign({ options: categories.map(({ key, label }) => ({
                                    label: intl.formatMessage({ id: label }),
                                    value: key,
                                })), label: intl.formatMessage({
                                    id: 'EditListingDescriptionForm.categoryLabel',
                                }), description: intl.formatMessage({
                                    id: 'EditListingDescriptionForm.categoryTooltip',
                                }), id: "category" }, category, { onChange: event => {
                                    categoryOnChange(event);
                                    setValue('campStyle', []);
                                }, inputRef: categoryRef, error: errors === null || errors === void 0 ? void 0 : errors.category })), categoryValue && !isAccommodation && (_jsx(InputMultiSelect, Object.assign({ control: control, id: "campStyle" }, campStyle, { label: intl.formatMessage({
                                    id: `EditListingDescriptionForm.campStyle.camping`,
                                }), options: config.custom.categoryCampStyles.camping.map(element => ({
                                    value: element.key,
                                    label: intl.formatMessage({ id: element.label }),
                                    icon: campStyleIcons[element.key],
                                })), error: errors === null || errors === void 0 ? void 0 : errors.campStyle }))), categoryValue && isAccommodation && (_jsx(InputRadioSelect, Object.assign({ control: control, id: "campStyle" }, campStyle, { label: intl.formatMessage({
                                    id: `EditListingDescriptionForm.campStyle.accommodation`,
                                }), description: intl.formatMessage({
                                    id: `EditListingDescriptionForm.campStyle.accommodation.tooltip`,
                                }), options: config.custom.categoryCampStyles.accommodation.map(element => ({
                                    value: element.key,
                                    label: intl.formatMessage({ id: element.label }),
                                    icon: campStyleIcons[element.key],
                                })), error: errors === null || errors === void 0 ? void 0 : errors.campStyle })))] }), categoryValue && !isAccommodation && (_jsx(FormRow, { children: _jsx(Input, Object.assign({ label: intl.formatMessage({
                                id: 'EditListingDescriptionForm.seats',
                            }), description: intl.formatMessage({
                                id: 'EditListingDescriptionForm.seatsTooltip',
                            }), id: "seats", type: "number", min: MIN_SEATS, max: MAX_SEATS, step: 1 }, seats, { inputRef: seats.ref, error: errors === null || errors === void 0 ? void 0 : errors.seats })) })), categoryValue && (_jsx(FormRow, { children: _jsx(Input, Object.assign({ label: intl.formatMessage({
                                id: `EditListingDescriptionForm.bookablePersonsPerSeat.${categoryValue}`,
                            }), id: "bookablePersonsPerSeat", type: "number", step: 1 }, bookablePersonsPerSeat, { inputRef: bookablePersonsPerSeat.ref, error: errors === null || errors === void 0 ? void 0 : errors.bookablePersonsPerSeat })) })), categoryValue && (_jsx(FormRow, { children: _jsx(CheckboxInput, Object.assign({ id: "parkingNearby" }, parkingNearby, { inputRef: parkingNearby.ref, error: errors === null || errors === void 0 ? void 0 : errors.parkingNearby, fieldLabel: intl.formatMessage({
                                id: `EditListingDescriptionForm.parkingNearby.${categoryValue}`,
                            }), label: intl.formatMessage({
                                id: 'FieldBoolean.yes',
                            }) })) })), _jsx(FormRow, { children: _jsx(SelectInput, Object.assign({ options: config.custom.accessTypes.map(({ key, label }) => ({
                                label: intl.formatMessage({ id: label }),
                                value: key,
                            })), label: intl.formatMessage({
                                id: 'EditListingDescriptionForm.accessType',
                            }), id: "accessType" }, accessType, { inputRef: accessType.ref, error: errors === null || errors === void 0 ? void 0 : errors.accessType })) }), !isAccommodation && (_jsx(FormRow, { children: _jsx(SelectInput, Object.assign({ options: config.custom.groundTypes.map(({ key, label }) => ({
                                label: intl.formatMessage({ id: label }),
                                value: key,
                            })), label: intl.formatMessage({
                                id: 'EditListingDescriptionForm.groundType',
                            }), id: "groundType" }, groundType, { inputRef: groundType.ref, error: errors === null || errors === void 0 ? void 0 : errors.groundType })) })), !isAccommodation && seatsValue === 1 && (_jsx(FormRow, { children: _jsx(CheckboxInput, Object.assign({ id: "privateCamp" }, privateCamp, { inputRef: privateCamp.ref, error: errors === null || errors === void 0 ? void 0 : errors.privateCamp, fieldLabel: intl.formatMessage({
                                id: `EditListingDescriptionForm.privateCamp`,
                            }), label: intl.formatMessage({
                                id: 'FieldBoolean.yes',
                            }), description: intl.formatMessage({
                                id: 'EditListingDescriptionForm.privateCampTooltip',
                            }) })) }))] }), _jsx(Text, Object.assign({ variant: TextVariant.H4, as: "h3" }, { children: intl.formatMessage({ id: 'EditListingDescriptionForm.description' }) })), _jsx(FormRow, { children: _jsx(Input, Object.assign({ label: intl.formatMessage({
                        id: 'EditListingDescriptionForm.title',
                    }), description: intl.formatMessage({
                        id: 'EditListingDescriptionForm.titleTooltip',
                    }), placeholder: intl.formatMessage({
                        id: `EditListingDescriptionForm.titlePlaceholder.${categoryValue || 'undefined'}`,
                    }), id: "title", type: "text" }, title, { inputRef: title.ref, error: errors === null || errors === void 0 ? void 0 : errors.title })) }), _jsx(FormRow, { children: _jsx(InputTextArea, Object.assign({ label: winterCapabilityValue
                        ? intl.formatMessage({
                            id: 'EditListingDescriptionForm.descriptionSummer',
                        })
                        : intl.formatMessage({
                            id: 'EditListingDescriptionForm.description',
                        }), description: intl.formatMessage({
                        id: 'EditListingDescriptionForm.descriptionTooltip',
                    }), placeholder: intl.formatMessage({
                        id: `EditListingDescriptionForm.descriptionPlaceholder.${categoryValue || 'undefined'}`,
                    }), id: "descriptionSummer", control: control }, descriptionSummer, { error: errors === null || errors === void 0 ? void 0 : errors.descriptionSummer })) }), _jsx(FormRow, { children: _jsx(CheckboxInput, Object.assign({ id: "winterCapability" }, winterCapability, { inputRef: winterCapability.ref, error: errors === null || errors === void 0 ? void 0 : errors.winterCapability, fieldLabel: intl.formatMessage({
                        id: `EditListingDescriptionForm.winterCapability`,
                    }), label: intl.formatMessage({
                        id: 'FieldBoolean.yes',
                    }), description: intl.formatMessage({
                        id: 'EditListingDescriptionForm.winterCapabilityTooltip',
                    }) })) }), _jsx(Button, { label: submitButtonLabel, colorVariant: ButtonColorVariant.Dark, sizeVariant: ButtonSizeVariant.Small, type: "submit", inProgress: updateListingInProgress }), updateListingError && (_jsx(FormErrorMessage, { id: "EditListingDescriptionForm.updateFailed" }))] })));
};
export default EditListingDescriptionForm;
