import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styled from 'styled-components/macro';
import Button2024, { ButtonSizeVariant, ButtonVariant, } from '../../Button2024/Button2024';
import { IconVariant } from '../../Icon/Icon';
const Foot = styled.div `
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 16px;

  padding-top: 8px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 0;

  @media ${props => props.theme.device.desktop} {
    padding-top: 30px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 40px;
  }
`;
const Wrapper = styled.div ``;
const ModalButtons = ({ leftButton, rightButton }) => {
    return (_jsxs(Foot, { children: [_jsx(Wrapper, { children: leftButton && (_jsx(Button2024, { label: leftButton.label, onClick: leftButton.onClick, variant: leftButton.isPrimary
                        ? ButtonVariant.Primary
                        : ButtonVariant.Secondary, iconOnLeft: leftButton.inProgress ? IconVariant.Spinner : undefined, disabled: leftButton.disabled, sizeVariant: ButtonSizeVariant.Small })) }), _jsx(Wrapper, { children: rightButton && (_jsx(Button2024, { label: rightButton.label, onClick: rightButton.onClick, variant: rightButton.isPrimary
                        ? ButtonVariant.Primary
                        : ButtonVariant.Secondary, iconOnRight: rightButton.inProgress ? IconVariant.Spinner : undefined, disabled: rightButton.disabled, sizeVariant: ButtonSizeVariant.Small })) })] }));
};
export default ModalButtons;
