import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import styled from 'styled-components/macro';
import { useIntl } from 'react-intl';
import MessageForm from '../MessageForm';
import SectionLayout from './SectionLayout';
const FormContainer = styled.div `
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
`;
const MessageSection = ({ formRef, hostName, transactionId, }) => {
    const intl = useIntl();
    return (_jsx(SectionLayout, Object.assign({ title: intl.formatMessage({ id: 'MessageForm.title' }) }, { children: _jsx(FormContainer, { children: _jsx(MessageForm, { transactionId: transactionId, hostName: hostName, formRef: formRef }) }) })));
};
export default MessageSection;
