import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import { NamedLink } from '../../../components';
import { getMarketplaceEntities } from '../../../ducks/marketplaceData.duck';
import Avatar, { SizeVariant } from '../../../styled-components/Avatar/Avatar';
import { IconVariant } from '../../../styled-components/Icon/Icon';
import Panel from '../../../styled-components/Panel';
import Text, { TextVariant } from '../../../styled-components/Text/Text';
import TranslatedBy from '../../../styled-components/TranslatedBy/TranslatedBy';
import UsersLanguageSkills from '../../../styled-components/UsersLanguageSkills/UsersLanguageSkills';
import { LayoutRow, LayoutRowBottomVariant, LayoutRowHorizontalVariant, } from '../../../styled-components/Layout/Layout';
import { ColorVariant } from '../../../theme';
const HostSectionWrapper = styled.div `
  background-color: ${props => props.theme.color.pearl};
  height: auto;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 15px;
  position: relative;
  ${props => props.theme.priority.zero};
`;
const ContentWrapper = styled.div `
  padding-bottom: 40px;
  @media ${props => props.theme.device.desktop} {
    display: grid;
    grid-template-columns: 1fr 2fr;
  }
`;
const AvatarWrapper = styled.div `
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 30px;
  position: relative;
  ${props => props.theme.priority.background};
  @media ${props => props.theme.device.desktop} {
    justify-content: flex-start;
  }
`;
const UserName = styled.div `
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
const Description = styled.div `
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  gap: 14px;
  @media ${props => props.theme.device.desktop} {
  }
`;
const TranslatedByWrapper = styled.div ``;
const IntroWrap = styled.div `
  @media ${props => props.theme.device.desktop} {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;
const HostProfileLink = styled(NamedLink) `
  background-color: ${props => props.theme.color.pearl};
  display: block;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
`;
const BIO_DIGGITS_TO_SHOW = 130;
const HostSection = () => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    const intl = useIntl();
    const state = useSelector((state) => state);
    const { provider, translationActive } = state.ListingPage;
    if (!provider) {
        return null;
    }
    const { originalLocale, translatedBy, languageSkills } = ((_b = (_a = provider.attributes) === null || _a === void 0 ? void 0 : _a.profile) === null || _b === void 0 ? void 0 : _b.publicData) || {};
    let profileImage;
    if ((_e = (_d = (_c = provider === null || provider === void 0 ? void 0 : provider.relationships) === null || _c === void 0 ? void 0 : _c.profileImage) === null || _d === void 0 ? void 0 : _d.data) === null || _e === void 0 ? void 0 : _e.id) {
        profileImage = getMarketplaceEntities(state, [
            provider.relationships.profileImage.data,
        ]);
    }
    const { biography } = ((_h = (_g = (_f = provider === null || provider === void 0 ? void 0 : provider.attributes) === null || _f === void 0 ? void 0 : _f.profile) === null || _g === void 0 ? void 0 : _g.publicData) === null || _h === void 0 ? void 0 : _h.localizedPublicData) || {};
    return (_jsxs(LayoutRow, Object.assign({ horizontalVariant: LayoutRowHorizontalVariant.MobileUncontained, bottomVariant: LayoutRowBottomVariant.SmallMargin }, { children: [_jsxs(HostSectionWrapper, { children: [_jsx(Text, Object.assign({ "data-testid": "HostSection_Title", as: "h2", variant: TextVariant.H30, colorVariant: ColorVariant.Cave }, { children: intl.formatMessage({ id: 'ListingPage.host' }) })), _jsxs(ContentWrapper, { children: [_jsx(IntroWrap, { children: _jsx(AvatarWrapper, { children: _jsx(Avatar, { source: profileImage === null || profileImage === void 0 ? void 0 : profileImage[0].attributes.variants['square-small2x'].url, sizeVariant: SizeVariant.XL }) }) }), _jsxs(Description, { children: [_jsx(UserName, { children: _jsx(Text, Object.assign({ variant: TextVariant.H20, as: "h3" }, { children: provider.attributes.profile.displayName ||
                                                intl.formatMessage({
                                                    id: 'ProfilePage.userNotFound',
                                                }) })) }), languageSkills && 0 < languageSkills.length && (_jsx(UsersLanguageSkills, { languages: languageSkills })), (biography === null || biography === void 0 ? void 0 : biography.length) && (_jsxs(Text, Object.assign({ variant: TextVariant.B20, as: "p" }, { children: [biography === null || biography === void 0 ? void 0 : biography.slice(0, BIO_DIGGITS_TO_SHOW), (biography === null || biography === void 0 ? void 0 : biography.length) > BIO_DIGGITS_TO_SHOW && '...'] }))), translationActive && originalLocale && translatedBy && (_jsx(TranslatedByWrapper, { children: _jsx(TranslatedBy, { translatedBy: translatedBy, originalLocale: originalLocale }) }))] })] })] }), _jsx(HostProfileLink, Object.assign({ name: "ProfilePage", params: { id: (_j = provider === null || provider === void 0 ? void 0 : provider.id) === null || _j === void 0 ? void 0 : _j.uuid } }, { children: _jsx(Panel, Object.assign({ iconVariant: IconVariant.Eye }, { children: _jsx(Text, Object.assign({ "data-testid": "HostSection_ProfileLinkText", variant: TextVariant.H51 }, { children: intl.formatMessage({ id: 'ListingPage.toHostProfile' }) })) })) }))] })));
};
export default HostSection;
