import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import Text, { TextVariant } from '../../../styled-components/Text/Text';
import { ColorVariant } from '../../../theme';
import styled from 'styled-components/macro';
import { useSelector } from 'react-redux';
import { listingsSelector, selectedListingUUIDSelector, } from '../CalendarSlice';
import AvailabilityDetailsForAllListingsWeekdayModal from './AvailabilityDetailsForAllListingsWeekdayModal';
import AvailabilityDetailsForSingleListingWeekdayModal from './AvailabilityDetailsForSingleListingWeekdayModal';
import { ALL_LISTINGS_KEY } from '../constants';
const Container = styled.div `
  position: relative;
  width: 100%;
  cursor: pointer;
  padding: 8px 4px;
  display: flex;
  flex-direction: column;
  gap: 4px;

  &:hover {
    background-color: ${props => props.theme.color.lagoon};
  }
`;
export const CalendarComponentMonthHeader = ({ label, date, }) => {
    const listings = useSelector(listingsSelector);
    const selectedListingUUID = useSelector(selectedListingUUIDSelector);
    const selectedListing = selectedListingUUID !== ALL_LISTINGS_KEY && listings
        ? listings.find(listing => listing.id.uuid === selectedListingUUID.uuid)
        : undefined;
    const [isModalOpen, setIsModalOpen] = useState(false);
    return (_jsxs(Container, Object.assign({ onClick: () => setIsModalOpen(true) }, { children: [_jsx(Text, Object.assign({ variant: TextVariant.L2SB, colorVariant: ColorVariant.Cave }, { children: label })), selectedListing === undefined &&
                selectedListingUUID === ALL_LISTINGS_KEY && (_jsx(AvailabilityDetailsForAllListingsWeekdayModal, { open: isModalOpen, onClose: () => setIsModalOpen(false), date: date })), selectedListing !== undefined && (_jsx(AvailabilityDetailsForSingleListingWeekdayModal, { open: isModalOpen, onClose: () => setIsModalOpen(false), date: date, selectedListing: selectedListing }))] })));
};
