import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components/macro';
import { ColorVariant } from '../../theme';
import Text, { TextVariant } from '../Text/Text';
const TotalDivider = styled.div `
  border-bottom: 2px solid ${props => props.theme.color.tar};
  margin: 8px 0px 8px;
`;
const TotalContainer = styled.div `
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
`;
const TotalCurrency = styled(Text) `
  font-size: 12px;
  font-weight: 500;
`;
const ItemText = styled(Text) `
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const Total = ({ currency, totalPrice, labelId = 'Total.label', }) => {
    const intl = useIntl();
    return (_jsxs(_Fragment, { children: [_jsx(TotalDivider, {}), _jsxs(TotalContainer, { children: [_jsx(ItemText, Object.assign({ colorVariant: ColorVariant.Tar, variant: TextVariant.B30 }, { children: intl.formatMessage({ id: labelId }) })), _jsxs(ItemText, Object.assign({ colorVariant: ColorVariant.Tar, variant: TextVariant.H50 }, { children: [_jsx(TotalCurrency, { children: currency }), " ", totalPrice.toFixed(2)] }))] })] }));
};
export default Total;
