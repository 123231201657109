import { LINE_ITEM_GIFT_CARD_DEDUCTION_1, LINE_ITEM_GIFT_CARD_DEDUCTION_2, LINE_ITEM_GIFT_CARD_DEDUCTION_3, LINE_ITEM_GIFT_CARD_TOTAL_TRANSACTION_VALUE, } from '../../utils/lineItemTypes';
import { calculateTotalForCustomer } from '../../utils/lineItemHelpers';
const getTotalTransactionValue = (lineItems) => {
    const lineItemsWithoutGiftCards = lineItems.filter(lineItem => lineItem.code !== LINE_ITEM_GIFT_CARD_DEDUCTION_1 &&
        lineItem.code !== LINE_ITEM_GIFT_CARD_DEDUCTION_2 &&
        lineItem.code !== LINE_ITEM_GIFT_CARD_DEDUCTION_3 &&
        lineItem.code !== LINE_ITEM_GIFT_CARD_TOTAL_TRANSACTION_VALUE);
    const totalTransactionValue = calculateTotalForCustomer(lineItemsWithoutGiftCards);
    return totalTransactionValue;
};
export default getTotalTransactionValue;
