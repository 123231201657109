import { types as sdkTypes } from '@nomady/shared/utils/sdkLoader';
const { UUID } = sdkTypes;
const getOrderParams = (params) => {
    const { bookingStart, bookingEnd, lineItemCalculator, giftCards, customerCommissionDiscountCode, listing, } = params;
    const listingId = (listing === null || listing === void 0 ? void 0 : listing.id) || new UUID('no-listing-id');
    const lineItems = lineItemCalculator.getLineItems();
    const orderParams = {
        listingId,
        bookingStart,
        bookingEnd,
        lineItems,
        protectedData: Object.assign(Object.assign({}, lineItemCalculator.getTransactionProtectedData()), { customerCommissionDiscountCode }),
        payinTotal: lineItemCalculator.getSharetribePayin(),
        payoutTotal: lineItemCalculator.getSharetribePayout(),
    };
    if (giftCards) {
        orderParams.protectedData.giftCards = giftCards;
    }
    if (giftCards) {
        orderParams.protectedData.giftCards = giftCards;
    }
    return orderParams;
};
export default getOrderParams;
