var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useRef, useEffect } from 'react';
import { htmlToText } from 'html-to-text';
import { useController } from 'react-hook-form';
import styled from 'styled-components/macro';
const TextBoxSpan = styled.span `
  display: block;
  width: 100%;
  overflow: hidden;
  resize: vertical;
  min-height: 140px;
  border: none;
  border-radius: 10px;
  padding: 15px;
  margin: 0;
  background-color: ${props => props.theme.color.pearl};
  color: ${props => props.theme.color.cave};
  ${props => props.theme.typeset.H52}
  &:empty::before {
    content: ${props => props.placeholder ? `'${props.placeholder}'` : 'none'};
    color: ${props => props.theme.color.stone};
  }
`;
const TextArea = (_a) => {
    var { placeholder, dataTestId, name, control, defaultValue } = _a, props = __rest(_a, ["placeholder", "dataTestId", "name", "control", "defaultValue"]);
    const { field } = useController({
        name,
        control,
        defaultValue,
    });
    const spanRef = useRef(null);
    useEffect(() => {
        if (spanRef.current) {
            spanRef.current.innerText = defaultValue || field.value || '';
        }
    }, []);
    return (_jsx(TextBoxSpan, Object.assign({ contentEditable: true, role: "textbox", placeholder: placeholder, ref: spanRef, "data-testid": dataTestId, onBlur: field.onBlur, onInput: (event) => {
            if (field) {
                const htmlContent = event.target.innerHTML;
                const textContent = htmlToText(htmlContent, {
                    wordwrap: false,
                    preserveNewlines: true,
                });
                field.onChange(textContent);
            }
            else {
                console.warn('Field is not defined');
            }
        } }, props)));
};
export default TextArea;
