import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components/macro';
import { nightsBetween } from '@nomady/shared/utils/dates';
import { CampCategory } from '@nomady/shared/types';
import Button, { ButtonColorVariant, } from '../../../../styled-components/Button/Button';
import Text, { TextVariant } from '../../../../styled-components/Text/Text';
import CheckoutPanels from '../../../../styled-components/CheckoutPanels/CheckoutPanels';
import PriceInformationBox from '../../../../styled-components/PriceInformationBox/PriceInformationBox';
const Wrapper = styled.div `
  position: sticky;
  width: 100%;
  top: 15%;
  z-index: ${props => props.theme.priority.always};
  display: flex;
  flex-direction: column;
  gap: 6px;
`;
const ButtonWithMessageWrapper = styled.div `
  margin: 10px auto 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
  width: fit-content;
`;
const DesktopBookingPanel = ({ listing, bookingStart, bookingEnd, seatQuantity, adultQuantity, teenQuantity, infantQuantity, dogQuantity, childQuantity, breakdownOpen, setBreakdownOpen, handleClickCheckout, bookingButtonLabel, disabled, totalSeats, availableSeats, extras, }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const intl = useIntl();
    const totalNights = bookingStart && bookingEnd ? nightsBetween(bookingStart, bookingEnd) : 1;
    const isAccommodation = ((_b = (_a = listing === null || listing === void 0 ? void 0 : listing.attributes) === null || _a === void 0 ? void 0 : _a.publicData) === null || _b === void 0 ? void 0 : _b.category) === CampCategory.accommodation;
    return (_jsxs(Wrapper, Object.assign({ "data-testid": "DesktopBookingPanel" }, { children: [_jsx(PriceInformationBox, { listing: listing, breakdownOpen: breakdownOpen, setBreakdownOpen: setBreakdownOpen, bookingStart: bookingStart, bookingEnd: bookingEnd, seatQuantity: seatQuantity, adultQuantity: adultQuantity, childQuantity: childQuantity || 0, teenQuantity: teenQuantity || 0, infantQuantity: infantQuantity || 0, dogQuantity: dogQuantity || 0, nightsQuantity: totalNights, extras: extras, role: "customer" }), _jsx(CheckoutPanels, { seatQuantity: seatQuantity, adultQuantity: adultQuantity, teenQuantity: teenQuantity, childQuantity: childQuantity, infantQuantity: infantQuantity, dogQuantity: dogQuantity, bookingStart: bookingStart, bookingEnd: bookingEnd, totalSeats: totalSeats, availableSeats: availableSeats, showSeatSelection: !isAccommodation, extras: extras, showExtras: !!(((_e = (_d = (_c = listing === null || listing === void 0 ? void 0 : listing.attributes) === null || _c === void 0 ? void 0 : _c.publicData) === null || _d === void 0 ? void 0 : _d.pricing) === null || _e === void 0 ? void 0 : _e.firewood) ||
                    ((_h = (_g = (_f = listing === null || listing === void 0 ? void 0 : listing.attributes) === null || _f === void 0 ? void 0 : _f.publicData) === null || _g === void 0 ? void 0 : _g.pricing) === null || _h === void 0 ? void 0 : _h.powerSupply)) }), _jsxs(ButtonWithMessageWrapper, { children: [_jsx(Button, { dataTestId: "DesktopBookingPanel_ContinueButton", colorVariant: ButtonColorVariant.Forest, label: bookingButtonLabel, onClick: handleClickCheckout, disabled: disabled }), _jsx(Text, Object.assign({ variant: TextVariant.B50 }, { children: intl.formatMessage({ id: 'ListingPage.noChargeOnCreditCard' }) }))] })] })));
};
export default DesktopBookingPanel;
