export const LINE_ITEM_NIGHT = 'line-item/night';
export const LINE_ITEM_DAY = 'line-item/day';
export const LINE_ITEM_UNITS = 'line-item/units';
export const LINE_ITEM_SEATS = 'line-item/seats';
export const LINE_ITEM_ADULTS = 'line-item/adults';
/**
 * @deprecated replaced by LINE_ITEM_ADULTS
 */
export const LINE_ITEM_EXTRA_ADULTS = 'line-item/extra-adults';
/**
 * @deprecated replaced by LINE_ITEM_ADULTS
 */
export const LINE_ITEM_MINUS_ADULTS = 'line-item/minus-adults';
export const LINE_ITEM_ADULT_TAXES = 'line-item/adult-taxes';
/**
 * @deprecated replaced by LINE_ITEM_TEENS
 */
export const LINE_ITEM_EXTRA_YOUTHS = 'line-item/extra-youths';
export const LINE_ITEM_TEENS = 'line-item/teens';
/**
 * @deprecated replaced by LINE_ITEM_CHILD
 */
export const LINE_ITEM_EXTRA_CHILDREN = 'line-item/extra-children';
export const LINE_ITEM_CHILDREN = 'line-item/children';
/**
 * @deprecated renamed to LINE_ITEM_TEEN_TAXES
 */
export const LINE_ITEM_YOUTH_TAX = 'line-item/youth-taxes';
export const LINE_ITEM_TEEN_TAXES = 'line-item/teen-taxes';
export const LINE_ITEM_CHILD_TAXES = 'line-item/child-taxes';
export const LINE_ITEM_INFANTS = 'line-item/infants';
export const LINE_ITEM_DOGS = 'line-item/dogs';
export const LINE_ITEM_CLEANING_FEE = 'line-item/cleaning-fee';
export const LINE_ITEM_EXTRAS_POWER_SUPPLY = 'line-item/extras-power-supply';
export const LINE_ITEM_EXTRAS_FIREWOOD = 'line-item/extras-firewood';
export const LINE_ITEM_CUSTOMER_COMMISSION = 'line-item/customer-commission';
export const LINE_ITEM_PROVIDER_COMMISSION = 'line-item/provider-commission';
export const LINE_ITEM_GIFT_CARD_DEDUCTION_1 = 'line-item/gift-card-deduction-1';
export const LINE_ITEM_GIFT_CARD_DEDUCTION_2 = 'line-item/gift-card-deduction-2';
export const LINE_ITEM_GIFT_CARD_DEDUCTION_3 = 'line-item/gift-card-deduction-3';
export const LINE_ITEM_GIFT_CARD_PROVIDER_PAYOUT = 'line-item/gift-card-provider-payout';
export const LINE_ITEM_GIFT_CARD_TOTAL_TRANSACTION_VALUE = 'line-item/gift-card-total-transaction-value';
export const LINE_ITEM_PROVIDER_PAYOUT = 'line-item/provider-payout';
export const LINE_ITEM_CUSTOMER_COMMISSION_DISCOUNT = 'line-item/customer-commission-discount';
export const LINE_ITEMS = [
    LINE_ITEM_NIGHT,
    LINE_ITEM_DAY,
    LINE_ITEM_UNITS,
    LINE_ITEM_SEATS,
    LINE_ITEM_ADULTS,
    LINE_ITEM_EXTRA_ADULTS,
    LINE_ITEM_MINUS_ADULTS,
    LINE_ITEM_ADULT_TAXES,
    LINE_ITEM_TEENS,
    LINE_ITEM_EXTRA_YOUTHS,
    LINE_ITEM_TEEN_TAXES,
    LINE_ITEM_YOUTH_TAX,
    LINE_ITEM_CHILDREN,
    LINE_ITEM_EXTRA_CHILDREN,
    LINE_ITEM_CHILD_TAXES,
    LINE_ITEM_INFANTS,
    LINE_ITEM_DOGS,
    LINE_ITEM_CLEANING_FEE,
    LINE_ITEM_EXTRAS_POWER_SUPPLY,
    LINE_ITEM_EXTRAS_FIREWOOD,
    LINE_ITEM_CUSTOMER_COMMISSION,
    LINE_ITEM_PROVIDER_COMMISSION,
    LINE_ITEM_GIFT_CARD_DEDUCTION_1,
    LINE_ITEM_GIFT_CARD_DEDUCTION_2,
    LINE_ITEM_GIFT_CARD_DEDUCTION_3,
    LINE_ITEM_GIFT_CARD_PROVIDER_PAYOUT,
    LINE_ITEM_GIFT_CARD_TOTAL_TRANSACTION_VALUE,
    LINE_ITEM_PROVIDER_PAYOUT,
    LINE_ITEM_CUSTOMER_COMMISSION_DISCOUNT,
];
export const TOURIST_TAX_LINE_ITEMS = [
    LINE_ITEM_ADULT_TAXES,
    LINE_ITEM_TEEN_TAXES,
    LINE_ITEM_CHILD_TAXES,
];
export const TOURIST_TAX_AND_CLEANING_FEE_LINE_ITEMS = [
    ...TOURIST_TAX_LINE_ITEMS,
    LINE_ITEM_CLEANING_FEE,
];
export const EXTRA_GUESTS = [
    LINE_ITEM_ADULTS,
    LINE_ITEM_TEENS,
    LINE_ITEM_CHILDREN,
    LINE_ITEM_INFANTS,
];
export const GIFT_CARD_DEDUCTION_LINE_ITEMS = [
    LINE_ITEM_GIFT_CARD_DEDUCTION_1,
    LINE_ITEM_GIFT_CARD_DEDUCTION_2,
    LINE_ITEM_GIFT_CARD_DEDUCTION_3,
];
