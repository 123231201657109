import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import styled, { css } from 'styled-components/macro';
import Publication from '../Publication/Publication';
const Wrapper = styled.section `
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  @media ${props => props.theme.device.tabletL} {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
`;
const PublicationWrapper = styled.div `
  width: 100%;

  ${props => props.isLast
    ? css `
          margin-bottom: initial;
        `
    : css `
          margin-bottom: 60px;

          @media ${props.theme.device.tablet} {
            margin-bottom: 100px;
          }
        `}
`;
const Publications = ({ publications, }) => (_jsx(Wrapper, { children: publications === null || publications === void 0 ? void 0 : publications.map((publication, index) => {
        return (_jsx(PublicationWrapper, Object.assign({ isLast: publications.length === index + 1 }, { children: _jsx(Publication, { image: publication.image, link: publication.link, download: publication.download, title: publication.title, description: publication.description, date: publication.date }) }), publication.id));
    }) }));
export default Publications;
