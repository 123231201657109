/**
 * Export reducers from ducks modules of different containers (i.e. default export)
 * We are following Ducks module proposition:
 * https://github.com/erikras/ducks-modular-redux
 */
import CheckoutPage from './CheckoutPage/CheckoutPageSlice';
import ContactDetailsPage from './ContactDetailsPage/ContactDetailsPageSlice';
import CmsContentPage from './CmsContentPage/CmsContentPageSlice';
import BookingsPage from './BookingsPage/BookingsPageSlice';
import JourneysPage from './JourneysPage/JourneysPageSlice';
import EditListingPage from './EditListingPage/EditListingPageSlice';
import ListingPage from './ListingPage/ListingPageSlice';
import LanguageSettingsPage from './LanguageSettingsPage/LanguageSettingsPage.duck';
import ManageListingsPage from './ManageListingsPage/ManageListingsPage.duck';
import PasswordChangePage from './PasswordChangePage/PasswordChangePage.duck';
import PasswordRecoveryPage from './PasswordRecoveryPage/PasswordRecoveryPageSlice';
import PasswordResetPage from './PasswordResetPage/PasswordResetPageSlice';
import ProfilePage from './ProfilePage/ProfilePage.duck';
import ProfileSettingsPage from './ProfileSettingsPage/ProfileSettingsPageSlice';
import SearchPage from './SearchPage/SearchPageSlice';
import TransactionPage from './TransactionPage/TransactionPage.duck';
import TouristTaxReportsPage from '../containers/TouristTaxReportsPage/TouristTaxReportsPageSlice';
import CalendarPage from './CalendarPage/CalendarSlice';
import MapboxSearch from '../slices/mapboxSearchSlice';
export const ContainerReducers = {
    CheckoutPage,
    ContactDetailsPage,
    CmsContentPage,
    BookingsPage,
    JourneysPage,
    EditListingPage,
    ListingPage,
    LanguageSettingsPage,
    ManageListingsPage,
    PasswordChangePage,
    PasswordRecoveryPage,
    PasswordResetPage,
    ProfilePage,
    ProfileSettingsPage,
    SearchPage,
    TransactionPage,
    TouristTaxReportsPage,
    CalendarPage,
    MapboxSearch,
};
