import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styled from 'styled-components/macro';
import Markdown from 'react-showdown';
import Text, { TextVariant } from '../Text/Text';
import Button, { ButtonColorVariant, ButtonSizeVariant, } from '../Button/Button';
import { getSrcSetAndSizeOfImg } from '../../util/imageHelper';
export var ImageFlagVariant;
(function (ImageFlagVariant) {
    ImageFlagVariant["Default"] = "Default";
    ImageFlagVariant["Reversed"] = "Reversed";
})(ImageFlagVariant || (ImageFlagVariant = {}));
const Wrapper = styled.section `
  display: flex;
  flex-direction: column;

  @media ${props => props.theme.device.tabletL} {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    ${props => props.variant === ImageFlagVariant.Reversed &&
    `flex-direction: row-reverse;`}
  }
`;
const Image = styled.img `
  width: 100%;
  margin-bottom: 60px;

  @media ${props => props.theme.device.tablet} {
    margin-bottom: 100px;
  }

  @media ${props => props.theme.device.tabletL} {
    width: 330px;
    margin-bottom: initial;
  }

  @media ${props => props.theme.device.desktop} {
    width: 450px;
  }

  @media ${props => props.theme.device.desktopL} {
    width: 445px;
  }
`;
const Content = styled.div `
  display: flex;
  flex-direction: column;
  @media ${props => props.theme.device.tabletL} {
    width: 330px;
  }

  @media ${props => props.theme.device.desktop} {
    width: 450px;
  }

  @media ${props => props.theme.device.desktopL} {
    width: 445px;
  }
`;
const Title = styled(Text) `
  margin-bottom: 20px;
  margin-top: 0px;

  @media ${props => props.theme.device.tablet} {
    margin-bottom: 50px;
    margin-top: 10px;
  }
`;
const ButtonWrapper = styled.div `
  display: flex;
  flex-direction: column;

  @media ${props => props.theme.device.tablet} {
    flex-direction: row;
    width: 100%;
  }

  @media ${props => props.theme.device.tabletL} {
    flex-direction: column;
  }

  @media ${props => props.theme.device.desktop} {
    flex-direction: row;
    width: 100%;
  }
`;
const FirstButtonWrapper = styled.div `
  margin-bottom: 20px;

  @media ${props => props.theme.device.tablet} {
    margin-right: 20px;
    margin-bottom: initial;
  }

  @media ${props => props.theme.device.tabletL} {
    margin-right: initial;
    margin-bottom: 20px;
  }

  @media ${props => props.theme.device.desktop} {
    margin-right: 20px;
    margin-bottom: initial;
  }
`;
const Logo = styled.img `
  width: 132px;
  margin: 0 auto 50px;
`;
const Flag = styled(Text) `
  margin-bottom: 50px;
`;
const ImageFlag = ({ image, imageAlt, variant, buttonSizeVariant = ButtonSizeVariant.Default, buttonColorVariant = ButtonColorVariant.Default, title, logo, text, buttonLink, buttonRoute, buttonText, secondaryButtonLink, secondaryButtonRoute, secondaryButtonText, }) => {
    var _a, _b, _c, _d, _e;
    const { srcSet, sizes } = getSrcSetAndSizeOfImg((_a = image.data) === null || _a === void 0 ? void 0 : _a.attributes.formats);
    return (_jsxs(Wrapper, Object.assign({ variant: variant }, { children: [_jsx(Image, { srcSet: srcSet, sizes: sizes, src: (_b = image.data) === null || _b === void 0 ? void 0 : _b.attributes.url, alt: ((_c = image.data) === null || _c === void 0 ? void 0 : _c.attributes.alternativeText) || imageAlt, loading: "lazy" }), _jsxs(Content, { children: [title ? (_jsx(Title, Object.assign({ variant: TextVariant.H3, as: "h2" }, { children: title }))) : null, (logo === null || logo === void 0 ? void 0 : logo.data) ? (_jsx(Logo, { src: (_d = logo.data) === null || _d === void 0 ? void 0 : _d.attributes.url, alt: (_e = logo.data) === null || _e === void 0 ? void 0 : _e.attributes.alternativeText, loading: "lazy" })) : null, _jsx(Flag, Object.assign({ variant: TextVariant.H8 }, { children: _jsx(Markdown, { markdown: text }) })), buttonLink || buttonRoute ? (_jsxs(ButtonWrapper, { children: [_jsx(FirstButtonWrapper, { children: _jsx(Button, { externalLink: buttonLink, targetRoute: buttonRoute, label: buttonText, colorVariant: buttonColorVariant, sizeVariant: buttonSizeVariant }) }), (secondaryButtonLink || secondaryButtonRoute) && (_jsx(Button, { externalLink: secondaryButtonLink, targetRoute: secondaryButtonRoute, label: secondaryButtonText, colorVariant: buttonColorVariant, sizeVariant: buttonSizeVariant }))] })) : null] })] })));
};
export default ImageFlag;
