import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styled from 'styled-components/macro';
import { ReactComponent as NewTabIcon } from './assets/new-tab.svg';
import { ReactComponent as DownloadIcon } from './assets/download.svg';
import { ReactComponent as DirectionLeft } from './assets/direction-left.svg';
import { ReactComponent as PlusIcon } from './assets/plus.svg';
import { ReactComponent as PlusThinIcon } from './assets/plus-thin.svg';
import { ReactComponent as MinusIcon } from './assets/minus.svg';
import { ReactComponent as ArrowLeftIcon } from './assets/arrow-left.svg';
import { ReactComponent as ArrowRightIcon } from './assets/arrow-right.svg';
import { ReactComponent as ArrowDownIcon } from './assets/arrow-down.svg';
import { ReactComponent as ArrowUpIcon } from './assets/arrow-up.svg';
import { ReactComponent as HamburgerIcon } from './assets/hamburger.svg';
import { ReactComponent as UserIcon } from './assets/user.svg';
import { ReactComponent as UserAddIcon } from './assets/user-add.svg';
import { ReactComponent as LoginIcon } from './assets/login.svg';
import { ReactComponent as LogoutIcon } from './assets/logout.svg';
import { ReactComponent as GiftCardIcon } from './assets/gift-card.svg';
import { ReactComponent as TentIcon } from './assets/tent.svg';
import { ReactComponent as PostcardIcon } from './assets/postcard.svg';
import { ReactComponent as MagnifierIcon } from './assets/magnifier.svg';
import { ReactComponent as SimplePinIcon } from './assets/simple-pin.svg';
import { ReactComponent as PinIcon } from './assets/pin.svg';
import { ReactComponent as FullscreenIcon } from './assets/fullscreen.svg';
import { ReactComponent as MinimizeIcon } from './assets/minimize.svg';
import { ReactComponent as HeartIcon } from './assets/heart.svg';
import { ReactComponent as CalendarIcon } from './assets/calendar.svg';
import { ReactComponent as SnowflakeIcon } from './assets/snowflake.svg';
import { ReactComponent as VanIcon } from './assets/van.svg';
import { ReactComponent as DogIcon } from './assets/dog.svg';
import { ReactComponent as CompassIcon } from './assets/compass.svg';
import { ReactComponent as RevertIcon } from './assets/revert.svg';
import { ReactComponent as CrossIcon } from './assets/cross.svg';
import { ReactComponent as BubbleCheckmarkIcon } from './assets/bubble-checkmark.svg';
import { ReactComponent as BubbleFlashIcon } from './assets/bubble-flash.svg';
import { ReactComponent as BubbleSnowflakeIcon } from './assets/bubble-snowflake.svg';
import { ReactComponent as BubbleFacebookIcon } from './assets/bubble-facebook.svg';
import { ReactComponent as BubbleInstagramIcon } from './assets/bubble-instagram.svg';
import { ReactComponent as BubbleLetterIcon } from './assets/bubble-letter.svg';
import { ReactComponent as BubbleLinkedInIcon } from './assets/bubble-linkedin.svg';
import { ReactComponent as BubbleSmileIcon } from './assets/bubble-smile.svg';
import { ReactComponent as MotorhomeIcon } from './assets/motorhome.svg';
import { ReactComponent as MotorhomePlusIcon } from './assets/motorhome-plus.svg';
import { ReactComponent as CaravanIcon } from './assets/caravan.svg';
import { ReactComponent as HutIcon } from './assets/hut.svg';
import { ReactComponent as BackpackIcon } from './assets/backpack.svg';
import { ReactComponent as GlobeIcon } from './assets/globe.svg';
import { ReactComponent as GarbageIcon } from './assets/garbage.svg';
import { ReactComponent as BubbleInfo } from './assets/bubble-info.svg';
import { ReactComponent as Checkmark } from './assets/checkmark.svg';
import { ReactComponent as PenIcon } from './assets/pen.svg';
import { ReactComponent as Star } from './assets/star.svg';
import { ReactComponent as Flash } from './assets/flash.svg';
import { ReactComponent as Knobs } from './assets/knobs.svg';
import { ReactComponent as Reset } from './assets/reset.svg';
import { ReactComponent as Rainbow } from './assets/rainbow.svg';
import { ReactComponent as Route } from './assets/route.svg';
import { ReactComponent as RooftopTent } from './assets/rooftop-tent.svg';
import { ReactComponent as Umbrella } from './assets/umbrella.svg';
import { ReactComponent as Campfire } from './assets/campfire.svg';
import { ReactComponent as Boot } from './assets/boot.svg';
import { ReactComponent as Coins } from './assets/coins.svg';
import { ReactComponent as Tap } from './assets/tap.svg';
import { ReactComponent as Mountains } from './assets/mountains.svg';
import { ReactComponent as CamperOnGround } from './assets/camper-on-ground.svg';
import { ReactComponent as Drop } from './assets/drop.svg';
import { ReactComponent as Table } from './assets/table.svg';
import { ReactComponent as Shop } from './assets/shop.svg';
import { ReactComponent as SortAsc } from './assets/sort-asc.svg';
import { ReactComponent as SortDesc } from './assets/sort-desc.svg';
import { ReactComponent as DoNotDisturbCard } from './assets/do-not-disturb-card.svg';
import { ReactComponent as Map } from './assets/map.svg';
import { ReactComponent as List } from './assets/list.svg';
import { ReactComponent as ShineIcon } from './assets/shine.svg';
import { ReactComponent as DartIcon } from './assets/dart.svg';
import { ReactComponent as FlagIcon } from './assets/flag.svg';
import { ReactComponent as UploadIcon } from './assets/upload.svg';
import { ReactComponent as TranslationActive } from './assets/translation-active.svg';
import { ReactComponent as TranslationInactive } from './assets/translation-inactive.svg';
import { ReactComponent as PhoneIcon } from './assets/phone.svg';
import { ReactComponent as Speaking } from './assets/speaking.svg';
import { ReactComponent as ToiletPaper } from './assets/toilet-paper.svg';
import { ReactComponent as Electricity } from './assets/electricity.svg';
import { ReactComponent as Shower } from './assets/shower.svg';
import { ReactComponent as Shade } from './assets/shade.svg';
import { ReactComponent as HorseToy } from './assets/horse-toy.svg';
import { ReactComponent as DirectionRight } from './assets/direction-right.svg';
import { ReactComponent as AT } from './assets/countries/AT.svg';
import { ReactComponent as IT } from './assets/countries/IT.svg';
import { ReactComponent as CH } from './assets/countries/CH.svg';
import { ReactComponent as DE } from './assets/countries/DE.svg';
import { ReactComponent as FR } from './assets/countries/FR.svg';
import { ReactComponent as Picture } from './assets/picture.svg';
import { ReactComponent as Shelter } from './assets/shelter.svg';
import { ReactComponent as Sledge } from './assets/sledge.svg';
import { ReactComponent as Tipi } from './assets/tipi.svg';
import { ReactComponent as Hand } from './assets/hand.svg';
import { ReactComponent as Lightbulb } from './assets/lightbulb.svg';
import { ReactComponent as PaperScroll } from './assets/paper-scroll.svg';
import { ReactComponent as Sign } from './assets/sign.svg';
import { ReactComponent as Moon } from './assets/moon.svg';
import { ReactComponent as MoonOutlined } from './assets/moon-outlined.svg';
import { ReactComponent as Clock } from './assets/clock.svg';
import { ReactComponent as CircledCross } from './assets/circledCross.svg';
import { ReactComponent as Eye } from './assets/eye.svg';
import { ReactComponent as PublicTransport } from './assets/public-transport.svg';
import { ReactComponent as Fingers } from './assets/fingers.svg';
import { ReactComponent as PaymentCard } from './assets/payment-card.svg';
import { ReactComponent as Password } from './assets/password.svg';
import { ReactComponent as AppleLogo } from './assets/apple-logo.svg';
import { ReactComponent as Spinner } from './assets/spinner.svg';
import { ReactComponent as Account } from './assets/2024/Account.svg';
import { ReactComponent as Activities } from './assets/2024/Activities.svg';
import { ReactComponent as ActivitiesWinter } from './assets/2024/ActivitiesWinter.svg';
import { ReactComponent as Amenities } from './assets/2024/Amenities.svg';
import { ReactComponent as ArrowMLeft } from './assets/2024/Arrow_M_Left.svg';
import { ReactComponent as ArrowMRight } from './assets/2024/Arrow_M_Right.svg';
import { ReactComponent as ArrowSDown } from './assets/2024/Arrow_S_down.svg';
import { ReactComponent as ArrowSUp } from './assets/2024/Arrow_S_up.svg';
import { ReactComponent as ArrowSRight } from './assets/2024/Arrow_S_right.svg';
import { ReactComponent as ArrowSLeft } from './assets/2024/Arrow_S_left.svg';
import { ReactComponent as Avatar } from './assets/2024/Avatar.svg';
import { ReactComponent as Bill } from './assets/2024/Bill.svg';
import { ReactComponent as Cabin } from './assets/2024/Cabin.svg';
import { ReactComponent as Calendar } from './assets/2024/Calendar.svg';
import { ReactComponent as CampToYourself } from './assets/2024/Camp_to_yourself.svg';
import { ReactComponent as Caravan } from './assets/2024/Caravan.svg';
import { ReactComponent as CheckBold } from './assets/2024/Check_Bold.svg';
import { ReactComponent as CheckLight } from './assets/2024/Check_Light.svg';
import { ReactComponent as CheckIn } from './assets/2024/Check-in.svg';
import { ReactComponent as CheckOut } from './assets/2024/Check-out.svg';
import { ReactComponent as Cheese } from './assets/2024/Cheese.svg';
import { ReactComponent as ChildFriendly } from './assets/2024/Child_friendly.svg';
import { ReactComponent as Counter } from './assets/2024/Counter.svg';
import { ReactComponent as Credit } from './assets/2024/Credit.svg';
import { ReactComponent as CurrentPosition } from './assets/2024/CurrentPosition.svg';
import { ReactComponent as Delete } from './assets/2024/Delete.svg';
import { ReactComponent as DirectbookingFilled } from './assets/2024/Directbooking_Filled.svg';
import { ReactComponent as DirectbookingOutline } from './assets/2024/Directbooking_outline.svg';
import { ReactComponent as Directbooking } from './assets/2024/Directbooking.svg';
import { ReactComponent as Directions } from './assets/2024/Directions.svg';
import { ReactComponent as DiscountCode } from './assets/2024/DiscountCode.svg';
import { ReactComponent as Dog } from './assets/2024/Dog.svg';
import { ReactComponent as Dots } from './assets/2024/Dots.svg';
import { ReactComponent as Download } from './assets/2024/Download.svg';
import { ReactComponent as Edit } from './assets/2024/Edit.svg';
import { ReactComponent as ElectricityNew } from './assets/2024/Electricity.svg';
import { ReactComponent as ErrorCareful } from './assets/2024/Error_Careful.svg';
import { ReactComponent as WarningOrange } from './assets/2024/Warning_Orange.svg';
import { ReactComponent as Extras } from './assets/2024/Extras.svg';
import { ReactComponent as Faq } from './assets/2024/FAQ.svg';
import { ReactComponent as Farmshop } from './assets/2024/Farmshop.svg';
import { ReactComponent as Filter } from './assets/2024/Filter.svg';
import { ReactComponent as Fireplace } from './assets/2024/Fireplace.svg';
import { ReactComponent as Flex } from './assets/2024/Flex.svg';
import { ReactComponent as France } from './assets/2024/France.svg';
import { ReactComponent as Food } from './assets/2024/Food.svg';
import { ReactComponent as GiftCard } from './assets/2024/GiftCard.svg';
import { ReactComponent as HamburgerMenu } from './assets/2024/HamburgerMenu.svg';
import { ReactComponent as HeartFavoritesFilled } from './assets/2024/HeartFavorites_Filled.svg';
import { ReactComponent as HeartFavoritesOutline } from './assets/2024/HeartFavorites_Outline.svg';
import { ReactComponent as Host } from './assets/2024/Host.svg';
import { ReactComponent as Image } from './assets/2024/Image.svg';
import { ReactComponent as Info } from './assets/2024/Info.svg';
import { ReactComponent as Languages } from './assets/2024/Languages.svg';
import { ReactComponent as Lenght } from './assets/2024/Lenght.svg';
import { ReactComponent as ListNew } from './assets/2024/List.svg';
import { ReactComponent as Listing } from './assets/2024/Listing.svg';
import { ReactComponent as LocationPin } from './assets/2024/LocationPin.svg';
import { ReactComponent as Login } from './assets/2024/Login.svg';
import { ReactComponent as Logout } from './assets/2024/Logout.svg';
import { ReactComponent as MessageSend } from './assets/2024/Message_Send.svg';
import { ReactComponent as Message1 } from './assets/2024/Message-1.svg';
import { ReactComponent as Message } from './assets/2024/Message.svg';
import { ReactComponent as Motorhome } from './assets/2024/Motorhome.svg';
import { ReactComponent as MotorhomePlus } from './assets/2024/Motorhome+.svg';
import { ReactComponent as Nights } from './assets/2024/Nights.svg';
import { ReactComponent as NomadyIcon } from './assets/2024/Nomady_Icon.svg';
import { ReactComponent as NotificationBell } from './assets/2024/NotificationBell.svg';
import { ReactComponent as OpenExternal } from './assets/2024/Open_external.svg';
import { ReactComponent as Parking } from './assets/2024/Parking.svg';
import { ReactComponent as Payment } from './assets/2024/Payment.svg';
import { ReactComponent as Plus } from './assets/2024/Plus.svg';
import { ReactComponent as PublicTransportation } from './assets/2024/PublicTransportation.svg';
import { ReactComponent as RatingFilled } from './assets/2024/Rating_filled.svg';
import { ReactComponent as RatingStar } from './assets/2024/RatingStar.svg';
import { ReactComponent as RatingStarFilled } from './assets/2024/RatingStarFilled.svg';
import { ReactComponent as RegionFlag } from './assets/2024/RegionFlag.svg';
import { ReactComponent as Register } from './assets/2024/Register.svg';
import { ReactComponent as Roof } from './assets/2024/Roof.svg';
import { ReactComponent as Rooftent } from './assets/2024/Rooftent.svg';
import { ReactComponent as SafePayment } from './assets/2024/SafePayment.svg';
import { ReactComponent as Search } from './assets/2024/Search.svg';
import { ReactComponent as Shadow } from './assets/2024/Shadow.svg';
import { ReactComponent as ShowerNew } from './assets/2024/Shower.svg';
import { ReactComponent as Sleepingbag } from './assets/2024/Sleepingbag.svg';
import { ReactComponent as SortBottomUp } from './assets/2024/Sort_BottomUp.svg';
import { ReactComponent as SortTopDown } from './assets/2024/Sort_Top_down.svg';
import { ReactComponent as SpeakLanguages } from './assets/2024/Speak_Languages.svg';
import { ReactComponent as Spots } from './assets/2024/Spots.svg';
import { ReactComponent as Superflex } from './assets/2024/Superflex.svg';
import { ReactComponent as Surroundings } from './assets/2024/Surroundings.svg';
import { ReactComponent as Switch } from './assets/2024/Switch.svg';
import { ReactComponent as Tent } from './assets/2024/Tent.svg';
import { ReactComponent as ThumbUp } from './assets/2024/ThumbUp.svg';
import { ReactComponent as Time } from './assets/2024/Time.svg';
import { ReactComponent as TipiNew } from './assets/2024/Tipi.svg';
import { ReactComponent as TophostHighlight } from './assets/2024/Tophost_Highlight.svg';
import { ReactComponent as Tophost } from './assets/2024/Tophost.svg';
import { ReactComponent as TranslateOriginal1 } from './assets/2024/Translate_Original_1.svg';
import { ReactComponent as TranslateOriginal2 } from './assets/2024/Translate_Original_2.svg';
import { ReactComponent as Travel } from './assets/2024/Travel.svg';
import { ReactComponent as Travel2 } from './assets/2024/Travel2.svg';
import { ReactComponent as Underground } from './assets/2024/Underground.svg';
import { ReactComponent as UndergroundWinter } from './assets/2024/UndergroundWinter.svg';
import { ReactComponent as Van } from './assets/2024/Van.svg';
import { ReactComponent as VerifiedFilled } from './assets/2024/Verified_filled.svg';
import { ReactComponent as View } from './assets/2024/View.svg';
import { ReactComponent as Water } from './assets/2024/Water.svg';
import { ReactComponent as Wc } from './assets/2024/WC.svg';
import { ReactComponent as Wifi } from './assets/2024/Wifi.svg';
import { ReactComponent as WinterSnowstar } from './assets/2024/Winter_Snowstar.svg';
import { ReactComponent as XClose } from './assets/2024/X_Close.svg';
import { ReactComponent as Payout } from './assets/2024/Payout.svg';
import { ReactComponent as Share } from './assets/2024/Share.svg';
import { ReactComponent as MinusNew } from './assets/2024/Minus.svg';
import { ReactComponent as NomadyLogoLeafOnly } from './assets/nomadyLogo/NomadyLogoLeafOnly.svg';
import { ColorVariant } from '../../theme';
import LabeledIcon from '../LabeledIcon/LabeledIcon';
import IconVariant from '@nomady/shared/interfaces/IconVariant';
/**
 * @deprecated directly import from @nomady/shared/interfaces/IconVariant
 */
export { default as IconVariant } from '@nomady/shared/interfaces/IconVariant';
export var IconSizeVariant;
(function (IconSizeVariant) {
    IconSizeVariant["Default"] = "24px";
    IconSizeVariant["ExtraSmall"] = "18px";
    IconSizeVariant["Tiny"] = "16px";
    IconSizeVariant["ExtraExtraSmall"] = "12px";
    IconSizeVariant["ExtraExtraExtraSmall"] = "8px";
    IconSizeVariant["Small"] = "24px";
    IconSizeVariant["Medium"] = "36px";
    IconSizeVariant["MediumLarge"] = "48px";
    IconSizeVariant["Large"] = "66px";
    IconSizeVariant["ExtraLarge"] = "192px";
    IconSizeVariant["Size20px"] = "20px";
})(IconSizeVariant || (IconSizeVariant = {}));
const Wrapper = styled.div `
  position: relative;

  display: flex;
  justify-content: center;

  svg {
    height: ${props => props.mobileSizeVariant};
    width: ${props => props.mobileSizeVariant};
  }

  div {
    height: ${props => props.mobileSizeVariant};
    width: ${props => props.mobileSizeVariant};
  }

  .stroke {
    stroke: ${props => props.crossedOut || props.strikethrough
    ? props.theme.color.fog
    : props.theme.color[props.colorVariant]};
  }

  .fill {
    fill: ${props => props.crossedOut || props.strikethrough
    ? props.theme.color.fog
    : props.theme.color[props.colorVariant]};
  }

  color: ${props => props.crossedOut || props.strikethrough
    ? props.theme.color.fog
    : props.theme.color[props.colorVariant]};

  @media ${props => props.theme.device.tablet} {
    svg {
      height: ${props => props.sizeVariant};
      width: ${props => props.sizeVariant};
    }

    div {
      height: ${props => props.sizeVariant};
      width: ${props => props.sizeVariant};
    }
  }
`;
const StyledOverlayIcon = styled.div `
  position: absolute;
  svg {
    height: ${props => props.sizeVariant};
    width: ${props => props.sizeVariant};
  }

  .stroke {
    stroke: ${props => props.colorVariant
    ? props.theme.color[props.colorVariant]
    : props.theme.color.cave};
  }

  .fill {
    fill: ${props => props.colorVariant
    ? props.theme.color[props.colorVariant]
    : props.theme.color.cave};
  }
`;
const Icon = ({ variant, colorVariant = ColorVariant.Cave, sizeVariant = IconSizeVariant.Default, mobileSizeVariant = sizeVariant, crossedOut = false, strikethrough = false, backgroundColor, }) => {
    let inner;
    switch (variant) {
        case IconVariant.Spinner:
            inner = _jsx(Spinner, {});
            break;
        case IconVariant.NewTab:
            inner = _jsx(NewTabIcon, {});
            break;
        case IconVariant.Download:
            inner = _jsx(DownloadIcon, {});
            break;
        case IconVariant.DirectionLeft:
            inner = _jsx(DirectionLeft, {});
            break;
        case IconVariant.Plus:
            inner = _jsx(PlusIcon, {});
            break;
        case IconVariant.PlusThin:
            inner = _jsx(PlusThinIcon, {});
            break;
        case IconVariant.Minus:
            inner = _jsx(MinusIcon, {});
            break;
        case IconVariant.ArrowLeft:
            inner = _jsx(ArrowLeftIcon, {});
            break;
        case IconVariant.ArrowRight:
            inner = _jsx(ArrowRightIcon, {});
            break;
        case IconVariant.ArrowDown:
            inner = _jsx(ArrowDownIcon, {});
            break;
        case IconVariant.ArrowUp:
            inner = _jsx(ArrowUpIcon, {});
            break;
        case IconVariant.Hamburger:
            inner = _jsx(HamburgerIcon, {});
            break;
        case IconVariant.User:
            inner = _jsx(UserIcon, {});
            break;
        case IconVariant.UserAdd:
            inner = _jsx(UserAddIcon, {});
            break;
        case IconVariant.Login:
            inner = _jsx(LoginIcon, {});
            break;
        case IconVariant.Logout:
            inner = _jsx(LogoutIcon, {});
            break;
        case IconVariant.GiftCard:
            inner = _jsx(GiftCardIcon, {});
            break;
        case IconVariant.Tent:
            inner = _jsx(TentIcon, {});
            break;
        case IconVariant.Postcard:
            inner = _jsx(PostcardIcon, {});
            break;
        case IconVariant.Magnifier:
            inner = _jsx(MagnifierIcon, {});
            break;
        case IconVariant.SimplePin:
            inner = _jsx(SimplePinIcon, {});
            break;
        case IconVariant.Pin:
            inner = _jsx(PinIcon, {});
            break;
        case IconVariant.Fullscreen:
            inner = _jsx(FullscreenIcon, {});
            break;
        case IconVariant.Minimize:
            inner = _jsx(MinimizeIcon, {});
            break;
        case IconVariant.Heart:
            inner = _jsx(HeartIcon, {});
            break;
        case IconVariant.Calendar:
            inner = _jsx(CalendarIcon, {});
            break;
        case IconVariant.Snowflake:
            inner = _jsx(SnowflakeIcon, {});
            break;
        case IconVariant.Van:
            inner = _jsx(VanIcon, {});
            break;
        case IconVariant.Dog:
            inner = _jsx(DogIcon, {});
            break;
        case IconVariant.Compass:
            inner = _jsx(CompassIcon, {});
            break;
        case IconVariant.Revert:
            inner = _jsx(RevertIcon, {});
            break;
        case IconVariant.Cross:
            inner = _jsx(CrossIcon, {});
            break;
        case IconVariant.BubbleCheckmark:
            inner = _jsx(BubbleCheckmarkIcon, {});
            break;
        case IconVariant.BubbleFlash:
            inner = _jsx(BubbleFlashIcon, {});
            break;
        case IconVariant.BubbleSnowflake:
            inner = _jsx(BubbleSnowflakeIcon, {});
            break;
        case IconVariant.BubbleFacebook:
            inner = _jsx(BubbleFacebookIcon, {});
            break;
        case IconVariant.BubbleInstagram:
            inner = _jsx(BubbleInstagramIcon, {});
            break;
        case IconVariant.BubbleLetter:
            inner = _jsx(BubbleLetterIcon, {});
            break;
        case IconVariant.BubbleSmile:
            inner = _jsx(BubbleSmileIcon, {});
            break;
        case IconVariant.BubbleLinkedIn:
            inner = _jsx(BubbleLinkedInIcon, {});
            break;
        case IconVariant.Motorhome:
            inner = _jsx(MotorhomeIcon, {});
            break;
        case IconVariant.MotorhomePlus:
            inner = _jsx(MotorhomePlusIcon, {});
            break;
        case IconVariant.Caravan:
            inner = _jsx(CaravanIcon, {});
            break;
        case IconVariant.Hut:
            inner = _jsx(HutIcon, {});
            break;
        case IconVariant.Backpack:
            inner = _jsx(BackpackIcon, {});
            break;
        case IconVariant.Globe:
            inner = _jsx(GlobeIcon, {});
            break;
        case IconVariant.Garbage:
            inner = _jsx(GarbageIcon, {});
            break;
        case IconVariant.Pen:
            inner = _jsx(PenIcon, {});
            break;
        case IconVariant.BubbleInfo:
            inner = _jsx(BubbleInfo, {});
            break;
        case IconVariant.Checkmark:
            inner = _jsx(Checkmark, {});
            break;
        case IconVariant.Star:
            inner = _jsx(Star, {});
            break;
        case IconVariant.Flash:
            inner = _jsx(Flash, {});
            break;
        case IconVariant.Knobs:
            inner = _jsx(Knobs, {});
            break;
        case IconVariant.Reset:
            inner = _jsx(Reset, {});
            break;
        case IconVariant.Rainbow:
            inner = _jsx(Rainbow, {});
            break;
        case IconVariant.Route:
            inner = _jsx(Route, {});
            break;
        case IconVariant.RooftopTent:
            inner = _jsx(RooftopTent, {});
            break;
        case IconVariant.Umbrella:
            inner = _jsx(Umbrella, {});
            break;
        case IconVariant.Campfire:
            inner = _jsx(Campfire, {});
            break;
        case IconVariant.Boot:
            inner = _jsx(Boot, {});
            break;
        case IconVariant.Coins:
            inner = _jsx(Coins, {});
            break;
        case IconVariant.Tap:
            inner = _jsx(Tap, {});
            break;
        case IconVariant.Mountains:
            inner = _jsx(Mountains, {});
            break;
        case IconVariant.CamperOnGround:
            inner = _jsx(CamperOnGround, {});
            break;
        case IconVariant.Drop:
            inner = _jsx(Drop, {});
            break;
        case IconVariant.Table:
            inner = _jsx(Table, {});
            break;
        case IconVariant.Shop:
            inner = _jsx(Shop, {});
            break;
        case IconVariant.SortAsc:
            inner = _jsx(SortAsc, {});
            break;
        case IconVariant.SortDesc:
            inner = _jsx(SortDesc, {});
            break;
        case IconVariant.DoNotDisturbCard:
            inner = _jsx(DoNotDisturbCard, {});
            break;
        case IconVariant.Map:
            inner = _jsx(Map, {});
            break;
        case IconVariant.List:
            inner = _jsx(List, {});
            break;
        case IconVariant.Shine:
            inner = _jsx(ShineIcon, {});
            break;
        case IconVariant.Dart:
            inner = _jsx(DartIcon, {});
            break;
        case IconVariant.Flag:
            inner = _jsx(FlagIcon, {});
            break;
        case IconVariant.Food:
            inner = _jsx(Food, {});
            break;
        case IconVariant.Upload:
            inner = _jsx(UploadIcon, {});
            break;
        case IconVariant.TranslationActive:
            inner = _jsx(TranslationActive, {});
            break;
        case IconVariant.TranslationInactive:
            inner = _jsx(TranslationInactive, {});
            break;
        case IconVariant.Phone:
            inner = _jsx(PhoneIcon, {});
            break;
        case IconVariant.Speaking:
            inner = _jsx(Speaking, {});
            break;
        case IconVariant.ToiletPaper:
            inner = _jsx(ToiletPaper, {});
            break;
        case IconVariant.Electricity:
            inner = _jsx(Electricity, {});
            break;
        case IconVariant.Shower:
            inner = _jsx(Shower, {});
            break;
        case IconVariant.Shade:
            inner = _jsx(Shade, {});
            break;
        case IconVariant.Tipi:
            inner = _jsx(Tipi, {});
            break;
        case IconVariant.HorseToy:
            inner = _jsx(HorseToy, {});
            break;
        case IconVariant.DirectionRight:
            inner = _jsx(DirectionRight, {});
            break;
        case IconVariant.DE:
            inner = _jsx(DE, {});
            break;
        case IconVariant.IT:
            inner = _jsx(IT, {});
            break;
        case IconVariant.CH:
            inner = _jsx(CH, {});
            break;
        case IconVariant.FR:
            inner = _jsx(FR, {});
            break;
        case IconVariant.AT:
            inner = _jsx(AT, {});
            break;
        case IconVariant.Picture:
            inner = _jsx(Picture, {});
            break;
        case IconVariant.Shelter:
            inner = _jsx(Shelter, {});
            break;
        case IconVariant.Sledge:
            inner = _jsx(Sledge, {});
            break;
        case IconVariant.Hand:
            inner = _jsx(Hand, {});
            break;
        case IconVariant.Lightbulb:
            inner = _jsx(Lightbulb, {});
            break;
        case IconVariant.PaperScroll:
            inner = _jsx(PaperScroll, {});
            break;
        case IconVariant.Sign:
            inner = _jsx(Sign, {});
            break;
        case IconVariant.Parking:
            inner = (_jsx(LabeledIcon, { content: 'P', backgroundColor: backgroundColor || ColorVariant.Sky, textColor: ColorVariant.Pearl, sizeVariant: sizeVariant }));
            break;
        case IconVariant.Seat1:
            inner = (_jsx(LabeledIcon, { content: '1', backgroundColor: backgroundColor || ColorVariant.Indigo, textColor: ColorVariant.Pearl, sizeVariant: sizeVariant }));
            break;
        case IconVariant.Seat2:
            inner = (_jsx(LabeledIcon, { content: '2', backgroundColor: backgroundColor || ColorVariant.Indigo, textColor: ColorVariant.Pearl, sizeVariant: sizeVariant }));
            break;
        case IconVariant.Seat3:
            inner = (_jsx(LabeledIcon, { content: '3', backgroundColor: backgroundColor || ColorVariant.Indigo, textColor: ColorVariant.Pearl, sizeVariant: sizeVariant }));
            break;
        case IconVariant.Seat4:
            inner = (_jsx(LabeledIcon, { content: '4', backgroundColor: backgroundColor || ColorVariant.Indigo, textColor: ColorVariant.Pearl, sizeVariant: sizeVariant }));
            break;
        case IconVariant.Seat5:
            inner = (_jsx(LabeledIcon, { content: '5', backgroundColor: backgroundColor || ColorVariant.Indigo, textColor: ColorVariant.Pearl, sizeVariant: sizeVariant }));
            break;
        case IconVariant.Seat6:
            inner = (_jsx(LabeledIcon, { content: '6', backgroundColor: backgroundColor || ColorVariant.Indigo, textColor: ColorVariant.Pearl, sizeVariant: sizeVariant }));
            break;
        case IconVariant.Seat7:
            inner = (_jsx(LabeledIcon, { content: '7', backgroundColor: backgroundColor || ColorVariant.Indigo, textColor: ColorVariant.Pearl, sizeVariant: sizeVariant }));
            break;
        case IconVariant.Seat8:
            inner = (_jsx(LabeledIcon, { content: '8', backgroundColor: backgroundColor || ColorVariant.Indigo, textColor: ColorVariant.Pearl, sizeVariant: sizeVariant }));
            break;
        case IconVariant.Seat9:
            inner = (_jsx(LabeledIcon, { content: '9', backgroundColor: backgroundColor || ColorVariant.Indigo, textColor: ColorVariant.Pearl, sizeVariant: sizeVariant }));
            break;
        case IconVariant.Seat10:
            inner = (_jsx(LabeledIcon, { content: '10', backgroundColor: backgroundColor || ColorVariant.Indigo, textColor: ColorVariant.Pearl, sizeVariant: sizeVariant }));
            break;
        case IconVariant.SeatThreeDots:
            inner = (_jsx(LabeledIcon, { content: '...', backgroundColor: backgroundColor || ColorVariant.Indigo, textColor: ColorVariant.Pearl, sizeVariant: sizeVariant }));
            break;
        case IconVariant.RedWarning:
            inner = (_jsx(LabeledIcon, { content: '!', backgroundColor: backgroundColor || ColorVariant.Rose, textColor: ColorVariant.Pearl, sizeVariant: sizeVariant }));
            break;
        case IconVariant.Moon:
            inner = _jsx(Moon, {});
            break;
        case IconVariant.MoonOutlined:
            inner = _jsx(MoonOutlined, {});
            break;
        case IconVariant.Clock:
            inner = _jsx(Clock, {});
            break;
        case IconVariant.CircledCross:
            inner = _jsx(CircledCross, {});
            break;
        case IconVariant.Eye:
            inner = _jsx(Eye, {});
            break;
        case IconVariant.PublicTransport:
            inner = _jsx(PublicTransport, {});
            break;
        case IconVariant.Fingers:
            inner = _jsx(Fingers, {});
            break;
        case IconVariant.PaymentCard:
            inner = _jsx(PaymentCard, {});
            break;
        case IconVariant.Password:
            inner = _jsx(Password, {});
            break;
        case IconVariant.AppleLogo:
            inner = _jsx(AppleLogo, {});
            break;
        case IconVariant.Account:
            inner = _jsx(Account, {});
            break;
        case IconVariant.Activities:
            inner = _jsx(Activities, {});
            break;
        case IconVariant.ActivitiesWinter:
            inner = _jsx(ActivitiesWinter, {});
            break;
        case IconVariant.Amenities:
            inner = _jsx(Amenities, {});
            break;
        case IconVariant.Arrow_M_Left:
            inner = _jsx(ArrowMLeft, {});
            break;
        case IconVariant.Arrow_M_Right:
            inner = _jsx(ArrowMRight, {});
            break;
        case IconVariant.Arrow_S_down:
            inner = _jsx(ArrowSDown, {});
            break;
        case IconVariant.Arrow_S_up:
            inner = _jsx(ArrowSUp, {});
            break;
        case IconVariant.Arrow_S_right:
            inner = _jsx(ArrowSRight, {});
            break;
        case IconVariant.Arrow_S_left:
            inner = _jsx(ArrowSLeft, {});
            break;
        case IconVariant.Avatar:
            inner = _jsx(Avatar, {});
            break;
        case IconVariant.Bill:
            inner = _jsx(Bill, {});
            break;
        case IconVariant.Cabin:
            inner = _jsx(Cabin, {});
            break;
        case IconVariant.Calendar_NEW:
            inner = _jsx(Calendar, {});
            break;
        case IconVariant.Camp_to_yourself:
            inner = _jsx(CampToYourself, {});
            break;
        case IconVariant.Caravan_NEW:
            inner = _jsx(Caravan, {});
            break;
        case IconVariant.Check_Bold:
            inner = _jsx(CheckBold, {});
            break;
        case IconVariant.Check_Light:
            inner = _jsx(CheckLight, {});
            break;
        case IconVariant.CheckIn:
            inner = _jsx(CheckIn, {});
            break;
        case IconVariant.CheckOut:
            inner = _jsx(CheckOut, {});
            break;
        case IconVariant.Cheese:
            inner = _jsx(Cheese, {});
            break;
        case IconVariant.Child_friendly:
            inner = _jsx(ChildFriendly, {});
            break;
        case IconVariant.Counter:
            inner = _jsx(Counter, {});
            break;
        case IconVariant.Credit:
            inner = _jsx(Credit, {});
            break;
        case IconVariant.CurrentPosition:
            inner = _jsx(CurrentPosition, {});
            break;
        case IconVariant.Delete:
            inner = _jsx(Delete, {});
            break;
        case IconVariant.Directbooking_Filled:
            inner = _jsx(DirectbookingFilled, {});
            break;
        case IconVariant.Directbooking_outline:
            inner = _jsx(DirectbookingOutline, {});
            break;
        case IconVariant.Directbooking:
            inner = _jsx(Directbooking, {});
            break;
        case IconVariant.Directions:
            inner = _jsx(Directions, {});
            break;
        case IconVariant.DiscountCode:
            inner = _jsx(DiscountCode, {});
            break;
        case IconVariant.Dog_NEW:
            inner = _jsx(Dog, {});
            break;
        case IconVariant.Dots:
            inner = _jsx(Dots, {});
            break;
        case IconVariant.Download_NEW:
            inner = _jsx(Download, {});
            break;
        case IconVariant.Edit:
            inner = _jsx(Edit, {});
            break;
        case IconVariant.Electricity_NEW:
            inner = _jsx(ElectricityNew, {});
            break;
        case IconVariant.Error_Careful:
            inner = _jsx(ErrorCareful, {});
            break;
        case IconVariant.Warning_Orange:
            inner = _jsx(WarningOrange, {});
            break;
        case IconVariant.Extras:
            inner = _jsx(Extras, {});
            break;
        case IconVariant.FAQ:
            inner = _jsx(Faq, {});
            break;
        case IconVariant.Farmshop:
            inner = _jsx(Farmshop, {});
            break;
        case IconVariant.Filter:
            inner = _jsx(Filter, {});
            break;
        case IconVariant.Fireplace:
            inner = _jsx(Fireplace, {});
            break;
        case IconVariant.Flex:
            inner = _jsx(Flex, {});
            break;
        case IconVariant.France:
            inner = _jsx(France, {});
            break;
        case IconVariant.GiftCard_NEW:
            inner = _jsx(GiftCard, {});
            break;
        case IconVariant.HamburgerMenu:
            inner = _jsx(HamburgerMenu, {});
            break;
        case IconVariant.HeartFavorites_Filled:
            inner = _jsx(HeartFavoritesFilled, {});
            break;
        case IconVariant.HeartFavorites_Outline:
            inner = _jsx(HeartFavoritesOutline, {});
            break;
        case IconVariant.Host:
            inner = _jsx(Host, {});
            break;
        case IconVariant.Image:
            inner = _jsx(Image, {});
            break;
        case IconVariant.Info:
            inner = _jsx(Info, {});
            break;
        case IconVariant.Languages:
            inner = _jsx(Languages, {});
            break;
        case IconVariant.Lenght:
            inner = _jsx(Lenght, {});
            break;
        case IconVariant.List_NEW:
            inner = _jsx(ListNew, {});
            break;
        case IconVariant.Listing:
            inner = _jsx(Listing, {});
            break;
        case IconVariant.LocationPin:
            inner = _jsx(LocationPin, {});
            break;
        case IconVariant.Login_NEW:
            inner = _jsx(Login, {});
            break;
        case IconVariant.Logout_NEW:
            inner = _jsx(Logout, {});
            break;
        case IconVariant.Message_Send:
            inner = _jsx(MessageSend, {});
            break;
        case IconVariant.Message1:
            inner = _jsx(Message1, {});
            break;
        case IconVariant.Message:
            inner = _jsx(Message, {});
            break;
        case IconVariant.Motorhome_NEW:
            inner = _jsx(Motorhome, {});
            break;
        case IconVariant.MotorhomePlus_NEW:
            inner = _jsx(MotorhomePlus, {});
            break;
        case IconVariant.Nights:
            inner = _jsx(Nights, {});
            break;
        case IconVariant.Nomady_Icon:
            inner = _jsx(NomadyIcon, {});
            break;
        case IconVariant.NotificationBell:
            inner = _jsx(NotificationBell, {});
            break;
        case IconVariant.Open_external:
            inner = _jsx(OpenExternal, {});
            break;
        case IconVariant.Parking_NEW:
            inner = _jsx(Parking, {});
            break;
        case IconVariant.Payment:
            inner = _jsx(Payment, {});
            break;
        case IconVariant.Payout:
            inner = _jsx(Payout, {});
            break;
        case IconVariant.Plus_NEW:
            inner = _jsx(Plus, {});
            break;
        case IconVariant.PublicTransportation:
            inner = _jsx(PublicTransportation, {});
            break;
        case IconVariant.Rating_filled:
            inner = _jsx(RatingFilled, {});
            break;
        case IconVariant.RatingStar:
            inner = _jsx(RatingStar, {});
            break;
        case IconVariant.RatingStarFilled:
            inner = _jsx(RatingStarFilled, {});
            break;
        case IconVariant.RegionFlag:
            inner = _jsx(RegionFlag, {});
            break;
        case IconVariant.Register:
            inner = _jsx(Register, {});
            break;
        case IconVariant.Roof:
            inner = _jsx(Roof, {});
            break;
        case IconVariant.Rooftent:
            inner = _jsx(Rooftent, {});
            break;
        case IconVariant.SafePayment:
            inner = _jsx(SafePayment, {});
            break;
        case IconVariant.Search:
            inner = _jsx(Search, {});
            break;
        case IconVariant.Shadow:
            inner = _jsx(Shadow, {});
            break;
        case IconVariant.ShowerNew:
            inner = _jsx(ShowerNew, {});
            break;
        case IconVariant.Sleepingbag:
            inner = _jsx(Sleepingbag, {});
            break;
        case IconVariant.Sort_BottomUp:
            inner = _jsx(SortBottomUp, {});
            break;
        case IconVariant.Sort_Top_down:
            inner = _jsx(SortTopDown, {});
            break;
        case IconVariant.Speak_Languages:
            inner = _jsx(SpeakLanguages, {});
            break;
        case IconVariant.Spots:
            inner = _jsx(Spots, {});
            break;
        case IconVariant.Superflex:
            inner = _jsx(Superflex, {});
            break;
        case IconVariant.Surroundings:
            inner = _jsx(Surroundings, {});
            break;
        case IconVariant.Switch:
            inner = _jsx(Switch, {});
            break;
        case IconVariant.Tent_NEW:
            inner = _jsx(Tent, {});
            break;
        case IconVariant.ThumbUp:
            inner = _jsx(ThumbUp, {});
            break;
        case IconVariant.Time:
            inner = _jsx(Time, {});
            break;
        case IconVariant.TipiNew:
            inner = _jsx(TipiNew, {});
            break;
        case IconVariant.Tophost_Highlight:
            inner = _jsx(TophostHighlight, {});
            break;
        case IconVariant.Tophost:
            inner = _jsx(Tophost, {});
            break;
        case IconVariant.Translate_Original_1:
            inner = _jsx(TranslateOriginal1, {});
            break;
        case IconVariant.Translate_Original_2:
            inner = _jsx(TranslateOriginal2, {});
            break;
        case IconVariant.Travel:
            inner = _jsx(Travel, {});
            break;
        case IconVariant.Travel2:
            inner = _jsx(Travel2, {});
            break;
        case IconVariant.Underground:
            inner = _jsx(Underground, {});
            break;
        case IconVariant.UndergroundWinter:
            inner = _jsx(UndergroundWinter, {});
            break;
        case IconVariant.Van_NEW:
            inner = _jsx(Van, {});
            break;
        case IconVariant.Verified_filled:
            inner = _jsx(VerifiedFilled, {});
            break;
        case IconVariant.View:
            inner = _jsx(View, {});
            break;
        case IconVariant.Water:
            inner = _jsx(Water, {});
            break;
        case IconVariant.WC:
            inner = _jsx(Wc, {});
            break;
        case IconVariant.Wifi:
            inner = _jsx(Wifi, {});
            break;
        case IconVariant.Winter_Snowstar:
            inner = _jsx(WinterSnowstar, {});
            break;
        case IconVariant.X_Close:
            inner = _jsx(XClose, {});
            break;
        case IconVariant.Share:
            inner = _jsx(Share, {});
            break;
        case IconVariant.Minus_NEW:
            inner = _jsx(MinusNew, {});
            break;
        case IconVariant.NomadyLogoLeafOnly:
            inner = _jsx(NomadyLogoLeafOnly, {});
            break;
        case IconVariant.Void:
        default:
            inner = _jsx("svg", {});
    }
    return (_jsxs(Wrapper, Object.assign({ colorVariant: colorVariant, sizeVariant: sizeVariant, mobileSizeVariant: mobileSizeVariant, crossedOut: crossedOut, strikethrough: strikethrough }, { children: [inner, crossedOut && (_jsx(StyledOverlayIcon, Object.assign({ sizeVariant: sizeVariant }, { children: _jsx(CrossIcon, {}) }))), strikethrough && (_jsx(StyledOverlayIcon, Object.assign({ sizeVariant: sizeVariant, colorVariant: ColorVariant.Stone }, { children: _jsx(MinusIcon, {}) })))] })));
};
export default Icon;
