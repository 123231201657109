import Decimal from '@nomady/shared/utils/decimal';

class GoogleAnalyticsHandler {
  // eslint-disable-next-line class-methods-use-this
  getDataLayer() {
    return window.dataLayer || [];
  }

  trackPageView(url) {
    const dataLayer = this.getDataLayer();

    dataLayer.push({ event: 'virtualPageView', page: url });
  }

  trackTransaction(params) {
    if (params && params.transaction) {
      const dataLayer = this.getDataLayer();
      dataLayer.push({
        event: 'purchase',
        transactionId: params.transaction.id,
        transactionAffiliation: params.transaction.provider_id,
        transactionTotal: params.transaction.revenue.toString(),
        transactionProducts: [
          {
            sku: params.transaction.listing_id,
            name: params.transaction.listing_title,
            price: new Decimal(params.transaction.revenue)
              .dividedBy(params.transaction.nights)
              .toString(),
            quantity: params.transaction.nights.toString(),
          },
        ],
      });
    }
  }
}

export default GoogleAnalyticsHandler;
