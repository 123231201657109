import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { useIntl } from 'react-intl';
import SectionLayout from './SectionLayout';
import CheckoutInfo from '../CheckoutInfo';
const CheckoutSection = ({ listing, bookingStart, bookingEnd, seatQuantity, adultQuantity, childQuantity, teenQuantity, infantQuantity, dogQuantity, giftCards, customerCommissionDiscountCode, onPayNow, paymentInProgress, extras, error, }) => {
    const intl = useIntl();
    return (_jsx(SectionLayout, Object.assign({ hideDivider: true, title: intl.formatMessage({ id: 'CheckoutSection.title' }) }, { children: _jsx(CheckoutInfo, { listing: listing, bookingStart: bookingStart, bookingEnd: bookingEnd, seatQuantity: seatQuantity, adultQuantity: adultQuantity, childQuantity: childQuantity, teenQuantity: teenQuantity, infantQuantity: infantQuantity, dogQuantity: dogQuantity, giftCards: giftCards, customerCommissionDiscountCode: customerCommissionDiscountCode, onPayNow: onPayNow, paymentInProgress: paymentInProgress, extras: extras, error: error }) })));
};
export default CheckoutSection;
