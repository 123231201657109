import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styled from 'styled-components/macro';
import { IconVariant } from '../Icon/Icon';
import { useSelector } from 'react-redux';
import { localeSelector } from '../../slices/UISlice';
import Text, { TextVariant } from '../Text/Text';
import IconButton, { IconButtonColorVariant, IconButtonSizeVariant, } from '../IconButton/IconButton';
import { ColorVariant } from '../../theme';
const Header = styled.div `
  display: flex;
  flex-direction: row;
  padding-top: 10px;
  margin-bottom: 10px;
  align-items: center;
`;
const Month = styled(Text) `
  width: 100%;
  text-align: center;

  display: flex;
  flex-direction: row;
  justify-content: space-evenly;

  ${props => 0 < props.monthIndex && `display:none;`}

  @media ${props => props.theme.device.desktop} {
    display: flex;

    ${props => 0 < props.monthIndex && `margin-left:50px;`}
  }
`;
const CalendarMonthSwitch = ({ onClickPrevMonth, onClickNextMonth, months, }) => {
    const locale = useSelector(localeSelector);
    return (_jsxs(Header, { children: [_jsx(IconButton, { colorVariant: IconButtonColorVariant.Snow, iconVariant: IconVariant.Arrow_M_Left, iconButtonSizeVariant: IconButtonSizeVariant.Small, onClick: onClickPrevMonth, type: "button" }), months === null || months === void 0 ? void 0 : months.map((month, index) => (_jsx(Month, Object.assign({ variant: TextVariant.L1SB, monthIndex: index, colorVariant: ColorVariant.BasicCaveBlack }, { children: month.locale(locale).format('MMMM YYYY') }), month.toISOString()))), _jsx(IconButton, { colorVariant: IconButtonColorVariant.Snow, iconVariant: IconVariant.Arrow_M_Right, iconButtonSizeVariant: IconButtonSizeVariant.Small, onClick: onClickNextMonth, type: "button" })] }));
};
export default CalendarMonthSwitch;
