import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import { useHistory } from 'react-router-dom';
import merge from 'lodash/merge';
import { createResourceLocatorString, findRouteByRouteName, } from '@nomady/shared/utils/routes';
import { convertMoneyToNumber } from '@nomady/shared/utils/currency';
import { createSlug } from '@nomady/shared/utils/urlHelpers';
import PageConfig from '@nomady/shared/routeConfiguration/Page';
import { calculateTotalForCustomer } from '@nomady/shared/utils/lineItemHelpers';
import { routeConfiguration } from '../../../..';
import { getListing } from '../../../../ducks/marketplaceData.duck';
import DesktopBookingPanel from './DesktopBookingPanel';
import MobileBookingBottomBar from './MobileBookingBottomBar';
import { scrollRefIntoView } from '../../utils';
import Modal from '../../../../styled-components/Modal/Modal';
import BookingDatesSelection from '../../ModalContents/BookingDatesSelection';
import BookingGuestsSelection from '../../ModalContents/BookingGuestsSelection';
import { ListingContentModalVariant, listingSelector, providerStripeAccountIdSelector, setGuestsChecked, } from '../../ListingPageSlice';
import { getBookingCalendarTitle, getExtrasTitle, getGuestsTitle, } from '../../../../styled-components/CheckoutPanels/CheckoutPanels';
import { useBreakdownLineItems } from '../../../../styled-components/BookingBreakdown';
import AuthenticationSheet from './AuthenticationSheet';
import { userHasLastNameSelector, userHasPhoneNumberSelector, userIsVerifiedSelector, } from '../../../../ducks/user.duck';
import { listingHasUpdatedPricing } from '../../ListingPage';
import { LINE_ITEM_NIGHT } from '@nomady/shared/utils/lineItemTypes';
import { CampCategory } from '@nomady/shared/types';
import CompletePersonalData from '../../ModalContents/CompletePersonalData';
import BookingExtrasSelection from '../../ModalContents/BookingExtrasSelection';
import { localeSelector } from '../../../../slices/UISlice';
const DesktopWrapper = styled.div `
  display: none;
  @media ${props => props.theme.device.desktop} {
    display: initial;
    max-width: 375px;
    height: 100%;
    padding: 20px 0px 10px 50px;
  }
`;
const BookingPanel = ({ disabled, availableSeats, mobileBookingRef, }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    const [bookingDatesModalIsOpen, setBookingDatesModalIsOpen] = useState(false);
    const [bookingGuestsModalIsOpen, setBookingGuestsModalIsOpen] = useState(false);
    const [bookingExtrasModalIsOpen, setBookingExtrasModalIsOpen] = useState(false);
    const [breakdownModalIsOpen, setBreakdownModalIsOpen] = useState(false);
    const [authenticationSheetIsOpen, setAuthenticationSheetIsOpen] = useState(null);
    const [missingPersonalDataModalIsOpen, setMissingPersonalDataModalIsOpen] = useState(null);
    const intl = useIntl();
    const history = useHistory();
    const state = useSelector((state) => state);
    const providerStripeAccountId = useSelector(providerStripeAccountIdSelector);
    const dispatch = useDispatch();
    const listing = useSelector(listingSelector);
    const locale = useSelector(localeSelector);
    const [extrasTouched, setExtrasTouched] = useState(!(((_c = (_b = (_a = listing === null || listing === void 0 ? void 0 : listing.attributes) === null || _a === void 0 ? void 0 : _a.publicData) === null || _b === void 0 ? void 0 : _b.pricing) === null || _c === void 0 ? void 0 : _c.powerSupply) ||
        ((_f = (_e = (_d = listing === null || listing === void 0 ? void 0 : listing.attributes) === null || _d === void 0 ? void 0 : _d.publicData) === null || _e === void 0 ? void 0 : _e.pricing) === null || _f === void 0 ? void 0 : _f.firewood)));
    const { bookingStart, bookingEnd, bookingData: { seatQuantity, adultQuantity, teenQuantity, childQuantity, infantQuantity, dogQuantity, extras, }, guestsChecked, provider, } = state.ListingPage;
    const { lineItemsFromCalculator } = useBreakdownLineItems({
        listing,
        bookingStart,
        bookingEnd,
        seatQuantity,
        adultQuantity,
        childQuantity,
        teenQuantity,
        infantQuantity,
        dogQuantity,
        extras,
    });
    const { isAuthenticated } = state.Auth;
    const { currentUser } = state.user;
    const userIsVerified = useSelector(userIsVerifiedSelector);
    const userHasLastName = useSelector(userHasLastNameSelector);
    const userHasPhoneNumber = useSelector(userHasPhoneNumberSelector);
    const userHasMissingData = !userHasLastName || !userHasPhoneNumber;
    useEffect(() => {
        var _a;
        if (listing && bookingEnd) {
            const bookingData = {
                unitType: LINE_ITEM_NIGHT,
                unitPrice: (_a = listing.attributes) === null || _a === void 0 ? void 0 : _a.price.amount,
                startDate: bookingStart,
                endDate: bookingEnd,
                quantity: adultQuantity + teenQuantity + childQuantity,
                seatQuantity,
                adultQuantity,
                teenQuantity,
                childQuantity,
                infantQuantity,
                dogQuantity,
                extras,
            };
            const mergedListing = merge(marketplaceListing, listing);
            const initialValues = {
                listing: mergedListing,
                bookingData,
                bookingDates: {
                    bookingStart,
                    bookingEnd,
                },
                currentUser,
                providerStripeAccountId,
                confirmPaymentError: null,
                provider,
            };
            const checkout = findRouteByRouteName(PageConfig.Checkout, userLocale, routeConfiguration);
            if (checkout.setInitialValues) {
                dispatch(checkout.setInitialValues(initialValues));
            }
        }
    }, [
        bookingEnd,
        seatQuantity,
        adultQuantity,
        teenQuantity,
        childQuantity,
        infantQuantity,
        dogQuantity,
        extras,
    ]);
    useEffect(() => {
        if (userIsVerified && authenticationSheetIsOpen === false) {
            if (userHasMissingData) {
                setMissingPersonalDataModalIsOpen(true);
            }
            else {
                redirectToCheckout();
            }
        }
    }, [authenticationSheetIsOpen, userHasMissingData]);
    useEffect(() => {
        if (missingPersonalDataModalIsOpen === false && !userHasMissingData) {
            redirectToCheckout();
        }
    }, [missingPersonalDataModalIsOpen, userHasMissingData]);
    const { locale: userLocale } = state.UI;
    if (!((_g = listing === null || listing === void 0 ? void 0 : listing.attributes) === null || _g === void 0 ? void 0 : _g.price) || !userLocale) {
        return null;
    }
    const marketplaceListing = getListing(state, listing === null || listing === void 0 ? void 0 : listing.id);
    const haveLineItems = !!(lineItemsFromCalculator === null || lineItemsFromCalculator === void 0 ? void 0 : lineItemsFromCalculator.length);
    const totalPriceForCustomer = haveLineItems
        ? calculateTotalForCustomer(lineItemsFromCalculator)
        : (_h = listing === null || listing === void 0 ? void 0 : listing.attributes) === null || _h === void 0 ? void 0 : _h.price;
    const redirectToCheckout = () => {
        history.push(createResourceLocatorString(PageConfig.Checkout, routeConfiguration, {
            id: listing.id.uuid,
            slug: createSlug(listing.attributes.title),
            language: userLocale,
        }, {}));
    };
    let bookingButtonLabel = intl.formatMessage({
        id: 'ListingPage.checkAvailability',
    });
    const { instantBookingAvailable, seats, category } = ((_j = listing === null || listing === void 0 ? void 0 : listing.attributes) === null || _j === void 0 ? void 0 : _j.publicData) || {};
    if (bookingStart && bookingEnd && guestsChecked) {
        bookingButtonLabel = intl.formatMessage({
            id: instantBookingAvailable
                ? 'BookingPanel.requestToInstantBook'
                : 'BookingPanel.requestToBook',
        });
    }
    const showAuthenticationSheet = () => {
        setAuthenticationSheetIsOpen(true);
    };
    const handleClickCheckout = () => {
        const readyForCheckout = bookingStart && bookingEnd && guestsChecked && extrasTouched;
        if (!bookingStart || !bookingEnd) {
            return setBookingDatesModalIsOpen(true);
        }
        if (bookingStart && bookingEnd && !guestsChecked) {
            return setBookingGuestsModalIsOpen(true);
        }
        if (bookingStart && bookingEnd && guestsChecked && !extrasTouched) {
            return setBookingExtrasModalIsOpen(true);
        }
        if ((readyForCheckout && !isAuthenticated) ||
            (isAuthenticated && !userIsVerified)) {
            return showAuthenticationSheet();
        }
        if (userHasMissingData) {
            return setMissingPersonalDataModalIsOpen(true);
        }
        if (readyForCheckout && isAuthenticated && userIsVerified) {
            return redirectToCheckout();
        }
    };
    const commonBookingProps = {
        listing,
        bookingStart,
        bookingEnd,
        seatQuantity,
        adultQuantity,
        teenQuantity,
        childQuantity,
        infantQuantity,
        dogQuantity,
        setBreakdownOpen: setBreakdownModalIsOpen,
        handleClickCheckout,
        bookingButtonLabel,
        extras,
    };
    const showSeatSelection = category !== CampCategory.accommodation;
    const onCloseDatesModal = () => {
        setBookingDatesModalIsOpen(false);
        if (guestsChecked) {
            scrollRefIntoView(mobileBookingRef);
        }
        else {
            setBookingGuestsModalIsOpen(true);
        }
    };
    const onCloseGuestsModal = () => {
        setBookingGuestsModalIsOpen(false);
        if (extrasTouched) {
            scrollRefIntoView(mobileBookingRef);
        }
        else {
            setBookingExtrasModalIsOpen(true);
        }
    };
    const onCloseExtrasModal = () => {
        setExtrasTouched(true);
        setBookingExtrasModalIsOpen(false);
        scrollRefIntoView(mobileBookingRef);
    };
    const hasUpdatedPricing = listingHasUpdatedPricing(listing);
    return (_jsxs(_Fragment, { children: [_jsx(DesktopWrapper, { children: _jsx(_Fragment, { children: hasUpdatedPricing ? (_jsx(DesktopBookingPanel, Object.assign({}, commonBookingProps, { breakdownOpen: breakdownModalIsOpen, disabled: disabled, totalSeats: seats || 1, availableSeats: availableSeats, extras: extras }))) : (_jsx("p", { children: _jsx("strong", { children: intl.formatMessage({ id: 'ListingPage.pricingNotUpToDate' }) }) })) }) }), !disabled && !authenticationSheetIsOpen && (_jsx(MobileBookingBottomBar, Object.assign({}, commonBookingProps, { totalPrice: convertMoneyToNumber(totalPriceForCustomer) }))), _jsx(Modal, Object.assign({ isOpen: bookingDatesModalIsOpen, onClose: () => setBookingDatesModalIsOpen(false), id: `ListingModal-${ListingContentModalVariant.BOOKING_DATES}`, title: getBookingCalendarTitle(intl, locale, bookingStart, bookingEnd), rightButton: {
                    label: intl.formatMessage({ id: 'ListingPage.saveDates' }),
                    onClick: onCloseDatesModal,
                    isPrimary: true,
                } }, { children: _jsx(BookingDatesSelection, { onClose: onCloseDatesModal }) })), _jsx(Modal, Object.assign({ isOpen: bookingGuestsModalIsOpen, onClose: onCloseGuestsModal, id: `ListingModal-${ListingContentModalVariant.BOOKING_GUESTS}`, title: getGuestsTitle(intl, guestsChecked, adultQuantity + teenQuantity + childQuantity), rightButton: {
                    label: intl.formatMessage({ id: 'ListingPage.saveGuests' }),
                    onClick: () => {
                        dispatch(setGuestsChecked(true));
                        onCloseGuestsModal();
                    },
                    isPrimary: true,
                } }, { children: _jsx(BookingGuestsSelection, { availableSeats: availableSeats, showSeatSelection: showSeatSelection }) })), _jsx(Modal, Object.assign({ isOpen: bookingExtrasModalIsOpen, onClose: onCloseExtrasModal, id: `ListingModal-${ListingContentModalVariant.BOOKING_EXTRAS}`, title: getExtrasTitle(intl) }, { children: _jsx(BookingExtrasSelection, { onClose: onCloseExtrasModal }) })), _jsx(Modal, Object.assign({ isOpen: !!missingPersonalDataModalIsOpen, onClose: () => setMissingPersonalDataModalIsOpen(false), id: `ListingModal-${ListingContentModalVariant.MISSING_PERSONAL_DATA}`, title: intl.formatMessage({ id: 'CheckoutPanels.missingPersonalData' }) }, { children: _jsx(CompletePersonalData, { onClose: () => setMissingPersonalDataModalIsOpen(false) }) })), _jsx(AuthenticationSheet, { open: !!authenticationSheetIsOpen, onClose: () => setAuthenticationSheetIsOpen(false) })] }));
};
export default BookingPanel;
