import { types as sdkTypes } from '@nomady/shared/utils/sdkLoader';
import { localeSelector } from '../slices/UISlice';
const { LatLngBounds, LatLng } = sdkTypes;
export const LOCAL_GEOCODER_ENTRY_ID_PREFIX = 'LOCAL_GEOCODER_ENTRY_ID_';
const LOCAL_GEOCODER_ENTRIES = [
    {
        linguisticLocationDetails: [
            { keyword: 'Österreich', title: 'Österreich', language: 'de' },
            { keyword: 'Austria', title: 'Austria', language: 'en' },
            { keyword: 'Autriche', title: 'Autriche', language: 'fr' },
            { keyword: 'Austria', title: 'Austria', language: 'it' },
        ],
        latLngBounds: new LatLngBounds(new LatLng(49.020525, 17.160761), new LatLng(46.372312, 9.530741)),
    },
    {
        linguisticLocationDetails: [
            { keyword: 'Deutschland', title: 'Deutschland', language: 'de' },
            { keyword: 'Germany', title: 'Germany', language: 'en' },
            { keyword: 'Allemagne', title: 'Allemagne', language: 'fr' },
            { keyword: 'Germania', title: 'Germania', language: 'it' },
        ],
        latLngBounds: new LatLngBounds(new LatLng(54.983104153, 16.0169958839), new LatLng(46.3024876979, 5.98865807458)),
    },
    {
        linguisticLocationDetails: [
            { keyword: 'Schwarzwald', title: 'Schwarzwald', language: 'de' },
            { keyword: 'Black Forest', title: 'Black Forest', language: 'en' },
            { keyword: 'Forêt-Noire', title: 'Forêt-Noire', language: 'fr' },
            { keyword: 'Foresta Nera', title: 'Foresta Nera', language: 'it' },
        ],
        latLngBounds: new LatLngBounds(new LatLng(49.08238, 9.17251), new LatLng(47.55935, 7.54654)),
    },
    {
        linguisticLocationDetails: [
            { keyword: 'Schwäbische Alb', title: 'Schwäbische Alb', language: 'de' },
            { keyword: 'Swabian Jura', title: 'Swabian Jura', language: 'en' },
            { keyword: 'Jura Souabe', title: 'Jura Souabe', language: 'fr' },
            { keyword: 'Giura Sveva', title: 'Giura Sveva', language: 'it' },
        ],
        latLngBounds: new LatLngBounds(new LatLng(49.20456, 10.33706), new LatLng(48.02251, 8.76053)),
    },
    {
        linguisticLocationDetails: [
            { keyword: 'Bodensee', title: 'Bodensee', language: 'de' },
            { keyword: 'Lake Constance', title: 'Lake Constance', language: 'en' },
            {
                keyword: 'Lac de Constance',
                title: 'Lac de Constance',
                language: 'fr',
            },
            {
                keyword: 'Lago di Costanza',
                title: 'Lago di Costanza',
                language: 'it',
            },
        ],
        latLngBounds: new LatLngBounds(new LatLng(48.02802, 9.89979), new LatLng(47.41355, 8.72116)),
    },
    {
        linguisticLocationDetails: [
            { keyword: 'Oberbayern', title: 'Oberbayern', language: 'de' },
            { keyword: 'Upper Bavaria', title: 'Upper Bavaria', language: 'en' },
            { keyword: 'Haute-Bavière', title: 'Haute-Bavière', language: 'fr' },
            { keyword: 'Alta Baviera', title: 'Alta Baviera', language: 'it' },
        ],
        latLngBounds: new LatLngBounds(new LatLng(49.22251, 13.10642), new LatLng(47.44431, 10.33706)),
    },
    {
        linguisticLocationDetails: [
            { keyword: 'Ostbayern', title: 'Ostbayern', language: 'de' },
            { keyword: 'Eastern Bavaria', title: 'Eastern Bavaria', language: 'en' },
            {
                keyword: 'Bavière orientale',
                title: 'Bavière orientale',
                language: 'fr',
            },
            {
                keyword: 'Baviera Orientale',
                title: 'Baviera Orientale',
                language: 'it',
            },
        ],
        latLngBounds: new LatLngBounds(new LatLng(49.22429, 13.86093), new LatLng(47.87738, 12.33484)),
    },
    {
        linguisticLocationDetails: [
            { keyword: 'Bayern', title: 'Bayern', language: 'de' },
            { keyword: 'Bavaria', title: 'Bavaria', language: 'en' },
            { keyword: 'Bavière', title: 'Bavière', language: 'fr' },
            { keyword: 'Baviera', title: 'Baviera', language: 'it' },
        ],
        latLngBounds: new LatLngBounds(new LatLng(50.38086, 13.48387), new LatLng(47.28707, 9.52879)),
    },
    {
        linguisticLocationDetails: [
            { keyword: 'Allgäu', title: 'Allgäu', language: 'de' },
            { keyword: 'Allgäu', title: 'Allgäu', language: 'en' },
            { keyword: 'Allgäu', title: 'Allgäu', language: 'fr' },
            { keyword: 'Allgäu', title: 'Allgäu', language: 'it' },
        ],
        latLngBounds: new LatLngBounds(new LatLng(48.24288, 11.09792), new LatLng(47.29244, 9.59826)),
    },
    {
        linguisticLocationDetails: [
            { keyword: 'Franken', title: 'Franken', language: 'de' },
            { keyword: 'Franconia', title: 'Franconia', language: 'en' },
            { keyword: 'Franconie', title: 'Franconie', language: 'fr' },
            { keyword: 'Franconia', title: 'Franconia', language: 'it' },
        ],
        latLngBounds: new LatLngBounds(new LatLng(50.42088, 12.62074), new LatLng(48.95689, 8.97328)),
    },
    {
        linguisticLocationDetails: [
            { keyword: 'Rheinland', title: 'Rheinland', language: 'de' },
            { keyword: 'Rhineland', title: 'Rhineland', language: 'en' },
            { keyword: 'Rhénanie', title: 'Rhénanie', language: 'fr' },
            { keyword: 'Renania', title: 'Renania', language: 'it' },
        ],
        latLngBounds: new LatLngBounds(new LatLng(51.91757, 8.38701), new LatLng(49.09509, 6.09376)),
    },
    {
        linguisticLocationDetails: [
            { keyword: 'Nordsee', title: 'Nordsee', language: 'de' },
            { keyword: 'North Sea', title: 'North Sea', language: 'en' },
            { keyword: 'Mer du Nord', title: 'Mer du Nord', language: 'fr' },
            { keyword: 'Mare del Nord', title: 'Mare del Nord', language: 'it' },
        ],
        latLngBounds: new LatLngBounds(new LatLng(54.99284, 10.33375), new LatLng(52.57578, 6.66432)),
    },
    {
        linguisticLocationDetails: [
            { keyword: 'Ostsee', title: 'Ostsee', language: 'de' },
            { keyword: 'Baltic Sea', title: 'Baltic Sea', language: 'en' },
            { keyword: 'Mer Baltique', title: 'Mer Baltique', language: 'fr' },
            { keyword: 'Mare Baltico', title: 'Mare Baltico', language: 'it' },
        ],
        latLngBounds: new LatLngBounds(new LatLng(54.97708, 14.57448), new LatLng(52.80884, 9.45485)),
    },
    {
        linguisticLocationDetails: [
            { keyword: 'Norddeutschland', title: 'Norddeutschland', language: 'de' },
            {
                keyword: 'Northern Germany',
                title: 'Northern Germany',
                language: 'en',
            },
            {
                keyword: 'Allemagne du Nord',
                title: 'Allemagne du Nord',
                language: 'fr',
            },
            {
                keyword: 'Germania Settentrionale',
                title: 'Germania Settentrionale',
                language: 'it',
            },
        ],
        latLngBounds: new LatLngBounds(new LatLng(54.99284, 14.57448), new LatLng(52.57578, 6.66432)),
    },
    {
        linguisticLocationDetails: [
            { keyword: 'Harz', title: 'Harz', language: 'de' },
            { keyword: 'Harz', title: 'Harz', language: 'en' },
            { keyword: 'Harz', title: 'Harz', language: 'fr' },
            { keyword: 'Harz', title: 'Harz', language: 'it' },
        ],
        latLngBounds: new LatLngBounds(new LatLng(52.05377, 11.45276), new LatLng(51.38508, 10.0726)),
    },
    {
        linguisticLocationDetails: [
            { keyword: 'Dahme-Spreewald', title: 'Dahme-Spreewald', language: 'de' },
            { keyword: 'Dahme-Spreewald', title: 'Dahme-Spreewald', language: 'en' },
            { keyword: 'Dahme-Spreewald', title: 'Dahme-Spreewald', language: 'fr' },
            { keyword: 'Dahme-Spreewald', title: 'Dahme-Spreewald', language: 'it' },
        ],
        latLngBounds: new LatLngBounds(new LatLng(52.41157, 14.98867), new LatLng(51.33877, 12.89728)),
    },
    {
        linguisticLocationDetails: [
            { keyword: 'Berliner-Umland', title: 'Berliner-Umland', language: 'de' },
            {
                keyword: 'Berlin Surroundings',
                title: 'Berlin Surroundings',
                language: 'en',
            },
            {
                keyword: 'Environs de Berlin',
                title: 'Environs de Berlin',
                language: 'fr',
            },
            {
                keyword: 'Dintorni di Berlino',
                title: 'Dintorni di Berlino',
                language: 'it',
            },
        ],
        latLngBounds: new LatLngBounds(new LatLng(53.03041, 14.57819), new LatLng(52.20767, 12.32668)),
    },
    {
        linguisticLocationDetails: [
            { keyword: 'Vogtland', title: 'Vogtland', language: 'de' },
            { keyword: 'Vogtland', title: 'Vogtland', language: 'en' },
            { keyword: 'Vogtland', title: 'Vogtland', language: 'fr' },
            { keyword: 'Vogtland', title: 'Vogtland', language: 'it' },
        ],
        latLngBounds: new LatLngBounds(new LatLng(50.91655, 12.49386), new LatLng(50.17568, 11.51745)),
    },
    {
        linguisticLocationDetails: [
            { keyword: 'Tirol', title: 'Tirol', language: 'de' },
            { keyword: 'Tyrol', title: 'Tyrol', language: 'en' },
            { keyword: 'Tyrol', title: 'Tyrol', language: 'fr' },
            { keyword: 'Tirolo', title: 'Tirolo', language: 'it' },
        ],
        latLngBounds: new LatLngBounds(new LatLng(47.48499, 12.39756), new LatLng(46.93602, 9.90125)),
    },
    {
        linguisticLocationDetails: [
            {
                keyword: 'Niederösterreich',
                title: 'Niederösterreich',
                language: 'de',
            },
            { keyword: 'Lower Austria', title: 'Lower Austria', language: 'en' },
            { keyword: 'Basse-Autriche', title: 'Basse-Autriche', language: 'fr' },
            { keyword: 'Bassa Austria', title: 'Bassa Austria', language: 'it' },
        ],
        latLngBounds: new LatLngBounds(new LatLng(48.98858, 17.05027), new LatLng(47.75528, 13.84776)),
    },
    {
        linguisticLocationDetails: [
            {
                keyword: 'Bergstraße-Odenwald',
                title: 'Bergstraße-Odenwald',
                language: 'de',
            },
            {
                keyword: 'Bergstrasse-Odenwald',
                title: 'Bergstrasse-Odenwald',
                language: 'en',
            },
            {
                keyword: 'Bergstrasse-Odenwald',
                title: 'Bergstrasse-Odenwald',
                language: 'fr',
            },
            {
                keyword: 'Bergstrasse-Odenwald',
                title: 'Bergstrasse-Odenwald',
                language: 'it',
            },
        ],
        latLngBounds: new LatLngBounds(new LatLng(49.99163, 9.54519), new LatLng(49.20392, 8.38064)),
    },
    {
        linguisticLocationDetails: [
            { keyword: 'Taunus', title: 'Taunus', language: 'de' },
            { keyword: 'Taunus', title: 'Taunus', language: 'en' },
            { keyword: 'Taunus', title: 'Taunus', language: 'fr' },
            { keyword: 'Taunus', title: 'Taunus', language: 'it' },
        ],
        latLngBounds: new LatLngBounds(new LatLng(50.59786, 8.99628), new LatLng(49.95676, 7.88323)),
    },
    {
        linguisticLocationDetails: [
            { keyword: 'Thüringer Wald', title: 'Thüringer Wald', language: 'de' },
            {
                keyword: 'Thuringian Forest',
                title: 'Thuringian Forest',
                language: 'en',
            },
            {
                keyword: 'Forêt de Thuringe',
                title: 'Forêt de Thuringe',
                language: 'fr',
            },
            {
                keyword: 'Foresta di Turingia',
                title: 'Foresta di Turingia',
                language: 'it',
            },
        ],
        latLngBounds: new LatLngBounds(new LatLng(51.17999, 11.524), new LatLng(50.21624, 9.87605)),
    },
    {
        linguisticLocationDetails: [
            { keyword: 'Salzburger Land', title: 'Salzburger Land', language: 'de' },
            { keyword: 'Salzburg State', title: 'Salzburg State', language: 'en' },
            {
                keyword: 'Land de Salzbourg',
                title: 'Land de Salzbourg',
                language: 'fr',
            },
            {
                keyword: 'Terra di Salisburgo',
                title: 'Terra di Salisburgo',
                language: 'it',
            },
        ],
        latLngBounds: new LatLngBounds(new LatLng(47.94781, 13.93471), new LatLng(47.07132, 12.0271)),
    },
    {
        linguisticLocationDetails: [
            {
                keyword: 'Korsika, Frankreich',
                title: 'Korsika, Frankreich',
                language: 'de',
            },
            { keyword: 'Corsica, France', title: 'Corsica, France', language: 'en' },
            { keyword: 'Corse, France', title: 'Corse, France', language: 'fr' },
            {
                keyword: 'Corsica, Francia',
                title: 'Corsica, Francia',
                language: 'it',
            },
        ],
        latLngBounds: new LatLngBounds(new LatLng(43.100408699, 9.6598889), new LatLng(41.310823829, 8.436661)),
    },
    {
        linguisticLocationDetails: [
            { keyword: 'Portugal', title: 'Portugal', language: 'pt' },
            { keyword: 'Portugal', title: 'Portugal', language: 'en' },
            { keyword: 'Portugal', title: 'Portugal', language: 'fr' },
            { keyword: 'Portogallo', title: 'Portogallo', language: 'it' },
        ],
        latLngBounds: new LatLngBounds(new LatLng(42.154276, -6.189158), new LatLng(29.9501178, -31.3687699)),
    },
    {
        linguisticLocationDetails: [
            { keyword: 'Italien', title: 'Italien', language: 'de' },
            { keyword: 'Italy', title: 'Italy', language: 'en' },
            { keyword: 'Italie', title: 'Italie', language: 'fr' },
            { keyword: 'Italia', title: 'Italia', language: 'it' },
        ],
        latLngBounds: new LatLngBounds(new LatLng(47.092146, 18.6201843), new LatLng(35.4122073, 6.626556)),
    },
    {
        linguisticLocationDetails: [
            { keyword: 'Spanien', title: 'Spanien', language: 'de' },
            { keyword: 'Spain', title: 'Spain', language: 'en' },
            { keyword: 'Espagne', title: 'Espagne', language: 'fr' },
            { keyword: 'Spagna', title: 'Spagna', language: 'it' },
        ],
        latLngBounds: new LatLngBounds(new LatLng(43.8640692, 4.4264828), new LatLng(27.5545087, -18.260243)),
    },
    {
        linguisticLocationDetails: [
            { keyword: 'Ungarn', title: 'Ungarn', language: 'de' },
            { keyword: 'Hungary', title: 'Hungary', language: 'en' },
            { keyword: 'Hongrie', title: 'Hongrie', language: 'fr' },
            { keyword: 'Ungheria', title: 'Ungheria', language: 'it' },
        ],
        latLngBounds: new LatLngBounds(new LatLng(48.58537, 22.897449), new LatLng(45.737224, 16.113867)),
    },
    {
        linguisticLocationDetails: [
            { keyword: 'Dänemark', title: 'Dänemark', language: 'de' },
            { keyword: 'Denmark', title: 'Denmark', language: 'en' },
            { keyword: 'Danemark', title: 'Danemark', language: 'fr' },
            { keyword: 'Danimarca', title: 'Danimarca', language: 'it' },
        ],
        latLngBounds: new LatLngBounds(new LatLng(57.8054138, 15.2917827), new LatLng(54.5048654, 7.9974703)),
    },
    {
        linguisticLocationDetails: [
            { keyword: 'Südtirol', title: 'Südtirol', language: 'de' },
            { keyword: 'South Tyrol', title: 'South Tyrol', language: 'en' },
            { keyword: 'Tyrol du Sud', title: 'Tyrol du Sud', language: 'fr' },
            { keyword: 'Alto Adige', title: 'Alto Adige', language: 'it' },
        ],
        latLngBounds: new LatLngBounds(new LatLng(47.092146, 12.477916), new LatLng(46.219772, 10.381822)),
    },
    {
        linguisticLocationDetails: [
            { keyword: 'Engadin', title: 'Engadin', language: 'de' },
            { keyword: 'Engadine', title: 'Engadine', language: 'en' },
            { keyword: 'Engadine', title: 'Engadine', language: 'fr' },
            { keyword: 'Engadina', title: 'Engadina', language: 'it' },
        ],
        latLngBounds: new LatLngBounds(new LatLng(46.973488, 10.491869), new LatLng(46.370414, 9.469458)),
    },
    {
        linguisticLocationDetails: [
            { keyword: 'Tessin', title: 'Tessin', language: 'de' },
            { keyword: 'Ticino', title: 'Ticino', language: 'en' },
            { keyword: 'Tessin', title: 'Tessin', language: 'fr' },
            { keyword: 'Ticino', title: 'Ticino', language: 'it' },
        ],
        latLngBounds: new LatLngBounds(new LatLng(46.559598, 9.337534), new LatLng(45.806576, 8.414066)),
    },
    {
        linguisticLocationDetails: [
            { keyword: 'Pfalz', title: 'Pfalz', language: 'de' },
            { keyword: 'Palatinate', title: 'Palatinate', language: 'en' },
            { keyword: 'Palatinat', title: 'Palatinat', language: 'fr' },
            { keyword: 'Palatinato', title: 'Palatinato', language: 'it' },
        ],
        latLngBounds: new LatLngBounds(new LatLng(50.983897, 8.533105), new LatLng(48.98523, 6.16696)),
    },
    {
        linguisticLocationDetails: [
            { keyword: 'Bern', title: 'Bern', language: 'de' },
            { keyword: 'Bern', title: 'Bern', language: 'en' },
            { keyword: 'Berne', title: 'Berne', language: 'fr' },
            { keyword: 'Berna', title: 'Berna', language: 'it' },
        ],
        latLngBounds: new LatLngBounds(new LatLng(47.308685, 8.0882), new LatLng(46.331402, 7.120771)),
    },
    {
        linguisticLocationDetails: [
            { keyword: 'Gantrisch', title: 'Gantrisch', language: 'de' },
            { keyword: 'Gantrisch', title: 'Gantrisch', language: 'en' },
            { keyword: 'Gantrisch', title: 'Gantrisch', language: 'fr' },
            { keyword: 'Gantrisch', title: 'Gantrisch', language: 'it' },
        ],
        latLngBounds: new LatLngBounds(new LatLng(46.898633, 7.531448), new LatLng(46.661646, 7.255236)),
    },
    {
        linguisticLocationDetails: [
            { keyword: 'Jura', title: 'Jura', language: 'de' },
            { keyword: 'Jura', title: 'Jura', language: 'en' },
            { keyword: 'Jura', title: 'Jura', language: 'fr' },
            { keyword: 'Giura', title: 'Giura', language: 'it' },
        ],
        latLngBounds: new LatLngBounds(new LatLng(47.520733, 7.541832), new LatLng(47.151235, 6.831309)),
    },
    {
        linguisticLocationDetails: [
            { keyword: 'Berner Oberland', title: 'Berner Oberland', language: 'de' },
            {
                keyword: 'Bernese Oberland',
                title: 'Bernese Oberland',
                language: 'en',
            },
            {
                keyword: 'Oberland bernois',
                title: 'Oberland bernois',
                language: 'fr',
            },
            {
                keyword: 'Oberland bernese',
                title: 'Oberland bernese',
                language: 'it',
            },
        ],
        latLngBounds: new LatLngBounds(new LatLng(46.780711, 8.437253), new LatLng(46.326876, 7.207965)),
    },
    {
        linguisticLocationDetails: [
            { keyword: 'Thunersee', title: 'Thunersee', language: 'de' },
            { keyword: 'Lake Thun', title: 'Lake Thun', language: 'en' },
            { keyword: 'Lac de Thoune', title: 'Lac de Thoune', language: 'fr' },
            { keyword: 'Lago di Thun', title: 'Lago di Thun', language: 'it' },
        ],
        latLngBounds: new LatLngBounds(new LatLng(46.735287, 7.849371), new LatLng(46.651454, 7.602014)),
    },
    {
        linguisticLocationDetails: [
            { keyword: 'Sihlsee', title: 'Sihlsee', language: 'de' },
            { keyword: 'Lake Sihl', title: 'Lake Sihl', language: 'en' },
            { keyword: 'Lac de Sihl', title: 'Lac de Sihl', language: 'fr' },
            { keyword: 'Lago di Sihl', title: 'Lago di Sihl', language: 'it' },
        ],
        latLngBounds: new LatLngBounds(new LatLng(47.152181, 8.821646), new LatLng(47.085644, 8.758031)),
    },
    {
        linguisticLocationDetails: [
            { keyword: 'Napf', title: 'Napf', language: 'de' },
            { keyword: 'Napf', title: 'Napf', language: 'en' },
            { keyword: 'Napf', title: 'Napf', language: 'fr' },
            { keyword: 'Napf', title: 'Napf', language: 'it' },
        ],
        latLngBounds: new LatLngBounds(new LatLng(47.140226, 7.995836), new LatLng(46.898626, 7.721949)),
    },
    {
        linguisticLocationDetails: [
            { keyword: 'Innerschweiz', title: 'Innerschweiz', language: 'de' },
            {
                keyword: 'Central Switzerland',
                title: 'Central Switzerland',
                language: 'en',
            },
            { keyword: 'Suisse centrale', title: 'Suisse centrale', language: 'fr' },
            {
                keyword: 'Svizzera centrale',
                title: 'Svizzera centrale',
                language: 'it',
            },
        ],
        latLngBounds: new LatLngBounds(new LatLng(47.242204, 8.948454), new LatLng(46.754564, 7.988471)),
    },
    {
        linguisticLocationDetails: [
            { keyword: 'Toskana', title: 'Toskana', language: 'de' },
            { keyword: 'Tuscany', title: 'Tuscany', language: 'en' },
            { keyword: 'Toscane', title: 'Toscane', language: 'fr' },
            { keyword: 'Toscana', title: 'Toscana', language: 'it' },
        ],
        latLngBounds: new LatLngBounds(new LatLng(44.388256, 12.060216), new LatLng(42.378202, 10.003928)),
    },
    {
        linguisticLocationDetails: [
            { keyword: 'Bayrischer Wald', title: 'Bayrischer Wald', language: 'de' },
            { keyword: 'Bavarian Forest', title: 'Bavarian Forest', language: 'en' },
            { keyword: 'Forêt bavaroise', title: 'Forêt bavaroise', language: 'fr' },
            {
                keyword: 'Foresta bavarese',
                title: 'Foresta bavarese',
                language: 'it',
            },
        ],
        latLngBounds: new LatLngBounds(new LatLng(49.460761, 13.667302), new LatLng(48.525729, 12.8612)),
    },
    {
        linguisticLocationDetails: [
            { keyword: 'Entlebuch', title: 'Entlebuch', language: 'de' },
            { keyword: 'Entlebuch', title: 'Entlebuch', language: 'en' },
            { keyword: 'Entlebuch', title: 'Entlebuch', language: 'fr' },
            { keyword: 'Entlebuch', title: 'Entlebuch', language: 'it' },
        ],
        latLngBounds: new LatLngBounds(new LatLng(47.026537, 8.147337), new LatLng(46.77789, 7.866999)),
    },
    {
        linguisticLocationDetails: [
            { keyword: 'Glarnerland', title: 'Glarnerland', language: 'de' },
            { keyword: 'Glarus', title: 'Glarus', language: 'en' },
            { keyword: 'Glaris', title: 'Glaris', language: 'fr' },
            { keyword: 'Glarona', title: 'Glarona', language: 'it' },
        ],
        latLngBounds: new LatLngBounds(new LatLng(47.1593, 9.233644), new LatLng(46.796813, 8.8671)),
    },
    {
        linguisticLocationDetails: [
            { keyword: 'Ligurien', title: 'Ligurien', language: 'de' },
            { keyword: 'Liguria', title: 'Liguria', language: 'en' },
            { keyword: 'Ligurie', title: 'Ligurie', language: 'fr' },
            { keyword: 'Liguria', title: 'Liguria', language: 'it' },
        ],
        latLngBounds: new LatLngBounds(new LatLng(44.532233, 10.063008), new LatLng(43.778407, 7.526441)),
    },
    {
        linguisticLocationDetails: [
            { keyword: 'Ostschweiz', title: 'Ostschweiz', language: 'de' },
            {
                keyword: 'Eastern Switzerland',
                title: 'Eastern Switzerland',
                language: 'en',
            },
            {
                keyword: 'Suisse orientale',
                title: 'Suisse orientale',
                language: 'fr',
            },
            {
                keyword: 'Svizzera orientale',
                title: 'Svizzera orientale',
                language: 'it',
            },
        ],
        latLngBounds: new LatLngBounds(new LatLng(47.737361, 10.41261), new LatLng(46.185416, 8.730579)),
    },
    {
        linguisticLocationDetails: [
            { keyword: 'Walensee', title: 'Walensee', language: 'de' },
            { keyword: 'Lake Walen', title: 'Lake Walen', language: 'en' },
            { keyword: 'Lac de Walen', title: 'Lac de Walen', language: 'fr' },
            { keyword: 'Lago di Walen', title: 'Lago di Walen', language: 'it' },
        ],
        latLngBounds: new LatLngBounds(new LatLng(47.145562, 9.34376), new LatLng(47.101177, 9.080588)),
    },
    {
        linguisticLocationDetails: [
            { keyword: 'Emmental', title: 'Emmental', language: 'de' },
            { keyword: 'Emmental', title: 'Emmental', language: 'en' },
            { keyword: 'Emmental', title: 'Emmental', language: 'fr' },
            { keyword: 'Emmental', title: 'Emmental', language: 'it' },
        ],
        latLngBounds: new LatLngBounds(new LatLng(47.168017, 7.903477), new LatLng(46.796659, 7.539269)),
    },
];
const LOCAL_GEOCODER_ENTRIES_WITH_ID_AND_CENTER = LOCAL_GEOCODER_ENTRIES.map((entry, index) => (Object.assign(Object.assign({}, entry), { id: index, center: new LatLng((entry.latLngBounds.sw.lat + entry.latLngBounds.ne.lat) / 2, (entry.latLngBounds.sw.lng + entry.latLngBounds.ne.lng) / 2) })));
export const getLocalGeocoderPredictions = (query, state) => {
    if (query.length < 3) {
        return [];
    }
    const userLanguage = localeSelector(state).substring(0, 2);
    const relevantLanguages = [userLanguage, userLanguage === 'en' ? 'de' : 'en'];
    const matchingEntries = LOCAL_GEOCODER_ENTRIES_WITH_ID_AND_CENTER.filter(entry => {
        const relevantLinguisticLocationDetails = entry.linguisticLocationDetails.filter(linguisticLocationDetail => relevantLanguages.includes(linguisticLocationDetail.language));
        return relevantLinguisticLocationDetails.some(linguisticLocationDetail => linguisticLocationDetail.keyword
            .toLowerCase()
            .includes(query.toLowerCase()));
    });
    return matchingEntries.map(entry => {
        var _a, _b;
        return ({
            label: (_b = (_a = entry.linguisticLocationDetails.find(linguisticLocationDetail => linguisticLocationDetail.language === userLanguage)) === null || _a === void 0 ? void 0 : _a.title) !== null && _b !== void 0 ? _b : entry.linguisticLocationDetails[0].title,
            id: `${LOCAL_GEOCODER_ENTRY_ID_PREFIX}${entry.id}`,
        });
    });
};
export const getLocalGeocoderEntry = (id, state) => {
    var _a, _b;
    const userLanguage = localeSelector(state).substring(0, 2);
    const index = parseInt(id.split(LOCAL_GEOCODER_ENTRY_ID_PREFIX)[1], 10);
    const entry = LOCAL_GEOCODER_ENTRIES_WITH_ID_AND_CENTER[index];
    return Object.assign(Object.assign({}, entry), { label: (_b = (_a = entry.linguisticLocationDetails.find(linguisticLocationDetail => linguisticLocationDetail.language === userLanguage)) === null || _a === void 0 ? void 0 : _a.title) !== null && _b !== void 0 ? _b : entry.linguisticLocationDetails[0].title });
};
