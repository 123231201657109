const isClarityAvailable = () => typeof window.clarity === 'function';
const waitForClarity = (callback) => {
    const MAX_ATTEMPTS = 15;
    let attempts = 0;
    let interval = 200;
    const attemptConnection = () => {
        if (isClarityAvailable()) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            callback(window.clarity);
        }
        else if (attempts >= MAX_ATTEMPTS) {
            console.warn('Clarity is not available after 15 attempts, giving up');
        }
        else {
            attempts++;
            interval *= 2;
            setTimeout(attemptConnection, interval);
        }
    };
    attemptConnection();
};
export const setClarityCustomTag = (key, value) => {
    waitForClarity(clarity => {
        clarity('set', key, value);
    });
};
export const setClarityCustomUserId = (userId) => {
    waitForClarity(clarity => {
        clarity('identify', userId);
    });
};
