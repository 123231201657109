import { types } from '../../utils/sdkLoader';
import getGiftCardLineItems from './getGiftCardLineItems';
import { calculateTotalForCustomer } from '../../utils/lineItemHelpers';
import getTotalProviderPayoutValue from './getTotalProviderPayoutValue';
import getTotalCommission from './getTotalCommission';
import getEmailData from './getEmailData';
const { Money } = types;
export const formatValueForEmail = (value) => `${(value / 100).toFixed(2)}`;
const getTransactionProtectedData = (lineItems, totalTransactionValue, currency, giftCards, giftCardTransferAction, customerCommissionDiscountCode, paymentMode) => {
    const totalEmailFormat = formatValueForEmail(totalTransactionValue);
    const giftCardDeductionLineItems = getGiftCardLineItems(lineItems);
    let giftCardDeduction = new Money(0, currency);
    if (0 < giftCardDeductionLineItems.length) {
        giftCardDeduction = calculateTotalForCustomer(giftCardDeductionLineItems);
    }
    const giftCard = Math.abs(giftCardDeduction.amount);
    const stripePayment = totalTransactionValue - giftCard;
    const stripePaymentEmailFormat = formatValueForEmail(stripePayment);
    const payins = {
        giftCard,
        stripePayment,
        stripePaymentEmailFormat,
        total: totalTransactionValue,
        totalEmailFormat,
        currency,
    };
    const providerTotalPayout = getTotalProviderPayoutValue(lineItems).amount;
    const providerTotalPayoutEmailFormat = formatValueForEmail(providerTotalPayout);
    const commission = getTotalCommission(lineItems, currency);
    const payouts = {
        total: providerTotalPayout,
        totalEmailFormat: providerTotalPayoutEmailFormat,
        commission: commission.amount,
        currency,
    };
    const emailData = getEmailData(lineItems, currency);
    const protectedData = {
        payins,
        payouts,
        emailData,
        paymentMode,
    };
    if (giftCards) {
        protectedData.giftCards = giftCards;
    }
    if (giftCardTransferAction) {
        protectedData.giftCardTransferAction = giftCardTransferAction;
    }
    if (customerCommissionDiscountCode) {
        protectedData.customerCommissionDiscountCode =
            customerCommissionDiscountCode;
    }
    return protectedData;
};
export default getTransactionProtectedData;
