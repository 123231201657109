import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styled from 'styled-components/macro';
import { useIntl } from 'react-intl';
import Icon, { IconVariant, IconSizeVariant } from '../../Icon/Icon';
import Text, { TextVariant } from '../../Text/Text';
import { ColorVariant } from '../../../theme';
const Wrapper = styled.div `
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const ReviewRating = ({ rating = 0, numberOfRatings = 0, }) => {
    const intl = useIntl();
    const showNoRating = !rating || rating === 0;
    const ratingText = rating < 100 ? rating.toFixed(1) : (rating / 100).toFixed(1);
    return (_jsxs(Wrapper, { children: [_jsx(Icon, { variant: IconVariant.RatingStarFilled, sizeVariant: IconSizeVariant.Tiny, colorVariant: ColorVariant.FunctionOrangeRating }), _jsx(Text, Object.assign({ variant: TextVariant.L4SB, colorVariant: ColorVariant.FunctionOrangeRating }, { children: showNoRating
                    ? intl.formatMessage({
                        id: 'ReviewRating.new',
                    })
                    : ratingText })), !showNoRating && numberOfRatings !== undefined && (_jsx(Text, Object.assign({ variant: TextVariant.L4R, colorVariant: ColorVariant.FunctionFogGreyInactive }, { children: `(${numberOfRatings})` })))] }));
};
export default ReviewRating;
