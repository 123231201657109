import { GroundType } from '@nomady/shared/types';
const getAccessGroundSearchParam = (ground) => {
    const values = [];
    if (ground === null || ground === void 0 ? void 0 : ground.lawn) {
        values.push(GroundType.lawn);
    }
    if (ground === null || ground === void 0 ? void 0 : ground.tarmac) {
        values.push(GroundType.asphalt);
    }
    if (ground === null || ground === void 0 ? void 0 : ground.gravelOrChippedWood) {
        values.push(GroundType.gravel);
    }
    if (values.length === 0) {
        return undefined;
    }
    return values.join(',');
};
export default getAccessGroundSearchParam;
