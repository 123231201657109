import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import styled from 'styled-components/macro';
import { DefaultTheme } from '../../theme';
import { getImageAltText } from '../../util/imageHelper';
const Wrapper = styled.div `
  position: relative;
  margin: 0 auto;

  ${props => props.theme.layout.container}
  padding-inline: 0 !important;
`;
const Picture = styled.img `
  height: 140px;
  width: 100%;
  object-fit: cover;
  cursor: pointer;

  border-radius: 5px;

  @media ${props => props.theme.device.tablet} {
    height: 250px;
    width: 100%;
  }

  @media ${props => props.theme.device.desktop} {
    height: 100%;
  }
`;
const Pictures = styled.div `
  width: 100%;
  overflow-x: auto;
  display: grid;

  grid-template-columns: 1fr 1fr;
  column-gap: 10px;
  row-gap: 10px;

  padding: 10px;

  @media ${props => props.theme.device.desktop} {
    max-width: 1024px;
    margin: 0 auto;

    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-auto-rows: 150px;

    ${Picture}:nth-child(10n + 1) {
      grid-column-start: 1;
      grid-column-end: span 2;
      grid-row-end: span 2;
    }

    ${Picture}:nth-child(10n + 8) {
      grid-column-start: 3;
      grid-column-end: span 2;
      grid-row-end: span 2;
    }
  }

  @media ${props => props.theme.device.desktopL} {
    max-width: 1110px;

    grid-auto-rows: 165px;
  }
`;
const PictureOverview = ({ images, altTexts = {}, fallbackAltText, onOpenPicture, }) => {
    return (_jsx(Wrapper, { children: _jsx(Pictures, Object.assign({ "data-testid": "PictureOverview_Pictures" }, { children: images.map((image) => {
                var _a, _b, _c, _d;
                return (_jsx(Picture, { src: (_a = image.attributes.variants['scaled-small']) === null || _a === void 0 ? void 0 : _a.url, srcSet: `${(_b = image.attributes.variants['scaled-small']) === null || _b === void 0 ? void 0 : _b.url} 300w, ${(_c = image.attributes.variants['scaled-medium']) === null || _c === void 0 ? void 0 : _c.url} 600w`, sizes: `${DefaultTheme.device.mobile} 100vw,
              ${DefaultTheme.device.tablet} 75vw,
              ${DefaultTheme.device.desktop} 50vw`, alt: (_d = getImageAltText(image, altTexts)) !== null && _d !== void 0 ? _d : fallbackAltText, onClick: () => onOpenPicture && onOpenPicture(image.id) }, image.id.uuid));
            }) })) }));
};
export default PictureOverview;
