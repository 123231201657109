const getFeaturesFilterCount = (state) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17, _18, _19, _20, _21, _22, _23, _24, _25, _26, _27, _28, _29, _30, _31, _32, _33, _34, _35, _36, _37, _38, _39, _40, _41, _42, _43, _44, _45, _46, _47, _48, _49, _50, _51, _52, _53, _54, _55, _56, _57, _58, _59, _60, _61, _62, _63, _64, _65, _66, _67, _68, _69, _70, _71, _72, _73, _74, _75, _76, _77, _78, _79, _80, _81, _82, _83, _84, _85, _86;
    const { listingSearch: { currentSearchQuery: { features }, }, } = state;
    if (!features)
        return 0;
    const numberOfFilters = [
        (_a = features.bookingAndCancellation) === null || _a === void 0 ? void 0 : _a.instantBooking,
        (_b = features.bookingAndCancellation) === null || _b === void 0 ? void 0 : _b.cancellationPolicyFlex,
        (_c = features.bookingAndCancellation) === null || _c === void 0 ? void 0 : _c.cancellationPolicySuperflex,
        (_d = features.bookingAndCancellation) === null || _d === void 0 ? void 0 : _d.verifiedHost,
        (_e = features.bookingAndCancellation) === null || _e === void 0 ? void 0 : _e.flexibleCancellation,
        features.fireplaceAvailable,
        features.dogsAllowed,
        features.dogCamp,
        features.familyFriendly,
        features.farmShop,
        (_f = features.fireplace) === null || _f === void 0 ? void 0 : _f.grate,
        (_g = features.fireplace) === null || _g === void 0 ? void 0 : _g.firewoodIncluded,
        (_h = features.fireplace) === null || _h === void 0 ? void 0 : _h.firewoodSale,
        (_j = features.fireplace) === null || _j === void 0 ? void 0 : _j.guestBringsFirewoodAlong,
        (_l = (_k = features.activities) === null || _k === void 0 ? void 0 : _k.winter) === null || _l === void 0 ? void 0 : _l.skiing,
        (_o = (_m = features.activities) === null || _m === void 0 ? void 0 : _m.winter) === null || _o === void 0 ? void 0 : _o.langlauf,
        (_q = (_p = features.activities) === null || _p === void 0 ? void 0 : _p.winter) === null || _q === void 0 ? void 0 : _q.crossCountrySkiing,
        (_s = (_r = features.activities) === null || _r === void 0 ? void 0 : _r.winter) === null || _s === void 0 ? void 0 : _s.skiTouring,
        (_u = (_t = features.activities) === null || _t === void 0 ? void 0 : _t.winter) === null || _u === void 0 ? void 0 : _u.snowHiking,
        (_w = (_v = features.activities) === null || _v === void 0 ? void 0 : _v.winter) === null || _w === void 0 ? void 0 : _w.hockey,
        (_y = (_x = features.activities) === null || _x === void 0 ? void 0 : _x.winter) === null || _y === void 0 ? void 0 : _y.iceRink,
        (_0 = (_z = features.activities) === null || _z === void 0 ? void 0 : _z.winter) === null || _0 === void 0 ? void 0 : _0.sledding,
        (_2 = (_1 = features.activities) === null || _1 === void 0 ? void 0 : _1.winter) === null || _2 === void 0 ? void 0 : _2.buildingIgloos,
        (_4 = (_3 = features.activities) === null || _3 === void 0 ? void 0 : _3.summer) === null || _4 === void 0 ? void 0 : _4.biking,
        (_6 = (_5 = features.activities) === null || _5 === void 0 ? void 0 : _5.summer) === null || _6 === void 0 ? void 0 : _6.hiking,
        (_8 = (_7 = features.activities) === null || _7 === void 0 ? void 0 : _7.summer) === null || _8 === void 0 ? void 0 : _8.climbing,
        (_10 = (_9 = features.activities) === null || _9 === void 0 ? void 0 : _9.summer) === null || _10 === void 0 ? void 0 : _10.canoeing,
        (_12 = (_11 = features.activities) === null || _11 === void 0 ? void 0 : _11.summer) === null || _12 === void 0 ? void 0 : _12.swimming,
        (_14 = (_13 = features.activities) === null || _13 === void 0 ? void 0 : _13.summer) === null || _14 === void 0 ? void 0 : _14.fishing,
        (_16 = (_15 = features.activities) === null || _15 === void 0 ? void 0 : _15.summer) === null || _16 === void 0 ? void 0 : _16.observeWildAnimals,
        ((_17 = features.price) === null || _17 === void 0 ? void 0 : _17.price) && features.price.price.length > 0,
        (_19 = (_18 = features.facilities) === null || _18 === void 0 ? void 0 : _18.basics) === null || _19 === void 0 ? void 0 : _19.water,
        (_22 = (_21 = (_20 = features.facilities) === null || _20 === void 0 ? void 0 : _20.basics) === null || _21 === void 0 ? void 0 : _21.powerSupply) === null || _22 === void 0 ? void 0 : _22.smallDevices,
        (_25 = (_24 = (_23 = features.facilities) === null || _23 === void 0 ? void 0 : _23.basics) === null || _24 === void 0 ? void 0 : _24.powerSupply) === null || _25 === void 0 ? void 0 : _25.campingVehicles,
        (_28 = (_27 = (_26 = features.facilities) === null || _26 === void 0 ? void 0 : _26.basics) === null || _27 === void 0 ? void 0 : _27.toilet) === null || _28 === void 0 ? void 0 : _28.regularToilet,
        (_31 = (_30 = (_29 = features.facilities) === null || _29 === void 0 ? void 0 : _29.basics) === null || _30 === void 0 ? void 0 : _30.toilet) === null || _31 === void 0 ? void 0 : _31.portableToilet,
        (_34 = (_33 = (_32 = features.facilities) === null || _32 === void 0 ? void 0 : _32.basics) === null || _33 === void 0 ? void 0 : _33.toilet) === null || _34 === void 0 ? void 0 : _34.closeToCampsite,
        (_36 = (_35 = features.facilities) === null || _35 === void 0 ? void 0 : _35.accommodation) === null || _36 === void 0 ? void 0 : _36.millionStars,
        (_38 = (_37 = features.facilities) === null || _37 === void 0 ? void 0 : _37.accommodation) === null || _38 === void 0 ? void 0 : _38.heating,
        (_40 = (_39 = features.facilities) === null || _39 === void 0 ? void 0 : _39.accommodation) === null || _40 === void 0 ? void 0 : _40.linen,
        (_42 = (_41 = features.facilities) === null || _41 === void 0 ? void 0 : _41.shower) === null || _42 === void 0 ? void 0 : _42.regularShower,
        (_44 = (_43 = features.facilities) === null || _43 === void 0 ? void 0 : _43.shower) === null || _44 === void 0 ? void 0 : _44.outdoorShower,
        (_46 = (_45 = features.facilities) === null || _45 === void 0 ? void 0 : _45.miscellaneous) === null || _46 === void 0 ? void 0 : _46.kitchen,
        (_48 = (_47 = features.facilities) === null || _47 === void 0 ? void 0 : _47.miscellaneous) === null || _48 === void 0 ? void 0 : _48.picnicTable,
        (_50 = (_49 = features.facilities) === null || _49 === void 0 ? void 0 : _49.miscellaneous) === null || _50 === void 0 ? void 0 : _50.bin,
        (_52 = (_51 = features.facilities) === null || _51 === void 0 ? void 0 : _51.miscellaneous) === null || _52 === void 0 ? void 0 : _52.dishwashing,
        (_54 = (_53 = features.facilities) === null || _53 === void 0 ? void 0 : _53.miscellaneous) === null || _54 === void 0 ? void 0 : _54.grayWaterDisposal,
        (_56 = (_55 = features.facilities) === null || _55 === void 0 ? void 0 : _55.miscellaneous) === null || _56 === void 0 ? void 0 : _56.shelter,
        (_58 = (_57 = features.facilities) === null || _57 === void 0 ? void 0 : _57.miscellaneous) === null || _58 === void 0 ? void 0 : _58.internetHotspot,
        (_60 = (_59 = features.facilities) === null || _59 === void 0 ? void 0 : _59.miscellaneous) === null || _60 === void 0 ? void 0 : _60.mobileReception,
        (_61 = features.facilities) === null || _61 === void 0 ? void 0 : _61.store,
        (_62 = features.facilities) === null || _62 === void 0 ? void 0 : _62.winterCamping,
        (_63 = features.ambiance) === null || _63 === void 0 ? void 0 : _63.shadowy,
        (_64 = features.ambiance) === null || _64 === void 0 ? void 0 : _64.forest,
        (_65 = features.ambiance) === null || _65 === void 0 ? void 0 : _65.lawn,
        (_66 = features.ambiance) === null || _66 === void 0 ? void 0 : _66.riverOrCreek,
        (_67 = features.ambiance) === null || _67 === void 0 ? void 0 : _67.lake,
        (_68 = features.ambiance) === null || _68 === void 0 ? void 0 : _68.mountains,
        (_69 = features.ambiance) === null || _69 === void 0 ? void 0 : _69.farm,
        (_70 = features.ambiance) === null || _70 === void 0 ? void 0 : _70.restaurant,
        (_71 = features.ambiance) === null || _71 === void 0 ? void 0 : _71.road,
        (_73 = (_72 = features.access) === null || _72 === void 0 ? void 0 : _72.access) === null || _73 === void 0 ? void 0 : _73.regularVehicle,
        (_75 = (_74 = features.access) === null || _74 === void 0 ? void 0 : _74.access) === null || _75 === void 0 ? void 0 : _75.groundClearanceSuggested,
        (_77 = (_76 = features.access) === null || _76 === void 0 ? void 0 : _76.access) === null || _77 === void 0 ? void 0 : _77.offRoader,
        (_79 = (_78 = features.access) === null || _78 === void 0 ? void 0 : _78.ground) === null || _79 === void 0 ? void 0 : _79.lawn,
        (_81 = (_80 = features.access) === null || _80 === void 0 ? void 0 : _80.ground) === null || _81 === void 0 ? void 0 : _81.tarmac,
        (_83 = (_82 = features.access) === null || _82 === void 0 ? void 0 : _82.ground) === null || _83 === void 0 ? void 0 : _83.gravelOrChippedWood,
        ...Array((_85 = (_84 = features.campStyle) === null || _84 === void 0 ? void 0 : _84.category) === null || _85 === void 0 ? void 0 : _85.length).fill(true),
        (_86 = features.campStyle) === null || _86 === void 0 ? void 0 : _86.private,
    ].filter(Boolean).length;
    return numberOfFilters;
};
export default getFeaturesFilterCount;
