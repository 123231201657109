import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import styled from 'styled-components/macro';
import { IconSizeVariant } from '../Icon/Icon';
import Text, { TextVariant } from '../Text/Text';
const Wrapper = styled.div `
  background-color: ${props => props.theme.color[props.backgroundColor]};
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${props => props.sizeVariant === IconSizeVariant.ExtraExtraSmall && '3px;'}
    ${props => props.sizeVariant === IconSizeVariant.ExtraSmall && '3px;'}
    ${props => props.sizeVariant === IconSizeVariant.Small && '3px;'}
    ${props => props.sizeVariant === IconSizeVariant.Default && '3px;'}
    ${props => props.sizeVariant === IconSizeVariant.Medium && '8px;'}
    ${props => props.sizeVariant === IconSizeVariant.Large && '15px;'}
    ${props => props.sizeVariant === IconSizeVariant.ExtraLarge && '25px;'};
`;
const StyledText = styled(Text) `
  font-size: ${props => props.sizeVariant === IconSizeVariant.ExtraExtraSmall && '9px;'}
    ${props => props.sizeVariant === IconSizeVariant.ExtraSmall && '10px;'}
    ${props => props.sizeVariant === IconSizeVariant.Small && '15px;'}
    ${props => props.sizeVariant === IconSizeVariant.Default && '15px;'}
    ${props => props.sizeVariant === IconSizeVariant.Medium && '23px;'}
    ${props => props.sizeVariant === IconSizeVariant.Large && '42px;'}
    ${props => props.sizeVariant === IconSizeVariant.ExtraLarge && '100px;'};
`;
const LabeledIcon = ({ content, textColor, backgroundColor, sizeVariant, }) => {
    return (_jsx(Wrapper, Object.assign({ backgroundColor: backgroundColor, sizeVariant: sizeVariant }, { children: _jsx(StyledText, Object.assign({ variant: TextVariant.B30, sizeVariant: sizeVariant, colorVariant: textColor }, { children: content })) })));
};
export default LabeledIcon;
