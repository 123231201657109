import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components/macro';
import { FormattedMessage } from '../../util/reactIntl';
import Button, { ButtonColorVariant, ButtonSizeVariant, } from '../Button/Button';
import { IconVariant } from '../Icon/Icon';
const Wrapper = styled.div `
  display: flex;
  align-items: center;
  flex-basis: 0;
  background-color: ${props => props.theme.color.rose};
  justify-content: center;

  padding: 18px;
`;
const Message = styled.p `
  color: ${props => props.theme.color.pearl};
`;
const ButtonWrapper = styled.div `
  flex: 0;
  margin-left: 20px;
`;
const LimitedAccessBanner = ({ currentUser, onLogout }) => {
    var _a;
    const { firstName, lastName } = ((_a = currentUser === null || currentUser === void 0 ? void 0 : currentUser.attributes) === null || _a === void 0 ? void 0 : _a.profile) || {};
    const intl = useIntl();
    return (_jsxs(Wrapper, { children: [_jsx(Message, { children: _jsx(FormattedMessage, { id: "LimitedAccessBanner.message", values: { firstName, lastName } }) }), _jsx(ButtonWrapper, { children: _jsx(Button, { onClick: onLogout, colorVariant: ButtonColorVariant.Light, sizeVariant: ButtonSizeVariant.Small, label: intl.formatMessage({ id: 'LimitedAccessBanner.logout' }), icon: IconVariant.Logout }) })] }));
};
export default LimitedAccessBanner;
