var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import styled from 'styled-components/macro';
import Page from '@nomady/shared/routeConfiguration/Page';
import { EMAIL_REGEX } from '@nomady/shared/utils/validation';
import Input from '../../styled-components/Input/Input';
import Button, { ButtonColorVariant, } from '../../styled-components/Button/Button';
import Text, { TextVariant } from '../../styled-components/Text/Text';
import FormErrorMessage from '../../styled-components/FormErrorMessage/FormErrorMessage';
import { loginInProgressSelector, login } from '../../ducks/Auth.duck';
import { ColorVariant } from '../../theme';
import { PASSWORD_MAX_LENGTH, PASSWORD_MIN_LENGTH, } from '../../util/validators';
import { NamedLink } from '../../components';
const Title = styled(Text) `
  margin-bottom: 20px;
`;
const Form = styled.form `
  padding-bottom: 100px;
`;
const FormRow = styled.div `
  margin-bottom: 10px;
`;
const AdditionalLinksWrapper = styled.div `
  text-align: center;
  margin-top: 12px;
`;
const ButtonCenteredWrap = styled.div `
  width: fit-content;
  margin: 30px auto;
`;
const LoginForm = ({ isFullPageSheet, onClickAccountCreation, onClickPasswordRecovery, onClickMigrateFromMyCabin, }) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const loginInProgress = useSelector(loginInProgressSelector);
    const loginError = useSelector((state) => state.Auth.loginError);
    const { register, handleSubmit, formState: { errors }, } = useForm();
    const _a = register('email', {
        required: true,
        validate: {
            trim: value => value.trim() !== '' || 'Input.validationMessage.email',
            pattern: value => EMAIL_REGEX.test(value.trim()) || 'Input.validationMessage.email',
        },
    }), { ref: emailRef } = _a, email = __rest(_a, ["ref"]);
    const _b = register('password', {
        required: true,
        minLength: {
            value: PASSWORD_MIN_LENGTH,
            message: 'Input.validationMessage.passwordTooShort',
        },
        maxLength: {
            value: PASSWORD_MAX_LENGTH,
            message: 'Input.validationMessage.passwordTooLong',
        },
    }), { ref: passwordRef } = _b, password = __rest(_b, ["ref"]);
    const onSuccess = (data) => __awaiter(void 0, void 0, void 0, function* () {
        const trimmedEmail = data.email.trim();
        const trimmedPassword = data.password.trim();
        dispatch(login(trimmedEmail, trimmedPassword));
    });
    const passwordRecoveryText = (_jsx(Text, Object.assign({ variant: TextVariant.H11B, colorVariant: ColorVariant.Cave }, { children: _jsx("u", { children: intl.formatMessage({
                id: 'LoginForm.forgotPasswordCta',
            }) }) })));
    const passwordRecoveryLink = isFullPageSheet && onClickPasswordRecovery ? (_jsx("span", Object.assign({ "data-testid": "LoginPage_passwordRecoveryLink", onClick: onClickPasswordRecovery }, { children: passwordRecoveryText }))) : (_jsx(NamedLink, Object.assign({ name: Page.PasswordRecovery }, { children: passwordRecoveryText })));
    const accountCreationText = (_jsx(Text, Object.assign({ variant: TextVariant.H11B, colorVariant: ColorVariant.Cave }, { children: _jsx("u", { children: intl.formatMessage({
                id: 'LoginForm.noAccountYetCta',
            }) }) })));
    const accountCreationLink = isFullPageSheet && onClickAccountCreation ? (_jsx("span", Object.assign({ "data-testid": "LoginPage_accountCreationLink", onClick: onClickAccountCreation }, { children: accountCreationText }))) : (_jsx(NamedLink, Object.assign({ name: Page.Signup }, { children: accountCreationText })));
    const myCabinMigrationText = (_jsx(Text, Object.assign({ variant: TextVariant.H11B, colorVariant: ColorVariant.Cave }, { children: _jsx("u", { children: intl.formatMessage({
                id: 'LoginForm.formerlyMyCabinCta',
            }) }) })));
    const myCabinMigrationLink = isFullPageSheet && onClickMigrateFromMyCabin ? (_jsx("span", Object.assign({ "data-testid": "LoginPage_myCabinMigrationLink", onClick: onClickMigrateFromMyCabin }, { children: myCabinMigrationText }))) : (_jsx(NamedLink, Object.assign({ name: Page.MyCabin }, { children: myCabinMigrationText })));
    return (_jsxs(Form, Object.assign({ onSubmit: handleSubmit(onSuccess) }, { children: [_jsx(Title, Object.assign({ variant: TextVariant.H20, as: "h2" }, { children: intl.formatMessage({ id: 'LoginForm.title' }) })), _jsx(FormRow, { children: _jsx(Input, Object.assign({ label: intl.formatMessage({ id: 'LoginForm.emailLabel' }), id: "email", autoComplete: "email" }, email, { inputRef: emailRef, error: errors === null || errors === void 0 ? void 0 : errors.email })) }), _jsx(FormRow, { children: _jsx(Input, Object.assign({ label: intl.formatMessage({
                        id: 'LoginForm.passwordLabel',
                    }), id: "password", type: "password", autoComplete: "new-password" }, password, { inputRef: passwordRef, error: errors === null || errors === void 0 ? void 0 : errors.password })) }), !!loginError && _jsx(FormErrorMessage, { id: 'LoginForm.loginFailed' }), _jsx(ButtonCenteredWrap, { children: _jsx(Button, { dataTestId: "LoginPage_LoginButton", label: intl.formatMessage({
                        id: 'LoginForm.primaryButtonLabel',
                    }), colorVariant: ButtonColorVariant.Dark, type: "submit", inProgress: loginInProgress }) }), _jsxs(_Fragment, { children: [_jsx(AdditionalLinksWrapper, { children: _jsx(Text, Object.assign({ variant: TextVariant.H12B, colorVariant: ColorVariant.Cave }, { children: intl.formatMessage({
                                id: 'LoginForm.formerlyMyCabin',
                            }, { migrateLink: myCabinMigrationLink }) })) }), _jsx(AdditionalLinksWrapper, { children: _jsx(Text, Object.assign({ variant: TextVariant.H12B, colorVariant: ColorVariant.Cave }, { children: intl.formatMessage({
                                id: 'LoginForm.noAccountYetText',
                            }, { signUpLink: accountCreationLink }) })) }), _jsx(AdditionalLinksWrapper, { children: _jsx(Text, Object.assign({ variant: TextVariant.H12B, colorVariant: ColorVariant.Cave }, { children: intl.formatMessage({
                                id: 'LoginForm.forgotPasswordText',
                            }, { passwordRecoveryLink }) })) })] })] })));
};
export default LoginForm;
