import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { useIntl } from 'react-intl';
import Button, { ButtonColorVariant, ButtonSizeVariant, } from '../styled-components/Button/Button';
import Text from '../styled-components/Text/Text';
const UsercentricsContext = React.createContext({});
const ConsentNotGivenWrapper = styled.div `
  background-color: ${props => props.theme.color.pearl};
  width: 100%;
  height: inherit;
  height: 100%;
  color: ${props => props.theme.color.cave};

  display: flex;
  border: 5px solid ${props => props.theme.color.cave};
  padding: 10px;
  align-items: center;
  justify-content: center;
`;
const Content = styled.div `
  max-width: 400px;
`;
const ConsentNotGivenText = styled(Text) `
  padding-bottom: 16px;
`;
const isBrowser = typeof window !== 'undefined';
const UsercentricsProvider = ({ children }) => {
    const [services, setServices] = useState({});
    const consentEventListener = (event) => {
        if (event.detail.event === 'consent_status') {
            setServices(event.detail);
        }
    };
    useEffect(() => {
        if (isBrowser) {
            window.addEventListener('ucEvent', consentEventListener);
        }
        return () => {
            if (isBrowser) {
                window.removeEventListener('ucEvent', consentEventListener);
            }
        };
    }, []);
    return (_jsx(UsercentricsContext.Provider, Object.assign({ value: services }, { children: children })));
};
export default UsercentricsProvider;
export const EnsureUsercentricsConsent = ({ services, children }) => {
    const intl = useIntl();
    return (_jsx(UsercentricsContext.Consumer, { children: usercentricServices => services.every(service => usercentricServices[service] === true) ? (children) : (_jsx(ConsentNotGivenWrapper, { children: _jsxs(Content, { children: [_jsx(ConsentNotGivenText, Object.assign({ as: "p" }, { children: intl.formatMessage({ id: 'ConsentNotGiven.text' }, { services: services.join(', ') }) })), _jsx(Button, { onClick: () => { var _a; return (_a = window.UC_UI) === null || _a === void 0 ? void 0 : _a.showSecondLayer(); }, type: "button", colorVariant: ButtonColorVariant.Dark, sizeVariant: ButtonSizeVariant.Small, label: intl.formatMessage({ id: 'ConsentNotGiven.ctaLabel' }) })] }) })) }));
};
