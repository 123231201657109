import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import isNull from 'lodash/isNull';
import moment from 'moment';
import styled, { css } from 'styled-components/macro';
import { ALL_AVAILABILITY_VARIANTS, ALL_BOOKING_RANGE_VARIANTS, CalendarDay, DayVariant, } from '../CalendarDay/CalendarDay';
import SkeletonLoader, { SkeletonLoaderVariant, } from '../SkeletonLoader/SkeletonLoader';
import Text, { TextVariant } from '../Text/Text';
const Wrapper = styled.div `
  display: flex;
  flex-direction: row;
`;
const SingleMonthWrap = styled.div `
  display: grid;
  grid-template-columns: repeat(7, 42px);
  height: max-content;

  @media ${props => props.theme.device.tablet} {
    grid-template-columns: repeat(7, 48px);
  }

  ${props => 0 < props.monthIndex && `display:none;`}

  @media ${props => props.theme.device.desktop} {
    display: grid;

    ${props => props.compact &&
    `
    grid-template-columns: repeat(7, 38px);
    `}

    ${props => props.compact &&
    0 < props.monthIndex &&
    `  margin-left: 16px;
    `}
  }
`;
const DayWrapper = styled.button `
  width: 42px;
  height: 42px;
  line-height: 42px;
  padding: 0;
  box-sizing: border-box;
  border: none;
  text-align: center;
  align-items: center;
  background-color: transparent;

  @media ${props => props.theme.device.tablet} {
    width: 48px;
    height: 48px;
    line-height: 48px;
  }

  ${props => props.variant &&
    ALL_AVAILABILITY_VARIANTS.includes(props.variant) &&
    css `
      padding: 2px;
    `}

  ${props => ((props.variant && ALL_BOOKING_RANGE_VARIANTS.includes(props.variant)) ||
    props.compact) &&
    css `
      margin: 3px;
      height: unset;
      width: unset;
      line-height: 36px;

      @media ${props => props.theme.device.tablet} {
        width: unset;
        height: unset;
        line-height: 42px;
      }
    `}

  @media ${props => props.theme.device.tablet} {
    ${props => props.variant &&
    ALL_AVAILABILITY_VARIANTS.includes(props.variant) &&
    css `
        padding: 4px;
      `}

    ${props => props.variant &&
    ALL_BOOKING_RANGE_VARIANTS.includes(props.variant) &&
    css `
        margin: 4px;
        height: 56px;
        width: 56px;
        line-height: 56px;
      `}
  }

  @media ${props => props.theme.device.desktop} {
    ${props => props.variant &&
    ALL_BOOKING_RANGE_VARIANTS.includes(props.variant) &&
    css `
        margin: 3px;
        height: 36px;
        width: 36px;
        line-height: 36px;
      `}
  }

  ${props => props.variant === DayVariant.BOOKING_INTERMEDIATE_RANGE_START &&
    css `
      width: 39px;
      margin-right: 0;
      padding-right: 3px;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      background: ${props => props.theme.color.lagoonLight};
      ${props.isEndOfTheWeek &&
        css `
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
      `};
    `}

  ${props => props.variant === DayVariant.BOOKING_INTERMEDIATE_RANGE_END &&
    css `
      width: 39px;
      margin-left: 0;
      padding-left: 3px;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      background: ${props => props.theme.color.lagoonLight};
      ${props.isStartOfTheWeek &&
        css `
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      `};
    `}

  ${props => props.variant === DayVariant.BOOKING_INTERMEDIATE_RANGE_IN_BETWEEN &&
    css `
      width: 100%;
      margin-left: 0;
      margin-right: 0;
      background: ${props => props.theme.color.lagoonLight};

      ${props.isEndOfTheWeek &&
        css `
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
      `};
      ${props.isStartOfTheWeek &&
        css `
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      `};
    `}

  ${props => props.variant === DayVariant.BOOKING_RANGE_START &&
    css `
      width: 39px;
      margin-right: 0;
      padding-right: 3px;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      background: ${props => props.theme.color.lagoon};
      ${props.isEndOfTheWeek &&
        css `
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
      `};
    `}

  ${props => props.variant === DayVariant.BOOKING_RANGE_IN_BETWEEN &&
    css `
      width: 100%;
      margin-left: 0;
      margin-right: 0;
      background: ${props => props.theme.color.lagoon};

      ${props.isEndOfTheWeek &&
        css `
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
      `};
      ${props.isStartOfTheWeek &&
        css `
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      `};
    `}

  ${props => props.variant === DayVariant.BOOKING_RANGE_END &&
    css `
      width: 39px;
      margin-left: 0;
      padding-left: 3px;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      background: ${props => props.theme.color.lagoon};
      ${props.isStartOfTheWeek &&
        css `
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      `};
    `}

  ${props => props.variant === DayVariant.DEFAULT &&
    css `
      padding: 2px;
    `}

  @media ${props => props.theme.device.tablet} {
    ${props => (props.variant === DayVariant.BOOKING_RANGE_END ||
    props.variant === DayVariant.BOOKING_RANGE_START ||
    props.variant === DayVariant.BOOKING_INTERMEDIATE_RANGE_START ||
    props.variant === DayVariant.BOOKING_INTERMEDIATE_RANGE_END) &&
    css `
        width: 60px;
      `}
    ${props => (props.variant === DayVariant.BOOKING_INTERMEDIATE_RANGE_START ||
    props.variant === DayVariant.BOOKING_RANGE_START) &&
    css `
        margin-right: 0;
        padding-right: 4px;
      `};
    ${props => (props.variant === DayVariant.BOOKING_INTERMEDIATE_RANGE_END ||
    props.variant === DayVariant.BOOKING_RANGE_END) &&
    css `
        padding-left: 4px;
        margin-left: 0;
      `};
    ${props => (props.variant === DayVariant.BOOKING_INTERMEDIATE_RANGE_IN_BETWEEN ||
    props.variant === DayVariant.BOOKING_RANGE_IN_BETWEEN) &&
    css `
        margin-left: 0;
        margin-right: 0;
        width: 100%;
      `};
  }

  @media ${props => props.theme.device.desktop} {
    ${props => (props.variant === DayVariant.BOOKING_RANGE_END ||
    props.variant === DayVariant.BOOKING_RANGE_START ||
    props.variant === DayVariant.BOOKING_INTERMEDIATE_RANGE_START ||
    props.variant === DayVariant.BOOKING_INTERMEDIATE_RANGE_END) &&
    css `
        width: 36px;
      `}
    ${props => (props.variant === DayVariant.BOOKING_INTERMEDIATE_RANGE_START ||
    props.variant === DayVariant.BOOKING_RANGE_START) &&
    css `
        width: 39px;
        margin-right: 0;
        padding-right: 3px;
      `};
    ${props => (props.variant === DayVariant.BOOKING_INTERMEDIATE_RANGE_END ||
    props.variant === DayVariant.BOOKING_RANGE_END) &&
    css `
        width: 39px;
        padding-left: 3px;
        margin-left: 0;
      `};
    ${props => (props.variant === DayVariant.BOOKING_INTERMEDIATE_RANGE_IN_BETWEEN ||
    props.variant === DayVariant.BOOKING_RANGE_IN_BETWEEN) &&
    css `
        margin-left: 0;
        margin-right: 0;
        width: 100%;
      `};
  }
`;
export const getDaysOfMonth = (month) => {
    const daysInMonth = month.clone().daysInMonth();
    const day = month.clone().startOf('month');
    return [...Array(daysInMonth).keys()].map((index) => ({
        date: day.clone().add(index, 'days'),
    }));
};
const getOffsetDays = (daysInMonth) => {
    var _a;
    const todayWeekday = (_a = daysInMonth[0]) === null || _a === void 0 ? void 0 : _a.date.clone().day();
    const IS_SUNDAY = todayWeekday === 0;
    const offsetWeekday = !IS_SUNDAY ? todayWeekday - 1 : 6;
    return [...Array(offsetWeekday).keys()];
};
const CalendarMonth = ({ months, onClickDay, loading, onHoverDay, timeSlots = null, compact = false, }) => {
    const dayHeaders = [1, 2, 3, 4, 5, 6, 7].map(day => ({
        day,
        label: moment().day(day).format('dd'),
    }));
    const isDayInTimeSlot = (day) => {
        if (isNull(timeSlots) || timeSlots.length === 0)
            return true;
        return timeSlots.some(timeSlot => timeSlot.isSame(day.date, 'day'));
    };
    const isEndOfTheWeek = (day) => {
        const tmp = moment(day.date.toISOString());
        return (tmp.locale('de').utc().endOf('week').startOf('day').toISOString() ===
            day.date.toISOString());
    };
    const isStartOfTheWeek = (day) => {
        const tmp = moment(day.date.toISOString());
        return (tmp.locale('de').utc().startOf('week').toISOString() ===
            day.date.toISOString());
    };
    return (_jsx(Wrapper, { children: months.map((month, index) => (_jsxs(SingleMonthWrap, Object.assign({ monthIndex: index, compact: compact }, { children: [dayHeaders.map(dayHead => {
                    return (_jsx(DayWrapper, Object.assign({ type: "button", variant: DayVariant.DEFAULT, compact: compact, disabled: true }, { children: _jsx(Text, Object.assign({ variant: TextVariant.H13 }, { children: dayHead.label })) }), dayHead.label));
                }), getOffsetDays(month).map(index => {
                    return (_jsx(DayWrapper, { type: "button", variant: DayVariant.DEFAULT, compact: compact, disabled: true }, `empty-day-${index}`));
                }), month.map(day => {
                    var _a, _b, _c, _d, _e, _f;
                    return (_jsxs(DayWrapper, Object.assign({ "data-testid": `CalendarDay_${day.date.format('DD-MM-YYYY')}`, onPointerEnter: () => onHoverDay && onHoverDay(day), onClick: () => !loading && isDayInTimeSlot(day) && onClickDay(day), variant: day.variant, compact: compact, type: "button", isEndOfTheWeek: isEndOfTheWeek(day), isStartOfTheWeek: isStartOfTheWeek(day) }, { children: [loading && (_jsx(SkeletonLoader, { variant: SkeletonLoaderVariant.CalendarDay })), !loading && (_jsx(CalendarDay, { isToday: moment().isSame(day.date, 'day'), variant: day.variant, label: day.date.clone().format('DD'), blockedSeats: day.blockedSeats &&
                                    day.blockedSeats < (day.totalSeats || 0) &&
                                    1 < (day.totalSeats || 0)
                                    ? day.blockedSeats
                                    : undefined, bookedSeats: 1 < (day.totalSeats || 0) &&
                                    day.bookings &&
                                    ((_a = day.bookings.filter(booking => { var _a; return ((_a = booking === null || booking === void 0 ? void 0 : booking.attributes) === null || _a === void 0 ? void 0 : _a.state) === 'accepted'; })) === null || _a === void 0 ? void 0 : _a.length) > 0
                                    ? (_c = (_b = day.bookings) === null || _b === void 0 ? void 0 : _b.filter(booking => booking.attributes.state === 'accepted')) === null || _c === void 0 ? void 0 : _c.length
                                    : undefined, pendingSeats: 1 < (day.totalSeats || 0) &&
                                    day.bookings &&
                                    ((_d = day.bookings.filter(booking => { var _a; return ((_a = booking === null || booking === void 0 ? void 0 : booking.attributes) === null || _a === void 0 ? void 0 : _a.state) === 'pending'; })) === null || _d === void 0 ? void 0 : _d.length) > 0
                                    ? (_f = (_e = day.bookings) === null || _e === void 0 ? void 0 : _e.filter(booking => booking.attributes.state === 'pending')) === null || _f === void 0 ? void 0 : _f.length
                                    : undefined }))] }), day.date.dayOfYear()));
                })] }), month[0].date.toISOString()))) }));
};
export default CalendarMonth;
