import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import * as Select from '@radix-ui/react-select';
import styled from 'styled-components/macro';
import Text, { TextVariant } from '../Text/Text';
import Icon, { IconSizeVariant, IconVariant } from '../Icon/Icon';
import { useIntl } from 'react-intl';
import Button2024, { ButtonSizeVariant, ButtonVariant, } from '../Button2024/Button2024';
const RadixSelectContent = styled(Select.Content) `
  overflow: hidden;
  background-color: ${props => props.theme.color.basicPearlWhiteTypeWhite};
  border-radius: 16px;
  box-shadow: 0px 10px 38px -10px rgba(22, 23, 24, 0.35),
    0px 10px 20px -15px rgba(22, 23, 24, 0.2);
  ${props => props.theme.priority.always};
`;
const RadixSelectViewport = styled(Select.Viewport) `
  padding: 4px;
`;
const RadixSelectItem = styled(Select.Item) `
  display: flex;
  border-radius: 16px;
  align-items: center;
  padding: 4px 8px 4px 30px;
  position: relative;
  position: relative;
  user-select: none;

  &[data-disabled] {
    color: ${props => props.theme.color.functionFogGreyInactive};
    pointer-events: none;
  }

  &[data-highlighted] {
    outline: none;
    background-color: ${props => props.theme.color.primaryFirGreen};
    color: ${props => props.theme.color.basicPearlWhiteTypeWhite};

    svg path {
      fill: ${props => props.theme.color.basicPearlWhiteTypeWhite};
    }
  }
`;
const RadixSelectItemIndicator = styled(Select.ItemIndicator) `
  position: absolute;
  left: 8px;
`;
const RadixSelectScrollUpButton = styled(Select.ScrollUpButton) `
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: default;
`;
const RadixSelectScrollDownButton = styled(Select.ScrollDownButton) `
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: default;
`;
export const RadixSelect = ({ selected, options, onChange, }) => {
    const intl = useIntl();
    return (_jsxs(Select.Root, Object.assign({ onValueChange: onChange, value: selected }, { children: [_jsx(Select.Trigger, Object.assign({ asChild: true }, { children: _jsx(Button2024, Object.assign({ variant: ButtonVariant.Tertiary, sizeVariant: ButtonSizeVariant.Small, iconOnLeft: IconVariant.Arrow_S_down }, { children: _jsx(Select.Value, {}) })) })), _jsx(Select.Portal, { children: _jsxs(RadixSelectContent, Object.assign({ ref: ref => {
                        if (!ref)
                            return;
                        ref.ontouchstart = e => {
                            e.preventDefault();
                        };
                    } }, { children: [_jsx(RadixSelectScrollUpButton, { children: _jsx(Icon, { variant: IconVariant.Arrow_S_up, sizeVariant: IconSizeVariant.Small }) }), _jsx(RadixSelectViewport, { children: options.map(option => (_jsxs(RadixSelectItem, Object.assign({ value: option.key }, { children: [_jsx(Select.ItemText, Object.assign({ asChild: true }, { children: _jsx(Text, Object.assign({ variant: TextVariant.L2R }, { children: intl.formatMessage({ id: option.intlKey }) })) })), _jsx(RadixSelectItemIndicator, { children: _jsx(Icon, { variant: IconVariant.Check_Bold, sizeVariant: IconSizeVariant.Tiny }) })] }), option.key))) }), _jsx(RadixSelectScrollDownButton, { children: _jsx(Icon, { variant: IconVariant.Arrow_S_down, sizeVariant: IconSizeVariant.Small }) })] })) })] })));
};
