var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createSlice } from '@reduxjs/toolkit';
import { DEFAULT_LOCALE, getDeeplLocale, } from '@nomady/shared/marketplace-custom-config';
import { legacyMarketplaceLocaleMap, } from '@nomady/shared/config';
import { isBrowser } from '@nomady/shared/utils/env';
import { isApp } from '../util/deviceContext';
export var ModalId;
(function (ModalId) {
    ModalId["AddToFavorites"] = "add-to-favorites";
})(ModalId || (ModalId = {}));
const initialState = {
    locale: undefined,
    autoTranslation: true,
    menuOpen: false,
    scrollingPosition: [],
    lastOpenModal: undefined,
    disableScroll: false,
    safeSpaceTop: 0,
    safeSpaceBottom: 0,
};
export const UISlice = createSlice({
    name: 'app/UI',
    initialState,
    reducers: {
        switchLocale: (state, action) => {
            state.locale = action.payload;
        },
        switchAutoTranslation: (state, action) => {
            state.autoTranslation = action.payload;
        },
        setScrollingPosition: (state, action) => {
            const { pathname, scrollingPosition } = action.payload;
            const maxLength = 10;
            const allPathnames = state.scrollingPosition.map(page => page.pathname);
            const newScrollingPosition = !allPathnames.includes(pathname)
                ? [...state.scrollingPosition, { pathname, scrollingPosition }]
                : state.scrollingPosition.map(page => page.pathname === pathname ? Object.assign(Object.assign({}, page), { scrollingPosition }) : page);
            if (allPathnames.includes(pathname)) {
                // send element to the end of the array
                const index = newScrollingPosition.findIndex(page => page.pathname === pathname);
                const element = newScrollingPosition[index];
                newScrollingPosition.splice(index, 1);
                newScrollingPosition.push(element);
            }
            if (newScrollingPosition.length >= maxLength) {
                newScrollingPosition.shift();
            }
            return Object.assign(Object.assign({}, state), { scrollingPosition: newScrollingPosition });
        },
        setMenuOpen: (state, action) => {
            state.menuOpen = action.payload;
            state.disableScroll = action.payload;
        },
        setLastOpenModal: (state, action) => {
            state.lastOpenModal = action.payload;
        },
        setDisableScroll: (state, action) => {
            state.disableScroll = action.payload;
        },
        setSafeSpaceTop: (state, action) => {
            state.safeSpaceTop = action.payload;
        },
        setSafeSpaceBottom: (state, action) => {
            state.safeSpaceBottom = action.payload;
        },
    },
});
export const { setScrollingPosition, setMenuOpen, setLastOpenModal, switchAutoTranslation, setDisableScroll, setSafeSpaceTop, setSafeSpaceBottom, } = UISlice.actions;
export default UISlice.reducer;
export const switchLocale = (locale) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    dispatch(UISlice.actions.switchLocale(legacyMarketplaceLocaleMap[locale]));
    if (isBrowser()) {
        yield ((_a = window.UC_UI) === null || _a === void 0 ? void 0 : _a.updateLanguage(locale.substring(0, 2)));
    }
    if (isApp) {
        const { default: OneSignal } = yield import('onesignal-cordova-plugin');
        OneSignal.User.setLanguage(locale.substring(0, 2).toLowerCase());
    }
});
export const localeSelector = (state) => state.UI.locale || DEFAULT_LOCALE;
export const menuOpenSelector = (state) => state.UI.menuOpen;
export const lastOpenModalData = (state) => state.UI.lastOpenModal;
export const scrollPositionSelector = (state, currentPathname) => {
    var _a;
    return (((_a = state.UI.scrollingPosition.find(scrollingPosition => scrollingPosition.pathname === currentPathname)) === null || _a === void 0 ? void 0 : _a.scrollingPosition) || 0);
};
export const disableScrollSelector = (state) => state.UI.disableScroll;
export const apiLocaleSelector = (state, forceOriginal) => {
    var _a, _b, _c, _d, _e;
    if (forceOriginal) {
        return undefined;
    }
    const loggedInUser = (_a = state === null || state === void 0 ? void 0 : state.user) === null || _a === void 0 ? void 0 : _a.currentUser;
    if (loggedInUser) {
        const loggedInUserEnabledAutomaticTranslation = (_d = (_c = (_b = loggedInUser.attributes) === null || _b === void 0 ? void 0 : _b.profile) === null || _c === void 0 ? void 0 : _c.privateData) === null || _d === void 0 ? void 0 : _d.automaticTranslationEnabled;
        if (loggedInUserEnabledAutomaticTranslation) {
            return getDeeplLocale((_e = loggedInUser.attributes.profile.privateData) === null || _e === void 0 ? void 0 : _e.locale);
        }
        if (state.UI.locale) {
            return getDeeplLocale(state.UI.locale);
        }
        return undefined;
    }
    const visitorAutoTranslationEnabled = state.UI.autoTranslation;
    if (visitorAutoTranslationEnabled && state.UI.locale) {
        return getDeeplLocale(state.UI.locale);
    }
    if (state.UI.locale) {
        return getDeeplLocale(state.UI.locale);
    }
    return undefined;
};
export const getInitialPageTranslationActiveSelector = (state) => {
    var _a, _b, _c, _d, _e, _f, _g;
    const isLoggedIn = (_a = state.user) === null || _a === void 0 ? void 0 : _a.currentUser;
    const loggedInUserEnabledAutomaticTranslation = !!((_f = (_e = (_d = (_c = (_b = state.user) === null || _b === void 0 ? void 0 : _b.currentUser) === null || _c === void 0 ? void 0 : _c.attributes) === null || _d === void 0 ? void 0 : _d.profile) === null || _e === void 0 ? void 0 : _e.privateData) === null || _f === void 0 ? void 0 : _f.automaticTranslationEnabled);
    const visitorEnabledAutoTranslation = !!((_g = state.UI) === null || _g === void 0 ? void 0 : _g.autoTranslation);
    return isLoggedIn
        ? loggedInUserEnabledAutomaticTranslation
        : visitorEnabledAutoTranslation;
};
export const safeSpaceTopSelector = (state) => state.UI.safeSpaceTop;
export const safeSpaceBottomSelector = (state) => state.UI.safeSpaceBottom;
