export var LINE_ITEM_GROUP;
(function (LINE_ITEM_GROUP) {
    LINE_ITEM_GROUP["BASE_PRICE"] = "basePrice";
    LINE_ITEM_GROUP["ADDITIONAL_GUESTS"] = "additionalGuests";
    LINE_ITEM_GROUP["TAXES_AND_FEES"] = "taxesAndFees";
    LINE_ITEM_GROUP["ADDITIONAL_SERVICES"] = "additionalServices";
    LINE_ITEM_GROUP["DISCOUNT"] = "discount";
    LINE_ITEM_GROUP["PAYMENT"] = "payment";
})(LINE_ITEM_GROUP || (LINE_ITEM_GROUP = {}));
export var CODE_TYPE;
(function (CODE_TYPE) {
    CODE_TYPE["GIFT_CARD"] = "gift-card";
    CODE_TYPE["DISCOUNT_CODE"] = "discount-code";
    CODE_TYPE["CUSTOMER_COMMISSION_DISCOUNT_CODE"] = "customer-commission-discount-code";
})(CODE_TYPE || (CODE_TYPE = {}));
