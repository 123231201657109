export const DEFAULT_ADULT_QUANTITY = 1;
export const DEFAULT_SEAT_QUANTITY = 1;
export var GIFT_CARD_TRANSFER_ACTION;
(function (GIFT_CARD_TRANSFER_ACTION) {
    GIFT_CARD_TRANSFER_ACTION["NO_TRANSFER"] = "0_no-transfer";
    GIFT_CARD_TRANSFER_ACTION["FULL_COMMISSION_FULL_PROVIDER_PAYOUT"] = "1_full-commission_full-provider-payout";
    GIFT_CARD_TRANSFER_ACTION["REMAINING_COMMISSION"] = "2_remaining-commission";
    GIFT_CARD_TRANSFER_ACTION["FULL_PROVIDER_PAYOUT"] = "3_full-provider-payout";
    GIFT_CARD_TRANSFER_ACTION["REMAINING_COMMISSION_FULL_PROVIDER_PAYOUT"] = "4_remaining-commission_full-provider-payout";
})(GIFT_CARD_TRANSFER_ACTION || (GIFT_CARD_TRANSFER_ACTION = {}));
export var MarketplaceCurrency;
(function (MarketplaceCurrency) {
    MarketplaceCurrency["CHF"] = "CHF";
    MarketplaceCurrency["EUR"] = "EUR";
})(MarketplaceCurrency || (MarketplaceCurrency = {}));
export const MARKETPLACE_CURRENCY = MarketplaceCurrency.CHF;
export const MARKETPLACE_CURRENCIES = [
    MarketplaceCurrency.CHF,
    MarketplaceCurrency.EUR,
];
export var MarketplaceShippingCountry;
(function (MarketplaceShippingCountry) {
    MarketplaceShippingCountry["DE"] = "DE";
    MarketplaceShippingCountry["CH_EU"] = "CH_EU";
})(MarketplaceShippingCountry || (MarketplaceShippingCountry = {}));
export const MARKETPLACE_SHIPPING_COUNTRIES = [
    MarketplaceShippingCountry.DE,
    MarketplaceShippingCountry.CH_EU,
];
export var MarketplaceLocale;
(function (MarketplaceLocale) {
    MarketplaceLocale["en-US"] = "en-US";
    MarketplaceLocale["de-DE"] = "de-DE";
    MarketplaceLocale["de-CH"] = "de-CH";
    MarketplaceLocale["de-AT"] = "de-AT";
    MarketplaceLocale["fr-FR"] = "fr-FR";
    MarketplaceLocale["fr-CH"] = "fr-CH";
    MarketplaceLocale["it-IT"] = "it-IT";
    MarketplaceLocale["it-CH"] = "it-CH";
})(MarketplaceLocale || (MarketplaceLocale = {}));
export var SitemapMarketplaceLocale;
(function (SitemapMarketplaceLocale) {
    SitemapMarketplaceLocale["en-US"] = "en-US";
    SitemapMarketplaceLocale["de-DE"] = "de-DE";
    SitemapMarketplaceLocale["fr-FR"] = "fr-FR";
    SitemapMarketplaceLocale["it-IT"] = "it-IT";
})(SitemapMarketplaceLocale || (SitemapMarketplaceLocale = {}));
export const legacyMarketplaceLocaleMap = {
    [MarketplaceLocale['en-US']]: SitemapMarketplaceLocale['en-US'],
    [MarketplaceLocale['de-DE']]: SitemapMarketplaceLocale['de-DE'],
    [MarketplaceLocale['de-CH']]: SitemapMarketplaceLocale['de-DE'],
    [MarketplaceLocale['de-AT']]: SitemapMarketplaceLocale['de-DE'],
    [MarketplaceLocale['fr-FR']]: SitemapMarketplaceLocale['fr-FR'],
    [MarketplaceLocale['fr-CH']]: SitemapMarketplaceLocale['fr-FR'],
    [MarketplaceLocale['it-IT']]: SitemapMarketplaceLocale['it-IT'],
    [MarketplaceLocale['it-CH']]: SitemapMarketplaceLocale['it-IT'],
};
export var SUPPORTED_TAX_COUNTRIES;
(function (SUPPORTED_TAX_COUNTRIES) {
    SUPPORTED_TAX_COUNTRIES["CH"] = "CH";
    SUPPORTED_TAX_COUNTRIES["LI"] = "LI";
})(SUPPORTED_TAX_COUNTRIES || (SUPPORTED_TAX_COUNTRIES = {}));
export const COUNTRY_TAX_RATES = {
    CH: 8.1,
    LI: 8.1,
};
export const BOOKING_PROCESS_NAME = 'sca-preauth-nightly-line-items-booking';
export const GOOGLE_MAPS_LISTING_SEARCH_CONFIG = {
    types: ['(regions)'],
};
export const MAXIMUM_NUMBER_OF_ACTIVE_LISTING_FAVORITES_LISTS = 10;
export const MAXIMUM_LENGTH_OF_LISTING_FAVORITES_LIST_NAME = 40;
export var TOURIST_TAX_REPORT_TYPE;
(function (TOURIST_TAX_REPORT_TYPE) {
    TOURIST_TAX_REPORT_TYPE["Monthly"] = "monthly";
    TOURIST_TAX_REPORT_TYPE["Biannual"] = "biannual";
    TOURIST_TAX_REPORT_TYPE["Annual"] = "annual";
})(TOURIST_TAX_REPORT_TYPE || (TOURIST_TAX_REPORT_TYPE = {}));
export const maps = {
    currentLocationBoundsDistance: 14000,
};
export var PaymentMode;
(function (PaymentMode) {
    PaymentMode["Legacy"] = "legacy";
    PaymentMode["Custom"] = "custom";
})(PaymentMode || (PaymentMode = {}));
