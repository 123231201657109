var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getPlacePredictions, getPlaceDetails, locationBounds, } from '@nomady/shared/utils/googleMaps';
import { userLocation } from '../../util/maps';
import config from '../../config';
export const CURRENT_LOCATION_ID = 'current-location';
/**
 * A forward geocoding (place name -> coordinates) implementation
 * using the Google Maps Places API.
 */
class GeocoderGoogleMaps {
    constructor() {
        this.getSessionToken = () => {
            this.sessionToken =
                this.sessionToken ||
                    new window.google.maps.places.AutocompleteSessionToken();
            return this.sessionToken;
        };
        // Public API
        //
        /**
         * Search places with the given name.
         *
         * @param {String} search query for place names
         * @param {Object} searchConfigurations - defines the search configurations that can be used with
         * the autocomplete service. Used to restrict search to specific country (or countries).
         *
         *
         * @return {Promise<{ search: String, predictions: Array<Object>}>}
         * results of the geocoding, should have the original search query
         * and an array of predictions. The format of the predictions is
         * only relevant for the `getPlaceDetails` function below.
         */
        this.getPlacePredictions = (search, searchConfigurations) => {
            return getPlacePredictions(search, this.getSessionToken(), searchConfigurations).then(results => {
                return {
                    search,
                    predictions: results.predictions,
                };
            });
        };
        /**
         * Fetch or read place details from the selected prediction.
         *
         * @param {Object} prediction selected prediction object
         *
         * @return {Promise<util.propTypes.place>} a place object
         */
        this.getPlaceDetails = (prediction) => __awaiter(this, void 0, void 0, function* () {
            if (GeocoderGoogleMaps.getPredictionId(prediction) === CURRENT_LOCATION_ID) {
                return userLocation().then(latlng => {
                    return {
                        address: '',
                        origin: latlng,
                        bounds: locationBounds(latlng, config.maps.search.currentLocationBoundsDistance),
                    };
                });
            }
            return getPlaceDetails(prediction.place_id, this.getSessionToken()).then(place => {
                this.sessionToken = null;
                return place;
            });
        });
        this.sessionToken = null;
    }
}
/**
 * Get the ID of the given prediction.
 */
GeocoderGoogleMaps.getPredictionId = (prediction) => {
    return prediction.place_id;
};
/**
 * Get the address text of the given prediction.
 */
GeocoderGoogleMaps.getPredictionAddress = (prediction) => {
    return prediction.description;
};
export default GeocoderGoogleMaps;
