var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createSlice, } from '@reduxjs/toolkit';
import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { queryTransactions } from '../../util/api';
const initialState = {
    fetchPastTransactionsError: null,
    fetchPastTransactionsInProgress: false,
    pastTransactionRefs: [],
    fetchFutureTransactionsError: null,
    fetchFutureTransactionsInProgress: false,
    futureTransactionRefs: [],
    currentFuturePage: 1,
    currentFuturePageSize: 10,
    currentPastPage: 1,
    currentPastPageSize: 10,
};
export const JourneysPageSlice = createSlice({
    name: 'app/JourneysPage',
    initialState,
    reducers: {
        fetchPastTransactionsRequest: state => {
            state.fetchPastTransactionsInProgress = true;
            state.fetchPastTransactionsError = null;
        },
        fetchPastTransactionsSuccess: (state, action) => {
            state.fetchPastTransactionsInProgress = false;
            state.pastTransactionRefs = action.payload.transactionRefs;
            state.pastPagination = action.payload.pagination;
        },
        fetchPastTransactionsError: (state, action) => {
            state.fetchPastTransactionsInProgress = false;
            state.fetchPastTransactionsError = action.payload;
        },
        fetchFutureTransactionsRequest: state => {
            state.fetchFutureTransactionsInProgress = true;
            state.fetchFutureTransactionsError = null;
        },
        fetchFutureTransactionsSuccess: (state, action) => {
            state.fetchFutureTransactionsInProgress = false;
            state.futureTransactionRefs = action.payload.transactionRefs;
            state.futurePagination = action.payload.pagination;
        },
        fetchFutureTransactionsError: (state, action) => {
            state.fetchFutureTransactionsInProgress = false;
            state.fetchFutureTransactionsError = action.payload;
        },
        setFuturePage: (state, action) => {
            state.currentFuturePage = action.payload;
        },
        setPastPage: (state, action) => {
            state.currentPastPage = action.payload;
        },
    },
});
export const { fetchFutureTransactionsRequest, fetchFutureTransactionsSuccess, fetchFutureTransactionsError, setFuturePage, fetchPastTransactionsRequest, fetchPastTransactionsSuccess, fetchPastTransactionsError, setPastPage, } = JourneysPageSlice.actions;
export const requestFutureTransactions = () => (dispatch, getState, sdk) => __awaiter(void 0, void 0, void 0, function* () {
    dispatch(fetchFutureTransactionsRequest());
    const state = getState();
    const { currentFuturePage, currentFuturePageSize } = state.JourneysPage;
    const params = {
        only: 'order-future',
        include: [
            'provider',
            'provider.profileImage',
            'customer',
            'customer.profileImage',
            'booking',
            'listing',
            'listing.images',
        ],
        'fields.transaction': [
            'lastTransition',
            'lastTransitionedAt',
            'transitions',
            'lineItems',
            'payinTotal',
            'payoutTotal',
            'protectedData',
        ],
        'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
        'fields.image': ['variants.square-small', 'variants.square-small2x'],
        page: currentFuturePage,
        perPage: currentFuturePageSize,
    };
    try {
        const response = yield queryTransactions(params);
        dispatch(addMarketplaceEntities(response));
        const transactionRefs = response.data.data.map((listing) => ({
            id: listing.id,
            type: listing.type,
        }));
        const pagination = response.data.meta;
        dispatch(fetchFutureTransactionsSuccess({ transactionRefs, pagination }));
    }
    catch (e) {
        dispatch(fetchFutureTransactionsError(storableError(e)));
    }
});
export const requestPastTransactions = () => (dispatch, getState, sdk) => __awaiter(void 0, void 0, void 0, function* () {
    dispatch(fetchPastTransactionsRequest());
    const state = getState();
    const { currentPastPageSize, currentPastPage } = state.JourneysPage;
    const params = {
        only: 'order-past',
        include: [
            'provider',
            'provider.profileImage',
            'customer',
            'customer.profileImage',
            'booking',
            'listing',
            'listing.images',
        ],
        'fields.transaction': [
            'lastTransition',
            'lastTransitionedAt',
            'transitions',
            'lineItems',
            'payinTotal',
            'payoutTotal',
            'protectedData',
        ],
        'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
        'fields.image': ['variants.square-small', 'variants.square-small2x'],
        page: currentPastPage,
        perPage: currentPastPageSize,
    };
    try {
        const response = yield queryTransactions(params);
        dispatch(addMarketplaceEntities(response));
        const transactionRefs = response.data.data.map((listing) => ({
            id: listing.id,
            type: listing.type,
        }));
        const pagination = response.data.meta;
        dispatch(fetchPastTransactionsSuccess({ transactionRefs, pagination }));
    }
    catch (e) {
        dispatch(fetchPastTransactionsError(storableError(e)));
    }
});
export const loadData = () => (dispatch, _) => {
    dispatch(requestPastTransactions());
    return dispatch(requestFutureTransactions());
};
export default JourneysPageSlice.reducer;
