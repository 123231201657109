import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styled from 'styled-components/macro';
import Text, { TextVariant } from '../../Text/Text';
import { ColorVariant } from '../../../theme';
import Logo from '../../Logo/Logo';
const IntroContentWrapper = styled.div `
  padding-top: 16px;
  padding-left: 32px;
  padding-right: 32px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 32px;
  height: 100%;
  overflow-y: auto;
  flex: 1;
`;
const IntroSheetContent = ({ customLogo, title, content, }) => {
    return (_jsxs(IntroContentWrapper, { children: [customLogo ? (customLogo) : (_jsx(Logo, { responsive: true, colorVariant: ColorVariant.Cave })), _jsx(Text, Object.assign({ variant: TextVariant.H1, as: "h3" }, { children: title })), content] }));
};
export default IntroSheetContent;
