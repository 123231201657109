import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styled, { css } from 'styled-components/macro';
import { ColorVariant } from '../../theme';
import Icon, { IconVariant } from '../Icon/Icon';
import SkeletonLoader, { SkeletonLoaderVariant, } from '../SkeletonLoader/SkeletonLoader';
import Text, { TextVariant } from '../Text/Text';
export var TypeVariant;
(function (TypeVariant) {
    TypeVariant["ProfilePicture"] = "ProfilePicture";
    TypeVariant["Initials"] = "Initial";
    TypeVariant["Anonymous"] = "Anonymous";
})(TypeVariant || (TypeVariant = {}));
export var SizeVariant;
(function (SizeVariant) {
    SizeVariant["XXS"] = "XXS";
    SizeVariant["XS"] = "XS";
    SizeVariant["S"] = "S";
    SizeVariant["L"] = "L";
    SizeVariant["XL"] = "XL";
    SizeVariant["Default"] = "L";
})(SizeVariant || (SizeVariant = {}));
const PictureWrapper = styled.div `
  position: relative;
  border-radius: 50%;
  display: flex;
  width: min-content;
  justify-content: center;
  align-items: center;

  ${props => props.theme.typeset.H10}

  color: ${props => props.theme.color.pearl};
  background: ${props => props.theme.color[props.colorVariant]};

  ${props => props.sizeVariant === SizeVariant.XXS &&
    css `
      height: 32px;
      width: 32px;
    `}

  ${props => props.sizeVariant === SizeVariant.XS &&
    css `
      height: 36px;
      width: 36px;
    `}

  ${props => props.sizeVariant === SizeVariant.S &&
    css `
      height: 54px;
      width: 54px;
    `}

  ${props => props.sizeVariant === SizeVariant.L &&
    css `
      height: 80px;
      width: 80px;
    `}

  ${props => props.sizeVariant === SizeVariant.XL &&
    css `
      height: 160px;
      width: 160px;
    `}

    ${props => props.responsive &&
    `
      height: 36px;
      width: 36px;

      @media ${props.theme.device.tablet} {
        height: 54px;
        width: 54px;
      }
    `}
`;
const Wrapper = styled.div `
  position: relative;
  ${props => props.backgroundSource &&
    css `
      background-image: url(${props.backgroundSource});
    `}

  background-color: ${props => props.theme.color.stone};
  background-size: cover;
  background-position: center center;
  border-radius: 50%;

  ${props => props.sizeVariant === SizeVariant.XXS &&
    css `
      height: 32px;
      width: 32px;
    `}

  ${props => props.sizeVariant === SizeVariant.XS &&
    css `
      height: 36px;
      width: 36px;
    `}

  ${props => props.sizeVariant === SizeVariant.S &&
    css `
      height: 54px;
      width: 54px;
    `}

  ${props => props.sizeVariant === SizeVariant.L &&
    css `
      height: 54px;
      width: 54px;

      @media ${props.theme.device.tablet} {
        height: 80px;
        width: 80px;
      }
    `}

  ${props => props.sizeVariant === SizeVariant.XL &&
    css `
      height: 160px;
      width: 160px;
    `}

    ${props => props.responsive &&
    `
      height: 36px;
      width: 36px;

      @media ${props.theme.device.tablet} {
        height: 54px;
        width: 54px;
      }
    `}
`;
const NotificationBadge = styled.div `
  position: absolute;
  height: 16px;
  min-width: 16px;
  top: -4px;
  left: -8px;
  text-align: center;
  background-color: ${props => props.theme.color.cinnabar};
  padding: 2px;
  border-radius: 50%;
  ${props => props.theme.typeset.NotificationBadge}
`;
const Avatar = ({ source, initials, sizeVariant = SizeVariant.Default, notificationCount, responsive, isLoading = false, }) => {
    const hasNotifications = !!(notificationCount && 0 < notificationCount);
    if (isLoading) {
        return (_jsx(Wrapper, Object.assign({ sizeVariant: sizeVariant, responsive: responsive }, { children: _jsx(SkeletonLoader, { variant: SkeletonLoaderVariant.Avatar }) })));
    }
    if (source) {
        return (_jsx(Wrapper, Object.assign({ sizeVariant: sizeVariant, backgroundSource: source, responsive: responsive }, { children: hasNotifications && (_jsx(NotificationBadge, { children: notificationCount })) })));
    }
    if (initials) {
        return (_jsxs(PictureWrapper, Object.assign({ sizeVariant: sizeVariant, colorVariant: ColorVariant.Fir, responsive: responsive }, { children: [_jsx(Text, Object.assign({ variant: TextVariant.H10 }, { children: initials })), hasNotifications && (_jsx(NotificationBadge, { children: notificationCount }))] })));
    }
    return (_jsx(PictureWrapper, Object.assign({ sizeVariant: sizeVariant, colorVariant: ColorVariant.Cinnabar, responsive: responsive }, { children: _jsx(Icon, { variant: IconVariant.User, colorVariant: ColorVariant.Pearl }) })));
};
export default Avatar;
