import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components/macro';
import { activities, winterActivities, } from '@nomady/shared/marketplace-custom-config';
import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { IconVariant } from '../../../styled-components/Icon/Icon';
import ListingChecklist from '../../../styled-components/ListingChecklist';
const Wrapper = styled.div `
  display: flex;
  gap: 40px;
  flex-direction: column;
`;
const ActivitiesContent = () => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    const { listing } = useSelector((state) => state.ListingPage);
    const intl = useIntl();
    const hasWinterCapability = (_b = (_a = listing === null || listing === void 0 ? void 0 : listing.attributes) === null || _a === void 0 ? void 0 : _a.publicData) === null || _b === void 0 ? void 0 : _b.winterCapability;
    const { summer, winter } = ((_e = (_d = (_c = listing === null || listing === void 0 ? void 0 : listing.attributes) === null || _c === void 0 ? void 0 : _c.publicData) === null || _d === void 0 ? void 0 : _d.localizedPublicData) === null || _e === void 0 ? void 0 : _e.seasons) || {};
    const summerActivitiesDescription = summer === null || summer === void 0 ? void 0 : summer.activitiesDescription;
    const activeActivitiesTypes = ((_g = (_f = listing === null || listing === void 0 ? void 0 : listing.attributes) === null || _f === void 0 ? void 0 : _f.publicData) === null || _g === void 0 ? void 0 : _g.activities) || [];
    const summerActivitiesElements = activities.map(activity => ({
        isChecked: activeActivitiesTypes.includes(activity.key),
        text: intl.formatMessage({ id: activity.label }),
    }));
    if (summerActivitiesDescription) {
        summerActivitiesElements.push({
            isChecked: true,
            text: summerActivitiesDescription,
        });
    }
    const winterActivitiesDescription = winter === null || winter === void 0 ? void 0 : winter.activitiesDescription;
    const activeWinterActivitiesTypes = ((_j = (_h = listing === null || listing === void 0 ? void 0 : listing.attributes) === null || _h === void 0 ? void 0 : _h.publicData) === null || _j === void 0 ? void 0 : _j.winterActivities) || [];
    const winterActivitiesElements = winterActivities.map(activity => ({
        isChecked: activeWinterActivitiesTypes.includes(activity.key),
        text: intl.formatMessage({ id: activity.label }),
    }));
    if (winterActivitiesDescription) {
        winterActivitiesElements.push({
            isChecked: true,
            text: winterActivitiesDescription,
        });
    }
    return (_jsxs(Wrapper, { children: [_jsx(ListingChecklist, { items: summerActivitiesElements, iconVariant: IconVariant.Boot, title: intl.formatMessage({ id: 'ListingPage.summerActivitiesTitle' }) }), hasWinterCapability && (_jsx(ListingChecklist, { items: winterActivitiesElements, iconVariant: IconVariant.Sledge, title: intl.formatMessage({
                    id: 'ListingPage.winterActivitiesTitle',
                }) }))] }));
};
export default ActivitiesContent;
