import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styled from 'styled-components/macro';
import Icon, { IconVariant } from '../Icon/Icon';
import Text, { TextVariant } from '../Text/Text';
import { NamedLink } from '../../components';
const Wrapper = styled.section `
  display: flex;
  align-items: center;
  max-width: 100%;

  &:hover {
    text-decoration: underline;
    color: ${props => props.theme.color.cave};
  }
`;
const Name = styled(Text) `
  margin-left: 10px;
  color: ${props => props.theme.color.cave};

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const ListingLink = ({ id, listingName, }) => {
    return (_jsx(NamedLink, Object.assign({ name: "ListingPageCanonical", params: { id } }, { children: _jsxs(Wrapper, { children: [_jsx(Icon, { variant: IconVariant.Postcard }), _jsx(Name, Object.assign({ variant: TextVariant.H13 }, { children: listingName }))] }) })));
};
export default ListingLink;
