var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { useDispatch, useSelector } from 'react-redux';
import FullPageSheet from '../../../../styled-components/FullPageSheet/FullPageSheet';
import LoginForm from '../../../../forms/LoginForm/LoginForm';
import PasswordRecoveryForm from '../../../../forms/PasswordRecoveryForm/PasswordRecoveryForm';
import CreateAccountForm from '../../../../forms/CreateAccountForm/CreateAccountForm';
import EmailVerificationCta from '../../../../styled-components/EmailVerificationCta/EmailVerificationCta';
import { fetchCurrentUser } from '../../../../ducks/user.duck';
import queryCurrentUserEmailVerification from '../../../../util/emailVerificationHelper';
import { updateUserLastLogin } from '../../../../util/api';
import { MigrationForm } from '../../../../styled-components/SectionMigrateMyCabinUser/components/MigrationForm';
import { useIntl } from 'react-intl';
const Wrapper = styled.div `
  padding: 50px 25px;
  margin: 40px auto;
  max-width: 600px;
`;
const MigrationCard = styled.div `
  padding: 32px;
  border-radius: 16px;
  background-color: ${props => props.theme.color.fir};
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: ${props => props.theme.color.pearl};
`;
const AuthenticationSheet = ({ open, onClose }) => {
    var _a;
    const dispatch = useDispatch();
    const state = useSelector((state) => state);
    const { isAuthenticated } = state.Auth;
    const { emailVerified } = ((_a = state.user.currentUser) === null || _a === void 0 ? void 0 : _a.attributes) || {};
    const intl = useIntl();
    const [showPasswordRecoveryForm, setShowPasswordRecoveryForm] = useState(false);
    const [showAccountCreationForm, setShowAccountCreationForm] = useState(false);
    const [showMyCabinMigrationForm, setShowMyCabinMigrationForm] = useState(false);
    const onClickLogin = () => {
        setShowPasswordRecoveryForm(false);
        setShowAccountCreationForm(false);
        setShowMyCabinMigrationForm(false);
    };
    const onClickPasswordRecovery = () => {
        setShowPasswordRecoveryForm(true);
    };
    const onClickAccountCreation = () => {
        setShowAccountCreationForm(true);
    };
    const onClickMigrateFromMyCabin = () => {
        setShowMyCabinMigrationForm(true);
    };
    const isAuthenticatedAndNotVerified = isAuthenticated && emailVerified === false;
    useEffect(() => {
        if (isAuthenticated && emailVerified && open) {
            onClose();
        }
        if (isAuthenticated &&
            !showPasswordRecoveryForm &&
            !showAccountCreationForm &&
            !showMyCabinMigrationForm) {
            updateUserLastLogin();
        }
    }, [isAuthenticated, emailVerified, open]);
    const onClickRefreshVerification = () => __awaiter(void 0, void 0, void 0, function* () {
        const hasUpdatedVerification = yield queryCurrentUserEmailVerification();
        if (hasUpdatedVerification) {
            dispatch(fetchCurrentUser());
        }
        return !!hasUpdatedVerification;
    });
    return (_jsx(FullPageSheet, Object.assign({ onClose: () => {
            setShowAccountCreationForm(false);
            setShowPasswordRecoveryForm(false);
            setShowMyCabinMigrationForm(false);
            onClose();
        }, open: open }, { children: _jsx(Wrapper, { children: isAuthenticatedAndNotVerified ? (_jsx(EmailVerificationCta, { onClickRefreshVerification: onClickRefreshVerification })) : (_jsxs(_Fragment, { children: [showPasswordRecoveryForm && (_jsx(PasswordRecoveryForm, { isFullPageSheet: true, onClickLogin: onClickLogin })), showAccountCreationForm && (_jsx(CreateAccountForm, { isFullPageSheet: true, onClickLogin: onClickLogin })), !showPasswordRecoveryForm &&
                        !showAccountCreationForm &&
                        !showMyCabinMigrationForm && (_jsx(LoginForm, { isFullPageSheet: true, onClickAccountCreation: onClickAccountCreation, onClickPasswordRecovery: onClickPasswordRecovery, onClickMigrateFromMyCabin: onClickMigrateFromMyCabin })), showMyCabinMigrationForm && (_jsx(MigrationCard, { children: _jsx(MigrationForm, { text: intl.formatMessage({
                                id: 'AppIntro.UserMigrateSheet.InformationText',
                            }) }) }))] })) }) })));
};
export default AuthenticationSheet;
