import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useState, useRef } from 'react';
import ReactDOM from 'react-dom';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components/macro';
import { isBrowser } from '@nomady/shared/utils/env';
import { DefaultTheme } from '../../theme';
import BackDrop from '../BackDrop/BackDrop';
import { IconVariant } from '../Icon/Icon';
import IconButtonOld from '../IconButtonOld/IconButtonOld';
import { getImageAltText } from '../../util/imageHelper';
import Text, { TextVariant } from '../Text/Text';
import useSwipe from './useSwipe';
import { ReactComponent as NavigationLeft } from '../../assets/svg/navigation-left.svg';
import { ReactComponent as NavigationRight } from '../../assets/svg/navigation-right.svg';
import { safeSpaceTopSelector } from '../../slices/UISlice';
const Wrapper = styled.div `
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;

  ${props => props.theme.layout.container}
  padding-top: 40px !important;
  @media ${props => props.theme.device.tablet} {
    padding-top: 0px !important;
  }
`;
const Grid = styled.div `
  transition: transform 0.2s ease-in-out;
  transform: ${props => (props.open ? 'translateY(-50%)' : 'translateY(100%)')};
  display: flex;
  flex-direction: column;
  width: 100%;
  position: absolute;
  top: 50%;
  padding-inline: 20px;
  ${props => props.theme.priority.high}
  @media ${props => props.theme.device.tablet} {
    transform: ${props => (props.open ? 'translateY(0)' : 'translateY(100%)')};
    position: relative;
    height: 100%;
    top: 0;
    padding: 0;
  } ;
`;
const BackButtonWrapper = styled.div `
  position: absolute;
  width: 100%;
  ${props => props.theme.priority.always};
  top: 20px;
  left: 30px;
  pointer-events: none;
  @media ${props => props.theme.device.desktopXL} {
    left: 0px;
  }
`;
const BackButtonContainer = styled.div `
  max-width: 1580px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  pointer-events: none;
  & button {
    pointer-events: auto;
  }
`;
const buttonStyle = css `
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 5px 4px 3px;
  gap: 4px;

  outline: 0;
  border: 0;

  width: 36px;
  height: 36px;

  background: rgba(34, 34, 34, 0.8);
  border-radius: 5px;

  cursor: pointer;

  @media ${props => props.theme.device.desktop} {
    width: 80px;
    height: 80px;
  }
`;
const Navigation = styled.div `
  display: flex;
  transition: transform 0.2s ease-in-out;
  transform: ${props => (props.open ? 'translateY(0)' : 'translateY(100vh)')};
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  ${props => props.theme.priority.always}
  @media ${props => props.theme.device.tablet} {
    transform: ${props => (props.open ? 'translateY(0)' : 'translateY(100vh)')};
    position: absolute;
    top: 0;
    width: calc(100% - 60px);
  }
  @media ${props => props.theme.device.desktopXL} {
    max-width: 1580px;
    width: calc(100%);
  } ;
`;
const NavigationButton = styled.button `
  ${buttonStyle}
  transform: translateY(calc(50vh - 40px - 50%));

  @media ${props => props.theme.device.desktop} {
    position: relative;
    transform: translateY(calc(30vh));
    align-self: center;
  }
`;
const Indicator = styled.div `
  background: ${props => props.theme.color.caveTransparent};
  border-radius: 10px;
  white-space: nowrap;
  height: min-content;
  padding: 2px 10px;

  ${props => props.theme.typeset.B60};

  align-self: center;

  transform: translateY(-50%);

  @media ${props => props.theme.device.tablet} {
    transform: translateY(50%);
    align-self: flex-end;
    position: relative;
    margin: 0 auto;
    margin-bottom: 20px;

    padding: 10px 20px;
  }

  @media ${props => props.theme.device.desktop} {
    transform: translateY(0);
  }
`;
const CurrentIndex = styled.span `
  color: ${props => props.theme.color.pearl};
`;
const Picture = styled.img `
  width: 100%;
  max-height: 60vh;
  object-fit: scale-down;

  margin-top: 10px;
  margin-bottom: 10px;

  border-radius: 5px;

  @media ${props => props.theme.device.tablet} {
    min-width: 50%;
    max-height: 70vh;
    height: 100%;
  }
`;
const AlternativeText = styled(Text) `
  color: ${props => props.theme.color.pearl};

  padding: 10px;
  width: 100%;
  max-height: 15vh;
  overflow-y: auto;
  overflow-x: hidden;
  @media ${props => props.theme.device.tablet} {
    max-height: calc(30vh - 100px);
    height: 100%;
  }
`;
const NavigationBar = ({ backdropVisible, showPrevious, showNext, currentIndex, images, }) => (_jsxs(Navigation, Object.assign({ open: backdropVisible }, { children: [_jsx(NavigationButton, Object.assign({ onClick: showPrevious }, { children: _jsx(NavigationLeft, {}) })), _jsxs(Indicator, Object.assign({ onClick: event => event.stopPropagation() }, { children: [_jsx(CurrentIndex, { children: currentIndex + 1 }), " | ", images.length] })), _jsx(NavigationButton, Object.assign({ onClick: showNext }, { children: _jsx(NavigationRight, {}) }))] })));
const PictureLightbox = ({ images, altTexts, currentImage, fallbackAltText, onClose, open, onChangeImage, }) => {
    const [isOpen, setIsOpen] = useState(open);
    const [backdropVisible, setBackdropVisible] = useState(false);
    const currentIndex = images.findIndex(element => element.id.uuid === (currentImage === null || currentImage === void 0 ? void 0 : currentImage.uuid));
    const previousIndex = (currentIndex + images.length - 1) % images.length;
    const nextIndex = (currentIndex + 1) % images.length;
    const showPrevious = (event) => {
        event === null || event === void 0 ? void 0 : event.stopPropagation();
        onChangeImage(images[previousIndex].id);
    };
    const showNext = (event) => {
        event === null || event === void 0 ? void 0 : event.stopPropagation();
        onChangeImage(images[nextIndex].id);
    };
    const { handleTouchStart, handleTouchMove, handleTouchEnd } = useSwipe(showNext, showPrevious);
    const [portalRoot, setPortalRoot] = useState();
    useEffect(() => {
        setIsOpen(open);
    }, [open]);
    useEffect(() => {
        if (!isBrowser()) {
            return undefined;
        }
        return setPortalRoot(document.getElementById('portal-root'));
    }, []);
    const swipeAreaRef = useRef(null);
    const handleBodyKeyUp = (event) => {
        if (event.key === 'Escape') {
            onClose();
            event.preventDefault();
            event.stopPropagation();
        }
        else if (event.key === 'ArrowLeft') {
            showPrevious();
        }
        else if (event.key === 'ArrowRight') {
            showNext();
        }
    };
    // avoid stale closure values on the event listener callbacks: https://stackoverflow.com/a/62005831
    const handleBodyKeyUpRef = useRef(handleBodyKeyUp);
    useEffect(() => {
        handleBodyKeyUpRef.current = handleBodyKeyUp;
    });
    useEffect(() => {
        const handleBodyKeyListener = (e) => handleBodyKeyUpRef.current(e);
        document.body.addEventListener('keyup', handleBodyKeyListener);
        return () => {
            document.body.removeEventListener('keyup', handleBodyKeyListener);
        };
    }, []);
    useEffect(() => {
        setPortalRoot(document.getElementById('portal-root'));
    }, []);
    const image = images[currentIndex];
    useEffect(() => {
        if (isOpen) {
            setTimeout(() => {
                setBackdropVisible(true);
            }, 10);
        }
        return () => {
            setBackdropVisible(false);
        };
    }, [isOpen]);
    const safeSpaceTop = useSelector(safeSpaceTopSelector);
    if (!image) {
        return _jsx(_Fragment, {});
    }
    const altText = getImageAltText(image, altTexts);
    const content = (_jsxs(BackDrop, Object.assign({ onClick: onClose, coversTopBar: true, visible: backdropVisible, safeSpaceTop: safeSpaceTop }, { children: [_jsx(BackButtonWrapper, { children: _jsx(BackButtonContainer, { children: _jsx(IconButtonOld, { onClick: onClose, iconVariant: IconVariant.Cross }) }) }), _jsxs(Wrapper, Object.assign({ "data-testid": "PictureLightbox", onTouchStart: handleTouchStart, onTouchMove: handleTouchMove, onTouchEnd: handleTouchEnd, ref: swipeAreaRef }, { children: [_jsx(NavigationBar, { backdropVisible: backdropVisible, showPrevious: showPrevious, showNext: showNext, currentIndex: currentIndex, images: images }), _jsxs(Grid, Object.assign({ open: backdropVisible }, { children: [images.map(element => {
                                var _a, _b, _c, _d;
                                return (_jsx(Picture, { src: (_a = element.attributes.variants['scaled-small']) === null || _a === void 0 ? void 0 : _a.url, srcSet: `${(_b = element.attributes.variants['scaled-small']) === null || _b === void 0 ? void 0 : _b.url} 300w, ${(_c = element.attributes.variants['scaled-medium']) === null || _c === void 0 ? void 0 : _c.url} 600w, ${(_d = element.attributes.variants['scaled-xlarge']) === null || _d === void 0 ? void 0 : _d.url} 900w`, sizes: `${DefaultTheme.device.mobile} 100vw,
              ${DefaultTheme.device.tablet} 75vw`, alt: altText !== null && altText !== void 0 ? altText : fallbackAltText, style: {
                                        display: (currentImage === null || currentImage === void 0 ? void 0 : currentImage.uuid) === element.id.uuid ? 'block' : 'none',
                                    }, onClick: event => event.stopPropagation() }, element.id.uuid));
                            }), !!altText && (_jsx(AlternativeText, Object.assign({ variant: TextVariant.B40, onClick: event => event.stopPropagation() }, { children: altText })))] }))] }))] })));
    if (isOpen && portalRoot) {
        return ReactDOM.createPortal(content, portalRoot);
    }
    return null;
};
export default PictureLightbox;
