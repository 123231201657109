import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { AvailabilityStateEnum } from '../types';
import styled from 'styled-components/macro';
import Text, { TextVariant } from '../../../styled-components/Text/Text';
import { ColorVariant } from '../../../theme';
import { useIntl } from 'react-intl';
const BlockedStatus = styled.div `
  background-color: ${props => props.theme.color.pearl};
  box-shadow: inset 0 0 0 1px ${props => props.theme.color[props.colorVariant]};
  border-radius: 4px;
  padding: 0 8px;
  display: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media ${props => props.theme.device.desktop} {
    display: flex;
  }
`;
const SmallIndicator = styled.div `
  background-color: ${props => props.theme.color[props.colorVariant]};
  margin: 4px;
  height: 8px;
  width: 8px;
  flex-shrink: 0;
  border-radius: 9999px;

  @media ${props => props.theme.device.desktop} {
    display: none;
  }
`;
export const AvailabilityStateTag = ({ availabilityState, }) => {
    const intl = useIntl();
    if (availabilityState === AvailabilityStateEnum.AllBlocked) {
        return (_jsxs(_Fragment, { children: [_jsx(BlockedStatus, Object.assign({ colorVariant: ColorVariant.Rose }, { children: _jsx(Text, Object.assign({ variant: TextVariant.L4SB, colorVariant: ColorVariant.Cave }, { children: intl.formatMessage({
                            id: 'BookingCalendar.AvailabilityTag.blocked',
                        }) })) })), _jsx(SmallIndicator, { colorVariant: ColorVariant.Rose })] }));
    }
    if (availabilityState === AvailabilityStateEnum.PartiallyBlocked) {
        return (_jsxs(_Fragment, { children: [_jsx(BlockedStatus, Object.assign({ colorVariant: ColorVariant.Rose }, { children: _jsx(Text, Object.assign({ variant: TextVariant.L4SB, colorVariant: ColorVariant.Cave }, { children: intl.formatMessage({
                            id: 'BookingCalendar.AvailabilityTag.blockedPartially',
                        }) })) })), _jsx(SmallIndicator, { colorVariant: ColorVariant.Rose })] }));
    }
    if (availabilityState === AvailabilityStateEnum.AllFree) {
        return _jsx("div", {});
    }
    return (_jsxs(_Fragment, { children: [_jsx(BlockedStatus, Object.assign({ colorVariant: ColorVariant.Indigo }, { children: _jsx(Text, Object.assign({ variant: TextVariant.L4SB, colorVariant: ColorVariant.Cave }, { children: intl.formatMessage({
                        id: 'BookingCalendar.AvailabilityTag.xAvailable',
                    }, { quantity: availabilityState.availableSeats }) })) })), _jsx(SmallIndicator, { colorVariant: ColorVariant.Indigo })] }));
};
