var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { denormalisedResponseEntities } from '@nomady/shared/utils/data';
import { transitionsToRequested } from '@nomady/shared/utils/transaction';
import { storableError } from '../util/errors';
import * as log from '../util/log';
import { authInfo, logout } from './Auth.duck';
import { stripeAccountCreateSuccess } from './stripeConnectAccount.duck';
import { switchLocale } from '../slices/UISlice';
import { fetchListingFavoritesLists } from '../slices/listingFavoritesListsSlice';
import { determinePlatform, reportUserAppVersion, showCurrentUser, } from '../util/api';
import { isApp, isIos } from '../util/deviceContext';
import { MYCABIN_MISSING_LAST_NAME_PLACEHOLDER } from '@nomady/shared/schemas/user/profile';
import { APPLICATION_VERSION } from '@nomady/shared/application-version';
import moment from 'moment';
import { setClarityCustomTag, setClarityCustomUserId, } from '../util/clarityUtils';
// ================ Action types ================ //
const CURRENT_USER_SHOW_REQUEST = 'app/user/CURRENT_USER_SHOW_REQUEST';
const CURRENT_USER_SHOW_SUCCESS = 'app/user/CURRENT_USER_SHOW_SUCCESS';
const CURRENT_USER_SHOW_ERROR = 'app/user/CURRENT_USER_SHOW_ERROR';
const CLEAR_CURRENT_USER = 'app/user/CLEAR_CURRENT_USER';
const FETCH_CURRENT_USER_HAS_LISTINGS_REQUEST = 'app/user/FETCH_CURRENT_USER_HAS_LISTINGS_REQUEST';
const FETCH_CURRENT_USER_HAS_LISTINGS_SUCCESS = 'app/user/FETCH_CURRENT_USER_HAS_LISTINGS_SUCCESS';
const FETCH_CURRENT_USER_HAS_LISTINGS_ERROR = 'app/user/FETCH_CURRENT_USER_HAS_LISTINGS_ERROR';
const SET_LISTING_DEFAULT_CURRENCY = 'app/user/SET_LISTING_DEFAULT_CURRENCY';
export const FETCH_CURRENT_PROVIDER_NOTIFICATIONS_REQUEST = 'app/user/FETCH_CURRENT_PROVIDER_NOTIFICATIONS_REQUEST';
export const FETCH_CURRENT_PROVIDER_NOTIFICATIONS_SUCCESS = 'app/user/FETCH_CURRENT_PROVIDER_NOTIFICATIONS_SUCCESS';
export const FETCH_CURRENT_PROVIDER_NOTIFICATIONS_ERROR = 'app/user/FETCH_CURRENT_PROVIDER_NOTIFICATIONS_ERROR';
const SEND_VERIFICATION_EMAIL_REQUEST = 'app/user/SEND_VERIFICATION_EMAIL_REQUEST';
const SEND_VERIFICATION_EMAIL_SUCCESS = 'app/user/SEND_VERIFICATION_EMAIL_SUCCESS';
const SEND_VERIFICATION_EMAIL_ERROR = 'app/user/SEND_VERIFICATION_EMAIL_ERROR';
// ================ Reducer ================ //
const mergeCurrentUser = (oldCurrentUser, newCurrentUser) => {
    const oldRelationships = __rest(oldCurrentUser || {}, []);
    const _a = newCurrentUser || {}, { id, type, attributes } = _a, relationships = __rest(_a, ["id", "type", "attributes"]);
    // Passing null will remove currentUser entity.
    // Only relationships are merged.
    // TODO figure out if sparse fields handling needs a better handling.
    if (newCurrentUser === null) {
        return null;
    }
    if (oldCurrentUser === null) {
        return newCurrentUser;
    }
    return Object.assign(Object.assign(Object.assign({}, oldRelationships), { id,
        type,
        attributes }), relationships);
};
const initialState = {
    currentUser: null,
    currentUserShowError: null,
    currentUserHasListings: undefined,
    currentUserHasListingsError: null,
    currentProviderNotificationCount: 0,
    currentProviderNotificationCountError: null,
    sendVerificationEmailInProgress: false,
    sendVerificationEmailError: null,
    listingsWithPendingRequests: null,
};
const UserReducer = (state = initialState, action) => {
    switch (action.type) {
        case CURRENT_USER_SHOW_REQUEST:
            return Object.assign(Object.assign({}, state), { currentUserShowError: null });
        case CURRENT_USER_SHOW_SUCCESS:
            return Object.assign(Object.assign({}, state), { currentUser: mergeCurrentUser(state.currentUser, action.payload) });
        case CURRENT_USER_SHOW_ERROR:
            // eslint-disable-next-line no-console
            console.error(action.payload);
            return Object.assign(Object.assign({}, state), { currentUserShowError: action.payload });
        case CLEAR_CURRENT_USER:
            return Object.assign(Object.assign({}, state), { currentUser: null, currentUserShowError: null, currentUserHasListings: undefined, currentUserHasListingsError: null, currentProviderNotificationCount: 0, currentProviderNotificationCountError: null });
        case FETCH_CURRENT_USER_HAS_LISTINGS_REQUEST:
            return Object.assign(Object.assign({}, state), { currentUserHasListingsError: null });
        case FETCH_CURRENT_USER_HAS_LISTINGS_SUCCESS:
            return Object.assign(Object.assign({}, state), { currentUserHasListings: action.payload.hasListings });
        case FETCH_CURRENT_USER_HAS_LISTINGS_ERROR:
            console.error(action.payload); // eslint-disable-line
            return Object.assign(Object.assign({}, state), { currentUserHasListingsError: action.payload });
        case FETCH_CURRENT_PROVIDER_NOTIFICATIONS_REQUEST:
            return Object.assign(Object.assign({}, state), { currentProviderNotificationCountError: null });
        case SET_LISTING_DEFAULT_CURRENCY:
            return Object.assign(Object.assign({}, state), { listingDefaultCurrency: action.payload.currency });
        case FETCH_CURRENT_PROVIDER_NOTIFICATIONS_SUCCESS:
            return Object.assign(Object.assign({}, state), { listingsWithPendingRequests: [
                    ...new Set(action.payload.transactions
                        .map(transaction => {
                        var _a, _b, _c;
                        return (_c = (_b = (_a = transaction === null || transaction === void 0 ? void 0 : transaction.relationships) === null || _a === void 0 ? void 0 : _a.listing) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.id.uuid;
                    })
                        .filter(element => !!element)),
                ] });
        case FETCH_CURRENT_PROVIDER_NOTIFICATIONS_ERROR:
            console.error(action.payload); // eslint-disable-line
            return Object.assign(Object.assign({}, state), { currentProviderNotificationCountError: action.payload });
        case SEND_VERIFICATION_EMAIL_REQUEST:
            return Object.assign(Object.assign({}, state), { sendVerificationEmailInProgress: true, sendVerificationEmailError: null });
        case SEND_VERIFICATION_EMAIL_SUCCESS:
            return Object.assign(Object.assign({}, state), { sendVerificationEmailInProgress: false });
        case SEND_VERIFICATION_EMAIL_ERROR:
            return Object.assign(Object.assign({}, state), { sendVerificationEmailInProgress: false, sendVerificationEmailError: action.payload });
        default:
            return state;
    }
};
export default UserReducer;
// ================ Selectors ================ //
export const hasCurrentUserErrors = (state) => {
    const { user } = state;
    return (user.currentUserShowError ||
        user.currentUserHasListingsError ||
        user.currentProviderNotificationCountError);
};
export const verificationSendingInProgress = (state) => {
    return state.user.sendVerificationEmailInProgress;
};
const currentUserShowRequest = () => ({
    type: CURRENT_USER_SHOW_REQUEST,
});
export const currentUserShowSuccess = (user) => ({
    type: CURRENT_USER_SHOW_SUCCESS,
    payload: user,
});
const currentUserShowError = (e) => ({
    type: CURRENT_USER_SHOW_ERROR,
    payload: e,
    error: true,
});
export const clearCurrentUser = () => ({
    type: CLEAR_CURRENT_USER,
});
const fetchCurrentUserHasListingsRequest = () => ({
    type: FETCH_CURRENT_USER_HAS_LISTINGS_REQUEST,
});
const fetchCurrentUserHasListingsSuccess = (hasListings) => ({
    type: FETCH_CURRENT_USER_HAS_LISTINGS_SUCCESS,
    payload: { hasListings },
});
const setListingDefaultCurrency = (currency) => ({
    type: SET_LISTING_DEFAULT_CURRENCY,
    payload: { currency },
});
const fetchCurrentUserHasListingsError = (e) => ({
    type: FETCH_CURRENT_USER_HAS_LISTINGS_ERROR,
    error: true,
    payload: e,
});
const fetchCurrentUserNotificationsRequest = () => ({
    type: FETCH_CURRENT_PROVIDER_NOTIFICATIONS_REQUEST,
});
const fetchCurrentUserNotificationsSuccess = (transactions) => ({
    type: FETCH_CURRENT_PROVIDER_NOTIFICATIONS_SUCCESS,
    payload: { transactions },
});
const fetchCurrentUserNotificationsError = (e) => ({
    type: FETCH_CURRENT_PROVIDER_NOTIFICATIONS_ERROR,
    error: true,
    payload: e,
});
const sendVerificationEmailRequest = () => ({
    type: SEND_VERIFICATION_EMAIL_REQUEST,
});
const sendVerificationEmailSuccess = () => ({
    type: SEND_VERIFICATION_EMAIL_SUCCESS,
});
const sendVerificationEmailError = (e) => ({
    type: SEND_VERIFICATION_EMAIL_ERROR,
    error: true,
    payload: e,
});
// ================ Thunks ================ //
const fetchCurrentUserHasListings = () => (dispatch, getState, sdk) => {
    dispatch(fetchCurrentUserHasListingsRequest());
    const { currentUser } = getState().user;
    if (!currentUser) {
        dispatch(fetchCurrentUserHasListingsSuccess(false));
        return Promise.resolve(null);
    }
    const params = {
        page: 1,
    };
    return sdk.ownListings
        .query(params)
        .then(response => {
        var _a, _b;
        const hasListings = response.data.data && response.data.data.length > 0;
        const defaultCurrency = [
            ...new Set((_b = (_a = response.data) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.map(listing => {
                if (listing.attributes.state === 'draft') {
                    return undefined;
                }
                return listing.attributes.publicData.currency;
            }).filter(element => !!element)),
        ][0];
        dispatch(fetchCurrentUserHasListingsSuccess(!!hasListings));
        dispatch(setListingDefaultCurrency(defaultCurrency));
    })
        .catch(e => dispatch(fetchCurrentUserHasListingsError(storableError(e))));
};
// Notificaiton page size is max (100 items on page)
const NOTIFICATION_PAGE_SIZE = 100;
export const fetchCurrentUserNotifications = () => (dispatch, getState, sdk) => {
    dispatch(fetchCurrentUserNotificationsRequest());
    const apiQueryParams = {
        only: 'sale',
        lastTransitions: transitionsToRequested,
        page: 1,
        perPage: NOTIFICATION_PAGE_SIZE,
        include: ['listing'],
    };
    sdk.transactions
        .query(apiQueryParams)
        .then(response => {
        const transactions = response.data.data;
        dispatch(fetchCurrentUserNotificationsSuccess(transactions));
    })
        .catch(e => dispatch(fetchCurrentUserNotificationsError(storableError(e))));
};
export const fetchCurrentUser = (params) => (dispatch, getState) => {
    dispatch(currentUserShowRequest());
    const { isAuthenticated } = getState().Auth;
    if (!isAuthenticated) {
        // Make sure current user is null
        dispatch(currentUserShowSuccess(null));
        return Promise.resolve({});
    }
    dispatch(fetchListingFavoritesLists());
    const parameters = params || {
        include: [
            'profileImage',
            'stripeAccount',
            'stripeCustomer.defaultPaymentMethod',
        ],
        'fields.image': ['variants.square-small', 'variants.square-small2x'],
    };
    return showCurrentUser(parameters)
        .then(response => {
        var _a, _b, _c, _d, _e;
        const entities = denormalisedResponseEntities(response);
        if (entities.length !== 1) {
            throw new Error('Expected a resource in the sdk.currentUser.show response');
        }
        const currentUser = entities[0];
        if ((_a = currentUser.attributes.profile.metadata) === null || _a === void 0 ? void 0 : _a.isMarkedDeleted) {
            dispatch(logout());
            throw new Error('User is deleted');
        }
        if (isApp &&
            (((_b = currentUser.attributes.profile.privateData) === null || _b === void 0 ? void 0 : _b.lastSeenOnAppVersion) !==
                APPLICATION_VERSION ||
                moment((_c = currentUser.attributes.profile.privateData) === null || _c === void 0 ? void 0 : _c.lastSeenOnAppAt)
                    .add(3, 'hours')
                    .isBefore(moment()))) {
            reportUserAppVersion();
        }
        setClarityCustomUserId(currentUser.id.uuid);
        setClarityCustomTag('user_email', currentUser.attributes.email);
        setClarityCustomTag('user_isApp', isApp ? 'true' : 'false');
        setClarityCustomTag('user_platform', determinePlatform(isApp, isIos));
        setClarityCustomTag('user_app_version', APPLICATION_VERSION);
        // Save stripeAccount to store.stripe.stripeAccount if it exists
        if (currentUser.stripeAccount) {
            dispatch(stripeAccountCreateSuccess(currentUser.stripeAccount));
        }
        // set current user id to the logger
        log.setUserId(currentUser.id.uuid);
        dispatch(currentUserShowSuccess(currentUser));
        if ((_d = currentUser.attributes.profile.privateData) === null || _d === void 0 ? void 0 : _d.locale) {
            dispatch(switchLocale((_e = currentUser.attributes.profile.privateData) === null || _e === void 0 ? void 0 : _e.locale));
        }
        // OneSignal login
        if (isApp) {
            const loginToOneSignal = () => __awaiter(void 0, void 0, void 0, function* () {
                var _f, _g, _h, _j;
                try {
                    const { default: OneSignal } = yield import('onesignal-cordova-plugin');
                    OneSignal.login(currentUser.id.uuid);
                    OneSignal.User.addEmail(currentUser.attributes.email);
                    OneSignal.User.addTag('hasListings', ((_g = (_f = currentUser.relationships) === null || _f === void 0 ? void 0 : _f.listings) === null || _g === void 0 ? void 0 : _g.length) &&
                        currentUser.relationships.listings.length > 0
                        ? 'true'
                        : 'false');
                    OneSignal.User.setLanguage(((_h = currentUser.attributes.profile.privateData) === null || _h === void 0 ? void 0 : _h.locale)
                        ? (_j = currentUser.attributes.profile.privateData) === null || _j === void 0 ? void 0 : _j.locale.substring(0, 2).toLowerCase()
                        : 'de');
                }
                catch (error) {
                    log.error(error, 'onesignal-login-failed');
                }
            });
            loginToOneSignal();
        }
        return currentUser;
    })
        .then(() => {
        dispatch(fetchCurrentUserHasListings());
        dispatch(fetchCurrentUserNotifications());
        // Make sure auth info is up to date
        dispatch(authInfo());
    })
        .catch(e => {
        // Make sure auth info is up to date
        dispatch(authInfo());
        log.error(e, 'fetch-current-user-failed');
        dispatch(currentUserShowError(storableError(e)));
    });
};
export const sendVerificationEmail = () => (dispatch, getState, sdk) => {
    if (verificationSendingInProgress(getState())) {
        return Promise.reject(new Error('Verification email sending already in progress'));
    }
    dispatch(sendVerificationEmailRequest());
    return sdk.currentUser
        .sendVerificationEmail()
        .then(() => dispatch(sendVerificationEmailSuccess()))
        .catch(e => dispatch(sendVerificationEmailError(storableError(e))));
};
export const userHasPhoneNumberSelector = (state) => { var _a, _b; return !!((_b = (_a = state.user.currentUser) === null || _a === void 0 ? void 0 : _a.attributes.profile.privateData) === null || _b === void 0 ? void 0 : _b.phoneNumber); };
export const userIsVerifiedSelector = (state) => { var _a, _b; return !!((_b = (_a = state.user.currentUser) === null || _a === void 0 ? void 0 : _a.attributes) === null || _b === void 0 ? void 0 : _b.emailVerified); };
export const currentUserSelector = (state) => state.user.currentUser;
export const userHasLastNameSelector = (state) => {
    var _a;
    const lastName = (_a = state.user.currentUser) === null || _a === void 0 ? void 0 : _a.attributes.profile.lastName;
    return !!lastName && lastName !== MYCABIN_MISSING_LAST_NAME_PLACEHOLDER;
};
