import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { isToday } from 'date-fns';
import React from 'react';
import Text, { TextVariant } from '../../../styled-components/Text/Text';
import styled from 'styled-components/macro';
import { ColorVariant } from '../../../theme';
import { useAvailabilityState } from '../hooks/useAvailabilityState';
import { AvailabilityStateTag } from './AvailabilityStateTag';
import { AvailabilityStateEnum } from '../types';
const Container = styled.div `
  pointer-events: none;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  padding: 4px;
`;
export const CalendarComponentDateHeader = ({ date, isOffRange, label, }) => {
    const { availabilityState } = useAvailabilityState(date);
    const getColorVariant = (date, isOffRange) => {
        if (isToday(date)) {
            return ColorVariant.Forest;
        }
        if (isOffRange) {
            return ColorVariant.Stone;
        }
        return ColorVariant.Cave;
    };
    return (_jsxs(Container, { children: [_jsx(AvailabilityStateTag, { availabilityState: availabilityState !== null && availabilityState !== void 0 ? availabilityState : AvailabilityStateEnum.AllFree }), _jsx(Text, Object.assign({ variant: TextVariant.L4SB, colorVariant: getColorVariant(date, isOffRange) }, { children: label }))] }));
};
