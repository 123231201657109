import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styled, { css } from 'styled-components/macro';
import Text, { TextVariant } from '../Text/Text';
const Wrapper = styled.section `
  display: flex;
  flex-direction: column;
`;
const Title = styled(Text) `
  margin-bottom: 20px;

  ${props => props.variant !== TextVariant.H5 &&
    css `
      @media ${props => props.theme.device.tabletL} {
        margin-bottom: 50px;
      }
    `}

  ${props => props.variant === TextVariant.H5 &&
    css `
      text-align: center;
      margin-bottom: 100px;
    `}
`;
const Content = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;

  @media ${props => props.theme.device.tabletL} {
    flex-wrap: wrap;
    flex-direction: row;
  }
`;
export var SectionContentVariant;
(function (SectionContentVariant) {
    SectionContentVariant["Default"] = "Default";
    SectionContentVariant["Subheading"] = "Subheading";
})(SectionContentVariant || (SectionContentVariant = {}));
const SectionContent = ({ children, variant = SectionContentVariant.Default, title, }) => (_jsxs(Wrapper, { children: [title && (_jsx(Title, Object.assign({ variant: variant === SectionContentVariant.Default
                ? TextVariant.H3
                : TextVariant.H5, as: "h2" }, { children: title }))), _jsx(Content, { children: children })] }));
export default SectionContent;
