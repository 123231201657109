export var ArrivalType;
(function (ArrivalType) {
    ArrivalType["direct"] = "direct";
    ArrivalType["reception"] = "reception";
})(ArrivalType || (ArrivalType = {}));
export var DogPolicy;
(function (DogPolicy) {
    DogPolicy["onRequest"] = "onRequest";
    DogPolicy["onlyOnLeash"] = "onlyOnLeash";
    DogPolicy["extraDogFriendly"] = "extraDogFriendly";
})(DogPolicy || (DogPolicy = {}));
export var AccessType;
(function (AccessType) {
    AccessType["regularAccess"] = "regular_access";
    AccessType["extraGroundClearance"] = "extra_ground_clearance";
    AccessType["fourByFour"] = "four_by_four";
})(AccessType || (AccessType = {}));
export var DefaultAmenity;
(function (DefaultAmenity) {
    DefaultAmenity["kitchen"] = "kitchen";
    DefaultAmenity["picNicTable"] = "picnic_table";
    DefaultAmenity["shelter"] = "shelter";
    DefaultAmenity["trash"] = "trash";
    DefaultAmenity["disposeTrashSelf"] = "dispose_trash_self";
    DefaultAmenity["wifi"] = "wifi";
    DefaultAmenity["reception"] = "reception";
    DefaultAmenity["dishwashing"] = "dishwashing";
    DefaultAmenity["greywater"] = "greywater";
})(DefaultAmenity || (DefaultAmenity = {}));
export var Environment;
(function (Environment) {
    Environment["forest"] = "forest";
    Environment["lawn"] = "lawn";
    Environment["river"] = "river";
    Environment["lake"] = "lake";
    Environment["mountains"] = "mountains";
    Environment["farm"] = "farm";
    Environment["street"] = "street";
    Environment["shadowy"] = "shadowy";
})(Environment || (Environment = {}));
export var AccommodationAmenity;
(function (AccommodationAmenity) {
    AccommodationAmenity["heating"] = "heating";
    AccommodationAmenity["bedding"] = "bedding";
    AccommodationAmenity["ownSleepingBag"] = "own_sleeping_bag";
})(AccommodationAmenity || (AccommodationAmenity = {}));
export var CampCategory;
(function (CampCategory) {
    CampCategory["accommodation"] = "accommodation";
    CampCategory["camping"] = "camping";
})(CampCategory || (CampCategory = {}));
export var CancellationType;
(function (CancellationType) {
    CancellationType["superFlex"] = "super_flex";
    CancellationType["flex"] = "flex";
    CancellationType["easy"] = "easy";
    CancellationType["medium"] = "medium";
    CancellationType["strict"] = "strict";
    CancellationType["none"] = "none";
})(CancellationType || (CancellationType = {}));
export const FlexibleCancellationTypes = [
    CancellationType.flex,
    CancellationType.superFlex,
];
export var ToiletType;
(function (ToiletType) {
    ToiletType["atCamp"] = "at_camp";
    ToiletType["regular"] = "regular_toilet";
    ToiletType["portable"] = "portable";
})(ToiletType || (ToiletType = {}));
export var CleaningFeeType;
(function (CleaningFeeType) {
    CleaningFeeType["included"] = "included";
    CleaningFeeType["selfClean"] = "self_clean";
    CleaningFeeType["cleaningFeePricing"] = "cleaning_fee_pricing";
})(CleaningFeeType || (CleaningFeeType = {}));
export var ShowerType;
(function (ShowerType) {
    ShowerType["regular"] = "regular_shower";
    ShowerType["outdoor"] = "outdoor";
})(ShowerType || (ShowerType = {}));
export var FireplaceType;
(function (FireplaceType) {
    FireplaceType["fireplaceWithGrate"] = "with_grate";
    FireplaceType["openFireAllowed"] = "open_fire_allowed";
})(FireplaceType || (FireplaceType = {}));
export var FirewoodPayment;
(function (FirewoodPayment) {
    FirewoodPayment["firewoodInclusive"] = "firewood_incl";
    FirewoodPayment["firewoodPurchasable"] = "firewood_purchasable";
    FirewoodPayment["firewoodSaleOnSite"] = "firewood_sale_on_site";
    FirewoodPayment["firewoodGuestBringsAlong"] = "firewood_guestBringsAlong";
})(FirewoodPayment || (FirewoodPayment = {}));
export var CampStyleAccommodation;
(function (CampStyleAccommodation) {
    CampStyleAccommodation["hut"] = "hut";
    CampStyleAccommodation["glamping"] = "glamping";
    CampStyleAccommodation["shelter"] = "shelter";
})(CampStyleAccommodation || (CampStyleAccommodation = {}));
export var CampStyleCamping;
(function (CampStyleCamping) {
    CampStyleCamping["tent"] = "tent";
    CampStyleCamping["caravan"] = "caravan";
    CampStyleCamping["smallVehicle"] = "smallVehicle";
    CampStyleCamping["mediumVehicle"] = "mediumVehicle";
    CampStyleCamping["largeVehicle"] = "largeVehicle";
    CampStyleCamping["carWithRooftopTent"] = "carWithRooftopTent";
})(CampStyleCamping || (CampStyleCamping = {}));
export const CampStyle = Object.assign(Object.assign({}, CampStyleAccommodation), CampStyleCamping);
export var PowerSupplyFeature;
(function (PowerSupplyFeature) {
    PowerSupplyFeature["smallDevices"] = "smallDevices";
    PowerSupplyFeature["campingVehicles"] = "campingVehicles";
    PowerSupplyFeature["extensionCordRequired"] = "extensionCordRequired";
})(PowerSupplyFeature || (PowerSupplyFeature = {}));
export var PowerSupplyPayment;
(function (PowerSupplyPayment) {
    PowerSupplyPayment["includedInPrice"] = "includedInPrice";
    PowerSupplyPayment["soldOnSite"] = "soldOnSite";
    PowerSupplyPayment["soldSeparately"] = "soldSeparately";
})(PowerSupplyPayment || (PowerSupplyPayment = {}));
export var CateringType;
(function (CateringType) {
    CateringType["bakery"] = "bakery";
    CateringType["farmShop"] = "farmShop";
    CateringType["cafe"] = "cafe";
    CateringType["restaurant"] = "restaurant";
    CateringType["alp"] = "alp";
})(CateringType || (CateringType = {}));
export var GroundType;
(function (GroundType) {
    GroundType["lawn"] = "lawn";
    GroundType["asphalt"] = "asphalt";
    GroundType["gravel"] = "gravel";
})(GroundType || (GroundType = {}));
export var VisitorsTaxType;
(function (VisitorsTaxType) {
    VisitorsTaxType["perPersonAndPerNight"] = "per_person_and_night";
    VisitorsTaxType["communal"] = "communal";
    VisitorsTaxType["none"] = "no_taxes";
})(VisitorsTaxType || (VisitorsTaxType = {}));
export var SummerActivity;
(function (SummerActivity) {
    SummerActivity["bike"] = "bike";
    SummerActivity["hike"] = "hike";
    SummerActivity["climb"] = "climb";
    SummerActivity["paddle"] = "paddle";
    SummerActivity["swim"] = "swim";
    SummerActivity["fishing"] = "fishing";
    SummerActivity["animalWatching"] = "animal_watching";
})(SummerActivity || (SummerActivity = {}));
export var WinterActivity;
(function (WinterActivity) {
    WinterActivity["skiing"] = "skiing";
    WinterActivity["langlauf"] = "langlauf";
    WinterActivity["skiTouring"] = "ski_touring";
    WinterActivity["snowShoeHiking"] = "snowshoe_hiking";
    WinterActivity["hockey"] = "hockey";
    WinterActivity["iceRink"] = "ice_rink";
    WinterActivity["sledding"] = "sledding";
    WinterActivity["iglooBuilding"] = "igloo_building";
})(WinterActivity || (WinterActivity = {}));
export var ReferralOption;
(function (ReferralOption) {
    ReferralOption["friendsAndRelatives"] = "friendsAndRelatives";
    ReferralOption["searchEngine"] = "searchEngine";
    ReferralOption["signAtCamp"] = "signAtCamp";
    ReferralOption["nomadyTeam"] = "nomadyTeam";
    ReferralOption["socialMedia"] = "socialMedia";
    ReferralOption["alpacaCamping"] = "alpacaCamping";
    ReferralOption["printMedia"] = "printMedia";
    ReferralOption["custom"] = "custom";
})(ReferralOption || (ReferralOption = {}));
export var MarkerType;
(function (MarkerType) {
    MarkerType["camp"] = "camp";
    MarkerType["fireplace"] = "fireplace";
    MarkerType["electricity"] = "electricity";
    MarkerType["parking"] = "parking";
    MarkerType["toilet"] = "toilet";
    MarkerType["water"] = "water";
})(MarkerType || (MarkerType = {}));
export var DeeplLocale;
(function (DeeplLocale) {
    DeeplLocale["EN"] = "en";
    DeeplLocale["DE"] = "de";
    DeeplLocale["IT"] = "it";
    DeeplLocale["FR"] = "fr";
})(DeeplLocale || (DeeplLocale = {}));
export var ProviderPayoutState;
(function (ProviderPayoutState) {
    ProviderPayoutState["PENDING"] = "pending";
    ProviderPayoutState["IN_TRANSIT"] = "in_transit";
    ProviderPayoutState["PAID"] = "paid";
    ProviderPayoutState["FAILED"] = "failed";
})(ProviderPayoutState || (ProviderPayoutState = {}));
export var ProviderPayoutInitiatedBy;
(function (ProviderPayoutInitiatedBy) {
    ProviderPayoutInitiatedBy["MANUAL"] = "manual";
    ProviderPayoutInitiatedBy["CRONJOB"] = "cronjob";
    ProviderPayoutInitiatedBy["SHARETRIBE"] = "sharetribe";
})(ProviderPayoutInitiatedBy || (ProviderPayoutInitiatedBy = {}));
export var ProviderPayoutSource;
(function (ProviderPayoutSource) {
    ProviderPayoutSource["BANK_ACCOUNT"] = "bank-account";
    ProviderPayoutSource["STRIPE_PROVIDER_BALANCE"] = "stripe-provider-balance";
})(ProviderPayoutSource || (ProviderPayoutSource = {}));
export var TransactionPayoutState;
(function (TransactionPayoutState) {
    TransactionPayoutState["SCHEDULED"] = "scheduled";
    TransactionPayoutState["CANCELLED"] = "cancelled";
    TransactionPayoutState["PENDING"] = "pending";
    TransactionPayoutState["FAILED"] = "failed";
    TransactionPayoutState["SETTLED"] = "settled";
    TransactionPayoutState["UNKNOWN"] = "unknown";
})(TransactionPayoutState || (TransactionPayoutState = {}));
export var TransactionRefundState;
(function (TransactionRefundState) {
    TransactionRefundState["NONE"] = "none";
    TransactionRefundState["PENDING"] = "pending";
    TransactionRefundState["ERROR"] = "error";
    TransactionRefundState["DONE"] = "done";
})(TransactionRefundState || (TransactionRefundState = {}));
export var CustomerTransactionTerminationReason;
(function (CustomerTransactionTerminationReason) {
    CustomerTransactionTerminationReason["OtherSpot"] = "OtherSpot";
    CustomerTransactionTerminationReason["Appointment"] = "Appointment";
    CustomerTransactionTerminationReason["Weather"] = "Weather";
    CustomerTransactionTerminationReason["TooLongNoReplyFromHost"] = "TooLongNoReplyFromHost";
    CustomerTransactionTerminationReason["TooLongNoReplyFromHostUrgent"] = "TooLongNoReplyFromHostUrgent";
    CustomerTransactionTerminationReason["PlansHaveChanged"] = "PlansHaveChanged";
    CustomerTransactionTerminationReason["Other"] = "Other";
})(CustomerTransactionTerminationReason || (CustomerTransactionTerminationReason = {}));
export var ProviderTransactionTerminationReason;
(function (ProviderTransactionTerminationReason) {
    ProviderTransactionTerminationReason["Private"] = "Private";
    ProviderTransactionTerminationReason["NotUsable"] = "NotUsable";
    ProviderTransactionTerminationReason["DontLikeGuestProfile"] = "DontLikeGuestProfile";
    ProviderTransactionTerminationReason["NotEnoughTime"] = "NotEnoughTime";
    ProviderTransactionTerminationReason["Occupied"] = "Occupied";
    ProviderTransactionTerminationReason["HigherForce"] = "HigherForce";
    ProviderTransactionTerminationReason["Other"] = "Other";
})(ProviderTransactionTerminationReason || (ProviderTransactionTerminationReason = {}));
