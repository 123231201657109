import { parse } from '@nomady/shared/utils/urlHelpers';
import { DefaultAmenity, GroundType, Environment, AccommodationAmenity, PowerSupplyFeature, } from '@nomady/shared/types';
import { initialDatesState, initialFeaturesState, initialGuestsState, } from '../../slices/listingSearchSlice';
const getSearchQueryFromQueryString = (urlSearch) => {
    var _a, _b;
    const queryParams = parse(urlSearch, {
        latlng: ['origin'],
        latlngBounds: ['bounds'],
    });
    let dates = initialDatesState;
    let features = initialFeaturesState;
    const guests = Object.assign({}, initialGuestsState);
    const location = {
        location: {
            name: queryParams.address,
            bounds: queryParams.bounds,
        },
        origin: queryParams.origin,
        region: queryParams.region,
    };
    location.isFlexible = queryParams.location_flexible;
    if (queryParams.dates !== undefined) {
        const parts = queryParams.dates.split(',');
        if (parts.length === 2) {
            dates = {
                startDate: new Date(parts[0]),
                endDate: new Date(parts[1]),
            };
        }
    }
    if (queryParams.seats !== undefined) {
        guests.seats = queryParams.seats;
    }
    if (queryParams.adults !== undefined) {
        guests.adults = queryParams.adults;
    }
    if (queryParams.teens !== undefined) {
        guests.teens = queryParams.teens;
    }
    if (queryParams.children !== undefined) {
        guests.children = queryParams.children;
    }
    if (queryParams.infants !== undefined) {
        guests.infants = queryParams.infants;
    }
    if (queryParams.dogs !== undefined) {
        guests.dogs = queryParams.dogs;
    }
    if (queryParams.campStyle_campCategory !== undefined) {
        features = Object.assign(Object.assign({}, features), { campStyle: Object.assign(Object.assign({}, features.campStyle), { category: queryParams.campStyle_campCategory.split(',') }) });
    }
    if (queryParams.campStyle_private !== undefined) {
        features = Object.assign(Object.assign({}, features), { campStyle: Object.assign(Object.assign({}, features.campStyle), { private: queryParams.campStyle_private }) });
    }
    if (!queryParams.campStyle_campCategory && queryParams.pub_campStyle) {
        features = Object.assign(Object.assign({}, features), { campStyle: Object.assign(Object.assign({}, features.campStyle), { category: queryParams.pub_campStyle.split(',') }) });
    }
    if (!queryParams.campStyle_campCategory && queryParams.pub_category) {
        features = Object.assign(Object.assign({}, features), { campStyle: Object.assign(Object.assign({}, features.campStyle), { category: queryParams.pub_category.split(',') }) });
    }
    if (queryParams.access !== undefined) {
        features = Object.assign(Object.assign({}, features), { access: Object.assign(Object.assign({}, features.access), { access: {
                    regularVehicle: queryParams.access.includes('regularVehicle'),
                    groundClearanceSuggested: queryParams.access.includes('groundClearanceSuggested'),
                    offRoader: queryParams.access.includes('offRoader'),
                } }) });
    }
    if (queryParams.access_ground !== undefined) {
        features = Object.assign(Object.assign({}, features), { access: Object.assign(Object.assign({}, features.access), { ground: {
                    lawn: queryParams.access_ground.includes(GroundType.lawn),
                    tarmac: queryParams.access_ground.includes(GroundType.asphalt),
                    gravelOrChippedWood: queryParams.access_ground.includes(GroundType.gravel),
                } }) });
    }
    if (queryParams.ambiance !== undefined) {
        features = Object.assign(Object.assign({}, features), { ambiance: {
                shadowy: queryParams.ambiance.includes(Environment.shadowy),
                forest: queryParams.ambiance.includes(Environment.forest),
                lawn: queryParams.ambiance.includes(Environment.lawn),
                riverOrCreek: queryParams.ambiance.includes(Environment.river),
                lake: queryParams.ambiance.includes(Environment.lake),
                mountains: queryParams.ambiance.includes(Environment.mountains),
                farm: queryParams.ambiance.includes(Environment.farm),
                road: queryParams.ambiance.includes(Environment.street),
            } });
    }
    if (queryParams.facilities_basics_water !== undefined) {
        features = Object.assign(Object.assign({}, features), { facilities: Object.assign(Object.assign({}, features.facilities), { basics: {
                    water: queryParams.facilities_basics_water,
                } }) });
    }
    if (queryParams.facilities_basics_power_supply !== undefined) {
        features = Object.assign(Object.assign({}, features), { facilities: Object.assign(Object.assign({}, features.facilities), { basics: Object.assign(Object.assign({}, (_a = features.facilities) === null || _a === void 0 ? void 0 : _a.basics), { powerSupply: {
                        campingVehicles: queryParams.facilities_basics_power_supply.includes(PowerSupplyFeature.campingVehicles),
                        smallDevices: queryParams.facilities_basics_power_supply.includes(PowerSupplyFeature.smallDevices),
                    } }) }) });
    }
    if (queryParams.facilities_toilet !== undefined) {
        features = Object.assign(Object.assign({}, features), { facilities: Object.assign(Object.assign({}, features.facilities), { basics: Object.assign(Object.assign({}, (_b = features.facilities) === null || _b === void 0 ? void 0 : _b.basics), { toilet: {
                        regularToilet: queryParams.facilities_toilet.includes('regularToilet'),
                        portableToilet: queryParams.facilities_toilet.includes('portableToilet'),
                        closeToCampsite: queryParams.facilities_toilet.includes('closeToCampsite'),
                    } }) }) });
    }
    if (queryParams.facilities_shower !== undefined) {
        features = Object.assign(Object.assign({}, features), { facilities: Object.assign(Object.assign({}, features.facilities), { shower: {
                    regularShower: queryParams.facilities_shower.includes('regularShower'),
                    outdoorShower: queryParams.facilities_shower.includes('outdoorShower'),
                } }) });
    }
    if (queryParams.facilities_miscellaneous !== undefined) {
        features = Object.assign(Object.assign({}, features), { facilities: Object.assign(Object.assign({}, features.facilities), { miscellaneous: {
                    kitchen: queryParams.facilities_miscellaneous.includes(DefaultAmenity.kitchen),
                    picnicTable: queryParams.facilities_miscellaneous.includes(DefaultAmenity.picNicTable),
                    bin: queryParams.facilities_miscellaneous.includes(DefaultAmenity.trash),
                    dishwashing: queryParams.facilities_miscellaneous.includes(DefaultAmenity.dishwashing),
                    grayWaterDisposal: queryParams.facilities_miscellaneous.includes(DefaultAmenity.greywater),
                    shelter: queryParams.facilities_miscellaneous.includes(DefaultAmenity.shelter),
                    internetHotspot: queryParams.facilities_miscellaneous.includes(DefaultAmenity.wifi),
                    mobileReception: queryParams.facilities_miscellaneous.includes(DefaultAmenity.reception),
                } }) });
    }
    if (queryParams.facilities_accommodation !== undefined) {
        features = Object.assign(Object.assign({}, features), { facilities: {
                accommodation: {
                    millionStars: queryParams.facilities_accommodation.includes('millionStars') ||
                        undefined,
                    heating: queryParams.facilities_accommodation.includes(AccommodationAmenity.heating) || undefined,
                    linen: queryParams.facilities_accommodation.includes(AccommodationAmenity.bedding) || undefined,
                },
            } });
    }
    if (queryParams.activities_summer !== undefined) {
        features = Object.assign(Object.assign({}, features), { activities: Object.assign(Object.assign({}, features.activities), { summer: {
                    biking: queryParams.activities_summer.includes('biking'),
                    hiking: queryParams.activities_summer.includes('hiking'),
                    climbing: queryParams.activities_summer.includes('climbing'),
                    canoeing: queryParams.activities_summer.includes('canoeing'),
                    swimming: queryParams.activities_summer.includes('swimming'),
                    fishing: queryParams.activities_summer.includes('fishing'),
                    observeWildAnimals: queryParams.activities_summer.includes('observeWildAnimals'),
                } }) });
    }
    if (queryParams.activities_winter !== undefined) {
        features = Object.assign(Object.assign({}, features), { activities: Object.assign(Object.assign({}, features.activities), { winter: {
                    skiing: queryParams.activities_winter.includes('skiing'),
                    langlauf: queryParams.activities_winter.includes('langlauf'),
                    crossCountrySkiing: queryParams.activities_winter.includes('crossCountrySkiing'),
                    skiTouring: queryParams.activities_winter.includes('skiTouring'),
                    snowHiking: queryParams.activities_winter.includes('snowHiking'),
                    hockey: queryParams.activities_winter.includes('hockey'),
                    iceRink: queryParams.activities_winter.includes('iceRink'),
                    sledding: queryParams.activities_winter.includes('sledding'),
                    buildingIgloos: queryParams.activities_winter.includes('buildingIgloos'),
                } }) });
    }
    if (queryParams.features_store !== undefined) {
        features = Object.assign(Object.assign({}, features), { facilities: Object.assign(Object.assign({}, features.facilities), { store: queryParams.features_store }) });
    }
    if (queryParams.features_winterCamping !== undefined) {
        features = Object.assign(Object.assign({}, features), { facilities: Object.assign(Object.assign({}, features.facilities), { winterCamping: queryParams.features_winterCamping }) });
    }
    if (queryParams.dogsAllowed !== undefined) {
        features = Object.assign(Object.assign({}, features), { dogsAllowed: queryParams.dogsAllowed });
    }
    if (queryParams.dogCamp !== undefined) {
        features = Object.assign(Object.assign({}, features), { dogCamp: queryParams.dogCamp });
    }
    if (queryParams.familyFriendly !== undefined) {
        features = Object.assign(Object.assign({}, features), { familyFriendly: queryParams.familyFriendly });
    }
    if (queryParams.farmShop !== undefined) {
        features = Object.assign(Object.assign({}, features), { farmShop: queryParams.farmShop });
    }
    if (queryParams.fireplaceAvailable !== undefined) {
        features = Object.assign(Object.assign({}, features), { fireplaceAvailable: queryParams.fireplaceAvailable });
    }
    if (queryParams.features_fireplace !== undefined) {
        features = Object.assign(Object.assign({}, features), { fireplace: {
                grate: queryParams.features_fireplace.includes('grate'),
                firewoodIncluded: queryParams.features_fireplace.includes('firewoodIncluded'),
                firewoodSale: queryParams.features_fireplace.includes('firewoodSale'),
            } });
    }
    if (queryParams.features_booking_and_cancellation !== undefined) {
        features = Object.assign(Object.assign({}, features), { bookingAndCancellation: {
                instantBooking: queryParams.features_booking_and_cancellation.includes('instantBooking'),
                cancellationPolicyFlex: queryParams.features_booking_and_cancellation.includes('cancellationPolicyFlex'),
                cancellationPolicySuperflex: queryParams.features_booking_and_cancellation.includes('cancellationPolicySuperflex'),
                verifiedHost: queryParams.features_booking_and_cancellation.includes('verifiedHost'),
                flexibleCancellation: queryParams.features_booking_and_cancellation.includes('flexibleCancellation'),
            } });
    }
    if (queryParams.price !== undefined) {
        const price = queryParams.price
            .split(',')
            .map((price) => parseInt(price, 10)) || undefined;
        features = Object.assign(Object.assign({}, features), { price: Object.assign(Object.assign({}, features.price), { price }) });
    }
    if (queryParams.currency !== undefined) {
        features = Object.assign(Object.assign({}, features), { price: Object.assign(Object.assign({}, features.price), { currency: queryParams.currency }) });
    }
    const searchQuery = {
        location,
        guests,
        features,
        dates,
        sort: queryParams.sort,
    };
    return searchQuery;
};
export default getSearchQueryFromQueryString;
