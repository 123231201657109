var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import InputErrorMessage from '../InputErrorMessage/InputErrorMessage';
import { TextVariant } from '../Text/Text';
import { Description, ErrorMessageWrapper, Label, Wrapper, } from '../Input/Input';
import TextArea from '../TextArea';
export var InputVariant;
(function (InputVariant) {
    InputVariant["Default"] = "Default";
    InputVariant["Dark"] = "Dark";
    InputVariant["Light"] = "Default";
})(InputVariant || (InputVariant = {}));
const InputTextArea = (_a) => {
    var { id, label, variant, textAreaRef, error, icon, description, placeholder, loading } = _a, rest = __rest(_a, ["id", "label", "variant", "textAreaRef", "error", "icon", "description", "placeholder", "loading"]);
    const hasError = !!error;
    const errorMessageId = hasError ? `${id}-errormessage` : '';
    const errorMessageAriaInputProps = hasError
        ? { 'aria-invalid': true, 'aria-errormessage': errorMessageId }
        : {};
    return (_jsxs(Wrapper, { children: [label && (_jsx(Label, Object.assign({ inputVariant: variant, variant: TextVariant.H13, as: "label", htmlFor: id, hasError: hasError }, { children: label }))), _jsx(TextArea, Object.assign({ id: id, placeholder: placeholder, textAreaRef: textAreaRef, dataTestId: "MessageForm_textArea", ref: textAreaRef }, rest, errorMessageAriaInputProps)), hasError && (_jsx(ErrorMessageWrapper, Object.assign({ role: "alert" }, { children: _jsx(InputErrorMessage, { id: errorMessageId, error: error }) }))), description && _jsx(Description, { text: description })] }));
};
export default InputTextArea;
