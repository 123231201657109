var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
const DEFAULT_TIMEOUT = 10000;
export var SnackBarType;
(function (SnackBarType) {
    SnackBarType["INFO"] = "info";
    SnackBarType["WARN"] = "warn";
    SnackBarType["ERROR"] = "error";
    SnackBarType["SUCCESS"] = "success";
    SnackBarType["CONTACT_JANNIS"] = "contact_jannis";
})(SnackBarType || (SnackBarType = {}));
const initialState = {
    snackBars: [],
};
export const snackbarSlice = createSlice({
    name: 'snackbar',
    initialState,
    reducers: {
        addSnackBarReducer: (state, action) => {
            state.snackBars.push(action.payload);
        },
        removeSnackBar: (state, action) => {
            state.snackBars = state.snackBars.filter(snackBar => snackBar.id !== action.payload);
        },
        clearSnackBars: state => {
            state.snackBars = [];
        },
    },
});
export const addSnackBar = createAsyncThunk('snackbar/addSnackBar', (snackBarData, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    const id = uuidv4();
    const timeout = (_a = snackBarData.timeout) !== null && _a !== void 0 ? _a : DEFAULT_TIMEOUT;
    dispatch(addSnackBarReducer(Object.assign(Object.assign({}, snackBarData), { id })));
    setTimeout(() => {
        dispatch(removeSnackBar(id));
    }, timeout);
}));
export const { addSnackBarReducer, removeSnackBar, clearSnackBars } = snackbarSlice.actions;
export const selectSnackBars = (state) => state.SnackBar.snackBars;
export default snackbarSlice.reducer;
