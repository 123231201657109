import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import styled from 'styled-components/macro';
import { getBackgroundImageUrlLarge, getBackgroundImageUrlMedium, } from '../../util/imageHelper';
import Button from '../Button/Button';
import { IconVariant } from '../Icon/Icon';
import Text, { TextVariant } from '../Text/Text';
export var Variant;
(function (Variant) {
    Variant["Dark"] = "dark";
    Variant["Light"] = "light";
})(Variant || (Variant = {}));
const Image = styled.div `
  background-size: cover;
  background-position: center center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  min-height: 400px;
  background-image: url(${props => { var _a, _b; return ((_b = (_a = props.formats) === null || _a === void 0 ? void 0 : _a.small) === null || _b === void 0 ? void 0 : _b.url) || props.imageSrc; }});

  @media ${props => props.theme.device.tablet} {
    height: 490px;
    background-image: url(${props => getBackgroundImageUrlMedium(props.formats, props.imageSrc)});
  }

  @media ${props => props.theme.device.tabletL} {
    height: 590px;
    background-image: url(${props => getBackgroundImageUrlMedium(props.formats, props.imageSrc)});
  }

  @media ${props => props.theme.device.desktop} {
    height: 690px;
    background-image: url(${props => getBackgroundImageUrlLarge(props.formats, props.imageSrc)});
  }

  @media ${props => props.theme.device.desktopL} {
    height: 790px;
    background-image: url(${props => props.imageSrc});
  }
`;
const Title = styled(Text) `
  margin-bottom: 50px;
  text-align: center;
  ${props => `color: ${props.sectionVariant === Variant.Dark
    ? props.theme.color.pearl
    : props.theme.color.cave};`}
`;
const SectionCta = ({ title, buttonText, buttonLink, backgroundImage, externalLink, }) => (_jsx(LazyLoadComponent, { children: _jsxs(Image, Object.assign({ formats: backgroundImage.data.attributes.formats, imageSrc: backgroundImage.data.attributes.url }, { children: [title ? (_jsx(Title, Object.assign({ title: title, sectionVariant: Variant.Dark, variant: TextVariant.H12, as: "h2" }, { children: title }))) : null, buttonLink || externalLink ? (_jsx(Button, { label: buttonText, targetRoute: buttonLink, externalLink: externalLink, icon: IconVariant.ArrowRight })) : null] })) }));
export default SectionCta;
