var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { useIntl } from 'react-intl';
import { isBrowser } from '@nomady/shared/utils/env';
import { parse } from '@nomady/shared/utils/urlHelpers';
import { generateGiftCardCodes } from '../../util/api';
import { localeSelector } from '../../slices/UISlice';
import FullPageSheet from '../FullPageSheet/FullPageSheet';
import GiftCardOrderConfirmation from './partials/GiftCardOrderConfirmation';
import { IconSpinner } from '../../components';
import Layout, { LayoutRow } from '../Layout/Layout';
import Text, { TextVariant } from '../Text/Text';
import Button, { ButtonColorVariant } from '../Button/Button';
const cookies = new Cookies();
const Wrapper = styled.section `
  display: flex;
  flex-direction: column;
`;
export const Information = styled.div `
  display: grid;
  row-gap: 10px;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 100px;
`;
const EnhancedIconSpinner = styled(IconSpinner) `
  margin: 0 auto;
`;
const SectionGiftCardOrderConfirmation = () => {
    const location = useLocation();
    const history = useHistory();
    const intl = useIntl();
    const locale = useSelector(localeSelector);
    const [error, setError] = useState();
    const [generationHasStarted, setGenerationHasStarted] = useState(false);
    const sessionStorageKey = 'giftCardOrderDetails';
    const orderDetailsFromQuery = parse(location.search);
    const { result, sessionId, orderId, quantity } = orderDetailsFromQuery;
    const orderDetailsString = isBrowser() && sessionStorage.getItem(sessionStorageKey);
    const orderDetails = JSON.parse(orderDetailsString);
    const onClickGiftcardGeneration = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!sessionId) {
            throw new Error('SessionId not set');
        }
        if (!quantity) {
            throw new Error('Quantity not set');
        }
        if (generationHasStarted) {
            return undefined;
        }
        setGenerationHasStarted(true);
        try {
            const response = yield generateGiftCardCodes(sessionId, locale);
            const { orderId, type, value, quantity: responseQuantity, currency, token, error, } = yield response.json();
            if (error) {
                console.error(error);
                return setError(error);
            }
            if (window === null || window === void 0 ? void 0 : window.fbq) {
                console.info('track purchase');
                window.fbq('track', 'Purchase', {
                    value,
                    currency,
                });
            }
            sessionStorage.setItem(sessionStorageKey, JSON.stringify({
                orderId,
                type,
                value,
                quantity: responseQuantity,
                currency,
            }));
            cookies.set('giftCardToken', token, { path: '/' });
            return history.push(`?orderId=${orderId}`);
        }
        catch (error) {
            console.error({ error });
            if (error) {
                setError(`${error}`);
            }
            return undefined;
        }
    });
    const generationInProgress = sessionId && quantity && !error;
    const buttonLabel = intl.formatMessage({
        id: 'SectionGiftCardOrderConfirmation.confirmOrderButtonLabel',
    });
    if (result === 'success' &&
        isBrowser() &&
        sessionId &&
        quantity &&
        !generationHasStarted &&
        !orderId) {
        return (_jsx(FullPageSheet, Object.assign({ open: true }, { children: _jsx(Layout, { children: _jsx(LayoutRow, { children: _jsxs(Information, { children: [_jsx(Text, Object.assign({ variant: TextVariant.H30, as: "h1" }, { children: intl.formatMessage({
                                    id: 'SectionGiftCardOrderConfirmation.title',
                                }, { buttonLabel }) })), _jsx(Button, { onClick: onClickGiftcardGeneration, colorVariant: ButtonColorVariant.Forest, label: buttonLabel })] }) }) }) })));
    }
    if (orderDetailsFromQuery && generationInProgress) {
        const { quantity } = orderDetailsFromQuery;
        return (_jsx(FullPageSheet, Object.assign({ open: true }, { children: _jsx(Layout, { children: _jsx(LayoutRow, { children: _jsxs(Information, { children: [_jsx(Text, Object.assign({ variant: TextVariant.H30, as: "h1" }, { children: intl.formatMessage({
                                    id: 'SectionGiftCardOrderConfirmation.title',
                                }, { buttonLabel }) })), _jsx(EnhancedIconSpinner, {}), _jsx(Text, Object.assign({ variant: TextVariant.B40, as: "p" }, { children: intl.formatMessage({
                                    id: 'SectionGiftCardOrderConfirmation.text',
                                }, { quantity }) }))] }) }) }) })));
    }
    if (isBrowser() && error) {
        return (_jsx(FullPageSheet, Object.assign({ open: true }, { children: _jsx(Layout, { children: _jsx(LayoutRow, { children: _jsxs(Information, { children: [_jsx(Text, Object.assign({ variant: TextVariant.H30, as: "h1" }, { children: intl.formatMessage({
                                    id: 'SectionGiftCardOrderConfirmation.unknownError',
                                }) })), _jsx("pre", Object.assign({ style: { fontFamily: 'monospace', fontSize: '16px' } }, { children: JSON.stringify({ orderId, sessionId, error }) }))] }) }) }) })));
    }
    const generated = cookies.get('giftCardToken');
    return (_jsx(Wrapper, { children: generated && orderDetails && (_jsx(GiftCardOrderConfirmation, Object.assign({}, orderDetails))) }));
};
export default SectionGiftCardOrderConfirmation;
