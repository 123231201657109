import { AccessType } from '@nomady/shared/types';
const getAccessSearchParam = (access) => {
    const values = [];
    if (access === null || access === void 0 ? void 0 : access.regularVehicle) {
        values.push(AccessType.regularAccess);
    }
    if (access === null || access === void 0 ? void 0 : access.groundClearanceSuggested) {
        values.push(AccessType.extraGroundClearance);
    }
    if (access === null || access === void 0 ? void 0 : access.offRoader) {
        values.push(AccessType.fourByFour);
    }
    if (values.length === 0) {
        return undefined;
    }
    return values.join(',');
};
export default getAccessSearchParam;
