import pick from 'lodash/pick';
import pickBy from 'lodash/pickBy';
import isEmpty from 'lodash/isEmpty';
import { types as sdkTypes } from '@nomady/shared/utils/sdkLoader.ts';
import {
  TRANSITION_ACCEPT,
  TRANSITION_DECLINE,
  TRANSITION_CANCEL_BY_CUSTOMER,
  TRANSITION_WITHDRAW_BOOKING_REQUEST_BY_CUSTOMER,
} from '@nomady/shared/utils/transaction.ts';
import {
  updatedEntities,
  denormalisedEntities,
  denormalisedResponseEntities,
} from '@nomady/shared/utils/data.ts';
import { storableError } from '../../util/errors.ts';
import * as log from '../../util/log.ts';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck.ts';
import {
  fetchCurrentUserNotifications,
  fetchCurrentUser,
} from '../../ducks/user.duck.ts';
import {
  apiLocaleSelector,
  getInitialPageTranslationActiveSelector,
} from '../../slices/UISlice.ts';
import {
  acceptPrivileged,
  cancelByCustomerPrivileged,
  declinePrivileged,
  withdrawByCustomerPriviledged,
  showTransaction,
  showListing,
  transactionCreateReview,
} from '../../util/api.ts';

const { UUID } = sdkTypes;

const MESSAGES_PAGE_SIZE = 100;

// ================ Action types ================ //

const SET_INITAL_VALUES = 'app/TransactionPage/SET_INITIAL_VALUES';

const FETCH_TRANSACTION_REQUEST =
  'app/TransactionPage/FETCH_TRANSACTION_REQUEST';
const FETCH_TRANSACTION_SUCCESS =
  'app/TransactionPage/FETCH_TRANSACTION_SUCCESS';
const FETCH_TRANSACTION_ERROR = 'app/TransactionPage/FETCH_TRANSACTION_ERROR';

const FETCH_TRANSITIONS_REQUEST =
  'app/TransactionPage/FETCH_TRANSITIONS_REQUEST';
const FETCH_TRANSITIONS_SUCCESS =
  'app/TransactionPage/FETCH_TRANSITIONS_SUCCESS';
const FETCH_TRANSITIONS_ERROR = 'app/TransactionPage/FETCH_TRANSITIONS_ERROR';

const ACCEPT_SALE_REQUEST = 'app/TransactionPage/ACCEPT_SALE_REQUEST';
const ACCEPT_SALE_SUCCESS = 'app/TransactionPage/ACCEPT_SALE_SUCCESS';
const ACCEPT_SALE_ERROR = 'app/TransactionPage/ACCEPT_SALE_ERROR';

const DECLINE_SALE_REQUEST = 'app/TransactionPage/DECLINE_SALE_REQUEST';
const DECLINE_SALE_SUCCESS = 'app/TransactionPage/DECLINE_SALE_SUCCESS';
const DECLINE_SALE_ERROR = 'app/TransactionPage/DECLINE_SALE_ERROR';

const WITHDRAW_BOOKING_REQUEST_REQUEST =
  'app/TransactionPage/WITHDRAW_BOOKING_REQUEST_REQUEST';
const WITHDRAW_BOOKING_REQUEST_SUCCESS =
  'app/TransactionPage/WITHDRAW_BOOKING_REQUEST_SUCCESS';
const WITHDRAW_BOOKING_REQUEST_ERROR =
  'app/TransactionPage/WITHDRAW_BOOKING_REQUEST_ERROR';

const CANCEL_SALE_REQUEST = 'app/TransactionPage/CANCEL_SALE_REQUEST';
const CANCEL_SALE_SUCCESS = 'app/TransactionPage/CANCEL_SALE_SUCCESS';
const CANCEL_SALE_ERROR = 'app/TransactionPage/CANCEL_SALE_ERROR';

const FETCH_MESSAGES_REQUEST = 'app/TransactionPage/FETCH_MESSAGES_REQUEST';
const FETCH_MESSAGES_SUCCESS = 'app/TransactionPage/FETCH_MESSAGES_SUCCESS';
const FETCH_MESSAGES_ERROR = 'app/TransactionPage/FETCH_MESSAGES_ERROR';

const SEND_MESSAGE_REQUEST = 'app/TransactionPage/SEND_MESSAGE_REQUEST';
const SEND_MESSAGE_SUCCESS = 'app/TransactionPage/SEND_MESSAGE_SUCCESS';
const SEND_MESSAGE_ERROR = 'app/TransactionPage/SEND_MESSAGE_ERROR';

const SEND_REVIEW_REQUEST = 'app/TransactionPage/SEND_REVIEW_REQUEST';
const SEND_REVIEW_SUCCESS = 'app/TransactionPage/SEND_REVIEW_SUCCESS';
const SEND_REVIEW_ERROR = 'app/TransactionPage/SEND_REVIEW_ERROR';

const FETCH_TIME_SLOTS_REQUEST = 'app/TransactionPage/FETCH_TIME_SLOTS_REQUEST';
const FETCH_TIME_SLOTS_SUCCESS = 'app/TransactionPage/FETCH_TIME_SLOTS_SUCCESS';
const FETCH_TIME_SLOTS_ERROR = 'app/TransactionPage/FETCH_TIME_SLOTS_ERROR';

const ADD_INVOICES = 'app/TransactionPage/ADD_INVOICES';

const SET_TRANSLATION_ACTIVE = 'app/TransactionPage/SET_TRANSLATION_ACTIVE';

const SET_TRANSLATION_LOADING = 'app/TransactionPage/SET_TRANSLATION_LOADING';

// ================ Reducer ================ //

const initialState = {
  fetchTransactionInProgress: false,
  fetchTransactionError: null,
  transactionRef: null,
  acceptInProgress: false,
  acceptSaleError: null,
  declineInProgress: false,
  declineSaleError: null,
  withdrawBookingRequestInProgress: false,
  withdrawBookingRequestError: null,
  cancelInProgress: false,
  cancelSaleError: null,
  fetchMessagesInProgress: false,
  fetchMessagesError: null,
  totalMessages: 0,
  totalMessagePages: 0,
  oldestMessagePageFetched: 0,
  messages: [],
  initialMessageFailedToTransaction: null,
  sendMessageInProgress: false,
  sendMessageError: null,
  sendReviewInProgress: false,
  sendReviewError: null,
  timeSlots: null,
  fetchTimeSlotsError: null,
  fetchTransitionsInProgress: false,
  fetchTransitionsError: null,
  processTransitions: null,
  invoices: [],
  translationActive: true,
  translationLoading: false,
};

// Merge entity arrays using ids, so that conflicting items in newer array (b) overwrite old values (a).
// const a = [{ id: { uuid: 1 } }, { id: { uuid: 3 } }];
// const b = [{ id: : { uuid: 2 } }, { id: : { uuid: 1 } }];
// mergeEntityArrays(a, b)
// => [{ id: { uuid: 3 } }, { id: : { uuid: 2 } }, { id: : { uuid: 1 } }]
const mergeEntityArrays = (a, b) => {
  return a
    .filter(aEntity => !b.find(bEntity => aEntity.id.uuid === bEntity.id.uuid))
    .concat(b);
};

export default function oldCheckoutPageReducer(
  state = initialState,
  action = {}
) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITAL_VALUES:
      return { ...initialState, ...payload };

    case FETCH_TRANSACTION_REQUEST:
      return {
        ...state,
        fetchTransactionInProgress: true,
        fetchTransactionError: null,
      };
    case FETCH_TRANSACTION_SUCCESS: {
      const transactionRef = { id: payload.data.data.id, type: 'transaction' };
      return { ...state, fetchTransactionInProgress: false, transactionRef };
    }
    case FETCH_TRANSACTION_ERROR:
      console.error(payload); // eslint-disable-line
      return {
        ...state,
        fetchTransactionInProgress: false,
        fetchTransactionError: payload,
      };

    case FETCH_TRANSITIONS_REQUEST:
      return {
        ...state,
        fetchTransitionsInProgress: true,
        fetchTransitionsError: null,
      };
    case FETCH_TRANSITIONS_SUCCESS:
      return {
        ...state,
        fetchTransitionsInProgress: false,
        processTransitions: payload,
      };
    case FETCH_TRANSITIONS_ERROR:
      console.error(payload); // eslint-disable-line
      return {
        ...state,
        fetchTransitionsInProgress: false,
        fetchTransitionsError: payload,
      };

    case ACCEPT_SALE_REQUEST:
      return {
        ...state,
        acceptInProgress: true,
        acceptSaleError: null,
        declineSaleError: null,
        cancelSaleError: null,
      };
    case ACCEPT_SALE_SUCCESS:
      return { ...state, acceptInProgress: false };
    case ACCEPT_SALE_ERROR:
      return { ...state, acceptInProgress: false, acceptSaleError: payload };

    case DECLINE_SALE_REQUEST:
      return {
        ...state,
        declineInProgress: true,
        declineSaleError: null,
        acceptSaleError: null,
        cancelSaleError: null,
      };
    case DECLINE_SALE_SUCCESS:
      return { ...state, declineInProgress: false };
    case DECLINE_SALE_ERROR:
      return { ...state, declineInProgress: false, declineSaleError: payload };

    case WITHDRAW_BOOKING_REQUEST_REQUEST:
      return {
        ...state,
        withdrawBookingRequestInProgress: true,
        withdrawBookingRequestError: null,
      };
    case WITHDRAW_BOOKING_REQUEST_SUCCESS:
      return { ...state, withdrawBookingRequestInProgress: false };
    case WITHDRAW_BOOKING_REQUEST_ERROR:
      return {
        ...state,
        withdrawBookingRequestInProgress: false,
        withdrawBookingRequestError: payload,
      };

    case CANCEL_SALE_REQUEST:
      return {
        ...state,
        cancelInProgress: true,
        cancelSaleError: null,
        declineSaleError: null,
        acceptSaleError: null,
      };
    case CANCEL_SALE_SUCCESS:
      return { ...state, cancelInProgress: false };
    case CANCEL_SALE_ERROR:
      return { ...state, cancelInProgress: false, cancelSaleError: payload };

    case FETCH_MESSAGES_REQUEST:
      return {
        ...state,
        fetchMessagesInProgress: true,
        fetchMessagesError: null,
      };
    case FETCH_MESSAGES_SUCCESS: {
      const oldestMessagePageFetched =
        state.oldestMessagePageFetched > payload.page
          ? state.oldestMessagePageFetched
          : payload.page;
      return {
        ...state,
        fetchMessagesInProgress: false,
        messages: mergeEntityArrays(state.messages, payload.messages),
        totalMessages: payload.totalItems,
        totalMessagePages: payload.totalPages,
        oldestMessagePageFetched,
      };
    }
    case FETCH_MESSAGES_ERROR:
      return {
        ...state,
        fetchMessagesInProgress: false,
        fetchMessagesError: payload,
      };

    case SEND_MESSAGE_REQUEST:
      return {
        ...state,
        sendMessageInProgress: true,
        sendMessageError: null,
        initialMessageFailedToTransaction: null,
      };
    case SEND_MESSAGE_SUCCESS:
      return { ...state, sendMessageInProgress: false };
    case SEND_MESSAGE_ERROR:
      return {
        ...state,
        sendMessageInProgress: false,
        sendMessageError: payload,
      };

    case SEND_REVIEW_REQUEST:
      return { ...state, sendReviewInProgress: true, sendReviewError: null };
    case SEND_REVIEW_SUCCESS:
      return { ...state, sendReviewInProgress: false };
    case SEND_REVIEW_ERROR:
      return {
        ...state,
        sendReviewInProgress: false,
        sendReviewError: payload,
      };

    case FETCH_TIME_SLOTS_REQUEST:
      return { ...state, fetchTimeSlotsError: null };
    case FETCH_TIME_SLOTS_SUCCESS:
      return { ...state, timeSlots: payload };
    case FETCH_TIME_SLOTS_ERROR:
      return { ...state, fetchTimeSlotsError: payload };

    case ADD_INVOICES:
      return { ...state, invoices: payload };

    case SET_TRANSLATION_LOADING:
      return { ...state, translationLoading: payload };

    case SET_TRANSLATION_ACTIVE: {
      return { ...state, translationActive: payload };
    }
    default:
      return state;
  }
}

// ================ Selectors ================ //

export const acceptOrDeclineOrCancelInProgress = state => {
  return (
    state.TransactionPage.acceptInProgress ||
    state.TransactionPage.declineInProgress ||
    state.TransactionPage.cancelInProgress
  );
};

export const withDrawBookingRequestInProgress = state => {
  return state.TransactionPage.withdrawBookingRequestInProgress;
};

// ================ Action creators ================ //
export const setInitialValues = initialValues => ({
  type: SET_INITAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

const fetchTransactionRequest = () => ({ type: FETCH_TRANSACTION_REQUEST });
const fetchTransactionSuccess = response => ({
  type: FETCH_TRANSACTION_SUCCESS,
  payload: response,
});
const fetchTransactionError = e => ({
  type: FETCH_TRANSACTION_ERROR,
  error: true,
  payload: e,
});

const fetchTransitionsRequest = () => ({ type: FETCH_TRANSITIONS_REQUEST });
const fetchTransitionsSuccess = response => ({
  type: FETCH_TRANSITIONS_SUCCESS,
  payload: response,
});
const fetchTransitionsError = e => ({
  type: FETCH_TRANSITIONS_ERROR,
  error: true,
  payload: e,
});

const acceptSaleRequest = () => ({ type: ACCEPT_SALE_REQUEST });
const acceptSaleSuccess = () => ({ type: ACCEPT_SALE_SUCCESS });
const acceptSaleError = e => ({
  type: ACCEPT_SALE_ERROR,
  error: true,
  payload: e,
});

const declineSaleRequest = () => ({ type: DECLINE_SALE_REQUEST });
const declineSaleSuccess = () => ({ type: DECLINE_SALE_SUCCESS });
const declineSaleError = e => ({
  type: DECLINE_SALE_ERROR,
  error: true,
  payload: e,
});

const withdrawBookingRequestRequest = () => ({
  type: WITHDRAW_BOOKING_REQUEST_REQUEST,
});
const withdrawBookingRequestSuccess = () => ({
  type: WITHDRAW_BOOKING_REQUEST_SUCCESS,
});
const withdrawBookingRequestError = e => ({
  type: WITHDRAW_BOOKING_REQUEST_ERROR,
  error: true,
  payload: e,
});

const cancelSaleRequest = () => ({ type: CANCEL_SALE_REQUEST });
const cancelSaleSuccess = () => ({ type: CANCEL_SALE_SUCCESS });

const fetchMessagesRequest = () => ({ type: FETCH_MESSAGES_REQUEST });
const fetchMessagesSuccess = (messages, pagination) => ({
  type: FETCH_MESSAGES_SUCCESS,
  payload: { messages, ...pagination },
});
const fetchMessagesError = e => ({
  type: FETCH_MESSAGES_ERROR,
  error: true,
  payload: e,
});

const sendMessageRequest = () => ({ type: SEND_MESSAGE_REQUEST });
const sendMessageSuccess = () => ({ type: SEND_MESSAGE_SUCCESS });
const sendMessageError = e => ({
  type: SEND_MESSAGE_ERROR,
  error: true,
  payload: e,
});

const sendReviewRequest = () => ({ type: SEND_REVIEW_REQUEST });
const sendReviewSuccess = () => ({ type: SEND_REVIEW_SUCCESS });
const sendReviewError = e => ({
  type: SEND_REVIEW_ERROR,
  error: true,
  payload: e,
});

const addInvoices = invoices => ({
  type: ADD_INVOICES,
  payload: invoices,
});

const setTranslationActive = active => ({
  type: SET_TRANSLATION_ACTIVE,
  payload: active,
});

const setTranslationLoading = loading => ({
  type: SET_TRANSLATION_LOADING,
  payload: loading,
});

// ================ Thunks ================ //

const listingRelationship = txResponse => {
  return txResponse.data?.data?.relationships.listing.data;
};

const IMAGE_VARIANTS = {
  'fields.image': [
    // Profile images
    'variants.square-small',
    'variants.square-small2x',

    // Listing images:
    'variants.landscape-crop',
    'variants.landscape-crop2x',
  ],
};

export const toggleTranslation =
  (listingId, transactionId) => (dispatch, getState) => {
    const state = getState();

    const translationActive = !state.TransactionPage.translationActive;
    dispatch(setTranslationActive(translationActive));

    dispatch(setTranslationLoading(true));

    if (listingId) {
      dispatch(fetchListing(listingId));
    }

    if (transactionId) {
      dispatch(fetchTransaction(transactionId));
    }
  };
export const fetchListing = listingId => async (dispatch, getState) => {
  const state = getState();

  const apiLocale = apiLocaleSelector(
    state,
    !state.TransactionPage.translationActive
  );

  const locale = apiLocale ? { locale: apiLocale } : {};

  const response = await showListing({
    id: listingId,
    include: ['author', 'author.profileImage', 'images'],
    ...locale,
    ...IMAGE_VARIANTS,
  });

  dispatch(addMarketplaceEntities(response));
  dispatch(setTranslationLoading(false));
};

export const fetchTransaction = id => async (dispatch, getState) => {
  dispatch(fetchTransactionRequest());

  const state = getState();

  const apiLocale = apiLocaleSelector(
    state,
    !state.TransactionPage.translationActive
  );

  const locale = apiLocale ? { locale: apiLocale } : {};

  try {
    const txResponse = await showTransaction(
      {
        id,
        include: [
          'customer',
          'customer.profileImage',
          'provider',
          'provider.profileImage',
          'listing',
          'booking',
          'reviews',
          'reviews.author',
          'reviews.subject',
        ],
        ...locale,
        ...IMAGE_VARIANTS,
      },
      { expand: true }
    );

    const listingId = listingRelationship(txResponse).id;
    const entities = updatedEntities({}, txResponse.data);
    const listingRef = { id: listingId, type: 'listing' };
    const transactionRef = { id, type: 'transaction' };
    const denormalised = denormalisedEntities(entities, [
      listingRef,
      transactionRef,
    ]);
    const listing = denormalised[0];

    if (txResponse.data.invoices) {
      dispatch(addInvoices(txResponse.data.invoices));
    }

    if (listing?.attributes && !listing.attributes.deleted) {
      dispatch(fetchListing(listingId));
    }

    dispatch(addMarketplaceEntities(txResponse));
    dispatch(fetchTransactionSuccess(txResponse));
    dispatch(setTranslationLoading(false));
    return txResponse;
  } catch (e) {
    console.table(e);
    dispatch(fetchTransactionError(storableError(e)));
    throw e;
  }
};

export const acceptSale = id => (dispatch, getState) => {
  if (acceptOrDeclineOrCancelInProgress(getState())) {
    return Promise.reject(
      new Error('Accept or decline or cancellation already in progress')
    );
  }
  dispatch(acceptSaleRequest());

  return acceptPrivileged({ id })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(acceptSaleSuccess());
      dispatch(fetchCurrentUserNotifications());
      return response;
    })
    .catch(e => {
      dispatch(acceptSaleError(storableError(e)));
      log.error(e, 'accept-sale-failed', {
        txId: id,
        transition: TRANSITION_ACCEPT,
      });
      throw e;
    });
};

export const declineSale = (id, reasonFormInputs) => (dispatch, getState) => {
  if (acceptOrDeclineOrCancelInProgress(getState())) {
    return Promise.reject(
      new Error('Accept or decline or cancellation already in progress')
    );
  }
  dispatch(declineSaleRequest());

  return declinePrivileged({
    id,
    ...reasonFormInputs,
  })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(declineSaleSuccess());
      dispatch(fetchCurrentUserNotifications());
      return response;
    })
    .catch(e => {
      dispatch(declineSaleError(storableError(e)));
      log.error(e, 'reject-sale-failed', {
        txId: id,
        transition: TRANSITION_DECLINE,
      });
      throw e;
    });
};

export const withdrawBookingRequestByCustomer =
  (id, reasonFormInputs) => (dispatch, getState) => {
    if (withDrawBookingRequestInProgress(getState())) {
      return Promise.reject(
        new Error('Withdraw booking request already in progress')
      );
    }
    dispatch(withdrawBookingRequestRequest());

    return withdrawByCustomerPriviledged({ id, ...reasonFormInputs })
      .then(response => {
        dispatch(addMarketplaceEntities(response));
        dispatch(withdrawBookingRequestSuccess());
        dispatch(fetchCurrentUserNotifications());
        return response;
      })
      .catch(e => {
        dispatch(withdrawBookingRequestError(storableError(e)));
        log.error(e, 'withdraw-booking-request-failed', {
          txId: id,
          transition: TRANSITION_WITHDRAW_BOOKING_REQUEST_BY_CUSTOMER,
        });
        throw e;
      });
  };

export const cancelSale = (id, reasonFormInputs) => (dispatch, getState) => {
  if (acceptOrDeclineOrCancelInProgress(getState())) {
    return Promise.reject(
      new Error('Accept or decline or cancellation already in progress')
    );
  }

  dispatch(cancelSaleRequest());

  return cancelByCustomerPrivileged({ id, ...reasonFormInputs })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(cancelSaleSuccess());
      dispatch(fetchCurrentUserNotifications());
      return response;
    })
    .catch(e => {
      dispatch(declineSaleError(storableError(e)));
      log.error(e, 'cancel-sale-failed', {
        txId: id,
        transition: TRANSITION_CANCEL_BY_CUSTOMER,
      });
      throw e;
    });
};

export const fetchMessages = (txId, page) => (dispatch, getState, sdk) => {
  const paging = { page, perPage: MESSAGES_PAGE_SIZE };
  dispatch(fetchMessagesRequest());

  return sdk.messages
    .query({
      transaction_id: txId,
      include: ['sender', 'sender.profileImage'],
      ...IMAGE_VARIANTS,
      ...paging,
    })
    .then(response => {
      const messages = denormalisedResponseEntities(response);
      const { totalItems, totalPages, page: fetchedPage } = response.data.meta;
      const pagination = { totalItems, totalPages, page: fetchedPage };
      const {
        TransactionPage: { totalMessages },
      } = getState();

      // Original fetchMessages call succeeded
      dispatch(fetchMessagesSuccess(messages, pagination));

      // Check if totalItems has changed between fetched pagination pages
      // if totalItems has changed, fetch first page again to include new incoming messages.
      // TODO if there're more than 100 incoming messages,
      // this should loop through most recent pages instead of fetching just the first one.
      if (totalItems > totalMessages && page > 1) {
        dispatch(fetchMessages(txId, 1))
          .then(() => {
            // Original fetch was enough as a response for user action,
            // this just includes new incoming messages
          })
          .catch(() => {
            // Background update, no need to to do anything atm.
          });
      }
    })
    .catch(e => {
      dispatch(fetchMessagesError(storableError(e)));
      throw e;
    });
};

export const fetchMoreMessages = txId => (dispatch, getState) => {
  const state = getState();
  const { oldestMessagePageFetched, totalMessagePages } = state.TransactionPage;
  const hasMoreOldMessages = totalMessagePages > oldestMessagePageFetched;

  // In case there're no more old pages left we default to fetching the current cursor position
  const nextPage = hasMoreOldMessages
    ? oldestMessagePageFetched + 1
    : oldestMessagePageFetched;

  return dispatch(fetchMessages(txId, nextPage));
};

export const sendMessage = (txId, message) => (dispatch, getState, sdk) => {
  dispatch(sendMessageRequest());

  return sdk.messages
    .send({ transactionId: txId, content: message })
    .then(response => {
      const messageId = response.data.data.id;

      // We fetch the first page again to add sent message to the page data
      // and update possible incoming messages too.
      // TODO if there're more than 100 incoming messages,
      // this should loop through most recent pages instead of fetching just the first one.
      return dispatch(fetchMessages(txId, 1))
        .then(() => {
          dispatch(sendMessageSuccess());
          return messageId;
        })
        .catch(() => dispatch(sendMessageSuccess()));
    })
    .catch(e => {
      dispatch(sendMessageError(storableError(e)));
      // Rethrow so the page can track whether the sending failed, and
      // keep the message in the form for a retry.
      throw e;
    });
};

export const sendReview = (id, rating, content) => async dispatch => {
  dispatch(sendReviewRequest());

  try {
    const response = await transactionCreateReview(
      { rating, content },
      { id, ...IMAGE_VARIANTS }
    );

    dispatch(addMarketplaceEntities(response));
    dispatch(sendReviewSuccess());
  } catch (error) {
    dispatch(sendReviewError(error));
  }

  return Promise.resolve();
};

const isNonEmpty = value => {
  return typeof value === 'object' || Array.isArray(value)
    ? !isEmpty(value)
    : !!value;
};

export const fetchNextTransitions = id => (dispatch, getState, sdk) => {
  dispatch(fetchTransitionsRequest());

  return sdk.processTransitions
    .query({ transactionId: id })
    .then(res => {
      dispatch(fetchTransitionsSuccess(res.data.data));
    })
    .catch(e => {
      dispatch(fetchTransitionsError(storableError(e)));
    });
};

// loadData is a collection of async calls that need to be made
// before page has all the info it needs to render itself
export const loadData = params => (dispatch, getState) => {
  dispatch(fetchCurrentUser());

  const txId = new UUID(params.id);
  const state = getState();
  const txRef = state.TransactionPage.transactionRef;

  // In case a transaction reference is found from a previous
  // data load -> clear the state. Otherwise keep the non-null
  // and non-empty values which may have been set from a previous page.
  const initialValues = txRef ? {} : pickBy(state.TransactionPage, isNonEmpty);
  dispatch(setInitialValues(initialValues));

  dispatch(
    setTranslationActive(getInitialPageTranslationActiveSelector(state))
  );

  // Sale / order (i.e. transaction entity in API)
  return Promise.all([
    dispatch(fetchTransaction(txId)),
    dispatch(fetchMessages(txId, 1)),
    dispatch(fetchNextTransitions(txId)),
  ]);
};
