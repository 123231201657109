import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styled from 'styled-components/macro';
import { ModalColorVariant, } from '../utils/interfaces';
import ModalHeader from './ModalHeader';
import ModalContent from './ModalContent';
import ModalButtons from './ModalButtons';
const Canvas = styled.div `
  width: 100vw;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: flex;
  flex-grow: 1;
  max-height: calc(100vh - 32px);
  padding-bottom: 16px;
  flex-direction: column;
  background-color: ${props => props.theme.color.cloud};

  ${props => props.colorVariant === ModalColorVariant.Snow &&
    `
  background-color:  ${props.theme.color.snow};
  `}

  @media ${props => props.theme.device.tabletL} {
    min-width: 600px;
    width: min-content;
    border-radius: 10px;
    max-height: 75vh;
  }
`;
const ModalCanvas = ({ children, title, illustration, leftButton, rightButton, colorVariant, }) => {
    return (_jsxs(Canvas, Object.assign({ onClick: (event) => event.stopPropagation(), colorVariant: colorVariant }, { children: [_jsx(ModalHeader, { title: title }), _jsx(ModalContent, Object.assign({ illustration: illustration }, { children: children })), (leftButton || rightButton) && (_jsx(ModalButtons, { leftButton: leftButton, rightButton: rightButton }))] })));
};
export default ModalCanvas;
