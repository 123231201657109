import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from '../../util/reactIntl';
import LayoutSideNavigation from '../../styled-components/LayoutSideNavigation/LayoutSideNavigation';
import SignedInPageContainer from '../../styled-components/PageContainer/SignedInPageContainer';
import { LanguageSettingsForm } from '../../forms';
import { changeLocale, changeLocaleClear } from './LanguageSettingsPage.duck';
import css from './LanguageSettingsPage.module.scss';
const LanguageSettingsPageComponent = props => {
    const { languageSettingsError, languageSettingsInProgress, languageSettingsChanged, currentUser, onChange, onSubmitLanguageSettings, intl, tabs, location, } = props;
    const changeLanguageSettingsForm = currentUser && currentUser.id ? (_jsx(LanguageSettingsForm, { className: css.form, location: location, languageSettingsError: languageSettingsError, currentUser: currentUser, onSubmit: onSubmitLanguageSettings, onChange: onChange, inProgress: languageSettingsInProgress, ready: languageSettingsChanged })) : null;
    const title = intl.formatMessage({ id: 'LanguageSettingsPage.title' });
    return (_jsx(SignedInPageContainer, Object.assign({ title: title }, { children: _jsx(LayoutSideNavigation, Object.assign({ tabs: tabs }, { children: changeLanguageSettingsForm })) })));
};
const mapStateToProps = (state) => {
    // Topbar needs user info.
    const { languageSettingsError, languageSettingsInProgress, languageSettingsChanged, } = state.LanguageSettingsPage;
    const { currentUser } = state.user;
    return {
        languageSettingsError,
        languageSettingsInProgress,
        languageSettingsChanged,
        currentUser,
    };
};
const mapDispatchToProps = (dispatch) => ({
    onChange: () => dispatch(changeLocaleClear()),
    onSubmitLanguageSettings: (locale) => dispatch(changeLocale(locale)),
});
const LanguageSettingsPage = compose(connect(mapStateToProps, mapDispatchToProps), injectIntl)(LanguageSettingsPageComponent);
export default LanguageSettingsPage;
