import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MAX_BOOKABLE_PERSONS_PER_SEAT, MIN_BOOKABLE_PERSONS_PER_SEAT, } from '@nomady/shared/schemas/listing/description';
import { setGuests, loadResults } from '../../slices/listingSearchSlice';
import NumberOfGuestsSelection from '../NumberOfGuestsSelection/NumberOfGuestsSelection';
import { setPage } from '../../containers/SearchPage/SearchPageSlice';
const SearchBarSheetGuests = () => {
    const dispatch = useDispatch();
    const onSetGuests = (guests) => {
        dispatch(setGuests(guests));
        dispatch(setPage(1));
        dispatch(loadResults());
    };
    const { currentSearchQuery: { guests: currentGuestsSearchQuery }, } = useSelector((state) => state.listingSearch);
    return (_jsx(NumberOfGuestsSelection, { seats: currentGuestsSearchQuery.seats || MIN_BOOKABLE_PERSONS_PER_SEAT, adults: currentGuestsSearchQuery.adults || MIN_BOOKABLE_PERSONS_PER_SEAT, teens: currentGuestsSearchQuery.teens || 0, children_: currentGuestsSearchQuery.children || 0, infants: currentGuestsSearchQuery.infants || 0, dogs: currentGuestsSearchQuery.dogs || 0, onValueChangeSeats: seats => onSetGuests({ seats }), onValueChangeAdults: adults => onSetGuests({ adults }), onValueChangeTeens: teens => onSetGuests({ teens }), onValueChangeChildren: children => onSetGuests({ children }), onValueChangeInfants: infants => onSetGuests({ infants }), onValueChangeDogs: dogs => onSetGuests({ dogs }), minSeats: MIN_BOOKABLE_PERSONS_PER_SEAT, maxSeats: MAX_BOOKABLE_PERSONS_PER_SEAT, minAdults: MIN_BOOKABLE_PERSONS_PER_SEAT, maxAdults: MAX_BOOKABLE_PERSONS_PER_SEAT, minTeens: 0, maxTeens: MAX_BOOKABLE_PERSONS_PER_SEAT, minChildren: 0, maxChildren: MAX_BOOKABLE_PERSONS_PER_SEAT, minInfants: 0, maxInfants: MAX_BOOKABLE_PERSONS_PER_SEAT, minDogs: 0, maxDogs: MAX_BOOKABLE_PERSONS_PER_SEAT }));
};
export default SearchBarSheetGuests;
