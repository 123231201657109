var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createSlice, } from '@reduxjs/toolkit';
import { relevantLastTransitionsForProviderNotifications } from '@nomady/shared/utils/transaction';
import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { queryTransactions } from '../../util/api';
export var ONLY;
(function (ONLY) {
    ONLY["OVERVIEW"] = "sale";
    ONLY["PAST_TRANSACTIONS"] = "sale-past";
    ONLY["FUTURE_TRANSACTIONS"] = "sale-future";
})(ONLY || (ONLY = {}));
const ONLY_MAP = {
    [ONLY.OVERVIEW]: ONLY.FUTURE_TRANSACTIONS,
    [ONLY.PAST_TRANSACTIONS]: ONLY.PAST_TRANSACTIONS,
    [ONLY.FUTURE_TRANSACTIONS]: ONLY.FUTURE_TRANSACTIONS,
};
const initialState = {
    fetchTransactionsError: null,
    fetchTransactionsInProgress: false,
    transactionRefs: [],
    fetchTodoTransactionsError: null,
    fetchTodoTransactionsInProgress: false,
    todoTransactionRefs: [],
    currentPage: 1,
    currentTodoPage: 1,
    only: ONLY.OVERVIEW,
};
export const TODO_PAGE_SIZE = 10;
export const DEFAULT_PAGE_SIZE = 40;
export const BookingsPageSlice = createSlice({
    name: 'app/BookingsPage',
    initialState,
    reducers: {
        fetchTransactionsRequest: state => {
            state.fetchTransactionsInProgress = true;
            state.fetchTransactionsError = null;
        },
        fetchTransactionsSuccess: (state, action) => {
            state.fetchTransactionsInProgress = false;
            state.transactionRefs = action.payload.transactionRefs;
            state.pagination = action.payload.pagination;
        },
        fetchTransactionsError: (state, action) => {
            state.fetchTransactionsInProgress = false;
            state.fetchTransactionsError = action.payload;
        },
        fetchTodoTransactionsRequest: state => {
            state.fetchTodoTransactionsInProgress = true;
            state.fetchTodoTransactionsError = null;
        },
        fetchTodoTransactionsSuccess: (state, action) => {
            state.fetchTodoTransactionsInProgress = false;
            state.todoTransactionRefs = action.payload.transactionRefs;
            state.todoPagination = action.payload.pagination;
        },
        fetchTodoTransactionsError: (state, action) => {
            state.fetchTodoTransactionsInProgress = false;
            state.fetchTodoTransactionsError = action.payload;
        },
        setTodoPage: (state, action) => {
            state.currentTodoPage = action.payload;
        },
        setOnly: (state, action) => {
            state.only = action.payload;
            state.currentPage = 1;
            state.currentTodoPage = 1;
        },
        setPage: (state, action) => {
            state.currentPage = action.payload;
        },
    },
});
export const { setOnly, fetchTransactionsRequest, fetchTransactionsSuccess, fetchTransactionsError, setPage, fetchTodoTransactionsRequest, fetchTodoTransactionsSuccess, fetchTodoTransactionsError, setTodoPage, } = BookingsPageSlice.actions;
export const requestTransactions = () => (dispatch, getState, sdk) => __awaiter(void 0, void 0, void 0, function* () {
    dispatch(fetchTransactionsRequest());
    const state = getState();
    const { only } = state.BookingsPage;
    const params = {
        only: ONLY_MAP[only],
        include: [
            'provider',
            'provider.profileImage',
            'customer',
            'customer.profileImage',
            'booking',
            'listing',
        ],
        'fields.transaction': [
            'lastTransition',
            'lastTransitionedAt',
            'transitions',
            'lineItems',
            'payinTotal',
            'payoutTotal',
            'protectedData',
        ],
        'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
        'fields.image': ['variants.square-small', 'variants.square-small2x'],
        page: state.BookingsPage.currentPage,
        perPage: DEFAULT_PAGE_SIZE,
    };
    try {
        const response = yield queryTransactions(params);
        dispatch(addMarketplaceEntities(response));
        const transactionRefs = response.data.data.map((listing) => ({
            id: listing.id,
            type: listing.type,
        }));
        const pagination = response.data.meta;
        dispatch(fetchTransactionsSuccess({ transactionRefs, pagination }));
    }
    catch (e) {
        dispatch(fetchTransactionsError(storableError(e)));
    }
});
export const requestTodoTransactions = () => (dispatch, getState, sdk) => __awaiter(void 0, void 0, void 0, function* () {
    dispatch(fetchTodoTransactionsRequest());
    const state = getState();
    const params = {
        only: 'sale',
        lastTransitions: relevantLastTransitionsForProviderNotifications,
        include: [
            'provider',
            'provider.profileImage',
            'customer',
            'customer.profileImage',
            'booking',
            'listing',
        ],
        'fields.transaction': [
            'lastTransition',
            'lastTransitionedAt',
            'transitions',
            'lineItems',
            'payinTotal',
            'payoutTotal',
            'protectedData',
        ],
        'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
        'fields.image': ['variants.square-small', 'variants.square-small2x'],
        page: state.BookingsPage.currentTodoPage,
        perPage: TODO_PAGE_SIZE,
    };
    try {
        const response = yield sdk.transactions.query(params);
        dispatch(addMarketplaceEntities(response));
        const transactionRefs = response.data.data.map((listing) => ({
            id: listing.id,
            type: listing.type,
        }));
        const pagination = response.data.meta;
        dispatch(fetchTodoTransactionsSuccess({ transactionRefs, pagination }));
    }
    catch (e) {
        dispatch(fetchTodoTransactionsError(storableError(e)));
    }
});
export const loadData = () => (dispatch, getState) => {
    const state = getState();
    if (showTodoSelector(state)) {
        dispatch(requestTodoTransactions());
    }
    return dispatch(requestTransactions());
};
export const showTodoSelector = (state) => state.BookingsPage.only === ONLY.OVERVIEW;
export default BookingsPageSlice.reducer;
