import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { dateFromAPIToLocalNoon } from '@nomady/shared/utils/dates';
import { txIsPaymentPending } from '@nomady/shared/utils/transaction';
import { createSlug } from '@nomady/shared/utils/urlHelpers';
import { createResourceLocatorString, findRouteByRouteName, } from '@nomady/shared/utils/routes';
import Page from '@nomady/shared/routeConfiguration/Page';
import { ensureTransaction } from '@nomady/shared/utils/data';
import routeConfiguration from '../../routeConfigurationWithComponents';
import { FormattedMessage } from '../../util/reactIntl';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { NamedRedirect, TransactionPanel } from '../../components/index';
import { setInitialValues } from './TransactionPage.duck';
import css from './TransactionPage.module.scss';
import SignedInPageContainer from '../../styled-components/PageContainer/SignedInPageContainer';
import { currentUserSelector } from '../../ducks/user.duck';
import { localeSelector } from '../../slices/UISlice';
const PROVIDER = 'provider';
const CUSTOMER = 'customer';
// TransactionPage handles data loading for Sale and Order views to transaction pages in Inbox.
const TransactionPage = props => {
    var _a, _b;
    const { params, transactionRole } = props;
    const intl = useIntl();
    const history = useHistory();
    const { fetchTransactionError, transactionRef, totalMessagePages, oldestMessagePageFetched, messages, initialMessageFailedToTransaction, processTransitions, } = useSelector((state) => state.TransactionPage);
    const currentUser = useSelector(currentUserSelector);
    const locale = useSelector(localeSelector);
    const state = useSelector((state) => state);
    const dispatch = useDispatch();
    const transactions = getMarketplaceEntities(state, transactionRef ? [transactionRef] : []);
    const unsafeTransaction = transactions.length > 0 ? transactions[0] : undefined;
    const transaction = ensureTransaction(unsafeTransaction);
    const { listing } = transaction;
    const isProviderRole = transactionRole === PROVIDER;
    const isCustomerRole = transactionRole === CUSTOMER;
    const callSetInitialValues = (_, values) => dispatch(setInitialValues(values));
    const redirectToCheckoutPageWithInitialValues = (initialValues) => {
        var _a;
        const routes = routeConfiguration;
        // Customize checkout page state with current listing and selected bookingDates
        const { setInitialValues } = findRouteByRouteName(Page.Checkout, locale, routes);
        callSetInitialValues(setInitialValues, initialValues);
        history.push(createResourceLocatorString(Page.Checkout, routes, {
            id: listing === null || listing === void 0 ? void 0 : listing.id.uuid,
            slug: createSlug(((_a = listing === null || listing === void 0 ? void 0 : listing.attributes) === null || _a === void 0 ? void 0 : _a.title) || ''),
            language: locale,
        }, {}));
    };
    // If payment is pending, redirect to CheckoutPage
    if (txIsPaymentPending(transaction) &&
        isCustomerRole &&
        ((_a = transaction === null || transaction === void 0 ? void 0 : transaction.attributes) === null || _a === void 0 ? void 0 : _a.lineItems) &&
        (transaction === null || transaction === void 0 ? void 0 : transaction.booking)) {
        const initialValues = {
            listing,
            // Transaction with payment pending should be passed to CheckoutPage
            transaction,
            // Original bookingData content is not available,
            // but it is already used since booking is created.
            // (E.g. quantity is used when booking is created.)
            bookingData: {},
            bookingDates: {
                bookingStart: dateFromAPIToLocalNoon(transaction.booking.attributes.start),
                bookingEnd: dateFromAPIToLocalNoon(transaction.booking.attributes.end),
            },
        };
        redirectToCheckoutPageWithInitialValues(initialValues);
    }
    const deletedListingTitle = intl.formatMessage({
        id: 'TransactionPage.deletedListing',
    });
    const listingTitle = (listing === null || listing === void 0 ? void 0 : listing.attributes.deleted)
        ? deletedListingTitle
        : (_b = listing === null || listing === void 0 ? void 0 : listing.attributes) === null || _b === void 0 ? void 0 : _b.title;
    // Redirect users with someone else's direct link to their own inbox/sales or inbox/orders page.
    const isDataAvailable = currentUser &&
        transaction.id &&
        transaction.id.uuid === params.id &&
        transaction.attributes.lineItems &&
        transaction.customer &&
        transaction.provider &&
        !fetchTransactionError;
    const isOwnSale = !!(isDataAvailable &&
        isProviderRole &&
        (transaction === null || transaction === void 0 ? void 0 : transaction.provider) &&
        currentUser.id.uuid === transaction.provider.id.uuid);
    const isOwnOrder = isDataAvailable &&
        isCustomerRole &&
        (transaction === null || transaction === void 0 ? void 0 : transaction.customer) &&
        currentUser.id.uuid === transaction.customer.id.uuid;
    if (isDataAvailable && isProviderRole && !isOwnSale) {
        // eslint-disable-next-line no-console
        console.error('Tried to access a sale that was not owned by the current user');
        return (_jsx(NamedRedirect, { name: Page.Inbox, params: { tab: 'sales', language: locale } }));
    }
    if (isDataAvailable && isCustomerRole && !isOwnOrder) {
        // eslint-disable-next-line no-console
        console.error('Tried to access an order that was not owned by the current user');
        return (_jsx(NamedRedirect, { name: Page.Inbox, params: { tab: 'orders', language: locale } }));
    }
    const fetchErrorMessage = isCustomerRole
        ? 'TransactionPage.fetchOrderFailed'
        : 'TransactionPage.fetchSaleFailed';
    const loadingMessage = isCustomerRole
        ? 'TransactionPage.loadingOrderData'
        : 'TransactionPage.loadingSaleData';
    const loadingOrFailedFetching = fetchTransactionError ? (_jsx("p", Object.assign({ className: css.error }, { children: _jsx(FormattedMessage, { id: `${fetchErrorMessage}` }) }))) : (_jsx("p", Object.assign({ className: css.loading }, { children: _jsx(FormattedMessage, { id: `${loadingMessage}` }) })));
    const initialMessageFailed = !!(initialMessageFailedToTransaction &&
        transaction.id &&
        initialMessageFailedToTransaction.uuid === transaction.id.uuid);
    // TransactionPanel is presentational component
    // that currently handles showing everything inside layout's main view area.
    const panel = isDataAvailable ? (_jsx(TransactionPanel, { currentUser: currentUser, transaction: transaction, totalMessagePages: totalMessagePages, oldestMessagePageFetched: oldestMessagePageFetched, messages: messages, initialMessageFailed: initialMessageFailed, transactionRole: transactionRole, nextTransitions: processTransitions, locale: locale })) : (loadingOrFailedFetching);
    return (_jsx(SignedInPageContainer, Object.assign({ title: intl.formatMessage({ id: 'TransactionPage.title' }, { title: listingTitle }), noIndex: true }, { children: _jsx("div", Object.assign({ className: css.root }, { children: panel })) })));
};
export default TransactionPage;
