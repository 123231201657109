import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { ReactComponent as ApplePayIcon } from './assets/Size=md, Payment method=ApplePay.svg';
import { ReactComponent as GiroPayIcon } from './assets/Size=md, Payment method=giropay.svg';
import { ReactComponent as GooglePayIcon } from './assets/Size=md, Payment method=GooglePay.svg';
import { ReactComponent as KlarnaIcon } from './assets/Size=md, Payment method=Klarna.svg';
import { ReactComponent as MastercardIcon } from './assets/Size=md, Payment method=Mastercard.svg';
import { ReactComponent as PayPalIcon } from './assets/Size=md, Payment method=PayPal.svg';
import { ReactComponent as SofortIcon } from './assets/Size=md, Payment method=Sofort.svg';
import { ReactComponent as VisaIcon } from './assets/Size=md, Payment method=Visa.svg';
import { ReactComponent as TwintIcon } from './assets/Size=md, Payment method=Twint.svg';
export var PaymentType;
(function (PaymentType) {
    PaymentType["ApplePay"] = "applepay";
    PaymentType["GiroPay"] = "giropay";
    PaymentType["GooglePay"] = "googlepay";
    PaymentType["Klarna"] = "klarna";
    PaymentType["Mastercard"] = "mastercard";
    PaymentType["PayPal"] = "paypal";
    PaymentType["Sofort"] = "sofort";
    PaymentType["Visa"] = "visa";
    PaymentType["Twint"] = "twint";
})(PaymentType || (PaymentType = {}));
const PaymentTypeIconMap = {
    [PaymentType.ApplePay]: _jsx(ApplePayIcon, {}),
    [PaymentType.GiroPay]: _jsx(GiroPayIcon, {}),
    [PaymentType.GooglePay]: _jsx(GooglePayIcon, {}),
    [PaymentType.Klarna]: _jsx(KlarnaIcon, {}),
    [PaymentType.Mastercard]: _jsx(MastercardIcon, {}),
    [PaymentType.PayPal]: _jsx(PayPalIcon, {}),
    [PaymentType.Sofort]: _jsx(SofortIcon, {}),
    [PaymentType.Visa]: _jsx(VisaIcon, {}),
    [PaymentType.Twint]: _jsx(TwintIcon, {}),
};
const PaymentIcon = ({ paymentType }) => PaymentTypeIconMap[paymentType];
export default PaymentIcon;
