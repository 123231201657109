import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import { DEFAULT_SEAT_QUANTITY } from '@nomady/shared/config';
import { setGuestsChecked, setBookingData } from '../ListingPageSlice';
import NumberOfGuestsSelection from '../../../styled-components/NumberOfGuestsSelection/NumberOfGuestsSelection';
const ContentWrap = styled.div `
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
`;
const MAX_DOGS = 12;
const BookingGuestsSelection = ({ availableSeats, showSeatSelection = false, }) => {
    var _a;
    const dispatch = useDispatch();
    const { bookingData, listing } = useSelector((state) => state.ListingPage);
    const { adultQuantity, seatQuantity, teenQuantity, childQuantity, infantQuantity, dogQuantity, } = bookingData;
    useEffect(() => {
        return () => {
            dispatch(setGuestsChecked(true));
        };
    }, []);
    const { bookablePersonsPerSeat = 0, dogsAllowed = false } = ((_a = listing === null || listing === void 0 ? void 0 : listing.attributes) === null || _a === void 0 ? void 0 : _a.publicData) || {};
    const freeBookablePersons = bookablePersonsPerSeat * seatQuantity -
        adultQuantity -
        teenQuantity -
        childQuantity;
    const minimumSeats = Math.ceil((adultQuantity + teenQuantity + childQuantity) / bookablePersonsPerSeat);
    const defaultMin = 0;
    const minAdult = 1;
    return (_jsx(ContentWrap, { children: _jsx(NumberOfGuestsSelection, { seats: showSeatSelection ? seatQuantity || DEFAULT_SEAT_QUANTITY : undefined, adults: adultQuantity, teens: teenQuantity || 0, children_: childQuantity || 0, infants: infantQuantity || 0, dogs: dogsAllowed ? dogQuantity || 0 : undefined, onValueChangeSeats: seatQuantity => dispatch(setBookingData({ seatQuantity })), onValueChangeAdults: adultQuantity => dispatch(setBookingData({ adultQuantity })), onValueChangeTeens: teenQuantity => dispatch(setBookingData({ teenQuantity })), onValueChangeChildren: childQuantity => dispatch(setBookingData({ childQuantity })), onValueChangeInfants: infantQuantity => dispatch(setBookingData({ infantQuantity })), onValueChangeDogs: dogQuantity => dispatch(setBookingData({ dogQuantity })), minSeats: minimumSeats, maxSeats: availableSeats, minAdults: minAdult, maxAdults: adultQuantity + freeBookablePersons, minTeens: defaultMin, maxTeens: teenQuantity + freeBookablePersons, minChildren: defaultMin, maxChildren: childQuantity + freeBookablePersons, minInfants: defaultMin, maxInfants: infantQuantity + freeBookablePersons, minDogs: defaultMin, maxDogs: MAX_DOGS }) }));
};
export default BookingGuestsSelection;
