var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components/macro';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import config from '../../config';
import Button, { ButtonColorVariant, ButtonSizeVariant, } from '../../styled-components/Button/Button';
import Text, { TextVariant } from '../../styled-components/Text/Text';
import CheckboxInput from '../../styled-components/CheckboxInput/CheckboxInput';
import { RULES_ITEM_MAX_LENGTH, getListingRulesDefaultValues, getListingRulesSchema, } from '@nomady/shared/schemas/listing/rules';
import FormErrorMessage from '../../styled-components/FormErrorMessage/FormErrorMessage';
import InputMultiSelectSlim from '../../styled-components/InputMultiSelectSlim/InputMultiSelectSlim';
import { FormSection } from '../EditListingDescriptionForm/EditListingDescriptionForm';
import Input from '../../styled-components/Input/Input';
import SelectInput from '../../styled-components/SelectInput/SelectInput';
import InputList from '../../styled-components/InputList/InputList';
const Form = styled.form ``;
const FormRow = styled.div `
  margin-bottom: 10px;
`;
const EditListingRulesForm = ({ updateListingInProgress, updateListingError, onSubmit, onFormDirty, listing, submitButtonLabel, }) => {
    var _a, _b;
    const intl = useIntl();
    const [defaultValuesLoaded, setDefaultValuesLoaded] = useState(false);
    const listingLoaded = !!(listing === null || listing === void 0 ? void 0 : listing.id);
    const { publicData } = (listing === null || listing === void 0 ? void 0 : listing.attributes) || {};
    const defaultValues = (listing === null || listing === void 0 ? void 0 : listing.attributes)
        ? getListingRulesDefaultValues(listing === null || listing === void 0 ? void 0 : listing.attributes)
        : {};
    const { register, handleSubmit, control, reset, trigger, watch, getValues, formState: { errors, isDirty, isSubmitSuccessful }, } = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: zodResolver(getListingRulesSchema(intl)),
    });
    useEffect(() => {
        reset(defaultValues);
        setDefaultValuesLoaded(true);
    }, [listingLoaded]);
    const validateOnRender = () => {
        defaultValuesLoaded && trigger();
    };
    useEffect(validateOnRender, [trigger, defaultValuesLoaded]);
    useEffect(() => onFormDirty(isDirty), [isDirty, onFormDirty]);
    useEffect(() => {
        if (isSubmitSuccessful) {
            const values = getValues();
            reset(values);
        }
    }, [isSubmitSuccessful, getValues, reset]);
    const onSuccess = (data) => __awaiter(void 0, void 0, void 0, function* () {
        const params = {
            publicData: {
                arrivalType: data.arrivalType,
                minBookableNights: data.minBookableNights,
                maxBookableNights: data.maxBookableNights,
                instantBookingAvailable: data.instantBookingAvailable,
                cancellationPolicyType: data.cancellationPolicyType,
                checkIn: data.checkIn,
                latestCheckIn: data.latestCheckIn,
                checkOut: data.checkOut,
                dogsAllowed: data.dogs.allowed,
                dogPolicies: data.dogs.policies,
                localizedPublicData: {
                    rules: data.rules,
                },
            },
        };
        onSubmit(params);
    });
    const minBookableNights = register('minBookableNights');
    const maxBookableNights = register('maxBookableNights');
    const checkIn = register('checkIn');
    const latestCheckIn = register('latestCheckIn');
    const checkOut = register('checkOut');
    const arrivalType = register('arrivalType');
    const instantBookingAvailable = register('instantBookingAvailable');
    const instantBookingAvailableValue = !!watch('instantBookingAvailable');
    const cancellationPolicyType = register('cancellationPolicyType');
    const dogsAllowed = register('dogs.allowed');
    const showDogDetails = !!watch('dogs.allowed');
    const dogsPolicies = register('dogs.policies');
    const category = publicData === null || publicData === void 0 ? void 0 : publicData.category;
    return (_jsxs(Form, Object.assign({ onSubmit: handleSubmit(onSuccess) }, { children: [_jsxs(FormSection, { children: [_jsx(Text, Object.assign({ variant: TextVariant.H4, as: "h3" }, { children: intl.formatMessage({
                            id: 'EditListingRulesForm.arrivalStayDeparture',
                        }) })), _jsx(FormRow, { children: _jsx(SelectInput, Object.assign({ id: "minBookableNights" }, minBookableNights, { label: intl.formatMessage({
                                id: `EditListingRulesForm.minBookableNights`,
                            }), options: config.custom.minNightsOptions.map(({ key, label }) => ({
                                value: key,
                                label: intl.formatMessage({
                                    id: label,
                                }),
                            })), error: errors === null || errors === void 0 ? void 0 : errors.minBookableNights, inputRef: minBookableNights.ref, description: intl.formatMessage({
                                id: 'EditListingRulesForm.minBookableNightsTooltip',
                            }) })) }), _jsx(FormRow, { children: _jsx(SelectInput, Object.assign({ id: "maxBookableNights" }, maxBookableNights, { label: intl.formatMessage({
                                id: `EditListingRulesForm.maxBookableNights`,
                            }), options: config.custom.maxNightsOptions.map(({ key, label }) => ({
                                value: key,
                                label: intl.formatMessage({
                                    id: label,
                                }),
                            })), error: errors === null || errors === void 0 ? void 0 : errors.maxBookableNights, inputRef: maxBookableNights.ref, description: intl.formatMessage({
                                id: 'EditListingRulesForm.maxBookableNightsTooltip',
                            }) })) }), _jsx(FormRow, { children: _jsx(Input, Object.assign({ id: "checkIn" }, checkIn, { inputRef: checkIn.ref, type: "time", error: errors === null || errors === void 0 ? void 0 : errors.checkIn, label: intl.formatMessage({
                                id: 'EditListingRulesForm.checkIn',
                            }) })) }), _jsx(FormRow, { children: _jsx(Input, Object.assign({ id: "latestCheckIn" }, latestCheckIn, { inputRef: latestCheckIn.ref, type: "time", error: errors === null || errors === void 0 ? void 0 : errors.latestCheckIn, label: intl.formatMessage({
                                id: 'EditListingRulesForm.latestCheckIn',
                            }) })) }), _jsx(FormRow, { children: _jsx(Input, Object.assign({ id: "checkOut" }, checkOut, { inputRef: checkOut.ref, type: "time", error: errors === null || errors === void 0 ? void 0 : errors.checkOut, label: intl.formatMessage({
                                id: 'EditListingRulesForm.checkOut',
                            }) })) }), _jsx(FormRow, { children: _jsx(SelectInput, Object.assign({ id: "arrivalType" }, arrivalType, { label: intl.formatMessage({
                                id: `EditListingRulesForm.arrivalType`,
                            }), options: config.custom.arrivalTypes.map(({ key, label }) => ({
                                value: key,
                                label: intl.formatMessage({
                                    id: label,
                                }),
                            })), error: errors === null || errors === void 0 ? void 0 : errors.arrivalType, inputRef: arrivalType.ref, description: intl.formatMessage({
                                id: 'EditListingRulesForm.arrivalTooltip',
                            }) })) }), _jsx(FormRow, { children: _jsx(SelectInput, Object.assign({ id: "instantBookingAvailable" }, instantBookingAvailable, { label: intl.formatMessage({
                                id: `EditListingRulesForm.instantBooking`,
                            }), options: config.custom.instantBookingTypes.map(({ key, label }) => ({
                                value: key,
                                label: intl.formatMessage({
                                    id: label,
                                }),
                            })), error: errors === null || errors === void 0 ? void 0 : errors.instantBookingAvailable, inputRef: instantBookingAvailable.ref, description: intl.formatMessage({
                                id: 'EditListingRulesForm.instantBookingTooltip',
                            }) })) }), _jsx(FormRow, { children: _jsx(SelectInput, Object.assign({ id: "cancellationPolicyType" }, cancellationPolicyType, { label: intl.formatMessage({
                                id: `EditListingRulesForm.cancellations`,
                            }), options: config.custom.cancellationTypes.map(({ key, label }) => ({
                                value: key,
                                label: intl.formatMessage({
                                    id: label,
                                }),
                            })), error: errors === null || errors === void 0 ? void 0 : errors.cancellationPolicyType, inputRef: cancellationPolicyType.ref, description: intl.formatMessage({
                                id: 'EditListingRulesForm.cancellationTooltip',
                            }) })) })] }), _jsxs(FormSection, { children: [_jsx(Text, Object.assign({ variant: TextVariant.H4, as: "h3" }, { children: intl.formatMessage({ id: 'EditListingRulesForm.dogs' }) })), _jsx(CheckboxInput, Object.assign({ id: "dogs.allowed" }, dogsAllowed, { inputRef: dogsAllowed.ref, error: (_a = errors === null || errors === void 0 ? void 0 : errors.dogs) === null || _a === void 0 ? void 0 : _a.allowed, fieldLabel: intl.formatMessage({
                            id: `EditListingRulesForm.dogsAllowed.${category}`,
                        }), label: intl.formatMessage({
                            id: 'FieldBoolean.yes',
                        }) })), showDogDetails && (_jsx(FormRow, { children: _jsx(InputMultiSelectSlim, Object.assign({ control: control, id: "dogs.policies" }, dogsPolicies, { label: intl.formatMessage({
                                id: `EditListingRulesForm.dogPolicies`,
                            }), options: config.custom.dogPolicyTypes
                                .filter(element => element.condition(instantBookingAvailableValue))
                                .map(({ key, label }) => ({
                                value: key,
                                label: intl.formatMessage({
                                    id: label,
                                }),
                            })), error: (_b = errors === null || errors === void 0 ? void 0 : errors.dogs) === null || _b === void 0 ? void 0 : _b.policies })) }))] }), _jsxs(FormSection, { children: [_jsx(Text, Object.assign({ variant: TextVariant.H4, as: "h3" }, { children: intl.formatMessage({ id: 'EditListingRulesForm.ownRules' }) })), _jsx(FormRow, { children: _jsx(InputList, { control: control, id: "rules", name: "rules", error: errors === null || errors === void 0 ? void 0 : errors.rules, maxItemLength: RULES_ITEM_MAX_LENGTH, description: intl.formatMessage({
                                id: 'EditListingRulesForm.rulesTooltipTop',
                            }), label: intl.formatMessage({
                                id: 'EditListingRulesForm.rules',
                            }), inputPlaceholder: intl.formatMessage({
                                id: 'EditListingRulesForm.rulesPlaceholder',
                            }) }) })] }), _jsx(Button, { label: submitButtonLabel, colorVariant: ButtonColorVariant.Dark, sizeVariant: ButtonSizeVariant.Small, type: "submit", inProgress: updateListingInProgress }), updateListingError && (_jsx(FormErrorMessage, { id: "EditListingDescriptionForm.updateFailed" }))] })));
};
export default EditListingRulesForm;
