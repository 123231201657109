import { DEFAULT_SEAT_QUANTITY, DEFAULT_ADULT_QUANTITY } from '../config';
import { hasLineItemCodeIncluded, getLineItemByCode, } from '../utils/lineItemHelpers';
import { LINE_ITEM_SEATS, LINE_ITEM_ADULTS, LINE_ITEM_TEENS, LINE_ITEM_CHILDREN, LINE_ITEM_GIFT_CARD_DEDUCTION_1, LINE_ITEM_GIFT_CARD_DEDUCTION_2, LINE_ITEM_GIFT_CARD_DEDUCTION_3, LINE_ITEM_CUSTOMER_COMMISSION_DISCOUNT, LINE_ITEM_ADULT_TAXES, LINE_ITEM_TEEN_TAXES, LINE_ITEM_CHILD_TAXES, LINE_ITEM_CLEANING_FEE, LINE_ITEM_INFANTS, LINE_ITEM_DOGS, LINE_ITEM_EXTRAS_FIREWOOD, LINE_ITEM_EXTRAS_POWER_SUPPLY, } from '../utils/lineItemTypes';
import LineItemCalculator from './LineItemCalculator';
const getLineItemCalculatorForExistingTransaction = (requestLineItems, protectedData, listing, booking) => {
    const { attributes: { start, end }, } = booking;
    const startDate = new Date(start);
    const endDate = new Date(end);
    const lineItems = {
        seats: DEFAULT_SEAT_QUANTITY,
        adults: DEFAULT_ADULT_QUANTITY,
        teens: 0,
        children: 0,
        infants: 0,
        dogs: 0,
        giftCards: [],
        customerCommissionDiscountCode: undefined,
    };
    const listingPricing = {
        seat: 0,
        adult: 0,
        teen: 0,
        child: 0,
        visitorsTax: {
            adult: 0,
            teen: 0,
            child: 0,
        },
        cleaningFee: 0,
    };
    if (hasLineItemCodeIncluded(requestLineItems, LINE_ITEM_SEATS)) {
        const lineItem = getLineItemByCode(requestLineItems, LINE_ITEM_SEATS);
        lineItems.seats = lineItem.seats;
        listingPricing.seat = lineItem.unitPrice.amount;
    }
    if (hasLineItemCodeIncluded(requestLineItems, LINE_ITEM_ADULTS)) {
        const lineItem = getLineItemByCode(requestLineItems, LINE_ITEM_ADULTS);
        lineItems.adults = lineItem.seats;
        listingPricing.adult = lineItem.unitPrice.amount;
    }
    if (hasLineItemCodeIncluded(requestLineItems, LINE_ITEM_TEENS)) {
        const lineItem = getLineItemByCode(requestLineItems, LINE_ITEM_TEENS);
        lineItems.teens = lineItem.seats;
        listingPricing.teen = lineItem.unitPrice.amount;
    }
    if (hasLineItemCodeIncluded(requestLineItems, LINE_ITEM_CHILDREN)) {
        const lineItem = getLineItemByCode(requestLineItems, LINE_ITEM_CHILDREN);
        lineItems.children = lineItem.seats;
        listingPricing.child = lineItem.unitPrice.amount;
    }
    if (hasLineItemCodeIncluded(requestLineItems, LINE_ITEM_INFANTS)) {
        const lineItem = getLineItemByCode(requestLineItems, LINE_ITEM_INFANTS);
        lineItems.infants = lineItem.seats;
    }
    if (hasLineItemCodeIncluded(requestLineItems, LINE_ITEM_DOGS)) {
        const lineItem = getLineItemByCode(requestLineItems, LINE_ITEM_DOGS);
        lineItems.dogs = lineItem.seats;
    }
    if (hasLineItemCodeIncluded(requestLineItems, LINE_ITEM_ADULT_TAXES)) {
        const lineItem = getLineItemByCode(requestLineItems, LINE_ITEM_ADULT_TAXES);
        listingPricing.visitorsTax.adult = lineItem.unitPrice.amount;
    }
    if (hasLineItemCodeIncluded(requestLineItems, LINE_ITEM_TEEN_TAXES)) {
        const lineItem = getLineItemByCode(requestLineItems, LINE_ITEM_TEENS);
        listingPricing.visitorsTax.adult = lineItem.unitPrice.amount;
    }
    if (hasLineItemCodeIncluded(requestLineItems, LINE_ITEM_CHILD_TAXES)) {
        const lineItem = getLineItemByCode(requestLineItems, LINE_ITEM_CHILD_TAXES);
        listingPricing.visitorsTax.child = lineItem.unitPrice.amount;
    }
    if (hasLineItemCodeIncluded(requestLineItems, LINE_ITEM_CLEANING_FEE)) {
        const lineItem = getLineItemByCode(requestLineItems, LINE_ITEM_CLEANING_FEE);
        listingPricing.cleaningFee = lineItem.unitPrice.amount;
    }
    if (hasLineItemCodeIncluded(requestLineItems, LINE_ITEM_EXTRAS_POWER_SUPPLY)) {
        const lineItem = getLineItemByCode(requestLineItems, LINE_ITEM_EXTRAS_POWER_SUPPLY);
        listingPricing.powerSupply = lineItem.unitPrice.amount;
        lineItems.extras = { powerSupply: true };
    }
    if (hasLineItemCodeIncluded(requestLineItems, LINE_ITEM_EXTRAS_FIREWOOD)) {
        const lineItem = getLineItemByCode(requestLineItems, LINE_ITEM_EXTRAS_FIREWOOD);
        listingPricing.firewood = lineItem.unitPrice.amount;
        lineItems.extras = Object.assign(Object.assign({}, lineItems.extras), { firewood: true });
    }
    const { giftCards, customerCommissionDiscountCode } = protectedData;
    if (hasLineItemCodeIncluded(requestLineItems, LINE_ITEM_GIFT_CARD_DEDUCTION_1) &&
        giftCards.length > 0) {
        const lineItem = getLineItemByCode(requestLineItems, LINE_ITEM_GIFT_CARD_DEDUCTION_1);
        lineItems.giftCards = [
            ...lineItems.giftCards,
            Object.assign(Object.assign({}, giftCards[0]), { remainingValue: -1 * lineItem.unitPrice.amount }),
        ];
    }
    if (hasLineItemCodeIncluded(requestLineItems, LINE_ITEM_GIFT_CARD_DEDUCTION_2) &&
        giftCards.length > 1) {
        const lineItem = getLineItemByCode(requestLineItems, LINE_ITEM_GIFT_CARD_DEDUCTION_2);
        lineItems.giftCards = [
            ...lineItems.giftCards,
            Object.assign(Object.assign({}, giftCards[1]), { remainingValue: -1 * lineItem.unitPrice.amount }),
        ];
    }
    if (hasLineItemCodeIncluded(requestLineItems, LINE_ITEM_GIFT_CARD_DEDUCTION_3) &&
        giftCards.length > 2) {
        const lineItem = getLineItemByCode(requestLineItems, LINE_ITEM_GIFT_CARD_DEDUCTION_3);
        lineItems.giftCards = [
            ...lineItems.giftCards,
            Object.assign(Object.assign({}, giftCards[2]), { remainingValue: -1 * lineItem.unitPrice.amount }),
        ];
    }
    if (hasLineItemCodeIncluded(requestLineItems, LINE_ITEM_CUSTOMER_COMMISSION_DISCOUNT) &&
        customerCommissionDiscountCode) {
        lineItems.customerCommissionDiscountCode = customerCommissionDiscountCode;
    }
    return new LineItemCalculator(listing, lineItems, startDate, endDate, listingPricing, protectedData.paymentMode);
};
export default getLineItemCalculatorForExistingTransaction;
