import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styled from 'styled-components/macro';
import { useSelector } from 'react-redux';
import { getDeeplLocale } from '@nomady/shared/marketplace-custom-config';
import { useIntl } from 'react-intl';
import Text, { TextVariant } from '../Text/Text';
import { ReactComponent as DeeplLogo } from '../../assets/svg/deepl-logo.svg';
import { ReactComponent as NomadyLogoSmall } from '../../assets/svg/nomady-logo-small.svg';
import TabletView from '../TabletView/TabletView';
import MobileView from '../MobileView/MobileView';
const originalLocaleToTranslationKey = {
    en: 'TranslatedBy.en',
    de: 'TranslatedBy.de',
    fr: 'TranslatedBy.fr',
    it: 'TranslatedBy.it',
};
const TranslatedByWrap = styled.div `
  display: flex;
  justify-content: flex-end;
  gap: 2px;
`;
const TranslatedByLabel = styled(Text) `
  display: flex;
  color: ${props => props.theme.color.stone};
`;
const TranslatedBy = ({ translatedBy, originalLocale, }) => {
    const intl = useIntl();
    const locale = useSelector((state) => {
        if (state.UI.locale) {
            return getDeeplLocale(state.UI.locale);
        }
        return null;
    });
    if (!locale || locale === originalLocale) {
        return null;
    }
    return (_jsxs(TranslatedByWrap, { children: [_jsx(MobileView, { children: _jsx(TranslatedByLabel, Object.assign({ variant: TextVariant.H14, "aria-hidden": true }, { children: intl.formatMessage({
                        id: `TranslatedBy.by`,
                    }) })) }), _jsx(TabletView, { children: _jsx(TranslatedByLabel, Object.assign({ variant: TextVariant.H14, "aria-hidden": true }, { children: intl.formatMessage({
                        id: originalLocaleToTranslationKey[originalLocale],
                    }) })) }), translatedBy === 'deepl' ? (_jsx(DeeplLogo, { "aria-labelledby": "title" })) : (_jsx(NomadyLogoSmall, { "aria-labelledby": "title" }))] }));
};
export default TranslatedBy;
