import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState } from 'react';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components/macro';
import { isBrowser } from '@nomady/shared/utils/env';
const Wrapper = styled.div `
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
`;
const InfoTooltip = styled.div `
  margin-left: 5px;
  margin-bottom: 1px;
`;
const TooltipWrapper = styled(ReactTooltip) `
  max-width: 400px;
`;
const Tooltip = ({ text, children }) => {
    const [id] = useState(String(Math.random()));
    const icon = children || (_jsx(InfoTooltip, { children: _jsxs("svg", Object.assign({ width: "19", height: "20", viewBox: "0 0 19 20", fill: "none", xmlns: "http://www.w3.org/2000/svg", preserveAspectRatio: "xMidYMid meet" }, { children: [_jsx("circle", { cx: "9.5", cy: "10", r: "9.5", fill: "black" }), _jsx("path", { d: "M9.51191 8.37C9.16525 8.37 8.88525 8.27667 8.67191 8.09C8.46525 7.89667 8.36191 7.65667 8.36191 7.37C8.36191 7.07667 8.46525 6.83333 8.67191 6.64C8.88525 6.44667 9.16525 6.35 9.51191 6.35C9.85191 6.35 10.1252 6.44667 10.3319 6.64C10.5452 6.83333 10.6519 7.07667 10.6519 7.37C10.6519 7.65667 10.5452 7.89667 10.3319 8.09C10.1252 8.27667 9.85191 8.37 9.51191 8.37ZM10.4819 8.89V14.5H8.52191V8.89H10.4819Z", fill: "white" })] })) }));
    if (!isBrowser()) {
        return _jsx(_Fragment, { children: children });
    }
    return text ? (_jsxs(Wrapper, { children: [_jsx(TooltipWrapper, { html: true, type: "dark", effect: "solid", id: id, backgroundColor: "#000000" }), _jsx("div", Object.assign({ "data-tip": text, "data-for": id }, { children: icon }))] })) : null;
};
export default Tooltip;
