var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components/macro';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import config from '../../config';
import Button, { ButtonColorVariant, ButtonSizeVariant, } from '../../styled-components/Button/Button';
import InputTextArea from '../../styled-components/InputTextArea/InputTextArea';
import CheckboxInput from '../../styled-components/CheckboxInput/CheckboxInput';
import { getListingDescriptionWinterDefaultValues, getListingDescriptionWinterSchema, } from '@nomady/shared/schemas/listing/descriptionWinter';
import FormErrorMessage from '../../styled-components/FormErrorMessage/FormErrorMessage';
import InputMultiSelectSlim from '../../styled-components/InputMultiSelectSlim/InputMultiSelectSlim';
const Form = styled.form ``;
const FormRow = styled.div `
  margin-bottom: 10px;
`;
const EditListingDescriptionWinterForm = ({ updateListingInProgress, updateListingError, onSubmit, onFormDirty, listing, submitButtonLabel, }) => {
    var _a;
    const intl = useIntl();
    const [defaultValuesLoaded, setDefaultValuesLoaded] = useState(false);
    const listingLoaded = !!(listing === null || listing === void 0 ? void 0 : listing.id);
    const defaultValues = getListingDescriptionWinterDefaultValues(listing === null || listing === void 0 ? void 0 : listing.attributes);
    const { category } = ((_a = listing === null || listing === void 0 ? void 0 : listing.attributes) === null || _a === void 0 ? void 0 : _a.publicData) || {};
    const { register, handleSubmit, control, reset, trigger, getValues, formState: { errors, isDirty, isSubmitSuccessful }, } = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: zodResolver(getListingDescriptionWinterSchema(intl)),
    });
    useEffect(() => {
        reset(defaultValues);
        setDefaultValuesLoaded(true);
    }, [listingLoaded]);
    const validateOnRender = () => {
        defaultValuesLoaded && trigger();
    };
    useEffect(validateOnRender, [trigger, defaultValuesLoaded]);
    useEffect(() => onFormDirty(isDirty), [isDirty, onFormDirty]);
    useEffect(() => {
        if (isSubmitSuccessful) {
            const values = getValues();
            reset(values);
        }
    }, [isSubmitSuccessful, getValues, reset]);
    const onSuccess = (data) => __awaiter(void 0, void 0, void 0, function* () {
        const params = {
            publicData: {
                localizedPublicData: {
                    seasons: {
                        winter: {
                            description: data.descriptionWinter,
                            accessAndGround: data.winterAccessAndGroundDescription,
                            activities: data.winterActivities,
                            activitiesDescription: data.winterActivitiesDescription,
                        },
                    },
                },
                descriptionWinterEnabled: data.descriptionWinterEnabled,
            },
        };
        onSubmit(params);
    });
    const descriptionWinter = register('descriptionWinter');
    const winterAccessAndGroundDescription = register('winterAccessAndGroundDescription');
    const winterActivities = register('winterActivities');
    const winterActivitiesDescription = register('winterActivitiesDescription');
    const descriptionWinterEnabled = register('descriptionWinterEnabled');
    return (_jsxs(Form, Object.assign({ onSubmit: handleSubmit(onSuccess) }, { children: [_jsx(FormRow, { children: _jsx(InputTextArea, Object.assign({ label: intl.formatMessage({
                        id: 'EditListingDescriptionWinterForm.descriptionWinter',
                    }), description: intl.formatMessage({
                        id: 'EditListingDescriptionWinterForm.descriptionWinterTooltip',
                    }), placeholder: intl.formatMessage({
                        id: `EditListingDescriptionWinterForm.descriptionWinterPlaceholder.${category}`,
                    }), id: "descriptionWinter", control: control }, descriptionWinter, { error: errors === null || errors === void 0 ? void 0 : errors.descriptionWinter })) }), _jsx(FormRow, { children: _jsx(InputTextArea, Object.assign({ label: intl.formatMessage({
                        id: 'EditListingDescriptionWinterForm.winterAccessAndGroundDescription',
                    }), description: intl.formatMessage({
                        id: 'EditListingDescriptionWinterForm.winterAccessAndGroundDescriptionTooltip',
                    }), placeholder: intl.formatMessage({
                        id: 'EditListingDescriptionWinterForm.winterAccessAndGroundDescriptionPlaceholder',
                    }), id: "winterAccessAndGroundDescription", control: control }, winterAccessAndGroundDescription, { error: errors === null || errors === void 0 ? void 0 : errors.winterAccessAndGroundDescription })) }), _jsxs(FormRow, { children: [_jsx(InputMultiSelectSlim, Object.assign({ control: control, id: "winterActivities" }, winterActivities, { label: intl.formatMessage({
                            id: `EditListingDescriptionWinterForm.winterActivities`,
                        }), options: config.custom.winterActivities.map(({ key, label }) => ({
                            value: key,
                            label: intl.formatMessage({
                                id: label,
                            }),
                        })), error: errors === null || errors === void 0 ? void 0 : errors.winterActivities })), _jsx(InputTextArea, Object.assign({ label: intl.formatMessage({
                            id: 'EditListingDescriptionWinterForm.winterActivitiesDescription',
                        }), placeholder: intl.formatMessage({
                            id: 'EditListingDescriptionWinterForm.winterActivitiesDescriptionPlaceholder',
                        }), id: "winterActivitiesDescription", control: control }, winterActivitiesDescription, { error: errors === null || errors === void 0 ? void 0 : errors.winterActivitiesDescription }))] }), _jsx(FormRow, { children: _jsx(CheckboxInput, Object.assign({ id: "descriptionWinterEnabled" }, descriptionWinterEnabled, { inputRef: descriptionWinterEnabled.ref, error: errors === null || errors === void 0 ? void 0 : errors.descriptionWinterEnabled, fieldLabel: intl.formatMessage({
                        id: `EditListingDescriptionWinterForm.descriptionWinterEnabled`,
                    }), label: intl.formatMessage({
                        id: 'FieldBoolean.yes',
                    }), description: intl.formatMessage({
                        id: 'EditListingDescriptionWinterForm.descriptionWinterEnabledTooltip',
                    }) })) }), _jsx(Button, { label: submitButtonLabel, colorVariant: ButtonColorVariant.Dark, sizeVariant: ButtonSizeVariant.Small, type: "submit", inProgress: updateListingInProgress }), updateListingError && (_jsx(FormErrorMessage, { id: "EditListingDescriptionForm.updateFailed" }))] })));
};
export default EditListingDescriptionWinterForm;
