import { jsx as _jsx } from "react/jsx-runtime";
import { isBrowser } from '@nomady/shared/utils/env';
import React, { Suspense } from 'react';
const LocationSection = React.lazy(() => import('./LocationSection'));
export const LocationSectionClientOnly = () => {
    if (!isBrowser()) {
        return null;
    }
    return (_jsx(Suspense, Object.assign({ fallback: _jsx("div", { children: "Loading..." }) }, { children: _jsx(LocationSection, {}) })));
};
