import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import styled from 'styled-components/macro';
import { Markdown } from 'react-showdown';
import { useIntl } from 'react-intl';
import Page from '@nomady/shared/routeConfiguration/Page';
import { SectionType, } from '../../containers/CmsContentPage/ICmsContent';
import Accordion from '../Accordion/Accordion';
import Benefits from '../Benefits/Benefits';
import { ButtonSizeVariant, ButtonOutlineVariant, ButtonColorVariant, } from '../Button/Button';
import Hero, { HeroVariant } from '../Hero/Hero';
import HeroCta from '../HeroCta/HeroCta';
import ImageFlag from '../ImageFlag/ImageFlag';
import ImageFlagGrid from '../ImageFlagGrid/ImageFlagGrid';
import ImageGrid, { ImageGridVariant } from '../ImageGrid/ImageGrid';
import ImageSlider from '../ImageSlider/ImageSlider';
import JobAccordion from '../JobAccordion/JobAccordion';
import { LayoutRowHorizontalVariant, LayoutRowColorVariant, LayoutRowBottomVariant, LayoutRow, } from '../Layout/Layout';
import { LayoutSideNavigationRow } from '../LayoutSideNavigation/LayoutSideNavigation';
import Lead from '../Lead/Lead';
import ListingSlider from '../ListingSlider/ListingSlider';
import Persons from '../Persons/Persons';
import Publications from '../Publications/Publications';
import SectionContent, { SectionContentVariant, } from '../SectionContent/SectionContent';
import SectionContentSlider from '../SectionContentSlider/SectionContentSlider';
import SectionCta from '../SectionCta/ImageSection';
import SectionNewsletter from '../SectionNewsletter/SectionNewsletter';
import SectionVideo from '../SectionVideo/SectionVideo';
import TermsOfService from '../TermsOfService/TermsOfService';
import ReviewSlider from '../ReviewSlider/ReviewSlider';
import { ColorVariant } from '../../theme';
import { IconVariant } from '../Icon/Icon';
import RegionNavigation from '../RegionNavigation/RegionNavigation';
import SearchPageLinksSlider from '../SearchPageLinksSlider/SearchPageLinksSlider';
import InspirationArticle from '../InspirationArticle/InspirationArticle';
import SectionGiftCard from '../SectionGiftCard/SectionGiftCard';
import HeroListingSearch from '../HeroListingSearch/HeroListingSearch';
import { TextVariant } from '../Text/Text';
import SectionBlogArticles from '../SectionBlogArticles/SectionBlogArticles';
import { initialCountryConfig } from '../../containers/CmsContentPage/CmsContentPageSlice';
import SectionGiftCardShop from '../SectionGiftCardShop/SectionGiftCardShop';
import SectionGiftCardOrderConfirmation from '../SectionGiftCardOrderConfirmation/SectionGiftCardOrderConfirmation';
import MigrateMyCabinUserSection from '../SectionMigrateMyCabinUser/MigrateMyCabinUserSection';
import { useSelector } from 'react-redux';
import { isAuthenticatedSelector } from '../../ducks/Auth.duck';
import SectionContentGrid from '../SectionContentGrid/SectionContentGrid';
import { ButtonVariant } from '../Button2024/Button2024';
import PartnerVersicherung from '../PartnerVersicherung/PartnerVersicherung';
const ImageWrapper = styled.img `
  max-width: 100%;
  height: auto;
`;
const LayoutRowSpacing = styled.div `
  padding-top: 40px;
  padding-bottom: 40px;

  @media ${props => props.theme.device.tablet} {
    padding-top: 95px;
    padding-bottom: 95px;
  }
`;
const getSections = (config) => {
    const { slug, sections, intl, withSideNavigation, allListingsOnPage, allReviewsOnPage, blogArticles, countryConfig = initialCountryConfig, scrollingPosition = 0, omitLayoutRows, isAuthenticated, showSearchBar = true, } = config;
    return sections.map((section, index) => {
        var _a, _b, _c, _d, _e, _f;
        const isLast = sections.length === index + 1;
        let inner = _jsx(_Fragment, {});
        const isUncontainedSection = [
            SectionType.VideoSection,
            SectionType.NewsletterSection,
            SectionType.CtaSection,
            SectionType.HeroSection,
            SectionType.LeadSection,
            SectionType.ReviewSliderSection,
            SectionType.RegionsSection,
            SectionType.DataPrivacySection,
            SectionType.ImageFlagGridSection,
            SectionType.GiftCardCtaSection,
            SectionType.LandingHeroSection,
            // eslint-disable-next-line no-underscore-dangle
        ].includes(section.__component);
        const nextSectionIsUncontainedSection = sections[index + 1] &&
            isUncontainedSection &&
            [
                SectionType.VideoSection,
                SectionType.NewsletterSection,
                SectionType.CtaSection,
                SectionType.LeadSection,
                SectionType.RegionsSection,
                SectionType.ReviewSliderSection,
                SectionType.InspirationsSection,
                // eslint-disable-next-line no-underscore-dangle
            ].includes(sections[index + 1].__component);
        const noMarginBottom = [
            SectionType.LeadSection,
            SectionType.LandingHeroSection,
            SectionType.SearchPageLinksSection,
            SectionType.GiftCardCtaSection,
            // eslint-disable-next-line no-underscore-dangle
        ].includes(section.__component);
        let horizontalVariant = isUncontainedSection
            ? LayoutRowHorizontalVariant.Uncontained
            : undefined;
        let colorVariant;
        let bottomVariant;
        // eslint-disable-next-line no-underscore-dangle
        switch (section.__component) {
            case SectionType.LandingHeroSection:
                inner = (_jsx(HeroListingSearch, { primaryImage: section.primaryImage, title: section.title, ctaTarget: section.ctaTarget, showSearchBar: showSearchBar, ctaText: section.ctaText, scrollingPosition: scrollingPosition }));
                break;
            case SectionType.PublicationSection:
                inner = (_jsx(SectionContent, Object.assign({ title: section.title }, { children: _jsx(Publications, { publications: section.publications }) })));
                break;
            case SectionType.ImageFlagSection:
                inner = (_jsx(ImageFlag, { image: section.image, imageAlt: intl.formatMessage({ id: 'ImageFlag.imageAlt' }), variant: section.variant, buttonLink: section.buttonLink, buttonRoute: section.buttonRoute, buttonText: section.buttonText, secondaryButtonRoute: section.secondaryButtonRoute, secondaryButtonText: section.secondaryButtonText, secondaryButtonLink: section.secondaryButtonLink, buttonSizeVariant: ButtonSizeVariant.Small, logo: section.logo, title: section.title, text: section.text }));
                break;
            case SectionType.TeamSection:
                inner = (_jsx(SectionContent, Object.assign({ title: section.title }, { children: _jsx(Persons, { persons: section.persons }) })));
                break;
            case SectionType.JobsSection:
                inner = (_jsx(SectionContent, Object.assign({ title: section.title, variant: SectionContentVariant.Subheading }, { children: _jsx(JobAccordion, { jobs: section.jobs, sharedText: section.shareText, shareTitle: section.shareTitle }) })));
                break;
            case SectionType.FaqSection:
                inner = (_jsx(SectionContent, Object.assign({ variant: SectionContentVariant.Subheading, title: intl.formatMessage({ id: section.title }) }, { children: _jsx(Accordion, { sections: section.sections }) })));
                break;
            case SectionType.BenefitSection:
                inner = (_jsx(SectionContent, Object.assign({ variant: SectionContentVariant.Subheading, title: intl.formatMessage({ id: section.title }) }, { children: _jsx(Benefits, { benefits: section.benefits }) })));
                break;
            case SectionType.ImageGridSection:
                if (section.variant === ImageGridVariant.Inline) {
                    horizontalVariant = LayoutRowHorizontalVariant.Uncontained;
                    bottomVariant = LayoutRowBottomVariant.SmallMargin;
                    inner = (_jsx(SectionContentSlider, Object.assign({ title: section.title, hideControls: true, horizontalVariant: LayoutRowHorizontalVariant.Uncontained, buttonTargetRoute: Page.Partner, buttonLabel: intl.formatMessage({
                            id: 'LandingPage.partnerButtonCta',
                        }), buttonColorVariant: ButtonColorVariant.Fir, buttonSizeVariant: ButtonSizeVariant.Small, buttonIconVariant: IconVariant.ArrowRight, titleTextVariant: TextVariant.H30 }, { children: _jsx(ImageGrid, { images: section.images, variant: ImageGridVariant.Inline }) })));
                }
                else {
                    inner = (_jsx(SectionContent, Object.assign({ variant: SectionContentVariant.Subheading, title: intl.formatMessage({ id: section.title }) }, { children: _jsx(ImageGrid, { images: section.images, variant: section.variant }) })));
                }
                break;
            case SectionType.TextSection:
                inner = (_jsx(SectionContent, Object.assign({ title: section.title }, { children: _jsx(Markdown, { markdown: section.text }) })));
                break;
            case SectionType.DataPrivacySection:
                if (window === null || window === void 0 ? void 0 : window.UC_UI) {
                    window.UC_UI.restartCMP();
                }
                inner = (_jsx("div", { className: "uc-embed", "uc-embedding-type": "category", "uc-layout": "1", "uc-data": "all", "uc-show-toggle": "true", "uc-styling": "true" }));
                break;
            case SectionType.ImageSection:
                bottomVariant = LayoutRowBottomVariant.SmallMargin;
                inner = (_jsx(ImageWrapper, { src: (_c = (_b = (_a = section.image) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.attributes) === null || _c === void 0 ? void 0 : _c.url, alt: ((_e = (_d = section.image) === null || _d === void 0 ? void 0 : _d.data) === null || _e === void 0 ? void 0 : _e.attributes.alternativeText) ||
                        intl.formatMessage({
                            id: 'CmsContentPage.altImageText',
                        }), loading: "lazy" }));
                break;
            case SectionType.TermsOfServiceSection:
                inner = _jsx(TermsOfService, { section: section });
                break;
            case SectionType.ListingSliderSection:
                horizontalVariant = LayoutRowHorizontalVariant.ContainedLeft;
                bottomVariant = LayoutRowBottomVariant.SmallMargin;
                inner = (_jsx(SectionContentSlider, Object.assign({ buttonLabel: section.buttonText, buttonExternalLink: section.buttonLink, buttonTargetRoute: section.buttonTargetRoute, buttonOutlineVariant: ButtonOutlineVariant.Outlined, buttonIconVariant: IconVariant.ArrowRight, title: section.title }, { children: allListingsOnPage && (_jsx(ListingSlider, { listings: !section.featured
                            ? allListingsOnPage.filter(listing => section.listingIds
                                .map(entry => entry.listingId)
                                .includes(listing.id.uuid))
                            : allListingsOnPage.filter(listing => listing.attributes.metadata.featured) })) })));
                break;
            case SectionType.ListingGridSection:
                bottomVariant = LayoutRowBottomVariant.SmallMargin;
                inner = (_jsx(SectionContentGrid, { buttonLabel: section.buttonText, buttonTargetRoute: Page.Search, buttonSearchParams: slug
                        ? {
                            region: slug,
                        }
                        : undefined, buttonVariant: ButtonVariant.Primary, buttonIconVariant: IconVariant.Arrow_M_Right, title: section.title, listings: !section.featured
                        ? allListingsOnPage === null || allListingsOnPage === void 0 ? void 0 : allListingsOnPage.filter(listing => section.listingIds
                            .map(entry => entry.listingId)
                            .includes(listing.id.uuid))
                        : allListingsOnPage === null || allListingsOnPage === void 0 ? void 0 : allListingsOnPage.filter(listing => listing.attributes.metadata.featured) }));
                break;
            case SectionType.ReviewSliderSection:
                horizontalVariant = LayoutRowHorizontalVariant.ContainedLeft;
                colorVariant = LayoutRowColorVariant.Cave;
                inner = (_jsx(LayoutRowSpacing, { children: _jsx(SectionContentSlider, Object.assign({ title: section.title, colorVariant: ColorVariant.Pearl }, { children: allReviewsOnPage && (_jsx(ReviewSlider, { reviews: allReviewsOnPage.filter(review => section.reviews
                                .map(entry => entry.reviewId)
                                .includes(review.id.uuid)) })) })) }));
                break;
            case SectionType.RegionsSection:
                colorVariant = LayoutRowColorVariant.Moss;
                bottomVariant = isLast ? LayoutRowBottomVariant.NoMargin : undefined;
                inner = (_jsx(LayoutRow, Object.assign({ enableTopMargin: true }, { children: _jsx(RegionNavigation, { title: section.title, icon: section.icon, countryConfig: countryConfig }) })));
                break;
            case SectionType.SearchPageLinksSection:
                horizontalVariant = LayoutRowHorizontalVariant.ContainedLeft;
                bottomVariant = isLast ? LayoutRowBottomVariant.NoMargin : undefined;
                inner = (_jsx(LayoutRowSpacing, { children: _jsx(SearchPageLinksSlider, {}) }));
                break;
            case SectionType.BlogArticlesSection:
                horizontalVariant = LayoutRowHorizontalVariant.ContainedLeft;
                bottomVariant = LayoutRowBottomVariant.SmallMargin;
                inner = (_jsx(SectionContentSlider, Object.assign({ title: section.title, titleLink: section.blogLink, titleTextVariant: TextVariant.H5 }, { children: _jsx(SectionBlogArticles, { articles: blogArticles || [] }) })));
                break;
            case SectionType.ImageSliderSection:
                horizontalVariant = LayoutRowHorizontalVariant.ContainedLeft;
                inner = _jsx(ImageSlider, { images: section.images });
                break;
            case SectionType.VideoSection:
                bottomVariant = isLast ? LayoutRowBottomVariant.NoMargin : undefined;
                inner = (_jsx(SectionVideo, { video: section.videoSrc }, `${section.__component}-${section.id}`));
                break;
            case SectionType.NewsletterSection:
                bottomVariant = isLast
                    ? LayoutRowBottomVariant.NoMargin
                    : LayoutRowBottomVariant.SmallMargin;
                inner = (_jsx(SectionNewsletter, { text: section.text }, `${section.__component}-${section.id}`));
                break;
            case SectionType.CtaSection:
                bottomVariant = isLast ? LayoutRowBottomVariant.NoMargin : undefined;
                inner = (_jsx(SectionCta, { title: section.title, backgroundImage: section.backgroundImage, buttonText: section.buttonText, buttonLink: section.buttonLink, externalLink: section.externalLink }, `${section.__component}-${section.id}`));
                break;
            case SectionType.HeroSection:
                if (section.variant === HeroVariant.Cta) {
                    inner = _jsx(HeroCta, Object.assign({}, section));
                }
                else {
                    inner = _jsx(Hero, Object.assign({}, section));
                }
                break;
            case SectionType.InspirationsSection:
                horizontalVariant = LayoutRowHorizontalVariant.ContainedLeft;
                colorVariant = LayoutRowColorVariant.Horizon;
                bottomVariant = LayoutRowBottomVariant.NoMargin;
                inner = (_jsx(LayoutRowSpacing, { children: _jsx(SectionContentSlider, Object.assign({ title: section.title ||
                            intl.formatMessage({
                                id: 'LandingPage.inspirationArticlesTitle',
                            }), colorVariant: ColorVariant.Pearl }, { children: (_f = section === null || section === void 0 ? void 0 : section.inspirationExcerpts) === null || _f === void 0 ? void 0 : _f.map(article => (_jsx(InspirationArticle, { article: {
                                image: article.image,
                                title: article.title,
                                excerpt: article.excerpt,
                                category: article.category,
                                targetLink: article.targetLink,
                            }, sizeVariant: article.sizeVariant }, `inspiration-excerpt-${article.id}`))) })) }));
                break;
            case SectionType.GiftCardCtaSection:
                inner = (_jsx(SectionGiftCard, { text: section.text, buttonText: section.buttonText }));
                break;
            case SectionType.GiftCardShopSection:
                bottomVariant = LayoutRowBottomVariant.NoMargin;
                inner = _jsx(SectionGiftCardShop, {});
                break;
            case SectionType.GiftCardOrderConfirmation:
                inner = _jsx(SectionGiftCardOrderConfirmation, {});
                break;
            case SectionType.LeadSection:
                inner = _jsx(Lead, Object.assign({ colorVariant: section.colorVariant }, { children: section.text }));
                break;
            case SectionType.ImageFlagGridSection:
                inner = (_jsx(LayoutRowSpacing, { children: _jsx(SectionContentSlider, Object.assign({ title: intl.formatMessage({ id: section.title }), buttonOutlineVariant: ButtonOutlineVariant.Outlined, buttonColorVariant: ButtonColorVariant.Light, buttonSizeVariant: ButtonSizeVariant.Small, buttonLabel: section.buttonText, buttonTargetRoute: section.buttonRoute, buttonExternalLink: section.buttonLink, secondaryButtonLabel: section.secondaryButtonText, secondaryButtonTargetRoute: section.secondaryButtonRoute, secondaryButtonExternalLink: section.secondaryButtonLink }, { children: _jsx(ImageFlagGrid, Object.assign({}, section)) })) }));
                colorVariant = LayoutRowColorVariant.Pearl;
                horizontalVariant = LayoutRowHorizontalVariant.DesktopOnlyContainedLeft;
                bottomVariant = LayoutRowBottomVariant.SmallMargin;
                break;
            case SectionType.MigrateMyCabinUserSection:
                colorVariant = LayoutRowColorVariant.Fir;
                bottomVariant = LayoutRowBottomVariant.SmallMargin;
                if (!isAuthenticated) {
                    inner = (_jsx(MigrateMyCabinUserSection, { title: section.title, text: section.text }));
                }
                break;
            case SectionType.PartnerVersicherungSection:
                inner = _jsx(PartnerVersicherung, {});
                break;
        }
        if (nextSectionIsUncontainedSection || noMarginBottom) {
            bottomVariant = LayoutRowBottomVariant.NoMargin;
        }
        if (withSideNavigation) {
            return (_jsx(LayoutSideNavigationRow, { children: inner }, `${section.__component}-${section.id}`));
        }
        if (omitLayoutRows) {
            return inner;
        }
        return (_jsx(LayoutRow, Object.assign({ horizontalVariant: horizontalVariant, bottomVariant: bottomVariant, colorVariant: colorVariant }, { children: inner }), `${section.__component}-${section.id}`));
    });
};
const CmsContent = ({ slug, sections, withSideNavigation, allListingsOnPage, allReviewsOnPage, blogArticles, countryConfig, scrollingPosition, omitLayoutRows = false, showSearchBar = true, }) => {
    const intl = useIntl();
    const isAuthenticated = useSelector(isAuthenticatedSelector);
    return (_jsx(_Fragment, { children: getSections({
            slug,
            sections,
            intl,
            withSideNavigation,
            allListingsOnPage,
            allReviewsOnPage,
            blogArticles,
            countryConfig,
            scrollingPosition,
            omitLayoutRows,
            isAuthenticated,
            showSearchBar,
        }) }));
};
export default CmsContent;
