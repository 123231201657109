import { jsx as _jsx } from "react/jsx-runtime";
/**
 * This component wraps React-Router's Link by providing name-based routing.
 *
 * The `name` prop should match a route in the flattened
 * routeConfiguration passed in context by the RoutesProvider
 * component. The `params` props is the route params for the route
 * path of the given route name.
 *
 * The `to` prop is an object with the same shape as Link requires,
 * but without `pathname` that will be generated from the given route
 * name.
 *
 * Some additional props can be passed for the <a> element like
 * `className` and `style`.
 *
 * The component can also be given the `activeClassName` prop that
 * will be added to the element className if the current URL matches
 * the one in the generated pathname of the link.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { MarketplaceLocale } from '@nomady/shared/config';
import { stringify } from '@nomady/shared/utils/urlHelpers';
import { pathByRouteName } from '@nomady/shared/utils/routes';
import routeConfiguration from '@nomady/shared/routeConfiguration/routeConfiguration';
const options = [
    { label: 'DE', key: MarketplaceLocale['de-CH'], short: 'de-CH' },
    { label: 'DE', key: MarketplaceLocale['de-DE'], short: 'de-DE' },
    { label: 'DE', key: MarketplaceLocale['de-AT'], short: 'de-AT' },
    { label: 'FR', key: MarketplaceLocale['fr-CH'], short: 'fr-CH' },
    { label: 'FR', key: MarketplaceLocale['fr-FR'], short: 'fr-FR' },
    { label: 'IT', key: MarketplaceLocale['it-CH'], short: 'it-CH' },
    { label: 'IT', key: MarketplaceLocale['it-IT'], short: 'it-IT' },
    { label: 'EN', key: MarketplaceLocale['en-US'], short: 'en-US' },
];
export const NamedLinkComponent = (props) => {
    var _a;
    const { name, params, title, searchParams, onClick, userLocale } = props;
    const { match: { params: { language }, }, } = props;
    // Link props
    const { to, children } = props;
    const pathname = pathByRouteName(name, routeConfiguration, Object.assign(Object.assign({}, params), { language: userLocale || ((_a = options.find(option => option.short === language)) === null || _a === void 0 ? void 0 : _a.key) }));
    const { match } = props;
    const active = match.url && match.url === pathname;
    // <a> element props
    const { className, style, activeClassName, target, rel, showActive } = props;
    const aElemProps = {
        className: classNames(className, {
            [activeClassName]: active,
        }),
        style,
        title,
        onClick,
        target,
        rel,
    };
    if (active && showActive) {
        aElemProps.style = { textDecoration: 'underline' };
    }
    const searchString = searchParams
        ? {
            search: stringify(searchParams),
        }
        : {};
    return (_jsx(Link, Object.assign({ to: Object.assign(Object.assign({ pathname }, to), searchString) }, aElemProps, { children: children })));
};
const { object, string, shape, any } = PropTypes;
NamedLinkComponent.defaultProps = {
    params: {},
    to: {},
    children: null,
    className: '',
    style: {},
    showActive: false,
    activeClassName: 'NamedLink_active',
    title: null,
    match: {},
};
// This ensures a nice display name in snapshots etc.
NamedLinkComponent.displayName = 'NamedLink';
NamedLinkComponent.propTypes = {
    // name of the route in routeConfiguration
    name: string.isRequired,
    // params object for the named route
    params: object,
    // Link component props
    to: shape({ search: string, hash: string, state: object }),
    children: any,
    // generic props for the underlying <a> element
    className: string,
    style: object,
    activeClassName: string,
    showActive: any,
    title: string,
    // from withRouter
    match: object,
};
const mapStateToProps = (state) => {
    const { locale } = state.UI;
    return { userLocale: locale };
};
const NamedLink = compose(withRouter, connect(mapStateToProps))(NamedLinkComponent);
export default NamedLink;
