import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styled, { css } from 'styled-components/macro';
import moment from 'moment';
import { userDisplayNameAsString } from '@nomady/shared/utils/data';
import Text, { TextVariant } from '../Text/Text';
import ListingLink from '../ListingLink/ListingLink';
import Avatar from '../Avatar/Avatar';
import { ColorVariant } from '../../theme';
import { NamedLink } from '../../components';
import ReviewRating from './ReviewRating';
import TranslatedBy from '../TranslatedBy/TranslatedBy';
export var ReviewStyleVariant;
(function (ReviewStyleVariant) {
    ReviewStyleVariant["Default"] = "Default";
    ReviewStyleVariant["VerticalList"] = "VerticalList";
})(ReviewStyleVariant || (ReviewStyleVariant = {}));
const Wrapper = styled.section `
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;

  ${props => props.variant === ReviewStyleVariant.VerticalList &&
    css `
      padding: 10px 0;
      border-bottom: solid 1px ${props => props.theme.color.pearl};
    `};
`;
const Content = styled.div `
  width: 100%;
`;
const AvatarWrapper = styled(NamedLink) ``;
const Header = styled.div `
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 12px;
`;
const ReviewMeta = styled.div `
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const ListingTitle = styled.div `
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const HeaderDetails = styled.div `
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`;
const Author = styled(NamedLink) ``;
const RatingWrapper = styled.div `
  margin-left: 6px;
`;
const DateWrapper = styled(Text) `
  margin-left: 6px;
`;
const shouldShowReviewContent = (review) => {
    const reviewContent = review.attributes.content;
    if (!reviewContent || reviewContent.length === 0) {
        return false;
    }
    if (review.attributes.isMyCabinImportedReview) {
        return false;
    }
    return true;
};
const Review = ({ review, locale, variant = ReviewStyleVariant.Default, }) => {
    var _a, _b, _c, _d;
    const hasListing = !!review.listing;
    return (_jsxs(Wrapper, Object.assign({ variant: variant }, { children: [_jsxs(Header, { children: [_jsx(AvatarWrapper, Object.assign({ name: "ProfilePage", params: { id: (_a = review.author) === null || _a === void 0 ? void 0 : _a.id.uuid } }, { children: _jsx(Avatar, { source: (_c = (_b = review.author) === null || _b === void 0 ? void 0 : _b.profileImage) === null || _c === void 0 ? void 0 : _c.attributes.variants['square-small2x'].url, responsive: true }) })), _jsxs(HeaderDetails, { children: [_jsxs(ReviewMeta, { children: [_jsx(Author, Object.assign({ name: "ProfilePage", params: { id: (_d = review.author) === null || _d === void 0 ? void 0 : _d.id.uuid } }, { children: _jsx(Text, Object.assign({ variant: TextVariant.H11B, colorVariant: ColorVariant.Cave }, { children: userDisplayNameAsString(review.author, 'N/A') })) })), _jsx(DateWrapper, Object.assign({ variant: TextVariant.H14, colorVariant: ColorVariant.Stone }, { children: moment(review.attributes.createdAt).format('MMM YYYY') })), _jsx(RatingWrapper, { children: _jsx(ReviewRating, { rating: review.attributes.rating, textVariant: TextVariant.H10M }) })] }), hasListing && (_jsx(ListingTitle, { children: _jsx(ListingLink, { listingName: review.listing.attributes.title, id: review.listing.id.uuid }) }))] })] }), shouldShowReviewContent(review) && (_jsxs(Content, { children: [_jsx(Text, Object.assign({ variant: TextVariant.H12B, colorVariant: ColorVariant.Cave }, { children: review.attributes.content })), review.attributes.translatedBy &&
                        review.attributes.originalLocale !== locale && (_jsx(TranslatedBy, { translatedBy: review.attributes.translatedBy, originalLocale: review.attributes.originalLocale }))] }))] })));
};
export default Review;
