import { calculateTotalForProvider } from '../../utils/lineItemHelpers';
import { LINE_ITEM_GIFT_CARD_PROVIDER_PAYOUT, LINE_ITEM_PROVIDER_PAYOUT, TOURIST_TAX_AND_CLEANING_FEE_LINE_ITEMS, } from '../../utils/lineItemTypes';
const getTotalProviderEarningsValue = (lineItems) => {
    const lineItemsProviderWithoutPayoutDifference = lineItems
        .filter(lineItem => lineItem.code !== LINE_ITEM_GIFT_CARD_PROVIDER_PAYOUT &&
        lineItem.code !== LINE_ITEM_PROVIDER_PAYOUT)
        .filter(lineItem => !TOURIST_TAX_AND_CLEANING_FEE_LINE_ITEMS.includes(lineItem.code));
    const totalProviderEarnings = calculateTotalForProvider(lineItemsProviderWithoutPayoutDifference);
    return totalProviderEarnings;
};
export default getTotalProviderEarningsValue;
