var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState, useRef } from 'react';
import styled from 'styled-components/macro';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import { useLocation, Redirect } from 'react-router-dom';
import { EMAIL_REGEX, hasLegalAge } from '@nomady/shared/utils/validation';
import Page from '@nomady/shared/routeConfiguration/Page';
import { REFERRAL_OPTIONS, REFFERAL_CUSTOM_OPTION, } from '@nomady/shared/marketplace-custom-config';
import moment from 'moment';
import Text, { TextVariant } from '../../styled-components/Text/Text';
import Input from '../../styled-components/Input/Input';
import Button, { ButtonColorVariant, } from '../../styled-components/Button/Button';
import { PASSWORD_MAX_LENGTH, PASSWORD_MIN_LENGTH, } from '../../util/validators';
import CmsContentModal, { CmsContentModalVariant, } from '../../modals/CmsContentModal/CmsContentModal';
import { localeSelector } from '../../slices/UISlice';
import { createAccountInProgressSelector, isAuthenticatedSelector, signup, } from '../../ducks/Auth.duck';
import { NamedLink, NamedRedirect } from '../../components';
import { isSignupEmailTakenError, isTooManyEmailVerificationRequestsError, } from '../../util/errors';
import FormErrorMessage from '../../styled-components/FormErrorMessage/FormErrorMessage';
import { ColorVariant } from '../../theme';
import { sendVerificationEmail } from '../../ducks/user.duck';
import Card from '../../styled-components/Card/Card';
import CheckboxInput, { CheckboxInputSpacingVariant, } from '../../styled-components/CheckboxInput/CheckboxInput';
import MailchimpSignup from '../../styled-components/MailchimpSignup/MailchimpSignup';
import SelectInput from '../../styled-components/SelectInput/SelectInput';
const Title = styled(Text) `
  margin-bottom: 20px;
`;
const Message = styled(Text) `
  margin-bottom: 20px;
`;
const Form = styled.form `
  padding-bottom: 100px;
`;
const FormRow = styled.div `
  margin-bottom: 10px;
`;
const LabelLink = styled.a `
  padding: 0;
  text-decoration: underline;

  ${props => props.theme.typeset.H13};

  color: ${props => props.theme.color.cave};
`;
const AdditionalLinksWrapper = styled.div `
  margin-top: 22px;
`;
const ButtonCenteredWrap = styled.div `
  width: fit-content;
  margin: 30px auto;
`;
const RequiredFieldInfo = styled.div `
  margin-bottom: 30px;
`;
const NewsletterInfo = styled.div `
  margin-bottom: 10px;
  margin-left: 28px;
`;
const CreateAccountForm = ({ isFullPageSheet, onClickLogin, }) => {
    var _a, _b, _c, _d, _e;
    const intl = useIntl();
    const [cmsContentModalVariant, setCmsContentModalVariant] = useState(null);
    const [newsletterSignupActivated, setNewsletterSignupActivated] = useState(false);
    const locale = useSelector(localeSelector);
    const dispatch = useDispatch();
    const { register, handleSubmit, getValues, watch, formState: { errors }, } = useForm();
    const mailchimpFormRef = useRef(null);
    const location = useLocation();
    const user = useSelector((state) => state.user.currentUser);
    const isAuthenticated = useSelector(isAuthenticatedSelector);
    const createAccountInProgress = useSelector(createAccountInProgressSelector);
    const sendVerificationEmailInProgress = useSelector((state) => state.user.sendVerificationEmailInProgress);
    const createAccountError = useSelector((state) => state.Auth.signupError);
    const from = ((_a = location === null || location === void 0 ? void 0 : location.state) === null || _a === void 0 ? void 0 : _a.from) || null;
    const currentUserLoaded = !!(user === null || user === void 0 ? void 0 : user.id);
    const showForm = !currentUserLoaded && !isAuthenticated;
    if (isAuthenticated && from) {
        return _jsx(Redirect, { to: from });
    }
    if (!showForm && ((_b = user === null || user === void 0 ? void 0 : user.attributes) === null || _b === void 0 ? void 0 : _b.emailVerified) && !isFullPageSheet) {
        return _jsx(NamedRedirect, { name: Page.Landing });
    }
    const onSuccess = (data) => __awaiter(void 0, void 0, void 0, function* () {
        var _q;
        if (mailchimpFormRef.current) {
            mailchimpFormRef.current.submit();
        }
        const params = {
            email: data.email.trim(),
            password: data.password.trim(),
            firstName: data.firstName.trim(),
            lastName: data.lastName.trim(),
            privateData: {
                birthday: moment.utc(data.birthday).toISOString(),
                locale,
                referral: data.referral,
                customReferral: (_q = data.customReferral) === null || _q === void 0 ? void 0 : _q.trim(),
            },
        };
        dispatch(signup(params));
    });
    const _f = register('firstName', {
        required: true,
    }), { ref: firstNameRef } = _f, firstName = __rest(_f, ["ref"]);
    const firstNameValue = getValues('firstName');
    const _g = register('lastName', {
        required: true,
    }), { ref: lastNameRef } = _g, lastName = __rest(_g, ["ref"]);
    const _h = register('email', {
        required: true,
        pattern: {
            value: EMAIL_REGEX,
            message: 'Input.validationMessage.email',
        },
    }), { ref: emailRef } = _h, email = __rest(_h, ["ref"]);
    const emailValue = getValues('email');
    const _j = register('birthday', {
        required: true,
        validate: hasLegalAge,
    }), { ref: birthdayRef } = _j, birthday = __rest(_j, ["ref"]);
    const _k = register('password', {
        required: true,
        minLength: {
            value: PASSWORD_MIN_LENGTH,
            message: 'Input.validationMessage.passwordTooShort',
        },
        maxLength: {
            value: PASSWORD_MAX_LENGTH,
            message: 'Input.validationMessage.passwordTooLong',
        },
    }), { ref: passwordRef } = _k, password = __rest(_k, ["ref"]);
    const _l = register('termsOfServiceAccepted', {
        required: 'Input.validationMessage.checkbox',
    }), { ref: termsOfServiceAcceptedRef } = _l, termsOfServiceAccepted = __rest(_l, ["ref"]);
    const _m = register('nomadyPactAccepted', {
        required: 'Input.validationMessage.checkbox',
    }), { ref: nomadyPactAcceptedRef } = _m, nomadyPactAccepted = __rest(_m, ["ref"]);
    const _o = register('referral', {
        required: true,
    }), { ref: referralRef } = _o, referral = __rest(_o, ["ref"]);
    const referralValue = watch('referral');
    const isCustomReferral = referralValue && REFFERAL_CUSTOM_OPTION.includes(referralValue);
    const _p = register('customReferral', {
        validate: {
            required: value => !!value ||
                !REFFERAL_CUSTOM_OPTION.includes(getValues('referral')),
        },
    }), { ref: customReferralRef } = _p, customReferral = __rest(_p, ["ref"]);
    const openTermsModal = (event) => {
        event.preventDefault();
        setCmsContentModalVariant(CmsContentModalVariant.TermsOfService);
    };
    const openPactModal = (event) => {
        event.preventDefault();
        setCmsContentModalVariant(CmsContentModalVariant.NomadyPact);
    };
    const openPrivacyModal = (event) => {
        event.preventDefault();
        setCmsContentModalVariant(CmsContentModalVariant.PrivacyPolicy);
    };
    const closeModal = () => setCmsContentModalVariant(null);
    const termsLink = (_jsx(LabelLink, Object.assign({ onClick: openTermsModal }, { children: `${intl.formatMessage({
            id: 'CreateAccountForm.termsAndConditionsLinkText',
        })}*` })));
    const pactLink = (_jsx(LabelLink, Object.assign({ onClick: openPactModal }, { children: `${intl.formatMessage({ id: 'CreateAccountForm.nomadyPactLinkText' })}*` })));
    const privacyLink = (_jsx(LabelLink, Object.assign({ onClick: openPrivacyModal }, { children: `${intl.formatMessage({
            id: 'CreateAccountForm.privacyLinkText',
        })}*` })));
    const signInText = (_jsx("u", { children: _jsx(Text, Object.assign({ variant: TextVariant.H11B, colorVariant: ColorVariant.Cave, "data-test-id": "signInLink" }, { children: intl.formatMessage({
                id: 'CreateAccountForm.alreadyHavingAnAccountCta',
            }) })) }));
    const signInLink = isFullPageSheet && onClickLogin ? (_jsx("span", Object.assign({ onClick: onClickLogin }, { children: signInText }))) : (_jsx(NamedLink, Object.assign({ name: Page.Login }, { children: signInText })));
    const resendEmailLink = (_jsx(Text, Object.assign({ variant: TextVariant.H11B, colorVariant: ColorVariant.Cave, onClick: () => dispatch(sendVerificationEmail()) }, { children: _jsx("u", { children: intl.formatMessage({
                id: 'CreateAccountForm.resendEmailLink',
            }) }) })));
    const referralOptions = REFERRAL_OPTIONS.map(value => ({
        label: intl.formatMessage({
            id: `CreateAccountForm.referral.${value}`,
        }),
        value,
    }));
    referralOptions.unshift({ value: '', label: '' });
    const fixEmailLink = (_jsx(NamedLink, Object.assign({ name: Page.ContactDetails }, { children: _jsx(Text, Object.assign({ variant: TextVariant.H11B, colorVariant: ColorVariant.Cave }, { children: _jsx("u", { children: intl.formatMessage({
                    id: 'CreateAccountForm.fixEmailLink',
                }) }) })) })));
    return (_jsxs(_Fragment, { children: [showForm && (_jsxs(Form, Object.assign({ onSubmit: handleSubmit(onSuccess) }, { children: [_jsx(Title, Object.assign({ variant: TextVariant.H20, as: "h2" }, { children: intl.formatMessage({ id: 'CreateAccountForm.title' }) })), _jsx(FormRow, { children: _jsx(Input, Object.assign({ label: `${intl.formatMessage({
                                id: 'CreateAccountForm.firstNameLabel',
                            })}*`, autoComplete: "given-name", id: "firstName" }, firstName, { inputRef: firstNameRef, error: errors === null || errors === void 0 ? void 0 : errors.firstName })) }), _jsx(FormRow, { children: _jsx(Input, Object.assign({ label: `${intl.formatMessage({
                                id: 'CreateAccountForm.lastNameLabel',
                            })}*`, id: "lastName", autoComplete: "family-name" }, lastName, { inputRef: lastNameRef, error: errors === null || errors === void 0 ? void 0 : errors.lastName })) }), _jsx(FormRow, { children: _jsx(Input, Object.assign({ label: `${intl.formatMessage({
                                id: 'CreateAccountForm.emailLabel',
                            })}*`, id: "email", autoComplete: "email" }, email, { inputRef: emailRef, error: errors === null || errors === void 0 ? void 0 : errors.email })) }), _jsx(FormRow, { children: _jsx(Input, Object.assign({ label: `${intl.formatMessage({
                                id: 'CreateAccountForm.birthdayLabel',
                            })}*`, id: "birthday", type: "date", autoComplete: "bday" }, birthday, { inputRef: birthdayRef, error: errors === null || errors === void 0 ? void 0 : errors.birthday })) }), _jsx(FormRow, { children: _jsx(Input, Object.assign({ label: `${intl.formatMessage({
                                id: 'CreateAccountForm.passwordLabel',
                            })}*`, id: "password", type: "password", autoComplete: "new-password" }, password, { inputRef: passwordRef, error: errors === null || errors === void 0 ? void 0 : errors.password })) }), _jsx(FormRow, { children: _jsx(SelectInput, Object.assign({ options: referralOptions, label: `${intl.formatMessage({
                                id: 'CreateAccountForm.referralLabel',
                            })}*`, id: "referral" }, referral, { inputRef: referralRef, error: errors === null || errors === void 0 ? void 0 : errors.referral })) }), isCustomReferral && (_jsx(FormRow, { children: _jsx(Input, Object.assign({ label: `${intl.formatMessage({
                                id: 'CreateAccountForm.customReferralLabel',
                            })}*`, id: "customReferral", type: "text" }, customReferral, { inputRef: customReferralRef, error: errors === null || errors === void 0 ? void 0 : errors.customReferral })) })), _jsx(RequiredFieldInfo, { children: _jsx(Text, Object.assign({ variant: TextVariant.B60, colorVariant: ColorVariant.Stone }, { children: `*${intl.formatMessage({
                                id: 'CreateAccountForm.requiredFields',
                            })}` })) }), _jsxs(Card, { children: [_jsx(Text, Object.assign({ variant: TextVariant.H6, colorVariant: ColorVariant.Stone }, { children: intl.formatMessage({
                                    id: 'CreateAccountForm.checkboxesTitle',
                                }) })), _jsx(FormRow, { children: _jsx(CheckboxInput, Object.assign({ id: "terms-of-service" }, termsOfServiceAccepted, { inputRef: termsOfServiceAcceptedRef, error: errors === null || errors === void 0 ? void 0 : errors.termsOfServiceAccepted, spacingVariant: CheckboxInputSpacingVariant.Slim }, { children: intl.formatMessage({
                                        id: 'CreateAccountForm.termsAndConditionsAcceptText',
                                    }, { termsLink, privacyLink }) })) }), _jsx(FormRow, { children: _jsx(CheckboxInput, Object.assign({ id: "nomady-pact" }, nomadyPactAccepted, { inputRef: nomadyPactAcceptedRef, error: errors === null || errors === void 0 ? void 0 : errors.nomadyPactAccepted, spacingVariant: CheckboxInputSpacingVariant.Slim }, { children: intl.formatMessage({
                                        id: 'CreateAccountForm.nomadyPactAcceptText',
                                    }, { pactLink }) })) }), !!cmsContentModalVariant && (_jsx(CmsContentModal, { variant: cmsContentModalVariant, onClose: closeModal })), _jsxs(FormRow, Object.assign({ onClick: event => {
                                    event.preventDefault();
                                    if (newsletterSignupActivated) {
                                        setNewsletterSignupActivated(false);
                                    }
                                    else {
                                        setNewsletterSignupActivated(true);
                                    }
                                } }, { children: [_jsx(CheckboxInput, Object.assign({ id: "newsletter", checked: newsletterSignupActivated, readOnly: true, spacingVariant: CheckboxInputSpacingVariant.Slim }, { children: intl.formatMessage({
                                            id: 'CreateAccountForm.newsletterCheckboxText',
                                        }) })), _jsx(NewsletterInfo, { children: _jsx(Text, Object.assign({ variant: TextVariant.B60, colorVariant: ColorVariant.Stone }, { children: intl.formatMessage({
                                                id: 'CreateAccountForm.newsletterInfo',
                                            }) })) })] }))] }), !!createAccountError && (_jsx(FormErrorMessage, { id: isSignupEmailTakenError(createAccountError)
                            ? 'CreateAccountForm.signupFailedEmailAlreadyTaken'
                            : 'CreateAccountForm.signupFailed' })), _jsx(ButtonCenteredWrap, { children: _jsx(Button, { label: intl.formatMessage({
                                id: 'CreateAccountForm.primaryButtonLabel',
                            }), colorVariant: ButtonColorVariant.Dark, type: "submit", inProgress: createAccountInProgress, dataTestId: "submitSignUp" }) }), _jsx(AdditionalLinksWrapper, { children: _jsx(Text, Object.assign({ variant: TextVariant.H12B, colorVariant: ColorVariant.Cave }, { children: intl.formatMessage({
                                id: 'CreateAccountForm.alreadyHavingAnAccountText',
                            }, { signInLink }) })) })] }))), newsletterSignupActivated && (_jsx(MailchimpSignup, { firstName: firstNameValue, email: emailValue, formRef: mailchimpFormRef, suppressSignupConfirmation: true })), !showForm && !isFullPageSheet && (_jsxs(_Fragment, { children: [_jsx(Title, Object.assign({ variant: TextVariant.H20, as: "h2" }, { children: intl.formatMessage({
                            id: 'CreateAccountForm.verifyEmailTitle',
                        }, { firstName: ((_d = (_c = user === null || user === void 0 ? void 0 : user.attributes) === null || _c === void 0 ? void 0 : _c.profile) === null || _d === void 0 ? void 0 : _d.firstName) || 'Lukas' }) })), _jsx(Message, Object.assign({ variant: TextVariant.H30, as: "p", colorVariant: ColorVariant.Stone, "data-test-id": "successMessage" }, { children: intl.formatMessage({
                            id: 'CreateAccountForm.verifyEmailText',
                        }, {
                            email: (_jsx(Text, Object.assign({ colorVariant: ColorVariant.Tar, variant: TextVariant.H30, as: "span" }, { children: (_e = user === null || user === void 0 ? void 0 : user.attributes) === null || _e === void 0 ? void 0 : _e.email }))),
                        }) })), !!createAccountError && (_jsx(FormErrorMessage, { id: isTooManyEmailVerificationRequestsError(createAccountError)
                            ? 'CreateAccountForm.resendFailedTooManyRequests'
                            : 'CreateAccountForm.resendFailed' })), _jsx(AdditionalLinksWrapper, { children: _jsx(Text, Object.assign({ variant: TextVariant.H12B, colorVariant: ColorVariant.Cave }, { children: !sendVerificationEmailInProgress
                                ? intl.formatMessage({
                                    id: 'CreateAccountForm.resendEmailText',
                                }, { resendEmailLink })
                                : intl.formatMessage({
                                    id: 'CreateAccountForm.resendingEmail',
                                }) })) }), _jsx(AdditionalLinksWrapper, { children: _jsx(Text, Object.assign({ variant: TextVariant.H12B, colorVariant: ColorVariant.Cave }, { children: intl.formatMessage({
                                id: 'CreateAccountForm.fixEmailText',
                            }, { fixEmailLink }) })) })] }))] }));
};
export default CreateAccountForm;
