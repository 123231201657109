var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components/macro';
import { useForm, } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { getUserProfileDefaultValues, getUserProfileSchema, } from '@nomady/shared/schemas/user/profile';
import Button, { ButtonColorVariant, ButtonSizeVariant, } from '../../styled-components/Button/Button';
import { getLanguageNativeFromCode, languageNativeCodes, } from '../../translations/languageNativeCodes';
import Input from '../../styled-components/Input/Input';
import InputLanguageSelect from '../../styled-components/InputLanguageSelect/InputLanguageSelect';
import InputTextArea from '../../styled-components/InputTextArea/InputTextArea';
import Avatar, { SizeVariant } from '../../styled-components/Avatar/Avatar';
import { IconVariant } from '../../styled-components/Icon/Icon';
const ACCEPT_IMAGES = 'image/*';
const Form = styled.form ``;
const FormRow = styled.div `
  margin-bottom: 10px;
`;
const AvatarRow = styled.div `
  display: grid;
  grid-auto-flow: row;
  align-items: center;
  margin-bottom: 10px;
  grid-gap: 10px;

  @media ${props => props.theme.device.tablet} {
    grid-auto-flow: column;
  }
`;
const HiddenInput = styled.input `
  display: none;
`;
const ProfileSettingsForm = ({ currentUser, uploadProfileInProgress, updateInProgress, updateProfileError, uploadImageError, onImageUpload, onSubmit, }) => {
    var _a;
    const intl = useIntl();
    const [defaultValuesLoaded, setDefaultValuesLoaded] = useState(false);
    const currentUserLoaded = !!(currentUser === null || currentUser === void 0 ? void 0 : currentUser.id);
    const defaultValues = getUserProfileDefaultValues(currentUser);
    const { register, handleSubmit, control, reset, trigger, formState: { errors }, } = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: zodResolver(getUserProfileSchema(intl)),
    });
    useEffect(() => {
        reset(defaultValues);
        setDefaultValuesLoaded(true);
    }, [currentUserLoaded]);
    const validateOnRender = () => {
        defaultValuesLoaded && trigger();
    };
    useEffect(validateOnRender, [trigger, defaultValuesLoaded]);
    const onProfilePictureChange = (event) => {
        var _a;
        const file = (_a = event.target.files) === null || _a === void 0 ? void 0 : _a[0];
        if (file != null) {
            const tempId = `${file.name}_${Date.now()}`;
            onImageUpload({ id: tempId, file });
        }
    };
    const onSuccess = (data) => __awaiter(void 0, void 0, void 0, function* () {
        var _b, _c;
        const params = {
            firstName: (_b = data.firstName) === null || _b === void 0 ? void 0 : _b.trim(),
            lastName: (_c = data.lastName) === null || _c === void 0 ? void 0 : _c.trim(),
            localizedProfile: {
                biography: data.biography,
            },
            profile: {
                publicData: {
                    languageSkills: data.languageSkills,
                },
            },
        };
        onSubmit(params);
    });
    const firstName = register('firstName');
    const lastName = register('lastName');
    const biography = register('biography');
    const languageSkills = register('languageSkills');
    const selectableLanguages = languageNativeCodes.map(languageObject => {
        return {
            id: languageObject.code,
            label: languageObject.native,
        };
    });
    return (_jsxs(Form, Object.assign({ onSubmit: handleSubmit(onSuccess) }, { children: [_jsxs(AvatarRow, { children: [_jsx(Avatar, { source: (_a = currentUser.profileImage) === null || _a === void 0 ? void 0 : _a.attributes.variants['square-small2x'].url, sizeVariant: SizeVariant.XL }), uploadImageError, _jsx(Button, Object.assign({ type: "button", sizeVariant: ButtonSizeVariant.Small, colorVariant: ButtonColorVariant.Light, icon: IconVariant.Picture, disabled: updateInProgress }, { children: _jsx("label", Object.assign({ htmlFor: "profilePicture" }, { children: intl.formatMessage({
                                id: 'ProfileSettingsForm.uploadNewProfilePicture',
                            }) })) })), _jsx(HiddenInput, { type: "file", id: "profilePicture", name: "profilePicture", onChange: onProfilePictureChange, accept: ACCEPT_IMAGES })] }), _jsx(FormRow, { children: _jsx(Input, Object.assign({ label: intl.formatMessage({
                        id: 'Input.label.firstName',
                    }), id: "firstName", autoComplete: "firstName", type: "text" }, firstName, { inputRef: firstName.ref, error: errors === null || errors === void 0 ? void 0 : errors.firstName })) }), _jsx(FormRow, { children: _jsx(Input, Object.assign({ label: intl.formatMessage({
                        id: 'Input.label.lastName',
                    }), id: "lastName", autoComplete: "lastName", type: "text" }, lastName, { inputRef: lastName.ref, error: errors === null || errors === void 0 ? void 0 : errors.lastName })) }), _jsx(FormRow, { children: _jsx(InputTextArea, Object.assign({ label: intl.formatMessage({
                        id: 'Input.label.biography',
                    }), placeholder: intl.formatMessage({
                        id: 'Input.label.biography.placeholder',
                    }), id: "biography", control: control }, biography, { error: errors === null || errors === void 0 ? void 0 : errors.biography })) }), _jsx(FormRow, { children: _jsx(InputLanguageSelect, Object.assign({ label: intl.formatMessage({
                        id: 'Input.label.languageSkills',
                    }), id: "languageSkills" }, languageSkills, { control: control, error: errors.languageSkills, selectableValues: selectableLanguages, valueSelectedIndicatorLabel: intl.formatMessage({
                        id: 'ProfileSettingsForm.selectedSpeakingSkillsLabel',
                    }), noValueSelectedIndicatorLabel: intl.formatMessage({
                        id: 'ProfileSettingsForm.speakingSkillsLabel',
                    }), transformActivatedValues: getLanguageNativeFromCode })) }), updateProfileError, _jsx(Button, { label: intl.formatMessage({
                    id: 'ContactDetailsPage.primaryButtonLabel',
                }), colorVariant: ButtonColorVariant.Dark, sizeVariant: ButtonSizeVariant.Small, type: "submit", inProgress: uploadProfileInProgress })] })));
};
export default ProfileSettingsForm;
