import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styled from 'styled-components/macro';
import { useIntl } from 'react-intl';
import Icon, { IconVariant, IconSizeVariant, } from '../../../styled-components/Icon/Icon';
import Text, { TextVariant } from '../../../styled-components/Text/Text';
import { ColorVariant } from '../../../theme';
import { ShowerType } from '@nomady/shared/types';
const ChecklistWrapper = styled.div `
  width: 100%;
  padding: 20px 0px;
`;
const TitleWrapper = styled.div `
  display: flex;
  flex-direction: column;
  align-items: left;
  margin: 15px 0px 20px;
  width: fit-content;
  @media ${props => props.theme.device.desktop} {
    margin: 50px 0px 42px;
  }
`;
const OverviewSection = styled.ul `
  display: grid;
  width: 100%;

  grid-template-columns: 1fr;
  grid-auto-flow: row;

  @media ${props => props.theme.device.tablet} {
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: 1fr 1fr;
  }
`;
const OverviewSectionWrapper = styled.div `
  display: flex;
  flex-direction: column;
  gap: 0px;

  @media ${props => props.theme.device.tablet} {
    flex-direction: row;
    gap: 20px;
  } ;
`;
const TextStyled = styled(Text) `
  margin-left: 18px;
  color: ${props => props.inactive ? props.theme.color.fog : props.theme.color.cave};
`;
const OverviewItemWrapper = styled.li `
  display: flex;
  align-items: center;
  padding: 15px;

  border-bottom: 1px solid ${props => props.theme.color.fog};

  &:last-child {
    border-bottom: 0px none;
  }
`;
const ListingOverviewSection = ({ toiletAvailable = false, openFireAllowed = false, waterAvailable = false, showerAvailable = false, showerType = [], dogsAllowed = false, dogCamp = false, familyFriendly = false, powerSupplyAvailable = false, shelterAvailable = false, }) => {
    const intl = useIntl();
    return (_jsxs(ChecklistWrapper, { children: [_jsx(TitleWrapper, { children: _jsx(Text, Object.assign({ "data-testid": "ListingOverviewSection_Title", as: "h2", variant: TextVariant.H30, colorVariant: ColorVariant.Cave }, { children: intl.formatMessage({ id: 'ListingPage.campOverview' }) })) }), _jsx(OverviewSectionWrapper, { children: _jsxs(OverviewSection, { children: [_jsxs(OverviewItemWrapper, { children: [_jsx(Icon, { variant: IconVariant.Fireplace, sizeVariant: IconSizeVariant.Medium, crossedOut: !openFireAllowed }), _jsx(TextStyled, Object.assign({ inactive: !openFireAllowed, variant: TextVariant.H51 }, { children: intl.formatMessage({ id: 'ListingPage.fireplace' }) }))] }), _jsxs(OverviewItemWrapper, { children: [_jsx(Icon, { variant: IconVariant.WC, sizeVariant: IconSizeVariant.Medium, crossedOut: !toiletAvailable }), _jsx(TextStyled, Object.assign({ inactive: !toiletAvailable, variant: TextVariant.H51 }, { children: intl.formatMessage({ id: 'ListingPage.wc' }) }))] }), _jsxs(OverviewItemWrapper, { children: [_jsx(Icon, { variant: IconVariant.Water, sizeVariant: IconSizeVariant.Medium, crossedOut: !waterAvailable }), _jsx(TextStyled, Object.assign({ inactive: !waterAvailable, variant: TextVariant.H51 }, { children: intl.formatMessage({ id: 'ListingPage.freshwater' }) }))] }), showerAvailable && (_jsxs(OverviewItemWrapper, { children: [_jsx(Icon, { variant: IconVariant.ShowerNew, sizeVariant: IconSizeVariant.Medium }), _jsx(TextStyled, Object.assign({ variant: TextVariant.H51 }, { children: intl.formatMessage({
                                        id: showerType.includes(ShowerType.outdoor)
                                            ? 'MarketplaceConfig.outdoorShower'
                                            : 'MarketplaceConfig.regularShower',
                                    }) }))] })), familyFriendly && (_jsxs(OverviewItemWrapper, { children: [_jsx(Icon, { variant: IconVariant.Child_friendly, sizeVariant: IconSizeVariant.Medium }), _jsx(TextStyled, Object.assign({ variant: TextVariant.H51 }, { children: intl.formatMessage({ id: 'ListingPage.familyFriendly' }) }))] })), _jsxs(OverviewItemWrapper, { children: [_jsx(Icon, { variant: IconVariant.Roof, sizeVariant: IconSizeVariant.Medium, crossedOut: !shelterAvailable }), _jsx(TextStyled, Object.assign({ inactive: !shelterAvailable, variant: TextVariant.H51 }, { children: intl.formatMessage({ id: 'ListingPage.shelter' }) }))] }), _jsxs(OverviewItemWrapper, { children: [_jsx(Icon, { variant: IconVariant.Dog_NEW, sizeVariant: IconSizeVariant.Medium, crossedOut: !dogsAllowed && !dogCamp }), _jsx(TextStyled, Object.assign({ inactive: !dogsAllowed && !dogCamp, variant: TextVariant.H51 }, { children: intl.formatMessage({
                                        id: `ListingPage.${dogCamp ? 'dogCamp' : 'dogsAllowed'}`,
                                    }) }))] }), _jsxs(OverviewItemWrapper, { children: [_jsx(Icon, { variant: IconVariant.Electricity_NEW, sizeVariant: IconSizeVariant.Medium, crossedOut: !powerSupplyAvailable }), _jsx(TextStyled, Object.assign({ inactive: !powerSupplyAvailable, variant: TextVariant.H51 }, { children: intl.formatMessage({ id: 'ListingPage.electricity' }) }))] })] }) })] }));
};
export default ListingOverviewSection;
