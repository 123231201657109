import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styled from 'styled-components/macro';
import Text from '../../../styled-components/Text/Text';
import { ColorVariant } from '../../../theme';
import Logo from '../../../styled-components/Logo/Logo';
const Wrapper = styled.div `
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: [left-column] auto [right-column] auto;
  align-items: center;
  height: 100%;
  width: 100vw;
  background-color: ${props => props.theme.color.pearl};
  padding: 17.5px 15px;
  @media ${props => props.theme.device.tablet} {
    background-color: ${props => props.theme.color.snow};
    grid-template-columns: [left-column] 1fr [center-column] 1fr [right-column] 1fr;
    flex-direction: row-reverse;
    justify-content: flex-end;
  }
`;
const Title = styled(Text) `
  ${props => props.theme.typeset.H30}
  grid-column: left-column;
  grid-row: 1;
  @media ${props => props.theme.device.tablet} {
    grid-column: center-column;
    ${props => props.theme.typeset.H20}
    justify-self: center;
    margin: 0 auto;
  }
`;
const LogoContainer = styled.div `
  display: flex;
  justify-content: flex-end;
  grid-column: right-column;
  grid-row: 1;
  & svg {
    .fill {
      fill: ${props => props.theme.color.stone};
    }
    height: 16px;
    width: auto;
  }
  @media ${props => props.theme.device.tablet} {
    grid-column: left-column;
    justify-content: flex-start;
    & svg {
      .fill {
        fill: ${props => props.theme.color.cave};
      }
      height: 25px;
      width: auto;
    }
  }
`;
const CheckoutPageHeader = ({ title }) => {
    return (_jsxs(Wrapper, { children: [_jsx(Title, Object.assign({ colorVariant: ColorVariant.Stone }, { children: title })), _jsx(LogoContainer, { children: _jsx(Logo, { responsive: true }) })] }));
};
export default CheckoutPageHeader;
