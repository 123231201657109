var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import Page from '@nomady/shared/routeConfiguration/Page';
import Button, { ButtonColorVariant } from '../Button/Button';
import Text, { TextVariant } from '../Text/Text';
import { ColorVariant } from '../../theme';
import EmailIllustrationSrc from './refresh-illustration.webp';
import { sendVerificationEmail } from '../../ducks/user.duck';
import { NamedLink } from '../../components';
const Wrapper = styled.div `
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  gap: 10px;
`;
const IllustrationWrapper = styled.div `
  background-image: url(${EmailIllustrationSrc});
  width: 100%;
  height: 180px;
  background-size: contain;
  background-position: center;
`;
const ButtonWrapper = styled.div `
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 8px auto;
`;
const AdditionalLinksWrapper = styled.div `
  text-align: center;
`;
const EmailVerificationCta = ({ onClickRefreshVerification }) => {
    var _a, _b;
    const [isRefreshing, setIsRefreshing] = useState(false);
    const [notYetVerified, setNotYetVerified] = useState(false);
    const intl = useIntl();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user.currentUser);
    const emailForDisplay = ((_a = user === null || user === void 0 ? void 0 : user.attributes) === null || _a === void 0 ? void 0 : _a.pendingEmail) || ((_b = user === null || user === void 0 ? void 0 : user.attributes) === null || _b === void 0 ? void 0 : _b.email);
    const sendVerificationEmailInProgress = useSelector((state) => state.user.sendVerificationEmailInProgress);
    const onClickRefresh = () => __awaiter(void 0, void 0, void 0, function* () {
        setNotYetVerified(false);
        setIsRefreshing(true);
        yield onClickRefreshVerification().then((updatedUserVerification) => {
            if (!updatedUserVerification) {
                setNotYetVerified(true);
            }
            setIsRefreshing(false);
        });
    });
    const resendEmailLink = (_jsx(Text, Object.assign({ variant: TextVariant.H11B, colorVariant: ColorVariant.Cave, onClick: () => dispatch(sendVerificationEmail()) }, { children: _jsx("u", { children: intl.formatMessage({
                id: 'CreateAccountForm.resendEmailLink',
            }) }) })));
    const fixEmailLink = (_jsx(NamedLink, Object.assign({ name: Page.ContactDetails }, { children: _jsx(Text, Object.assign({ variant: TextVariant.H11B, colorVariant: ColorVariant.Cave }, { children: _jsx("u", { children: intl.formatMessage({
                    id: 'CreateAccountForm.fixEmailLink',
                }) }) })) })));
    return (_jsxs(Wrapper, { children: [_jsx(Text, Object.assign({ variant: TextVariant.H50, colorVariant: ColorVariant.Stone, as: "h3" }, { children: intl.formatMessage({ id: 'EmailVerificationCta.almostDone' }) })), _jsx(IllustrationWrapper, {}), _jsx(Text, Object.assign({ variant: TextVariant.H20 }, { children: intl.formatMessage({ id: 'EmailVerificationCta.title' }) })), _jsx(Text, Object.assign({ variant: TextVariant.B40 }, { children: _jsxs("ol", { children: [_jsxs("li", { children: [intl.formatMessage({
                                    id: 'EmailVerificationCta.descriptionEmail',
                                }), _jsxs("b", { children: [" ", emailForDisplay] })] }), _jsx("li", { children: intl.formatMessage({
                                id: 'EmailVerificationCta.descriptionActivation',
                            }) }), _jsx("li", { children: intl.formatMessage({
                                id: 'EmailVerificationCta.descriptionContinue',
                            }) })] }) })), _jsxs(ButtonWrapper, { children: [notYetVerified && (_jsx(Text, Object.assign({ variant: TextVariant.B40 }, { children: intl.formatMessage({ id: 'EmailVerificationCta.retry' }) }))), _jsx(Button, { dataTestId: "EmailVerificationCta_refreshButton", label: intl.formatMessage({ id: 'EmailVerificationCta.refresh' }), inProgress: isRefreshing, colorVariant: ButtonColorVariant.Forest, onClick: onClickRefresh })] }), _jsx(AdditionalLinksWrapper, { children: _jsx(Text, Object.assign({ variant: TextVariant.H12B, colorVariant: ColorVariant.Cave }, { children: !sendVerificationEmailInProgress
                        ? intl.formatMessage({
                            id: 'CreateAccountForm.resendEmailText',
                        }, { resendEmailLink })
                        : intl.formatMessage({
                            id: 'CreateAccountForm.resendingEmail',
                        }) })) }), _jsx(AdditionalLinksWrapper, { children: _jsx(Text, Object.assign({ variant: TextVariant.H12B, colorVariant: ColorVariant.Cave }, { children: intl.formatMessage({
                        id: 'PasswordRecoveryForm.fixEmailText',
                    }, { fixEmailLink }) })) })] }));
};
export default EmailVerificationCta;
