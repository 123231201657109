import { useMediaQuery } from 'react-responsive';
const useBreakpoints = () => {
    const isMobile = useMediaQuery({ query: '(min-width: 320px)' });
    const isTablet = useMediaQuery({ query: '(min-width: 600px)' });
    const isTabletL = useMediaQuery({ query: '(min-width: 768px)' });
    const isDesktop = useMediaQuery({ query: '(min-width: 1024px)' });
    const isDesktopL = useMediaQuery({ query: '(min-width: 1440px)' });
    const isDesktopXL = useMediaQuery({ query: '(min-width: 1580px)' });
    const isDesktopXXL = useMediaQuery({ query: '(min-width: 1921px)' });
    return {
        isMobile,
        isTablet,
        isTabletL,
        isDesktop,
        isDesktopL,
        isDesktopXL,
        isDesktopXXL,
    };
};
export default useBreakpoints;
