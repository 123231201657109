var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { EMAIL_REGEX, hasLegalAge } from '@nomady/shared/utils/validation';
import SignedInPageContainer from '../../styled-components/PageContainer/SignedInPageContainer';
import config from '../../config';
import Text, { TextVariant } from '../../styled-components/Text/Text';
import Input from '../../styled-components/Input/Input';
import Button, { ButtonColorVariant, ButtonSizeVariant, } from '../../styled-components/Button/Button';
import { isChangeEmailTakenError, isChangeEmailWrongPassword, isTooManyEmailVerificationRequestsError, } from '../../util/errors';
import FormErrorMessage from '../../styled-components/FormErrorMessage/FormErrorMessage';
import { ColorVariant } from '../../theme';
import { sendVerificationEmail } from '../../ducks/user.duck';
import LayoutSideNavigation from '../../styled-components/LayoutSideNavigation/LayoutSideNavigation';
import InputPhone from '../../styled-components/InputPhone/InputPhone';
import InputGeolocation from '../../styled-components/InputGeolocation/InputGeolocation';
import { saveContactDetails } from './ContactDetailsPageSlice';
import { PASSWORD_MIN_LENGTH, PASSWORD_MAX_LENGTH, } from '../../util/validators';
const Title = styled(Text) `
  margin-bottom: 20px;
`;
const Form = styled.form ``;
const FormRow = styled.div `
  margin-bottom: 10px;
`;
const SHOW_EMAIL_SENT_TIMEOUT = 2000;
const ContactDetailsPage = ({ tabs }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    const intl = useIntl();
    const { siteTitle } = config;
    const schemaTitle = intl.formatMessage({ id: 'ContactDetailsPage.schemaTitle' }, { siteTitle });
    const dispatch = useDispatch();
    const currentUser = useSelector((state) => state.user.currentUser);
    const sendVerificationEmailInProgress = useSelector((state) => state.user.sendVerificationEmailInProgress);
    const saveContactDetailsInProgress = useSelector((state) => state.ContactDetailsPage.saveContactDetailsInProgress);
    const sendVerificationEmailError = useSelector((state) => state.user.sendVerificationEmailError);
    const saveDataError = useSelector((state) => state.ContactDetailsPage.saveDataError);
    const saveEmailError = useSelector((state) => state.ContactDetailsPage.saveEmailError);
    const [showVerificationEmailSent, setShowVerificationEmailSent] = useState(false);
    const currentUserLoaded = !!(currentUser === null || currentUser === void 0 ? void 0 : currentUser.id);
    const defaultValues = {
        email: (_a = currentUser === null || currentUser === void 0 ? void 0 : currentUser.attributes) === null || _a === void 0 ? void 0 : _a.email,
        birthday: moment((_d = (_c = (_b = currentUser === null || currentUser === void 0 ? void 0 : currentUser.attributes) === null || _b === void 0 ? void 0 : _b.profile) === null || _c === void 0 ? void 0 : _c.privateData) === null || _d === void 0 ? void 0 : _d.birthday).format('Y-MM-DD'),
        phoneNumber: (_g = (_f = (_e = currentUser === null || currentUser === void 0 ? void 0 : currentUser.attributes) === null || _e === void 0 ? void 0 : _e.profile) === null || _f === void 0 ? void 0 : _f.privateData) === null || _g === void 0 ? void 0 : _g.phoneNumber,
        address: (_j = (_h = currentUser === null || currentUser === void 0 ? void 0 : currentUser.attributes) === null || _h === void 0 ? void 0 : _h.profile.privateData) === null || _j === void 0 ? void 0 : _j.address,
    };
    const { register, handleSubmit, control, reset, watch, setError, formState: { errors }, } = useForm({ defaultValues });
    useEffect(() => {
        reset(defaultValues);
    }, [currentUserLoaded]);
    useEffect(() => {
        const emailAlreadyTaken = isChangeEmailTakenError(saveEmailError);
        if (emailAlreadyTaken) {
            setError('email', {
                type: 'custom',
                message: intl.formatMessage({
                    id: 'ContactDetailsPage.emailTakenError',
                }),
            });
        }
        const passwordWrong = isChangeEmailWrongPassword(saveEmailError);
        if (passwordWrong) {
            setError('currentPassword', {
                type: 'custom',
                message: intl.formatMessage({
                    id: 'ContactDetailsPage.passwordFailed',
                }),
            });
        }
    }, [saveContactDetailsInProgress]);
    const onSuccess = (data) => __awaiter(void 0, void 0, void 0, function* () {
        var _k, _l;
        const params = {
            email: (_k = data.email) === null || _k === void 0 ? void 0 : _k.trim(),
            phoneNumber: data.phoneNumber,
            address: data.address,
            birthday: moment.utc(data.birthday).toDate(),
            currentPassword: (_l = data.currentPassword) === null || _l === void 0 ? void 0 : _l.trim(),
        };
        dispatch(saveContactDetails(params));
    });
    const email = register('email', {
        required: true,
        pattern: {
            value: EMAIL_REGEX,
            message: 'Input.validationMessage.email',
        },
    });
    const birthday = register('birthday', {
        required: true,
        validate: hasLegalAge,
    });
    const requireCurrentPassword = watch('email') !== defaultValues.email;
    let currentPassword;
    if (requireCurrentPassword) {
        currentPassword = register('currentPassword', {
            required: true,
            minLength: {
                value: PASSWORD_MIN_LENGTH,
                message: 'Input.validationMessage.passwordTooShort',
            },
            maxLength: {
                value: PASSWORD_MAX_LENGTH,
                message: 'Input.validationMessage.passwordTooLong',
            },
        });
    }
    if (!currentUser) {
        return null;
    }
    const showForm = !!currentUserLoaded;
    const handleResendVerificationEmail = () => __awaiter(void 0, void 0, void 0, function* () {
        setShowVerificationEmailSent(true);
        dispatch(sendVerificationEmail());
        window.setTimeout(() => {
            setShowVerificationEmailSent(false);
        }, SHOW_EMAIL_SENT_TIMEOUT);
    });
    const { email: currentEmail, emailVerified, pendingEmail, } = (currentUser === null || currentUser === void 0 ? void 0 : currentUser.attributes) || {};
    const emailChanged = currentEmail !== watch('email');
    const tooManyVerificationRequests = isTooManyEmailVerificationRequestsError(sendVerificationEmailError);
    let resendEmailMessage = null;
    if (tooManyVerificationRequests) {
        resendEmailMessage = (_jsx(Text, Object.assign({ variant: TextVariant.H11B }, { children: _jsx(FormattedMessage, { id: "ContactDetailsPage.tooManyVerificationRequests" }) })));
    }
    else if (sendVerificationEmailInProgress || showVerificationEmailSent) {
        resendEmailMessage = (_jsx(Text, Object.assign({ variant: TextVariant.H10, colorVariant: ColorVariant.Cave }, { children: _jsx(FormattedMessage, { id: "ContactDetailsPage.emailSent" }) })));
    }
    else {
        resendEmailMessage = (_jsx(Text, Object.assign({ onClick: handleResendVerificationEmail, role: "button", variant: TextVariant.H11B }, { children: _jsx(FormattedMessage, { id: "ContactDetailsPage.resendEmailVerificationText" }) })));
    }
    let emailVerifiedInfo = null;
    if (emailVerified && !pendingEmail && !emailChanged) {
        emailVerifiedInfo = (_jsx(Text, Object.assign({ variant: TextVariant.H10, colorVariant: ColorVariant.Lagoon }, { children: _jsx(FormattedMessage, { id: "ContactDetailsPage.emailVerified" }) })));
    }
    else if (!emailVerified && !pendingEmail) {
        emailVerifiedInfo = (_jsx(Text, Object.assign({ variant: TextVariant.H10, colorVariant: ColorVariant.Cinnabar }, { children: _jsx(FormattedMessage, { id: "ContactDetailsPage.emailUnverified", values: { resendEmailMessage } }) })));
    }
    else if (pendingEmail) {
        const pendingEmailStyled = (_jsx(Text, Object.assign({ variant: TextVariant.H11B }, { children: pendingEmail })));
        const pendingEmailCheckInbox = (_jsx(Text, Object.assign({ variant: TextVariant.H10 }, { children: _jsx(FormattedMessage, { id: "ContactDetailsPage.pendingEmailCheckInbox", values: { pendingEmail: pendingEmailStyled } }) })));
        emailVerifiedInfo = (_jsx(Text, Object.assign({ variant: TextVariant.H10, colorVariant: ColorVariant.Amber }, { children: _jsx(FormattedMessage, { id: "ContactDetailsPage.pendingEmailUnverified", values: { pendingEmailCheckInbox, resendEmailMessage } }) })));
    }
    const emailAlreadyTaken = isChangeEmailTakenError(saveEmailError);
    const passwordWrong = isChangeEmailWrongPassword(saveEmailError);
    const isGenericEmailError = saveEmailError && !(emailAlreadyTaken || passwordWrong);
    let genericError = null;
    if (isGenericEmailError && saveDataError) {
        genericError = _jsx(FormErrorMessage, { id: "ContactDetailsPage.genericFailure" });
    }
    else if (isGenericEmailError) {
        genericError = (_jsx(FormErrorMessage, { id: "ContactDetailsPage.genericEmailFailure" }));
    }
    else if (saveDataError) {
        genericError = (_jsx(FormErrorMessage, { id: "ContactDetailsPage.genericDataFailure" }));
    }
    return (_jsx(SignedInPageContainer, Object.assign({ title: schemaTitle }, { children: _jsx(LayoutSideNavigation, Object.assign({ tabs: tabs }, { children: showForm && (_jsxs(Form, Object.assign({ onSubmit: handleSubmit(onSuccess) }, { children: [_jsx(Title, Object.assign({ variant: TextVariant.H3, as: "h2" }, { children: intl.formatMessage({ id: 'ContactDetailsPage.title' }) })), _jsx(FormRow, { children: _jsx(Input, Object.assign({ label: intl.formatMessage({
                                id: 'Input.label.email',
                            }), id: "email", autoComplete: "email", type: "email" }, email, { inputRef: email.ref, error: errors === null || errors === void 0 ? void 0 : errors.email })) }), currentEmail.endsWith('@privaterelay.appleid.com') && (_jsx(Text, Object.assign({ variant: TextVariant.H10, colorVariant: ColorVariant.Cinnabar }, { children: _jsx(FormattedMessage, { id: "ContactDetailsPage.appleHideMyMailInfo", values: { email: currentEmail } }) }))), _jsx("br", {}), emailVerifiedInfo, _jsx(FormRow, { children: _jsx(InputPhone, { label: intl.formatMessage({
                                id: 'Input.label.phoneNumber',
                            }), id: "phoneNumber", name: "phoneNumber", control: control, rules: {
                                required: true,
                                validate: (value) => !!value.formattedValue ||
                                    'Input.validationMessage.invalidPhoneNumber',
                            } }) }), _jsx(FormRow, { children: _jsx(Input, Object.assign({ label: intl.formatMessage({
                                id: 'Input.label.birthday',
                            }), id: "birthday", type: "date", autoComplete: "bday" }, birthday, { inputRef: birthday.ref, error: errors === null || errors === void 0 ? void 0 : errors.birthday })) }), _jsx(FormRow, { children: _jsx(InputGeolocation, { label: intl.formatMessage({
                                id: 'Input.label.address',
                            }), id: "address", name: "address", control: control, rules: {
                                required: true,
                            } }) }), requireCurrentPassword && currentPassword && (_jsx(FormRow, { children: _jsx(Input, Object.assign({ label: intl.formatMessage({
                                id: 'Input.label.currentPassword',
                            }), id: "currentPassword", type: "password", autoComplete: "current-password" }, currentPassword, { inputRef: currentPassword.ref, error: errors === null || errors === void 0 ? void 0 : errors.currentPassword })) })), genericError, _jsx(Button, { label: intl.formatMessage({
                            id: 'ContactDetailsPage.primaryButtonLabel',
                        }), colorVariant: ButtonColorVariant.Dark, sizeVariant: ButtonSizeVariant.Small, type: "submit", inProgress: saveContactDetailsInProgress })] }))) })) })));
};
export default ContactDetailsPage;
