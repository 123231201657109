import moment from 'moment';
import { CANCELLATION_TYPE } from '../marketplace-custom-config';
import { GIFT_CARD_TRANSFER_ACTION } from '../config';
import { LINE_ITEM_CUSTOMER_COMMISSION, LINE_ITEM_GIFT_CARD_DEDUCTION_1, LINE_ITEM_GIFT_CARD_DEDUCTION_2, LINE_ITEM_GIFT_CARD_DEDUCTION_3, TOURIST_TAX_AND_CLEANING_FEE_LINE_ITEMS, } from '../utils/lineItemTypes';
import RefundCalculator, { REFUND_TYPE } from './RefundCalculator';
import { txHasBeenAccepted, txIsCancellationPossibleByCancellationPolicy, } from '../utils/transaction';
import { CancellationType, } from '../types';
import getListingCheckInTime from '../utils/listing';
export const getArrivalDate = (startDay, checkInTime) => {
    return moment(startDay)
        .set('hour', checkInTime.hour())
        .set('minute', checkInTime.minute());
};
class RefundCalculatorFactory {
    // eslint-disable-next-line class-methods-use-this
    getRefundCalculatorByTransactionAndListing(transaction, listing, isCustomer, booking, forcedRefundType) {
        var _a;
        const cancellationPolicy = (_a = listing.attributes.publicData) === null || _a === void 0 ? void 0 : _a.cancellationPolicyType;
        if (!Object.values(CancellationType).includes(cancellationPolicy)) {
            throw new Error(`invalid cancellation policy "${cancellationPolicy}`);
        }
        const { giftCards } = transaction.attributes.protectedData;
        const hasGiftCard1 = 0 < (giftCards === null || giftCards === void 0 ? void 0 : giftCards.length);
        const hasGiftCard2 = 1 < (giftCards === null || giftCards === void 0 ? void 0 : giftCards.length);
        const hasGiftCard3 = 2 < (giftCards === null || giftCards === void 0 ? void 0 : giftCards.length);
        const payinDate = transaction.attributes.createdAt;
        let giftCard1;
        if (hasGiftCard1) {
            const giftCard1LineItem = transaction.attributes.lineItems.find(lineItem => lineItem.code === LINE_ITEM_GIFT_CARD_DEDUCTION_1);
            if (!giftCard1LineItem) {
                throw new Error(`could not find line item ${LINE_ITEM_GIFT_CARD_DEDUCTION_1}`);
            }
            giftCard1 = Object.assign(Object.assign({}, giftCard1LineItem.lineTotal), { metadata: giftCards[0] });
        }
        let giftCard2;
        if (hasGiftCard2) {
            const giftCard2LineItem = transaction.attributes.lineItems.find(lineItem => lineItem.code === LINE_ITEM_GIFT_CARD_DEDUCTION_2);
            if (!giftCard2LineItem) {
                throw new Error(`could not find line item ${LINE_ITEM_GIFT_CARD_DEDUCTION_2}`);
            }
            giftCard2 = Object.assign(Object.assign({}, giftCard2LineItem.lineTotal), { metadata: giftCards[1] });
        }
        let giftCard3;
        if (hasGiftCard3) {
            const giftCard3LineItem = transaction.attributes.lineItems.find(lineItem => lineItem.code === LINE_ITEM_GIFT_CARD_DEDUCTION_3);
            if (!giftCard3LineItem) {
                throw new Error(`could not find line item ${LINE_ITEM_GIFT_CARD_DEDUCTION_3}`);
            }
            giftCard3 = Object.assign(Object.assign({}, giftCard3LineItem.lineTotal), { metadata: giftCards[2] });
        }
        let creditCard;
        if (transaction.attributes.protectedData.giftCardTransferAction !==
            GIFT_CARD_TRANSFER_ACTION.FULL_COMMISSION_FULL_PROVIDER_PAYOUT) {
            creditCard = transaction.attributes.payinTotal;
        }
        else {
            //   __
            //  /_ |
            //   | |
            //   | |
            //   | |
            //   |_|
            creditCard = undefined;
        }
        const customerCommission = transaction.attributes.lineItems.find(lineItem => lineItem.code === LINE_ITEM_CUSTOMER_COMMISSION);
        const containsTouristTaxOrCleaningFee = transaction.attributes.lineItems.some(lineItem => TOURIST_TAX_AND_CLEANING_FEE_LINE_ITEMS.includes(lineItem.code));
        if (!customerCommission) {
            throw new Error(`could not find line item ${LINE_ITEM_CUSTOMER_COMMISSION}`);
        }
        let refundType;
        let lastLegalCancellationDate;
        const checkInTime = getListingCheckInTime(listing);
        const arrivalDate = getArrivalDate(booking.attributes.displayStart, checkInTime);
        const daysBuffer = Object.values(CANCELLATION_TYPE).find(cancellationType => cancellationType.key === cancellationPolicy).days;
        if (!txHasBeenAccepted(transaction)) {
            refundType = REFUND_TYPE.CUSTOMER_BEFORE_ACCEPT;
        }
        else if (txIsCancellationPossibleByCancellationPolicy(transaction, cancellationPolicy, isCustomer, booking, checkInTime)) {
            refundType = REFUND_TYPE.CUSTOMER_WITHIN_CANCELLATION_POLICY;
            lastLegalCancellationDate = arrivalDate.subtract(daysBuffer, 'days');
        }
        else if (moment().isBefore(arrivalDate)) {
            if (containsTouristTaxOrCleaningFee) {
                refundType =
                    REFUND_TYPE.CUSTOMER_OUTSIDE_CANCELLATION_POLICY_BEFORE_CHECKIN;
                lastLegalCancellationDate = arrivalDate;
            }
            else {
                refundType =
                    REFUND_TYPE.CUSTOMER_OUTSIDE_CANCELLATION_POLICY_AFTER_CHECKIN;
            }
        }
        else {
            refundType =
                REFUND_TYPE.CUSTOMER_OUTSIDE_CANCELLATION_POLICY_AFTER_CHECKIN;
        }
        if (forcedRefundType) {
            refundType = forcedRefundType;
        }
        const refundCalculator = new RefundCalculator(refundType, payinDate, transaction.attributes.lineItems, creditCard, giftCard1, giftCard2, giftCard3, lastLegalCancellationDate);
        return refundCalculator;
    }
}
export default RefundCalculatorFactory;
