var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import styled from 'styled-components/macro';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import { useLocation, Redirect } from 'react-router-dom';
import { createSlug, parse } from '@nomady/shared/utils/urlHelpers';
import Page from '@nomady/shared/routeConfiguration/Page';
import SplitPicturePageContainer from '../../styled-components/PageContainer/SplitPicturePageContainer';
import config from '../../config';
import Text, { TextVariant } from '../../styled-components/Text/Text';
import Input from '../../styled-components/Input/Input';
import Button, { ButtonColorVariant, ButtonSizeVariant, } from '../../styled-components/Button/Button';
import { isAuthenticatedSelector } from '../../ducks/Auth.duck';
import { NamedRedirect } from '../../components';
import FormErrorMessage from '../../styled-components/FormErrorMessage/FormErrorMessage';
import { ColorVariant } from '../../theme';
import { resetPassword, resetPasswordInProgressSelector, } from './PasswordResetPageSlice';
import { PASSWORD_MAX_LENGTH, PASSWORD_MIN_LENGTH, } from '../../util/validators';
import { retrieveBookingDataAndDates } from '../ListingPage/ListingPageSlice';
const Title = styled(Text) `
  margin-bottom: 20px;
`;
const Message = styled(Text) `
  margin-bottom: 20px;
`;
const Form = styled.form ``;
const FormRow = styled.div `
  margin-bottom: 10px;
`;
const parseUrlParams = (location) => {
    const params = parse(location.search);
    const { t: token, e: email } = params;
    return { token, email };
};
const PasswordResetPage = () => {
    var _a, _b, _c;
    const intl = useIntl();
    const { siteTitle } = config;
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const activateFormerMyCabinAccount = queryParams.get('activateFormerMyCabinAccount') === 'true';
    const schemaTitle = intl.formatMessage({
        id: `PasswordResetPage${activateFormerMyCabinAccount ? 'MCActivation' : ''}.schemaTitle`,
    }, { siteTitle });
    const dispatch = useDispatch();
    const { register, handleSubmit, formState: { errors }, } = useForm();
    const user = useSelector((state) => state.user.currentUser);
    const isAuthenticated = useSelector(isAuthenticatedSelector);
    const passwordResetInProgress = useSelector(resetPasswordInProgressSelector);
    const { passwordResetted, resetPasswordError } = useSelector((state) => state.PasswordResetPage);
    const { listing, bookingEnd, bookingStart } = useSelector((state) => state.ListingPage);
    const from = ((_a = location === null || location === void 0 ? void 0 : location.state) === null || _a === void 0 ? void 0 : _a.from) || null;
    const currentUserLoaded = !!(user === null || user === void 0 ? void 0 : user.id);
    if (isAuthenticated && from) {
        return _jsx(Redirect, { to: from });
    }
    if (isAuthenticated && currentUserLoaded) {
        return _jsx(NamedRedirect, { name: Page.Landing });
    }
    const { token, email } = parseUrlParams(location);
    const paramsValid = !!(token && email);
    const onSuccess = (data) => __awaiter(void 0, void 0, void 0, function* () {
        const trimmedNewPassword = data.newPassword.trim();
        dispatch(retrieveBookingDataAndDates());
        dispatch(resetPassword(email, token, trimmedNewPassword));
    });
    const _d = register('newPassword', {
        required: true,
        minLength: {
            value: PASSWORD_MIN_LENGTH,
            message: 'Input.validationMessage.passwordTooShort',
        },
        maxLength: {
            value: PASSWORD_MAX_LENGTH,
            message: 'Input.validationMessage.passwordTooLong',
        },
    }), { ref: newPasswordRef } = _d, newPassword = __rest(_d, ["ref"]);
    const showForm = !passwordResetted;
    const defaultButton = (_jsx(Button, { label: intl.formatMessage({
            id: 'PasswordResetPage.successPrimaryButtonLabel',
        }), colorVariant: ButtonColorVariant.Dark, sizeVariant: ButtonSizeVariant.Small, targetRoute: Page.Login, inProgress: passwordResetInProgress, searchParams: { activateFormerMyCabinAccount } }));
    const targetRouteParams = {
        id: ((_b = listing === null || listing === void 0 ? void 0 : listing.id) === null || _b === void 0 ? void 0 : _b.uuid) || '',
        slug: createSlug(((_c = listing === null || listing === void 0 ? void 0 : listing.attributes) === null || _c === void 0 ? void 0 : _c.title) || ''),
    };
    const continueBookingButton = (_jsx(Button, { label: intl.formatMessage({
            id: 'PasswordResetPage.continueBooking',
        }), colorVariant: ButtonColorVariant.Dark, sizeVariant: ButtonSizeVariant.Small, targetRoute: Page.Listing, targetRouteParams: targetRouteParams, inProgress: passwordResetInProgress }));
    const verificationWhileBooking = listing && bookingStart && bookingEnd;
    return (_jsxs(SplitPicturePageContainer, Object.assign({ schemaTitle: schemaTitle, noIndex: true }, { children: [showForm && (_jsxs(Form, Object.assign({ onSubmit: handleSubmit(onSuccess) }, { children: [_jsx(Title, Object.assign({ variant: TextVariant.H20, as: "h2" }, { children: intl.formatMessage({
                            id: `PasswordResetPage${activateFormerMyCabinAccount ? 'MCActivation' : ''}.title`,
                        }) })), _jsx(Message, Object.assign({ variant: TextVariant.H14, as: "p", colorVariant: ColorVariant.Cave }, { children: intl.formatMessage({
                            id: `PasswordResetPage${activateFormerMyCabinAccount ? 'MCActivation' : ''}.message`,
                        }) })), _jsx(FormRow, { children: _jsx(Input, Object.assign({ label: intl.formatMessage({
                                id: `PasswordResetPage${activateFormerMyCabinAccount ? 'MCActivation' : ''}.passwordLabel`,
                            }), id: "newPassword", type: "password", autoComplete: "new-password" }, newPassword, { inputRef: newPasswordRef, error: errors === null || errors === void 0 ? void 0 : errors.newPassword })) }), _jsx(Button, { label: intl.formatMessage({
                            id: `PasswordResetPage${activateFormerMyCabinAccount ? 'MCActivation' : ''}.primaryButtonLabel`,
                        }), colorVariant: ButtonColorVariant.Dark, sizeVariant: ButtonSizeVariant.Small, type: "submit", inProgress: passwordResetInProgress }), paramsValid && !!resetPasswordError && (_jsx(FormErrorMessage, { id: `PasswordResetPage${activateFormerMyCabinAccount ? 'MCActivation' : ''}.resetFailed` })), !paramsValid && (_jsx(FormErrorMessage, { id: 'PasswordResetPage.invalidUrlParams' }))] }))), !showForm && (_jsxs(_Fragment, { children: [_jsx(Title, Object.assign({ variant: TextVariant.H3, as: "h2" }, { children: intl.formatMessage({
                            id: `PasswordResetPage${activateFormerMyCabinAccount ? 'MCActivation' : ''}.successTitle`,
                        }) })), _jsx(Message, Object.assign({ variant: TextVariant.H14, as: "p", colorVariant: ColorVariant.Cave }, { children: intl.formatMessage({
                            id: `PasswordResetPage${activateFormerMyCabinAccount ? 'MCActivation' : ''}.successMessage`,
                        }) })), verificationWhileBooking ? continueBookingButton : defaultButton] }))] })));
};
export default PasswordResetPage;
