import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { availabilityExceptionsSelector, selectedListingUUIDSelector, listingsSelector, } from '../CalendarSlice';
import { findExceptionForSlot, getMaxSeatsOfListing } from '../utils';
import { ALL_LISTINGS_KEY, DIFFERENT_AVAILABLE_SEATS_WITHIN_SLOT_RANGE, } from '../constants';
export const useAvailabilitiesOverSlotRange = (slots) => {
    const selectedListingUUID = useSelector(selectedListingUUIDSelector);
    const allAvailabilityExceptions = useSelector(availabilityExceptionsSelector);
    const listings = useSelector(listingsSelector);
    const selectedListing = selectedListingUUID !== ALL_LISTINGS_KEY && listings
        ? listings.find(listing => listing.id.uuid === selectedListingUUID.uuid)
        : undefined;
    const [availabilitiesOverSlotRange, setAvailabilitiesOverSlotRange] = useState();
    useEffect(() => {
        if (!slots ||
            !allAvailabilityExceptions ||
            selectedListingUUID === ALL_LISTINGS_KEY ||
            !selectedListing) {
            setAvailabilitiesOverSlotRange(undefined);
            return;
        }
        const maxSeats = getMaxSeatsOfListing(selectedListing);
        const exceptionsForSelectedListing = allAvailabilityExceptions[selectedListingUUID.uuid] || [];
        const exceptionDataForAllSlots = slots.map(slot => {
            const exception = findExceptionForSlot(slot, exceptionsForSelectedListing);
            const availableSeats = exception ? exception.attributes.seats : maxSeats;
            const exceptionId = exception ? exception.id : undefined;
            return { availableSeats, exceptionId };
        });
        const availableSeatsForAllSlotsArray = exceptionDataForAllSlots.map(exceptionData => exceptionData.availableSeats);
        const availableSeatsForSlots = availableSeatsForAllSlotsArray.some(value => value !== availableSeatsForAllSlotsArray[0])
            ? DIFFERENT_AVAILABLE_SEATS_WITHIN_SLOT_RANGE
            : availableSeatsForAllSlotsArray[0];
        const exceptionIdsForAllSlots = exceptionDataForAllSlots
            .map(exceptionData => exceptionData.exceptionId)
            .filter(exceptionId => exceptionId !== undefined);
        setAvailabilitiesOverSlotRange({
            slots: availableSeatsForSlots,
            exceptionIds: exceptionIdsForAllSlots,
        });
    }, [slots, selectedListingUUID, allAvailabilityExceptions, selectedListing]);
    return availabilitiesOverSlotRange;
};
