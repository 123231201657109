import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import Text, { TextVariant } from '../../../styled-components/Text/Text';
import { ColorVariant } from '../../../theme';
import styled from 'styled-components/macro';
import { useIntl } from 'react-intl';
import { transactionStatusToTranslationKey } from '../../../styled-components/TransactionTag/TransactionTag';
const Wrapper = styled.div `
  padding: 0 8px;
  display: flex;

  &:hover span {
    color: ${props => props.theme.color.pearl};
  }
`;
export const CalendarComponentEvent = ({ event: { title, state }, }) => {
    const intl = useIntl();
    return (_jsx(Wrapper, { children: _jsx(Text, Object.assign({ variant: TextVariant.L4SB, colorVariant: ColorVariant.Cave, truncateWithEllipsis: true }, { children: intl.formatMessage({ id: 'BookingCalendar.eventTitle' }, {
                state: intl.formatMessage({
                    id: transactionStatusToTranslationKey[state],
                }),
                title,
            }) })) }));
};
