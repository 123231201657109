import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components/macro';
import Text, { TextVariant } from '../Text/Text';
import { DESKTOP_TEMPLATE_COLUMNS } from './BookingCard';
const Wrapper = styled.div `
  display: none;

  @media ${props => props.theme.device.desktop} {
    display: grid;
    width: 100%;
    grid-template-columns: ${DESKTOP_TEMPLATE_COLUMNS};
    grid-template-rows: auto;

    margin-bottom: 12px;
  }
`;
const Status = styled(Text) `
  grid-column-start: 1;
  grid-column-end: 2;
`;
const GuestName = styled(Text) `
  grid-column-start: 3;
  grid-column-end: 6;
`;
const ListingName = styled(Text) `
  grid-column-start: 6;
  grid-column-end: 7;
`;
const PayoutTotal = styled(Text) `
  grid-column-start: 7;
  grid-column-end: 8;
`;
const ArrivalDate = styled(Text) `
  grid-column-start: 8;
  grid-column-end: 9;
`;
const DepartureDate = styled(Text) `
  grid-column-start: 9;
  grid-column-end: 10;
`;
const BookingCardTableHead = () => {
    const intl = useIntl();
    return (_jsxs(Wrapper, { children: [_jsx(Status, Object.assign({ variant: TextVariant.B60 }, { children: intl.formatMessage({ id: 'BookingCardTableHead.status' }) })), _jsx(GuestName, Object.assign({ variant: TextVariant.B60 }, { children: intl.formatMessage({ id: 'BookingCardTableHead.guestName' }) })), _jsx(ListingName, Object.assign({ variant: TextVariant.B60 }, { children: intl.formatMessage({ id: 'BookingCardTableHead.listingName' }) })), _jsx(PayoutTotal, Object.assign({ variant: TextVariant.B60 }, { children: intl.formatMessage({ id: 'BookingCardTableHead.payoutTotal' }) })), _jsx(ArrivalDate, Object.assign({ variant: TextVariant.B60 }, { children: intl.formatMessage({ id: 'BookingCardTableHead.arrivalDate' }) })), _jsx(DepartureDate, Object.assign({ variant: TextVariant.B60 }, { children: intl.formatMessage({ id: 'BookingCardTableHead.departureDate' }) }))] }));
};
export default BookingCardTableHead;
