var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components/macro';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import config from '../../config';
import Button, { ButtonColorVariant, ButtonSizeVariant, } from '../../styled-components/Button/Button';
import Text, { TextVariant } from '../../styled-components/Text/Text';
import InputTextArea from '../../styled-components/InputTextArea/InputTextArea';
import CheckboxInput from '../../styled-components/CheckboxInput/CheckboxInput';
import { CampCategory, FirewoodPayment, PowerSupplyPayment, } from '@nomady/shared/types';
import { FAQ_ITEM_MAX_LENGTH, HIGHLIGHT_ITEM_MAX_LENGTH, STORE_PRODUCT_ITEM_MAX_LENGTH, getListingFacilitiesDefaultValues, getListingFacilitiesSchema, } from '@nomady/shared/schemas/listing/facilities';
import FormErrorMessage from '../../styled-components/FormErrorMessage/FormErrorMessage';
import InputMultiSelectSlim from '../../styled-components/InputMultiSelectSlim/InputMultiSelectSlim';
import { FormSection } from '../EditListingDescriptionForm/EditListingDescriptionForm';
import InputPrice from '../../styled-components/InputPrice/InputPrice';
import InputList from '../../styled-components/InputList/InputList';
import InputRadioSelect from '../../styled-components/InputRadioSelect/InputRadioSelect';
const Form = styled.form ``;
const FormRow = styled.div `
  margin-bottom: 10px;
`;
const EditListingFacilitiesForm = ({ updateListingInProgress, updateListingError, onSubmit, onFormDirty, listing, submitButtonLabel, }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t;
    const intl = useIntl();
    const [defaultValuesLoaded, setDefaultValuesLoaded] = useState(false);
    const listingLoaded = !!(listing === null || listing === void 0 ? void 0 : listing.id);
    const defaultValues = getListingFacilitiesDefaultValues(listing === null || listing === void 0 ? void 0 : listing.attributes);
    const { register, handleSubmit, control, reset, trigger, watch, getValues, formState: { errors, isDirty, isSubmitSuccessful }, } = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: zodResolver(getListingFacilitiesSchema(intl)),
    });
    useEffect(() => {
        reset(defaultValues);
        setDefaultValuesLoaded(true);
    }, [listingLoaded]);
    const validateOnRender = () => {
        defaultValuesLoaded && trigger();
    };
    useEffect(validateOnRender, [trigger, defaultValuesLoaded]);
    useEffect(() => onFormDirty(isDirty), [isDirty, onFormDirty]);
    useEffect(() => {
        if (isSubmitSuccessful) {
            const values = getValues();
            reset(values);
        }
    }, [isSubmitSuccessful, getValues, reset]);
    const onSuccess = (data) => __awaiter(void 0, void 0, void 0, function* () {
        var _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8;
        const storeAvailable = 0 < (((_u = data.storeProducts) === null || _u === void 0 ? void 0 : _u.length) || 0) || undefined;
        const params = {
            publicData: {
                localizedPublicData: {
                    seasons: {
                        summer: {
                            activities: data.summerActivities,
                            activitiesDescription: data.summerActivitiesDescription,
                        },
                    },
                    amenitiesDescription: data.amenitiesDescription,
                    environmentDescription: data.environmentDescription,
                    cateringDescription: data.cateringDescription,
                    faq: data.faq,
                    storeProducts: data.storeProducts,
                    highlights: data.highlights,
                },
                amenities: data.amenities,
                environment: data.environment,
                toiletAvailable: data.toilet.available,
                toiletType: data.toilet.feature,
                openFireAllowed: data.fireplace.available,
                fireplaceType: [
                    ...((_w = (_v = data.fireplace) === null || _v === void 0 ? void 0 : _v.feature) !== null && _w !== void 0 ? _w : []),
                    (_y = (_x = data.fireplace) === null || _x === void 0 ? void 0 : _x.pricing) === null || _y === void 0 ? void 0 : _y.payment,
                ].filter(element => !!element),
                showerAvailable: data.shower.available,
                showerType: data.shower.feature,
                waterAvailable: data.waterAvailable,
                powerSupplyAvailable: (_z = data.powerSupply) === null || _z === void 0 ? void 0 : _z.available,
                powerSupplyType: [
                    ...((_1 = (_0 = data.powerSupply) === null || _0 === void 0 ? void 0 : _0.feature) !== null && _1 !== void 0 ? _1 : []),
                    (_3 = (_2 = data.powerSupply) === null || _2 === void 0 ? void 0 : _2.pricing) === null || _3 === void 0 ? void 0 : _3.payment,
                ].filter(element => !!element),
                storeAvailable,
                cateringType: data.cateringType,
                familyFriendly: data.familyFriendly,
                pricing: {
                    firewood: (_6 = (_5 = (_4 = data.fireplace) === null || _4 === void 0 ? void 0 : _4.pricing) === null || _5 === void 0 ? void 0 : _5.price) === null || _6 === void 0 ? void 0 : _6.amount,
                    powerSupply: (_8 = (_7 = data.powerSupply.pricing) === null || _7 === void 0 ? void 0 : _7.price) === null || _8 === void 0 ? void 0 : _8.amount,
                },
            },
        };
        onSubmit(params);
    });
    const showerAvailable = register('shower.available');
    const showShowerDetails = !!watch('shower.available');
    const showerFeature = register('shower.feature');
    const toiletAvailable = register('toilet.available');
    const showToiletDetails = !!watch('toilet.available');
    const toiletFeature = register('toilet.feature');
    const waterAvailable = register('waterAvailable');
    const powerSupplyAvailable = register('powerSupply.available');
    const showPowerSupplyDetails = !!watch('powerSupply.available');
    const powerSupplyFeature = register('powerSupply.feature');
    const powerSupplyPayment = register('powerSupply.pricing.payment');
    const showPowerSupplyPricing = (_a = watch('powerSupply.pricing.payment')) === null || _a === void 0 ? void 0 : _a.includes(PowerSupplyPayment.soldSeparately);
    const powerSupplyPricingPrice = register('powerSupply.pricing.price');
    const fireplaceAvailable = register('fireplace.available');
    const showFireplaceDetails = !!watch('fireplace.available');
    const fireplaceFeature = register('fireplace.feature');
    const firewoodPayment = register('fireplace.pricing.payment');
    const showFirewoodPricing = (_b = watch('fireplace.pricing.payment')) === null || _b === void 0 ? void 0 : _b.includes(FirewoodPayment.firewoodPurchasable);
    const pricingFirewood = register('fireplace.pricing.price');
    const amenities = register('amenities');
    const amenitiesDescription = register('amenitiesDescription');
    const environment = register('environment');
    const environmentDescription = register('environmentDescription');
    const summerActivities = register('summerActivities');
    const summerActivitiesDescription = register('summerActivitiesDescription');
    const cateringType = register('cateringType');
    const cateringDescription = register('cateringDescription');
    const familyFriendly = register('familyFriendly');
    const amenityValues = (listing === null || listing === void 0 ? void 0 : listing.attributes.publicData.category) === CampCategory.accommodation
        ? config.custom.accommodationAmenities
        : config.custom.amenities;
    return (_jsxs(Form, Object.assign({ onSubmit: handleSubmit(onSuccess) }, { children: [_jsxs(FormSection, { children: [_jsx(Text, Object.assign({ variant: TextVariant.H4, as: "h3" }, { children: intl.formatMessage({ id: 'EditListingFacilitiesForm.basics' }) })), _jsx(FormRow, { children: _jsx(CheckboxInput, Object.assign({ id: "shower.available" }, showerAvailable, { inputRef: showerAvailable.ref, error: (_c = errors === null || errors === void 0 ? void 0 : errors.shower) === null || _c === void 0 ? void 0 : _c.available, fieldLabel: intl.formatMessage({
                                id: `EditListingFacilitiesForm.showerAvailable`,
                            }), label: intl.formatMessage({
                                id: 'FieldBoolean.yes',
                            }) })) }), showShowerDetails && (_jsx(FormRow, { children: _jsx(InputMultiSelectSlim, Object.assign({ control: control, id: "shower.feature" }, showerFeature, { label: intl.formatMessage({
                                id: `EditListingFacilitiesForm.showerType`,
                            }), options: config.custom.showerTypes.map(({ key, label }) => ({
                                value: key,
                                label: intl.formatMessage({
                                    id: label,
                                }),
                            })), error: (_d = errors === null || errors === void 0 ? void 0 : errors.shower) === null || _d === void 0 ? void 0 : _d.feature })) })), _jsx(FormRow, { children: _jsx(CheckboxInput, Object.assign({ id: "toilet.available" }, toiletAvailable, { inputRef: toiletAvailable.ref, error: (_e = errors === null || errors === void 0 ? void 0 : errors.toilet) === null || _e === void 0 ? void 0 : _e.available, fieldLabel: intl.formatMessage({
                                id: `EditListingFacilitiesForm.toiletAvailable`,
                            }), label: intl.formatMessage({
                                id: 'FieldBoolean.yes',
                            }) })) }), showToiletDetails && (_jsx(FormRow, { children: _jsx(InputMultiSelectSlim, Object.assign({ control: control, id: "toilet.feature" }, toiletFeature, { label: intl.formatMessage({
                                id: `EditListingFacilitiesForm.toiletFeature`,
                            }), options: config.custom.toiletTypes.map(({ key, label }) => ({
                                value: key,
                                label: intl.formatMessage({
                                    id: label,
                                }),
                            })), error: (_f = errors === null || errors === void 0 ? void 0 : errors.toilet) === null || _f === void 0 ? void 0 : _f.feature })) })), _jsx(FormRow, { children: _jsx(CheckboxInput, Object.assign({ id: "waterAvailable" }, waterAvailable, { inputRef: waterAvailable.ref, error: errors === null || errors === void 0 ? void 0 : errors.waterAvailable, fieldLabel: intl.formatMessage({
                                id: `EditListingFacilitiesForm.waterAvailable`,
                            }), label: intl.formatMessage({
                                id: 'FieldBoolean.yes',
                            }) })) })] }), _jsxs(FormSection, { children: [_jsx(Text, Object.assign({ variant: TextVariant.H4, as: "h3" }, { children: intl.formatMessage({ id: 'EditListingFacilitiesForm.powerSupply' }) })), _jsx(CheckboxInput, Object.assign({ id: "powerSupply.available" }, powerSupplyAvailable, { inputRef: powerSupplyAvailable.ref, error: (_g = errors === null || errors === void 0 ? void 0 : errors.powerSupply) === null || _g === void 0 ? void 0 : _g.available, fieldLabel: intl.formatMessage({
                            id: `EditListingFacilitiesForm.powerSupplyAvailable`,
                        }), label: intl.formatMessage({
                            id: 'FieldBoolean.yes',
                        }) })), showPowerSupplyDetails && (_jsxs(_Fragment, { children: [_jsx(FormRow, { children: _jsx(InputMultiSelectSlim, Object.assign({ control: control, id: "powerSupply.feature" }, powerSupplyFeature, { label: intl.formatMessage({
                                        id: `EditListingFacilitiesForm.powerSupplyFeature`,
                                    }), options: config.custom.powerSupplyFeatures.map(({ key, label }) => ({
                                        value: key,
                                        label: intl.formatMessage({
                                            id: label,
                                        }),
                                    })), error: (_h = errors === null || errors === void 0 ? void 0 : errors.powerSupply) === null || _h === void 0 ? void 0 : _h.feature })) }), _jsx(FormRow, { children: _jsx(InputRadioSelect, Object.assign({ control: control, id: "powerSupply.pricing.payment" }, powerSupplyPayment, { label: intl.formatMessage({
                                        id: `EditListingFacilitiesForm.powerSupplyPayment`,
                                    }), options: config.custom.powerSupplyPayment.map(({ key, label }) => ({
                                        value: key,
                                        label: intl.formatMessage({
                                            id: label,
                                        }),
                                    })), error: (_k = (_j = errors === null || errors === void 0 ? void 0 : errors.powerSupply) === null || _j === void 0 ? void 0 : _j.pricing) === null || _k === void 0 ? void 0 : _k.payment })) }), showPowerSupplyPricing && (_jsx(FormRow, { children: _jsx(InputPrice, Object.assign({ control: control, label: intl.formatMessage({
                                        id: 'EditListingFacilitiesForm.powerSupplyPricingPrice',
                                    }), id: "powerSupplyPricingPrice", type: "text" }, powerSupplyPricingPrice, { error: (_m = (_l = errors === null || errors === void 0 ? void 0 : errors.powerSupply) === null || _l === void 0 ? void 0 : _l.pricing) === null || _m === void 0 ? void 0 : _m.price })) }))] }))] }), _jsxs(FormSection, { children: [_jsx(Text, Object.assign({ variant: TextVariant.H4, as: "h3" }, { children: intl.formatMessage({ id: 'EditListingFacilitiesForm.firewood' }) })), _jsx(CheckboxInput, Object.assign({ id: "fireplace.available" }, fireplaceAvailable, { inputRef: fireplaceAvailable.ref, error: (_o = errors === null || errors === void 0 ? void 0 : errors.fireplace) === null || _o === void 0 ? void 0 : _o.available, fieldLabel: intl.formatMessage({
                            id: `EditListingFacilitiesForm.fireplaceAvailable`,
                        }), label: intl.formatMessage({
                            id: 'FieldBoolean.yes',
                        }) })), showFireplaceDetails && (_jsxs(_Fragment, { children: [_jsx(FormRow, { children: _jsx(InputMultiSelectSlim, Object.assign({ control: control, id: "fireplace.feature" }, fireplaceFeature, { label: intl.formatMessage({
                                        id: 'EditListingFacilitiesForm.openFireAllowed',
                                    }), options: config.custom.fireplaceTypes.map(({ key, label }) => ({
                                        value: key,
                                        label: intl.formatMessage({
                                            id: label,
                                        }),
                                    })), error: (_p = errors === null || errors === void 0 ? void 0 : errors.fireplace) === null || _p === void 0 ? void 0 : _p.feature })) }), _jsx(FormRow, { children: _jsx(InputRadioSelect, Object.assign({ control: control, id: "fireplace.pricing.payment" }, firewoodPayment, { label: intl.formatMessage({
                                        id: `EditListingFacilitiesForm.firewoodPayment`,
                                    }), options: config.custom.firewoodPaymentTypes.map(({ key, label }) => ({
                                        value: key,
                                        label: intl.formatMessage({
                                            id: label,
                                        }),
                                    })), error: (_r = (_q = errors === null || errors === void 0 ? void 0 : errors.fireplace) === null || _q === void 0 ? void 0 : _q.pricing) === null || _r === void 0 ? void 0 : _r.payment })) }), showFirewoodPricing && (_jsx(FormRow, { children: _jsx(InputPrice, Object.assign({ control: control, label: intl.formatMessage({
                                        id: 'EditListingFacilitiesForm.pricingFirewood',
                                    }), id: "pricingFirewood", type: "text" }, pricingFirewood, { error: (_t = (_s = errors === null || errors === void 0 ? void 0 : errors.fireplace) === null || _s === void 0 ? void 0 : _s.pricing) === null || _t === void 0 ? void 0 : _t.price })) }))] }))] }), _jsxs(FormSection, { children: [_jsx(Text, Object.assign({ variant: TextVariant.H4, as: "h3" }, { children: intl.formatMessage({
                            id: 'EditListingFacilitiesForm.aroundYourCamp',
                        }) })), _jsxs(FormRow, { children: [_jsx(InputMultiSelectSlim, Object.assign({ control: control, id: "amenities" }, amenities, { label: intl.formatMessage({
                                    id: `EditListingFacilitiesForm.amenities`,
                                }), options: amenityValues.map(({ key, label }) => ({
                                    value: key,
                                    label: intl.formatMessage({
                                        id: label,
                                    }),
                                })), error: errors === null || errors === void 0 ? void 0 : errors.amenities })), _jsx(InputTextArea, Object.assign({ label: intl.formatMessage({
                                    id: 'EditListingFacilitiesForm.amenitiesDescription',
                                }), placeholder: intl.formatMessage({
                                    id: 'EditListingFacilitiesForm.amenitiesDescriptionPlaceholder',
                                }), id: "amenitiesDescription", control: control }, amenitiesDescription, { error: errors === null || errors === void 0 ? void 0 : errors.amenitiesDescription }))] }), _jsxs(FormRow, { children: [_jsx(InputMultiSelectSlim, Object.assign({ control: control, id: "environment" }, environment, { label: intl.formatMessage({
                                    id: `EditListingFacilitiesForm.environment`,
                                }), options: config.custom.environment.map(({ key, label }) => ({
                                    value: key,
                                    label: intl.formatMessage({
                                        id: label,
                                    }),
                                })), error: errors === null || errors === void 0 ? void 0 : errors.environment })), _jsx(InputTextArea, Object.assign({ label: intl.formatMessage({
                                    id: 'EditListingFacilitiesForm.environmentDescription',
                                }), placeholder: intl.formatMessage({
                                    id: 'EditListingFacilitiesForm.environmentDescriptionPlaceholder',
                                }), id: "environmentDescription", control: control }, environmentDescription, { error: errors === null || errors === void 0 ? void 0 : errors.environmentDescription }))] }), _jsxs(FormRow, { children: [_jsx(InputMultiSelectSlim, Object.assign({ control: control, id: "summerActivities" }, summerActivities, { label: intl.formatMessage({
                                    id: `EditListingFacilitiesForm.summerActivities`,
                                }), options: config.custom.activities.map(({ key, label }) => ({
                                    value: key,
                                    label: intl.formatMessage({
                                        id: label,
                                    }),
                                })), error: errors === null || errors === void 0 ? void 0 : errors.summerActivities })), _jsx(InputTextArea, Object.assign({ label: intl.formatMessage({
                                    id: 'EditListingFacilitiesForm.summerActivitiesDescription',
                                }), placeholder: intl.formatMessage({
                                    id: 'EditListingFacilitiesForm.summerActivitiesDescriptionPlaceholder',
                                }), id: "summerActivitiesDescription", control: control }, summerActivitiesDescription, { error: errors === null || errors === void 0 ? void 0 : errors.summerActivitiesDescription }))] }), _jsx(FormRow, { children: _jsx(InputList, { control: control, id: "faq", name: "faq", error: errors === null || errors === void 0 ? void 0 : errors.faq, maxItemLength: FAQ_ITEM_MAX_LENGTH, description: intl.formatMessage({
                                id: 'EditListingDescriptionForm.miscellaneousTooltipTop',
                            }), label: intl.formatMessage({
                                id: 'EditListingDescriptionForm.miscellaneous',
                            }), inputPlaceholder: intl.formatMessage({
                                id: 'EditListingDescriptionForm.miscellaneousPlaceholder',
                            }) }) }), _jsx(FormRow, { children: _jsx(InputList, { control: control, id: "highlights", name: "highlights", error: errors === null || errors === void 0 ? void 0 : errors.highlights, maxItemLength: HIGHLIGHT_ITEM_MAX_LENGTH, description: intl.formatMessage({
                                id: 'EditListingDescriptionForm.highlightsTooltipTop',
                            }), label: intl.formatMessage({
                                id: 'EditListingDescriptionForm.highlight',
                            }), inputPlaceholder: intl.formatMessage({
                                id: 'EditListingDescriptionForm.highlightsPlaceholder',
                            }) }) })] }), _jsxs(FormSection, { children: [_jsx(Text, Object.assign({ variant: TextVariant.H4, as: "h3" }, { children: intl.formatMessage({ id: 'EditListingFacilitiesForm.catering' }) })), _jsxs(FormRow, { children: [_jsx(InputMultiSelectSlim, Object.assign({ control: control, id: "cateringType" }, cateringType, { label: intl.formatMessage({
                                    id: `EditListingFacilitiesForm.cateringType`,
                                }), options: config.custom.cateringTypes.map(({ key, label }) => ({
                                    value: key,
                                    label: intl.formatMessage({
                                        id: label,
                                    }),
                                })), error: errors === null || errors === void 0 ? void 0 : errors.cateringType })), _jsx(InputTextArea, Object.assign({ label: intl.formatMessage({
                                    id: 'EditListingFacilitiesForm.cateringDescription',
                                }), placeholder: intl.formatMessage({
                                    id: 'EditListingFacilitiesForm.cateringDescriptionPlaceholder',
                                }), id: "cateringDescription", control: control }, cateringDescription, { error: errors === null || errors === void 0 ? void 0 : errors.cateringDescription }))] }), _jsx(FormRow, { children: _jsx(InputList, { control: control, id: "storeProducts", name: "storeProducts", maxItemLength: STORE_PRODUCT_ITEM_MAX_LENGTH, description: intl.formatMessage({
                                id: 'EditListingFacilitiesForm.storeProductsTooltipTop',
                            }), label: intl.formatMessage({
                                id: 'EditListingFacilitiesForm.storeProducts',
                            }), inputPlaceholder: intl.formatMessage({
                                id: 'EditListingFacilitiesForm.storeProductPlaceholder',
                            }) }) })] }), _jsxs(FormSection, { children: [_jsx(Text, Object.assign({ variant: TextVariant.H4, as: "h3" }, { children: intl.formatMessage({ id: 'EditListingFacilitiesForm.families' }) })), _jsx(FormRow, { children: _jsx(CheckboxInput, Object.assign({ id: "familyFriendly" }, familyFriendly, { inputRef: familyFriendly.ref, error: errors === null || errors === void 0 ? void 0 : errors.familyFriendly, fieldLabel: intl.formatMessage({
                                id: `EditListingFacilitiesForm.familyFriendly`,
                            }), label: intl.formatMessage({
                                id: 'FieldBoolean.yes',
                            }) })) })] }), _jsx(Button, { label: submitButtonLabel, colorVariant: ButtonColorVariant.Dark, sizeVariant: ButtonSizeVariant.Small, type: "submit", inProgress: updateListingInProgress }), updateListingError && (_jsx(FormErrorMessage, { id: "EditListingDescriptionForm.updateFailed" }))] })));
};
export default EditListingFacilitiesForm;
