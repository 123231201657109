import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styled, { keyframes } from 'styled-components/macro';
function blinkingEffect() {
    return keyframes `
    50% {
      fill: transparent;
    }
  `;
}
const SvgWrap = styled.svg `
  height: ${props => props.height}px;
  width: 40px;
`;
const DotCircle = styled.circle `
  animation: ${blinkingEffect} 1s linear infinite;
  fill: ${props => props.theme.color.stone};
  :nth-child(2) {
    animation-delay: 250ms;
  }

  :nth-child(3) {
    animation-delay: 500ms;
  }
`;
const AnimatedDots = ({ height = 40 }) => {
    const dotHeight = height / 2;
    return (_jsxs(SvgWrap, Object.assign({ height: height }, { children: [_jsx(DotCircle, { cx: "10", cy: dotHeight, r: "3" }), _jsx(DotCircle, { cx: "20", cy: dotHeight, r: "3" }), _jsx(DotCircle, { cx: "30", cy: dotHeight, r: "3" })] })));
};
export default AnimatedDots;
