import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { useIntl } from 'react-intl';
import { ColorVariant } from '../../theme';
import Text, { TextVariant } from '../Text/Text';
const getErrorMessage = (error) => {
    const hasCustomMessage = error.message;
    if (hasCustomMessage) {
        return error.message;
    }
    const isRequired = error.type === 'required';
    if (isRequired) {
        return 'Input.validationMessage.required';
    }
    return 'Input.validationMessage.unknown';
};
const InputErrorMessage = ({ id, error, }) => {
    const intl = useIntl();
    return (_jsx(Text, Object.assign({ variant: TextVariant.H14, as: "p", colorVariant: ColorVariant.Rose, id: id }, { children: intl.formatMessage({ id: getErrorMessage(error) }) })));
};
export default InputErrorMessage;
