var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { denormalisedResponseEntities } from '@nomady/shared/utils/data';
import { createSlice } from '@reduxjs/toolkit';
import { getDeeplLocale } from '@nomady/shared/marketplace-custom-config';
import { currentUserShowSuccess } from '../../ducks/user.duck';
import { updateProfileCurrentUser } from '../../util/api';
import { storableError } from '../../util/errors';
import { localeSelector } from '../../slices/UISlice';
const initialState = {
    uploadInProgress: false,
    uploadImageError: null,
    updateInProgress: false,
    updateProfileError: null,
    image: null,
};
export const ProfileSettingsPageSlice = createSlice({
    name: 'app/ProfileSettingsPage',
    initialState,
    reducers: {
        uploadImageRequest: (state, action) => {
            state.uploadInProgress = true;
            state.uploadImageError = null;
            state.image = action.payload;
        },
        uploadImageSuccess: (state, action) => {
            var _a;
            const { id, uploadedImage } = action.payload;
            const { file } = (_a = state.image) !== null && _a !== void 0 ? _a : {};
            const image = { id, imageId: uploadedImage.id, file, uploadedImage };
            state.image = image;
            state.uploadInProgress = false;
        },
        uploadImageError: (state, action) => {
            state.image = null;
            state.uploadInProgress = false;
            state.uploadImageError = action.payload;
        },
        updateProfileRequest: state => {
            state.updateInProgress = true;
            state.updateProfileError = null;
        },
        updateProfileSuccess: state => {
            state.updateInProgress = false;
            state.image = null;
        },
        updateProfileError: (state, action) => {
            state.image = null;
            state.uploadInProgress = false;
            state.updateProfileError = action.payload;
        },
        clearUpdateForm: state => {
            state.updateProfileError = null;
            state.uploadImageError = null;
        },
    },
});
export const { uploadImageRequest, uploadImageSuccess, uploadImageError, updateProfileRequest, updateProfileSuccess, updateProfileError, clearUpdateForm, } = ProfileSettingsPageSlice.actions;
export const uploadImage = (actionPayload) => (dispatch, _, sdk) => __awaiter(void 0, void 0, void 0, function* () {
    const { id } = actionPayload;
    dispatch(uploadImageRequest(actionPayload));
    if (!actionPayload.file) {
        dispatch(uploadImageError(storableError({ error: 'no file supplied' })));
        return;
    }
    const bodyParams = {
        image: actionPayload.file,
    };
    const queryParams = {
        expand: true,
        'fields.image': ['variants.square-small', 'variants.square-small2x'],
    };
    try {
        const response = yield sdk.images.upload(bodyParams, queryParams);
        const uploadedImage = response.data.data;
        if (!response.data.data.id) {
            throw new Error('upload image failed');
        }
        dispatch(uploadImageSuccess({ id, uploadedImage }));
        dispatch(updateProfile({ profileImageId: response.data.data.id }));
    }
    catch (error) {
        dispatch(uploadImageError(storableError(error)));
    }
});
export const updateProfile = (profile) => (dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
    dispatch(updateProfileRequest());
    const state = getState();
    const locale = getDeeplLocale(localeSelector(state));
    const queryParams = {
        expand: true,
        include: ['profileImage'],
        'fields.image': ['variants.square-small', 'variants.square-small2x'],
        locale,
    };
    try {
        const response = yield updateProfileCurrentUser(profile, queryParams);
        dispatch(updateProfileSuccess());
        const entities = denormalisedResponseEntities(response);
        if (entities.length !== 1) {
            throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
        }
        const currentUser = entities[0];
        // Update current user in state.user.currentUser through user.duck.ts.js
        dispatch(currentUserShowSuccess(currentUser));
    }
    catch (error) {
        dispatch(updateProfileError(storableError(error)));
    }
});
export default ProfileSettingsPageSlice.reducer;
