import { jsx as _jsx } from "react/jsx-runtime";
import React, { useState } from 'react';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import styled, { css } from 'styled-components/macro';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import IconButton, { IconButtonColorVariant, IconButtonRadiusVariant, IconButtonSizeVariant, } from '../IconButton/IconButton';
import { IconVariant } from '../Icon/Icon';
export var ImageCarouselSizeVariant;
(function (ImageCarouselSizeVariant) {
    ImageCarouselSizeVariant["DEFAULT"] = "Default";
    ImageCarouselSizeVariant["SMALL"] = "Small";
})(ImageCarouselSizeVariant || (ImageCarouselSizeVariant = {}));
const Wrapper = styled.div `
  && .carousel-status {
    ${props => props.hasMultipleImages &&
    css `
        width: fit-content;
        height: fit-content;

        ${props.theme.typeset.L4R}

        padding: 0 4px;
        margin: 0;
        top: ${props.hasFacilityIcons ? '126px' : '144px'};
        right: 8px;
        border-radius: 4px;
        background: ${props.theme.color.basicRavenBlack};
        opacity: 0.8;
        color: ${props.theme.color.basicPearlWhiteTypeWhite};

        @media ${props.theme.device.tablet} {
          top: 144px;
        }
      `}
  }

  cursor: pointer;
`;
const CarouselImage = styled.div `
  height: 168px;
  width: 100%;
  background-image: url(${props => props.backgroundUrl});
  background-size: cover;
  background-position: center;
  ${props => props.sizeVariant === ImageCarouselSizeVariant.SMALL &&
    css `
      height: 140px;
    `}
`;
const ArrowPrev = styled.div `
  display: none;
  @media ${props => props.theme.device.tablet} {
    display: flex;
    align-items: center;
    justify-content: start;
    position: absolute;
    inset: 0;
    padding: 8px;
    ${props => props.theme.priority.medium}
    pointer-events: none;
  }
`;
const ArrowNext = styled.div `
  display: none;
  @media ${props => props.theme.device.tablet} {
    display: flex;
    align-items: center;
    justify-content: end;
    position: absolute;
    inset: 0;
    padding: 8px;
    ${props => props.theme.priority.medium}
    pointer-events: none;
  }
`;
const ImageCarousel = ({ images, sizeVariant, listingId, listingTitle, hasFacilityIcons, }) => {
    const [hideControls, setHideControls] = useState(true);
    const [swipedRecently, setSwipedRecently] = useState(false);
    const statusFormatter = (index, total) => {
        if (total === 1)
            return '';
        return `${index.toString()}   |   ${total.toString()}`;
    };
    const renderArrowPrev = (clickHandler, hasPrev) => {
        return (hasPrev && (_jsx(ArrowPrev, { children: _jsx(IconButton, { onClick: e => {
                    e.preventDefault();
                    e.stopPropagation();
                    clickHandler(e);
                }, iconVariant: IconVariant.Arrow_S_left, iconButtonSizeVariant: IconButtonSizeVariant.ExtraSmall, colorVariant: IconButtonColorVariant.Black, radiusVariant: IconButtonRadiusVariant.Small, transparent: true, "aria-label": "Previous image" }) })));
    };
    const renderArrowNext = (clickHandler, hasNext) => {
        return (hasNext && (_jsx(ArrowNext, { children: _jsx(IconButton, { onClick: e => {
                    e.preventDefault();
                    e.stopPropagation();
                    clickHandler(e);
                }, iconVariant: IconVariant.Arrow_S_right, iconButtonSizeVariant: IconButtonSizeVariant.ExtraSmall, colorVariant: IconButtonColorVariant.Black, radiusVariant: IconButtonRadiusVariant.Small, transparent: true, "aria-label": "Next image" }) })));
    };
    const showControlsTemporarly = () => {
        setSwipedRecently(true);
        setTimeout(() => setSwipedRecently(false), 1500);
    };
    return (_jsx(Wrapper, Object.assign({ sizeVariant: sizeVariant, onPointerEnter: () => setTimeout(() => setHideControls(false), 100), onPointerLeave: () => setTimeout(() => setHideControls(true), 100), hasMultipleImages: (images === null || images === void 0 ? void 0 : images.length) > 1, hasFacilityIcons: hasFacilityIcons }, { children: _jsx(Carousel, Object.assign({ showThumbs: false, infiniteLoop: true, autoPlay: false, showIndicators: false, autoFocus: false, interval: 9999999, showArrows: !hideControls, showStatus: !hideControls || swipedRecently, statusFormatter: statusFormatter, renderArrowPrev: renderArrowPrev, renderArrowNext: renderArrowNext, onSwipeStart: () => showControlsTemporarly() }, { children: images.map((image, index) => (_jsx(LazyLoadComponent, { children: _jsx(CarouselImage, { sizeVariant: sizeVariant, backgroundUrl: image }, image) }, index))) })) })));
};
export default ImageCarousel;
