import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styled from 'styled-components/macro';
import { useIntl } from 'react-intl';
import { MAXIMUM_LENGTH_OF_LISTING_FAVORITES_LIST_NAME } from '@nomady/shared/config';
import Input from '../Input/Input';
import Button, { ButtonColorVariant, ButtonSizeVariant, } from '../Button/Button';
import useFavoritesList from './hooks/useFavoritesList';
import Text, { TextVariant } from '../Text/Text';
import FavoritesListCard from './components/FavoritesListCard';
import Modal from '../Modal/Modal';
const ListingFavoritesListCardWrapper = styled.li `
  list-style: none;
  &:last-child {
    margin-bottom: 20px;
  }
`;
const AddNewListSubmitButtonWrapper = styled.div `
  align-self: flex-end;
  margin-top: 20px;
  max-width: 50%;
`;
const Container = styled.div `
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 20px;
  max-width: 1110px;
  margin-left: auto;
  margin-right: auto;
  @media ${props => props.theme.device.tablet} {
    flex-direction: column;
    justify-content: flex-end;
    gap: unset;
    margin-bottom: unset;
  }
`;
const FavoritesListsContainer = styled.div `
  max-width: 100vw;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
const FavoriteLists = styled.ul `
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-height: 25vh;
  overflow-y: scroll;
  list-style: none;
  list-style-type: none;
  padding-bottom: 0;
  margin: 10px 0px 0px !important; // disable default module css for ul
`;
const ScrollGradient = styled.div `
  height: 30px;
  position: absolute;
  bottom: 100%;
  transform: translateY(-65%);
  width: 100%;
  background: linear-gradient(
    rgba(255, 255, 255, 0.001),
    ${props => props.theme.color.cloud}
  );
  @media ${props => props.theme.device.tablet} {
    transform: none;
  }
`;
const FormContainer = styled.div `
  position: relative;
  padding-top: 20px;
`;
const AddNewListForm = styled.div `
  margin-top: 10px;
  position: relative;
  height: fit-content;
  display: flex;
  flex-direction: column;
`;
const AddToFavoritesModal = ({ open, onClose, listingId }) => {
    const { listing, enhancedLists, addOrRemoveListingFromList, createNewList, createListingLoading, newListName, setNewListName, } = useFavoritesList(listingId);
    const intl = useIntl();
    if (!listing) {
        return null;
    }
    return (_jsx(Modal, Object.assign({ isOpen: open, onClose: onClose, title: intl.formatMessage({
            id: 'AddToFavoritesModal.title',
        }, {
            listingName: listing.attributes.title,
        }) }, { children: _jsxs(Container, { children: [enhancedLists.length > 0 && (_jsxs(FavoritesListsContainer, { children: [_jsx(Text, Object.assign({ variant: TextVariant.H51 }, { children: intl.formatMessage({
                                id: 'AddToFavoritesModal.yourFavoritesLists',
                            }) })), _jsx(FavoriteLists, Object.assign({ "data-testid": "FavoritesList_Lists" }, { children: enhancedLists.map(listingFavoriteList => {
                                var _a;
                                return (_jsx(ListingFavoritesListCardWrapper, { children: _jsx(FavoritesListCard, { name: listingFavoriteList.name, onAddOrRemoveListing: () => addOrRemoveListingFromList(listingFavoriteList.id, listing.id.uuid), listings: (_a = listingFavoriteList.listings) === null || _a === void 0 ? void 0 : _a.length, currentListingInList: listingFavoriteList.currentListingInList, addOrRemoveLoading: listingFavoriteList.addOrRemoveListingInProgress }) }, listingFavoriteList.id));
                            }) }))] })), enhancedLists.length <
                    MAXIMUM_LENGTH_OF_LISTING_FAVORITES_LIST_NAME && (_jsxs(FormContainer, { children: [enhancedLists.length > 0 && _jsx(ScrollGradient, {}), _jsx(Text, Object.assign({ variant: TextVariant.H51 }, { children: intl.formatMessage({
                                id: 'AddToFavoritesModal.newListInputLabel',
                            }) })), _jsxs(AddNewListForm, { children: [_jsx(Input, { maxLength: MAXIMUM_LENGTH_OF_LISTING_FAVORITES_LIST_NAME, onChange: event => setNewListName(event.target.value), value: newListName, placeholder: intl.formatMessage({
                                        id: 'AddToFavoritesModal.inputPlaceholder',
                                    }) }), _jsx(AddNewListSubmitButtonWrapper, { children: _jsx(Button, { disabled: !newListName, label: intl.formatMessage({
                                            id: 'AddToFavoritesModal.createNewListButton',
                                        }), colorVariant: ButtonColorVariant.Dark, sizeVariant: ButtonSizeVariant.Small, onClick: () => createNewList(listing.id.uuid), inProgress: createListingLoading }) })] })] }))] }) })));
};
export default AddToFavoritesModal;
