import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useIntl } from 'react-intl';
import { amenities, accommodationAmenities, showerTypes, toiletTypes, } from '@nomady/shared/marketplace-custom-config';
import { CampCategory, ToiletType } from '@nomady/shared/types';
import { useSelector } from 'react-redux';
import { IconVariant } from '../../../styled-components/Icon/Icon';
import ListingChecklist from '../../../styled-components/ListingChecklist';
const AmenitiesContent = () => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    const { listing } = useSelector((state) => state.ListingPage);
    const intl = useIntl();
    const activeAmenitiesTypes = ((_b = (_a = listing === null || listing === void 0 ? void 0 : listing.attributes) === null || _a === void 0 ? void 0 : _a.publicData) === null || _b === void 0 ? void 0 : _b.amenities) || [];
    const { amenitiesDescription } = ((_d = (_c = listing === null || listing === void 0 ? void 0 : listing.attributes) === null || _c === void 0 ? void 0 : _c.publicData) === null || _d === void 0 ? void 0 : _d.localizedPublicData) || {};
    const activeToiletTypes = ((_f = (_e = listing === null || listing === void 0 ? void 0 : listing.attributes) === null || _e === void 0 ? void 0 : _e.publicData) === null || _f === void 0 ? void 0 : _f.toiletType) || [];
    const activeShowerTypes = ((_h = (_g = listing === null || listing === void 0 ? void 0 : listing.attributes) === null || _g === void 0 ? void 0 : _g.publicData) === null || _h === void 0 ? void 0 : _h.showerType) || [];
    const isAccommodation = ((_k = (_j = listing === null || listing === void 0 ? void 0 : listing.attributes) === null || _j === void 0 ? void 0 : _j.publicData) === null || _k === void 0 ? void 0 : _k.category) === CampCategory.accommodation;
    const amenitiesElements = (isAccommodation ? accommodationAmenities : amenities).map(amenity => ({
        isChecked: activeAmenitiesTypes.includes(amenity.key),
        text: intl.formatMessage({ id: amenity.label }),
    }));
    if (amenitiesDescription) {
        amenitiesElements.push({
            isChecked: true,
            text: amenitiesDescription,
        });
    }
    const showerAndToiletElements = [...toiletTypes, ...showerTypes].map(toilet => ({
        isChecked: [...activeToiletTypes, ...activeShowerTypes].includes(toilet.key),
        text: intl.formatMessage({
            id: isAccommodation && toilet.key === ToiletType.atCamp
                ? 'MarketplaceConfig.toiletAtAccommodation'
                : toilet.label,
        }),
    }));
    return (_jsxs(_Fragment, { children: [_jsx(ListingChecklist, { items: showerAndToiletElements, iconVariant: IconVariant.Shower, title: intl.formatMessage({ id: 'ListingPage.showerAndToilet' }) }), _jsx(ListingChecklist, { items: amenitiesElements, iconVariant: IconVariant.Table, title: intl.formatMessage({ id: 'ListingPage.amenities' }) })] }));
};
export default AmenitiesContent;
