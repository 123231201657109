import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { IconVariant } from '../../../styled-components/Icon/Icon';
import ListingChecklist from '../../../styled-components/ListingChecklist';
const ShopContent = () => {
    var _a, _b, _c;
    const { listing } = useSelector((state) => state.ListingPage);
    const intl = useIntl();
    const storeProducts = ((_c = (_b = (_a = listing === null || listing === void 0 ? void 0 : listing.attributes) === null || _a === void 0 ? void 0 : _a.publicData) === null || _b === void 0 ? void 0 : _b.localizedPublicData) === null || _c === void 0 ? void 0 : _c.storeProducts) || [];
    const elements = storeProducts.map((element, index) => ({
        text: element,
        isChecked: true,
        key: `product-${index}`,
    }));
    return (_jsx(ListingChecklist, { items: elements, title: intl.formatMessage({ id: 'ListingPage.shop' }), iconVariant: IconVariant.Farmshop }));
};
export default ShopContent;
