import { CampCategory, } from '@nomady/shared/types';
import { dateFromAPIToLocalNoon, enumerateDaysBetweenDatesNightlyBookings, } from '@nomady/shared/utils/dates';
import { TIME_SLOT_DAY } from '@nomady/shared/utils/types';
import { ensureTimeSlot } from '@nomady/shared/utils/data';
import moment from 'moment';
export const scrollRefIntoView = (ref) => {
    if (ref === null || ref === void 0 ? void 0 : ref.current) {
        ref.current.scrollIntoView({
            block: 'center',
            behavior: 'smooth',
        });
    }
};
export const getSchema = (listing, reviews) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const { attributes } = listing || {};
    const { publicData } = listing.attributes || {};
    const hasWinterCapability = publicData === null || publicData === void 0 ? void 0 : publicData.winterCapability;
    const descriptionWinterEnabled = publicData === null || publicData === void 0 ? void 0 : publicData.descriptionWinterEnabled;
    const showWinter = hasWinterCapability && descriptionWinterEnabled;
    const season = showWinter ? 'winter' : 'summer';
    const description = (_d = (_c = (_b = (_a = publicData === null || publicData === void 0 ? void 0 : publicData.localizedPublicData) === null || _a === void 0 ? void 0 : _a.seasons) === null || _b === void 0 ? void 0 : _b[season]) === null || _c === void 0 ? void 0 : _c.description) === null || _d === void 0 ? void 0 : _d.slice(0, 160);
    const isAccommodation = (publicData === null || publicData === void 0 ? void 0 : publicData.category) === CampCategory.accommodation;
    const schema = Object.assign(Object.assign({ '@context': 'https://schema.org', '@type': 'Offer', additionalType: isAccommodation ? 'LodgingBusiness' : 'Campground', name: listing.attributes.title, image: listing.images[0].attributes.variants['landscape-crop'].url }, (((_e = attributes === null || attributes === void 0 ? void 0 : attributes.metadata) === null || _e === void 0 ? void 0 : _e.rating) &&
        ((_f = reviews === null || reviews === void 0 ? void 0 : reviews[0]) === null || _f === void 0 ? void 0 : _f.length) > 0 && {
        aggregateRating: {
            itemReviewed: {
                '@type': 'Thing',
                name: listing.attributes.title,
            },
            ratingCount: (_g = reviews === null || reviews === void 0 ? void 0 : reviews[0]) === null || _g === void 0 ? void 0 : _g.length,
            ratingValue: ((_h = attributes === null || attributes === void 0 ? void 0 : attributes.metadata) === null || _h === void 0 ? void 0 : _h.rating) / 100,
            bestRating: 5,
            worstRating: 1,
        },
    })), ((attributes === null || attributes === void 0 ? void 0 : attributes.price) && {
        availability: 'https://schema.org/InStock',
        description,
        price: attributes.price.amount / 100,
        priceCurrency: listing.attributes.publicData.currency,
        offeredBy: {
            '@type': 'Person',
            name: listing.author.attributes.profile.displayName,
        },
    }));
    return schema;
};
export const timeSlotEqualsDay = (timeSlot, day) => {
    if (ensureTimeSlot(timeSlot).attributes.type === TIME_SLOT_DAY) {
        const localStartDate = dateFromAPIToLocalNoon(timeSlot.attributes.start);
        return moment(day).isSame(localStartDate, 'day');
    }
    return false;
};
export const getSeatsAvailability = (timeSlots, startDate, endDate) => {
    if (!timeSlots || !startDate || !endDate) {
        return null;
    }
    const daysToCheck = enumerateDaysBetweenDatesNightlyBookings(moment(startDate), moment(endDate));
    const timeSlotsInRange = [];
    daysToCheck.forEach(day => {
        timeSlotsInRange.push(timeSlots === null || timeSlots === void 0 ? void 0 : timeSlots.filter(timeSlot => {
            return timeSlot && timeSlotEqualsDay(timeSlot, day.toDate());
        }));
    });
    let seatsAvailability = 99999;
    if (timeSlotsInRange.length > 0) {
        timeSlotsInRange === null || timeSlotsInRange === void 0 ? void 0 : timeSlotsInRange.forEach((timeSlot) => {
            var _a;
            const firstTimeSlot = timeSlot && (timeSlot === null || timeSlot === void 0 ? void 0 : timeSlot[0]);
            const freeTimeSlotSeats = ((_a = firstTimeSlot === null || firstTimeSlot === void 0 ? void 0 : firstTimeSlot.attributes) === null || _a === void 0 ? void 0 : _a.seats) || 0;
            if (freeTimeSlotSeats < seatsAvailability) {
                seatsAvailability = freeTimeSlotSeats;
            }
        });
        return seatsAvailability;
    }
    return null;
};
