import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components/macro';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { GoogleApiWrapper } from 'google-maps-react';
import Button, { ButtonColorVariant, ButtonSizeVariant, TextAlign, } from '../Button/Button';
import { IconVariant } from '../Icon/Icon';
import { openSearchBarSheet, isGeolocationUsedSelector, } from '../../slices/listingSearchSlice';
import Input from './Input';
import getFeaturesFilterCount from '../../util/search-query/features/getFeaturesFilterCount';
import SearchBarSheetVariant from '../SearchBarSheet/SearchBarSheetVariant';
import config from '../../config';
export var ListingSearchBarColorVariant;
(function (ListingSearchBarColorVariant) {
    ListingSearchBarColorVariant["Default"] = "Default";
    ListingSearchBarColorVariant["Snow"] = "Snow";
})(ListingSearchBarColorVariant || (ListingSearchBarColorVariant = {}));
const Wrapper = styled.div `
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
`;
const SearchWrapper = styled.div `
  justify-content: space-between;
  background-color: ${props => props.colorVariant === ListingSearchBarColorVariant.Default
    ? props.theme.color.pearl
    : props.theme.color.snow};
  border-radius: 10px;
  padding: 3px;
  flex: 1;
`;
const SearchBarSheetButtonWrapper = styled.div `
  margin-left: 7px;
  margin-right: 2px;
`;
const LocationButtonWrap = styled.div `
  width: 100%;
  min-width: 100px;
  flex-shrink: 1;
  text-align: left;

  @media ${props => props.theme.device.desktop} {
    width: 239px;
    flex-shrink: 0;
  }
`;
const RegionalListingSearchBar = ({ colorVariant = ListingSearchBarColorVariant.Default }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const intl = useIntl();
    const dispatch = useDispatch();
    const locationInputRef = useRef(null);
    const onSetActiveSearchBarSheet = (searchBarSheetVariant) => dispatch(openSearchBarSheet(searchBarSheetVariant));
    const { isOpen, activeSearchBarSheet, currentSearchQuery } = useSelector((state) => state.listingSearch);
    const featuresFilterCount = useSelector(getFeaturesFilterCount);
    useEffect(() => {
        if (locationInputRef.current &&
            activeSearchBarSheet === SearchBarSheetVariant.Location &&
            isOpen) {
            locationInputRef.current.focus();
        }
    }, []);
    const geolocationUsed = useSelector(isGeolocationUsedSelector);
    const noLocation = !((_b = (_a = currentSearchQuery.location) === null || _a === void 0 ? void 0 : _a.location) === null || _b === void 0 ? void 0 : _b.name) &&
        !((_c = currentSearchQuery.location) === null || _c === void 0 ? void 0 : _c.isFlexible) &&
        !geolocationUsed;
    const flexibleAddress = !((_e = (_d = currentSearchQuery.location) === null || _d === void 0 ? void 0 : _d.location) === null || _e === void 0 ? void 0 : _e.name) &&
        ((_f = currentSearchQuery.location) === null || _f === void 0 ? void 0 : _f.isFlexible);
    let buttonLabel = (_h = (_g = currentSearchQuery.location) === null || _g === void 0 ? void 0 : _g.location) === null || _h === void 0 ? void 0 : _h.name;
    buttonLabel = flexibleAddress
        ? intl.formatMessage({
            id: 'SearchBarSheet.Location.flexible',
        })
        : buttonLabel;
    buttonLabel = geolocationUsed
        ? intl.formatMessage({
            id: 'SearchBarSheet.Location.geolocation',
        })
        : buttonLabel;
    return (_jsxs(Wrapper, Object.assign({ colorVariant: colorVariant }, { children: [_jsx(SearchWrapper, Object.assign({ colorVariant: colorVariant }, { children: noLocation ? (_jsx(Input, { placeholder: intl.formatMessage({
                        id: 'SearchBarSheet.Location.searchPlaceholder',
                    }), onFocus: () => {
                        onSetActiveSearchBarSheet(SearchBarSheetVariant.Location);
                    }, inputRef: locationInputRef })) : (_jsx(LocationButtonWrap, { children: _jsx(Button, { icon: IconVariant.Magnifier, sizeVariant: ButtonSizeVariant.ListingSearchBar, colorVariant: activeSearchBarSheet === SearchBarSheetVariant.Location
                            ? ButtonColorVariant.Light
                            : ButtonColorVariant.Transparent, onClick: () => {
                            onSetActiveSearchBarSheet(SearchBarSheetVariant.Location);
                        }, label: buttonLabel, textAlign: TextAlign.Left }) })) })), _jsx(SearchBarSheetButtonWrapper, { children: _jsx(Button, { sizeVariant: ButtonSizeVariant.ListingSearchBar, icon: IconVariant.Knobs, colorVariant: ButtonColorVariant.Light, onClick: () => {
                        onSetActiveSearchBarSheet(SearchBarSheetVariant.Features);
                    }, label: 0 < featuresFilterCount ? `${featuresFilterCount}` : undefined }) })] })));
};
export default GoogleApiWrapper({
    apiKey: config.maps.googleMapsAPIKey,
    libraries: ['places'],
})(RegionalListingSearchBar);
