var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { SignInWithApple, } from '@capacitor-community/apple-sign-in';
import { FormProvider, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import jwtDecode from 'jwt-decode';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { EMAIL_REGEX } from '@nomady/shared/utils/validation';
import Button, { ButtonColorVariant, ButtonSizeVariant, } from '../../Button/Button';
import Text, { TextVariant } from '../../../styled-components/Text/Text';
import FormErrorMessage from '../../FormErrorMessage/FormErrorMessage';
import Input, { InputVariant } from '../../Input/Input';
import { isPasswordRecoveryEmailNotFoundError } from '../../../util/errors';
import { passwordRecoveryInProgressSelector, recoverPassword, retypeEmail, } from '../../../containers/PasswordRecoveryPage/PasswordRecoveryPageSlice';
import { ColorVariant } from '../../../theme';
import { Markdown } from 'react-showdown';
import { IconVariant } from '../../Icon/Icon';
import { isIos } from '../../../util/deviceContext';
import { UnsureAboutEmail } from '../components/UnsureAboutEmail';
const Form = styled.form ``;
const FormRow = styled.div `
  margin-bottom: 10px;
`;
const AdditionalLinksWrapper = styled.div `
  text-align: center;
  margin-top: 12px;
`;
const Title = styled(Text) `
  margin-bottom: 32px;

  @media ${props => props.theme.device.tablet} {
    margin-bottom: 64px;
  }
`;
const Message = styled(Text) `
  display: block;
  margin-bottom: 16px;
`;
const InfoAndFormGrid = styled.div `
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;
  @media ${props => props.theme.device.tablet} {
    grid-template-columns: 1fr 1fr;
    grid-gap: 64px;
  }
`;
const MobileOnly = styled.div `
  display: block;
  @media ${props => props.theme.device.tablet} {
    display: none;
  }
`;
const DesktopOnly = styled.div `
  display: none;
  @media ${props => props.theme.device.tablet} {
    display: block;
  }
`;
const InfoWrapper = styled.div ``;
export const MigrationForm = ({ title, text, }) => {
    const intl = useIntl();
    const location = useLocation();
    const emailFromQuery = new URLSearchParams(location.search).get('email');
    const nameFromQuery = new URLSearchParams(location.search).get('firstName');
    const methods = useForm({
        defaultValues: { email: emailFromQuery !== null && emailFromQuery !== void 0 ? emailFromQuery : '' },
    });
    const { register, handleSubmit, formState: { errors }, } = methods;
    const dispatch = useDispatch();
    const passwordRecoveryInProgress = useSelector(passwordRecoveryInProgressSelector);
    const onSuccess = (data) => __awaiter(void 0, void 0, void 0, function* () {
        const trimmedEmail = data.email.trim();
        dispatch(recoverPassword(trimmedEmail));
    });
    const _a = register('email', {
        required: true,
        pattern: {
            value: EMAIL_REGEX,
            message: 'Input.validationMessage.email',
        },
    }), { ref: emailRef } = _a, email = __rest(_a, ["ref"]);
    const { submittedEmail, passwordRequested, recoveryError, initialEmail } = useSelector((state) => state.PasswordRecoveryPage);
    const resendEmailLink = (_jsx(Text, Object.assign({ variant: TextVariant.H11B, colorVariant: ColorVariant.Cave, onClick: () => submittedEmail && dispatch(recoverPassword(submittedEmail)) }, { children: _jsx("u", { children: intl.formatMessage({
                id: 'PasswordRecoveryForm.resendEmailLink',
            }) }) })));
    const onReset = () => {
        dispatch(retypeEmail());
    };
    const fixEmailLink = (_jsx(Text, Object.assign({ variant: TextVariant.H11B, colorVariant: ColorVariant.Cave, onClick: onReset }, { children: _jsx("u", { children: intl.formatMessage({
                id: 'PasswordRecoveryForm.fixEmailLink',
            }) }) })));
    const showForm = !passwordRequested;
    const [formerAppleLoginMail, setFormerAppleLoginMail] = useState();
    const determineFormerAppleLoginEmail = () => __awaiter(void 0, void 0, void 0, function* () {
        let result;
        try {
            result = yield SignInWithApple.authorize({
                clientId: 'eu.mycabin.mycabin.preview',
                redirectURI: 'http://localhost',
                scopes: 'email name',
                state: '12345',
                nonce: 'nonce',
            });
        }
        catch (error) {
            console.error(error);
        }
        if (result === undefined)
            return;
        const decodedUserData = jwtDecode(result.response.identityToken);
        const userEmail = decodedUserData.email;
        setFormerAppleLoginMail(userEmail);
        dispatch(recoverPassword(userEmail));
    });
    if (showForm)
        return (_jsxs(_Fragment, { children: [nameFromQuery && (_jsx(Message, Object.assign({ variant: TextVariant.B30, colorVariant: ColorVariant.Snow }, { children: intl.formatMessage({
                        id: 'MigrateMyCabinUser.welcome',
                    }, { name: nameFromQuery }) }))), title && (_jsx(Title, Object.assign({ variant: TextVariant.H20, as: "h2" }, { children: title }))), _jsxs(InfoAndFormGrid, { children: [_jsxs(InfoWrapper, { children: [_jsx(Message, Object.assign({ variant: TextVariant.B30, colorVariant: ColorVariant.Snow }, { children: _jsx(Markdown, { markdown: text || '' }) })), _jsx(DesktopOnly, { children: _jsx(UnsureAboutEmail, {}) })] }), _jsx(FormProvider, Object.assign({}, methods, { children: _jsxs(Form, Object.assign({ onSubmit: handleSubmit(onSuccess) }, { children: [_jsx(FormRow, { children: _jsx(Input, Object.assign({ variant: InputVariant.Dark, label: intl.formatMessage({
                                                id: 'PasswordRecoveryForm.emailLabel',
                                            }), id: "email", autoComplete: "email" }, email, { inputRef: emailRef, error: errors === null || errors === void 0 ? void 0 : errors.email, placeholder: intl.formatMessage({
                                                id: 'MigrateMyCabinUser.email.placeholder',
                                            }) })) }), _jsx(FormRow, { children: _jsx(Button, { dataTestId: "PasswordRecoveryPage_recoveryButton", label: intl.formatMessage({
                                                id: 'MigrateMyCabinUser.activateAccountCTA',
                                            }), colorVariant: ButtonColorVariant.Light, type: "submit", inProgress: passwordRecoveryInProgress, sizeVariant: ButtonSizeVariant.Default }) }), !!recoveryError && (_jsx(FormErrorMessage, { id: isPasswordRecoveryEmailNotFoundError(recoveryError)
                                            ? 'PasswordRecoveryForm.emailNotFound'
                                            : 'PasswordRecoveryForm.actionFailedMessage' })), formerAppleLoginMail && (_jsx(Message, Object.assign({ variant: TextVariant.B30, colorVariant: ColorVariant.Aquamarine }, { children: formerAppleLoginMail }))), isIos && (_jsxs(_Fragment, { children: [_jsx(Message, Object.assign({ variant: TextVariant.B30, colorVariant: ColorVariant.Snow }, { children: intl.formatMessage({
                                                    id: 'MigrateMyCabinUser.recoverFromApple',
                                                }) })), _jsx(Button, { onClick: determineFormerAppleLoginEmail, label: intl.formatMessage({
                                                    id: 'MigrateMyCabinUser.recoverFromAppleButton',
                                                }), sizeVariant: ButtonSizeVariant.Default, colorVariant: ButtonColorVariant.Dark, icon: IconVariant.AppleLogo })] }))] })) })), _jsx(MobileOnly, { children: _jsx(UnsureAboutEmail, {}) })] })] }));
    return (_jsxs(_Fragment, { children: [_jsx(Title, Object.assign({ variant: TextVariant.H3, as: "h2" }, { children: intl.formatMessage({
                    id: 'PasswordRecoveryForm.emailSubmittedTitle',
                }) })), _jsx(Message, Object.assign({ variant: TextVariant.H30, as: "p", colorVariant: ColorVariant.Stone }, { children: intl.formatMessage({
                    id: 'MigrateMyCabinUser.emailSubmittedMessage',
                }, { initialEmail }) })), !!recoveryError && (_jsx(FormErrorMessage, { id: "PasswordRecoveryForm.actionFailedMessage" })), _jsx(AdditionalLinksWrapper, { children: _jsx(Text, Object.assign({ variant: TextVariant.H12B, colorVariant: ColorVariant.Cave }, { children: !passwordRecoveryInProgress
                        ? intl.formatMessage({
                            id: 'PasswordRecoveryForm.resendEmailText',
                        }, { resendEmailLink })
                        : intl.formatMessage({
                            id: 'PasswordRecoveryForm.resendingEmail',
                        }) })) }), _jsx(AdditionalLinksWrapper, { children: _jsx(Text, Object.assign({ variant: TextVariant.H12B, colorVariant: ColorVariant.Cave }, { children: intl.formatMessage({
                        id: 'PasswordRecoveryForm.fixEmailText',
                    }, { fixEmailLink }) })) })] }));
};
