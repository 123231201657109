import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styled from 'styled-components/macro';
import Icon, { IconSizeVariant, IconVariant } from '../Icon/Icon';
import { ColorVariant } from '../../theme';
const ButtonWrapper = styled.button `
  width: 100%;
  max-height: 68px;
  height: ${props => (props.hasFixedHeight ? '68px' : 'auto')};
  background-color: ${props => props.theme.color[props.backgroundColor]};
  padding: 11px 13px;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: none;
  transition: all 0.1s ease-in-out;
  overflow: hidden;
  border: 1px solid ${props => props.theme.color.transparent};
  :hover {
    cursor: pointer;
    border: 1px solid ${props => props.theme.color.stone};
  }
  :active {
    border: 1px solid ${props => props.theme.color.cornflowerblue};
    transition: all 50ms ease-in-out;
  }
`;
const ButtonInfoContainer = styled.span `
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 12px;
`;
const IconWrapper = styled.div `
  padding-right: 12px;
`;
const Panel = ({ dataTestId, onClick, children, ariaLabel, hasFixedHeight = false, backgroundColor = ColorVariant.Pearl, iconRightColorVariant = ColorVariant.Cave, iconVariant, iconSizeVariant = IconSizeVariant.Default, iconColorVariant = ColorVariant.Tar, }) => {
    return (_jsxs(ButtonWrapper, Object.assign({ "data-testid": dataTestId, "aria-label": ariaLabel, onClick: onClick || undefined, hasFixedHeight: hasFixedHeight, backgroundColor: backgroundColor }, { children: [_jsxs(ButtonInfoContainer, { children: [_jsx(IconWrapper, { children: _jsx(Icon, { variant: iconVariant, sizeVariant: iconSizeVariant, colorVariant: iconColorVariant }) }), children] }), _jsx(Icon, { sizeVariant: IconSizeVariant.ExtraExtraSmall, variant: IconVariant.DirectionRight, colorVariant: iconRightColorVariant })] })));
};
export default Panel;
