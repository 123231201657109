import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useRef } from 'react';
import styled from 'styled-components/macro';
import { useDrag, useDrop } from 'react-dnd';
import Text, { TextVariant } from '../Text/Text';
import IconButtonOld, { OldIconButtonColorVariant, OldIconButtonSizeVariant, } from '../IconButtonOld/IconButtonOld';
import { IconVariant } from '../Icon/Icon';
const Item = styled.div `
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  min-height: 32px;
  align-items: center;
  background-color: ${props => props.theme.color.pearl};
  border: 1px solid ${props => props.theme.color.cave};
  border-radius: 32px;
  padding: 2px 25px;
  margin-bottom: 10px;

  ${props => props.isDragging &&
    `
    opacity: 0.5;
  `}
`;
const ItemName = styled(Text) `
  flex-grow: 0;
  margin-right: 10px;
`;
const RemoveButton = styled(IconButtonOld) `
  flex-grow: 0;
`;
const InputListItem = ({ item, index, moveItem, removeItem, type, }) => {
    const ref = useRef(null);
    const [{ isDragging }, drag] = useDrag({
        type,
        item: () => ({
            index,
        }),
        collect: monitor => ({
            isDragging: monitor.isDragging(),
        }),
    });
    const [, drop] = useDrop({
        accept: type,
        canDrop: () => true,
        drop: (item, monitor) => moveItem(item.index, index),
    });
    drag(drop(ref));
    return (_jsxs(Item, Object.assign({ ref: ref, isDragging: isDragging }, { children: [_jsx(ItemName, Object.assign({ variant: TextVariant.H12B }, { children: item })), _jsx(RemoveButton, { onClick: event => removeItem(event, item), iconVariant: IconVariant.Garbage, iconButtonSizeVariant: OldIconButtonSizeVariant.Small, colorVariant: OldIconButtonColorVariant.Light })] })));
};
export default InputListItem;
