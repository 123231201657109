import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styled from 'styled-components/macro';
const Wrapper = styled.div `
  height: 44px;
`;
const TrackLine = styled.div `
  position: absolute;
  width: 100%;
  height: 1px;
  top: 24px;
  left: 0;
  right: 0;

  box-sizing: content-box;
  background-color: ${props => props.theme.color.fog};
`;
const Range = styled.div `
  position: absolute;
  top: 24px;
  height: 1px;
  background-color: ${props => props.theme.color.indigo};
`;
const Track = ({ children, handles, valueToPosition, }) => {
    const positionFromIndex = (index) => valueToPosition(handles[index]);
    return (_jsxs(Wrapper, { children: [_jsx(TrackLine, {}), handles.map((handle, index) => (_jsx(Range, { style: {
                    left: `${valueToPosition(handle)}px`,
                    width: `${positionFromIndex(index + 1) - valueToPosition(handle)}px`,
                } }, `range_${index}-${index + 1}`))), children] }));
};
export default Track;
