import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styled from 'styled-components/macro';
import { useIntl } from 'react-intl';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import { IconVariant } from '../Icon/Icon';
import Button, { ButtonSizeVariant } from '../Button/Button';
import { DefaultTheme } from '../../theme';
import { getImageAltText } from '../../util/imageHelper';
const MAX_AMOUNT_OF_IMAGES = 5;
const Wrapper = styled.div `
  position: relative;
  margin: 0 auto;
  background: ${props => props.theme.color.pearl};
  border-radius: 10px;

  ${props => props.theme.layout.container}
  padding: 10px;
  padding-right: 0px !important;
  @media ${props => props.theme.device.desktop} {
    margin-top: 0px;
    padding: 0px;
  }
`;
const Picture = styled.img `
  height: 204px;
  width: auto;
  max-width: 265px;
  object-fit: cover;

  border-radius: 5px;
  cursor: pointer;

  @media ${props => props.theme.device.tablet} {
    height: 275px;
    max-width: 405px;
  }

  @media ${props => props.theme.device.desktop} {
    width: 100%;
    height: 100%;
    max-width: initial;
  }
`;
const Pictures = styled.div `
  width: 100%;
  overflow-x: auto;
  display: grid;
  grid-auto-flow: column;
  column-gap: 10px;

  @media ${props => props.theme.device.desktop} {
    padding: 10px;
    max-width: 1024px;
    margin: 0 auto;

    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 150px 150px;
    grid-auto-flow: row;
    row-gap: 10px;

    ${Picture}:first-child {
      grid-column-start: 1;
      grid-column-end: 3;
      grid-row-start: 1;
      grid-row-end: 3;
    }
  }

  @media ${props => props.theme.device.desktopL} {
    max-width: 1110px;

    grid-template-rows: 165px 165px;
  }
`;
const ShowAllPictures = styled.div `
  position: absolute;
  right: 20px;
  bottom: 20px;
  & svg {
    margin-bottom: -3px;
    margin-top: 2px;
  }
  & button {
    height: 36px;
  }
`;
const PicturePreview = ({ images, altTexts = {}, fallbackAltText, onClickShowAllPictures, }) => {
    const intl = useIntl();
    return (_jsxs(Wrapper, { children: [_jsx(LazyLoadComponent, { children: _jsx(Pictures, Object.assign({ "data-testid": "PicturePreview_Pictures" }, { children: images === null || images === void 0 ? void 0 : images.slice(0, MAX_AMOUNT_OF_IMAGES).map((image) => {
                        var _a, _b, _c, _d;
                        return (_jsx(Picture, { src: (_a = image.attributes.variants['scaled-small']) === null || _a === void 0 ? void 0 : _a.url, srcSet: `${(_b = image.attributes.variants['scaled-small']) === null || _b === void 0 ? void 0 : _b.url} 300w, ${(_c = image.attributes.variants['scaled-medium']) === null || _c === void 0 ? void 0 : _c.url} 600w`, sizes: `${DefaultTheme.device.mobile} 100vw,
              ${DefaultTheme.device.tablet} 75vw,
              ${DefaultTheme.device.desktop} 50vw`, alt: (_d = getImageAltText(image, altTexts)) !== null && _d !== void 0 ? _d : fallbackAltText, onClick: onClickShowAllPictures }, image.id.uuid));
                    }) })) }), onClickShowAllPictures && (_jsx(ShowAllPictures, { children: _jsx(Button, { icon: IconVariant.Picture, label: intl.formatMessage({ id: 'PicturePreview.showAllPictures' }), onClick: onClickShowAllPictures, sizeVariant: ButtonSizeVariant.Small }) }))] }));
};
export default PicturePreview;
