import { useIntl } from 'react-intl';
import { DEFAULT_ADULT_QUANTITY, DEFAULT_SEAT_QUANTITY, } from '@nomady/shared/config';
import { useSelector } from 'react-redux';
import { isGeolocationUsedSelector } from '../../slices/listingSearchSlice';
import moment from 'moment';
export const getHasVehicleAndGuestSelections = (currentSearchQuery) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
    return (((_a = currentSearchQuery.guests) === null || _a === void 0 ? void 0 : _a.adults) !== DEFAULT_ADULT_QUANTITY ||
        ((_c = (_b = currentSearchQuery.guests) === null || _b === void 0 ? void 0 : _b.teens) !== null && _c !== void 0 ? _c : 0) !== 0 ||
        ((_e = (_d = currentSearchQuery.guests) === null || _d === void 0 ? void 0 : _d.children) !== null && _e !== void 0 ? _e : 0) !== 0 ||
        ((_g = (_f = currentSearchQuery.guests) === null || _f === void 0 ? void 0 : _f.infants) !== null && _g !== void 0 ? _g : 0) !== 0 ||
        ((_j = (_h = currentSearchQuery.guests) === null || _h === void 0 ? void 0 : _h.seats) !== null && _j !== void 0 ? _j : DEFAULT_SEAT_QUANTITY) !==
            DEFAULT_SEAT_QUANTITY ||
        ((_l = (_k = currentSearchQuery.guests) === null || _k === void 0 ? void 0 : _k.dogs) !== null && _l !== void 0 ? _l : 0) !== 0);
};
export const getGuestString = (currentSearchQuery, intl) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    return intl.formatMessage({
        id: 'ListingSearchBar.guestsSelected',
    }, {
        guests: ((_b = (_a = currentSearchQuery.guests) === null || _a === void 0 ? void 0 : _a.adults) !== null && _b !== void 0 ? _b : DEFAULT_ADULT_QUANTITY) +
            ((_d = (_c = currentSearchQuery.guests) === null || _c === void 0 ? void 0 : _c.teens) !== null && _d !== void 0 ? _d : 0) +
            ((_f = (_e = currentSearchQuery.guests) === null || _e === void 0 ? void 0 : _e.children) !== null && _f !== void 0 ? _f : 0) +
            ((_h = (_g = currentSearchQuery.guests) === null || _g === void 0 ? void 0 : _g.infants) !== null && _h !== void 0 ? _h : 0),
        seats: (_k = (_j = currentSearchQuery.guests) === null || _j === void 0 ? void 0 : _j.seats) !== null && _k !== void 0 ? _k : DEFAULT_SEAT_QUANTITY,
        dogs: (_m = (_l = currentSearchQuery.guests) === null || _l === void 0 ? void 0 : _l.dogs) !== null && _m !== void 0 ? _m : 0,
    });
};
export const getFormattedDate = (startDate, endDate, long) => {
    const yearFormat = long ? 'YYYY' : 'YY';
    const withYearFormat = `DD.MM.${yearFormat}`;
    const start = moment(startDate);
    const end = endDate && moment(endDate);
    if (!end) {
        return `${start.format('DD.MM')}–`;
    }
    const sameYear = start.get('year') === end.get('year');
    const sameMonth = start.get('month') === end.get('month');
    if (sameYear && sameMonth) {
        return `${start.format('DD.')}–${end.format(withYearFormat)}`;
    }
    if (sameYear) {
        return `${start.format('DD.MM')}–${end.format(withYearFormat)}`;
    }
    return `${start.format(withYearFormat)}–${end.format(withYearFormat)}`;
};
export const useFilterSummary = () => {
    var _a, _b, _c, _d, _e;
    const intl = useIntl();
    const { currentSearchQuery } = useSelector((state) => state.listingSearch);
    const geolocationUsed = useSelector(isGeolocationUsedSelector);
    const flexibleAddress = !((_b = (_a = currentSearchQuery.location) === null || _a === void 0 ? void 0 : _a.location) === null || _b === void 0 ? void 0 : _b.name) &&
        ((_c = currentSearchQuery.location) === null || _c === void 0 ? void 0 : _c.isFlexible);
    let locationLabel = (_e = (_d = currentSearchQuery.location) === null || _d === void 0 ? void 0 : _d.location) === null || _e === void 0 ? void 0 : _e.name;
    locationLabel = flexibleAddress
        ? intl.formatMessage({
            id: 'SearchBarSheet.Location.flexible',
        })
        : locationLabel;
    locationLabel = geolocationUsed
        ? intl.formatMessage({
            id: 'SearchBarSheet.Location.geolocation',
        })
        : locationLabel;
    const dateString = currentSearchQuery.dates.startDate
        ? getFormattedDate(currentSearchQuery.dates.startDate, currentSearchQuery.dates.endDate, false)
        : undefined;
    const title = locationLabel || dateString
        ? `${locationLabel || ''}${locationLabel && dateString ? ', ' : ''}${dateString || ''}`
        : undefined;
    const hasVehicleAndGuestSelections = getHasVehicleAndGuestSelections(currentSearchQuery);
    const subtitle = hasVehicleAndGuestSelections
        ? getGuestString(currentSearchQuery, intl)
        : '';
    return { title, subtitle };
};
