import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { powerSupplyFeatures, powerSupplyPayment, } from '@nomady/shared/marketplace-custom-config';
import { PowerSupplyPayment } from '@nomady/shared/types';
import { IconVariant } from '../../../styled-components/Icon/Icon';
import ListingChecklist from '../../../styled-components/ListingChecklist';
import { BookingExtraType } from '../../../styled-components/BookingExtra/BookingExtra';
import BookingExtrasSelection from './BookingExtrasSelection';
const PowerSupplyContent = () => {
    var _a, _b;
    const { listing } = useSelector((state) => state.ListingPage);
    const intl = useIntl();
    const activeCateringTypes = ((_b = (_a = listing === null || listing === void 0 ? void 0 : listing.attributes) === null || _a === void 0 ? void 0 : _a.publicData) === null || _b === void 0 ? void 0 : _b.powerSupplyType) || [];
    const elements = [...powerSupplyFeatures, ...powerSupplyPayment]
        .filter(element => activeCateringTypes.includes(element.key))
        .map((element, index) => ({
        text: intl.formatMessage({ id: element.label }),
        isChecked: true,
        key: `catering-${index}`,
    }));
    const showBookingExtras = activeCateringTypes.includes(PowerSupplyPayment.soldSeparately);
    return (_jsxs(_Fragment, { children: [_jsx(ListingChecklist, { items: elements, title: intl.formatMessage({ id: 'ListingPage.powerSupply' }), iconVariant: IconVariant.Electricity_NEW }), showBookingExtras && (_jsx(BookingExtrasSelection, { single: BookingExtraType.PowerSupply }))] }));
};
export default PowerSupplyContent;
