/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    currentLocation: null,
    currentCanonicalUrl: null,
};
export const routingSlice = createSlice({
    name: 'app/Routing',
    initialState,
    reducers: {
        locationChanged: (state, action) => {
            state.currentLocation = action.payload.location;
            state.currentCanonicalUrl = action.payload.canonicalUrl;
        },
    },
});
export const { locationChanged } = routingSlice.actions;
export default routingSlice.reducer;
