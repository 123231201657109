import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components/macro';
import { ColorVariant } from '../../theme';
import Button, { ButtonColorVariant, ButtonOutlineVariant, ButtonSizeVariant, } from '../Button/Button';
import { IconVariant } from '../Icon/Icon';
import Text, { TextVariant } from '../Text/Text';
const Wrapper = styled.div `
  display: flex;
  flex-direction: column;
`;
const Header = styled(Text) `
  display: flex;
  justify-content: flex-start;
  padding-bottom: 20px;
  border-bottom: 1px solid ${props => props.theme.color.fog};
  margin-bottom: 20px;
  text-transform: uppercase;
`;
const TableWrapper = styled.div `
  display: table;
`;
const RowWrap = styled.div `
  display: table-row;
  display: flex;
  border-radius: 10px;
  height: 62px;
  line-height: 62px;
  align-items: center;

  @media ${props => props.theme.device.desktop} {
    padding-left: 15px;
    padding-right: 15px;
  }

  &:hover {
    background-color: ${props => props.theme.color.pearl};
  }
`;
const FileName = styled(Text) `
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const DescriptionWrap = styled.div `
  display: table-cell;
  width: 100%;
  display: flex;
  flex-direction: column;
  @media ${props => props.theme.device.tablet} {
    display: initial;
  }
`;
const DownloadWrap = styled.div `
  display: table-cell;
  width: fit-content;
`;
const DesktopButton = styled.div `
  display: none;
  ${props => props.isHovered &&
    css `
      @media ${props => props.theme.device.desktop} {
        display: initial;
      }
    `}
`;
const MobileButton = styled.div `
  @media ${props => props.theme.device.desktop} {
    display: none;
  }
`;
const DesktopHyphon = styled.div `
  display: none;
  @media ${props => props.theme.device.tablet} {
    display: initial;
  }
`;
const FileDownloadTable = ({ files, title, startDownload, }) => {
    const [hoveredFile, setHoveredFile] = useState('');
    const intl = useIntl();
    const startHover = (event, id) => {
        event.stopPropagation();
        if (hoveredFile !== id) {
            setHoveredFile(id);
        }
    };
    const stopHover = (event) => {
        event.stopPropagation();
        setHoveredFile('');
    };
    return (_jsxs(Wrapper, { children: [_jsx(Header, Object.assign({ variant: TextVariant.H11B, colorVariant: ColorVariant.Cave }, { children: title })), _jsxs(TableWrapper, { children: [files.map(file => (_jsxs(RowWrap, Object.assign({ onMouseEnter: event => startHover(event, file.id), onMouseLeave: event => stopHover(event) }, { children: [_jsxs(DescriptionWrap, { children: [_jsx(FileName, Object.assign({ variant: TextVariant.H11B, colorVariant: ColorVariant.Cave }, { children: file.name })), _jsxs(DesktopHyphon, { children: ["\u00A0", _jsx(Text, Object.assign({ variant: TextVariant.H11B, colorVariant: ColorVariant.Cave }, { children: '-' })), "\u00A0"] }), _jsx(Text, Object.assign({ variant: TextVariant.H11B, colorVariant: ColorVariant.Cave }, { children: file.meta }))] }), _jsxs(DownloadWrap, { children: [_jsx(MobileButton, { children: _jsx(Button, { sizeVariant: ButtonSizeVariant.ExtraSmall, colorVariant: ButtonColorVariant.Transparent, icon: IconVariant.Download, onClick: () => startDownload(file.id) }) }), _jsx(DesktopButton, Object.assign({ isHovered: hoveredFile === file.id }, { children: _jsx(Button, { sizeVariant: ButtonSizeVariant.Small, outlineVariant: ButtonOutlineVariant.Outlined, colorVariant: ButtonColorVariant.Transparent, icon: IconVariant.Download, onClick: () => startDownload(file.id), label: intl.formatMessage({
                                                id: 'FileDownloadTable.buttonLabel',
                                            }) }) }), file.id)] })] }), file.id))), files.length === 0 &&
                        intl.formatMessage({
                            id: 'FileDownloadTable.noResults',
                        })] })] }));
};
export default FileDownloadTable;
