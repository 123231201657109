import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { ColorVariant } from '../../../theme';
import Modal from '../../Modal/Modal';
import Text from '../../Text/Text';
const GeolocationModal = ({ isOpen, onSuccess, onCancel, }) => {
    const { browserGeolocationError, browserGeolocationInProgress } = useSelector((state) => state.geolocation);
    const intl = useIntl();
    const leftButton = {
        label: intl.formatMessage({ id: 'GeolocationModal.cancelButton' }),
        isPrimary: false,
        onClick: onCancel,
    };
    const rightButton = {
        label: intl.formatMessage({ id: 'GeolocationModal.okButton' }),
        isPrimary: true,
        onClick: onSuccess,
        inProgress: browserGeolocationInProgress,
    };
    return (_jsxs(Modal, Object.assign({ id: "GeolocationModal", title: intl.formatMessage({ id: 'GeolocationModal.title' }), isOpen: isOpen, onClose: onCancel, leftButton: leftButton, rightButton: rightButton }, { children: [_jsx(Text, { children: intl.formatMessage({ id: 'GeolocationModal.description' }) }), browserGeolocationError && (_jsx(Text, Object.assign({ colorVariant: ColorVariant.Cinnabar, as: "p" }, { children: intl.formatMessage({ id: 'GeolocationModal.failed' }) })))] })));
};
export default GeolocationModal;
