const getCampStyleApiParams = (campStyle) => {
    var _a;
    const pub_campStyle = 0 < (((_a = campStyle === null || campStyle === void 0 ? void 0 : campStyle.category) === null || _a === void 0 ? void 0 : _a.length) || 0)
        ? `has_any:${((campStyle === null || campStyle === void 0 ? void 0 : campStyle.category) || []).join(',')}` || undefined
        : undefined;
    const pub_privateCamp = (campStyle === null || campStyle === void 0 ? void 0 : campStyle.private) !== undefined ? `${campStyle === null || campStyle === void 0 ? void 0 : campStyle.private}` : undefined;
    return {
        pub_campStyle,
        pub_privateCamp,
    };
};
export default getCampStyleApiParams;
