import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import ReactPlayer from 'react-player';
import styled from 'styled-components/macro';
import { EnsureUsercentricsConsent } from '../../hooks/UsercentricsProvider';
const Wrapper = styled.div `
  height: 360px;

  @media ${props => props.theme.device.tablet} {
    height: 540px;
  }
`;
const SectionVideo = ({ video }) => {
    return (_jsx(Wrapper, { children: _jsx(EnsureUsercentricsConsent, Object.assign({ services: ['YouTube Video'] }, { children: _jsx(ReactPlayer, { url: video, controls: false, volume: 0, muted: true, light: false, loop: true, width: "100%", height: "100%" }) })) }));
};
export default SectionVideo;
