import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styled from 'styled-components/macro';
import CheckboxInput, { CheckboxInputSpacingVariant, } from '../CheckboxInput/CheckboxInput';
const Checkboxes = styled.div `
  margin-left: 32px;
  display: flex;
  flex-direction: column;
`;
const CheckboxInputGroup = ({ id, label, checkboxes, onChange = (values, id) => console.table({ id, values }), }) => {
    const isChecked = checkboxes.map(checkbox => checkbox.checked).includes(true) &&
        !checkboxes.map(checkbox => checkbox.checked).includes(false);
    const intermediaryChecked = checkboxes.map(checkbox => checkbox.checked).includes(true) &&
        checkboxes.map(checkbox => checkbox.checked).includes(false);
    const toggleGroup = () => {
        if (isChecked || intermediaryChecked) {
            onChange(checkboxes.map(checkbox => (Object.assign(Object.assign({}, checkbox), { checked: false }))), id);
        }
        else {
            onChange(checkboxes.map(checkbox => (Object.assign(Object.assign({}, checkbox), { checked: true }))), id);
        }
    };
    const onChangeCheckbox = (newValue, checkboxId) => {
        const index = checkboxes.findIndex(element => element.id === checkboxId);
        const updatedCheckboxes = [...checkboxes];
        updatedCheckboxes[index].checked = newValue;
        onChange(updatedCheckboxes, id);
    };
    return (_jsxs(_Fragment, { children: [_jsx(CheckboxInput, { id: id, label: label, checked: isChecked, intermediaryChecked: intermediaryChecked, onChange: toggleGroup }), _jsx(Checkboxes, { children: checkboxes.map(element => (_jsx(CheckboxInput, { id: element.id, label: element.label, checked: element.checked, spacingVariant: CheckboxInputSpacingVariant.Default, onChange: event => onChangeCheckbox(event.target.checked, element.id) }, element.id))) })] }));
};
export default CheckboxInputGroup;
