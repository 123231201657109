import * as z from 'zod';
import { localizedZodCustomErrorMap } from '../localizedZodCustomErrorMap';
import { AccountType } from '../../stripe-config';
const CommonStripeAccountEditSchema = z.object({
    accountType: z.nativeEnum(AccountType),
    userCountry: z.string().min(1),
    vatId: z.string().or(z.null()).optional(),
    placeOfBirth: z.string().or(z.null()).optional(),
});
const StripeAccountEditIndividualSchema = CommonStripeAccountEditSchema.extend({
    accountType: z.literal(AccountType.individual),
    placeOfBirth: z.string().min(1),
});
const StripeAccountEditBusinessSchema = CommonStripeAccountEditSchema.extend({
    accountType: z.literal(AccountType.company),
    vatId: z.string().min(1),
});
export const StripeAccountEditSchema = z.discriminatedUnion('accountType', [
    StripeAccountEditIndividualSchema,
    StripeAccountEditBusinessSchema,
]);
export const getStripeAccountEditSchema = (intl) => {
    z.setErrorMap(localizedZodCustomErrorMap(intl, 'UserSchemaError'));
    return StripeAccountEditSchema;
};
