import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import styled from 'styled-components/macro';
const Images = styled.div `
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  align-items: flex-end;
`;
const Image = styled.img `
  width: 270px;

  height: auto;
  margin-right: 20px;

  @media ${props => props.theme.device.tablet} {
    width: 300px;
  }

  @media ${props => props.theme.device.tabletL} {
    width: 350px;
  }

  @media ${props => props.theme.device.desktop} {
    width: 400px;
  }

  @media ${props => props.theme.device.desktopL} {
    width: 450px;
  }
`;
const ImageSlider = ({ images }) => (_jsx(Images, { children: images.map((image, index) => {
        return (_jsx(Image, { src: image.image.url, alt: image.imageAlt, loading: "lazy" }, index));
    }) }));
export default ImageSlider;
