var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { distanceSortKey } from '@nomady/shared/marketplace-custom-config';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { createResourceLocatorString } from '@nomady/shared/utils/routes';
import Page from '@nomady/shared/routeConfiguration/Page';
import { CampStyle, CampStyleAccommodation } from '@nomady/shared/types';
import styled from 'styled-components/macro';
import React, { useState } from 'react';
import weekendImage from '../SearchPageLinksSlider/assets/weekend.webp';
import accommodationInSurroundingsImage from '../SearchPageLinksSlider/assets/accommodationInSurroundings.webp';
import campsInSurroundingsImage from '../SearchPageLinksSlider/assets/campsInSurroundings.webp';
import farmCampingImage from '../SearchPageLinksSlider/assets/farmCamping.webp';
import millionstarImage from '../SearchPageLinksSlider/assets/millionstar.webp';
import bikeTripImage from '../SearchPageLinksSlider/assets/bikeTrip.webp';
import farmersShopImage from '../SearchPageLinksSlider/assets/farmersShop.webp';
import fishingImage from '../SearchPageLinksSlider/assets/fishing.webp';
import hikingImage from '../SearchPageLinksSlider/assets/hiking.webp';
import GeolocationModal from '../SearchBarSheet/Location/GeolocationModal';
import getWeekend from '../../util/search-query/dates/utils/getWeekend';
import { browserGeolocationError, browserGeolocationRequest, browserGeolocationSuccess, setGeolocationSource, } from '../../slices/geolocationSlice';
import getQueryString from '../../util/search-query/getQueryString';
import { userLocation } from '../../util/maps';
import GeolocationSource from '../../util/GeolocationSource';
import { config, routeConfiguration } from '../..';
import Card from './Card';
import { getBoundsOfDistance } from '../../util/getBoundsOfDistance';
const SearchLinksLandingWrapper = styled.div `
  display: grid;
  gap: 15px;
  grid-auto-flow: column;
  align-items: start;
  margin-bottom: 5px;
  padding-bottom: 20px;
  grid-template-columns: repeat(
    ${props => props.columnsNumber},
    minmax(200px, 244px)
  );
`;
const ConnectedPictureCard = ({ startIndex, endIndex, }) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const [showGeolocationModal, setShowGeolocationModal] = useState(false);
    const [geolocationSearchQueryParams, setGeolocationSearchQueryParams] = useState();
    const history = useHistory();
    const nextWeekendSearchQuery = getWeekend();
    const ADVENTURE_CARDS = [
        {
            searchQuery: { dates: nextWeekendSearchQuery },
            backgroundImage: weekendImage,
            title: 'SearchPageLinksSlider.adventure.weekend',
        },
        {
            onClick: () => {
                setGeolocationSearchQueryParams({
                    features: {
                        campStyle: { category: Object.values(CampStyleAccommodation) },
                    },
                });
                setShowGeolocationModal(true);
            },
            backgroundImage: accommodationInSurroundingsImage,
            title: 'SearchPageLinksSlider.adventure.accommodationInSurroundings',
        },
        {
            onClick: () => {
                setGeolocationSearchQueryParams({
                    features: { campStyle: { category: [CampStyle.tent] } },
                });
                setShowGeolocationModal(true);
            },
            backgroundImage: campsInSurroundingsImage,
            title: 'SearchPageLinksSlider.adventure.campsInSurroundings',
        },
        {
            searchQuery: { features: { ambiance: { farm: true } } },
            backgroundImage: farmCampingImage,
            title: 'SearchPageLinksSlider.adventure.farmCamping',
        },
        {
            searchQuery: {
                features: {
                    facilities: { accommodation: { millionStars: true } },
                },
            },
            backgroundImage: millionstarImage,
            title: 'SearchPageLinksSlider.adventure.millionstar',
        },
        {
            searchQuery: {
                features: { activities: { summer: { biking: true } } },
            },
            backgroundImage: bikeTripImage,
            title: 'SearchPageLinksSlider.adventure.bikeTrip',
        },
        {
            searchQuery: { features: { facilities: { store: true } } },
            backgroundImage: farmersShopImage,
            title: 'SearchPageLinksSlider.adventure.farmersShop',
        },
        {
            searchQuery: {
                features: { activities: { summer: { fishing: true } } },
            },
            backgroundImage: fishingImage,
            title: 'SearchPageLinksSlider.adventure.fishing',
        },
        {
            searchQuery: {
                features: { activities: { summer: { hiking: true } } },
            },
            backgroundImage: hikingImage,
            title: 'SearchPageLinksSlider.adventure.hiking',
        },
    ];
    const start = startIndex || 0;
    const end = endIndex || ADVENTURE_CARDS.length;
    const numberOfCardsToDisplay = end - start;
    return (_jsxs(_Fragment, { children: [_jsx(GeolocationModal, { isOpen: showGeolocationModal, onCancel: () => setShowGeolocationModal(false), onSuccess: () => __awaiter(void 0, void 0, void 0, function* () {
                    try {
                        dispatch(browserGeolocationRequest());
                        dispatch(setGeolocationSource(GeolocationSource.SearchBar));
                        const origin = yield userLocation();
                        const bounds = getBoundsOfDistance(origin, config.maps.search.currentLocationBoundsDistance);
                        const searchQuery = Object.assign(Object.assign({}, geolocationSearchQueryParams), { location: {
                                origin,
                                location: { bounds },
                            }, sort: distanceSortKey });
                        const queryString = getQueryString(searchQuery);
                        const target = createResourceLocatorString(Page.Search, routeConfiguration, {}, queryString);
                        history.push(target);
                        dispatch(browserGeolocationSuccess());
                    }
                    catch (error) {
                        console.error(error);
                        dispatch(browserGeolocationError(error));
                    }
                }) }), _jsx(SearchLinksLandingWrapper, Object.assign({ columnsNumber: numberOfCardsToDisplay }, { children: ADVENTURE_CARDS.map((card, index) => {
                    if (index >= start && index < end)
                        return (_jsx(Card, { onClick: card.onClick, searchQuery: card.searchQuery, backgroundImage: card.backgroundImage, title: intl.formatMessage({
                                id: card.title,
                            }) }, `adventure-${index}`));
                    return null;
                }) }))] }));
};
export default ConnectedPictureCard;
