import { useEffect, useState } from 'react';
import { AvailabilityStateEnum, } from '../types';
import { useSelector } from 'react-redux';
import { availabilityExceptionsSelector, selectedListingUUIDSelector, } from '../CalendarSlice';
import { isDateWithinException } from '../utils';
import { ALL_LISTINGS_KEY } from '../constants';
export const useAvailabilityState = (date) => {
    const selectedListingUUID = useSelector(selectedListingUUIDSelector);
    const allAvailabilityExceptions = useSelector(availabilityExceptionsSelector);
    const [availabilityState, setAvailabilityState] = useState();
    useEffect(() => {
        if (!allAvailabilityExceptions)
            return;
        const newState = selectedListingUUID === ALL_LISTINGS_KEY
            ? computeStateForAllListings(allAvailabilityExceptions, date)
            : computeStateForSelectedListing(allAvailabilityExceptions[selectedListingUUID.uuid] || [], date);
        setAvailabilityState(newState);
    }, [selectedListingUUID, allAvailabilityExceptions, date]);
    const computeStateForAllListings = (allAvailabilityExceptions, date) => {
        const stateMap = Object.values(allAvailabilityExceptions).map((exceptions) => {
            const exceptionsToday = exceptions.filter(exception => isDateWithinException(exception, date));
            if (exceptionsToday.length === 0)
                return AvailabilityStateEnum.AllFree;
            return exceptionsToday[0].attributes.seats === 0
                ? AvailabilityStateEnum.AllBlocked
                : AvailabilityStateEnum.PartiallyBlocked;
        });
        if (stateMap.every(state => state === AvailabilityStateEnum.AllFree)) {
            return AvailabilityStateEnum.AllFree;
        }
        if (stateMap.every(state => state === AvailabilityStateEnum.AllBlocked)) {
            return AvailabilityStateEnum.AllBlocked;
        }
        return AvailabilityStateEnum.PartiallyBlocked;
    };
    const computeStateForSelectedListing = (exceptions, date) => {
        const exceptionsToday = exceptions.filter(exception => isDateWithinException(exception, date));
        if (exceptionsToday.length === 0) {
            return AvailabilityStateEnum.AllFree;
        }
        const availableSeats = exceptionsToday[0].attributes.seats;
        return availableSeats > 0
            ? { availableSeats }
            : AvailabilityStateEnum.AllBlocked;
    };
    return { availabilityState };
};
