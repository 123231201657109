import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styled from 'styled-components/macro';
import Text, { TextVariant } from '../Text/Text';
import Icon, { IconSizeVariant } from '../Icon/Icon';
import { ColorVariant } from '../../theme';
const Wrapper = styled.button `
  position: relative;
  display: flex;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px 8px 10px 8px;
  border: 0;
  cursor: pointer;
  background: ${props => props.theme.color.basicPearlWhiteTypeWhite};
  border-radius: 16px;

  ${props => !props.checked &&
    `
    &:hover {
      background: ${props.theme.color.lagoonLight};
    }`}

  ${props => props.checked &&
    `
    background: ${props.theme.color.lagoon};
  `}
`;
const Label = styled(Text) `
  margin-top: -2px;
`;
const Info = styled(Text) `
  position: absolute;
  top: 8px;
  right: 8px;
  height: 16px;
  border-radius: 16px;
  background: ${props => props.theme.color.basicSnowGrey};
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 4px;
  padding-right: 4px;
`;
export const CampStyleToggle = ({ checked, label, iconVariant, onChange, info = undefined, }) => {
    return (_jsxs(Wrapper, Object.assign({ checked: checked, onClick: () => onChange(!checked) }, { children: [_jsx(Icon, { variant: iconVariant, sizeVariant: IconSizeVariant.Medium }), _jsx(Label, Object.assign({ variant: TextVariant.L4SB, colorVariant: ColorVariant.BasicCaveBlack }, { children: label })), info && (_jsx(Info, Object.assign({ variant: TextVariant.L4R, colorVariant: ColorVariant.Cave }, { children: info })))] })));
};
export default CampStyleToggle;
