/* Stripe related configuration.
NOTE: REACT_APP_STRIPE_PUBLISHABLE_KEY is mandatory environment variable.
This variable is set in a hidden file: .env
To make Stripe connection work, you also need to set Stripe's private key in the Flex Console.
*/
import env from './utils/env';
export const stripePublishableKey = env('STRIPE_PUBLISHABLE_KEY');
/**
 * Default merchant category code (MCC)
 * MCCs are used to classify businesses by the type of goods or services they provide.
 *
 * In FTW we use code 5734 Computer Software Stores as a default for all the connected accounts.
 *
 * See the whole list of MCC codes from https://stripe.com/docs/connect/setting-mcc#list
 */
export const defaultMCC = '6513';
export var AccountType;
(function (AccountType) {
    AccountType["individual"] = "individual";
    AccountType["company"] = "company";
})(AccountType || (AccountType = {}));
export const accountTypes = [
    {
        key: AccountType.individual,
        label: 'MarketplaceConfig.stripeConnectAccountType.individual',
    },
    {
        key: AccountType.company,
        label: 'MarketplaceConfig.stripeConnectAccountType.business',
    },
];
/*
Stripe only supports payments in certain countries, see full list
at https://stripe.com/global
You can find the bank account formats from https://stripe.com/docs/connect/payouts#formats
*/
export const stripeCountryDetails = [
    {
        // Austria
        code: 'AT',
        currency: 'EUR',
        accountConfig: {
            iban: true,
        },
    },
    {
        // Belgium
        code: 'BE',
        currency: 'EUR',
        accountConfig: {
            iban: true,
        },
    },
    {
        // Denmark
        code: 'DK',
        currency: 'DKK',
        accountConfig: {
            iban: true,
        },
    },
    {
        // Estionia
        code: 'EE',
        currency: 'EUR',
        accountConfig: {
            iban: true,
        },
    },
    {
        // Finland
        code: 'FI',
        currency: 'EUR',
        accountConfig: {
            iban: true,
        },
    },
    {
        // France
        code: 'FR',
        currency: 'EUR',
        accountConfig: {
            iban: true,
        },
    },
    {
        // Germany
        code: 'DE',
        currency: 'EUR',
        accountConfig: {
            iban: true,
        },
    },
    {
        // Greece
        code: 'GR',
        currency: 'EUR',
        accountConfig: {
            iban: true,
        },
    },
    {
        // Ireland
        code: 'IE',
        currency: 'EUR',
        accountConfig: {
            iban: true,
        },
    },
    {
        // Italy
        code: 'IT',
        currency: 'EUR',
        accountConfig: {
            iban: true,
        },
    },
    {
        // Latvia
        code: 'LV',
        currency: 'EUR',
        accountConfig: {
            iban: true,
        },
    },
    {
        // Lithuania
        code: 'LT',
        currency: 'EUR',
        accountConfig: {
            iban: true,
        },
    },
    {
        // Luxembourg
        code: 'LU',
        currency: 'EUR',
        accountConfig: {
            iban: true,
        },
    },
    {
        // Netherlands
        code: 'NL',
        currency: 'EUR',
        accountConfig: {
            iban: true,
        },
    },
    {
        // Norway
        code: 'NO',
        currency: 'EUR',
        accountConfig: {
            iban: true,
        },
    },
    {
        // Poland
        code: 'PL',
        currency: 'EUR',
        accountConfig: {
            iban: true,
        },
    },
    {
        // Portugal
        code: 'PT',
        currency: 'EUR',
        accountConfig: {
            iban: true,
        },
    },
    {
        // Slovakia
        code: 'SK',
        currency: 'EUR',
        accountConfig: {
            iban: true,
        },
    },
    {
        // Slovenia
        code: 'SI',
        currency: 'EUR',
        accountConfig: {
            iban: true,
        },
    },
    {
        // Spain
        code: 'ES',
        currency: 'EUR',
        accountConfig: {
            iban: true,
        },
    },
    {
        // Sweden
        code: 'SE',
        currency: 'SEK',
        accountConfig: {
            iban: true,
        },
    },
    {
        // Switzerland
        code: 'CH',
        currency: 'CHF',
        accountConfig: {
            iban: true,
        },
    },
];
/*
NOTE: This configuration will not be updated!
We might remove this code in the later releases.
With Connect Onboarding Stripe will handle collecting most of the information about user. For new setup we only need the list of countries and accountConfig.
If you want to handle the whole onboarding flow on your on application, you can use the old PayoutDetailsForm as a starting point. That form uses this configuration option.
You should make sure that the list of countries is up-to-date and that the config contains all the required infomation you need to collect.
Remember to change the import from config.js if you want to use this config!
This setup is for API version '2019-02-19' and later.
If you have an older API version in use, you need to update your Stripe API.
You can check your Stripe API version from Stripe Dashboard -> Developers.
Stripe only supports payments in certain countries, see full list
at https://stripe.com/global
*/
