import { MarketplaceLocale } from '@nomady/shared/config';
export const shouldShowBackButton = (pathname) => {
    const startTrimmedPathname = pathname.startsWith('/')
        ? pathname.slice(1)
        : pathname;
    const trimmedPathname = startTrimmedPathname.endsWith('/')
        ? startTrimmedPathname.slice(0, -1)
        : startTrimmedPathname;
    const isOnHomePage = trimmedPathname
        .split('/')
        .filter(Boolean)
        .filter(value => !Object.values(MarketplaceLocale).includes(value)).length === 0;
    return !isOnHomePage;
};
