import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import debounce from 'lodash/debounce';
import { offsetForHiddenModal, offsetLimit, offsetToClose } from '../constants';
import { setDisableScroll } from '../../../slices/UISlice';
const useDraggableModal = (onClose, isOpen) => {
    const [offsetY, setOffsetY] = useState(offsetForHiddenModal);
    const [initialY, setInitialY] = useState(null);
    const [isDragging, setIsDragging] = useState(false);
    const dispatch = useDispatch();
    const modalRef = useRef(null);
    const handleClose = (e) => {
        setOffsetY(offsetForHiddenModal);
        setTimeout(() => onClose(e), 200);
    };
    const handleDrag = event => {
        const startingPoint = initialY !== null && initialY !== void 0 ? initialY : event.targetTouches[0].pageY;
        const deltaY = startingPoint - event.targetTouches[0].pageY;
        if (modalRef.current && deltaY < offsetLimit) {
            setOffsetY(deltaY);
        }
    };
    const handleDragStart = event => {
        setIsDragging(true);
        setInitialY(event.targetTouches[0].pageY);
    };
    const handleDragEnd = event => {
        setIsDragging(false);
        if (offsetY <= offsetToClose) {
            setOffsetY(offsetForHiddenModal);
            debounce(() => handleClose(event), 50)();
        }
        else {
            setOffsetY(0);
        }
    };
    useEffect(() => {
        setOffsetY(0);
        return () => {
            setIsDragging(false);
            setOffsetY(offsetForHiddenModal);
        };
    }, []);
    useEffect(() => {
        if (isOpen) {
            setOffsetY(0);
            dispatch(setDisableScroll(true));
        }
        else {
            setOffsetY(offsetForHiddenModal);
        }
        setInitialY(null);
        return () => {
            dispatch(setDisableScroll(false));
        };
    }, [dispatch, isOpen]);
    return {
        modalRef,
        handleClose,
        handleDrag,
        handleDragStart,
        handleDragEnd,
        isDragging,
        offsetY,
        initialY,
    };
};
export default useDraggableModal;
