import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styled from 'styled-components/macro';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { createSlug } from '@nomady/shared/utils/urlHelpers';
import PageConfig from '@nomady/shared/routeConfiguration/Page';
import { FormattedMessage } from '../../util/reactIntl';
import { NamedLink } from '../../components';
import LayoutSideNavigation from '../../styled-components/LayoutSideNavigation/LayoutSideNavigation';
import { fetchListingFavoritesLists, listingFavoritesListsSelector, listingFavoritesListsError, } from '../../slices/listingFavoritesListsSlice';
import ListingFavoritesListCard from '../../styled-components/ListingFavoritesListCard/ListingFavoritesListCard';
import SignedInPageContainer from '../../styled-components/PageContainer/SignedInPageContainer';
import ErrorCard from '../../styled-components/ErrorCard/ErrorCard';
const ListingFavoritesListCardWrapper = styled.div `
  width: 100%;
  margin-bottom: 20px;
`;
const ErrorCadWrapper = styled.div `
  margin-left: 50px;
`;
const ListingFavoritesListsPage = () => {
    const dispatch = useDispatch();
    const listingFavoritesLists = useSelector(listingFavoritesListsSelector);
    const listingFavoritesListsErrorMessage = useSelector(listingFavoritesListsError);
    const intl = useIntl();
    const handleOnRetry = () => {
        dispatch(fetchListingFavoritesLists());
    };
    return (_jsx(SignedInPageContainer, Object.assign({ title: "Favorites", noIndex: true }, { children: listingFavoritesListsErrorMessage ? (_jsx(ErrorCadWrapper, { children: _jsx(ErrorCard, { title: intl.formatMessage({
                    id: 'SearchBarSheet.ErrorCard.title',
                }), description: intl.formatMessage({
                    id: 'SearchBarSheet.ErrorCard.description',
                }), onRetry: handleOnRetry, retryButtonLabel: intl.formatMessage({
                    id: 'SearchBarSheet.ErrorCard.retryButtonLabel',
                }) }) })) : (_jsxs(LayoutSideNavigation, { children: [_jsx("h1", { children: _jsx(FormattedMessage, { id: "Favorites" }) }), listingFavoritesLists.map(listingFavoritesList => {
                    var _a;
                    return (_jsx(ListingFavoritesListCardWrapper, { children: _jsx(NamedLink, Object.assign({ name: PageConfig.ListingFavoritesList, params: {
                                id: (listingFavoritesList === null || listingFavoritesList === void 0 ? void 0 : listingFavoritesList.id) || '',
                                slug: createSlug((listingFavoritesList === null || listingFavoritesList === void 0 ? void 0 : listingFavoritesList.name) || ''),
                            } }, { children: _jsx(ListingFavoritesListCard, { name: listingFavoritesList === null || listingFavoritesList === void 0 ? void 0 : listingFavoritesList.name, coverPicture: listingFavoritesList === null || listingFavoritesList === void 0 ? void 0 : listingFavoritesList.cover_picture, listings: (_a = listingFavoritesList === null || listingFavoritesList === void 0 ? void 0 : listingFavoritesList.listings) === null || _a === void 0 ? void 0 : _a.length }) })) }, listingFavoritesList === null || listingFavoritesList === void 0 ? void 0 : listingFavoritesList.id));
                })] })) })));
};
export default ListingFavoritesListsPage;
