import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styled from 'styled-components/macro';
import Markdown from 'react-showdown';
import Text, { TextVariant } from '../Text/Text';
import { getSrcSetAndSizeOfImg } from '../../util/imageHelper';
const Wrapper = styled.section `
  display: flex;
  flex-direction: column;

  ${props => props.theme.layout.col6}
`;
const Image = styled.img `
  width: 100%;

  margin-bottom: 30px;

  @media ${props => props.theme.device.tablet} {
    margin-bottom: 40px;
  }
`;
const Name = styled(Text) `
  margin-top: 0;
  margin-bottom: 8px;
`;
const Role = styled(Text) `
  margin-bottom: 20px;

  @media ${props => props.theme.device.tablet} {
    margin-bottom: 25px;
  }
`;
const Person = ({ picture, pictureAlt, name, role, description, }) => {
    const { srcSet, sizes } = getSrcSetAndSizeOfImg(picture.data.attributes.formats);
    return (_jsxs(Wrapper, { children: [_jsx(Image, { srcSet: srcSet, sizes: sizes, src: picture.data.attributes.url, alt: pictureAlt }), _jsx(Name, Object.assign({ variant: TextVariant.H6, as: "h4" }, { children: name })), _jsx(Role, Object.assign({ variant: TextVariant.H7 }, { children: role })), _jsx(Text, { children: _jsx(Markdown, { markdown: description }) })] }));
};
export default Person;
