import { CampStyle } from '@nomady/shared/types';
import { IconVariant } from './styled-components/Icon/Icon';
const campStyleIcons = {
    [CampStyle.tent]: IconVariant.Tent_NEW,
    [CampStyle.smallVehicle]: IconVariant.Van_NEW,
    [CampStyle.mediumVehicle]: IconVariant.Motorhome_NEW,
    [CampStyle.largeVehicle]: IconVariant.MotorhomePlus_NEW,
    [CampStyle.carWithRooftopTent]: IconVariant.Rooftent,
    [CampStyle.caravan]: IconVariant.Caravan_NEW,
    [CampStyle.hut]: IconVariant.Cabin,
    [CampStyle.glamping]: IconVariant.TipiNew,
    [CampStyle.shelter]: IconVariant.Roof,
};
export default campStyleIcons;
