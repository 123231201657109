import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import IntroSheetContent from './IntroSheetContent';
import Text, { TextVariant } from '../../Text/Text';
import styled from 'styled-components/macro';
import { ColorVariant } from '../../../theme';
import Button, { ButtonColorVariant, ButtonSizeVariant, } from '../../Button/Button';
import background from '../assets/231122_Nomady_DigitalFormats_AppWelcome2.webp';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { safeSpaceTopSelector } from '../../../slices/UISlice';
import Icon, { IconSizeVariant, IconVariant } from '../../Icon/Icon';
const Wrapper = styled.div `
  position: fixed;
  background-color: ${props => props.theme.color.snow};
  width: 100vw;
  height: 100vh;
  z-index: 20;
  display: flex;
  flex-direction: column;
  padding-top: ${props => props.safeSpaceTop}px;
  background-image: url(${background});
  background-position: bottom;
  background-size: cover;
  justify-content: space-between;
`;
const HighlightList = styled.ul `
  list-style: none;
  margin: 0;
  padding: 0;
`;
const HighlightWrapper = styled.div `
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0 0 16px 0;
`;
const ButtonContainer = styled.div `
  display: flex;
  gap: 32px;
  justify-content: space-between;
  padding: 32px;
  flex-shrink: 0;
`;
const ButtonWrapper = styled.div `
  width: 100%;
  text-align: center;
`;
const Spacer = styled.div `
  height: 192px;
  width: 1px;
`;
const SheetNotification = ({ onContinue, onBack, }) => {
    const safeSpaceTop = useSelector(safeSpaceTopSelector);
    const intl = useIntl();
    return (_jsxs(Wrapper, Object.assign({ safeSpaceTop: safeSpaceTop }, { children: [_jsx(IntroSheetContent, { title: intl.formatMessage({
                    id: 'AppIntro.NotificationSheet.Title',
                }), content: _jsxs(HighlightList, { children: [[
                            intl.formatMessage({
                                id: 'AppIntro.NotificationSheet.Benefit1',
                            }),
                            intl.formatMessage({
                                id: 'AppIntro.NotificationSheet.Benefit2',
                            }),
                            intl.formatMessage({
                                id: 'AppIntro.NotificationSheet.Benefit3',
                            }),
                        ].map((highlight, index) => (_jsxs(HighlightWrapper, Object.assign({ as: "li" }, { children: [_jsx(Icon, { variant: IconVariant.Check_Bold, sizeVariant: IconSizeVariant.Medium, colorVariant: ColorVariant.BasicCaveBlack }), _jsx(Text, Object.assign({ variant: TextVariant.H8, colorVariant: ColorVariant.Cave }, { children: highlight }))] }), highlight))), _jsx(Spacer, {})] }) }, 1), _jsxs(ButtonContainer, { children: [_jsx(ButtonWrapper, { children: _jsx(Button, { sizeVariant: ButtonSizeVariant.Default, colorVariant: ButtonColorVariant.Fir, onClick: onBack, label: intl.formatMessage({
                                id: 'AppIntro.NotificationSheet.BackButton',
                            }) }) }), _jsx(ButtonWrapper, { children: _jsx(Button, { sizeVariant: ButtonSizeVariant.Default, colorVariant: ButtonColorVariant.Light, onClick: onContinue, label: intl.formatMessage({
                                id: 'AppIntro.NotificationSheet.ContinueButton',
                            }) }) })] })] })));
};
export default SheetNotification;
