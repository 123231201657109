import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styled from 'styled-components/macro';
import { useIntl } from 'react-intl';
import Text, { TextVariant } from '../../../styled-components/Text/Text';
import { AvailabilityStateTag } from './AvailabilityStateTag';
import { AvailabilityStateEnum } from '../types';
const LegendWrapper = styled.div `
  display: flex;
  @media ${props => props.theme.device.desktop} {
    display: none;
  }

  gap: 8px;
  flex-wrap: wrap;
  align-items: center;
`;
const Legend = styled.div `
  display: flex;
  gap: 4px;
  align-items: center;
`;
export const CalendarLegend = () => {
    const intl = useIntl();
    return (_jsxs(LegendWrapper, { children: [_jsxs(Legend, { children: [_jsx(AvailabilityStateTag, { availabilityState: AvailabilityStateEnum.AllBlocked }), _jsx(Text, Object.assign({ variant: TextVariant.L2SB }, { children: intl.formatMessage({
                            id: 'BookingCalendar.AvailabilityTag.blocked',
                        }) }))] }), _jsxs(Legend, { children: [_jsx(AvailabilityStateTag, { availabilityState: { availableSeats: 1 } }), _jsx(Text, Object.assign({ variant: TextVariant.L2SB }, { children: intl.formatMessage({
                            id: 'BookingCalendar.AvailabilityTag.blockedPartially',
                        }) }))] })] }));
};
