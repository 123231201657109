var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import update from 'immutability-helper';
import { Controller, } from 'react-hook-form';
import { types as sdkTypes } from '@nomady/shared/utils/sdkLoader';
import { ErrorMessageWrapper } from '../Input/Input';
import InputPicturesItem from './InputPicturesItem';
import InputErrorMessage from '../InputErrorMessage/InputErrorMessage';
import InputPicturesUploadItem from './InputPicturesUploadItem';
import { useDispatch } from 'react-redux';
import { requestImageUpload } from '../../containers/EditListingPage/EditListingPageSlice';
const { UUID } = sdkTypes;
const Wrapper = styled.div `
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
`;
const ItemsWrapper = styled.div `
  display: grid;

  grid-template-columns: 1fr;
  grid-auto-rows: 1fr 1fr;
  column-gap: 12px;
  row-gap: 12px;

  @media ${props => props.theme.device.tablet} {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;
const InternalField = (_a) => {
    var { type, description, maxItemLength, field, fieldState, formState, error, onImageUploaded } = _a, props = __rest(_a, ["type", "description", "maxItemLength", "field", "fieldState", "formState", "error", "onImageUploaded"]);
    const [pictures, setPictures] = useState(field.value || []);
    const [uploadInProgressPictures, setUploadInProgressPictures] = useState([]);
    const [editingPictures, setEditingPictures] = useState([]);
    useEffect(() => {
        setPictures(field.value || []);
    }, [field.value]);
    const dispatch = useDispatch();
    const onImageUpload = (image) => __awaiter(void 0, void 0, void 0, function* () {
        return dispatch(requestImageUpload(image));
    });
    const hasError = !!error;
    const errorMessageId = hasError ? `${props.id}-errormessage` : '';
    const findPicture = (uuid) => {
        const picture = pictures.filter(element => {
            var _a;
            return ((_a = element === null || element === void 0 ? void 0 : element.id) === null || _a === void 0 ? void 0 : _a.uuid) === uuid;
        })[0];
        return {
            picture,
            index: pictures.indexOf(picture),
        };
    };
    const movePicture = (id, atIndex) => {
        const { picture, index } = findPicture(id);
        if (picture && (index || index === 0)) {
            const newOrder = update(pictures, {
                $splice: [
                    [index, 1],
                    [atIndex, 0, picture],
                ],
            });
            setPictures(newOrder);
            field.onChange(newOrder);
        }
    };
    const onClickEdit = (id) => {
        if (!editingPictures.includes(id)) {
            setEditingPictures([...editingPictures, id]);
        }
    };
    const removePictureFromEditing = (id) => setEditingPictures(editingPictures.filter(editingId => editingId !== id));
    const onDeletePicture = (id) => {
        removePictureFromEditing(id);
        const newPictures = pictures.filter(picture => picture.id.uuid !== id);
        setPictures(newPictures);
        field.onChange(newPictures);
    };
    const onUpdatePicture = (id, data) => {
        const newPictures = pictures.map(picture => {
            if (picture.id.uuid !== id) {
                return picture;
            }
            return Object.assign(Object.assign({}, picture), { attributes: Object.assign(Object.assign({}, picture === null || picture === void 0 ? void 0 : picture.attributes), { description: data.description }) });
        });
        setPictures(newPictures);
        field.onChange(newPictures);
        removePictureFromEditing(id);
    };
    return (_jsxs(Wrapper, { children: [_jsxs(ItemsWrapper, { children: [pictures.map(picture => {
                        const id = picture.id.uuid;
                        return (_jsx(InputPicturesItem, { picture: picture, findPicture: findPicture, movePicture: movePicture, onClickEdit: onClickEdit, isEditing: editingPictures.includes(id), onEndEditing: removePictureFromEditing, onDeletePicture: onDeletePicture, onUpdatePicture: onUpdatePicture, isUploadInProgress: uploadInProgressPictures.length > 0 }, id));
                    }), _jsx(InputPicturesUploadItem, { isUploadInProgress: uploadInProgressPictures.length > 0, numberOfPicturesInUploadQueue: uploadInProgressPictures.length, onChange: (files) => __awaiter(void 0, void 0, void 0, function* () {
                            const filesWithTempIds = files.map((file, index) => ({
                                id: new UUID(`(${index})_${file.name}_${Date.now()}`),
                                file,
                            }));
                            setUploadInProgressPictures(filesWithTempIds);
                            const newlyAddedPictures = [];
                            for (const file of filesWithTempIds) {
                                const newPicture = yield onImageUpload({
                                    file: file.file,
                                });
                                newlyAddedPictures.push(Object.assign(Object.assign({}, newPicture), { file: file.file }));
                            }
                            const newPictures = [...pictures, ...newlyAddedPictures];
                            field.onChange(newPictures);
                            setEditingPictures(newlyAddedPictures.map(picture => picture.id.uuid));
                            setUploadInProgressPictures([]);
                        }) })] }), hasError && (_jsx(ErrorMessageWrapper, Object.assign({ role: "alert" }, { children: _jsx(InputErrorMessage, { id: errorMessageId, error: error }) })))] }));
};
const InputPictures = (_a) => {
    var { control, rules } = _a, props = __rest(_a, ["control", "rules"]);
    return (_jsx(Controller, { render: fieldProps => _jsx(InternalField, Object.assign({}, fieldProps, props)), name: props.name, control: control, rules: rules }));
};
export default InputPictures;
