import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addListingToFavoritesList, addOrRemoveListingToListingFavoritesListsInProgressSelector, createListInProgressSelector, createListingFavoritesList, listingFavoritesListsSelector, removeListingFromFavoritesList, } from '../../../slices/listingFavoritesListsSlice';
import { getListingsById } from '../../../ducks/marketplaceData.duck';
const useFavoritesList = (listingId) => {
    const [newListName, setNewListName] = useState('');
    const dispatch = useDispatch();
    const listings = useSelector((state) => getListingsById(state, [listingId]));
    const listingFavoritesLists = useSelector(listingFavoritesListsSelector);
    const addOrRemoveinProgressListings = useSelector(addOrRemoveListingToListingFavoritesListsInProgressSelector);
    const createListingLoading = useSelector(createListInProgressSelector);
    const addOrRemoveListingFromList = (listId, listingId) => {
        const currentList = listingFavoritesLists.find(element => element.id === listId);
        const alreadyContainsListing = currentList === null || currentList === void 0 ? void 0 : currentList.listings.includes(listingId);
        if (alreadyContainsListing) {
            dispatch(removeListingFromFavoritesList(listId, listingId));
        }
        else {
            dispatch(addListingToFavoritesList(listId, listingId));
        }
    };
    const createNewList = (listingId) => {
        dispatch(createListingFavoritesList(newListName, listingId));
        setNewListName('');
    };
    if (listings.length !== 1) {
        return {
            listing: null,
            createListingLoading: false,
            enhancedLists: [],
            newListName,
            setNewListName,
            addOrRemoveListingFromList,
            createNewList,
        };
    }
    const listing = listings[0];
    const enhancedLists = listingFavoritesLists
        .map(list => {
        var _a, _b;
        return (Object.assign(Object.assign({}, list), { currentListingInList: (_a = list.listings) === null || _a === void 0 ? void 0 : _a.includes((_b = listing === null || listing === void 0 ? void 0 : listing.id) === null || _b === void 0 ? void 0 : _b.uuid) }));
    })
        .map(list => (Object.assign(Object.assign({}, list), { addOrRemoveListingInProgress: addOrRemoveinProgressListings === null || addOrRemoveinProgressListings === void 0 ? void 0 : addOrRemoveinProgressListings.includes(list.id) })))
        .sort((a, b) => Number(b.currentListingInList) - Number(a.currentListingInList));
    return {
        listing,
        createListingLoading,
        enhancedLists,
        newListName,
        setNewListName,
        addOrRemoveListingFromList,
        createNewList,
    };
};
export default useFavoritesList;
