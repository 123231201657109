import { DEFAULT_SORT_KEY, distanceSortKey, sortKeyToSharetribeSortKeyWithDirection, } from '@nomady/shared/marketplace-custom-config';
import getCampStyleApiParams from './camp-style/api/getCampStyleApiParams';
import getDatesApiParams from './dates/api/getDatesApiParams';
import getFeaturesApiParams from './features/api/getFeaturesApiParams';
import getGuestsApiParams from './guests/api/getGuestsApiParams';
import getLocationApiParams from './location/api/getLocationApiParams';
const getApiParams = (currentSearchQuery) => {
    var _a;
    const locationApiParams = getLocationApiParams(currentSearchQuery.location);
    const datesApiParams = getDatesApiParams(currentSearchQuery.dates);
    const guestsApiParams = getGuestsApiParams(currentSearchQuery);
    const campStyleApiParams = getCampStyleApiParams((_a = currentSearchQuery.features) === null || _a === void 0 ? void 0 : _a.campStyle);
    const featuresApiParams = getFeaturesApiParams(currentSearchQuery.features);
    const { sort } = currentSearchQuery;
    const defaultSort = sortKeyToSharetribeSortKeyWithDirection(DEFAULT_SORT_KEY);
    const apiParams = Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({ sort: sort !== distanceSortKey
            ? sortKeyToSharetribeSortKeyWithDirection(sort) || defaultSort
            : undefined }, locationApiParams), datesApiParams), guestsApiParams), campStyleApiParams), featuresApiParams);
    Object.keys(apiParams).forEach((key) => apiParams[key] === undefined &&
        delete apiParams[key]);
    return apiParams;
};
export default getApiParams;
