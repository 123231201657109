import { types } from '../../utils/sdkLoader';
import { LINE_ITEM_CUSTOMER_COMMISSION, LINE_ITEM_CUSTOMER_COMMISSION_DISCOUNT, LINE_ITEM_PROVIDER_COMMISSION, } from '../../utils/lineItemTypes';
const { Money } = types;
const getTotalCommission = (lineItems, currency) => {
    var _a;
    const providerCommissionLineItem = lineItems.find(lineItem => lineItem.code === LINE_ITEM_PROVIDER_COMMISSION);
    const customerCommissionLineItem = lineItems.find(lineItem => lineItem.code === LINE_ITEM_CUSTOMER_COMMISSION);
    const customerCommissionDiscountLineItem = lineItems.find(lineItem => lineItem.code === LINE_ITEM_CUSTOMER_COMMISSION_DISCOUNT);
    return new Money(-1 * (providerCommissionLineItem === null || providerCommissionLineItem === void 0 ? void 0 : providerCommissionLineItem.lineTotal.amount) +
        (customerCommissionLineItem === null || customerCommissionLineItem === void 0 ? void 0 : customerCommissionLineItem.lineTotal.amount) +
        (((_a = customerCommissionDiscountLineItem === null || customerCommissionDiscountLineItem === void 0 ? void 0 : customerCommissionDiscountLineItem.lineTotal) === null || _a === void 0 ? void 0 : _a.amount) || 0), currency);
};
export default getTotalCommission;
