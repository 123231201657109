import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styled from 'styled-components/macro';
import { useIntl } from 'react-intl';
import SteppedNumberInput, { SteppedNumberInputVariant, } from '../SteppedNumberInput/SteppedNumberInput';
import Text, { TextVariant } from '../../styled-components/Text/Text';
import { ColorVariant } from '../../theme';
const ContentWrap = styled.div `
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
`;
const Section = styled.div `
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
const NumberInputWrapper = styled.div `
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 16px;
  row-gap: 8px;
  grid-auto-flow: row;
`;
const LabelLine = styled.div `
  display: flex;
  gap: 2px;
  flex-wrap: wrap;
`;
const NumberOfGuestsSelection = ({ seats, adults, teens, children_, infants, dogs, onValueChangeSeats, onValueChangeAdults, onValueChangeTeens, onValueChangeChildren, onValueChangeInfants, onValueChangeDogs, minSeats, maxSeats, minAdults, maxAdults, minTeens, maxTeens, minChildren, maxChildren, minInfants, maxInfants, minDogs, maxDogs, }) => {
    const intl = useIntl();
    return (_jsxs(ContentWrap, { children: [seats !== undefined && onValueChangeSeats && (_jsxs(Section, { children: [_jsx(Text, Object.assign({ variant: TextVariant.L1SB, as: "p", colorVariant: ColorVariant.BasicCaveBlack }, { children: intl.formatMessage({ id: 'BookingGuestsSelection.seatsTitle' }) })), _jsx(NumberInputWrapper, { children: _jsx(SteppedNumberInput, { name: "seats", min: minSeats, max: maxSeats, value: seats, onValueChange: onValueChangeSeats, variant: SteppedNumberInputVariant.White }) })] })), _jsxs(Section, { children: [_jsx(Text, Object.assign({ variant: TextVariant.L1SB, as: "p", colorVariant: ColorVariant.BasicCaveBlack }, { children: intl.formatMessage({ id: 'BookingGuestsSelection.guestsTitle' }) })), _jsxs(NumberInputWrapper, { children: [_jsx(SteppedNumberInput, { customLabel: _jsxs(LabelLine, { children: [_jsx(Text, Object.assign({ variant: TextVariant.L4SB, colorVariant: ColorVariant.FunctionGranitGrey }, { children: intl.formatMessage({ id: 'SearchBarSheet.Guests.adults' }) })), _jsx(Text, Object.assign({ variant: TextVariant.L4R, colorVariant: ColorVariant.FunctionStoneGreyInactive }, { children: intl.formatMessage({
                                                id: 'SearchBarSheet.Guests.adultsAge',
                                            }) }))] }), name: "adults", min: minAdults, max: maxAdults, value: adults, onValueChange: onValueChangeAdults, variant: SteppedNumberInputVariant.White }), _jsx(SteppedNumberInput, { name: "teens", customLabel: _jsxs(LabelLine, { children: [_jsx(Text, Object.assign({ variant: TextVariant.L4SB, colorVariant: ColorVariant.FunctionGranitGrey }, { children: intl.formatMessage({ id: 'SearchBarSheet.Guests.teens' }) })), _jsx(Text, Object.assign({ variant: TextVariant.L4R, colorVariant: ColorVariant.FunctionStoneGreyInactive }, { children: intl.formatMessage({
                                                id: 'SearchBarSheet.Guests.teensAge',
                                            }) }))] }), min: minTeens, max: maxTeens, value: teens, onValueChange: onValueChangeTeens, variant: SteppedNumberInputVariant.White }), _jsx(SteppedNumberInput, { name: "children", customLabel: _jsxs(LabelLine, { children: [_jsx(Text, Object.assign({ variant: TextVariant.L4SB, colorVariant: ColorVariant.FunctionGranitGrey }, { children: intl.formatMessage({ id: 'SearchBarSheet.Guests.children' }) })), _jsx(Text, Object.assign({ variant: TextVariant.L4SB, colorVariant: ColorVariant.FunctionStoneGreyInactive }, { children: intl.formatMessage({
                                                id: 'SearchBarSheet.Guests.childrenAge',
                                            }) }))] }), min: minChildren, max: maxChildren, value: children_, onValueChange: onValueChangeChildren, variant: SteppedNumberInputVariant.White }), _jsx(SteppedNumberInput, { name: "infants", customLabel: _jsxs(LabelLine, { children: [_jsx(Text, Object.assign({ variant: TextVariant.L4SB, colorVariant: ColorVariant.FunctionGranitGrey }, { children: intl.formatMessage({ id: 'SearchBarSheet.Guests.infants' }) })), _jsx(Text, Object.assign({ variant: TextVariant.L4R, colorVariant: ColorVariant.FunctionStoneGreyInactive }, { children: intl.formatMessage({
                                                id: 'SearchBarSheet.Guests.infantsAge',
                                            }) })), _jsx(Text, Object.assign({ variant: TextVariant.L4SB, colorVariant: ColorVariant.FunctionStoneGreyInactive }, { children: intl.formatMessage({
                                                id: 'SearchBarSheet.Guests.stayFree',
                                            }) }))] }), min: minInfants, max: maxInfants, value: infants, onValueChange: onValueChangeInfants, variant: SteppedNumberInputVariant.White }), dogs !== undefined && onValueChangeDogs && (_jsx(SteppedNumberInput, { name: "dogs", customLabel: _jsxs(LabelLine, { children: [_jsx(Text, Object.assign({ variant: TextVariant.L4SB, colorVariant: ColorVariant.FunctionGranitGrey }, { children: intl.formatMessage({ id: 'SearchBarSheet.Guests.dogs' }) })), _jsx(Text, Object.assign({ variant: TextVariant.L4SB, colorVariant: ColorVariant.FunctionStoneGreyInactive }, { children: intl.formatMessage({
                                                id: 'SearchBarSheet.Guests.stayFree',
                                            }) }))] }), min: minDogs, max: maxDogs, value: dogs, onValueChange: onValueChangeDogs, variant: SteppedNumberInputVariant.White }))] })] })] }));
};
export default NumberOfGuestsSelection;
