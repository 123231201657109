var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import styled, { css } from 'styled-components/macro';
import routeConfiguration from '@nomady/shared/routeConfiguration/routeConfiguration';
import Text, { TextVariant } from '../Text/Text';
import Icon, { IconSizeVariant } from '../Icon/Icon';
import { ColorVariant } from '../../theme';
import { NamedLink } from '../../components';
export var ButtonVariant;
(function (ButtonVariant) {
    ButtonVariant["Primary"] = "Primary";
    ButtonVariant["Secondary"] = "Secondary";
    ButtonVariant["Tertiary"] = "Tertiary";
    ButtonVariant["Outlined"] = "Outlined";
    ButtonVariant["Link"] = "Link";
})(ButtonVariant || (ButtonVariant = {}));
export var ButtonSizeVariant;
(function (ButtonSizeVariant) {
    ButtonSizeVariant["Large"] = "Large";
    ButtonSizeVariant["Medium"] = "Medium";
    ButtonSizeVariant["Small"] = "Small";
})(ButtonSizeVariant || (ButtonSizeVariant = {}));
const Wrapper = styled.button `
  width: fit-content;
  display: flex;
  align-items: center;
  border-radius: 999px;
  border: none;
  cursor: pointer;

  ${props => {
    let sizeStyles;
    if (props.sizeVariant === ButtonSizeVariant.Small) {
        sizeStyles = css `
        padding: 4px 12px;
        gap: 6px;
      `;
    }
    else if (props.sizeVariant === ButtonSizeVariant.Medium) {
        sizeStyles = css `
        padding: 8px 16px;
        gap: 6px;
      `;
    }
    else if (props.sizeVariant === ButtonSizeVariant.Large) {
        sizeStyles = css `
        padding: 12px 20px;
        gap: 8px;
      `;
    }
    else {
        sizeStyles = null;
    }
    return sizeStyles;
}}

  background: ${props => props.theme.color[props.buttonColorObject.default.backgroundColor]};
  box-shadow: ${props => `${props.buttonColorObject.default.glow
    ? `0px 0px 5px -1px ${props.theme.color.functionElectricVioletActive},`
    : ''}${props.buttonColorObject.default.outline
    ? `inset 0 0 0 ${props.buttonColorObject.default.outline.width}
        ${props.theme.color[props.buttonColorObject.default.outline.color]}`
    : ''}`};
  &:hover {
    background: ${props => props.theme.color[props.buttonColorObject.hover.backgroundColor]};
    box-shadow: ${props => `${props.buttonColorObject.hover.glow
    ? `0px 0px 5px -1px ${props.theme.color.functionElectricVioletActive},`
    : ''}${props.buttonColorObject.hover.outline
    ? `inset 0 0 0 ${props.buttonColorObject.hover.outline.width}
        ${props.theme.color[props.buttonColorObject.hover.outline.color]}`
    : ''}`};
  }
  &:active {
    background: ${props => props.theme.color[props.buttonColorObject.pressed.backgroundColor]};
    box-shadow: ${props => `${props.buttonColorObject.pressed.glow
    ? `0px 0px 5px -1px ${props.theme.color.functionElectricVioletActive},`
    : ''}${props.buttonColorObject.pressed.outline
    ? `inset 0 0 0 ${props.buttonColorObject.pressed.outline.width}
        ${props.theme.color[props.buttonColorObject.pressed.outline.color]}`
    : ''}`};
  }
  &:focus {
    background: ${props => props.theme.color[props.buttonColorObject.focused.backgroundColor]};
    box-shadow: ${props => `${props.buttonColorObject.focused.glow
    ? `0px 0px 5px -1px ${props.theme.color.functionElectricVioletActive},`
    : ''}${props.buttonColorObject.focused.outline
    ? `inset 0 0 0 ${props.buttonColorObject.focused.outline.width}
        ${props.theme.color[props.buttonColorObject.focused.outline.color]}`
    : ''}`};
  }
  &:disabled {
    background: ${props => props.theme.color[props.buttonColorObject.disabled.backgroundColor]};
    box-shadow: ${props => `${props.buttonColorObject.disabled.glow
    ? `0px 0px 5px -1px ${props.theme.color.functionElectricVioletActive},`
    : ''}${props.buttonColorObject.disabled.outline
    ? `inset 0 0 0 ${props.buttonColorObject.disabled.outline.width}
        ${props.theme.color[props.buttonColorObject.disabled.outline.color]}`
    : ''}`};
  }
`;
const Content = styled(Text) `
  white-space: nowrap;
  text-overflow: ellipsis;
  ${props => props.underlineLabel &&
    css `
      text-decoration: underline;
    `}
`;
const IconWrapper = styled.div ``;
const getIconSize = (sizeVariant) => {
    switch (sizeVariant) {
        case ButtonSizeVariant.Large:
        case ButtonSizeVariant.Medium:
            return IconSizeVariant.Size20px;
        case ButtonSizeVariant.Small:
            return IconSizeVariant.Tiny;
        default:
            return IconSizeVariant.Size20px;
    }
};
const generateCompositeButtonColorObjectKey = (variant, dark, destructive) => {
    return `${variant}_${dark ? 'dark' : 'light'}_${destructive ? 'destructive' : 'normal'}`;
};
const colorMapping = {
    Primary_light_normal: {
        default: {
            backgroundColor: ColorVariant.FunctionSuccess3,
            contentColor: ColorVariant.BasicPearlWhiteTypeWhite,
        },
        hover: {
            backgroundColor: ColorVariant.FunctionSuccess2,
            contentColor: ColorVariant.BasicPearlWhiteTypeWhite,
        },
        pressed: {
            backgroundColor: ColorVariant.FunctionSuccess2,
            contentColor: ColorVariant.BasicPearlWhiteTypeWhite,
            outline: {
                color: ColorVariant.FunctionSuccess3,
                width: '1px',
            },
        },
        focused: {
            backgroundColor: ColorVariant.FunctionSuccess3,
            contentColor: ColorVariant.BasicSnowGrey,
            outline: {
                color: ColorVariant.BasicPearlWhiteTypeWhite,
                width: '2px',
            },
            glow: true,
        },
        disabled: {
            backgroundColor: ColorVariant.FunctionStoneGreyInactive,
            contentColor: ColorVariant.FunctionFogGreyInactive,
        },
    },
    Secondary_light_normal: {
        default: {
            backgroundColor: ColorVariant.PrimaryFirGreen,
            contentColor: ColorVariant.BasicPearlWhiteTypeWhite,
        },
        hover: {
            backgroundColor: ColorVariant.PrimaryFirGreen,
            contentColor: ColorVariant.BasicPearlWhiteTypeWhite,
        },
        pressed: {
            backgroundColor: ColorVariant.PrimaryFirGreen,
            contentColor: ColorVariant.BasicPearlWhiteTypeWhite,
            outline: {
                color: ColorVariant.PrimaryMossGreen,
                width: '1px',
            },
        },
        focused: {
            backgroundColor: ColorVariant.PrimaryFirGreen,
            contentColor: ColorVariant.BasicSnowGrey,
            outline: {
                color: ColorVariant.FunctionElectricVioletActive,
                width: '2px',
            },
            glow: true,
        },
        disabled: {
            backgroundColor: ColorVariant.FunctionStoneGreyInactive,
            contentColor: ColorVariant.FunctionFogGreyInactive,
        },
    },
    Tertiary_light_normal: {
        default: {
            backgroundColor: ColorVariant.BasicPearlWhiteTypeWhite,
            contentColor: ColorVariant.BasicRavenBlack,
        },
        hover: {
            backgroundColor: ColorVariant.BasicPearlWhiteTypeWhite,
            contentColor: ColorVariant.BasicRavenBlack,
            outline: {
                color: ColorVariant.FunctionStoneGreyInactive,
                width: '2px',
            },
        },
        pressed: {
            backgroundColor: ColorVariant.BasicPearlWhiteTypeWhite,
            contentColor: ColorVariant.BasicRavenBlack,
            outline: {
                color: ColorVariant.FunctionGranitGrey,
                width: '2px',
            },
        },
        focused: {
            backgroundColor: ColorVariant.BasicPearlWhiteTypeWhite,
            contentColor: ColorVariant.BasicRavenBlack,
            outline: {
                color: ColorVariant.FunctionElectricVioletActive,
                width: '2px',
            },
        },
        disabled: {
            backgroundColor: ColorVariant.FunctionStoneGreyInactive,
            contentColor: ColorVariant.FunctionFogGreyInactive,
        },
    },
    Outlined_light_normal: {
        default: {
            backgroundColor: ColorVariant.Transparent,
            contentColor: ColorVariant.BasicRavenBlack,
            outline: {
                color: ColorVariant.BasicRavenBlack,
                width: '2px',
            },
        },
        hover: {
            backgroundColor: ColorVariant.Transparent,
            contentColor: ColorVariant.PrimaryFirGreen,
            outline: {
                color: ColorVariant.FunctionStoneGreyInactive,
                width: '2px',
            },
        },
        pressed: {
            backgroundColor: ColorVariant.Transparent,
            contentColor: ColorVariant.PrimaryFirGreen,
            outline: {
                color: ColorVariant.FunctionStoneGreyInactive,
                width: '2px',
            },
        },
        focused: {
            backgroundColor: ColorVariant.Transparent,
            contentColor: ColorVariant.PrimaryFirGreen,
            outline: {
                color: ColorVariant.BasicRavenBlack,
                width: '2px',
            },
            glow: true,
        },
        disabled: {
            backgroundColor: ColorVariant.Transparent,
            contentColor: ColorVariant.PrimaryOstseeBeach,
            outline: {
                color: ColorVariant.PrimaryOstseeBeach,
                width: '2px',
            },
        },
    },
    Link_light_normal: {
        default: {
            backgroundColor: ColorVariant.Transparent,
            contentColor: ColorVariant.BasicRavenBlack,
        },
        hover: {
            backgroundColor: ColorVariant.Transparent,
            contentColor: ColorVariant.FunctionStoneGreyInactive,
        },
        pressed: {
            backgroundColor: ColorVariant.Transparent,
            contentColor: ColorVariant.FunctionStoneGreyInactive,
        },
        focused: {
            backgroundColor: ColorVariant.Transparent,
            contentColor: ColorVariant.BasicRavenBlack,
        },
        disabled: {
            backgroundColor: ColorVariant.Transparent,
            contentColor: ColorVariant.FunctionFogGreyInactive,
        },
    },
    Primary_light_destructive: {
        default: {
            backgroundColor: ColorVariant.FunctionError3,
            contentColor: ColorVariant.BasicPearlWhiteTypeWhite,
        },
        hover: {
            backgroundColor: ColorVariant.FunctionError1,
            contentColor: ColorVariant.BasicPearlWhiteTypeWhite,
        },
        pressed: {
            backgroundColor: ColorVariant.FunctionError1,
            contentColor: ColorVariant.BasicPearlWhiteTypeWhite,
            outline: {
                color: ColorVariant.FunctionError3,
                width: '1px',
            },
        },
        focused: {
            backgroundColor: ColorVariant.FunctionError3,
            contentColor: ColorVariant.BasicPearlWhiteTypeWhite,
            outline: {
                color: ColorVariant.FunctionElectricVioletActive,
                width: '2px',
            },
            glow: true,
        },
        disabled: {
            backgroundColor: ColorVariant.FunctionStoneGreyInactive,
            contentColor: ColorVariant.FunctionFogGreyInactive,
        },
    },
    Outlined_light_destructive: {
        default: {
            backgroundColor: ColorVariant.Transparent,
            contentColor: ColorVariant.FunctionError3,
            outline: {
                color: ColorVariant.FunctionError3,
                width: '2px',
            },
        },
        hover: {
            backgroundColor: ColorVariant.Transparent,
            contentColor: ColorVariant.FunctionError3,
            outline: {
                color: ColorVariant.FunctionError2,
                width: '2px',
            },
        },
        pressed: {
            backgroundColor: ColorVariant.Transparent,
            contentColor: ColorVariant.FunctionError2,
            outline: {
                color: ColorVariant.FunctionError2,
                width: '2px',
            },
        },
        focused: {
            backgroundColor: ColorVariant.Transparent,
            contentColor: ColorVariant.FunctionElectricVioletActive,
            outline: {
                color: ColorVariant.FunctionElectricVioletActive,
                width: '2px',
            },
            glow: true,
        },
        disabled: {
            backgroundColor: ColorVariant.Transparent,
            contentColor: ColorVariant.FunctionFogGreyInactive,
            outline: {
                color: ColorVariant.FunctionFogGreyInactive,
                width: '2px',
            },
        },
    },
    Primary_dark_normal: {
        default: {
            backgroundColor: ColorVariant.FunctionSuccess3,
            contentColor: ColorVariant.BasicPearlWhiteTypeWhite,
        },
        hover: {
            backgroundColor: ColorVariant.FunctionSuccess2,
            contentColor: ColorVariant.BasicPearlWhiteTypeWhite,
        },
        pressed: {
            backgroundColor: ColorVariant.FunctionSuccess2,
            contentColor: ColorVariant.BasicPearlWhiteTypeWhite,
            outline: {
                color: ColorVariant.FunctionSuccess3,
                width: '1px',
            },
        },
        focused: {
            backgroundColor: ColorVariant.FunctionSuccess3,
            contentColor: ColorVariant.BasicSnowGrey,
            outline: {
                color: ColorVariant.BasicPearlWhiteTypeWhite,
                width: '2px',
            },
            glow: true,
        },
        disabled: {
            backgroundColor: ColorVariant.FunctionColeGrey,
            contentColor: ColorVariant.FunctionGranitGrey,
        },
    },
    Secondary_dark_normal: {
        default: {
            backgroundColor: ColorVariant.BasicPearlWhiteTypeWhite,
            contentColor: ColorVariant.BasicCaveBlack,
        },
        hover: {
            backgroundColor: ColorVariant.BasicPearlWhiteTypeWhite,
            contentColor: ColorVariant.BasicCaveBlack,
        },
        pressed: {
            backgroundColor: ColorVariant.BasicPearlWhiteTypeWhite,
            contentColor: ColorVariant.BasicCaveBlack,
            outline: {
                color: ColorVariant.PrimaryMossGreen,
                width: '1px',
            },
        },
        focused: {
            backgroundColor: ColorVariant.BasicPearlWhiteTypeWhite,
            contentColor: ColorVariant.BasicCaveBlack,
            outline: {
                color: ColorVariant.FunctionElectricVioletActive,
                width: '2px',
            },
            glow: true,
        },
        disabled: {
            backgroundColor: ColorVariant.FunctionColeGrey,
            contentColor: ColorVariant.FunctionGranitGrey,
        },
    },
    Tertiary_dark_normal: {
        default: {
            backgroundColor: ColorVariant.FunctionColeGrey,
            contentColor: ColorVariant.BasicPearlWhiteTypeWhite,
        },
        hover: {
            backgroundColor: ColorVariant.FunctionColeGrey,
            contentColor: ColorVariant.BasicPearlWhiteTypeWhite,
            outline: {
                color: ColorVariant.FunctionStoneGreyInactive,
                width: '2px',
            },
        },
        pressed: {
            backgroundColor: ColorVariant.FunctionColeGrey,
            contentColor: ColorVariant.BasicPearlWhiteTypeWhite,
            outline: {
                color: ColorVariant.FunctionGranitGrey,
                width: '2px',
            },
        },
        focused: {
            backgroundColor: ColorVariant.FunctionColeGrey,
            contentColor: ColorVariant.BasicPearlWhiteTypeWhite,
            outline: {
                color: ColorVariant.FunctionElectricVioletActive,
                width: '2px',
            },
            glow: true,
        },
        disabled: {
            backgroundColor: ColorVariant.FunctionColeGrey,
            contentColor: ColorVariant.FunctionGranitGrey,
        },
    },
    Outlined_dark_normal: {
        default: {
            backgroundColor: ColorVariant.Transparent,
            contentColor: ColorVariant.BasicPearlWhiteTypeWhite,
            outline: {
                color: ColorVariant.BasicPearlWhiteTypeWhite,
                width: '2px',
            },
        },
        hover: {
            backgroundColor: ColorVariant.Transparent,
            contentColor: ColorVariant.BasicPearlWhiteTypeWhite,
            outline: {
                color: ColorVariant.BasicPearlWhiteTypeWhite,
                width: '2px',
            },
        },
        pressed: {
            backgroundColor: ColorVariant.Transparent,
            contentColor: ColorVariant.BasicPearlWhiteTypeWhite,
            outline: {
                color: ColorVariant.BasicPearlWhiteTypeWhite,
                width: '2px',
            },
        },
        focused: {
            backgroundColor: ColorVariant.Transparent,
            contentColor: ColorVariant.BasicPearlWhiteTypeWhite,
            outline: {
                color: ColorVariant.FunctionElectricVioletActive,
                width: '2px',
            },
            glow: true,
        },
        disabled: {
            backgroundColor: ColorVariant.Transparent,
            contentColor: ColorVariant.PrimaryOstseeBeach,
            outline: {
                color: ColorVariant.FunctionColeGrey,
                width: '1px',
            },
        },
    },
    Link_dark_normal: {
        default: {
            backgroundColor: ColorVariant.Transparent,
            contentColor: ColorVariant.BasicPearlWhiteTypeWhite,
        },
        hover: {
            backgroundColor: ColorVariant.Transparent,
            contentColor: ColorVariant.FunctionStoneGreyInactive,
        },
        pressed: {
            backgroundColor: ColorVariant.Transparent,
            contentColor: ColorVariant.FunctionStoneGreyInactive,
        },
        focused: {
            backgroundColor: ColorVariant.Transparent,
            contentColor: ColorVariant.BasicPearlWhiteTypeWhite,
        },
        disabled: {
            backgroundColor: ColorVariant.Transparent,
            contentColor: ColorVariant.FunctionColeGrey,
        },
    },
    Primary_dark_destructive: {
        default: {
            backgroundColor: ColorVariant.FunctionError3,
            contentColor: ColorVariant.BasicPearlWhiteTypeWhite,
        },
        hover: {
            backgroundColor: ColorVariant.FunctionError1,
            contentColor: ColorVariant.BasicPearlWhiteTypeWhite,
        },
        pressed: {
            backgroundColor: ColorVariant.FunctionError1,
            contentColor: ColorVariant.BasicPearlWhiteTypeWhite,
            outline: {
                color: ColorVariant.FunctionError3,
                width: '1px',
            },
        },
        focused: {
            backgroundColor: ColorVariant.FunctionError3,
            contentColor: ColorVariant.BasicPearlWhiteTypeWhite,
            outline: {
                color: ColorVariant.FunctionElectricVioletActive,
                width: '2px',
            },
            glow: true,
        },
        disabled: {
            backgroundColor: ColorVariant.FunctionColeGrey,
            contentColor: ColorVariant.FunctionGranitGrey,
        },
    },
    Outlined_dark_destructive: {
        default: {
            backgroundColor: ColorVariant.Transparent,
            contentColor: ColorVariant.FunctionError3,
            outline: {
                color: ColorVariant.FunctionError3,
                width: '2px',
            },
        },
        hover: {
            backgroundColor: ColorVariant.Transparent,
            contentColor: ColorVariant.FunctionError3,
            outline: {
                color: ColorVariant.FunctionError2,
                width: '2px',
            },
        },
        pressed: {
            backgroundColor: ColorVariant.Transparent,
            contentColor: ColorVariant.FunctionError2,
            outline: {
                color: ColorVariant.FunctionError2,
                width: '2px',
            },
        },
        focused: {
            backgroundColor: ColorVariant.Transparent,
            contentColor: ColorVariant.FunctionElectricVioletActive,
            outline: {
                color: ColorVariant.FunctionElectricVioletActive,
                width: '2px',
            },
            glow: true,
        },
        disabled: {
            backgroundColor: ColorVariant.Transparent,
            contentColor: ColorVariant.FunctionColeGrey,
            outline: {
                color: ColorVariant.FunctionColeGrey,
                width: '2px',
            },
        },
    },
    Link_dark_destructive: null,
    Link_light_destructive: null,
    Secondary_dark_destructive: null,
    Secondary_light_destructive: null,
    Tertiary_dark_destructive: null,
    Tertiary_light_destructive: null,
};
const Button2024 = React.forwardRef((_a, forwardedRef) => {
    var { target, targetRoute, targetRouteParams, searchParams, externalLink, variant = ButtonVariant.Primary, dark = false, destructive = false, sizeVariant = ButtonSizeVariant.Medium, disabled = false, iconOnLeft, onClickIconOnLeft, iconOnRight, onClickIconOnRight, label, dataTestId, children } = _a, buttonAttributes = __rest(_a, ["target", "targetRoute", "targetRouteParams", "searchParams", "externalLink", "variant", "dark", "destructive", "sizeVariant", "disabled", "iconOnLeft", "onClickIconOnLeft", "iconOnRight", "onClickIconOnRight", "label", "dataTestId", "children"]);
    const [isHovered, setIsHovered] = useState(false);
    const buttonColorObject = colorMapping[generateCompositeButtonColorObjectKey(variant, dark, destructive)];
    const content = label ? _jsx(_Fragment, { children: label }) : children;
    if (!buttonColorObject) {
        throw new Error('Button color object not available, create it or rather change your inputs for variant, dark and destructive');
    }
    const getButtonContentColor = () => {
        if (disabled) {
            return buttonColorObject.disabled.contentColor;
        }
        else if (isHovered) {
            return buttonColorObject.hover.contentColor;
        }
        else {
            return buttonColorObject.default.contentColor;
        }
    };
    const buttonContentColor = getButtonContentColor();
    const iconSizeVariant = getIconSize(sizeVariant);
    const { style } = buttonAttributes, relevantButtonAttributes = __rest(buttonAttributes, ["style"]);
    const inner = (_jsxs(Wrapper, Object.assign({ ref: forwardedRef }, relevantButtonAttributes, { buttonColorObject: buttonColorObject, sizeVariant: sizeVariant, disabled: disabled, "data-test-id": dataTestId, "data-testid": dataTestId, onMouseEnter: () => setIsHovered(true), onMouseLeave: () => setIsHovered(false) }, { children: [iconOnLeft && (_jsx(IconWrapper, Object.assign({ onClick: onClickIconOnLeft
                    ? e => {
                        e.stopPropagation();
                        onClickIconOnLeft();
                    }
                    : undefined }, { children: _jsx(Icon, { variant: iconOnLeft, colorVariant: buttonContentColor, sizeVariant: iconSizeVariant }) }))), content && (_jsx(Content, Object.assign({ underlineLabel: variant === ButtonVariant.Link, variant: sizeVariant === ButtonSizeVariant.Large
                    ? TextVariant.L1R
                    : TextVariant.L2R, colorVariant: buttonContentColor }, { children: content }))), iconOnRight && (_jsx(IconWrapper, Object.assign({ onClick: onClickIconOnRight
                    ? e => {
                        e.stopPropagation();
                        onClickIconOnRight();
                    }
                    : undefined }, { children: _jsx(Icon, { variant: iconOnRight, colorVariant: buttonContentColor, sizeVariant: iconSizeVariant }) })))] })));
    if (targetRoute && routeConfiguration) {
        return (_jsx(NamedLink, Object.assign({ name: targetRoute, params: targetRouteParams, searchParams: searchParams }, { children: inner })));
    }
    if (externalLink) {
        return (_jsx("a", Object.assign({ href: externalLink, target: target }, { children: inner })));
    }
    return inner;
});
Button2024.displayName = 'Button2024';
export default Button2024;
