import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useRef } from 'react';
import styled from 'styled-components/macro';
import { ColorVariant } from '../../theme';
import Button, { ButtonColorVariant, ButtonSizeVariant, } from '../Button/Button';
import { IconVariant } from '../Icon/Icon';
import ListingSearchBar from '../ListingSearchBar/ListingSearchBar';
import TopBar from '../TopBar/TopBar';
import { PageLayoutTop } from '../PageLayout/PageLayout';
const Wrapper = styled.section `
  display: flex;
  justify-content: center;

  width: 100%;
  background-size: cover;
  background-position: center center;
  background-color: ${props => props.theme.color.fir};
  ${props => {
    var _a, _b;
    return `background-image: url(${((_b = (_a = props.backgroundImage.data.attributes.formats) === null || _a === void 0 ? void 0 : _a.large) === null || _b === void 0 ? void 0 : _b.url) ||
        props.backgroundImage.data.attributes.url});`;
}}

  min-height: 600px;

  @media ${props => props.theme.device.desktopL} {
    ${props => `background-image: url(${props.backgroundImage.data.attributes.url})`};
  }
`;
const Content = styled.div `
  margin-top: 80px;
  margin-bottom: 50px;
  grid-column-start: contentStart;
  grid-column-end: contentEnd;

  display: flex;
  flex-direction: column;
  min-width: 0;
  justify-content: space-between;
  align-items: center;

  padding-left: 16px;
  padding-right: 16px;

  @media ${props => props.theme.device.tablet} {
    margin-top: 100px;
  }

  @media ${props => props.theme.device.desktop} {
    margin-top: 100px;
    margin-bottom: 40px;
  }
`;
const Top = styled.div `
  margin-top: 15px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Title = styled.h1 `
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  margin-top: 40px;
  margin-bottom: 60px;
  max-width: 610px;
  text-align: center;

  ${props => props.theme.typeset.H3}
  color: ${props => props.theme.color.pearl};
`;
const HeroListingSearch = ({ primaryImage, title, ctaText, ctaTarget, scrollingPosition, showSearchBar = true, }) => {
    var _a;
    const hero = useRef(null);
    const topBarThreshhold = (((_a = hero.current) === null || _a === void 0 ? void 0 : _a.clientHeight) || 0) - 50;
    const showListingSearchBarInTopBar = topBarThreshhold < scrollingPosition;
    const logoColorVariant = showListingSearchBarInTopBar
        ? ColorVariant.Tar
        : ColorVariant.Pearl;
    return (_jsxs(Wrapper, Object.assign({ backgroundImage: primaryImage, ref: hero }, { children: [_jsx(PageLayoutTop, { children: _jsx(TopBar, { hideListingSearchBar: !showListingSearchBarInTopBar || !showSearchBar, transparent: !showListingSearchBarInTopBar, logoColorVariant: logoColorVariant }) }), _jsxs(Content, { children: [_jsxs(Top, { children: [!showListingSearchBarInTopBar && showSearchBar && (_jsx(ListingSearchBar, { dataTestId: "HeroListingSearch_ListingSearchBar", integratedInTopBar: false })), _jsx(Title, { children: title })] }), ctaText && (_jsx(Button, { targetRoute: ctaTarget, label: ctaText, colorVariant: ButtonColorVariant.Light, sizeVariant: ButtonSizeVariant.Small, icon: IconVariant.Tent }))] })] })));
};
export default HeroListingSearch;
