import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components/macro';
import { ColorVariant } from '../../theme';
import Icon, { IconVariant } from '../Icon/Icon';
import Text, { TextVariant } from '../Text/Text';
import Toggle from '../Toggle/Toggle';
const ToggleWrap = styled.div `
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding-left: 15px;

  @media ${props => props.theme.device.tablet} {
    align-items: center;
    margin-left: initial;
  }
`;
const InnerToggleWrap = styled.div `
  display: flex;
  flex-direction: column;
  @media ${props => props.theme.device.tablet} {
    flex-direction: row;
    align-items: center;
  }
`;
const ToggleAction = styled.div `
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 15px;
`;
const AutomaticTranslationLabel = styled.div `
  margin-left: -20px;
  margin-top: 10px;
  display: flex;
  align-items: flex-start;

  @media ${props => props.theme.device.tablet} {
    margin-left: 15px;
    margin-top: initial;
  }
`;
const AutomaticTranslationToggle = ({ onToggle, checked }) => {
    const intl = useIntl();
    return (_jsxs(ToggleWrap, { children: [checked ? (_jsx(Icon, { variant: IconVariant.TranslationActive })) : (_jsx(Icon, { variant: IconVariant.TranslationInactive })), _jsxs(InnerToggleWrap, { children: [_jsx(ToggleAction, { children: _jsx(Toggle, { checked: checked, onClick: onToggle }) }), _jsx(AutomaticTranslationLabel, { children: _jsx(Text, Object.assign({ variant: TextVariant.H12B, colorVariant: ColorVariant.Tar }, { children: intl.formatMessage({
                                id: 'LocaleSwitch.automaticTranslation',
                            }) })) })] })] }));
};
export default AutomaticTranslationToggle;
