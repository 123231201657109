import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components/macro';
import Icon, { IconSizeVariant, IconVariant, } from '../../../styled-components/Icon/Icon';
import Text, { TextVariant } from '../../../styled-components/Text/Text';
import { ColorVariant } from '../../../theme';
const InstantBookingBubble = styled.div `
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px 12px;
  gap: 4px;
  height: 22px;
  border-radius: 5px;
  width: max-content;
  margin-bottom: 15px;

  ${props => `color: ${props.theme.color.pearl};
  background-color: ${props.theme.color.cave};`}
`;
const Title = styled.div `
  margin: 20px 0;
`;
const InstantBookingContent = () => {
    const intl = useIntl();
    return (_jsxs(_Fragment, { children: [_jsxs(InstantBookingBubble, { children: [_jsx(Icon, { sizeVariant: IconSizeVariant.ExtraExtraSmall, variant: IconVariant.Flash, colorVariant: ColorVariant.Pearl }), _jsx(Text, Object.assign({ variant: TextVariant.H13, colorVariant: ColorVariant.Pearl }, { children: intl.formatMessage({
                            id: 'ListingCard.instantBooking',
                        }) }))] }), _jsx(Title, { children: _jsx(Text, Object.assign({ variant: TextVariant.H20 }, { children: intl.formatMessage({ id: 'ListingPage.instantBooking' }) })) }), _jsx(Text, Object.assign({ variant: TextVariant.B20 }, { children: intl.formatMessage({ id: 'ListingPage.instantBookingDescription' }) }))] }));
};
export default InstantBookingContent;
