import { WinterActivity } from '@nomady/shared/types';
const getWinterActivitesSearchParam = (activitiesWinterSearchQuery) => {
    const values = [];
    if (activitiesWinterSearchQuery === null || activitiesWinterSearchQuery === void 0 ? void 0 : activitiesWinterSearchQuery.skiing) {
        values.push(WinterActivity.skiing);
    }
    if (activitiesWinterSearchQuery === null || activitiesWinterSearchQuery === void 0 ? void 0 : activitiesWinterSearchQuery.langlauf) {
        values.push(WinterActivity.langlauf);
    }
    if (activitiesWinterSearchQuery === null || activitiesWinterSearchQuery === void 0 ? void 0 : activitiesWinterSearchQuery.skiTouring) {
        values.push(WinterActivity.skiTouring);
    }
    if (activitiesWinterSearchQuery === null || activitiesWinterSearchQuery === void 0 ? void 0 : activitiesWinterSearchQuery.snowHiking) {
        values.push(WinterActivity.snowShoeHiking);
    }
    if (activitiesWinterSearchQuery === null || activitiesWinterSearchQuery === void 0 ? void 0 : activitiesWinterSearchQuery.sledding) {
        values.push(WinterActivity.sledding);
    }
    if (activitiesWinterSearchQuery === null || activitiesWinterSearchQuery === void 0 ? void 0 : activitiesWinterSearchQuery.buildingIgloos) {
        values.push(WinterActivity.iglooBuilding);
    }
    if (activitiesWinterSearchQuery === null || activitiesWinterSearchQuery === void 0 ? void 0 : activitiesWinterSearchQuery.iceRink) {
        values.push(WinterActivity.iceRink);
    }
    if (activitiesWinterSearchQuery === null || activitiesWinterSearchQuery === void 0 ? void 0 : activitiesWinterSearchQuery.hockey) {
        values.push(WinterActivity.hockey);
    }
    if (values.length === 0) {
        return undefined;
    }
    return values.join(',');
};
export default getWinterActivitesSearchParam;
