import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import styled from 'styled-components/macro';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { setStartDate, setEndDate, loadResults, } from '../../slices/listingSearchSlice';
import DateRangeCalendar from '../DateRangeCalendar/DateRangeCalendar';
import { setPage } from '../../containers/SearchPage/SearchPageSlice';
const Wrapper = styled.div `
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
`;
const SearchBarSheetDates = () => {
    const dispatch = useDispatch();
    const currentDatesSearchQuery = useSelector((state) => state.listingSearch.currentSearchQuery.dates);
    const onSetDateRange = (startDate, endDate) => {
        dispatch(setStartDate(startDate));
        dispatch(setEndDate(endDate));
        if (startDate && endDate) {
            dispatch(setPage(1));
            dispatch(loadResults());
        }
    };
    return (_jsx(Wrapper, { children: _jsx(DateRangeCalendar, { onChangeRange: (start, end) => {
                onSetDateRange(start === null || start === void 0 ? void 0 : start.toDate(), end === null || end === void 0 ? void 0 : end.toDate());
            }, startDay: (currentDatesSearchQuery === null || currentDatesSearchQuery === void 0 ? void 0 : currentDatesSearchQuery.startDate)
                ? moment(currentDatesSearchQuery.startDate)
                : undefined, endDay: (currentDatesSearchQuery === null || currentDatesSearchQuery === void 0 ? void 0 : currentDatesSearchQuery.endDate)
                ? moment(currentDatesSearchQuery.endDate)
                : undefined }) }));
};
export default SearchBarSheetDates;
