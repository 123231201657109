import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styled from 'styled-components/macro';
import moment from 'moment';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import DateRangeCalendar from '../../../styled-components/DateRangeCalendar/DateRangeCalendar';
import { setBookingEnd, setBookingStart } from '../ListingPageSlice';
import Text, { TextVariant } from '../../../styled-components/Text/Text';
import { ColorVariant } from '../../../theme';
const ContentWrap = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const BookingDatesSelection = ({ onClose, isInvalidDatesRange, setIsInvalidDatesRange, }) => {
    var _a;
    const intl = useIntl();
    const dispatch = useDispatch();
    const { bookingEnd, bookingStart, listing, timeSlots, bookingData: { seatQuantity }, } = useSelector((state) => state.ListingPage);
    const { minBookableNights, maxBookableNights, seats: totalSeats, } = ((_a = listing === null || listing === void 0 ? void 0 : listing.attributes) === null || _a === void 0 ? void 0 : _a.publicData) || {};
    const handleChangeRange = (start, end) => {
        const nightsSelected = end && start && end.diff(start, 'days');
        if (nightsSelected) {
            if (minBookableNights && nightsSelected < minBookableNights) {
                setIsInvalidDatesRange === null || setIsInvalidDatesRange === void 0 ? void 0 : setIsInvalidDatesRange(true);
            }
            else if (maxBookableNights && nightsSelected > maxBookableNights) {
                setIsInvalidDatesRange === null || setIsInvalidDatesRange === void 0 ? void 0 : setIsInvalidDatesRange(true);
            }
            else {
                setIsInvalidDatesRange === null || setIsInvalidDatesRange === void 0 ? void 0 : setIsInvalidDatesRange(false);
                dispatch(setBookingEnd(end === null || end === void 0 ? void 0 : end.toDate()));
            }
        }
        if (!!bookingEnd && !end)
            dispatch(setBookingEnd(undefined));
        dispatch(setBookingStart(start === null || start === void 0 ? void 0 : start.toDate()));
    };
    if (!timeSlots) {
        return null;
    }
    return (_jsxs(ContentWrap, Object.assign({ "data-testid": "BookingDatesSelection" }, { children: [_jsx(DateRangeCalendar, { onChangeRange: handleChangeRange, startDay: bookingStart ? moment(bookingStart) : undefined, endDay: bookingEnd ? moment(bookingEnd) : undefined, showSingleMonthOnDesktop: true, timeSlots: timeSlots, totalSeats: totalSeats, requestedSeats: seatQuantity }), isInvalidDatesRange && (_jsx(Text, Object.assign({ variant: TextVariant.B50, colorVariant: ColorVariant.Blood }, { children: intl.formatMessage({ id: 'ListingPage.invalidRange' }, { minBookableNights, maxBookableNights }) })))] })));
};
export default BookingDatesSelection;
