import { jsx as _jsx } from "react/jsx-runtime";
/**
 * This component wraps React-Router's Redirect by providing name-based routing.
 * (Helps to narrow down the scope of possible format changes to routes.)
 */
import React from 'react';
import { Redirect } from 'react-router-dom';
import { pathByRouteName } from '@nomady/shared/utils/routes';
import routeConfiguration from '@nomady/shared/routeConfiguration/routeConfiguration';
const NamedRedirect = ({ name, search = '', state = {}, push = false, params = {}, }) => {
    const pathname = pathByRouteName(name, routeConfiguration, params);
    return _jsx(Redirect, { to: { pathname, search, state }, push: push });
};
export default NamedRedirect;
