import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import styled from 'styled-components/macro';
import CmsContentModal, { CmsContentModalVariant, } from '../../modals/CmsContentModal/CmsContentModal';
import { ColorVariant } from '../../theme';
import Text, { TextVariant } from '../Text/Text';
import { loadData as loadCmsContentData } from '../../slices/cmsDataSlice';
const Container = styled.div `
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 10px 0px;
`;
const LabelLink = styled.a `
  ${props => props.theme.typeset.B50};
  text-decoration: underline;
  color: ${props => props.theme.color.cave};
  font-weight: 700;

  &:hover {
    cursor: pointer;
  }
`;
const BoldText = styled(Text) `
  font-weight: 700;
`;
const LegalAgreement = ({ agreeButtonLabelId, showNomadyPact, showGiftCardTerms, }) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const [cmsContentModalVariant, setCmsContentModalVariant] = useState(null);
    const handleOpenModal = (event, variant) => {
        event.preventDefault();
        setCmsContentModalVariant(variant);
    };
    useEffect(() => {
        dispatch(loadCmsContentData());
    }, []);
    const closeModal = () => {
        setCmsContentModalVariant(null);
    };
    const agreementLabelId = showGiftCardTerms
        ? `LegalAgreement.agreementWithGiftCardTerms`
        : 'LegalAgreement.agreement';
    return (_jsxs(Container, { children: [_jsx(Text, Object.assign({ variant: TextVariant.B50, colorVariant: ColorVariant.Cave }, { children: intl.formatMessage({ id: agreementLabelId }, {
                    label: (_jsx(BoldText, Object.assign({ variant: TextVariant.B50, colorVariant: ColorVariant.Cave }, { children: `'${intl.formatMessage({
                            id: agreeButtonLabelId,
                        })}'` }))),
                    termsLink: (_jsx(LabelLink, Object.assign({ onClick: e => handleOpenModal(e, CmsContentModalVariant.TermsOfService) }, { children: intl.formatMessage({ id: 'LegalAgreement.termsOfService' }) }))),
                    privacyLink: (_jsx(LabelLink, Object.assign({ onClick: e => handleOpenModal(e, CmsContentModalVariant.PrivacyPolicy) }, { children: intl.formatMessage({ id: 'LegalAgreement.privacyPolicy' }) }))),
                    giftCardTermsLink: (_jsx(LabelLink, Object.assign({ onClick: e => handleOpenModal(e, CmsContentModalVariant.GiftCardTerms) }, { children: intl.formatMessage({ id: 'LegalAgreement.giftCardTerms' }) }))),
                }) })), showNomadyPact && (_jsx(Text, Object.assign({ variant: TextVariant.B50, colorVariant: ColorVariant.Cave }, { children: intl.formatMessage({ id: 'LegalAgreement.pact' }, {
                    pactLink: (_jsx(LabelLink, Object.assign({ onClick: e => handleOpenModal(e, CmsContentModalVariant.NomadyPact) }, { children: intl.formatMessage({ id: 'LegalAgreement.nomadyPact' }) }))),
                }) }))), _jsx(CmsContentModal, { variant: cmsContentModalVariant, onClose: closeModal })] }));
};
export default LegalAgreement;
