const resolvePropString = (input) => {
    const values = Object.values(input || {});
    const keys = Object.keys(input || {});
    return (keys.filter((key, index) => values && values[index] && !!key).join(',') ||
        undefined);
};
const getFeaturesQueryString = (currentSearchQuery) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14;
    return ({
        access: resolvePropString((_b = (_a = currentSearchQuery.features) === null || _a === void 0 ? void 0 : _a.access) === null || _b === void 0 ? void 0 : _b.access),
        access_ground: resolvePropString((_d = (_c = currentSearchQuery.features) === null || _c === void 0 ? void 0 : _c.access) === null || _d === void 0 ? void 0 : _d.ground),
        activities_summer: resolvePropString((_f = (_e = currentSearchQuery.features) === null || _e === void 0 ? void 0 : _e.activities) === null || _f === void 0 ? void 0 : _f.summer),
        activities_winter: resolvePropString((_h = (_g = currentSearchQuery.features) === null || _g === void 0 ? void 0 : _g.activities) === null || _h === void 0 ? void 0 : _h.winter),
        ambiance: resolvePropString((_j = currentSearchQuery.features) === null || _j === void 0 ? void 0 : _j.ambiance),
        features_booking_and_cancellation: resolvePropString((_k = currentSearchQuery.features) === null || _k === void 0 ? void 0 : _k.bookingAndCancellation),
        facilities_accommodation: resolvePropString((_m = (_l = currentSearchQuery.features) === null || _l === void 0 ? void 0 : _l.facilities) === null || _m === void 0 ? void 0 : _m.accommodation),
        facilities_toilet: resolvePropString((_q = (_p = (_o = currentSearchQuery.features) === null || _o === void 0 ? void 0 : _o.facilities) === null || _p === void 0 ? void 0 : _p.basics) === null || _q === void 0 ? void 0 : _q.toilet),
        facilities_basics_water: (_t = (_s = (_r = currentSearchQuery.features) === null || _r === void 0 ? void 0 : _r.facilities) === null || _s === void 0 ? void 0 : _s.basics) === null || _t === void 0 ? void 0 : _t.water,
        facilities_basics_power_supply: resolvePropString((_w = (_v = (_u = currentSearchQuery.features) === null || _u === void 0 ? void 0 : _u.facilities) === null || _v === void 0 ? void 0 : _v.basics) === null || _w === void 0 ? void 0 : _w.powerSupply),
        facilities_miscellaneous: resolvePropString((_y = (_x = currentSearchQuery.features) === null || _x === void 0 ? void 0 : _x.facilities) === null || _y === void 0 ? void 0 : _y.miscellaneous),
        facilities_shower: resolvePropString((_0 = (_z = currentSearchQuery.features) === null || _z === void 0 ? void 0 : _z.facilities) === null || _0 === void 0 ? void 0 : _0.shower),
        features_store: (_2 = (_1 = currentSearchQuery.features) === null || _1 === void 0 ? void 0 : _1.facilities) === null || _2 === void 0 ? void 0 : _2.store,
        features_winterCamping: (_4 = (_3 = currentSearchQuery.features) === null || _3 === void 0 ? void 0 : _3.facilities) === null || _4 === void 0 ? void 0 : _4.winterCamping,
        fireplaceAvailable: (_5 = currentSearchQuery.features) === null || _5 === void 0 ? void 0 : _5.fireplaceAvailable,
        features_fireplace: resolvePropString((_6 = currentSearchQuery.features) === null || _6 === void 0 ? void 0 : _6.fireplace),
        price: ((_9 = (_8 = (_7 = currentSearchQuery.features) === null || _7 === void 0 ? void 0 : _7.price) === null || _8 === void 0 ? void 0 : _8.price) === null || _9 === void 0 ? void 0 : _9.join(',')) || undefined,
        currency: ((_11 = (_10 = currentSearchQuery.features) === null || _10 === void 0 ? void 0 : _10.price) === null || _11 === void 0 ? void 0 : _11.currency) || undefined,
        dogsAllowed: ((_12 = currentSearchQuery.features) === null || _12 === void 0 ? void 0 : _12.dogsAllowed) || undefined,
        dogCamp: ((_13 = currentSearchQuery.features) === null || _13 === void 0 ? void 0 : _13.dogCamp) || undefined,
        familyFriendly: ((_14 = currentSearchQuery.features) === null || _14 === void 0 ? void 0 : _14.familyFriendly) || undefined,
    });
};
export default getFeaturesQueryString;
