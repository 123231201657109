import Decimal from '@nomady/shared/utils/decimal';
const providerCommissionPercentage1 = 15;
const blockProviderCommissionEdge1 = 10000;
const providerCommissionPercentage2 = 10;
const blockProviderCommissionEdge2 = 30000;
const gradientProviderCommissionBlock2 = -0.00025;
const customerCommissionPercentage1 = 15;
const blockCustomerCommissionEdge1 = 20000;
const customerCommissionPercentage2 = 10;
const blockCustomerCommissionEdge2 = 100000;
const gradientCustomerCommissionBlock2 = -0.0000625;
export const MAX_CUSTOMER_COMMISSION_DISCOUNT = Math.min(providerCommissionPercentage1, providerCommissionPercentage2);
export const calculateCustomerCommissionPercentage = (commissionBasicValue) => {
    if (commissionBasicValue <= blockCustomerCommissionEdge1) {
        return new Decimal(customerCommissionPercentage1);
    }
    if (blockCustomerCommissionEdge1 < commissionBasicValue &&
        commissionBasicValue <= blockCustomerCommissionEdge2) {
        return new Decimal(commissionBasicValue - blockCustomerCommissionEdge1)
            .times(gradientCustomerCommissionBlock2)
            .add(customerCommissionPercentage1)
            .toNearest(0.001, Decimal.ROUND_HALF_UP);
    }
    return new Decimal(customerCommissionPercentage2);
};
export const calculateProviderCommissionPercentage = (commissionBasicValue) => {
    let commission = new Decimal(providerCommissionPercentage2);
    if (commissionBasicValue <= blockProviderCommissionEdge1) {
        commission = new Decimal(providerCommissionPercentage1);
    }
    if (blockProviderCommissionEdge1 < commissionBasicValue &&
        commissionBasicValue <= blockProviderCommissionEdge2) {
        commission = new Decimal(commissionBasicValue - blockProviderCommissionEdge1)
            .times(gradientProviderCommissionBlock2)
            .add(providerCommissionPercentage1)
            .toNearest(0.001, Decimal.ROUND_HALF_UP);
    }
    return commission.mul(-1);
};
export const calculateCustomerCommissionAbsolute = (commissionBasicValue) => {
    const customerCommissionPercentage = calculateCustomerCommissionPercentage(commissionBasicValue);
    return new Decimal(commissionBasicValue)
        .times(customerCommissionPercentage)
        .dividedBy(100)
        .toNearest(1, Decimal.ROUND_HALF_UP)
        .toNumber();
};
export const calculateProviderCommissionAbsolute = (commissionBasicValue) => {
    const providerCommissionPercentage = calculateProviderCommissionPercentage(commissionBasicValue);
    return new Decimal(commissionBasicValue)
        .times(providerCommissionPercentage)
        .dividedBy(100)
        .toNearest(1, Decimal.ROUND_HALF_UP)
        .toNumber();
};
export const calculateCustomerCommissionDiscountCodeDiscountAbsolute = (commissionBasicValue, discountInPercent) => {
    return new Decimal(commissionBasicValue)
        .times(discountInPercent)
        .dividedBy(100)
        .times(-1)
        .toNearest(1, Decimal.ROUND_HALF_UP)
        .toNumber();
};
