var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import PageContainer from './PageContainer';
import { PageLayoutBottom, PageLayoutContent, PageLayoutTop, } from '../PageLayout/PageLayout';
import Footer from '../Footer/Footer';
import TopBar from '../TopBar/TopBar';
import styled from 'styled-components/macro';
import Button, { ButtonColorVariant } from '../Button/Button';
import { IconVariant } from '../Icon/Icon';
import { openSearchBarSheet } from '../../slices/listingSearchSlice';
import SearchBarSheetVariant from '../SearchBarSheet/SearchBarSheetVariant';
import { useDispatch } from 'react-redux';
import { isApp } from '../../util/deviceContext';
import { useIntl } from 'react-intl';
const FloatingDiscoveryCTAWrapper = styled.div `
  position: fixed;
  bottom: 32px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  z-index: ${props => props.theme.priority.high};
`;
const FloatingDiscoveryCTA = styled.div ``;
const ContentPageContainer = (_a) => {
    var { hideTopBar = false, children } = _a, props = __rest(_a, ["hideTopBar", "children"]);
    const intl = useIntl();
    const dispatch = useDispatch();
    return (_jsxs(PageContainer, Object.assign({}, props, { children: [!hideTopBar && (_jsx(PageLayoutTop, { children: _jsx(TopBar, { hideListingSearchBar: props.hideListingSearchBar, hideSearchBarInMobile: props.hideSearchBarInMobile, onClickListingSearchBar: props.onClickListingSearchBar }) })), _jsx(PageLayoutContent, Object.assign({ ignoreTopBarOffset: hideTopBar }, { children: children })), _jsx(PageLayoutBottom, { children: _jsx(Footer, {}) }), isApp &&
                !props.hideSearchBarInMobile &&
                !props.hideListingSearchBar &&
                !hideTopBar && (_jsx(FloatingDiscoveryCTAWrapper, { children: _jsx(FloatingDiscoveryCTA, { children: _jsx(Button, { label: intl.formatMessage({
                            id: 'CmsContentPage.floatingDiscoverButton',
                        }), colorVariant: ButtonColorVariant.Fir, icon: IconVariant.Search, onClick: () => dispatch(openSearchBarSheet(SearchBarSheetVariant.Location)) }) }) }))] })));
};
export default ContentPageContainer;
