import React from 'react';
import classNames from 'classnames';
import { ExternalLink } from '../index.ts';

import css from './TransactionPanel.module.scss';

// Functional component as a helper to build AddressLinkMaybe
const AddressLinkMaybe = props => {
  const { className, rootClassName, location, geolocation, showAddress } =
    props;
  const { address, building } = location || {};
  const { lat, lng } = geolocation || {};

  const latLongUrl = `https://maps.google.com/?q=${lat},${lng}`;
  const adressUrl = address
    ? `https://maps.google.com/?q=${encodeURIComponent(address)}`
    : null;

  const hrefToGoogleMaps = geolocation ? latLongUrl : adressUrl;

  const fullAddress =
    typeof building === 'string' && building.length > 0
      ? `${building}, ${address}`
      : address;

  const classes = classNames(rootClassName || css.address, className);
  return showAddress && hrefToGoogleMaps ? (
    <h4 className={classes}>
      <ExternalLink href={hrefToGoogleMaps}>{fullAddress}</ExternalLink>
    </h4>
  ) : null;
};

export default AddressLinkMaybe;
