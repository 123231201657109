/**
 * This is the main entrypoint file for the application.
 *
 * When loaded in the client side, the application is rendered in the
 * #root element.
 *
 * When the bundle created from this file is imported in the server
 * side, the exported `renderApp` function can be used for server side
 * rendering.
 *
 * Note that this file is required for the build process.
 */

// React 16 depends on the collection types Map and Set, as well as requestAnimationFrame.
// https://reactjs.org/docs/javascript-environment-requirements.html
import 'core-js/features/map';
import 'core-js/features/set';
import 'raf/polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import {
  createInstance,
  types as sdkTypes,
} from '@nomady/shared/utils/sdkLoader.ts';
import createStore from '@nomady/shared/utils/tokenStore/browserTokenStore.ts';
import { typeHandlers } from '@nomady/shared/utils/sdk.ts';
import { matchPathname } from '@nomady/shared/utils/routes.ts';
import env from '@nomady/shared/utils/env';
import { ClientApp, renderApp } from './app.tsx';
import getStore from './store.ts';
import * as sample from './util/sample';
import config from './config.ts';
import { authInfo } from './ducks/Auth.duck.ts';
import { fetchCurrentUser } from './ducks/user.duck.ts';
import routeConfiguration from './routeConfigurationWithComponents.ts';
import * as log from './util/log.ts';
import { GoogleAnalyticsHandler } from './analytics/handlers';
import createMiddleware from './analytics/analytics';

import './marketplaceIndex.module.scss';

const render = (store, shouldHydrate) => {
  // If the server already loaded the auth information, render the app
  // immediately. Otherwise wait for the flag to be loaded and render
  // when auth information is present.
  const { authInfoLoaded } = store.getState().Auth;
  const info = authInfoLoaded
    ? Promise.resolve({})
    : store.dispatch(authInfo());
  info
    .then(() => {
      store.dispatch(fetchCurrentUser());

      if (shouldHydrate) {
        ReactDOM.hydrate(
          <ClientApp store={store} />,
          document.getElementById('root')
        );
      } else {
        ReactDOM.render(
          <ClientApp store={store} />,
          document.getElementById('root')
        );
      }
    })
    .catch(e => {
      log.error(e, 'browser-side-render-failed');
    });
};

const setupAnalyticsHandlers = () => {
  const handlers = [];

  handlers.push(new GoogleAnalyticsHandler(window.ga));

  return handlers;
};

// If we're in a browser already, render the client application.
if (typeof window !== 'undefined') {
  // set up logger with Sentry DSN client key and environment
  log.setup();

  const baseUrl = config.sdk.baseUrl ? { baseUrl: config.sdk.baseUrl } : {};

  // eslint-disable-next-line no-underscore-dangle
  const preloadedState = window.__PRELOADED_STATE__ || '{}';
  const initialState = JSON.parse(preloadedState, sdkTypes.reviver);
  const sdk = createInstance({
    transitVerbose: config.sdk.transitVerbose,
    clientId: config.sdk.clientId,
    secure: config.usingSSL,
    tokenStore: createStore({
      clientId: config.sdk.clientId,
      secure: config.usingSSL,
    }),
    typeHandlers,
    ...baseUrl,
  });
  const analyticsHandlers = setupAnalyticsHandlers();
  const middlewares = [createMiddleware(analyticsHandlers)];

  const store = getStore(sdk, initialState, middlewares);

  // eslint-disable-next-line global-require
  require('./util/polyfills');
  // eslint-disable-next-line no-underscore-dangle
  render(store, !!window.__PRELOADED_STATE__);

  if (config.dev) {
    // Expose stuff for the browser REPL
    window.app = {
      config,
      sdk,
      sdkTypes,
      store,
      sample,
      routeConfiguration,
    };
  }
}

// Show warning if CSP is not enabled
const CSP = env('CSP');
const cspEnabled = CSP === 'block' || CSP === 'report';

if (CSP === 'report' && env('ENV') === 'production') {
  console.warn(
    'Your production environment should use CSP with "block" mode. Read more from: https://www.sharetribe.com/docs/ftw-security/how-to-set-up-csp-for-ftw/'
  );
} else if (!cspEnabled) {
  console.warn(
    "CSP is currently not enabled! You should add an environment variable REACT_APP_CSP with the value 'report' or 'block'. Read more from: https://www.sharetribe.com/docs/ftw-security/how-to-set-up-csp-for-ftw/"
  );
}

// Export the function for server side rendering.
export default renderApp;

// exporting matchPathname and getStore for server side rendering.
// matchPathname helps to figure out which route is called and if it has preloading needs
// getStore is used for creating initial store state for Redux after preloading
export { matchPathname, getStore, routeConfiguration, config };
