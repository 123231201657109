import styled, { css } from 'styled-components/macro';
const TabletView = styled.div `
  display: none;
  @media ${props => props.theme.device.tablet} {
    display: block;
  }

  ${props => props.hideOnDesktop &&
    css `
      @media ${props => props.theme.device.desktop} {
        display: none;
      }
    `}
`;
export default TabletView;
