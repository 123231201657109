import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Switch, Route, withRouter } from 'react-router-dom';
import { propTypes } from '@nomady/shared/utils/types.ts';
import { canonicalRoutePath } from '@nomady/shared/utils/routes.ts';
import {
  LOCALE_CONFIG,
  LanguagePrefixMap,
} from '@nomady/shared/routeConfiguration/routeConfiguration.ts';
import { NotFoundPage } from './containers/index.ts';
import { NamedRedirect } from './components/index.ts';
import { locationChanged } from './slices/routingSlice.ts';
import * as log from './util/log.ts';
import routeConfiguration from './routeConfigurationWithComponents.ts';
import { switchLocale } from './slices/UISlice.ts';
import { getUserDefaultLocale } from './util/language.ts';

const { arrayOf, bool, object, func, shape, string } = PropTypes;

const canShowComponent = props => {
  const { isAuthenticated, route } = props;
  const { auth } = route;
  return !auth || isAuthenticated;
};

const callLoadData = props => {
  const { match, location, route, dispatch, logoutInProgress } = props;
  const { loadData, name } = route;
  const shouldLoadData =
    typeof loadData === 'function' &&
    canShowComponent(props) &&
    !logoutInProgress;

  if (shouldLoadData) {
    dispatch(loadData(match.params, location.search))
      .then(() => {
        // eslint-disable-next-line no-console
        console.log(`loadData success for ${name} route`);
      })
      .catch(e => {
        log.error(e, 'load-data-failed', { routeName: name });
      });
  }
};

const setPageScrollPosition = location => {
  if (!location.hash) {
    // No hash, scroll to top
    window.scroll({
      top: 0,
      left: 0,
    });
  } else {
    const el = document.querySelector(location.hash);
    if (el) {
      // Found element with the given fragment identifier, scrolling
      // to that element.
      //
      // NOTE: This isn't foolproof. It works when navigating within
      // the application between pages and within a single page. It
      // also works with the initial page load. However, it doesn't
      // seem work work properly when refreshing the page, at least
      // not in Chrome.
      //
      // TODO: investigate why the scrolling fails on refresh
      el.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      });
    }
  }
};

const handleLocationChanged = (dispatch, location, params) => {
  setPageScrollPosition(location);
  const canonicalUrl = canonicalRoutePath(routeConfiguration, location, params);
  dispatch(locationChanged({ location, canonicalUrl }));
};

class RouteComponentRenderer extends Component {
  componentDidMount() {
    callLoadData(this.props);
    handleLocationChanged(
      this.props.dispatch,
      this.props.location,
      this.props.match.params
    );
  }

  render() {
    const {
      route,
      match,
      location,
      staticContext,
      language,
      currentLocale,
      dispatch,
      isAuthenticated,
    } = this.props;
    const { component: RouteComponent, authPage = 'SignupPage' } = route;
    const canShow = canShowComponent(this.props);

    const languageFromUrl = match.params.language;

    if (!canShow) {
      staticContext.unauthorized = true;
    }

    if (!currentLocale && language) {
      dispatch(switchLocale(language));
    }

    if (
      !isAuthenticated &&
      !currentLocale &&
      (!languageFromUrl ||
        Object.keys(LanguagePrefixMap).includes(languageFromUrl))
    ) {
      const userDefaultLocale = getUserDefaultLocale();

      const requestedLanguage =
        Object.values(LOCALE_CONFIG).find(
          locale => locale.short === languageFromUrl
        )?.key || userDefaultLocale;

      dispatch(switchLocale(requestedLanguage));

      return React.createElement(NamedRedirect, {
        name: route.name,
        search: location.search,
        params: {
          ...match.params,
          language: requestedLanguage,
        },
      });
    }

    return canShow ? (
      <RouteComponent
        params={match.params}
        location={location}
        language={language}
      />
    ) : (
      <NamedRedirect
        name={authPage}
        state={{
          from: `${location.pathname}${location.search}${location.hash}`,
        }}
        params={{ language, ...match.params }}
      />
    );
  }
}

RouteComponentRenderer.propTypes = {
  isAuthenticated: bool.isRequired, // eslint-disable-line react/no-unused-prop-types
  logoutInProgress: bool.isRequired, // eslint-disable-line react/no-unused-prop-types
  route: propTypes.route.isRequired,
  match: shape({
    params: object.isRequired,
    url: string.isRequired,
  }).isRequired,
  location: shape({
    search: string.isRequired,
  }).isRequired,
  staticContext: object.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  dispatch: func.isRequired,
};

const hydrateWithMatchProps = (
  ComponentWithoutProps,
  matchProps,
  renderProps
) => {
  const { params, location, match } = matchProps;
  return (
    <ComponentWithoutProps
      {...renderProps}
      params={params}
      location={location}
      match={match}
    />
  );
};

const Routes = props => {
  const {
    isAuthenticated,
    logoutInProgress,
    staticContext,
    dispatch,
    routes,
    locale: currentLocale,
  } = props;

  const toRouteComponent = route => {
    const renderProps = {
      isAuthenticated,
      logoutInProgress,
      route,
      staticContext,
      dispatch,
      language: route.language,
      currentLocale,
    };

    // By default, our routes are exact.
    // https://reacttraining.com/react-router/web/api/Route/exact-bool
    const isExact = route.exact != null ? route.exact : true;
    return (
      <Route
        key={route.name}
        path={route.path}
        exact={isExact}
        render={matchProps =>
          hydrateWithMatchProps(RouteComponentRenderer, matchProps, renderProps)
        }
      />
    );
  };

  if (!currentLocale) {
    const userDefaultLocale = getUserDefaultLocale();
    dispatch(switchLocale(userDefaultLocale));
  }

  // N.B. routes prop within React Router needs to stay the same,
  // so that React is is not rerendering page component.
  // That's why we pass-in props.routes instead of calling routeConfiguration here.
  return (
    <Switch>
      {routes.map(toRouteComponent)}
      <Route component={NotFoundPage} />
    </Switch>
  );
};

Routes.defaultProps = { staticContext: {} };

Routes.propTypes = {
  isAuthenticated: bool.isRequired,
  logoutInProgress: bool.isRequired,
  routes: arrayOf(propTypes.route).isRequired,

  // from withRouter
  staticContext: object,

  // from connect
  dispatch: func.isRequired,
};

const mapStateToProps = state => {
  const { isAuthenticated, logoutInProgress } = state.Auth;
  const { locale } = state.UI;

  return { isAuthenticated, logoutInProgress, locale };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
export default compose(withRouter, connect(mapStateToProps))(Routes);
