var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import { useLocation, Redirect } from 'react-router-dom';
import styled from 'styled-components/macro';
import Page from '@nomady/shared/routeConfiguration/Page';
import { EMAIL_REGEX } from '@nomady/shared/utils/validation';
import Text, { TextVariant } from '../../styled-components/Text/Text';
import Input from '../../styled-components/Input/Input';
import Button, { ButtonColorVariant, ButtonSizeVariant, } from '../../styled-components/Button/Button';
import { isAuthenticatedSelector } from '../../ducks/Auth.duck';
import { NamedLink, NamedRedirect } from '../../components';
import FormErrorMessage from '../../styled-components/FormErrorMessage/FormErrorMessage';
import { ColorVariant } from '../../theme';
import { passwordRecoveryInProgressSelector, recoverPassword, retypeEmail, } from '../../containers/PasswordRecoveryPage/PasswordRecoveryPageSlice';
import { isPasswordRecoveryEmailNotFoundError } from '../../util/errors';
const Title = styled(Text) `
  margin-bottom: 20px;
`;
const Message = styled(Text) `
  margin-bottom: 20px;
`;
const Form = styled.form ``;
const FormRow = styled.div `
  margin-bottom: 10px;
`;
const AdditionalLinksWrapper = styled.div `
  text-align: center;
  margin-top: 12px;
`;
const PasswordRecoveryForm = ({ isFullPageSheet, onClickLogin, }) => {
    var _a;
    const intl = useIntl();
    const dispatch = useDispatch();
    const { register, handleSubmit, formState: { errors }, } = useForm();
    const location = useLocation();
    const user = useSelector((state) => state.user.currentUser);
    const isAuthenticated = useSelector(isAuthenticatedSelector);
    const passwordRecoveryInProgress = useSelector(passwordRecoveryInProgressSelector);
    const { submittedEmail, passwordRequested, recoveryError, initialEmail } = useSelector((state) => state.PasswordRecoveryPage);
    const from = ((_a = location === null || location === void 0 ? void 0 : location.state) === null || _a === void 0 ? void 0 : _a.from) || null;
    const currentUserLoaded = !!(user === null || user === void 0 ? void 0 : user.id);
    if (isAuthenticated && from) {
        return _jsx(Redirect, { to: from });
    }
    if (isAuthenticated && currentUserLoaded) {
        return _jsx(NamedRedirect, { name: Page.Landing });
    }
    const onSuccess = (data) => __awaiter(void 0, void 0, void 0, function* () {
        const trimmedEmail = data.email.trim();
        dispatch(recoverPassword(trimmedEmail));
    });
    const onReset = () => {
        dispatch(retypeEmail());
    };
    const _b = register('email', {
        required: true,
        pattern: {
            value: EMAIL_REGEX,
            message: 'Input.validationMessage.email',
        },
    }), { ref: emailRef } = _b, email = __rest(_b, ["ref"]);
    const loginText = (_jsx(Text, Object.assign({ variant: TextVariant.H11B, colorVariant: ColorVariant.Cave }, { children: _jsx("u", { children: intl.formatMessage({
                id: 'PasswordRecoveryForm.loginLink',
            }) }) })));
    const loginLink = isFullPageSheet && onClickLogin ? (_jsx("span", Object.assign({ onClick: onClickLogin }, { children: loginText }))) : (_jsx(NamedLink, Object.assign({ name: Page.Login }, { children: loginText })));
    const resendEmailLink = (_jsx(Text, Object.assign({ variant: TextVariant.H11B, colorVariant: ColorVariant.Cave, onClick: () => submittedEmail && dispatch(recoverPassword(submittedEmail)) }, { children: _jsx("u", { children: intl.formatMessage({
                id: 'PasswordRecoveryForm.resendEmailLink',
            }) }) })));
    const fixEmailLink = (_jsx(Text, Object.assign({ variant: TextVariant.H11B, colorVariant: ColorVariant.Cave, onClick: onReset }, { children: _jsx("u", { children: intl.formatMessage({
                id: 'PasswordRecoveryForm.fixEmailLink',
            }) }) })));
    const showForm = !passwordRequested;
    return (_jsxs(_Fragment, { children: [showForm && (_jsxs(Form, Object.assign({ onSubmit: handleSubmit(onSuccess) }, { children: [_jsx(Title, Object.assign({ variant: TextVariant.H20, as: "h2" }, { children: intl.formatMessage({ id: 'PasswordRecoveryForm.title' }) })), _jsx(Message, Object.assign({ variant: TextVariant.H30, as: "p", colorVariant: ColorVariant.Stone }, { children: intl.formatMessage({
                            id: 'PasswordRecoveryForm.forgotPasswordMessage',
                        }) })), _jsx(FormRow, { children: _jsx(Input, Object.assign({ label: intl.formatMessage({
                                id: 'PasswordRecoveryForm.emailLabel',
                            }), id: "email", autoComplete: "email" }, email, { inputRef: emailRef, error: errors === null || errors === void 0 ? void 0 : errors.email })) }), !!recoveryError && (_jsx(FormErrorMessage, { id: isPasswordRecoveryEmailNotFoundError(recoveryError)
                            ? 'PasswordRecoveryForm.emailNotFound'
                            : 'PasswordRecoveryForm.actionFailedMessage' })), _jsx(Button, { dataTestId: "PasswordRecoveryPage_recoveryButton", label: intl.formatMessage({
                            id: 'PasswordRecoveryForm.primaryButtonLabel',
                        }), colorVariant: ButtonColorVariant.Dark, sizeVariant: ButtonSizeVariant.Small, type: "submit", inProgress: passwordRecoveryInProgress }), _jsx(AdditionalLinksWrapper, { children: _jsx(Text, Object.assign({ variant: TextVariant.H12B, colorVariant: ColorVariant.Cave }, { children: intl.formatMessage({
                                id: 'PasswordRecoveryForm.loginText',
                            }, { loginLink }) })) })] }))), !showForm && (_jsxs(_Fragment, { children: [_jsx(Title, Object.assign({ variant: TextVariant.H3, as: "h2" }, { children: intl.formatMessage({
                            id: 'PasswordRecoveryForm.emailSubmittedTitle',
                        }) })), _jsx(Message, Object.assign({ variant: TextVariant.H30, as: "p", colorVariant: ColorVariant.Stone }, { children: intl.formatMessage({
                            id: 'PasswordRecoveryForm.emailSubmittedMessage',
                        }, { initialEmail }) })), !!recoveryError && (_jsx(FormErrorMessage, { id: "PasswordRecoveryForm.actionFailedMessage" })), _jsx(AdditionalLinksWrapper, { children: _jsx(Text, Object.assign({ variant: TextVariant.H12B, colorVariant: ColorVariant.Cave }, { children: intl.formatMessage({
                                id: 'PasswordRecoveryForm.loginText',
                            }, { loginLink }) })) }), _jsx(AdditionalLinksWrapper, { children: _jsx(Text, Object.assign({ variant: TextVariant.H12B, colorVariant: ColorVariant.Cave }, { children: !passwordRecoveryInProgress
                                ? intl.formatMessage({
                                    id: 'PasswordRecoveryForm.resendEmailText',
                                }, { resendEmailLink })
                                : intl.formatMessage({
                                    id: 'PasswordRecoveryForm.resendingEmail',
                                }) })) }), _jsx(AdditionalLinksWrapper, { children: _jsx(Text, Object.assign({ variant: TextVariant.H12B, colorVariant: ColorVariant.Cave }, { children: intl.formatMessage({
                                id: 'PasswordRecoveryForm.fixEmailText',
                            }, { fixEmailLink }) })) })] }))] }));
};
export default PasswordRecoveryForm;
