var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import styled, { css } from 'styled-components/macro';
import { ColorVariant } from '../../theme';
import { ReactComponent as DefaultLogo } from './assets/logo.svg';
import { ReactComponent as WithClaimLogo } from './assets/with-claim.svg';
export var LogoVariant;
(function (LogoVariant) {
    LogoVariant["Default"] = "Default";
    LogoVariant["WithClaim"] = "WithClaim";
})(LogoVariant || (LogoVariant = {}));
const Wrapper = styled.div `
  svg {
    width: 111px;
    height: 19px;

    ${props => props.logoVariant === LogoVariant.WithClaim &&
    `
      width:150px;
      height: 50px;
    `}

    .fill {
      fill: ${props => props.theme.color[props.colorVariant]};
    }
  }

  ${props => props.responsive &&
    css `
      @media ${props.theme.device.tablet} {
        svg {
          width: 146px;
          height: 25px;
        }
      }
    `}
`;
const Logo = (_a) => {
    var { variant = LogoVariant.Default, responsive = false, colorVariant = ColorVariant.Cave } = _a, props = __rest(_a, ["variant", "responsive", "colorVariant"]);
    switch (variant) {
        case LogoVariant.WithClaim:
            return (_jsx(Wrapper, Object.assign({ responsive: responsive, colorVariant: colorVariant, logoVariant: variant }, { children: _jsx(WithClaimLogo, Object.assign({}, props)) })));
        case LogoVariant.Default:
        default:
            return (_jsx(Wrapper, Object.assign({ responsive: responsive, colorVariant: colorVariant, logoVariant: variant }, { children: _jsx(DefaultLogo, Object.assign({}, props)) })));
    }
};
export default Logo;
