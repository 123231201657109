import { momentToUTCDate } from '@nomady/shared/utils/dates';
import moment from 'moment';
export const isDateWithinException = (exception, date) => {
    const startDate = exception.attributes.start;
    const endDate = exception.attributes.end;
    const dateToCheck = momentToUTCDate(moment(date));
    return dateToCheck >= startDate && dateToCheck < endDate;
};
export const findExceptionForSlot = (slot, exceptions) => {
    const matchingException = exceptions.find(exception => {
        return isDateWithinException(exception, slot);
    });
    return matchingException;
};
export const getDatesWithSameWeekday = (startDate, numberOfMonths) => {
    const dates = [];
    const startMoment = moment(startDate);
    const endMoment = moment(startMoment).add(numberOfMonths, 'months');
    for (let currentMoment = moment(startMoment); currentMoment.isBefore(endMoment); currentMoment.add(7, 'days')) {
        dates.push(currentMoment.toDate());
    }
    return dates;
};
export const getMaxSeatsOfListing = (listing) => {
    var _a;
    // Redefine Type, maybe a bug in the sharetribe type definitions
    const entries = (_a = listing.attributes.availabilityPlan) === null || _a === void 0 ? void 0 : _a.entries;
    const maxSeats = entries[0].seats;
    return maxSeats;
};
