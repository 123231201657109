var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components/macro';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { zodResolver } from '@hookform/resolvers/zod';
import { merge } from 'lodash';
import { types as sdkTypes } from '@nomady/shared/utils/sdkLoader';
import { getDefaultLocaleCurrency } from '@nomady/shared/marketplace-custom-config';
import config from '../../config';
import Button, { ButtonColorVariant, ButtonSizeVariant, } from '../../styled-components/Button/Button';
import Text, { TextVariant } from '../../styled-components/Text/Text';
import { CampCategory, CleaningFeeType, VisitorsTaxType, } from '@nomady/shared/types';
import { LISTING_MINIMUM_PRICES, getListingPricingDefaultValues, getListingPricingSchema, } from '@nomady/shared/schemas/listing/pricing';
import FormErrorMessage from '../../styled-components/FormErrorMessage/FormErrorMessage';
import { FormSection } from '../EditListingDescriptionForm/EditListingDescriptionForm';
import { Description } from '../../styled-components/Input/Input';
import SelectInput from '../../styled-components/SelectInput/SelectInput';
import { MarketplaceCurrency } from '@nomady/shared/config';
import InputPrice from '../../styled-components/InputPrice/InputPrice';
import { convertMoneyToNumber, formatMoney, } from '@nomady/shared/utils/currency';
import BookingBreakdown, { useBreakdownLineItems, } from '../../styled-components/BookingBreakdown';
import moment from 'moment';
import { LINE_ITEM_GROUP } from '../../styled-components/BookingBreakdown/types';
import Card from '../../styled-components/Card/Card';
import getTotalTransactionValue from '@nomady/shared/controllers/utils/getTotalTransactionValue';
import getTotalProviderPayoutValue from '@nomady/shared/controllers/utils/getTotalProviderPayoutValue';
import getTotalProviderEarningsValue from '@nomady/shared/controllers/utils/getTotalProviderEarningsValue';
import { localeSelector } from '../../slices/UISlice';
const { Money } = sdkTypes;
const Form = styled.form ``;
const FormRow = styled.div `
  margin-bottom: 10px;
`;
const bookingStart = moment().startOf('day').toDate();
const bookingEnd = moment().startOf('day').add(1, 'day').toDate();
const EditListingPricingForm = ({ currencyFromPublishedListing, updateListingInProgress, updateListingError, onSubmit, onFormDirty, listing, submitButtonLabel, }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s;
    const intl = useIntl();
    const [defaultValuesLoaded, setDefaultValuesLoaded] = useState(false);
    const [temporaryListing, setTemporaryListing] = useState(listing);
    const locale = useSelector(localeSelector);
    const listingLoaded = !!(listing === null || listing === void 0 ? void 0 : listing.id);
    const { publicData } = (listing === null || listing === void 0 ? void 0 : listing.attributes) || {};
    const defaultCurrency = getDefaultLocaleCurrency(locale);
    const defaultValues = publicData
        ? getListingPricingDefaultValues(publicData, currencyFromPublishedListing || defaultCurrency)
        : {};
    const { register, handleSubmit, control, reset, trigger, watch, getValues, formState: { errors, isDirty, isSubmitSuccessful }, } = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: zodResolver(getListingPricingSchema(intl)),
    });
    useEffect(() => {
        reset(defaultValues);
        setDefaultValuesLoaded(true);
    }, [listingLoaded]);
    const validateOnRender = () => {
        defaultValuesLoaded && trigger();
    };
    useEffect(validateOnRender, [trigger, defaultValuesLoaded]);
    useEffect(() => onFormDirty(isDirty), [isDirty, onFormDirty]);
    useEffect(() => {
        if (isSubmitSuccessful) {
            const values = getValues();
            reset(values);
        }
    }, [isSubmitSuccessful, getValues, reset]);
    const onSuccess = (data) => __awaiter(void 0, void 0, void 0, function* () {
        var _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9;
        const price = new Money((((_u = data.pricing.seat) === null || _u === void 0 ? void 0 : _u.amount) || 0) + (((_v = data.pricing.adult) === null || _v === void 0 ? void 0 : _v.amount) || 0), data.currency);
        const params = {
            price,
            publicData: {
                pricing: {
                    seat: (_w = data.pricing.seat) === null || _w === void 0 ? void 0 : _w.amount,
                    adult: (_x = data.pricing.adult) === null || _x === void 0 ? void 0 : _x.amount,
                    teen: (_y = data.pricing.teen) === null || _y === void 0 ? void 0 : _y.amount,
                    child: (_z = data.pricing.child) === null || _z === void 0 ? void 0 : _z.amount,
                    visitorsTax: {
                        adult: (_1 = (_0 = data.pricing.visitorsTax) === null || _0 === void 0 ? void 0 : _0.adult) === null || _1 === void 0 ? void 0 : _1.amount,
                        teen: (_3 = (_2 = data.pricing.visitorsTax) === null || _2 === void 0 ? void 0 : _2.teen) === null || _3 === void 0 ? void 0 : _3.amount,
                        child: (_5 = (_4 = data.pricing.visitorsTax) === null || _4 === void 0 ? void 0 : _4.child) === null || _5 === void 0 ? void 0 : _5.amount,
                    },
                    cleaningFee: (_7 = (_6 = data.cleaningFee) === null || _6 === void 0 ? void 0 : _6.price) === null || _7 === void 0 ? void 0 : _7.amount,
                },
                visitorsTaxType: (_8 = data.pricing.visitorsTax) === null || _8 === void 0 ? void 0 : _8.policy,
                cleaningFeeType: (_9 = data.cleaningFee) === null || _9 === void 0 ? void 0 : _9.policy,
                currency: data.currency,
            },
        };
        onSubmit(params);
    });
    const _t = register('currency'), { onChange: currencyOnChange } = _t, currency = __rest(_t, ["onChange"]);
    const currencyValue = watch('currency') || defaultCurrency;
    const pricingSeat = register('pricing.seat');
    const pricingSeatValue = watch('pricing.seat');
    const pricingAdult = register('pricing.adult');
    const pricingAdultValue = watch('pricing.adult');
    const pricingTeen = register('pricing.teen');
    const pricingTeenValue = watch('pricing.teen');
    const pricingChild = register('pricing.child');
    const pricingChildValue = watch('pricing.child');
    const pricingVisitorsTaxPolicy = register('pricing.visitorsTax.policy');
    const pricingVisitorsTaxPolicyValue = watch('pricing.visitorsTax.policy');
    const pricingVisitorsTaxAdult = register('pricing.visitorsTax.adult');
    const pricingVisitorsTaxAdultValue = watch('pricing.visitorsTax.adult');
    const pricingVisitorsTaxTeen = register('pricing.visitorsTax.teen');
    const pricingVisitorsTaxTeenValue = watch('pricing.visitorsTax.teen');
    const pricingVisitorsTaxChild = register('pricing.visitorsTax.child');
    const pricingVisitorsTaxChildValue = watch('pricing.visitorsTax.child');
    const showTouristTaxDetails = watch('pricing.visitorsTax.policy') ===
        VisitorsTaxType.perPersonAndPerNight;
    const showCleaningFeeSection = (publicData === null || publicData === void 0 ? void 0 : publicData.category) === CampCategory.accommodation;
    const cleaningFeePolicy = register('cleaningFee.policy');
    const cleaningFeePolicyValue = watch('cleaningFee.policy');
    const showCleaningFeeDetails = cleaningFeePolicyValue === CleaningFeeType.cleaningFeePricing;
    const cleaningFeePrice = register('cleaningFee.price');
    const cleaningFeePriceValue = watch('cleaningFee.price');
    const category = publicData === null || publicData === void 0 ? void 0 : publicData.category;
    const price = ((pricingSeatValue === null || pricingSeatValue === void 0 ? void 0 : pricingSeatValue.amount) || 0) + ((pricingAdultValue === null || pricingAdultValue === void 0 ? void 0 : pricingAdultValue.amount) || 0);
    useEffect(() => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
        if (!listing) {
            return;
        }
        const enhancedListing = Object.assign(Object.assign({}, listing), { attributes: Object.assign(Object.assign({}, listing === null || listing === void 0 ? void 0 : listing.attributes), { publicData: Object.assign(Object.assign({}, (_a = listing === null || listing === void 0 ? void 0 : listing.attributes) === null || _a === void 0 ? void 0 : _a.publicData), { currency: currencyValue, pricing: Object.assign(Object.assign({}, (_c = (_b = listing === null || listing === void 0 ? void 0 : listing.attributes) === null || _b === void 0 ? void 0 : _b.publicData) === null || _c === void 0 ? void 0 : _c.pricing), { seat: (_d = pricingSeatValue === null || pricingSeatValue === void 0 ? void 0 : pricingSeatValue.amount) !== null && _d !== void 0 ? _d : 0, adult: (_e = pricingAdultValue === null || pricingAdultValue === void 0 ? void 0 : pricingAdultValue.amount) !== null && _e !== void 0 ? _e : 0, teen: (_f = pricingTeenValue === null || pricingTeenValue === void 0 ? void 0 : pricingTeenValue.amount) !== null && _f !== void 0 ? _f : 0, child: (_g = pricingChildValue === null || pricingChildValue === void 0 ? void 0 : pricingChildValue.amount) !== null && _g !== void 0 ? _g : 0, visitorsTax: {
                            adult: (_h = pricingVisitorsTaxAdultValue === null || pricingVisitorsTaxAdultValue === void 0 ? void 0 : pricingVisitorsTaxAdultValue.amount) !== null && _h !== void 0 ? _h : 0,
                            teen: (_j = pricingVisitorsTaxTeenValue === null || pricingVisitorsTaxTeenValue === void 0 ? void 0 : pricingVisitorsTaxTeenValue.amount) !== null && _j !== void 0 ? _j : 0,
                            child: (_k = pricingVisitorsTaxChildValue === null || pricingVisitorsTaxChildValue === void 0 ? void 0 : pricingVisitorsTaxChildValue.amount) !== null && _k !== void 0 ? _k : 0,
                        }, cleaningFee: (_l = cleaningFeePriceValue === null || cleaningFeePriceValue === void 0 ? void 0 : cleaningFeePriceValue.amount) !== null && _l !== void 0 ? _l : 0 }), cleaningFeeType: cleaningFeePolicyValue, visitorsTaxType: pricingVisitorsTaxPolicyValue }) }) });
        setTemporaryListing(enhancedListing);
    }, [
        listing,
        pricingSeatValue,
        pricingAdultValue,
        pricingTeenValue,
        pricingChildValue,
        pricingVisitorsTaxAdultValue,
        pricingVisitorsTaxTeenValue,
        pricingVisitorsTaxChildValue,
        cleaningFeePriceValue,
        cleaningFeePolicyValue,
        pricingVisitorsTaxPolicyValue,
    ]);
    const bookingData = {
        listing: temporaryListing,
        bookingStart,
        bookingEnd,
        seatQuantity: 1,
        adultQuantity: 2,
        childQuantity: 0,
        teenQuantity: 0,
        infantQuantity: 0,
        dogQuantity: 0,
    };
    const { groupedLineItems, lineItemsFromCalculator } = useBreakdownLineItems(Object.assign(Object.assign({}, bookingData), { role: 'provider' }));
    const { lineItemsFromCalculator: customerLineItemsFromCalculator } = useBreakdownLineItems(Object.assign(Object.assign({}, bookingData), { role: 'customer' }));
    const totalPriceForCustomer = (customerLineItemsFromCalculator === null || customerLineItemsFromCalculator === void 0 ? void 0 : customerLineItemsFromCalculator.length)
        ? getTotalTransactionValue(customerLineItemsFromCalculator)
        : listing === null || listing === void 0 ? void 0 : listing.attributes.price;
    const totalPriceNumber = convertMoneyToNumber(totalPriceForCustomer !== null && totalPriceForCustomer !== void 0 ? totalPriceForCustomer : new Money(0, currencyValue));
    const totalProviderPayoutValue = (lineItemsFromCalculator === null || lineItemsFromCalculator === void 0 ? void 0 : lineItemsFromCalculator.length)
        ? getTotalProviderPayoutValue(lineItemsFromCalculator)
        : listing === null || listing === void 0 ? void 0 : listing.attributes.price;
    const totalProviderPayout = convertMoneyToNumber(totalProviderPayoutValue !== null && totalProviderPayoutValue !== void 0 ? totalProviderPayoutValue : new Money(0, currencyValue));
    const totalProviderEarningsValue = (lineItemsFromCalculator === null || lineItemsFromCalculator === void 0 ? void 0 : lineItemsFromCalculator.length)
        ? getTotalProviderEarningsValue(lineItemsFromCalculator)
        : listing === null || listing === void 0 ? void 0 : listing.attributes.price;
    const totalProviderEarnings = convertMoneyToNumber(totalProviderEarningsValue !== null && totalProviderEarningsValue !== void 0 ? totalProviderEarningsValue : new Money(0, currencyValue));
    return (_jsxs(Form, Object.assign({ onSubmit: handleSubmit(onSuccess) }, { children: [_jsxs(FormSection, { children: [_jsx(Text, Object.assign({ variant: TextVariant.H4, as: "h3" }, { children: intl.formatMessage({
                            id: 'EditListingPricingForm.basePrice',
                        }) })), _jsx(FormRow, { children: _jsx(Description, { text: intl.formatMessage({
                                id: 'EditListingPricingForm.basePriceCalculationInfo',
                            }) }) }), _jsx(FormRow, { children: _jsx(SelectInput, Object.assign({ id: "currency" }, currency, { onChange: event => {
                                var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
                                currencyOnChange(event);
                                const values = getValues();
                                const newPublicData = merge(values, {
                                    pricing: {
                                        seat: (_a = values.pricing.seat) === null || _a === void 0 ? void 0 : _a.amount,
                                        adult: (_b = values.pricing.adult) === null || _b === void 0 ? void 0 : _b.amount,
                                        teen: (_c = values.pricing.teen) === null || _c === void 0 ? void 0 : _c.amount,
                                        child: (_d = values.pricing.child) === null || _d === void 0 ? void 0 : _d.amount,
                                        visitorsTax: {
                                            adult: (_f = (_e = values.pricing.visitorsTax) === null || _e === void 0 ? void 0 : _e.adult) === null || _f === void 0 ? void 0 : _f.amount,
                                            teen: (_h = (_g = values.pricing.visitorsTax) === null || _g === void 0 ? void 0 : _g.teen) === null || _h === void 0 ? void 0 : _h.amount,
                                            child: (_k = (_j = values.pricing.visitorsTax) === null || _j === void 0 ? void 0 : _j.child) === null || _k === void 0 ? void 0 : _k.amount,
                                        },
                                        cleaningFee: (_m = (_l = values.cleaningFee) === null || _l === void 0 ? void 0 : _l.price) === null || _m === void 0 ? void 0 : _m.amount,
                                    },
                                    visitorsTaxType: (_o = values.pricing.visitorsTax) === null || _o === void 0 ? void 0 : _o.policy,
                                    cleaningFeeType: (_p = values.cleaningFee) === null || _p === void 0 ? void 0 : _p.policy,
                                    currency: event.target.value,
                                });
                                const updatedValues = getListingPricingDefaultValues(newPublicData, event.target.value);
                                reset(updatedValues);
                            }, label: intl.formatMessage({
                                id: `EditListingPricingForm.currency`,
                            }), options: Object.values(MarketplaceCurrency).map(value => ({
                                value: value,
                                label: value,
                            })), disabled: !!currencyFromPublishedListing, error: errors === null || errors === void 0 ? void 0 : errors.currency, inputRef: currency.ref, description: intl.formatMessage({
                                id: 'EditListingPricingForm.currencyTooltip',
                            }) })) }), _jsx(FormRow, { children: _jsx(InputPrice, Object.assign({ control: control, label: intl.formatMessage({
                                id: `EditListingPricingForm.pricingSeat.${category}`,
                            }), id: "pricing.seat", type: "text" }, pricingSeat, { error: (_a = errors === null || errors === void 0 ? void 0 : errors.pricing) === null || _a === void 0 ? void 0 : _a.seat })) }), _jsx(FormRow, { children: _jsx(InputPrice, Object.assign({ control: control, label: intl.formatMessage({
                                id: 'EditListingPricingForm.pricingAdult',
                            }), id: "pricingAdult", type: "text" }, pricingAdult, { error: ((_c = (_b = errors === null || errors === void 0 ? void 0 : errors.pricing) === null || _b === void 0 ? void 0 : _b.adult) === null || _c === void 0 ? void 0 : _c.message) ===
                                'ListingSchemaError.basePriceTooLow'
                                ? {
                                    message: intl.formatMessage({ id: 'ListingSchemaError.basePriceTooLow' }, {
                                        value: formatMoney(intl, new Money(LISTING_MINIMUM_PRICES[currencyValue], currencyValue)),
                                    }),
                                }
                                : (_d = errors === null || errors === void 0 ? void 0 : errors.pricing) === null || _d === void 0 ? void 0 : _d.adult, description: intl.formatMessage({
                                id: 'EditListingPricingForm.pricingAdultTooltip',
                            }) })) }), _jsx(FormRow, { children: _jsx("p", Object.assign({ style: { fontWeight: 'bold' } }, { children: intl.formatMessage({ id: 'EditListingPricingForm.basePriceCalculation' }, { basePrice: formatMoney(intl, new Money(price, currencyValue)) }) })) })] }), _jsxs(FormSection, { children: [_jsx(Text, Object.assign({ variant: TextVariant.H4, as: "h3" }, { children: intl.formatMessage({ id: 'EditListingPricingForm.extraPersons' }) })), _jsx(FormRow, { children: _jsx(InputPrice, Object.assign({ control: control, label: intl.formatMessage({
                                id: 'EditListingPricingForm.pricingTeen',
                            }), id: "pricingTeen", type: "text" }, pricingTeen, { error: (_e = errors === null || errors === void 0 ? void 0 : errors.pricing) === null || _e === void 0 ? void 0 : _e.teen, description: intl.formatMessage({
                                id: 'EditListingPricingForm.pricingTeenTooltip',
                            }) })) }), _jsx(FormRow, { children: _jsx(InputPrice, Object.assign({ control: control, label: intl.formatMessage({
                                id: 'EditListingPricingForm.pricingChild',
                            }), id: "pricingChild", type: "text" }, pricingChild, { error: (_f = errors === null || errors === void 0 ? void 0 : errors.pricing) === null || _f === void 0 ? void 0 : _f.child, description: intl.formatMessage({
                                id: 'EditListingPricingForm.pricingChildTooltip',
                            }) })) }), _jsx(FormRow, { children: _jsx(Description, { text: intl.formatMessage({
                                id: 'EditListingPricingForm.infantInfo',
                            }) }) })] }), _jsxs(FormSection, { children: [_jsx(Text, Object.assign({ variant: TextVariant.H4, as: "h3" }, { children: intl.formatMessage({
                            id: 'EditListingPricingForm.visitorsTaxTypeTitle',
                        }) })), _jsx(FormRow, { children: _jsx(SelectInput, Object.assign({ id: "pricingVisitorsTaxPolicy" }, pricingVisitorsTaxPolicy, { label: intl.formatMessage({
                                id: `EditListingPricingForm.visitorsTaxType`,
                            }), options: config.custom.visitorsTaxTypes.map(({ key, label }) => ({
                                value: key,
                                label: intl.formatMessage({
                                    id: label,
                                }),
                            })), error: (_h = (_g = errors === null || errors === void 0 ? void 0 : errors.pricing) === null || _g === void 0 ? void 0 : _g.visitorsTax) === null || _h === void 0 ? void 0 : _h.policy, inputRef: pricingVisitorsTaxPolicy.ref })) }), showTouristTaxDetails && (_jsxs(_Fragment, { children: [_jsx(FormRow, { children: _jsx(InputPrice, Object.assign({ control: control, label: intl.formatMessage({
                                        id: 'EditListingPricingForm.pricingVisitorsTaxAdult',
                                    }), id: "pricingVisitorsTaxAdult", type: "text" }, pricingVisitorsTaxAdult, { error: (_k = (_j = errors === null || errors === void 0 ? void 0 : errors.pricing) === null || _j === void 0 ? void 0 : _j.visitorsTax) === null || _k === void 0 ? void 0 : _k.adult, description: intl.formatMessage({
                                        id: 'EditListingPricingForm.pricingVisitorsTaxAdultTooltip',
                                    }) })) }), _jsx(FormRow, { children: _jsx(InputPrice, Object.assign({ control: control, label: intl.formatMessage({
                                        id: 'EditListingPricingForm.pricingVisitorsTaxTeen',
                                    }), id: "pricingVisitorsTaxTeen", type: "text" }, pricingVisitorsTaxTeen, { error: (_m = (_l = errors === null || errors === void 0 ? void 0 : errors.pricing) === null || _l === void 0 ? void 0 : _l.visitorsTax) === null || _m === void 0 ? void 0 : _m.teen, description: intl.formatMessage({
                                        id: 'EditListingPricingForm.pricingVisitorsTaxTeenTooltip',
                                    }) })) }), _jsx(FormRow, { children: _jsx(InputPrice, Object.assign({ control: control, label: intl.formatMessage({
                                        id: 'EditListingPricingForm.pricingVisitorsTaxChild',
                                    }), id: "pricingVisitorsTaxChild", type: "text" }, pricingVisitorsTaxChild, { error: (_p = (_o = errors === null || errors === void 0 ? void 0 : errors.pricing) === null || _o === void 0 ? void 0 : _o.visitorsTax) === null || _p === void 0 ? void 0 : _p.child, description: intl.formatMessage({
                                        id: 'EditListingPricingForm.pricingVisitorsTaxChildTooltip',
                                    }) })) })] }))] }), showCleaningFeeSection && (_jsxs(FormSection, { children: [_jsx(Text, Object.assign({ variant: TextVariant.H4, as: "h3" }, { children: intl.formatMessage({
                            id: 'EditListingPricingForm.cleaningFeeTitle',
                        }) })), _jsx(FormRow, { children: _jsx(SelectInput, Object.assign({ id: "cleaningFeePolicy" }, cleaningFeePolicy, { label: intl.formatMessage({
                                id: `EditListingPricingForm.cleaningFeeType`,
                            }), options: config.custom.cleaningFeeTypes.map(({ key, label }) => ({
                                value: key,
                                label: intl.formatMessage({
                                    id: label,
                                }),
                            })), error: (_q = errors === null || errors === void 0 ? void 0 : errors.cleaningFee) === null || _q === void 0 ? void 0 : _q.policy, inputRef: cleaningFeePolicy.ref })) }), showCleaningFeeDetails && (_jsx(FormRow, { children: _jsx(InputPrice, Object.assign({ control: control, label: intl.formatMessage({
                                id: 'EditListingPricingForm.cleaningFee',
                            }), id: "cleaningFeePrice", type: "text" }, cleaningFeePrice, { error: (_r = errors === null || errors === void 0 ? void 0 : errors.cleaningFee) === null || _r === void 0 ? void 0 : _r.price })) }))] })), _jsxs(FormSection, { children: [_jsx(Text, Object.assign({ variant: TextVariant.H4, as: "h3" }, { children: intl.formatMessage({
                            id: 'EditListingPricingForm.bookingBreakdownTitle',
                        }) })), _jsx(FormRow, { children: _jsx(Description, { text: intl.formatMessage({
                                id: 'EditListingPricingForm.bookingBreakdownSubtitle',
                            }, { zeroValue: formatMoney(intl, new Money(0, currencyValue)) }) }) }), _jsx(FormRow, { children: 0 < ((_s = groupedLineItems[LINE_ITEM_GROUP.BASE_PRICE]) === null || _s === void 0 ? void 0 : _s.length) && (_jsx(Card, { children: _jsx(BookingBreakdown, { currency: currencyValue, totalPrice: totalPriceNumber, totalProviderPayout: totalProviderPayout, totalProviderEarnings: totalProviderEarnings, category: category, lineItems: groupedLineItems, nightsBooked: 1, bookingStart: bookingStart, bookingEnd: bookingEnd, totalGiftcardPayin: 0 }) })) })] }), _jsx(Button, { label: submitButtonLabel, colorVariant: ButtonColorVariant.Dark, sizeVariant: ButtonSizeVariant.Small, type: "submit", inProgress: updateListingInProgress }), updateListingError && (_jsx(FormErrorMessage, { id: "EditListingDescriptionForm.updateFailed" }))] })));
};
export default EditListingPricingForm;
