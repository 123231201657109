import { DEFAULT_LOCALE } from '@nomady/shared/marketplace-custom-config';
import { MarketplaceLocale } from '@nomady/shared/config';
import { isBrowser } from '@nomady/shared/utils/env';
export const getUserDefaultLocale = () => {
    if (!isBrowser()) {
        return DEFAULT_LOCALE;
    }
    const { languages } = navigator;
    const reversedLanguages = [...languages].reverse();
    let userLanguage = DEFAULT_LOCALE;
    reversedLanguages.forEach(language => {
        if (language.startsWith('en')) {
            userLanguage = MarketplaceLocale['en-US'];
            return;
        }
        if (language.startsWith('de-CH')) {
            userLanguage = MarketplaceLocale['de-CH'];
            return;
        }
        if (language.startsWith('de-AT')) {
            userLanguage = MarketplaceLocale['de-AT'];
            return;
        }
        if (language.startsWith('de')) {
            userLanguage = MarketplaceLocale['de-DE'];
            return;
        }
        if (language.startsWith('fr-CH')) {
            userLanguage = MarketplaceLocale['fr-CH'];
            return;
        }
        if (language.startsWith('fr')) {
            userLanguage = MarketplaceLocale['fr-FR'];
            return;
        }
        if (language.startsWith('it-CH')) {
            userLanguage = MarketplaceLocale['it-CH'];
            return;
        }
        if (language.startsWith('it')) {
            userLanguage = MarketplaceLocale['it-IT'];
        }
    });
    return userLanguage;
};
export const getRequestDefaultLocale = (request) => {
    const urlParts = request.originalUrl.split('/');
    const langPrefix = 0 < urlParts.length && urlParts[1];
    if (langPrefix === 'en' || langPrefix === 'en-US') {
        return MarketplaceLocale['en-US'];
    }
    if (langPrefix === 'de-CH') {
        return MarketplaceLocale['de-CH'];
    }
    if (langPrefix === 'de-AT') {
        return MarketplaceLocale['de-AT'];
    }
    if (langPrefix === 'de' || langPrefix === 'de-DE') {
        return MarketplaceLocale['de-DE'];
    }
    if (langPrefix === 'fr-CH') {
        return MarketplaceLocale['fr-CH'];
    }
    if (langPrefix === 'fr' || langPrefix === 'fr-FR') {
        return MarketplaceLocale['fr-FR'];
    }
    if (langPrefix === 'it-CH') {
        return MarketplaceLocale['it-CH'];
    }
    if (langPrefix === 'it' || langPrefix === 'it-IT') {
        return MarketplaceLocale['it-IT'];
    }
    const preferredLanguage = request.acceptsLanguages('en', 'en-US', 'de-CH', 'de-AT', 'de-DE', 'de', 'fr-CH', 'fr-FR', 'fr', 'it-IT', 'it-CH', 'it');
    if (preferredLanguage) {
        if (preferredLanguage.startsWith('en')) {
            return MarketplaceLocale['en-US'];
        }
        if (preferredLanguage.startsWith('de-CH')) {
            return MarketplaceLocale['de-CH'];
        }
        if (preferredLanguage.startsWith('de-AT')) {
            return MarketplaceLocale['de-AT'];
        }
        if (preferredLanguage.startsWith('de')) {
            return MarketplaceLocale['de-DE'];
        }
        if (preferredLanguage.startsWith('it-CH')) {
            return MarketplaceLocale['it-CH'];
        }
        if (preferredLanguage.startsWith('it')) {
            return MarketplaceLocale['it-IT'];
        }
        if (preferredLanguage.startsWith('fr-CH')) {
            return MarketplaceLocale['fr-CH'];
        }
        if (preferredLanguage.startsWith('fr')) {
            return MarketplaceLocale['fr-FR'];
        }
    }
    return DEFAULT_LOCALE;
};
