import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styled from 'styled-components/macro';
import Icon, { IconSizeVariant } from '../Icon/Icon';
import { ColorVariant } from '../../theme';
import { NamedLink } from '../../components';
import routeConfiguration from '@nomady/shared/routeConfiguration/routeConfiguration';
import Text, { TextVariant } from '../Text/Text';
import { Link } from 'react-router-dom';
export var IconButtonColorVariant;
(function (IconButtonColorVariant) {
    IconButtonColorVariant["Light"] = "Light";
    IconButtonColorVariant["Dark"] = "Dark";
    IconButtonColorVariant["Green"] = "Green";
    IconButtonColorVariant["Snow"] = "Snow";
    IconButtonColorVariant["Red"] = "Red";
    IconButtonColorVariant["Black"] = "Black";
})(IconButtonColorVariant || (IconButtonColorVariant = {}));
export var IconButtonSizeVariant;
(function (IconButtonSizeVariant) {
    IconButtonSizeVariant["ExtraSmall"] = "40px";
    IconButtonSizeVariant["Small"] = "48px";
    IconButtonSizeVariant["Medium"] = "64px";
    IconButtonSizeVariant["Large"] = "120px";
})(IconButtonSizeVariant || (IconButtonSizeVariant = {}));
export var IconButtonRadiusVariant;
(function (IconButtonRadiusVariant) {
    IconButtonRadiusVariant["Small"] = "8px";
    IconButtonRadiusVariant["Default"] = "16px";
})(IconButtonRadiusVariant || (IconButtonRadiusVariant = {}));
const IconButtonElement = styled.button `
  cursor: pointer;
  border-radius: ${props => props.radiusVariant};
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${props => props.iconButtonSizeVariant};
  width: ${props => props.iconButtonSizeVariant};
  border: 0;
  flex-shrink: 0;
  background-color: ${props => props.theme.color[props.iconColorObject.default.backgroundColor]};
  opacity: ${props => (props.transparent ? 0.8 : 1)};
  pointer-events: auto;

  ${props => !props.forceActive &&
    `
    &:not(:disabled) {
      &:hover {
        background-color: ${props.theme.color[props.iconColorObject.hover.backgroundColor]};
        box-shadow: inset 0 0 0 1px ${props.theme.color[props.iconColorObject.hover.borderColor]};
      }

      &:active {
        background-color: ${props.theme.color[props.iconColorObject.active.backgroundColor]};
        box-shadow: 0px 0px 5px -1px ${props.theme.color.functionElectricVioletActive},
          inset 0 0 0 1px ${props.theme.color.functionElectricVioletActive};
      }
    }
  `}

  ${props => props.forceActive &&
    `background-color: ${props.theme.color[props.iconColorObject.active.backgroundColor]};
    box-shadow: 0px 0px 5px -1px ${props.theme.color.functionElectricVioletActive}, inset 0 0 0 1px ${props.theme.color.functionElectricVioletActive};
  `}

  &:disabled {
    background-color: ${props => props.theme.color[props.iconColorObject.disabled.backgroundColor]};
    cursor: not-allowed;
  }
`;
const colorMapping = {
    [IconButtonColorVariant.Light]: {
        default: {
            backgroundColor: ColorVariant.BasicPearlWhiteTypeWhite,
            iconColor: ColorVariant.BasicCaveBlack,
        },
        hover: {
            backgroundColor: ColorVariant.BasicPearlWhiteTypeWhite,
            borderColor: ColorVariant.FunctionStoneGreyInactive,
        },
        active: {
            backgroundColor: ColorVariant.BasicPearlWhiteTypeWhite,
        },
        disabled: {
            backgroundColor: ColorVariant.BasicPearlWhiteTypeWhite,
            iconColor: ColorVariant.FunctionFogGreyInactive,
        },
    },
    [IconButtonColorVariant.Dark]: {
        default: {
            backgroundColor: ColorVariant.FunctionColeGrey,
            iconColor: ColorVariant.BasicSnowGrey,
        },
        hover: {
            backgroundColor: ColorVariant.FunctionColeGrey,
            borderColor: ColorVariant.FunctionStoneGreyInactive,
        },
        active: {
            backgroundColor: ColorVariant.FunctionColeGrey,
        },
        disabled: {
            backgroundColor: ColorVariant.FunctionColeGrey,
            iconColor: ColorVariant.BasicCaveBlack,
        },
    },
    [IconButtonColorVariant.Green]: {
        default: {
            backgroundColor: ColorVariant.BasicPearlWhiteTypeWhite,
            iconColor: ColorVariant.BasicCaveBlack,
        },
        hover: {
            backgroundColor: ColorVariant.FunctionSuccess1,
            borderColor: ColorVariant.FunctionSuccess1,
        },
        active: {
            backgroundColor: ColorVariant.FunctionSuccess2,
        },
        disabled: {
            backgroundColor: ColorVariant.BasicPearlWhiteTypeWhite,
            iconColor: ColorVariant.FunctionFogGreyInactive,
        },
    },
    [IconButtonColorVariant.Snow]: {
        default: {
            backgroundColor: ColorVariant.BasicSnowGrey,
            iconColor: ColorVariant.BasicCaveBlack,
        },
        hover: {
            backgroundColor: ColorVariant.BasicSnowGrey,
            borderColor: ColorVariant.FunctionStoneGreyInactive,
        },
        active: {
            backgroundColor: ColorVariant.BasicSnowGrey,
        },
        disabled: {
            backgroundColor: ColorVariant.BasicPearlWhiteTypeWhite,
            iconColor: ColorVariant.FunctionFogGreyInactive,
        },
    },
    [IconButtonColorVariant.Red]: {
        default: {
            backgroundColor: ColorVariant.BasicPearlWhiteTypeWhite,
            iconColor: ColorVariant.PrimaryCinnabarRed,
        },
        hover: {
            backgroundColor: ColorVariant.BasicPearlWhiteTypeWhite,
            borderColor: ColorVariant.PrimaryCinnabarRed,
        },
        active: {
            backgroundColor: ColorVariant.BasicPearlWhiteTypeWhite,
        },
        disabled: {
            backgroundColor: ColorVariant.FunctionFogGreyInactive,
            iconColor: ColorVariant.BasicCaveBlack,
        },
    },
    [IconButtonColorVariant.Black]: {
        default: {
            backgroundColor: ColorVariant.BasicRavenBlack,
            iconColor: ColorVariant.BasicSnowGrey,
        },
        hover: {
            backgroundColor: ColorVariant.BasicRavenBlack,
            borderColor: ColorVariant.BasicSnowGrey,
        },
        active: {
            backgroundColor: ColorVariant.BasicRavenBlack,
        },
        disabled: {
            backgroundColor: ColorVariant.BasicRavenBlack,
            iconColor: ColorVariant.FunctionStoneGreyInactive,
        },
    },
};
const getIconSize = (sizeVariant) => {
    switch (sizeVariant) {
        case IconButtonSizeVariant.ExtraSmall:
            return IconSizeVariant.Medium;
        case IconButtonSizeVariant.Small:
            return IconSizeVariant.Small;
        case IconButtonSizeVariant.Medium:
            return IconSizeVariant.Medium;
        case IconButtonSizeVariant.Large:
            return IconSizeVariant.Large;
        default:
            return IconSizeVariant.MediumLarge;
    }
};
const IconButton = ({ iconVariant, iconText, iconButtonSizeVariant = IconButtonSizeVariant.Small, colorVariant = IconButtonColorVariant.Light, onClick, targetRoute, targetRouteParams, searchParams, link, ariaLabel, ariaExpanded, ariaControls, ariaHasPopUp, disabled = false, forceActive = false, type, radiusVariant = IconButtonRadiusVariant.Default, transparent, }) => {
    const buttonContent = (_jsxs(IconButtonElement, Object.assign({ type: type, onClick: onClick, disabled: disabled, "aria-label": ariaLabel, "aria-expanded": ariaExpanded, "aria-controls": ariaControls, "aria-haspopup": ariaHasPopUp, iconButtonSizeVariant: iconButtonSizeVariant, iconColorObject: colorMapping[colorVariant], forceActive: forceActive, radiusVariant: radiusVariant, transparent: transparent }, { children: [iconVariant && (_jsx(Icon, { variant: iconVariant, colorVariant: colorMapping[colorVariant][disabled ? 'disabled' : 'default']
                    .iconColor, sizeVariant: getIconSize(iconButtonSizeVariant) })), iconText && (_jsx(Text, Object.assign({ variant: TextVariant.L1SB, colorVariant: ColorVariant.BasicCaveBlack }, { children: iconText })))] })));
    if (targetRoute && routeConfiguration) {
        return (_jsx(NamedLink, Object.assign({ name: targetRoute, params: targetRouteParams, searchParams: searchParams }, { children: buttonContent })));
    }
    if (link) {
        return _jsx(Link, Object.assign({}, link, { children: buttonContent }));
    }
    return buttonContent;
};
export default IconButton;
