import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styled from 'styled-components/macro';
import { useIntl } from 'react-intl';
import Modal from '../../../styled-components/Modal/Modal';
import Button from '../../../styled-components/Button/Button';
import Text from '../../../styled-components/Text/Text';
import Icon, { IconSizeVariant, IconVariant, } from '../../../styled-components/Icon/Icon';
const ModalContentWrapper = styled.div `
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
const IconWrapper = styled.div `
  padding: 0 15px 15px;
  width: fit-content;
  margin-bottom: -5px;
`;
const ExpiredPaymentModal = ({ onClose, onAccept, showModal }) => {
    const intl = useIntl();
    return (_jsx(Modal, Object.assign({ id: 'expiredPaymentModal', isOpen: showModal, onClose: onClose, title: intl.formatMessage({
            id: 'ExpiredPaymentModal.title',
        }) }, { children: _jsxs(ModalContentWrapper, { children: [_jsx(IconWrapper, { children: _jsx(Icon, { variant: IconVariant.Clock, sizeVariant: IconSizeVariant.Large }) }), _jsx(Text, { children: intl.formatMessage({
                        id: 'ExpiredPaymentModal.description',
                    }) }), _jsx(Button, { onClick: onAccept, label: intl.formatMessage({
                        id: 'ExpiredPaymentModal.goBackToListing',
                    }) })] }) })));
};
export default ExpiredPaymentModal;
