import listingFavoritesLists from './listingFavoritesListsSlice';
import listingSearch from './listingSearchSlice';
import UI from './UISlice';
import cmsData from './cmsDataSlice';
import EmailVerification from '../containers/EmailVerificationPage/EmailVerificationPageSlice';
import geolocation from './geolocationSlice';
import Routing from './routingSlice';
import SnackBar from '../components/SnackBar/SnackBarSlice';
export const SliceReducers = {
    listingFavoritesLists,
    listingSearch,
    UI,
    cmsData,
    EmailVerification,
    geolocation,
    Routing,
    SnackBar,
};
