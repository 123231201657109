import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import styled from 'styled-components/macro';
import Text, { TextVariant } from '../Text/Text';
const StyledButton = styled.button `
  background-color: ${props => props.theme.color.transparent};
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  outline: none;
`;
const StyledText = styled(Text) `
  border-bottom: 1px solid ${props => props.theme.color.cave};
  padding-bottom: 0;
`;
const TertiaryButton = ({ label, onClick }) => {
    return (_jsx(StyledButton, Object.assign({ onClick: onClick }, { children: _jsx(StyledText, Object.assign({ variant: TextVariant.H52 }, { children: label })) })));
};
export default TertiaryButton;
