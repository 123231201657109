import { createSlug } from '@nomady/shared/utils/urlHelpers';
import { createResourceLocatorString } from '@nomady/shared/utils/routes';
import routeConfiguration from '@nomady/shared/routeConfiguration/routeConfiguration';
import { getMarketplaceDomain, getDomainWithProtocol, } from '@nomady/shared/utils/localized-domains';
import config from '../../config';
const createSearchResultSchema = (listings, address, locale, intl) => {
    const { siteTitle } = config;
    const searchAddress = address || intl.formatMessage({ id: 'SearchPage.schemaMapSearch' });
    const schemaDescription = intl.formatMessage({
        id: 'SearchPage.schemaDescription',
    });
    const schemaTitle = intl.formatMessage({ id: 'SearchPage.schemaTitle' }, { searchAddress, siteTitle }) + config.custom.seoSuffix;
    const domain = getMarketplaceDomain();
    const domainWithProtocol = getDomainWithProtocol(domain);
    const schemaListings = listings.map((l, i) => {
        const { title } = l.attributes;
        const pathToItem = createResourceLocatorString('ListingPage', routeConfiguration, {
            id: l.id.uuid,
            slug: createSlug(title),
        });
        return {
            '@type': 'ListItem',
            position: i,
            url: `${domainWithProtocol}${pathToItem}`,
            name: title,
        };
    });
    const schemaMainEntity = JSON.stringify({
        '@type': 'ItemList',
        name: searchAddress,
        itemListOrder: 'https://schema.org/ItemListOrderAscending',
        itemListElement: schemaListings,
    });
    return {
        title: schemaTitle,
        description: schemaDescription,
        schema: {
            '@context': 'https://schema.org',
            '@type': 'SearchResultsPage',
            description: schemaDescription,
            name: schemaTitle,
            mainEntity: [schemaMainEntity],
        },
    };
};
export default createSearchResultSchema;
