import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import CheckboxInputSection from '../../CheckboxInputSection/CheckboxInputSection';
import FilterGroup from '../../FilterGroup/FilterGroup';
import { IconVariant } from '../../Icon/Icon';
const ActivitiesFilterGroup = ({ currentFeaturesSearchQuery, intl, onSetFeatures, }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5;
    return (_jsxs(FilterGroup, Object.assign({ iconVariant: IconVariant.Boot, label: intl.formatMessage({
            id: 'SearchBarSheet.Features.Activities.title',
        }) }, { children: [_jsx(CheckboxInputSection, { label: intl.formatMessage({
                    id: 'SearchBarSheet.Features.Activities.title_summer',
                }), checkboxes: [
                    {
                        id: 'activities-summer-biking',
                        checked: !!((_b = (_a = currentFeaturesSearchQuery.activities) === null || _a === void 0 ? void 0 : _a.summer) === null || _b === void 0 ? void 0 : _b.biking),
                        label: intl.formatMessage({ id: 'MarketplaceConfig.bike' }),
                        onChange: (value) => onSetFeatures({
                            activities: { summer: { biking: value } },
                        }),
                    },
                    {
                        id: 'activities-summer-hiking',
                        checked: !!((_d = (_c = currentFeaturesSearchQuery.activities) === null || _c === void 0 ? void 0 : _c.summer) === null || _d === void 0 ? void 0 : _d.hiking),
                        label: intl.formatMessage({ id: 'MarketplaceConfig.hike' }),
                        onChange: (value) => onSetFeatures({
                            activities: { summer: { hiking: value } },
                        }),
                    },
                    {
                        id: 'activities-summer-climbing',
                        checked: !!((_f = (_e = currentFeaturesSearchQuery.activities) === null || _e === void 0 ? void 0 : _e.summer) === null || _f === void 0 ? void 0 : _f.climbing),
                        label: intl.formatMessage({ id: 'MarketplaceConfig.climb' }),
                        onChange: (value) => onSetFeatures({
                            activities: { summer: { climbing: value } },
                        }),
                    },
                    {
                        id: 'activities-summer-canoeing',
                        checked: !!((_h = (_g = currentFeaturesSearchQuery.activities) === null || _g === void 0 ? void 0 : _g.summer) === null || _h === void 0 ? void 0 : _h.canoeing),
                        label: intl.formatMessage({ id: 'MarketplaceConfig.paddle' }),
                        onChange: (value) => onSetFeatures({
                            activities: { summer: { canoeing: value } },
                        }),
                    },
                    {
                        id: 'activities-summer-swimming',
                        checked: !!((_k = (_j = currentFeaturesSearchQuery.activities) === null || _j === void 0 ? void 0 : _j.summer) === null || _k === void 0 ? void 0 : _k.swimming),
                        label: intl.formatMessage({ id: 'MarketplaceConfig.swim' }),
                        onChange: (value) => onSetFeatures({
                            activities: { summer: { swimming: value } },
                        }),
                    },
                    {
                        id: 'activities-summer-fishing',
                        checked: !!((_m = (_l = currentFeaturesSearchQuery.activities) === null || _l === void 0 ? void 0 : _l.summer) === null || _m === void 0 ? void 0 : _m.fishing),
                        label: intl.formatMessage({ id: 'MarketplaceConfig.fishing' }),
                        onChange: (value) => onSetFeatures({
                            activities: { summer: { fishing: value } },
                        }),
                    },
                    {
                        id: 'activities-summer-observe-wild-animals',
                        checked: !!((_p = (_o = currentFeaturesSearchQuery.activities) === null || _o === void 0 ? void 0 : _o.summer) === null || _p === void 0 ? void 0 : _p.observeWildAnimals),
                        label: intl.formatMessage({
                            id: 'MarketplaceConfig.animalWatching',
                        }),
                        onChange: (value) => onSetFeatures({
                            activities: { summer: { observeWildAnimals: value } },
                        }),
                    },
                ] }), _jsx(CheckboxInputSection, { label: intl.formatMessage({
                    id: 'SearchBarSheet.Features.Activities.title_winter',
                }), checkboxes: [
                    {
                        id: 'activities-winter-skiing',
                        checked: !!((_r = (_q = currentFeaturesSearchQuery.activities) === null || _q === void 0 ? void 0 : _q.winter) === null || _r === void 0 ? void 0 : _r.skiing),
                        label: intl.formatMessage({ id: 'MarketplaceConfig.skiing' }),
                        onChange: (value) => onSetFeatures({
                            activities: { winter: { skiing: value } },
                        }),
                    },
                    {
                        id: 'activities-winter-langlauf',
                        checked: !!((_t = (_s = currentFeaturesSearchQuery.activities) === null || _s === void 0 ? void 0 : _s.winter) === null || _t === void 0 ? void 0 : _t.langlauf),
                        label: intl.formatMessage({ id: 'MarketplaceConfig.langlauf' }),
                        onChange: (value) => onSetFeatures({
                            activities: { winter: { langlauf: value } },
                        }),
                    },
                    {
                        id: 'activities-winter-ski-touring',
                        checked: !!((_v = (_u = currentFeaturesSearchQuery.activities) === null || _u === void 0 ? void 0 : _u.winter) === null || _v === void 0 ? void 0 : _v.skiTouring),
                        label: intl.formatMessage({ id: 'MarketplaceConfig.skiTouring' }),
                        onChange: (value) => onSetFeatures({
                            activities: { winter: { skiTouring: value } },
                        }),
                    },
                    {
                        id: 'activities-winter-snow-hiking',
                        checked: !!((_x = (_w = currentFeaturesSearchQuery.activities) === null || _w === void 0 ? void 0 : _w.winter) === null || _x === void 0 ? void 0 : _x.snowHiking),
                        label: intl.formatMessage({
                            id: 'MarketplaceConfig.snowShoeHiking',
                        }),
                        onChange: (value) => onSetFeatures({
                            activities: { winter: { snowHiking: value } },
                        }),
                    },
                    {
                        id: 'activities-winter-hockey',
                        checked: !!((_z = (_y = currentFeaturesSearchQuery.activities) === null || _y === void 0 ? void 0 : _y.winter) === null || _z === void 0 ? void 0 : _z.hockey),
                        label: intl.formatMessage({ id: 'MarketplaceConfig.hockey' }),
                        onChange: (value) => onSetFeatures({
                            activities: { winter: { hockey: value } },
                        }),
                    },
                    {
                        id: 'activities-winter-ice-rink',
                        checked: !!((_1 = (_0 = currentFeaturesSearchQuery.activities) === null || _0 === void 0 ? void 0 : _0.winter) === null || _1 === void 0 ? void 0 : _1.iceRink),
                        label: intl.formatMessage({ id: 'MarketplaceConfig.iceRink' }),
                        onChange: (value) => onSetFeatures({
                            activities: { winter: { iceRink: value } },
                        }),
                    },
                    {
                        id: 'activities-winter-sledding',
                        checked: !!((_3 = (_2 = currentFeaturesSearchQuery.activities) === null || _2 === void 0 ? void 0 : _2.winter) === null || _3 === void 0 ? void 0 : _3.sledding),
                        label: intl.formatMessage({ id: 'MarketplaceConfig.sleeding' }),
                        onChange: (value) => onSetFeatures({
                            activities: { winter: { sledding: value } },
                        }),
                    },
                    {
                        id: 'activities-winter-observe-building-igloos',
                        checked: !!((_5 = (_4 = currentFeaturesSearchQuery.activities) === null || _4 === void 0 ? void 0 : _4.winter) === null || _5 === void 0 ? void 0 : _5.buildingIgloos),
                        label: intl.formatMessage({
                            id: 'MarketplaceConfig.iglooBuilding',
                        }),
                        onChange: (value) => onSetFeatures({
                            activities: { winter: { buildingIgloos: value } },
                        }),
                    },
                ] })] })));
};
export default ActivitiesFilterGroup;
