import * as z from 'zod';
import { ArrivalType, CampCategory, CancellationType, DogPolicy, } from '../../types';
import { localizedZodCustomErrorMap } from '../localizedZodCustomErrorMap';
import { parseTimeString } from '../../utils/listing';
const DEFAULT_MIN_BOOKABLE_NIGHTS = 1;
const DEFAULT_MAX_BOOKABLE_NIGHTS = 1;
const DEFAULT_CHECKIN = '12:00';
const DEFAULT_LATEST_CHECKIN = '22:00';
const DEFAULT_CHECKOUT = '10:00';
const DEFAULT_INSTANT_BOOKING_AVAILABLE = true;
const DEFAULT_DOGS_ALLOWED = true;
const DEFAULT_CANCELLATION_POLICY_TYPE = {
    [CampCategory.accommodation]: CancellationType.medium,
    [CampCategory.camping]: CancellationType.easy,
};
export const MIN_AMOUNT_NIGHTS = 0;
export const MAX_AMOUNT_NIGHTS = 365;
export const RULES_ITEM_MAX_LENGTH = 1000;
export const MAX_RULES = 6;
const TIME_REGEX = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
const DogsAllowedSchema = z.object({
    allowed: z.literal(true),
    policies: z.array(z.nativeEnum(DogPolicy)).nullish(),
});
const DogsNotAllowedSchema = z.object({
    allowed: z.literal(false),
});
export const ListingRulesSchema = z
    .object({
    minBookableNights: z.coerce
        .number()
        .int()
        .gte(MIN_AMOUNT_NIGHTS)
        .lte(MAX_AMOUNT_NIGHTS),
    maxBookableNights: z.coerce
        .number()
        .int()
        .gte(MIN_AMOUNT_NIGHTS)
        .lte(MAX_AMOUNT_NIGHTS),
    checkIn: z.string().refine(value => TIME_REGEX.test(value), {
        message: 'SchemaError.time.invalid_value',
    }),
    latestCheckIn: z.string().refine(value => TIME_REGEX.test(value), {
        message: 'SchemaError.time.invalid_value',
    }),
    checkOut: z.string().refine(value => TIME_REGEX.test(value), {
        message: 'SchemaError.time.invalid_value',
    }),
    arrivalType: z.nativeEnum(ArrivalType),
    instantBookingAvailable: z
        .union([z.boolean(), z.literal('true'), z.literal('false')])
        .transform(value => value === true || value === 'true'),
    summerActivitiesDescription: z.string().nullish(),
    cancellationPolicyType: z.nativeEnum(CancellationType),
    dogs: z.discriminatedUnion('allowed', [
        DogsAllowedSchema,
        DogsNotAllowedSchema,
    ]),
    rules: z
        .array(z.string().max(RULES_ITEM_MAX_LENGTH))
        .max(MAX_RULES)
        .nullish(),
})
    .refine(data => data.maxBookableNights === 0 ||
    data.minBookableNights === 0 ||
    data.minBookableNights <= data.maxBookableNights, {
    message: 'ListingSchemaError.minBookableNights.mismatch',
    path: ['minBookableNights'],
})
    .refine(data => parseTimeString(data.latestCheckIn).isAfter(parseTimeString(data.checkIn)), {
    message: 'ListingSchemaError.checkIn.mismatch',
    path: ['checkIn'],
});
export const getListingRulesSchema = (intl) => {
    z.setErrorMap(localizedZodCustomErrorMap(intl, 'ListingSchemaError'));
    return ListingRulesSchema;
};
export const getListingRulesDefaultValues = (attributes) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y;
    return ({
        minBookableNights: (_b = (_a = attributes === null || attributes === void 0 ? void 0 : attributes.publicData) === null || _a === void 0 ? void 0 : _a.minBookableNights) !== null && _b !== void 0 ? _b : DEFAULT_MIN_BOOKABLE_NIGHTS,
        maxBookableNights: (_d = (_c = attributes === null || attributes === void 0 ? void 0 : attributes.publicData) === null || _c === void 0 ? void 0 : _c.maxBookableNights) !== null && _d !== void 0 ? _d : DEFAULT_MAX_BOOKABLE_NIGHTS,
        checkIn: (_f = (_e = attributes === null || attributes === void 0 ? void 0 : attributes.publicData) === null || _e === void 0 ? void 0 : _e.checkIn) !== null && _f !== void 0 ? _f : DEFAULT_CHECKIN,
        latestCheckIn: (_h = (_g = attributes === null || attributes === void 0 ? void 0 : attributes.publicData) === null || _g === void 0 ? void 0 : _g.latestCheckIn) !== null && _h !== void 0 ? _h : DEFAULT_LATEST_CHECKIN,
        checkOut: (_k = (_j = attributes === null || attributes === void 0 ? void 0 : attributes.publicData) === null || _j === void 0 ? void 0 : _j.checkOut) !== null && _k !== void 0 ? _k : DEFAULT_CHECKOUT,
        arrivalType: (_m = (_l = attributes === null || attributes === void 0 ? void 0 : attributes.publicData) === null || _l === void 0 ? void 0 : _l.arrivalType) !== null && _m !== void 0 ? _m : ArrivalType.direct,
        instantBookingAvailable: (_p = (_o = attributes === null || attributes === void 0 ? void 0 : attributes.publicData) === null || _o === void 0 ? void 0 : _o.instantBookingAvailable) !== null && _p !== void 0 ? _p : DEFAULT_INSTANT_BOOKING_AVAILABLE,
        cancellationPolicyType: (_r = (_q = attributes === null || attributes === void 0 ? void 0 : attributes.publicData) === null || _q === void 0 ? void 0 : _q.cancellationPolicyType) !== null && _r !== void 0 ? _r : DEFAULT_CANCELLATION_POLICY_TYPE[(attributes === null || attributes === void 0 ? void 0 : attributes.publicData.category) || CampCategory.camping],
        dogs: {
            allowed: (_t = (_s = attributes === null || attributes === void 0 ? void 0 : attributes.publicData) === null || _s === void 0 ? void 0 : _s.dogsAllowed) !== null && _t !== void 0 ? _t : DEFAULT_DOGS_ALLOWED,
            policies: (_v = (_u = attributes === null || attributes === void 0 ? void 0 : attributes.publicData) === null || _u === void 0 ? void 0 : _u.dogPolicies) !== null && _v !== void 0 ? _v : [],
        },
        rules: (_y = (_x = (_w = attributes === null || attributes === void 0 ? void 0 : attributes.publicData) === null || _w === void 0 ? void 0 : _w.localizedPublicData) === null || _x === void 0 ? void 0 : _x.rules) !== null && _y !== void 0 ? _y : [],
    });
};
