import moment from 'moment';
/**
 * Input names for the DateRangePicker from react-dates.
 */
export const START_DATE = 'startDate';
export const END_DATE = 'endDate';
/**
 * Check that the given parameter is a Date object.
 *
 * @param {Date} object that should be a Date.
 *
 * @returns {boolean} true if given parameter is a Date object.
 */
export const isDate = (d) => d &&
    Object.prototype.toString.call(d) === '[object Date]' &&
    !Number.isNaN(d.getTime());
/**
 * Check if the given parameters represent the same Date value (timestamps are compared)
 *
 * @param {Date} first param that should be a Date and it should have same timestamp as second param.
 * @param {Date} second param that should be a Date and it should have same timestamp as second param.
 *
 * @returns {boolean} true if given parameters have the same timestamp.
 */
export const isSameDate = (a, b) => a && isDate(a) && b && isDate(b) && a.getTime() === b.getTime();
/**
 * Convert date given by API to something meaningful noon on browser's timezone
 * So, what happens is that date given by client
 * ("Fri Mar 30 2018 12:00:00 GMT-1100 (SST)" aka "Fri Mar 30 2018 23:00:00 GMT+0000 (UTC)")
 * will be read as UTC time. Then API normalizes night/day bookings to
 * start from 00:00 UTC (i.e. discards hours from UTC day).
 * So Api gives 00:00 UTC which (in our example) would be locally
 * "Thu Mar 29 2018 13:00:00 GMT-1100 (SST)".
 *
 * The resulting timestamp from API is:
 * localTimestamp.subtract(12h).add(timezoneoffset) (in eg. -23 h)
 *
 * So, this function adds those removed hours back.
 *
 * @param {Date} date is a local date object
 *
 * @returns {Date} date (given by API as UTC 00:00) converted back to local noon.
 */
export const dateFromAPIToLocalNoon = (date) => {
    const timezoneDiffInMinutes = moment(date).utcOffset();
    // Example timezone SST:
    // We get a Fri 00:00 UTC aka "Thu Mar 29 2018 13:00:00 GMT-1100 (SST)"
    // We need to subtract timezone difference (-11h), effectively adding 11h - to get to correct date
    const momentInLocalTimezone = moment(date).subtract(timezoneDiffInMinutes, 'minutes');
    // To be on the safe zone with leap seconds and stuff when using day / night picker
    // we'll add 12 h to get to the noon of day in local timezone.
    return momentInLocalTimezone.add(12, 'hours').toDate();
};
/**
 * Convert date given by API to something meaningful noon on browser's timezone
 * So, what happens is that date given by client
 * ("Fri Mar 30 2018 12:00:00 GMT-1100 (SST)" aka "Fri Mar 30 2018 23:00:00 GMT+0000 (UTC)")
 * will be read as UTC time. Then API normalizes night/day bookings to
 * start from 00:00 UTC (i.e. discards hours from UTC day).
 * So Api gives 00:00 UTC which (in our example) would be locally
 * "Thu Mar 29 2018 13:00:00 GMT-1100 (SST)".
 *
 * The resulting timestamp from API is:
 * localTimestamp.subtract(12h).add(timezoneoffset) (in eg. -23 h)
 *
 * So, this function adds those removed hours back.
 *
 * @param {Date} date is a local date object
 *
 * @returns {Date} date (given by API as UTC 00:00) converted back to local noon.
 */
export const dateFromAPIToLocal = (date) => {
    const timezoneDiffInMinutes = moment(date).utcOffset();
    // Example timezone SST:
    // We get a Fri 00:00 UTC aka "Thu Mar 29 2018 13:00:00 GMT-1100 (SST)"
    // We need to subtract timezone difference (-11h), effectively adding 11h - to get to correct date
    const momentInLocalTimezone = moment(date).subtract(timezoneDiffInMinutes, 'minutes');
    // To be on the safe zone with leap seconds and stuff when using day / night picker
    // we'll add 12 h to get to the noon of day in local timezone.
    return momentInLocalTimezone.toDate();
};
/**
 * Convert local date for API.
 * Date given by browser
 * ("Fri Mar 30 2018 12:00:00 GMT-1100 (SST)" aka "Fri Mar 30 2018 23:00:00 GMT+0000 (UTC)")
 * must be modified so that API will get correct moment also in UTC.
 * We achieve this by adding timezone offset to local date / timestamp.
 *
 * The resulting timestamp for the API is:
 * localTimestamp.add(timezoneoffset)
 * In eg. Fri Mar 30 2018 23:00:00 GMT-1100 (SST) aka "Fri Mar 30 2018 12:00:00 GMT+0000 (UTC)"
 *
 * @param {Date} date is a local date object
 *
 * @returns {Date} date (given by API as UTC 00:00) converted back to local noon.
 */
export const dateFromLocalToAPI = (date) => {
    const timezoneDiffInMinutes = moment(date).utcOffset();
    const momentInLocalTimezone = moment(date).add(timezoneDiffInMinutes, 'minutes');
    return momentInLocalTimezone.toDate();
};
/**
 * Calculate the number of nights between the given dates
 *
 * @param {Date} startDate start of the time period
 * @param {Date} endDate end of the time period
 *
 * @throws Will throw if the end date is before the start date
 * @returns {Number} number of nights between the given dates
 */
export const nightsBetween = (startDate, endDate) => {
    const parsedStart = moment.utc(startDate).startOf('day');
    const parsedEnd = moment.utc(endDate).startOf('day');
    const nights = parsedEnd.diff(parsedStart, 'days');
    if (nights < 0) {
        throw new Error('End date cannot be before start date');
    }
    return nights;
};
export const enumerateDaysBetweenDatesNightlyBookings = (startDate, endDate) => {
    const dates = [];
    let dateToCheck = startDate.clone();
    while (!dateToCheck.isSame(endDate, 'day')) {
        dates.push(moment(dateToCheck.toDate()));
        dateToCheck = dateToCheck.add(1, 'days');
    }
    return dates;
};
/**
 * Calculate the number of days between the given dates
 *
 * @param {Date} startDate start of the time period
 * @param {Date} endDate end of the time period. NOTE: with daily
 * bookings, it is expected that this date is the exclusive end date,
 * i.e. the last day of the booking is the previous date of this end
 * date.
 *
 * @throws Will throw if the end date is before the start date
 * @returns {Number} number of days between the given dates
 */
export const daysBetween = (startDate, endDate) => {
    const days = moment.utc(endDate).diff(moment.utc(startDate), 'days');
    if (days < 0) {
        throw new Error('End date cannot be before start date');
    }
    return days;
};
/**
 * Calculate the number of minutes between the given dates
 *
 * @param {Date} startDate start of the time period
 * @param {Date} endDate end of the time period.
 *
 * @throws Will throw if the end date is before the start date
 * @returns {Number} number of minutes between the given Date objects
 */
export const minutesBetween = (startDate, endDate) => {
    const minutes = moment(endDate).diff(startDate, 'minutes');
    if (minutes < 0) {
        throw new Error('End Date cannot be before start Date');
    }
    return minutes;
};
/**
 * Format the given date to month id/string
 *
 * @param {Date} date to be formatted
 *
 * @returns {String} formatted month string
 */
export const monthIdString = (date) => moment(date).format('YYYY-MM');
/**
 * Format the given date to UTC month id/string
 *
 * @param {Date} date to be formatted
 *
 * @returns {String} formatted month string
 */
export const monthIdStringInUTC = (date) => moment(date).utc().format('YYYY-MM');
/**
 * Format the given date
 *
 * @param {Object} intl Intl object from react-intl
 * @param {String} todayString translation for the current day
 * @param {Date} d Date to be formatted
 *
 * @returns {String} formatted date
 */
export const formatDate = (intl, todayString, d) => {
    const paramsValid = intl && d instanceof Date && typeof todayString === 'string';
    if (!paramsValid) {
        throw new Error(`Invalid params for formatDate: (${intl}, ${todayString}, ${d})`);
    }
    // By default we can use moment() directly but in tests we need to use a specific dates.
    // fakeIntl used in tests contains now() function wich returns predefined date
    const now = intl.now ? moment(intl.now()) : moment();
    const formattedTime = intl.formatTime(d);
    let formattedDate;
    if (now.isSame(d, 'day')) {
        // e.g. "Today, 9:10pm"
        formattedDate = todayString;
    }
    else if (now.isSame(d, 'week')) {
        // e.g. "Wed, 8:00pm"
        formattedDate = intl.formatDate(d, {
            weekday: 'short',
        });
    }
    else if (now.isSame(d, 'year')) {
        // e.g. "Aug 22, 7:40pm"
        formattedDate = intl.formatDate(d, {
            month: 'short',
            day: 'numeric',
        });
    }
    else {
        // e.g. "Jul 17 2016, 6:02pm"
        const date = intl.formatDate(d, {
            month: 'short',
            day: 'numeric',
        });
        const year = intl.formatDate(d, {
            year: 'numeric',
        });
        formattedDate = `${date} ${year}`;
    }
    return `${formattedDate}, ${formattedTime}`;
};
/**
 * Converts string given in ISO8601 format to date object.
 * This is used e.g. when when dates are parsed form urlParams
 *
 * @param {String} dateString in 'YYYY-MM-DD'format
 *
 * @returns {Date} parsed date object
 */
export const parseDateFromISO8601 = (dateString) => {
    return moment(dateString, 'YYYY-MM-DD').toDate();
};
/**
 * Converts date to string ISO8601 format ('YYYY-MM-DD').
 * This string is used e.g. in urlParam.
 *
 * @param {Date} date
 *
 * @returns {String} string in 'YYYY-MM-DD'format
 */
export const stringifyDateToISO8601 = (date) => {
    return moment(date).format('YYYY-MM-DD');
};
/**
 * Formats string ('YYYY-MM-DD') to UTC format ('0000-00-00T00:00:00.000Z').
 * This is used in search query.
 *
 * @param {String} string in 'YYYY-MM-DD'format
 *
 * @returns {String} string in '0000-00-00T00:00:00.000Z' format
 */
export const formatDateStringToUTC = (dateString) => {
    return moment.utc(dateString).toDate();
};
/**
 * Formats string ('YYYY-MM-DD') to UTC format ('0000-00-00T00:00:00.000Z') and adds one day.
 * This is used as end date of the search query.
 * One day must be added because end of the availability is exclusive in API.
 *
 * @param {String} string in 'YYYY-MM-DD'format
 *
 * @returns {String} string in '0000-00-00T00:00:00.000Z' format
 */
export const getExclusiveEndDate = (dateString) => {
    return moment.utc(dateString).add(1, 'days').startOf('day').toDate();
};
export const formatDateToText = (intl, date) => {
    return {
        date: intl.formatDate(date, {
            month: 'short',
            day: 'numeric',
        }),
        time: intl.formatDate(date, {
            hour: 'numeric',
            minute: 'numeric',
        }),
        dateAndTime: intl.formatTime(date, {
            month: 'short',
            day: 'numeric',
        }),
    };
};
export const formatDisplayDate = (date, hideTime = false) => {
    const timeConfig = !hideTime
        ? {
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
        }
        : {};
    return new Date(date).toLocaleDateString(undefined, Object.assign({ year: 'numeric', month: 'numeric', day: 'numeric' }, timeConfig));
};
export const formatDisplayUtcDate = (date) => {
    return moment(date).utc().format('L LTS');
};
export const prevMonthFn = (month) => month.clone().subtract(1, 'months').startOf('month').utc();
export const nextMonthFn = (month) => month.clone().add(1, 'months').startOf('month').utc();
export const momentToUTCDate = (moment) => moment.clone().utc().add(moment.utcOffset(), 'minutes').toDate();
export const isPast = (date) => {
    return moment().subtract(1, 'days').isSameOrAfter(date);
};
export const getStartDate = (currentDate) => {
    return currentDate.clone().startOf('day').toDate();
};
export const getEndDate = (currentDate) => {
    return currentDate.clone().add(1, 'days').startOf('day').toDate();
};
export const getAffectedDays = (start, end) => {
    let startMoment = moment(start);
    const endMoment = moment(end);
    const result = [];
    while (startMoment && startMoment.isBefore(endMoment, 'day')) {
        result.push(startMoment);
        startMoment = startMoment.clone().add(1, 'days');
    }
    return result;
};
