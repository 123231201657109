import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useIntl } from 'react-intl';
import debounce from 'lodash/debounce';
import ModalLayout from './ModalLayout';
import { setDisableScroll } from '../../slices/UISlice';
import { useDispatch } from 'react-redux';
const Modal = ({ id, title, leftButton, rightButton, children, isOpen, illustration, colorVariant, onClose, fullTransparency, }) => {
    const [portalRoot, setPortalRoot] = useState(null);
    const intl = useIntl();
    const closeLabel = intl.formatMessage({
        id: 'Modal.closeModal',
    });
    const dispatch = useDispatch();
    const handleClose = (event, debounced) => {
        dispatch(setDisableScroll(false));
        debounce(() => onClose(event), debounced ? 50 : 0)();
    };
    useEffect(() => {
        setPortalRoot(document.getElementById('portal-root'));
    }, []);
    const modalProps = {
        id,
        title,
        closeLabel,
        leftButton,
        rightButton,
        isOpen,
        illustration,
        colorVariant,
        fullTransparency,
    };
    const content = (_jsx(ModalLayout, Object.assign({}, modalProps, { onClose: e => handleClose(e, true) }, { children: children })));
    if (isOpen && portalRoot) {
        return ReactDOM.createPortal(content, portalRoot);
    }
    return null;
};
export default Modal;
