import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useIntl } from 'react-intl';
import moment from 'moment';
import { cancellationTypes } from '@nomady/shared/marketplace-custom-config';
import { CancellationType } from '@nomady/shared/types';
import SectionLayout, { DescriptionContainer } from './SectionLayout';
import InfoLabel, { ContentVariant, } from '../../../../styled-components/InfoLabel';
import { getBookingCalendarTitle, getGuestsSubtitle, getGuestsTitle, } from '../../../../styled-components/CheckoutPanels/CheckoutPanels';
import Icon, { IconVariant } from '../../../../styled-components/Icon/Icon';
import Text, { TextVariant } from '../../../../styled-components/Text/Text';
import { ColorVariant } from '../../../../theme';
import { useSelector } from 'react-redux';
import { localeSelector } from '../../../../slices/UISlice';
const cancellationTypeToDescriptionTranslationKey = {
    [CancellationType.superFlex]: 'ListingPage.cancellationDescription.super_flex',
    [CancellationType.flex]: 'ListingPage.cancellationDescription.flex',
    [CancellationType.easy]: 'ListingPage.cancellationDescription.easy',
    [CancellationType.medium]: 'ListingPage.cancellationDescription.medium',
    [CancellationType.strict]: 'ListingPage.cancellationDescription.strict',
    [CancellationType.none]: 'ListingPage.cancellationDescription.none',
};
const BookingDetailsSection = ({ bookingStart, bookingEnd, adultQuantity, teenQuantity = 0, childQuantity = 0, infantQuantity = 0, dogQuantity = 0, cancellationPolicyType, checkinTime, }) => {
    const intl = useIntl();
    const locale = useSelector(localeSelector);
    const activeMarketPlaceCancellationType = cancellationTypes.find(cancellationType => cancellationType.key === cancellationPolicyType);
    const cancellationDate = (activeMarketPlaceCancellationType === null || activeMarketPlaceCancellationType === void 0 ? void 0 : activeMarketPlaceCancellationType.days) !== null
        ? moment(bookingStart)
            .subtract(activeMarketPlaceCancellationType === null || activeMarketPlaceCancellationType === void 0 ? void 0 : activeMarketPlaceCancellationType.days, 'days')
            .set('hour', checkinTime.hour())
            .set('minute', checkinTime.minute())
        : null;
    const cancellationDateText = !!cancellationDate && ` ${cancellationDate.format('DD.MM.YYYY HH:mm')}`;
    const cancellationInfoText = `${intl.formatMessage({
        id: (cancellationDate === null || cancellationDate === void 0 ? void 0 : cancellationDate.isSameOrAfter())
            ? 'BookingDetailsSection.until'
            : 'BookingDetailsSection.noCancellation',
    })}${(cancellationDate === null || cancellationDate === void 0 ? void 0 : cancellationDate.isSameOrAfter(moment())) ? cancellationDateText : ''}. ${intl.formatMessage({
        id: cancellationTypeToDescriptionTranslationKey[cancellationPolicyType || CancellationType.strict],
    })}`;
    return (_jsxs(SectionLayout, Object.assign({ title: intl.formatMessage({ id: 'BookingDetailsSection.title' }) }, { children: [_jsx(InfoLabel, Object.assign({ title: getBookingCalendarTitle(intl, locale, bookingStart, bookingEnd), variant: ContentVariant.Inline, dataTestId: "BookingDetailsSection_date" }, { children: _jsx(Icon, { variant: IconVariant.Calendar }) })), _jsx(InfoLabel, Object.assign({ title: getGuestsTitle(intl, true, adultQuantity + teenQuantity + childQuantity), subtitle: getGuestsSubtitle(intl, adultQuantity, teenQuantity, childQuantity, infantQuantity, dogQuantity), variant: ContentVariant.Block, dataTestId: "BookingDetailsSection_guests" }, { children: _jsx(Icon, { variant: IconVariant.User }) })), _jsx(InfoLabel, Object.assign({ title: intl.formatMessage({
                    id: 'BookingDetailsSection.cancellation',
                }), subtitle: intl.formatMessage({
                    id: activeMarketPlaceCancellationType === null || activeMarketPlaceCancellationType === void 0 ? void 0 : activeMarketPlaceCancellationType.label,
                }), variant: ContentVariant.Block, dataTestId: "BookingDetailsSection_cancellation" }, { children: _jsx(Icon, { variant: IconVariant.Rainbow }) })), _jsx(DescriptionContainer, { children: _jsx(Text, Object.assign({ variant: TextVariant.B50, colorVariant: ColorVariant.Cave, "data-testid": "BookingDetailsSection_cancellationDescription" }, { children: cancellationInfoText })) })] })));
};
export default BookingDetailsSection;
