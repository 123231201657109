import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styled from 'styled-components/macro';
import { IconVariant } from '../Icon/Icon';
import Button, { ButtonSizeVariant } from '../Button/Button';
import IconButtonOld from '../IconButtonOld/IconButtonOld';
const Wrapper = styled.div `
  position: relative;
  height: 60px;
  background-color: ${props => props.theme.color.pearl};
  justify-content: space-between;

  margin-bottom: 20px;
  width: 100%;
  overflow: hidden;
  ${props => props.theme.priority.medium};
  @media ${props => props.theme.device.tablet} {
    position: fixed;
  }
`;
const Content = styled.div `
  display: flex;
  align-items: center;
  flex-basis: 0;
  height: 100%;
  padding: 12px 0;
  max-width: 1580px;
  margin-inline: 30px;
  justify-content: space-between;
  @media ${props => props.theme.device.desktopXL} {
    margin-inline: auto;
  }
`;
const LeftButtonWrapper = styled.div `
  display: flex;
  align-items: center;
`;
const LeftContainer = styled.div `
  display: flex;
  align-items: center;
  min-width: 60%;
`;
const RightContainer = styled.div `
  margin-left: 10px;
  & span {
    display: none;
    margin-left: 0px;
  }
  & svg {
    margin-left: -6px;
  }
  @media ${props => props.theme.device.tablet} {
    & svg {
      margin-left: -12px;
    }
    & span {
      display: initial;
      margin-left: 0px;
    }
  }
`;
const StyledButton = styled.button `
  background-color: ${props => props.theme.color.pearl};
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  margin: 0 10px 0 0;
  @media ${props => props.theme.device.tablet} {
    margin: 0 30px 0 0;
  }
`;
const Banner = ({ goBack, buttonProps, children }) => {
    return (_jsx(Wrapper, { children: _jsxs(Content, { children: [_jsxs(LeftContainer, { children: [goBack && (_jsx(LeftButtonWrapper, { children: _jsx(IconButtonOld, { iconVariant: IconVariant.ArrowLeft, onClick: goBack }) })), children] }), buttonProps && (_jsx(RightContainer, { children: _jsx(Button, Object.assign({}, buttonProps, { sizeVariant: ButtonSizeVariant.Small, icon: IconVariant.Pen })) }))] }) }));
};
export default Banner;
