import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components/macro';
import { ColorVariant } from '../../theme';
import Text, { TextVariant } from '../Text/Text';
const Wrapper = styled.div `
  border-radius: 10px;
  background-color: ${props => props.theme.color.pearl};
  border: 1px solid ${props => props.theme.color.rose};
  display: flex;
  flex-direction: row;
  padding: 10px 15px;
  margin: 16px;
`;
const ExclamationMark = styled.div `
  margin-right: 22px;
  margin-left: 17px;
  margin-top: 7px;
`;
const FormErrorMessage = ({ id, values, }) => {
    const intl = useIntl();
    return (_jsxs(Wrapper, Object.assign({ id: id }, { children: [_jsx(ExclamationMark, { children: _jsx(Text, Object.assign({ variant: TextVariant.H9, as: "p", colorVariant: ColorVariant.Rose }, { children: _jsx("b", { children: "!" }) })) }), _jsx(Text, Object.assign({ variant: TextVariant.B30, as: "p", colorVariant: ColorVariant.Rose }, { children: intl.formatMessage({ id }, values) }))] })));
};
export default FormErrorMessage;
