import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components/macro';
import Text, { TextVariant } from '../Text/Text';
import Icon, { IconSizeVariant, IconVariant } from '../Icon/Icon';
import { ColorVariant } from '../../theme';
const NotificationWrapper = styled.div `
  width: 100%;
  margin: 5px 0 10px 0;
  padding: 5px;
  border-radius: 10px;
  border: 1px solid ${props => props.theme.color.rose};
  background: ${props => props.theme.color.pearl};
  display: flex;
  flex-direction: row;
`;
const IconWrapper = styled.div `
  width: 30px;
  height: 30px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${props => props.theme.color.rose};
  margin-right: 8px;
`;
const TextWrapper = styled.div `
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const ErrorNotification = ({ message, }) => {
    const intl = useIntl();
    return (_jsxs(NotificationWrapper, { children: [_jsx(IconWrapper, { children: _jsx(Icon, { variant: IconVariant.Cross, colorVariant: ColorVariant.Pearl, sizeVariant: IconSizeVariant.ExtraSmall }) }), _jsxs(TextWrapper, { children: [_jsx(Text, Object.assign({ variant: TextVariant.B30 }, { children: intl.formatMessage({ id: 'ErrorNotification.title' }) })), _jsx(Text, Object.assign({ variant: TextVariant.B60 }, { children: message }))] })] }));
};
export default ErrorNotification;
