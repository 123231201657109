export const ANALYTICS_EVENT = 'app/Analytics/ANALYTICS_EVENT';
const initialState = {
    analyticsEvent: {},
};
const AnalyticsStateReducer = (state = initialState, action) => {
    var _a, _b, _c, _d;
    switch (action.type) {
        case ANALYTICS_EVENT:
            return Object.assign(Object.assign({}, state), { analyticsEvent: {
                    name: (_a = action.payload) === null || _a === void 0 ? void 0 : _a.name,
                    category: (_b = action.payload) === null || _b === void 0 ? void 0 : _b.category,
                    label: (_c = action.payload) === null || _c === void 0 ? void 0 : _c.params.label,
                    value: (_d = action.payload) === null || _d === void 0 ? void 0 : _d.params.value.toString(),
                } });
        default:
            return state;
    }
};
export default AnalyticsStateReducer;
export const analyticsEvent = (payload) => ({
    type: ANALYTICS_EVENT,
    payload,
});
export const recordAnalyticsEvent = (name, category, params) => (dispatch) => {
    const analyticsEventPayload = {
        name,
        category,
        params,
    };
    return dispatch(analyticsEvent(analyticsEventPayload));
};
