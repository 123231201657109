import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components/macro';
import { ColorVariant } from '../../theme';
import Icon, { IconVariant } from '../Icon/Icon';
import Text, { TextVariant } from '../Text/Text';
const SecureCheckoutContainer = styled.div `
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin: 10px 0px;

  & svg {
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
const SecureCheckoutTag = () => {
    const intl = useIntl();
    return (_jsxs(SecureCheckoutContainer, { children: [_jsx(Icon, { variant: IconVariant.Password }), _jsx(Text, Object.assign({ variant: TextVariant.B50, colorVariant: ColorVariant.Cave }, { children: intl.formatMessage({ id: 'SecureCheckoutTag.label' }) }))] }));
};
export default SecureCheckoutTag;
