var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import reduce from 'lodash/reduce';
import { legacyMarketplaceLocaleMap } from '../config';
import { DEFAULT_LOCALE } from '../marketplace-custom-config';
/**
 * Combine the given relationships objects
 *
 * See: http://jsonapi.org/format/#document-resource-object-relationships
 */
export const combinedRelationships = (oldRels, newRels) => {
    if (!oldRels && !newRels) {
        // Special case to avoid adding an empty relationships object when
        // none of the resource objects had any relationships.
        return null;
    }
    return Object.assign(Object.assign({}, oldRels), newRels);
};
/**
 * Combine the given resource objects
 *
 * See: http://jsonapi.org/format/#document-resource-objects
 */
export const combinedResourceObjects = (oldRes, newRes) => {
    const { id, type } = oldRes;
    if (newRes.id.uuid !== id.uuid || newRes.type !== type) {
        throw new Error('Cannot merge resource objects with different ids or types');
    }
    const attributes = newRes.attributes || oldRes.attributes;
    const locale = newRes.locale || oldRes.locale;
    const attrs = attributes ? { attributes: Object.assign({}, attributes) } : null;
    const loc = locale ? { locale: Object.assign({}, locale) } : null;
    const relationships = combinedRelationships(oldRes.relationships, newRes.relationships);
    const rels = relationships ? { relationships } : null;
    return Object.assign(Object.assign(Object.assign({ id, type }, attrs), rels), loc);
};
/**
 * Combine the resource objects form the given api response to the
 * existing entities.
 */
export const updatedEntities = (oldEntities, apiResponse) => {
    const { data, included = [] } = apiResponse;
    const objects = (Array.isArray(data) ? data : [data]).concat(included);
    const newEntities = objects.reduce((entities, curr) => {
        const { id, type } = curr;
        // eslint-disable-next-line no-param-reassign
        entities[type] = entities[type] || {};
        const entity = entities[type][id.uuid];
        // eslint-disable-next-line no-param-reassign
        entities[type][id.uuid] = entity
            ? combinedResourceObjects(Object.assign({}, entity), curr)
            : curr;
        return entities;
    }, oldEntities);
    return newEntities;
};
/**
 * Denormalise the entities with the resources from the entities object
 *
 * This function calculates the dernormalised tree structure from the
 * normalised entities object with all the relationships joined in.
 *
 * @param {Object} entities entities object in the SDK Redux store
 * @param {Array<{ id, type }} resources array of objects
 * with id and type
 * @param {Boolean} throwIfNotFound wheather to skip a resource that
 * is not found (false), or to throw an Error (true)
 *
 * @return {Array} the given resource objects denormalised that were
 * found in the entities
 */
export const denormalisedEntities = (entities, resources, throwIfNotFound = true) => {
    const denormalised = resources.map((res) => {
        const { id, type } = res;
        const entityFound = entities[type] && id && entities[type][id.uuid];
        if (!entityFound) {
            if (throwIfNotFound) {
                throw new Error(`Entity with type "${type}" and id "${id ? id.uuid : id}" not found`);
            }
            return null;
        }
        const entity = entities[type][id.uuid];
        const { relationships } = entity, entityData = __rest(entity, ["relationships"]);
        if (relationships) {
            // Recursively join in all the relationship entities
            return reduce(relationships, (ent, relRef, relName) => {
                // A relationship reference can be either a single object or
                // an array of objects. We want to keep that form in the final
                // result.
                const hasMultipleRefs = Array.isArray(relRef.data);
                const multipleRefsEmpty = hasMultipleRefs && relRef.data.length === 0;
                if (!relRef.data || multipleRefsEmpty) {
                    // eslint-disable-next-line no-param-reassign
                    ent[relName] = hasMultipleRefs ? [] : null;
                }
                else {
                    const refs = hasMultipleRefs ? relRef.data : [relRef.data];
                    // If a relationship is not found, an Error should be thrown
                    const rels = denormalisedEntities(entities, refs, true);
                    // eslint-disable-next-line no-param-reassign
                    ent[relName] = hasMultipleRefs ? rels : rels[0];
                }
                return ent;
            }, entityData);
        }
        return entityData;
    });
    return denormalised.filter((e) => !!e);
};
/**
 * Denormalise the data from the given SDK response
 *
 * @param {Object} sdkResponse response object from an SDK call
 *
 * @return {Array} entities in the response with relationships
 * denormalised from the included data
 */
export const denormalisedResponseEntities = (sdkResponse) => {
    const apiResponse = sdkResponse.data;
    const { data } = apiResponse;
    const resources = Array.isArray(data) ? data : [data];
    if (!data || resources.length === 0) {
        return [];
    }
    const entities = updatedEntities({}, apiResponse);
    return denormalisedEntities(entities, resources);
};
/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} transaction entity object, which is to be ensured against null values
 */
export const ensureTransaction = (transaction, booking, listing, provider) => {
    const empty = {
        type: 'transaction',
        attributes: {},
        booking,
        listing,
        provider,
    };
    return Object.assign(Object.assign({}, empty), transaction);
};
/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} time slot entity object, which is to be ensured against null values
 */
export const ensureTimeSlot = (timeSlot) => {
    const empty = { type: 'timeSlot', attributes: {} };
    return Object.assign(Object.assign({}, empty), timeSlot);
};
/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} availability exception entity object, which is to be ensured against null values
 */
export const ensureAvailabilityException = (availabilityException) => {
    const empty = { type: 'availabilityException', attributes: {} };
    return Object.assign(Object.assign({}, empty), availabilityException);
};
/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} stripeCustomer entity from API, which is to be ensured against null values
 */
export const ensureStripeCustomer = (stripeCustomer) => {
    const empty = { type: 'stripeCustomer', attributes: {} };
    return Object.assign(Object.assign({}, empty), stripeCustomer);
};
/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} stripeCustomer entity from API, which is to be ensured against null values
 */
export const ensurePaymentMethodCard = (stripePaymentMethod) => {
    const empty = {
        type: 'stripePaymentMethod',
        attributes: { type: 'stripe-payment-method/card', card: {} },
    };
    const cardPaymentMethod = Object.assign(Object.assign({}, empty), stripePaymentMethod);
    if (cardPaymentMethod.attributes.type !== 'stripe-payment-method/card') {
        throw new Error(`'ensurePaymentMethodCard' got payment method with wrong type.
      'stripe-payment-method/card' was expected, received ${cardPaymentMethod.attributes.type}`);
    }
    return cardPaymentMethod;
};
/**
 * Get the display name of the given user as string. This function handles
 * missing data (e.g. when the user object is still being downloaded),
 * fully loaded users, as well as banned users.
 *
 * For banned or deleted users, a translated name should be provided.
 *
 * @param {propTypes.user} user
 * @param {String} defaultUserDisplayName
 *
 * @return {String} display name that can be rendered in the UI
 */
export const userDisplayNameAsString = (user, defaultUserDisplayName) => {
    const hasAttributes = user && user.attributes;
    const hasProfile = hasAttributes && user.attributes.profile;
    const hasDisplayName = hasProfile && user.attributes.profile.displayName;
    if (hasDisplayName) {
        return user.attributes.profile.displayName;
    }
    return defaultUserDisplayName || '';
};
/**
 * Get the abbreviated name of the given user. This function handles
 * missing data (e.g. when the user object is still being downloaded),
 * fully loaded users, as well as banned users.
 *
 * For banned  or deleted users, a default abbreviated name should be provided.
 *
 * @param {propTypes.user} user
 * @param {String} defaultUserAbbreviatedName
 *
 * @return {String} abbreviated name that can be rendered in the UI
 * (e.g. in Avatar initials)
 */
export const userAbbreviatedName = (user, defaultUserAbbreviatedName) => {
    const hasAttributes = user && user.attributes;
    const hasProfile = hasAttributes && user.attributes.profile;
    const hasDisplayName = hasProfile && user.attributes.profile.abbreviatedName;
    if (hasDisplayName) {
        return user.attributes.profile.abbreviatedName;
    }
    return defaultUserAbbreviatedName || '';
};
/**
 * Get the locale of the given user or undefined
 *
 * @param user
 *
 * @return {MarketplaceLocale} locale
 * (e.g. 'de-CH')
 */
export const getUserLocale = (user) => {
    var _a, _b, _c;
    return legacyMarketplaceLocaleMap[((_c = (_b = (_a = user === null || user === void 0 ? void 0 : user.attributes) === null || _a === void 0 ? void 0 : _a.profile) === null || _b === void 0 ? void 0 : _b.privateData) === null || _c === void 0 ? void 0 : _c.locale) ||
        DEFAULT_LOCALE];
};
/**
 * Humanizes a line item code. Strips the "line-item/" namespace
 * definition from the beginnign, replaces dashes with spaces and
 * capitalizes the first character.
 *
 * @param {string} code a line item code
 *
 * @return {string} returns the line item code humanized
 */
export const humanizeLineItemCode = (code) => {
    if (!/^line-item\/.+/.test(code)) {
        throw new Error(`Invalid line item code: ${code}`);
    }
    const lowercase = code.replace(/^line-item\//, '').replace(/-/g, ' ');
    return lowercase.charAt(0).toUpperCase() + lowercase.slice(1);
};
