import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useLocation, Redirect } from 'react-router-dom';
import Page from '@nomady/shared/routeConfiguration/Page';
import SplitPicturePageContainer from '../../styled-components/PageContainer/SplitPicturePageContainer';
import config from '../../config';
import { isAuthenticatedSelector } from '../../ducks/Auth.duck';
import { NamedRedirect } from '../../components';
import LoginForm from '../../forms/LoginForm/LoginForm';
import { updateUserLastLogin } from '../../util/api';
const LoginPage = () => {
    var _a, _b;
    const intl = useIntl();
    const { siteTitle } = config;
    const schemaTitle = intl.formatMessage({ id: 'LoginPage.schemaTitle' }, { siteTitle });
    const location = useLocation();
    const from = ((_a = location === null || location === void 0 ? void 0 : location.state) === null || _a === void 0 ? void 0 : _a.from) || null;
    const queryParams = new URLSearchParams(location.search);
    const activateFormerMyCabinAccount = queryParams.get('activateFormerMyCabinAccount') === 'true';
    const user = useSelector((state) => state.user.currentUser);
    const hasListings = useSelector((state) => state.user.currentUserHasListings);
    const isAuthenticated = useSelector(isAuthenticatedSelector);
    const currentUserLoaded = !!(user === null || user === void 0 ? void 0 : user.id);
    if (isAuthenticated && ((_b = user === null || user === void 0 ? void 0 : user.id) === null || _b === void 0 ? void 0 : _b.uuid)) {
        updateUserLastLogin();
    }
    if (isAuthenticated && from) {
        return _jsx(Redirect, { to: from });
    }
    if (isAuthenticated && currentUserLoaded && hasListings !== undefined) {
        if (activateFormerMyCabinAccount && hasListings) {
            return _jsx(NamedRedirect, { name: Page.ManageListings });
        }
        if (user.attributes.email.endsWith('@privaterelay.appleid.com')) {
            return _jsx(NamedRedirect, { name: Page.ContactDetails });
        }
        return _jsx(NamedRedirect, { name: Page.Landing });
    }
    return (_jsx(SplitPicturePageContainer, Object.assign({ schemaTitle: schemaTitle }, { children: _jsx(LoginForm, {}) })));
};
export default LoginPage;
