import { updatedEntities, denormalisedEntities, } from '@nomady/shared/utils/data';
// ================ Action types ================ //
export const ADD_MARKETPLACE_ENTITIES = 'app/marketplaceData/ADD_MARKETPLACE_ENTITIES';
export const RESET_MARKETPLACE_ENTITIES = 'app/marketplaceData/RESET_MARKETPLACE_ENTITIES';
const initialState = {
    // Database of all the fetched entities.
    entities: {},
};
const merge = (state, sdkResponse) => {
    const apiResponse = sdkResponse.data;
    return Object.assign(Object.assign({}, state), { entities: updatedEntities(Object.assign({}, state.entities), apiResponse) });
};
export default function marketplaceDataReducer(state = initialState, action) {
    switch (action.type) {
        case RESET_MARKETPLACE_ENTITIES:
            return { entities: [] };
        case ADD_MARKETPLACE_ENTITIES:
            return merge(state, action.payload);
        default:
            return state;
    }
}
// ================ Selectors ================ //
export const getListing = (state, id) => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    const hasListing = listings.length === 1;
    const translationDataPresent = hasListing && listings[0].attributes.publicData.originalLocale;
    return translationDataPresent ? listings[0] : null;
};
/**
 * Get the denormalised listing entities with the given IDs
 *
 * @param {Object} state the full Redux store
 * @param {Array<UUID>} listingIds listing IDs to select from the store
 */
export const getListingsById = (state, listingIds) => {
    const { entities } = state.marketplaceData;
    const resources = listingIds.map(id => ({
        id,
        type: 'listing',
    }));
    const throwIfNotFound = false;
    return denormalisedEntities(entities, resources, throwIfNotFound);
};
/**
 * Get the denormalised entities from the given entity references.
 *
 * @param {Object} state the full Redux store
 *
 * @param {Array<{ id, type }} entityRefs References to entities that
 * we want to query from the data. Currently we expect that all the
 * entities have the same type.
 *
 * @return {Array<Object>} denormalised entities
 */
export const getMarketplaceEntities = (state, entityRefs) => {
    const { entities } = state.marketplaceData;
    const throwIfNotFound = false;
    return denormalisedEntities(entities, entityRefs, throwIfNotFound);
};
export const addMarketplaceEntities = (sdkResponse) => ({
    type: ADD_MARKETPLACE_ENTITIES,
    payload: sdkResponse,
});
export const resetMarketplaceEntities = () => ({
    type: RESET_MARKETPLACE_ENTITIES,
});
