import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styled from 'styled-components/macro';
import { useIntl } from 'react-intl';
import Text, { TextVariant } from '../Text/Text';
import { ColorVariant } from '../../theme';
import Icon, { IconSizeVariant, IconVariant } from '../Icon/Icon';
const PriceDetails = styled.div `
  padding: 3px 0px;
  border-top: 1px solid ${props => props.theme.color.fog};
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:hover {
    cursor: ${props => (props.detailsDisabled ? 'default' : 'pointer')};
  }
`;
const IconContainer = styled.div `
  margin-right: 5px;
`;
const BookingBreakdownToggle = ({ detailsDisabled }) => {
    const intl = useIntl();
    return (_jsx(PriceDetails, Object.assign({ detailsDisabled: detailsDisabled }, { children: detailsDisabled ? (_jsx(Text, Object.assign({ variant: TextVariant.B60, color: ColorVariant.Stone }, { children: intl.formatMessage({
                id: 'ListingPage.priceDetailsLabel',
            }) }))) : (_jsxs(_Fragment, { children: [_jsx(Text, Object.assign({ variant: TextVariant.B30, color: ColorVariant.Stone }, { children: intl.formatMessage({ id: 'ListingPage.priceDetails' }) })), _jsx(IconContainer, { children: _jsx(Icon, { variant: IconVariant.DirectionRight, sizeVariant: IconSizeVariant.ExtraExtraSmall }) })] })) })));
};
export default BookingBreakdownToggle;
