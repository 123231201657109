import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components/macro';
import Text, { TextVariant } from '../Text/Text';
import FireplaceIllustration from './assets/fireplace.webp';
import PlugIllustration from './assets/plug.webp';
import Card from '../Card/Card';
import Button, { ButtonColorVariant, ButtonOutlineVariant, ButtonSizeVariant, } from '../Button/Button';
import { IconVariant } from '../Icon/Icon';
import { formatMoney } from '@nomady/shared/utils/currency';
const Content = styled.div `
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 120px min-content min-content;
`;
const Illustration = styled.img `
  height: 100%;
  width: auto;
  margin: 0 auto;

  align-self: center;
`;
const Center = styled.div `
  margin: 8px 0;
`;
const Bottom = styled.div `
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 8px 0;

  button {
    width: min-content;
  }
`;
export var BookingExtraType;
(function (BookingExtraType) {
    BookingExtraType["PowerSupply"] = "powerSupply";
    BookingExtraType["Firewood"] = "firewood";
})(BookingExtraType || (BookingExtraType = {}));
const illustrations = {
    [BookingExtraType.PowerSupply]: PlugIllustration,
    [BookingExtraType.Firewood]: FireplaceIllustration,
};
const BookingExtra = ({ type, checked, price, onChange, title, description, }) => {
    const intl = useIntl();
    return (_jsx(Card, { children: _jsxs(Content, { children: [_jsx(Illustration, { src: illustrations[type], alt: intl.formatMessage({ id: `BookingExtra.illustration.${type}` }) }), _jsxs(Center, { children: [_jsx(Text, Object.assign({ variant: TextVariant.L1SB, as: "h4" }, { children: title })), _jsx(Text, Object.assign({ variant: TextVariant.P3R, as: "p" }, { children: description }))] }), _jsxs(Bottom, { children: [_jsx(Text, Object.assign({ variant: TextVariant.L2SB }, { children: intl.formatMessage({ id: `BookingExtra.priceLabel.${type}` }, { price: formatMoney(intl, price) }) })), _jsx(Button, { outlineVariant: ButtonOutlineVariant.Outlined, icon: checked ? IconVariant.Check_Light : IconVariant.Extras, colorVariant: checked ? ButtonColorVariant.Forest : ButtonColorVariant.Default, sizeVariant: ButtonSizeVariant.Small, onClick: () => onChange(!checked), label: intl.formatMessage({
                                id: checked
                                    ? 'BookingExtra.removeLabel'
                                    : 'BookingExtra.addLabel',
                            }) })] })] }) }));
};
export default BookingExtra;
