// source: https://github.com/andrewmclagan/react-env/blob/master/packages/node/src/index.js
/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
/* eslint-disable no-underscore-dangle */
export const isBrowser = () => typeof window !== 'undefined';
const isBrowserWithEnv = () => !!(isBrowser() && window._env);
/**
 *  This function allows to access process.env in a SSR context so it's ensured that it returns the acutal process.env instead of the process.env interpolated by the Create React App build process
 *
 * @returns Record<string,string> process.env
 */
export const uninterpolatedProcessEnv = () => 
// eslint-disable-next-line no-eval
eval('process.env');
const env = (key = '') => {
    const safeKey = `REACT_APP_${key}`;
    if (isBrowserWithEnv() && key === 'NODE_ENV') {
        return window._env.NODE_ENV;
    }
    if (isBrowserWithEnv()) {
        return window._env[safeKey];
    }
    if (!isBrowser()) {
        if (key === 'NODE_ENV') {
            return uninterpolatedProcessEnv().NODE_ENV;
        }
        return uninterpolatedProcessEnv()[safeKey];
    }
    return process.env[safeKey];
};
export default env;
