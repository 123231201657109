import { DEFAULT_ADULT_QUANTITY, DEFAULT_SEAT_QUANTITY, } from '@nomady/shared/config';
export const defaultBookingData = {
    adultQuantity: DEFAULT_ADULT_QUANTITY,
    seatQuantity: DEFAULT_SEAT_QUANTITY,
    teenQuantity: 0,
    childQuantity: 0,
    infantQuantity: 0,
    extras: { powerSupply: false, firewood: false },
    dogQuantity: 0,
};
