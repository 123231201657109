import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styled from 'styled-components/macro';
import Button, { ButtonColorVariant, ButtonSizeVariant, } from '../Button/Button';
import Text, { TextVariant } from '../Text/Text';
import { ReactComponent as RetryIllustration } from './assets/retry-illustration.svg';
const Wrapper = styled.div `
  display: flex;
  flex-direction: column;

  @media ${props => props.theme.device.tabletL} {
    flex-direction: row;
    width: 450px;
  }
`;
const Illustration = styled.div `
  background: ${props => props.theme.color.rose};
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 24px;
  padding-bottom: 24px;

  @media ${props => props.theme.device.tabletL} {
    width: 148px;
    height: 148px;
  }
`;
const Content = styled.div `
  display: flex;
  flex-direction: column;
  padding: 10px;

  @media ${props => props.theme.device.tabletL} {
    padding: 0;
    margin-left: 15px;
  }
`;
const Title = styled(Text) `
  margin-top: 15px;
`;
const Description = styled(Text) `
  margin-top: 2px;
`;
const ButtonWrapper = styled.div `
  margin-top: 15px;
  align-self: flex-start;
`;
const ErrorCard = ({ title, description, onRetry, retryButtonLabel, retryInProgress = false, }) => {
    const showRetryButton = onRetry && retryButtonLabel;
    return (_jsxs(Wrapper, { children: [_jsx(Illustration, { children: _jsx(RetryIllustration, {}) }), _jsxs(Content, { children: [_jsx(Title, Object.assign({ as: "h4", variant: TextVariant.H11B }, { children: title })), description && (_jsx(Description, Object.assign({ as: "p", variant: TextVariant.H14 }, { children: description }))), showRetryButton && (_jsx(ButtonWrapper, { children: _jsx(Button, { sizeVariant: ButtonSizeVariant.Small, colorVariant: ButtonColorVariant.Dark, onClick: onRetry, inProgress: retryInProgress, label: retryButtonLabel }) }))] })] }));
};
export default ErrorCard;
