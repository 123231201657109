import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styled from 'styled-components/macro';
import { useIntl } from 'react-intl';
import Text, { TextVariant } from '../../../styled-components/Text/Text';
import Modal from '../../../styled-components/Modal/Modal';
import Avatar, { SizeVariant } from '../../../styled-components/Avatar/Avatar';
import TransactionTag from '../../../styled-components/TransactionTag/TransactionTag';
import { getTransactionStatus, txIsRequested, } from '@nomady/shared/utils/transaction';
import { InfoItem } from '../../ListingPage/Sections/BookingDetailsSection';
import { IconVariant } from '../../../styled-components/Icon/Icon';
import { nightsBetween } from '@nomady/shared/utils/dates';
import moment from 'moment';
import { LINE_ITEM_ADULTS, LINE_ITEM_CHILDREN, LINE_ITEM_INFANTS, LINE_ITEM_SEATS, LINE_ITEM_TEENS, } from '@nomady/shared/utils/lineItemTypes';
import { getGuestsSubtitle } from '../../../styled-components/CheckoutPanels/CheckoutPanels';
import { ColorVariant } from '../../../theme';
import { useDispatch, useSelector } from 'react-redux';
import getListingCheckInTime from '@nomady/shared/utils/listing';
import { getArrivalDate } from '@nomady/shared/controllers/RefundCalculatorFactory';
import ProviderActions from './ProviderActions';
import { acceptSale, declineSale, } from '../../TransactionPage/TransactionPage.duck';
import Button, { ButtonColorVariant, ButtonSizeVariant, } from '../../../styled-components/Button/Button';
import Page from '@nomady/shared/routeConfiguration/Page';
const Container = styled.div `
  display: flex;
  flex-direction: column;
  gap: 32px;
`;
const GuestWrapper = styled.div `
  display: flex;
  gap: 8px;
  flex-direction: column;
`;
const GuestRow = styled.div `
  display: flex;
  gap: 10px;
  align-items: center;
`;
const StatusWrapper = styled.div `
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 8px;
  border-radius: 8px;
  background-color: ${props => props.theme.color.pearl};
`;
const InfoWrapper = styled.div `
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
const BookingDetailsModal = ({ open, onClose, booking }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q;
    const intl = useIntl();
    const dispatch = useDispatch();
    const onAcceptSale = (transactionId) => dispatch(acceptSale(transactionId));
    const onDeclineSale = (transactionId, reasonFormInputs) => dispatch(declineSale(transactionId, reasonFormInputs));
    const { acceptInProgress, acceptSaleError, declineInProgress, declineSaleError, } = useSelector((state) => state.TransactionPage);
    if (!booking) {
        return null;
    }
    const transaction = booking.booking.transaction;
    const status = getTransactionStatus(transaction);
    const bookingStart = booking.booking.attributes.start;
    const bookingEnd = booking.booking.attributes.end;
    const totalNights = bookingStart && bookingEnd ? nightsBetween(bookingStart, bookingEnd) : 1;
    const periodBooked = intl.formatMessage({ id: 'BookingCalendar.dateRange' }, {
        startDate: moment(bookingStart).format('LL'),
        endDate: moment(bookingEnd).format('LL'),
    });
    const lineItems = (_a = transaction.attributes.lineItems) !== null && _a !== void 0 ? _a : [];
    const spots = (_b = lineItems.find(lineItem => lineItem.code === LINE_ITEM_SEATS)) === null || _b === void 0 ? void 0 : _b.seats;
    const adults = ((_d = (_c = transaction.attributes.lineItems) === null || _c === void 0 ? void 0 : _c.find(lineItem => lineItem.code === LINE_ITEM_ADULTS)) === null || _d === void 0 ? void 0 : _d.seats) || 0;
    const teens = ((_f = (_e = transaction.attributes.lineItems) === null || _e === void 0 ? void 0 : _e.find(lineItem => lineItem.code === LINE_ITEM_TEENS)) === null || _f === void 0 ? void 0 : _f.seats) || 0;
    const children = ((_h = (_g = transaction.attributes.lineItems) === null || _g === void 0 ? void 0 : _g.find(lineItem => lineItem.code === LINE_ITEM_CHILDREN)) === null || _h === void 0 ? void 0 : _h.seats) || 0;
    const infants = ((_k = (_j = transaction.attributes.lineItems) === null || _j === void 0 ? void 0 : _j.find(lineItem => lineItem.code === LINE_ITEM_INFANTS)) === null || _k === void 0 ? void 0 : _k.seats) || 0;
    const guestSubtitle = getGuestsSubtitle(intl, adults, teens, children, infants);
    const guestsQuantity = adults + teens + children + infants;
    const checkInTime = getListingCheckInTime(booking.listing);
    const arrivalDate = ((_l = transaction.booking) === null || _l === void 0 ? void 0 : _l.attributes.displayStart) &&
        checkInTime &&
        getArrivalDate(transaction.booking.attributes.displayStart, checkInTime);
    const showProviderActions = txIsRequested(transaction);
    const providerAcceptModal = showProviderActions
        ? arrivalDate && (arrivalDate === null || arrivalDate === void 0 ? void 0 : arrivalDate.isBefore(moment()))
            ? {
                rightButton: {
                    label: intl.formatMessage({
                        id: 'TransactionPanel.ProviderAcceptActions.acceptAfterCheckinButtonLabel',
                    }),
                    onClick: () => onAcceptSale(transaction.id),
                    inProgress: acceptInProgress,
                    isPrimary: true,
                },
                illustration: undefined,
                title: intl.formatMessage({
                    id: 'TransactionPanel.ProviderAcceptActions.acceptAfterCheckinTitle',
                }),
                children: intl.formatMessage({
                    id: 'TransactionPanel.ProviderAcceptActions.acceptAfterCheckinText',
                }),
            }
            : {
                rightButton: {
                    label: intl.formatMessage({
                        id: 'TransactionPanel.ProviderAcceptActions.acceptBeforeCheckinButtonLabel',
                    }),
                    onClick: () => onAcceptSale(transaction.id),
                    inProgress: acceptInProgress,
                    isPrimary: true,
                },
                illustration: undefined,
                title: intl.formatMessage({
                    id: 'TransactionPanel.ProviderAcceptActions.acceptBeforeCheckinTitle',
                }),
                children: intl.formatMessage({
                    id: 'TransactionPanel.ProviderAcceptActions.acceptBeforeCheckinText',
                }),
            }
        : {};
    return (_jsx(Modal, Object.assign({ isOpen: open, onClose: onClose, title: booking.listing.attributes.title }, { children: _jsxs(Container, { children: [_jsxs(GuestWrapper, { children: [_jsx(Text, Object.assign({ variant: TextVariant.H5SB, colorVariant: ColorVariant.Cave }, { children: intl.formatMessage({
                                id: 'BookingCalendar.BookingDetails.guestsTitle',
                            }) })), _jsxs(GuestRow, { children: [_jsx(Avatar, { sizeVariant: SizeVariant.S, initials: (_m = transaction.customer) === null || _m === void 0 ? void 0 : _m.attributes.profile.abbreviatedName, source: (_p = (_o = transaction.customer) === null || _o === void 0 ? void 0 : _o.profileImage) === null || _p === void 0 ? void 0 : _p.attributes.variants.default.url }), _jsx(Text, Object.assign({ variant: TextVariant.H5SB, colorVariant: ColorVariant.Fog }, { children: (_q = transaction.customer) === null || _q === void 0 ? void 0 : _q.attributes.profile.displayName }))] })] }), _jsxs(StatusWrapper, { children: [_jsx(Text, Object.assign({ variant: TextVariant.H5SB, colorVariant: ColorVariant.Cave }, { children: intl.formatMessage({
                                id: 'BookingCalendar.BookingDetails.statusTitle',
                            }) })), _jsx(TransactionTag, { status: status }), _jsx(ProviderActions, { showButtons: showProviderActions, acceptInProgress: acceptInProgress, declineInProgress: declineInProgress, acceptSaleError: acceptSaleError, declineSaleError: declineSaleError, onDeclineSale: reasonFormInputs => onDeclineSale(transaction.id, reasonFormInputs), acceptModal: providerAcceptModal })] }), _jsxs(InfoWrapper, { children: [_jsx(Text, Object.assign({ variant: TextVariant.H5SB, colorVariant: ColorVariant.Cave }, { children: intl.formatMessage({
                                id: 'BookingCalendar.BookingDetails.detailsTitle',
                            }) })), _jsx(InfoItem, { title: intl.formatMessage({ id: 'BookingCalendar.BookingDetails.nightQuantity' }, { quantity: totalNights }), iconVariant: IconVariant.Calendar_NEW, text: periodBooked }), _jsx(InfoItem, { title: intl.formatMessage({ id: 'BookingCalendar.BookingDetails.seatQuantity' }, { quantity: spots }), iconVariant: IconVariant.Spots }), _jsx(InfoItem, { text: guestSubtitle, title: intl.formatMessage({
                                id: 'BookingCalendar.BookingDetails.guestQuantity',
                            }, {
                                quantity: guestsQuantity,
                            }), iconVariant: IconVariant.Avatar })] }), _jsx(Button, Object.assign({ colorVariant: ButtonColorVariant.Dark, sizeVariant: ButtonSizeVariant.Default, targetRoute: Page.Sale, targetRouteParams: {
                        id: transaction.id.uuid,
                    } }, { children: intl.formatMessage({
                        id: 'BookingCalendar.BookingDetails.goToBooking',
                    }) }))] }) })));
};
export default BookingDetailsModal;
