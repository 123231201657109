import { jsx as _jsx } from "react/jsx-runtime";
import { environment } from '@nomady/shared/marketplace-custom-config';
import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { IconVariant } from '../../../styled-components/Icon/Icon';
import ListingChecklist from '../../../styled-components/ListingChecklist';
const EnvironmentContent = () => {
    var _a, _b, _c, _d;
    const { listing } = useSelector((state) => state.ListingPage);
    const intl = useIntl();
    const activeEnvironmentTypes = ((_b = (_a = listing === null || listing === void 0 ? void 0 : listing.attributes) === null || _a === void 0 ? void 0 : _a.publicData) === null || _b === void 0 ? void 0 : _b.environment) || [];
    const { environmentDescription } = ((_d = (_c = listing === null || listing === void 0 ? void 0 : listing.attributes) === null || _c === void 0 ? void 0 : _c.publicData) === null || _d === void 0 ? void 0 : _d.localizedPublicData) || {};
    const environmentElements = environment.map(item => ({
        isChecked: activeEnvironmentTypes.includes(item.key),
        text: intl.formatMessage({ id: item.label }),
    }));
    if (environmentDescription) {
        environmentElements.push({
            isChecked: true,
            text: environmentDescription,
        });
    }
    return (_jsx(ListingChecklist, { items: environmentElements, title: intl.formatMessage({ id: 'ListingPage.exploreScenery' }), iconVariant: IconVariant.Mountains }));
};
export default EnvironmentContent;
