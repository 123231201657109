import { useEffect, useState } from 'react';
import LineItemCalculator from '@nomady/shared/controllers/LineItemCalculator';
import groupLineItems from '../utils';
import { getLineItemsByRole } from '@nomady/shared/utils/lineItemHelpers';
const useBreakdownLineItems = ({ listing, giftCards, customerCommissionDiscountCode, bookingStart, bookingEnd, seatQuantity, adultQuantity, childQuantity, teenQuantity, infantQuantity, dogQuantity, extras, role = 'customer', }) => {
    var _a;
    const [breakdownLineItems, setBreakdownLineItems] = useState();
    useEffect(() => {
        var _a, _b, _c, _d;
        if (listing &&
            bookingStart &&
            bookingEnd &&
            (((_a = listing.attributes.publicData.pricing) === null || _a === void 0 ? void 0 : _a.seat) ||
                ((_b = listing.attributes.publicData.pricing) === null || _b === void 0 ? void 0 : _b.seat) === 0) &&
            (((_c = listing.attributes.publicData.pricing) === null || _c === void 0 ? void 0 : _c.adult) ||
                ((_d = listing.attributes.publicData.pricing) === null || _d === void 0 ? void 0 : _d.adult) === 0)) {
            const lineItems = new LineItemCalculator(listing, {
                seats: seatQuantity,
                adults: adultQuantity,
                children: childQuantity || 0,
                teens: teenQuantity || 0,
                infants: infantQuantity || 0,
                dogs: dogQuantity || 0,
                giftCards,
                customerCommissionDiscountCode,
                extras,
            }, bookingStart, bookingEnd, listing.attributes.publicData.pricing);
            setBreakdownLineItems(lineItems);
        }
    }, [
        listing,
        bookingStart,
        bookingEnd,
        seatQuantity,
        adultQuantity,
        teenQuantity,
        childQuantity,
        infantQuantity,
        dogQuantity,
        giftCards,
        customerCommissionDiscountCode,
        extras,
    ]);
    useEffect(() => {
        if (!bookingStart && !bookingEnd) {
            setBreakdownLineItems(undefined);
        }
    }, [bookingStart, bookingEnd]);
    const allLineItems = (_a = breakdownLineItems === null || breakdownLineItems === void 0 ? void 0 : breakdownLineItems.getLineItems()) !== null && _a !== void 0 ? _a : [];
    const lineItemsFromCalculator = getLineItemsByRole(allLineItems, role);
    const giftCardDeduction = breakdownLineItems === null || breakdownLineItems === void 0 ? void 0 : breakdownLineItems.getGiftCardDeduction();
    const groupedLineItems = groupLineItems(lineItemsFromCalculator);
    return {
        groupedLineItems,
        lineItemsFromCalculator,
        breakdownLineItems,
        giftCardDeduction,
    };
};
export default useBreakdownLineItems;
