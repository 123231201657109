var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createSlice } from '@reduxjs/toolkit';
import { storableError } from '../../util/errors';
import { denormalisedResponseEntities } from '@nomady/shared/utils/data';
import { BOOKING_STATES } from '@nomady/shared/utils/types';
import { Operation, } from './types';
import { getEndDate, getStartDate, momentToUTCDate, } from '@nomady/shared/utils/dates';
import moment from 'moment';
import { ALL_LISTINGS_KEY, FUTURE_AVAILABILITY_EDIT_MAX_DAYS, } from './constants';
import { isDateWithinException } from './utils';
import { SnackBarType, addSnackBar, } from '../../components/SnackBar/SnackBarSlice';
import { txIsPaymentExpired, txIsPaymentPending, } from '@nomady/shared/utils/transaction';
const initialState = {
    fetchListingsError: null,
    fetchListingsInProgress: false,
    listings: null,
    selectedListingUUID: ALL_LISTINGS_KEY,
    startDate: null,
    endDate: null,
    fetchBookingsError: null,
    fetchBookingsInProgress: false,
    bookings: [],
    fetchAvailabilityExceptionsError: null,
    fetchAvailabilityExceptionsInProgress: false,
    availabilityExceptions: null,
};
export const calendarSlice = createSlice({
    name: 'app/Calendar',
    initialState,
    reducers: {
        fetchListingsSuccess(state, action) {
            state.fetchListingsError = null;
            state.fetchListingsInProgress = false;
            state.listings = action.payload.listings;
        },
        fetchListingsError(state, action) {
            state.fetchListingsError = action.payload.error;
            state.fetchListingsInProgress = false;
            state.listings = null;
        },
        setSelectedListingId(state, action) {
            state.selectedListingUUID = action.payload.id;
        },
        setDateRange(state, action) {
            state.startDate = action.payload.startDate;
            state.endDate = action.payload.endDate;
        },
        fetchBookingsInProgress(state) {
            state.fetchBookingsInProgress = true;
        },
        fetchBookingsSuccess(state, action) {
            state.fetchBookingsError = null;
            state.fetchBookingsInProgress = false;
            state.bookings = action.payload.bookings;
        },
        fetchBookingsError(state, action) {
            state.fetchBookingsError = action.payload.error;
            state.fetchBookingsInProgress = false;
            state.bookings = [];
        },
        fetchAvailabilityExceptionsSuccess(state, action) {
            state.fetchAvailabilityExceptionsError = null;
            state.fetchAvailabilityExceptionsInProgress = false;
            const existingExceptions = state.availabilityExceptions || {};
            state.availabilityExceptions = Object.assign(Object.assign({}, existingExceptions), action.payload.availabilityExceptions);
        },
        fetchAvailabilityExceptionsError(state, action) {
            state.fetchAvailabilityExceptionsError = action.payload.error;
            state.fetchAvailabilityExceptionsInProgress = false;
            state.availabilityExceptions = null;
        },
        clearAvailabilityExceptions(state) {
            state.availabilityExceptions = null;
        },
    },
});
export const { fetchListingsSuccess, fetchListingsError, setSelectedListingId, setDateRange, fetchBookingsInProgress, fetchBookingsSuccess, fetchBookingsError, fetchAvailabilityExceptionsSuccess, fetchAvailabilityExceptionsError, clearAvailabilityExceptions, } = calendarSlice.actions;
export default calendarSlice.reducer;
export const fetchOwnListings = () => (dispatch, getState, sdk) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const response = yield sdk.ownListings.query();
        const listings = denormalisedResponseEntities(response);
        dispatch(fetchListingsSuccess({ listings }));
        dispatch(fetchBookings());
        dispatch(fetchAvailabilityExceptions());
    }
    catch (error) {
        dispatch(fetchListingsError({ error: storableError(error) }));
    }
});
export const selectListingByUUID = (listingId) => (dispatch, getState, sdk) => __awaiter(void 0, void 0, void 0, function* () {
    dispatch(setSelectedListingId({ id: listingId }));
    dispatch(fetchBookings());
    const availabilityExceptions = getState().CalendarPage.availabilityExceptions;
    if (!availabilityExceptions) {
        dispatch(fetchAvailabilityExceptions());
        return;
    }
    const listingsWithAvailabilityExceptions = Object.keys(availabilityExceptions);
    if (listingId === ALL_LISTINGS_KEY) {
        const listings = getState().CalendarPage.listings;
        if (listings) {
            const listingIds = listings.map(l => l.id.uuid);
            if (!listingIds.every(id => listingsWithAvailabilityExceptions.includes(id))) {
                dispatch(fetchAvailabilityExceptions());
            }
        }
    }
    else if (!listingsWithAvailabilityExceptions.includes(listingId.uuid)) {
        dispatch(fetchAvailabilityExceptions());
    }
});
export const selectDateRange = (startDate, endDate) => (dispatch, getState, sdk) => __awaiter(void 0, void 0, void 0, function* () {
    dispatch(setDateRange({ startDate, endDate }));
    dispatch(fetchBookings());
});
const fetchBookings = () => (dispatch, getState, sdk) => __awaiter(void 0, void 0, void 0, function* () {
    let listings = getState().CalendarPage.listings;
    if (listings === null) {
        return;
    }
    const listingId = getState().CalendarPage.selectedListingUUID;
    const start = getState().CalendarPage.startDate;
    const end = getState().CalendarPage.endDate;
    if (listingId !== ALL_LISTINGS_KEY) {
        const selectedListing = listings.find(l => l.id.uuid === listingId.uuid);
        if (selectedListing !== undefined) {
            listings = [selectedListing];
        }
    }
    if (start === null || end === null) {
        return;
    }
    dispatch(fetchBookingsInProgress());
    try {
        const promises = listings.map((listing) => __awaiter(void 0, void 0, void 0, function* () {
            const response = yield sdk.bookings.query({
                listingId: listing.id,
                start: moment(start).subtract(1, 'millisecond').toDate(),
                end,
                state: BOOKING_STATES.join(','),
                include: [
                    'transaction',
                    'transaction.customer',
                    'transaction.customer.profileImage',
                ],
            });
            const bookings = denormalisedResponseEntities(response);
            const filteredBookings = bookings.filter(booking => !txIsPaymentExpired(booking.transaction) &&
                !txIsPaymentPending(booking.transaction));
            return filteredBookings.map(booking => ({ booking, listing }));
        }));
        const bookings = (yield Promise.all(promises)).flat();
        dispatch(fetchBookingsSuccess({ bookings }));
    }
    catch (error) {
        dispatch(fetchBookingsError({ error: storableError(error) }));
    }
});
export const fetchAvailabilityExceptions = () => (dispatch, getState, sdk) => __awaiter(void 0, void 0, void 0, function* () {
    let listings = getState().CalendarPage.listings;
    if (listings === null) {
        return;
    }
    const listingId = getState().CalendarPage.selectedListingUUID;
    const start = new Date();
    const end = moment(start)
        .add(FUTURE_AVAILABILITY_EDIT_MAX_DAYS - 1, 'days')
        .toDate();
    if (listingId !== ALL_LISTINGS_KEY) {
        const selectedListing = listings.find(l => l.id.uuid === listingId.uuid);
        if (selectedListing !== undefined) {
            listings = [selectedListing];
        }
    }
    try {
        const promises = listings.map((listing) => __awaiter(void 0, void 0, void 0, function* () {
            const availabilityExceptions = [];
            let page = 1;
            let totalPages = 1;
            do {
                const response = yield sdk.availabilityExceptions.query({
                    listingId: listing.id,
                    start,
                    end,
                    page,
                });
                totalPages = response.data.meta.totalPages;
                availabilityExceptions.push(...denormalisedResponseEntities(response));
                page = page + 1;
            } while (page <= totalPages);
            return {
                [listing.id.uuid]: availabilityExceptions,
            };
        }));
        const availabilityExceptions = (yield Promise.all(promises)).reduce((acc, curr) => (Object.assign(Object.assign({}, acc), curr)), {});
        dispatch(fetchAvailabilityExceptionsSuccess({ availabilityExceptions }));
    }
    catch (error) {
        dispatch(fetchAvailabilityExceptionsError({
            error: storableError(error),
        }));
    }
});
export const createAvailabilityException = ({ end, seats, start, listingId }) => (dispatch, getState, sdk) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const response = yield sdk.availabilityExceptions.create({
            start: momentToUTCDate(moment(start)),
            end: momentToUTCDate(moment(end)),
            seats,
            listingId,
        }, { expand: true });
        return response;
    }
    catch (error) {
        if (error instanceof Error) {
            const isInPast = moment(start).isBefore(moment(), 'day');
            const isOverAYearInTheFuture = moment(start).isAfter(moment().add(1, 'year').subtract(1, 'day'), 'day');
            const determineI18nTitleKey = () => {
                if (isInPast) {
                    return 'BookingCalendar.Error.cannotBlockPastDays';
                }
                else if (isOverAYearInTheFuture) {
                    return 'BookingCalendar.Error.cannotBlockOverOneYearFutureDays';
                }
                return 'BookingCalendar.Error.cannotBlockDays';
            };
            dispatch(addSnackBar({
                type: SnackBarType.ERROR,
                i18nTitleKey: determineI18nTitleKey(),
                plainText: error.message,
            }));
        }
        else {
            // Sentry.captureException(error);
        }
    }
});
export const deleteAvailabilityException = ({ id }) => (dispatch, getState, sdk) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        yield sdk.availabilityExceptions.delete({ id }, { expand: true });
    }
    catch (error) {
        if (error instanceof Error) {
            dispatch(addSnackBar({
                type: SnackBarType.ERROR,
                i18nTitleKey: 'BookingCalendar.Error.cannotDeleteAvailabilityException',
                plainText: error.message,
            }));
        }
        else {
            // Sentry.captureException(error);
        }
    }
});
export const editAvailabilityExceptionsForAllListings = ({ dates, operation }) => (dispatch, getState, sdk) => __awaiter(void 0, void 0, void 0, function* () {
    const listings = getState().CalendarPage.listings;
    const availabilityExceptions = getState().CalendarPage.availabilityExceptions;
    if (listings === null ||
        listings.length === 0 ||
        availabilityExceptions === null) {
        return;
    }
    const exceptions = Object.values(availabilityExceptions).flat();
    const exceptionsOverDates = dates
        .map(date => exceptions.filter(exception => isDateWithinException(exception, date)))
        .flat();
    yield Promise.all(exceptionsOverDates.map(exception => dispatch(deleteAvailabilityException({ id: exception.id }))));
    if (operation === Operation.Blocking) {
        const dataForCreateExceptionOperation = listings
            .map(listing => dates.map((date) => ({
            listingId: listing.id,
            start: getStartDate(moment(date)),
            end: getEndDate(moment(date)),
            seats: 0,
        })))
            .flat();
        yield Promise.all(dataForCreateExceptionOperation.map(data => dispatch(createAvailabilityException(data))));
    }
    yield dispatch(fetchAvailabilityExceptions());
});
export const editAvailabilityExceptionsForSingleListing = ({ dates, availabilitiesOverSlotRange, newAvailableSlots, maxSeats, }) => (dispatch, getState, sdk) => __awaiter(void 0, void 0, void 0, function* () {
    if (availabilitiesOverSlotRange === null || availabilitiesOverSlotRange === void 0 ? void 0 : availabilitiesOverSlotRange.exceptionIds) {
        yield Promise.all(availabilitiesOverSlotRange.exceptionIds.map(exceptionId => dispatch(deleteAvailabilityException({ id: exceptionId }))));
    }
    const listingId = getState().CalendarPage.selectedListingUUID;
    if (newAvailableSlots !== undefined &&
        newAvailableSlots !== maxSeats &&
        listingId !== ALL_LISTINGS_KEY) {
        yield Promise.all(dates.map(slot => dispatch(createAvailabilityException({
            start: getStartDate(moment(slot)),
            end: getEndDate(moment(slot)),
            seats: newAvailableSlots,
            listingId,
        }))));
    }
    yield dispatch(fetchAvailabilityExceptions());
});
export const listingsSelector = (state) => state.CalendarPage.listings;
export const selectedListingUUIDSelector = (state) => state.CalendarPage.selectedListingUUID;
export const bookingsSelector = (state) => state.CalendarPage.bookings;
export const availabilityExceptionsSelector = (state) => state.CalendarPage.availabilityExceptions;
export const isFetchingCalendarBookingsSelector = (state) => state.CalendarPage.fetchBookingsInProgress;
