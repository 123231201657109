import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { useSelector } from 'react-redux';
import CmsContent from '../../styled-components/CmsContent/CmsContent';
import Modal from '../../styled-components/Modal/Modal';
import { ModalIllustrationVariant } from '../../styled-components/Modal/utils/interfaces';
export var CmsContentModalVariant;
(function (CmsContentModalVariant) {
    CmsContentModalVariant["TermsOfService"] = "TermsOfService";
    CmsContentModalVariant["PrivacyPolicy"] = "PrivacyPolicy";
    CmsContentModalVariant["NomadyPact"] = "NomadyPact";
    CmsContentModalVariant["GiftCardTerms"] = "GiftCardTerms";
})(CmsContentModalVariant || (CmsContentModalVariant = {}));
const CmsContentModal = ({ onClose, variant, }) => {
    const cmsData = useSelector((state) => state.cmsData);
    if (variant === CmsContentModalVariant.TermsOfService &&
        (cmsData === null || cmsData === void 0 ? void 0 : cmsData.termsOfServiceTitle) &&
        (cmsData === null || cmsData === void 0 ? void 0 : cmsData.termsOfServiceSection)) {
        return (_jsx(Modal, Object.assign({ id: "termsModal", isOpen: true, onClose: onClose, title: cmsData === null || cmsData === void 0 ? void 0 : cmsData.termsOfServiceTitle, illustration: ModalIllustrationVariant.Contract }, { children: _jsx(CmsContent, { omitLayoutRows: true, sections: cmsData === null || cmsData === void 0 ? void 0 : cmsData.termsOfServiceSection }) })));
    }
    if (variant === CmsContentModalVariant.PrivacyPolicy &&
        (cmsData === null || cmsData === void 0 ? void 0 : cmsData.privacyPolicyTitle) &&
        (cmsData === null || cmsData === void 0 ? void 0 : cmsData.privacyPolicySection)) {
        return (_jsx(Modal, Object.assign({ id: "policyModal", isOpen: true, onClose: onClose, title: cmsData === null || cmsData === void 0 ? void 0 : cmsData.privacyPolicyTitle }, { children: _jsx(CmsContent, { omitLayoutRows: true, sections: cmsData === null || cmsData === void 0 ? void 0 : cmsData.privacyPolicySection }) })));
    }
    if (variant === CmsContentModalVariant.GiftCardTerms &&
        (cmsData === null || cmsData === void 0 ? void 0 : cmsData.giftCardTermsTitle) &&
        (cmsData === null || cmsData === void 0 ? void 0 : cmsData.giftCardTermsSection)) {
        return (_jsx(Modal, Object.assign({ id: "pactModal", isOpen: true, onClose: onClose, title: cmsData === null || cmsData === void 0 ? void 0 : cmsData.giftCardTermsTitle }, { children: _jsx(CmsContent, { omitLayoutRows: true, sections: cmsData === null || cmsData === void 0 ? void 0 : cmsData.giftCardTermsSection }) })));
    }
    if (variant === CmsContentModalVariant.NomadyPact &&
        (cmsData === null || cmsData === void 0 ? void 0 : cmsData.nomadyPactTitle) &&
        (cmsData === null || cmsData === void 0 ? void 0 : cmsData.nomadyPactSection)) {
        return (_jsx(Modal, Object.assign({ id: "pactModal", isOpen: true, onClose: onClose, title: cmsData === null || cmsData === void 0 ? void 0 : cmsData.nomadyPactTitle }, { children: _jsx(CmsContent, { omitLayoutRows: true, sections: cmsData === null || cmsData === void 0 ? void 0 : cmsData.nomadyPactSection }) })));
    }
    return null;
};
export default CmsContentModal;
