var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { showTouristTaxReports } from '../../util/api';
const initialState = {
    touristTaxReports: [],
    fetchTouristTaxReportsInProgress: false,
    fetchTouristTaxReportsError: null,
};
export const TouristTaxReportsSlice = createSlice({
    name: 'app/touristTaxReports',
    initialState,
    reducers: {
        fetchTouristTaxReportsRequest: state => {
            state.fetchTouristTaxReportsInProgress = true;
            state.fetchTouristTaxReportsError = null;
        },
        fetchTouristTaxReportsSuccess: (state, action) => {
            state.touristTaxReports = action.payload;
            state.fetchTouristTaxReportsInProgress = false;
            state.fetchTouristTaxReportsError = null;
        },
        fetchTouristTaxReportsError: (state, action) => {
            state.fetchTouristTaxReportsInProgress = false;
            state.fetchTouristTaxReportsError = action.payload;
        },
    },
});
export const { fetchTouristTaxReportsSuccess, fetchTouristTaxReportsError } = TouristTaxReportsSlice.actions;
export const loadTouristTaxReports = () => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const touristTaxReports = yield showTouristTaxReports();
        dispatch(fetchTouristTaxReportsSuccess(touristTaxReports));
    }
    catch (error) {
        fetchTouristTaxReportsError(error);
        console.error(error);
    }
});
export const loadData = () => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    dispatch(loadTouristTaxReports());
});
export default TouristTaxReportsSlice.reducer;
