import React, { Component } from 'react';
import { string, object, array } from 'prop-types';
import classNames from 'classnames';
import { GoogleApiWrapper, Map, Marker } from 'google-maps-react';
import env from '@nomady/shared/utils/env';
import config from '../../config.ts';
import mapMarkerIcon from '../../util/mapMarkerIcon.ts';
import { getInitialMapPosition } from '../../util/maps.ts';

import css from './TransactionPanel.module.scss';

class DetailCardHeadingsMaybe extends Component {
  constructor(props) {
    super(props);

    this.initialMapPosition = getInitialMapPosition(
      this.props.markers,
      this.props.geolocation
    );
  }

  render() {
    const { showDetailCardHeadings, rootClassName } = this.props;

    const style = {
      position: 'relative',
      height: '450px',
      width: '90%',
    };

    const availableMarkerKeys = config.custom.mapMarkerTypes.map(
      mapMarkerType => mapMarkerType.key
    );
    const validMarkers = this.props.markers
      ? this.props.markers.filter(marker => {
          const splitKey = marker.key ? marker.key.split('_') : null;
          return splitKey && availableMarkerKeys.includes(splitKey[0]);
        })
      : [];

    const markers = validMarkers.map((marker, index) => {
      if (!marker) {
        return null;
      }

      const mapIcon = {
        url: mapMarkerIcon[marker.type],
        anchor: new window.google.maps.Point(19.43, 23.405),
        scaledSize: new window.google.maps.Size(38.86, 46.81),
      };
      const position = { lat: marker.lat, lng: marker.lng };
      return (
        <Marker
          key={marker.key}
          title={marker.type}
          position={position}
          icon={mapIcon}
        />
      );
    });

    return showDetailCardHeadings ? (
      <div className={classNames(rootClassName, css.detailCardMap)}>
        <Map
          google={window.google}
          draggable={true}
          fullscreenControl={false}
          streetViewControl={false}
          panControl={true}
          rotateControl={true}
          scaleControl={true}
          mapTypeControl={true}
          mapType={'satellite'}
          zoom={18}
          containerStyle={style}
          initialCenter={this.initialMapPosition}
          center={this.initialMapPosition}
        >
          {markers}
        </Map>
      </div>
    ) : null;
  }
}

DetailCardHeadingsMaybe.defaultProps = {
  tooltip: null,
  geocodeApiKey: env('GOOGLE_GEOCODER_API_KEY'),
  chosenMarker: config.custom.mapMarkerTypes[0],
  markers: [],
};

DetailCardHeadingsMaybe.propTypes = {
  tooltip: string,
  geocodeApiKey: string,
  locationCoordinates: object,
  markers: array,
};

export default GoogleApiWrapper({
  apiKey: config.maps.googleMapsAPIKey,
})(DetailCardHeadingsMaybe);
