import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import styled from 'styled-components/macro';
import { useSelector } from 'react-redux';
import Review from '../Review/Review';
import Card from '../Card/Card';
import { localeSelector } from '../../slices/UISlice';
const Reviews = styled.div `
  display: flex;
  gap: 16px;
  align-items: start;
`;
const ReviewCardWrap = styled.div `
  width: 284px;

  @media ${props => props.theme.device.tablet} {
    width: 345px;
  }
`;
const ReviewSlider = ({ reviews, }) => {
    const locale = useSelector(localeSelector);
    return (_jsx(Reviews, { children: reviews === null || reviews === void 0 ? void 0 : reviews.map(review => (_jsx(ReviewCardWrap, { children: _jsx(Card, { children: _jsx(Review, { review: review, locale: locale }) }) }, review.id.uuid))) }));
};
export default ReviewSlider;
