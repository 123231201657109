import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import css from './NotFoundPage.module.scss';
import { LayoutRow } from '../../styled-components/Layout/Layout.tsx';
import ContentPageContainer from '../../styled-components/PageContainer/ContentPageContainer.tsx';

class NotFoundPageComponent extends Component {
  constructor(props) {
    super(props);
    // The StaticRouter component used in server side rendering
    // provides the context object. We attach a `notfound` flag to
    // the context to tell the server to change the response status
    // code into a 404.
    this.props.staticContext.notfound = true;
  }

  render() {
    const { intl } = this.props;

    const title = intl.formatMessage({
      id: 'NotFoundPage.title',
    });

    return (
      <ContentPageContainer title={title}>
        <LayoutRow>
          <div className={css.root}>
            <div className={css.content}>
              <div className={css.number}>404</div>
              <h1 className={css.heading}>
                <FormattedMessage id="NotFoundPage.heading" />
              </h1>
              <p className={css.description}>
                <FormattedMessage id="NotFoundPage.description" />
              </p>
            </div>
          </div>
        </LayoutRow>
      </ContentPageContainer>
    );
  }
}

NotFoundPageComponent.defaultProps = {
  staticContext: {},
};

const { func, object, shape } = PropTypes;

NotFoundPageComponent.propTypes = {
  // context object from StaticRouter, injected by the withRouter wrapper
  staticContext: object,

  // from injectIntl
  intl: intlShape.isRequired,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const NotFoundPage = compose(withRouter, injectIntl)(NotFoundPageComponent);

export default NotFoundPage;
