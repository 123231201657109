import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components/macro';
import Button, { ButtonColorVariant, ButtonSizeVariant, } from '../../../styled-components/Button/Button';
import Icon, { IconSizeVariant, IconVariant, } from '../../../styled-components/Icon/Icon';
import Text, { TextVariant } from '../../../styled-components/Text/Text';
const IconWrapper = styled.div `
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-block: 10px;
`;
const ButtonRow = styled.div `
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex: 1;
  padding-top: 25px;
`;
const ButtonWrapper = styled.div ``;
const ListingLoadingErrorContent = () => {
    const intl = useIntl();
    const reloadPage = () => {
        window.location.reload();
    };
    return (_jsxs(_Fragment, { children: [_jsx(IconWrapper, { children: _jsx(Icon, { variant: IconVariant.RedWarning, sizeVariant: IconSizeVariant.Large }) }), _jsx(Text, Object.assign({ variant: TextVariant.H20 }, { children: intl.formatMessage({ id: 'ListingPage.listingLoadingErrorTitle' }) })), _jsx(ButtonRow, { children: _jsx(ButtonWrapper, { children: _jsx(Button, { onClick: reloadPage, label: intl.formatMessage({
                            id: 'ListingPage.reloadPage',
                        }), sizeVariant: ButtonSizeVariant.Small, colorVariant: ButtonColorVariant.Dark }) }) })] }));
};
export default ListingLoadingErrorContent;
