import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { useIntl } from 'react-intl';
import PasswordRecoveryForm from '../../forms/PasswordRecoveryForm/PasswordRecoveryForm';
import config from '../../config';
import SplitPicturePageContainer from '../../styled-components/PageContainer/SplitPicturePageContainer';
const PasswordRecoveryPage = () => {
    const intl = useIntl();
    const { siteTitle } = config;
    const schemaTitle = intl.formatMessage({ id: 'PasswordRecoveryPage.schemaTitle' }, { siteTitle });
    return (_jsx(SplitPicturePageContainer, Object.assign({ schemaTitle: schemaTitle, noIndex: true }, { children: _jsx(PasswordRecoveryForm, {}) })));
};
export default PasswordRecoveryPage;
