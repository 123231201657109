var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useLayoutEffect } from 'react';
import { ServerStyleSheet, ThemeProvider } from 'styled-components/macro';
import PropTypes from 'prop-types';
import ReactDOMServer from 'react-dom/server';
// react-dates needs to be initialized before using any react-dates component
// https://github.com/airbnb/react-dates#initialize
// NOTE: Initializing it here will initialize it also for app.test.js
import 'react-dates/initialize';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter, StaticRouter, useLocation } from 'react-router-dom';
import { Provider, useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { matchPathname } from '@nomady/shared/utils/routes';
import { isBrowser } from '@nomady/shared/utils/env';
import { IntlProvider } from './util/reactIntl';
import getStore from './store';
import routeConfiguration from './routeConfigurationWithComponents';
import Routes from './Routes';
import messagesEn from './translations/en.json';
import messagesEnUS from './translations/en-US.json';
import messagesDe from './translations/de.json';
import messagesDeDE from './translations/de-DE.json';
import messagesDeCH from './translations/de-CH.json';
import messagesDeAT from './translations/de-AT.json';
import messagesFr from './translations/fr.json';
import messagesFrFR from './translations/fr-FR.json';
import messagesFrCH from './translations/fr-CH.json';
import messagesIt from './translations/it.json';
import messagesItIT from './translations/it-IT.json';
import messagesItCH from './translations/it-CH.json';
import 'moment/locale/fr';
import 'moment/locale/fr-ch';
import 'moment/locale/it';
import 'moment/locale/it-ch';
import 'moment/locale/de';
import 'moment/locale/de-ch';
import 'moment/locale/de-at';
import { DefaultTheme } from './theme';
import { localeSelector, switchLocale } from './slices/UISlice';
import { getUserDefaultLocale } from './util/language';
import Usercentrics from './hooks/UsercentricsProvider';
import AppRouteHandler from './util/AppRouteHandler';
import { isApp } from './util/deviceContext';
import { SnackBarProvider } from './components/SnackBar/SnackBarProvider';
import { ErrorBoundary } from '@sentry/react';
import { ErrorBoundaryFallback } from './styled-components/ErrorBoundaryFallback/ErrorBoundaryFallback';
const locales = {
    'de-DE': Object.assign(Object.assign({}, messagesDe), messagesDeDE),
    'de-CH': Object.assign(Object.assign({}, messagesDe), messagesDeCH),
    'de-AT': Object.assign(Object.assign({}, messagesDe), messagesDeAT),
    'fr-FR': Object.assign(Object.assign({}, messagesFr), messagesFrFR),
    'fr-CH': Object.assign(Object.assign({}, messagesFr), messagesFrCH),
    'it-IT': Object.assign(Object.assign({}, messagesIt), messagesItIT),
    'it-CH': Object.assign(Object.assign({}, messagesIt), messagesItCH),
    'en-US': Object.assign(Object.assign({}, messagesEn), messagesEnUS),
};
const momentLocales = {
    'de-DE': 'de',
    'de-CH': 'de-CH',
    'de-AT': 'de-AT',
    'fr-FR': 'fr',
    'fr-CH': 'fr-CH',
    'it-IT': 'it',
    'it-CH': 'it-CH',
    'en-US': 'en-US',
};
const EnhancedIntlProvider = ({ children }) => {
    const { pathname } = useLocation();
    const locale = useSelector(localeSelector);
    const messages = locales[locale];
    const dispatch = useDispatch();
    const setLocale = (locale) => dispatch(switchLocale(locale));
    useLayoutEffect(() => {
        var _a, _b;
        if (isBrowser()) {
            const routes = matchPathname(pathname, routeConfiguration);
            const requestedLocale = 0 < (routes === null || routes === void 0 ? void 0 : routes.length) ? (_b = (_a = routes[0]) === null || _a === void 0 ? void 0 : _a.params) === null || _b === void 0 ? void 0 : _b.language : undefined;
            if (requestedLocale) {
                setLocale(requestedLocale);
            }
            else if (!locale) {
                setLocale(getUserDefaultLocale());
            }
        }
        moment.locale(momentLocales[locale], {
            week: {
                dow: 1,
            },
        });
    }, []);
    return (_jsx(IntlProvider, Object.assign({ locale: locale, messages: messages }, { children: children })));
};
export const ClientApp = props => {
    const { store } = props;
    const initOneSignal = () => __awaiter(void 0, void 0, void 0, function* () {
        const onesignalAppId = process.env.REACT_APP_ONESIGNAL_APP_ID;
        if (!onesignalAppId)
            throw new Error('Missing REACT_APP_ONESIGNAL_APP_ID environment variable');
        const { default: OneSignal } = yield import('onesignal-cordova-plugin');
        // Uncomment to set OneSignal logging to VERBOSE
        // OneSignal.Debug.setLogLevel(6);
        // OneSignal.Debug.setAlertLevel(6);
        OneSignal.initialize(onesignalAppId);
    });
    useEffect(() => {
        if (!isApp)
            return;
        initOneSignal();
    }, []);
    return (_jsx(Provider, Object.assign({ store: store }, { children: _jsx(DndProvider, Object.assign({ backend: HTML5Backend }, { children: _jsx(HelmetProvider, { children: _jsx(ThemeProvider, Object.assign({ theme: DefaultTheme }, { children: _jsxs(BrowserRouter, { children: [_jsx(AppRouteHandler, {}), _jsx(EnhancedIntlProvider, { children: _jsx(Usercentrics, { children: _jsxs(ErrorBoundary, Object.assign({ fallback: ({ resetError, error }) => (_jsx(ErrorBoundaryFallback, { resetError: resetError, error: error })) }, { children: [_jsx(Routes, { routes: routeConfiguration }), _jsx(SnackBarProvider, {})] })) }) })] }) })) }) })) })));
};
const { any } = PropTypes;
ClientApp.propTypes = { store: any.isRequired };
export const ServerApp = props => {
    const { url, context, helmetContext, store } = props;
    HelmetProvider.canUseDOM = false;
    return (_jsx(Provider, Object.assign({ store: store }, { children: _jsx(DndProvider, Object.assign({ backend: HTML5Backend }, { children: _jsx(HelmetProvider, Object.assign({ context: helmetContext }, { children: _jsx(ThemeProvider, Object.assign({ theme: DefaultTheme }, { children: _jsx(StaticRouter, Object.assign({ location: url, context: context }, { children: _jsx(EnhancedIntlProvider, { children: _jsx(Usercentrics, { children: _jsx(ErrorBoundary, Object.assign({ fallback: ({ resetError, error }) => (_jsx(ErrorBoundaryFallback, { resetError: resetError, error: error })) }, { children: _jsx(Routes, { routes: routeConfiguration }) })) }) }) })) })) })) })) })));
};
/**
 * Render the given route.
 *
 * @param {String} url Path to render
 * @param {Object} serverContext Server rendering context from react-router
 *
 * @returns {Object} Object with keys:
 *  - {String} body: Rendered application body of the given route
 *  - {Object} head: Application head metadata from react-helmet
 */
export const renderApp = (url, serverContext, preloadedState) => {
    // Don't pass an SDK instance since we're only rendering the
    // component tree with the preloaded store state and components
    // shouldn't do any SDK calls in the (server) rendering lifecycle.
    const store = getStore(undefined, preloadedState);
    const sheet = new ServerStyleSheet();
    const helmetContext = {};
    const body = ReactDOMServer.renderToString(sheet.collectStyles(_jsx(ServerApp, { url: url, context: serverContext, helmetContext: helmetContext, store: store })));
    const { helmet: head } = helmetContext;
    const styles = sheet.getStyleTags();
    return { head, body, styles };
};
