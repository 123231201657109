import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Controller, } from 'react-hook-form';
import styled from 'styled-components/macro';
import { ColorVariant } from '../../theme';
import Icon from '../Icon/Icon';
import InputErrorMessage from '../InputErrorMessage/InputErrorMessage';
import Spinner from '../Spinner/Spinner';
import { TextVariant } from '../Text/Text';
import { Wrapper, Label, ErrorMessageWrapper, FakeInput, SpinnerWrapper, Description, } from '../Input/Input';
import CheckboxInput from '../CheckboxInput/CheckboxInput';
const Element = styled.div `
  margin-top: 8px;
`;
const StyledFakeInput = styled(FakeInput) `
  display: flex;
  justify-content: space-between;
  padding: 0 16px;
`;
const InputMultiSelect = ({ id, label, description, variant, error, loading, options, control, rules, name, }) => {
    const hasError = !!error;
    const errorMessageId = hasError ? `${id}-errormessage` : '';
    const errorMessageAriaInputProps = hasError
        ? { 'aria-invalid': true, 'aria-errormessage': errorMessageId }
        : {};
    return (_jsx(Controller, { render: ({ field }) => {
            const selectedValues = field.value || [];
            return (_jsxs(Wrapper, { children: [label && (_jsx(Label, Object.assign({ inputVariant: variant, variant: TextVariant.H13, as: "label", htmlFor: id, hasError: hasError }, { children: label }))), options.map(({ value, label, icon }) => (_jsx(Element, { children: _jsxs(StyledFakeInput, Object.assign({ variant: variant, hasError: hasError, hasIcon: !!icon }, errorMessageAriaInputProps, { children: [_jsx(CheckboxInput, { id: value, label: label, checked: selectedValues.includes(value), onChange: () => {
                                        const checked = selectedValues.includes(value);
                                        if (checked) {
                                            const index = selectedValues.indexOf(value);
                                            const editedArray = selectedValues;
                                            editedArray.splice(index, 1);
                                            return field.onChange([...editedArray]);
                                        }
                                        return field.onChange([...selectedValues, value]);
                                    } }), icon && (_jsxs("label", Object.assign({ htmlFor: id, "aria-hidden": true }, { children: [!loading && _jsx(Icon, { variant: icon }), loading && (_jsx(SpinnerWrapper, { children: _jsx(Spinner, { colorVariant: ColorVariant.Cave }) }))] })))] })) }, `${id}-${value}`))), hasError && (_jsx(ErrorMessageWrapper, Object.assign({ role: "alert" }, { children: _jsx(InputErrorMessage, { id: errorMessageId, error: error }) }))), description && _jsx(Description, { text: description })] }));
        }, name: name, control: control, rules: rules }));
};
export default InputMultiSelect;
