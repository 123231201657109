var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styled from 'styled-components/macro';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { LOCALE_CODES } from '@nomady/shared/marketplace-custom-config';
import routeConfiguration from '@nomady/shared/routeConfiguration/routeConfiguration';
import { matchPathname, pathByRouteName } from '@nomady/shared/utils/routes';
import { getDomainWithProtocol, getMarketplaceDomain, } from '@nomady/shared/utils/localized-domains';
import { switchAutoTranslation } from '../../slices/UISlice';
import Modal from '../Modal/Modal';
import { ModalIllustrationVariant } from '../Modal/utils/interfaces';
import Button, { ButtonColorVariant, ButtonOutlineVariant, ButtonSizeVariant, } from '../Button/Button';
import AutomaticTranslationToggle from '../AutomaticTranslationToggle/AutomaticTranslationToggle';
import { IconVariant } from '../Icon/Icon';
import { isApp } from '../../util/deviceContext';
import Text, { TextVariant } from '../Text/Text';
const ModalWrap = styled.div `
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const LocaleSelectionWrap = styled.div `
  margin-top: 20px;
  display: flex;
  flex-direction: column;

  @media ${props => props.theme.device.tablet} {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;
const LocaleOption = styled.div `
  border: 0;
  ${props => props.theme.typeset.H13}
  height: 36px;
  margin-bottom: 10px;
  text-align: center;
  border-radius: 36px;
  flex-grow: 1;

  @media ${props => props.theme.device.tablet} {
    margin-bottom: 15px;
  }
`;
const LocaleSwitchModal = ({ showLocaleSwitchModal, location, match, selectedLocale, onCloseLocaleSwitchModal, }) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const onAutomaticTranslationToggleChanged = (checked) => dispatch(switchAutoTranslation(checked));
    const { autoTranslation: automaticTranslationChecked } = useSelector((state) => state.UI);
    const { dynamicLocalizedRouteNames } = useSelector((state) => state.CmsContentPage);
    return (_jsx(Modal, Object.assign({ id: 'LocaleSwitchModal', title: intl.formatMessage({ id: 'LocaleSwitch.title' }), isOpen: showLocaleSwitchModal, onClose: onCloseLocaleSwitchModal, illustration: ModalIllustrationVariant.Conversation }, { children: _jsxs(ModalWrap, { children: [!isApp && (_jsx(LocaleSelectionWrap, { children: LOCALE_CODES.map(locale => {
                        var _a, _b, _c;
                        const inner = (_jsx(LocaleOption, { children: _jsx(Button, { icon: IconVariant.Globe, onClick: () => {
                                    if (selectedLocale !== locale.key) {
                                        onCloseLocaleSwitchModal();
                                    }
                                }, label: locale.label, sizeVariant: ButtonSizeVariant.Small, outlineVariant: selectedLocale === locale.key
                                    ? ButtonOutlineVariant.Outlined
                                    : undefined, colorVariant: ButtonColorVariant.Transparent }) }, locale.key));
                        if (selectedLocale === locale.key) {
                            return _jsx("div", { children: inner }, locale.key);
                        }
                        const page = matchPathname(location.pathname, routeConfiguration);
                        const _d = match.params, { name } = _d, restParams = __rest(_d, ["name"]);
                        let modifiedName = name;
                        if (dynamicLocalizedRouteNames) {
                            const localeKey = (_a = locale.key) === null || _a === void 0 ? void 0 : _a.substring(0, 2);
                            const updatedName = dynamicLocalizedRouteNames === null || dynamicLocalizedRouteNames === void 0 ? void 0 : dynamicLocalizedRouteNames[localeKey];
                            if (updatedName) {
                                modifiedName = updatedName;
                            }
                        }
                        const to = pathByRouteName((_c = (_b = page[0]) === null || _b === void 0 ? void 0 : _b.route) === null || _c === void 0 ? void 0 : _c.name, routeConfiguration, Object.assign(Object.assign({}, restParams), { name: modifiedName, language: locale.key }));
                        const domain = getMarketplaceDomain();
                        const hrefPrefix = getDomainWithProtocol(domain);
                        return (_jsx("a", Object.assign({ href: `${hrefPrefix}${to}`, onClick: event => event.stopPropagation() }, { children: inner }), locale.key));
                    }) })), isApp && (_jsx(Text, Object.assign({ variant: TextVariant.B30 }, { children: intl.formatMessage({ id: 'LocaleSwitch.appInfo' }) }))), _jsx(AutomaticTranslationToggle, { checked: automaticTranslationChecked, onToggle: onAutomaticTranslationToggleChanged })] }) })));
};
export default withRouter(LocaleSwitchModal);
