import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styled, { css } from 'styled-components/macro';
import { ColorVariant } from '../../theme';
import Icon, { IconVariant } from '../Icon/Icon';
import Text, { TextVariant } from '../Text/Text';
import Toggle from '../Toggle/Toggle';
export var LabeledToggleColorVariant;
(function (LabeledToggleColorVariant) {
    LabeledToggleColorVariant["Default"] = "Default";
    LabeledToggleColorVariant["Grey"] = "Grey";
    LabeledToggleColorVariant["White"] = "White";
})(LabeledToggleColorVariant || (LabeledToggleColorVariant = {}));
const Wrapper = styled.div `
  display: grid;
  grid-auto-flow: row;
  row-gap: 8px;
  width: 100%;
`;
const ToggleWrapper = styled.div `
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 100%;
  border: 0;

  ${props => props.colorVariant &&
    css `
      border-radius: 10px;
      padding: 10px 10px;
    `}

  background: ${props => {
    if (!props.colorVariant) {
        return props.theme.color.transparent;
    }
    return props.colorVariant === LabeledToggleColorVariant.White
        ? props.theme.color.pearl
        : props.theme.color.snow;
}}
`;
const Label = styled(Text) `
  display: block;
  margin-left: 4px;
`;
const Title = styled.div `
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
`;
const Description = styled.div `
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: min-content 1fr;
  column-gap: 10px;
`;
const LabeledToggle = ({ iconVariant, label, checked, onChange, colorVariant, description, }) => {
    return (_jsxs(Wrapper, { children: [_jsxs(ToggleWrapper, Object.assign({ colorVariant: colorVariant }, { children: [_jsxs(Title, { children: [iconVariant && _jsx(Icon, { variant: iconVariant }), label && (_jsx(Label, Object.assign({ variant: TextVariant.H13, colorVariant: ColorVariant.Cave }, { children: label })))] }), _jsx(Toggle, { checked: checked, onClick: onChange })] })), description && (_jsxs(Description, { children: [_jsx(Icon, { variant: IconVariant.Info, colorVariant: ColorVariant.Stone }), _jsx(Text, Object.assign({ variant: TextVariant.L4R, colorVariant: ColorVariant.Stone }, { children: description }))] }))] }));
};
export default LabeledToggle;
