import * as z from 'zod';
import { CampCategory, CleaningFeeType, VisitorsTaxType, } from '../../types';
import { types as sdkTypes } from '@nomady/shared/utils/sdkLoader';
import { localizedZodCustomErrorMap } from '../localizedZodCustomErrorMap';
import { PriceSchema } from '../price';
import { MARKETPLACE_CURRENCY, MarketplaceCurrency } from '../../config';
const { Money } = sdkTypes;
export const LISTING_MINIMUM_PRICES = {
    [MarketplaceCurrency.CHF]: 2000,
    [MarketplaceCurrency.EUR]: 500,
};
const defaultPricing = {
    [MarketplaceCurrency.EUR]: {
        seat: 1300,
        adult: 200,
        teen: 150,
        child: 100,
    },
    [MarketplaceCurrency.CHF]: {
        seat: 2500,
        adult: 500,
        teen: 300,
        child: 200,
    },
};
const CleaningFeePricingSchema = z.object({
    policy: z.literal(CleaningFeeType.cleaningFeePricing),
    price: PriceSchema,
});
const CleaningFeeIncludedSchema = z.object({
    policy: z.literal(CleaningFeeType.included),
});
const CleaningFeeSelfCleanSchema = z.object({
    policy: z.literal(CleaningFeeType.selfClean),
});
const VisitorsTaxPerPersonPerNightSchema = z.object({
    policy: z.literal(VisitorsTaxType.perPersonAndPerNight),
    adult: PriceSchema,
    teen: PriceSchema,
    child: PriceSchema,
});
const VisitorsTaxCommunalSchema = z.object({
    policy: z.literal(VisitorsTaxType.communal),
});
const VisitorsTaxNoneSchema = z.object({
    policy: z.literal(VisitorsTaxType.none),
});
const CommonPricingSchema = z.object({
    currency: z.nativeEnum(MarketplaceCurrency),
    pricing: z
        .object({
        seat: PriceSchema,
        adult: PriceSchema,
        teen: PriceSchema,
        child: PriceSchema,
        visitorsTax: z.discriminatedUnion('policy', [
            VisitorsTaxPerPersonPerNightSchema,
            VisitorsTaxCommunalSchema,
            VisitorsTaxNoneSchema,
        ]),
    })
        .refine(data => LISTING_MINIMUM_PRICES[data.seat.currency] <=
        data.seat.amount + data.adult.amount, {
        message: 'ListingSchemaError.basePriceTooLow',
        path: ['adult'],
    }),
});
const AccommodationPricingSchema = CommonPricingSchema.extend({
    category: z.literal(CampCategory.accommodation),
    cleaningFee: z.discriminatedUnion('policy', [
        CleaningFeePricingSchema,
        CleaningFeeIncludedSchema,
        CleaningFeeSelfCleanSchema,
    ]),
});
const CampingPricingSchema = CommonPricingSchema.extend({
    category: z.literal(CampCategory.camping),
});
export const ListingPricingSchema = z.discriminatedUnion('category', [
    AccommodationPricingSchema,
    CampingPricingSchema,
]);
export const getListingPricingSchema = (intl) => {
    z.setErrorMap(localizedZodCustomErrorMap(intl, 'ListingSchemaError'));
    return ListingPricingSchema;
};
export const getListingPricingDefaultValues = (publicData, currencyFromPublishedListing) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w;
    const currency = (_b = (_a = publicData === null || publicData === void 0 ? void 0 : publicData.currency) !== null && _a !== void 0 ? _a : currencyFromPublishedListing) !== null && _b !== void 0 ? _b : MARKETPLACE_CURRENCY;
    return {
        currency,
        category: publicData === null || publicData === void 0 ? void 0 : publicData.category,
        pricing: {
            seat: new Money((_d = (_c = publicData === null || publicData === void 0 ? void 0 : publicData.pricing) === null || _c === void 0 ? void 0 : _c.seat) !== null && _d !== void 0 ? _d : defaultPricing[currency].seat, currency),
            adult: new Money((_f = (_e = publicData === null || publicData === void 0 ? void 0 : publicData.pricing) === null || _e === void 0 ? void 0 : _e.adult) !== null && _f !== void 0 ? _f : defaultPricing[currency].adult, currency),
            teen: new Money((_h = (_g = publicData === null || publicData === void 0 ? void 0 : publicData.pricing) === null || _g === void 0 ? void 0 : _g.teen) !== null && _h !== void 0 ? _h : defaultPricing[currency].teen, currency),
            child: new Money((_k = (_j = publicData === null || publicData === void 0 ? void 0 : publicData.pricing) === null || _j === void 0 ? void 0 : _j.child) !== null && _k !== void 0 ? _k : defaultPricing[currency].child, currency),
            visitorsTax: {
                policy: publicData === null || publicData === void 0 ? void 0 : publicData.visitorsTaxType,
                adult: new Money((_o = (_m = (_l = publicData === null || publicData === void 0 ? void 0 : publicData.pricing) === null || _l === void 0 ? void 0 : _l.visitorsTax) === null || _m === void 0 ? void 0 : _m.adult) !== null && _o !== void 0 ? _o : 0, currency),
                teen: new Money((_r = (_q = (_p = publicData === null || publicData === void 0 ? void 0 : publicData.pricing) === null || _p === void 0 ? void 0 : _p.visitorsTax) === null || _q === void 0 ? void 0 : _q.teen) !== null && _r !== void 0 ? _r : 0, currency),
                child: new Money((_u = (_t = (_s = publicData === null || publicData === void 0 ? void 0 : publicData.pricing) === null || _s === void 0 ? void 0 : _s.visitorsTax) === null || _t === void 0 ? void 0 : _t.child) !== null && _u !== void 0 ? _u : 0, currency),
            },
        },
        cleaningFee: {
            policy: publicData === null || publicData === void 0 ? void 0 : publicData.cleaningFeeType,
            price: new Money((_w = (_v = publicData === null || publicData === void 0 ? void 0 : publicData.pricing) === null || _v === void 0 ? void 0 : _v.cleaningFee) !== null && _w !== void 0 ? _w : 0, currency),
        },
    };
};
