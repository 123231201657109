import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import styled from 'styled-components/macro';
import Markdown from 'react-showdown';
import Text, { TextVariant } from '../../styled-components/Text/Text';
const Chapter = styled.div `
  margin-top: 60px;
  margin-bottom: 60px;
`;
const ParagraphGroup = styled.div `
  margin-top: 40px;
  margin-bottom: 40px;
`;
const ParagraphGroupTitle = styled.div `
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const Paragraph = styled.div `
  display: flex;
  flex-direction: row;
  margin-top: 30px;
  margin-bottom: 30px;
`;
const ParagraphNumber = styled(Text) `
  display: block;
  margin-right: 30px;
  margin-left: 15px;
  flex-shrink: 0;
`;
const ParagraphText = styled(Text) `
  display: block;
  flex-grow: 1;

  p {
    margin: 0;
  }

  strong {
    font-weight: 500;
  }
`;
const TermsOfService = ({ section }) => (_jsxs(_Fragment, { children: [_jsx(Text, { children: section === null || section === void 0 ? void 0 : section.issuedAt }), section === null || section === void 0 ? void 0 : section.chapters.map((chapter, chapterIndex) => (_jsxs(Chapter, { children: [_jsx(Text, Object.assign({ variant: TextVariant.H3, as: "h2" }, { children: chapter.title })), chapter.groups.map((paragraphGroup, paragraphGroupIndex) => (_jsxs(ParagraphGroup, { children: [_jsxs(ParagraphGroupTitle, { children: [_jsx(ParagraphNumber, Object.assign({ variant: TextVariant.H6 }, { children: paragraphGroup.number })), _jsx(Text, Object.assign({ variant: TextVariant.H6, as: "h3" }, { children: paragraphGroup.title }))] }), paragraphGroup.paragraphs.map((paragraph, paragraphIndex) => (_jsxs(Paragraph, { children: [_jsx(ParagraphNumber, { children: paragraph.number }), _jsx(ParagraphText, { children: _jsx(Markdown, { markdown: paragraph.text }) })] }, `paragraph-${chapterIndex}-${paragraphGroupIndex}-${paragraphIndex}`)))] }, `paragraph-group-${chapterIndex}-${paragraphGroupIndex}`)))] }, `chapter=${chapterIndex}`)))] }));
export default TermsOfService;
