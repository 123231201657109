import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useRef } from 'react';
import styled from 'styled-components/macro';
import { injectIntl } from 'react-intl';
import Text from '../Text/Text';
import Button, { ButtonColorVariant } from '../Button/Button';
import Input, { InputVariant } from '../Input/Input';
import MailchimpSignup from '../MailchimpSignup/MailchimpSignup';
const Wrapper = styled.section `
  width: 100%;

  background: ${props => props.theme.color.fir};
`;
const Content = styled.div `
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding-top: 60px;
  padding-bottom: 60px;

  @media ${props => props.theme.device.desktop} {
    flex-direction: row;

    padding-top: 80px;
    padding-bottom: 80px;
  }

  ${props => props.theme.layout.container}
`;
const Left = styled(Text) `
  display: block;
  margin-bottom: 20px;

  color: ${props => props.theme.color.pearl};

  @media ${props => props.theme.device.tabletL} {
    margin-bottom: 40px;
  }

  @media ${props => props.theme.device.desktop} {
    margin-bottom: 0;
    margin-right: 80px;
    width: 290px;
  }
`;
const ButtonWrapper = styled.div `
  align-self: flex-end;

  @media ${props => props.theme.device.tabletL} {
    margin-top: 30px;
  }
`;
const FormWrapper = styled.form `
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  flex-grow: 1;

  @media ${props => props.theme.device.tabletL} {
    margin-bottom: 0;
  }
`;
const InputWrapper = styled.div `
  margin-bottom: 30px;

  div:first-child {
    margin-bottom: 30px;
  }

  @media ${props => props.theme.device.tabletL} {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-basis: 100%;
    margin-bottom: initial;

    div:first-child {
      flex-grow: 1;
      margin-right: 30px;
      margin-bottom: initial;
    }

    div:last-child {
      flex-grow: 1;
    }
  }
`;
const SectionNewsletter = ({ text, intl, }) => {
    const [firstName, setFirstName] = useState();
    const [email, setEmail] = useState();
    const formRef = useRef(null);
    const loadMailchimpSignup = !!email && !!firstName;
    const onSubmit = (event) => {
        var _a;
        event.preventDefault();
        if (formRef === null || formRef === void 0 ? void 0 : formRef.current) {
            (_a = formRef === null || formRef === void 0 ? void 0 : formRef.current) === null || _a === void 0 ? void 0 : _a.submit();
        }
    };
    return (_jsx(Wrapper, { children: _jsxs(Content, { children: [_jsx(Left, Object.assign({ as: "h2" }, { children: text || intl.formatMessage({ id: 'Newsletter.introduction' }) })), _jsxs(FormWrapper, Object.assign({ onSubmit: onSubmit }, { children: [_jsxs(InputWrapper, { children: [_jsx(Input, { id: "given-name", type: "text", label: intl.formatMessage({ id: 'Newsletter.firstName' }), variant: InputVariant.Dark, autoComplete: "given-name", value: firstName, onChange: event => setFirstName(event.target.value) }), _jsx(Input, { id: "email", type: "email", label: intl.formatMessage({ id: 'Newsletter.email' }), variant: InputVariant.Dark, autoComplete: "email", value: email, onChange: event => setEmail(event.target.value) })] }), _jsx(ButtonWrapper, { children: _jsx(Button, { type: "submit", label: intl.formatMessage({ id: 'Newsletter.submit' }), colorVariant: ButtonColorVariant.Light }) })] })), loadMailchimpSignup && (_jsx(MailchimpSignup, { email: email, firstName: firstName, formRef: formRef }))] }) }));
};
export default injectIntl(SectionNewsletter);
