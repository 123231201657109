import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { types as sdkTypes } from '@nomady/shared/utils/sdkLoader';
import { LISTING_PAGE_PARAM_TYPE_DRAFT, LISTING_PAGE_PARAM_TYPE_NEW, LISTING_PAGE_PENDING_APPROVAL_VARIANT, createSlug, } from '@nomady/shared/utils/urlHelpers';
import { LISTING_STATE_DRAFT, LISTING_STATE_PENDING_APPROVAL, } from '@nomady/shared/utils/types';
import Page from '@nomady/shared/routeConfiguration/Page';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { currentUserSelector } from '../../ducks/user.duck';
import { EditListingWizard, NamedRedirect } from '../../components/index';
import SignedInPageContainer from '../../styled-components/PageContainer/SignedInPageContainer';
const { UUID } = sdkTypes;
// N.B. All the presentational content needs to be extracted to their own components
export const EditListingPage = props => {
    var _a;
    const { params } = props;
    const intl = useIntl();
    const state = useSelector((state) => state);
    const currentUser = useSelector(currentUserSelector);
    const page = state.EditListingPage;
    const getOwnListing = (id) => {
        const listings = getMarketplaceEntities(state, [{ id, type: 'ownListing' }]);
        return listings.length === 1 ? listings[0] : null;
    };
    const { id, type, returnURLType } = params;
    const isNewURI = type === LISTING_PAGE_PARAM_TYPE_NEW;
    const isDraftURI = type === LISTING_PAGE_PARAM_TYPE_DRAFT;
    const isNewListingFlow = isNewURI || isDraftURI;
    const listingId = page.submittedListingId || (id ? new UUID(id) : null);
    const listing = listingId && getOwnListing(listingId);
    const listingState = (_a = listing === null || listing === void 0 ? void 0 : listing.attributes) === null || _a === void 0 ? void 0 : _a.state;
    const isPastDraft = listingState && listingState !== LISTING_STATE_DRAFT;
    const shouldRedirect = isNewListingFlow && listingId && isPastDraft;
    const hasStripeOnboardingDataIfNeeded = returnURLType
        ? !!(currentUser && currentUser.id)
        : true;
    const showForm = hasStripeOnboardingDataIfNeeded && (isNewURI || (listing === null || listing === void 0 ? void 0 : listing.id));
    if (shouldRedirect) {
        const isPendingApproval = listing && listingState === LISTING_STATE_PENDING_APPROVAL;
        // If page has already listingId (after submit) and current listings exist
        // redirect to listing page
        const listingSlug = listing ? createSlug(listing.attributes.title) : null;
        const redirectProps = isPendingApproval
            ? {
                name: Page.ListingVariant,
                params: {
                    id: listingId.uuid,
                    slug: listingSlug,
                    variant: LISTING_PAGE_PENDING_APPROVAL_VARIANT,
                },
            }
            : {
                name: Page.Listing,
                params: {
                    id: listingId.uuid,
                    slug: listingSlug,
                },
            };
        return _jsx(NamedRedirect, Object.assign({}, redirectProps));
    }
    if (showForm) {
        const title = isNewListingFlow
            ? intl.formatMessage({ id: 'EditListingPage.titleCreateListing' })
            : intl.formatMessage({ id: 'EditListingPage.titleEditListing' });
        return (_jsx(SignedInPageContainer, Object.assign({ title: title }, { children: _jsx(EditListingWizard, { params: params, listing: listing, currentUser: currentUser, updateInProgress: page.updateInProgress || page.createListingDraftInProgress }) })));
    }
    // If user has come to this page through a direct linkto edit existing listing,
    // we need to load it first.
    return (_jsx(SignedInPageContainer, { title: intl.formatMessage({
            id: 'EditListingPage.loadingListingData',
        }) }));
};
export default EditListingPage;
