const getLocationApiParams = (locationSearchQuery) => {
    const location = locationSearchQuery === null || locationSearchQuery === void 0 ? void 0 : locationSearchQuery.location;
    const bounds = !(locationSearchQuery === null || locationSearchQuery === void 0 ? void 0 : locationSearchQuery.region) && (location === null || location === void 0 ? void 0 : location.bounds)
        ? `${location.bounds.ne.lat},${location.bounds.ne.lng},${location.bounds.sw.lat},${location.bounds.sw.lng}`
        : undefined;
    const origin = (locationSearchQuery === null || locationSearchQuery === void 0 ? void 0 : locationSearchQuery.origin)
        ? `${locationSearchQuery === null || locationSearchQuery === void 0 ? void 0 : locationSearchQuery.origin.lat},${locationSearchQuery === null || locationSearchQuery === void 0 ? void 0 : locationSearchQuery.origin.lng}`
        : undefined;
    return {
        bounds,
        meta_regions: locationSearchQuery === null || locationSearchQuery === void 0 ? void 0 : locationSearchQuery.region,
        origin,
    };
};
export default getLocationApiParams;
