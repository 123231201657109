var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import styled from 'styled-components/macro';
const InputWrapper = styled.input `
  background-color: ${props => props.theme.color.basicPearlWhiteTypeWhite};
  border-radius: 8px;
  padding: 0 16px;
  border: 1px solid transparent;
  width: 100%;
  height: 64px;

  ${props => props.theme.typeset.L1R}

  &:focus {
    outline: 0;
  }

  &:hover {
    border: 1px solid ${props => props.theme.color.functionStoneGreyInactive};
  }

  &::placeholder {
    color: ${props => props.theme.color.functionStoneGreyInactive};
  }
`;
const Input = (_a) => {
    var { inputRef } = _a, rest = __rest(_a, ["inputRef"]);
    return (_jsx(InputWrapper, Object.assign({}, rest, { ref: inputRef })));
};
export default Input;
