import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styled from 'styled-components/macro';
import { ColorVariant } from '../../theme';
import Icon, { IconSizeVariant } from '../Icon/Icon';
import Text, { TextVariant } from '../Text/Text';
const Wrapper = styled.div `
  display: flex;
  flex-direction: column;
  min-width: 280px;
  position: relative;
`;
export const Label = styled(Text) `
  margin-left: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  width: 100%;
`;
export const Title = styled.div `
  display: flex;
  gap: 8px;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;
  height: 64px;

  border-bottom: 1px solid ${props => props.theme.color.functionFogGreyInactive};
`;
const ChildrenWrapper = styled.div `
  display: block;
  overflow-y: auto;

  @media ${props => props.theme.device.tablet} {
    padding-bottom: 10px;
  }
`;
const ChildSeperator = styled.hr `
  height: 1px;
  border: none;
  margin-bottom: 5px;

  @media ${props => props.theme.device.tablet} {
    box-sizing: border-box;
    border-bottom: 1px solid ${props => props.theme.color.snow};
    margin-top: 0;
    margin-left: 5px;
    margin-bottom: 0;
    margin-right: 5px;
  }
`;
const FilterGroup = ({ iconVariant, label, children, }) => {
    return (_jsxs(Wrapper, { children: [_jsxs(Title, { children: [_jsx(Icon, { variant: iconVariant, sizeVariant: IconSizeVariant.MediumLarge }), _jsx(Label, Object.assign({ variant: TextVariant.L1SB, colorVariant: ColorVariant.Cave }, { children: label }))] }), _jsxs(ChildrenWrapper, { children: [0 < (children === null || children === void 0 ? void 0 : children.length) &&
                        (children === null || children === void 0 ? void 0 : children.map((child, index) => (_jsxs(React.Fragment, { children: [child, index !== children.length - 1 && (_jsx(ChildSeperator, {}))] }, index)))), !(children === null || children === void 0 ? void 0 : children.length) && children] })] }));
};
export default FilterGroup;
