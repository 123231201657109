import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import 'react-big-calendar/lib/css/react-big-calendar.css';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import { Calendar, momentLocalizer, Views, } from 'react-big-calendar';
import LayoutSideNavigation from '../../styled-components/LayoutSideNavigation/LayoutSideNavigation';
import SignedInPageContainer from '../../styled-components/PageContainer/SignedInPageContainer';
import Text, { TextVariant } from '../../styled-components/Text/Text';
import { CalendarComponentToolbar } from './components/CalendarComponentToolbar';
import { CalendarComponentDateHeader } from './components/CalendarComponentDateHeader';
import { CalendarComponentMonthHeader } from './components/CalendarComponentMonthHeader';
import { CalendarComponentWeekHeader } from './components/CalendarComponentWeekHeader';
import { CalendarComponentEvent } from './components/CalendarComponentEvent';
import { CalendarComponentEventWrapper } from './components/CalendarComponentEventWrapper';
import { getTransactionStatus } from '@nomady/shared/utils/transaction';
import BookingDetailsModal from './components/BookingDetailsModal';
import moment from 'moment';
import { momentToUTCDate } from '@nomady/shared/utils/dates';
import { bookingsSelector, fetchOwnListings, isFetchingCalendarBookingsSelector, listingsSelector, selectDateRange, } from './CalendarSlice';
import { CalendarComponentDateCellWrapper } from './components/CalendarComponentDateCellWrapper';
import AvailabilityDetailsModal from './components/AvailabilityDetailsModal';
import Button, { ButtonColorVariant, ButtonSizeVariant, } from '../../styled-components/Button/Button';
import { IconVariant } from '../../styled-components/Icon/Icon';
import Page from '@nomady/shared/routeConfiguration/Page';
import { CalendarLegend } from './components/CalendarLegend';
const Head = styled.div `
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 18px;
`;
const CalendarStyles = styled.div `
  min-height: 80vh;

  .rbc-month-view {
    min-height: ${props => (props.maxNumberOfBookingsForSingleDay * 20 + 50) * 5 + 50}px;
  }

  .rbc-time-content {
    display: none;
  }
  .rbc-time-header {
    height: 100%;
  }
  .rbc-time-header-gutter {
    display: none;
  }
  .rbc-time-header-content {
    border: none;
  }
  .rbc-show-more {
    text-align: start;
    background-color: ${props => props.theme.color.pearl};
    margin: 2px 4px;
    padding: 0 8px;
    height: auto;
    width: calc(100% - 8px);
    pointer-events: auto;
    border-radius: 4px;
    color: ${props => props.theme.color.stone};

    ${props => props.theme.typeset.L4SB}
  }
  .rbc-show-more:hover {
    box-shadow: inset 0 0 0 1px ${props => props.theme.color.fog};
  }

  .rbc-month-view,
  .rbc-time-view {
    border: none;
    overflow: hidden;
  }

  .rbc-header {
    background-color: white;
    border-radius: 8px;
    border: 0.5px solid ${props => props.theme.color.fog};
    padding: 0;
  }
  .rbc-day-bg {
    background-color: ${props => props.theme.color.pearl};
    border-radius: 8px;
    border: 0.5px solid ${props => props.theme.color.fog};
    cursor: pointer;
  }
  .rbc-day-bg:hover {
    background-color: ${props => props.theme.color.lagoon};
    opacity: 0.5;
  }

  .rbc-day-bg {
    margin-top: -0.25px;
    margin-bottom: -0.25px;
  }
  .rbc-header {
    margin-left: -0.25px;
    margin-right: -0.25px;
    margin-bottom: -0.25px;
  }
  /* Adjust bottom margin for the last row */
  .rbc-month-row:last-child .rbc-day-bg {
    margin-bottom: 0;
  }
  /* Adjust left margin for the first column */
  .rbc-header:first-child {
    margin-left: 0;
  }
  /* Adjust right margin for the last column */
  .rbc-header:last-child {
    margin-right: 0;
  }

  .rbc-month-row {
    border: none;
  }

  .rbc-off-range-bg {
    background-color: ${props => props.theme.color.pearl};
    opacity: 0.5;
  }

  .rbc-day-bg.rbc-selected-cell {
    background-color: ${props => props.theme.color.lagoon};
    opacity: 1;
  }
  .rbc-today {
    border-color: ${props => props.theme.color.forest};
    z-index: 1;
  }

  .rbc-row-content {
    pointer-events: none;
  }
  .rbc-allday-cell {
    padding-top: 4px;
  }

  .rbc-row-segment {
    padding: 0;
  }
`;
const ButtonsGroup = styled.div `
  display: flex;
  gap: 8px;
`;
const localizer = momentLocalizer(moment);
const CalendarPage = () => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const isFetchingBookings = useSelector(isFetchingCalendarBookingsSelector);
    const [date, setDate] = useState(new Date());
    const [delayedDate, setDelayedDate] = useState(date);
    const [view, setView] = useState(Views.MONTH);
    const onNavigate = useCallback((newDate) => setDate(newDate), []);
    const onView = useCallback((newView) => setView(newView), []);
    const setWeekView = useCallback((events, date) => {
        setView(Views.WEEK);
        setDate(date);
    }, []);
    useEffect(() => {
        if (!isFetchingBookings) {
            setDelayedDate(date);
        }
    }, [isFetchingBookings]);
    useEffect(() => {
        const startDate = (view === Views.MONTH ? moment(date).startOf('month') : moment(date)).startOf('isoWeek');
        const endDate = (view === Views.MONTH ? moment(date).endOf('month') : moment(date)).endOf('isoWeek');
        dispatch(selectDateRange(momentToUTCDate(startDate), momentToUTCDate(endDate)));
    }, [date, view]);
    const [bookingDetailsModalOpen, setBookingDetailsModalOpen] = useState(false);
    const [selectedBooking, setSelectedBooking] = useState();
    const [selectedSlots, setSelectedSlots] = useState();
    const listings = useSelector(listingsSelector);
    const bookings = useSelector(bookingsSelector);
    const calendarEvents = useMemo(() => {
        return bookings.map(booking => ({
            id: booking.booking.id,
            title: booking.listing.attributes.title,
            start: booking.booking.attributes.start,
            end: booking.booking.attributes.end,
            state: getTransactionStatus(booking.booking.transaction),
        }));
    }, [bookings]);
    const maxNumberOfBookingsForSingleDay = useMemo(() => {
        const maxNumberOfBookingsForSingleDayObject = calendarEvents.reduce((max, event) => {
            const start = moment(event.start).startOf('day');
            const end = moment(event.end).startOf('day');
            let currentDay = start;
            while (currentDay <= end) {
                const dayKey = currentDay.format('YYYY-MM-DD');
                max[dayKey] = (max[dayKey] || 0) + 1;
                currentDay = currentDay.clone().add(1, 'days');
            }
            return max;
        }, {});
        return Math.max(...Object.values(maxNumberOfBookingsForSingleDayObject), 0);
    }, [calendarEvents]);
    const onSelectEvent = (selectedEvent) => {
        const foundBooking = bookings.find(booking => booking.booking.id.uuid === selectedEvent.id.uuid);
        setSelectedBooking(foundBooking);
        setBookingDetailsModalOpen(true);
    };
    useEffect(() => {
        dispatch(fetchOwnListings());
    }, []);
    if (!listings) {
        return null;
    }
    return (_jsx(SignedInPageContainer, Object.assign({ title: intl.formatMessage({ id: 'BookingCalendar.title' }) }, { children: _jsxs(LayoutSideNavigation, { children: [_jsxs(Head, { children: [_jsx(Text, Object.assign({ variant: TextVariant.H2SB, as: "h1" }, { children: intl.formatMessage({ id: 'BookingCalendar.title' }) })), _jsx(ButtonsGroup, { children: _jsx(Button, Object.assign({ sizeVariant: ButtonSizeVariant.Small, colorVariant: ButtonColorVariant.Forest, icon: IconVariant.Calendar_NEW, targetRoute: Page.InboxBase }, { children: intl.formatMessage({ id: 'BookingCalendar.switchToList' }) })) })] }), _jsx(CalendarStyles, Object.assign({ maxNumberOfBookingsForSingleDay: maxNumberOfBookingsForSingleDay }, { children: _jsx(Calendar, { date: delayedDate, onNavigate: onNavigate, onView: onView, view: view, longPressThreshold: 10, localizer: localizer, events: calendarEvents, defaultView: "month", views: ['month', 'week'], onShowMore: setWeekView, selectable: "ignoreEvents", onSelectSlot: setSelectedSlots, eventPropGetter: (event) => ({
                            style: {
                                margin: '0',
                                padding: '0',
                                backgroundColor: 'transparent',
                                outline: 'none',
                            },
                        }), onSelectEvent: onSelectEvent, messages: {
                            showMore: total => intl.formatMessage({ id: 'BookingCalendar.showMore' }, { amount: total }),
                        }, components: {
                            event: CalendarComponentEvent,
                            eventWrapper: CalendarComponentEventWrapper,
                            toolbar: CalendarComponentToolbar,
                            month: {
                                dateHeader: CalendarComponentDateHeader,
                                header: CalendarComponentMonthHeader,
                            },
                            week: {
                                header: CalendarComponentWeekHeader,
                            },
                            dateCellWrapper: CalendarComponentDateCellWrapper,
                        } }) })), _jsx(CalendarLegend, {}), _jsx(BookingDetailsModal, { open: bookingDetailsModalOpen, onClose: () => setBookingDetailsModalOpen(false), booking: selectedBooking }), _jsx(AvailabilityDetailsModal, { selectedSlots: selectedSlots, setSelectedSlots: setSelectedSlots })] }) })));
};
export default CalendarPage;
