import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styled from 'styled-components/macro';
import { useIntl } from 'react-intl';
import Text, { TextVariant } from '../../Text/Text';
import { ColorVariant } from '../../../theme';
import LikeButton, { LikeButtonColorVariant, } from '../../LikeButton/LikeButton';
import TertiaryButton from '../../TertiaryButton';
const Wrapper = styled.div `
  background-color: ${props => props.theme.color.pearl};
  border-radius: 5px;
  display: flex;
  padding: 5px;
  align-items: center;
  min-height: 68px;
  border: 1px solid ${props => props.theme.color.cave};
  gap: 10px;

  @media ${props => props.theme.device.desktop} {
    min-width: 420px;
  }
`;
const Content = styled.div `
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  min-width: 0;
  margin-block: auto;

  @media ${props => props.theme.device.tabletL} {
    align-self: flex-start;
  }
`;
const LikeButtonWrapper = styled.div `
  margin-inline: 5px;
`;
const TertiaryButtonWrapper = styled.div `
  margin-inline: 20px;
`;
const FavoritesListCard = ({ name, listings, onAddOrRemoveListing, currentListingInList, addOrRemoveLoading = false, }) => {
    const intl = useIntl();
    const liked = currentListingInList || false;
    return (_jsxs(Wrapper, { children: [onAddOrRemoveListing && (_jsx(LikeButtonWrapper, { children: _jsx(LikeButton, { liked: liked, onClick: onAddOrRemoveListing, colorVariant: LikeButtonColorVariant.Transparent, loading: addOrRemoveLoading }) })), _jsxs(Content, { children: [_jsx(Text, Object.assign({ variant: TextVariant.B50, colorVariant: ColorVariant.Stone, truncateWithEllipsis: true }, { children: intl.formatMessage({ id: 'ListingFavoritesListCard.numberOfListings' }, { count: listings }) })), _jsx(Text, Object.assign({ variant: TextVariant.H51, truncateWithEllipsis: true }, { children: name }))] }), _jsx(TertiaryButtonWrapper, { children: _jsx(TertiaryButton, { label: liked
                        ? intl.formatMessage({
                            id: 'ListingFavoritesListCard.RemoveFromFavorites',
                        })
                        : intl.formatMessage({
                            id: 'ListingFavoritesListCard.AddToFavorites',
                        }), onClick: onAddOrRemoveListing }) })] }));
};
export default FavoritesListCard;
