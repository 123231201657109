import { routingSlice } from '../slices/routingSlice.ts';
import { ANALYTICS_EVENT } from '../ducks/Analytics.duck.ts';

// Create a Redux middleware from the given analytics handlers. Each
// handler should have the following methods:
//
const createMiddleware = handlers => () => next => action => {
  const { type, payload } = action;

  if (
    type ===
    `${routingSlice.name}/${routingSlice.caseReducers.locationChanged.name}`
  ) {
    const { canonicalUrl } = payload;

    handlers.forEach(handler => {
      handler.trackPageView(canonicalUrl);
    });
  }

  if (type === ANALYTICS_EVENT) {
    const { params } = payload;

    handlers.forEach(handler => {
      handler.trackTransaction(params);
    });
  }

  next(action);
};

export default createMiddleware;
