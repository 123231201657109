import styled from 'styled-components/macro';
const BackDrop = styled.div `
  position: fixed;
  ${props => props.coversTopBar
    ? props.theme.priority.ultra
    : props.theme.priority.medium}
  top: ${props => props.safeSpaceTop || 0}px;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: flex-end;

  transition: background-color 300ms ease-in-out;
  background: ${props => props.visible === undefined || props.visible
    ? props.theme.color.tarTransparent
    : props.theme.color.transparent};

  @media ${props => props.theme.device.tablet} {
    display: flex;
    ${props => props.fullTransparency && `background-color: initial;`}
    flex-direction: column;
    align-items: center;
    padding-bottom: 5vh;
    justify-content: center;
  }
`;
export default BackDrop;
