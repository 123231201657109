import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components/macro';
import { useDispatch, useSelector } from 'react-redux';
import { LayoutRow, LayoutRowBottomVariant, LayoutRowHorizontalVariant, } from '../../../styled-components/Layout/Layout';
import Text, { TextVariant } from '../../../styled-components/Text/Text';
import { ColorVariant } from '../../../theme';
import IconVariant from '@nomady/shared/interfaces/IconVariant';
import { useHistory } from 'react-router';
import IconButton from '../../../styled-components/IconButton/IconButton';
import Icon, { IconSizeVariant } from '../../../styled-components/Icon/Icon';
import { requestCountryConfig } from '../../CmsContentPage/CmsContentPageSlice';
import NamedLink from '../../../components/NamedLink/NamedLink';
import Page from '@nomady/shared/routeConfiguration/Page';
const BreadcrumbsWrapper = styled.div `
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 8px;
  background-color: ${props => props.theme.color.basicPearlWhiteTypeWhite};
  padding: 8px;
  margin-top: 8px;
  margin-bottom: 8px;
  border-radius: 10px;
  width: fit-content;
  max-width: 100%;
  overflow: auto;

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;
const BackButtonWrapper = styled.div `
  display: none;
  @media ${props => props.theme.device.tablet} {
    display: block;
    position: fixed;
    transform: ${props => props.scrolled ? 'translateX(0)' : 'translateX(-130%)'};
  }
`;
const Tag = styled.div `
  background-color: ${props => props.theme.color.basicPearlWhiteTypeWhite};
  box-shadow: inset 0 0 0 1px ${props => props.theme.color.primaryFirGreen};
  border-radius: 4px;
  padding: 0 8px;
  white-space: nowrap;
  width: fit-content;
  display: flex;
`;
const IconWrapper = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
`;
const regionSlugsCountryLevel = [
    'austria',
    'deutschland',
    'switzerland',
    'france',
    'italy',
];
const Breadcrumbs = ({ isOwnListing, isPendingApproval, }) => {
    const { listing } = useSelector((state) => state.ListingPage);
    const listingRegions = listing === null || listing === void 0 ? void 0 : listing.attributes.metadata.regions;
    const history = useHistory();
    const { locale } = useSelector((state) => state.UI);
    const { countryConfig } = useSelector((state) => state.CmsContentPage);
    const dispatch = useDispatch();
    useEffect(() => {
        if (locale) {
            dispatch(requestCountryConfig(locale));
        }
    }, [locale, dispatch]);
    const allRegions = useMemo(() => Object.values(countryConfig).flat(), [countryConfig]);
    const breadcrumbRegions = useMemo(() => listingRegions
        ? allRegions
            .filter(region => listingRegions.includes(region.slug))
            .sort((a, b) => Number(regionSlugsCountryLevel.includes(b.slug)) -
            Number(regionSlugsCountryLevel.includes(a.slug)))
        : [], [allRegions, listingRegions]);
    if (!listing) {
        return null;
    }
    return (_jsxs(LayoutRow, Object.assign({ horizontalVariant: LayoutRowHorizontalVariant.MobileUncontained, bottomVariant: LayoutRowBottomVariant.NoMargin }, { children: [!(isOwnListing || isPendingApproval) && (_jsx(BackButtonWrapper, Object.assign({ scrolled: false }, { children: _jsx(IconButton, { iconVariant: IconVariant.Arrow_M_Left, onClick: () => history.goBack() }) }))), _jsx(BreadcrumbsWrapper, { children: breadcrumbRegions.map((breadcrumb, index) => (_jsxs(_Fragment, { children: [_jsx(NamedLink, Object.assign({ name: Page.Region, params: { name: breadcrumb.routeName } }, { children: _jsx(Tag, { children: _jsx(Text, Object.assign({ colorVariant: ColorVariant.BasicCaveBlack, variant: TextVariant.L4R }, { children: breadcrumb.name }), index) }) }), breadcrumb.id), index < breadcrumbRegions.length - 1 &&
                            regionSlugsCountryLevel.includes(breadcrumb.slug) && (_jsx(IconWrapper, { children: _jsx(Icon, { variant: IconVariant.Arrow_S_right, sizeVariant: IconSizeVariant.Tiny }) }))] }))) })] })));
};
export default Breadcrumbs;
