var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import union from 'lodash/union';
import { deleteListingFavoritesList, getListingFavoritesLists, postAddListingToListingFavoritesList, postListingFavoritesList, postRemoveListingfromListingFavoritesList, putListingFavoritesList, queryListings, } from '../util/api';
import { addMarketplaceEntities } from '../ducks/marketplaceData.duck';
import { localeSelector } from './UISlice';
const initialState = {
    listingFavoritesLists: [],
    fetchListingFavoritesListsInProgress: false,
    fetchListingFavoritesListsError: null,
    fetchListingFavoritesListInProgress: false,
    fetchListingFavoritesListError: null,
    createListingFavoritesListInProgress: false,
    createListingFavoritesListError: null,
    deleteListingFavoritesListInProgress: false,
    deleteListingFavoritesListError: null,
    updateListingFavoritesListInProgress: false,
    updateListingFavoritesListError: null,
    addListingToListInProgress: [],
    addListingToListError: null,
    removeListingFromListInProgress: [],
    removeListingFromListError: null,
};
export const listingFavoritesListsSlice = createSlice({
    name: 'app/ListingFavoritesList',
    initialState,
    reducers: {
        setInitialState: (state, action) => {
            state = action.payload;
        },
        fetchListingFavoritesListsRequest: state => {
            state.fetchListingFavoritesListsInProgress = true;
            state.fetchListingFavoritesListsError = null;
        },
        fetchListingFavoritesListsSuccess: (state, action) => {
            state.listingFavoritesLists = action.payload;
            state.fetchListingFavoritesListsInProgress = false;
            state.fetchListingFavoritesListsError = null;
        },
        fetchListingFavoritesListsError: (state, action) => {
            state.fetchListingFavoritesListsInProgress = false;
            state.fetchListingFavoritesListsError = action.payload;
        },
        fetchListingFavoritesListRequest: state => {
            state.fetchListingFavoritesListInProgress = true;
            state.fetchListingFavoritesListError = null;
        },
        fetchListingFavoritesListError: (state, action) => {
            state.fetchListingFavoritesListInProgress = false;
            state.fetchListingFavoritesListError = action.payload;
        },
        createListingFavoritesListRequest: state => {
            state.createListingFavoritesListInProgress = true;
            state.createListingFavoritesListError = null;
        },
        createListingFavoritesListSuccess: (state, action) => {
            state.listingFavoritesLists.unshift(action.payload);
            state.createListingFavoritesListInProgress = false;
            state.createListingFavoritesListError = null;
        },
        createListingFavoritesListError: (state, action) => {
            state.createListingFavoritesListInProgress = false;
            state.createListingFavoritesListError = action.payload;
        },
        deleteListingFavoritesListRequest: state => {
            state.deleteListingFavoritesListInProgress = true;
            state.deleteListingFavoritesListError = null;
        },
        deleteListingFavoritesListSuccess: (state, action) => {
            state.listingFavoritesLists = state.listingFavoritesLists.filter(listingFavoritesList => listingFavoritesList.id !== action.payload);
            state.deleteListingFavoritesListInProgress = false;
            state.deleteListingFavoritesListError = null;
        },
        deleteListingFavoritesListError: (state, action) => {
            state.deleteListingFavoritesListInProgress = false;
            state.deleteListingFavoritesListError = action.payload;
        },
        updateListingFavoritesListRequest: state => {
            state.updateListingFavoritesListInProgress = true;
            state.updateListingFavoritesListError = null;
        },
        updateListingFavoritesListSuccess: (state, action) => {
            state.listingFavoritesLists = state.listingFavoritesLists.map(element => {
                if (element.id === action.payload.id) {
                    return action.payload;
                }
                return element;
            });
            state.updateListingFavoritesListInProgress = false;
            state.updateListingFavoritesListError = null;
        },
        updateListingFavoritesListError: (state, action) => {
            state.updateListingFavoritesListInProgress = false;
            state.updateListingFavoritesListError = action.payload;
        },
        addListingToListRequest: (state, action) => {
            state.addListingToListInProgress.push(action.payload);
            state.addListingToListError = null;
        },
        addListingToListSuccess: (state, action) => {
            state.listingFavoritesLists = state.listingFavoritesLists.map(element => {
                if (element.id === action.payload.id) {
                    return action.payload;
                }
                return element;
            });
            state.addListingToListInProgress =
                state.addListingToListInProgress.filter(element => element !== action.payload.id);
            state.addListingToListError = null;
        },
        addListingToListError: (state, action) => {
            state.addListingToListInProgress = [];
            state.addListingToListError = action.payload;
        },
        removeListingFromListRequest: (state, action) => {
            state.removeListingFromListInProgress.push(action.payload);
            state.removeListingFromListError = null;
        },
        removeListingFromListSuccess: (state, action) => {
            state.listingFavoritesLists = state.listingFavoritesLists.map(element => {
                if (element.id === action.payload.id) {
                    return action.payload;
                }
                return element;
            });
            state.removeListingFromListInProgress =
                state.removeListingFromListInProgress.filter(element => element !== action.payload.id);
            state.removeListingFromListError = null;
        },
        removeListingFromListError: (state, action) => {
            state.removeListingFromListInProgress = [];
            state.removeListingFromListError = action.payload;
        },
    },
});
export const { setInitialState, fetchListingFavoritesListsRequest, fetchListingFavoritesListsSuccess, fetchListingFavoritesListsError, fetchListingFavoritesListRequest, fetchListingFavoritesListError, createListingFavoritesListRequest, createListingFavoritesListSuccess, createListingFavoritesListError, deleteListingFavoritesListRequest, deleteListingFavoritesListSuccess, deleteListingFavoritesListError, updateListingFavoritesListRequest, updateListingFavoritesListSuccess, updateListingFavoritesListError, addListingToListRequest, addListingToListSuccess, addListingToListError, removeListingFromListRequest, removeListingFromListSuccess, removeListingFromListError, } = listingFavoritesListsSlice.actions;
export const fetchListingFavoritesLists = () => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    dispatch(fetchListingFavoritesListsRequest());
    try {
        const listingFavoritesListsResponse = yield getListingFavoritesLists();
        const listingFavoritesLists = yield listingFavoritesListsResponse.json();
        dispatch(fetchListingFavoritesListsSuccess(listingFavoritesLists));
    }
    catch (error) {
        dispatch(fetchListingFavoritesListsError(error));
    }
});
export const fetchListingFavoritesList = (id) => (dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
    const state = getState();
    const locale = localeSelector(state);
    const { isAuthenticated } = state.Auth;
    if (!isAuthenticated) {
        return;
    }
    try {
        const listingFavoritesList = state.listingFavoritesLists.listingFavoritesLists.find(list => list.id === id);
        if (listingFavoritesList) {
            const response = yield queryListings({
                ids: listingFavoritesList.listings,
                include: ['author', 'author.profileImage', 'images'],
                'fields.image': [
                    // Listing page
                    'variants.landscape-crop',
                    'variants.landscape-crop2x',
                    'variants.landscape-crop4x',
                    'variants.landscape-crop6x',
                    // Social media
                    'variants.facebook',
                    'variants.twitter',
                    // Image carousel
                    'variants.scaled-small',
                    'variants.scaled-medium',
                    'variants.scaled-large',
                    'variants.scaled-xlarge',
                    // Avatars
                    'variants.square-small',
                    'variants.square-small2x',
                ],
                locale,
            });
            dispatch(addMarketplaceEntities(response));
        }
    }
    catch (error) {
        dispatch(fetchListingFavoritesListError(error));
    }
});
export const createListingFavoritesList = (name, listingId) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    dispatch(createListingFavoritesListRequest());
    try {
        const newListingFavoriteListResponse = yield postListingFavoritesList(name, listingId);
        if (newListingFavoriteListResponse.status === 200) {
            const newListingFavoriteList = yield newListingFavoriteListResponse.json();
            dispatch(createListingFavoritesListSuccess(newListingFavoriteList));
        }
        else {
            throw new Error();
        }
    }
    catch (error) {
        dispatch(createListingFavoritesListError(error));
    }
});
export const removeListingFavoritesList = (id) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    dispatch(deleteListingFavoritesListRequest());
    try {
        const response = yield deleteListingFavoritesList(id);
        if (response.status === 200) {
            dispatch(deleteListingFavoritesListSuccess(id));
        }
        else {
            throw new Error();
        }
    }
    catch (error) {
        dispatch(deleteListingFavoritesListError(error));
    }
});
export const updateListingFavoritesList = (id, name) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    dispatch(updateListingFavoritesListRequest());
    try {
        const response = yield putListingFavoritesList(id, name);
        if (response.status === 200) {
            const { updatedListingFavoritesList } = yield response.json();
            dispatch(updateListingFavoritesListSuccess(updatedListingFavoritesList));
        }
        else {
            throw new Error();
        }
    }
    catch (error) {
        dispatch(updateListingFavoritesListError(error));
    }
});
export const addListingToFavoritesList = (listId, listingId) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    dispatch(addListingToListRequest(listId));
    try {
        const response = yield postAddListingToListingFavoritesList(listId, listingId);
        if (response.status === 200) {
            const { listingFavoritesList } = yield response.json();
            dispatch(addListingToListSuccess(listingFavoritesList));
        }
        else {
            throw new Error();
        }
    }
    catch (error) {
        dispatch(addListingToListError(error));
    }
});
export const removeListingFromFavoritesList = (listId, listingId) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    dispatch(removeListingFromListRequest(listId));
    try {
        const response = yield postRemoveListingfromListingFavoritesList(listId, listingId);
        if (response.status === 200) {
            const { listingFavoritesList } = yield response.json();
            dispatch(removeListingFromListSuccess(listingFavoritesList));
        }
        else {
            throw new Error();
        }
    }
    catch (error) {
        dispatch(removeListingFromListError(error));
    }
});
export default listingFavoritesListsSlice.reducer;
export const listingFavoritesListsSelector = (state) => { var _a; return ((_a = state.listingFavoritesLists) === null || _a === void 0 ? void 0 : _a.listingFavoritesLists) || []; };
export const listingFavoritesListsError = (state) => { var _a; return ((_a = state.listingFavoritesLists) === null || _a === void 0 ? void 0 : _a.fetchListingFavoritesListsError) || null; };
export const listingFavoritesListError = (state) => { var _a; return ((_a = state.listingFavoritesLists) === null || _a === void 0 ? void 0 : _a.fetchListingFavoritesListError) || null; };
export const listingFavoritesListSelector = (id) => (state) => {
    var _a;
    return (_a = state.listingFavoritesLists) === null || _a === void 0 ? void 0 : _a.listingFavoritesLists.find(list => list.id === id);
};
export const listingIdsInListingFavoritesListsSelector = (listingId) => (state) => union(...(state.listingFavoritesLists.listingFavoritesLists || []).map(list => list.listings)).includes(listingId);
export const addOrRemoveListingToListingFavoritesListsInProgressSelector = (state) => [
    ...state.listingFavoritesLists.addListingToListInProgress,
    ...state.listingFavoritesLists.removeListingFromListInProgress,
];
export const createListInProgressSelector = (state) => state.listingFavoritesLists.createListingFavoritesListInProgress;
export const hasListingFavoritesListsSelector = (state) => 0 < state.listingFavoritesLists.listingFavoritesLists.length;
