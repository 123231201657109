const getLocationQueryString = (currentSearchQuery) => {
    var _a, _b, _c, _d, _e, _f, _g;
    const bounds = ((_b = (_a = currentSearchQuery.location) === null || _a === void 0 ? void 0 : _a.location) === null || _b === void 0 ? void 0 : _b.bounds)
        ? `${currentSearchQuery.location.location.bounds.ne.lat},${currentSearchQuery.location.location.bounds.ne.lng},${currentSearchQuery.location.location.bounds.sw.lat},${currentSearchQuery.location.location.bounds.sw.lng}`
        : undefined;
    const origin = ((_c = currentSearchQuery.location) === null || _c === void 0 ? void 0 : _c.origin)
        ? `${currentSearchQuery.location.origin.lat},${currentSearchQuery.location.origin.lng}`
        : undefined;
    return {
        bounds,
        address: (_e = (_d = currentSearchQuery.location) === null || _d === void 0 ? void 0 : _d.location) === null || _e === void 0 ? void 0 : _e.name,
        region: (_f = currentSearchQuery.location) === null || _f === void 0 ? void 0 : _f.region,
        location_flexible: (_g = currentSearchQuery.location) === null || _g === void 0 ? void 0 : _g.isFlexible,
        origin,
    };
};
export default getLocationQueryString;
