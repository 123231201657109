var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styled from 'styled-components/macro';
import { useIntl } from 'react-intl';
import { ColorVariant } from '../../theme';
import Icon from '../Icon/Icon';
import InputErrorMessage from '../InputErrorMessage/InputErrorMessage';
import Spinner from '../Spinner/Spinner';
import { TextVariant } from '../Text/Text';
import { Description, ErrorMessageWrapper, FakeInput, InputWrapper, Label, SpinnerWrapper, Wrapper, } from '../Input/Input';
const SelectInputWrapper = styled(InputWrapper) `
  outline: none;
`;
const Input = (_a) => {
    var _b, _c;
    var { id, label, variant, inputRef, error, icon, loading, description, options } = _a, rest = __rest(_a, ["id", "label", "variant", "inputRef", "error", "icon", "loading", "description", "options"]);
    const hasError = !!error;
    const intl = useIntl();
    const value = ((_b = rest.value) === null || _b === void 0 ? void 0 : _b.formattedValue)
        ? {
            value: (_c = rest.value) === null || _c === void 0 ? void 0 : _c.formattedValue,
        }
        : {};
    const errorMessageId = hasError ? `${id}-errormessage` : '';
    const errorMessageAriaSelectInputProps = hasError
        ? { 'aria-invalid': true, 'aria-errormessage': errorMessageId }
        : {};
    return (_jsxs(Wrapper, { children: [label && (_jsx(Label, Object.assign({ inputVariant: variant, variant: TextVariant.H13, as: "label", htmlFor: id, hasError: hasError }, { children: label }))), _jsxs(FakeInput, Object.assign({ variant: variant, hasError: hasError, hasIcon: !!icon, disabled: rest.disabled }, { children: [icon && (_jsxs("label", Object.assign({ htmlFor: id, "aria-hidden": true }, { children: [!loading && _jsx(Icon, { variant: icon }), loading && (_jsx(SpinnerWrapper, { children: _jsx(Spinner, { colorVariant: ColorVariant.Cave }) }))] }))), _jsxs(SelectInputWrapper, Object.assign({ as: "select", defaultValue: value.value, id: id, variant: variant, ref: inputRef, hasError: hasError, hasIcon: !!icon }, rest, errorMessageAriaSelectInputProps, value, { disabled: rest.disabled }, { children: [_jsx("option", Object.assign({ disabled: true, selected: true, value: '' }, { children: intl.formatMessage({ id: 'EditListingWizard.selectPlaceholder' }) })), options.map(({ value, label }) => (_jsx("option", Object.assign({ value: `${value}` }, { children: label }), `${value}`)))] }))] })), hasError && (_jsx(ErrorMessageWrapper, Object.assign({ role: "alert" }, { children: _jsx(InputErrorMessage, { id: errorMessageId, error: error }) }))), description && _jsx(Description, { text: description })] }));
};
export default Input;
