/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-underscore-dangle */
import moment from 'moment';
import Decimal from '@nomady/shared/utils/decimal';
import { types as sdkTypes } from '@nomady/shared/utils/sdkLoader';
import { isBrowser } from '@nomady/shared/utils/env';
const { UUID, Money } = sdkTypes;
// TODO: combine duplicated code with CheckoutPage/utils/sessionStorage
const validateProperties = (obj, validators) => {
    return Object.entries(validators).reduce((acc, [prop, fn]) => {
        if (Object.prototype.hasOwnProperty.call(obj, prop) && fn(obj[prop])) {
            return acc;
        }
        return false;
    }, true);
};
const isValidBookingDates = (bookingDates) => {
    if (!bookingDates) {
        return false;
    }
    const validators = {
        bookingStart: (d) => d instanceof Date,
        bookingEnd: (d) => d instanceof Date,
    };
    return validateProperties(bookingDates, validators);
};
const isValidListing = (listing) => {
    if (!listing) {
        return false;
    }
    const props = {
        id: (id) => id instanceof UUID,
        attributes: (v) => {
            return typeof v === 'object' && v.price instanceof Money;
        },
    };
    return validateProperties(listing, props);
};
const replacer = function replacer(key, value) {
    if (this[key] instanceof Date) {
        return { date: value, _serializedType: 'SerializableDate' };
    }
    if (this[key] instanceof Decimal) {
        return { decimal: value, _serializedType: 'SerializableDecimal' };
    }
    return sdkTypes.replacer(key, value);
};
export const storeData = (storageKey, values) => {
    const { listing, bookingDates, bookingData } = values;
    if (isBrowser() &&
        window.localStorage &&
        listing &&
        bookingDates &&
        bookingData) {
        const data = {
            bookingData,
            bookingDates,
            listing,
            storedAt: new Date(),
        };
        const storableData = JSON.stringify(data, replacer);
        window.localStorage.setItem(storageKey, storableData);
    }
};
const reviver = (key, value) => {
    if (value &&
        typeof value === 'object' &&
        value._serializedType === 'SerializableDate') {
        return new Date(value.date);
    }
    if (value &&
        typeof value === 'object' &&
        value._serializedType === 'SerializableDecimal') {
        return new Decimal(value.decimal);
    }
    return sdkTypes.reviver(key, value);
};
export const retrieveData = (storageKey) => {
    if (window === null || window === void 0 ? void 0 : window.sessionStorage) {
        const pageData = window.localStorage.getItem(storageKey);
        const { bookingData, bookingDates, listing, storedAt } = pageData
            ? JSON.parse(pageData, reviver)
            : {};
        const isOutdated = storedAt
            ? moment(storedAt).isBefore(moment().subtract(1, 'days'))
            : false;
        const isStoredDataValid = !isOutdated &&
            isValidBookingDates(bookingDates) &&
            isValidListing(listing);
        if (isStoredDataValid) {
            return { bookingData, bookingDates, listing };
        }
    }
    return {};
};
export const clearData = (storageKey) => {
    if (window === null || window === void 0 ? void 0 : window.localStorage) {
        window.localStorage.removeItem(storageKey);
    }
};
