import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { CancellationType, } from '@nomady/shared/types';
import RefundCalculatorFactory from '@nomady/shared/controllers/RefundCalculatorFactory';
import { FormattedMessage } from '../../../util/reactIntl';
import Button, { ButtonColorVariant, ButtonSizeVariant, } from '../../../styled-components/Button/Button';
import RefundModal from '../../../modals/Refund/Refund';
import Tooltip from '../../../styled-components/Tooltip/Tooltip';
import ActionButtons from './ActionButtons';
import ActionErrors from './ActionErrors';
import ActionButtonWrapper from './ActionButtonWrapper';
import CancellationTypeComponent from './CancellationType';
const CustomerActions = props => {
    const { showCancelButton, buttonLabel, inProgress, cancelSaleError, onCancelSale, isCustomer, cancellationType, currentListing, refundModal, currentTransaction, } = props;
    const intl = useIntl();
    const [refundModalOpen, setRefundModalOpen] = useState(false);
    const [refundConditions, setRefundConditions] = useState();
    const cancelErrorMessage = cancelSaleError ? (_jsx(FormattedMessage, { id: "TransactionPanel.cancelSaleFailed" })) : null;
    const localizationConfig = {
        [CancellationType.superFlex]: {
            name: 'TransactionPanel.cancellationTypeSuperFlex',
            tooltip: 'ListingPage.cancellationTooltip_super_flex',
        },
        [CancellationType.flex]: {
            name: 'TransactionPanel.cancellationTypeFlex',
            tooltip: 'ListingPage.cancellationTooltip_flex',
        },
        [CancellationType.easy]: {
            name: 'TransactionPanel.cancellationTypeEasy',
            tooltip: 'ListingPage.cancellationTooltip_easy',
        },
        [CancellationType.medium]: {
            name: 'TransactionPanel.cancellationTypeMedium',
            tooltip: 'ListingPage.cancellationTooltip_medium',
        },
        [CancellationType.strict]: {
            name: 'TransactionPanel.cancellationTypeStrict',
            tooltip: 'ListingPage.cancellationTooltip_strict',
        },
        [CancellationType.none]: {
            name: 'TransactionPanel.cancellationTypeNone',
            tooltip: 'ListingPage.cancellationTooltip_none',
        },
    };
    const openRefundModal = () => {
        setRefundModalOpen(true);
        const refundCalculator = new RefundCalculatorFactory().getRefundCalculatorByTransactionAndListing(currentTransaction, currentListing, isCustomer, currentTransaction.booking, undefined);
        const refund = refundCalculator.getRefund();
        setRefundConditions(refund);
    };
    return (_jsxs(ActionButtons, { children: [_jsx(ActionErrors, { children: cancelErrorMessage }), cancellationType && (_jsxs(CancellationTypeComponent, { children: [_jsx("strong", { children: _jsx(FormattedMessage, { id: "TransactionPanel.cancellationTypeLabel" }) }), _jsx(FormattedMessage, { id: localizationConfig[cancellationType].name }), _jsx(Tooltip, { text: intl.formatMessage({
                            id: localizationConfig[cancellationType].tooltip,
                        }) })] })), showCancelButton ? (_jsxs(ActionButtonWrapper, { children: [_jsx(Button, { inProgress: inProgress, disabled: inProgress, onClick: () => openRefundModal(), label: intl.formatMessage({ id: buttonLabel }), colorVariant: ButtonColorVariant.Fir, sizeVariant: ButtonSizeVariant.Small }), refundModal && (_jsx(RefundModal, { isOpen: refundModalOpen, onClose: () => setRefundModalOpen(false), onRefund: onCancelSale, texts: refundModal, refundConditions: refundConditions, inProgress: inProgress, intl: intl }))] })) : null] }));
};
export default CustomerActions;
