import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';
import { useLocation, useParams } from 'react-router-dom';
import routeConfiguration from '@nomady/shared/routeConfiguration/routeConfiguration';
import { canonicalRoutePath } from '@nomady/shared/utils/routes';
import { getMarketplaceDomain, getDomainWithProtocol, } from '@nomady/shared/utils/localized-domains';
import { PageLayout } from '../PageLayout/PageLayout';
import config from '../../config';
import metaTagProps from '../../util/seo';
import facebookImage from '../../assets/nomady-social-media-mountain-1200x630.jpg';
import twitterImage from '../../assets/nomady-social-media-mountain-600x314.jpg';
import GeolocationModal from '../SearchBarSheet/Location/GeolocationModal';
import { requestBrowserGeolocation, setShowRequestBrowserGeolocationModal, } from '../../slices/geolocationSlice';
import useScrollRestoration from '../../hooks/useScrollRestoration';
import { setActiveSearchBarSheet } from '../../slices/listingSearchSlice';
import SearchBarSheetVariant from '../SearchBarSheet/SearchBarSheetVariant';
const PageContainer = ({ children, author, description, facebookImages, twitterImages, published, referrer, schema, title, updated, disableScrollRestoration = false, noIndex, metaRobots, customPathParams, 
// eslint-disable-next-line @typescript-eslint/no-empty-function
onScrollContent = () => { }, }) => {
    var _a;
    const intl = useIntl();
    const location = useLocation();
    const params = useParams();
    const dispatch = useDispatch();
    const { showRequestBrowserGeolocationModal, browserGeolocationError } = useSelector((state) => state.geolocation);
    const { currentSearchQuery } = useSelector((state) => state.listingSearch);
    useScrollRestoration(disableScrollRestoration);
    const onSetShowRequestBrowserGeolocationModal = (isOpen) => {
        dispatch(setShowRequestBrowserGeolocationModal(isOpen));
    };
    const { isOpen: searchbarSheetIsOpen } = useSelector((state) => state.listingSearch);
    const onSetUserLocation = () => {
        dispatch(requestBrowserGeolocation());
        if (searchbarSheetIsOpen) {
            dispatch(setActiveSearchBarSheet(SearchBarSheetVariant.Dates));
        }
    };
    useEffect(() => {
        const onScrollEventListener = () => {
            onScrollContent((window === null || window === void 0 ? void 0 : window.scrollY) || 0);
        };
        const preventDefault = (e) => e.preventDefault();
        document.addEventListener('dragover', preventDefault);
        document.addEventListener('drop', preventDefault);
        window === null || window === void 0 ? void 0 : window.addEventListener('scroll', onScrollEventListener);
        return () => {
            window === null || window === void 0 ? void 0 : window.removeEventListener('scroll', onScrollEventListener);
            document.removeEventListener('dragover', preventDefault);
            document.removeEventListener('drop', preventDefault);
        };
    });
    useEffect(() => {
        var _a;
        if (showRequestBrowserGeolocationModal &&
            !browserGeolocationError &&
            !!((_a = currentSearchQuery.location) === null || _a === void 0 ? void 0 : _a.origin)) {
            onSetShowRequestBrowserGeolocationModal(false);
        }
    }, [
        showRequestBrowserGeolocationModal,
        browserGeolocationError,
        (_a = currentSearchQuery.location) === null || _a === void 0 ? void 0 : _a.origin,
    ]);
    const { siteTitle } = config;
    const referrerMeta = referrer ? (_jsx("meta", { name: "referrer", content: referrer })) : null;
    const canonicalUrl = canonicalRoutePath(routeConfiguration, location, Object.assign(Object.assign({}, params), customPathParams), {}, true);
    const contentType = 'website';
    const facebookImgs = facebookImages || [
        {
            name: 'facebook',
            url: `${canonicalUrl}${facebookImage}`,
            width: 1200,
            height: 630,
        },
    ];
    const twitterImgs = twitterImages || [
        {
            name: 'twitter',
            url: `${canonicalUrl}${twitterImage}`,
            width: 600,
            height: 314,
        },
    ];
    const schemaTitle = intl.formatMessage({ id: 'Page.schemaTitle' }, { siteTitle });
    const schemaDescription = intl.formatMessage({
        id: 'Page.schemaDescription',
    });
    const metaDescription = description || schemaDescription;
    const domain = getMarketplaceDomain();
    const canonicalRootURL = getDomainWithProtocol(domain);
    const metaToHead = metaTagProps({
        author,
        contentType,
        description: metaDescription,
        facebookImages: facebookImgs,
        twitterImages: twitterImgs,
        published,
        title: title || schemaTitle,
        updated,
        url: canonicalUrl,
        locale: intl.locale,
        metaRobots,
        canonicalRootURL,
    });
    const metaTags = metaToHead.map((metaProps, i) => (_jsx("meta", Object.assign({}, metaProps), i)));
    const schemaFromProps = Array.isArray(schema) ? schema : [schema];
    const schemaArrayJSONString = JSON.stringify([...schemaFromProps]);
    const noIndexTag = noIndex ? _jsx("meta", { name: "robots", content: "noindex" }) : null;
    const schemaArrayJsonStringToUnicode = schemaArrayJSONString.replace(/</g, '\\u003c');
    return (_jsxs(_Fragment, { children: [_jsxs(Helmet, Object.assign({ htmlAttributes: {
                    lang: intl.locale,
                    translate: 'no',
                } }, { children: [_jsx("title", { children: title }), referrerMeta, _jsx("link", { rel: "canonical", href: canonicalUrl }), _jsx("meta", { httpEquiv: "Content-Type", content: "text/html; charset=UTF-8" }), _jsx("meta", { httpEquiv: "Content-Language", content: intl.locale }), metaTags, noIndexTag, schemaArrayJsonStringToUnicode !== '[null]' && (_jsx("script", Object.assign({ type: "application/ld+json" }, { children: schemaArrayJsonStringToUnicode }))), _jsx("meta", { name: "viewport", content: "width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no" })] })), _jsxs(PageLayout, { children: [_jsx(GeolocationModal, { isOpen: showRequestBrowserGeolocationModal, onCancel: () => onSetShowRequestBrowserGeolocationModal(false), onSuccess: onSetUserLocation }), children] })] }));
};
export default PageContainer;
