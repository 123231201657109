import { jsx as _jsx } from "react/jsx-runtime";
import { TransactionStatus } from '@nomady/shared/utils/transaction';
import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components/macro';
import { DefaultTheme } from '../../theme';
export const transactionStatusToTranslationKey = {
    [TransactionStatus.REQUEST]: 'TransactionTag.REQUEST',
    [TransactionStatus.ACCEPTED]: 'TransactionTag.ACCEPTED',
    [TransactionStatus.WITHDRAWN]: 'TransactionTag.WITHDRAWN',
    [TransactionStatus.EXPIRED]: 'TransactionTag.EXPIRED',
    [TransactionStatus.DECLINED]: 'TransactionTag.DECLINED',
    [TransactionStatus.CANCELLED]: 'TransactionTag.CANCELLED',
    [TransactionStatus.DELIVERED]: 'TransactionTag.DELIVERED',
    [TransactionStatus.REVIEWED]: 'TransactionTag.REVIEWED',
    [TransactionStatus.UNKNOWN]: 'TransactionTag.UNKNOWN',
};
const StyleConfig = {
    REQUEST: {
        borderColor: DefaultTheme.color.clementine,
    },
    ACCEPTED: {
        borderColor: DefaultTheme.color.lagoon,
    },
    WITHDRAWN: {
        borderColor: DefaultTheme.color.rose,
    },
    EXPIRED: {
        borderColor: DefaultTheme.color.rose,
    },
    DECLINED: {
        borderColor: DefaultTheme.color.rose,
    },
    CANCELLED: {
        borderColor: DefaultTheme.color.wine,
    },
    DELIVERED: {
        borderColor: DefaultTheme.color.amber,
    },
    REVIEWED: {
        borderColor: DefaultTheme.color.gras,
        color: DefaultTheme.color.gras,
    },
    UNKNOWN: {
        borderColor: DefaultTheme.color.blood,
        color: DefaultTheme.color.blood,
    },
};
const Tag = styled.div `
  ${props => props.theme.typeset.B50}

  color:   ${props => StyleConfig[props.status].color || props.theme.color.cave};
  border: 1px solid ${props => StyleConfig[props.status].borderColor};
  border-radius: 4px;

  width: min-content;
  padding: 0 4px;
`;
const TransactionTag = ({ status }) => {
    const intl = useIntl();
    return (_jsx(Tag, Object.assign({ status: status }, { children: intl.formatMessage({ id: transactionStatusToTranslationKey[status] }) })));
};
export default TransactionTag;
