const getGuestsQueryString = (currentSearchQuery) => {
    var _a, _b, _c, _d, _e, _f;
    return ({
        seats: (_a = currentSearchQuery.guests) === null || _a === void 0 ? void 0 : _a.seats,
        adults: (_b = currentSearchQuery.guests) === null || _b === void 0 ? void 0 : _b.adults,
        teens: (_c = currentSearchQuery.guests) === null || _c === void 0 ? void 0 : _c.teens,
        children: (_d = currentSearchQuery.guests) === null || _d === void 0 ? void 0 : _d.children,
        infants: (_e = currentSearchQuery.guests) === null || _e === void 0 ? void 0 : _e.infants,
        dogs: (_f = currentSearchQuery.guests) === null || _f === void 0 ? void 0 : _f.dogs,
    });
};
export default getGuestsQueryString;
