import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import debounce from 'lodash/debounce';
import React, { useRef, useState } from 'react';
import { injectIntl } from 'react-intl';
import styled, { css } from 'styled-components/macro';
import { ColorVariant } from '../../theme';
import Button, { ButtonColorVariant, ButtonOutlineVariant, ButtonSizeVariant, } from '../Button/Button';
import { IconVariant } from '../Icon/Icon';
import IconButtonOld, { OldIconButtonSizeVariant, } from '../IconButtonOld/IconButtonOld';
import { LayoutRowHorizontalVariant } from '../Layout/Layout';
import Text, { TextVariant } from '../Text/Text';
const Wrapper = styled.div `
  ${props => props.enableBottomSpacing && 'margin-bottom: 50px;'}
`;
const Title = styled(Text) `
  margin-bottom: 20px;
  justify-self: center;
  margin-right: auto;

  color: ${props => props.theme.color[props.colorVariant]};

  ${props => props.variant !== TextVariant.H5 &&
    css `
      @media ${props => props.theme.device.tabletL} {
        margin-bottom: 50px;
      }
    `}
  ${props => props.variant === TextVariant.H5 &&
    css `
      text-align: center;
      margin-left: auto;
      margin-bottom: 100px;
      color: ${props => props.theme.color.stone};
    `};

  ${props => props.hideTitleOnMobile &&
    css `
      display: none;
      @media ${props => props.theme.device.tabletL} {
        display: grid;
      }
    `}
`;
const TitleLink = styled.a `
  color: inherit;
  cursor: pointer;
`;
const Header = styled.div `
  display: flex;
  width: 290px;
  margin: 0 auto;
  padding-left: 0px;

  @media ${props => props.theme.device.tablet} {
    width: 510px;
  }

  @media ${props => props.theme.device.tabletL} {
    width: 690px;
  }

  @media ${props => props.theme.device.desktop} {
    width: 930px;
  }

  @media ${props => props.theme.device.desktopL} {
    width: 1110px;
  }

  ${props => props.horizontalVariant === LayoutRowHorizontalVariant.ContainedLeft &&
    `
    @media ${props.theme.device.mobile} {
      margin-left: 0px;
      padding-left: 0px;
    }
    `}
`;
const Controls = styled.div `
  display: none;

  @media ${props => props.theme.device.tabletL} {
    display: flex;
  }
`;
const FirstButtonWrapper = styled.div `
  margin-right: 20px;
`;
const Footer = styled.div `
  ${props => props.theme.layout.container}
  display: flex;
  position: absolute;
  left: 0px;
  padding: 0px;
  width: 100%;

  justify-content: center;
  @media ${props => props.theme.device.mobile} {
    margin-left: 0px;
    padding-left: 0px;
    margin-right: 0px;
    padding-right: 0px;
    width: 100%;
  }

  margin-right: 0px;

  ${props => props.horizontalVariant === LayoutRowHorizontalVariant.ContainedLeft &&
    `
    margin-left: 0;
  `}
`;
const SecondButtonWrapper = styled.div `
  width: fit-content;
  margin-left: 8px;
  @media ${props => props.theme.device.tablet} {
    margin-left: 15px;
  }
`;
const Content = styled.div `
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;

  // eslint-ignore
  &::-webkit-scrollbar {
    display: none;
  }
`;
const FooterWrapper = styled.div `
  margin-bottom: 60px;
`;
export var SectionContentSliderVariant;
(function (SectionContentSliderVariant) {
    SectionContentSliderVariant["Default"] = "Default";
    SectionContentSliderVariant["Subheading"] = "Subheading";
    SectionContentSliderVariant["Body"] = "Body";
})(SectionContentSliderVariant || (SectionContentSliderVariant = {}));
const SCROLL_WIDTH = 290;
const SectionContentSlider = ({ children, titleTextVariant = TextVariant.H4, title, titleLink, intl, colorVariant = ColorVariant.Cave, buttonOutlineVariant = ButtonOutlineVariant.Default, buttonColorVariant = ButtonColorVariant.Default, buttonSizeVariant = ButtonSizeVariant.Default, buttonIconVariant, buttonLabel, buttonTargetRoute, buttonExternalLink, secondaryButtonLabel, secondaryButtonTargetRoute, secondaryButtonExternalLink, horizontalVariant = LayoutRowHorizontalVariant.ContainedLeft, hideControls = false, hideTitleOnMobile, enableBottomSpacing = false, }) => {
    const contentRef = useRef(null);
    const [scrollOffset, setScrollOffset] = useState(0);
    const scrollEnd = (contentRef === null || contentRef === void 0 ? void 0 : contentRef.current)
        ? contentRef.current.scrollWidth - contentRef.current.clientWidth
        : 0;
    const scrollLeft = () => {
        var _a;
        return (_a = contentRef.current) === null || _a === void 0 ? void 0 : _a.scrollBy({
            left: -1 * SCROLL_WIDTH,
            behavior: 'smooth',
        });
    };
    const scrollRight = () => {
        var _a;
        return (_a = contentRef.current) === null || _a === void 0 ? void 0 : _a.scrollBy({
            left: SCROLL_WIDTH,
            behavior: 'smooth',
        });
    };
    return (_jsxs(Wrapper, Object.assign({ enableBottomSpacing: enableBottomSpacing, "data-testid": `SectionContentSlider_${title}` }, { children: [_jsxs(Header, Object.assign({ horizontalVariant: horizontalVariant }, { children: [_jsx(Title, Object.assign({ "data-testid": `SectionContentSlider_${title}Title`, variant: titleTextVariant, colorVariant: colorVariant, hideTitleOnMobile: hideTitleOnMobile, as: "h2" }, { children: titleLink ? _jsx(TitleLink, Object.assign({ href: titleLink }, { children: title })) : title })), !hideControls && (_jsxs(Controls, { children: [_jsx(FirstButtonWrapper, { children: _jsx(IconButtonOld, { iconVariant: IconVariant.ArrowLeft, iconButtonSizeVariant: OldIconButtonSizeVariant.Medium, ariaLabel: intl.formatMessage({
                                        id: 'SectionContentSlider.ariaLabelPrevious',
                                    }), onClick: scrollLeft, disabled: scrollOffset === 0 }) }), _jsx(IconButtonOld, { iconVariant: IconVariant.ArrowRight, iconButtonSizeVariant: OldIconButtonSizeVariant.Medium, ariaLabel: intl.formatMessage({
                                    id: 'SectionContentSlider.ariaLabelForward',
                                }), onClick: scrollRight, disabled: scrollEnd === scrollOffset })] }))] })), _jsx(Content, Object.assign({ ref: contentRef, onScroll: event => {
                    const debounceFunction = debounce(scrollOffset => {
                        setScrollOffset(scrollOffset);
                    }, 100);
                    debounceFunction(event.target.scrollLeft);
                } }, { children: children })), buttonLabel && (_jsx(FooterWrapper, { children: _jsxs(Footer, Object.assign({ horizontalVariant: horizontalVariant }, { children: [_jsx(Button, { label: buttonLabel, outlineVariant: buttonOutlineVariant, colorVariant: buttonColorVariant, sizeVariant: buttonSizeVariant, icon: buttonIconVariant, targetRoute: buttonTargetRoute, externalLink: buttonExternalLink }), secondaryButtonLabel && (_jsx(SecondButtonWrapper, { children: _jsx(Button, { label: secondaryButtonLabel, outlineVariant: buttonOutlineVariant, colorVariant: buttonColorVariant, sizeVariant: buttonSizeVariant, icon: buttonIconVariant, targetRoute: secondaryButtonTargetRoute, externalLink: secondaryButtonExternalLink }) }))] })) }))] })));
};
export default injectIntl(SectionContentSlider);
