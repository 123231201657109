import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styled from 'styled-components/macro';
import { useIntl } from 'react-intl';
import SideNavigation from '../SideNavigation/SideNavigation';
export const NavigationWrapper = styled.div `
  @media ${props => props.theme.device.desktop} {
    ${props => props.theme.layout.col4}
    margin-right: 30px;
  }
`;
export const ContentWrapper = styled.div `
  display: flex;
  flex-direction: column;
  ${props => props.theme.layout.container}

  @media ${props => props.theme.device.desktop} {
    ${props => props.theme.layout.resetContainer}
  }
`;
export const LayoutSideNavigationWrapper = styled.div `
  width: 100vw;
  background: ${props => props.theme.color.snow};
  padding-bottom: 100px;

  @media ${props => props.theme.device.desktop} {
    padding-top: 128px;
    padding-bottom: 128px;
    ${props => props.theme.layout.container}
    display: flex;
    flex-direction: row;
  }
`;
export const LayoutSideNavigationRow = styled.div `
  width: 100%;
`;
const LayoutSideNavigation = ({ children, tabs, }) => {
    const intl = useIntl();
    return (_jsxs(LayoutSideNavigationWrapper, { children: [tabs && (_jsx(NavigationWrapper, { children: _jsx(SideNavigation, { tabs: tabs.map(tab => (Object.assign(Object.assign({}, tab), { title: intl.formatMessage({
                            id: tab.intlId,
                        }) }))) }) })), _jsx(ContentWrapper, { children: children })] }));
};
export default LayoutSideNavigation;
