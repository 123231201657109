import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import { isBrowser } from '@nomady/shared/utils/env';
import { safeSpaceBottomSelector, safeSpaceTopSelector, setDisableScroll, } from '../../slices/UISlice';
import { IconVariant } from '../Icon/Icon';
import IconButtonOld from '../IconButtonOld/IconButtonOld';
const Wrapper = styled.div `
  position: fixed;
  top: ${props => props.safeSpaceTop}px;
  bottom: 0;
  left: 0;
  right: 0;
  padding-bottom: ${props => props.safeSpaceBottom}px;

  background: ${props => props.theme.color.snow};

  ${props => props.theme.priority.xhigh};

  display: grid;
  grid-template-columns: 100vw;
  grid-template-rows: [topEnd] min-content [contentStart] 1fr [contentEnd] min-content [bottomEnd];

  @media ${props => props.theme.device.tablet} {
    display: flex;
    flex-direction: column;

    grid-template-columns: initial;
    grid-template-rows: initial;
  }
`;
const Content = styled.div `
  flex-grow: 1;
  grid-column: 1;
  grid-row-start: contentStart;
  grid-row-end: contentEnd;

  overflow-y: auto;
`;
const BackButtonWrapper = styled.div `
  position: absolute;
  width: 100%;
  ${props => props.theme.priority.always};
  top: 20px;
  left: 30px;
  pointer-events: none;
  @media ${props => props.theme.device.desktopXL} {
    left: 0px;
  }
`;
const BackButtonContainer = styled.div `
  max-width: 1580px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  pointer-events: none;
  & button {
    pointer-events: auto;
  }
`;
const FullPageSheet = ({ open, children, onClose, }) => {
    const [isOpen, setIsOpen] = useState(open);
    const [portalRoot, setPortalRoot] = useState();
    const dispatch = useDispatch();
    useEffect(() => {
        if (open) {
            dispatch(setDisableScroll(true));
        }
        else {
            dispatch(setDisableScroll(false));
        }
        setIsOpen(open);
        return () => {
            dispatch(setDisableScroll(false));
        };
    }, [open]);
    useEffect(() => {
        if (!isBrowser()) {
            return undefined;
        }
        setPortalRoot(document.getElementById('fullpage-sheet-portal-root'));
        const handleEscape = (event) => {
            if (onClose && event.key === 'Escape' && isOpen) {
                onClose(event);
            }
        };
        document.body.addEventListener('keyup', event => handleEscape(event));
        return () => {
            document.body.removeEventListener('keyup', event => handleEscape(event));
        };
    }, [isOpen]);
    const safeSpaceTop = useSelector(safeSpaceTopSelector);
    const safeSpaceBottom = useSelector(safeSpaceBottomSelector);
    const content = (_jsxs(Wrapper, Object.assign({ safeSpaceTop: safeSpaceTop, safeSpaceBottom: safeSpaceBottom }, { children: [onClose && (_jsx(BackButtonWrapper, { children: _jsx(BackButtonContainer, { children: _jsx(IconButtonOld, { iconVariant: IconVariant.Cross, onClick: onClose, dataTestId: "FullPageSheet_backButton" }) }) })), _jsx(Content, { children: children })] })));
    if (isOpen && portalRoot) {
        return createPortal(content, portalRoot);
    }
    return null;
};
export default FullPageSheet;
