import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styled from 'styled-components/macro';
import { useIntl } from 'react-intl';
import Icon, { IconVariant } from '../../Icon/Icon';
import Text, { TextVariant } from '../../Text/Text';
import { ColorVariant } from '../../../theme';
import { CODE_TYPE } from '../types';
const Card = styled.div `
  background-color: ${props => props.theme.color.pearl};
  border-radius: 5px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 8px 4px;
  max-height: 36px;
  margin-top: 3px;
`;
const LeftContainer = styled.div `
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
`;
const RightContainer = styled.div `
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;
const IconButton = styled.button `
  background-color: ${props => props.theme.color.transparent};
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.6;
  }

  &:focus {
    opacity: 0.6;
  }
`;
const PaymentInfoCard = ({ code, codeType, value, currency, onRemove, }) => {
    const intl = useIntl();
    const giftCardTypes = [CODE_TYPE.GIFT_CARD, CODE_TYPE.DISCOUNT_CODE];
    const typeColorVariant = giftCardTypes.includes(codeType)
        ? ColorVariant.Amber
        : ColorVariant.Lilac;
    const typeToText = (codeType) => {
        if (giftCardTypes.includes(codeType)) {
            return intl.formatMessage({ id: 'PaymentInfoCard.giftCard' });
        }
        return intl.formatMessage({ id: 'PaymentInfoCard.discountCode' });
    };
    const giftCardDeductionPositive = codeType !== CODE_TYPE.CUSTOMER_COMMISSION_DISCOUNT_CODE && value > 0;
    const valueText = currency !== '%'
        ? `${currency} ${value.toFixed(2)}`
        : `${value} ${currency}`;
    return (_jsxs(Card, { children: [_jsxs(LeftContainer, { children: [_jsx(Text, Object.assign({ colorVariant: ColorVariant.Cave, variant: TextVariant.B60 }, { children: typeToText(codeType) })), _jsxs(Text, Object.assign({ colorVariant: typeColorVariant, variant: TextVariant.B60 }, { children: ["\u2022\u2022\u2022\u2022", code.slice(-2)] }))] }), _jsxs(RightContainer, { children: [_jsxs(Text, Object.assign({ colorVariant: ColorVariant.Cave, variant: TextVariant.B60 }, { children: [giftCardDeductionPositive &&
                                `${intl.formatMessage({ id: 'PaymentInfoCard.balance' })} `, valueText] })), !!onRemove && (_jsx(IconButton, Object.assign({ "aria-label": "remove element", onClick: onRemove }, { children: _jsx(Icon, { variant: IconVariant.Garbage }) })))] })] }));
};
export default PaymentInfoCard;
