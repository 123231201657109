import http from 'http';
import https from 'https';
import * as sharetribeSdk from 'sharetribe-flex-sdk';
import Decimal from './decimal';
import createExpressTokenStore from './tokenStore/expressTokenStore';
const { types, tokenStore, transit, createInstance } = sharetribeSdk;
const CLIENT_ID = process.env.REACT_APP_SHARETRIBE_SDK_CLIENT_ID ||
    process.env.SHARETRIBE_SDK_MARKETPLACE_CLIENT_ID;
const CLIENT_SECRET = process.env.SHARETRIBE_SDK_CLIENT_SECRET ||
    process.env.SHARETRIBE_SDK_MARKETPLACE_CLIENT_SECRET;
const USING_SSL = process.env.REACT_APP_SHARETRIBE_USING_SSL === 'true';
const TRANSIT_VERBOSE = process.env.REACT_APP_SHARETRIBE_SDK_TRANSIT_VERBOSE === 'true';
export const typeHandlers = [
    {
        type: types.BigDecimal,
        customType: Decimal,
        writer: (v) => new types.BigDecimal(v.toString()),
        reader: (v) => {
            return new Decimal(v.value);
        },
    },
];
const baseUrlMaybe = process.env.REACT_APP_SHARETRIBE_SDK_BASE_URL
    ? { baseUrl: process.env.REACT_APP_SHARETRIBE_SDK_BASE_URL }
    : null;
const httpAgent = new http.Agent({ keepAlive: true });
const httpsAgent = new https.Agent({ keepAlive: true });
const memoryStore = (token) => {
    const store = tokenStore.memoryStore();
    store.setToken(token);
    return store;
};
// Read the user token from the request cookie
const getUserToken = (req) => {
    const cookieTokenStore = createExpressTokenStore({
        clientId: CLIENT_ID,
        req,
        secure: USING_SSL,
    });
    return cookieTokenStore.getToken();
};
export const serialize = (data) => {
    return transit.write(data, {
        typeHandlers,
        verbose: TRANSIT_VERBOSE,
    });
};
export const deserialize = (str) => {
    return transit.read(str, { typeHandlers });
};
export const handleError = (res, error) => {
    console.error(error);
    if (error.status && error.statusText && error.data) {
        // JS SDK error
        return res.status(error.status).json({
            status: error.status,
            statusText: error.statusText,
            data: error.data,
        });
    }
    return res.status(500).json({ error: error.message });
};
export const getSdk = (req, res) => {
    return createInstance(Object.assign({ transitVerbose: TRANSIT_VERBOSE, clientId: CLIENT_ID, httpAgent,
        httpsAgent, tokenStore: createExpressTokenStore({
            clientId: CLIENT_ID,
            req,
            res,
            secure: USING_SSL,
        }), typeHandlers }, baseUrlMaybe));
};
export const getMarketplaceSdk = () => {
    return createInstance({
        clientId: CLIENT_ID,
    });
};
export const getTrustedSdk = (req) => {
    const userToken = getUserToken(req);
    // Initiate an SDK instance for token exchange
    const sdk = createInstance(Object.assign({ transitVerbose: TRANSIT_VERBOSE, clientId: CLIENT_ID, clientSecret: CLIENT_SECRET, httpAgent,
        httpsAgent, tokenStore: memoryStore(userToken), typeHandlers }, baseUrlMaybe));
    // Perform a token exchange
    return sdk.exchangeToken().then(response => {
        // Setup a trusted sdk with the token we got from the exchange:
        const trustedToken = response.data;
        return createInstance(Object.assign({ transitVerbose: TRANSIT_VERBOSE, 
            // We don't need CLIENT_SECRET here anymore
            clientId: CLIENT_ID, 
            // Important! Do not use a cookieTokenStore here but a memoryStore
            // instead so that we don't leak the token back to browser client.
            tokenStore: memoryStore(trustedToken), httpAgent,
            httpsAgent,
            typeHandlers }, baseUrlMaybe));
    });
};
