import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styled from 'styled-components/macro';
import CheckboxInputSection from '../../CheckboxInputSection/CheckboxInputSection';
import FilterGroup from '../../FilterGroup/FilterGroup';
import { IconVariant } from '../../Icon/Icon';
import LabeledToggle, { LabeledToggleColorVariant, } from '../../LabeledToggle/LabeledToggle';
const MainOptions = styled.div `
  margin-top: 8px;
`;
const FireplaceFilterGroup = ({ currentFeaturesSearchQuery, intl, onSetFeatures, }) => {
    var _a, _b, _c, _d;
    const checkboxes = [
        {
            id: 'fireplace-grate',
            checked: !!((_a = currentFeaturesSearchQuery.fireplace) === null || _a === void 0 ? void 0 : _a.grate),
            label: intl.formatMessage({
                id: 'MarketplaceConfig.fireplaceWithGrate',
            }),
            onChange: (value) => onSetFeatures({
                fireplace: { grate: value },
            }),
        },
        {
            id: 'fireplace-firewood-included',
            checked: !!((_b = currentFeaturesSearchQuery.fireplace) === null || _b === void 0 ? void 0 : _b.firewoodIncluded),
            label: intl.formatMessage({
                id: 'MarketplaceConfig.firewoodInclusive',
            }),
            onChange: (value) => onSetFeatures({
                fireplace: { firewoodIncluded: value },
            }),
        },
        {
            id: 'fireplace-purchasable',
            checked: !!((_c = currentFeaturesSearchQuery.fireplace) === null || _c === void 0 ? void 0 : _c.firewoodSale),
            label: intl.formatMessage({
                id: 'MarketplaceConfig.firewoodPurchasable',
            }),
            onChange: (value) => onSetFeatures({
                fireplace: { firewoodSale: value },
            }),
        },
        {
            id: 'fireplace-guest-brings-along',
            checked: !!((_d = currentFeaturesSearchQuery.fireplace) === null || _d === void 0 ? void 0 : _d.guestBringsFirewoodAlong),
            label: intl.formatMessage({
                id: 'MarketplaceConfig.firewoodGuestBringsAlong',
            }),
            onChange: (value) => onSetFeatures({
                fireplace: { guestBringsFirewoodAlong: value },
            }),
        },
    ];
    return (_jsxs(FilterGroup, Object.assign({ iconVariant: IconVariant.Campfire, label: intl.formatMessage({
            id: 'SearchBarSheet.Features.Fireplace.title',
        }) }, { children: [_jsx(MainOptions, { children: _jsx(LabeledToggle, { iconVariant: IconVariant.Fireplace, colorVariant: LabeledToggleColorVariant.White, onChange: value => onSetFeatures({ fireplaceAvailable: value }), checked: (currentFeaturesSearchQuery === null || currentFeaturesSearchQuery === void 0 ? void 0 : currentFeaturesSearchQuery.fireplaceAvailable) || false, label: intl.formatMessage({
                        id: 'SearchBarSheet.Features.fireplaceAvailable',
                    }) }) }), _jsx(CheckboxInputSection, { checkboxes: checkboxes })] })));
};
export default FireplaceFilterGroup;
