import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { GoogleApiWrapper, Map, Marker, } from 'google-maps-react';
import { useIntl } from 'react-intl';
import config from '../../config';
import { getInitialMapPosition } from '../../util/maps';
import mapMarkerIcon from '../../util/mapMarkerIcon';
import { mapMarkerTypes, } from '@nomady/shared/marketplace-custom-config';
const GoogleMapCoordinatePicker = ({ markers, initialCenter, addMarker, chosenMarker }) => {
    const intl = useIntl();
    const initialMapPosition = getInitialMapPosition(markers, initialCenter);
    const [mapCenter, setMapCenter] = useState();
    const mapClicked = (_, __, event) => {
        if (!chosenMarker) {
            return;
        }
        const { latLng } = event;
        const lat = latLng.lat();
        const lng = latLng.lng();
        const type = chosenMarker;
        const key = `${type}_${lat}_${lng}`;
        addMarker({
            type,
            key,
            lat,
            lng,
        });
    };
    const mapMoved = (_, map) => {
        const center = map === null || map === void 0 ? void 0 : map.getCenter();
        if (center) {
            setMapCenter(center.toJSON());
        }
    };
    useEffect(() => {
        const positionOnInitialCenterInsteadOfMarkers = markers.length === 0 &&
            ((initialCenter === null || initialCenter === void 0 ? void 0 : initialCenter.lat) !== (mapCenter === null || mapCenter === void 0 ? void 0 : mapCenter.lat) ||
                (initialCenter === null || initialCenter === void 0 ? void 0 : initialCenter.lng) !== (mapCenter === null || mapCenter === void 0 ? void 0 : mapCenter.lng));
        if (positionOnInitialCenterInsteadOfMarkers) {
            setMapCenter(initialCenter);
        }
    }, [initialCenter]);
    const style = {
        position: 'relative',
        width: '100%',
        maxWidth: '100%',
        height: '400px',
    };
    const mapMarkers = markers.map((marker, index) => {
        if (!marker) {
            return null;
        }
        const mapIcon = {
            url: mapMarkerIcon[marker.type],
            anchor: new window.google.maps.Point(20, 45),
            scaledSize: new window.google.maps.Size(38.86, 46.81),
        };
        const position = { lat: marker.lat, lng: marker.lng };
        const keyLabelConfig = Object.values(mapMarkerTypes).find(element => element.key === marker.type);
        const { label } = keyLabelConfig;
        return (_jsx(Marker, { title: intl.formatMessage({ id: label }), position: position, icon: mapIcon }, encodeURI(marker.key)));
    });
    return (_jsx(Map, Object.assign({ google: window.google, mapTypeControl: false, 
        // @ts-ignore not properly typed in 3rd party library
        mapType: window.google.maps.MapTypeId.SATELLITE, draggable: true, fullscreenControl: false, zoomControl: false, streetViewControl: false, panControl: false, rotateControl: false, scaleControl: false, zoom: 18, containerStyle: style, initialCenter: initialMapPosition, center: mapCenter, onClick: mapClicked, onDragend: mapMoved }, { children: mapMarkers })));
};
const ConnectedComponent = GoogleApiWrapper({
    apiKey: config.maps.googleMapsAPIKey,
})(GoogleMapCoordinatePicker);
export default ConnectedComponent;
