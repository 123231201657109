var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { transit } from '@nomady/shared/utils/sdkLoader';
import paramsSerializer from '@nomady/shared/utils/paramsSerializer';
import { typeHandlers } from '@nomady/shared/utils/sdk';
import createBrowserTokenStore from '@nomady/shared/utils/tokenStore/browserTokenStore';
import { apiHost } from '@nomady/shared/utils/localized-domains';
import { APPLICATION_VERSION } from '@nomady/shared/application-version';
import config from '../config';
import { isApp, isIos } from './deviceContext';
export const determinePlatform = (isApp, isIos) => {
    if (isApp) {
        return isIos ? 'ios' : 'android';
    }
    return 'web';
};
const platformString = determinePlatform(isApp, isIos);
const serialize = (data) => {
    return transit.write(data, {
        typeHandlers,
        verbose: config.sdk.transitVerbose,
    });
};
const deserialize = (str) => {
    return transit.read(str, { typeHandlers });
};
const credentials = process.env.NODE_ENV === 'production' &&
    process.env.REACT_APP_BUILD_TARGET !== 'app'
    ? 'same-origin'
    : 'include';
const getAuthorizationTokenForRequest = () => {
    const token = createBrowserTokenStore({
        clientId: process.env.REACT_APP_SHARETRIBE_SDK_CLIENT_ID,
    }).getToken();
    const headers = {};
    if (token) {
        headers['Authorization'] = JSON.stringify(token);
        headers['X-Platform'] = platformString;
        headers['X-Client-Version'] = APPLICATION_VERSION;
    }
    return headers;
};
const post = (path, body) => {
    const headers = getAuthorizationTokenForRequest();
    const url = `${apiHost()}${path}`;
    const options = {
        method: 'POST',
        credentials,
        headers: Object.assign({ 'Content-Type': 'application/transit+json' }, headers),
        body: serialize(body),
    };
    return window
        .fetch(url, options)
        .then(res => {
        if (res.status >= 400) {
            const e = new Error('Local API request failed');
            e.apiResponse = res;
            throw e;
        }
        return res;
    })
        .then(res => {
        const contentTypeHeader = res.headers.get('Content-Type');
        const contentType = contentTypeHeader
            ? contentTypeHeader.split(';')[0]
            : null;
        if (contentType === 'application/transit+json') {
            return res.text().then(deserialize);
        }
        if (contentType === 'application/json') {
            return res.json();
        }
        return res.text();
    });
};
const get = (path) => __awaiter(void 0, void 0, void 0, function* () {
    const headers = getAuthorizationTokenForRequest();
    const response = yield fetch(`${apiHost()}${path}`, { credentials, headers });
    return response;
});
// Initiate a privileged transaction.
//
// With privileged transitions, the transactions need to be created
// from the backend. This endpoint enables sending the booking data to
// the local backend, and passing that to the Marketplace API.
//
// See `server/api/initiate-privileged.js` to see what data should be
// sent in the body.
export const initiatePrivileged = (body) => {
    return post('/api/initiate-privileged', body);
};
// Transition a transaction with a privileged transition.
//
// This is similar to the `initiatePrivileged` above. It will use the
// backend for the transition. The backend endpoint will add the
// payment line items to the transition params.
//
// See `server/api/transition-privileged.js` to see what data should
// be sent in the body.
export const transitionPrivileged = (body) => {
    return post('/api/transition-privileged', body);
};
export const confirmPaymentPrivileged = (body) => {
    return post('/api/confirm-payment-privileged', body);
};
export const declinePrivileged = (body) => {
    return post('/api/decline-privileged', body);
};
export const withdrawByCustomerPriviledged = (body) => {
    return post('/api/withdraw-by-customer-privileged', body);
};
export const acceptPrivileged = (body) => {
    return post('/api/accept-privileged', body);
};
export const showTransaction = (body) => __awaiter(void 0, void 0, void 0, function* () {
    const editedBody = Object.assign(Object.assign({}, body), { id: body.id.uuid });
    const query = paramsSerializer(editedBody);
    const response = yield get(`/api/transactions/show?${query}`);
    const responseText = yield response.text();
    return deserialize(responseText);
});
export const queryTransactions = (body) => __awaiter(void 0, void 0, void 0, function* () {
    const query = paramsSerializer(body);
    const response = yield get(`/api/transactions/query?${query}`);
    const responseText = yield response.text();
    return deserialize(responseText);
});
export const transactionCreateReview = (bodyParams, queryParams) => __awaiter(void 0, void 0, void 0, function* () {
    const query = paramsSerializer(queryParams);
    const response = yield post(`/api/transactions/create_review?${query}`, bodyParams);
    return {
        status: response.status,
        statusText: response.statusText,
        data: response,
    };
});
export const showTouristTaxReports = () => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield get(`/api/tourist-tax-reports/show`);
    const responseText = yield response.text();
    return deserialize(responseText);
});
export const cancelByCustomerPrivileged = (body) => {
    return post('/api/cancel-by-customer-privileged', body);
};
export const checkGiftCardCode = (giftCardCode) => {
    return fetch(`${apiHost()}/api/gift-card/check-code`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            giftCardCode,
        }),
    });
};
export const checkCustomerCommissionDiscountCode = (customerCommissionDiscountCode, bookingStart, bookingEnd) => {
    return fetch(`${apiHost()}/api/customer-commission-discount-code/check-code`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            customerCommissionDiscountCode,
            bookingStart,
            bookingEnd,
        }),
    });
};
export const downloadInvoice = (id) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield get(`/api/invoice/${id}/download`);
    return response.blob();
});
export const downloadTouristTaxReport = (id, fileType) => __awaiter(void 0, void 0, void 0, function* () {
    const headers = getAuthorizationTokenForRequest();
    const response = yield fetch(`${apiHost()}/api/tourist-tax-report/${id}/download/${fileType}`, {
        credentials,
        headers,
    });
    return response.blob();
});
export const createCheckoutSession = (value, quantity, type, currency, shippingCountry, locale) => fetch(`${apiHost()}/api/gift-card/create-checkout-session`, {
    method: 'POST',
    headers: {
        'Content-Type': 'application/json',
    },
    body: JSON.stringify({
        value,
        quantity,
        type,
        currency,
        shippingCountry,
        locale,
        cancelReturnUrl: window.location.href.replace('capacitor://localhost', 'https://nomady.camp'),
    }),
});
export const generateGiftCardCodes = (sessionId, locale) => fetch(`${apiHost()}/api/gift-card/generate`, {
    method: 'POST',
    headers: {
        'Content-Type': 'application/json',
    },
    body: JSON.stringify({ session_id: sessionId, locale }),
});
export const getListingFavoritesLists = () => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield get(`/api/listing-favorites-lists`);
    if (400 <= (response === null || response === void 0 ? void 0 : response.status)) {
        const error = yield response.json();
        throw new Error(yield (error === null || error === void 0 ? void 0 : error.error));
    }
    return response;
});
export const postListingFavoritesList = (name, listingId) => {
    const headers = getAuthorizationTokenForRequest();
    return fetch(`${apiHost()}/api/listing-favorites-list/create`, {
        method: 'POST',
        headers: Object.assign({ 'Content-Type': 'application/json' }, headers),
        body: JSON.stringify({
            name,
            listingId,
        }),
        credentials,
    });
};
export const deleteListingFavoritesList = (id) => {
    const headers = getAuthorizationTokenForRequest();
    return fetch(`${apiHost()}/api/listing-favorites-list/${id}`, {
        method: 'DELETE',
        headers: Object.assign({ 'Content-Type': 'application/json' }, headers),
        credentials,
    });
};
export const putListingFavoritesList = (id, name) => {
    const headers = getAuthorizationTokenForRequest();
    return fetch(`${apiHost()}/api/listing-favorites-list/${id}`, {
        method: 'PUT',
        headers: Object.assign({ 'Content-Type': 'application/json' }, headers),
        body: JSON.stringify({
            name,
        }),
        credentials,
    });
};
export const postAddListingToListingFavoritesList = (listId, listingId) => {
    const headers = getAuthorizationTokenForRequest();
    return fetch(`${apiHost()}/api/listing-favorites-list/${listId}/add-listing`, {
        method: 'POST',
        headers: Object.assign({ 'Content-Type': 'application/json' }, headers),
        body: JSON.stringify({
            listingId,
        }),
        credentials,
    });
};
export const postRemoveListingfromListingFavoritesList = (listId, listingId) => {
    const headers = getAuthorizationTokenForRequest();
    return fetch(`${apiHost()}/api/listing-favorites-list/${listId}/remove-listing`, {
        method: 'POST',
        headers: Object.assign({ 'Content-Type': 'application/json' }, headers),
        body: JSON.stringify({
            listingId,
        }),
        credentials,
    });
};
export const showOwnListing = (queryParams) => __awaiter(void 0, void 0, void 0, function* () {
    const query = paramsSerializer(queryParams);
    const response = yield get(`/api/own_listings/show?${query}`);
    if (400 <= (response === null || response === void 0 ? void 0 : response.status)) {
        throw new Error(response.statusText);
    }
    const responseText = yield response.text();
    return {
        status: response.status,
        statusText: response.statusText,
        data: deserialize(responseText),
    };
});
export const queryListings = (queryParams) => __awaiter(void 0, void 0, void 0, function* () {
    const query = paramsSerializer(queryParams);
    const response = yield get(`/api/listings/query?${query}`);
    if (400 <= (response === null || response === void 0 ? void 0 : response.status)) {
        throw new Error(response.statusText);
    }
    const responseText = yield response.text();
    return {
        status: response.status,
        statusText: response.statusText,
        data: deserialize(responseText),
    };
});
export const showListing = (queryParams) => __awaiter(void 0, void 0, void 0, function* () {
    const query = paramsSerializer(Object.assign(Object.assign({}, queryParams), { id: queryParams.id.uuid }));
    const response = yield get(`/api/listings/show?${query}`);
    if (400 <= (response === null || response === void 0 ? void 0 : response.status)) {
        throw new Error(response.statusText);
    }
    const responseText = yield response.text();
    return {
        status: response.status,
        statusText: response.statusText,
        data: deserialize(responseText),
    };
});
export const queryReviews = (queryParams) => __awaiter(void 0, void 0, void 0, function* () {
    const query = paramsSerializer(queryParams);
    const response = yield get(`/api/reviews/query?${query}`);
    if (400 <= (response === null || response === void 0 ? void 0 : response.status)) {
        throw new Error(response.statusText);
    }
    const responseText = yield response.text();
    return {
        status: response.status,
        statusText: response.statusText,
        data: deserialize(responseText),
    };
});
export const createOwnListingDraft = (body, queryParams) => __awaiter(void 0, void 0, void 0, function* () {
    const query = paramsSerializer(queryParams);
    const response = yield post(`/api/own_listings/create_draft?${query}`, body);
    return {
        status: response.status,
        statusText: response.statusText,
        data: response,
    };
});
export const updateOwnListing = (body, queryParams = {}) => __awaiter(void 0, void 0, void 0, function* () {
    const query = paramsSerializer(queryParams);
    const response = yield post(`/api/own_listings/update?${query}`, body);
    return {
        status: response.status,
        statusText: response.statusText,
        data: response,
    };
});
export const createCurrentUser = (body, queryParams) => __awaiter(void 0, void 0, void 0, function* () {
    const query = paramsSerializer(queryParams);
    const headers = getAuthorizationTokenForRequest();
    const response = yield fetch(`${apiHost()}/api/current_user/create?${query}`, {
        body: JSON.stringify(body),
        method: 'POST',
        headers: Object.assign({ 'Content-Type': 'application/json' }, headers),
        credentials,
    });
    if (400 <= (response === null || response === void 0 ? void 0 : response.status)) {
        const responseText = yield response.text();
        // eslint-disable-next-line no-throw-literal
        throw deserialize(responseText);
    }
    return {
        status: response.status,
        statusText: response.statusText,
        data: response,
    };
});
export const updateUserLastLogin = () => __awaiter(void 0, void 0, void 0, function* () {
    const headers = getAuthorizationTokenForRequest();
    return fetch(`${apiHost()}/api/update-user-last-login`, {
        method: 'POST',
        headers: Object.assign({ 'Content-Type': 'application/json' }, headers),
        body: JSON.stringify({
            clientVersion: APPLICATION_VERSION,
            platform: platformString,
        }),
        credentials,
    });
});
export const reportUserAppVersion = () => {
    const headers = getAuthorizationTokenForRequest();
    return fetch(`${apiHost()}/api/report-user-app-version`, {
        method: 'POST',
        headers: Object.assign({ 'Content-Type': 'application/json' }, headers),
        body: JSON.stringify({
            clientVersion: APPLICATION_VERSION,
            platform: platformString,
        }),
        credentials,
    });
};
export const showUser = (queryParams) => __awaiter(void 0, void 0, void 0, function* () {
    const query = paramsSerializer(queryParams);
    const response = yield get(`/api/users/show?${query}`);
    if (400 <= (response === null || response === void 0 ? void 0 : response.status)) {
        throw new Error(response.statusText);
    }
    const responseText = yield response.text();
    return {
        status: response.status,
        statusText: response.statusText,
        data: deserialize(responseText),
    };
});
export const showCurrentUser = (queryParams) => __awaiter(void 0, void 0, void 0, function* () {
    const query = paramsSerializer(queryParams);
    const response = yield get(`/api/current_user/show?${query}`);
    if (400 <= (response === null || response === void 0 ? void 0 : response.status)) {
        throw new Error(response.statusText);
    }
    const responseText = yield response.text();
    return {
        status: response.status,
        statusText: response.statusText,
        data: deserialize(responseText),
    };
});
export const updateProfileCurrentUser = (body, queryParams) => __awaiter(void 0, void 0, void 0, function* () {
    const query = paramsSerializer(queryParams);
    const response = yield post(`/api/current_user/update_profile?${query}`, body);
    return {
        status: response.status,
        statusText: response.statusText,
        data: response,
    };
});
export const deleteCurrentUser = () => __awaiter(void 0, void 0, void 0, function* () {
    const headers = getAuthorizationTokenForRequest();
    return fetch(`${apiHost()}/api/current_user/delete`, {
        method: 'POST',
        headers: Object.assign({ 'Content-Type': 'application/json' }, headers),
        credentials,
    });
});
