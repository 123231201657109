import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { types as sdkTypes } from '@nomady/shared/utils/sdkLoader';
import { ListingLink } from '..';
import { Title } from '../EditListingDescriptionPanel/EditListingDescriptionPanel';
import EditListingPricingForm from '../../forms/EditListingPricingForm/EditListingPricingForm';
import { MARKETPLACE_CURRENCIES, } from '@nomady/shared/config';
const { Money } = sdkTypes;
const EditListingPricingPanel = props => {
    const { listing, listingsWithPendingRequests, listingDefaultCurrency, onSubmit, onFormDirty, submitButtonText, updateInProgress, errors, } = props;
    const panelTitle = (_jsx(FormattedMessage, { id: "EditListingPricingPanel.title", values: { listingTitle: _jsx(ListingLink, { listing: listing }) } }));
    const { price } = (listing === null || listing === void 0 ? void 0 : listing.attributes) || {};
    const priceCurrencyValid = price instanceof Money
        ? MARKETPLACE_CURRENCIES.includes(price === null || price === void 0 ? void 0 : price.currency)
        : true;
    const hasPendingRequest = listing &&
        listingsWithPendingRequests &&
        listingsWithPendingRequests.includes(listing.id.uuid);
    return (_jsxs(_Fragment, { children: [_jsx(Title, { children: panelTitle }), priceCurrencyValid ? (_jsx(_Fragment, { children: !hasPendingRequest ? (_jsx(EditListingPricingForm, { listing: listing, submitButtonLabel: submitButtonText, updateListingInProgress: updateInProgress, currencyFromPublishedListing: listingDefaultCurrency, updateListingError: errors.updateListingError, onSubmit: onSubmit, onFormDirty: onFormDirty })) : (_jsx(_Fragment, { children: _jsx(FormattedMessage, { id: "EditListingPricingForm.pendingBookingRequestsError" }) })) })) : (_jsx("div", { children: _jsx(FormattedMessage, { id: "EditListingPricingPanel.listingPriceCurrencyInvalid" }) }))] }));
};
export default EditListingPricingPanel;
