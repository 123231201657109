var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { types as sdkTypes } from '@nomady/shared/utils/sdkLoader';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { storableError } from '../../util/errors';
import { queryReviews as apiQueryReviews, showUser, queryListings, } from '../../util/api';
import { apiLocaleSelector, getInitialPageTranslationActiveSelector, localeSelector, } from '../../slices/UISlice';
const { UUID } = sdkTypes;
const REVIEWS_PER_PAGE = 5;
export var ReviewType;
(function (ReviewType) {
    ReviewType["Provider"] = "ofProvider";
    ReviewType["Customer"] = "ofCustomer";
})(ReviewType || (ReviewType = {}));
// ================ Action types ================ //
const SET_INITIAL_STATE = 'app/ProfilePage/SET_INITIAL_STATE';
const QUERY_USER_REQUEST = 'app/ProfilePage/QUERY_USER_REQUEST';
const QUERY_USER_SUCCESS = 'app/ProfilePage/QUERY_USER_SUCCESS';
const QUERY_USER_ERROR = 'app/ProfilePage/QUERY_USER_ERROR';
const QUERY_USER_REVIEW_TYPES = 'app/ProfilePage/QUERY_USER_REVIEW_TYPES';
const SET_REVIEW_TYPE_FILTER = 'app/ProfilePage/SET_REVIEW_TYPE_FILTER';
const QUERY_LISTINGS_REQUEST = 'app/ProfilePage/QUERY_LISTINGS_REQUEST';
const QUERY_LISTINGS_SUCCESS = 'app/ProfilePage/QUERY_LISTINGS_SUCCESS';
const QUERY_LISTINGS_ERROR = 'app/ProfilePage/QUERY_LISTINGS_ERROR';
const QUERY_REVIEWS_REQUEST = 'app/ProfilePage/QUERY_REVIEWS_REQUEST';
const QUERY_REVIEWS_SUCCESS = 'app/ProfilePage/QUERY_REVIEWS_SUCCESS';
const QUERY_REVIEWS_ERROR = 'app/ProfilePage/QUERY_REVIEWS_ERROR';
const SET_TRANSLATION_ACTIVE = 'app/ProfilePage/SET_TRANSLATION_ACTIVE';
const initialState = {
    userId: null,
    translationActive: true,
    userListingRefs: [],
    queryUserError: null,
    userLoading: true,
    hasProviderReviews: false,
    hasCustomerReviews: false,
    queryListingsError: null,
    reviews: [],
    reviewTypeFilter: ReviewType.Provider,
    reviewsLoading: false,
    reviewsPage: 1,
    reviewsTotalPages: 1,
    totalReviews: 0,
    queryReviewsError: null,
};
export default function profilePageReducer(state = initialState, action) {
    switch (action.type) {
        case SET_INITIAL_STATE:
            return Object.assign({}, initialState);
        case QUERY_USER_REQUEST:
            return Object.assign(Object.assign({}, state), { queryUserError: null, userLoading: true, userId: action.payload.userId });
        case QUERY_USER_SUCCESS:
            return Object.assign(Object.assign({}, state), { userLoading: false });
        case QUERY_USER_ERROR:
            return Object.assign(Object.assign({}, state), { queryUserError: action.payload, userLoading: false });
        case QUERY_USER_REVIEW_TYPES:
            return Object.assign(Object.assign({}, state), { hasProviderReviews: action.payload.hasProviderReviews, hasCustomerReviews: action.payload.hasCustomerReviews, reviewTypeFilter: action.payload.hasCustomerReviews
                    ? ReviewType.Customer
                    : ReviewType.Provider });
        case SET_REVIEW_TYPE_FILTER:
            return Object.assign(Object.assign({}, state), { reviewTypeFilter: action.payload.reviewTypeFilter });
        case QUERY_LISTINGS_REQUEST:
            return Object.assign(Object.assign({}, state), { 
                // Empty listings only when user id changes
                userListingRefs: action.payload.userId === state.userId ? state.userListingRefs : [], queryListingsError: null });
        case QUERY_LISTINGS_SUCCESS:
            return Object.assign(Object.assign({}, state), { userListingRefs: action.payload.listingRefs });
        case QUERY_LISTINGS_ERROR:
            return Object.assign(Object.assign({}, state), { userListingRefs: [], queryListingsError: action.payload });
        case QUERY_REVIEWS_REQUEST:
            return Object.assign(Object.assign({}, state), { queryReviewsError: null, reviewsLoading: true });
        case QUERY_REVIEWS_SUCCESS:
            return Object.assign(Object.assign({}, state), { reviews: action.payload.reviews, reviewsPage: action.payload.reviewsPage, reviewsTotalPages: action.payload.reviewsTotalPages, totalReviews: action.payload.totalReviews, reviewsLoading: false });
        case QUERY_REVIEWS_ERROR:
            return Object.assign(Object.assign({}, state), { reviews: [], queryReviewsError: action.payload, reviewsLoading: false });
        case SET_TRANSLATION_ACTIVE: {
            return Object.assign(Object.assign({}, state), { translationActive: action.payload });
        }
        default:
            return state;
    }
}
const setInitialState = () => ({
    type: SET_INITIAL_STATE,
});
const queryUserReviewTypes = (hasCustomerReviews, hasProviderReviews) => ({
    type: QUERY_USER_REVIEW_TYPES,
    payload: { hasCustomerReviews, hasProviderReviews },
});
const setReviewTypeFilterAction = (reviewTypeFilter) => ({
    type: SET_REVIEW_TYPE_FILTER,
    payload: { reviewTypeFilter },
});
const queryUserRequest = (userId) => ({
    type: QUERY_USER_REQUEST,
    payload: { userId },
});
const queryUserSuccess = () => ({
    type: QUERY_USER_SUCCESS,
});
const queryUserError = (e) => ({
    type: QUERY_USER_ERROR,
    error: true,
    payload: e,
});
const queryListingsRequest = (userId) => ({
    type: QUERY_LISTINGS_REQUEST,
    payload: { userId },
});
const queryListingsSuccess = (listingRefs) => ({
    type: QUERY_LISTINGS_SUCCESS,
    payload: { listingRefs },
});
const queryListingsError = (e) => ({
    type: QUERY_LISTINGS_ERROR,
    error: true,
    payload: e,
});
const queryReviewsRequest = () => ({
    type: QUERY_REVIEWS_REQUEST,
});
const queryReviewsSuccess = (reviews, reviewsPage, reviewsTotalPages, totalReviews) => ({
    type: QUERY_REVIEWS_SUCCESS,
    payload: {
        reviews,
        reviewsPage,
        reviewsTotalPages,
        totalReviews,
    },
});
const queryReviewsError = (e) => ({
    type: QUERY_REVIEWS_ERROR,
    error: true,
    payload: e,
});
const setTranslationActive = (active) => ({
    type: SET_TRANSLATION_ACTIVE,
    payload: active,
});
// ================ Thunks ================ //
// todo: fix this
export const queryUserListings = (userId) => (dispatch, getState, sdk) => {
    const state = getState();
    const locale = localeSelector(state);
    dispatch(queryListingsRequest(userId));
    return queryListings({
        authorId: userId,
        include: ['images'],
        'fields.image': [
            // Listing page
            'variants.landscape-crop',
            'variants.landscape-crop2x',
            'variants.landscape-crop4x',
            'variants.landscape-crop6x',
            // Social media
            'variants.facebook',
            'variants.twitter',
            // Image carousel
            'variants.scaled-small',
            'variants.scaled-medium',
            'variants.scaled-large',
            'variants.scaled-xlarge',
            // Avatars
            'variants.square-small',
            'variants.square-small2x',
        ],
        locale,
    })
        .then((response) => {
        // Pick only the id and type properties from the response listings
        const listingRefs = response.data.data.map(({ id, type }) => ({
            id,
            type,
        }));
        dispatch(addMarketplaceEntities(response));
        dispatch(queryListingsSuccess(listingRefs));
        return response;
    })
        .catch((e) => dispatch(queryListingsError(storableError(e))));
};
const queryReviews = (page, userId, type) => (dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
    dispatch(queryReviewsRequest());
    const state = getState();
    const apiLocale = apiLocaleSelector(state, !state.ProfilePage.translationActive);
    const locale = apiLocale ? { locale: apiLocale } : {};
    const params = Object.assign({ subjectId: userId, type: type, include: ['author', 'listing', 'author.profileImage'], perPage: REVIEWS_PER_PAGE, page }, locale);
    try {
        const showReviewsResponse = yield apiQueryReviews(params);
        dispatch(queryReviewsSuccess(showReviewsResponse.data.data, page, showReviewsResponse.data.totalPages, showReviewsResponse.data.totalItems));
    }
    catch (error) {
        dispatch(queryReviewsError(error));
    }
});
export const changeReviewsPage = (userId, page, type) => (dispatch, getState) => {
    const { ProfilePage: { reviewTypeFilter }, } = getState();
    dispatch(queryReviews(page, userId, reviewTypeFilter));
};
export const setReviewTypeFilter = (type) => (dispatch, getState) => {
    const { ProfilePage: { userId }, } = getState();
    if (userId) {
        dispatch(setReviewTypeFilterAction(type));
        dispatch(queryReviews(1, userId, type));
    }
};
export const queryUserReviews = (userId, type) => (dispatch, getState) => {
    const { ProfilePage: { reviewTypeFilter }, } = getState();
    dispatch(queryReviews(1, userId, reviewTypeFilter));
};
export const toggleTranslation = (userId) => (dispatch, getState) => {
    const state = getState();
    const { ProfilePage: { reviewTypeFilter }, } = getState();
    const translationActive = !state.ProfilePage.translationActive;
    dispatch(setTranslationActive(translationActive));
    dispatch(queryUser(userId));
    dispatch(queryReviews(1, userId, reviewTypeFilter));
};
export const queryUser = (userId) => (dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
    dispatch(queryUserRequest(userId));
    const state = getState();
    const apiLocale = apiLocaleSelector(state, !state.ProfilePage.translationActive);
    const locale = apiLocale ? { locale: apiLocale } : {};
    try {
        const response = yield showUser(Object.assign(Object.assign({ id: userId }, locale), { include: ['profileImage'], 'fields.image': ['variants.square-small', 'variants.square-small2x'] }));
        dispatch(addMarketplaceEntities(response));
        const paramsHasCustomerReviews = {
            subjectId: response.data.data.id,
            type: 'ofCustomer',
            limit: 1,
        };
        const hasCustomerReviewsResponse = yield apiQueryReviews(paramsHasCustomerReviews);
        const hasCustomerReviews = 0 < hasCustomerReviewsResponse.data.totalItems;
        const paramsHasProviderReviews = {
            subjectId: response.data.data.id,
            type: 'ofProvider',
            limit: 1,
        };
        const hasProviderReviewsResponse = yield apiQueryReviews(paramsHasProviderReviews);
        const hasProviderReviews = 0 < hasProviderReviewsResponse.data.totalItems;
        dispatch(queryUserReviewTypes(hasCustomerReviews, hasProviderReviews));
        dispatch(queryUserSuccess());
        return response;
    }
    catch (error) {
        return dispatch(queryUserError(storableError(error)));
    }
});
export const loadData = ({ id }) => (dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
    // Clear state so that previously loaded data is not visible
    // in case this page load fails.
    dispatch(setInitialState());
    const userId = new UUID(id);
    dispatch(setTranslationActive(getInitialPageTranslationActiveSelector(getState())));
    const { ProfilePage: { reviewTypeFilter }, } = getState();
    yield Promise.all([
        dispatch(fetchCurrentUser()),
        dispatch(queryUser(userId)),
        dispatch(queryUserListings(userId)),
        dispatch(queryReviews(1, userId, reviewTypeFilter)),
    ]);
    return Promise.all([dispatch(queryUserReviews(userId))]);
});
