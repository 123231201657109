import React from 'react';
import classNames from 'classnames';
import AddressLinkMaybe from './AddressLinkMaybe';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import ToggleTranslationButton from '../../styled-components/ToggleTranslationButton/ToggleTranslationButton.tsx';
import TranslatedBy from '../../styled-components/TranslatedBy/TranslatedBy.tsx';

import css from './TransactionPanel.module.scss';
import gridCss from '../../bootstrapGrid.module.scss';

import { ReactComponent as IconLock } from '../../assets/svg/lock.svg';

// Functional component as a helper to build detail card headings
const DetailCardHeadingsMaybeComponent = props => {
  const {
    showDetailCardHeadings,
    listingAfterBookingInfo,
    listingAfterBookingPhone,
    listingAfterBookingRoute,
    location,
    geolocation,
    rootClassName,
    onToggleTranslation,
    translationActive,
    translatedBy,
    originalLocale,
    translatable,
    translationLoading,
  } = props;

  const listingAfterBookingInfoSplit = listingAfterBookingInfo
    ? listingAfterBookingInfo.split(/\r?\n/)
    : [listingAfterBookingInfo];

  const showTranslatedByLabel =
    translationActive && originalLocale && translatedBy;

  return showDetailCardHeadings ? (
    <div
      className={classNames(rootClassName, css.detailCardInfo)}
      title="detailCardInfo"
      id="detailCardInfo"
    >
      <div className={gridCss.gridContainer}>
        <div className={gridCss.row}>
          <div className={gridCss.col12}>
            <h2 className={css.detailCardTitle}>
              <span className={css.detailCardInfo__lockIcon}>
                <IconLock />
              </span>
              <FormattedMessage id="TransactionPanel.detailCardInfoTitle" />
            </h2>
            <h4 className={css.detailCardInfo__subtitle}>
              <FormattedMessage id="TransactionPanel.afterTheBooking" />
            </h4>
            <div className={gridCss.row}>
              <div
                className={classNames(
                  gridCss.col12,
                  gridCss.colSmartphone4,
                  css.detailCardInfo__columnHeadings
                )}
              >
                <FormattedMessage id="TransactionPanel.detailCardInfoAddress" />
              </div>
              <div
                className={classNames(
                  gridCss.col12,
                  gridCss.colSmartphone8,
                  css.detailCardInfo__columnValues
                )}
              >
                <AddressLinkMaybe
                  rootClassName={css.detailCardInfo__addressLink}
                  location={location}
                  geolocation={geolocation}
                  showAddress={showDetailCardHeadings}
                />
              </div>
            </div>
            {listingAfterBookingPhone && (
              <div className={gridCss.row}>
                <div
                  className={classNames(
                    gridCss.col12,
                    gridCss.colSmartphone4,
                    css.detailCardInfo__columnHeadings
                  )}
                >
                  <FormattedMessage id="TransactionPanel.detailCardInfoPhone" />
                </div>
                <a
                  className={classNames(
                    gridCss.col12,
                    gridCss.colSmartphone8,
                    css.detailCardInfo__columnValues,
                    css.detailCardInfo__phoneNumber
                  )}
                  href={`tel://${listingAfterBookingPhone.number}`}
                >
                  {listingAfterBookingPhone.formattedValue}
                </a>
              </div>
            )}
            {listingAfterBookingRoute && (
              <div className={gridCss.row}>
                <div
                  className={classNames(
                    gridCss.col12,
                    gridCss.colSmartphone4,
                    css.detailCardInfo__columnHeadings
                  )}
                >
                  <FormattedMessage id="TransactionPanel.detailCardInfoRoute" />
                </div>
                <div
                  className={classNames(
                    gridCss.col12,
                    gridCss.colSmartphone8,
                    css.detailCardInfo__columnValues
                  )}
                >
                  {listingAfterBookingRoute}
                </div>
              </div>
            )}
            <div className={gridCss.row}>
              <div
                className={classNames(
                  gridCss.col12,
                  gridCss.colSmartphone4,
                  css.detailCardInfo__columnHeadings
                )}
              >
                <FormattedMessage id="TransactionPanel.detailCardInfoText" />
              </div>
              <div
                className={classNames(
                  gridCss.col12,
                  gridCss.colSmartphone8,
                  css.detailCardInfo__columnValues
                )}
              >
                {listingAfterBookingInfoSplit.map((paragraph, index) => {
                  return (
                    <div className={css.afterBookingInfoParagraph} key={index}>
                      {paragraph}
                    </div>
                  );
                })}
              </div>
              <div
                className={classNames(
                  gridCss.col12,
                  gridCss.colSmartphone4,
                  css.detailCardInfo__columnHeadings
                )}
              ></div>
              <div
                className={classNames(
                  gridCss.col12,
                  gridCss.colSmartphone8,
                  css.detailCardInfo__columnValues
                )}
              >
                <div>
                  {originalLocale && translatable && (
                    <ToggleTranslationButton
                      active={translationActive}
                      onClick={onToggleTranslation}
                      loading={translationLoading}
                    />
                  )}
                  {showTranslatedByLabel && (
                    <TranslatedBy
                      translatedBy={translatedBy}
                      originalLocale={originalLocale}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

const DetailCardHeadingsMaybe = injectIntl(DetailCardHeadingsMaybeComponent);

export default DetailCardHeadingsMaybe;
