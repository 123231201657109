import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import { useIntl } from 'react-intl';
import PageConfig from '@nomady/shared/routeConfiguration/Page';
import { getDeeplLocale } from '@nomady/shared/marketplace-custom-config';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { NotFoundPage } from '..';
import { changeReviewsPage, setReviewTypeFilter, toggleTranslation, } from './ProfilePage.duck';
import config from '../../config';
import ListingSlider from '../../styled-components/ListingSlider/ListingSlider';
import Layout, { LayoutRow, LayoutRowBottomVariant, LayoutRowHorizontalVariant, } from '../../styled-components/Layout/Layout';
import Avatar, { SizeVariant } from '../../styled-components/Avatar/Avatar';
import Text, { TextVariant } from '../../styled-components/Text/Text';
import DefaultProfileBanner from '../../assets/default-profile-banner.webp';
import Button, { ButtonOutlineVariant, ButtonColorVariant, ButtonSizeVariant, } from '../../styled-components/Button/Button';
import SectionContentSlider from '../../styled-components/SectionContentSlider/SectionContentSlider';
import { ColorVariant } from '../../theme';
import { IconVariant } from '../../styled-components/Icon/Icon';
import HorizontalLine from '../../styled-components/HorizontalLine/HorizontalLine';
import ReviewList from '../../styled-components/Review/ReviewList';
import PaginationControl from '../../styled-components/PaginationControl/PaginationControl';
import SkeletonLoader, { SkeletonLoaderVariant, } from '../../styled-components/SkeletonLoader/SkeletonLoader';
import ContentPageContainer from '../../styled-components/PageContainer/ContentPageContainer';
import ToggleTranslationButton from '../../styled-components/ToggleTranslationButton/ToggleTranslationButton';
import { localeSelector } from '../../slices/UISlice';
import TranslatedBy from '../../styled-components/TranslatedBy/TranslatedBy';
import UsersLanguageSkills from '../../styled-components/UsersLanguageSkills/UsersLanguageSkills';
import { currentUserSelector } from '../../ducks/user.duck';
const Hero = styled.div `
  height: 240px;
  background-image: url(${DefaultProfileBanner});

  background-size: cover;
  background-position: center;

  @media ${props => props.theme.device.tablet} {
    height: 350px;
  }
`;
const ProfileHeader = styled.div `
  display: flex;
  position: relative;
  top: -80px;
  flex-wrap: wrap;

  @media ${props => props.theme.device.tablet} {
    top: -45px;
    margin-bottom: 50px;
  }

  @media ${props => props.theme.device.desktop} {
    flex-wrap: inherit;
  }
`;
const AvatarWrapper = styled.div `
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;

  @media ${props => props.theme.device.tablet} {
    width: 160px;
  }

  @media ${props => props.theme.device.desktop} {
    flex-wrap: inherit;
  }
`;
const DescriptionHead = styled.div `
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;
const LargerThanTabletWrapper = styled.div `
  display: none;

  @media ${props => props.theme.device.tablet} {
    display: inherit;
  }
`;
const ButtonMobileWrapper = styled.div `
  padding-top: 30px;

  @media ${props => props.theme.device.tablet} {
    display: none;
  }
`;
const Description = styled.div `
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 50px;

  @media ${props => props.theme.device.tablet} {
    margin-top: 60px;
  }

  @media ${props => props.theme.device.desktop} {
    margin-top: 65px;
    padding-left: 30px;
  }
`;
const ReviewWrapper = styled.div `
  margin-top: 15px;
`;
const PaginationWrapper = styled.div `
  margin-top: 80px;
  display: flex;
  justify-content: center;
`;
const Username = styled(Text) `
  margin-bottom: 4px;
`;
const UserType = styled(Text) `
  margin-bottom: 75px;
  color: ${props => props.theme.color.stone};
`;
const ProfileActions = styled.div `
  display: grid;
  grid-template-rows: 100%;
  grid-template-columns: auto auto;
  grid-column-gap: 10px;
`;
const TranslatedByWrapper = styled.div `
  margin-top: 20px;
`;
const ProfilePage = () => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
    const intl = useIntl();
    const dispatch = useDispatch();
    const onChangeReviewPage = (userId) => (page) => dispatch(changeReviewsPage(userId, page));
    const onSetReviewTypeFilter = (reviewTypeFilter) => dispatch(setReviewTypeFilter(reviewTypeFilter));
    const onToggleProfileLocale = (userId) => dispatch(toggleTranslation(userId));
    const { userId, userLoading, queryUserError, userListingRefs, reviews, reviewsLoading, reviewsPage, reviewsTotalPages, hasProviderReviews, hasCustomerReviews, reviewTypeFilter, totalReviews, translationActive, } = useSelector((state) => state.ProfilePage);
    const userLocale = useSelector(localeSelector);
    const currentUser = useSelector(currentUserSelector);
    const state = useSelector((state) => state);
    const userMatches = getMarketplaceEntities(state, [{ type: 'user', id: userId }]);
    const profileUser = userMatches.length === 1 ? userMatches[0] : null;
    const listings = getMarketplaceEntities(state, userListingRefs);
    const isCurrentUser = ((_a = currentUser === null || currentUser === void 0 ? void 0 : currentUser.id) === null || _a === void 0 ? void 0 : _a.uuid) === (userId === null || userId === void 0 ? void 0 : userId.uuid);
    if (!profileUser) {
        return null;
    }
    if (queryUserError) {
        return _jsx(NotFoundPage, {});
    }
    const { attributes: { profile: { displayName }, }, } = profileUser;
    const hasListings = 0 < listings.length;
    const schemaTitle = intl.formatMessage({
        id: 'ProfilePage.schemaTitle',
    }, {
        name: displayName,
        siteTitle: config.siteTitle,
    });
    const isProvider = hasListings || hasProviderReviews;
    const isCustomer = hasCustomerReviews;
    let profileTypeLabel;
    if (isProvider && !isCustomer) {
        profileTypeLabel = intl.formatMessage({
            id: 'ProfilePage.profileTypeProvider',
        });
    }
    else if (!isProvider && isCustomer) {
        profileTypeLabel = intl.formatMessage({
            id: 'ProfilePage.profileTypeCustomer',
        });
    }
    else if (isProvider && isCustomer) {
        profileTypeLabel = intl.formatMessage({
            id: 'ProfilePage.profileTypeProviderAndCustomer',
        });
    }
    const { originalLocale, translatedBy, languageSkills } = ((_c = (_b = profileUser.attributes) === null || _b === void 0 ? void 0 : _b.profile) === null || _c === void 0 ? void 0 : _c.publicData) || {};
    const translatable = originalLocale && originalLocale !== getDeeplLocale(userLocale);
    const profileActions = (_jsxs(ProfileActions, { children: [originalLocale && translatable && (_jsx(ToggleTranslationButton, { active: translationActive, onClick: () => onToggleProfileLocale(profileUser.id) })), isCurrentUser && (_jsx(Button, { label: intl.formatMessage({
                    id: 'ProfilePage.editProfile',
                }), outlineVariant: ButtonOutlineVariant.Outlined, colorVariant: ButtonColorVariant.Transparent, sizeVariant: ButtonSizeVariant.ExtraSmall, icon: IconVariant.Pen, targetRoute: PageConfig.ProfileSettings }))] }));
    return (_jsx(ContentPageContainer, Object.assign({ title: schemaTitle, noIndex: true }, { children: _jsxs(Layout, { children: [_jsx(Hero, {}), _jsx(LayoutRow, Object.assign({ bottomVariant: LayoutRowBottomVariant.NoMargin }, { children: _jsxs(ProfileHeader, { children: [_jsx(AvatarWrapper, { children: _jsx(Avatar, { source: (_d = profileUser.profileImage) === null || _d === void 0 ? void 0 : _d.attributes.variants['square-small2x'].url, sizeVariant: SizeVariant.XL, isLoading: userLoading }) }), _jsxs(Description, { children: [userLoading && (_jsx(SkeletonLoader, { variant: SkeletonLoaderVariant.ProfileDescription })), !userLoading && (_jsxs(_Fragment, { children: [_jsxs(DescriptionHead, { children: [_jsxs("div", { children: [_jsx(Username, Object.assign({ variant: TextVariant.H4, as: "h1" }, { children: profileUser.attributes.profile.displayName ||
                                                                    intl.formatMessage({
                                                                        id: 'ProfilePage.userNotFound',
                                                                    }) })), _jsx(UserType, Object.assign({ variant: TextVariant.H11B }, { children: profileTypeLabel }))] }), _jsx(LargerThanTabletWrapper, { children: profileActions })] }), languageSkills && 0 < languageSkills.length && (_jsx(UsersLanguageSkills, { languages: languageSkills })), ((_h = (_g = (_f = (_e = profileUser === null || profileUser === void 0 ? void 0 : profileUser.attributes) === null || _e === void 0 ? void 0 : _e.profile) === null || _f === void 0 ? void 0 : _f.publicData) === null || _g === void 0 ? void 0 : _g.localizedPublicData) === null || _h === void 0 ? void 0 : _h.biography) && (_jsx(Text, Object.assign({ variant: TextVariant.H9, as: "p" }, { children: profileUser.attributes.profile.publicData
                                                    .localizedPublicData.biography }))), translationActive && originalLocale && translatedBy && (_jsx(TranslatedByWrapper, { children: _jsx(TranslatedBy, { translatedBy: translatedBy, originalLocale: originalLocale }) })), _jsx(ButtonMobileWrapper, { children: profileActions })] }))] })] }) })), (hasListings || userLoading) && (_jsx(LayoutRow, Object.assign({ horizontalVariant: LayoutRowHorizontalVariant.ContainedLeft, bottomVariant: LayoutRowBottomVariant.SmallMargin }, { children: _jsx(SectionContentSlider, Object.assign({ title: intl.formatMessage({ id: 'ProfilePage.listingTitle' }), titleTextVariant: TextVariant.H7, colorVariant: ColorVariant.Stone, buttonIconVariant: IconVariant.ArrowRight }, { children: _jsx(ListingSlider, { listings: listings }) })) }))), _jsxs(LayoutRow, { children: [_jsx(HorizontalLine, {}), _jsxs(ReviewWrapper, { children: [_jsx(ReviewList, { locale: userLocale, reviews: reviews, totalReviews: totalReviews, averageRating: ((_l = (_k = (_j = profileUser.attributes) === null || _j === void 0 ? void 0 : _j.profile) === null || _k === void 0 ? void 0 : _k.publicData) === null || _l === void 0 ? void 0 : _l.rating) || 0, isLoading: reviewsLoading, showReviewFilter: hasProviderReviews && hasCustomerReviews, reviewTypeFilter: reviewTypeFilter, onSetReviewTypeFilter: onSetReviewTypeFilter }), 1 < reviewsTotalPages && (_jsx(PaginationWrapper, { children: _jsx(PaginationControl, { totalPages: reviewsTotalPages, activePage: reviewsPage, onChangePage: page => onChangeReviewPage(profileUser.id)(page) }) }))] })] })] }) })));
};
export default ProfilePage;
