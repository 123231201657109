import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import createReducer from './reducers';
const getStore = (sdk, preloadedState, additionalMiddlewares = []) => configureStore({
    reducer: createReducer(),
    preloadedState,
    middleware: [
        thunk.withExtraArgument(sdk),
        ...additionalMiddlewares,
    ],
});
export default getStore;
