import * as z from 'zod';
const UuidSchema = z.object({
    _sdkType: z.literal('UUID'),
    uuid: z.string().uuid(),
});
const PhotoVariantSchema = z.object({
    height: z.number(),
    width: z.number(),
    url: z.string(),
    name: z.string(),
});
export const PhotoSchema = z.object({
    id: UuidSchema,
    type: z.literal('image'),
    attributes: z.object({
        variants: z.record(PhotoVariantSchema),
        description: z.string().nullish(),
    }),
});
