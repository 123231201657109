import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Controller, } from 'react-hook-form';
import styled from 'styled-components/macro';
import { ColorVariant } from '../../theme';
import Icon from '../Icon/Icon';
import InputErrorMessage from '../InputErrorMessage/InputErrorMessage';
import Spinner from '../Spinner/Spinner';
import { TextVariant } from '../Text/Text';
import { Wrapper, Label, ErrorMessageWrapper, FakeInput, SpinnerWrapper, Description, } from '../Input/Input';
import RadioInput from '../RadioInput/RadioInput';
const Element = styled.div `
  margin-top: 8px;
`;
const StyledFakeInput = styled(FakeInput) `
  display: flex;
  justify-content: space-between;
  padding: 0 16px;
`;
const InputRadioSelect = ({ id, label, description, variant, error, loading, options, control, rules, name, disabled, }) => {
    const hasError = !!error;
    const errorMessageId = hasError ? `${id}-errormessage` : '';
    const errorMessageAriaInputProps = hasError
        ? { 'aria-invalid': true, 'aria-errormessage': errorMessageId }
        : {};
    return (_jsx(Controller, { render: ({ field }) => {
            const selectedValues = field.value || [];
            return (_jsxs(Wrapper, { children: [label && (_jsx(Label, Object.assign({ inputVariant: variant, variant: TextVariant.H13, as: "label", htmlFor: id, hasError: hasError }, { children: label }))), options.map(({ value, label, icon }) => (_jsx(Element, { children: _jsxs(StyledFakeInput, Object.assign({ variant: variant, hasError: hasError, hasIcon: !!icon, disabled: disabled }, errorMessageAriaInputProps, { children: [_jsx(RadioInput, { id: value, label: label, checked: selectedValues.includes(value), onChange: () => field.onChange(value), disabled: disabled }), icon && (_jsxs("label", Object.assign({ htmlFor: id, "aria-hidden": true }, { children: [!loading && _jsx(Icon, { variant: icon }), loading && (_jsx(SpinnerWrapper, { children: _jsx(Spinner, { colorVariant: !disabled ? ColorVariant.Cave : ColorVariant.Stone }) }))] })))] })) }, `${id}-${value}`))), hasError && (_jsx(ErrorMessageWrapper, Object.assign({ role: "alert" }, { children: _jsx(InputErrorMessage, { id: errorMessageId, error: error }) }))), description && _jsx(Description, { text: description })] }));
        }, name: name, control: control, rules: rules }));
};
export default InputRadioSelect;
