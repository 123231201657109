import { LINE_ITEM_ADULTS, LINE_ITEM_ADULT_TAXES, LINE_ITEM_CHILDREN, LINE_ITEM_CHILD_TAXES, LINE_ITEM_CLEANING_FEE, LINE_ITEM_EXTRAS_FIREWOOD, LINE_ITEM_EXTRAS_POWER_SUPPLY, LINE_ITEM_SEATS, LINE_ITEM_TEENS, LINE_ITEM_TEEN_TAXES, } from './lineItemTypes';
const getUnitPriceByLineItem = (lineItems, lineItemCode) => {
    var _a, _b;
    console.table(lineItems);
    return (((_b = (_a = lineItems.find(lineItem => lineItem.code === lineItemCode)) === null || _a === void 0 ? void 0 : _a.unitPrice) === null || _b === void 0 ? void 0 : _b.amount) || undefined);
};
export const getListingPricingFromTransaction = (transaction) => {
    const { lineItems } = transaction.attributes;
    const cleaningFee = getUnitPriceByLineItem(lineItems, LINE_ITEM_CLEANING_FEE);
    const seat = getUnitPriceByLineItem(lineItems, LINE_ITEM_SEATS);
    const adult = getUnitPriceByLineItem(lineItems, LINE_ITEM_ADULTS) || 0;
    const teen = getUnitPriceByLineItem(lineItems, LINE_ITEM_TEENS) || 0;
    const child = getUnitPriceByLineItem(lineItems, LINE_ITEM_CHILDREN) || 0;
    const firewood = getUnitPriceByLineItem(lineItems, LINE_ITEM_EXTRAS_FIREWOOD);
    const powerSupply = getUnitPriceByLineItem(lineItems, LINE_ITEM_EXTRAS_POWER_SUPPLY);
    const visitorsTaxAdult = getUnitPriceByLineItem(lineItems, LINE_ITEM_ADULT_TAXES);
    const visitorsTaxTeen = getUnitPriceByLineItem(lineItems, LINE_ITEM_TEEN_TAXES);
    const visitorsTaxChild = getUnitPriceByLineItem(lineItems, LINE_ITEM_CHILD_TAXES);
    const listingPricing = {
        cleaningFee,
        seat,
        adult,
        teen,
        child,
        firewood,
        powerSupply,
        visitorsTax: {
            adult: visitorsTaxAdult,
            teen: visitorsTaxTeen,
            child: visitorsTaxChild,
        },
    };
    return listingPricing;
};
