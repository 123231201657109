import styled from 'styled-components/macro';
export const AnimationWrapper = styled.div `
  opacity: 0;
  ${props => props.show &&
    `
  opacity: 1;
`};
`;
export const AnimationBackground = styled.div `
  background-color: ${props => props.theme.color.pearl};
  padding: 0px 4px;
  border-radius: 50px;
  display: flex;
  height: ${props => props.height}px;
  align-self: center;
`;
