import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { useIntl } from 'react-intl';
import { nightsBetween } from '@nomady/shared/utils/dates';
import PriceInformationBox from '../../../styled-components/PriceInformationBox/PriceInformationBox';
import Button, { ButtonColorVariant, } from '../../../styled-components/Button/Button';
import ErrorNotification from '../../../styled-components/ErrorNotification/ErrorNotification';
import SecureCheckoutTag from '../../../styled-components/SecureCheckoutTag/SecureCheckoutTag';
import LegalAgreement from '../../../styled-components/LegalAgreement/LegalAgreement';
const Wrapper = styled.div ``;
const CheckoutInfo = ({ listing, bookingStart, bookingEnd, seatQuantity, adultQuantity, childQuantity, teenQuantity, infantQuantity, dogQuantity, giftCards, customerCommissionDiscountCode, onPayNow, paymentInProgress, error, extras, }) => {
    const [breakdownOpen, setBreakdownOpen] = useState(false);
    const intl = useIntl();
    const { instantBookingAvailable } = listing.attributes.publicData;
    const onPayButtonLabelId = instantBookingAvailable
        ? 'CheckoutSection.buttonLabelInstant'
        : 'CheckoutSection.buttonLabelRequest';
    return (_jsxs(Wrapper, { children: [_jsx(PriceInformationBox, { listing: listing, giftCards: giftCards, customerCommissionDiscountCode: customerCommissionDiscountCode, breakdownOpen: breakdownOpen, setBreakdownOpen: setBreakdownOpen, bookingStart: bookingStart, bookingEnd: bookingEnd, seatQuantity: seatQuantity, adultQuantity: adultQuantity, childQuantity: childQuantity, teenQuantity: teenQuantity, infantQuantity: infantQuantity, dogQuantity: dogQuantity, nightsQuantity: nightsBetween(bookingStart, bookingEnd), extras: extras, role: "customer" }), _jsx(LegalAgreement, { agreeButtonLabelId: onPayButtonLabelId, showNomadyPact: true }), error && (_jsx(ErrorNotification, { message: error.message, "data-testid": error.code })), _jsx(Button, { label: intl.formatMessage({
                    id: onPayButtonLabelId,
                }), onClick: onPayNow, type: "button", inProgress: paymentInProgress, colorVariant: ButtonColorVariant.Forest, dataTestId: "CheckoutInfo_payButton" }), _jsx(SecureCheckoutTag, {})] }));
};
export default CheckoutInfo;
