import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components/macro';
import Text, { TextVariant } from '../Text/Text';
import { ColorVariant } from '../../theme';
import Review from './Review';
import ReviewRating from './ReviewRating';
import HorizontalLine from '../HorizontalLine/HorizontalLine';
import SkeletonLoader, { SkeletonLoaderVariant, } from '../SkeletonLoader/SkeletonLoader';
import FilterDropdown from '../FilterDropdown/FilterDropdown';
const Wrapper = styled.section `
  width: 100%;
`;
const Header = styled.div `
  display: flex;
  flex-wrap: wrap;

  @media ${props => props.theme.device.tablet} {
    justify-content: space-between;
  }
`;
const ReviewTitle = styled.div `
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-basis: 100%;

  @media ${props => props.theme.device.tablet} {
    flex-basis: initial;
  }
`;
const AverageRating = styled.div `
  margin-left: 40px;
`;
const ReviewsWrapper = styled.div `
  margin-top: 45px;

  @media ${props => props.theme.device.desktop} {
    margin-left: 100px;
    margin-right: 100px;
  }
`;
const ReviewWrapper = styled.div `
  max-width: 100%;
`;
const ReviewList = ({ reviews, isLoading, totalReviews, averageRating, reviewTypeFilter, onSetReviewTypeFilter, showReviewFilter = false, locale, }) => {
    const filters = [
        {
            label: `ReviewFilterTypeTabs.ofCustomer`,
            key: 'ofCustomer',
        },
        {
            label: `ReviewFilterTypeTabs.ofProvider`,
            key: 'ofProvider',
        },
    ];
    return (_jsxs(Wrapper, { children: [_jsxs(Header, { children: [_jsxs(ReviewTitle, { children: [_jsx(Text, Object.assign({ variant: TextVariant.H14, colorVariant: ColorVariant.Stone }, { children: _jsxs("div", { children: [_jsx(FormattedMessage, { id: "ReviewList.reviewTitle" }), ' ', !isLoading && `• ${totalReviews}`] }) })), !isLoading && averageRating !== 0 && (_jsx(AverageRating, { children: _jsx(ReviewRating, { rating: averageRating }) }))] }), showReviewFilter && (_jsx(FilterDropdown, { filters: filters, filterSelection: reviewTypeFilter, onChange: onSetReviewTypeFilter }))] }), _jsx(ReviewsWrapper, { children: isLoading ? (_jsx(_Fragment, { children: _jsx(ReviewWrapper, { children: _jsx(SkeletonLoader, { variant: SkeletonLoaderVariant.Review }) }) })) : (reviews.map(review => (_jsxs(ReviewWrapper, { children: [_jsx(Review, { review: review, locale: locale }), _jsx(HorizontalLine, { colorVariant: ColorVariant.Pearl })] }, review.id.uuid)))) })] }));
};
export default ReviewList;
