import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styled from 'styled-components/macro';
import { LISTING_STATE_DRAFT } from '@nomady/shared/utils/types';
import { FormattedMessage } from '../../util/reactIntl';
import { ListingLink } from '..';
import EditListingDescriptionForm from '../../forms/EditListingDescriptionForm/EditListingDescriptionForm';
export const Title = styled.h2 `
  ${props => props.theme.typeset.H3};

  margin-top: 0;
  margin-bottom: 20px;
`;
const EditListingDescriptionPanel = props => {
    var _a, _b;
    const { listing, onSubmit, onFormDirty, submitButtonText, updateInProgress, errors, } = props;
    const title = (_a = listing === null || listing === void 0 ? void 0 : listing.attributes) === null || _a === void 0 ? void 0 : _a.title;
    const isDraft = !((listing === null || listing === void 0 ? void 0 : listing.id) &&
        title &&
        ((_b = listing === null || listing === void 0 ? void 0 : listing.attributes) === null || _b === void 0 ? void 0 : _b.state) !== LISTING_STATE_DRAFT);
    const panelTitle = isDraft ? (_jsx(FormattedMessage, { id: "EditListingDescriptionPanel.createListingTitle" })) : (_jsx(FormattedMessage, { id: "EditListingDescriptionPanel.title", values: { listingTitle: _jsx(ListingLink, { listing: listing }) } }));
    return (_jsxs(_Fragment, { children: [_jsx(Title, { children: panelTitle }), _jsx(EditListingDescriptionForm, { listing: listing, submitButtonLabel: submitButtonText, updateListingInProgress: updateInProgress, updateListingError: errors.updateListingError, onSubmit: onSubmit, onFormDirty: onFormDirty })] }));
};
export default EditListingDescriptionPanel;
