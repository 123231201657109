import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styled from 'styled-components/macro';
import { ColorVariant } from '../../theme';
import CheckboxInput, { CheckboxInputSpacingVariant, } from '../CheckboxInput/CheckboxInput';
import CheckboxInputGroup from '../CheckboxInputGroup/CheckboxInputGroup';
import Text, { TextVariant } from '../Text/Text';
const Wrapper = styled.div `
  margin-left: 12px;
  margin-right: 12px;
`;
const Label = styled(Text) `
  display: block;
  margin-bottom: 18px;
`;
const CheckboxInputSection = ({ label, checkboxes }) => {
    return (_jsxs(Wrapper, { children: [label && (_jsx(Label, Object.assign({ variant: TextVariant.H5SB, colorVariant: ColorVariant.Stone }, { children: label }))), checkboxes.map(checkbox => checkbox.checkboxes ? (_jsx(CheckboxInputGroup, Object.assign({}, checkbox), checkbox.id)) : (_jsx(CheckboxInput, Object.assign({}, checkbox, { spacingVariant: CheckboxInputSpacingVariant.Default, onChange: event => checkbox.onChange &&
                    checkbox.onChange(event.target.checked, checkbox.id) }), checkbox.id)))] }));
};
export default CheckboxInputSection;
