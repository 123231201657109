var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { denormalisedResponseEntities } from '@nomady/shared/utils/data';
import { showCurrentUser } from './api';
const queryCurrentUserEmailVerification = () => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    const response = yield showCurrentUser({});
    const entities = denormalisedResponseEntities(response);
    const updatedUser = entities === null || entities === void 0 ? void 0 : entities[0];
    return (_a = updatedUser === null || updatedUser === void 0 ? void 0 : updatedUser.attributes) === null || _a === void 0 ? void 0 : _a.emailVerified;
});
export default queryCurrentUserEmailVerification;
