var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';
import Page from '@nomady/shared/routeConfiguration/Page';
import routeConfiguration from '@nomady/shared/routeConfiguration/routeConfiguration';
import { types as sdkTypes } from '@nomady/shared/utils/sdkLoader';
import { createResourceLocatorString } from '@nomady/shared/utils/routes';
import { isBrowser } from '@nomady/shared/utils/env';
import Hero from '../../styled-components/Hero/Hero';
import LayoutSideNavigation from '../../styled-components/LayoutSideNavigation/LayoutSideNavigation';
import Lead from '../../styled-components/Lead/Lead';
import Text, { TextVariant } from '../../styled-components/Text/Text';
import CmsContent from '../../styled-components/CmsContent/CmsContent';
import Layout from '../../styled-components/Layout/Layout';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { SectionType } from './ICmsContent';
import AddToFavoritesModal from '../../styled-components/AddToFavoritesModal';
import { setLastOpenModal, ModalId } from '../../slices/UISlice';
import { getSeoSocialMediaImage } from '../../util/imageHelper';
import ContentPageContainer from '../../styled-components/PageContainer/ContentPageContainer';
import { CmsContentPageType } from './CmsContentPageSlice';
import getQueryString from '../../util/search-query/getQueryString';
import AppIntro from '../../styled-components/AppIntro/AppIntro';
import { Preferences } from '@capacitor/preferences';
import { isApp } from '../../util/deviceContext';
const { UUID } = sdkTypes;
const MainTitle = styled(Text) `
  margin-top: 0;
  margin-bottom: 40px;
`;
const CmsContentPage = ({ tabs, }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
    const { cmsContent, blogArticles, countryConfig, featuredListingsRefs, listingRefs, reviewRefs, pageType, } = useSelector((state) => state.CmsContentPage);
    const { lastOpenModal } = useSelector((state) => state.UI);
    const { isAuthenticated } = useSelector((state) => state.Auth);
    const state = useSelector((rootState) => rootState);
    const dispatch = useDispatch();
    const intl = useIntl();
    let favoriteBottomSheetOpen = lastOpenModal && lastOpenModal.modalId === ModalId.AddToFavorites;
    const setFavoriteBottomSheetOpen = (newValue) => {
        favoriteBottomSheetOpen = newValue;
    };
    const setLastOpenModalDisp = (setLastOpenModalPayload) => dispatch(setLastOpenModal(setLastOpenModalPayload));
    const handleOnCloseFavoriteBottomSheet = () => {
        setFavoriteBottomSheetOpen(false);
        setLastOpenModalDisp(undefined);
    };
    const [scrollingPosition, setScrollingPosition] = useState(0);
    const onScrollContent = (event) => {
        setScrollingPosition(event);
    };
    const allListingsOnPage = 1 <= listingRefs.length + featuredListingsRefs.length
        ? getMarketplaceEntities(state, [...listingRefs, ...featuredListingsRefs])
        : [];
    const allReviewsOnPage = 1 <= reviewRefs.length ? getMarketplaceEntities(state, reviewRefs) : [];
    const { schemaTitle, schemaDescription, schemaImage, heroBackgroundImage, heroText, mainTitle, lead, leadVariant, sections, seo, pronoun, name, slug, showSearchBar, } = cmsContent;
    const hasHero = !!((heroBackgroundImage === null || heroBackgroundImage === void 0 ? void 0 : heroBackgroundImage.data) && heroText);
    const sideNavigationLayout = 0 < (tabs === null || tabs === void 0 ? void 0 : tabs.length);
    const hasListingSearchHero = !!sections.find((section) => 
    // eslint-disable-next-line no-underscore-dangle
    section.__component === SectionType.LandingHeroSection);
    const getAllRegionalListingLink = () => {
        if (!isBrowser() || !(window === null || window === void 0 ? void 0 : window.google)) {
            return '';
        }
        const searchQuery = { location: { region: slug } };
        const queryString = getQueryString(searchQuery);
        const target = createResourceLocatorString(Page.Search, routeConfiguration, {}, queryString);
        return target;
    };
    const nameWithPronoun = `${pronoun} ${name}`;
    const enhancedSections = sections.map(section => {
        if (
        // eslint-disable-next-line no-underscore-dangle
        (section.__component === SectionType.ListingSliderSection ||
            section.__component === SectionType.ListingGridSection) &&
            pageType === CmsContentPageType.Region) {
            return Object.assign(Object.assign({}, section), { buttonText: intl.formatMessage({
                    id: 'RegionPage.listingSliderCta',
                }, { nameWithPronoun }), title: intl.formatMessage({ id: 'RegionPage.listingSliderTitle' }, { nameWithPronoun }), listingIds: listingRefs.map(listing => ({
                    listingId: listing.id.uuid,
                })), buttonLink: getAllRegionalListingLink() });
        }
        if (
        // eslint-disable-next-line no-underscore-dangle
        section.__component === SectionType.ReviewSliderSection &&
            pageType === CmsContentPageType.Region) {
            return Object.assign(Object.assign({}, section), { title: intl.formatMessage({ id: 'RegionPage.reviewSliderTitle' }, { nameWithPronoun }), reviews: reviewRefs.map(review => ({
                    reviewId: review.id.uuid,
                })) });
        }
        // eslint-disable-next-line no-underscore-dangle
        if (section.__component === SectionType.RegionsSection) {
            return Object.assign(Object.assign({}, section), { countryConfig });
        }
        return section;
    });
    const layoutContent = (withSideNavigation) => (_jsxs(_Fragment, { children: [mainTitle && (_jsx(MainTitle, Object.assign({ variant: TextVariant.H1, as: "h1" }, { children: mainTitle }))), lead && !withSideNavigation && (_jsx(Lead, Object.assign({ colorVariant: leadVariant }, { children: lead }))), _jsx(CmsContent, { slug: slug, showSearchBar: showSearchBar !== false, sections: enhancedSections, withSideNavigation: withSideNavigation, allListingsOnPage: allListingsOnPage, allReviewsOnPage: allReviewsOnPage, scrollingPosition: scrollingPosition, blogArticles: blogArticles, countryConfig: countryConfig })] }));
    const staticPageContent = sideNavigationLayout ? (_jsx(_Fragment, { children: _jsx(LayoutSideNavigation, Object.assign({ tabs: tabs }, { children: layoutContent(true) })) })) : (_jsxs(_Fragment, { children: [hasHero && _jsx(Hero, { image: heroBackgroundImage, title: heroText }), _jsx(Layout, { children: layoutContent() })] }));
    const schema = [
        {
            '@context': 'https://schema.org',
            '@type': 'WebPage',
            description: (seo === null || seo === void 0 ? void 0 : seo.metaDescription) || schemaDescription,
            name: (seo === null || seo === void 0 ? void 0 : seo.metaTitle) || schemaTitle,
            image: [
                ((_c = (_b = (_a = seo === null || seo === void 0 ? void 0 : seo.metaImage) === null || _a === void 0 ? void 0 : _a.data.attributes.formats) === null || _b === void 0 ? void 0 : _b.large) === null || _c === void 0 ? void 0 : _c.url) || '',
                ((_f = (_e = (_d = seo === null || seo === void 0 ? void 0 : seo.metaImage) === null || _d === void 0 ? void 0 : _d.data.attributes.formats) === null || _e === void 0 ? void 0 : _e.medium) === null || _f === void 0 ? void 0 : _f.url) || '',
                ((_j = (_h = (_g = seo === null || seo === void 0 ? void 0 : seo.metaImage) === null || _g === void 0 ? void 0 : _g.data.attributes.formats) === null || _h === void 0 ? void 0 : _h.small) === null || _j === void 0 ? void 0 : _j.url) || '',
                ((_m = (_l = (_k = seo === null || seo === void 0 ? void 0 : seo.metaImage) === null || _k === void 0 ? void 0 : _k.data.attributes.formats) === null || _l === void 0 ? void 0 : _l.thumbnail) === null || _m === void 0 ? void 0 : _m.url) || '',
                schemaImage,
            ],
        },
    ];
    const faqSection = sections.find(
    // eslint-disable-next-line no-underscore-dangle
    section => section.__component === SectionType.FaqSection);
    if (faqSection) {
        const questionsAndAnswersSchema = (_p = (_o = faqSection.sections[0]) === null || _o === void 0 ? void 0 : _o.questions) === null || _p === void 0 ? void 0 : _p.map(question => {
            return {
                '@type': 'Question',
                name: question.title,
                acceptedAnswer: {
                    '@type': 'Answer',
                    text: question.text,
                },
            };
        });
        schema.push({
            '@context': 'https://schema.org',
            '@type': 'FAQPage',
            mainEntity: questionsAndAnswersSchema,
        });
    }
    const mergedSchema = Object.assign(Object.assign({}, schema), seo === null || seo === void 0 ? void 0 : seo.structuredData);
    const { pathname } = useLocation();
    const homePageUrlRegex = /^\/[a-z]{2}-[A-Z]{2}$/;
    const hideTopBar = hasListingSearchHero;
    const [completedAppIntro, setCompletedAppIntro] = useState(true);
    useEffect(() => {
        const fetchPreferences = () => __awaiter(void 0, void 0, void 0, function* () {
            // For AppIntro Development uncomment the following line to reset the completedAppIntro preference
            // await Preferences.remove({ key: 'completedAppIntro' });
            const result = yield Preferences.get({ key: 'completedAppIntro' });
            const didCompleteAppIntroAlready = result.value === 'true';
            setCompletedAppIntro(didCompleteAppIntroAlready);
        });
        if (isApp) {
            fetchPreferences();
        }
    }, []);
    if (!completedAppIntro) {
        return _jsx(AppIntro, { onClose: () => setCompletedAppIntro(true) });
    }
    return (_jsxs(ContentPageContainer, Object.assign({ title: (seo === null || seo === void 0 ? void 0 : seo.metaTitle) || schemaTitle, description: (seo === null || seo === void 0 ? void 0 : seo.metaDescription) || schemaDescription, schema: mergedSchema, hideTopBar: hideTopBar, onScrollContent: onScrollContent, metaRobots: seo === null || seo === void 0 ? void 0 : seo.metaRobots, facebookImages: getSeoSocialMediaImage(seo) || schemaImage, twitterImages: getSeoSocialMediaImage(seo) || schemaImage, disableScrollRestoration: !pathname.match(homePageUrlRegex), hideListingSearchBar: showSearchBar === false }, { children: [staticPageContent, lastOpenModal &&
                isAuthenticated &&
                lastOpenModal.modalId === ModalId.AddToFavorites &&
                lastOpenModal.metaData.uuid && (_jsx(AddToFavoritesModal, { open: favoriteBottomSheetOpen || false, onClose: handleOnCloseFavoriteBottomSheet, listingId: new UUID(lastOpenModal.metaData.uuid) }))] })));
};
export default CmsContentPage;
