import { PaymentMode } from '../config';
export const getCaptureMethod = (paymentMode, instantBookingAvailable) => {
    if (paymentMode === PaymentMode.Legacy) {
        return 'manual';
    }
    if (instantBookingAvailable) {
        return 'automatic';
    }
    return 'manual';
};
