import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import Button, { ButtonColorVariant, ButtonSizeVariant, } from '../../../styled-components/Button/Button';
import { useIntl } from 'react-intl';
import { IconVariant } from '../../../styled-components/Icon/Icon';
import IconButtonOld, { OldIconButtonColorVariant, OldIconButtonSizeVariant, } from '../../../styled-components/IconButtonOld/IconButtonOld';
import Text, { TextVariant } from '../../../styled-components/Text/Text';
import { ColorVariant } from '../../../theme';
import { useDispatch, useSelector } from 'react-redux';
import { isFetchingCalendarBookingsSelector, listingsSelector, selectListingByUUID, selectedListingUUIDSelector, } from '../CalendarSlice';
import FilterDropdown from '../../../styled-components/FilterDropdown/FilterDropdown';
import { types as sdkTypes } from '@nomady/shared/utils/sdkLoader';
import { ALL_LISTINGS_KEY } from '../constants';
import { useLocation } from 'react-router-dom';
const { UUID } = sdkTypes;
const Container = styled.div `
  margin-bottom: 40px;
  display: flex;
  gap: 8px;

  flex-direction: column;
  justify-content: stretch;

  @media ${props => props.theme.device.desktop} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;
const ButtonContainer = styled.div `
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;
const FilterDropdownWrapper = styled.div `
  width: 100%;
  display: flex;

  z-index: 5;

  @media ${props => props.theme.device.desktop} {
    width: 30%;
  }
`;
const DateTextWrapper = styled.div `
  text-align: center;
  width: 100%;

  @media ${props => props.theme.device.desktop} {
    width: 263px; // This is the max width the text will ever get (english and german)
  }
`;
export const CalendarComponentToolbar = ({ label, view, onView, onNavigate, }) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const listings = useSelector(listingsSelector);
    const selectedListingUUID = useSelector(selectedListingUUIDSelector);
    const isFetchingBookings = useSelector(isFetchingCalendarBookingsSelector);
    const [activeButton, setActiveButton] = useState(null);
    const allListingsOption = {
        key: ALL_LISTINGS_KEY,
        label: intl.formatMessage({ id: 'BookingCalendar.allListings' }),
    };
    const onChangeListing = (listingId) => {
        const uuid = listingId !== ALL_LISTINGS_KEY ? new UUID(listingId) : listingId;
        dispatch(selectListingByUUID(uuid));
    };
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const initialListingId = queryParams.get('listingId');
    useEffect(() => {
        if (initialListingId) {
            dispatch(selectListingByUUID(new UUID(initialListingId)));
        }
    }, [initialListingId]);
    const handleNavigate = (direction) => {
        setActiveButton(direction);
        onNavigate(direction);
    };
    useEffect(() => {
        if (!isFetchingBookings) {
            setActiveButton(null);
        }
    }, [isFetchingBookings]);
    return (_jsxs(Container, { children: [listings && (_jsx(FilterDropdownWrapper, { children: _jsx(FilterDropdown, { onChange: onChangeListing, filters: [
                        allListingsOption,
                        ...listings.map(listing => ({
                            key: listing.id.uuid,
                            label: listing.attributes.title,
                        })),
                    ], filterSelection: selectedListingUUID === ALL_LISTINGS_KEY
                        ? selectedListingUUID
                        : selectedListingUUID.uuid, sizeVariant: ButtonSizeVariant.Small }) })), _jsxs(ButtonContainer, { children: [_jsx(IconButtonOld, { colorVariant: OldIconButtonColorVariant.Transparent, iconVariant: IconVariant.Arrow_M_Left, iconButtonSizeVariant: OldIconButtonSizeVariant.Large, onClick: () => handleNavigate('PREV'), type: "button", inProgress: activeButton === 'PREV', disabled: isFetchingBookings && activeButton !== 'PREV' }), _jsx(DateTextWrapper, { children: _jsx(Text, Object.assign({ variant: TextVariant.L1SB, colorVariant: ColorVariant.Cave }, { children: label })) }), _jsx(IconButtonOld, { colorVariant: OldIconButtonColorVariant.Transparent, iconVariant: IconVariant.Arrow_M_Right, iconButtonSizeVariant: OldIconButtonSizeVariant.Large, onClick: () => handleNavigate('NEXT'), type: "button", inProgress: activeButton === 'NEXT', disabled: isFetchingBookings && activeButton !== 'NEXT' })] }), _jsxs(ButtonContainer, { children: [_jsx(Button, { sizeVariant: ButtonSizeVariant.Small, colorVariant: ButtonColorVariant.Default, onClick: () => handleNavigate('TODAY'), label: intl.formatMessage({
                            id: 'BookingCalendar.todayButton',
                        }), inProgress: activeButton === 'TODAY', disabled: isFetchingBookings && activeButton !== 'TODAY' }), _jsx(Button, { sizeVariant: ButtonSizeVariant.Small, colorVariant: ButtonColorVariant.Default, onClick: () => onView(view === 'month' ? 'week' : 'month'), label: intl.formatMessage({
                            id: view === 'month'
                                ? 'BookingCalendar.weekViewButton'
                                : 'BookingCalendar.monthViewButton',
                        }) })] })] }));
};
