import * as z from 'zod';
import { localizedZodCustomErrorMap } from '../localizedZodCustomErrorMap';
import { PhotoSchema } from '../photo';
const mergeImagesAndDescriptions = (images, imageAltTexts) => {
    return images.map(image => {
        var _a;
        const description = (_a = imageAltTexts[`image_caption_${image.id.uuid}`]) !== null && _a !== void 0 ? _a : undefined;
        return Object.assign(Object.assign({}, image), { attributes: Object.assign(Object.assign({}, image.attributes), { description }) });
    });
};
export const ListingPhotosSchema = z.object({
    photos: z.array(PhotoSchema).min(5),
});
export const getListingPhotosSchema = (intl) => {
    z.setErrorMap(localizedZodCustomErrorMap(intl, 'ListingSchemaError'));
    return ListingPhotosSchema;
};
export const getListingPhotosDefaultValues = (listing) => {
    var _a, _b, _c;
    const imageAltTexts = ((_c = (_b = (_a = listing === null || listing === void 0 ? void 0 : listing.attributes) === null || _a === void 0 ? void 0 : _a.publicData) === null || _b === void 0 ? void 0 : _b.localizedPublicData) === null || _c === void 0 ? void 0 : _c.imageAltTexts) || {};
    const listingImages = (listing === null || listing === void 0 ? void 0 : listing.images) || [];
    const photos = mergeImagesAndDescriptions(listingImages, imageAltTexts);
    return {
        photos,
    };
};
