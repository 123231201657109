import { ToiletType } from '@nomady/shared/types';
const getFacilitiesToiletSearchParam = (facilitiesToiletSearchQuery) => {
    const values = [];
    if (facilitiesToiletSearchQuery === null || facilitiesToiletSearchQuery === void 0 ? void 0 : facilitiesToiletSearchQuery.portableToilet) {
        values.push(ToiletType.portable);
    }
    if (facilitiesToiletSearchQuery === null || facilitiesToiletSearchQuery === void 0 ? void 0 : facilitiesToiletSearchQuery.regularToilet) {
        values.push(ToiletType.regular);
    }
    if (facilitiesToiletSearchQuery === null || facilitiesToiletSearchQuery === void 0 ? void 0 : facilitiesToiletSearchQuery.closeToCampsite) {
        values.push(ToiletType.atCamp);
    }
    if (values.length === 0) {
        return undefined;
    }
    return `has_any:${values.join(',')}`;
};
export default getFacilitiesToiletSearchParam;
