import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import styled from 'styled-components/macro';
import Review, { ReviewStyleVariant, } from '../../../styled-components/Review/Review';
import { localeSelector } from '../../../slices/UISlice';
import PaginationControl from '../../../styled-components/PaginationControl/PaginationControl';
import { fetchReviews } from '../ListingPageSlice';
import Text, { TextVariant } from '../../../styled-components/Text/Text';
import ReviewRating from '../../../styled-components/Review/ReviewRating';
import { scrollRefIntoView } from '../utils';
const Wrapper = styled.div `
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
const ListHead = styled.div `
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid ${props => props.theme.color.fog};
  padding-bottom: 10px;
`;
const PaginationWrapper = styled.div `
  display: flex;
  justify-content: center;
`;
const ReviewsList = () => {
    var _a, _b;
    const { listing, reviews, reviewsPagination } = useSelector((state) => state.ListingPage);
    const headRef = useRef(null);
    const locale = useSelector(localeSelector);
    const dispatch = useDispatch();
    const intl = useIntl();
    if (!(listing === null || listing === void 0 ? void 0 : listing.id) || reviewsPagination.totalReviews === 0) {
        return null;
    }
    const onChangePage = (page) => {
        dispatch(fetchReviews(listing.id, page));
        scrollRefIntoView(headRef);
    };
    return (_jsxs(Wrapper, { children: [_jsxs(ListHead, Object.assign({ ref: headRef }, { children: [_jsx(Text, Object.assign({ variant: TextVariant.H50 }, { children: `${intl.formatMessage({ id: 'ListingPage.reviewListTitle' })} (${reviewsPagination.totalReviews})` })), _jsx(ReviewRating, { rating: (_b = (_a = listing === null || listing === void 0 ? void 0 : listing.attributes) === null || _a === void 0 ? void 0 : _a.metadata) === null || _b === void 0 ? void 0 : _b.rating })] })), reviews[(reviewsPagination === null || reviewsPagination === void 0 ? void 0 : reviewsPagination.activePage) - 1].map((review, index) => (_jsx(Review, { locale: locale, review: review, variant: ReviewStyleVariant.VerticalList }, `review${index}`))), _jsx(PaginationWrapper, { children: _jsx(PaginationControl, { totalPages: reviewsPagination.totalPages, activePage: reviewsPagination.activePage, onChangePage: onChangePage }) })] }));
};
export default ReviewsList;
