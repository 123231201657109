var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { PaymentMode } from '@nomady/shared/config';
import sanitizeCode from '@nomady/shared/customer-commission-discount-code/sanitizeCode';
import { checkCustomerCommissionDiscountCode, checkGiftCardCode, } from '../../../../util/api';
const responseStatusToErrorId = (status, isGiftCard) => {
    switch (status) {
        case 404:
            return isGiftCard
                ? 'GiftCardDiscountCodeForm.GiftCard.errorCodeNotFound'
                : 'GiftCardDiscountCodeForm.DiscountCode.errorCodeNotFound';
        case 400:
            return 'GiftCardDiscountCodeForm.DiscountCode.restrictionsNotMet';
        default:
            return 'GiftCardDiscountCodeForm.GiftCard.errorUnknown';
    }
};
const useValidateCode = ({ bookingStart, bookingEnd, giftCardCodes, customerCommissionDiscountCode, listingCurrency, addGiftCardCode, addCustomerCommissionDiscountCode, paymentMode, }) => {
    const intl = useIntl();
    const [errorMessage, setErrorMessage] = useState();
    const setNoRemainingValueError = () => {
        setErrorMessage(intl.formatMessage({
            id: 'GiftCardDiscountCodeForm.GiftCard.errorNoRemainingValue',
        }));
        return 'error';
    };
    const setCodeLimitReached = () => {
        setErrorMessage(intl.formatMessage({
            id: 'GiftCardDiscountCodeForm.GiftCard.errorAlreadyThreeGiftCards',
        }));
        return 'error';
    };
    const setAlreadyAddedError = () => {
        setErrorMessage(intl.formatMessage({
            id: 'GiftCardDiscountCodeForm.GiftCard.errorAlreadyAdded',
        }));
        return 'error';
    };
    const setMixingCodesError = () => {
        setErrorMessage(intl.formatMessage({
            id: 'GiftCardDiscountCodeForm.GiftCard.errorMixing',
        }));
        return 'error';
    };
    const setListingCurrencyMismatchError = (responseCurrency) => {
        setErrorMessage(intl.formatMessage({
            id: 'GiftCardDiscountCodeForm.GiftCard.errorListingCurrencyMismatch',
        }, { currency: responseCurrency }));
        return 'error';
    };
    const handleAddCode = (code) => __awaiter(void 0, void 0, void 0, function* () {
        setErrorMessage(undefined);
        const isGiftCard = !code.includes('-');
        const sanitizedCode = sanitizeCode(code).toLocaleUpperCase();
        const response = isGiftCard
            ? yield checkGiftCardCode(sanitizedCode)
            : yield checkCustomerCommissionDiscountCode(sanitizedCode, bookingStart, bookingEnd);
        if (response.status !== 200) {
            setErrorMessage(intl.formatMessage({
                id: responseStatusToErrorId(response.status, isGiftCard),
            }));
            return 'error';
        }
        if (isGiftCard) {
            const { giftCardCode: giftCardCodeResponse } = yield response.json();
            if (giftCardCodeResponse && paymentMode === PaymentMode.Custom) {
                setErrorMessage(intl.formatMessage({
                    id: 'GiftCardDiscountCodeForm.GiftCard.errorPleaseSelectLegacyPaymentMode',
                }));
                return 'error';
            }
            const alreadyAddedGiftCard = giftCardCodes === null || giftCardCodes === void 0 ? void 0 : giftCardCodes.find(code => code.id === giftCardCodeResponse.id);
            if (alreadyAddedGiftCard) {
                return setAlreadyAddedError();
            }
            const noRemainingValue = giftCardCodeResponse.remainingValue === 0;
            if (noRemainingValue) {
                return setNoRemainingValueError();
            }
            const doesMixDiscountCodeAndGiftCard = giftCardCodes &&
                giftCardCodes.length > 0 &&
                !!giftCardCodes.find(code => code.codeType !== giftCardCodeResponse.codeType);
            if (doesMixDiscountCodeAndGiftCard) {
                return setMixingCodesError();
            }
            const listingCurrencyMismatch = giftCardCodeResponse.currency !== listingCurrency;
            if (listingCurrencyMismatch) {
                return setListingCurrencyMismatchError(giftCardCodeResponse.currency);
            }
            const alreadyThreeCodes = (giftCardCodes === null || giftCardCodes === void 0 ? void 0 : giftCardCodes.length) === 3;
            if (alreadyThreeCodes) {
                return setCodeLimitReached();
            }
            addGiftCardCode(giftCardCodeResponse);
        }
        if (!isGiftCard && !customerCommissionDiscountCode) {
            const customerCommissionDiscountCodeResponse = yield response.json();
            addCustomerCommissionDiscountCode(customerCommissionDiscountCodeResponse);
        }
        return 'reset';
    });
    return {
        errorMessage,
        handleAddCode,
    };
};
export default useValidateCode;
