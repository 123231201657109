import { jsx as _jsx } from "react/jsx-runtime";
import styled from 'styled-components/macro';
import React from 'react';
import { DefaultTheme } from '../../../theme';
const TransactionStatusColorMap = {
    REQUEST: DefaultTheme.color.clementine,
    ACCEPTED: DefaultTheme.color.lagoon,
    WITHDRAWN: DefaultTheme.color.rose,
    EXPIRED: DefaultTheme.color.rose,
    DECLINED: DefaultTheme.color.rose,
    CANCELLED: DefaultTheme.color.wine,
    DELIVERED: DefaultTheme.color.amber,
    REVIEWED: DefaultTheme.color.gras,
    UNKNOWN: DefaultTheme.color.blood,
};
const Wrapper = styled.div `
  padding: 1px 4px;
`;
const Bar = styled.div `
  background-color: ${props => props.theme.color.pearl};
  box-shadow: inset 0 0 0 1px ${props => props.color};
  border-radius: 4px;
  pointer-events: auto;

  &:hover {
    background-color: ${props => props.color};
  }
`;
export const CalendarComponentEventWrapper = ({ children, event: { state } }) => (_jsx(Wrapper, { children: _jsx(Bar, Object.assign({ color: TransactionStatusColorMap[state] }, { children: children })) }));
