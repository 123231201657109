import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import IntroSheetContent from './IntroSheetContent';
import Text, { TextVariant } from '../../Text/Text';
import styled from 'styled-components/macro';
import { ColorVariant } from '../../../theme';
import Button, { ButtonColorVariant, ButtonSizeVariant, } from '../../Button/Button';
import Page from '@nomady/shared/routeConfiguration/Page';
import { useSelector } from 'react-redux';
import background from '../assets/231122_Nomady_DigitalFormats_AppWelcome4.webp';
import { useIntl } from 'react-intl';
import { safeSpaceTopSelector } from '../../../slices/UISlice';
import { MigrationForm } from '../../SectionMigrateMyCabinUser/components/MigrationForm';
import { routeConfiguration } from '../../..';
import { createResourceLocatorString } from '@nomady/shared/utils/routes';
import { useHistory } from 'react-router-dom';
import Icon, { IconSizeVariant, IconVariant } from '../../Icon/Icon';
const Wrapper = styled.div `
  position: fixed;
  background-color: ${props => props.theme.color.snow};
  width: 100vw;
  height: 100vh;
  z-index: 20;
  display: flex;
  flex-direction: column;
  padding-top: ${props => props.safeSpaceTop}px;
  background-image: url(${background});
  background-position: bottom;
  background-size: cover;
`;
const LoginButtonAndTextWrapper = styled.div `
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
const MigrationUIWrapper = styled.div `
  margin-bottom: 32px;
  position: relative;
  min-height: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
  justify-content: space-between;
`;
const MigrationCard = styled.div `
  padding: 32px;
  border-radius: 16px;
  background-color: ${props => props.theme.color.fir};
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow-y: scroll;
  color: ${props => props.theme.color.pearl};
`;
const CloseSheetButton = styled.div `
  position: fixed;
  top: calc(${props => props.safeSpaceTop}px + 32px);
  right: 32px;
  padding: 8px;
  cursor: pointer;
  background-color: ${props => props.theme.color.fog};
  border-radius: 16px;
`;
const SheetUserMigrationFromMyCabin = ({ onClose, onClickNoMyCabinAccount }) => {
    const safeSpaceTop = useSelector(safeSpaceTopSelector);
    const intl = useIntl();
    const { passwordRequested } = useSelector((state) => state.PasswordRecoveryPage);
    const history = useHistory();
    const onCloseClick = () => {
        const target = createResourceLocatorString(Page.MyCabin, routeConfiguration);
        history.push(target);
        onClose();
    };
    return (_jsx(Wrapper, Object.assign({ safeSpaceTop: safeSpaceTop }, { children: _jsx(IntroSheetContent, { title: intl.formatMessage({
                id: 'AppIntro.UserMigrateSheet.Title',
            }), content: _jsxs(MigrationUIWrapper, { children: [_jsx(MigrationCard, { children: _jsx(MigrationForm, { text: intl.formatMessage({
                                id: 'AppIntro.UserMigrateSheet.InformationText',
                            }) }) }), passwordRequested ? (_jsxs(LoginButtonAndTextWrapper, { children: [_jsx(Text, Object.assign({ variant: TextVariant.B30, colorVariant: ColorVariant.Cave }, { children: intl.formatMessage({
                                    id: 'AppIntro.UserMigrateSheet.doYouWantToReadMore',
                                }) })), _jsx(Button, { sizeVariant: ButtonSizeVariant.Default, colorVariant: ButtonColorVariant.Light, label: intl.formatMessage({
                                    id: 'AppIntro.UserMigrateSheet.moreInfoButton',
                                }), onClick: onCloseClick })] })) : (_jsxs(LoginButtonAndTextWrapper, { children: [_jsx(Text, Object.assign({ variant: TextVariant.B30, colorVariant: ColorVariant.Cave }, { children: intl.formatMessage({
                                    id: 'AppIntro.UserMigrateSheet.noMyCabinAccountHint',
                                }) })), _jsx(Button, { sizeVariant: ButtonSizeVariant.Default, colorVariant: ButtonColorVariant.Light, label: intl.formatMessage({
                                    id: 'AppIntro.UserMigrateSheet.register',
                                }), onClick: onClickNoMyCabinAccount })] })), _jsx(CloseSheetButton, Object.assign({ safeSpaceTop: safeSpaceTop, onClick: onCloseClick }, { children: _jsx(Icon, { variant: IconVariant.X_Close, sizeVariant: IconSizeVariant.Default, colorVariant: ColorVariant.BasicCaveBlack }) }))] }) }, 2) })));
};
export default SheetUserMigrationFromMyCabin;
