import * as z from 'zod';
import { AccessType, CampCategory, CampStyleAccommodation, CampStyleCamping, GroundType, } from '../../types';
import { localizedZodCustomErrorMap } from '../localizedZodCustomErrorMap';
export const MIN_BOOKABLE_PERSONS_PER_SEAT = 1;
export const MAX_BOOKABLE_PERSONS_PER_SEAT = 20;
const TITLE_MIN_LENGTH = 1;
const TITLE_MAX_LENGTH = 35;
export const DESCRIPTION_MIN_LENGTH = 300;
export const DESCRIPTION_MAX_LENGTH = 10000;
const MIN_SEATS = 1;
const MAX_SEATS = 10;
const CommonSchema = z.object({
    bookablePersonsPerSeat: z
        .number()
        .int()
        .min(MIN_BOOKABLE_PERSONS_PER_SEAT)
        .max(MAX_BOOKABLE_PERSONS_PER_SEAT),
    parkingNearby: z.boolean(),
    accessType: z.nativeEnum(AccessType),
    title: z.string().min(TITLE_MIN_LENGTH).max(TITLE_MAX_LENGTH),
    descriptionSummer: z
        .string()
        .min(DESCRIPTION_MIN_LENGTH)
        .max(DESCRIPTION_MAX_LENGTH),
    winterCapability: z.boolean().nullish(),
});
const AccommodationSchema = CommonSchema.extend({
    category: z.literal(CampCategory.accommodation),
    campStyle: z.nativeEnum(CampStyleAccommodation),
    seats: z.number().int().default(1),
    privateCamp: z.boolean().default(true),
});
const CampingSchema = CommonSchema.extend({
    category: z.literal(CampCategory.camping),
    campStyle: z.array(z.nativeEnum(CampStyleCamping)).min(1),
    seats: z.number().int().min(MIN_SEATS).max(MAX_SEATS),
    groundType: z.nativeEnum(GroundType),
    privateCamp: z.boolean().nullish(),
});
export const ListingDescriptionSchema = z.discriminatedUnion('category', [
    AccommodationSchema,
    CampingSchema,
]);
export const getListingDescriptionSchema = (intl) => {
    z.setErrorMap(localizedZodCustomErrorMap(intl, 'ListingSchemaError'));
    return ListingDescriptionSchema;
};
export const getListingDescriptionDefaultValues = (attributes) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
    return ({
        title: attributes === null || attributes === void 0 ? void 0 : attributes.title,
        descriptionSummer: (_d = (_c = (_b = (_a = attributes === null || attributes === void 0 ? void 0 : attributes.publicData) === null || _a === void 0 ? void 0 : _a.localizedPublicData) === null || _b === void 0 ? void 0 : _b.seasons) === null || _c === void 0 ? void 0 : _c.summer) === null || _d === void 0 ? void 0 : _d.description,
        winterCapability: (_e = attributes === null || attributes === void 0 ? void 0 : attributes.publicData) === null || _e === void 0 ? void 0 : _e.winterCapability,
        category: (_f = attributes === null || attributes === void 0 ? void 0 : attributes.publicData) === null || _f === void 0 ? void 0 : _f.category,
        seats: (_g = attributes === null || attributes === void 0 ? void 0 : attributes.publicData) === null || _g === void 0 ? void 0 : _g.seats,
        campStyle: (attributes === null || attributes === void 0 ? void 0 : attributes.publicData.category) === CampCategory.accommodation
            ? (_h = attributes === null || attributes === void 0 ? void 0 : attributes.publicData.campStyle) === null || _h === void 0 ? void 0 : _h[0]
            : attributes === null || attributes === void 0 ? void 0 : attributes.publicData.campStyle,
        parkingNearby: (_j = attributes === null || attributes === void 0 ? void 0 : attributes.publicData) === null || _j === void 0 ? void 0 : _j.parkingNearby,
        accessType: (_k = attributes === null || attributes === void 0 ? void 0 : attributes.publicData) === null || _k === void 0 ? void 0 : _k.accessType,
        groundType: (_l = attributes === null || attributes === void 0 ? void 0 : attributes.publicData) === null || _l === void 0 ? void 0 : _l.groundType,
        bookablePersonsPerSeat: (_m = attributes === null || attributes === void 0 ? void 0 : attributes.publicData) === null || _m === void 0 ? void 0 : _m.bookablePersonsPerSeat,
        privateCamp: (_o = attributes === null || attributes === void 0 ? void 0 : attributes.publicData) === null || _o === void 0 ? void 0 : _o.privateCamp,
    });
};
