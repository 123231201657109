import { jsx as _jsx } from "react/jsx-runtime";
import { getTransactionStatus } from '@nomady/shared/utils/transaction';
import React from 'react';
import BookingCard from './BookingCard';
const ConnectedBookingCard = ({ transaction, unread = false, }) => {
    var _a, _b, _c;
    const { id } = transaction;
    const { total = -1, currency = 'N/A' } = transaction.attributes.protectedData.payouts || {};
    const { lastTransitionedAt } = transaction.attributes;
    const listingName = ((_a = transaction.listing) === null || _a === void 0 ? void 0 : _a.attributes.title) || '';
    const status = getTransactionStatus(transaction);
    return (_jsx(BookingCard, { id: id, listingName: listingName, guest: transaction.customer, arrivalDate: (_b = transaction.booking) === null || _b === void 0 ? void 0 : _b.attributes.displayStart, departureDate: (_c = transaction.booking) === null || _c === void 0 ? void 0 : _c.attributes.displayEnd, status: status, payoutTotal: total, currency: currency, lastTransitionedAt: lastTransitionedAt, unread: unread }));
};
export default ConnectedBookingCard;
