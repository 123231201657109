import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import moment from 'moment';
import { nextMonthFn, prevMonthFn } from '@nomady/shared/utils/dates';
import { DayVariant } from '../CalendarDay/CalendarDay';
import CalendarMonth, { getDaysOfMonth } from '../CalendarMonth/CalendarMonth';
import CalendarMonthSwitch from '../CalendarMonth/CalendarMonthSwitch';
import getMonthData from './utils';
const Wrapper = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: stretch;
`;
const DateRangeCalendar = ({ startDay, endDay, onChangeRange, showSingleMonthOnDesktop = false, timeSlots = null, totalSeats = 1, requestedSeats, }) => {
    const initialCurrentDays = (timeSlots || []).map(timeSlot => moment.utc(timeSlot.attributes.start));
    const [months, setMonths] = useState([
        moment().utc().startOf('day').startOf('month'),
        moment().utc().startOf('day').startOf('month').add(1, 'month'),
    ]);
    const [intermediateStart, setIntermediateStart] = useState();
    const [intermediateEnd, setIntermediateEnd] = useState();
    const [currentDays, setCurrentDays] = useState(initialCurrentDays);
    const [availabilityMonths, setAvailabilityMonths] = useState(getMonthData(timeSlots, months.map(currentMonth => getDaysOfMonth(currentMonth)), totalSeats, requestedSeats, intermediateStart, intermediateEnd, startDay, endDay));
    const onLeaveCalendar = () => {
        setIntermediateStart(undefined);
        setIntermediateEnd(undefined);
    };
    const onHoverDay = (hoveredDay) => {
        setIntermediateStart(undefined);
        setIntermediateEnd(undefined);
        const dayIsAvailable = hoveredDay.variant !== DayVariant.UNAVAILABLE;
        const hasPreviousRange = startDay && endDay;
        const nothingSelected = !startDay && !endDay && hoveredDay;
        const newStartDay = hasPreviousRange && hoveredDay;
        const newStartBeforeCurrentStart = startDay && hoveredDay.date.isBefore(startDay, 'day');
        const newStartAfterCurrentRange = hasPreviousRange && (endDay === null || endDay === void 0 ? void 0 : endDay.isBefore(hoveredDay.date, 'day'));
        const hoveredDayIsCurrentStartDay = startDay && hoveredDay && startDay.isSame(hoveredDay.date, 'day');
        if (dayIsAvailable) {
            if (nothingSelected ||
                newStartDay ||
                newStartBeforeCurrentStart ||
                newStartAfterCurrentRange ||
                hoveredDayIsCurrentStartDay) {
                setIntermediateStart(hoveredDay);
                return;
            }
            setIntermediateEnd(hoveredDay);
        }
    };
    const onClickNextMonth = () => {
        setMonths(months.map(month => nextMonthFn(month)));
    };
    const onClickPrevMonth = () => {
        setMonths(months.map(month => prevMonthFn(month)));
    };
    const onDayClicked = (day) => {
        const isInPast = day.date.isBefore(moment(), 'day');
        if (isInPast) {
            return;
        }
        if (!startDay) {
            onChangeRange(day.date, undefined);
        }
        else if (startDay.isAfter(day.date, 'day')) {
            onChangeRange(day.date, undefined);
        }
        else if (!endDay && startDay.isSame(day.date, 'day')) {
            return;
        }
        else if (startDay && !endDay) {
            onChangeRange(startDay, day.date);
        }
        else if (startDay && endDay) {
            onChangeRange(day.date, undefined);
        }
        setIntermediateStart(undefined);
        setIntermediateEnd(undefined);
    };
    useEffect(() => {
        setAvailabilityMonths(getMonthData(timeSlots, months.map(currentMonth => getDaysOfMonth(currentMonth)), totalSeats, requestedSeats, intermediateStart, intermediateEnd, startDay, endDay));
    }, [
        months,
        intermediateStart,
        intermediateEnd,
        startDay,
        endDay,
        currentDays,
        totalSeats,
        requestedSeats,
    ]);
    useEffect(() => {
        if (initialCurrentDays) {
            const availableDays = [];
            let firstUnavailableDayFound = false;
            if (startDay) {
                initialCurrentDays.map((currentDay, index) => {
                    if (currentDay.isBefore(startDay)) {
                        return availableDays.push(currentDay);
                    }
                    if (initialCurrentDays.length - 1 !== index &&
                        !firstUnavailableDayFound) {
                        const nextDay = currentDay.clone().add(1, 'day');
                        const nextCurrentDay = initialCurrentDays[index + 1].clone();
                        if (nextCurrentDay.toISOString() === nextDay.toISOString() &&
                            !firstUnavailableDayFound) {
                            return availableDays.push(currentDay);
                        }
                        if (!firstUnavailableDayFound) {
                            firstUnavailableDayFound = true;
                            return availableDays.push(currentDay, nextDay);
                        }
                    }
                    return false;
                });
                setCurrentDays(availableDays);
            }
        }
    }, [startDay]);
    useEffect(() => {
        if (endDay) {
            setCurrentDays(initialCurrentDays);
        }
        if (!startDay) {
            setCurrentDays(initialCurrentDays);
        }
    }, [startDay, endDay]);
    return (_jsxs(Wrapper, Object.assign({ onPointerLeave: onLeaveCalendar }, { children: [_jsx(CalendarMonthSwitch, { months: showSingleMonthOnDesktop ? [months[0]] : months, onClickPrevMonth: onClickPrevMonth, onClickNextMonth: onClickNextMonth }), _jsx(CalendarMonth, { months: showSingleMonthOnDesktop
                    ? [availabilityMonths[0]]
                    : availabilityMonths, onClickDay: onDayClicked, loading: false, onHoverDay: onHoverDay, timeSlots: currentDays, compact: true })] })));
};
export default DateRangeCalendar;
