import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styled from 'styled-components/macro';
import Text, { TextVariant } from '../Text/Text';
import RegionalListingSearchBar from '../ListingSearchBar/RegionalListingSearchBar';
import Layout, { LayoutRow, LayoutRowBottomVariant } from '../Layout/Layout';
export var HeroVariant;
(function (HeroVariant) {
    HeroVariant["Default"] = "Default";
    HeroVariant["Cta"] = "Cta";
    HeroVariant["ListingSearch"] = "ListingSearch";
})(HeroVariant || (HeroVariant = {}));
export var HeroColorVariant;
(function (HeroColorVariant) {
    HeroColorVariant["Dark"] = "dark";
    HeroColorVariant["Light"] = "light";
})(HeroColorVariant || (HeroColorVariant = {}));
const Wrapper = styled.section `
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  justify-content: flex-end;
  width: 100%;
  background-size: cover;
  background-position: center center;
  background-color: ${props => props.theme.color.fir};
  height: 480px;

  ${props => {
    var _a, _b;
    return `background-image: url(${((_b = (_a = props.heroBackgroundImageFormats) === null || _a === void 0 ? void 0 : _a.large) === null || _b === void 0 ? void 0 : _b.url) || props.backgroundImage});`;
}}
  ${props => `color: ${props.colorVariant === HeroColorVariant.Dark
    ? props.theme.color.pearl
    : props.theme.color.cave};`}
    

  @media ${props => props.theme.device.tablet} {
    height: 600px;
  }

  @media ${props => props.theme.device.desktopL} {
    background-image: url(${props => props.backgroundImage});
  }
`;
const Content = styled(Layout) `
  padding-bottom: 100px;

  @media ${props => props.theme.device.tablet} {
    padding-bottom: 160px;
    justify-self: flex-end;
  }
`;
const Title = styled(Text) ``;
const SubTitle = styled(Text) ``;
const Mobile = styled.div `
  width: 90%;
  margin: 0 auto;
  margin-top: 15px;
  margin-top: 15px;
  @media ${props => props.theme.device.tablet} {
    display: none;
  }
`;
const Hero = ({ image, colorVariant = HeroColorVariant.Dark, title, subTitle, displayRegionalListingSearchBar, onClickListingSearchBar, }) => {
    return (_jsxs(Wrapper, Object.assign({ heroBackgroundImageFormats: image === null || image === void 0 ? void 0 : image.data.attributes.formats, backgroundImage: image === null || image === void 0 ? void 0 : image.data.attributes.url, colorVariant: colorVariant, displayRegionalListingSearchBar: displayRegionalListingSearchBar }, { children: [displayRegionalListingSearchBar && (_jsx(Mobile, Object.assign({ onClick: onClickListingSearchBar }, { children: _jsx(RegionalListingSearchBar, {}) }))), _jsx(Content, { children: _jsxs(LayoutRow, Object.assign({ bottomVariant: LayoutRowBottomVariant.NoMargin }, { children: [subTitle && (_jsx(SubTitle, Object.assign({ variant: TextVariant.H50, as: "p" }, { children: subTitle }))), _jsx(Title, Object.assign({ variant: TextVariant.H1, as: "h1" }, { children: title }))] })) })] })));
};
export default Hero;
