var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { useIntl } from 'react-intl';
import Modal from '../../../styled-components/Modal/Modal';
import { InfoItem } from '../../ListingPage/Sections/BookingDetailsSection';
import { IconVariant } from '../../../styled-components/Icon/Icon';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { editAvailabilityExceptionsForAllListings } from '../CalendarSlice';
import { InfoCard } from './InfoCard';
import { Operation } from '../types';
const Container = styled.div `
  display: flex;
  flex-direction: column;
  gap: 32px;
`;
const InfoWrapper = styled.div `
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
const AvailabilityDetailsForAllListingsModal = ({ open, onClose, selectedSlots }) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const [savingInProgress, setSavingInProgress] = useState(false);
    const isSingleDay = (selectedSlots === null || selectedSlots === void 0 ? void 0 : selectedSlots.slots.length) === 1;
    const periodSelectedText = isSingleDay
        ? `${moment(selectedSlots.start).format('LL')}`
        : intl.formatMessage({ id: 'BookingCalendar.dateRange' }, {
            startDate: moment(selectedSlots === null || selectedSlots === void 0 ? void 0 : selectedSlots.start).format('LL'),
            endDate: moment(selectedSlots === null || selectedSlots === void 0 ? void 0 : selectedSlots.end).format('LL'),
        });
    const save = (operation) => __awaiter(void 0, void 0, void 0, function* () {
        if (selectedSlots === undefined) {
            return;
        }
        setSavingInProgress(operation);
        yield dispatch(editAvailabilityExceptionsForAllListings({
            operation,
            dates: selectedSlots.slots,
        }));
        setSavingInProgress(false);
        onClose();
    });
    return (_jsx(Modal, Object.assign({ isOpen: open, onClose: onClose, title: intl.formatMessage({
            id: 'BookingCalendar.AvailabilityDetails.title',
        }), leftButton: {
            label: intl.formatMessage({
                id: 'BookingCalendar.AvailabilityDetails.blockAll',
            }),
            onClick: () => save(Operation.Blocking),
            inProgress: savingInProgress === Operation.Blocking,
            disabled: savingInProgress === Operation.Freeing,
        }, rightButton: {
            label: intl.formatMessage({
                id: 'BookingCalendar.AvailabilityDetails.unblockAll',
            }),
            onClick: () => save(Operation.Freeing),
            inProgress: savingInProgress === Operation.Freeing,
            disabled: savingInProgress === Operation.Blocking,
            isPrimary: true,
        } }, { children: _jsxs(Container, { children: [_jsxs(InfoWrapper, { children: [_jsx(InfoItem, { title: intl.formatMessage({
                                id: 'BookingCalendar.AvailabilityDetails.listingTitlePlural',
                            }), iconVariant: IconVariant.Listing, text: intl.formatMessage({
                                id: 'BookingCalendar.AvailabilityDetails.allListings',
                            }) }), _jsx(InfoItem, { title: intl.formatMessage({
                                id: `BookingCalendar.AvailabilityDetails.date${isSingleDay ? '' : 'Range'}`,
                            }), iconVariant: IconVariant.Calendar_NEW, text: periodSelectedText })] }), _jsx(InfoCard, { title: intl.formatMessage({
                        id: 'BookingCalendar.AvailabilityDetails.allListingsInfoTitle',
                    }), text: intl.formatMessage({
                        id: 'BookingCalendar.AvailabilityDetails.allListingsInfoText',
                    }) })] }) })));
};
export default AvailabilityDetailsForAllListingsModal;
