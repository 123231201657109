import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import styled from 'styled-components/macro';
import { useIntl } from 'react-intl';
import moment from 'moment';
import { convertMoneyToNumber } from '@nomady/shared/utils/currency';
import { LINE_ITEM_ADULTS, LINE_ITEM_SEATS, } from '@nomady/shared/utils/lineItemTypes';
import { calculateTotalFromLineItems } from '@nomady/shared/utils/lineItemHelpers';
import Text, { TextVariant } from '../Text/Text';
import { ColorVariant } from '../../theme';
import DetailElement from './partials/DetailElement';
import BreakdownGroup, { BREAKDOWN_GROUPS } from './partials/BreakdownGroup';
import { CODE_TYPE, LINE_ITEM_GROUP } from './types';
import PaymentInfoCard from './partials/PaymentInfoCard';
import Total from '../Total/Total';
const Wrapper = styled.div `
  display: flex;
  flex-direction: column;
  padding: 12px;
  border: 1px solid ${props => props.theme.color.fog};
  border-radius: 10px;
  width: 100%;
  background-color: ${props => props.theme.color.transparent};
`;
const DetailsList = styled.div `
  display: flex;
  flex-direction: column;
`;
const GroupDivider = styled.div `
  border-bottom: 1px solid ${props => props.theme.color.stone};
  margin-block: 2px;
`;
const TotalDivider = styled.div `
  border-bottom: 2px solid ${props => props.theme.color.tar};
  margin: 8px 0px 8px;
`;
const TotalContainer = styled.div `
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
`;
const TotalCurrency = styled(Text) `
  font-size: 12px;
  font-weight: 500;
`;
const ItemText = styled(Text) `
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const DisclaimerWrapper = styled.div `
  margin-top: 8px;
`;
const DisclaimerText = styled(Text) `
  padding: 0px;
  font-weight: 400;
`;
const PaymentContainer = styled.div `
  display: flex;
  flex-direction: column;
  margin-top: 6px;
  margin-bottom: 14px;
`;
const BookingBreakdown = ({ lineItems, category, currency = 'N/A', nightsBooked, totalPrice, totalProviderEarnings, totalProviderPayout, bookingStart, bookingEnd, giftCards, customerCommissionDiscountCode, totalGiftcardPayin, }) => {
    const intl = useIntl();
    const periodBooked = `${moment(bookingStart).format('DD. MMM yyyy')} - ${moment(bookingEnd).format('DD. MMM yyyy')}`;
    const allLineItemGroups = Object.keys(lineItems);
    const hasPayment = !!lineItems[LINE_ITEM_GROUP.PAYMENT].length;
    let outstandingBalance = totalPrice;
    if (totalGiftcardPayin && hasPayment) {
        outstandingBalance = (totalPrice || 0) + totalGiftcardPayin;
    }
    const lineItemsForTotal = [];
    const seatsLineItem = lineItems[LINE_ITEM_GROUP.BASE_PRICE].find(element => element.code === LINE_ITEM_SEATS);
    if (seatsLineItem) {
        lineItemsForTotal.push(seatsLineItem);
    }
    const adultsLineItem = lineItems[LINE_ITEM_GROUP.BASE_PRICE].find(element => element.code === LINE_ITEM_ADULTS);
    if (adultsLineItem) {
        lineItemsForTotal.push(adultsLineItem);
    }
    const totalBasePrice = calculateTotalFromLineItems(lineItemsForTotal);
    return (_jsxs(_Fragment, { children: [_jsxs(Wrapper, { children: [_jsx(Text, Object.assign({ colorVariant: ColorVariant.Tar, variant: TextVariant.B30 }, { children: intl.formatMessage({ id: 'BookingBreakdown.basePrice' }) })), _jsxs(DetailsList, { children: [_jsx(DetailElement, { leftText: intl.formatMessage({
                                    id: `BookingBreakdown.seatsPerNight.${category}`,
                                }, { quantity: seatsLineItem === null || seatsLineItem === void 0 ? void 0 : seatsLineItem.seats }), rightText: `${currency} ${(convertMoneyToNumber(seatsLineItem === null || seatsLineItem === void 0 ? void 0 : seatsLineItem.unitPrice) *
                                    ((seatsLineItem === null || seatsLineItem === void 0 ? void 0 : seatsLineItem.seats) || 1)).toFixed(2)}` }), _jsx(DetailElement, { leftText: intl.formatMessage({
                                    id: 'BookingBreakdown.adultsPerNight',
                                }, { quantity: adultsLineItem === null || adultsLineItem === void 0 ? void 0 : adultsLineItem.seats }), rightText: `${currency} ${(convertMoneyToNumber(adultsLineItem === null || adultsLineItem === void 0 ? void 0 : adultsLineItem.unitPrice) *
                                    ((adultsLineItem === null || adultsLineItem === void 0 ? void 0 : adultsLineItem.seats) || 1)).toFixed(2)}` }), _jsx(DetailElement, { leftText: periodBooked, rightText: intl.formatMessage({ id: 'BookingBreakdown.nightQuantity' }, { quantity: nightsBooked }) }), _jsx(DetailElement, { rightText: `${currency} ${convertMoneyToNumber(totalBasePrice).toFixed(2)}`, isTotal: true })] }), _jsx(GroupDivider, {}), allLineItemGroups.map(group => (_jsx(_Fragment, { children: lineItems[group].length > 0 &&
                            BREAKDOWN_GROUPS.includes(group) && (_jsx(BreakdownGroup, { group: group, lineItems: lineItems })) }))), customerCommissionDiscountCode &&
                        lineItems[LINE_ITEM_GROUP.DISCOUNT].map(lineItem => {
                            return (_jsx(PaymentInfoCard, { code: customerCommissionDiscountCode.code, currency: lineItem.lineTotal.currency, codeType: CODE_TYPE.CUSTOMER_COMMISSION_DISCOUNT_CODE, value: convertMoneyToNumber(lineItem.lineTotal) }, lineItem.code));
                        }), totalProviderPayout && (_jsx(Total, { currency: currency, totalPrice: totalProviderPayout, labelId: "BookingBreakdown.providerTotalPayout" })), totalProviderEarnings && (_jsxs(_Fragment, { children: [_jsx(Total, { currency: currency, totalPrice: totalProviderEarnings, labelId: "BookingBreakdown.providerTotalEarnings" }), _jsx(Text, Object.assign({ colorVariant: ColorVariant.Tar, variant: TextVariant.B60, as: "p" }, { children: intl.formatMessage({
                                    id: 'BookingBreakdown.providerTotalExplained',
                                }) }))] })), !totalProviderPayout && totalPrice ? (_jsx(Total, { currency: currency, totalPrice: totalPrice })) : (_jsxs(_Fragment, { children: [totalPrice && (_jsx(Total, { currency: currency, totalPrice: totalPrice, labelId: totalProviderPayout
                                    ? 'BookingBreakdown.customerTotalDefault'
                                    : undefined })), _jsx(Text, Object.assign({ colorVariant: ColorVariant.Tar, variant: TextVariant.B60, as: "p" }, { children: intl.formatMessage({
                                    id: 'BookingBreakdown.customerTotalNote',
                                }) }))] })), hasPayment && (_jsxs(_Fragment, { children: [_jsx(Text, Object.assign({ colorVariant: ColorVariant.Tar, variant: TextVariant.B30 }, { children: intl.formatMessage({
                                    id: 'BookingBreakdown.payment',
                                }, { paymentDate: moment().format('DD.MM.YYYY') }) })), _jsx(PaymentContainer, { children: lineItems[LINE_ITEM_GROUP.PAYMENT].map(lineItem => {
                                    var _a;
                                    const itemCode = ((_a = giftCards === null || giftCards === void 0 ? void 0 : giftCards.find(card => card.id === lineItem.id)) === null || _a === void 0 ? void 0 : _a.last2) || '';
                                    return (_jsx(PaymentInfoCard, { code: itemCode, currency: lineItem.lineTotal.currency, codeType: CODE_TYPE.GIFT_CARD, value: convertMoneyToNumber(lineItem.lineTotal) }, lineItem.code));
                                }) })] })), hasPayment && outstandingBalance && (_jsxs(_Fragment, { children: [_jsx(TotalDivider, {}), _jsxs(TotalContainer, { children: [_jsx(ItemText, Object.assign({ colorVariant: ColorVariant.Tar, variant: TextVariant.B30 }, { children: intl.formatMessage({
                                            id: 'BookingBreakdown.outstandingAmount',
                                        }) })), _jsxs(ItemText, Object.assign({ colorVariant: ColorVariant.Tar, variant: TextVariant.H50 }, { children: [_jsx(TotalCurrency, { children: currency }), ' ', outstandingBalance.toFixed(2)] }))] })] }))] }), _jsx(DisclaimerWrapper, { children: _jsx(DisclaimerText, Object.assign({ variant: TextVariant.B30 }, { children: intl.formatMessage({
                        id: 'BookingBreakdown.commissionFeeNote',
                    }) })) })] }));
};
export default BookingBreakdown;
