import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import styled, { css } from 'styled-components/macro';
export const LayoutSideNavigation = styled.div `
  @media ${props => props.theme.device.desktop} {
    ${props => props.theme.layout.col4}
    margin-right: 30px;
  }
`;
export var LayoutRowBottomVariant;
(function (LayoutRowBottomVariant) {
    LayoutRowBottomVariant["Default"] = "default";
    LayoutRowBottomVariant["NoMargin"] = "no-margin";
    LayoutRowBottomVariant["MediumMargin"] = "medium-margin";
    LayoutRowBottomVariant["SmallMargin"] = "small-margin";
    LayoutRowBottomVariant["ExtraSmallMargin"] = "extra-small-margin";
})(LayoutRowBottomVariant || (LayoutRowBottomVariant = {}));
export var LayoutRowHorizontalVariant;
(function (LayoutRowHorizontalVariant) {
    LayoutRowHorizontalVariant["Uncontained"] = "uncontained";
    LayoutRowHorizontalVariant["ContainedLeft"] = "contained-left";
    LayoutRowHorizontalVariant["DesktopOnlyContainedLeft"] = "desktop-only-contained-left";
    LayoutRowHorizontalVariant["MobileUncontained"] = "mobile-uncontained";
    LayoutRowHorizontalVariant["MobileContainedLeft"] = "mobile-contained-left";
})(LayoutRowHorizontalVariant || (LayoutRowHorizontalVariant = {}));
export var LayoutRowColorVariant;
(function (LayoutRowColorVariant) {
    LayoutRowColorVariant["Default"] = "Default";
    LayoutRowColorVariant["Horizon"] = "Horizon";
    LayoutRowColorVariant["Fir"] = "Fir";
    LayoutRowColorVariant["Pearl"] = "Pearl";
    LayoutRowColorVariant["Cave"] = "Cave";
    LayoutRowColorVariant["Moss"] = "Moss";
    LayoutRowColorVariant["Alge"] = "Alge";
})(LayoutRowColorVariant || (LayoutRowColorVariant = {}));
const LayoutRowWrapper = styled.div `
  max-width: 100%;
  grid-column-start: contentStart;
  grid-column-end: contentEnd;

  ${props => props.horizontalVariant === LayoutRowHorizontalVariant.Uncontained &&
    css `
      grid-column-start: viewPortStart;
      grid-column-end: viewPortEnd;
    `}

  ${props => props.horizontalVariant === LayoutRowHorizontalVariant.ContainedLeft &&
    css `
      grid-column-start: contentStart;
      grid-column-end: viewPortEnd;
    `}


  ${props => props.horizontalVariant ===
    LayoutRowHorizontalVariant.DesktopOnlyContainedLeft &&
    css `
      grid-column-start: contentStart;
      grid-column-end: contentEnd;

      @media ${props => props.theme.device.desktop} {
        grid-column-end: viewPortEnd;
      }
    `}

  ${props => props.horizontalVariant === LayoutRowHorizontalVariant.MobileUncontained &&
    css `
      grid-column-start: viewPortStart;
      grid-column-end: viewPortEnd;
      padding: 10px 15px;

      @media ${props => props.theme.device.desktop} {
        padding: 45px 10px;
      }

      @media ${props => props.theme.device.desktop} {
        padding: 0;
        grid-column-start: contentStart;
        grid-column-end: contentEnd;
      }
    `}

      ${props => props.horizontalVariant ===
    LayoutRowHorizontalVariant.MobileContainedLeft &&
    css `
      grid-column-start: viewPortStart;
      grid-column-end: viewPortEnd;

      @media ${props => props.theme.device.desktop} {
        grid-column-start: contentStart;
        grid-column-end: viewPortEnd;
      }
    `}
`;
const LayoutRowGridContainer = styled.div `
  ${props => props.colorVariant === LayoutRowColorVariant.Fir &&
    `
    background-color:  ${props.theme.color.fir};
    color: ${props.theme.color.pearl};
    `}

  ${props => props.colorVariant === LayoutRowColorVariant.Horizon &&
    `
      background-color:  ${props.theme.color.horizon4};
      color: ${props.theme.color.pearl};
    `}

  ${props => props.colorVariant === LayoutRowColorVariant.Cave &&
    `
      background-color:  ${props.theme.color.cave};
      color: ${props.theme.color.pearl};
    `}

  ${props => props.colorVariant === LayoutRowColorVariant.Moss &&
    `
      background-color:  ${props.theme.color.moss};
    `}

  ${props => props.colorVariant === LayoutRowColorVariant.Pearl &&
    `
      background-color: ${props.theme.color.pearl};
    `}

  ${props => props.colorVariant === LayoutRowColorVariant.Alge &&
    `
      background-color: ${props.theme.color.alge};
    `}


  ${props => props.theme.layout.gridContainer}

  margin-bottom: 100px;

  ${props => props.enableTopMargin &&
    `
    padding-top: 50px;
  `}

  @media ${props => props.theme.device.tablet} {
    margin-bottom: 200px;

    ${props => props.enableTopMargin &&
    `
      padding-top: 100px;
    `}

    ${props => props.bottomVariant === LayoutRowBottomVariant.NoMargin &&
    css `
        margin-bottom: initial;
      `}

    ${props => props.bottomVariant === LayoutRowBottomVariant.ExtraSmallMargin &&
    css `
        margin-bottom: 20px;
      `}

    ${props => props.bottomVariant === LayoutRowBottomVariant.SmallMargin &&
    css `
        margin-bottom: 60px;
      `}

    ${props => props.bottomVariant === LayoutRowBottomVariant.MediumMargin &&
    css `
        margin-bottom: 100px;
      `}
  }

  ${props => props.bottomVariant === LayoutRowBottomVariant.NoMargin &&
    css `
      margin-bottom: initial;
    `}

  ${props => props.bottomVariant === LayoutRowBottomVariant.ExtraSmallMargin &&
    css `
      margin-bottom: 20px;
    `}

  ${props => props.bottomVariant === LayoutRowBottomVariant.SmallMargin &&
    css `
      margin-bottom: 60px;
    `}

  ${props => props.bottomVariant === LayoutRowBottomVariant.MediumMargin &&
    css `
      margin-bottom: 100px;
    `}
`;
export const LayoutRow = ({ children, horizontalVariant, bottomVariant, colorVariant = LayoutRowColorVariant.Default, enableTopMargin = false, }) => {
    return (_jsx(LayoutRowGridContainer, Object.assign({ colorVariant: colorVariant, bottomVariant: bottomVariant, enableTopMargin: enableTopMargin }, { children: _jsx(LayoutRowWrapper, Object.assign({ horizontalVariant: horizontalVariant }, { children: children })) })));
};
const Layout = styled.div `
  display: flex;
  flex-direction: column;
  width: 100%;
`;
export default Layout;
