import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import styled from 'styled-components/macro';
import Icon, { IconSizeVariant } from '../Icon/Icon';
import Text, { TextVariant } from '../Text/Text';
import { ListingChecklistItem } from '.';
const ChecklistWrapper = styled.div `
  width: 100%;
`;
const TitleWrapper = styled.div `
  display: flex;
  flex-direction: column;
  align-items: left;
  margin-bottom: 20px;
  width: fit-content;
`;
const IconWrapper = styled.div `
  padding: 0 15px 15px;
  width: fit-content;
  margin-bottom: -5px;
`;
const ListingChecklist = ({ iconVariant, title, items, }) => {
    return (_jsxs(ChecklistWrapper, { children: [_jsxs(TitleWrapper, { children: [_jsx(IconWrapper, { children: _jsx(Icon, { variant: iconVariant, sizeVariant: IconSizeVariant.Large }) }), _jsx(Text, Object.assign({ variant: TextVariant.H20 }, { children: title }))] }), items.map((item, index) => (_jsxs(_Fragment, { children: [_jsx(ListingChecklistItem, { isChecked: item.isChecked, text: item.text }, item.text), index !== items.length - 1 && _jsx("hr", {})] })))] }));
};
export default ListingChecklist;
