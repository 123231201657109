var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import styled, { css } from 'styled-components/macro';
import { Controller, useFormContext } from 'react-hook-form';
import IconButtonOld, { OldButtonBorderRadiusVariant, OldIconButtonColorVariant, } from '../IconButtonOld/IconButtonOld';
import { IconVariant } from '../Icon/Icon';
import Text, { TextVariant } from '../Text/Text';
import { ColorVariant } from '../../theme';
export var SteppedNumberInputVariant;
(function (SteppedNumberInputVariant) {
    SteppedNumberInputVariant["Default"] = "Transparent";
    SteppedNumberInputVariant["Grey"] = "Grey";
    SteppedNumberInputVariant["White"] = "White";
})(SteppedNumberInputVariant || (SteppedNumberInputVariant = {}));
const AccessibilityInput = styled.input `
  display: none;
`;
const Wrapper = styled.div `
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
const NumberDisplay = styled(Text) `
  display: block;
  padding: 6px 10px 6px 15px;
  color: ${props => props.theme.color.cave};
  height: 62px;
  line-height: 50px;
`;
const Content = styled.div `
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  ${props => (props === null || props === void 0 ? void 0 : props.variant) === SteppedNumberInputVariant.Grey &&
    css `
      background: ${props.theme.color.snow};
    `}
  ${props => (props === null || props === void 0 ? void 0 : props.variant) === SteppedNumberInputVariant.White &&
    css `
      background: ${props.theme.color.pearl};
    `}
`;
const SteppedNumberInput = (_a) => {
    var { dataTestId, min, max, step = 1, steps, variant = SteppedNumberInputVariant.Default, label, customLabel, value, onValueChange, valueSuffix, name } = _a, inputProps = __rest(_a, ["dataTestId", "min", "max", "step", "steps", "variant", "label", "customLabel", "value", "onValueChange", "valueSuffix", "name"]);
    const formContext = useFormContext();
    const control = formContext ? formContext.control : undefined;
    const [internalValue, setInternalValue] = useState(value);
    const increment = () => {
        const currentValue = (control ? internalValue : value);
        let newValue = (control ? internalValue : value) + step;
        if (steps) {
            const currentIndex = steps.findIndex(element => element === currentValue);
            newValue = steps[currentIndex + 1];
        }
        if (!max || newValue <= max) {
            if (control) {
                setInternalValue(newValue);
            }
            return newValue;
        }
        return internalValue;
    };
    const decrement = () => {
        const currentValue = (control ? internalValue : value);
        let newValue = (control ? internalValue : value) - step;
        if (steps) {
            const currentIndex = steps.findIndex(element => element === currentValue);
            newValue = steps[currentIndex - 1];
        }
        if (!min || newValue >= min) {
            if (control) {
                setInternalValue(newValue);
            }
            return newValue;
        }
        return internalValue;
    };
    if (control) {
        return (_jsx(Controller, { name: name, control: control, defaultValue: value, render: ({ field: { onChange, value } }) => (_jsxs(Wrapper, Object.assign({ "data-testid": dataTestId }, { children: [label && (_jsx(Text, Object.assign({ colorVariant: ColorVariant.FunctionGranitGrey, variant: TextVariant.L4SB }, { children: label }))), customLabel, _jsxs(Content, Object.assign({ variant: variant }, { children: [_jsx(IconButtonOld, { dataTestId: "SteppedNumberInput_MinusButton", buttonBorderRadiusVariant: OldButtonBorderRadiusVariant.Squared, disabled: value <= min, iconVariant: IconVariant.Minus, colorVariant: OldIconButtonColorVariant.Transparent, onClick: () => onChange(decrement()), type: "button" }), _jsxs(NumberDisplay, { children: [value === null || value === void 0 ? void 0 : value.toString(), valueSuffix ? ` ${valueSuffix}` : ''] }), _jsx(IconButtonOld, { dataTestId: "SteppedNumberInput_PlusButton", buttonBorderRadiusVariant: OldButtonBorderRadiusVariant.Squared, disabled: value >= max, iconVariant: IconVariant.Plus, colorVariant: OldIconButtonColorVariant.Transparent, onClick: () => onChange(increment()), type: "button" }), _jsx(AccessibilityInput, Object.assign({ type: "number", min: min, max: max, value: value, readOnly: true }, inputProps))] }))] }))) }));
    }
    return (_jsxs(Wrapper, Object.assign({ "data-testid": dataTestId }, { children: [label && (_jsx(Text, Object.assign({ colorVariant: ColorVariant.FunctionGranitGrey, variant: TextVariant.L4SB }, { children: label }))), customLabel, _jsxs(Content, Object.assign({ variant: variant }, { children: [_jsx(IconButtonOld, { dataTestId: "SteppedNumberInput_MinusButton", buttonBorderRadiusVariant: OldButtonBorderRadiusVariant.Squared, disabled: value <= min, iconVariant: IconVariant.Minus, colorVariant: OldIconButtonColorVariant.Transparent, onClick: () => onValueChange && onValueChange(decrement()), type: "button" }), _jsxs(NumberDisplay, { children: [value === null || value === void 0 ? void 0 : value.toString(), valueSuffix ? ` ${valueSuffix}` : ''] }), _jsx(IconButtonOld, { dataTestId: "SteppedNumberInput_PlusButton", buttonBorderRadiusVariant: OldButtonBorderRadiusVariant.Squared, disabled: value >= max, iconVariant: IconVariant.Plus, colorVariant: OldIconButtonColorVariant.Transparent, onClick: () => onValueChange && onValueChange(increment()), type: "button" }), _jsx(AccessibilityInput, Object.assign({ type: "number", min: min, max: max, value: value, readOnly: true }, inputProps))] }))] })));
};
export default SteppedNumberInput;
