import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { pathByRouteName } from '@nomady/shared/utils/routes';
import routeConfiguration from '@nomady/shared/routeConfiguration/routeConfiguration';
import AddToFavoritesModal from '../../AddToFavoritesModal';
import { useDispatch, useSelector } from 'react-redux';
import { listingIdsInListingFavoritesListsSelector } from '../../../slices/listingFavoritesListsSlice';
import { isAuthenticatedSelector } from '../../../ducks/Auth.duck';
import { useHistory } from 'react-router-dom';
import { ModalId, setLastOpenModal, } from '../../../slices/UISlice';
import Page from '@nomady/shared/routeConfiguration/Page';
import IconButton, { IconButtonColorVariant, IconButtonRadiusVariant, IconButtonSizeVariant, } from '../../IconButton/IconButton';
import { IconVariant } from '../../Icon/Icon';
const LikeButton = ({ listingId }) => {
    const [favoriteBottomSheetOpen, setFavoriteBottomSheetOpen] = useState(false);
    const liked = useSelector(listingIdsInListingFavoritesListsSelector(listingId.uuid));
    const isAuthenticated = useSelector(isAuthenticatedSelector);
    const currentURL = useSelector((state) => state.Routing.currentCanonicalUrl);
    const history = useHistory();
    const dispatch = useDispatch();
    const openFavoritesBottomSheet = () => {
        if (isAuthenticated) {
            setFavoriteBottomSheetOpen(true);
        }
        else {
            const path = pathByRouteName(Page.Signup, routeConfiguration);
            const payload = {
                modalId: ModalId.AddToFavorites,
                metaData: Object.assign(Object.assign({}, listingId), { backUrl: currentURL || undefined }),
            };
            dispatch(setLastOpenModal(payload));
            history.push(path);
        }
    };
    return (_jsxs("div", Object.assign({ onClick: event => {
            event.stopPropagation();
            event.preventDefault();
        } }, { children: [_jsx(IconButton, { iconVariant: liked
                    ? IconVariant.HeartFavorites_Filled
                    : IconVariant.HeartFavorites_Outline, colorVariant: IconButtonColorVariant.Red, onClick: openFavoritesBottomSheet, iconButtonSizeVariant: IconButtonSizeVariant.ExtraSmall, radiusVariant: IconButtonRadiusVariant.Small, "aria-label": "Add to favorites" }), _jsx(AddToFavoritesModal, { open: favoriteBottomSheetOpen, onClose: () => setFavoriteBottomSheetOpen(false), listingId: listingId })] })));
};
export default LikeButton;
