import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useRef, useState, useEffect } from 'react';
import styled, { css } from 'styled-components/macro';
import { isBrowser } from '@nomady/shared/utils/env';
import { ColorVariant } from '../../theme';
import BackDrop from '../BackDrop/BackDrop';
import Icon, { IconVariant } from '../Icon/Icon';
import IconButtonOld, { OldIconButtonColorVariant, OldIconButtonSizeVariant, } from '../IconButtonOld/IconButtonOld';
import Text, { TextVariant } from '../Text/Text';
const Wrapper = styled.div `
  display: flex;
  flex-direction: column;
  min-width: 280px;
  position: relative;
`;
const LabelAndDescription = styled.div `
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 100%;
`;
const UnifiedBackgroundColor = styled.div `
  width: 100%;
  display: flex;
  flex-direction: row;
  border: 1px solid;
  border-color: ${props => props.isOpen ? props.theme.color.indigo : props.theme.color.snow};

  background-color: ${props => {
    if (props.activatedFilters) {
        return props.theme.color.cactus;
    }
    if (props.isOpen || props.forcePearlBackground) {
        return props.theme.color.pearl;
    }
    return props.theme.color.snow;
}};
  border-radius: 10px;

  color: ${props => props.isOpen ? props.theme.color.snow : props.theme.color.tar};

  &:hover {
    border-color: ${props => props.theme.color.stone};
    cursor: pointer;
  }

  &:focus {
    border-color: ${props => props.theme.color.indigo};
    background-color: ${props => props.theme.color.pearl};
  }
`;
const ClearFilterButton = styled.button `
  border: 0px;
  padding-right: 20px;
  padding-left: 20px;
  border-left: 1px solid ${props => props.theme.color.stone};
  margin-top: 3px;
  margin-bottom: 3px;
  cursor: pointer;
  width: 50px;
`;
const FilterCard = styled.button `
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: ${props => (props.activatedFilters ? 'calc(100% - 65px)' : '100%')};

  padding-left: 10px;
  padding-bottom: ${props => (props.activatedFilters ? '13px' : '20px')};
  padding-top: ${props => (props.activatedFilters ? '14px' : '20px')};
  padding-right: 30px;
  border: 0px;
  cursor: pointer;
`;
const Label = styled(Text) `
  margin-left: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  width: 100%;
`;
const Title = styled.div `
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
`;
const BackDropWrapper = styled.div `
  display: none;

  @media ${props => props.theme.device.tablet} {
    display: block;
  }
`;
const ChildrenWrapper = styled.div `
  display: none;

  ${props => props.isOpen &&
    css `
      display: block;
      margin-top: 20px;
      margin-left: 15px;
      margin-bottom: 15px;
      border-left: 2px solid ${props => props.theme.color.snow};
      overflow-y: auto;

      @media ${props.theme.device.tablet} {
        background: ${props.theme.color.pearl};
        padding-bottom: 10px;
        position: absolute;
        left: 0;
        right: 0;
        border-radius: 10px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

        margin-top: initial;
        margin-left: initial;
        margin-bottom: initial;
        border-left: initial;

        ${props.theme.priority.high};

        ${props.clientStyle &&
        css `
          top: ${props.clientStyle.top}px;
          max-height: ${props.clientStyle.maxHeight}px;
        `}
      }
    `}
`;
const InputLanguageSelectFilterGroup = ({ iconVariant, label, children, activatedFilters = '', onReset, forcePearlBackground, isForceClosed, resetForceClose, }) => {
    const [open, setOpen] = useState(false);
    const buttonRef = useRef();
    const getChildrenWrapperPosition = () => {
        if (isBrowser() && buttonRef.current) {
            const boundingClientRect = buttonRef.current.getBoundingClientRect();
            const top = boundingClientRect.height + 3;
            const bottomMargin = 20;
            return {
                top,
                maxHeight: window.innerHeight - top - boundingClientRect.top - bottomMargin,
            };
        }
        return undefined;
    };
    const childrenWrapperClientStyle = getChildrenWrapperPosition();
    useEffect(() => {
        if (isForceClosed) {
            setOpen(false);
            buttonRef.current.blur();
        }
    }, [isForceClosed]);
    return (_jsxs(Wrapper, { children: [open && (_jsx(BackDropWrapper, { children: _jsx(BackDrop, { onClick: () => {
                        setOpen(false);
                        resetForceClose === null || resetForceClose === void 0 ? void 0 : resetForceClose();
                    }, fullTransparency: true }) })), _jsxs(UnifiedBackgroundColor, Object.assign({ isOpen: open, activatedFilters: activatedFilters, forcePearlBackground: forcePearlBackground }, { children: [_jsx(FilterCard, Object.assign({ type: "button", onClick: () => {
                            setOpen(!open);
                            resetForceClose === null || resetForceClose === void 0 ? void 0 : resetForceClose();
                        }, isOpen: open, ref: buttonRef, activatedFilters: activatedFilters }, { children: _jsxs(Title, { children: [_jsx(Icon, { variant: iconVariant, colorVariant: activatedFilters ? ColorVariant.Snow : ColorVariant.Cave }), _jsxs(LabelAndDescription, { children: [_jsx(Label, Object.assign({ variant: TextVariant.H13, colorVariant: activatedFilters ? ColorVariant.Snow : ColorVariant.Cave }, { children: label })), _jsx(Label, Object.assign({ variant: TextVariant.H14, colorVariant: ColorVariant.Snow }, { children: activatedFilters }))] }), !activatedFilters && (_jsx(IconButtonOld, { iconVariant: open ? IconVariant.ArrowUp : IconVariant.ArrowDown, iconButtonSizeVariant: OldIconButtonSizeVariant.Small, colorVariant: OldIconButtonColorVariant.Transparent }))] }) })), activatedFilters && (_jsx(ClearFilterButton, Object.assign({ type: "button", onClick: onReset }, { children: _jsx(IconButtonOld, { type: "button", iconVariant: IconVariant.Cross, iconButtonSizeVariant: OldIconButtonSizeVariant.Small, colorVariant: OldIconButtonColorVariant.Cactus }) })))] })), _jsx(ChildrenWrapper, Object.assign({ isOpen: open, clientStyle: childrenWrapperClientStyle }, { children: open && children }))] }));
};
export default InputLanguageSelectFilterGroup;
