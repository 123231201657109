var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState } from 'react';
import styled, { css } from 'styled-components/macro';
import { useIntl } from 'react-intl';
import Markdown from 'react-showdown';
import Icon, { IconVariant } from '../Icon/Icon';
import Text, { TextVariant } from '../Text/Text';
const AccordionWrapper = styled.div `
  width: 100%;
`;
const Section = styled.div `
  border-top: 1px solid ${props => props.theme.color.stone};

  ${props => props.isLast &&
    css `
      border-bottom: 1px solid ${props => props.theme.color.stone};
    `}
`;
const SectionToggle = styled.button `
  width: 100%;
  border: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: transparent;
`;
const SectionTitle = styled(Text) `
  text-align: left;
  margin: 0;
  padding: 35px 0;
`;
const ShareLink = styled.a `
  display: flex;
  text-align: left;
  margin: 0;
  margin-left: 15px;
  padding: 35px 0;
  color: ${props => props.theme.color.tar};

  ${props => props.theme.typeset.H9};
`;
const PaddedIcon = styled.div `
  padding: 35px 8px;
  text-align: right;
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
`;
const SectionContentWrapper = styled.div `
  padding-bottom: 25px;
`;
const ElementText = styled(Text) `
  display: block;
  margin-bottom: 20px;
  margin-left: 20px;
`;
const JobAccordion = ({ jobs, sharedText, shareTitle, }) => {
    const intl = useIntl();
    const [displayedOpenPosition, setDisplayedOpenPosition] = useState([]);
    const handleShareLinkClick = (job) => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b, _c;
        const preparedMessage = sharedText
            .replace('{jobTitle}', job.title)
            .replace('{pdfLink}', ((_c = (_b = (_a = job.pdf) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.attributes) === null || _c === void 0 ? void 0 : _c.url) || '');
        try {
            yield navigator.share({ title: shareTitle, text: preparedMessage });
        }
        catch (err) {
            window.open(`mailto:your@email.address?subject=${shareTitle}&body=${preparedMessage}`);
        }
    });
    const handleSectionOnClick = (openPosition) => {
        setDisplayedOpenPosition(displayedOpenPosition.includes(openPosition.id)
            ? displayedOpenPosition.filter((element) => element !== openPosition.id)
            : [...displayedOpenPosition, openPosition.id]);
    };
    return (_jsx(AccordionWrapper, { children: jobs.map((job, sectionIndex) => {
            var _a, _b, _c, _d, _e, _f;
            return (_jsxs(Section, Object.assign({ isLast: jobs.length === sectionIndex + 1 }, { children: [_jsxs(SectionToggle, Object.assign({ "aria-expanded": displayedOpenPosition.includes(job.id), "aria-controls": `section-${job.id}` }, { children: [_jsx(SectionTitle, Object.assign({ variant: TextVariant.H4, as: "h4", onClick: () => handleSectionOnClick(job) }, { children: job.title })), ((_c = (_b = (_a = job.pdf) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.attributes) === null || _c === void 0 ? void 0 : _c.url) && (_jsxs(_Fragment, { children: [_jsxs(ShareLink, Object.assign({ onClick: () => handleShareLinkClick(job) }, { children: [_jsx(Icon, { variant: IconVariant.Upload }), intl.formatMessage({ id: 'JobAccordion.share' })] })), _jsxs(ShareLink, Object.assign({ href: (_f = (_e = (_d = job.pdf) === null || _d === void 0 ? void 0 : _d.data) === null || _e === void 0 ? void 0 : _e.attributes) === null || _f === void 0 ? void 0 : _f.url }, { children: [_jsx(Icon, { variant: IconVariant.Download_NEW }), intl.formatMessage({ id: 'JobAccordion.download' })] }))] })), _jsx(PaddedIcon, Object.assign({ onClick: () => handleSectionOnClick(job) }, { children: _jsx(Icon, { variant: displayedOpenPosition.includes(job.id)
                                        ? IconVariant.Minus
                                        : IconVariant.Plus }) }))] })), displayedOpenPosition.includes(job.id) && (_jsx(SectionContentWrapper, Object.assign({ id: `section-${job.id}` }, { children: _jsx(ElementText, Object.assign({ id: `element-${job.id}`, itemProp: "text" }, { children: _jsx(Markdown, { markdown: job.description }) })) })))] }), job.id));
        }) }));
};
export default JobAccordion;
