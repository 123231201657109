import { LINE_ITEM_ADULTS, LINE_ITEM_ADULT_TAXES, LINE_ITEM_CHILDREN, LINE_ITEM_CHILD_TAXES, LINE_ITEM_CLEANING_FEE, LINE_ITEM_CUSTOMER_COMMISSION, LINE_ITEM_CUSTOMER_COMMISSION_DISCOUNT, LINE_ITEM_EXTRAS_FIREWOOD, LINE_ITEM_EXTRAS_POWER_SUPPLY, LINE_ITEM_GIFT_CARD_DEDUCTION_1, LINE_ITEM_GIFT_CARD_DEDUCTION_2, LINE_ITEM_GIFT_CARD_DEDUCTION_3, LINE_ITEM_INFANTS, LINE_ITEM_DOGS, LINE_ITEM_PROVIDER_COMMISSION, LINE_ITEM_SEATS, LINE_ITEM_TEENS, LINE_ITEM_TEEN_TAXES, } from '@nomady/shared/utils/lineItemTypes';
export const GiftCardLineItems = [
    LINE_ITEM_GIFT_CARD_DEDUCTION_1,
    LINE_ITEM_GIFT_CARD_DEDUCTION_2,
    LINE_ITEM_GIFT_CARD_DEDUCTION_3,
];
const groupLineItems = (lineItems) => {
    const lineItemGroups = {
        basePrice: [LINE_ITEM_SEATS, LINE_ITEM_ADULTS],
        additionalGuests: [
            LINE_ITEM_CHILDREN,
            LINE_ITEM_TEENS,
            LINE_ITEM_INFANTS,
            LINE_ITEM_DOGS,
        ],
        taxesAndFees: [
            LINE_ITEM_ADULT_TAXES,
            LINE_ITEM_TEEN_TAXES,
            LINE_ITEM_CHILD_TAXES,
            LINE_ITEM_CUSTOMER_COMMISSION,
            LINE_ITEM_PROVIDER_COMMISSION,
            LINE_ITEM_CLEANING_FEE,
        ],
        discount: [LINE_ITEM_CUSTOMER_COMMISSION_DISCOUNT],
        payment: GiftCardLineItems,
        additionalServices: [
            LINE_ITEM_EXTRAS_FIREWOOD,
            LINE_ITEM_EXTRAS_POWER_SUPPLY,
        ],
    };
    const groupedLineItems = {};
    if (!lineItems) {
        return groupedLineItems;
    }
    Object.keys(lineItemGroups).forEach(group => {
        const groupItems = lineItemGroups[group];
        const groupLineItems = lineItems.filter(lineItem => groupItems.includes(lineItem.code));
        groupedLineItems[group] = groupLineItems;
    });
    return groupedLineItems;
};
export default groupLineItems;
