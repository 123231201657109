import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styled from 'styled-components/macro';
import { useSelector } from 'react-redux';
import { formatDisplayMoney } from '@nomady/shared/utils/currency';
import Page from '@nomady/shared/routeConfiguration/Page';
import { NamedLink } from '../../components';
import Text, { TextVariant } from '../Text/Text';
import { ColorVariant } from '../../theme';
import { localeSelector } from '../../slices/UISlice';
import Avatar, { SizeVariant } from '../Avatar/Avatar';
import TransactionTag from '../TransactionTag/TransactionTag';
export const DESKTOP_TEMPLATE_COLUMNS = '100px 150px 46px 160px 50px auto 100px 100px 100px';
export var BookingCardVariant;
(function (BookingCardVariant) {
    BookingCardVariant["Default"] = "Default";
    BookingCardVariant["Flag"] = "Flag";
    BookingCardVariant["Summary"] = "Summary";
})(BookingCardVariant || (BookingCardVariant = {}));
export var BookingCardShadowVariant;
(function (BookingCardShadowVariant) {
    BookingCardShadowVariant["Default"] = "Default";
    BookingCardShadowVariant["Shadow"] = "Shadow";
})(BookingCardShadowVariant || (BookingCardShadowVariant = {}));
const Wrapper = styled.div `
  display: grid;
  flex-grow: 1;

  border-radius: 5px;
  background-color: ${props => props.theme.color.pearl};
  overflow: hidden;

  padding: 7px 10px 5px 10px;

  grid-template-columns: min-content 20px auto auto;
  grid-template-rows: auto auto auto;

  align-items: center;
`;
const PositionedTransactionTag = styled.div `
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 1;
  grid-row-end: 2;
  display: flex;
`;
const LineWrapper = styled.section `
  display: flex;

  align-items: center;
`;
const PositionedImage = styled.div `
  width: 185px;

  display: none;
  @media ${props => props.theme.device.desktop} {
    display: flex;
  }
`;
const Picture = styled.div `
  background-position: center center;
  display: flex;
  width: ${props => (props.fullWidth ? '185px' : '90px')};
  height: 85px;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background-size: cover;
  margin-right: 5px;
  background-image: url(${props => props.backgroundImage});
`;
const PositionedAvatar = styled.div `
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 2;
  grid-row-end: 4;

  padding-top: 10px;
  padding-right: 10px;
`;
const PositionedListingName = styled(Text) `
  grid-column-start: 2;
  grid-column-end: 6;
  grid-row-start: 2;
  grid-row-end: 3;

  padding-top: 5px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const PositionedProviderName = styled(Text) `
  grid-column-start: 2;
  grid-column-end: 5;
  grid-row-start: 3;
  grid-row-end: 4;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const PositionedPayinTotal = styled(Text) `
  grid-column-start: 4;
  grid-column-end: 6;
  grid-row-start: 3;
  grid-row-end: 4;

  text-align: right;
`;
const PositionedDates = styled(Text) `
  grid-column-start: 4;
  grid-column-end: 6;
  grid-row-start: 1;
  grid-row-end: 2;

  text-align: right;
`;
const PositionedArrivalDate = styled(Text) `
  display: none;
`;
const StyledTransitionAtText = styled(Text) `
  margin-left: 5px;
`;
const PositionedDepartureDate = styled(Text) `
  display: none;
`;
const JourneyCard = ({ id, listingName, provider, arrivalDate, departureDate, payinTotal, status, currency, lastTransitionedAt, listingImages, }) => {
    var _a;
    const locale = useSelector(localeSelector);
    const getImageUrl = (image) => {
        var _a, _b;
        return ((_b = (_a = image === null || image === void 0 ? void 0 : image.attributes) === null || _a === void 0 ? void 0 : _a.variants['square-small']) === null || _b === void 0 ? void 0 : _b.url) || '';
    };
    return (_jsx(NamedLink, Object.assign({ name: Page.OrderDetails, params: {
            id: id.uuid,
        } }, { children: _jsxs(LineWrapper, { children: [_jsxs(PositionedImage, { children: [listingImages.length > 1 && (_jsxs(_Fragment, { children: [_jsx(Picture, { backgroundImage: getImageUrl(listingImages[0]) }), _jsx(Picture, { backgroundImage: getImageUrl(listingImages[1]) })] })), listingImages.length === 1 && (_jsx(Picture, { fullWidth: true, backgroundImage: getImageUrl(listingImages[0]) }))] }), _jsxs(Wrapper, { children: [_jsxs(PositionedTransactionTag, { children: [_jsx(TransactionTag, { status: status }), _jsx(StyledTransitionAtText, Object.assign({ variant: TextVariant.B60 }, { children: lastTransitionedAt.toLocaleDateString(locale, {
                                        year: '2-digit',
                                        month: 'numeric',
                                        day: 'numeric',
                                    }) }))] }), provider && (_jsx(PositionedAvatar, { children: _jsx(Avatar, { sizeVariant: SizeVariant.XS, initials: provider.attributes.profile.abbreviatedName, source: (_a = provider.profileImage) === null || _a === void 0 ? void 0 : _a.attributes.variants['square-small'].url }) })), _jsx(PositionedListingName, Object.assign({ variant: TextVariant.H50 }, { children: listingName })), provider && (_jsx(PositionedProviderName, Object.assign({ variant: TextVariant.B60 }, { children: provider.attributes.profile.displayName }))), _jsxs(PositionedPayinTotal, Object.assign({ variant: TextVariant.B50 }, { children: [currency, " ", formatDisplayMoney(payinTotal)] })), arrivalDate && departureDate && (_jsx(_Fragment, { children: _jsxs(PositionedDates, Object.assign({ variant: TextVariant.B50, colorVariant: ColorVariant.Cave }, { children: [arrivalDate.toLocaleDateString(locale, {
                                        year: arrivalDate.getFullYear() !== departureDate.getFullYear()
                                            ? '2-digit'
                                            : undefined,
                                        month: 'numeric',
                                        day: 'numeric',
                                    }), "\u2013", departureDate.toLocaleDateString(locale, {
                                        year: '2-digit',
                                        month: 'numeric',
                                        day: 'numeric',
                                    })] })) })), arrivalDate && (_jsx(PositionedArrivalDate, Object.assign({ variant: TextVariant.B50, colorVariant: ColorVariant.Cave }, { children: arrivalDate.toLocaleDateString(locale, {
                                year: 'numeric',
                                month: 'numeric',
                                day: 'numeric',
                                weekday: 'short',
                            }) }))), departureDate && (_jsx(PositionedDepartureDate, Object.assign({ variant: TextVariant.B50, colorVariant: ColorVariant.Cave }, { children: departureDate.toLocaleDateString(locale, {
                                year: 'numeric',
                                month: 'numeric',
                                day: 'numeric',
                                weekday: 'short',
                            }) })))] })] }) })));
};
export default JourneyCard;
