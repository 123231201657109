import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import CmsContentPage from './CmsContentPage';
import { loadRegionData } from './CmsContentPageSlice';
import { useDispatch } from 'react-redux';
export const RegionWrapper = (props) => {
    const { name } = useParams();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(loadRegionData({ name }));
    }, [dispatch, name]);
    return _jsx(CmsContentPage, Object.assign({}, props));
};
