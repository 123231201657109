import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { ColorVariant } from '../../theme';
import { injectIntl } from '../../util/reactIntl';
import RadioInput, { RadioInputSpacingVariant } from '../RadioInput/RadioInput';
import RangeSliderInput from '../RangeSliderInput/RangeSliderInput';
import Text, { TextVariant } from '../Text/Text';
const Wrapper = styled.div `
  display: flex;
  flex-direction: column;
  min-width: 280px;
  background: ${props => props.theme.color.snow};
  padding: 15px;
`;
const AmountWrapper = styled.div `
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
`;
const InputWrapper = styled.div `
  display: flex;
  align-items: center;
  flex-direction: row;
`;
const InputSeparator = styled.div `
  ${props => props.theme.typeset.H8};
  color: ${props => props.theme.color.cave};

  margin-left: 4px;
  margin-right: 4px;
`;
const RangeSliderInputWrapper = styled.div `
  flex-basis: 100%;
`;
const FakeInput = styled.input `
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px 0;
  width: 68px;
  height: 62px;
  border: 0;

  ${props => props.theme.typeset.H8}

  text-align:center;

  background: ${props => props.theme.color.snow};
  border-radius: 10px;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
const CurrencyWrapper = styled.div `
  margin-top: 10px;
`;
const PriceSliderInput = ({ min, max, step, values, defaultMin, defaultMax, onChange, currencies, currency, onChangeCurrency, intl, }) => {
    const valuesOrDefault = values || [defaultMin, defaultMax];
    const [intermediateValues, setIntermediateValues] = useState(valuesOrDefault);
    useEffect(() => {
        if ((values === null || values === void 0 ? void 0 : values.length) === 2) {
            setIntermediateValues(values);
        }
    }, [values]);
    const handleLowerValueInput = (event) => {
        const inputValue = parseInt(event.target.value, 10);
        const newValues = [intermediateValues[1]];
        if (intermediateValues[1] < inputValue) {
            newValues.unshift(intermediateValues[1]);
        }
        else if (inputValue < min) {
            newValues.unshift(min);
        }
        else {
            newValues.unshift(inputValue);
        }
        setIntermediateValues(values);
        onChange(newValues);
    };
    const handleUpperValueInput = (event) => {
        const inputValue = parseInt(event.target.value, 10);
        const newValues = [intermediateValues[0]];
        if (max < inputValue) {
            newValues.push(max);
        }
        else if (inputValue < intermediateValues[0]) {
            newValues.push(intermediateValues[0]);
        }
        else {
            newValues.push(inputValue);
        }
        setIntermediateValues(values);
        onChange(newValues);
    };
    const handleSliderChange = (newValues) => {
        setIntermediateValues(newValues);
    };
    return (_jsxs(Wrapper, { children: [_jsxs(AmountWrapper, { children: [_jsx(Text, Object.assign({ variant: TextVariant.H13, colorVariant: ColorVariant.Cave }, { children: intl.formatMessage({ id: 'PriceSliderInput.price' }) })), _jsxs(InputWrapper, { children: [_jsx(FakeInput, { type: "number", value: intermediateValues[0], step: step, style: { order: 1 }, onChange: handleLowerValueInput }), _jsx(InputSeparator, Object.assign({ style: { order: 2 } }, { children: "\u2013" })), _jsx(FakeInput, { type: "number", value: intermediateValues[1], step: step, style: { order: 3 }, onChange: handleUpperValueInput })] }), _jsx(RangeSliderInputWrapper, { children: _jsx(RangeSliderInput, { min: min, max: max, step: step, handles: intermediateValues, onChange: handleSliderChange, onFinal: () => onChange(intermediateValues) }) })] }), _jsxs(CurrencyWrapper, { children: [_jsx(RadioInput, { id: `currency-all`, spacingVariant: RadioInputSpacingVariant.Slim, checked: !currency, label: intl.formatMessage({ id: 'PriceSliderInput.allCurrencies' }), onChange: () => onChangeCurrency(undefined) }, `currency-all`), currencies.map(element => (_jsx(RadioInput, { id: `currency-${element}`, spacingVariant: RadioInputSpacingVariant.Slim, checked: element === currency, label: element, onChange: () => onChangeCurrency(element) }, `currency-${element}`)))] })] }));
};
export default injectIntl(PriceSliderInput);
