import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import { CampCategory } from '@nomady/shared/types';
import { campStyles } from '@nomady/shared/marketplace-custom-config';
import Icon, { IconSizeVariant, IconVariant, } from '../../../styled-components/Icon/Icon';
import Text, { TextVariant } from '../../../styled-components/Text/Text';
import { ColorVariant } from '../../../theme';
import campStyleIcons from '../../../campStyleIcons';
const Wrapper = styled.div `
  display: flex;
  flex-direction: column;
`;
const Element = styled.div `
  display: flex;
  padding: 12px 5px;
  flex-direction: column;
  border-bottom: 1px solid ${props => props.theme.color.fog};

  @media ${props => props.theme.device.tablet} {
    &:last-child {
      border-bottom: 0px none;
    }
  }
`;
const TitleWrapper = styled.div `
  display: flex;
`;
const DescriptionWrapper = styled.div ``;
const TextStyled = styled(Text) `
  margin-left: 15px;
`;
const CampStyleDetailsContent = () => {
    var _a, _b, _c, _d;
    const { listing } = useSelector((state) => state.ListingPage);
    const intl = useIntl();
    const activeCampStyles = ((_b = (_a = listing === null || listing === void 0 ? void 0 : listing.attributes) === null || _a === void 0 ? void 0 : _a.publicData) === null || _b === void 0 ? void 0 : _b.campStyle) || [];
    const listingCategory = (_d = (_c = listing === null || listing === void 0 ? void 0 : listing.attributes) === null || _c === void 0 ? void 0 : _c.publicData) === null || _d === void 0 ? void 0 : _d.category;
    return (_jsx(Wrapper, { children: listingCategory === CampCategory.camping ? (_jsx(_Fragment, { children: campStyles
                .filter(campStyle => activeCampStyles.includes(campStyle.key))
                .map(campStyle => {
                return (_jsxs(Element, { children: [_jsxs(TitleWrapper, { children: [_jsx(Icon, { variant: campStyleIcons[campStyle.key], sizeVariant: IconSizeVariant.Small }), _jsx(TextStyled, Object.assign({ variant: TextVariant.H51, colorVariant: ColorVariant.Cave }, { children: intl.formatMessage({ id: campStyle.label }) }))] }), _jsx(DescriptionWrapper, { children: _jsx(Text, Object.assign({ variant: TextVariant.H52 }, { children: intl.formatMessage({
                                    id: `ListingPage.campStyleDescription.${campStyle.key}`,
                                }) })) })] }, campStyle.key));
            }) })) : (_jsxs(Element, { children: [_jsxs(TitleWrapper, { children: [_jsx(Icon, { variant: IconVariant.Hut, sizeVariant: IconSizeVariant.Small }), _jsx(TextStyled, Object.assign({ variant: TextVariant.H51, colorVariant: ColorVariant.Cave }, { children: intl.formatMessage({ id: 'MarketplaceConfig.accommodation' }) }))] }), _jsx(DescriptionWrapper, { children: _jsx(Text, Object.assign({ variant: TextVariant.H52 }, { children: intl.formatMessage({
                            id: 'ListingPage.campStyleDescription.accommodation',
                        }) })) })] })) }));
};
export default CampStyleDetailsContent;
