import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styled, { css } from 'styled-components/macro';
import { useIntl } from 'react-intl';
import { ListingFavoritesListCoverPicture } from '@nomady/shared/models/ListingFavoritesListCoverPicture';
import cover1 from './assets/cover-1.webp';
import cover2 from './assets/cover-2.webp';
import cover3 from './assets/cover-3.webp';
import cover4 from './assets/cover-4.webp';
import cover5 from './assets/cover-5.webp';
import cover6 from './assets/cover-6.webp';
import cover7 from './assets/cover-7.webp';
import cover8 from './assets/cover-8.webp';
import cover9 from './assets/cover-9.webp';
import cover10 from './assets/cover-10.webp';
import cover11 from './assets/cover-11.webp';
import coverMyCabin from './assets/cover-mycabin.webp';
import Text, { TextVariant } from '../Text/Text';
import { ColorVariant } from '../../theme';
import LikeButton, { LikeButtonColorVariant } from '../LikeButton/LikeButton';
import Icon from '../Icon/Icon';
const Wrapper = styled.div `
  background-color: ${props => props.theme.color.pearl};
  border-radius: 5px;
  display: flex;
  padding: 5px;
  align-items: center;

  @media ${props => props.theme.device.desktop} {
    min-width: 420px;
  }
`;
const TransparentCoverBox = styled.div `
  ${props => props.transparentCoverColor &&
    css `
      background-color: ${props.theme.color[props.transparentCoverColor]};
    `};

  opacity: 0.5;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
`;
const CoverPicture = styled.div `
  height: 50px;
  width: 50px;
  border-radius: 5px;
  background-size: contain;
  margin-right: 15px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  @media ${props => props.theme.device.tabletL} {
    height: 75px;
    width: 75px;
  }

  ${props => props.coverPicture === ListingFavoritesListCoverPicture.Cover1 &&
    `
  background-image: url(${cover1});
  `}

  ${props => props.coverPicture === ListingFavoritesListCoverPicture.Cover2 &&
    `
  background-image: url(${cover2});
  `}

  ${props => props.coverPicture === ListingFavoritesListCoverPicture.Cover3 &&
    `
  background-image: url(${cover3});
  `}

  ${props => props.coverPicture === ListingFavoritesListCoverPicture.Cover4 &&
    `
  background-image: url(${cover4});
  `}

  ${props => props.coverPicture === ListingFavoritesListCoverPicture.Cover5 &&
    `
  background-image: url(${cover5});
  `}

  ${props => props.coverPicture === ListingFavoritesListCoverPicture.Cover6 &&
    `
  background-image: url(${cover6});
  `}

  ${props => props.coverPicture === ListingFavoritesListCoverPicture.Cover7 &&
    `
  background-image: url(${cover7});
  `}

  ${props => props.coverPicture === ListingFavoritesListCoverPicture.Cover8 &&
    `
  background-image: url(${cover8});
  `}

  ${props => props.coverPicture === ListingFavoritesListCoverPicture.Cover9 &&
    `
  background-image: url(${cover9});
  `}

  ${props => props.coverPicture === ListingFavoritesListCoverPicture.Cover10 &&
    `
  background-image: url(${cover10});
  `}

  ${props => props.coverPicture === ListingFavoritesListCoverPicture.Cover11 &&
    `
  background-image: url(${cover11});
  `}

  ${props => props.coverPicture === ListingFavoritesListCoverPicture.CoverMyCabin &&
    `
  background-image: url(${coverMyCabin});
  `}
`;
const Content = styled.div `
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  min-width: 0;

  @media ${props => props.theme.device.tabletL} {
    align-self: flex-start;
  }
`;
const LikeButtonWrapper = styled.div `
  margin-right: 10px;
`;
const ListingFavoritesListCard = ({ name, coverPicture, listings, onAddOrRemoveListing, currentListingInList, addOrRemoveLoading = false, transparentCoverColor, centeredIcon, centeredIconColor, }) => {
    const intl = useIntl();
    return (_jsxs(Wrapper, { children: [_jsx(CoverPicture, Object.assign({ coverPicture: coverPicture }, { children: _jsx(TransparentCoverBox, Object.assign({ transparentCoverColor: transparentCoverColor }, { children: centeredIcon && (_jsx(Icon, { variant: centeredIcon, colorVariant: centeredIconColor })) })) })), _jsxs(Content, { children: [_jsx(Text, Object.assign({ variant: TextVariant.H10B, colorVariant: ColorVariant.Cave, truncateWithEllipsis: true }, { children: name })), _jsx(Text, Object.assign({ variant: TextVariant.H14, truncateWithEllipsis: true }, { children: intl.formatMessage({ id: 'ListingFavoritesListCard.numberOfListings' }, { count: listings }) }))] }), onAddOrRemoveListing && (_jsx(LikeButtonWrapper, { children: _jsx(LikeButton, { liked: currentListingInList || false, onClick: onAddOrRemoveListing, colorVariant: LikeButtonColorVariant.Transparent, loading: addOrRemoveLoading }) }))] }));
};
export default ListingFavoritesListCard;
