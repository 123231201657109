import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import styled, { css } from 'styled-components/macro';
import Markdown from 'react-showdown';
import Icon, { IconVariant } from '../Icon/Icon';
import Text, { TextVariant } from '../Text/Text';
const AccordionWrapper = styled.div `
  width: 100%;
`;
const Section = styled.div `
  border-top: 1px solid ${props => props.theme.color.stone};

  ${props => props.isLast &&
    css `
      border-bottom: 1px solid ${props => props.theme.color.stone};
    `}
`;
const SectionToggle = styled.button `
  width: 100%;
  padding: 35px 0;
  border: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: transparent;
`;
const SectionTitle = styled(Text) `
  flex-grow: 1;
  text-align: left;
  margin: 0;
`;
const PaddedIcon = styled.div `
  padding: 0 8px;
`;
const SectionContentWrapper = styled.div `
  padding-bottom: 25px;
`;
const Element = styled.div ``;
const ElementToggle = styled.button `
  width: 100%;
  padding: 20px 0 25px 0;
  border: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: transparent;
`;
const ElementTitle = styled(Text) `
  flex-grow: 1;
  text-align: left;
  margin-left: 20px;
  margin-top: 0;
  margin-bottom: 0;
`;
const ElementText = styled(Text) `
  display: block;
  margin-bottom: 20px;
  margin-left: 20px;
`;
const Accordion = ({ sections }) => {
    var _a;
    const [openSections, setOpenSections] = useState([]);
    const [openElements, setOpenElements] = useState([]);
    if (sections.length === 1) {
        return (_jsx(AccordionWrapper, { children: (_a = sections[0].questions) === null || _a === void 0 ? void 0 : _a.map((question, questionIndex) => (_jsxs(Section, Object.assign({ isLast: sections[0].questions.length === questionIndex + 1 }, { children: [_jsxs(SectionToggle, Object.assign({ onClick: () => setOpenSections(openSections.includes(question.id)
                            ? openSections.filter((element) => element !== question.id)
                            : [...openSections, question.id]), "aria-expanded": openSections.includes(question.id), "aria-controls": `section-${question.id}` }, { children: [_jsx(SectionTitle, Object.assign({ variant: TextVariant.H4, as: "h3" }, { children: question.title })), _jsx(PaddedIcon, { children: _jsx(Icon, { variant: openSections.includes(question.id)
                                        ? IconVariant.Minus
                                        : IconVariant.Plus }) })] })), openSections.includes(question.id) && (_jsx(SectionContentWrapper, Object.assign({ id: `section-${question.id}` }, { children: _jsx(ElementText, Object.assign({ id: `element-${question.id}` }, { children: _jsx(Markdown, { markdown: question.text }) })) })))] }), question.id))) }));
    }
    return (_jsx(AccordionWrapper, { children: sections.map((section, sectionIndex) => {
            var _a;
            return (_jsxs(Section, Object.assign({ isLast: sections.length === sectionIndex + 1 }, { children: [_jsxs(SectionToggle, Object.assign({ onClick: () => setOpenSections(openSections.includes(section.id)
                            ? openSections.filter((element) => element !== section.id)
                            : [...openSections, section.id]), "aria-expanded": openSections.includes(section.id), "aria-controls": `section-${section.id}` }, { children: [_jsx(SectionTitle, Object.assign({ variant: TextVariant.H4, as: "h3" }, { children: section.title })), _jsx(PaddedIcon, { children: _jsx(Icon, { variant: openSections.includes(section.id)
                                        ? IconVariant.Minus
                                        : IconVariant.Plus }) })] })), openSections.includes(section.id) && (_jsx(SectionContentWrapper, Object.assign({ id: `section-${section.id}` }, { children: (_a = section.questions) === null || _a === void 0 ? void 0 : _a.map(question => (_jsxs(Element, { children: [_jsxs(ElementToggle, Object.assign({ onClick: () => setOpenElements(openElements.includes(question.id)
                                        ? openElements.filter((a) => a !== question.id)
                                        : [...openElements, question.id]), "aria-expanded": openElements.includes(question.id), "aria-controls": `section-${question.id}` }, { children: [_jsx(ElementTitle, Object.assign({ variant: TextVariant.H6, as: "h4" }, { children: question.title })), _jsx(PaddedIcon, { children: _jsx(Icon, { variant: openElements.includes(question.id)
                                                    ? IconVariant.Minus
                                                    : IconVariant.Plus }) })] })), openElements.includes(question.id) && (_jsx("div", { children: _jsx(ElementText, Object.assign({ id: `element-${question.id}` }, { children: _jsx(Markdown, { markdown: question.text }) })) }))] }, question.id))) })))] }), section.id));
        }) }));
};
export default Accordion;
