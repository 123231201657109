import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styled from 'styled-components/macro';
import { FormattedMessage, useIntl } from 'react-intl';
import { ListingFavoritesListCoverPicture } from '@nomady/shared/models/ListingFavoritesListCoverPicture';
import BottomSheet, { BottomSheetLeft, BottomSheetRight, BottomSheetTitle, } from '../BottomSheet/BottomSheet';
import ListingFavoritesListCard from '../ListingFavoritesListCard/ListingFavoritesListCard';
import Button, { ButtonColorVariant, ButtonSizeVariant, } from '../Button/Button';
import { ColorVariant } from '../../theme';
import { IconVariant } from '../Icon/Icon';
const ButtonWrapper = styled.div `
  display: flex;
  align-self: flex-end;
  margin-top: 20px;
`;
const RemoveFavouritesListBottomSheet = ({ isOpen, onClose, listingFavoritesList, listings, onDelete }) => {
    const intl = useIntl();
    return (_jsxs(BottomSheet, Object.assign({ open: isOpen, onClose: onClose }, { children: [_jsx(BottomSheetLeft, { children: _jsx(ListingFavoritesListCard, { name: (listingFavoritesList === null || listingFavoritesList === void 0 ? void 0 : listingFavoritesList.name) ||
                        intl.formatMessage({
                            id: 'ListingFavoritesListPage.titleNotFound',
                        }), coverPicture: (listingFavoritesList === null || listingFavoritesList === void 0 ? void 0 : listingFavoritesList.cover_picture) ||
                        ListingFavoritesListCoverPicture.Cover3, listings: listings, centeredIcon: IconVariant.Garbage, transparentCoverColor: ColorVariant.Rose, centeredIconColor: ColorVariant.Snow }) }), _jsxs(BottomSheetRight, { children: [_jsx(BottomSheetTitle, { children: _jsx(FormattedMessage, { id: "RemoveFavoritesListBottomSheet.title" }) }), _jsxs(ButtonWrapper, { children: [_jsx(Button, { label: intl.formatMessage({
                                    id: 'RemoveFavoritesListBottomSheet.cancelButton',
                                }), colorVariant: ButtonColorVariant.Default, sizeVariant: ButtonSizeVariant.Small, onClick: onClose }), _jsx(Button, { label: intl.formatMessage({
                                    id: 'RemoveFavoritesListBottomSheet.removeButton',
                                }), colorVariant: ButtonColorVariant.Dark, sizeVariant: ButtonSizeVariant.Small, onClick: () => onDelete((listingFavoritesList === null || listingFavoritesList === void 0 ? void 0 : listingFavoritesList.id) || '') })] })] })] })));
};
export default RemoveFavouritesListBottomSheet;
