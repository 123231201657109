import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styled from 'styled-components/macro';
import { useIntl } from 'react-intl';
import { LISTING_STATE_PUBLISHED, } from '@nomady/shared/utils/types';
import Banner from '../../../styled-components/Banner/Banner';
import { ButtonColorVariant } from '../../../styled-components/Button/Button';
import Text, { TextVariant } from '../../../styled-components/Text/Text';
import { ColorVariant } from '../../../theme';
import Tag from '../../../styled-components/Tag/Tag';
const ContentWrapper = styled.div `
  display: flex;
  gap: 10px;
  align-items: center;
  min-width: 60%;
  margin-left: 10px;
  @media ${props => props.theme.device.tablet} {
    margin-left: 20px;
  }
`;
const StyledText = styled(Text) `
  font-size: 12px;
  line-height: 20px;
  @media ${props => props.theme.device.tablet} {
    font-size: 20px;
    line-height: 28px;
  }
`;
const OwnListingBanner = ({ listingState, targetRouteParams, goBack, }) => {
    const intl = useIntl();
    return (_jsx(Banner, Object.assign({ goBack: goBack, buttonProps: {
            label: intl.formatMessage({
                id: 'ListingPage.ownListingButtonLabel',
            }),
            targetRoute: 'EditListingPage',
            targetRouteParams,
            colorVariant: ButtonColorVariant.Dark,
        } }, { children: listingState === LISTING_STATE_PUBLISHED ? (_jsx(ContentWrapper, { children: _jsx(Text, Object.assign({ variant: TextVariant.H51, colorVariant: ColorVariant.Stone }, { children: intl.formatMessage({ id: 'ListingPage.ownListingApprovedTitle' }) })) })) : (_jsxs(ContentWrapper, { children: [_jsx(Tag, { label: intl.formatMessage({
                        id: 'ListingPage.ownListingPendingApprovalTag',
                    }), borderColor: ColorVariant.Clementine, fontWeight: "500", textColor: ColorVariant.Tar, textVariant: TextVariant.B50 }), _jsx(StyledText, Object.assign({ variant: TextVariant.H51, colorVariant: ColorVariant.Stone }, { children: intl.formatMessage({
                        id: 'ListingPage.ownListingPendingApprovalTitle',
                    }) }))] })) })));
};
export default OwnListingBanner;
