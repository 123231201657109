import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import IntroSheetContent from './IntroSheetContent';
import Text, { TextVariant } from '../../Text/Text';
import styled from 'styled-components/macro';
import Button, { ButtonColorVariant, ButtonSizeVariant, } from '../../Button/Button';
import { useSelector } from 'react-redux';
import background from '../assets/231122_Nomady_DigitalFormats_AppWelcome1.webp';
import { useIntl } from 'react-intl';
import { ReactComponent as LogoMyCabin } from '../assets/Logo_MyCabin.svg';
import Logo from '../../Logo/Logo';
import { ColorVariant } from '../../../theme';
import { safeSpaceTopSelector } from '../../../slices/UISlice';
import { CampStyle } from '@nomady/shared/types';
import campStyleIcons from '../../../campStyleIcons';
import Icon, { IconSizeVariant } from '../../Icon/Icon';
const Wrapper = styled.div `
  position: fixed;
  background-color: ${props => props.theme.color.snow};
  width: 100vw;
  height: 100vh;
  z-index: 20;
  display: flex;
  flex-direction: column;
  padding-top: ${props => props.safeSpaceTop}px;
  background-image: url(${background});
  background-position: bottom;
  background-size: cover;
  justify-content: space-between;
`;
const ButtonWrapper = styled.div `
  padding: 32px;
  width: 100%;
  flex-shrink: 0;
`;
const ContentWrapper = styled.div `
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
const IconRow = styled.div `
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
`;
const LogoWrapper = styled.div `
  display: flex;
  gap: 10px;
  align-items: center;
`;
const Spacer = styled.div `
  height: 192px;
  width: 1px;
`;
const SheetWelcome = ({ onContinue, isFormerMyCabinAppUser, }) => {
    const safeSpaceTop = useSelector(safeSpaceTopSelector);
    const intl = useIntl();
    return (_jsxs(Wrapper, Object.assign({ safeSpaceTop: safeSpaceTop }, { children: [_jsx(IntroSheetContent, { customLogo: isFormerMyCabinAppUser ? (_jsxs(LogoWrapper, { children: [_jsx(Logo, { responsive: true, colorVariant: ColorVariant.Cave }), _jsx(Text, Object.assign({ variant: TextVariant.H6 }, { children: "\u00D7" })), _jsx(LogoMyCabin, { width: "110", fill: "#FE5F55" })] })) : undefined, title: intl.formatMessage({
                    id: `AppIntro.WelcomeSheet${isFormerMyCabinAppUser ? 'MyCabin' : ''}.Title`,
                }), content: _jsxs(ContentWrapper, { children: [_jsx(Text, Object.assign({ variant: TextVariant.H2 }, { children: intl.formatMessage({
                                id: `AppIntro.WelcomeSheet${isFormerMyCabinAppUser ? 'MyCabin' : ''}.Subtitle`,
                            }) })), !isFormerMyCabinAppUser && (_jsx(IconRow, { children: [
                                CampStyle.tent,
                                CampStyle.carWithRooftopTent,
                                CampStyle.smallVehicle,
                                CampStyle.mediumVehicle,
                                CampStyle.largeVehicle,
                                CampStyle.caravan,
                                CampStyle.hut,
                                CampStyle.glamping,
                                CampStyle.shelter,
                            ].map(campStyle => (_jsx(Icon, { variant: campStyleIcons[campStyle], sizeVariant: IconSizeVariant.Medium, colorVariant: ColorVariant.BasicCaveBlack }, `icon-${campStyle}`))) })), _jsx(Spacer, {})] }) }, 0), _jsx(ButtonWrapper, { children: _jsx(Button, { sizeVariant: ButtonSizeVariant.Default, colorVariant: ButtonColorVariant.Light, onClick: onContinue, label: intl.formatMessage({
                        id: 'AppIntro.WelcomeSheet.ContinueButton',
                    }) }) })] })));
};
export default SheetWelcome;
