import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { App } from '@capacitor/app';
import { isApp } from './deviceContext';
const AppRouteHandler = () => {
    const history = useHistory();
    useEffect(() => {
        const listeners = [];
        if (isApp) {
            const backListener = App.addListener('backButton', () => {
                history.goBack();
            });
            const urlListener = App.addListener('appUrlOpen', (event) => {
                const path = event.url
                    .split('nomady.camp')
                    .slice(1)
                    .join('nomady.camp');
                if (path)
                    history.push(path);
            });
            listeners.push(backListener, urlListener);
        }
        return () => {
            listeners.forEach(listener => listener.remove());
        };
    }, [history]);
    return null;
};
export default AppRouteHandler;
