import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import styled from 'styled-components/macro';
const Wrapper = styled.div `
  padding: 8px 10px;

  background: ${props => props.theme.color.pearl};
  border-radius: 5px;

  color: ${props => props.theme.color.cave};
`;
const Card = ({ children }) => {
    return _jsx(Wrapper, { children: children });
};
export default Card;
