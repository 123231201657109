var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Markdown } from 'react-showdown';
import styled, { css } from 'styled-components/macro';
import { ColorVariant } from '../../theme';
import Icon from '../Icon/Icon';
import InputErrorMessage from '../InputErrorMessage/InputErrorMessage';
import Spinner from '../Spinner/Spinner';
import Text, { TextVariant } from '../Text/Text';
export var InputVariant;
(function (InputVariant) {
    InputVariant["Default"] = "Default";
    InputVariant["Dark"] = "Dark";
    InputVariant["Light"] = "Default";
})(InputVariant || (InputVariant = {}));
export const Label = styled(Text) `
  ${props => props.theme.typeset.B30}
  margin-bottom: 1px;
  margin-left: 8px;
  width: 100%;

  padding-top: 6px;
  padding-bottom: 2px;
  line-height: 24px;

  ${props => props.inputVariant === InputVariant.Dark &&
    css `
      color: ${props.theme.color.pearl};
    `}

  ${props => props.hasError && `color: ${props.theme.color.rose}`}
`;
export const InputWrapper = styled.input `
  margin: 10px;
  padding: 6px;

  ${props => props.hasIcon && `margin-left:6px;`}

  ${props => props.theme.typeset.H12B}
  color: ${props => props.theme.color.cave};

  // TODO: remove after removing old input component
  border: none;

  ${props => props.disabled &&
    `
  color: ${props.theme.color.stone};
    `}
`;
export const Wrapper = styled.div `
  display: flex;
  flex-direction: column;
`;
export const FakeInput = styled.span `
  display: flex;
  align-items: center;

  ${props => props.hasIcon && `padding-left: 16px;`}

  background-color: ${props => props.theme.color.pearl};
  border-radius: 5px;

  border: 1px solid transparent;
  width: 100%;

  ${props => props.theme.typeset.H8}

  &:hover {
    border: 1px solid
      ${props => props.variant === InputVariant.Dark
    ? props.theme.color.pearl
    : props.theme.color.fog};
  }

  &:focus-within {
    border: 1px solid ${props => props.theme.color.indigo};
  }

  ${props => props.hasError && `border: 1px solid ${props.theme.color.rose}`}

  ${props => props.disabled &&
    `


    &:focus-within {
      border-color:transparent;
    }

    &:hover {
      border-color:transparent;
    }
  
  
  `}
`;
const DescriptionWrapper = styled(Text) `
  ${props => props.theme.typeset.B40};

  padding: 8px 0;
  margin-left: 8px;
  hyphens: auto;

  p {
    margin: 0;
  }
`;
export const Description = ({ text, }) => (_jsx(DescriptionWrapper, { children: _jsx(Markdown, { markdown: text }) }));
export const ErrorMessageWrapper = styled(Text) `
  margin-left: 8px;
`;
export const SpinnerWrapper = styled.div `
  height: 18px;
  width: 18px;
`;
const Input = (_a) => {
    var _b, _c;
    var { id, label, variant, inputRef, error, icon, description, loading, children } = _a, rest = __rest(_a, ["id", "label", "variant", "inputRef", "error", "icon", "description", "loading", "children"]);
    const hasError = !!error || rest.hasError;
    const value = ((_b = rest.value) === null || _b === void 0 ? void 0 : _b.formattedValue)
        ? {
            value: (_c = rest.value) === null || _c === void 0 ? void 0 : _c.formattedValue,
        }
        : {};
    const errorMessageId = hasError ? `${id}-errormessage` : '';
    const errorMessageAriaInputProps = hasError
        ? { 'aria-invalid': true, 'aria-errormessage': errorMessageId }
        : {};
    return (_jsxs(Wrapper, { children: [label && (_jsx(Label, Object.assign({ inputVariant: variant, variant: TextVariant.H13, as: "label", htmlFor: id, hasError: hasError }, { children: label }))), _jsxs(FakeInput, Object.assign({ variant: variant, hasError: hasError, hasIcon: !!icon || !!loading, disabled: rest.disabled }, { children: [(icon || loading) && (_jsxs("label", Object.assign({ htmlFor: id, "aria-hidden": true }, { children: [icon && !loading && _jsx(Icon, { variant: icon }), loading && (_jsx(SpinnerWrapper, { children: _jsx(Spinner, { colorVariant: ColorVariant.Cave }) }))] }))), _jsx(InputWrapper, Object.assign({ id: id, variant: variant, ref: inputRef, hasError: hasError, hasIcon: !!icon }, rest, errorMessageAriaInputProps, value))] })), children, hasError && error && (_jsx(ErrorMessageWrapper, Object.assign({ role: "alert" }, { children: _jsx(InputErrorMessage, { id: errorMessageId, error: error }) }))), description && _jsx(Description, { text: description })] }));
};
export default Input;
