import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import styled, { css } from 'styled-components/macro';
import Person from '../Person/Person';
const Wrapper = styled.section `
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  @media ${props => props.theme.device.tabletL} {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
`;
const PersonWrapper = styled.div `
  ${props => props.isLast
    ? css `
          margin-bottom: initial;
        `
    : css `
          margin-bottom: 60px;

          @media ${props.theme.device.tablet} {
            margin-bottom: 100px;
          }
        `}
`;
const Persons = ({ persons }) => (_jsx(Wrapper, { children: persons.map((person, index) => {
        return (_jsx(PersonWrapper, Object.assign({ isLast: persons.length === index + 1 }, { children: _jsx(Person, { picture: person.picture, name: person.name, role: person.role, description: person.description }) }), person.id));
    }) }));
export default Persons;
