import isEqual from 'lodash/isEqual';
import getAccessGroundSearchParam from './getAccessGroundSearchParam';
import getAccessSearchParam from './getAccessSearchParam';
import getAmenitiesSearchParam from './getAmenitiesSearchParam';
import getEnvironmentSearchParam from './getEnvironmentSearchParam';
import getFacilitiesToiletSearchParam from './getFacilitiesToiletSearchParam';
import getFirePlaceSearchParam from './getFirePlaceSearchParam';
import getPriceSearchParam from './getPriceSearchParam';
import getSummerActivitiesSearchParam from './getSummerActivitiesSearchParam';
import getWinterActivitesSearchParam from './getWinterActivitesSearchParam';
import getFacilitiesShowerSearchParam from './getFacilitiesShowerSearchParam';
import { FlexibleCancellationTypes, DogPolicy, CateringType, } from '@nomady/shared/types';
import getPowerSupplySearchParam from './getPowerSupplySearchParam';
const getFeaturesApiParams = (featuresSearchQuery) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16;
    if (!featuresSearchQuery) {
        return {};
    }
    /* eslint-disable camelcase */
    const pub_instantBookingAvailable = (_a = featuresSearchQuery.bookingAndCancellation) === null || _a === void 0 ? void 0 : _a.instantBooking;
    const pub_cancellationPolicyType = ((_b = featuresSearchQuery.bookingAndCancellation) === null || _b === void 0 ? void 0 : _b.flexibleCancellation)
        ? FlexibleCancellationTypes.join(',')
        : undefined;
    const meta_isVerified = ((_c = featuresSearchQuery.bookingAndCancellation) === null || _c === void 0 ? void 0 : _c.verifiedHost)
        ? `${1}`
        : undefined;
    const meta_isMillionStars = ((_e = (_d = featuresSearchQuery.facilities) === null || _d === void 0 ? void 0 : _d.accommodation) === null || _e === void 0 ? void 0 : _e.millionStars) || undefined;
    const pub_openFireAllowed = featuresSearchQuery.fireplaceAvailable || undefined;
    const pub_fireplaceType = getFirePlaceSearchParam(featuresSearchQuery.fireplace);
    const pub_activities = getSummerActivitiesSearchParam((_f = featuresSearchQuery.activities) === null || _f === void 0 ? void 0 : _f.summer);
    const pub_winterActivities = getWinterActivitesSearchParam((_g = featuresSearchQuery.activities) === null || _g === void 0 ? void 0 : _g.winter);
    const price = !isEqual((_h = featuresSearchQuery.price) === null || _h === void 0 ? void 0 : _h.price, undefined)
        ? getPriceSearchParam((_j = featuresSearchQuery.price) === null || _j === void 0 ? void 0 : _j.price)
        : undefined;
    const pub_toiletAvailable = ((_m = (_l = (_k = featuresSearchQuery.facilities) === null || _k === void 0 ? void 0 : _k.basics) === null || _l === void 0 ? void 0 : _l.toilet) === null || _m === void 0 ? void 0 : _m.closeToCampsite) ||
        ((_q = (_p = (_o = featuresSearchQuery.facilities) === null || _o === void 0 ? void 0 : _o.basics) === null || _p === void 0 ? void 0 : _p.toilet) === null || _q === void 0 ? void 0 : _q.portableToilet) ||
        ((_t = (_s = (_r = featuresSearchQuery.facilities) === null || _r === void 0 ? void 0 : _r.basics) === null || _s === void 0 ? void 0 : _s.toilet) === null || _t === void 0 ? void 0 : _t.regularToilet) ||
        undefined;
    const pub_toiletType = getFacilitiesToiletSearchParam((_v = (_u = featuresSearchQuery.facilities) === null || _u === void 0 ? void 0 : _u.basics) === null || _v === void 0 ? void 0 : _v.toilet);
    const pub_powerSupplyAvailable = ((_y = (_x = (_w = featuresSearchQuery.facilities) === null || _w === void 0 ? void 0 : _w.basics) === null || _x === void 0 ? void 0 : _x.powerSupply) === null || _y === void 0 ? void 0 : _y.campingVehicles) ||
        ((_1 = (_0 = (_z = featuresSearchQuery.facilities) === null || _z === void 0 ? void 0 : _z.basics) === null || _0 === void 0 ? void 0 : _0.powerSupply) === null || _1 === void 0 ? void 0 : _1.smallDevices) ||
        undefined;
    const pub_powerSupplyType = getPowerSupplySearchParam((_3 = (_2 = featuresSearchQuery.facilities) === null || _2 === void 0 ? void 0 : _2.basics) === null || _3 === void 0 ? void 0 : _3.powerSupply);
    const pub_waterAvailable = ((_6 = (_5 = (_4 = featuresSearchQuery.facilities) === null || _4 === void 0 ? void 0 : _4.basics) === null || _5 === void 0 ? void 0 : _5.water) === null || _6 === void 0 ? void 0 : _6.toString()) || undefined;
    const pub_amenities = getAmenitiesSearchParam(featuresSearchQuery);
    const pub_showerAvailable = ((_8 = (_7 = featuresSearchQuery.facilities) === null || _7 === void 0 ? void 0 : _7.shower) === null || _8 === void 0 ? void 0 : _8.outdoorShower) ||
        ((_10 = (_9 = featuresSearchQuery.facilities) === null || _9 === void 0 ? void 0 : _9.shower) === null || _10 === void 0 ? void 0 : _10.regularShower) ||
        undefined;
    const pub_showerType = getFacilitiesShowerSearchParam((_11 = featuresSearchQuery.facilities) === null || _11 === void 0 ? void 0 : _11.shower);
    const pub_winterCapability = (_12 = featuresSearchQuery.facilities) === null || _12 === void 0 ? void 0 : _12.winterCamping;
    const pub_storeAvailable = ((_13 = featuresSearchQuery.facilities) === null || _13 === void 0 ? void 0 : _13.store) || undefined;
    const pub_environment = getEnvironmentSearchParam(featuresSearchQuery.ambiance);
    // pub_dogsAllowed is set in getGuestsApiParams
    const pub_currency = ((_14 = featuresSearchQuery.price) === null || _14 === void 0 ? void 0 : _14.currency) || undefined;
    const pub_dogPolicies = featuresSearchQuery.dogCamp
        ? `${DogPolicy.extraDogFriendly}`
        : undefined;
    const pub_familyFriendly = featuresSearchQuery.familyFriendly || undefined;
    const pub_cateringType = featuresSearchQuery.farmShop
        ? CateringType.farmShop
        : undefined;
    const pub_accessType = getAccessSearchParam((_15 = featuresSearchQuery.access) === null || _15 === void 0 ? void 0 : _15.access);
    const pub_groundType = getAccessGroundSearchParam((_16 = featuresSearchQuery.access) === null || _16 === void 0 ? void 0 : _16.ground);
    /* eslint-enable camelcase */
    return {
        meta_isVerified,
        meta_isMillionStars,
        price,
        pub_accessType,
        pub_activities,
        pub_amenities,
        pub_cancellationPolicyType,
        pub_currency,
        pub_winterCapability,
        pub_powerSupplyAvailable,
        pub_powerSupplyType,
        pub_environment,
        pub_openFireAllowed,
        pub_fireplaceType,
        pub_groundType,
        pub_instantBookingAvailable,
        pub_showerAvailable,
        pub_showerType,
        pub_storeAvailable,
        pub_toiletAvailable,
        pub_toiletType,
        pub_waterAvailable,
        pub_winterActivities,
        pub_dogPolicies,
        pub_familyFriendly,
        pub_cateringType,
    };
};
export default getFeaturesApiParams;
