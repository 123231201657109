var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { Prompt } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import Page from '@nomady/shared/routeConfiguration/Page';
import { createSlug, LISTING_PAGE_PARAM_TYPE_DRAFT, LISTING_PAGE_PARAM_TYPE_NEW, LISTING_PAGE_PENDING_APPROVAL_VARIANT, } from '@nomady/shared/utils/urlHelpers';
import IconVariant from '@nomady/shared/interfaces/IconVariant';
import { UserProfileSchema, getUserProfileDefaultValues, } from '@nomady/shared/schemas/user/profile';
import { ListingDescriptionSchema, getListingDescriptionDefaultValues, } from '@nomady/shared/schemas/listing/description';
import { ListingFacilitiesSchema, getListingFacilitiesDefaultValues, } from '@nomady/shared/schemas/listing/facilities';
import { ListingLocationSchema, getListingLocationDefaultValues, } from '@nomady/shared/schemas/listing/location';
import { ListingRulesSchema, getListingRulesDefaultValues, } from '@nomady/shared/schemas/listing/rules';
import { ListingDescriptionWinterSchema, getListingDescriptionWinterDefaultValues, } from '@nomady/shared/schemas/listing/descriptionWinter';
import { TabState } from '@nomady/shared/routeConfiguration/routeConfiguration';
import { NamedRedirect } from '..';
import EditListingWizardTab, { EditListingTab, } from './EditListingWizardTab';
import { LayoutSideNavigationWrapper, NavigationWrapper, ContentWrapper, } from '../../styled-components/LayoutSideNavigation/LayoutSideNavigation';
import SideNavigation from '../../styled-components/SideNavigation/SideNavigation';
import { ListingPricingSchema, getListingPricingDefaultValues, } from '@nomady/shared/schemas/listing/pricing';
import { ListingPhotosSchema, getListingPhotosDefaultValues, } from '@nomady/shared/schemas/listing/photos';
import Button, { ButtonColorVariant, ButtonSizeVariant, } from '../../styled-components/Button/Button';
// You can reorder these panels.
// Note 1: You need to change save button translations for new listing flow
// Note 2: Ensure that draft listing is created after the first panel
// and listing publishing happens after last panel.
const STRIPE_ONBOARDING_RETURN_URL_SUCCESS = 'success';
const TAB_LABEL = {
    [EditListingTab.hostProfile]: 'EditListingWizard.tabLabelHostProfile',
    [EditListingTab.description]: 'EditListingWizard.tabLabelDescription',
    [EditListingTab.location]: 'EditListingWizard.tabLabelLocation',
    [EditListingTab.facilities]: 'EditListingWizard.tabLabelFeatures',
    [EditListingTab.rules]: 'EditListingWizard.tabLabelPolicy',
    [EditListingTab.pricing]: 'EditListingWizard.tabLabelPricing',
    [EditListingTab.descriptionWinter]: 'EditListingWizard.tabLabelWinterDescription',
    [EditListingTab.photos]: 'EditListingWizard.tabLabelPhotos',
    [EditListingTab.payout]: 'EditListingWizard.tabLabelPayout',
};
const TAB_ICON = {
    [EditListingTab.hostProfile]: IconVariant.User,
    [EditListingTab.description]: IconVariant.Tent,
    [EditListingTab.location]: IconVariant.Pin,
    [EditListingTab.facilities]: IconVariant.Table,
    [EditListingTab.rules]: IconVariant.PaperScroll,
    [EditListingTab.pricing]: IconVariant.Coins,
    [EditListingTab.descriptionWinter]: IconVariant.Snowflake,
    [EditListingTab.photos]: IconVariant.Picture,
    [EditListingTab.payout]: IconVariant.Payout,
};
export const getTabStatus = listing => ({
    [EditListingTab.hostProfile]: true,
    [EditListingTab.description]: true,
    [EditListingTab.location]: true,
    [EditListingTab.facilities]: true,
    [EditListingTab.rules]: true,
    [EditListingTab.pricing]: true,
    [EditListingTab.descriptionWinter]: !!(listing === null || listing === void 0 ? void 0 : listing.attributes.publicData.winterCapability),
    [EditListingTab.photos]: true,
    [EditListingTab.payout]: true,
});
/**
 * Check if a wizard tab is completed.
 *
 * @param tab wizard's tab
 * @param listing is contains some specific data if tab is completed
 * @param currentUser is contains currentuser
 *
 * @return true if tab / step is completed.
 */
const tabCompleted = (tab, currentUser, listing) => {
    const { publicData: allPublicData } = (listing === null || listing === void 0 ? void 0 : listing.attributes) || {};
    const _a = allPublicData || {}, { localizedPublicData } = _a, publicData = __rest(_a, ["localizedPublicData"]);
    const getTabCompletedRequirement = {
        [EditListingTab.hostProfile]: () => UserProfileSchema.safeParse(getUserProfileDefaultValues(currentUser))
            .success,
        [EditListingTab.description]: () => ListingDescriptionSchema.safeParse(getListingDescriptionDefaultValues(listing === null || listing === void 0 ? void 0 : listing.attributes)).success,
        [EditListingTab.location]: () => ListingLocationSchema.safeParse(getListingLocationDefaultValues(listing === null || listing === void 0 ? void 0 : listing.attributes)).success,
        [EditListingTab.facilities]: () => ListingFacilitiesSchema.safeParse(getListingFacilitiesDefaultValues(listing === null || listing === void 0 ? void 0 : listing.attributes)).success,
        [EditListingTab.rules]: () => ListingRulesSchema.safeParse(getListingRulesDefaultValues(listing === null || listing === void 0 ? void 0 : listing.attributes)).success,
        [EditListingTab.pricing]: () => ListingPricingSchema.safeParse(getListingPricingDefaultValues(publicData))
            .success,
        [EditListingTab.descriptionWinter]: () => publicData.winterCapability === false ||
            ListingDescriptionWinterSchema.safeParse(getListingDescriptionWinterDefaultValues(listing === null || listing === void 0 ? void 0 : listing.attributes)).success,
        [EditListingTab.photos]: () => ListingPhotosSchema.safeParse(getListingPhotosDefaultValues(listing))
            .success,
        [EditListingTab.payout]: () => true,
    };
    return getTabCompletedRequirement[tab]();
};
const getTabState = (currentUser, listing, enabledTabs) => {
    let lastValidTabIndex = enabledTabs.length;
    enabledTabs.some((_, tabIndex) => {
        const isActive = tabCompleted(enabledTabs[tabIndex], currentUser, listing);
        lastValidTabIndex = tabIndex;
        return !isActive;
    }, {});
    return enabledTabs.reduce((acc, tab, currentIndex) => {
        const isValid = !tabCompleted(enabledTabs[currentIndex], currentUser, listing)
            ? TabState.Invalid
            : undefined;
        return Object.assign(Object.assign({}, acc), { [tab]: lastValidTabIndex < currentIndex ? TabState.Disabled : isValid });
    }, {});
};
// Get attribute: stripeAccountData
const getStripeAccountData = (stripeAccount) => stripeAccount.attributes.stripeAccountData || null;
// Check if there's requirements on selected type: 'past_due', 'currently_due' etc.
const hasRequirements = (stripeAccountData, requirementType) => stripeAccountData != null &&
    stripeAccountData.requirements &&
    Array.isArray(stripeAccountData.requirements[requirementType]) &&
    stripeAccountData.requirements[requirementType].length > 0;
const ButtonWrapper = styled.div `
  margin-top: 20px;
`;
const EditListingWizard = props => {
    var _a, _b;
    const [showPayoutDetails, setShowPayoutDetails] = React.useState(false);
    const [formHasChanged, setFormHasChanged] = React.useState(false);
    useEffect(() => {
        const { params: { returnURLType }, } = props;
        if (returnURLType != null && !showPayoutDetails) {
            setShowPayoutDetails(true);
        }
    }, [props, showPayoutDetails]);
    const intl = useIntl();
    const { params, listing } = props;
    const tabStatus = getTabStatus(listing);
    const enabledTabs = Object.keys(tabStatus).filter(key => !!tabStatus[key]);
    const state = useSelector((state) => state);
    const { stripeAccount } = state.stripeConnectAccount;
    const { currentUser } = state.user;
    const isNewListingFlow = [
        LISTING_PAGE_PARAM_TYPE_NEW,
        LISTING_PAGE_PARAM_TYPE_DRAFT,
    ].includes(params.type);
    const selectedTab = params.tab;
    const tabState = getTabState(currentUser, listing, enabledTabs);
    // If selectedTab is disabled, redirect to the nearest valid tab
    if (tabState[selectedTab] === TabState.Disabled) {
        const currentTabIndex = enabledTabs.indexOf(selectedTab);
        const nearestActiveTab = enabledTabs
            .slice(0, currentTabIndex)
            .reverse()
            .find(t => tabState[t] === undefined) || enabledTabs[0];
        return (_jsx(NamedRedirect, { name: Page.EditListing, params: Object.assign(Object.assign({}, params), { tab: nearestActiveTab }) }));
    }
    const tabLink = (tab) => {
        return { name: Page.EditListing, params: Object.assign(Object.assign({}, params), { tab }) };
    };
    const currentUserLoaded = !!(currentUser === null || currentUser === void 0 ? void 0 : currentUser.id);
    const stripeConnected = currentUserLoaded && !!stripeAccount && !!stripeAccount.id;
    const { returnURLType } = params, pathParams = __rest(params, ["returnURLType"]);
    const stripeAccountData = stripeConnected
        ? getStripeAccountData(stripeAccount)
        : null;
    const requirementsMissing = stripeAccount &&
        (hasRequirements(stripeAccountData, 'past_due') ||
            hasRequirements(stripeAccountData, 'currently_due'));
    const returnedNormallyFromStripe = returnURLType === STRIPE_ONBOARDING_RETURN_URL_SUCCESS;
    // Redirect from success URL to basic path for StripePayoutPage
    if (returnedNormallyFromStripe && stripeConnected && !requirementsMissing) {
        return _jsx(NamedRedirect, { name: Page.EditListing, params: pathParams });
    }
    const listingParams = ((_a = listing === null || listing === void 0 ? void 0 : listing.attributes) === null || _a === void 0 ? void 0 : _a.title)
        ? {
            slug: createSlug(listing.attributes.title),
            id: listing.id.uuid,
            variant: LISTING_PAGE_PENDING_APPROVAL_VARIANT,
        }
        : {};
    let previewLink = null;
    if (!isNewListingFlow &&
        ((_b = listing === null || listing === void 0 ? void 0 : listing.attributes) === null || _b === void 0 ? void 0 : _b.state) &&
        listing.attributes.state !== 'draft') {
        previewLink = (_jsx(ButtonWrapper, { children: _jsx(Button, { targetRoute: Page.ListingVariant, targetRouteParams: listingParams, label: intl.formatMessage({
                    id: 'EditListingWizard.listingPreview',
                }), colorVariant: ButtonColorVariant.Fir, sizeVariant: ButtonSizeVariant.Small, icon: IconVariant.Eye }) }));
    }
    const onFormDirty = (isDirty) => {
        if (listing === null || listing === void 0 ? void 0 : listing.id) {
            setFormHasChanged(isDirty);
        }
    };
    const tabs = enabledTabs.map(tab => ({
        intlId: tab,
        linkProps: tabLink(tab),
        title: intl.formatMessage({ id: TAB_LABEL[tab] }),
        state: tabState[tab],
        active: tab === selectedTab,
        icon: TAB_ICON[tab],
    }));
    return (_jsxs(LayoutSideNavigationWrapper, { children: [_jsx(Prompt, { when: formHasChanged, message: intl.formatMessage({
                    id: 'EditListingWizard.changesWarning',
                }) }), _jsxs(NavigationWrapper, { children: [_jsx(SideNavigation, { tabs: tabs }), previewLink] }), _jsx(ContentWrapper, { children: _jsx(EditListingWizardTab, { params: params, listing: listing, onFormDirty: onFormDirty, currentUser: currentUser }) })] }));
};
export default EditListingWizard;
