import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Page from '@nomady/shared/routeConfiguration/Page';
import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components/macro';
import Button, { ButtonOutlineVariant, ButtonSizeVariant, } from '../Button/Button';
import Text, { TextVariant } from '../Text/Text';
const CreateListingPlaceholderCard = () => {
    const intl = useIntl();
    const Wrapper = styled.div `
    display: flex;
    margin-bottom: 36px;
    justify-content: center;
    flex-basis: 100%;
    background: ${props => props.theme.color.pearl};
    border-radius: 10px;
    min-height: 300px;

    @media ${props => props.theme.device.tabletL} {
      flex-basis: calc(50% - 12px);
      margin-right: 24px;
      min-height: 340px;
    }

    @media ${props => props.theme.device.desktopXXL} {
      flex-basis: calc(33.33% - 16px);
      margin-right: 24px;
      min-height: 380px;
    }
  `;
    const PlaceholderCard = styled.div `
    align-items: center;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 260px;
  `;
    const NewListingText = styled(Text) `
    margin-bottom: 12px;
  `;
    return (_jsx(Wrapper, { children: _jsxs(PlaceholderCard, { children: [_jsx(NewListingText, Object.assign({ variant: TextVariant.H11B }, { children: intl.formatMessage({
                        id: 'CreateListingPlaceholderCard.sevenSteps',
                    }) })), _jsx(Button, { label: intl.formatMessage({
                        id: 'CreateListingPlaceholderCard.createListing',
                    }), targetRoute: Page.NewListing, outlineVariant: ButtonOutlineVariant.Outlined, sizeVariant: ButtonSizeVariant.Small })] }) }));
};
export default CreateListingPlaceholderCard;
