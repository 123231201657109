import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Page from '@nomady/shared/routeConfiguration/Page';
import { createResourceLocatorString } from '@nomady/shared/utils/routes';
import styled from 'styled-components/macro';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import ConnectedJourneyCard from '../../styled-components/JourneyCard/ConnectedJourneyCard';
import LayoutSideNavigation from '../../styled-components/LayoutSideNavigation/LayoutSideNavigation';
import SignedInPageContainer from '../../styled-components/PageContainer/SignedInPageContainer';
import PaginationControl from '../../styled-components/PaginationControl/PaginationControl';
import Text, { TextVariant } from '../../styled-components/Text/Text';
import { requestFutureTransactions, requestPastTransactions, setFuturePage, setPastPage, } from './JourneysPageSlice';
import { ColorVariant } from '../../theme';
import SkeletonLoader, { SkeletonLoaderVariant, } from '../../styled-components/SkeletonLoader/SkeletonLoader';
import EmptyCard from '../../styled-components/EmptyCard/EmptyCard';
import { routeConfiguration } from '../..';
import ConnectedPictureCard from '../../styled-components/PictureCard/ConnectedPictureCard';
import Button, { ButtonColorVariant, ButtonSizeVariant, } from '../../styled-components/Button/Button';
import SectionContentSlider from '../../styled-components/SectionContentSlider/SectionContentSlider';
const Head = styled.div `
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 18px;
`;
const Section = styled.section `
  margin-bottom: 52px;
`;
const SectionTitle = styled(Text) `
  margin-bottom: 10px;
`;
const Bookings = styled.div `
  display: grid;
  grid-auto-rows: auto;
  row-gap: 10px;
  margin-bottom: 52px;
`;
const Pagination = styled.div `
  width: min-content;
  margin: 0 auto;
`;
const StyledAllCampsButtonWrapper = styled.div `
  ${props => props.theme.layout.container}
  margin: 0 auto;
`;
const JourneysPage = () => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const history = useHistory();
    const title = intl.formatMessage({ id: 'JourneysPage.title' });
    const state = useSelector((state) => state);
    const { fetchFutureTransactionsInProgress, fetchPastTransactionsInProgress, futureTransactionRefs, pastTransactionRefs, futurePagination, currentFuturePage, currentFuturePageSize, currentPastPage, currentPastPageSize, pastPagination, } = useSelector((state) => state.JourneysPage);
    const onChangeFuturePage = (page) => {
        dispatch(setFuturePage(page));
        dispatch(requestFutureTransactions());
    };
    const onChangePastPage = (page) => {
        dispatch(setPastPage(page));
        dispatch(requestPastTransactions());
    };
    const navigateToSearchPage = () => {
        const target = createResourceLocatorString(Page.Search, routeConfiguration);
        history.push(target);
    };
    const transactions = getMarketplaceEntities(state, futureTransactionRefs);
    const todoTransactions = getMarketplaceEntities(state, pastTransactionRefs);
    return (_jsx(SignedInPageContainer, Object.assign({ title: title }, { children: _jsxs(LayoutSideNavigation, { children: [_jsx(Head, { children: _jsx(Text, Object.assign({ variant: TextVariant.H20, as: "h1" }, { children: intl.formatMessage({ id: 'JourneysPage.title' }) })) }), _jsxs(Section, { children: [_jsx(SectionTitle, Object.assign({ as: "h2", variant: TextVariant.H50, colorVariant: ColorVariant.Stone }, { children: !fetchFutureTransactionsInProgress &&
                                (futurePagination === null || futurePagination === void 0 ? void 0 : futurePagination.totalItems) === 0
                                ? intl.formatMessage({ id: 'JourneysPage.noFutureJourneysTitle' })
                                : intl.formatMessage({ id: 'JourneysPage.futureJourneysTitle' }) })), _jsxs(Bookings, { children: [!fetchFutureTransactionsInProgress &&
                                    transactions.map(transaction => (_jsx(ConnectedJourneyCard, { transaction: transaction }, transaction.id.uuid))), fetchFutureTransactionsInProgress && (_jsx(SkeletonLoader, { variant: SkeletonLoaderVariant.BookingCards }))] }), !fetchFutureTransactionsInProgress &&
                            (futurePagination === null || futurePagination === void 0 ? void 0 : futurePagination.totalItems) === 0 && (_jsxs(_Fragment, { children: [_jsx(SectionContentSlider, { children: _jsx(ConnectedPictureCard, { startIndex: 0, endIndex: 5 }) }), (pastPagination === null || pastPagination === void 0 ? void 0 : pastPagination.totalItems) === 0 && (_jsx(SectionContentSlider, { children: _jsx(ConnectedPictureCard, { startIndex: 5, endIndex: 10 }) })), _jsx(StyledAllCampsButtonWrapper, { children: _jsx(Button, { label: intl.formatMessage({
                                            id: 'LandingPage.buttonAllCamps',
                                        }), onClick: () => navigateToSearchPage(), colorVariant: ButtonColorVariant.Dark, sizeVariant: ButtonSizeVariant.Small }) })] })), futurePagination &&
                            currentFuturePageSize < futurePagination.totalItems && (_jsx(Pagination, { children: _jsx(PaginationControl, { onChangePage: page => onChangeFuturePage(page), activePage: currentFuturePage, totalPages: (futurePagination === null || futurePagination === void 0 ? void 0 : futurePagination.totalPages) || 1 }) }))] }), _jsxs(Section, { children: [_jsx(SectionTitle, Object.assign({ as: "h2", variant: TextVariant.H50, colorVariant: ColorVariant.Stone }, { children: intl.formatMessage({ id: 'JourneysPage.pastJourneysTitle' }) })), _jsxs(Bookings, { children: [!fetchPastTransactionsInProgress &&
                                    todoTransactions.map(transaction => {
                                        return (_jsx(ConnectedJourneyCard, { transaction: transaction }, transaction.id.uuid));
                                    }), fetchPastTransactionsInProgress && (_jsx(SkeletonLoader, { variant: SkeletonLoaderVariant.BookingCards })), !fetchPastTransactionsInProgress &&
                                    (pastPagination === null || pastPagination === void 0 ? void 0 : pastPagination.totalItems) === 0 && (_jsx(EmptyCard, { description: intl.formatMessage({
                                        id: 'BookingPage.noTodoDescription',
                                    }), buttonLabel: intl.formatMessage({
                                        id: 'BookingPage.noTodoButtonLabel',
                                    }), button: {
                                        onClick: () => {
                                            dispatch(requestPastTransactions());
                                        },
                                    } }))] }), !fetchPastTransactionsInProgress &&
                            pastPagination &&
                            currentPastPageSize < pastPagination.totalItems && (_jsx(Pagination, { children: _jsx(PaginationControl, { onChangePage: page => onChangePastPage(page), activePage: currentPastPage, totalPages: (pastPagination === null || pastPagination === void 0 ? void 0 : pastPagination.totalPages) || 1 }) }))] })] }) })));
};
export default JourneysPage;
