import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useLocation, Redirect } from 'react-router-dom';
import SplitPicturePageContainer from '../../styled-components/PageContainer/SplitPicturePageContainer';
import { isAuthenticatedSelector } from '../../ducks/Auth.duck';
import config from '../../config';
import CreateAccountForm from '../../forms/CreateAccountForm/CreateAccountForm';
const CreateAccountPage = () => {
    var _a;
    const intl = useIntl();
    const { siteTitle } = config;
    const schemaTitle = intl.formatMessage({ id: 'CreateAccountPage.schemaTitle' }, { siteTitle });
    const location = useLocation();
    const isAuthenticated = useSelector(isAuthenticatedSelector);
    const from = ((_a = location === null || location === void 0 ? void 0 : location.state) === null || _a === void 0 ? void 0 : _a.from) || null;
    if (isAuthenticated && from) {
        return _jsx(Redirect, { to: from });
    }
    return (_jsx(SplitPicturePageContainer, Object.assign({ schemaTitle: schemaTitle }, { children: _jsx(CreateAccountForm, {}) })));
};
export default CreateAccountPage;
