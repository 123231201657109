import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styled from 'styled-components/macro';
import Icon, { IconVariant } from '../../../styled-components/Icon/Icon';
import { ColorVariant } from '../../../theme';
const Wrapper = styled.div `
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;

  margin: -0.25px;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  .rbc-selected-cell ~ .calendar-select-checkbox {
    display: block;
  }
`;
const Checkbox = styled.div `
  position: absolute;
  left: 0;
  bottom: 0;
  display: none;
  z-index: ${props => props.theme.priority.low};
  width: 24px;
  height: 24px;
  background-color: ${props => props.theme.color.forest};
  margin: 4px;
  border-radius: 4px;
  pointer-events: none;
`;
export const CalendarComponentDateCellWrapper = ({ children, }) => (_jsxs(Wrapper, { children: [children, _jsx(Checkbox, Object.assign({ className: "calendar-select-checkbox" }, { children: _jsx(Icon, { variant: IconVariant.Check_Light, colorVariant: ColorVariant.Pearl }) }))] }));
