import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import Button2024, { ButtonSizeVariant, ButtonVariant, } from '../../../styled-components/Button2024/Button2024';
import Text, { TextVariant } from '../../../styled-components/Text/Text';
import { ColorVariant } from '../../../theme';
import { loadResults, resetFeatures, resetLocation, setMapSearchEnabled, } from '../../../slices/listingSearchSlice';
import { IconVariant } from '../../../styled-components/Icon/Icon';
const BannerWrapper = styled.div `
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px;
  background-color: ${props => props.theme.color.basicPearlWhiteTypeWhite};
  border: 1px solid ${props => props.theme.color.functionOrangeRating};
  border-radius: 8px;
`;
const TextsWrapper = styled.div `
  display: flex;
  flex-direction: column;
`;
export const NoSearchResultsBanner = () => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const [clicked, setClicked] = useState(false);
    const handleResetFilters = () => {
        setClicked(true);
        dispatch(setMapSearchEnabled(false));
        dispatch(resetFeatures());
        dispatch(resetLocation());
        dispatch(loadResults());
    };
    const { searchInProgress } = useSelector((state) => state.SearchPage);
    if (searchInProgress && !clicked) {
        return null;
    }
    return (_jsxs(BannerWrapper, { children: [_jsxs(TextsWrapper, { children: [_jsx(Text, Object.assign({ variant: TextVariant.P4SB, color: ColorVariant.BasicCaveBlack }, { children: intl.formatMessage({ id: 'NoSearchResultsBanner.title' }) })), _jsx(Text, Object.assign({ variant: TextVariant.P4R, color: ColorVariant.BasicCaveBlack }, { children: intl.formatMessage({ id: 'NoSearchResultsBanner.subtitle' }) }))] }), _jsx(Button2024, { label: intl.formatMessage({ id: 'NoSearchResultsBanner.resetFilters' }), variant: ButtonVariant.Outlined, sizeVariant: ButtonSizeVariant.Small, onClick: handleResetFilters, iconOnLeft: searchInProgress ? IconVariant.Spinner : undefined })] }));
};
