import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import Button, { ButtonColorVariant } from '../Button/Button';
import styled from 'styled-components/macro';
import Icon, { IconSizeVariant, IconVariant } from '../Icon/Icon';
import Text, { TextVariant } from '../Text/Text';
import { ColorVariant } from '../../theme';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
const Wrapper = styled.div `
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const Box = styled.div `
  background-color: ${props => props.theme.color.basicPearlWhiteTypeWhite};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  text-align: center;
  padding: 24px;
  max-width: 320px;
  border-radius: 16px;
`;
export const ErrorBoundaryFallback = ({ resetError, error, }) => {
    var _a;
    const history = useHistory();
    const intl = useIntl();
    const fallback = ((_a = history.location.pathname) === null || _a === void 0 ? void 0 : _a.includes('/s'))
        ? {
            path: '/',
            buttonTitle: intl.formatMessage({ id: 'ErrorBoundary.startButton' }),
        }
        : {
            path: '/s',
            buttonTitle: intl.formatMessage({ id: 'ErrorBoundary.searchButton' }),
        };
    return (_jsx(Wrapper, { children: _jsxs(Box, { children: [_jsx(Icon, { variant: IconVariant.Error_Careful, sizeVariant: IconSizeVariant.Medium, colorVariant: ColorVariant.BasicCaveBlack }), _jsx(Text, Object.assign({ variant: TextVariant.L2SB, colorVariant: ColorVariant.PrimaryFirGreen }, { children: intl.formatMessage({
                        id: 'ErrorBoundary.title',
                    }) })), _jsx(Text, Object.assign({ variant: TextVariant.L4R, colorVariant: ColorVariant.FunctionFogGreyInactive }, { children: intl.formatMessage({
                        id: 'ErrorBoundary.subtitle',
                    }) })), _jsxs(Text, Object.assign({ variant: TextVariant.L4R, colorVariant: ColorVariant.PrimaryCinnabarRed }, { children: ["(", history.location.pathname, ": ", error.message, ")"] })), _jsx(Button, Object.assign({ onClick: () => {
                        resetError();
                        if (typeof window !== 'undefined') {
                            window.location.reload();
                        }
                    }, colorVariant: ButtonColorVariant.Fir }, { children: intl.formatMessage({ id: 'ErrorBoundary.retryButton' }) })), _jsx(Button, Object.assign({ onClick: () => {
                        resetError();
                        history.push(fallback.path);
                    }, colorVariant: ButtonColorVariant.Dark }, { children: fallback.buttonTitle }))] }) }));
};
