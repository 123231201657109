import { ShowerType } from '@nomady/shared/types';
const getFacilitiesShowerSearchParam = (facilitiesShowerSearchQuery) => {
    const values = [];
    if (facilitiesShowerSearchQuery === null || facilitiesShowerSearchQuery === void 0 ? void 0 : facilitiesShowerSearchQuery.outdoorShower) {
        values.push(ShowerType.outdoor);
    }
    if (facilitiesShowerSearchQuery === null || facilitiesShowerSearchQuery === void 0 ? void 0 : facilitiesShowerSearchQuery.regularShower) {
        values.push(ShowerType.regular);
    }
    if (values.length === 0) {
        return undefined;
    }
    return `has_any:${values.join(',')}`;
};
export default getFacilitiesShowerSearchParam;
