import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect } from 'react';
export const PartnerVersicherung = () => {
    useEffect(() => {
        var _a;
        const script = document.createElement('script');
        script.src =
            'https://form.partner-versicherung.de/widgets/181094/tcpp-iframe-kfz/kfz-iframe.js';
        script.async = true;
        (_a = document.getElementById('tcpp-iframe-kfz')) === null || _a === void 0 ? void 0 : _a.appendChild(script);
        return () => {
            var _a;
            (_a = document.getElementById('tcpp-iframe-kfz')) === null || _a === void 0 ? void 0 : _a.removeChild(script);
        };
    }, []);
    return _jsx("div", { style: { width: '100%' }, id: "tcpp-iframe-kfz" });
};
export default PartnerVersicherung;
