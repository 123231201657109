import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styled, { css } from 'styled-components/macro';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import Page from '@nomady/shared/routeConfiguration/Page';
import { useHistory } from 'react-router-dom';
import Logo from '../Logo/Logo';
import MainNavigation from '../MainNavigation/MainNavigation';
import IconButtonOld, { OldIconButtonColorVariant, } from '../IconButtonOld/IconButtonOld';
import { IconSizeVariant, IconVariant } from '../Icon/Icon';
import { isAuthenticatedSelector, logout } from '../../ducks/Auth.duck';
import { NamedLink } from '../../components';
import BackDrop from '../BackDrop/BackDrop';
import { localeSelector, menuOpenSelector, safeSpaceTopSelector, setDisableScroll, setMenuOpen, } from '../../slices/UISlice';
import ListingSearchBar from '../ListingSearchBar/ListingSearchBar';
import { setIsOpen } from '../../slices/listingSearchSlice';
import { ColorVariant } from '../../theme';
import { hasListingFavoritesListsSelector } from '../../slices/listingFavoritesListsSlice';
import MenuItem from '../MenuItem/MenuItem';
import LimitedAccessBanner from '../LimitedAccessBanner/LimitedAccessBanner';
import { isApp } from '../../util/deviceContext';
import useBreakpoints from '../../util/useMediaBreakpoints';
import { shouldShowBackButton } from './utils';
export const TOPBAR_HEIGHT = {
    mobile: '68px',
    tablet: '100px',
};
const Wrapper = styled.section `
  height: ${TOPBAR_HEIGHT.mobile};
  width: 100%;
  position: absolute;
  top: ${props => props.safeSpaceTop}px;
  ${props => !props.transparent &&
    css `
      @media ${props => props.theme.device.tablet} {
        background: ${props => props.theme.color.basicSnowGrey};
      }
    `}
  @media ${props => props.theme.device.tablet} {
    height: ${TOPBAR_HEIGHT.tablet};

    ${props => props.theme.priority.high}
  }
`;
const Content = styled.div `
  display: flex;
  min-width: 0;
  height: 100%;
  align-items: center;
  justify-content: space-between;

  margin-left: 16px;
  margin-right: 16px;

  @media ${props => props.theme.device.tablet} {
    gap: 12px;
  }

  @media ${props => props.theme.device.desktopXL} {
    margin-left: auto;
    margin-right: auto;
    max-width: 1580px;
  }
`;
const Left = styled.div `
  align-items: center;

  ${props => !props.hideListingSearchBar
    ? css `
          display: none;
        `
    : css `
          display: flex;
        `}

  @media ${props => props.theme.device.tablet} {
    display: flex;
    ${props => props.theme.priority.high}
    flex: 0 0 auto;
  }
`;
const Center = styled.div `
  display: ${props => (props.hideSearchBarInMobile ? 'none' : 'flex')};
  flex: 1;
  min-width: 0;
  justify-content: stretch;

  @media ${props => props.theme.device.tablet} {
    display: flex;
  }
`;
const Right = styled.div `
  @media ${props => props.theme.device.tablet} {
    ${props => props.theme.priority.high}

    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 0 0 auto;
  }
`;
const TopLinkWrapper = styled.div `
  display: none;

  @media ${props => props.theme.device.desktop} {
    height: 32px;
    width: fit-content;
    display: flex;
    flex: 0;
    margin-right: 20px;
    margin-left: 20px;
    background: ${props => props.theme.color.pearl};
    border-radius: 49px;
    padding-right: 20px;

    a {
      padding-left: 10px;
    }
  }
`;
const MenuWrapper = styled.div `
  ${props => !props.menuOpen && `display:none;`}

  position: absolute;

  ${props => props.theme.priority.high}

  top: ${TOPBAR_HEIGHT.mobile};
  left: 0;
  height: calc(100vh - ${TOPBAR_HEIGHT.mobile});
  overflow-y: auto;
  border-radius: 20px 20px 0px 0px;

  @media ${props => props.theme.device.tablet} {
    margin-left: -200px;
    top: 85px;
    bottom: initial;
    left: initial;
    border-radius: initial;
  }
`;
const CloseButtonWrapper = styled.div `
  position: fixed;
  ${props => props.theme.priority.high}
  top: ${props => props.safeSpaceTop + 10}px;
  left: 15px;

  @media ${props => props.theme.device.tablet} {
    display: none;
  }
`;
const BackButtonContainer = styled.div `
  max-width: 1580px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  pointer-events: none;
  & button {
    pointer-events: auto;
  }
`;
const tertiaryMenuItems = [
    {
        label: 'TopBar.buyGiftcard',
        to: Page.BuyGiftCard,
        icon: IconVariant.GiftCard,
    },
    {
        label: 'TopBar.scoutPage',
        to: Page.Scout,
    },
    {
        label: 'TopBar.hostPage',
        to: Page.Host,
    },
    {
        label: 'TopBar.camperPage',
        to: Page.Camper,
    },
    {
        label: 'TopBar.contactPage',
        to: Page.Contact,
    },
];
const TopBar = ({ hideListingSearchBar = false, transparent = false, logoColorVariant = ColorVariant.Cave, hideSearchBarInMobile = false, onClickListingSearchBar, }) => {
    var _a, _b, _c, _d;
    const intl = useIntl();
    const isAuthenticated = useSelector(isAuthenticatedSelector);
    const { authScopes } = useSelector((state) => state.Auth);
    const selectedLocale = useSelector(localeSelector);
    const safeSpaceTop = useSelector(safeSpaceTopSelector);
    const history = useHistory();
    const { currentUser, currentUserHasListings: hasListings, currentProviderNotificationCount, } = useSelector((state) => state.user);
    const hasListingFavoritesLists = useSelector(hasListingFavoritesListsSelector);
    const showLimitedAccessBanner = (currentUser === null || currentUser === void 0 ? void 0 : currentUser.id) &&
        isAuthenticated &&
        authScopes &&
        authScopes.length === 1 &&
        authScopes[0] === 'user:limited';
    const userInitials = (_a = currentUser === null || currentUser === void 0 ? void 0 : currentUser.attributes) === null || _a === void 0 ? void 0 : _a.profile.abbreviatedName;
    const userDisplayName = (_b = currentUser === null || currentUser === void 0 ? void 0 : currentUser.attributes) === null || _b === void 0 ? void 0 : _b.profile.displayName;
    const userAvatar = (_d = (_c = currentUser === null || currentUser === void 0 ? void 0 : currentUser.profileImage) === null || _c === void 0 ? void 0 : _c.attributes) === null || _d === void 0 ? void 0 : _d.variants['square-small'].url;
    const menuOpen = useSelector(menuOpenSelector);
    const dispatch = useDispatch();
    const onLogout = () => dispatch(logout());
    const onCloseSearchBarSheet = () => {
        dispatch(setDisableScroll(false));
        dispatch(setIsOpen(false));
    };
    const onToggleMenu = () => dispatch(setMenuOpen(!menuOpen));
    const onCloseMenu = () => dispatch(setMenuOpen(false));
    let primaryMenuItems = [
        {
            label: 'TopBar.signIn',
            to: Page.Login,
            icon: IconVariant.Login,
        },
        {
            label: 'TopBar.signUp',
            to: Page.Signup,
            icon: IconVariant.UserAdd,
        },
    ];
    let secondaryMenuItems = [
        {
            label: 'TopBar.newCamp',
            to: Page.Host,
            icon: IconVariant.PlusThin,
        },
    ];
    if (isAuthenticated) {
        primaryMenuItems = [];
        if (isApp) {
            primaryMenuItems.push({
                label: 'TopBar.startpage',
                to: Page.Landing,
                icon: IconVariant.Hut,
            });
        }
        primaryMenuItems.push({
            label: 'TopBar.journeys',
            to: Page.InboxOrders,
            icon: IconVariant.Van,
        });
        secondaryMenuItems = [
            {
                label: 'TopBar.createListing',
                to: Page.NewListing,
                icon: IconVariant.PlusThin,
            },
        ];
        if (hasListings) {
            primaryMenuItems.push({
                label: 'TopBar.bookings',
                to: Page.InboxBase,
                icon: IconVariant.Tent,
                hasNotifications: !!(currentProviderNotificationCount &&
                    0 < currentProviderNotificationCount),
            });
        }
        primaryMenuItems.push({
            label: 'TopBar.yourCamps',
            to: Page.ManageListings,
            icon: IconVariant.Postcard,
        });
        if (hasListingFavoritesLists) {
            primaryMenuItems.push({
                label: 'TopBar.listingFavoritesLists',
                to: Page.ListingFavoritesLists,
                icon: IconVariant.Heart,
            });
        }
        primaryMenuItems.push({
            label: 'TopBar.profile',
            to: Page.ProfileSettings,
            icon: IconVariant.User,
        });
    }
    const showBackButton = shouldShowBackButton(history.location.pathname);
    const { isTablet } = useBreakpoints();
    return (_jsxs(_Fragment, { children: [showLimitedAccessBanner && currentUser && (_jsx(LimitedAccessBanner, { currentUser: currentUser, onLogout: onLogout })), _jsx(Wrapper, Object.assign({ transparent: transparent, onClick: onClickListingSearchBar, safeSpaceTop: safeSpaceTop }, { children: _jsxs(Content, { children: [_jsx(Left, Object.assign({ hideListingSearchBar: hideListingSearchBar }, { children: isApp ? (_jsx(_Fragment, { children: showBackButton ? (_jsx(BackButtonContainer, { children: _jsx(IconButtonOld, { iconVariant: IconVariant.ArrowLeft, onClick: history.goBack }) })) : (_jsx(NamedLink, Object.assign({ name: Page.Landing }, { children: _jsx(Logo, { responsive: true, colorVariant: logoColorVariant }) }))) })) : (_jsx(NamedLink, Object.assign({ name: Page.Landing, onClick: () => {
                                    onCloseMenu();
                                    onCloseSearchBarSheet();
                                } }, { children: _jsx(Logo, { responsive: true, colorVariant: logoColorVariant }) }))) })), !hideListingSearchBar && (_jsx(Center, Object.assign({ hideSearchBarInMobile: hideSearchBarInMobile }, { children: _jsx(ListingSearchBar, { dataTestId: "TopBar_ListingSearchBar", integratedInTopBar: true }) }))), _jsxs(Right, { children: [(!isAuthenticated || !hasListings) && (_jsx(TopLinkWrapper, { children: _jsx(MenuItem, { label: intl.formatMessage({
                                            id: 'TopBar.newCamp',
                                        }), icon: IconVariant.PlusThin, to: isAuthenticated ? Page.NewListing : Page.Host, hoverColorVariant: ColorVariant.Pearl }) })), _jsxs("nav", Object.assign({ "aria-label": intl.formatMessage({
                                        id: 'TopBar.mainMenu',
                                    }), role: "navigation" }, { children: [(hideListingSearchBar || isTablet) && (_jsx(IconButtonOld, { iconVariant: IconVariant.Hamburger, onClick: () => {
                                                onCloseSearchBarSheet();
                                                onToggleMenu();
                                            }, avatar: {
                                                initials: userInitials || '',
                                                avatarSource: userAvatar || '',
                                                notificationCount: currentProviderNotificationCount,
                                            }, ariaExpanded: menuOpen, ariaHasPopUp: true, ariaControls: "main-menu" })), _jsx(MenuWrapper, Object.assign({ menuOpen: menuOpen, "aria-hidden": menuOpen, onClick: onCloseMenu }, { children: _jsx(MainNavigation, { userInitials: userInitials, userDisplayName: userDisplayName, userAvatarSource: userAvatar, primaryMenuItems: primaryMenuItems, secondaryMenuItems: secondaryMenuItems, tertiaryMenuItems: tertiaryMenuItems, logout: () => {
                                                    onLogout();
                                                    onCloseMenu();
                                                }, selectedLocale: selectedLocale, id: "main-menu" }) }))] }))] })] }) })), menuOpen && (_jsxs(_Fragment, { children: [_jsx(CloseButtonWrapper, Object.assign({ safeSpaceTop: safeSpaceTop }, { children: _jsx(IconButtonOld, { iconVariant: IconVariant.Revert, iconSizeVariant: IconSizeVariant.Medium, colorVariant: OldIconButtonColorVariant.Dark, onClick: () => {
                                onCloseMenu();
                                onCloseSearchBarSheet();
                            }, "aria-label": intl.formatMessage({
                                id: 'TopBar.closeMenu',
                            }) }) })), _jsx(BackDrop, { onClick: () => {
                            onCloseMenu();
                            onCloseSearchBarSheet();
                        }, fullTransparency: true })] }))] }));
};
export default TopBar;
