import { useEffect, useState } from 'react';
import { getSeatsAvailability } from '../../utils';
const useGetAvailability = (bookingStart, bookingEnd, timeSlots, totalSeats) => {
    const [availableSeats, setAvailableSeats] = useState(0);
    useEffect(() => {
        if (bookingStart && bookingEnd) {
            const availability = getSeatsAvailability(timeSlots !== null && timeSlots !== void 0 ? timeSlots : [], bookingStart, bookingEnd);
            setAvailableSeats(availability !== null && availability !== void 0 ? availability : 0);
        }
    }, [bookingStart, bookingEnd]);
    if ((!bookingStart || !bookingEnd) && totalSeats) {
        return totalSeats;
    }
    return availableSeats;
};
export default useGetAvailability;
