var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ProfileSettingsForm from '../../forms/ProfileSettingsForm/ProfileSettingsForm';
import { FormattedMessage } from '../../util/reactIntl';
import { uploadImage, updateProfile, } from '../../containers/ProfileSettingsPage/ProfileSettingsPageSlice';
import { currentUserSelector } from '../../ducks/user.duck';
import { onImageUploadHandler } from '../../containers/ProfileSettingsPage/ProfileSettingsPage';
import { Title } from '../EditListingDescriptionPanel/EditListingDescriptionPanel';
const EditListingHostProfilePanel = props => {
    const dispatch = useDispatch();
    const onImageUpload = (data) => dispatch(uploadImage(data));
    const onUpdateProfile = (data) => dispatch(updateProfile(data));
    const { updateInProgress, updateProfileError, uploadImageError, uploadInProgress, } = useSelector((state) => state.ProfileSettingsPage);
    const currentUser = useSelector(currentUserSelector);
    if (!currentUser) {
        return null;
    }
    const handleSubmit = (values) => __awaiter(void 0, void 0, void 0, function* () {
        yield onUpdateProfile(values);
        if (props.onSubmit) {
            props.onSubmit();
        }
    });
    return (_jsxs(_Fragment, { children: [_jsx(Title, { children: _jsx(FormattedMessage, { id: "EditListingHostProfilePanel.title" }) }), _jsx(ProfileSettingsForm, { currentUser: currentUser, onImageUpload: (e) => onImageUploadHandler(e, onImageUpload), updateInProgress: updateInProgress, uploadProfileInProgress: uploadInProgress, uploadImageError: uploadImageError, updateProfileError: updateProfileError, onSubmit: handleSubmit })] }));
};
export default EditListingHostProfilePanel;
