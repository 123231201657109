import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styled from 'styled-components/macro';
import { useIntl } from 'react-intl';
import Text, { TextVariant } from '../../../styled-components/Text/Text';
import { ColorVariant } from '../../../theme';
import Button, { ButtonColorVariant, ButtonSizeVariant, } from '../../Button/Button';
const Message = styled(Text) `
  display: block;
  margin-bottom: 16px;
`;
const ContactButtonWrapper = styled.div `
  display: flex;
`;
export const UnsureAboutEmail = () => {
    const intl = useIntl();
    return (_jsxs(_Fragment, { children: [_jsx(Message, Object.assign({ variant: TextVariant.B30, colorVariant: ColorVariant.Snow }, { children: intl.formatMessage({
                    id: 'MigrateMyCabinUser.unsureAboutEmail',
                }) })), _jsx(ContactButtonWrapper, { children: _jsx(Button, { externalLink: "mailto:hello@nomady.camp", target: "_blank", label: "hello@nomady.camp", sizeVariant: ButtonSizeVariant.Small, colorVariant: ButtonColorVariant.Dark }) })] }));
};
