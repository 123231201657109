import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styled from 'styled-components/macro';
import { ColorVariant } from '../../../theme';
import Icon, { IconVariant } from '../../Icon/Icon';
import Text, { TextVariant } from '../../Text/Text';
const ItemWrapper = styled.span `
  display: flex;
  align-items: center;
  margin-block: 8px;
`;
const IconWrapper = styled.span `
  margin-right: 6px;
`;
const ListingChecklistItem = ({ isChecked, text }) => {
    return (_jsxs(ItemWrapper, { children: [_jsx(IconWrapper, { children: _jsx(Icon, { variant: isChecked ? IconVariant.Checkmark : IconVariant.Cross, colorVariant: isChecked ? ColorVariant.Aquamarine : ColorVariant.Stone }) }), _jsx(Text, Object.assign({ variant: TextVariant.H51, colorVariant: isChecked ? ColorVariant.Cave : ColorVariant.Fog }, { children: text }))] }));
};
export default ListingChecklistItem;
