var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import TextArea from '../../../styled-components/TextArea';
import { sendMessageToProvider } from '../CheckoutPageSlice';
const MessageForm = ({ formRef, transactionId, hostName }) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const { register, handleSubmit, control } = useForm();
    const message = register('message');
    const onSuccess = (values) => __awaiter(void 0, void 0, void 0, function* () {
        const { message } = values;
        if (!!message && !!transactionId) {
            dispatch(sendMessageToProvider(message, transactionId));
        }
    });
    return (_jsx("form", Object.assign({ onSubmit: handleSubmit(onSuccess), ref: formRef }, { children: _jsx(TextArea, Object.assign({ placeholder: intl.formatMessage({
                id: 'MessageForm.placeholder',
            }, { name: hostName }), control: control }, message, { name: "message", dataTestId: "MessageForm_textArea" })) })));
};
export default MessageForm;
