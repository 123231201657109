import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { Controller, } from 'react-hook-form';
import styled from 'styled-components/macro';
import { mapMarkerTypes, } from '@nomady/shared/marketplace-custom-config';
import { IconVariant } from '../Icon/Icon';
import InputErrorMessage from '../InputErrorMessage/InputErrorMessage';
import Text, { TextVariant } from '../Text/Text';
import { Wrapper, Label, ErrorMessageWrapper, FakeInput, Description, } from '../Input/Input';
import GoogleMapCoordinatePicker from './GoogleMapCoordinatePicker';
import config from '../../config';
import { useIntl } from 'react-intl';
import mapMarkerIcon from '../../util/mapMarkerIcon';
import Card from '../Card/Card';
import IconButtonOld from '../IconButtonOld/IconButtonOld';
const EditMarkersWrapper = styled.div `
  display: grid;
  grid-auto-flow: column;
  width: 100%;
`;
const EditMarker = styled.button `
  display: flex;
  flex-direction: column;
  align-items: center;

  outline: 0;
  border: 0;

  cursor: pointer;

  ${props => props.active &&
    `

    @keyframes bounce {
      from {
        transform: translateY(0px);
      }
      to {
        transform: translateY(-10px);
      }
    }
      
    @-webkit-keyframes bounce {
      from {
        transform: translateY(0px);
      }
      to {
        transform: translateY(-10px);
      }
    }

    animation: bounce 0.75s infinite alternate;
    -webkit-animation: bounce 0.75s infinite alternate;
  `};
`;
const MarkerIcon = styled.img `
  height: 40px;
  width: 33.23px;
`;
const StyledFakeInput = styled(FakeInput) `
  display: grid;
  grid-auto-flow: row;
  padding: 16px;
  row-gap: 16px;
`;
const SelectedMarkers = styled.div `
  display: grid;
  grid-auto-flow: row;
  padding: 16px;
  row-gap: 16px;
`;
const SelectedMarker = styled.div `
  display: grid;
  grid-template-columns: 40px 1fr 40px;
  column-gap: 8px;
  align-items: center;
  grid-auto-flow: column;
`;
const InputListingMarker = ({ id, label, description, variant, error, initialCenter, control, rules, name, }) => {
    const hasError = !!error;
    const intl = useIntl();
    const [activeMarker, setActiveMarker] = useState(null);
    const errorMessageId = hasError ? `${id}-errormessage` : '';
    const errorMessageAriaInputProps = hasError
        ? { 'aria-invalid': true, 'aria-errormessage': errorMessageId }
        : {};
    return (_jsx(Controller, { render: ({ field }) => {
            const markers = field.value || [];
            return (_jsxs(Wrapper, { children: [label && (_jsx(Label, Object.assign({ inputVariant: variant, variant: TextVariant.H13, as: "label", htmlFor: id, hasError: hasError }, { children: label }))), _jsxs(StyledFakeInput, Object.assign({ variant: variant, hasError: hasError }, errorMessageAriaInputProps, { children: [_jsx(EditMarkersWrapper, { children: config.custom.mapMarkerTypes.map(marker => (_jsxs(EditMarker, Object.assign({ type: "button", active: marker.key === activeMarker }, { children: [_jsx(Text, Object.assign({ variant: TextVariant.B30 }, { children: marker.label
                                                ? intl.formatMessage({ id: marker.label })
                                                : '' })), _jsx(MarkerIcon, { src: mapMarkerIcon[marker.key], onClick: () => setActiveMarker(marker.key), alt: intl.formatMessage({ id: 'EditListingDescriptionForm.markerIcon' }, {
                                                markerLabel: intl.formatMessage({
                                                    id: marker.label,
                                                }),
                                            }) })] }), `mapmarker_${marker.key}`))) }), _jsx(GoogleMapCoordinatePicker, { markers: markers, chosenMarker: activeMarker, initialCenter: initialCenter, addMarker: (marker) => {
                                    field.onChange([...markers, marker]);
                                } }), description && _jsx(Description, { text: description })] })), hasError && (_jsx(ErrorMessageWrapper, Object.assign({ role: "alert" }, { children: _jsx(InputErrorMessage, { id: errorMessageId, error: error }) }))), field.value && 0 < field.value.length && (_jsx(SelectedMarkers, { children: field.value.map((marker) => {
                            const { key, type } = marker;
                            const icon = mapMarkerIcon[type];
                            const keyLabelConfig = Object.values(mapMarkerTypes).find(marker => marker.key === type);
                            const label = intl.formatMessage({
                                id: keyLabelConfig.label,
                            });
                            return (_jsx(Card, { children: _jsxs(SelectedMarker, { children: [_jsx("img", { src: icon, alt: intl.formatMessage({ id: 'EditListingDescriptionForm.markerIcon' }, { markerLabel: label }) }), _jsx(Text, Object.assign({ variant: TextVariant.B30, as: "p" }, { children: intl.formatMessage({
                                                id: label,
                                            }) })), _jsx(IconButtonOld, { iconVariant: IconVariant.Cross, onClick: () => {
                                                const newMarkers = markers.filter(stateMarker => {
                                                    return (marker !== stateMarker && stateMarker !== null);
                                                });
                                                field.onChange(newMarkers);
                                            } })] }) }, key));
                        }) }))] }));
        }, name: name, control: control, rules: rules }));
};
export default InputListingMarker;
