import { EXTRA_GUESTS, GIFT_CARD_DEDUCTION_LINE_ITEMS, LINE_ITEM_CLEANING_FEE, LINE_ITEM_CUSTOMER_COMMISSION, LINE_ITEM_CUSTOMER_COMMISSION_DISCOUNT, LINE_ITEM_PROVIDER_COMMISSION, LINE_ITEM_SEATS, TOURIST_TAX_LINE_ITEMS, } from '../../utils/lineItemTypes';
import { formatValueForEmail } from './getTransactionProtectedData';
import { calculateTotalForProviderBeforeCommission } from '../../utils/lineItemHelpers';
const getEmailData = (lineItems, currency) => {
    const emailData = {
        currency,
        providerTotalBeforeCommissionFormat: '0.00',
        customerServiceFeeFormat: '0.00',
        providerServiceFeeFormat: '0.00',
        touristTaxFormat: '0.00',
        cleaningFeeFormat: '0.00',
        discountCodeFormat: '0.00',
        giftCardFormat: '0.00',
        guests: 0,
        nights: 0,
    };
    let touristTax = 0;
    let giftCardDeductionTotal = 0;
    const seatLineItem = lineItems.find(lineItem => lineItem.code === LINE_ITEM_SEATS);
    emailData.nights = seatLineItem.units.toNumber();
    const providerTotalBeforeCommission = calculateTotalForProviderBeforeCommission(lineItems).amount;
    emailData.providerTotalBeforeCommissionFormat = formatValueForEmail(providerTotalBeforeCommission);
    lineItems.forEach(lineItem => {
        if (TOURIST_TAX_LINE_ITEMS.includes(lineItem.code)) {
            touristTax += lineItem.lineTotal.amount;
        }
        if (lineItem.code === LINE_ITEM_CLEANING_FEE) {
            emailData.cleaningFeeFormat = formatValueForEmail(lineItem.lineTotal.amount);
        }
        if (lineItem.code === LINE_ITEM_CUSTOMER_COMMISSION) {
            emailData.customerServiceFeeFormat = formatValueForEmail(lineItem.lineTotal.amount);
        }
        if (lineItem.code === LINE_ITEM_PROVIDER_COMMISSION) {
            emailData.providerServiceFeeFormat = formatValueForEmail(-1 * lineItem.lineTotal.amount);
        }
        if (EXTRA_GUESTS.includes(lineItem.code)) {
            emailData.guests += lineItem.seats;
        }
        if (lineItem.code === LINE_ITEM_CUSTOMER_COMMISSION_DISCOUNT) {
            emailData.discountCodeFormat = formatValueForEmail(lineItem.lineTotal.amount);
        }
        if (GIFT_CARD_DEDUCTION_LINE_ITEMS.includes(lineItem.code)) {
            giftCardDeductionTotal += lineItem.lineTotal.amount;
        }
    });
    emailData.touristTaxFormat = formatValueForEmail(touristTax);
    emailData.giftCardFormat = formatValueForEmail(giftCardDeductionTotal);
    return emailData;
};
export default getEmailData;
