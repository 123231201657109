import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styled from 'styled-components/macro';
import Icon, { IconSizeVariant } from '../Icon/Icon';
import Avatar, { SizeVariant } from '../Avatar/Avatar';
import { ColorVariant } from '../../theme';
import Spinner from '../Spinner/Spinner';
export var OldButtonBorderRadiusVariant;
(function (OldButtonBorderRadiusVariant) {
    OldButtonBorderRadiusVariant[OldButtonBorderRadiusVariant["Default"] = 40] = "Default";
    OldButtonBorderRadiusVariant[OldButtonBorderRadiusVariant["Squared"] = 10] = "Squared";
})(OldButtonBorderRadiusVariant || (OldButtonBorderRadiusVariant = {}));
export var OldIconButtonColorVariant;
(function (OldIconButtonColorVariant) {
    OldIconButtonColorVariant["Default"] = "Light";
    OldIconButtonColorVariant["Dark"] = "Dark";
    OldIconButtonColorVariant["Light"] = "Light";
    OldIconButtonColorVariant["Cactus"] = "Cactus";
    OldIconButtonColorVariant["Stone"] = "Stone";
    OldIconButtonColorVariant["Forest"] = "Forest";
    OldIconButtonColorVariant["Transparent"] = "Transparent";
})(OldIconButtonColorVariant || (OldIconButtonColorVariant = {}));
export var OldIconButtonSizeVariant;
(function (OldIconButtonSizeVariant) {
    OldIconButtonSizeVariant["Default"] = "48px";
    OldIconButtonSizeVariant["Small"] = "24px";
    OldIconButtonSizeVariant["ExtraSmall"] = "18px";
    OldIconButtonSizeVariant["Medium"] = "32px";
    OldIconButtonSizeVariant["Large"] = "48px";
})(OldIconButtonSizeVariant || (OldIconButtonSizeVariant = {}));
export var OldIconButtonOutlineVariant;
(function (OldIconButtonOutlineVariant) {
    OldIconButtonOutlineVariant["Default"] = "Default";
    OldIconButtonOutlineVariant["Outlined"] = "Dark";
})(OldIconButtonOutlineVariant || (OldIconButtonOutlineVariant = {}));
const Wrapper = styled.button `
  background-color: ${props => props.theme.color[props.backgroundColorVariant]};

  border-radius: ${props => props.buttonBorderRadiusVariant}px;

  height: ${props => props.iconButtonSizeVariant};
  width: ${props => props.iconButtonSizeVariant};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border: 0;

  ${props => props.disabled &&
    `
    opacity: 0.5;
  `}

  ${props => props.iconSizeVariant === IconSizeVariant.Medium &&
    `
    padding: 6px;
  `}

  ${props => props.iconButtonOutlineVariant === OldIconButtonOutlineVariant.Outlined &&
    `
    border: 1px black solid;
    
  `}

  ${props => props.hasAvatar &&
    `
      width: auto;
      height: auto;
      padding: 6px 8px 6px 20px;
  `}

  display: flex;
  align-items: center;

  @media ${props => props.theme.device.tablet} {
    ${props => props.iconSizeVariant === IconSizeVariant.Medium &&
    `
    padding: 10px;
  `}

    ${props => props.hasAvatar &&
    `
    padding: 10px 12px 10px 24px;`}
  }

  &:hover {
    cursor: pointer;
  }
`;
const IconWrapper = styled.div `
  height: ${props => props.sizeVariant};
  width: ${props => props.sizeVariant};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  ${props => props.hasAvatar &&
    `
      width: auto;
      height: auto;
      margin-right: 22px;
  `}
`;
const getBackgroundColor = (colorVariant) => {
    switch (colorVariant) {
        case OldIconButtonColorVariant.Dark:
            return ColorVariant.Cave;
        case OldIconButtonColorVariant.Cactus:
            return ColorVariant.Cactus;
        case OldIconButtonColorVariant.Transparent:
            return ColorVariant.Transparent;
        case OldIconButtonColorVariant.Forest:
            return ColorVariant.Forest;
        case OldIconButtonColorVariant.Light:
        default:
            return ColorVariant.Pearl;
    }
};
const getIconColor = (colorVariant) => {
    switch (colorVariant) {
        case OldIconButtonColorVariant.Dark:
        case OldIconButtonColorVariant.Cactus:
            return ColorVariant.Pearl;
        case OldIconButtonColorVariant.Forest:
            return ColorVariant.Pearl;
        case OldIconButtonColorVariant.Stone:
            return ColorVariant.Stone;
        case OldIconButtonColorVariant.Light:
        default:
            return ColorVariant.Cave;
    }
};
/**
 * @deprecated Use IconButton instead
 */
const IconButtonOld = ({ dataTestId, iconVariant, iconSizeVariant = IconSizeVariant.Default, iconButtonSizeVariant = OldIconButtonSizeVariant.Default, buttonBorderRadiusVariant = OldButtonBorderRadiusVariant.Default, onClick, avatar, ariaLabel, ariaExpanded, ariaControls, ariaHasPopUp, colorVariant = OldIconButtonColorVariant.Light, disabled, type, inProgress = false, iconButtonOutlineVariant = OldIconButtonOutlineVariant.Default, }) => {
    const iconContent = inProgress ? (_jsx(Spinner, { colorVariant: getIconColor(colorVariant) })) : (_jsx(Icon, { variant: iconVariant, colorVariant: getIconColor(colorVariant), sizeVariant: iconSizeVariant }));
    return (_jsxs(Wrapper, Object.assign({ "data-testid": dataTestId, onClick: onClick, hasAvatar: !!avatar, backgroundColorVariant: getBackgroundColor(colorVariant), iconSizeVariant: iconSizeVariant, buttonBorderRadiusVariant: buttonBorderRadiusVariant, iconButtonSizeVariant: iconButtonSizeVariant, "aria-label": ariaLabel, "aria-expanded": ariaExpanded, "aria-controls": ariaControls, "aria-haspopup": ariaHasPopUp, disabled: disabled, type: type, iconButtonOutlineVariant: iconButtonOutlineVariant }, { children: [_jsx(IconWrapper, Object.assign({ hasAvatar: !!avatar, sizeVariant: iconSizeVariant }, { children: iconContent })), avatar && (_jsx(Avatar, { sizeVariant: SizeVariant.XS, initials: avatar.initials, source: avatar.avatarSource, notificationCount: avatar.notificationCount }))] })));
};
export default IconButtonOld;
