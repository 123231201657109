import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';
import { LOCALE_CODES } from '@nomady/shared/marketplace-custom-config.ts';
import { getUserLocale } from '@nomady/shared/utils/data.ts';
import { propTypes } from '@nomady/shared/utils/types.ts';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import * as validators from '../../util/validators';
import { Form, FieldSelect, FieldCheckbox } from '../../components/index.ts';
import css from './LanguageSettingsForm.module.scss';
import Button, {
  ButtonColorVariant,
} from '../../styled-components/Button/Button.tsx';
import AutomaticTranslationToggle from '../../styled-components/AutomaticTranslationToggle/AutomaticTranslationToggle.tsx';
import { isApp } from '../../util/deviceContext';

const RESET_TIMEOUT = 800;
const automaticTranslationToggleName = 'automaticTranslationEnabled';

class LanguageSettingsFormComponent extends Component {
  constructor(props) {
    super(props);
    this.resetTimeoutId = null;
    this.submittedValues = {};
  }

  componentWillUnmount() {
    window.clearTimeout(this.resetTimeoutId);
  }

  render() {
    return (
      <FinalForm
        {...this.props}
        initialValues={{
          locale: getUserLocale(this.props.currentUser),
          automaticTranslationEnabled:
            this.props.currentUser?.attributes?.profile?.privateData
              ?.automaticTranslationEnabled,
        }}
        mutators={{
          toggleAutomaticTranslation: (args, state, util) => {
            util.changeValue(
              state,
              automaticTranslationToggleName,
              () => args[0]
            );
          },
        }}
        render={fieldRenderProps => {
          const {
            rootClassName,
            className,
            formId,
            languageSettingsError,
            currentUser,
            handleSubmit,
            inProgress,
            intl,
            invalid,
            ready,
            form,
            values,
          } = fieldRenderProps;

          if (!currentUser?.id) {
            return null;
          }

          const languageRequiredMessage = intl.formatMessage({
            id: 'LanguageSettingsForm.languageRequired',
          });

          const languageRequired = validators.required(languageRequiredMessage);

          const genericFailure =
            languageSettingsError && !languageRequired ? (
              <span className={css.error}>
                <FormattedMessage id="LanguageSettingsForm.genericFailure" />
              </span>
            ) : null;

          const submittedOnce = Object.keys(this.submittedValues).length > 0;
          const pristineSinceLastSubmit =
            submittedOnce && isEqual(values, this.submittedValues);
          const classes = classNames(rootClassName || css.root, className);
          const submitDisabled =
            invalid || pristineSinceLastSubmit || inProgress;

          const languageTitle = (
            <span className={css.title}>
              <FormattedMessage id="LanguageSettingsPage.heading" />
            </span>
          );

          const automaticTranslationTitle = (
            <span className={css.title}>
              <FormattedMessage id="LanguageSettingsPage.automaticTranslationHeading" />
            </span>
          );

          const automaticTranslationDescription = (
            <p>
              <FormattedMessage id="LanguageSettingsPage.automaticTranslationDescription" />
            </p>
          );

          return (
            <Form
              className={classes}
              onSubmit={e => {
                this.submittedValues = values;
                handleSubmit(e)
                  .then(() => {
                    this.resetTimeoutId = window.setTimeout(
                      form.reset,
                      RESET_TIMEOUT
                    );
                  })
                  .catch(() => {
                    // Error is handled in duck file already.
                  });
              }}
            >
              <div className={css.languageTitleWrapper}>{languageTitle}</div>
              {!isApp && (
                <FieldSelect
                  id={formId ? `${formId}.locale` : 'locale'}
                  name="locale"
                  className={css.field}
                  validate={languageRequired}
                >
                  {LOCALE_CODES.map(language => {
                    return (
                      <option key={language.key} value={language.key}>
                        {language.label}
                      </option>
                    );
                  })}
                </FieldSelect>
              )}
              {isApp && intl.formatMessage({ id: 'LocaleSwitch.appInfo' })}
              <div className={css.bottomWrapper}>
                {automaticTranslationTitle}
                {automaticTranslationDescription}
                <AutomaticTranslationToggle
                  checked={values[automaticTranslationToggleName]}
                  onToggle={form.mutators.toggleAutomaticTranslation}
                />
                <FieldCheckbox
                  checked={values[automaticTranslationToggleName]}
                  id={automaticTranslationToggleName}
                  name={automaticTranslationToggleName}
                  className={css.checkbox}
                />
              </div>

              <div className={css.bottomWrapper}>
                {genericFailure}
                <Button
                  type="submit"
                  inProgress={inProgress}
                  ready={ready}
                  disabled={submitDisabled}
                  colorVariant={ButtonColorVariant.Fir}
                  label={intl.formatMessage({
                    id: 'LanguageSettingsForm.saveChanges',
                  })}
                />
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

LanguageSettingsFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  languageSettingsError: null,
  inProgress: false,
  formId: null,
};

const { bool, string } = PropTypes;

LanguageSettingsFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  languageSettingsError: propTypes.error,
  inProgress: bool,
  intl: intlShape.isRequired,
  ready: bool.isRequired,
  formId: string,
};

const LanguageSettingsForm = compose(injectIntl)(LanguageSettingsFormComponent);
LanguageSettingsForm.displayName = 'LanguageSettingsForm';

export default LanguageSettingsForm;
