import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import styled from 'styled-components/macro';
import { withDimensions } from '../../util/contextHelpers';
import Handle from './Handle';
import Track from './Track';
const Wrapper = styled.div `
  position: relative;
`;
class RangeSliderInput extends React.Component {
    constructor(props) {
        super(props);
        const { min, max, handles } = props;
        handles.forEach((handle, index) => {
            if (handle < min ||
                handle > max ||
                (index < handles.length - 1 && handle > handles[index + 1])) {
                throw new Error('RangeSlider error: handles need to be given in ascending order and they need to be within min and max values');
            }
        });
        this.state = { activeHandle: 0 };
    }
    toPosition(value) {
        const { dimensions, min, max } = this.props;
        const { width } = dimensions;
        const valueOffset = value - min;
        const scale = max - min;
        const position = Math.round((valueOffset / scale) * width);
        return position;
    }
    toValue(position) {
        const { dimensions, min, max, step } = this.props;
        const { width } = dimensions;
        const scale = max - min;
        const value = Math.round((position / width) * scale) + min;
        return Math.ceil(value / step) * step;
    }
    changeActive(index) {
        this.setState({ activeHandle: index });
    }
    onChange(position, handleIndex) {
        this.props.onChange(Object.assign([...this.props.handles], { [handleIndex]: position }));
    }
    render() {
        const { handles, min, max } = this.props;
        return (_jsx(Wrapper, { children: _jsx(Track, Object.assign({ handles: handles, valueToPosition: (value) => this.toPosition(value) }, { children: handles.map((handle, index) => (_jsx(Handle, { value: handle, min: index === 0 ? min : handles[index - 1], max: index === handles.length - 1 ? max : handles[index + 1], valueToPosition: (value) => this.toPosition(value), positionToValue: (position) => this.toValue(position), changeActive: () => this.changeActive(index), onChange: (value) => this.onChange(value, index), onMouseUp: () => this.props.onFinal() }, index))) })) }));
    }
}
export default withDimensions(RangeSliderInput, {
    pure: true,
});
