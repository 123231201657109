export const CHANGE_LOCATION = 'app/LocationFilter/CHANGE_LOCATION';
const LocationFilterReducer = (state = '', action) => {
    switch (action.type) {
        case CHANGE_LOCATION: {
            return action.payload;
        }
        default:
            return state;
    }
};
export default LocationFilterReducer;
export const changeLocationFilter = (location) => ({
    type: CHANGE_LOCATION,
    payload: location,
});
