import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { LISTING_STATE_DRAFT } from '@nomady/shared/utils/types';
import { FormattedMessage } from '../../util/reactIntl';
import { ListingLink } from '..';
import { Title } from '../EditListingDescriptionPanel/EditListingDescriptionPanel';
import EditListingPhotosForm from '../../forms/EditListingPhotosForm/EditListingPhotosForm';
const EditListingPhotosPanel = props => {
    var _a;
    const { listing, onSubmit, onFormDirty, submitButtonText, updateInProgress, errors, onImageUploaded, } = props;
    const isDraft = (listing === null || listing === void 0 ? void 0 : listing.id) && ((_a = listing === null || listing === void 0 ? void 0 : listing.attributes) === null || _a === void 0 ? void 0 : _a.state) === LISTING_STATE_DRAFT;
    const panelTitle = isDraft ? (_jsx(FormattedMessage, { id: "EditListingPhotosPanel.createListingTitle" })) : (_jsx(FormattedMessage, { id: "EditListingPhotosPanel.title", values: { listingTitle: _jsx(ListingLink, { listing: listing }) } }));
    return (_jsxs(_Fragment, { children: [_jsx(Title, { children: panelTitle }), _jsx(EditListingPhotosForm, { listing: listing, submitButtonLabel: submitButtonText, updateListingInProgress: updateInProgress, updateListingError: errors.updateListingError, onSubmit: onSubmit, onFormDirty: onFormDirty, onImageUploaded: onImageUploaded })] }));
};
export default EditListingPhotosPanel;
