import * as z from 'zod';
import { localizedZodCustomErrorMap } from '../localizedZodCustomErrorMap';
import { DESCRIPTION_MAX_LENGTH, DESCRIPTION_MIN_LENGTH } from './description';
import { WinterActivity } from '../../types';
const WinterCapabilityTrueSchema = z.object({
    winterCapability: z.literal(true),
    descriptionWinter: z
        .string()
        .min(DESCRIPTION_MIN_LENGTH)
        .max(DESCRIPTION_MAX_LENGTH),
    winterAccessAndGroundDescription: z.string().nullish(),
    winterActivities: z.array(z.nativeEnum(WinterActivity)).nullish(),
    winterActivitiesDescription: z.string().nullish(),
    descriptionWinterEnabled: z.boolean().nullish(),
});
const WinterCapabilityFalseSchema = z.object({
    winterCapability: z.literal(false),
    descriptionWinter: z.literal(null),
    descriptionWinterEnabled: z.literal(null),
    winterAccessAndGroundDescription: z.literal(null),
    winterActivities: z.literal(null),
    winterActivitiesDescription: z.literal(null),
});
const WinterCapabilityNullSchema = z.object({
    winterCapability: z.literal(null),
    descriptionWinter: z.literal(null),
    descriptionWinterEnabled: z.literal(null),
    winterAccessAndGroundDescription: z.literal(null),
    winterActivities: z.literal(null),
    winterActivitiesDescription: z.literal(null),
});
export const ListingDescriptionWinterSchema = z.discriminatedUnion('winterCapability', [
    WinterCapabilityTrueSchema,
    WinterCapabilityFalseSchema,
    WinterCapabilityNullSchema,
]);
export const getListingDescriptionWinterSchema = (intl) => {
    z.setErrorMap(localizedZodCustomErrorMap(intl, 'ListingSchemaError'));
    return ListingDescriptionWinterSchema;
};
export const getListingDescriptionWinterDefaultValues = (attributes) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x;
    return ({
        winterCapability: (_a = attributes === null || attributes === void 0 ? void 0 : attributes.publicData) === null || _a === void 0 ? void 0 : _a.winterCapability,
        descriptionWinter: ((_e = (_d = (_c = (_b = attributes === null || attributes === void 0 ? void 0 : attributes.publicData) === null || _b === void 0 ? void 0 : _b.localizedPublicData) === null || _c === void 0 ? void 0 : _c.seasons) === null || _d === void 0 ? void 0 : _d.winter) === null || _e === void 0 ? void 0 : _e.description) ||
            ((_j = (_h = (_g = (_f = attributes === null || attributes === void 0 ? void 0 : attributes.publicData) === null || _f === void 0 ? void 0 : _f.localizedPublicData) === null || _g === void 0 ? void 0 : _g.seasons) === null || _h === void 0 ? void 0 : _h.summer) === null || _j === void 0 ? void 0 : _j.description),
        descriptionWinterEnabled: (_k = attributes === null || attributes === void 0 ? void 0 : attributes.publicData) === null || _k === void 0 ? void 0 : _k.descriptionWinterEnabled,
        winterAccessAndGroundDescription: (_p = (_o = (_m = (_l = attributes === null || attributes === void 0 ? void 0 : attributes.publicData) === null || _l === void 0 ? void 0 : _l.localizedPublicData) === null || _m === void 0 ? void 0 : _m.seasons) === null || _o === void 0 ? void 0 : _o.winter) === null || _p === void 0 ? void 0 : _p.accessAndGround,
        winterActivities: (_t = (_s = (_r = (_q = attributes === null || attributes === void 0 ? void 0 : attributes.publicData) === null || _q === void 0 ? void 0 : _q.localizedPublicData) === null || _r === void 0 ? void 0 : _r.seasons) === null || _s === void 0 ? void 0 : _s.winter) === null || _t === void 0 ? void 0 : _t.activities,
        winterActivitiesDescription: (_x = (_w = (_v = (_u = attributes === null || attributes === void 0 ? void 0 : attributes.publicData) === null || _u === void 0 ? void 0 : _u.localizedPublicData) === null || _v === void 0 ? void 0 : _v.seasons) === null || _w === void 0 ? void 0 : _w.winter) === null || _x === void 0 ? void 0 : _x.activitiesDescription,
    });
};
