var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { storableError } from '../../util/errors';
import { fetchCurrentUser } from '../../ducks/user.duck';
const initialState = {
    isVerified: false,
    verificationError: null,
    verificationInProgress: false,
};
export const EmailVerificationPageSlice = createSlice({
    name: 'app/EmailVerificationPage',
    initialState,
    reducers: {
        verificationRequest: state => {
            state.verificationInProgress = true;
            state.verificationError = null;
        },
        verificationSuccess: state => {
            state.verificationInProgress = false;
            state.verificationError = null;
            state.isVerified = true;
        },
        verificationError: (state, action) => {
            state.verificationInProgress = false;
            state.verificationError = action.payload;
        },
    },
});
export const { verificationRequest, verificationSuccess, verificationError } = EmailVerificationPageSlice.actions;
export const verify = (verificationToken) => (dispatch, getState, sdk) => __awaiter(void 0, void 0, void 0, function* () {
    if (verificationInProgressSelector(getState())) {
        return Promise.reject(new Error('Email verification already in progress'));
    }
    dispatch(verificationRequest());
    try {
        yield sdk.currentUser.verifyEmail({ verificationToken });
        dispatch(verificationSuccess());
        dispatch(fetchCurrentUser());
    }
    catch (error) {
        dispatch(verificationError(storableError(error)));
    }
    return Promise.resolve();
});
export const verificationInProgressSelector = (state) => state.EmailVerification.verificationInProgress;
export default EmailVerificationPageSlice.reducer;
