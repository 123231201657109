var IconVariant;
(function (IconVariant) {
    IconVariant["Spinner"] = "Spinner";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["ArrowDown"] = "ArrowDown";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["ArrowLeft"] = "ArrowLeft";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["ArrowRight"] = "ArrowRight";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["ArrowUp"] = "ArrowUp";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["AT"] = "AT";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["Backpack"] = "Backpack";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["Boot"] = "Boot";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["BubbleCheckmark"] = "BubbleCheckmark";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["BubbleFacebook"] = "BubbleFacebook";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["BubbleFlash"] = "BubbleFlash";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["BubbleInfo"] = "BubbleInfo";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["BubbleInstagram"] = "BubbleInstagram";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["BubbleLetter"] = "BubbleLetter";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["BubbleLinkedIn"] = "BubbleLinkedIn";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["BubbleSmile"] = "BubbleSmile";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["BubbleSnowflake"] = "BubbleSnowflake";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["Van"] = "Van";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["Calendar"] = "Calendar";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["Motorhome"] = "Motorhome";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["MotorhomePlus"] = "MotorhomePlus";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["CamperOnGround"] = "CamperOnGround";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["Campfire"] = "Campfire";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["Caravan"] = "Caravan";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["CH"] = "CH";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["Checkmark"] = "Checkmark";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["CircledCross"] = "CircledCross";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["Clock"] = "Clock";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["Coins"] = "Coins";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["Compass"] = "Compass";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["Cross"] = "Cross";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["Dart"] = "Dart";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["DE"] = "DE";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["DirectionLeft"] = "DirectionLeft";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["DirectionRight"] = "DirectionRight";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["Dog"] = "Dog";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["DoNotDisturbCard"] = "DoNotDisturbCard";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["Download"] = "Download";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["Drop"] = "Drop";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["Electricity"] = "Electricity";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["Eye"] = "Eye";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["Flag"] = "Flag";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["Flash"] = "Flash";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["FR"] = "FR";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["Fullscreen"] = "Fullscreen";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["Garbage"] = "Garbage";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["GiftCard"] = "GiftCard";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["Globe"] = "Globe";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["Hamburger"] = "Hamburger";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["Hand"] = "Hand";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["Heart"] = "Heart";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["HorseToy"] = "HorseToy";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["Hut"] = "Hut";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["IT"] = "IT";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["Knobs"] = "Knobs";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["Lightbulb"] = "Lightbulb";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["List"] = "List";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["Login"] = "Login";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["Logout"] = "Logout";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["Magnifier"] = "Magnifier";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["Map"] = "Map";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["Minicamping"] = "Minicamping";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["Minimize"] = "Minimize";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["Minus"] = "Minus";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["Moon"] = "Moon";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["MoonOutlined"] = "MoonOutlined";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["Mountains"] = "Mountains";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["NewTab"] = "NewTab";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["PaperScroll"] = "PaperScroll";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["Parking"] = "Parking";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["Password"] = "Password";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["Pen"] = "Pen";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["Phone"] = "Phone";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["Picture"] = "Picture";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["Pin"] = "Pin";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["Plus"] = "Plus";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["PlusThin"] = "PlusThin";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["Postcard"] = "Postcard";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["PublicTransport"] = "PublicTransport";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["Rainbow"] = "Rainbow";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["RedWarning"] = "RedWarning";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["Reset"] = "Reset";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["Revert"] = "Revert";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["Route"] = "Route";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["RooftopTent"] = "RooftopTent";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["Seat1"] = "Seat1";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["Seat2"] = "Seat2";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["Seat3"] = "Seat3";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["Seat4"] = "Seat4";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["Seat5"] = "Seat5";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["Seat6"] = "Seat6";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["Seat7"] = "Seat7";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["Seat8"] = "Seat8";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["Seat9"] = "Seat9";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["Seat10"] = "Seat10";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["SeatThreeDots"] = "SeatThreeDots";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["Shade"] = "Shade";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["Shelter"] = "Shelter";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["Shine"] = "Shine";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["Shop"] = "Shop";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["Shower"] = "Shower";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["Sign"] = "Sign";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["SimplePin"] = "SimplePin";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["Sledge"] = "Sledge";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["Snowflake"] = "Snowflake";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["SortAsc"] = "SortAsc";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["SortDesc"] = "SortDesc";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["Speaking"] = "Speaking";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["Star"] = "Star";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["Table"] = "Table";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["Tap"] = "Tap";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["Tent"] = "Tent";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["Tipi"] = "Tipi";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["ToiletPaper"] = "ToiletPaper";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["TranslationActive"] = "TranslationActive";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["TranslationInactive"] = "TranslationInactive";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["Umbrella"] = "Umbrella";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["Upload"] = "Upload";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["User"] = "User";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["UserAdd"] = "UserAdd";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["Fingers"] = "Fingers";
    /** @deprecated Please strongly prefer new design system with new icons */
    IconVariant["PaymentCard"] = "PaymentCard";
    IconVariant["AppleLogo"] = "AppleLogo";
    IconVariant["Account"] = "Account";
    IconVariant["Activities"] = "Activities";
    IconVariant["ActivitiesWinter"] = "ActivitiesWinter";
    IconVariant["Amenities"] = "Amenities";
    IconVariant["Arrow_M_Left"] = "Arrow_M_Left";
    IconVariant["Arrow_M_Right"] = "Arrow_M_Right";
    IconVariant["Arrow_S_down"] = "Arrow_S_down";
    IconVariant["Arrow_S_up"] = "Arrow_S_up";
    IconVariant["Arrow_S_right"] = "Arrow_S_right";
    IconVariant["Arrow_S_left"] = "Arrow_S_left";
    IconVariant["Avatar"] = "Avatar";
    IconVariant["Bill"] = "Bill";
    IconVariant["Cabin"] = "Cabin";
    IconVariant["Calendar_NEW"] = "Calendar_NEW";
    IconVariant["Camp_to_yourself"] = "Camp_to_yourself";
    IconVariant["Caravan_NEW"] = "Caravan_NEW";
    IconVariant["Check_Bold"] = "Check_Bold";
    IconVariant["Check_Light"] = "Check_Light";
    IconVariant["CheckIn"] = "CheckIn";
    IconVariant["CheckOut"] = "CheckOut";
    IconVariant["Cheese"] = "Cheese";
    IconVariant["Child_friendly"] = "Child_friendly";
    IconVariant["Counter"] = "Counter";
    IconVariant["Credit"] = "Credit";
    IconVariant["CurrentPosition"] = "CurrentPosition";
    IconVariant["Delete"] = "Delete";
    IconVariant["Directbooking_Filled"] = "Directbooking_Filled";
    IconVariant["Directbooking_outline"] = "Directbooking_outline";
    IconVariant["Directbooking"] = "Directbooking";
    IconVariant["Directions"] = "Directions";
    IconVariant["DiscountCode"] = "DiscountCode";
    IconVariant["Dog_NEW"] = "Dog_NEW";
    IconVariant["Dots"] = "Dots";
    IconVariant["Download_NEW"] = "Download_NEW";
    IconVariant["Edit"] = "Edit";
    IconVariant["Extras"] = "Extras";
    IconVariant["Electricity_NEW"] = "Electricity_NEW";
    IconVariant["Error_Careful"] = "Error_Careful";
    IconVariant["Warning_Orange"] = "Warning_Orange";
    IconVariant["FAQ"] = "FAQ";
    IconVariant["Farmshop"] = "Farmshop";
    IconVariant["Filter"] = "Filter";
    IconVariant["Fireplace"] = "Fireplace";
    IconVariant["Flex"] = "Flex";
    IconVariant["France"] = "France";
    IconVariant["Food"] = "Food";
    IconVariant["GiftCard_NEW"] = "GiftCard_NEW";
    IconVariant["HamburgerMenu"] = "HamburgerMenu";
    IconVariant["HeartFavorites_Filled"] = "HeartFavorites_Filled";
    IconVariant["HeartFavorites_Outline"] = "HeartFavorites_Outline";
    IconVariant["Host"] = "Host";
    IconVariant["Image"] = "Image";
    IconVariant["Info"] = "Info";
    IconVariant["Languages"] = "Languages";
    IconVariant["Lenght"] = "Lenght";
    IconVariant["List_NEW"] = "List_NEW";
    IconVariant["Listing"] = "Listing";
    IconVariant["LocationPin"] = "LocationPin";
    IconVariant["Login_NEW"] = "Login_NEW";
    IconVariant["Logout_NEW"] = "Logout_NEW";
    IconVariant["Message_Send"] = "Message_Send";
    IconVariant["Message1"] = "Message1";
    IconVariant["Message"] = "Message";
    IconVariant["Motorhome_NEW"] = "Motorhome_NEW";
    IconVariant["MotorhomePlus_NEW"] = "MotorhomePlus_NEW";
    IconVariant["Nights"] = "Nights";
    IconVariant["Nomady_Icon"] = "Nomady_Icon";
    IconVariant["NotificationBell"] = "NotificationBell";
    IconVariant["Open_external"] = "Open_external";
    IconVariant["Parking_NEW"] = "Parking_NEW";
    IconVariant["Payment"] = "Payment";
    IconVariant["Payout"] = "Payout";
    IconVariant["Plus_NEW"] = "Plus_NEW";
    IconVariant["PublicTransportation"] = "PublicTransportation";
    IconVariant["Rating_filled"] = "Rating_filled";
    IconVariant["RatingStar"] = "RatingStar";
    IconVariant["RatingStarFilled"] = "RatingStarFilled";
    IconVariant["RegionFlag"] = "RegionFlag";
    IconVariant["Register"] = "Register";
    IconVariant["Roof"] = "Roof";
    IconVariant["Rooftent"] = "Rooftent";
    IconVariant["SafePayment"] = "SafePayment";
    IconVariant["Search"] = "Search";
    IconVariant["Shadow"] = "Shadow";
    IconVariant["ShowerNew"] = "ShowerNew";
    IconVariant["Sleepingbag"] = "Sleepingbag";
    IconVariant["Sort_BottomUp"] = "Sort_BottomUp";
    IconVariant["Sort_Top_down"] = "Sort_Top_down";
    IconVariant["Speak_Languages"] = "Speak_Languages";
    IconVariant["Spots"] = "Spots";
    IconVariant["Superflex"] = "Superflex";
    IconVariant["Surroundings"] = "Surroundings";
    IconVariant["Switch"] = "Switch";
    IconVariant["Tent_NEW"] = "Tent_NEW";
    IconVariant["ThumbUp"] = "ThumbUp";
    IconVariant["Time"] = "Time";
    IconVariant["TipiNew"] = "TipiNew";
    IconVariant["Tophost_Highlight"] = "Tophost_Highlight";
    IconVariant["Tophost"] = "Tophost";
    IconVariant["Translate_Original_1"] = "Translate_Original_1";
    IconVariant["Translate_Original_2"] = "Translate_Original_2";
    IconVariant["Travel"] = "Travel";
    IconVariant["Travel2"] = "Travel2";
    IconVariant["Underground"] = "Underground";
    IconVariant["UndergroundWinter"] = "UndergroundWinter";
    IconVariant["Van_NEW"] = "Van_NEW";
    IconVariant["Verified_filled"] = "Verified_filled";
    IconVariant["View"] = "View";
    IconVariant["Water"] = "Water";
    IconVariant["WC"] = "WC";
    IconVariant["Wifi"] = "Wifi";
    IconVariant["Winter_Snowstar"] = "Winter_Snowstar";
    IconVariant["X_Close"] = "X_Close";
    IconVariant["Void"] = "Void";
    IconVariant["Share"] = "Share";
    IconVariant["Minus_NEW"] = "Minus_NEW";
    IconVariant["NomadyLogoLeafOnly"] = "NomadyLogoLeafOnly";
})(IconVariant || (IconVariant = {}));
export default IconVariant;
