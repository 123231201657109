import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styled from 'styled-components/macro';
import { useIntl } from 'react-intl';
import Text, { TextVariant } from '../../Text/Text';
import { ColorVariant } from '../../../theme';
const PriceSection = styled.div `
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0px 0px 5px;
  gap: 15px;
`;
const PriceLabel = styled.div `
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: flex-end;
`;
const EllipsisText = styled(Text) `
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const Price = styled(Text) `
  font-size: 28px;
  margin-bottom: 3px;
  @media ${props => props.theme.device.tablet} {
    margin-bottom: -3px;
  }
`;
const PriceInformation = ({ price, currency = 'N/A', totalPeople, nightsQuantity, }) => {
    const intl = useIntl();
    return (_jsxs(PriceSection, { children: [_jsx(EllipsisText, Object.assign({ variant: TextVariant.B30 }, { children: intl.formatMessage({ id: 'PriceInformation.perPersonPerNight' }, { totalPeople, nights: nightsQuantity }) })), _jsxs(PriceLabel, { children: [_jsx(Text, Object.assign({ variant: TextVariant.B30, "data-testid": "PriceInformation_currency" }, { children: currency })), _jsx(Price, Object.assign({ variant: TextVariant.H30, colorVariant: ColorVariant.Cave, "data-testid": "PriceInformation_price" }, { children: price }))] })] }));
};
export default PriceInformation;
