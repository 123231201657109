import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { getDeeplLocale } from '@nomady/shared/marketplace-custom-config.ts';
import { propTypes } from '@nomady/shared/utils/types.ts';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { IconReviewUser, Modal } from '../index.ts';
import { ReviewForm } from '../../forms';

import css from './ReviewModal.module.scss';

const ReviewModal = props => {
  const {
    className,
    rootClassName,
    id,
    intl,
    isOpen,
    onCloseModal,
    onSubmitReview,
    revieweeName,
    reviewSent,
    sendReviewInProgress,
    sendReviewError,
    userLocale,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const closeButtonMessage = intl.formatMessage({ id: 'ReviewModal.later' });
  const reviewee = <span className={css.reviewee}>{revieweeName}</span>;

  const userLocaleLabelTranslated =
    userLocale &&
    intl.formatMessage({
      id: `MarketplaceConfig.language.${getDeeplLocale(userLocale)}`,
    });

  return (
    <Modal
      id={id}
      containerClassName={classes}
      contentClassName={css.modalContent}
      isOpen={isOpen}
      onClose={onCloseModal}
      usePortal
      closeButtonMessage={closeButtonMessage}
    >
      <IconReviewUser className={css.modalIcon} />
      <p className={css.modalTitle}>
        <FormattedMessage
          id="ReviewModal.title"
          values={{ revieweeName: reviewee }}
        />
      </p>
      <p className={css.modalMessage}>
        <FormattedMessage id="ReviewModal.description" />{' '}
        <FormattedMessage
          id="ReviewModal.languageReview"
          values={{ userLanguage: userLocaleLabelTranslated }}
        />
      </p>
      <ReviewForm
        onSubmit={onSubmitReview}
        reviewSent={reviewSent}
        sendReviewInProgress={sendReviewInProgress}
        sendReviewError={sendReviewError}
        userLocale={userLocale}
      />
    </Modal>
  );
};

const { bool, string } = PropTypes;

ReviewModal.defaultProps = {
  className: null,
  rootClassName: null,
  reviewSent: false,
  sendReviewInProgress: false,
  sendReviewError: null,
};

ReviewModal.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  reviewSent: bool,
  sendReviewInProgress: bool,
  sendReviewError: propTypes.error,
};

export default injectIntl(ReviewModal);
