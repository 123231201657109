import styled from 'styled-components/macro';
export default styled.span `
  width: 100%;
  text-align: center;

  ${props => props.theme.typeset.H10B}

  color: ${props => props.theme.color.cinnabar};

  @media ${props => props.theme.device.tablet} {
    position: absolute;
    top: 151px;
  }
`;
