import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import EmptyCard from '../EmptyCard/EmptyCard';
import { useIntl } from 'react-intl';
const PlateShopAdvert = () => {
    const intl = useIntl();
    return (_jsx(EmptyCard, { description: intl.formatMessage({
            id: 'Advert.plateShop.title',
        }), buttonLabel: intl.formatMessage({
            id: 'Advert.plateShop.button',
        }), button: {
            externalLink: 'https://static.nomady.camp/cms/240301_Schilder_Angebot_Bilder_komprimiert_9498f932b1.pdf',
            target: '_blank',
        }, plateShoopIcon: true }));
};
export default PlateShopAdvert;
