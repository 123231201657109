/* eslint-disable no-param-reassign */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createSlice } from '@reduxjs/toolkit';
import { loadResults, setSearchQuery, setStoreSearchQueryInSearchParams, } from '../../slices/listingSearchSlice';
const initialState = {
    pagination: { page: 0, perPage: 0, totalPages: 0, totalItems: 0 },
    searchParams: null,
    searchInProgress: false,
    searchListingsError: null,
    currentPageResultIds: [],
    fullSizeMap: false,
};
const resultIds = (data) => data.data.map((l) => l.id);
export const SearchPageSlice = createSlice({
    name: 'app/SearchPage',
    initialState,
    reducers: {
        searchListingsRequest: (state, action) => {
            state.searchParams = action.payload.searchParams;
            state.searchInProgress = true;
            state.searchListingsError = null;
        },
        searchListingsSuccess: (state, action) => {
            state.currentPageResultIds = resultIds(action.payload.data);
            state.pagination = action.payload.data.meta;
            state.searchInProgress = false;
        },
        searchListingsError: (state, action) => {
            state.searchInProgress = false;
            state.searchListingsError = action.payload;
        },
        setActiveListing: (state, action) => {
            state.activeListingId = action.payload;
        },
        resetActiveListing: state => {
            state.activeListingId = undefined;
        },
        setHighlightedListing: (state, action) => {
            state.highlightedListingId = action.payload;
        },
        resetHighlightedListing: state => {
            state.highlightedListingId = undefined;
        },
        setPage: (state, action) => {
            state.pagination = Object.assign(Object.assign({}, state.pagination), { page: action.payload });
        },
        setFullSizeMap: (state, action) => {
            state.fullSizeMap = action.payload;
        },
    },
});
export const { searchListingsRequest, searchListingsSuccess, searchListingsError, setActiveListing, resetActiveListing, setHighlightedListing, resetHighlightedListing, setPage, setFullSizeMap, } = SearchPageSlice.actions;
export const loadInitialState = (searchQuery, page = 1) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    dispatch(setStoreSearchQueryInSearchParams(true));
    dispatch(setSearchQuery(searchQuery));
    dispatch(setPage(page));
    dispatch(loadResults({ pushSearchQueryToDataLayer: false, imagesLimit: 1 }));
});
export default SearchPageSlice.reducer;
