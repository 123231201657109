import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import ModalCanvas from './components/ModalCanvas';
import DraggableContainer from '../DraggableContainer/DraggableContainer';
const KEY_CODE_ESCAPE = 27;
const ModalLayout = ({ id, fullTransparency, title, closeLabel, leftButton, rightButton, children, isOpen, illustration, colorVariant, onClose, }) => {
    const handleBodyKeyUp = (event) => {
        if (event.keyCode === KEY_CODE_ESCAPE && isOpen) {
            onClose(event);
        }
    };
    useEffect(() => {
        document.body.addEventListener('keyup', event => handleBodyKeyUp(event));
        return () => {
            document.body.removeEventListener('keyup', event => handleBodyKeyUp(event));
        };
    }, [isOpen]);
    return (_jsx(DraggableContainer, Object.assign({ isOpen: isOpen, onClose: onClose, closeLabel: closeLabel, fullTransparency: fullTransparency }, { children: _jsx(ModalCanvas, Object.assign({ colorVariant: colorVariant, title: title, illustration: illustration, leftButton: leftButton, rightButton: rightButton }, { children: children })) })));
};
export default ModalLayout;
