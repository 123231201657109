import config from '../config';
import { storableError } from '../util/errors';
import * as log from '../util/log';
// ================ Action types ================ //
const STRIPE_ACCOUNT_CREATE_REQUEST = 'app/stripe/STRIPE_ACCOUNT_CREATE_REQUEST';
const STRIPE_ACCOUNT_CREATE_SUCCESS = 'app/stripe/STRIPE_ACCOUNT_CREATE_SUCCESS';
const STRIPE_ACCOUNT_CREATE_ERROR = 'app/stripe/STRIPE_ACCOUNT_CREATE_ERROR';
const STRIPE_ACCOUNT_UPDATE_REQUEST = 'app/stripe/STRIPE_ACCOUNT_UPDATE_REQUEST';
const STRIPE_ACCOUNT_UPDATE_SUCCESS = 'app/stripe/STRIPE_ACCOUNT_UPDATE_SUCCESS';
const STRIPE_ACCOUNT_UPDATE_ERROR = 'app/stripe/STRIPE_ACCOUNT_UPDATE_ERROR';
const STRIPE_ACCOUNT_FETCH_REQUEST = 'app/stripe/STRIPE_ACCOUNT_FETCH_REQUEST';
const STRIPE_ACCOUNT_FETCH_SUCCESS = 'app/stripe/STRIPE_ACCOUNT_FETCH_SUCCESS';
const STRIPE_ACCOUNT_FETCH_ERROR = 'app/stripe/STRIPE_ACCOUNT_FETCH_ERROR';
const STRIPE_ACCOUNT_CLEAR_ERROR = 'app/stripe/STRIPE_ACCOUNT_CLEAR_ERROR';
const GET_ACCOUNT_LINK_REQUEST = 'app/stripeConnectAccount/GET_ACCOUNT_LINK_REQUEST';
const GET_ACCOUNT_LINK_SUCCESS = 'app/stripeConnectAccount/GET_ACCOUNT_LINK_SUCCESS';
const GET_ACCOUNT_LINK_ERROR = 'app/stripeConnectAccount/GET_ACCOUNT_LINK_ERROR';
const SAVE_PAYOUT_DETAILS_REQUEST = 'app/stripeConnectAcount/SAVE_PAYOUT_DETAILS_REQUEST';
const SAVE_PAYOUT_DETAILS_SUCCESS = 'app/stripeConnectAcount/SAVE_PAYOUT_DETAILS_SUCCESS';
const SAVE_PAYOUT_DETAILS_ERROR = 'app/stripeConnectAcount/SAVE_PAYOUT_DETAILS_ERROR';
const CLEAR_CURRENT_STRIPE_CONNECT_ACCOUNT = 'CLEAR_CURRENT_STRIPE_CONNECT_ACCOUNT';
const initialState = {
    createStripeAccountInProgress: false,
    createStripeAccountError: null,
    updateStripeAccountInProgress: false,
    updateStripeAccountError: null,
    fetchStripeAccountInProgress: false,
    fetchStripeAccountError: null,
    getAccountLinkInProgress: false,
    getAccountLinkError: null,
    stripeAccount: null,
    stripeAccountFetched: false,
    payoutDetailsSaveInProgress: false,
    payoutDetailsSaved: false,
};
const StripeAccountReducer = (state = initialState, action) => {
    switch (action.type) {
        case STRIPE_ACCOUNT_CREATE_REQUEST:
            return Object.assign(Object.assign({}, state), { createStripeAccountError: null, createStripeAccountInProgress: true });
        case STRIPE_ACCOUNT_CREATE_SUCCESS:
            return Object.assign(Object.assign({}, state), { createStripeAccountInProgress: false, stripeAccount: action.payload, stripeAccountFetched: true });
        case STRIPE_ACCOUNT_CREATE_ERROR:
            console.error(action.payload);
            return Object.assign(Object.assign({}, state), { createStripeAccountError: action.payload, createStripeAccountInProgress: false });
        case STRIPE_ACCOUNT_UPDATE_REQUEST:
            return Object.assign(Object.assign({}, state), { updateStripeAccountError: null, updateStripeAccountInProgress: true });
        case STRIPE_ACCOUNT_UPDATE_SUCCESS:
            return Object.assign(Object.assign({}, state), { updateStripeAccountInProgress: false, stripeAccount: action.payload, stripeAccountFetched: true });
        case STRIPE_ACCOUNT_UPDATE_ERROR:
            console.error(action.payload);
            return Object.assign(Object.assign({}, state), { updateStripeAccountError: action.payload, createStripeAccountInProgress: false });
        case STRIPE_ACCOUNT_FETCH_REQUEST:
            return Object.assign(Object.assign({}, state), { fetchStripeAccountError: null, fetchStripeAccountInProgress: true });
        case STRIPE_ACCOUNT_FETCH_SUCCESS:
            return Object.assign(Object.assign({}, state), { fetchStripeAccountInProgress: false, stripeAccount: action.payload, stripeAccountFetched: true });
        case STRIPE_ACCOUNT_FETCH_ERROR:
            console.error(action.payload);
            return Object.assign(Object.assign({}, state), { fetchStripeAccountError: action.payload, fetchStripeAccountInProgress: false });
        case STRIPE_ACCOUNT_CLEAR_ERROR:
            return Object.assign({}, initialState);
        case GET_ACCOUNT_LINK_REQUEST:
            return Object.assign(Object.assign({}, state), { getAccountLinkError: null, getAccountLinkInProgress: true });
        case GET_ACCOUNT_LINK_ERROR:
            console.error(action.payload);
            return Object.assign(Object.assign({}, state), { getAccountLinkInProgress: false, getAccountLinkError: action.payload });
        case GET_ACCOUNT_LINK_SUCCESS:
            return Object.assign(Object.assign({}, state), { getAccountLinkInProgress: false });
        case SAVE_PAYOUT_DETAILS_REQUEST:
            return Object.assign(Object.assign({}, state), { payoutDetailsSaveInProgress: true });
        case SAVE_PAYOUT_DETAILS_ERROR:
            return Object.assign(Object.assign({}, state), { payoutDetailsSaveInProgress: false });
        case SAVE_PAYOUT_DETAILS_SUCCESS:
            return Object.assign(Object.assign({}, state), { payoutDetailsSaveInProgress: false, payoutDetailsSaved: true });
        case CLEAR_CURRENT_STRIPE_CONNECT_ACCOUNT:
            return initialState;
        default:
            return state;
    }
};
export default StripeAccountReducer;
const stripeAccountCreateRequest = () => ({
    type: STRIPE_ACCOUNT_CREATE_REQUEST,
});
export const stripeAccountCreateSuccess = (stripeAccount) => ({
    type: STRIPE_ACCOUNT_CREATE_SUCCESS,
    payload: stripeAccount,
});
const stripeAccountCreateError = (e) => ({
    type: STRIPE_ACCOUNT_CREATE_ERROR,
    payload: e,
    error: true,
});
const stripeAccountUpdateRequest = () => ({
    type: STRIPE_ACCOUNT_UPDATE_REQUEST,
});
const stripeAccountUpdateSuccess = (stripeAccount) => ({
    type: STRIPE_ACCOUNT_UPDATE_SUCCESS,
    payload: stripeAccount,
});
const stripeAccountUpdateError = (e) => ({
    type: STRIPE_ACCOUNT_UPDATE_ERROR,
    payload: e,
    error: true,
});
const stripeAccountFetchRequest = () => ({
    type: STRIPE_ACCOUNT_FETCH_REQUEST,
});
const stripeAccountFetchSuccess = (stripeAccount) => ({
    type: STRIPE_ACCOUNT_FETCH_SUCCESS,
    payload: stripeAccount,
});
const stripeAccountFetchError = (e) => ({
    type: STRIPE_ACCOUNT_FETCH_ERROR,
    payload: e,
    error: true,
});
export const stripeAccountClearError = () => ({
    type: STRIPE_ACCOUNT_CLEAR_ERROR,
});
const getAccountLinkRequest = () => ({
    type: GET_ACCOUNT_LINK_REQUEST,
});
const getAccountLinkError = (e) => ({
    type: GET_ACCOUNT_LINK_ERROR,
    payload: e,
    error: true,
});
export const savePayoutDetailsRequest = () => ({
    type: SAVE_PAYOUT_DETAILS_REQUEST,
});
export const savePayoutDetailsError = () => ({
    type: SAVE_PAYOUT_DETAILS_ERROR,
});
export const savePayoutDetailsSuccess = () => ({
    type: SAVE_PAYOUT_DETAILS_SUCCESS,
});
export const clearCurrentStripeConnectAccount = () => ({
    type: CLEAR_CURRENT_STRIPE_CONNECT_ACCOUNT,
});
// ================ Thunks ================ //
export const savePayoutDetails = (values, isUpdateCall) => (dispatch) => {
    const upsertThunk = isUpdateCall
        ? updateStripeAccount
        : createStripeAccount;
    dispatch(savePayoutDetailsRequest());
    return dispatch(upsertThunk(values))
        .then(response => {
        dispatch(savePayoutDetailsSuccess());
        return response;
    })
        .catch(() => dispatch(savePayoutDetailsError()));
};
export const createStripeAccount = (params) => (dispatch, getState, sdk) => {
    if (typeof window === 'undefined' || !window.Stripe) {
        throw new Error('Stripe must be loaded for submitting PayoutPreferences');
    }
    const stripe = window.Stripe(config.stripe.publishableKey);
    const { country, accountType, bankAccountToken, businessProfileMCC, businessProfileURL, } = params;
    // Capabilities are a collection of settings that can be requested for each provider.
    // What Capabilities are required determines what information Stripe requires to be
    // collected from the providers.
    // You can read more from here: https://stripe.com/docs/connect/capabilities-overview
    // In Flex both 'card_payments' and 'transfers' are required.
    const requestedCapabilities = ['card_payments', 'transfers'];
    const accountInfo = {
        business_type: accountType,
        tos_shown_and_accepted: true,
    };
    dispatch(stripeAccountCreateRequest());
    return stripe
        .createToken('account', accountInfo)
        .then(response => {
        var _a;
        const accountToken = (_a = response.token) === null || _a === void 0 ? void 0 : _a.id;
        return sdk.stripeAccount.create({
            country,
            accountToken,
            bankAccountToken,
            requestedCapabilities,
            businessProfileMCC,
            businessProfileURL,
        }, { expand: true });
    })
        .then(response => {
        const stripeAccount = response.data.data;
        dispatch(stripeAccountCreateSuccess(stripeAccount));
        return stripeAccount;
    })
        .catch(err => {
        const e = storableError(err);
        dispatch(stripeAccountCreateError(e));
        const stripeMessage = e.apiErrors && e.apiErrors.length > 0 && e.apiErrors[0].meta
            ? e.apiErrors[0].meta.stripeMessage
            : null;
        log.error(err, 'create-stripe-account-failed', { stripeMessage });
        throw e;
    });
};
// This function is used for updating the bank account token
// but could be expanded to other information as well.
//
// If the Stripe account has been created with account token,
// you need to use account token also to update the account.
// By default the account token will not be used.
// See API reference for more information:
// https://www.sharetribe.com/api-reference/?javascript#update-stripe-account
export const updateStripeAccount = (params) => (dispatch, getState, sdk) => {
    const { bankAccountToken } = params;
    dispatch(stripeAccountUpdateRequest());
    return sdk.stripeAccount.update({
        bankAccountToken,
        requestedCapabilities: ['card_payments', 'transfers'],
    }, { expand: true })
        .then(response => {
        const stripeAccount = response.data.data;
        dispatch(stripeAccountUpdateSuccess(stripeAccount));
        return stripeAccount;
    })
        .catch(err => {
        const e = storableError(err);
        dispatch(stripeAccountUpdateError(e));
        const stripeMessage = e.apiErrors && e.apiErrors.length > 0 && e.apiErrors[0].meta
            ? e.apiErrors[0].meta.stripeMessage
            : null;
        log.error(err, 'update-stripe-account-failed', { stripeMessage });
        throw e;
    });
};
export const fetchStripeAccount = () => (dispatch, getState, sdk) => {
    dispatch(stripeAccountFetchRequest());
    return sdk.stripeAccount
        .fetch()
        .then(response => {
        const stripeAccount = response.data.data;
        dispatch(stripeAccountFetchSuccess(stripeAccount));
        return stripeAccount;
    })
        .catch(err => {
        const e = storableError(err);
        dispatch(stripeAccountFetchError(e));
        const stripeMessage = e.apiErrors && e.apiErrors.length > 0 && e.apiErrors[0].meta
            ? e.apiErrors[0].meta.stripeMessage
            : null;
        log.error(err, 'fetch-stripe-account-failed', { stripeMessage });
        throw e;
    });
};
export const getStripeConnectAccountLink = (params) => (dispatch, getState, sdk) => {
    const { failureURL, successURL, type } = params;
    dispatch(getAccountLinkRequest());
    return sdk.stripeAccountLinks
        .create({
        failureURL,
        successURL,
        type,
        collect: 'currently_due',
    })
        .then(response => {
        // Return the account link
        return response.data.data.attributes.url;
    })
        .catch(err => {
        const e = storableError(err);
        dispatch(getAccountLinkError(e));
        const stripeMessage = e.apiErrors && e.apiErrors.length > 0 && e.apiErrors[0].meta
            ? e.apiErrors[0].meta.stripeMessage
            : null;
        log.error(err, 'get-stripe-account-link-failed', {
            stripeMessage,
        });
        throw e;
    });
};
export const stripeConnectAccountLoading = (state) => state.stripeConnectAccount.fetchStripeAccountInProgress;
export const stripeConnectAccountSelector = (state) => state.stripeConnectAccount.stripeAccount;
export const payoutDetailsSaveInProgressSelector = (state) => state.stripeConnectAccount.payoutDetailsSaveInProgress;
