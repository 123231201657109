import { types as sdkTypes } from '@nomady/shared/utils/sdkLoader';
import AustriaImage from './assets/countries/austria.webp';
import FranceImage from './assets/countries/france.webp';
import ItalyImage from './assets/countries/italy.webp';
import GermanyImage from './assets/countries/germany.webp';
import SwitzerlandImage from './assets/countries/switzerland.webp';
const { LatLng, LatLngBounds } = sdkTypes;
export const defaultLocation = {
    name: 'MarketplaceConfig.DefaultLocations.europe',
    bounds: new LatLngBounds(new LatLng(51.349583, 11.26064), new LatLng(43.328575, 6.929273)),
};
// An array of locations to show in the LocationAutocompleteInput when
// the input is in focus but the user hasn't typed in any search yet.
//
// Each item in the array should be an object with a unique `id` (String) and a
// `predictionPlace` (util.types.place) properties.
const defaultLocations = [
    {
        name: 'MarketplaceConfig.DefaultLocations.switzerland',
        bounds: new LatLngBounds(new LatLng(48.12454038, 10.717010799), new LatLng(45.21170811, 5.70175201)),
        image: SwitzerlandImage,
    },
    {
        name: 'MarketplaceConfig.DefaultLocations.italy',
        bounds: new LatLngBounds(new LatLng(46.08149599, 17.41293514), new LatLng(36.43086964, 7.74771297)),
        image: ItalyImage,
    },
    {
        name: 'MarketplaceConfig.DefaultLocations.france',
        bounds: new LatLngBounds(new LatLng(47.84053034, 6.94576581), new LatLng(45.63487096, 4.52946027)),
        image: FranceImage,
    },
    {
        name: 'MarketplaceConfig.DefaultLocations.germany',
        bounds: new LatLngBounds(new LatLng(48.73726303, 9.20974602), new LatLng(47.66455488, 8.00159325)),
        image: GermanyImage,
    },
    {
        name: 'MarketplaceConfig.DefaultLocations.austria',
        bounds: new LatLngBounds(new LatLng(48.7265449, 11.7900154), new LatLng(46.55816823, 9.37370986)),
        image: AustriaImage,
    },
];
export default defaultLocations;
