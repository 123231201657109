import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components/macro';
import { isBrowser } from '@nomady/shared/utils/env';
import BackDrop from '../BackDrop/BackDrop';
import Text from '../Text/Text';
const KEY_CODE_ESCAPE = 27;
const DisplayWrapper = styled.div `
  display: grid;

  @media ${props => props.theme.device.tablet} {
    display: ${props => (props.hideOnDesktop ? 'none' : 'grid')};
  }
`;
const Wrapper = styled.div `
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  min-height: 40px;

  background: ${props => props.theme.color.snow};
  border-radius: 20px 20px 0px 0px;

  ${props => props.theme.priority.always}

  display: grid;
  grid-template-columns: 100vw;
  grid-template-rows: [topEnd] min-content [contentStart] 1fr [contentEnd] min-content [bottomEnd];

  max-height: calc(100vh - 20px);

  @media ${props => props.theme.device.tablet} {
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 180px);

    grid-template-columns: initial;
    grid-template-rows: initial;
  }
`;
const Content = styled.div `
  flex-grow: 1;
  grid-column: 1;
  grid-row-start: contentStart;
  grid-row-end: contentEnd;

  padding: 15px;
  overflow-y: auto;
  max-width: 1580px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;

  @media ${props => props.theme.device.tablet} {
    flex-direction: row;
    margin-top: 50px;
    margin-bottom: 200px;
  }
`;
export const BottomSheetTitle = styled(Text) `
  text-align: center;
  margin-bottom: 20px;

  @media ${props => props.theme.device.tablet} {
    text-align: left;
  }
`;
export const BottomSheetLeft = styled.div `
  display: none;

  @media ${props => props.theme.device.tablet} {
    display: flex;
    flex-grow: 1;
    justify-content: center;
  }
`;
export const BottomSheetRight = styled.div `
  @media ${props => props.theme.device.tablet} {
    flex-grow: 1;
    flex-direction: column;
  }
`;
const BottomSheet = ({ open, children, onClose, hideOnDesktop, }) => {
    const [isOpen, setIsOpen] = useState(open);
    const [portalRoot, setPortalRoot] = useState();
    useEffect(() => setIsOpen(open), [open]);
    useEffect(() => {
        if (!isBrowser()) {
            return undefined;
        }
        setPortalRoot(document.getElementById('portal-root'));
        const handleEscape = (event) => {
            if (event.keyCode === KEY_CODE_ESCAPE && isOpen) {
                onClose(event);
            }
        };
        document.body.addEventListener('keyup', event => handleEscape(event));
        return () => {
            document.body.removeEventListener('keyup', event => handleEscape(event));
        };
    }, [isOpen, onClose]);
    const content = (_jsxs(DisplayWrapper, Object.assign({ hideOnDesktop: hideOnDesktop }, { children: [_jsx(Wrapper, { children: _jsx(Content, { children: children }) }), _jsx(BackDrop, { coversTopBar: true, onClick: onClose })] })));
    if (isOpen && portalRoot) {
        return createPortal(content, portalRoot);
    }
    return null;
};
export default BottomSheet;
