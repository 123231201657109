import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { MARKETPLACE_CURRENCIES, } from '@nomady/shared/config';
import FilterGroup from '../../FilterGroup/FilterGroup';
import { IconVariant } from '../../Icon/Icon';
import PriceSliderInput from '../../PriceSliderInput/PriceSliderInput';
const MAX_PRICE = 350;
const MIN_PRICE = 0;
const PriceFilterGroup = ({ currentFeaturesSearchQuery, intl, onSetFeatures, }) => {
    var _a, _b;
    return (_jsx(FilterGroup, Object.assign({ iconVariant: IconVariant.Coins, label: intl.formatMessage({
            id: 'SearchBarSheet.Features.Price.title',
        }) }, { children: _jsx(PriceSliderInput, { min: MIN_PRICE, max: MAX_PRICE, step: 1, values: ((_a = currentFeaturesSearchQuery.price) === null || _a === void 0 ? void 0 : _a.price) || [MIN_PRICE, MAX_PRICE], defaultMin: MIN_PRICE, defaultMax: MAX_PRICE, onChange: (value) => {
                var _a;
                const updatedValue = value && value[0] === MIN_PRICE && value[1] === MAX_PRICE
                    ? undefined
                    : value;
                onSetFeatures({
                    price: {
                        price: updatedValue,
                        currency: (_a = currentFeaturesSearchQuery.price) === null || _a === void 0 ? void 0 : _a.currency,
                    },
                });
            }, currencies: MARKETPLACE_CURRENCIES, currency: (_b = currentFeaturesSearchQuery.price) === null || _b === void 0 ? void 0 : _b.currency, onChangeCurrency: (value) => {
                var _a;
                return onSetFeatures({
                    price: {
                        currency: value,
                        price: (_a = currentFeaturesSearchQuery.price) === null || _a === void 0 ? void 0 : _a.price,
                    },
                });
            } }) })));
};
export default PriceFilterGroup;
