import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import styled, { css } from 'styled-components/macro';
import Benefit from '../Benefit/Benefit';
const Wrapper = styled.section `
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  @media ${props => props.theme.device.tabletL} {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
`;
const BenefitWrapper = styled.div `
  width: 100%;

  ${props => props.isLast
    ? css `
          margin-bottom: initial;
        `
    : css `
          margin-bottom: 8px;
        `}
`;
const Benefits = ({ benefits }) => (_jsx(Wrapper, { children: benefits === null || benefits === void 0 ? void 0 : benefits.map((benefit, index) => {
        return (_jsx(BenefitWrapper, Object.assign({ isLast: benefits.length === index + 1 }, { children: _jsx(Benefit, { icon: benefit.icon, text: benefit.text, isFirst: index === 0 }) }), benefit.id));
    }) }));
export default Benefits;
