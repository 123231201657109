import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import styled from 'styled-components/macro';
import { ModalIllustrationVariant } from '../utils/interfaces';
import { ReactComponent as BrokenArrowIllustration } from '../illustrations/broken-arrow.svg';
import { ReactComponent as ContractIllustration } from '../illustrations/contract.svg';
import ConversationIllustrationSrc from '../illustrations/conversation.webp';
const Illustration = styled.div `
  background-color: none;
  ${props => props.variant === ModalIllustrationVariant.BrokenArrow &&
    `
      background-color: ${props.theme.color.amber};
    `};

  ${props => props.variant === ModalIllustrationVariant.Contract &&
    `
    background-color: ${props.theme.color.horizon};
  `}

  height: 100px;
  border-radius: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 10px;

  svg {
    max-height: 100%;
    width: auto;
  }

  @media ${props => props.theme.device.tablet} {
    height: 150px;
  }
`;
const ConversationIllustration = styled.img `
  height: 100%;
  width: auto;
`;
const Content = styled.div `
  overflow-y: auto;
  max-height: 70vh;
  flex-grow: 1;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  box-sizing: border-box;
  width: 100vw;
  overflow-wrap: break-word;
  @media ${props => props.theme.device.tablet} {
    height: inherit;
    max-height: inherit;
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
  }
`;
const ModalContent = ({ illustration, children }) => {
    return (_jsx(_Fragment, { children: _jsxs(Content, Object.assign({ onClick: (event) => event.stopPropagation() }, { children: [illustration && (_jsxs(Illustration, Object.assign({ variant: illustration }, { children: [illustration === ModalIllustrationVariant.BrokenArrow && (_jsx(BrokenArrowIllustration, {})), illustration === ModalIllustrationVariant.Contract && (_jsx(Illustration, Object.assign({ variant: illustration }, { children: _jsx(ContractIllustration, {}) }))), illustration === ModalIllustrationVariant.Conversation && (_jsx(ConversationIllustration, { src: ConversationIllustrationSrc, alt: 'Conversation illustration image' }))] }))), children] })) }));
};
export default ModalContent;
