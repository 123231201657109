import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import styled from 'styled-components/macro';
import { injectIntl } from 'react-intl';
import { IconVariant } from '../Icon/Icon';
import Avatar, { SizeVariant } from '../Avatar/Avatar';
import Text, { TextVariant } from '../Text/Text';
import MenuItem, { MenuItemVariant } from '../MenuItem/MenuItem';
import LocaleSwitch from '../LocaleSwitch/LocaleSwitch';
import { APPLICATION_VERSION } from '@nomady/shared/application-version';
import { ColorVariant } from '../../theme';
const Wrapper = styled.div `
  background: ${props => props.theme.color.pearl};
  width: 100vw;
  min-height: 100%;

  @media ${props => props.theme.device.tablet} {
    width: 320px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    min-height: initial;
  }
`;
const AvatarWrapper = styled.div `
  display: flex;
  align-items: center;
  padding: 15px;
`;
const UserDisplayName = styled(Text) `
  margin-left: 20px;
`;
const MenuWrapper = styled.ul `
  padding: 15px;
  list-style: none;
  margin: 0;
`;
const HorizontalRule = styled.hr `
  border-top: 0;
  border-bottom: 1px solid ${props => props.theme.color.fog};
  margin: 0;
`;
const VersionWrapper = styled.div `
  padding-right: 8px;
  padding-bottom: 4px;
  display: flex;
  justify-content: end;
`;
const MainNavigation = ({ primaryMenuItems, secondaryMenuItems, tertiaryMenuItems, userInitials, userAvatarSource, userDisplayName, selectedLocale, id, intl, logout, }) => (_jsxs(Wrapper, Object.assign({ id: id }, { children: [userInitials && (_jsxs(_Fragment, { children: [_jsxs(AvatarWrapper, { children: [_jsx(Avatar, { sizeVariant: SizeVariant.S, initials: userInitials, source: userAvatarSource }), _jsx(UserDisplayName, Object.assign({ variant: TextVariant.H6 }, { children: userDisplayName }))] }), _jsx(HorizontalRule, {})] })), _jsxs(MenuWrapper, { children: [userInitials && (_jsx(Text, Object.assign({ variant: TextVariant.H10B }, { children: intl.formatMessage({ id: 'TopBar.dashboard' }) }))), primaryMenuItems.map((menuItem, index) => menuItem.to && (_jsx(MenuItem, { to: menuItem.to, icon: menuItem.icon, label: intl.formatMessage({ id: menuItem.label }), hasNotifications: menuItem.hasNotifications, showActive: true }, `primary-menu-${index}`)))] }), _jsx(HorizontalRule, {}), _jsx(MenuWrapper, { children: secondaryMenuItems &&
                secondaryMenuItems.map((menuItem, index) => menuItem.to && (_jsx(MenuItem, { to: menuItem.to, icon: menuItem.icon, label: intl.formatMessage({ id: menuItem.label }), hasNotifications: menuItem.hasNotifications, menuItemVariant: MenuItemVariant.Primary, showActive: true }, `secondary-menu-${index}`))) }), _jsx(HorizontalRule, {}), _jsx(MenuWrapper, { children: tertiaryMenuItems &&
                tertiaryMenuItems.map((menuItem, index) => menuItem.to && (_jsx(MenuItem, { to: menuItem.to, icon: menuItem.icon, label: intl.formatMessage({ id: menuItem.label }), hasNotifications: menuItem.hasNotifications, menuItemVariant: MenuItemVariant.Secondary, showActive: true }, `tertiary-menu-${index}`))) }), userInitials && (_jsxs(_Fragment, { children: [_jsx(HorizontalRule, {}), _jsx(MenuWrapper, { children: _jsx(MenuItem, { icon: IconVariant.Logout, label: intl.formatMessage({ id: 'TopBar.signOut' }), menuItemVariant: MenuItemVariant.Secondary, onClick: () => logout && logout() }) })] })), _jsx(HorizontalRule, {}), _jsx(MenuWrapper, { children: _jsx(LocaleSwitch, { selectedLocale: selectedLocale, isUserLoggedIn: !!userInitials }) }), _jsx(VersionWrapper, { children: _jsx(Text, Object.assign({ variant: TextVariant.L4R, colorVariant: ColorVariant.Cave }, { children: intl.formatMessage({ id: 'TopBar.version' }, { version: APPLICATION_VERSION }) })) })] })));
export default injectIntl(MainNavigation);
