import { Environment } from '@nomady/shared/types';
const getEnvironmentSearchParam = (ambianceSearchQuery) => {
    const values = [];
    if (ambianceSearchQuery === null || ambianceSearchQuery === void 0 ? void 0 : ambianceSearchQuery.shadowy) {
        values.push(Environment.shadowy);
    }
    if (ambianceSearchQuery === null || ambianceSearchQuery === void 0 ? void 0 : ambianceSearchQuery.forest) {
        values.push(Environment.forest);
    }
    if (ambianceSearchQuery === null || ambianceSearchQuery === void 0 ? void 0 : ambianceSearchQuery.lawn) {
        values.push(Environment.lawn);
    }
    if (ambianceSearchQuery === null || ambianceSearchQuery === void 0 ? void 0 : ambianceSearchQuery.riverOrCreek) {
        values.push(Environment.river);
    }
    if (ambianceSearchQuery === null || ambianceSearchQuery === void 0 ? void 0 : ambianceSearchQuery.lake) {
        values.push(Environment.lake);
    }
    if (ambianceSearchQuery === null || ambianceSearchQuery === void 0 ? void 0 : ambianceSearchQuery.mountains) {
        values.push(Environment.mountains);
    }
    if (ambianceSearchQuery === null || ambianceSearchQuery === void 0 ? void 0 : ambianceSearchQuery.farm) {
        values.push(Environment.farm);
    }
    if (ambianceSearchQuery === null || ambianceSearchQuery === void 0 ? void 0 : ambianceSearchQuery.road) {
        values.push(Environment.street);
    }
    if (values.length === 0) {
        return undefined;
    }
    return values.join(',');
};
export default getEnvironmentSearchParam;
