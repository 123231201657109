var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Controller } from 'react-hook-form';
import styled from 'styled-components/macro';
import { ColorVariant } from '../../theme';
import Icon, { IconVariant } from '../Icon/Icon';
import InputErrorMessage from '../InputErrorMessage/InputErrorMessage';
import Spinner from '../Spinner/Spinner';
import { TextVariant } from '../Text/Text';
import { Wrapper, Label, ErrorMessageWrapper, FakeInput, SpinnerWrapper, } from '../Input/Input';
import CheckboxInputSection from '../CheckboxInputSection/CheckboxInputSection';
import InputLanguageSelectFilterGroup from './InputLanguageSelectFilterGroup';
const FilterGroupWrapper = styled.div `
  width: 100%;
`;
const Input = (_a) => {
    var { id, label, valueSelectedIndicatorLabel, noValueSelectedIndicatorLabel, variant, error, icon, loading, selectableValues, control, rules, name } = _a, props = __rest(_a, ["id", "label", "valueSelectedIndicatorLabel", "noValueSelectedIndicatorLabel", "variant", "error", "icon", "loading", "selectableValues", "control", "rules", "name"]);
    const hasError = !!error;
    const errorMessageId = hasError ? `${id}-errormessage` : '';
    const errorMessageAriaInputProps = hasError
        ? { 'aria-invalid': true, 'aria-errormessage': errorMessageId }
        : {};
    const getFilterGroupLabel = (value) => {
        const label = 0 < value.length
            ? valueSelectedIndicatorLabel
            : noValueSelectedIndicatorLabel;
        return label || '';
    };
    const defaultTransformActivatedFilters = (item) => item;
    const transformActivatedFilters = props.transformActivatedValues || defaultTransformActivatedFilters;
    return (_jsx(Controller, { render: ({ field }) => {
            const selectedValues = field.value || [];
            return (_jsxs(Wrapper, { children: [label && (_jsx(Label, Object.assign({ inputVariant: variant, variant: TextVariant.H13, as: "label", htmlFor: id, hasError: hasError }, { children: label }))), _jsxs(FakeInput, Object.assign({ variant: variant, hasError: hasError, hasIcon: !!icon }, errorMessageAriaInputProps, { children: [icon && (_jsxs("label", Object.assign({ htmlFor: id, "aria-hidden": true }, { children: [!loading && _jsx(Icon, { variant: icon }), loading && (_jsx(SpinnerWrapper, { children: _jsx(Spinner, { colorVariant: ColorVariant.Cave }) }))] }))), _jsx(FilterGroupWrapper, { children: _jsx(InputLanguageSelectFilterGroup, Object.assign({ iconVariant: IconVariant.Globe, label: getFilterGroupLabel(selectedValues), activatedFilters: selectedValues
                                        .map(transformActivatedFilters)
                                        .join(', '), onReset: () => {
                                        field.onChange([]);
                                    }, forcePearlBackground: true }, { children: _jsx(CheckboxInputSection, { checkboxes: selectableValues.map(value => (Object.assign(Object.assign({}, value), { checked: selectedValues.includes(value.id), onChange: (value, id) => {
                                                if (value) {
                                                    return field.onChange([...selectedValues, id]);
                                                }
                                                const index = selectedValues.indexOf(id);
                                                if (index > -1) {
                                                    const editedArray = selectedValues;
                                                    editedArray.splice(index, 1);
                                                    return field.onChange([...editedArray]);
                                                }
                                                return undefined;
                                            } }))) }) })) })] })), hasError && (_jsx(ErrorMessageWrapper, Object.assign({ role: "alert" }, { children: _jsx(InputErrorMessage, { id: errorMessageId, error: error }) })))] }));
        }, name: name, control: control, rules: rules }));
};
export default Input;
