import { combineReducers } from 'redux';
import { USER_LOGOUT } from './ducks/Auth.duck';
import { GlobalReducers } from './ducks/index';
import { ContainerReducers } from './containers/reducers';
import { SliceReducers } from './slices';
/**
 * Function _createReducer_ combines global reducers (reducers that are used in
 * multiple pages) and reducers that are handling actions happening inside one page container.
 * Since we combineReducers, pageReducers will get page specific key (e.g. SearchPage)
 * which is page specific.
 * Future: this structure could take in asyncReducers, which are changed when you navigate pages.
 */
const appReducer = combineReducers(Object.assign(Object.assign(Object.assign({}, GlobalReducers), ContainerReducers), SliceReducers));
const createReducer = () => {
    return (state, action) => {
        // Clear sessionStorage when logging out.
        if (action.type === USER_LOGOUT &&
            typeof window !== 'undefined' &&
            !!window.sessionStorage) {
            window.sessionStorage.clear();
        }
        return appReducer(state, action);
    };
};
export default createReducer;
