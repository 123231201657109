import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styled from 'styled-components/macro';
import Text from '../Text/Text';
const Wrapper = styled.section `
  width: 270px;
  display: flex;
  flex-direction: column;
  margin-right: 30px;

  @media ${props => props.theme.device.tablet} {
    width: 310px;
  }
`;
const Link = styled.a `
  color: initial;
`;
const Image = styled.img `
  width: 100%;
  height: auto;
`;
const Category = styled.div `
  font-family: Poppins, sans-serif;
  letter-spacing: 0.205em;
  font-size: 11px;
  text-transform: uppercase;
  line-height: 1.3;
  margin-top: 22px;
  margin-bottom: 20px;
  font-weight: 600;
  color: ${props => props.categoryColor || props.theme.color.cave};
`;
const Title = styled(Text) `
  margin-bottom: 8px;
  color: #000;
  font-size: 20px;
  font-weight: 300;
  line-height: 1.3;
  word-break: break-word;
  font-family: Poppins, sans-serif;
  letter-spacing: 0px;
`;
const Excerpt = styled(Text) `
  font-family: 'Noto Serif', serif;
  font-size: 16px;
  margin-bottom: 0;
  font-weight: 300;
  line-height: 1.5;
`;
const PublishingDate = styled(Text) `
  font-family: Poppins, sans-serif;
  letter-spacing: 0.205em;
  font-size: 11px;
  text-transform: uppercase;
  line-height: 1.3;
  margin-top: 20px;
  font-weight: 300;
`;
const BlogArticle = ({ article: { url, image, category, categoryColor, title, excerpt, date }, }) => {
    return (_jsx(Link, Object.assign({ href: url, target: "_blank", rel: "noopener noreferrer" }, { children: _jsxs(Wrapper, { children: [_jsx(Image, { src: image.url, alt: image.title, loading: "lazy" }), _jsx(Category, Object.assign({ categoryColor: categoryColor }, { children: category })), _jsx(Title, { children: title }), _jsx(Excerpt, { children: excerpt }), _jsx(PublishingDate, { children: date })] }) })));
};
export default BlogArticle;
