var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import isEmpty from 'lodash/isEmpty';
import { clearCurrentUser, fetchCurrentUser, userIsVerifiedSelector, } from './user.duck';
import { clearCurrentStripeConnectAccount } from './stripeConnectAccount.duck';
import { storableError } from '../util/errors';
import * as log from '../util/log';
import { createCurrentUser } from '../util/api';
import { localeSelector } from '../slices/UISlice';
import { isApp } from '../util/deviceContext';
const authenticated = (authInfo) => (authInfo === null || authInfo === void 0 ? void 0 : authInfo.isAnonymous) === false;
// ================ Action types ================ //
export const AUTH_INFO_REQUEST = 'app/Auth/AUTH_INFO_REQUEST';
export const AUTH_INFO_SUCCESS = 'app/Auth/AUTH_INFO_SUCCESS';
export const LOGIN_REQUEST = 'app/Auth/LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'app/Auth/LOGIN_SUCCESS';
export const LOGIN_ERROR = 'app/Auth/LOGIN_ERROR';
export const LOGOUT_REQUEST = 'app/Auth/LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'app/Auth/LOGOUT_SUCCESS';
export const LOGOUT_ERROR = 'app/Auth/LOGOUT_ERROR';
export const SIGNUP_REQUEST = 'app/Auth/SIGNUP_REQUEST';
export const SIGNUP_SUCCESS = 'app/Auth/SIGNUP_SUCCESS';
export const SIGNUP_ERROR = 'app/Auth/SIGNUP_ERROR';
// Generic user_logout action that can be handled elsewhere
// E.g. src/reducers.js clears store as a consequence
export const USER_LOGOUT = 'app/USER_LOGOUT';
const initialState = {
    isAuthenticated: false,
    authScopes: [],
    authInfoLoaded: false,
    loginError: null,
    loginInProgress: false,
    logoutError: null,
    logoutInProgress: false,
    signupError: null,
    signupInProgress: false,
};
const AuthReducer = (state = initialState, action) => {
    var _a;
    switch (action.type) {
        case AUTH_INFO_REQUEST:
            return state;
        case AUTH_INFO_SUCCESS:
            return Object.assign(Object.assign({}, state), { authInfoLoaded: true, isAuthenticated: authenticated(action.payload || {}), authScopes: ((_a = action.payload) === null || _a === void 0 ? void 0 : _a.scopes) || [] });
        case LOGIN_REQUEST:
            return Object.assign(Object.assign({}, state), { loginInProgress: true, loginError: null, logoutError: null, signupError: null });
        case LOGIN_SUCCESS:
            return Object.assign(Object.assign({}, state), { loginInProgress: false, isAuthenticated: true });
        case LOGIN_ERROR:
            return Object.assign(Object.assign({}, state), { loginInProgress: false, loginError: action.payload });
        case LOGOUT_REQUEST:
            return Object.assign(Object.assign({}, state), { logoutInProgress: true, loginError: null, logoutError: null });
        case LOGOUT_SUCCESS:
            return Object.assign(Object.assign({}, state), { logoutInProgress: false, isAuthenticated: false, authScopes: [] });
        case LOGOUT_ERROR:
            return Object.assign(Object.assign({}, state), { logoutInProgress: false, logoutError: action.payload });
        case SIGNUP_REQUEST:
            return Object.assign(Object.assign({}, state), { signupInProgress: true, loginError: null, signupError: null });
        case SIGNUP_SUCCESS:
            return Object.assign(Object.assign({}, state), { signupInProgress: false });
        case SIGNUP_ERROR:
            return Object.assign(Object.assign({}, state), { signupInProgress: false, signupError: action.payload });
        default:
            return state;
    }
};
export default AuthReducer;
// ================ Selectors ================ //
export const authenticationInProgress = (state) => {
    const { loginInProgress, logoutInProgress, signupInProgress } = state.Auth;
    return loginInProgress || logoutInProgress || signupInProgress;
};
export const authInfoRequest = () => ({
    type: AUTH_INFO_REQUEST,
});
export const authInfoSuccess = (info) => ({
    type: AUTH_INFO_SUCCESS,
    payload: info,
});
export const loginRequest = () => ({ type: LOGIN_REQUEST });
export const loginSuccess = () => ({ type: LOGIN_SUCCESS });
export const loginError = (error) => ({
    type: LOGIN_ERROR,
    payload: error,
    error: true,
});
export const logoutRequest = () => ({
    type: LOGOUT_REQUEST,
});
export const logoutSuccess = () => ({
    type: LOGOUT_SUCCESS,
});
export const logoutError = (error) => ({
    type: LOGOUT_ERROR,
    payload: error,
    error: true,
});
export const signupRequest = () => ({
    type: SIGNUP_REQUEST,
});
export const signupSuccess = () => ({
    type: SIGNUP_SUCCESS,
});
export const signupError = (error) => ({
    type: SIGNUP_ERROR,
    payload: error,
    error: true,
});
export const userLogout = () => ({ type: USER_LOGOUT });
// ================ Thunks ================ //
export const authInfo = () => (dispatch, getState, sdk) => {
    dispatch(authInfoRequest());
    return sdk
        .authInfo()
        .then(info => dispatch(authInfoSuccess(info)))
        .catch(e => {
        // Requesting auth info just reads the token from the token
        // store (i.e. cookies), and should not fail in normal
        // circumstances. If it fails, it's due to a programming
        // error. In that case we mark the operation done and dispatch
        // `null` success action that marks the user as unauthenticated.
        log.error(e, 'auth-info-failed');
        dispatch(authInfoSuccess());
    });
};
export const login = (username, password) => (dispatch, getState, sdk) => {
    if (authenticationInProgress(getState())) {
        return Promise.reject(new Error('Login or logout already in progress'));
    }
    dispatch(loginRequest());
    // Note that the thunk does not reject when the login fails, it
    // just dispatches the login error action.
    return sdk
        .login({ username, password })
        .then(() => dispatch(loginSuccess()))
        .then(() => dispatch(fetchCurrentUser()))
        .catch(e => dispatch(loginError(storableError(e))));
};
export const logout = () => (dispatch, getState, sdk) => __awaiter(void 0, void 0, void 0, function* () {
    if (authenticationInProgress(getState())) {
        return Promise.reject(new Error('Login or logout already in progress'));
    }
    dispatch(logoutRequest());
    // Note that the thunk does not reject when the logout fails, it
    // just dispatches the logout error action.
    try {
        yield sdk.logout();
        // The order of the dispatched actions
        dispatch(logoutSuccess());
        dispatch(clearCurrentUser());
        dispatch(clearCurrentStripeConnectAccount());
        log.clearUserId();
        dispatch(userLogout());
        if (isApp) {
            const { default: OneSignal } = yield import('onesignal-cordova-plugin');
            OneSignal.logout();
        }
    }
    catch (e) {
        return dispatch(logoutError(storableError(e)));
    }
});
export const signup = (params) => (dispatch, getState) => {
    const state = getState();
    if (authenticationInProgress(state)) {
        return Promise.reject(new Error('Login or logout already in progress'));
    }
    dispatch(signupRequest());
    const { email, password, firstName, lastName, protectedData, privateData } = params;
    const createUserParams = isEmpty(protectedData) && isEmpty(privateData)
        ? { email, password, firstName, lastName }
        : {
            email,
            password,
            firstName,
            lastName,
            profile: {
                protectedData,
                privateData,
            },
        };
    const locale = localeSelector(state);
    // We must login the user if signup succeeds since the API doesn't
    // do that automatically.
    return createCurrentUser(createUserParams, { locale })
        .then(() => dispatch(signupSuccess()))
        .then(() => dispatch(login(email, password)))
        .catch((e) => {
        dispatch(signupError(storableError(e)));
        log.error(e, 'signup-failed', {
            email: params.email,
            firstName: params.firstName,
            lastName: params.lastName,
        });
    });
};
export const isAuthenticatedSelector = (state) => state.Auth.isAuthenticated;
export const createAccountInProgressSelector = (state) => state.Auth.signupInProgress;
export const loginInProgressSelector = (state) => state.Auth.loginInProgress;
export const userIsAllowedToStartBookingProcessSelector = (state) => {
    const isAuthenticated = isAuthenticatedSelector(state);
    const userIsVerified = userIsVerifiedSelector(state);
    return isAuthenticated && userIsVerified;
};
