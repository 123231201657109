import { useState } from 'react';
const useSwipe = (showNext, showPrevious) => {
    const [startX, setStartX] = useState(null);
    const [endX, setEndX] = useState(null);
    const handleTouchStart = (event) => {
        setStartX(event.touches[0].clientX);
    };
    const handleTouchMove = (event) => {
        setEndX(event.touches[0].clientX);
    };
    const handleTouchEnd = () => {
        if (startX && endX) {
            if (startX - endX > 0) {
                showNext();
            }
            else if (startX - endX < 0) {
                showPrevious();
            }
        }
    };
    return {
        handleTouchStart,
        handleTouchMove,
        handleTouchEnd,
    };
};
export default useSwipe;
