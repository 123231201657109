var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { SearchBoxCore, SessionToken, } from '@mapbox/search-js-core';
import { types as sdkTypes } from '@nomady/shared/utils/sdkLoader';
import { localeSelector } from './UISlice';
import config from '../config';
const { LatLngBounds, LatLng } = sdkTypes;
const initialState = {
    loading: false,
    error: null,
    sessionToken: new SessionToken(),
    suggestions: [],
};
const searchBoxCore = new SearchBoxCore({
    accessToken: config.maps.mapboxKey,
});
export const fetchPredictions = createAsyncThunk('mapboxSearch/fetchPredictions', (query, { getState, rejectWithValue }) => __awaiter(void 0, void 0, void 0, function* () {
    const { sessionToken } = getState().MapboxSearch;
    const language = localeSelector(getState()).substring(0, 2);
    try {
        const result = yield searchBoxCore.suggest(query, {
            sessionToken,
            country: 'de,at,fr,ch,li,it,hu,pt,es,nl,dk,si,gr,pl,rs,cz,bg',
            language,
            types: 'country,region,district,place,locality,neighborhood,city',
        });
        return result.suggestions;
    }
    catch (error) {
        return rejectWithValue(error.message);
    }
}));
export const fetchPlaceDetails = createAsyncThunk('mapboxSearch/fetchPlaceDetails', (placeId, { getState, rejectWithValue }) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b, _c;
    const { sessionToken, suggestions } = getState().MapboxSearch;
    const suggestion = suggestions.find(s => s.mapbox_id === placeId);
    if (!suggestion) {
        return rejectWithValue('Suggestion not found');
    }
    try {
        const { features } = yield searchBoxCore.retrieve(suggestion, {
            sessionToken,
        });
        if (!features) {
            return rejectWithValue('No details found');
        }
        const bboxToLatLngBounds = (bbox) => {
            return new LatLngBounds(new LatLng(bbox[3], bbox[2]), new LatLng(bbox[1], bbox[0]));
        };
        const place = features[0];
        const bbox = (_b = (_a = place.geometry.bbox) !== null && _a !== void 0 ? _a : place.bbox) !== null && _b !== void 0 ? _b : place.properties.bbox;
        const placeDetails = {
            placeId: (_c = place.id) === null || _c === void 0 ? void 0 : _c.toString(),
            address: place.properties.address,
            bounds: bbox
                ? bboxToLatLngBounds(bbox)
                : new LatLngBounds(new LatLng(place.geometry.coordinates[1] + 0.3, place.geometry.coordinates[0] + 0.3), new LatLng(place.geometry.coordinates[1] - 0.3, place.geometry.coordinates[0] - 0.3)),
            name: place.properties.name,
            location: new LatLng(place.geometry.coordinates[1], place.geometry.coordinates[0]),
        };
        return placeDetails;
    }
    catch (error) {
        return rejectWithValue(error.message);
    }
}));
export const mapboxSearchSlice = createSlice({
    name: 'mapboxSearch',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchPredictions.pending, state => {
            state.loading = true;
        })
            .addCase(fetchPredictions.fulfilled, (state, action) => {
            state.loading = false;
            state.suggestions = action.payload;
        })
            .addCase(fetchPredictions.rejected, state => {
            state.loading = false;
            state.error = 'Error fetching predictions';
        })
            .addCase(fetchPlaceDetails.pending, state => {
            state.loading = true;
        })
            .addCase(fetchPlaceDetails.fulfilled, state => {
            state.loading = false;
        })
            .addCase(fetchPlaceDetails.rejected, state => {
            state.loading = false;
            state.error = 'Error fetching place details';
        });
    },
});
export default mapboxSearchSlice.reducer;
