var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import classNames from 'classnames';
import { intlShape, injectIntl } from '../../util/reactIntl';
import { ValidationError } from '..';
import Tooltip from '../../styled-components/Tooltip/Tooltip';
import css from './FieldSelect.module.scss';
const FieldSelectComponent = (props) => {
    const { rootClassName, className, id, label, input, meta, children, required, tooltip, intl, inputOnChange } = props, rest = __rest(props, ["rootClassName", "className", "id", "label", "input", "meta", "children", "required", "tooltip", "intl", "inputOnChange"]);
    if (label && !id) {
        throw new Error('id required when a label is given');
    }
    const { valid, invalid, touched, error } = meta;
    // Error message and input error styles are only shown if the
    // field has been touched and the validation has failed.
    const hasError = touched && invalid && error;
    const selectClasses = classNames(css.select, {
        [css.selectSuccess]: valid,
        [css.selectError]: hasError,
    });
    const selectProps = Object.assign(Object.assign({ className: selectClasses, id }, input), rest);
    const classes = classNames(rootClassName, className);
    const infoBubble = tooltip ? _jsx(Tooltip, { text: tooltip }) : null;
    return (_jsxs("div", Object.assign({ className: classes }, { children: [label ? (_jsxs("label", Object.assign({ htmlFor: id }, { children: [required ? '* ' : null, label, " ", infoBubble] }))) : null, _jsxs("select", Object.assign({}, selectProps, { onChange: event => {
                    input.onChange(event);
                    if (inputOnChange) {
                        inputOnChange(event);
                    }
                } }, { children: [_jsx("option", Object.assign({ value: "", disabled: true }, { children: intl.formatMessage({ id: 'EditListingWizard.selectPlaceholder' }) })), children] })), _jsx(ValidationError, { fieldMeta: meta })] })));
};
FieldSelectComponent.defaultProps = {
    rootClassName: null,
    className: null,
    id: null,
    label: null,
    tooltip: null,
    children: null,
};
const { string, object, node } = PropTypes;
FieldSelectComponent.propTypes = {
    intl: intlShape.isRequired,
    rootClassName: string,
    className: string,
    // Label is optional, but if it is given, an id is also required so
    // the label can reference the input in the `for` attribute
    id: string,
    label: string,
    tooltip: string,
    // Generated by final-form's Field component
    input: object.isRequired,
    meta: object.isRequired,
    children: node,
};
const FieldSelect = (_a) => {
    var { onChange } = _a, props = __rest(_a, ["onChange"]);
    return _jsx(Field, Object.assign({ component: FieldSelectComponent }, props));
};
export default compose(injectIntl)(FieldSelect);
