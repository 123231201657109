import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import styled from 'styled-components/macro';
import { useSelector } from 'react-redux';
import { formatDisplayMoney } from '@nomady/shared/utils/currency';
import Page from '@nomady/shared/routeConfiguration/Page';
import { NamedLink } from '../../components';
import Text, { TextVariant } from '../Text/Text';
import { ColorVariant } from '../../theme';
import { localeSelector } from '../../slices/UISlice';
import Avatar, { SizeVariant } from '../Avatar/Avatar';
import TransactionTag from '../TransactionTag/TransactionTag';
export const DESKTOP_TEMPLATE_COLUMNS = '100px 150px 46px 160px 50px auto 100px 100px 100px';
export var BookingCardVariant;
(function (BookingCardVariant) {
    BookingCardVariant["Default"] = "Default";
    BookingCardVariant["Flag"] = "Flag";
    BookingCardVariant["Summary"] = "Summary";
})(BookingCardVariant || (BookingCardVariant = {}));
export var BookingCardShadowVariant;
(function (BookingCardShadowVariant) {
    BookingCardShadowVariant["Default"] = "Default";
    BookingCardShadowVariant["Shadow"] = "Shadow";
})(BookingCardShadowVariant || (BookingCardShadowVariant = {}));
const Wrapper = styled.section `
  display: grid;

  border-radius: 5px;
  background-color: ${props => props.theme.color.pearl};
  overflow: hidden;

  padding: 7px 10px 5px 10px;

  grid-template-columns: min-content min-content 1fr auto auto;
  grid-template-rows: auto auto auto;

  align-items: center;

  @media ${props => props.theme.device.desktop} {
    grid-template-columns: ${DESKTOP_TEMPLATE_COLUMNS};
    grid-template-rows: auto;
  }
`;
const PositionedTransactionTag = styled.div `
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 2;

  @media ${props => props.theme.device.desktop} {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 1;
  }
`;
const PositionedUnreadTag = styled.div `
  grid-column-start: 3;
  grid-column-end: 4;
  grid-row-start: 1;
  grid-row-end: 2;

  margin-left: 5px;

  text-align: left;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media ${props => props.theme.device.desktop} {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 1;
  }
`;
const PositionedAvatar = styled.div `
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 2;
  grid-row-end: 4;

  padding-top: 10px;
  padding-right: 10px;

  @media ${props => props.theme.device.desktop} {
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 1;

    padding-top: initial;
  }
`;
const PositionedGuestName = styled(Text) `
  grid-column-start: 2;
  grid-column-end: 6;
  grid-row-start: 2;
  grid-row-end: 3;

  padding-top: 5px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media ${props => props.theme.device.desktop} {
    grid-column-start: 4;
    grid-column-end: 5;
    grid-row-start: 1;
    grid-row-end: 1;

    padding: initial;
  }
`;
const StyledNamedLink = styled(NamedLink) `
  &:hover {
    text-decoration: underline;
    color: ${props => props.theme.color.cave};
  }
`;
const PositionedListingName = styled(Text) `
  grid-column-start: 2;
  grid-column-end: 5;
  grid-row-start: 3;
  grid-row-end: 4;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media ${props => props.theme.device.desktop} {
    grid-column-start: 6;
    grid-column-end: 7;
    grid-row-start: 1;
    grid-row-end: 1;
  }
`;
const PositionedPayoutTotal = styled(Text) `
  grid-column-start: 4;
  grid-column-end: 6;
  grid-row-start: 3;
  grid-row-end: 4;

  text-align: right;

  @media ${props => props.theme.device.desktop} {
    grid-column-start: 7;
    grid-column-end: 8;
    grid-row-start: 1;
    grid-row-end: 1;
  }
`;
const PositionedDates = styled(Text) `
  grid-column-start: 4;
  grid-column-end: 6;
  grid-row-start: 1;
  grid-row-end: 2;

  text-align: right;

  @media ${props => props.theme.device.desktop} {
    display: none;
    grid-column-start: 8;
    grid-column-end: 9;
    grid-row-start: 1;
    grid-row-end: 1;
  }
`;
const PositionedArrivalDate = styled(Text) `
  display: none;

  @media ${props => props.theme.device.desktop} {
    display: block;
    grid-column-start: 8;
    grid-column-end: 9;
    grid-row-start: 1;
    grid-row-end: 1;

    text-align: right;
  }
`;
const PositionedDepartureDate = styled(Text) `
  display: none;

  @media ${props => props.theme.device.desktop} {
    display: block;
    grid-column-start: 9;
    grid-column-end: 10;
    grid-row-start: 1;
    grid-row-end: 1;

    text-align: right;
  }
`;
const BookingCard = ({ id, listingName, guest, arrivalDate, departureDate, payoutTotal, status, currency, lastTransitionedAt, unread = false, }) => {
    var _a;
    const locale = useSelector(localeSelector);
    return (_jsx(NamedLink, Object.assign({ name: Page.Sale, params: {
            id: id.uuid,
        } }, { children: _jsxs(Wrapper, { children: [_jsx(PositionedTransactionTag, { children: _jsx(TransactionTag, { status: status }) }), guest && (_jsx(PositionedAvatar, { children: _jsx(StyledNamedLink, Object.assign({ name: "ProfilePage", params: { id: guest.id.uuid } }, { children: _jsx(Avatar, { sizeVariant: SizeVariant.XS, initials: guest.attributes.profile.abbreviatedName, source: (_a = guest.profileImage) === null || _a === void 0 ? void 0 : _a.attributes.variants['square-small'].url }) })) })), guest && (_jsx(PositionedGuestName, Object.assign({ variant: TextVariant.H50 }, { children: _jsx(StyledNamedLink, Object.assign({ name: "ProfilePage", params: { id: guest.id.uuid } }, { children: guest.attributes.profile.displayName })) }))), _jsx(PositionedListingName, Object.assign({ variant: TextVariant.B60 }, { children: listingName })), _jsxs(PositionedPayoutTotal, Object.assign({ variant: TextVariant.B50 }, { children: [currency, " ", formatDisplayMoney(payoutTotal)] })), unread && (_jsxs(PositionedUnreadTag, { children: [_jsx("svg", Object.assign({ width: "12", height: "11", viewBox: "0 0 12 11", fill: "none", style: { marginRight: '3px' } }, { children: _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M6 10.5C8.76142 10.5 11 8.26142 11 5.5C11 2.73858 8.76142 0.5 6 0.5C3.23858 0.5 1 2.73858 1 5.5C1 8.26142 3.23858 10.5 6 10.5Z", fill: "#E13F32" }) })), _jsx(Text, Object.assign({ variant: TextVariant.B60 }, { children: lastTransitionedAt.toLocaleDateString(locale, {
                                year: '2-digit',
                                month: 'numeric',
                                day: 'numeric',
                                weekday: 'short',
                            }) }))] })), arrivalDate && departureDate && (_jsx(_Fragment, { children: _jsxs(PositionedDates, Object.assign({ variant: TextVariant.B50, colorVariant: ColorVariant.Cave }, { children: [arrivalDate.toLocaleDateString(locale, {
                                year: arrivalDate.getFullYear() !== departureDate.getFullYear()
                                    ? '2-digit'
                                    : undefined,
                                month: 'numeric',
                                day: 'numeric',
                            }), "\u2013", departureDate.toLocaleDateString(locale, {
                                year: '2-digit',
                                month: 'numeric',
                                day: 'numeric',
                            })] })) })), arrivalDate && (_jsx(PositionedArrivalDate, Object.assign({ variant: TextVariant.B50, colorVariant: ColorVariant.Cave }, { children: arrivalDate.toLocaleDateString(locale, {
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                        weekday: 'short',
                    }) }))), departureDate && (_jsx(PositionedDepartureDate, Object.assign({ variant: TextVariant.B50, colorVariant: ColorVariant.Cave }, { children: departureDate.toLocaleDateString(locale, {
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                        weekday: 'short',
                    }) })))] }) })));
};
export default BookingCard;
