import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import Icon, { IconSizeVariant, IconVariant, } from '../../../styled-components/Icon/Icon';
import Text, { TextVariant } from '../../../styled-components/Text/Text';
import { ColorVariant } from '../../../theme';
const Wrapper = styled.div `
  display: flex;
  flex-direction: column;
`;
const IconWrapper = styled.div `
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: flex-start;
  padding-left: 0px;
  padding-bottom: 15px;
`;
const Title = styled.div `
  margin: 20px 0;
`;
const CampSpotsContent = ({ availableSeats, }) => {
    var _a, _b;
    const { listing } = useSelector((state) => state.ListingPage);
    const intl = useIntl();
    const seats = ((_b = (_a = listing === null || listing === void 0 ? void 0 : listing.attributes) === null || _a === void 0 ? void 0 : _a.publicData) === null || _b === void 0 ? void 0 : _b.seats) || 0;
    const freeAvailableSeats = availableSeats || seats;
    const spotIds = [...Array(seats).keys()].map(spotIndex => spotIndex + 1);
    return (_jsxs(Wrapper, { children: [_jsx(IconWrapper, { children: spotIds.map(spotId => (_jsx(Icon, { variant: IconVariant[`Seat${spotId}`], sizeVariant: IconSizeVariant.Small, strikethrough: freeAvailableSeats < spotId, backgroundColor: freeAvailableSeats < spotId ? ColorVariant.Fog : undefined }, `campSeats${spotId}`))) }), _jsx(Title, { children: _jsx(Text, Object.assign({ variant: TextVariant.H20 }, { children: intl.formatMessage({ id: 'ListingPage.campSpots.title' }) })) }), freeAvailableSeats !== seats && (_jsx(Text, Object.assign({ variant: TextVariant.B20 }, { children: intl.formatMessage({ id: `ListingPage.campSpots.availableInThisPeriod` }, { freeAvailableSeats }) }))), _jsx(Text, Object.assign({ variant: TextVariant.B20 }, { children: intl.formatMessage({ id: 'ListingPage.campSpots.description' }, { seats }) }))] }));
};
export default CampSpotsContent;
