import React from 'react';
import { string, oneOfType, bool } from 'prop-types';
import classNames from 'classnames';
import {
  userDisplayNameAsString,
  userAbbreviatedName,
} from '@nomady/shared/utils/data.ts';
import { propTypes } from '@nomady/shared/utils/types.ts';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { ResponsiveImage, IconBannedUser, NamedLink } from '../index.ts';

import css from './Avatar.module.scss';

// Responsive image sizes hint
const AVATAR_SIZES = '40px';

const AVATAR_IMAGE_VARIANTS = [
  // 240x240
  // 'square-small',

  // 480x480
  'square-small2x',

  // 320x275
  'scaled-small',
];

const AvatarComponent = props => {
  const {
    rootClassName,
    className,
    user,
    renderSizes,
    disableProfileLink,
    intl,
  } = props;
  const classes = classNames(rootClassName || css.root, className);

  const isBannedUser = user?.attributes.banned;
  const isDeletedUser = user?.attributes.deleted;

  const bannedUserDisplayName = intl.formatMessage({
    id: 'Avatar.bannedUserDisplayName',
  });

  const deletedUserDisplayName = intl.formatMessage({
    id: 'Avatar.deletedUserDisplayName',
  });

  let defaultUserDisplayName = '';

  if (isBannedUser) {
    defaultUserDisplayName = bannedUserDisplayName;
  } else if (isDeletedUser) {
    defaultUserDisplayName = deletedUserDisplayName;
  }

  const defaultUserAbbreviatedName = '';

  const displayName = userDisplayNameAsString(user, defaultUserDisplayName);
  const abbreviatedName = userAbbreviatedName(user, defaultUserAbbreviatedName);
  const rootProps = { className: classes, title: displayName };
  const linkProps = user?.id
    ? { name: 'ProfilePage', params: { id: user?.id.uuid } }
    : { name: 'ProfileBasePage' };
  const hasProfileImage = user?.profileImage && user?.profileImage.id;
  const profileLinkEnabled = !disableProfileLink;

  if (isBannedUser || isDeletedUser) {
    return (
      <div {...rootProps}>
        <IconBannedUser className={css.bannedUserIcon} />
      </div>
    );
  }

  if (hasProfileImage && profileLinkEnabled) {
    return (
      <NamedLink {...rootProps} {...linkProps}>
        <ResponsiveImage
          rootClassName={css.avatarImage}
          alt={displayName}
          image={user?.profileImage}
          variants={AVATAR_IMAGE_VARIANTS}
          sizes={renderSizes}
        />
      </NamedLink>
    );
  }

  if (hasProfileImage) {
    return (
      <div {...rootProps}>
        <ResponsiveImage
          rootClassName={css.avatarImage}
          alt={displayName}
          image={user?.profileImage}
          variants={AVATAR_IMAGE_VARIANTS}
          sizes={renderSizes}
        />
      </div>
    );
  }

  if (profileLinkEnabled) {
    // Placeholder avatar (initials)
    return (
      <NamedLink {...rootProps} {...linkProps}>
        <span className={css.initials}>{abbreviatedName}</span>
      </NamedLink>
    );
  }

  // Placeholder avatar (initials)
  return (
    <div {...rootProps}>
      <span className={css.initials}>{abbreviatedName}</span>
    </div>
  );
};

AvatarComponent.defaultProps = {
  className: null,
  rootClassName: null,
  user: null,
  renderSizes: AVATAR_SIZES,
  disableProfileLink: false,
};

AvatarComponent.propTypes = {
  rootClassName: string,
  className: string,
  user: oneOfType([propTypes.user, propTypes.currentUser]),

  renderSizes: string,
  disableProfileLink: bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

const Avatar = injectIntl(AvatarComponent);

export default Avatar;
