var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import { SafeArea } from 'capacitor-plugin-safe-area';
import { useDispatch, useSelector } from 'react-redux';
import { isApp, isIos } from '../../util/deviceContext';
import { TOPBAR_HEIGHT } from '../TopBar/TopBar';
import { safeSpaceBottomSelector, safeSpaceTopSelector, setSafeSpaceBottom, setSafeSpaceTop, } from '../../slices/UISlice';
import usePreventBodyScroll from './usePreventBodyScroll';
import { useContactJannis } from '../../hooks/useContactJannis';
const PageLayoutWrapper = styled.div `
  display: grid;
  min-height: calc(
    100vh-${props => props.safeSpaceTop}px-${props => props.safeSpaceBottom}px
  );
  margin-top: ${props => props.safeSpaceTop}px;
  grid-template-columns: 100%;
  grid-template-rows: min-content auto min-content;
  height: auto;
`;
const SafeAreaTop = styled.div `
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: ${props => props.safeSpaceTop}px;
  background-color: ${props => props.theme.color.snow};
  z-index: 1000;
`;
export const PageLayout = ({ children }) => {
    const safeSpaceTop = useSelector(safeSpaceTopSelector);
    const safeSpaceBottom = useSelector(safeSpaceBottomSelector);
    const dispatch = useDispatch();
    useEffect(() => {
        const getInsets = () => __awaiter(void 0, void 0, void 0, function* () {
            const insets = yield SafeArea.getSafeAreaInsets();
            const top = isIos ? insets.insets.top : 0;
            const bottom = isIos ? insets.insets.bottom : 0;
            dispatch(setSafeSpaceTop(top));
            dispatch(setSafeSpaceBottom(bottom));
        });
        if (isApp)
            getInsets();
    }, [dispatch, isApp, isIos]);
    usePreventBodyScroll();
    useContactJannis();
    return (_jsxs(PageLayoutWrapper, Object.assign({ safeSpaceTop: safeSpaceTop, safeSpaceBottom: safeSpaceBottom }, { children: [isApp && _jsx(SafeAreaTop, { safeSpaceTop: safeSpaceTop }), children] })));
};
export const PageLayoutTop = styled.div `
  min-height: ${TOPBAR_HEIGHT.mobile};
  grid-column: 1 / 1;
  grid-row: 1 / 1;

  ${props => !props.relative &&
    ` 
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  `}

  ${props => props.theme.priority.high}

  @media ${props => props.theme.device.tablet} {
    min-height: ${TOPBAR_HEIGHT.tablet};
  }
`;
export const PageLayoutContent = styled.div `
  grid-column: 1 / 1;
  grid-row: 2 / 2;

  ${props => !props.ignoreTopBarOffset && `margin-top: ${TOPBAR_HEIGHT.mobile};`}

  @media ${props => props.theme.device.tablet} {
    ${props => !props.ignoreTopBarOffset && `margin-top: ${TOPBAR_HEIGHT.tablet};`}
  }
`;
export const PageLayoutBottom = styled.div `
  grid-column: 1/1;
  grid-row: 3/3;
`;
