import React from 'react';
import { string, arrayOf, bool, func, number } from 'prop-types';
import dropWhile from 'lodash/dropWhile';
import classNames from 'classnames';
import { formatDate } from '@nomady/shared/utils/dates.ts';
import { getDeeplLocale } from '@nomady/shared/marketplace-custom-config';
import { ensureTransaction } from '@nomady/shared/utils/data.ts';
import {
  TRANSITION_ACCEPT,
  TRANSITION_ACCEPT_BY_OPERATOR,
  TRANSITION_CANCEL_BY_OPERATOR,
  TRANSITION_CANCEL_FOR_MANUAL_REFUND_BY_OPERATOR,
  TRANSITION_CANCEL_BY_CUSTOMER,
  TRANSITION_CANCEL_BY_CUSTOMER_WITHIN_CANCELLATION_POLICY,
  TRANSITION_CANCEL_BY_CUSTOMER_OUTSIDE_CANCELLATION_POLICY,
  TRANSITION_COMPLETE,
  TRANSITION_COMPLETE_FULL_AMOUNT_WITH_GIFT_CODE,
  TRANSITION_DECLINE,
  TRANSITION_DECLINE_BY_OPERATOR,
  TRANSITION_EXPIRE,
  TRANSITION_CONFIRM_PAYMENT,
  TRANSITION_CONFIRM_PAYMENT_FOR_INSTANT_BOOKING,
  TRANSITION_REVIEW_1_BY_CUSTOMER,
  TRANSITION_REVIEW_1_BY_PROVIDER,
  TRANSITION_REVIEW_2_BY_CUSTOMER,
  TRANSITION_REVIEW_2_BY_PROVIDER,
  transitionIsReviewed,
  txIsDelivered,
  txIsInFirstReviewBy,
  txIsReviewed,
  isCustomerReview,
  isProviderReview,
  txRoleIsProvider,
  txRoleIsCustomer,
  getUserTxRole,
  isRelevantPastTransition,
  TRANSITION_REACTIVATE_CUSTOMER_REVIEW_PERIOD_BY_OPERATOR,
  TRANSITION_REACTIVATE_PROVIDER_REVIEW_PERIOD_BY_OPERATOR,
  TRANSITION_REACTIVATE_CUSTOMER_AND_PROVIDER_REVIEW_PERIOD_BY_OPERATOR,
  TRANSITION_CLOSE_CUSTOMER_REVIEW_PERIOD_BY_OPERATOR,
  TRANSITION_CLOSE_PROVIDER_REVIEW_PERIOD_BY_OPERATOR,
  TRANSITION_CLOSE_CUSTOMER_AND_PROVIDER_REVIEW_PERIOD_BY_OPERATOR,
  TX_TRANSITION_ACTOR_CUSTOMER,
  TX_TRANSITION_ACTOR_PROVIDER,
  TRANSITION_PAY_FULL_AMOUNT_WITH_GIFT_CODE,
  TRANSITION_PAY_FULL_AMOUNT_WITH_GIFT_CODE_FOR_INSTANT_BOOKING,
  TRANSITION_DECLINE_BY_OPERATOR_FULL_AMOUNT_WITH_GIFT_CODE,
  TRANSITION_DECLINE_FULL_AMOUNT_WITH_GIFT_CODE,
  TRANSITION_ACCEPT_BY_OPERATOR_FULL_AMOUNT_WITH_GIFT_CODE,
  TRANSITION_ACCEPT_FULL_AMOUNT_WITH_GIFT_CODE,
  TRANSITION_EXPIRE_FULL_AMOUNT_WITH_GIFT_CODE,
  TRANSITION_CANCEL_BY_OPERATOR_FULL_AMOUNT_WITH_GIFT_CODE,
  TRANSITION_WITHDRAW_BOOKING_REQUEST_BY_CUSTOMER,
  TRANSITION_WITHDRAW_BOOKING_REQUEST_BY_CUSTOMER_FULL_AMOUNT_WITH_GIFT_CODE,
  TRANSITION_UPDATE_BOOKING_BY_OPERATOR,
  TRANSITION_CANCEL_BY_CUSTOMER_OUTSIDE_CANCELLATION_POLICY_PARTIAL_REFUND,
} from '@nomady/shared/utils/transaction.ts';
import { propTypes } from '@nomady/shared/utils/types.ts';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import {
  Avatar,
  InlineTextButton,
  ReviewRating,
  UserDisplayName,
} from '../index.ts';
import TranslatedBy from '../../styled-components/TranslatedBy/TranslatedBy.tsx';
import ToggleTranslationButton from '../../styled-components/ToggleTranslationButton/ToggleTranslationButton.tsx';
import * as log from '../../util/log.ts';

import css from './ActivityFeed.module.scss';

const Message = props => {
  const { message, intl } = props;
  const todayString = intl.formatMessage({ id: 'ActivityFeed.today' });
  return (
    <div className={css.message}>
      <div className={css.senderMessageContentWrapper}>
        <p className={css.messageContent}>
          <Avatar
            className={classNames(css.avatar, css.senderAvatar)}
            user={message.sender}
          />
          {message.attributes.content}
        </p>
        <p className={css.messageDate}>
          {formatDate(intl, todayString, message.attributes.createdAt)}
        </p>
      </div>
    </div>
  );
};

Message.propTypes = {
  message: propTypes.message.isRequired,
  intl: intlShape.isRequired,
};

const OwnMessage = props => {
  const { message, intl } = props;
  const todayString = intl.formatMessage({ id: 'ActivityFeed.today' });
  return (
    <div className={css.ownMessage}>
      <div className={css.ownMessageContentWrapper}>
        <p className={css.ownMessageContent} data-testid="ActivityFeed_message">
          <Avatar
            className={classNames(css.avatar, css.ownAvatar)}
            user={message.sender}
          />
          {message.attributes.content}
        </p>
      </div>
      <p className={css.ownMessageDate}>
        {formatDate(intl, todayString, message.attributes.createdAt)}
      </p>
    </div>
  );
};

OwnMessage.propTypes = {
  message: propTypes.message.isRequired,
  intl: intlShape.isRequired,
};

const Review = props => {
  const { content, rating, translatedBy, originalLocale, translationActive } =
    props;

  const showTranslatedByLabel =
    translationActive && originalLocale && translatedBy;

  return (
    <div>
      <p className={css.reviewContent}>{content}</p>
      {showTranslatedByLabel && (
        <TranslatedBy
          translatedBy={translatedBy}
          originalLocale={originalLocale}
        />
      )}
      <ReviewRating
        reviewStarClassName={css.reviewStar}
        className={css.reviewStars}
        rating={rating}
      />
    </div>
  );
};

Review.propTypes = {
  content: string.isRequired,
  rating: number.isRequired,
};

const hasUserLeftAReviewFirst = (userRole, transaction) => {
  // Because function txIsInFirstReviewBy uses isCustomer to check in which state the reviews are
  // we should also use isCustomer insted of isProvider
  const isCustomer = txRoleIsCustomer(userRole);
  return txIsInFirstReviewBy(transaction, isCustomer);
};

const resolveTransitionMessage = (
  transaction,
  transition,
  listingTitle,
  ownRole,
  otherUsersName,
  intl,
  onOpenReviewModal
) => {
  const isOwnTransition = transition.by === ownRole;
  const currentTransition = transition.transition;
  const displayName = otherUsersName;
  const isProvider = ownRole === 'provider';

  const customerName =
    transaction &&
    transaction.customer &&
    transaction.customer.attributes &&
    transaction.customer.attributes.profile &&
    transaction.customer.attributes.profile.displayName;

  // Show the leave a review link if the state is delivered and if the current user is the first to leave a review
  const reviewPeriodJustStarted = txIsDelivered(transaction);

  const reviewAsFirstLink = reviewPeriodJustStarted ? (
    <InlineTextButton onClick={onOpenReviewModal}>
      <FormattedMessage
        id="ActivityFeed.leaveAReview"
        values={{ displayName }}
      />
    </InlineTextButton>
  ) : null;

  // show the leave a review link if current user is not the first
  // one to leave a review
  const reviewPeriodIsOver = txIsReviewed(transaction);
  const userHasLeftAReview = hasUserLeftAReviewFirst(ownRole, transaction);
  const reviewAsSecondLink = !(reviewPeriodIsOver || userHasLeftAReview) ? (
    <InlineTextButton onClick={onOpenReviewModal}>
      <FormattedMessage
        id="ActivityFeed.leaveAReviewSecond"
        values={{ displayName }}
      />
    </InlineTextButton>
  ) : null;

  switch (currentTransition) {
    case TRANSITION_CONFIRM_PAYMENT:
    case TRANSITION_PAY_FULL_AMOUNT_WITH_GIFT_CODE:
      return isOwnTransition ? (
        <FormattedMessage
          id="ActivityFeed.ownTransitionRequest"
          values={{ listingTitle }}
        />
      ) : (
        <FormattedMessage
          id="ActivityFeed.transitionRequest"
          values={{ displayName, listingTitle }}
        />
      );
    case TRANSITION_CONFIRM_PAYMENT_FOR_INSTANT_BOOKING:
    case TRANSITION_PAY_FULL_AMOUNT_WITH_GIFT_CODE_FOR_INSTANT_BOOKING:
      return <FormattedMessage id="ActivityFeed.ownTransitionInstantAccept" />;
    case TRANSITION_ACCEPT_BY_OPERATOR:
    case TRANSITION_ACCEPT_BY_OPERATOR_FULL_AMOUNT_WITH_GIFT_CODE:
    case TRANSITION_ACCEPT:
    case TRANSITION_ACCEPT_FULL_AMOUNT_WITH_GIFT_CODE:
      return isOwnTransition ? (
        <FormattedMessage id="ActivityFeed.ownTransitionAccept" />
      ) : (
        <FormattedMessage
          id="ActivityFeed.transitionAccept"
          values={{ displayName }}
        />
      );
    case TRANSITION_DECLINE_BY_OPERATOR:
    case TRANSITION_DECLINE_BY_OPERATOR_FULL_AMOUNT_WITH_GIFT_CODE:
    case TRANSITION_DECLINE:
    case TRANSITION_DECLINE_FULL_AMOUNT_WITH_GIFT_CODE:
      return isOwnTransition ? (
        <FormattedMessage id="ActivityFeed.ownTransitionDecline" />
      ) : (
        <FormattedMessage
          id="ActivityFeed.transitionDecline"
          values={{ displayName }}
        />
      );
    case TRANSITION_WITHDRAW_BOOKING_REQUEST_BY_CUSTOMER:
    case TRANSITION_WITHDRAW_BOOKING_REQUEST_BY_CUSTOMER_FULL_AMOUNT_WITH_GIFT_CODE:
      return isOwnTransition ? (
        <FormattedMessage id="ActivityFeed.ownTransitionBookingRequestWithdrawn" />
      ) : (
        <FormattedMessage
          id="ActivityFeed.transitionBookingRequestWithdrawn"
          values={{ displayName }}
        />
      );
    case TRANSITION_EXPIRE:
    case TRANSITION_EXPIRE_FULL_AMOUNT_WITH_GIFT_CODE:
      return txRoleIsProvider(ownRole) ? (
        <FormattedMessage id="ActivityFeed.ownTransitionExpire" />
      ) : (
        <FormattedMessage
          id="ActivityFeed.transitionExpire"
          values={{ displayName }}
        />
      );
    case TRANSITION_CANCEL_BY_OPERATOR:
    case TRANSITION_CANCEL_BY_OPERATOR_FULL_AMOUNT_WITH_GIFT_CODE:
      return isProvider ? (
        <FormattedMessage id="ActivityFeed.transitionCancelByOperatorProvider" />
      ) : (
        <FormattedMessage id="ActivityFeed.transitionCancelByOperatorCustomer" />
      );
    case TRANSITION_CANCEL_BY_CUSTOMER:
    case TRANSITION_CANCEL_FOR_MANUAL_REFUND_BY_OPERATOR:
      return (
        <FormattedMessage id="ActivityFeed.transitionCancelForManualRefund" />
      );
    case TRANSITION_CANCEL_BY_CUSTOMER_WITHIN_CANCELLATION_POLICY:
      return isProvider ? (
        <FormattedMessage
          id="ActivityFeed.transitionCancelByCustomerWithinCancellationPolicyProvider"
          values={{ displayName: customerName }}
        />
      ) : (
        <FormattedMessage id="ActivityFeed.transitionCancelByCustomerWithinCancellationPolicyCustomer" />
      );
    case TRANSITION_CANCEL_BY_CUSTOMER_OUTSIDE_CANCELLATION_POLICY:
      return isProvider ? (
        <FormattedMessage
          id="ActivityFeed.transitionCancelByCustomerOutsideCancellationPolicyProvider"
          values={{ displayName: customerName }}
        />
      ) : (
        <FormattedMessage id="ActivityFeed.transitionCancelByCustomerOutsideCancellationPolicyCustomer" />
      );
    case TRANSITION_CANCEL_BY_CUSTOMER_OUTSIDE_CANCELLATION_POLICY_PARTIAL_REFUND:
      return isProvider ? (
        <FormattedMessage
          id="ActivityFeed.transitionCancelByCustomerOutsideCancellationPolicyProvider"
          values={{ displayName: customerName }}
        />
      ) : (
        <FormattedMessage id="ActivityFeed.transitionCancelByCustomerOutsideCancellationPolicyPartialRefundCustomer" />
      );
    case TRANSITION_COMPLETE:
    case TRANSITION_COMPLETE_FULL_AMOUNT_WITH_GIFT_CODE:
      return (
        <FormattedMessage
          id="ActivityFeed.transitionComplete"
          values={{ reviewLink: reviewAsFirstLink }}
        />
      );

    case TRANSITION_REVIEW_1_BY_PROVIDER:
    case TRANSITION_REVIEW_1_BY_CUSTOMER:
      if (isOwnTransition) {
        return (
          <FormattedMessage
            id="ActivityFeed.ownTransitionReview"
            values={{ displayName }}
          />
        );
      }
      return (
        <FormattedMessage
          id="ActivityFeed.transitionReview"
          values={{ displayName, reviewLink: reviewAsSecondLink }}
        />
      );
    case TRANSITION_REVIEW_2_BY_PROVIDER:
    case TRANSITION_REVIEW_2_BY_CUSTOMER:
      if (isOwnTransition) {
        return (
          <FormattedMessage
            id="ActivityFeed.ownTransitionReview"
            values={{ displayName }}
          />
        );
      }
      return (
        <FormattedMessage
          id="ActivityFeed.transitionReview"
          values={{ displayName, reviewLink: null }}
        />
      );

    case TRANSITION_REACTIVATE_CUSTOMER_REVIEW_PERIOD_BY_OPERATOR:
      return (
        <FormattedMessage
          id="ActivityFeed.transitionReactivateCustomerReviewPeriod"
          values={{
            reviewLink:
              ownRole === TX_TRANSITION_ACTOR_CUSTOMER
                ? reviewAsSecondLink
                : null,
          }}
        />
      );

    case TRANSITION_REACTIVATE_PROVIDER_REVIEW_PERIOD_BY_OPERATOR:
      return (
        <FormattedMessage
          id="ActivityFeed.transitionReactivateProviderReviewPeriod"
          values={{
            reviewLink:
              ownRole === TX_TRANSITION_ACTOR_PROVIDER
                ? reviewAsSecondLink
                : null,
          }}
        />
      );

    case TRANSITION_REACTIVATE_CUSTOMER_AND_PROVIDER_REVIEW_PERIOD_BY_OPERATOR:
      return (
        <FormattedMessage
          id="ActivityFeed.transitionReactivateCustomerAndProviderReviewPeriod"
          values={{
            reviewLink: reviewAsFirstLink,
          }}
        />
      );

    case TRANSITION_CLOSE_CUSTOMER_REVIEW_PERIOD_BY_OPERATOR:
      return (
        <FormattedMessage id="ActivityFeed.transitionCloseCustomerReviewPeriod" />
      );

    case TRANSITION_CLOSE_PROVIDER_REVIEW_PERIOD_BY_OPERATOR:
      return (
        <FormattedMessage id="ActivityFeed.transitionCloseProviderReviewPeriod" />
      );

    case TRANSITION_CLOSE_CUSTOMER_AND_PROVIDER_REVIEW_PERIOD_BY_OPERATOR:
      return (
        <FormattedMessage id="ActivityFeed.transitionCloseCustomerAndProviderReviewPeriod" />
      );

    case TRANSITION_UPDATE_BOOKING_BY_OPERATOR:
      return (
        <FormattedMessage id="ActivityFeed.transitionUpdateBookingByOperator" />
      );

    default:
      log.error(
        new Error('Unknown transaction transition type'),
        'unknown-transition-type',
        {
          transitionType: currentTransition,
        }
      );
      return '';
  }
};

const reviewByAuthorId = (transaction, userId) => {
  return transaction.reviews.filter(r => r.author?.id?.uuid === userId.uuid)[0];
};

const Transition = props => {
  const {
    transition,
    transaction,
    currentUser,
    intl,
    onOpenReviewModal,
    translationActive,
  } = props;

  const currentTransaction = ensureTransaction(transaction);
  const { customer, provider } = currentTransaction;

  const deletedListing = intl.formatMessage({
    id: 'ActivityFeed.deletedListing',
  });
  const listingTitle = currentTransaction.listing.attributes.deleted
    ? deletedListing
    : currentTransaction.listing.attributes.title;
  const {
    attributes: { lastTransition },
  } = currentTransaction;

  const ownRole = getUserTxRole(currentUser.id, currentTransaction);

  const otherUsersName = txRoleIsProvider(ownRole) ? (
    <UserDisplayName user={customer} intl={intl} />
  ) : (
    <UserDisplayName user={provider} intl={intl} />
  );

  const transitionMessage = resolveTransitionMessage(
    transaction,
    transition,
    listingTitle,
    ownRole,
    otherUsersName,
    intl,
    onOpenReviewModal
  );
  const currentTransition = transition.transition;

  let reviewComponent = null;

  if (transitionIsReviewed(lastTransition)) {
    if (isCustomerReview(currentTransition)) {
      const review = reviewByAuthorId(currentTransaction, customer.id);
      reviewComponent = review ? (
        <Review
          content={review.attributes.content}
          rating={review.attributes.rating}
          translatedBy={review.attributes.translatedBy}
          originalLocale={review.attributes.originalLocale}
          translationActive={translationActive}
        />
      ) : null;
    } else if (isProviderReview(currentTransition)) {
      const review = reviewByAuthorId(currentTransaction, provider.id);
      reviewComponent = review ? (
        <Review
          content={review.attributes.content}
          rating={review.attributes.rating}
          translatedBy={review.attributes.translatedBy}
          originalLocale={review.attributes.originalLocale}
          translationActive={translationActive}
        />
      ) : null;
    }
  }

  const todayString = intl.formatMessage({ id: 'ActivityFeed.today' });

  return (
    <div className={css.transition}>
      <div className={css.bullet}>
        <p className={css.transitionContent}>•</p>
      </div>
      <div>
        <p className={css.transitionContent}>{transitionMessage}</p>
        <p className={css.transitionDate}>
          {formatDate(intl, todayString, transition.createdAt)}
        </p>
        {reviewComponent}
      </div>
    </div>
  );
};

Transition.propTypes = {
  transition: propTypes.transition.isRequired,
  transaction: propTypes.transaction.isRequired,
  currentUser: propTypes.currentUser.isRequired,
  intl: intlShape.isRequired,
  onOpenReviewModal: func.isRequired,
};

const EmptyTransition = () => {
  return (
    <div className={css.transition}>
      <div className={css.bullet}>
        <p className={css.transitionContent}>•</p>
      </div>
      <div>
        <p className={css.transitionContent} />
        <p className={css.transitionDate} />
      </div>
    </div>
  );
};

const isMessage = item => item && item.type === 'message';

// Compare function for sorting an array containing messages and transitions
const compareItems = (a, b) => {
  const itemDate = item =>
    isMessage(item) ? item.attributes.createdAt : item.createdAt;
  return itemDate(a) - itemDate(b);
};

const organizedItems = (messages, transitions, hideOldTransitions) => {
  const items = messages.concat(transitions).sort(compareItems);
  if (hideOldTransitions) {
    // Hide transitions that happened before the oldest message. Since
    // we have older items (messages) that we are not showing, seeing
    // old transitions would be confusing.
    return dropWhile(items, i => !isMessage(i));
  }

  return items;
};

const ActivityFeedComponent = props => {
  const {
    rootClassName,
    className,
    messages,
    transaction,
    currentUser,
    hasOlderMessages,
    onOpenReviewModal,
    onShowOlderMessages,
    fetchMessagesInProgress,
    intl,
    translationActive,
    translationLoading,
    onToggleTranslation,
    locale,
  } = props;
  const classes = classNames(rootClassName || css.root, className);

  const currentTransaction = ensureTransaction(transaction);
  const transitions = currentTransaction.attributes.transitions
    ? currentTransaction.attributes.transitions
    : [];
  const { customer, provider, listing } = currentTransaction;

  const transitionsAvailable = !!(
    currentUser?.id &&
    customer?.id &&
    provider?.id &&
    listing?.id
  );

  // combine messages and transaction transitions
  const items = organizedItems(
    messages,
    transitions,
    hasOlderMessages || fetchMessagesInProgress
  );

  const transitionComponent = transition => {
    if (transitionsAvailable) {
      return (
        <Transition
          transition={transition}
          transaction={transaction}
          currentUser={currentUser}
          intl={intl}
          onOpenReviewModal={onOpenReviewModal}
          translationActive={translationActive}
          onToggleTranslation={onToggleTranslation}
          translationLoading={translationLoading}
        />
      );
    }

    return <EmptyTransition />;
  };

  const messageComponent = message => {
    const isOwnMessage =
      message.sender &&
      message.sender.id &&
      currentUser &&
      currentUser.id &&
      message.sender.id.uuid === currentUser.id.uuid;
    if (isOwnMessage) {
      return <OwnMessage message={message} intl={intl} />;
    }
    return <Message message={message} intl={intl} />;
  };

  const messageListItem = message => {
    return (
      <li
        id={`msg-${message.id.uuid}`}
        key={message.id.uuid}
        className={css.messageItem}
      >
        {messageComponent(message)}
      </li>
    );
  };

  const transitionListItem = transition => {
    if (isRelevantPastTransition(transition.transition)) {
      return (
        <li
          key={`${transition.transition}-${transition.createdAt.toISOString()}`}
          className={css.transitionItem}
        >
          {transitionComponent(transition)}
        </li>
      );
    }

    return null;
  };

  const showTranslateToggleButton = () => {
    const reviewFromCustomer = reviewByAuthorId(
      currentTransaction,
      customer.id
    );
    const reviewFromProvider = reviewByAuthorId(
      currentTransaction,
      provider.id
    );

    const deeplLocale = getDeeplLocale(locale);

    return (
      (reviewFromCustomer?.attributes?.originalLocale &&
        reviewFromCustomer.attributes.originalLocale !== deeplLocale) ||
      (reviewFromProvider?.attributes?.originalLocale &&
        reviewFromProvider.attributes.originalLocale !== deeplLocale)
    );
  };

  return (
    <ul className={classes}>
      {hasOlderMessages ? (
        <li className={css.showOlderWrapper} key="show-older-messages">
          <InlineTextButton
            className={css.showOlderButton}
            onClick={onShowOlderMessages}
          >
            <FormattedMessage id="ActivityFeed.showOlderMessages" />
          </InlineTextButton>
        </li>
      ) : null}
      {items.map(item => {
        if (isMessage(item)) {
          return messageListItem(item);
        }

        return transitionListItem(item);
      })}

      {showTranslateToggleButton() && (
        <ToggleTranslationButton
          active={translationActive}
          onClick={onToggleTranslation}
          loading={translationLoading}
        />
      )}
    </ul>
  );
};

ActivityFeedComponent.defaultProps = {
  rootClassName: null,
  className: null,
};

ActivityFeedComponent.propTypes = {
  rootClassName: string,
  className: string,

  currentUser: propTypes.currentUser,
  transaction: propTypes.transaction,
  messages: arrayOf(propTypes.message),
  hasOlderMessages: bool.isRequired,
  onOpenReviewModal: func.isRequired,
  onShowOlderMessages: func.isRequired,
  fetchMessagesInProgress: bool.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const ActivityFeed = injectIntl(ActivityFeedComponent);

export default ActivityFeed;
