import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import styled from 'styled-components/macro';
import { ReactComponent as GooglePlayBlack } from './assets/GooglePlayBlack.svg';
import { ReactComponent as AppStoreBlack } from './assets/AppStoreBlack.svg';
const ButtonWrapper = styled.a `
  cursor: pointer;
`;
export var StoreType;
(function (StoreType) {
    StoreType["GooglePlay"] = "googlePlay";
    StoreType["AppStore"] = "appStore";
})(StoreType || (StoreType = {}));
const AppStoreButton = ({ store }) => {
    if (store === StoreType.GooglePlay) {
        return (_jsx(ButtonWrapper, Object.assign({ href: "https://play.google.com/store/apps/details?id=eu.mycabin.mycabin.preview&referrer=utm_source%3Dhomepage", target: "_blank", className: store }, { children: _jsx(GooglePlayBlack, {}) })));
    }
    if (store === StoreType.AppStore) {
        return (_jsx(ButtonWrapper, Object.assign({ href: "https://apps.apple.com/app/apple-store/id1622555256?pt=124203961&ct=homepage&mt=8", target: "_blank", className: store }, { children: _jsx(AppStoreBlack, {}) })));
    }
    return null;
};
export default AppStoreButton;
