var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { useIntl } from 'react-intl';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { FileOpener } from '@capacitor-community/file-opener';
import { GIFT_CARD_SALE_TYPE } from '@nomady/shared/gift-card/config';
import { apiHost } from '@nomady/shared/utils/localized-domains';
import { formatMoney } from '@nomady/shared/utils/currency';
import { types as sdkTypes } from '@nomady/shared/utils/sdkLoader';
import Text, { TextVariant } from '../../Text/Text';
import { ReactComponent as DeliveryIcon } from '../../../assets/svg/delivery.svg';
import Button, { ButtonSizeVariant } from '../../Button/Button';
import DetailElement from '../../BookingBreakdown/partials/DetailElement';
import Total from '../../Total/Total';
import blobToBase64 from '../../../util/blobToBase64';
import { isApp } from '../../../util/deviceContext';
const { Money } = sdkTypes;
const GiftCardWrapper = styled.div `
  border-top: 1px solid ${props => props.theme.color.fog};
  padding-top: 20px;
  margin-bottom: 20px;
`;
const GiftCardPng = styled.img `
  max-width: 100%;
  height: auto;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
`;
const GiftCardActions = styled.p `
  display: grid;
  grid-auto-flow: column;
  column-gap: 15px;
  width: min-content;
`;
const CodeGeneratedWrapper = styled.div `
  max-width: 580px;
  margin: 0 auto;
`;
const DeliveryWrapper = styled.div `
  padding: 45px 0;
  border-top: 1px solid ${props => props.theme.color.fog};
  display: flex;
  align-items: center;
  flex-direction: column;
`;
const Invoice = styled.div `
  border-top: 1px solid ${props => props.theme.color.fog};
  padding-top: 43px;
`;
const Title = styled(Text) `
  padding-top: 128px;
  padding-bottom: 70px;

  @media ${props => props.theme.device.tabletL} {
    padding-top: 64px;
  }

  @media ${props => props.theme.device.desktop} {
    padding-top: 128px;
  }
`;
const GiftCardText = styled(Text) `
  padding-bottom: 10px;
`;
const GiftCardOrderConfirmation = ({ orderId, quantity, value, type, currency, }) => {
    const intl = useIntl();
    const [downloadInProgress, setDownloadInProgress] = useState(false);
    const downloadGiftCardApp = (index) => __awaiter(void 0, void 0, void 0, function* () {
        setDownloadInProgress(true);
        const response = yield fetch(`${apiHost()}/api/gift-card/code.pdf?index=${index}&download=true`, {
            method: 'GET',
            credentials: 'include',
        });
        const blob = yield response.blob();
        const base64 = yield blobToBase64(blob);
        const writeFileResult = yield Filesystem.writeFile({
            path: 'gift-card-code.pdf',
            data: base64,
            directory: Directory.Documents,
            recursive: true,
        });
        setDownloadInProgress(false);
        FileOpener.open({ filePath: writeFileResult.uri });
    });
    return (_jsxs(CodeGeneratedWrapper, { children: [_jsx(Title, Object.assign({ variant: TextVariant.H30, as: "h1" }, { children: intl.formatMessage({
                    id: [
                        GIFT_CARD_SALE_TYPE.DIGITAL_GIFT_CARD,
                        GIFT_CARD_SALE_TYPE.PHYSICAL_GIFT_CARD,
                    ].includes(type)
                        ? 'GiftCardOrderConfirmation.titleCodesGenerated'
                        : 'GiftCardOrderConfirmation.titleOrder',
                }, { orderId }) })), type === GIFT_CARD_SALE_TYPE.PHYSICAL_GIFT_CARD && (_jsxs(DeliveryWrapper, { children: [_jsx(DeliveryIcon, {}), _jsx(GiftCardText, Object.assign({ variant: TextVariant.H6, as: "p" }, { children: intl.formatMessage({
                            id: 'GiftCardOrderConfirmation.deliveryTime',
                        }, { quantity }) }))] })), type === GIFT_CARD_SALE_TYPE.DIGITAL_GIFT_CARD &&
                [...Array(quantity).keys()].map(index => (_jsxs(GiftCardWrapper, { children: [_jsx(GiftCardPng, { src: `${apiHost()}/api/gift-card/code.png?index=${index}`, alt: intl.formatMessage({
                                id: 'GiftCardOrderConfirmation.imageAlt',
                            }) }), _jsx(GiftCardActions, { children: isApp ? (_jsx(Button, { onClick: () => downloadGiftCardApp(index), label: intl.formatMessage({
                                    id: 'GiftCardOrderConfirmation.downloadPdf',
                                }), sizeVariant: ButtonSizeVariant.Small, inProgress: downloadInProgress })) : (_jsxs(_Fragment, { children: [_jsx(Button, { externalLink: `${apiHost()}/api/gift-card/code.pdf?index=${index}`, target: "_blank", rel: "noopener noreferrer", label: intl.formatMessage({
                                            id: 'GiftCardOrderConfirmation.openPdf',
                                        }), sizeVariant: ButtonSizeVariant.Small }), '   ', _jsx(Button, { externalLink: `${apiHost()}/api/gift-card/code.pdf?index=${index}&download=true`, label: intl.formatMessage({
                                            id: 'GiftCardOrderConfirmation.downloadPdf',
                                        }), sizeVariant: ButtonSizeVariant.Small })] })) })] }, `giftcard-${index}`))), _jsxs(Invoice, { children: [_jsx(Text, Object.assign({ variant: TextVariant.B30, as: "h2" }, { children: intl.formatMessage({
                            id: 'GiftCardOrderConfirmation.billTitle',
                        }) })), _jsx(DetailElement, { leftText: `${quantity}× ${intl.formatMessage({
                            id: `GiftCardOrderConfirmation.${type}.title`,
                        })} ${currency} ${value / 100} `, rightText: formatMoney(intl, new Money(quantity * value, currency)) }), _jsx(Total, { currency: currency, totalPrice: (quantity * value) / 100 }), _jsx(Text, Object.assign({ variant: TextVariant.H10 }, { children: intl.formatMessage({
                            id: 'GiftCardOrderConfirmation.creditCard',
                        }) }))] })] }));
};
export default GiftCardOrderConfirmation;
