var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components/macro';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import Button, { ButtonColorVariant, ButtonSizeVariant, } from '../../styled-components/Button/Button';
import { getListingPhotosDefaultValues, getListingPhotosSchema, } from '@nomady/shared/schemas/listing/photos';
import FormErrorMessage from '../../styled-components/FormErrorMessage/FormErrorMessage';
import { FormSection } from '../EditListingDescriptionForm/EditListingDescriptionForm';
import { Description } from '../../styled-components/Input/Input';
import InputPictures from '../../styled-components/InputPictures/InputPictures';
const Form = styled.form ``;
const FormRow = styled.div `
  margin-bottom: 10px;
`;
const EditListingPhotosForm = ({ updateListingInProgress, updateListingError, onSubmit, listing, submitButtonLabel, onImageUploaded, onFormDirty, }) => {
    const intl = useIntl();
    const [defaultValuesLoaded, setDefaultValuesLoaded] = useState(false);
    const listingLoaded = !!(listing === null || listing === void 0 ? void 0 : listing.id);
    const defaultValues = getListingPhotosDefaultValues(listing);
    const { handleSubmit, control, reset, trigger, getValues, formState: { errors, isDirty, isSubmitSuccessful }, } = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: zodResolver(getListingPhotosSchema(intl)),
    });
    useEffect(() => {
        reset(defaultValues);
        setDefaultValuesLoaded(true);
    }, [listingLoaded]);
    const validateOnRender = () => {
        defaultValuesLoaded && trigger();
    };
    useEffect(validateOnRender, [trigger, defaultValuesLoaded]);
    useEffect(() => onFormDirty(isDirty), [isDirty, onFormDirty]);
    useEffect(() => {
        if (isSubmitSuccessful) {
            const values = getValues();
            reset(values);
        }
    }, [isSubmitSuccessful, getValues, reset]);
    const onSuccess = (data) => __awaiter(void 0, void 0, void 0, function* () {
        const imageAltTexts = {};
        data.photos
            .filter(photo => !!photo.attributes.description)
            .forEach(photo => {
            imageAltTexts[`image_caption_${photo.id.uuid}`] = photo.attributes
                .description;
        });
        const params = {
            images: data.photos.map(photo => photo.id),
            publicData: {
                localizedPublicData: { imageAltTexts },
            },
        };
        onSubmit(params);
    });
    return (_jsxs(Form, Object.assign({ onSubmit: handleSubmit(onSuccess) }, { children: [_jsxs(FormSection, { children: [_jsx(FormRow, { children: _jsx(Description, { text: intl.formatMessage({
                                id: 'EditListingPhotosForm.addImagesTip',
                            }) }) }), _jsx(FormRow, { children: _jsx(InputPictures, { name: "photos", control: control, onImageUploaded: onImageUploaded, error: errors.photos }) })] }), _jsx(Button, { label: submitButtonLabel, colorVariant: ButtonColorVariant.Dark, sizeVariant: ButtonSizeVariant.Small, type: "submit", inProgress: updateListingInProgress }), updateListingError && (_jsx(FormErrorMessage, { id: "EditListingDescriptionForm.updateFailed" }))] })));
};
export default EditListingPhotosForm;
