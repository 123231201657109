var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components/macro';
import { useForm, } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { getListingLocationDefaultValues, getListingLocationSchema, } from '@nomady/shared/schemas/listing/location';
import Button, { ButtonColorVariant, ButtonSizeVariant, } from '../../styled-components/Button/Button';
import InputTextArea from '../../styled-components/InputTextArea/InputTextArea';
import FormErrorMessage from '../../styled-components/FormErrorMessage/FormErrorMessage';
import InputPhone from '../../styled-components/InputPhone/InputPhone';
import InputGeolocation from '../../styled-components/InputGeolocation/InputGeolocation';
import InputListingMarker from '../../styled-components/InputListingMarker/InputListingMarker';
const Form = styled.form ``;
const FormRow = styled.div `
  margin-bottom: 10px;
`;
const EditListingLocationForm = ({ updateListingInProgress, updateListingError, onSubmit, onFormDirty, listing, submitButtonLabel, }) => {
    var _a, _b;
    const intl = useIntl();
    const [defaultValuesLoaded, setDefaultValuesLoaded] = useState(false);
    const listingLoaded = !!(listing === null || listing === void 0 ? void 0 : listing.id);
    const { geolocation } = (_a = listing === null || listing === void 0 ? void 0 : listing.attributes) !== null && _a !== void 0 ? _a : {};
    const defaultValues = getListingLocationDefaultValues(listing === null || listing === void 0 ? void 0 : listing.attributes);
    const { register, handleSubmit, control, reset, trigger, watch, getValues, formState: { errors, isDirty, isSubmitSuccessful }, } = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: zodResolver(getListingLocationSchema(intl)),
    });
    useEffect(() => {
        reset(defaultValues);
        setDefaultValuesLoaded(true);
    }, [listingLoaded]);
    const validateOnRender = () => {
        defaultValuesLoaded && trigger();
    };
    useEffect(validateOnRender, [trigger, defaultValuesLoaded]);
    useEffect(() => onFormDirty(isDirty), [isDirty, onFormDirty]);
    useEffect(() => {
        if (isSubmitSuccessful) {
            const values = getValues();
            reset(values);
        }
    }, [isSubmitSuccessful, getValues, reset]);
    const onSuccess = (data) => __awaiter(void 0, void 0, void 0, function* () {
        var _c;
        const _d = (_c = data.location) !== null && _c !== void 0 ? _c : {}, { location: geolocation } = _d, location = __rest(_d, ["location"]);
        const params = {
            publicData: {
                markers: data.markers,
                nearbyPublicTransportStops: undefined,
                nearbyPublicTransportStopsCalculatedAt: undefined,
            },
            protectedData: {
                afterBookingPhone: data.afterBookingPhone,
                localizedProtectedData: {
                    afterBookingInfo: data.afterBookingInfo,
                    afterBookingRoute: data.afterBookingRoute,
                },
            },
        };
        if (geolocation) {
            params.geolocation = geolocation;
        }
        if (location) {
            params.publicData = Object.assign(Object.assign({}, params.publicData), { location });
        }
        onSubmit(params);
    });
    const afterBookingRoute = register('afterBookingRoute');
    const afterBookingInfo = register('afterBookingInfo');
    const markers = register('markers');
    const locationValue = watch('location');
    return (_jsxs(Form, Object.assign({ onSubmit: handleSubmit(onSuccess) }, { children: [_jsx(FormRow, { children: _jsx(InputGeolocation, { label: intl.formatMessage({
                        id: 'EditListingLocationForm.address',
                    }), id: "location", name: "location", placeholder: intl.formatMessage({
                        id: 'EditListingLocationForm.addressPlaceholder',
                    }), description: intl.formatMessage({
                        id: 'EditListingLocationForm.addressTooltip',
                    }), control: control }) }), _jsx(FormRow, { children: _jsx(InputPhone, { label: intl.formatMessage({
                        id: 'EditListingLocationForm.afterBookingPhone',
                    }), id: "afterBookingPhone", name: "afterBookingPhone", control: control, description: intl.formatMessage({
                        id: 'EditListingLocationForm.afterBookingPhoneTooltip',
                    }), placeholder: intl.formatMessage({
                        id: 'Input.placeholder.phoneNumber',
                    }) }) }), _jsx(FormRow, { children: _jsx(InputTextArea, Object.assign({ label: intl.formatMessage({
                        id: 'EditListingLocationForm.afterBookingRoute',
                    }), placeholder: intl.formatMessage({
                        id: 'EditListingLocationForm.afterBookingRoutePlaceholder',
                    }), description: intl.formatMessage({
                        id: 'EditListingLocationForm.afterBookingRouteTooltip',
                    }), id: "afterBookingRoute", control: control }, afterBookingRoute, { error: errors === null || errors === void 0 ? void 0 : errors.afterBookingRoute })) }), _jsx(FormRow, { children: _jsx(InputTextArea, Object.assign({ label: intl.formatMessage({
                        id: 'EditListingLocationForm.afterBookingInfo',
                    }), description: intl.formatMessage({
                        id: 'EditListingLocationForm.afterBookingInfoTooltip',
                    }), id: "afterBookingInfo", control: control }, afterBookingInfo, { error: errors === null || errors === void 0 ? void 0 : errors.afterBookingInfo })) }), (locationValue === null || locationValue === void 0 ? void 0 : locationValue.address) && (_jsx(FormRow, { children: _jsx(InputListingMarker, Object.assign({ label: intl.formatMessage({
                        id: 'EditListingLocationForm.mapMarkerSelect',
                    }), initialCenter: (_b = locationValue.location) !== null && _b !== void 0 ? _b : geolocation, description: intl.formatMessage({
                        id: 'EditListingLocationForm.mapMarkerSelectTooltip',
                    }), id: "markers", control: control }, markers, { error: errors === null || errors === void 0 ? void 0 : errors.markers })) })), _jsx(Button, { label: submitButtonLabel, colorVariant: ButtonColorVariant.Dark, sizeVariant: ButtonSizeVariant.Small, type: "submit", inProgress: updateListingInProgress }), updateListingError && (_jsx(FormErrorMessage, { id: "EditListingDescriptionForm.updateFailed" }))] })));
};
export default EditListingLocationForm;
