import moment from 'moment';
const getWeekend = () => {
    const nextFriday = moment().day(5).toDate();
    const nextSunday = moment().day(7).toDate();
    return {
        startDate: nextFriday,
        endDate: nextSunday,
    };
};
export default getWeekend;
