import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { injectIntl } from 'react-intl';
import styled from 'styled-components/macro';
import Markdown from 'react-showdown';
import Icon, { IconVariant } from '../Icon/Icon';
import Text, { TextVariant } from '../Text/Text';
const Wrapper = styled.section `
  width: 100%;
  display: flex;
  flex-direction: column;

  @media ${props => props.theme.device.tabletL} {
    flex-direction: row;
    align-items: stretch;
  }
`;
const Content = styled.div `
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 24px;
  border-bottom: 1px solid ${props => props.theme.color.stone};

  @media ${props => props.theme.device.tabletL} {
    margin-left: 30px;
  }
`;
const LinkWrapper = styled.a `
  display: flex;
  flex-direction: row;
  text-decoration: none;
  align-items: center;
  color: inherit;

  @media ${props => props.theme.device.tablet} {
    margin-left: 16px;
  }
`;
const Image = styled.img `
  width: 100%;
  align-self: flex-start;
  margin-bottom: 30px;

  @media ${props => props.theme.device.tabletL} {
    width: 330px;
    margin-bottom: initial;
  }

  @media ${props => props.theme.device.desktop} {
    width: 450px;
  }

  @media ${props => props.theme.device.desktopL} {
    width: 540;
  }
`;
const Title = styled(Text) `
  margin-bottom: 8px;
`;
const Date = styled(Text) `
  margin-bottom: 25px;
`;
const Description = styled(Text) `
  margin-bottom: 25px;
`;
const PaddedIcon = styled.div `
  padding-right: 15px;
`;
const Publication = ({ image, link, download, title, date, description, intl, }) => {
    var _a, _b, _c, _d, _e, _f, _g;
    return (_jsxs(Wrapper, { children: [_jsx(Image, { src: ((_b = (_a = image === null || image === void 0 ? void 0 : image.data) === null || _a === void 0 ? void 0 : _a.attributes) === null || _b === void 0 ? void 0 : _b.url) || '', alt: (_c = image === null || image === void 0 ? void 0 : image.data) === null || _c === void 0 ? void 0 : _c.attributes.alternativeText }), _jsxs(Content, { children: [_jsx(Title, Object.assign({ variant: TextVariant.H6, as: "h4" }, { children: title })), _jsx(Date, Object.assign({ variant: TextVariant.H7, as: "span" }, { children: intl.formatDate(date, {
                            day: '2-digit',
                            month: 'long',
                            year: 'numeric',
                        }) })), _jsx(Description, { children: _jsx(Markdown, { markdown: description || '' }) }), _jsxs(LinkWrapper, Object.assign({ href: link || ((_e = (_d = download === null || download === void 0 ? void 0 : download.data) === null || _d === void 0 ? void 0 : _d.attributes) === null || _e === void 0 ? void 0 : _e.url), target: "_blank", rel: "noopener noreferrer" }, { children: [_jsx(PaddedIcon, { children: _jsx(Icon, { variant: download ? IconVariant.Download : IconVariant.NewTab }) }), _jsx(Text, Object.assign({ variant: TextVariant.H10 }, { children: ((_g = (_f = download === null || download === void 0 ? void 0 : download.data) === null || _f === void 0 ? void 0 : _f.attributes) === null || _g === void 0 ? void 0 : _g.url)
                                    ? intl.formatMessage({ id: 'Publication.downloadPDF' })
                                    : intl.formatMessage({ id: 'Publication.newTab' }) }))] }))] })] }));
};
export default injectIntl(Publication);
