import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { disableScrollSelector } from '../../slices/UISlice';
const usePreventBodyScroll = () => {
    const disableScroll = useSelector(disableScrollSelector);
    const [allowTouchScroll, setAllowTouchScroll] = useState(undefined);
    const startY = useRef(null);
    useEffect(() => {
        if (!disableScroll) {
            return;
        }
        const isScrollable = (element) => {
            const overflowY = window.getComputedStyle(element).overflowY;
            const isScrollableY = overflowY !== 'hidden' && overflowY !== 'visible';
            const canScrollY = element.scrollHeight > element.clientHeight;
            return isScrollableY && canScrollY;
        };
        const handleWheel = (event) => {
            let target = event.target;
            while (target && target !== document.body) {
                if (isScrollable(target)) {
                    const atTop = target.scrollTop === 0;
                    const atBottom = target.scrollTop + target.clientHeight === target.scrollHeight;
                    const scrollingDown = event.deltaY > 0;
                    if ((!scrollingDown && !atTop) || (scrollingDown && !atBottom)) {
                        return;
                    }
                }
                target = target.parentElement;
            }
            event.preventDefault();
        };
        const handleTouchStart = (event) => {
            setAllowTouchScroll(undefined);
            startY.current = event.touches[0].clientY;
        };
        const handleTouchMove = (event) => {
            if (allowTouchScroll === false) {
                event.preventDefault();
                return;
            }
            if (allowTouchScroll === true) {
                return;
            }
            let target = event.target;
            const touch = event.touches[0];
            if (startY.current === null) {
                startY.current = touch.clientY;
                return;
            }
            const deltaY = startY.current - touch.clientY;
            while (target && target !== document.body) {
                if (isScrollable(target)) {
                    const atTop = target.scrollTop === 0;
                    const atBottom = target.scrollTop + target.clientHeight === target.scrollHeight;
                    const scrollingDown = deltaY > 0;
                    if ((!scrollingDown && !atTop) || (scrollingDown && !atBottom)) {
                        setAllowTouchScroll(true);
                        return;
                    }
                    else {
                        setAllowTouchScroll(false);
                    }
                }
                target = target.parentElement;
            }
            setAllowTouchScroll(false);
            event.preventDefault();
        };
        document.body.addEventListener('wheel', handleWheel, { passive: false });
        document.body.addEventListener('touchstart', handleTouchStart, {
            passive: false,
        });
        document.body.addEventListener('touchmove', handleTouchMove, {
            passive: false,
        });
        return () => {
            document.body.removeEventListener('wheel', handleWheel);
            document.body.removeEventListener('touchstart', handleTouchStart);
            document.body.removeEventListener('touchmove', handleTouchMove);
        };
    }, [disableScroll, allowTouchScroll]);
    return null;
};
export default usePreventBodyScroll;
