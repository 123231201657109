var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { storableError } from '../../util/errors';
const initialState = {
    initialEmail: null,
    submittedEmail: null,
    recoveryError: null,
    recoveryInProgress: false,
    passwordRequested: false,
};
export const PasswordRecoveryPageSlice = createSlice({
    name: 'app/PasswordRecoveryPage',
    initialState,
    reducers: {
        passwordRecoveryRequest: state => {
            state.submittedEmail = null;
            state.recoveryInProgress = true;
            state.recoveryError = null;
        },
        passwordRecoverySuccess: (state, action) => {
            state.submittedEmail = action.payload;
            state.initialEmail = action.payload;
            state.recoveryInProgress = false;
            state.recoveryError = null;
            state.passwordRequested = true;
        },
        passwordRecoveryError: (state, action) => {
            state.recoveryInProgress = false;
            state.recoveryError = action.payload.error;
            state.initialEmail = action.payload.email;
        },
        retypeEmail: state => {
            state.initialEmail = state.submittedEmail;
            state.submittedEmail = null;
            state.passwordRequested = false;
        },
        clearPasswordRecoveryError: state => {
            state.recoveryError = null;
        },
    },
});
export const { passwordRecoveryRequest, passwordRecoverySuccess, passwordRecoveryError, retypeEmail, clearPasswordRecoveryError, } = PasswordRecoveryPageSlice.actions;
export const recoverPassword = (email) => (dispatch, _, sdk) => __awaiter(void 0, void 0, void 0, function* () {
    dispatch(passwordRecoveryRequest());
    try {
        yield sdk.passwordReset.request({ email });
        dispatch(passwordRecoverySuccess(email));
    }
    catch (error) {
        dispatch(passwordRecoveryError({ error: storableError(error), email }));
    }
});
export default PasswordRecoveryPageSlice.reducer;
export const passwordRecoveryInProgressSelector = (state) => state.PasswordRecoveryPage.recoveryInProgress;
