export var ListingFavoritesListCoverPicture;
(function (ListingFavoritesListCoverPicture) {
    ListingFavoritesListCoverPicture["Cover1"] = "cover-1";
    ListingFavoritesListCoverPicture["Cover2"] = "cover-2";
    ListingFavoritesListCoverPicture["Cover3"] = "cover-3";
    ListingFavoritesListCoverPicture["Cover4"] = "cover-4";
    ListingFavoritesListCoverPicture["Cover5"] = "cover-5";
    ListingFavoritesListCoverPicture["Cover6"] = "cover-6";
    ListingFavoritesListCoverPicture["Cover7"] = "cover-7";
    ListingFavoritesListCoverPicture["Cover8"] = "cover-8";
    ListingFavoritesListCoverPicture["Cover9"] = "cover-9";
    ListingFavoritesListCoverPicture["Cover10"] = "cover-10";
    ListingFavoritesListCoverPicture["Cover11"] = "cover-11";
    ListingFavoritesListCoverPicture["CoverMyCabin"] = "cover-mycabin";
})(ListingFavoritesListCoverPicture || (ListingFavoritesListCoverPicture = {}));
