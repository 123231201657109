import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { forwardRef } from 'react';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import styled from 'styled-components/macro';
import { useIntl } from 'react-intl';
import { formatCurrencyMajorUnit } from '@nomady/shared/utils/currency';
import { CampStyle } from '@nomady/shared/types';
import { createSlug } from '@nomady/shared/utils/urlHelpers';
import Page from '@nomady/shared/routeConfiguration/Page';
import { NamedLink } from '../../components';
import Icon, { IconSizeVariant, IconVariant } from '../Icon/Icon';
import Text, { TextVariant } from '../Text/Text';
import ImageCarousel from '../ImageCarousel/ImageCarousel';
import { ColorVariant } from '../../theme';
import campStyleIcons from '../../campStyleIcons';
import ListingCardBadge from './components/ListingCardBadge';
import LikeButton from './components/LikeButton';
import getLocationInformation from '../../util/addressHelpers';
import ReviewRating from './components/ReviewRating';
const Wrapper = styled.section `
  width: 100%;
  max-width: 100%;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  border-width: 2px;
  overflow: hidden;
  cursor: pointer;

  ${props => props.highlighted &&
    `box-shadow: 0 0 0 2px ${props.theme.color.primaryFirGreen};`}

  ${props => props.active &&
    `background-color: ${props.theme.color.basicPearlWhiteTypeWhite};`}

  &:hover {
    background-color: ${props => props.theme.color.basicPearlWhiteTypeWhite};
  }
`;
const Head = styled.div `
  position: relative;
  height: 168px;
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
`;
const BottomLeftCornerOfImage = styled.div `
  position: absolute;
  bottom: 8px;
  left: 8px;
  display: flex;
  gap: 2px;
`;
const BottomRightCornerOfImage = styled.div `
  position: absolute;
  bottom: 8px;
  right: 8px;
`;
const TopRightCornerOfImage = styled.div `
  position: absolute;
  top: 8px;
  right: 8px;
  display: flex;
  flex-direction: column;
  align-items: end;
  gap: 8px;
`;
const OnlyOnMediumVariant = styled.div `
  display: none;

  @media ${props => props.theme.device.tablet} {
    display: block;
  }
`;
const OnlyOnSmallVariant = styled.div `
  display: block;

  @media ${props => props.theme.device.tablet} {
    display: none;
  }
`;
const FavoriteButton = styled.div `
  position: absolute;
  top: 8px;
  left: 8px;
`;
const FacilityIconsWrapper = styled.div `
  display: flex;
  gap: 2px;
  justify-content: flex-end;
`;
const FacilityIcon = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.color.basicPearlWhiteTypeWhite};
  padding: 0 2px;
  border-radius: 4px;
`;
const Content = styled.div `
  padding: 4px;
  display: flex;
  flex-direction: column;
  gap: 2px;

  @media ${props => props.theme.device.tablet} {
    padding: 8px;
  }
`;
const IconRow = styled.div `
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const CampStyles = styled.div `
  display: flex;
  flex-direction: row;
  gap: 2px;
`;
const Title = styled(Text) `
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
const Location = styled.div `
  display: flex;
  flex-direction: row;
  gap: 2px;
  justify-content: space-between;
`;
const Address = styled.div `
  display: flex;
  flex-direction: row;
  gap: 2px;
  flex-grow: 1;
  flex-shrink: 1;
  overflow: hidden;
`;
const Distance = styled.div `
  display: flex;
  flex-direction: row;
  gap: 2px;
  flex-shrink: 0;
  white-space: nowrap;
`;
const LocationText = styled(Text) `
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 144px;
`;
const DistanceText = styled(Text) `
  display: block;
  white-space: nowrap;
`;
const FooterRow = styled.div `
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
const PriceWrapper = styled.div `
  display: flex;
  flex-direction: row;
  gap: 2px;
`;
const PriceBeforeDiscountText = styled(Text) `
  text-decoration: line-through;
`;
const ListingCard = forwardRef(({ id, title, campStyles, verified, instantBooking, topHost, priceBeforeDiscount, price, images, rating, numberOfRatings, hideLikeButton = false, titleComponent = 'h5', distance, addressComponents, toilet, power, fireplace, active = false, highlighted = false, onPointerEnter, onPointerLeave, }, ref) => {
    const intl = useIntl();
    const facilityIcons = (_jsxs(FacilityIconsWrapper, { children: [toilet && (_jsx(FacilityIcon, { children: _jsx(Icon, { variant: IconVariant.WC, sizeVariant: IconSizeVariant.Tiny, colorVariant: ColorVariant.BasicCaveBlack }) })), power && (_jsx(FacilityIcon, { children: _jsx(Icon, { variant: IconVariant.Electricity_NEW, sizeVariant: IconSizeVariant.Tiny, colorVariant: ColorVariant.BasicCaveBlack }) })), fireplace && (_jsx(FacilityIcon, { children: _jsx(Icon, { variant: IconVariant.Fireplace, sizeVariant: IconSizeVariant.Tiny, colorVariant: ColorVariant.BasicCaveBlack }) }))] }));
    return (_jsx(Wrapper, Object.assign({ ref: ref, active: active, highlighted: highlighted, onPointerEnter: onPointerEnter, onPointerLeave: onPointerLeave }, { children: _jsxs(NamedLink, Object.assign({ name: Page.Listing, params: {
                id: id.uuid,
                slug: createSlug(title),
            } }, { children: [_jsxs(Head, { children: [_jsx(LazyLoadComponent, { children: _jsx(ImageCarousel, { images: images, listingId: id.uuid, listingTitle: title, hasFacilityIcons: toilet || power || fireplace }) }), _jsx(OnlyOnMediumVariant, { children: _jsxs(TopRightCornerOfImage, { children: [instantBooking && (_jsx(ListingCardBadge, { iconVariant: IconVariant.Directbooking, labelId: "ListingCard.instantBooking", backgroundColorVariant: ColorVariant.BasicRavenBlack })), topHost && (_jsx(ListingCardBadge, { iconVariant: IconVariant.Tophost, labelId: "ListingCard.topHost", backgroundColorVariant: ColorVariant.BasicPearlWhiteTypeWhite }))] }) }), _jsxs(BottomLeftCornerOfImage, { children: [verified && (_jsx(ListingCardBadge, { iconVariant: IconVariant.Check_Bold, labelId: "ListingCard.verified", backgroundColorVariant: ColorVariant.PrimaryMossGreen })), instantBooking && (_jsx(OnlyOnSmallVariant, { children: _jsx(ListingCardBadge, { iconVariant: IconVariant.Directbooking, labelId: "ListingCard.instantBooking", backgroundColorVariant: ColorVariant.BasicRavenBlack }) })), topHost && (_jsx(OnlyOnSmallVariant, { children: _jsx(ListingCardBadge, { iconVariant: IconVariant.Tophost, labelId: "ListingCard.topHost", backgroundColorVariant: ColorVariant.BasicPearlWhiteTypeWhite }) }))] }), _jsx(OnlyOnSmallVariant, { children: _jsx(BottomRightCornerOfImage, { children: facilityIcons }) }), !hideLikeButton && (_jsx(FavoriteButton, { children: _jsx(LikeButton, { listingId: id }) }))] }), _jsxs(Content, { children: [_jsxs(IconRow, { children: [campStyles && (_jsx(CampStyles, { children: [
                                        CampStyle.hut,
                                        CampStyle.glamping,
                                        CampStyle.shelter,
                                        CampStyle.tent,
                                        CampStyle.carWithRooftopTent,
                                        CampStyle.smallVehicle,
                                        CampStyle.mediumVehicle,
                                        CampStyle.largeVehicle,
                                        CampStyle.caravan,
                                    ]
                                        .filter(element => campStyles.includes(element))
                                        .map(campStyle => (_jsx(Icon, { variant: campStyleIcons[campStyle], sizeVariant: IconSizeVariant.Default }, `icon-${campStyle}`))) })), _jsx(OnlyOnMediumVariant, { children: facilityIcons })] }), _jsx(Title, Object.assign({ as: titleComponent, variant: TextVariant.L1SB, colorVariant: ColorVariant.BasicCaveBlack }, { children: title })), _jsxs(Location, { children: [_jsxs(Address, { children: [distance === undefined && (_jsx(Icon, { variant: IconVariant.LocationPin, sizeVariant: IconSizeVariant.Tiny })), _jsx(LocationText, Object.assign({ variant: TextVariant.L4SB, colorVariant: ColorVariant.BasicCaveBlack }, { children: getLocationInformation(addressComponents) }))] }), distance !== undefined && (_jsxs(Distance, { children: [_jsx(Icon, { variant: IconVariant.LocationPin, sizeVariant: IconSizeVariant.Tiny }), _jsx(DistanceText, Object.assign({ variant: TextVariant.L4SB, colorVariant: ColorVariant.BasicCaveBlack }, { children: intl.formatMessage({ id: 'ListingCard.distanceInKm' }, { distanceInKm: `~${Math.round(distance)}` }) }))] }))] }), _jsxs(FooterRow, { children: [_jsx(ReviewRating, { rating: rating, numberOfRatings: numberOfRatings }), _jsxs(PriceWrapper, { children: [priceBeforeDiscount && (_jsx(PriceBeforeDiscountText, Object.assign({ variant: TextVariant.L4SB, colorVariant: ColorVariant.FunctionFogGreyInactive }, { children: formatCurrencyMajorUnit(intl, priceBeforeDiscount) }))), _jsx(Text, Object.assign({ variant: TextVariant.L4SB, colorVariant: ColorVariant.BasicCaveBlack }, { children: formatCurrencyMajorUnit(intl, price) }))] })] })] })] })) })));
});
ListingCard.displayName = 'ListingCard';
export default ListingCard;
