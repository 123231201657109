import { jsx as _jsx } from "react/jsx-runtime";
import { getTransactionStatus } from '@nomady/shared/utils/transaction';
import React from 'react';
import JourneyCard from './JourneyCard';
const ConnectedJourneyCard = ({ transaction, }) => {
    var _a, _b, _c, _d;
    const { id } = transaction;
    const { total = -1, currency = 'N/A' } = transaction.attributes.protectedData.payins || {};
    const { lastTransitionedAt } = transaction.attributes;
    const listingName = ((_a = transaction.listing) === null || _a === void 0 ? void 0 : _a.attributes.title) || '';
    const status = getTransactionStatus(transaction);
    return (_jsx(JourneyCard, { id: id, listingName: listingName, provider: transaction.provider, arrivalDate: (_b = transaction.booking) === null || _b === void 0 ? void 0 : _b.attributes.displayStart, departureDate: (_c = transaction.booking) === null || _c === void 0 ? void 0 : _c.attributes.displayEnd, status: status, payinTotal: total, currency: currency, lastTransitionedAt: lastTransitionedAt, listingImages: (_d = transaction.listing) === null || _d === void 0 ? void 0 : _d.images }));
};
export default ConnectedJourneyCard;
