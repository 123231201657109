import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components/macro';
const StyledBackDropDimmed = styled.div `
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: ${props => (props.shown ? 0.35 : 0)};
  ${props => props.theme.priority.xxxhigh};

  transition: opacity 200ms ease-in-out;
  background-color: ${props => props.theme.color.basicRavenBlack};
`;
const BackDropDimmed = ({ shown, onClick }) => {
    const [render, setRender] = useState(shown);
    const [delayedShown, setDelayedShown] = useState(shown);
    const startY = useRef(null);
    const totalDeltaY = useRef(0);
    useEffect(() => {
        if (shown) {
            setRender(true);
            setTimeout(() => setDelayedShown(true), 10); // Small delay to ensure transition
        }
        else {
            setDelayedShown(false);
        }
    }, [shown]);
    useEffect(() => {
        if (shown) {
            startY.current = null;
            totalDeltaY.current = 0;
        }
    }, [shown]);
    const onAnimationEnd = () => {
        if (!shown) {
            setRender(false);
        }
    };
    const handleTouchMove = (event) => {
        const clientY = event.changedTouches[0].clientY;
        if (startY.current === null) {
            startY.current = clientY;
        }
        else {
            const deltaY = Math.abs(startY.current - clientY);
            if (deltaY >= 30) {
                onClick === null || onClick === void 0 ? void 0 : onClick();
            }
        }
        event.stopPropagation();
        event.preventDefault();
    };
    const handleWheel = (event) => {
        totalDeltaY.current += Math.abs(event.deltaY);
        if (totalDeltaY.current >= 30) {
            onClick === null || onClick === void 0 ? void 0 : onClick();
        }
        event.stopPropagation();
        event.preventDefault();
    };
    if (!render) {
        return null;
    }
    return (_jsx(StyledBackDropDimmed, { onTransitionEnd: onAnimationEnd, shown: delayedShown, onClick: event => {
            event.stopPropagation();
            event.preventDefault();
            onClick === null || onClick === void 0 ? void 0 : onClick();
        }, onWheel: handleWheel, onTouchMove: handleTouchMove }));
};
export default BackDropDimmed;
