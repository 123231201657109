import { PowerSupplyFeature } from '@nomady/shared/types';
const getPowerSupplySearchParam = (powerSupplySearchQuery) => {
    const values = [];
    if (powerSupplySearchQuery === null || powerSupplySearchQuery === void 0 ? void 0 : powerSupplySearchQuery.smallDevices) {
        values.push(PowerSupplyFeature.smallDevices);
    }
    if (powerSupplySearchQuery === null || powerSupplySearchQuery === void 0 ? void 0 : powerSupplySearchQuery.campingVehicles) {
        values.push(PowerSupplyFeature.campingVehicles);
    }
    if (values.length === 0) {
        return undefined;
    }
    return `has_any:${values.join(',')}`;
};
export default getPowerSupplySearchParam;
