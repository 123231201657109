import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { isToday } from 'date-fns';
import React from 'react';
import styled from 'styled-components/macro';
import Text, { TextVariant } from '../../../styled-components/Text/Text';
import { ColorVariant } from '../../../theme';
import { useAvailabilityState } from '../hooks/useAvailabilityState';
import { AvailabilityStateTag } from './AvailabilityStateTag';
const Container = styled.div `
  pointer-events: none;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  padding: 4px;
`;
export const CalendarComponentWeekHeader = ({ label, date, }) => {
    const { availabilityState } = useAvailabilityState(date);
    return (_jsxs(Container, { children: [_jsx(Text, Object.assign({ variant: TextVariant.L2SB, colorVariant: isToday(date) ? ColorVariant.Forest : ColorVariant.Cave }, { children: label })), availabilityState && (_jsx(AvailabilityStateTag, { availabilityState: availabilityState }))] }));
};
