import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styled from 'styled-components/macro';
import moment from 'moment';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import Button, { ButtonColorVariant, ButtonSizeVariant, } from '../../../../styled-components/Button/Button';
import Icon, { IconSizeVariant, IconVariant, } from '../../../../styled-components/Icon/Icon';
import { ColorVariant } from '../../../../theme';
import { safeSpaceBottomSelector } from '../../../../slices/UISlice';
import { listingHasUpdatedPricing } from '../../ListingPage';
const Wrapper = styled.div ``;
const BottomCheckoutBar = styled.div `
  position: fixed;
  bottom: 0;
  left: 0;
  ${props => props.theme.priority.low};

  width: 100%;
  min-height: 66px;
  background: ${props => props.theme.color.pearl};
  box-shadow: 0px -40px 100px rgba(0, 0, 0, 0.05);
  border-radius: 20px 20px 0px 0px;
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: max(15px, ${props => props.safeSpaceBottom}px);

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  transition: bottom 0.3s ease-in-out;
  align-items: center;

  @media ${props => props.theme.device.desktop} {
    display: none;
  }
`;
const BookingArea = styled.div `
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
const ButtonWrapper = styled.div ``;
const Summary = styled.div `
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`;
const SummaryLine = styled.div `
  display: flex;
  flex-direction: row;
  margin-left: -3px;
`;
const PriceLabel = styled.div `
  display: flex;
  flex-direction: row;
`;
const AmountPeople = styled.span `
  ${props => props.theme.typeset.B30};
  margin-right: 5px;
`;
const AmountNights = styled.span `
  ${props => props.theme.typeset.B30}
`;
const Currency = styled.span `
  margin-right: 5px;
  ${props => props.theme.typeset.B30}
`;
const MoneyValue = styled.span `
  ${props => props.theme.typeset.B30}
`;
const MobileBookingBottomBar = ({ listing, bookingStart, bookingEnd, adultQuantity, teenQuantity = 0, childQuantity = 0, setBreakdownOpen, handleClickCheckout, bookingButtonLabel, totalPrice, }) => {
    const intl = useIntl();
    const DEFAULT_AMOUNT_NIGHTS = '1';
    const hasUpdatedPricing = listingHasUpdatedPricing(listing);
    const enabled = !!(bookingEnd && bookingStart);
    const safeSpaceBottom = useSelector(safeSpaceBottomSelector);
    return (_jsx(Wrapper, { children: _jsxs(BottomCheckoutBar, Object.assign({ safeSpaceBottom: safeSpaceBottom }, { children: [_jsxs(BookingArea, Object.assign({ onClick: () => enabled && setBreakdownOpen(true) }, { children: [enabled && (_jsx(Icon, { variant: IconVariant.ArrowUp, mobileSizeVariant: IconSizeVariant.Small })), _jsxs(Summary, { children: [_jsxs(PriceLabel, { children: [_jsx(Currency, { children: listing.attributes.publicData.currency }), _jsx(MoneyValue, { children: totalPrice.toFixed(2) })] }), _jsxs(SummaryLine, { children: [_jsx(Icon, { variant: IconVariant.User, colorVariant: ColorVariant.Stone, sizeVariant: IconSizeVariant.ExtraSmall }), _jsx(AmountPeople, { children: adultQuantity + teenQuantity + childQuantity }), _jsx(Icon, { variant: IconVariant.MoonOutlined, sizeVariant: IconSizeVariant.ExtraSmall }), _jsx(AmountNights, { children: bookingEnd && bookingStart
                                                ? moment(bookingEnd).diff(moment(bookingStart), 'days')
                                                : DEFAULT_AMOUNT_NIGHTS })] })] })] })), _jsxs(ButtonWrapper, { children: [_jsx(Button, { label: bookingButtonLabel, onClick: () => handleClickCheckout(), colorVariant: ButtonColorVariant.Forest, mobileSizeVariant: ButtonSizeVariant.Small, disabled: !hasUpdatedPricing }), !hasUpdatedPricing && (_jsx("p", { children: _jsx("strong", { children: intl.formatMessage({ id: 'ListingPage.pricingNotUpToDate' }) }) }))] })] })) }));
};
export default MobileBookingBottomBar;
