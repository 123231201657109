export const localizedZodCustomErrorMap = (intl, namespace) => issue => {
    const namespaces = [namespace, issue.path, issue.code].filter(element => !!element);
    const id = namespaces.join('.');
    const message = intl.formatMessage({
        id,
    }, issue);
    if (message !== id) {
        return { message };
    }
    // @ts-ignore issue.type is not properly typed in external library
    const defaultNamespaces = ['SchemaError', issue.type, issue.code].filter(element => !!element);
    return {
        message: intl.formatMessage({
            id: defaultNamespaces.join('.'),
        }, issue),
    };
};
