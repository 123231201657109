import { localizedZodCustomErrorMap } from '@nomady/shared/schemas/localizedZodCustomErrorMap';
import { z } from 'zod';
export const getTransactionTerminationReasonSchema = (intl, enumType) => {
    z.setErrorMap(localizedZodCustomErrorMap(intl, 'RefundSchemaError'));
    return z
        .object({
        reason: z.nativeEnum(enumType),
        reasonText: z.string().optional(),
    })
        .refine(data => {
        if (data.reason === enumType.Other &&
            (data.reasonText === undefined || data.reasonText.length < 30)) {
            return false;
        }
        return true;
    }, {
        message: intl.formatMessage({
            id: 'RefundModal.reasonText.minLength',
        }),
        path: ['reasonText'],
    });
};
