function toRad(value) {
    return (value * Math.PI) / 180;
}
const getDistanceBetween2Points = (lat1, lng1, lat2, lng2) => {
    const earthDiameter = 6371; // km
    const differenceLat = toRad(lat2 - lat1);
    const differenceLng = toRad(lng2 - lng1);
    const latMain = toRad(lat1);
    const latSecond = toRad(lat2);
    const a = Math.sin(differenceLat / 2) * Math.sin(differenceLat / 2) +
        Math.sin(differenceLng / 2) *
            Math.sin(differenceLng / 2) *
            Math.cos(latMain) *
            Math.cos(latSecond);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return earthDiameter * c;
};
export default getDistanceBetween2Points;
