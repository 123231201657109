import { AccommodationAmenity, DefaultAmenity } from '@nomady/shared/types';
const getAmenitiesSearchParam = (featuresSearchQuery) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v;
    const values = [];
    if ((_b = (_a = featuresSearchQuery.facilities) === null || _a === void 0 ? void 0 : _a.accommodation) === null || _b === void 0 ? void 0 : _b.heating) {
        values.push(AccommodationAmenity.heating);
    }
    if ((_d = (_c = featuresSearchQuery.facilities) === null || _c === void 0 ? void 0 : _c.accommodation) === null || _d === void 0 ? void 0 : _d.linen) {
        values.push(AccommodationAmenity.bedding);
    }
    if ((_f = (_e = featuresSearchQuery.facilities) === null || _e === void 0 ? void 0 : _e.miscellaneous) === null || _f === void 0 ? void 0 : _f.kitchen) {
        values.push(DefaultAmenity.kitchen);
    }
    if ((_h = (_g = featuresSearchQuery.facilities) === null || _g === void 0 ? void 0 : _g.miscellaneous) === null || _h === void 0 ? void 0 : _h.picnicTable) {
        values.push(DefaultAmenity.picNicTable);
    }
    if ((_k = (_j = featuresSearchQuery.facilities) === null || _j === void 0 ? void 0 : _j.miscellaneous) === null || _k === void 0 ? void 0 : _k.shelter) {
        values.push(DefaultAmenity.shelter);
    }
    if ((_m = (_l = featuresSearchQuery.facilities) === null || _l === void 0 ? void 0 : _l.miscellaneous) === null || _m === void 0 ? void 0 : _m.bin) {
        values.push(DefaultAmenity.trash);
    }
    if ((_p = (_o = featuresSearchQuery.facilities) === null || _o === void 0 ? void 0 : _o.miscellaneous) === null || _p === void 0 ? void 0 : _p.internetHotspot) {
        values.push(DefaultAmenity.wifi);
    }
    if ((_r = (_q = featuresSearchQuery.facilities) === null || _q === void 0 ? void 0 : _q.miscellaneous) === null || _r === void 0 ? void 0 : _r.mobileReception) {
        values.push(DefaultAmenity.reception);
    }
    if ((_t = (_s = featuresSearchQuery.facilities) === null || _s === void 0 ? void 0 : _s.miscellaneous) === null || _t === void 0 ? void 0 : _t.dishwashing) {
        values.push(DefaultAmenity.dishwashing);
    }
    if ((_v = (_u = featuresSearchQuery.facilities) === null || _u === void 0 ? void 0 : _u.miscellaneous) === null || _v === void 0 ? void 0 : _v.grayWaterDisposal) {
        values.push(DefaultAmenity.greywater);
    }
    if (values.length === 0) {
        return undefined;
    }
    return values.join(',');
};
export default getAmenitiesSearchParam;
