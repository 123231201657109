import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { FormattedMessage, useIntl } from 'react-intl';
import { MAXIMUM_LENGTH_OF_LISTING_FAVORITES_LIST_NAME } from '@nomady/shared/config';
import { ListingFavoritesListCoverPicture } from '@nomady/shared/models/ListingFavoritesListCoverPicture';
import BottomSheet, { BottomSheetLeft, BottomSheetRight, BottomSheetTitle, } from '../BottomSheet/BottomSheet';
import ListingFavoritesListCard from '../ListingFavoritesListCard/ListingFavoritesListCard';
import Button, { ButtonColorVariant, ButtonSizeVariant, } from '../Button/Button';
import Input from '../Input/Input';
const BottomSheetWrapper = styled.div `
  display: flex;
  flex-direction: column;

  @media ${props => props.theme.device.tablet} {
    flex-direction: row;
    margin-top: 50px;
    margin-bottom: 200px;
  }
`;
const ButtonWrapper = styled.div `
  display: flex;
  align-self: flex-end;
  margin-top: 20px;
`;
const EditFavoritesListBottomSheet = ({ isOpen, onClose, listingFavoritesList, listings, onUpdate }) => {
    const intl = useIntl();
    const [newListName, setNewListName] = useState((listingFavoritesList === null || listingFavoritesList === void 0 ? void 0 : listingFavoritesList.name) || '');
    return (_jsx(BottomSheet, Object.assign({ open: isOpen, onClose: onClose }, { children: _jsxs(BottomSheetWrapper, { children: [_jsx(BottomSheetLeft, { children: _jsx(ListingFavoritesListCard, { name: (listingFavoritesList === null || listingFavoritesList === void 0 ? void 0 : listingFavoritesList.name) ||
                            intl.formatMessage({
                                id: 'ListingFavoritesListPage.titleNotFound',
                            }), coverPicture: (listingFavoritesList === null || listingFavoritesList === void 0 ? void 0 : listingFavoritesList.cover_picture) ||
                            ListingFavoritesListCoverPicture.Cover3, listings: listings }) }), _jsxs(BottomSheetRight, { children: [_jsx(BottomSheetTitle, { children: _jsx(FormattedMessage, { id: "EditFavoritesListBottomSheet.title" }) }), _jsx(Input, { maxLength: MAXIMUM_LENGTH_OF_LISTING_FAVORITES_LIST_NAME, onChange: event => {
                                setNewListName(event.target.value);
                            }, value: newListName, placeholder: (listingFavoritesList === null || listingFavoritesList === void 0 ? void 0 : listingFavoritesList.name) || '' }), _jsxs(ButtonWrapper, { children: [_jsx(Button, { label: intl.formatMessage({
                                        id: 'EditFavoritesListBottomSheet.cancelButton',
                                    }), colorVariant: ButtonColorVariant.Default, sizeVariant: ButtonSizeVariant.Small, onClick: onClose }), _jsx(Button, { label: intl.formatMessage({
                                        id: 'EditFavoritesListBottomSheet.updateButton',
                                    }), colorVariant: ButtonColorVariant.Dark, sizeVariant: ButtonSizeVariant.Small, onClick: () => onUpdate(newListName, (listingFavoritesList === null || listingFavoritesList === void 0 ? void 0 : listingFavoritesList.id) || ''), disabled: newListName === (listingFavoritesList === null || listingFavoritesList === void 0 ? void 0 : listingFavoritesList.name) })] })] })] }) })));
};
export default EditFavoritesListBottomSheet;
