import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styled from 'styled-components/macro';
import { ColorVariant } from '../../theme';
import CheckboxInputSection from '../CheckboxInputSection/CheckboxInputSection';
import Icon, { IconSizeVariant } from '../Icon/Icon';
import LabeledToggle, { LabeledToggleColorVariant, } from '../LabeledToggle/LabeledToggle';
import Text, { TextVariant } from '../Text/Text';
import { Label, Title } from '../FilterGroup/FilterGroup';
const Wrapper = styled.div `
  display: flex;
  flex-direction: column;
  min-width: 280px;
  width: 100%;
  background: ${props => props.theme.color.snow};
`;
const FilterDetailCard = styled.div `
  display: flex;
  width: 100%;
  flex-grow: 1;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;

  border: 0;
  background: transparent;

  padding-left: 15px;
  padding-bottom: 8px;
  padding-top: 8px;
  padding-right: 20px;
`;
const Description = styled(Text) `
  display: block;
  margin-left: 25px;
  margin-right: 25px;
  margin-bottom: 20px;
`;
const FilterDetailGroup = ({ iconVariant, label, toggle, checkboxes, }) => {
    return (_jsxs(Wrapper, { children: [toggle && (_jsx(FilterDetailCard, { children: _jsx(LabeledToggle, { label: label, iconVariant: iconVariant, checked: toggle.checked, colorVariant: LabeledToggleColorVariant.White, onChange: toggle.onChange }) })), !toggle && (
            // <FilterCard
            _jsxs(Title, { children: [_jsx(Icon, { variant: iconVariant, sizeVariant: IconSizeVariant.MediumLarge }), _jsx(Label, Object.assign({ variant: TextVariant.L1SB, colorVariant: ColorVariant.Cave }, { children: label }))] })
            // </FilterCard>
            ), (toggle === null || toggle === void 0 ? void 0 : toggle.description) && (_jsx(Description, Object.assign({ variant: TextVariant.H14 }, { children: toggle.description }))), checkboxes && _jsx(CheckboxInputSection, { checkboxes: checkboxes })] }));
};
export default FilterDetailGroup;
