import * as z from 'zod';
import { localizedZodCustomErrorMap } from '../localizedZodCustomErrorMap';
import { MarkerType } from '../../types';
const MAX_MARKERS = 20;
export const ListingLocationSchema = z.object({
    location: z.object({
        address: z.string(),
        address_components: z.object({
            country: z.string().nullish(),
            level1: z.string().nullish(),
            level2: z.string().nullish(),
            locality: z.string().nullish(),
            postal_code: z.string().nullish(),
            street: z.string().nullish().nullish(),
            street_number: z.string().nullish(),
        }),
        location: z
            .object({
            lat: z.number(),
            lng: z.number(),
        })
            .nullish(),
        placeId: z.string(),
    }),
    afterBookingPhone: z.object({
        country: z.string(),
        formattedValue: z.string(),
        nationalNumber: z.string(),
        number: z.string(),
    }),
    afterBookingInfo: z.string().nullish(),
    afterBookingRoute: z.string().nullish(),
    markers: z
        .array(z.object({
        key: z.string(),
        lat: z.number(),
        lng: z.number(),
        type: z.nativeEnum(MarkerType),
    }))
        .max(MAX_MARKERS)
        .nullish(),
});
export const getListingLocationSchema = (intl) => {
    z.setErrorMap(localizedZodCustomErrorMap(intl, 'ListingSchemaError'));
    return ListingLocationSchema;
};
export const getListingLocationDefaultValues = (attributes) => {
    var _a, _b;
    return ({
        afterBookingPhone: attributes === null || attributes === void 0 ? void 0 : attributes.protectedData.afterBookingPhone,
        afterBookingInfo: (_a = attributes === null || attributes === void 0 ? void 0 : attributes.protectedData.localizedProtectedData) === null || _a === void 0 ? void 0 : _a.afterBookingInfo,
        afterBookingRoute: (_b = attributes === null || attributes === void 0 ? void 0 : attributes.protectedData.localizedProtectedData) === null || _b === void 0 ? void 0 : _b.afterBookingRoute,
        markers: attributes === null || attributes === void 0 ? void 0 : attributes.publicData.markers,
        location: attributes === null || attributes === void 0 ? void 0 : attributes.publicData.location,
    });
};
