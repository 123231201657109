import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import styled, { css } from 'styled-components/macro';
export var ImageGridVariant;
(function (ImageGridVariant) {
    ImageGridVariant["Default"] = "Default";
    ImageGridVariant["Wrapped"] = "Default";
    ImageGridVariant["Inline"] = "Inline";
})(ImageGridVariant || (ImageGridVariant = {}));
const Wrapper = styled.div `
  display: flex;
  margin: 0 auto;
  flex-wrap: wrap;
  justify-content: center;

  @media ${props => props.theme.device.tablet} {
    justify-content: space-between;
  }

  @media ${props => props.theme.device.tabletL} {
    width: 870px;
  }

  @media ${props => props.theme.device.desktop} {
    width: 100%;
  }

  ${props => props.variant === ImageGridVariant.Inline &&
    `
    flex-wrap:nowrap;
  `}
`;
const Image = styled.img `
  width: 200px;
  height: 200px;
  ${props => props.variant === ImageGridVariant.Inline &&
    css `
      width: 130px;
      height: 130px;
    `}

  @media ${props => props.theme.device.tabletL} {
    width: 290px;
    height: 290px;
    ${props => props.variant === ImageGridVariant.Inline &&
    css `
        width: 180px;
        height: 180px;
      `}
  }
`;
const ImageGrid = ({ images, variant }) => (_jsx(Wrapper, Object.assign({ variant: variant }, { children: images.map(image => {
        const hasUrl = !!image.targetLink;
        const inner = (_jsx(Image, { variant: variant, src: image.image.data.attributes.url, alt: image.image.data.attributes.alternativeText, loading: "lazy" }, image.id));
        if (hasUrl) {
            return (_jsx("a", Object.assign({ href: image.targetLink, target: "_blank", rel: "noreferrer" }, { children: inner })));
        }
        return inner;
    }) })));
export default ImageGrid;
