import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components/macro';
import SectionContentSlider from '../SectionContentSlider/SectionContentSlider';
import ConnectedPictureCard from '../PictureCard/ConnectedPictureCard';
const SearchLinksPictureWrapper = styled.div `
  display: grid;
  gap: 15px;
  grid-auto-flow: column;
  align-items: start;
  margin-bottom: 30px;
  padding-bottom: 20px;
  min-width: 2500px;
`;
const SearchPageLinksSlider = () => {
    const intl = useIntl();
    return (_jsx(SectionContentSlider, Object.assign({ title: intl.formatMessage({
            id: 'SearchPageLinksSlider.adventure.title',
        }), hideTitleOnMobile: true }, { children: _jsx(SearchLinksPictureWrapper, { children: _jsx(ConnectedPictureCard, {}) }) })));
};
export default SearchPageLinksSlider;
