import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styled from 'styled-components/macro';
import Icon, { IconVariant } from '../../../styled-components/Icon/Icon';
import Text, { TextVariant } from '../../../styled-components/Text/Text';
import { ColorVariant } from '../../../theme';
const InfoCardWrapper = styled.div `
  display: flex;
  align-items: start;
  gap: 8px;
  padding: 8px;
  background-color: ${props => props.theme.color.pearl};
  box-shadow: inset 0 0 0 1px
    ${props => props.theme.color.functionBlueInformation};
  border-radius: 8px;
`;
const InfoIconWrapper = styled.div `
  padding: 4px;
  border-radius: 8px;
  background-color: ${props => props.theme.color.functionBlueInformation};
`;
const InfoTextWrapper = styled.div `
  display: flex;
  flex-direction: column;
`;
export const InfoCard = ({ title, text, children, }) => (_jsxs(InfoCardWrapper, { children: [_jsx(InfoIconWrapper, { children: _jsx(Icon, { variant: IconVariant.Info, colorVariant: ColorVariant.Pearl }) }), _jsxs(InfoTextWrapper, { children: [_jsx(Text, Object.assign({ variant: TextVariant.P4SB, colorVariant: ColorVariant.Cave }, { children: title })), _jsx(Text, Object.assign({ variant: TextVariant.P4R, colorVariant: ColorVariant.Stone }, { children: children || text }))] })] }));
