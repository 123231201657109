import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { ReactComponent as NomadyStampIcon } from './assets/Nomady_Stamp.svg';
import styled from 'styled-components/macro';
const StampWrapper = styled.div `
  svg {
    height: 100%;
    width: 100%;
    color: ${props => props.colorVariant
    ? props.theme.color[props.colorVariant]
    : 'currentColor'};
  }
`;
const NomadyStamp = ({ colorVariant }) => {
    return (_jsx(StampWrapper, Object.assign({ colorVariant: colorVariant }, { children: _jsx(NomadyStampIcon, {}) })));
};
export default NomadyStamp;
