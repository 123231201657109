import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Page from '@nomady/shared/routeConfiguration/Page';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components/macro';
import { ColorVariant } from '../../theme';
import Button, { ButtonBorderRadiusVariant, ButtonColorVariant, ButtonSizeVariant, } from '../Button/Button';
import Text, { TextVariant } from '../Text/Text';
import Button2024, { ButtonVariant } from '../Button2024/Button2024';
const Wrapper = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const TitleWrapper = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
`;
const Title = styled(Text) `
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
`;
const CountriesWrapper = styled.div `
  display: grid;
  grid-auto-flow: column;
  align-items: start;
  grid-auto-columns: min-content;
  width: min-content;
  max-width: 100%;
  margin: 0 auto;

  background-color: ${props => props.theme.color.pearl};
  border-radius: 10px;

  padding: 10px 15px;
  overflow-x: scroll;
  margin-bottom: 20px;
`;
const RegionsWrapper = styled.div `
  display: ${props => (!props.show ? 'none' : 'flex')};
  flex-wrap: wrap;
  gap: 8px;
`;
const Illustration = styled.img `
  height: 150px;
  width: 150px;
`;
const RegionNavigation = ({ title, countryConfig, icon, }) => {
    const intl = useIntl();
    const countryKeys = Object.keys(countryConfig);
    const [selectedCountry, setSelectedCountry] = useState(countryKeys[0]);
    useEffect(() => {
        const firstCountryWithRegions = countryKeys.findIndex((countryKey) => 0 < countryConfig[countryKey].length);
        setSelectedCountry(countryKeys[firstCountryWithRegions]);
    }, [countryConfig]);
    const countries = countryKeys
        .filter((countryKey) => 0 < countryConfig[countryKey].length)
        .map((countryKey) => {
        return {
            id: countryKey,
            name: intl.formatMessage({
                id: `MarketplaceConfig.country.${countryKey}`,
            }),
        };
    });
    return (_jsxs(Wrapper, { children: [_jsxs(TitleWrapper, { children: [_jsx(Illustration, { src: icon === null || icon === void 0 ? void 0 : icon.data.attributes.url, loading: "lazy" }), _jsx(Title, Object.assign({ variant: TextVariant.H20, colorVariant: ColorVariant.Cave, as: "h2" }, { children: title }))] }), _jsx(CountriesWrapper, { children: countries.map(country => (_jsx(Button, { label: intl.formatMessage({ id: country.name }), sizeVariant: ButtonSizeVariant.RegionNavigation, borderRadiusVariant: ButtonBorderRadiusVariant.Squared, colorVariant: country.id === selectedCountry
                        ? ButtonColorVariant.Dark
                        : ButtonColorVariant.Transparent, icon: country.id.toLocaleUpperCase(), onClick: () => {
                        setSelectedCountry(country.id);
                    } }, country.id))) }), Object.keys(countryConfig).map(country => {
                const regions = countryConfig[country];
                return (_jsx(RegionsWrapper, Object.assign({ show: country === selectedCountry }, { children: regions.map(region => (_jsx(Button2024, { label: region.name, variant: ButtonVariant.Tertiary, targetRoute: Page.Region, targetRouteParams: { name: region.routeName } }, region.id))) }), country));
            })] }));
};
export default RegionNavigation;
