var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components/macro';
import { useIntl } from 'react-intl';
import Modal from '../../../styled-components/Modal/Modal';
import { InfoItem } from '../../ListingPage/Sections/BookingDetailsSection';
import { IconVariant } from '../../../styled-components/Icon/Icon';
import moment from 'moment';
import SteppedNumberInput, { SteppedNumberInputVariant, } from '../../../styled-components/SteppedNumberInput/SteppedNumberInput';
import { useDispatch } from 'react-redux';
import { editAvailabilityExceptionsForSingleListing } from '../CalendarSlice';
import { useAvailabilitiesOverSlotRange } from '../hooks/useAvailabilitiesOverSlotRange';
import { InfoCard } from './InfoCard';
import Text, { TextVariant } from '../../../styled-components/Text/Text';
import { ColorVariant } from '../../../theme';
import { getDatesWithSameWeekday, getMaxSeatsOfListing } from '../utils';
import { DIFFERENT_AVAILABLE_SEATS_WITHIN_SLOT_RANGE, FUTURE_AVAILABILITY_EDIT_MAX_MONTHS, } from '../constants';
const Container = styled.div `
  display: flex;
  flex-direction: column;
  gap: 32px;
`;
const InfoWrapper = styled.div `
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
const EditAvailabilitiesWrapper = styled.div `
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 8px;
  box-shadow: inset 0 0 0 1px ${props => props.theme.color.fog};
  border-radius: 8px;
`;
const ButtonRow = styled.div `
  display: flex;
  gap: 8px;
  justify-content: space-between;
`;
const BlockButtonText = styled(Text) `
  text-decoration: underline;
  cursor: pointer;
  padding: 8px 12px;
`;
const AvailabilityDetailsForSingleListingWeekdayModal = ({ open, onClose, date, selectedListing }) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const [numberOfMonths, setNumberOfMonths] = useState(1);
    const [newAvailableSlots, setNewAvailableSlots] = useState();
    const [hasDifferentAvailableSeatsWithinSlotRange, setHasDifferentAvailableSeatsWithinSlotRange,] = useState(false);
    const [savingInProgress, setSavingInProgress] = useState(false);
    const slots = useMemo(() => getDatesWithSameWeekday(date, numberOfMonths), [date, numberOfMonths]);
    const availabilitiesOverSlotRange = useAvailabilitiesOverSlotRange(slots);
    useEffect(() => {
        if (availabilitiesOverSlotRange) {
            if (availabilitiesOverSlotRange.slots ===
                DIFFERENT_AVAILABLE_SEATS_WITHIN_SLOT_RANGE) {
                setNewAvailableSlots(maxSeats);
                setHasDifferentAvailableSeatsWithinSlotRange(true);
            }
            else {
                setNewAvailableSlots(availabilitiesOverSlotRange.slots);
                setHasDifferentAvailableSeatsWithinSlotRange(false);
            }
        }
    }, [availabilitiesOverSlotRange === null || availabilitiesOverSlotRange === void 0 ? void 0 : availabilitiesOverSlotRange.slots]);
    if (selectedListing === undefined) {
        return null;
    }
    const weekday = moment(date).format('dddd');
    const maxSeats = getMaxSeatsOfListing(selectedListing);
    const save = () => __awaiter(void 0, void 0, void 0, function* () {
        if (slots === undefined) {
            return;
        }
        setSavingInProgress(true);
        yield dispatch(editAvailabilityExceptionsForSingleListing({
            dates: slots,
            availabilitiesOverSlotRange,
            newAvailableSlots,
            maxSeats,
        }));
        setSavingInProgress(false);
        onClose();
    });
    const onChangeNewAvailableSlots = savingInProgress
        ? undefined
        : (value) => {
            setNewAvailableSlots(value);
        };
    return (_jsx(Modal, Object.assign({ isOpen: open, onClose: onClose, title: intl.formatMessage({
            id: 'BookingCalendar.AvailabilityDetails.title',
        }), rightButton: {
            label: intl.formatMessage({
                id: 'BookingCalendar.AvailabilityDetails.save',
            }),
            isPrimary: true,
            onClick: save,
            inProgress: savingInProgress,
        }, leftButton: {
            label: intl.formatMessage({
                id: 'BookingCalendar.AvailabilityDetails.cancel',
            }),
            onClick: onClose,
        } }, { children: _jsxs(Container, { children: [_jsxs(InfoWrapper, { children: [_jsx(InfoItem, { title: intl.formatMessage({
                                id: 'BookingCalendar.AvailabilityDetails.listingTitleSingular',
                            }), iconVariant: IconVariant.Listing, text: selectedListing.attributes.title }), _jsx(InfoItem, { title: intl.formatMessage({
                                id: 'BookingCalendar.AvailabilityDetails.weekday',
                            }), iconVariant: IconVariant.Calendar_NEW, text: weekday }), _jsx(InfoItem, { title: intl.formatMessage({
                                id: 'BookingCalendar.AvailabilityDetails.totalSpaces',
                            }), iconVariant: IconVariant.Spots, text: maxSeats.toString() })] }), _jsx(SteppedNumberInput, { label: intl.formatMessage({
                        id: 'BookingCalendar.AvailabilityDetails.numberOfMonths',
                    }), name: "numberOfMonths", min: 1, max: FUTURE_AVAILABILITY_EDIT_MAX_MONTHS, value: numberOfMonths, onValueChange: setNumberOfMonths, variant: SteppedNumberInputVariant.White, disabled: !!savingInProgress }), hasDifferentAvailableSeatsWithinSlotRange && (_jsx(InfoCard, { title: intl.formatMessage({
                        id: 'BookingCalendar.AvailabilityDetails.differingAvailabilitiesInfoTitle',
                    }), text: intl.formatMessage({
                        id: 'BookingCalendar.AvailabilityDetails.differingAvailabilitiesInfoText',
                    }) })), _jsxs(EditAvailabilitiesWrapper, { children: [availabilitiesOverSlotRange !== undefined && (_jsx(SteppedNumberInput, { label: intl.formatMessage({
                                id: 'BookingCalendar.AvailabilityDetails.availableSeats',
                            }), name: "availableSeats", min: 0, max: maxSeats, value: newAvailableSlots || 0, onValueChange: setNewAvailableSlots, variant: SteppedNumberInputVariant.White, disabled: savingInProgress })), _jsxs(ButtonRow, { children: [_jsx(BlockButtonText, Object.assign({ colorVariant: ColorVariant.Cave, variant: TextVariant.L2R, onClick: () => onChangeNewAvailableSlots === null || onChangeNewAvailableSlots === void 0 ? void 0 : onChangeNewAvailableSlots(0) }, { children: intl.formatMessage({
                                        id: 'BookingCalendar.AvailabilityDetails.blockAll',
                                    }) })), _jsx(BlockButtonText, Object.assign({ colorVariant: ColorVariant.Cave, variant: TextVariant.L2R, onClick: () => onChangeNewAvailableSlots === null || onChangeNewAvailableSlots === void 0 ? void 0 : onChangeNewAvailableSlots(maxSeats) }, { children: intl.formatMessage({
                                        id: 'BookingCalendar.AvailabilityDetails.unblockAll',
                                    }) }))] })] })] }) })));
};
export default AvailabilityDetailsForSingleListingWeekdayModal;
