var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components/macro';
import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useIntl } from 'react-intl';
import { Controller, useForm, } from 'react-hook-form';
import { localizedZodCustomErrorMap } from '@nomady/shared/schemas/localizedZodCustomErrorMap';
import Input, { ErrorMessageWrapper } from '../Input/Input';
import InputErrorMessage from '../InputErrorMessage/InputErrorMessage';
import SelectInput from '../SelectInput/SelectInput';
import { AccountType, stripeCountryDetails, } from '@nomady/shared/stripe-config';
import { useStripe } from '@stripe/react-stripe-js';
import debounce from 'lodash/debounce';
const DEBOUNCE_WAIT_TIME = 1000;
const Wrapper = styled.div `
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
`;
const InternalField = (_a) => {
    var { type, description, field, fieldState, formState, error, defaultValues, disabled } = _a, props = __rest(_a, ["type", "description", "field", "fieldState", "formState", "error", "defaultValues", "disabled"]);
    const intl = useIntl();
    const stripe = useStripe();
    const [loading, setLoading] = useState(false);
    const [stripeError, setStripeError] = useState();
    const hasError = !!error;
    const errorMessageId = hasError ? `${props.id}-errormessage` : '';
    z.setErrorMap(localizedZodCustomErrorMap(intl, 'ListingSchemaError'));
    const InputSchema = z.object({
        country: z.string(),
        currency: z.string(),
        bankAccountNumber: z.string(),
    });
    const createBankAccountToken = useCallback((accountData) => __awaiter(void 0, void 0, void 0, function* () {
        var _b;
        try {
            const result = yield (stripe === null || stripe === void 0 ? void 0 : stripe.createToken('bank_account', accountData));
            if (result === null || result === void 0 ? void 0 : result.error) {
                const error = new Error(result === null || result === void 0 ? void 0 : result.error.message);
                error.stripeError = result.error;
                throw error;
            }
            const token = result === null || result === void 0 ? void 0 : result.token.id;
            field.onChange(token);
            setStripeError(undefined);
        }
        catch (error) {
            if (!error.stripeError) {
                throw error;
            }
            field.onChange(undefined);
            setStripeError((_b = error === null || error === void 0 ? void 0 : error.stripeError) === null || _b === void 0 ? void 0 : _b.message);
        }
        setLoading(false);
    }), [field, stripe]);
    const debouncedCreateBankAccountToken = useMemo(() => debounce(createBankAccountToken, DEBOUNCE_WAIT_TIME), [createBankAccountToken]);
    const { register, watch, formState: { errors, isValid }, resetField, setValue, getValues, } = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: zodResolver(InputSchema),
    });
    const country = register('country');
    const countryValue = watch('country');
    const currency = register('currency');
    const bankAccountNumber = register('bankAccountNumber');
    return (_jsxs(Wrapper, { children: [_jsx(SelectInput, Object.assign({ disabled: disabled, options: stripeCountryDetails.map(({ code }) => ({
                    label: intl.formatMessage({
                        id: `StripeConnectAccountForm.countryNames.${code}`,
                    }),
                    value: code,
                })), label: intl.formatMessage({
                    id: 'InputBankAccount.country',
                }), id: "country" }, country, { onChange: event => {
                    var _a;
                    resetField('bankAccountNumber');
                    const newCurrency = (_a = stripeCountryDetails.find(country => country.code === event.target.value)) === null || _a === void 0 ? void 0 : _a.currency;
                    setValue('currency', newCurrency, { shouldValidate: true });
                    country.onChange(event);
                    setStripeError(undefined);
                }, inputRef: country.ref, error: errors === null || errors === void 0 ? void 0 : errors.country })), countryValue && props.accountType && (_jsxs(_Fragment, { children: [_jsx(Input, Object.assign({}, currency, { id: "currency", type: "text", inputRef: currency.ref, label: intl.formatMessage({ id: 'InputBankAccount.currency' }), disabled: true })), _jsx(Input, Object.assign({ disabled: disabled, label: intl.formatMessage({
                            id: 'InputBankAccount.bankAccountNumberLabel',
                        }), loading: loading, description: description, id: "bankAccount" }, bankAccountNumber, { onChange: event => {
                            bankAccountNumber.onChange(event);
                            const values = getValues();
                            if (isValid &&
                                values.country &&
                                values.currency &&
                                values.bankAccountNumber) {
                                setLoading(true);
                                const params = {
                                    country: values.country,
                                    currency: values.currency,
                                    account_number: values.bankAccountNumber,
                                };
                                if (props.accountType === AccountType.company) {
                                    params.account_holder_type = props.accountType;
                                }
                                debouncedCreateBankAccountToken(params);
                            }
                        }, type: "text", placeholder: intl.formatMessage({
                            id: 'InputBankAccount.bankAccountNumberPlaceholder',
                        }, { country: countryValue }), inputRef: bankAccountNumber.ref, error: stripeError
                            ? { message: stripeError }
                            : errors.bankAccountNumber, hasError: hasError || !!stripeError }))] })), hasError && (_jsx(ErrorMessageWrapper, Object.assign({ role: "alert" }, { children: _jsx(InputErrorMessage, { id: errorMessageId, error: error }) })))] }));
};
const InputBankAccount = (_a) => {
    var { control, rules } = _a, props = __rest(_a, ["control", "rules"]);
    return (_jsx(Controller, { render: fieldProps => _jsx(InternalField, Object.assign({}, fieldProps, props)), name: props.name, control: control, rules: rules }));
};
export default InputBankAccount;
