import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import LayoutSideNavigation from '../../styled-components/LayoutSideNavigation/LayoutSideNavigation';
import { PasswordChangeForm } from '../../forms';
import { changePassword, changePasswordClear } from './PasswordChangePage.duck';
import css from './PasswordChangePage.module.scss';
import SignedInPageContainer from '../../styled-components/PageContainer/SignedInPageContainer';
export const PasswordChangePageComponent = props => {
    const { changePasswordError, changePasswordInProgress, currentUser, onChange, onSubmitChangePassword, passwordChanged, intl, tabs, } = props;
    const changePasswordForm = currentUser && currentUser.id ? (_jsx(PasswordChangeForm, { className: css.form, changePasswordError: changePasswordError, currentUser: currentUser, onSubmit: onSubmitChangePassword, onChange: onChange, inProgress: changePasswordInProgress, ready: passwordChanged })) : null;
    const title = intl.formatMessage({ id: 'PasswordChangePage.title' });
    return (_jsx(SignedInPageContainer, Object.assign({ title: title }, { children: _jsxs(LayoutSideNavigation, Object.assign({ tabs: tabs }, { children: [_jsx("h1", Object.assign({ className: css.title }, { children: _jsx(FormattedMessage, { id: "PasswordChangePage.heading" }) })), changePasswordForm] })) })));
};
const mapStateToProps = (state) => {
    // Topbar needs user info.
    const { changePasswordError, changePasswordInProgress, passwordChanged } = state.PasswordChangePage;
    const { currentUser } = state.user;
    return {
        changePasswordError,
        changePasswordInProgress,
        currentUser,
        passwordChanged,
    };
};
const mapDispatchToProps = (dispatch) => ({
    onChange: () => dispatch(changePasswordClear()),
    onSubmitChangePassword: (values) => dispatch(changePassword(values)),
});
const PasswordChangePage = compose(connect(mapStateToProps, mapDispatchToProps), injectIntl)(PasswordChangePageComponent);
export default PasswordChangePage;
