import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components/macro';
import range from 'lodash/range';
import IconButton, { IconButtonSizeVariant } from '../IconButton/IconButton';
import { IconVariant } from '../Icon/Icon';
const Wrapper = styled.ul `
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 4px;
`;
const MAXIMUM_NUMBER_OF_PAGES = 4;
const isVisible = (pageIndex, openPageIndex, totalPages) => {
    const DISPLAY_ALL_PAGES = totalPages <= MAXIMUM_NUMBER_OF_PAGES;
    if (DISPLAY_ALL_PAGES)
        return true;
    const DISPLAY_FIRST_THREE = pageIndex < 2 && openPageIndex < 2;
    if (DISPLAY_FIRST_THREE)
        return true;
    const DISPLAY_LAST_THREE = totalPages - 3 <= pageIndex && totalPages - 2 <= openPageIndex;
    if (DISPLAY_LAST_THREE)
        return true;
    const DISPLAY_CURRENT_PAGE = pageIndex === openPageIndex;
    if (DISPLAY_CURRENT_PAGE)
        return true;
    const DISPLAY_NEXT_PAGE = pageIndex + 1 === openPageIndex;
    if (DISPLAY_NEXT_PAGE)
        return true;
    const ALWAYS_DISPLAY_LAST_PAGE = MAXIMUM_NUMBER_OF_PAGES < totalPages && totalPages - 1 === pageIndex;
    if (ALWAYS_DISPLAY_LAST_PAGE)
        return true;
    return false;
};
const displayDummyButton = (pageIndex, openPageIndex, totalPages) => {
    const dummyButton = (_jsx(IconButton, { iconText: "...", iconButtonSizeVariant: IconButtonSizeVariant.Small, disabled: true }));
    const DISPLAY_THIRD_IN_BEGINNING = openPageIndex === 0 && pageIndex === 2;
    if (DISPLAY_THIRD_IN_BEGINNING)
        return dummyButton;
    const DISPLAY_NEXT_IN_MIDDLE = openPageIndex + 1 === pageIndex;
    if (DISPLAY_NEXT_IN_MIDDLE)
        return dummyButton;
    const DISPLAY_FOURTH_LAST_IN_THE_END = totalPages - 2 <= openPageIndex && pageIndex === totalPages - 4;
    if (DISPLAY_FOURTH_LAST_IN_THE_END)
        return dummyButton;
    return _jsx(_Fragment, {});
};
const createLinkProps = (page, link) => {
    if (!link) {
        return undefined;
    }
    return Object.assign(Object.assign({}, link), { to: Object.assign(Object.assign({}, link.to), { search: `?page=${page}` }) });
};
const PaginationControl = ({ totalPages, activePage, onChangePage, link, }) => {
    const intl = useIntl();
    const pages = range(1, totalPages + 1);
    const openPreviousDisabled = activePage === 1;
    const openNextDisabled = activePage === totalPages;
    return (_jsxs(Wrapper, { children: [_jsx(IconButton, { iconVariant: IconVariant.Arrow_M_Left, onClick: onChangePage && !openPreviousDisabled
                    ? () => onChangePage(activePage - 1)
                    : undefined, link: !openPreviousDisabled
                    ? createLinkProps(activePage - 1, link)
                    : undefined, iconButtonSizeVariant: IconButtonSizeVariant.Small, disabled: openPreviousDisabled, ariaLabel: intl.formatMessage({ id: 'Pagination.openPrevious' }) }), pages.map((page, pageIndex) => isVisible(pageIndex, activePage - 1, totalPages) ? (_jsx(IconButton, { onClick: onChangePage ? () => onChangePage(page) : undefined, link: createLinkProps(page, link), forceActive: activePage === page, iconButtonSizeVariant: IconButtonSizeVariant.Small, ariaLabel: intl.formatMessage({ id: 'Pagination.openPageOf' }, { pageNumber: page, pagesLength: totalPages }), iconText: `${page}` }, `pagination-control-${page}`)) : (displayDummyButton(pageIndex, activePage - 1, totalPages))), _jsx(IconButton, { iconVariant: IconVariant.Arrow_M_Right, onClick: onChangePage && !openNextDisabled
                    ? () => onChangePage(activePage + 1)
                    : undefined, link: !openNextDisabled ? createLinkProps(activePage + 1, link) : undefined, iconButtonSizeVariant: IconButtonSizeVariant.Small, disabled: openNextDisabled, ariaLabel: intl.formatMessage({ id: 'Pagination.openNext' }) })] }));
};
export default PaginationControl;
