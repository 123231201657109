import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styled from 'styled-components/macro';
import { useIntl } from 'react-intl';
import { convertMoneyToNumber } from '@nomady/shared/utils/currency';
import Text, { TextVariant } from '../../Text/Text';
import { ColorVariant } from '../../../theme';
import { LINE_ITEM_GROUP } from '../types';
import DetailElement from './DetailElement';
export const BREAKDOWN_GROUPS = [
    LINE_ITEM_GROUP.ADDITIONAL_GUESTS,
    LINE_ITEM_GROUP.ADDITIONAL_SERVICES,
    LINE_ITEM_GROUP.TAXES_AND_FEES,
];
const lineItemGroupsToTranslationKeys = {
    [LINE_ITEM_GROUP.ADDITIONAL_GUESTS]: 'BookingBreakdown.additionalGuests',
    [LINE_ITEM_GROUP.TAXES_AND_FEES]: 'BookingBreakdown.taxesAndFees',
    [LINE_ITEM_GROUP.ADDITIONAL_SERVICES]: 'BookingBreakdown.additionalServices',
};
const DetailsList = styled.div `
  display: flex;
  flex-direction: column;
`;
const GroupContainer = styled.div `
  margin-top: 8px;
`;
const DetailsDivider = styled.div `
  border-bottom: 1px solid ${props => props.theme.color.fog};
  margin-block: 1px;
`;
const BreakdownGroup = ({ group, lineItems, }) => {
    const intl = useIntl();
    const lineItemsTranslationKeys = (lineItem) => {
        return lineItem.replace(/[-/][a-z]/g, match => match.charAt(1).toUpperCase());
    };
    return (_jsxs(GroupContainer, { children: [BREAKDOWN_GROUPS.includes(group) && (_jsx(Text, Object.assign({ colorVariant: ColorVariant.Tar, variant: TextVariant.B30 }, { children: intl.formatMessage({
                    id: lineItemGroupsToTranslationKeys[group],
                }) }))), _jsx(DetailsList, { children: lineItems[group].map((lineItem, lineItemIndex) => (_jsxs(_Fragment, { children: [_jsx(DetailElement, { leftText: intl.formatMessage({
                                id: `BookingBreakdown.${lineItemsTranslationKeys(lineItem.code)}`,
                            }, { quantity: lineItem.seats }), rightText: `${lineItem === null || lineItem === void 0 ? void 0 : lineItem.lineTotal.currency} ${convertMoneyToNumber(lineItem.lineTotal).toFixed(2)}` }, lineItem.code), lineItemIndex !== lineItems[group].length - 1 && (_jsx(DetailsDivider, {}, `${lineItem.code}-divider`))] }))) }, group)] }));
};
export default BreakdownGroup;
