import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { accessTypes, groundTypes, } from '@nomady/shared/marketplace-custom-config';
import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { IconVariant } from '../../../styled-components/Icon/Icon';
import ListingCheckList from '../../../styled-components/ListingChecklist';
const AccessContent = () => {
    var _a, _b, _c, _d;
    const { listing } = useSelector((state) => state.ListingPage);
    const intl = useIntl();
    const activeAccessType = (_b = (_a = listing === null || listing === void 0 ? void 0 : listing.attributes) === null || _a === void 0 ? void 0 : _a.publicData) === null || _b === void 0 ? void 0 : _b.accessType;
    const activeGroundType = (_d = (_c = listing === null || listing === void 0 ? void 0 : listing.attributes) === null || _c === void 0 ? void 0 : _c.publicData) === null || _d === void 0 ? void 0 : _d.groundType;
    const accessElements = accessTypes.map(accessType => ({
        isChecked: activeAccessType === accessType.key,
        text: intl.formatMessage({ id: accessType.label }),
    }));
    const groundElements = groundTypes.map(groundType => ({
        isChecked: activeGroundType === groundType.key,
        text: intl.formatMessage({ id: groundType.label }),
    }));
    return (_jsxs(_Fragment, { children: [_jsx(ListingCheckList, { items: accessElements, iconVariant: IconVariant.Sign, title: intl.formatMessage({ id: 'ListingPage.accessTitle' }) }), _jsx(ListingCheckList, { items: groundElements, iconVariant: IconVariant.CamperOnGround, title: intl.formatMessage({ id: 'ListingPage.undergroundTitle' }) })] }));
};
export default AccessContent;
